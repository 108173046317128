import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StudentsAssignTableComponent, { StudentsTabViewModel } from 'site/pages/shared/events-and-requests/tabs/students-assign-component';
import { connect } from 'redux-scaffolding-ts';
import { EventFormStore } from 'stores/events/event-form-store';

export interface StudentsAssignTableComponentContainerProps extends WithTranslation {
  readOnly: boolean;
  eventFormStore?: EventFormStore;
}

@connect(['eventFormStore', EventFormStore])
class StudentsAssignTableComponentContainer extends Component<StudentsAssignTableComponentContainerProps> {
  private handleOnStudentsChange = (newValue: StudentsTabViewModel) => {
    this.props.eventFormStore.change({
      students: newValue.students,
      studentsAssigned: newValue.studentsAssigned,
      studentsRequested: newValue.studentsRequested,
      studentsMandatory: !newValue.studentsOptional
    });
  };

  render() {
    const { readOnly } = this.props;
    const event = this.props.eventFormStore.state.item;
    const value: StudentsTabViewModel = {
      students: event.students,
      studentsAssigned: event.studentsAssigned,
      studentsRequested: event.studentsRequested,
      studentsOptional: !event.studentsMandatory
    };

    return <StudentsAssignTableComponent readOnly={readOnly} value={value} onChange={this.handleOnStudentsChange} />;
  }
}

export default withTranslation()(StudentsAssignTableComponentContainer);
