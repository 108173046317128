import React, { FC, memo, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import IWSFormSectionComponent from './iws-form-section.component';
import {
  Average,
  IwsFormItemDto,
  TemplateSectionAnswersDto,
  QualifierDto,
  QualifierTemplateDto,
  IwsSkillAnswerDto,
  IwsFormListStore
} from 'stores/assessments/forms/iws-forms-store';
import { IWSFormContext } from './iws-edit-form';
import IwsFormTableScore from './iws-form-table-score';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import { Button } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
interface IWSFormTabProps extends WithTranslation {
  templateSectionAnswers: TemplateSectionAnswersDto;
  scoresVisibility: boolean;
  totalSkillAverage?: Average;
  onExportExcel?: () => void;
  iwsFormListStore?: IwsFormListStore;
}

const IWSFormTabComponent: FC<IWSFormTabProps> = ({ t, onExportExcel, iwsFormListStore, ...props }) => {
  const {
    getAssessorType,
    answerHandler,
    getAStatus,
    recommendationHandler,
    submittable,
    copyAnswersFromMachinesWithSameTemplate,
    ...contextValues
  } = useContext(IWSFormContext);
  const { isAdminOrPoC, isAssessor, isEmployee, userId } = contextValues;

  //const [templateSeleted, setTemplateSeleted] = useState({});

  const status = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).status) as string;
  const iwsFormQualifiers = useSelector<ApplicationState>(
    ({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).qualifiers || []
  ) as QualifierDto[];

  const assessorAnswerStatus = getAStatus();

  const isEmployeeStatus = status === 'FirstAssesment' || status === 'SecondAssesment';
  const assessorReadOnlyMode = isAssessor && isEmployeeStatus;

  const { templateSectionAnswers, scoresVisibility } = props;

  const getUserSkillsTemplateSectionAnswers = (): IwsSkillAnswerDto[] => {
    if (
      !(
        status === 'Done' ||
        isAdminOrPoC ||
        assessorReadOnlyMode ||
        isEmployee /* && status !== 'FirstAssesment'*/ ||
        (!isAdminOrPoC && isAssessor && assessorAnswerStatus?.answerStatus === 'Done') ||
        (!isAdminOrPoC && isAssessor && scoresVisibility)
      )
    ) {
      const iwsFormQualifiersTemplates = ((iwsFormQualifiers.filter(x => x.userId === userId).selectMany(x => x.templates) ||
        []) as QualifierTemplateDto[]).map(a => a.templateId);

      const userSkills = templateSectionAnswers.iwsSkillAnswers?.filter(x => iwsFormQualifiersTemplates.includes(x.templateId));
      return userSkills;
    } else return templateSectionAnswers.iwsSkillAnswers;
  };

  return (
    templateSectionAnswers && (
      <>
        {(status === 'Done' ||
          isAdminOrPoC ||
          assessorReadOnlyMode ||
          (isEmployee && status !== 'FirstAssesment') ||
          (!isAdminOrPoC && isAssessor && assessorAnswerStatus?.answerStatus === 'Done') ||
          (!isAdminOrPoC && isAssessor && scoresVisibility)) && (
          <>
            <div className="template__score-by-skills-table__container">
              <IwsFormTableScore
                scoreTitle={'SCORE BY SKILLS'}
                section={templateSectionAnswers}
                onRecommendationChange={(value: string, id: string, templateId?: string) =>
                  !isAdminOrPoC &&
                  !assessorReadOnlyMode &&
                  status !== 'Done' &&
                  !(isEmployee && status === 'Qualifier') &&
                  recommendationHandler(value, id, templateSectionAnswers.templateId ?? templateId)
                }
              />
            </div>

            <Button className="iws-form-btn" onClick={onExportExcel}>
              {t(`Export to Excel`)}
            </Button>
          </>
        )}
        <div id="iwsLanguageSelector" className="ssa-form-language-selector">
          <LanguageEditor
            className="planit-user-dropdown-custom"
            placeholder={t('Select Language')}
            clearable
            nullable
            value={iwsFormListStore.state.item.currentLanguage || null}
            onChange={val => iwsFormListStore.changeCurrentLanguage(val?.id || null)}
            onlyForTests
          />
        </div>
        <IWSFormSectionComponent
          skillSections={getUserSkillsTemplateSectionAnswers()}
          onQuestionAnswered={(answer, questionId, skillId, templateId, comment) =>
            (assessorAnswerStatus?.answerStatus === 'Pending' || assessorAnswerStatus?.answerStatus === 'Unknown') &&
            answerHandler(answer, questionId, skillId, templateId, comment)
          }
        />
      </>
    )
  );
};

export default withTranslation()(memo(IWSFormTabComponent));
