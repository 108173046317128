import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AnswerType } from 'stores/assessments/questionBank/theoretical-test-store';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';

interface AnswertypeSelectorProps extends WithTranslation, StrictDropdownProps {
  answerType: AnswerType;
  onChange: (event: React.SyntheticEvent<HTMLElement, Event>, value: StrictDropdownProps) => void;
}

const TheoreticalAnswertypeSelector: FC<AnswertypeSelectorProps> = ({ i18n: _0, tReady: _1, t, answerType, onChange, ...rest }) => {
  const options =
    answerType === 'Rating'
      ? [
          { value: 'A', text: 'Option A' },
          { value: 'B', text: 'Option B' },
          { value: 'C', text: 'Option C' },
          { value: 'D', text: 'Option D' },
          { value: 'E', text: 'Option E' }
        ]
      : [
          { value: 'Yes', text: 'Yes' },
          { value: 'No', text: 'No' }
        ];
  return (
    <Dropdown
      className={'planit-user-dropdown'}
      selection
      inline
      closeOnChange
      closeOnEscape
      closeOnBlur={true}
      selectOnBlur={false}
      selectOnNavigation={false}
      multiple={false}
      placeholder={t('Answer Type')}
      options={options}
      onChange={onChange}
      {...rest}
    />
  );
};
export default withTranslation()(TheoreticalAnswertypeSelector);
