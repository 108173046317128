import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Dropdown } from 'semantic-ui-react';
import { PositionCodeDto, DropDownPositionCodesStore } from 'stores/configuration/profiles/position-codes-store';
import { nameof } from 'utils/object';

interface PositionCodeEditorProps extends WithTranslation {
  value: string;
  onChange: (value: PositionCodeDto) => void;
  placeholder?: string;
  positionCodes?: DropDownPositionCodesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  clearable?: boolean;
  className?: string;
  inline?: boolean;
  readonly?: boolean;
  showDescription?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface PositionCodeEditorState {
  value: string;
  availablePositionCodes: { [id: string]: PositionCodeDto };
  positionCodeOptions: { text: string; value: string }[];
}

@connect(['positionCodes', DropDownPositionCodesStore])
class PositionCodeEditor extends React.Component<PositionCodeEditorProps, PositionCodeEditorState> {
  private get positionCodeStore() {
    return this.props.positionCodes;
  }

  private get placeHolderPros() {
    const { t } = this.props as any;

    if (this.props.placeholder) {
      return this.props.placeholder;
    } else {
      return t('Select PositionCode');
    }
  }

  private get classNamePros() {
    if (this.props.className) {
      return this.props.className;
    } else {
      return 'planit-users-inputs planit-user-dropdown planit-user-selector planit-user-selector__position-code';
    }
  }

  constructor(props: PositionCodeEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      availablePositionCodes: {},
      positionCodeOptions: []
    };

    this.initPositionCodes();
  }

  UNSAFE_componentWillReceiveProps(next: PositionCodeEditorProps) {
    if (next !== this.props && next.value !== this.props.value && (next.value == null || this.props.value == null)) {
      this.setState({
        value: next.value
      });
      this.initPositionCodes();
    }
  }

  private async initPositionCodes() {
    return await this.positionCodeStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<PositionCodeDto>('code'), useProfile: false }]
      })
      .then(positionCodes => {
        const dict = {};
        const options = [];

        positionCodes.items.forEach(posCode => {
          dict[posCode.id] = posCode;
          options.push({ text: this.props.showDescription ? posCode.description : posCode.code, value: posCode.id });
        });

        this.setState({
          availablePositionCodes: dict,
          positionCodeOptions: options
        });
      });
  }

  public render() {
    const positionCodeOptions = this.state.positionCodeOptions;

    return (
      <Dropdown
        disabled={this.props.readonly}
        clearable={this.props.clearable || false}
        search
        inline={this.props.inline != null ? this.props.inline : true}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        options={positionCodeOptions}
        className={this.classNamePros}
        value={this.state.value ? this.state.value : null}
        onChange={(e, data) => this.selectedPositionCode(data)}
        placeholder={this.placeHolderPros}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }

  selectedPositionCode(data: any) {
    this.setState({
      value: data.value
    });

    this.props.onChange(this.state.availablePositionCodes[data.value]);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(PositionCodeEditor);
