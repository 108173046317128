import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
// import { EventInstructorRole } from 'stores/events/events-store';

export interface EventGroupRelationshipDto extends BaseDto {
  id: string;
  eventGroupId: string;
  eventGroupName: string;
  leadTypeHoursCodeId: string;
  leadTypeHoursCodeName: string;
  apprenticeTypeHoursCodeId: string;
  apprenticeTypeHoursCodeName: string;
}

export interface CreateEventGroupRelationshipDto {
  eventGroupId: string;
  leadTypeHoursCodeId: string;
  // leadTypeHourCode: TypeHoursCodeDto;
  apprenticeTypeHoursCodeId: string;
  // apprenticeTypeHourCode: TypeHoursCodeDto;
}

export interface ChangeEventGroupRelationshipDto {
  id: string;
  eventGroupId: string;
  leadTypeHoursCodeId: string;
  apprenticeTypeHoursCodeId: string;
}

export class CreateEventGroupRelationshipValidator extends AbstractValidator<CreateEventGroupRelationshipDto> {
  constructor() {
    super();

    this.validateIfString(o => o.eventGroupId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Group is required'));

    this.validateIfString(o => o.leadTypeHoursCodeId)
      .isUuid('4')
      .whenNotEmpty()
      .whenNotNull()
      .withFailureMessage(i18n.t('Lead Type Hour Code Id not Guid'));

    this.validateIfString(o => o.apprenticeTypeHoursCodeId)
      .isUuid('4')
      .whenNotEmpty()
      .whenNotNull()
      .withFailureMessage(i18n.t('Lead Type Hour Code Id not Guid'));
  }
}

export class ChangeEventGroupRelationshipValidator extends AbstractValidator<ChangeEventGroupRelationshipDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Relation Id is required'));

    this.validateIfString(o => o.eventGroupId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Group  is required'));

    this.validateIfString(o => o.leadTypeHoursCodeId)
      .isUuid('4')
      .whenNotEmpty()
      .whenNotNull()
      .withFailureMessage(i18n.t('Lead Type Hour Code Id not Guid'));

    this.validateIfString(o => o.apprenticeTypeHoursCodeId)
      .isUuid('4')
      .whenNotEmpty()
      .whenNotNull()
      .withFailureMessage(i18n.t('Lead Type Hour Code Id not Guid'));
  }
}

@repository('@@EVENTGROUPRELATIONSHIPS', 'eventGroupRelationship.summary')
export class EventGroupRelationshipsStore extends DataStore<EventGroupRelationshipDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroupRelationship';
  retrievePath = 'get-eventGroupRelationships';
  updatePath = 'update-eventGroupRelationship';
  deletePath = 'delete-eventGroupRelationship';

  protected validate(item: EventGroupRelationshipDto) {
    return new ChangeEventGroupRelationshipValidator().validate(item);
  }

  constructor() {
    super('EVENTGROUPRELATIONSHIP', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTGROUPRELATIONSHIPS', 'eventGroupRelationship.dropdown')
export class DropDownEventGroupRelationshipsStore extends DataStore<EventGroupRelationshipDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-eventGroupRelationships';
  updatePath = '';
  deletePath = '';

  protected validate(item: EventGroupRelationshipDto) {
    return new ChangeEventGroupRelationshipValidator().validate(item);
  }

  constructor() {
    super('EVENTGROUPRELATIONSHIPDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTGROUPRELATIONSHIPS', 'eventGroupRelationship.new')
export class NewEventGroupRelationshipStore extends FormStore<CreateEventGroupRelationshipDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroupRelationship';
  retrievePath = 'get-eventGroupRelationships';
  updatePath = 'update-eventGroupRelationship';

  protected validate(item: CreateEventGroupRelationshipDto) {
    return new CreateEventGroupRelationshipValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENTGROUPRELATIONSHIP', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EVENTGROUPRELATIONSHIPS', 'eventGroupRelationship.change')
export class ChangeEventGroupRelationshipStore extends FormStore<ChangeEventGroupRelationshipDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroupRelationship';
  retrievePath = 'get-eventGroupRelationshipRelationships';
  updatePath = 'update-eventGroupRelationship';

  protected validate(item: ChangeEventGroupRelationshipDto) {
    return new ChangeEventGroupRelationshipValidator().validate(item);
  }

  constructor() {
    super('CHANGE_EVENTGROUPRELATIONSHIP', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
