import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import StepsComponent from '../../../../../../shared/tna-and-ssa/wizard/wizard-step';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { WithTranslation, withTranslation } from 'react-i18next';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateInput } from 'widgets/form/dateInput';
import './general-data-step.less';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { SsaFormWizardStore, SsaFormWizardViewModel } from 'stores/assessments/wizard/ssa-form-wizard-store';
import { connect } from 'redux-scaffolding-ts';
import { DateTimeService } from 'services/datetime-service';

export interface GeneralDataStepProps extends WithTranslation {
  active: boolean;
  ssaFormWizardStore?: SsaFormWizardStore;
  onStepChanged?: () => void;
  isEditMode?: boolean;
  isGlobalPoc: boolean;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
class GeneralDataStep extends Component<GeneralDataStepProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private handleOnChange = (property: keyof SsaFormWizardViewModel, propertyValue: any) => {
    let changes = {};
    changes[property] = propertyValue;

    this.props.ssaFormWizardStore.change(changes);
    this.props.onStepChanged && this.props.onStepChanged();
  };

  render() {
    const { t, active: isStepActive, isEditMode, isGlobalPoc } = this.props;
    const { title, location, profileId, deadline } = this.props.ssaFormWizardStore.state.item;
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !isGlobalPoc
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    return (
      <div className="wizard__step wizard__step-one ssa-form-general-data">
        <StepsComponent active={isStepActive} number={'1'} title={t('GENERAL DATA')}>
          <div className="step-one__content ssa-form-wizard__step-one__title-wrapper editor-38px">
            <label>{t('SSA Title')}</label>
            <Input
              value={title}
              className="normal-looking-disabled-input"
              onChange={(c, { value }) => this.handleOnChange('title', value)}
            />
          </div>

          <div className="step-one__content ssa-form-step-one__content">
            <div className={`editor-38px ${pocLocations.length === 1 ? 'normal-looking-disabled-input' : ''}`}>
              <label>{t('Select Location')}</label>
              <LocationEditor
                loadDataOnOpen={true}
                nullable={pocLocations.length !== 1}
                clearable={pocLocations.length !== 1}
                readOnly={pocLocations.length === 1 || isEditMode}
                placeholder={t('Location')}
                value={location}
                onChange={l => this.handleOnChange('location', l)}
                locationsReceived={pocLocations}
              />
            </div>
            <div className="editor-38px">
              <label>{t('Who is this SSA for?')}</label>
              <ProfileEditor
                readonly={isEditMode}
                assessment={'SSA'}
                nullable
                value={profileId}
                className="custom-editor"
                clearable
                placeholder={t('Profile')}
                onChange={profile => this.handleOnChange('profileId', profile ? profile.id : null)}
              />
            </div>
            <div className="ssa-form-wizard__step-one__deadline-wrapper editor-38px">
              <label>{t('Set Deadline')}</label>
              <DateInput
                propagateOnInvalid
                value={deadline}
                onChange={(_, d) => this.handleOnChange('deadline', d)}
                iconPosition="right"
                minDate={isEditMode ? null : DateTimeService.toString(DateTimeService.today())}
              />
            </div>
          </div>
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(GeneralDataStep);
