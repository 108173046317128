import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { MachineModelDto, MachineModelsStore } from '../../stores/configuration/machinery/machine-models-store';
import { SelectionInput, DropdownProps, SelectionItem } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces, customEqualCompare } from '../../utils/useful-functions';

export interface MachineModelItemReference extends ItemReference {
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
}

interface MachineModelEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference | string;
  onChange?: (value: ItemReference | MachineModelItemReference) => void;
  machineModels?: MachineModelsStore;
  cascade?: string;
  reloadOnChange?: boolean;
  clearOnReload?: boolean;
  clearable?: boolean;
  equipmentId?: string;
  clusterId?: string;
  oemId?: string;
  readonly?: boolean;
  error?: boolean;
  direction?: 'left' | 'right';
  loadDataOnOpen?: boolean;
}

interface MachineModelEditorState {
  value: MachineModelItemReference | ItemReference | string;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
  selectedClusterId?: string;
  selectedEquipmentId?: string;
  selectedOemId?: string;
  currentQuery?: QueryResult<MachineModelDto>;
  isAlreadyQuerying: boolean;
}

@connect(['machineModels', MachineModelsStore])
class MachineModelEditor extends React.Component<MachineModelEditorProps, MachineModelEditorState> {
  private get machineModelsStore() {
    return this.props.machineModels;
  }

  constructor(props: MachineModelEditorProps) {
    super(props);
    this.state = {
      searchQuery: null,
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getMachineModelMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value),
      selectedClusterId: null,
      selectedEquipmentId: null,
      selectedOemId: null,
      currentQuery: null,
      isAlreadyQuerying: false
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange, oemId, equipmentId, clusterId } = this.props;

    if (!customEqualCompare(prevProps.value, value) && reloadOnChange) {
      this.setState({
        value,
        searchQuery: null,
        options: getOptionsFromValue(this.state?.query, this.props.value)
      });
    }

    if (
      !customEqualCompare(oemId, prevProps.oemId) ||
      !customEqualCompare(equipmentId, prevProps.equipmentId) ||
      clusterId !== prevProps.clusterId
    ) {
      this.setState({
        value: value,
        searchQuery: null,
        query: this.getMachineModelMethod(),
        options: getOptionsFromValue(this.state?.query, this.props.value)
      });
    }
  }

  onOpenDropDown = () => {
    const { value } = this.props;

    if ((!this.hasPrefilterMachine() || !!!value) && this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getMachineModelMethod() });
    }
  };

  @autobind
  private getMachineModelMethod() {
    const method = async (search: string) => {
      if (this.state.isAlreadyQuerying) return;
      this.setState({ isAlreadyQuerying: true });
      const filters = isNullOrWhiteSpaces(search)
        ? ['active eq true']
        : [
            `active eq true ${this.props.cascade ? `and ${this.props.cascade}` : ''} and contains(tolower(${nameof<MachineModelDto>(
              'name'
            )}), '${search.toLowerCase()}')`
          ];

      if (
        (this.props.clusterId || this.props.equipmentId || this.props.oemId) &&
        this.state?.currentQuery?.items &&
        this.props.clusterId === this.state.selectedClusterId &&
        this.props.equipmentId === this.state.selectedEquipmentId &&
        this.props.oemId === this.state.selectedOemId
      ) {
        this.setState({ isAlreadyQuerying: false });
        return Object.assign({}, this.state.currentQuery, {
          items: this.state.currentQuery.items.map(c => ({
            id: c.id,
            title: c.name,
            cluster: { id: c.clusterId, title: c.clusterName },
            equipmentType: { id: c.equipmentTypeId, title: c.equipmentTypeName },
            oem: { id: c.oemId, title: c.oemName }
          }))
        }) as QueryResult<MachineModelItemReference>;
      }

      if (this.props.clusterId) {
        filters.push(`${nameof<MachineModelDto>('clusterId')} eq ${this.props.clusterId}`);
        this.setState({ selectedClusterId: this.props.clusterId });
      } else {
        this.setState({ selectedClusterId: undefined });
      }

      if (this.props.equipmentId) {
        filters.push(`${nameof<MachineModelDto>('equipmentTypeId')} eq ${this.props.equipmentId}`);
        this.setState({ selectedEquipmentId: this.props.equipmentId });
      } else {
        this.setState({ selectedEquipmentId: undefined });
      }

      if (this.props.oemId) {
        filters.push(`${nameof<MachineModelDto>('oemId')} eq ${this.props.oemId}`);
        this.setState({ selectedOemId: this.props.oemId });
      } else {
        this.setState({ selectedOemId: undefined });
      }

      const result = await this.machineModelsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<MachineModelDto>('name'), useProfile: false }],
        filter: filters
      });

      this.setState({
        currentQuery: this.props.clusterId || this.props.equipmentId || this.props.oemId ? result : undefined,
        isAlreadyQuerying: false
      });

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.id,
          title: c.name,
          cluster: { id: c.clusterId, title: c.clusterName },
          equipmentType: { id: c.equipmentTypeId, title: c.equipmentTypeName },
          oem: { id: c.oemId, title: c.oemName }
        }))
      }) as QueryResult<MachineModelItemReference>;
    };
    return method;
  }

  @autobind
  private hasPrefilterMachine(): boolean {
    if (this.props.clusterId || this.props.equipmentId || this.props.oemId) {
      return true;
    } else {
      return false;
    }
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        direction={this.props.direction}
        error={this.props.error || false}
        content={item => <div>{item.title}</div>}
        searchable
        clearable={this.props.clearable}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        readOnly={this.props.readonly}
        value={val}
        searchQuery={this.state.searchQuery}
        onQueryChange={q => this.setState({ searchQuery: q })}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
        clearOnReload={this.props.clearOnReload}
        options={query !== null ? null : options}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MachineModelEditor);
