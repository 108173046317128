import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import i18n from '../../../../i18n';
import OemsListPage from './oems/oems-list';
import ClustersListPage from './clusters/clusters-list';
import EquipmentTypesListPage from './equipment-types/equipment-types-list';
import MachineUnitsListPage from './machine-units/machine-units-list';
import PlcTypesListPage from './plc-types/plc-types-list';
import PatternsListPage from './patterns/pattern-list';
import MachineModelListPage from './machine-models/machine-model-list';
import queryString from 'query-string';
import AllListPage from './all-machines-list';

export interface MachineryConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface MachineryConfigurationHomeState {
  activeIndex: number;
}

class MachineryConfigurationHomePage extends React.Component<MachineryConfigurationHomeProps, MachineryConfigurationHomeState> {
  private panes = [
    {
      menuItem: i18n.t('Patterns'),
      render: () => (
        <Tab.Pane attached={false}>
          <PatternsListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Clusters'),
      render: () => (
        <Tab.Pane attached={false}>
          <ClustersListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Equipment Types'),
      render: () => (
        <Tab.Pane attached={false}>
          <EquipmentTypesListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('OEMs'),
      render: () => (
        <Tab.Pane attached={false}>
          <OemsListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Machine Models'),
      render: () => (
        <Tab.Pane attached={false}>
          <MachineModelListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Machine Units'),
      render: () => (
        <Tab.Pane attached={false}>
          <MachineUnitsListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('PLC Types'),
      render: () => (
        <Tab.Pane attached={false}>
          <PlcTypesListPage readOnly={false} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('All'),
      render: () => (
        <Tab.Pane attached={false}>
          <AllListPage />
        </Tab.Pane>
      )
    }
  ];

  private getActiveIndex = () => {
    let values = queryString.parse(this.props.location.search);
    if (values == null || values.tab == null) values = this.props.match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    const l = this.panes.length;
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
    return 0;
  };

  state: MachineryConfigurationHomeState = {
    activeIndex: this.getActiveIndex()
  };

  private onTabChange = (_, { activeIndex }) => {
    this.props.history.replace(`/configuration/machinery?tab=${activeIndex}`);
    this.setState({ activeIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const menu = { secondary: true, pointing: true };
    return (
      <>
        <h3>{t('Machine Related')}</h3>
        <Tab className="main-tab" activeIndex={activeIndex} onTabChange={this.onTabChange} menu={menu} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(MachineryConfigurationHomePage);
