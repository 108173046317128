import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { BaseDto } from '../types';

export interface MatchRowDto extends BaseDto {
  id: string;
  name: string;
  surname: string;
  email: string;
  locationId: string;
  location: string;
  personnelAreaId: string;
  personnelArea: string;
  positionCode: string;
  userImportId: string;
  userId: string;
  idPUserId: string;
  selected: boolean;
}

export interface CreateMatchRowDto extends BaseDto {
  name: string;
  surname: string;
  email: string;
  locationId: string;
  location: string;
  personnelAreaId: string;
  personnelArea: string;
  positionCode: string;
  userImportId: string;
  userId: string;
  idPUserId: string;
}

export interface ChangeMatchRowDto extends BaseDto {
  id: string;
  name: string;
  surname: string;
  email: string;
  locationId: string;
  location: string;
  personnelAreaId: string;
  personnelArea: string;
  positionCode: string;
  userImportId: string;
  userId: string;
  idPUserId: string;
}

@repository('@@MATCHES', 'matches.summary')
export class MatchRowsStore extends DataStore<MatchRowDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-match';
  retrievePath = 'get-matches-by-id';
  updatePath = '';
  deletePath = 'delete-match';

  protected validate(item: MatchRowDto) {
    return new CreateMatchRowValidator().validate(item);
  }
  constructor() {
    super('MATCHES', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

export class CreateMatchRowValidator extends AbstractValidator<CreateMatchRowDto> {
  constructor() {
    super();

    this.validateIfString(o => o.userImportId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('User Import Id is required'));
  }
}
