import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps /* , Form, Input */ } from 'semantic-ui-react';
import { EventInstructorRole } from 'stores/events/events-store';

interface EventInstructorRoleEditorProps extends WithTranslation {
  value: EventInstructorRole;
  onChange?: (value: EventInstructorRole) => void;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
}

interface EventInstructorRoleEditorState {
  options: DropdownItemProps[];
}

class EventInstructorRoleEditor extends React.Component<EventInstructorRoleEditorProps, EventInstructorRoleEditorState> {
  constructor(props: EventInstructorRoleEditorProps) {
    super(props);
    this.state = {
      options: this.getAllEventInstructorRoles()
    };
  }

  public render() {
    return (
      <Dropdown
        className={!this.props.className ? 'user-dropdown' : this.props.className}
        selection
        disabled={this.props.readOnly}
        options={this.state.options}
        value={EventInstructorRole[this.props.value]}
        onChange={(e, { value }) => this.handleOnSelection(value)}
      />
    );
  }

  private handleOnSelection(value) {
    let eventInstructorRoleStr: string = value;
    let eventInstructorRole: EventInstructorRole = EventInstructorRole[eventInstructorRoleStr];
    this.props.onChange(eventInstructorRole);
  }

  private getAllEventInstructorRoles() {
    let map: { text: string; value: string }[] = [];

    for (let n in EventInstructorRole) {
      if (typeof EventInstructorRole[n] === 'number') {
        map.push({ text: n, value: EventInstructorRole[n] });
      }
    }

    return map;
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EventInstructorRoleEditor);
