import React, { FC, ReactText } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AnswerType, OptionSelected, TheoreticalQuestionDto } from 'stores/assessments/questionBank/theoretical-test-store';
import { Button, Checkbox, Container } from 'semantic-ui-react';

interface AnswerComponentProps extends WithTranslation {
  type: AnswerType;
  onChange: (answer: OptionSelected | ReactText) => void;
  value: OptionSelected | 'unavailableMedia';
  item: TheoreticalQuestionDto;
  disabled: boolean;
}

const AnswerComponent: FC<AnswerComponentProps> = ({ value, type, t, onChange, item, disabled }) => {
  const { optionA, optionB, optionC, optionD, optionE } = item;
  if (type === 'YesOrNo')
    return (
      <>
        <Button disabled={disabled} className={`answer-btn${value === 'Yes' ? ' active' : ''}`} onClick={() => onChange('Yes')}>
          {t('Yes')}
        </Button>
        <Button disabled={disabled} className={`answer-btn${value === 'No' ? ' active' : ''}`} onClick={() => onChange('No')}>
          {t('No')}
        </Button>
      </>
    );
  if (type === 'Rating')
    return (
      <Container className="rating-answer__container">
        <Checkbox
          disabled={disabled}
          radio
          className={`answer-checkbox${value === 'Yes' ? ' active' : ''}`}
          type="checkbox"
          name="rating-answer"
          value={'A'}
          onChange={(_, { value }) => !disabled && onChange(value)}
          checked={value === 'A'}
          label={`A. ${optionA || ''}`}
        />
        <Checkbox
          disabled={disabled}
          radio
          className={`answer-checkbox${value === 'Yes' ? ' active' : ''}`}
          type="checkbox"
          name="rating-answer"
          value={'B'}
          onChange={(_, { value }) => !disabled && onChange(value)}
          checked={value === 'B'}
          label={`B. ${optionB || ''}`}
        />
        <Checkbox
          disabled={disabled}
          radio
          className={`answer-checkbox${value === 'Yes' ? ' active' : ''}`}
          type="checkbox"
          name="rating-answer"
          value={'C'}
          onChange={(_, { value }) => !disabled && onChange(value)}
          checked={value === 'C'}
          label={`C. ${optionC || ''}`}
        />
        {optionD && (
          <Checkbox
            disabled={disabled}
            radio
            className={`answer-checkbox${value === 'Yes' ? ' active' : ''}`}
            type="checkbox"
            name="rating-answer"
            value={'D'}
            onChange={(_, { value }) => !disabled && onChange(value)}
            checked={value === 'D'}
            label={`D. ${optionD || ''}`}
          />
        )}
        {optionE && (
          <Checkbox
            disabled={disabled}
            radio
            className={`answer-checkbox${value === 'Yes' ? ' active' : ''}`}
            type="checkbox"
            name="rating-answer"
            value={'E'}
            onChange={(_, { value }) => !disabled && onChange(value)}
            checked={value === 'E'}
            label={`E. ${optionE || ''}`}
          />
        )}
      </Container>
    );
};
export default withTranslation()(AnswerComponent);
