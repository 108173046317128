import moment from 'moment';
import { WorkingDayDto } from 'stores/working-days/working-days-store';
import { PublicHolidayDto } from 'stores/public-holidays/public-holidays-store';
import { DateTimeService, MomentUnit } from 'services/datetime-service';
import { ResourceTimeRangeModel, RecurringTimeSpan } from '@planit/bryntum-scheduler';
import { clone, nameof } from 'utils/object';
import { EventDto, EventInstructorDto } from 'stores/events/events-store';
import { extractFriendlyIdNumber } from 'utils/useful-functions';
import { RequestDto } from 'stores/requests/requests-store';
import { RequestPopupFilterValues } from './request-component/request-popup-filters';
import { IUserInfo } from 'services/identity-service';

export interface ResourceTR extends ResourceTimeRangeModel, RecurringTimeSpan {}

export const getResourceTR = (cls: string, from?: string, to?: string, id?: string, dur?: number, recurr?: boolean): ResourceTR => {
  const result: any = {};
  const defaultStartDate = moment()
    .subtract(1, 'y')
    .startOf('y')
    .startOf('m')
    .startOf('w')
    .day('Saturday')
    .startOf('d')
    .toISOString();
  if (from) result.startDate = from;
  else result.startDate = defaultStartDate;
  if (to) result.endDate = to;
  result.resourceId = id;
  if (dur) result.duration = dur;
  else result.duration = 2;
  result.durationUnit = 'day';
  if (cls) result.cls = cls;
  if (recurr) result.recurrenceRule = 'FREQ=WEEKLY;INTERVAL=1;';
  return result;
};

export const getAllLocationWorkingDays = (workingDays: WorkingDayDto, resourceId?: string): ResourceTR[] => {
  const result = [];
  const id = resourceId || workingDays.locationId;
  moment.weekdays().forEach(day => {
    if (workingDays[day.toLowerCase()] === false) {
      const startDate = moment()
        .subtract(1, 'y')
        .startOf('y')
        .startOf('m')
        .startOf('w')
        .day(day)
        .startOf('d')
        .toISOString();

      result.push(getResourceTR('weekend', startDate, null, id, 1, true));
    }
  });
  return result;
};

export const getAllLocationPublicHolidays = (publicHolidays: PublicHolidayDto, resourceId?: string): ResourceTR[] => {
  const { publicHolidayPeriods, locationId } = publicHolidays;
  const result = [];
  const id = resourceId || locationId;
  (publicHolidayPeriods || []).forEach(holidaysRange => {
    const { startDate: from, endDate: to } = holidaysRange;
    const startDate = moment(from)
      .startOf('day')
      .toString();
    const duration = DateTimeService.toMoment(to, true).diff(DateTimeService.toMoment(from), 'day');
    duration >= 0 && result.push(getResourceTR('holiday', startDate, null, id, +duration + 1, false));
  });
  return result;
};

export const momentOperation = (date: string, dateDifference: number, operator: 'add' | 'subtract', unit: MomentUnit = 'd') =>
  DateTimeService.toMoment(date)
    [operator](Math.abs(dateDifference), unit)
    .toISOString();

export const manageEventDates = (e: EventDto, dateDifference: number = 0, operator: 'add' | 'subtract'): EventDto => {
  if (dateDifference === 0) return e;
  const event = clone<EventDto>(e);

  event.startDate = momentOperation(event.startDate, dateDifference, operator);
  event.endDate = momentOperation(event.endDate, dateDifference, operator);

  event.pausePeriods = (event.pausePeriods || []).map(pp => ({
    from: momentOperation(pp.from, dateDifference, operator),
    to: momentOperation(pp.to, dateDifference, operator)
  }));

  event.instructors = (event.instructors || []).map(ins => manageInstructorDates(ins, dateDifference, operator));
  return event;
};

export const manageInstructorDates = (instructor: EventInstructorDto, dateDifference: number = 0, operator: 'add' | 'subtract') => {
  if (!instructor) return;
  if (instructor.travelDays?.departure?.from && instructor.travelDays?.departure?.to) {
    instructor.travelDays.departure.from = momentOperation(instructor.travelDays?.departure?.from, dateDifference, operator);
    instructor.travelDays.departure.to = momentOperation(instructor.travelDays?.departure?.to, dateDifference, operator);
  }

  if (instructor.travelDays?.arrival?.from && instructor.travelDays?.arrival?.to) {
    instructor.travelDays.arrival.from = momentOperation(instructor.travelDays?.arrival?.from, dateDifference, operator);
    instructor.travelDays.arrival.to = momentOperation(instructor.travelDays?.arrival?.to, dateDifference, operator);
  }

  if (instructor?.period?.from && instructor?.period?.to) {
    instructor.period.from = momentOperation(instructor.period.from, dateDifference, operator);
    instructor.period.to = momentOperation(instructor.period.to, dateDifference, operator);
  }

  return instructor;
};

export const buildRequestFilter = (
  value: RequestPopupFilterValues,
  activeRole: string,
  currentUserInfo: IUserInfo,
  isGlobalReporting?: boolean
) => {
  if (isGlobalReporting == null) {
    isGlobalReporting = false;
  }

  const filters: any = getInstructorDefaultRequestFilterValue(activeRole, currentUserInfo);
  if (!value) return filters;
  const { title, eventType, location, machineModel, isMachineRelated, trainingLevel, deliveryMethod, ...rest } = value;
  const { category, cluster, friendlyId, functionalArea, priority } = rest;
  if (friendlyId) {
    const input = friendlyId as string;
    let filter = `contains(tolower(FriendlyEventId), '${input.toLowerCase()}')`;

    const fId = extractFriendlyIdNumber(input, 'R');
    if (!Number.isNaN(fId)) {
      if (input.startsWith('R')) filter = `cast(FriendlyEventId, 'Edm.String') eq '${fId}'`;
      else filter = `contains(cast(FriendlyEventId, 'Edm.String'), '${fId !== 0 ? fId : input}')`;
    }
    filters.push(filter);
  }

  if (eventType && eventType.text) filters.push({ EventType: { Name: { eq: eventType.text } } });

  if (title) filters.push(`contains(tolower(${nameof<RequestDto>('title')}),'${title.toLowerCase()}')`);

  if (trainingLevel) filters.push({ TrainingLevelId: { eq: { type: 'guid', value: trainingLevel } } });

  if (location && !isGlobalReporting) filters.push({ RequestingLocationId: { eq: { type: 'guid', value: location } } });

  if (deliveryMethod) filters.push({ DeliveryMethodId: { eq: { type: 'guid', value: deliveryMethod } } });

  if (priority) filters.push({ priorityId: { eq: { type: 'guid', value: priority } } });

  if (category) filters.push({ category });

  if (machineModel) filters.push({ RequestMachines: { any: { MachineModelId: { eq: { type: 'guid', value: machineModel } } } } });

  if (isMachineRelated != null) filters.push({ isMachineRelated: { eq: isMachineRelated } });

  if (cluster) filters.push({ nmrClusterId: { eq: { type: 'guid', value: cluster } } });

  if (cluster && functionalArea) filters.push({ nmrFunctionalAreaId: { eq: { type: 'guid', value: functionalArea } } });

  return filters;
};

const initialRequestFilter = () => [{ or: [{ Status: 'Pending' }, { Status: 'InProgress' }] }];

const locatedRequestFilter = (value: string[]) => {
  return { or: [{ RequestingLocationId: { in: { type: 'guid', value } } }, { EventLocationId: { in: { type: 'guid', value } } }] };
};

export const getInstructorDefaultRequestFilterValue = (activeRole: string, currentUserInfo: IUserInfo) => {
  if (activeRole === 'PoC') {
    const locationsByActiveRole = currentUserInfo.locationsByRoles[activeRole] as string[];
    return [...initialRequestFilter(), locatedRequestFilter(locationsByActiveRole)];
  }
  return initialRequestFilter();
};

export const getLocationDefaultRequestFilterValue = (activeRole: string, currentUserInfo: IUserInfo, isGlobalReporting?: boolean) => {
  if (activeRole === 'PoC' || activeRole === 'Factory Lead' || (activeRole === 'Reporting' && !isGlobalReporting)) {
    const locationsByActiveRole = currentUserInfo.locationsByRoles[activeRole] as string[];
    return [...initialRequestFilter(), locatedRequestFilter(locationsByActiveRole)];
  }
  return initialRequestFilter();
};
