import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { DeliveryTypeDto, DropDownDeliveryTypesStore } from '../../stores/configuration/events-n-requests/delivery-types-store';
import { nameof } from '../../utils/object';
import { getOptionsFromValue, ItemReference, QueryResult } from '../../stores/dataStore';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';
import { SelectionInput, DropdownProps, SelectionItem } from 'widgets/form/selectionInput';

interface DeliveryMethodEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange?: (value: ItemReference) => void;
  deliveryMethods?: DropDownDeliveryTypesStore;
  reloadOnChange?: boolean;
  clearable?: boolean;
  loadDataOnOpen?: boolean;
}

interface DeliveryMethodEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['deliveryMethods', DropDownDeliveryTypesStore])
class DeliveryMethodEditor extends React.Component<DeliveryMethodEditorProps, DeliveryMethodEditorState> {
  private get deliveryMethodsStore() {
    return this.props.deliveryMethods;
  }

  constructor(props: DeliveryMethodEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getDeliveryMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.value !== this.props.value && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        options: getOptionsFromValue(this.state?.query, next.value)
        // query: this.getDeliveryMethod()
      });
    }
  }

  @autobind
  private getDeliveryMethod() {
    const method = async (search: string) => {
      const result = await this.deliveryMethodsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<DeliveryTypeDto>('name'), useProfile: false }],
        filter: isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<DeliveryTypeDto>('name')}), '${search.toLowerCase()}')`]
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getDeliveryMethod() });
    }
  };

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        content={item => <>{item.title}</>}
        searchable
        className={this.props.className}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        clearable={this.props.clearable}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(DeliveryMethodEditor);
