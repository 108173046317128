// import { BaseDto } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrUndefined } from 'util';
import { TestCategoryDto } from 'stores/assessments/testCategory-store';

export interface ProfileItemDto {
  id: string;
  name: string;
  friendlyId: string;
  professionId: string;
  professionName: string;
  skills: ProfileSkillDto[];
  assessment: AssessmentStrings;
  description: string;
  isActive: boolean;
}
export interface ProfileSkillDto {
  assessment: AssessmentStrings;
  skillId: string;
  skillName: string;
  substitute: number;
  main: number;
  isMachineRelated?: boolean;
  testCategory?: TestCategoryDto;
  phase0: number;
  phase1: number;
  phase2: number;
  phase3: number;
  phase4: number;
}

export enum Assessment {
  Unknown = 0,
  TNA = 10,
  SSA = 20,
  IWS = 30
}

export type AssessmentStrings = keyof typeof Assessment;

export interface CreateProfileItemDto {
  professionId: string;
  skills: ProfileSkillDto[];
  description: string;
  assessment: AssessmentStrings;
  isActive: boolean;
}

export interface ChangeProfileItemDto {
  id: string; //Guid
  professionId: string;
  skills: ProfileSkillDto[];
  assessment: AssessmentStrings;
  description: string;
  isActive: boolean;
}

export class CreateProfileItemValidator extends AbstractValidator<CreateProfileItemDto> {
  constructor() {
    super();

    this.validateIfString(p => p.professionId)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIf(p => p.assessment)
      .isNotNull()
      .isDefined()
      .fulfills(x => Assessment[x].toString() !== Assessment[Assessment.Unknown].toString())
      .withFailureMessage(i18n.t('Assessment is required'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => p.skills.length === 0 || p.skills.all(x => x.substitute <= x.main))
      .when(p => p.assessment !== 'IWS')
      .withFailureMessage(i18n.t('Skills main grade should be greater than substitute grade'));

    //Commented Validation
    // this.validateIf(x => x)
    //   .fulfills(
    //     ({ skills, assessment }) =>
    //       skills?.length === 0 ||
    //       Assessment[assessment].toString() !== Assessment[Assessment.TNA].toString() ||
    //       (Assessment[assessment].toString() === Assessment[Assessment.TNA].toString() && skills?.some(y => y.isMachineRelated))
    //   )
    //   .withFailureMessage(i18n.t('There should be at least one machine related skill'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => p.skills.length === 0 || p.skills.all(x => !isNullOrUndefined(x.phase0)))
      .when(p => p.assessment === 'IWS')
      .withFailureMessage(i18n.t('Skills Phase 1 is required'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => {
        const skillIds = p.skills.map(({ skillId }) => skillId);
        const duplicatedSkills = skillIds.find((skillId, index) => skillIds.indexOf(skillId) !== index);
        return p.skills.length === 0 || isNullOrUndefined(duplicatedSkills);
      })
      .withFailureMessage(i18n.t('There are duplicate skills'));
  }
}

export class ChangeProfileItemValidator extends AbstractValidator<ChangeProfileItemDto> {
  constructor() {
    super();

    this.validateIfString(p => p.professionId)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIf(p => p.assessment)
      .isNotNull()
      .isDefined()
      .fulfills(assessment => assessment !== Assessment[Assessment.Unknown].toString())
      .withFailureMessage(i18n.t('Assessment is required'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => p.skills.length === 0 || p.skills.all(x => x.substitute <= x.main))
      .when(p => p.assessment !== 'IWS')
      .withFailureMessage(i18n.t('Skills main grade should be greater than substitute grade'));

    //Commented Validation
    // this.validateIf(x => x)
    //   .fulfills(
    //     ({ skills, assessment }) =>
    //       skills?.length === 0 ||
    //       assessment !== Assessment[Assessment.TNA].toString() ||
    //       (assessment === Assessment[Assessment.TNA].toString() && skills?.some(y => y.isMachineRelated))
    //   )
    //   .withFailureMessage(i18n.t('There should be at least one machine related skill'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => p.skills.length === 0 || p.skills.all(x => !isNullOrUndefined(x.phase0)))
      .when(p => p.assessment === 'IWS')
      .withFailureMessage(i18n.t('Skills Phase 1 is required'));

    this.validateIf(p => p)
      .isDefined()
      .isNotNull()
      .fulfills(p => {
        const skillIds = p.skills.map(({ skillId }) => skillId);
        const duplicatedSkills = skillIds.find((skillId, index) => skillIds.indexOf(skillId) !== index);
        return p.skills.length === 0 || isNullOrUndefined(duplicatedSkills);
      })
      .withFailureMessage(i18n.t('There are skills duplicated'));
  }
}

@repository('@@PROFILES', 'profiles.summary')
export class ProfileItemStore extends DataStore<ProfileItemDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-profiles';
  retrievePath = 'get-profiles';
  updatePath = 'update-profile';
  deletePath = '';
  retrieveOnePath = 'get-profile';

  protected validate(item: ProfileItemDto) {
    return new ChangeProfileItemValidator().validate(item);
  }

  constructor() {
    super('PROFILES', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getProfileById(id: string): Promise<ProfileItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<ProfileItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@PROFILES', 'profiles.new')
export class NewProfileItemStore extends FormStore<CreateProfileItemDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-profile';
  retrievePath = 'get-profiles';
  updatePath = 'update-profile';

  protected validate(item: CreateProfileItemDto) {
    return new CreateProfileItemValidator().validate(item);
  }

  constructor() {
    super('NEW_PROFILE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PROFILES', 'profiles.change')
export class ChangeProfileItemStore extends FormStore<ChangeProfileItemDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-profile';
  retrievePath = 'get-profiles';
  updatePath = 'update-profile';

  protected validate(item: ChangeProfileItemDto) {
    return new ChangeProfileItemValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PROFILE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
