import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import EvaluationsSearch from './evaluation-search';
import { EvaluationsDynamicTableStore, EvaluationsSearchFilters } from 'stores/evaluations/evaluations-store';
import { connect } from 'redux-scaffolding-ts';
import EvaluationDynamicTable from './evaluation-dynamic-table';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface EvaluationsHomePageProps extends WithTranslation, RouteComponentProps {
  evaluationsStore?: EvaluationsDynamicTableStore;
}

enum EvaluationSearchState {
  ModifyingSearch,
  SeeingResult
}
export interface EvaluationsHomePageState {
  state: EvaluationSearchState;
  filters: EvaluationsSearchFilters;
}

@connect(['evaluationsStore', EvaluationsDynamicTableStore])
class EvaluationsHomePage extends React.Component<EvaluationsHomePageProps, EvaluationsHomePageState> {
  state: EvaluationsHomePageState = { state: EvaluationSearchState.ModifyingSearch, filters: null };

  componentDidMount() {
    this.props.evaluationsStore.clear();
    this.setState({ state: EvaluationSearchState.ModifyingSearch });
  }

  onSearchCompleted = (filters?: EvaluationsSearchFilters) => {
    const changes: any = { state: EvaluationSearchState.SeeingResult };
    if (filters) changes.filters = filters;
    this.setState({ ...changes });
  };

  onModifySearch = () => {
    this.props.evaluationsStore.clear();
    this.setState({ state: EvaluationSearchState.ModifyingSearch });
  };

  render() {
    const { t } = this.props;
    const { state, filters } = this.state;
    return (
      <>
        <h3 className="evaluations__title">{t('Evaluations')}</h3>
        <LineSeparator />
        {state === EvaluationSearchState.ModifyingSearch && (
          <EvaluationsSearch prevSearch={filters} onSearchCompleted={this.onSearchCompleted} />
        )}
        {state === EvaluationSearchState.SeeingResult && <EvaluationDynamicTable onModifySearch={this.onModifySearch} />}
      </>
    );
  }
}

export default withTranslation()(EvaluationsHomePage);
