import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import IwsStepsComponent from '../../../wizards/iws-wizard-step';
import { Input, TextArea } from 'semantic-ui-react';
import './iws-wizard-first-step.less';
import { IwsTemplateData } from '../iws-templates-wizard-component';
import { SkillType } from 'widgets/bussiness/skills-editor';
import { ItemReference } from 'stores/dataStore';
import SkillsSelector from 'widgets/bussiness/selectors/skills-selector';

export interface IwsFirstStepProps extends WithTranslation {
  data: IwsTemplateData;
  onChangeProperty: (property: string, value: any) => void;
  onChangeSkill: (skill: ItemReference) => void;
  isEditMode: boolean;
}

class IwsFirstStepContent extends React.Component<IwsFirstStepProps> {
  shouldComponentUpdate(nextProps, nextState) {
    const { skillSection, title, header } = this.props.data;
    if (
      skillSection?.skillId !== nextProps.data?.skillSection?.skillId ||
      title !== nextProps.data?.title ||
      header !== nextProps.data?.header ||
      this.props.isEditMode !== nextProps.isEditMode
    )
      return true;
    else return false;
  }

  render() {
    const { t, data, isEditMode } = this.props;
    var skillValue =
      data && data.skillSection && data.skillSection.skillId ? { id: data.skillSection.skillId, title: data.skillSection.skillName } : null;

    return (
      <div className="iws-wizard__step wizard__step-one">
        <IwsStepsComponent isStepActive stepNumber={'1'} stepTitle={'IDENTIFY THE NEW TEMPLATE'}></IwsStepsComponent>
        <div className="step-one__content flex-start-center">
          <label>{t('Skill of the template')}</label>
          <div className="input profile-editor">
            <SkillsSelector
              readOnly={isEditMode}
              nullable
              clearable
              onChange={skill => this.props.onChangeSkill(skill)}
              value={skillValue?.id || null}
              assessment={SkillType.IWS}
              className="planit-user-dropdown-custom"
              placeholder={t('Select Skill')}
            />
          </div>
        </div>

        <div className="step-one__content flex-start">
          <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
          <Input
            maxLength="100"
            value={data && data.title ? data.title : ''}
            onChange={(_, { value }) => this.props.onChangeProperty('title', value)}
          />
          <label>{t('Header (Optional)')}</label>
          <TextArea
            maxLength="500"
            value={!data ? '' : data.header ? data.header : ''}
            onChange={(_, { value }) => this.props.onChangeProperty('header', value)}
            className="iws-wizard__textarea"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(IwsFirstStepContent);
