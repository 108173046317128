import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Message, Dimmer, Loader, Modal, Button, Container } from 'semantic-ui-react';
import {
  IwsFormListStore,
  IwsFormItemDto,
  IwsFormChangeAnswerStore,
  IwsFormAnswersDto,
  ChangeIwsFormAnswerDto,
  ChangeQuestionAnswerDto,
  IwsQuestionAnswerDto,
  QualifierDto,
  IwsFormStatus,
  ChangeTemplateSectionAnswersDto,
  TemplateSectionAnswersDto,
  ChangeIwsSkillAnswerDto,
  IwsSkillAnswerDto
} from 'stores/assessments/forms/iws-forms-store';
import { AssessorAnswerStatus } from 'stores/assessments/forms//tna-forms-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { RouteComponentProps } from 'react-router';
import { connect } from 'redux-scaffolding-ts';
import IWSFormComponent from './iws-form.component';
import IwsFormFooterComponent from './iws-form-footer-component';
import IwsFormHeaderComponent from './iws-form-header-component';
import { goToPage } from 'utils/useful-functions';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import './iws-edit-form-general.less';
import NotAvailable from 'site/not-available-component';

interface ChangeIwsFormViewProps extends WithTranslation, RouteComponentProps {
  onClose: (isSuccess: boolean) => void;
  onChangeLanguage?: (language: string) => void;
  changeIwsAnswerForm?: IwsFormChangeAnswerStore;
  iwsFormListStore?: IwsFormListStore;
}

interface ChangeIwsFormViewState {
  seletedTemplateId: string;
  loading: boolean;
  showStatusBackModal: boolean;
  showStatusBackModalText: string;
  statusSelected: string;
  showQualifiersSelector: boolean;
  qualifiersSelectedToBackStatus: string[];
}

export interface AssessorType {
  userId: string;
  type: 'qualifier' | 'nonAssessor';
  qualifierDto?: QualifierDto;
}

export type SkillRemainingModel = {
  skillId: string;
  skillName: string;
  remaining: string;
};

export type TemplateRemainingModel = {
  templateId: string;
  templateName: string;
  totalTemp: string;
  skills: SkillRemainingModel[];
};
export interface RemainingQuestionsModel {
  totalForm: string;
  templates: TemplateRemainingModel[];
}

const nonAssessorStatus: AssessorAnswerStatus = { answerStatus: 'Unknown', userId: '' };

export const IWSFormContext = React.createContext({
  submittable: (): boolean => false,
  modStatus: (): boolean => false,
  answerHandler: (...args) => {},
  onSave: () => {},
  onChange: (property: keyof ChangeIwsFormAnswerDto, value: any) => {},
  onSend: () => {},
  onSendStatusBack: (status: string) => {},
  getAssessorType: (): AssessorType => null,
  getAStatus: (): AssessorAnswerStatus => nonAssessorStatus,
  recommendationHandler: (...args) => {},
  copyAnswersFromMachinesWithSameTemplate: (machineModelIdSource: string, machineModelIdDest: string) => {},
  isAdminOrPoC: false,
  isAssessor: false,
  isEmployee: false,
  isAdmin: false,
  userId: ''
});

@connect(['iwsFormListStore', IwsFormListStore], ['changeIwsAnswerForm', IwsFormChangeAnswerStore])
class ChangeIwsFormView extends React.Component<ChangeIwsFormViewProps, ChangeIwsFormViewState> {
  state: ChangeIwsFormViewState = {
    seletedTemplateId: null,
    loading: false,
    showStatusBackModal: false,
    showStatusBackModalText: '',
    statusSelected: '',
    showQualifiersSelector: false,
    qualifiersSelectedToBackStatus: []
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private get iwsFormStore() {
    return this.props.iwsFormListStore;
  }

  private get changeIwsFormAnswerStore() {
    return this.props.changeIwsAnswerForm;
  }

  componentDidMount() {
    document.querySelector('.planit-main-container').classList.add('employee-background');
    this.load();
  }

  private isAssessor = (): boolean => {
    const { userId } = this.identityService.getUserInfo();
    const { qualifiers } = this.iwsFormStore.state.item as IwsFormItemDto;

    const isQualifier = (qualifiers || []).any(({ userId: qualifierUserId }) => userId === qualifierUserId);

    return isQualifier;
  };

  private getAssessorType = (): AssessorType => {
    const { userId } = this.identityService.getUserInfo();
    const { qualifiers } = this.iwsFormStore.state.item as IwsFormItemDto;

    if ((qualifiers || []).any(({ userId: qualifierUserId }) => userId === qualifierUserId)) {
      const qualifierDto = qualifiers.find(({ userId: id }) => id === userId);

      if (qualifierDto != null) return { userId, type: 'qualifier', qualifierDto: qualifierDto };

      return { userId, type: 'qualifier' };
    }

    return { userId, type: 'nonAssessor' };
  };

  private isInRoleEmployee = (): boolean => {
    const { userId } = this.identityService.getUserInfo();
    const { userId: iwsUser } = this.iwsFormStore.state.item as IwsFormItemDto;
    return iwsUser === userId && this.identityService.isInRole(Roles.Employee);
  };

  private isInRoleAdmin = (): boolean => IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo());

  private isInRoleAdminOrPoC = (): boolean => this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC);

  private getUserId = (): string => {
    const { userId } = this.identityService.getUserInfo();
    return userId;
  };

  onExportExcel = () => {
    const { iwsFormListStore } = this.props;
    const { state } = iwsFormListStore;

    this.setState({ loading: true });

    state &&
      state.item &&
      this.props.changeIwsAnswerForm
        .exportToExcel(state.item.id)

        .then(res => {
          ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
          this.setState({ loading: false });
        })
        .catch(error => {
          console.error(error);
          ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
          this.setState({ loading: false });
        });
  };

  private getIwsFormAnswers = async (id: string) => {
    try {
      const currentData = await this.changeIwsFormAnswerStore.getIwsFormAnswersByFormId(id);
      return currentData;
    } catch (error) {
      goToPage('./NotFound');
      console.error(error);
    }
  };

  private getIwsForm = async (id: string) => {
    try {
      const iwsForm = await this.iwsFormStore.getFormById(id);
      return iwsForm;
    } catch (error) {
      goToPage('./NotFound');
      console.error(error);
    }
  };

  load = async () => {
    const id = '' + this.props.match.params['id'];
    const iwsForm = await this.getIwsForm(id);
    let answers = await this.getIwsFormAnswers(id);

    this.iwsFormStore.changeAnswers(answers);
    const item = this.mapToNewRawItem(iwsForm, answers);
    this.changeIwsFormAnswerStore.change({ ...item });
  };

  getCurrentUserEdit = (assessorType: AssessorType, iwsFormStatus: IwsFormStatus): string => {
    let currentUserEdit = null;
    if (assessorType.type === 'qualifier') {
      currentUserEdit = 'qualifierAnswer';
    } else {
      if (iwsFormStatus.toString() === 'FirstAssesment') currentUserEdit = 'employeeFirstAssessmentAnswer';
      else currentUserEdit = 'employeeSecondAssessmentAnswer';
    }

    return currentUserEdit;
  };

  mapQuestionAnswers = (questionItem: IwsQuestionAnswerDto, iwsFormStatus: IwsFormStatus): ChangeQuestionAnswerDto => {
    const assessorType = this.getAssessorType();
    let currentUserEdit = this.getCurrentUserEdit(assessorType, iwsFormStatus);
    let item = null;

    item = questionItem[currentUserEdit];

    return { answer: item?.answer ?? null, comment: item?.comment || '', questionId: questionItem?.questionId };
  };

  mapToIwsSkillAnswer = (iwsSkillAnswer: IwsSkillAnswerDto, iwsFormStatus: IwsFormStatus): ChangeIwsSkillAnswerDto => {
    const item = {
      skillId: iwsSkillAnswer?.skillId,
      templateId: iwsSkillAnswer?.templateId,
      qualifierRecomendation: iwsSkillAnswer.qualifierRecomendation,
      questionAnswers: (iwsSkillAnswer.questionAnswers || []).map(question => this.mapQuestionAnswers(question, iwsFormStatus))
    };

    return item;
  };

  mapTemplateSections = (templateSections: TemplateSectionAnswersDto[], iwsFormStatus: IwsFormStatus): ChangeTemplateSectionAnswersDto[] =>
    (templateSections || []).map(
      ({ templateId, iwsSkillAnswers, employeeFirstAssessmentComment, employeeSecondAssessmentComment, qualifierComment }) => ({
        templateId,
        iwsSkillAnswers: iwsSkillAnswers.map(skill => this.mapToIwsSkillAnswer(skill, iwsFormStatus)),
        employeeFirstAssessmentComment,
        employeeSecondAssessmentComment,
        qualifierComment
      })
    );

  mapToNewRawItem = (iwsForm: IwsFormItemDto, answers: IwsFormAnswersDto) => {
    const status = this.iwsFormStore.state.item.status as string;

    const { templateSectionAnswers: templatesSections, ...rest } = answers;

    const assessorType = this.getAssessorType();
    const isEmployee = this.isInRoleEmployee();
    const { type } = assessorType;

    if (type === 'nonAssessor' && !isEmployee && status !== 'Done') return;

    const templateSectionAnswers = this.mapTemplateSections(templatesSections, iwsForm.status);

    const newItem: ChangeIwsFormAnswerDto = {
      ...rest,
      id: iwsForm.id,
      templateSectionAnswers: templateSectionAnswers
    };
    return newItem;
  };

  getRemainingItems = (assessorInfo: AssessorType, isAdminOrPoC: boolean = false) => {
    if (!assessorInfo || isAdminOrPoC) return;
    if (!this.props.iwsFormListStore.state.item.answers) return;
    const form: IwsFormItemDto = this.props.iwsFormListStore.state.item as IwsFormItemDto;
    const answers: IwsFormAnswersDto = form.answers;
    const model: RemainingQuestionsModel = { templates: null, totalForm: '' };
    let roleUserTarget = this.getCurrentUserEdit(assessorInfo, form.status);

    let totalForm = 0;
    let countForm = 0;
    model.templates = [];
    (answers.templateSectionAnswers || []).forEach(({ templateId, templateName, iwsSkillAnswers }) => {
      if (
        assessorInfo.type === 'qualifier' &&
        !form.qualifiers.any(x => x.userId === assessorInfo.userId && x.templates.any(temp => temp.templateId === templateId))
      )
        return;

      let totalTemp = 0;
      let countTemp = 0;

      const skillRemaining = (iwsSkillAnswers || []).map(({ questionAnswers, skillName, skillId }) => {
        const totalQuestions = (questionAnswers || []).length;
        const countQuestions = (questionAnswers || []).filter(x => x[roleUserTarget]?.answer != null)?.length;
        totalTemp += totalQuestions;
        countTemp += countQuestions;
        return { skillName: skillName, skillId: skillId, remaining: `${countQuestions}/${totalQuestions}` };
      });

      totalForm += totalTemp;
      countForm += countTemp;

      model.templates.push({
        templateId: templateId,
        templateName: templateName,
        skills: skillRemaining,
        totalTemp: `${countTemp}/${totalTemp}`
      });
    });
    model.totalForm = `${totalForm}/${countForm}`;

    return model;
  };

  getAStatus = (): AssessorAnswerStatus =>
    (this.props.iwsFormListStore.state.item?.assessorAnswerStatus || []).find((x: any) => x.userId === this.identityService.userId) ||
    nonAssessorStatus;

  onDismiss = () => {
    this.props.changeIwsAnswerForm.clearMessages();
    this.props.iwsFormListStore.clearMessages();
  };

  private onChange = (property: keyof ChangeIwsFormAnswerDto, value: any) => {
    const change: Partial<ChangeIwsFormAnswerDto> = {};
    change[property as string] = value;
    this.changeIwsFormAnswerStore.change(change);
  };

  onAnswerChangeStore = (change: number | string, questionId: string, skillId: string, templateId: string, comment?: boolean) => {
    const newChange = this.changeIwsFormAnswerStore.state.item;
    const { templateSectionAnswers } = newChange;
    let currentEdit = null;
    if (!comment) {
      currentEdit = 'answer';
      change = change === 5 ? -1 : change;
    } else currentEdit = 'comment';

    const templateSection = templateSectionAnswers.find(temp => temp.templateId === templateId);
    const skill = templateSection.iwsSkillAnswers.find(skill => skill.skillId === skillId);
    const changeQuestion = skill.questionAnswers.find(question => question.questionId === questionId);

    changeQuestion[currentEdit] = change;

    this.onChange('templateSectionAnswers', templateSectionAnswers);
  };

  submittable = () => {
    if (!this.changeIwsFormAnswerStore.state.item) return;
    const assessorType = this.getAssessorType();
    const { templateSectionAnswers } = this.changeIwsFormAnswerStore.state.item;

    const form = this.iwsFormStore.state.item as IwsFormItemDto;
    let isValid = false;

    if (assessorType.type === 'qualifier') {
      const neededTemplates = form.qualifiers.find(x => x.userId === assessorType.userId)?.templates?.map(x => x.templateId);
      isValid =
        neededTemplates &&
        templateSectionAnswers &&
        templateSectionAnswers
          .filter(x => neededTemplates.includes(x.templateId))
          .all(({ iwsSkillAnswers }) => iwsSkillAnswers.all(({ questionAnswers }) => questionAnswers.all(({ answer }) => answer != null)));
    } else {
      isValid =
        templateSectionAnswers &&
        templateSectionAnswers.all(({ iwsSkillAnswers }) =>
          iwsSkillAnswers.all(({ questionAnswers }) => questionAnswers.all(({ answer }) => answer != null))
        );
    }

    return isValid;
  };

  answerHandler = (change: number | string, questionId: string, skillId: string, templateId: string = null, comment: boolean = false) => {
    const answers: IwsFormAnswersDto = this.iwsFormStore.state.item.answers;
    const form = this.iwsFormStore.state.item as IwsFormItemDto;
    const currentAssessorType = this.getAssessorType();
    let currentUserEdit = this.getCurrentUserEdit(currentAssessorType, form.status);

    const currentTempSection = answers.templateSectionAnswers.find(temp => temp.templateId === templateId);
    const currentSkill = currentTempSection.iwsSkillAnswers.find(skill => skill.skillId === skillId);
    const currentQuestion = currentSkill.questionAnswers.find(question => question.questionId === questionId);

    if (!comment) currentQuestion[currentUserEdit] = { ...currentQuestion[currentUserEdit], answer: change };
    else currentQuestion[currentUserEdit] = { ...currentQuestion[currentUserEdit], comment: change };

    this.iwsFormStore.changeAnswers(answers);

    this.onAnswerChangeStore(change, questionId, skillId, templateId, comment);
  };

  modStatus = (): boolean => {
    const item: IwsFormItemDto = this.iwsFormStore.state.item;
    if (!item) return;
    const employee = this.isInRoleEmployee();
    const assessor = this.isAssessor();
    if (item.status.toString() !== 'Done') {
      if (employee && item.status.toString() === 'FirstAssesment') return true;
      if (employee && item.status.toString() === 'SecondAssesment') return true;
      if (assessor && item.status.toString() === 'Qualifier') return true;
    } else return;
  };

  updateQualifierStatus = () => {
    const { userId } = this.identityService.getUserInfo();
    const item = { ...this.iwsFormStore.state.item } as IwsFormItemDto;
    const itemChange = item.assessorAnswerStatus.find(assessorStatus => assessorStatus.userId === userId);
    if (itemChange && itemChange.answerStatus === 'Pending') this.load();
    else window.close();
  };

  private onSend = async () => {
    const { t } = this.props;
    const assessorType = this.getAssessorType();
    const isQualifier = assessorType.type === 'qualifier';
    const status = this.iwsFormStore.state.item.status;
    try {
      const sendIwsResult = await this.changeIwsFormAnswerStore.SendIwsForm(status, assessorType.qualifierDto);
      if (sendIwsResult?.isSuccess) {
        ToastComponent({ type: 'success-toast', text: t('Form successfully submitted!') });
        if (!isQualifier) window.close();
        else this.updateQualifierStatus();
      }
    } catch {
      console.error('There was a problem submitting IWS SuC Answers');
    }
  };

  private onSendStatusBack = async (status: string) => {
    if (status === 'assesment') {
      this.setState({
        showStatusBackModalText: 'Are you sure to send this form back to 2nd Assesment Status?',
        showStatusBackModal: true,
        statusSelected: status
      });
    } else if (status === 'qualifier') {
      this.setState({
        showStatusBackModalText: 'Are you sure to send this form back to Qualifier Status?',
        showStatusBackModal: true,
        statusSelected: status
      });
    }
  };

  private onSave = async () => {
    const { t } = this.props;
    try {
      const result = await this.changeIwsFormAnswerStore.update();
      if (result?.isSuccess) ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
    } catch (e) {
      console.dir(e);
      console.error(e, 'There was a problem saving IWS SuC Answers');
    }
  };

  recommendationHandler = (value: string, skillId: string, templateId: string) => {
    const { answers }: { answers: IwsFormAnswersDto } = this.iwsFormStore.state.item;
    this.onStoredFERecommendationChangeHandler(value, skillId, templateId);
    let { templateSectionAnswers } = answers;

    templateSectionAnswers = templateSectionAnswers.map(tempAnswer => {
      let skillAnswers = templateId
        ? tempAnswer.iwsSkillAnswers.find(x => x.skillId === skillId && x.templateId === templateId)
        : tempAnswer.iwsSkillAnswers.find(x => x.skillId === skillId);
      if (skillAnswers != null) skillAnswers.qualifierRecomendation = value;

      return tempAnswer;
    });

    this.iwsFormStore.changeAnswers({ ...answers, templateSectionAnswers });
  };

  onStoredFERecommendationChangeHandler = (value: string, _skillId: string, templateId: string) => {
    const { item } = this.changeIwsFormAnswerStore.state;
    let { templateSectionAnswers: storeTempAnswers } = item;

    storeTempAnswers = storeTempAnswers.map(storeTemp => {
      let skillAnswers = templateId
        ? storeTemp.iwsSkillAnswers.find(x => x.skillId === _skillId && x.templateId === templateId)
        : storeTemp.iwsSkillAnswers.find(x => x.skillId === _skillId);
      if (skillAnswers != null) skillAnswers.qualifierRecomendation = value;

      return storeTemp;
    });

    this.changeIwsFormAnswerStore.change({ ...item, templateSectionAnswers: storeTempAnswers });
  };

  setSeletedTemplateId = (templateId: string) => {
    this.setState({ seletedTemplateId: templateId });
  };

  private hideConfirmModal = () => {
    this.setState({ showStatusBackModal: false, showStatusBackModalText: '', statusSelected: '' });
  };

  private hideQualifiersModal = () => {
    this.setState({ showQualifiersSelector: false });
  };

  changeIwsFormStatus = async (sendQualifier: boolean = false) => {
    try {
      this.setState({ loading: true });
      const { t } = this.props;
      const form = this.iwsFormStore?.state?.item as IwsFormItemDto;
      if (form) {
        if (this.state.statusSelected === 'assesment') {
          const qualifiers = form?.qualifiers?.map(x => x.userId) || [];
          const result = await this.props.changeIwsAnswerForm.sendBackStatus(this.state.statusSelected, form.id, qualifiers);
          if (result) {
            this.hideConfirmModal();
            ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
            window.location.reload();
          }
        } else if (this.state.statusSelected === 'qualifier') {
          if (sendQualifier) {
            const result = await this.props.changeIwsAnswerForm.sendBackStatus(
              this.state.statusSelected,
              form.id,
              this.state.qualifiersSelectedToBackStatus
            );
            if (result) {
              this.hideQualifiersModal();
              this.hideConfirmModal();
              ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
              window.location.reload();
            }
          } else {
            this.setState({ showQualifiersSelector: true, qualifiersSelectedToBackStatus: [] });
          }
        }
      }
    } catch (e) {
      console.dir(e);
      console.error(e, 'There was a problem changing status');
    }
    this.setState({ loading: false });
  };

  handleAssesorToSendBackToQualifier(qualifier: QualifierDto, checked: boolean) {
    let qualifiers = this.state.qualifiersSelectedToBackStatus;
    if (checked) {
      qualifiers.push(qualifier.userId);
    } else {
      qualifiers = qualifiers.filter(x => x !== qualifier.userId);
    }
    this.setState({ qualifiersSelectedToBackStatus: qualifiers });
  }

  handleScroll = e => {
    const myDiv = document.getElementById('iwsLanguageSelector');
    const scroll = e.target.scrollTop;
    if (scroll >= 300) {
      myDiv.style.position = 'absolute';
      myDiv.style.top = '0px';
      myDiv.style.right = '0px';
      myDiv.style.transform = `translateY(60px)`;
    } else {
      myDiv.removeAttribute('style');
    }
  };

  public render() {
    const { loading, showStatusBackModal, showQualifiersSelector } = this.state;
    const {
      submittable,
      modStatus,
      onSave,
      onChange,
      onSend,
      onSendStatusBack,
      getAssessorType,
      getAStatus,
      answerHandler,
      recommendationHandler
    } = this;
    const { t, iwsFormListStore } = this.props;
    const isAdminOrPoC = this.isInRoleAdminOrPoC();
    const isAssessor = this.isAssessor();
    const isEmployee = this.isInRoleEmployee();
    const isAdmin = this.isInRoleAdmin();
    const userId = this.getUserId();
    const { isBusy: fetchingAnswers, item: changeStoreItem, result } = this.changeIwsFormAnswerStore.state;
    const { state } = iwsFormListStore;
    const assessorType = state.item?.answers && this.getAssessorType();
    const isEmployeeStatus = state.item.status === 'FirstAssesment' || state.item.status === 'SecondAssesment';
    const assessorReadOnlyMode = assessorType?.type === 'qualifier' && isEmployeeStatus;
    const remainingQuestions =
      !isAdminOrPoC && !(isEmployee && state.item.status === 'Qualifier') && !assessorReadOnlyMode && this.getRemainingItems(assessorType);
    let ctxValues: any = {
      submittable,
      modStatus,
      onSave,
      onChange,
      onSend,
      onSendStatusBack,
      getAssessorType,
      getAStatus,
      answerHandler
    };
    ctxValues = { ...ctxValues, recommendationHandler, isAdminOrPoC, isAssessor, isEmployee, isAdmin, userId };

    if (!isAdminOrPoC && !isEmployee && !isAssessor && !state.isBusy)
      return <NotAvailable textToShow={'This IWS SuC is no longer available'} />;
    return (
      <>
        <div>
          <h2 className="edit-form__title">{(state.item.iwsAssessment?.title || '').toUpperCase()}</h2>
        </div>

        {state.result && !state.result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={state.result.messages.map(m => m.body)} />
        )}
        <Dimmer active={loading || state.isBusy || fetchingAnswers} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {result && !result.isSuccess && (result?.messages || []).length > 0 && (
          <Message onDismiss={this.onDismiss} error list={result.messages.map(m => m.body)} />
        )}
        {state.item.user && (
          <Form reply>
            <div onScroll={this.handleScroll} className="form__all-wrapper tna-form">
              <IWSFormContext.Provider value={ctxValues}>
                <IwsFormHeaderComponent
                  remainingQuestions={remainingQuestions}
                  isNotEmployee={!isEmployee || (isEmployee && state.item.status === 'Qualifier')}
                  assessorType={this.getAssessorType()}
                  assessorAnswerStatus={this.getAStatus()}
                  isAdminOrPoC={isAdminOrPoC}
                  onAnswerChange={(answer, questionId, skillId, templateId, comment) =>
                    this.onAnswerChangeStore(answer, questionId, skillId, templateId, comment)
                  }
                />
                <main className="form__all-wrapper__main-content scroll">
                  {state.item?.answers && (
                    <IWSFormComponent
                      handleTemplateChange={this.setSeletedTemplateId}
                      onExportExcel={this.onExportExcel}
                      iwsFormListStore={iwsFormListStore}
                    />
                  )}
                </main>

                {changeStoreItem && <IwsFormFooterComponent seletedTemplateId={this.state.seletedTemplateId} />}
              </IWSFormContext.Provider>
            </div>
          </Form>
        )}
        <div>
          <Modal
            open={showStatusBackModal}
            size="mini"
            className="have-warnings-popup"
            closeOnEscape={true}
            onClose={this.hideConfirmModal}
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{this.state.showStatusBackModalText}</p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
              <Button
                className="have-warnings-popup__btn have-warnings-popup__pending-btn"
                content={t('Yes')}
                onClick={() => this.changeIwsFormStatus()}
              />
            </div>
          </Modal>
        </div>
        <div>
          <Modal open={showQualifiersSelector} closeOnEscape={true} onClose={this.hideQualifiersModal} closeOnDimmerClick={false}>
            <Dimmer active={this.state.loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <Loader indeterminate>{t('')}</Loader>
            </Dimmer>
            <Modal.Header className="modal__header">{t('Select qualifiers to reset qualifications')}</Modal.Header>
            <Modal.Content image>
              <Container>
                <Form>
                  {state.item?.qualifiers &&
                    state.item?.qualifiers.length !== 0 &&
                    state.item?.qualifiers.map((qualifier: QualifierDto, i: number) => (
                      <Form.Group key={i} widths="equal">
                        <Form.Checkbox
                          style={{ top: 25 }}
                          label={qualifier.user.firstName + ' ' + qualifier.user.lastName}
                          placeholder={qualifier.user.firstName}
                          onChange={(e, { checked }) => {
                            this.handleAssesorToSendBackToQualifier(qualifier, checked);
                          }}
                        />
                      </Form.Group>
                    ))}
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.hideQualifiersModal} basic>
                {t('Cancel')}
              </Button>
              <Button
                disabled={this.state?.qualifiersSelectedToBackStatus?.length === 0}
                onClick={() => this.changeIwsFormStatus(true)}
                positive
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </>
    );
  }
} // end class

export default withTranslation()(ChangeIwsFormView);
