import React, { Component } from 'react';
import LocationSelector from 'widgets/bussiness/selectors/location-selector';
import { Grid, Button, Dimmer, Loader, Message } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import {
  ChangeWorkingDaysStore,
  NewWorkingDaysStore,
  WorkingDaysStore,
  WorkingDayDto,
  CreateWorkingDaysDto
} from 'stores/working-days/working-days-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Query } from 'stores/dataStore';
import { DateTimeService } from 'services/datetime-service';
import moment from 'moment';
import { LocationDto } from 'stores/configuration/locations/locations-store';

export interface SchedulerWorkingDays {
  id: string;
  locationId: string;
  year: number;
  selectedDays: boolean[];
}

export interface SchedulerWorkingDaysProps extends WithTranslation {
  onClose?: (data?: CreateWorkingDaysDto) => void;
  workingDaysStore?: WorkingDaysStore;
  changeWorkingDaysStore?: ChangeWorkingDaysStore;
  newWorkingDaysStore?: NewWorkingDaysStore;
  location?: LocationDto;
}

interface SchedulerWorkingDaysState {
  schedulerWorkingDays: SchedulerWorkingDays;
}

@connect(
  ['workingDaysStore', WorkingDaysStore],
  ['changeWorkingDaysStore', ChangeWorkingDaysStore],
  ['newWorkingDaysStore', NewWorkingDaysStore]
)
class SchedulerWorkingDaysComponent extends Component<SchedulerWorkingDaysProps, SchedulerWorkingDaysState> {
  today = DateTimeService.now();

  weekArray = Array.apply(null, Array(7)).map((_, i) => {
    return moment(i, 'e')
      .startOf('week')
      .isoWeekday(i + 1)
      .format('ddd');
  });

  getDefaultDaysSelection = () => {
    return [false, false, false, false, false, false, false];
  };

  getDefaultWorkingDays = () => ({ id: null, locationId: null, year: this.today.year(), selectedDays: this.getDefaultDaysSelection() });

  state: SchedulerWorkingDaysState = { schedulerWorkingDays: this.getDefaultWorkingDays() };

  componentDidMount() {
    this.props.location && this.handleOnLocationChange(this.props.location.id);
  }

  handleOnLocationChange = (value: string) => {
    if (!value) {
      this.setState({ schedulerWorkingDays: this.getDefaultWorkingDays() });
      return;
    }

    const workingDays = { ...this.state.schedulerWorkingDays };
    workingDays.locationId = value;
    const filter = [{ LocationId: { eq: { type: 'guid', value: workingDays.locationId } } } /* , { Year: workingDays.year } */];

    const query: Query = { skip: 0, take: 1, filter, searchQuery: '' };

    this.props.workingDaysStore.getAllAsync(query).then(response => {
      const currentWorkingDaysConfiguration = { ...this.state.schedulerWorkingDays };
      const previousWorkingDaysConfiguration = response.items.length !== 0;

      currentWorkingDaysConfiguration.id = previousWorkingDaysConfiguration ? response.items[0].id : null;
      currentWorkingDaysConfiguration.selectedDays = previousWorkingDaysConfiguration
        ? this.toSelectedDays(response.items[0])
        : this.getDefaultDaysSelection();

      this.setState({ schedulerWorkingDays: currentWorkingDaysConfiguration });
    });

    this.setState({ schedulerWorkingDays: workingDays });
  };

  toSelectedDays(workingDaysDto: WorkingDayDto): boolean[] {
    return [
      workingDaysDto.monday,
      workingDaysDto.tuesday,
      workingDaysDto.wednesday,
      workingDaysDto.thursday,
      workingDaysDto.friday,
      workingDaysDto.saturday,
      workingDaysDto.sunday
    ];
  }

  setDaySelected = index => {
    const workingdays = { ...this.state.schedulerWorkingDays };
    workingdays.selectedDays[index] = !workingdays.selectedDays[index];

    this.setState({ schedulerWorkingDays: workingdays });
  };

  weekDays = () =>
    this.weekArray.map((day, index) => (
      <Button
        className={`poc-config-modal__item-day${this.state.schedulerWorkingDays.selectedDays[index] ? ' selected' : ''}`}
        circular
        content={day}
        onClick={() => this.setDaySelected(index)}
        disabled={!this.state.schedulerWorkingDays.locationId}
      />
    ));

  mapToCreatePublicationHolidayConfigurationDto(schedulerWorkingDays: SchedulerWorkingDays): CreateWorkingDaysDto {
    const { locationId, selectedDays, year } = schedulerWorkingDays;
    return {
      locationId,
      monday: selectedDays[0],
      tuesday: selectedDays[1],
      wednesday: selectedDays[2],
      thursday: selectedDays[3],
      friday: selectedDays[4],
      saturday: selectedDays[5],
      sunday: selectedDays[6],
      year
    };
  }

  handleOnSaveClicked = async () => {
    const { newWorkingDaysStore, onClose, changeWorkingDaysStore } = this.props;
    const { schedulerWorkingDays } = this.state;
    const commonChanges = this.mapToCreatePublicationHolidayConfigurationDto(schedulerWorkingDays);
    if (!schedulerWorkingDays.id) {
      newWorkingDaysStore.createNew(commonChanges);
      await newWorkingDaysStore.submit();
      onClose && onClose(commonChanges);
      return;
    }

    changeWorkingDaysStore.change({ id: schedulerWorkingDays.id, ...commonChanges });
    await changeWorkingDaysStore.update();
    onClose && onClose(commonChanges);
  };

  onDismiss = () => {
    this.props.newWorkingDaysStore.clearMessages();
    this.props.changeWorkingDaysStore.clearMessages();
    this.props.workingDaysStore.clearMessages();
  };

  render() {
    const { t, onClose, location, newWorkingDaysStore, changeWorkingDaysStore, workingDaysStore } = this.props;
    const { locationId } = this.state.schedulerWorkingDays;
    const isBusy = newWorkingDaysStore.state.isBusy || changeWorkingDaysStore.state.isBusy || workingDaysStore.state.isBusy;
    const messages = [
      ...(newWorkingDaysStore.state.result?.messages || []).map(x => x.body),
      ...(changeWorkingDaysStore.state.result?.messages || []).map(x => x.body),
      ...(workingDaysStore.state.result?.messages || []).map(x => x.body)
    ];
    const msgClass = 'error-message__style';
    const msgIcon = 'exclamation circle';
    return (
      <div className="poc-config-modal__container">
        <Dimmer page active={isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {messages.length > 0 && (
          <Message onDismiss={this.onDismiss} className={msgClass} icon={msgIcon} error header={t('An error ocurred')} list={messages} />
        )}
        <Grid.Row>
          {location?.location ? (
            <h2>
              <b>{location?.location}</b>
            </h2>
          ) : (
            <LocationSelector
              className="filter-form__item-input"
              nullable
              clearable
              filterByRole
              searchable
              placeholder={t('Select Location')}
              value={locationId}
              onChange={this.handleOnLocationChange}
            />
          )}
        </Grid.Row>
        <Grid.Row className="poc-config-modal__range-content" textAlign="left">
          {this.weekDays()}
        </Grid.Row>
        <Grid.Row className="poc-config-modal__action-buttons">
          <div className="left"></div>
          <div className="right">
            <Button basic className="cancel" content={t('Cancel')} onClick={() => onClose()} />
            <Button positive disabled={!locationId} className="save" content={t('Save')} onClick={this.handleOnSaveClicked} />
          </div>
        </Grid.Row>
      </div>
    );
  }
}

export default withTranslation()(SchedulerWorkingDaysComponent);
