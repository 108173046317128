import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { TestCategoryDto } from '../testCategory-store';

export interface SsaSkillDto extends BaseDto {
  id: string; //Guid
  friendlyId: string;
  name: string;
  description: string;
  testCategoryId: string;
  testCategory: TestCategoryDto;
}

export interface CreateSsaSkillDto {
  testCategoryId: string;
  description: string;
  name: string;
}

export interface ChangeSsaSkillDto {
  id: string;
  testCategoryId: string;
  description: string;
  name: string;
}

export class CreateSsaSkillValidator extends AbstractValidator<CreateSsaSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.testCategoryId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));
  }
}

export class ChangeSsaSkillValidator extends AbstractValidator<ChangeSsaSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Skill Id is required'));

    this.validateIfString(o => o.testCategoryId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));
  }
}

@repository('@@SSASKILLS', 'ssaskills.summary')
export class SsaSkillsStore extends DataStore<SsaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-skill';
  retrievePath = 'get-ssa-skills';
  updatePath = 'update-ssa-skill';
  deletePath = 'delete-ssa-skill';
  retrieveOnePath = 'get-ssa-skill';
  retrieveCountPath = 'get-used-ssa-skill-count';

  protected validate(item: SsaSkillDto) {
    return new ChangeSsaSkillValidator().validate(item);
  }

  constructor() {
    super('SSASKILL', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getSsaSkillById(id: string): Promise<SsaSkillDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<SsaSkillDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedSkillCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@SSASKILLS', 'ssaskills.new')
export class NewSsaSkillStore extends FormStore<CreateSsaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-skill';
  retrievePath = 'get-ssa-skills';
  updatePath = 'update-ssa-skill';

  protected validate(item: CreateSsaSkillDto) {
    return new CreateSsaSkillValidator().validate(item);
  }

  constructor() {
    super('NEW_SSASKILL', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@SSASKILLS', 'ssaskills.change')
export class ChangeSsaSkillStore extends FormStore<ChangeSsaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-skill';
  retrievePath = 'get-ssa-skills';
  updatePath = 'update-ssa-skill';

  protected validate(item: ChangeSsaSkillDto) {
    return new ChangeSsaSkillValidator().validate(item);
  }

  constructor() {
    super('CHANGE_SSASKILL', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
