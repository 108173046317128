import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, Button, Divider, List, Modal } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { TnaFormListStore, TnaFormItemDto, TnaFormAnswersDto, AssessorAnswerStatus } from 'stores/assessments/forms/tna-forms-store';
import './tna-edit-form-header-styles.less';
import { AssessorType, RemainingQuestionsModel } from './tna-edit-form';

interface TNAFormHeaderProps extends WithTranslation {
  isNotEmployee: boolean;
  tnaFormListStore?: TnaFormListStore;
  remainingQuestions: RemainingQuestionsModel;
  assessorType: AssessorType;
  assessorAnswerStatus: AssessorAnswerStatus;
  isAdminOrPoC: boolean;
  onAnswerChange: (
    change: number | string,
    questionId: string,
    skillId: string,
    machineUnitId?: string,
    machineModelId?: string,
    comment?: boolean
  ) => void;
}
interface FunctionalExpertObj {
  skillName: string;
  functionalExpertName: string;
}

interface TNAFormHeaderState {
  remainingModal: boolean;
  accordionOpen: boolean;
}
@connect(['tnaFormListStore', TnaFormListStore])
class TNAFormHeaderComponent extends Component<TNAFormHeaderProps, TNAFormHeaderState> {
  state: TNAFormHeaderState = { remainingModal: false, accordionOpen: false };

  private get assessorType() {
    return this.props.assessorType;
  }

  private get onAnswerChange() {
    return this.props.onAnswerChange;
  }

  getNMRFunctionalExperts = () => {
    const item: TnaFormItemDto = this.props.tnaFormListStore.state.item;
    const functionalExpertSkills: FunctionalExpertObj[][] = [];
    (item?.nonMachineFunctionalExperts || []).forEach(({ skills, user: { lastName, firstName } }) => {
      const functionalExpertName = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
      functionalExpertSkills.push((skills || []).map(({ name }) => ({ skillName: `Skill: ${name}`, functionalExpertName })));
    });

    return functionalExpertSkills;
  };

  getMRFunctionalExperts = () => {
    const item: TnaFormItemDto = this.props.tnaFormListStore.state.item;
    const t = this.props.t;

    const functionalExpertSkills: FunctionalExpertObj[][] = [];

    if (item.tnaAssessment.type === 'SameMachineHierarchy') {
      if ((item?.machineFunctionalExperts || []).length !== 0) {
        const machineFunctionalExpert = item.machineFunctionalExpert;
        return [
          [
            {
              skillName: t('MR Skills'),
              functionalExpertName: `${machineFunctionalExpert.firstName.toUpperCase()} ${machineFunctionalExpert.lastName.toUpperCase()}`
            }
          ]
        ];
      }
    }

    (item?.machineFunctionalExperts || []).forEach(({ machineModels, user: { lastName, firstName } }) => {
      const functionalExpertName = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
      functionalExpertSkills.push((machineModels || []).map(({ name }) => ({ skillName: `Machine: ${name}`, functionalExpertName })));
    });

    return functionalExpertSkills;
  };

  CopyScoresFromEmployeeToAssessor = () => {
    const item: TnaFormItemDto = this.props.tnaFormListStore.state.item;
    const answers: TnaFormAnswersDto = item.answers;

    if (this.assessorType.type === 'lineManager') {
      (answers.machineModelAnswers || []).forEach(({ machineUnitAnswers, machineModelId }) => {
        (machineUnitAnswers || []).forEach(({ skillAnswers, machineUnitId }) => {
          (skillAnswers || []).forEach(({ questionAnswers, skillId }) => {
            (questionAnswers || []).forEach(answerItem => {
              answerItem.lineManagerAnswer = { ...answerItem.lineManagerAnswer, answer: answerItem.employeeAnswer.answer };
              this.onAnswerChange(answerItem.employeeAnswer.answer, answerItem.questionId, skillId, machineUnitId, machineModelId, false);
            });
          });
        });
      });

      (answers.nonRelatedMachineSkillAnswers || []).forEach(({ questionAnswers, skillId }) => {
        (questionAnswers || []).forEach(answerItem => {
          answerItem.lineManagerAnswer = { ...answerItem.lineManagerAnswer, answer: answerItem.employeeAnswer.answer };
          this.onAnswerChange(answerItem.employeeAnswer.answer, answerItem.questionId, skillId, null, null, false);
        });
      });
    }

    if (this.assessorType.type === 'functionalExpert') {
      var nrmskillAnswers = answers.nonRelatedMachineSkillAnswers.filter(x =>
        (this.assessorType.nmrFunctionalExpertsSkills?.skills || []).any(skill => skill.id === x.skillId)
      );

      (nrmskillAnswers || []).forEach(({ questionAnswers, skillId }) => {
        (questionAnswers || []).forEach(answerItem => {
          answerItem.functionalExpertAnswer = { ...answerItem.functionalExpertAnswer, answer: answerItem.employeeAnswer.answer };
          this.onAnswerChange(answerItem.employeeAnswer.answer, answerItem.questionId, skillId, null, null, false);
        });
      });

      if (this.assessorType.mrSkills) {
        (answers.machineModelAnswers || []).forEach(({ machineUnitAnswers, machineModelId }) => {
          (machineUnitAnswers || []).forEach(({ skillAnswers, machineUnitId }) => {
            (skillAnswers || []).forEach(({ questionAnswers, skillId }) => {
              (questionAnswers || []).forEach(answerItem => {
                answerItem.functionalExpertAnswer = { ...answerItem.functionalExpertAnswer, answer: answerItem.employeeAnswer.answer };
                this.onAnswerChange(answerItem.employeeAnswer.answer, answerItem.questionId, skillId, machineUnitId, machineModelId, false);
              });
            });
          });
        });
      }
    }

    this.props.tnaFormListStore.changeAnswers(answers);
  };

  showRemainingModal = () => this.setState({ remainingModal: true });

  hideRemainingModal = () => this.setState({ remainingModal: false });

  toggleAccordion = () => this.setState(({ accordionOpen }) => ({ accordionOpen: !accordionOpen }));

  render() {
    const { remainingModal, accordionOpen } = this.state;
    const { t, isNotEmployee, tnaFormListStore, remainingQuestions } = this.props;
    const itemState: TnaFormItemDto = tnaFormListStore.state.item;
    const { user, lineManager, tnaAssessment } = itemState;
    const nmrFunctionalExpert = this.getNMRFunctionalExperts();
    const mrFunctionalExpert = this.getMRFunctionalExperts();

    const tnaStatus = itemState.status;
    const isDone = tnaStatus === 'Done';
    const assessorPending = ['Pending', 'PendingRecommendation'].includes(this.props.assessorAnswerStatus.answerStatus);
    const assessorEditable = !this.props.isAdminOrPoC && !isDone && assessorPending;

    return (
      <>
        <div className="header-accordion-spacer">
          <Accordion className="tna-header-accordion" panels={[]}>
            <Accordion.Title active={accordionOpen} onClick={this.toggleAccordion} className="tna-header-accordion__title" />
            <Accordion.Content active={accordionOpen}>
              <header className="form__all-wrapper__header">
                <div className="edit-form__header__data-language flex-between">
                  <div className="edit-form__header__general-info">
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Employee')}</h5>
                      <p>{`${(user?.firstName || '').toUpperCase()} ${(user?.lastName || '').toUpperCase()}`}</p>
                    </div>
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Bypass Employee Evaluation')}</h5>
                      <p>{itemState.bypassEmployeeEvaluation === true ? t('Yes') : t('No')}</p>
                    </div>
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Bypass Line Manager Evaluation')}</h5>
                      <p>{itemState.bypassLineManagerEvaluation === true ? t('Yes') : t('No')}</p>
                    </div>

                    {tnaAssessment?.tnaTemplate && (
                      <>
                        <div className="edit-form__header__general-info__element">
                          <h5>{t('Profile')}</h5>
                          <p>{tnaAssessment.tnaTemplate.profileName}</p>
                        </div>

                        {isNotEmployee && (
                          <>
                            <div className="edit-form__header__general-info__element">
                              <h5>{t('Line Manager')}</h5>
                              <p>{`${lineManager.firstName.toUpperCase()} ${lineManager.lastName.toUpperCase()}`}</p>
                            </div>

                            {(mrFunctionalExpert || []).map((expertArray, j) => (
                              <Fragment key={j + expertArray[0].skillName}>
                                {(expertArray || []).map(({ skillName, functionalExpertName }, i) => (
                                  <div key={skillName + functionalExpertName + i} className="edit-form__header__general-info__element">
                                    <h5>
                                      {t('Functional Expert')} <br />
                                      {t(skillName)}
                                    </h5>
                                    <p>{functionalExpertName}</p>
                                  </div>
                                ))}
                              </Fragment>
                            ))}

                            {(nmrFunctionalExpert || []).map((expertArray, j) => (
                              <Fragment key={j + expertArray[0].skillName}>
                                {(expertArray || []).map(({ skillName, functionalExpertName }, i) => (
                                  <div key={skillName + functionalExpertName + i} className="edit-form__header__general-info__element">
                                    <h5>
                                      {t('Functional Expert')} <br />
                                      {t(skillName)}
                                    </h5>
                                    <p>{functionalExpertName}</p>
                                  </div>
                                ))}
                              </Fragment>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="header-right">
                    {/* <LanguageEditor
                      className="planit-user-dropdown-custom"
                      placeholder={t('Select Language')}
                      clearable
                      nullable
                      value={tnaFormListStore.state.item.currentLanguage || null}
                      onChange={val => tnaFormListStore.changeCurrentLanguage(val?.id || null)}
                      onlyForTests
                    /> */}
                    {remainingQuestions && (
                      <Button className="btn__check-questions" onClick={this.showRemainingModal}>
                        {t('Check')}
                      </Button>
                    )}
                    {assessorEditable && isNotEmployee && itemState.bypassEmployeeEvaluation !== true && (
                      <Button className="btn__check-questions" onClick={this.CopyScoresFromEmployeeToAssessor}>
                        {t('Copy Scores from Employee')}
                      </Button>
                    )}
                  </div>
                </div>
              </header>
            </Accordion.Content>
          </Accordion>
        </div>
        <Divider />
        <Modal className="remaining-questions" size="small" open={remainingModal} closeOnEscape={true} onClose={this.hideRemainingModal}>
          <Modal.Header>
            <h1>{t('Check Questions')}</h1>
          </Modal.Header>
          <Modal.Content className="remaining-questions__modal-content">
            <List className="remaining-questions__all-list">
              {(remainingQuestions?.general?.skills || []).length > 0 && (
                <>
                  <List.Item>
                    <div className="remaining-questions__title">
                      <h2>{t('General Tab')}:</h2>
                      <span className="remaining-item__box">{remainingQuestions?.totalNMR || ''}</span>
                    </div>
                    <List bulleted ordered className="remaining-questions__general-list">
                      {(remainingQuestions?.general?.skills || []).map(({ name, remaining, id }) => (
                        <List.Item className="remaining-questions__remaining-item" key={id}>
                          <div className="remaining-questions__title">
                            <b>{name}:</b>
                            <span className="remaining-item__box">{remaining}</span>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  </List.Item>
                  <hr />
                </>
              )}
              {remainingQuestions?.machines != null && (
                <List.Item>
                  <div className="remaining-questions__title">
                    <h2>{t('Machines')}:</h2>
                    <span className="remaining-item__box">{remainingQuestions?.totalMR || ''}</span>
                  </div>
                  <List className="remaining-questions__machines-list">
                    {(remainingQuestions?.machines || []).map(({ machineModel, machineUnits, id: machineModelId, totalMM }) => (
                      <List.Item key={machineModelId}>
                        <div className="remaining-questions__title">
                          <h3 className="underlined">{machineModel}:</h3>
                          <span className="remaining-item__box">{totalMM}</span>
                        </div>
                        <List className="remaining-questions__mu-list">
                          {(machineUnits || []).map(({ machineUnit, id: machineUnitId, skills, totalMU }) => (
                            <Fragment key={machineUnitId + machineModelId}>
                              <List.Item>
                                <div className="remaining-questions__title">
                                  <h3>{machineUnit}: </h3>
                                  <span className="remaining-item__box">{totalMU || ''}</span>
                                </div>

                                <List ordered className="remaining-questions__skill-questions">
                                  {(skills || []).map(({ name, remaining, id }) => (
                                    <List.Item className="remaining-questions__remaining-item" key={id + machineUnitId + machineModelId}>
                                      <div className="remaining-questions__title">
                                        <b>{name}:</b>
                                        <span className="remaining-item__box">{remaining}</span>
                                      </div>
                                    </List.Item>
                                  ))}
                                </List>
                              </List.Item>
                              <br />
                            </Fragment>
                          ))}
                        </List>
                      </List.Item>
                    ))}
                  </List>
                </List.Item>
              )}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideRemainingModal}>OK</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(TNAFormHeaderComponent);
