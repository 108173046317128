import React, { Fragment, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SimpleTheoreticalFormAnswerDto } from 'stores/events/theoretical-form-store';

interface TheoreticalTableEmployeeProps extends WithTranslation {
  theoreticalFormAnswers: SimpleTheoreticalFormAnswerDto[];
  currentUserId: string;
}

const TheoreticalEmployeeTable: FC<TheoreticalTableEmployeeProps> = ({ t, theoreticalFormAnswers, currentUserId }) => {
  return (
    <div className="form__theoretical-table form__theoretical-table__employee-view">
      <p className="theoretical-table__name-column">{t('User Name')}</p>
      <p className="theoretical-table__name-column">{t('Last Name')}</p>
      <p className="theoretical-table__name-column">{t('First Name')}</p>
      <p className="theoretical-table__name-column">{t('Location')}</p>
      <p className="theoretical-table__name-column">{t('Average Time')}</p>
      <p className="theoretical-table__name-column">{t('Total Time')}</p>
      <p className="theoretical-table__name-column">{t('Result')}</p>
      <p className="theoretical-table__name-column">{t('Number of Questions')}</p>

      {(theoreticalFormAnswers || [])
        .filter(({ userId }) => userId === currentUserId)
        .map(form => {
          if (!form) return null;
          const { user, id, userId, averageTime, result, totalQuestions, totalTime, questionsAnswered } = form;

          return (
            <Fragment key={`theoretical-table__${id}-${userId}`}>
              <p className="theoretical-table__cell">{user.userName}</p>
              <p className="theoretical-table__cell">{user.lastName}</p>
              <p className="theoretical-table__cell">{user.firstName}</p>
              <p className="theoretical-table__cell">{user.location ? user.location.location : '-'}</p>
              <p className="theoretical-table__cell">{averageTime ? averageTime : '-'}</p>
              <p className="theoretical-table__cell">{totalTime ? totalTime : '-'}</p>
              <p className="theoretical-table__cell">{result ? `${result} %` : '-'}</p>
              <p className="theoretical-table__cell">
                {questionsAnswered == null || totalQuestions == null ? '-' : `${questionsAnswered} / ${totalQuestions} `}
              </p>
            </Fragment>
          );
        })}
    </div>
  );
};

export default withTranslation()(TheoreticalEmployeeTable);
