import i18n from 'i18n';
import * as React from 'react';
import { Button, Divider, DropdownItemProps, Form, Header, Icon, Popup, Segment, Select } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface IOptionsFilterProps {
  triggerTitle: string;
  filterTitle: string;
  options: DropdownItemProps[];
  onFilter: (value: string) => void;
  onClear: () => void;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  position?: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center';
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[];
  active?: boolean;
  onActivate?: (visible: boolean) => void;
}

interface IOptionsFilterState {
  visible: boolean;
  filtered: boolean;
  value: string;
}

export class OptionsFilter extends React.Component<IOptionsFilterProps, IOptionsFilterState> {
  constructor(props: IOptionsFilterProps) {
    super(props);
    this.state = {
      visible: false,
      filtered: this.props.active,
      value: ''
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.active !== this.state.filtered) {
      if (next.active) {
        this.setState({ filtered: true });
      } else {
        this.setState({ filtered: false, visible: false, value: '' });
        this.props.onActivate && this.props.onActivate(false);
      }
    }
  }

  private onChange = (e, { value }) => {
    this.setState({ value });
  };

  private onFilter = e => {
    e.preventDefault();
    if (this.props.onFilter) {
      this.props.onFilter(this.state.value);
    }
    this.setState({ filtered: true, visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClear = () => {
    if (this.props.onClear) {
      this.props.onClear();
    }
    this.setState({ visible: false, filtered: false, value: '' });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClose = () => {
    this.setState({ visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onOpen = e => {
    e.preventDefault();
    this.setState({ visible: true });
    this.props.onActivate && this.props.onActivate(true);
  };

  render() {
    const { filterTitle, triggerTitle, size, position, on, options } = this.props;
    const { visible, filtered, value } = this.state;
    return (
      <Popup
        open={visible}
        trigger={
          <a
            href="!#"
            onClick={this.onOpen}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
          >
            {triggerTitle}&nbsp;
            <Icon name="filter" size="small" disabled={!filtered} />
          </a>
        }
        content={
          <Segment size="mini" basic className="options-filter">
            <Header as="h4">{filterTitle}</Header>
            <Divider />
            <Form size="tiny">
              <Form.Group>
                <Form.Field width={13} className="select-field">
                  <Select
                    onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                    fluid
                    size="tiny"
                    onChange={this.onChange}
                    value={value}
                    options={options}
                    placeholder={i18n.t('Select...')}
                    selectOnNavigation={false}
                    selectOnBlur={false}
                  />
                </Form.Field>
                <Form.Field width={3} className="filter-button-field">
                  <Button
                    size="small"
                    icon="filter"
                    className="no-rounded"
                    compact
                    onClick={this.onFilter}
                    disabled={isNullOrWhiteSpaces(value)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider />
            <Button size="tiny" compact onClick={this.onClear}>
              <Icon name="times" /> {i18n.t('Clear')}
            </Button>
          </Segment>
        }
        on={on || 'click'}
        position={position || 'bottom left'}
        size={size || 'mini'}
        onClose={this.onClose}
      />
    );
  }
}
