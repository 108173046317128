import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { ItemReference, Query } from 'stores/dataStore';
import { SsaDevelopmentPlanGroupingStore, SsaDevelopmentPlanGroupingDto } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import { ProfileItemDto } from 'stores/profile/profile-store';
import i18n from 'i18n';
import YearEditor from 'widgets/bussiness/year-editor';
import LocationEditor from 'widgets/bussiness/location-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { nameof } from 'utils/object';
import './location-tab-pane.less';
import SsaActivityTypeEditor from 'widgets/bussiness/ssa-activity-type-editor';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';

interface LocationTabPaneFilters {
  skill: ItemReference;
  profile: ProfileItemDto;
  activity: ItemReference;
  location: ItemReference;
  year?: number;
}

interface LocationTabPaneState {
  query: Query;
  filters: LocationTabPaneFilters;
}

export interface LocationTabPaneProps extends WithTranslation {
  ssaDevelopmentPlanGroupingStore?: SsaDevelopmentPlanGroupingStore;
}

@connect(['ssaDevelopmentPlanGroupingStore', SsaDevelopmentPlanGroupingStore])
class LocationTabPane extends Component<LocationTabPaneProps, LocationTabPaneState> {
  state: LocationTabPaneState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    filters: {
      skill: null,
      profile: null,
      activity: null,
      location: null,
      year: null
    }
  };

  componentDidMount() {
    this.load();
  }

  private columns: TableColumn<SsaDevelopmentPlanGroupingDto>[] = [
    {
      title: i18n.t('Location'),
      tooltipRenderer: true,
      renderer: data => data.locationName
    },
    {
      title: i18n.t('Profile'),
      tooltipRenderer: true,
      renderer: data => data.profileName
    },
    {
      title: i18n.t('Skill Category'),
      tooltipRenderer: true,
      renderer: data => data.skillCategoryName
    },
    {
      title: i18n.t('Skill'),
      tooltipRenderer: true,
      renderer: data => data.skillName
    },
    {
      title: i18n.t('Activity'),
      tooltipRenderer: true,
      renderer: data => data.ssaActivityName
    },
    {
      title: i18n.t('Year'),
      tooltipRenderer: true,
      renderer: data => data.deadlineYear
    },
    {
      title: i18n.t('Planned'),
      tooltipRenderer: true,
      renderer: data => data.plannedCount
    },
    {
      title: i18n.t('In Progress'),
      tooltipRenderer: true,
      renderer: data => data.inProgressCount
    },
    {
      title: i18n.t('Completed'),
      tooltipRenderer: true,
      renderer: data => data.completedCount
    },
    {
      title: i18n.t('Out of Date'),
      tooltipRenderer: true,
      renderer: data => data.outOfDateCount
    }
  ];

  private handleFilterChange(property: string, value: any) {
    const filters = { ...this.state.filters };
    filters[property] = value;

    this.setState({ filters: filters }, this.load);
  }

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.ssaDevelopmentPlanGroupingStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: LocationTabPaneFilters): Query => {
    const queryParameters = {};
    const filter = [];

    if (filters.activity && filters.activity.id) filter.push({ SsaActivityId: { eq: { type: 'guid', value: filters.activity.id } } });
    if (filters.profile && filters.profile.id) filter.push({ ProfileItemId: { eq: { type: 'guid', value: filters.profile.id } } });
    if (filters.skill && filters.skill.id) filter.push({ SkillId: { eq: { type: 'guid', value: filters.skill.id } } });
    if (!!filters.year) filter.push({ DeadlineYear: { eq: filters.year } });
    if (filters.location && filters.location.id) queryParameters['LocationId'] = filters.location.id;

    return { ...this.state.query, filter: filter, parameters: queryParameters };
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  render() {
    const { t, ssaDevelopmentPlanGroupingStore: store } = this.props;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.ssaDevelopmentPlanGroupingStore.state
    } as TableModel<SsaDevelopmentPlanGroupingDto>;

    return (
      <div className="development-plan-by-location__wrapper">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body)}
            onDismiss={() => this.props.ssaDevelopmentPlanGroupingStore.clearMessages()}
          />
        )}

        <div className="development-plan-by-location__filters">
          <SkillsEditor
            skillType={SkillType.SSA}
            clearable
            placeholder={t('SSA Skill')}
            value={this.state.filters.skill}
            nullable
            className="custom-editor"
            onChange={value => this.handleFilterChange(nameof<LocationTabPaneFilters>('skill'), value)}
          />

          <ProfileEditor
            clearable
            placeholder={t('SSA Profile')}
            value={this.state.filters.profile && this.state.filters.profile.id}
            nullable
            className="custom-editor"
            assessment="SSA"
            onChange={value => this.handleFilterChange(nameof<LocationTabPaneFilters>('profile'), value)}
          />

          <SsaActivityTypeEditor
            clearable
            className="custom-editor"
            placeholder={t('Activity')}
            nullable
            value={null}
            onChange={value => this.handleFilterChange(nameof<LocationTabPaneFilters>('activity'), value)}
          />

          <LocationEditor
            clearable
            className="custom-editor"
            placeholder={t('Location')}
            nullable
            value={null}
            filterLocationsByActiveRole
            onChange={value => this.handleFilterChange(nameof<LocationTabPaneFilters>('location'), value)}
          />

          <YearEditor
            clearable
            className="custom-editor year-editor"
            placeholder={t('Year')}
            value={this.state.filters.year}
            onChange={value => this.handleFilterChange(nameof<LocationTabPaneFilters>('year'), value)}
          />
        </div>
        <TableView model={tableModel} onRefresh={this.load} onPageChange={this.handlePageChange} hiddeMenu></TableView>
      </div>
    );
  }
}

export default withTranslation()(LocationTabPane);
