import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';

import { MrHoursDurationDto, MrHoursDurationsStore } from 'stores/configuration/events-n-requests/mr-hours-durations-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import NewMrHoursDurationView from './new-mr-hours-duration';
import ChangeMrHoursDurationView from './edit-mr-hours-duration';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DropDownProfessionsStore, ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { DropDownTrainingLevelsStore, TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { EventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';

export interface MrHoursDurationsListProps extends WithTranslation, RouteComponentProps {
  mrHoursDurations: MrHoursDurationsStore;
  dropdownroles: DropDownProfessionsStore;
  dropdowTrainingLevel: DropDownTrainingLevelsStore;
  readOnly: boolean;
  dropdownEventTypesStore: EventTypesStore;
}

export interface MrHoursDurationsListState {
  query: Query;
  newMrHoursDurationShown: boolean;
  changeMrHoursDurationShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(
  ['mrHoursDurations', MrHoursDurationsStore],
  ['dropdownroles', DropDownProfessionsStore],
  ['dropdowTrainingLevel', DropDownTrainingLevelsStore],
  ['dropdownEventTypesStore', EventTypesStore]
)
class MrHoursDurationsListPage extends React.Component<MrHoursDurationsListProps, MrHoursDurationsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
        skip: 0,
        take: 10
      },
      newMrHoursDurationShown: false,
      changeMrHoursDurationShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.mrHoursDurations.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: MrHoursDurationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.mrHoursDurations.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: MrHoursDurationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.mrHoursDurations.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newMrHoursDurationShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newMrHoursDurationShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeMrHoursDurationShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeMrHoursDurationShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Event Type'),
          renderer: data => <>{data.eventTypeName}</>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<EventTypeDto>
              filterTitle={t('Filter by Event Type')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter('EventTypeOriginalId', { EventTypeOriginalId: { eq: { type: 'guid', value } } })}
              onClear={() => onClear('EventTypeOriginalId')}
              active={activeFilters.includes('EventTypeOriginalId')}
              getItems={q => this.props.dropdownEventTypesStore.getAllAsync(q)}
              parameters="OriginalEventTypeId,name"
              orderBy={[{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: EventTypeDto) => u.originalEventTypeId}
              titleSelector={(u: EventTypeDto) => u.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Cluster'),
          renderer: data => <>{data.clusterName}</>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MrHoursDurationDto>('clusterName'), `contains(tolower(Cluster/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('clusterName'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'Cluster/Name',
            useProfile: false
          }
        },
        {
          title: t('Equipment Type'),
          renderer: data => data.equipmentTypeName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MrHoursDurationDto>('equipmentTypeName'), `contains(tolower(EquipmentType/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('equipmentTypeName'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('equipmentTypeName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'EquipmentType/Name',
            useProfile: false
          }
        },
        {
          title: t('OEM'),
          renderer: data => data.oemName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by OEM')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<MrHoursDurationDto>('oemName'), `contains(tolower(Oem/Name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<MrHoursDurationDto>('oemName'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('oemName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'Oem/Name',
            useProfile: false
          }
        },
        {
          title: t('Machine Model'),
          renderer: data => data.machineModelName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Machine Model')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MrHoursDurationDto>('machineModelName'), `contains(tolower(MachineModel/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('machineModelName'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('machineModelName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'MachineModel/Name',
            useProfile: false
          }
        },
        {
          title: t('Machine Unit'),
          renderer: data => data.machineUnitName || '[ENTIRE MACHINE]',
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Machine Unit')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MrHoursDurationDto>('machineUnitName'), `contains(tolower(MachineUnit/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('machineUnitName'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('machineUnitName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'MachineUnit/Name',
            useProfile: false
          }
        },
        {
          title: t('Role'),
          renderer: data => data.professionName || '[ANY]',
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<ProfessionDto>
              filterTitle={t('Filter by Role')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter(nameof<MrHoursDurationDto>('professionId'), { professionId: { eq: { type: 'guid', value } } })
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('professionId'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('professionId'))}
              getItems={q => this.props.dropdownroles.getAllAsync(q)}
              parameters="id,profession"
              orderBy={[{ direction: 'Ascending', field: nameof<ProfessionDto>('profession'), useProfile: false }]}
              filterGenerator={search =>
                isNullOrWhiteSpaces(search) ? {} : { 'tolower(profession)': { startswith: search.toLowerCase() } }
              }
              valueSelector={(u: ProfessionDto) => u.id}
              titleSelector={(u: ProfessionDto) => u.profession}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Training Level'),
          renderer: data => data.trainingLevelName || '[ANY]',
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<TrainingLevelDto>
              filterTitle={t('Filter by Training Level')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter(nameof<MrHoursDurationDto>('trainingLevelId'), { trainingLevelId: { eq: { type: 'guid', value } } })
              }
              onClear={() => onClear(nameof<MrHoursDurationDto>('trainingLevelId'))}
              active={activeFilters.includes(nameof<MrHoursDurationDto>('trainingLevelId'))}
              getItems={q => this.props.dropdowTrainingLevel.getAllAsync(q)}
              parameters="id,name"
              orderBy={[{ direction: 'Ascending', field: nameof<TrainingLevelDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: TrainingLevelDto) => u.id}
              titleSelector={(u: TrainingLevelDto) => u.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Hours Duration'),
          tooltipRenderer: true,
          renderer: data => data.hoursDuration
        }
      ],
      data: this.props.mrHoursDurations.state
    } as TableModel<MrHoursDurationDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.mrHoursDurations.state.result && !this.props.mrHoursDurations.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.mrHoursDurations.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newMrHoursDurationShown && !this.state.changeMrHoursDurationShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={
                !this.props.readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={!this.props.readOnly}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!this.props.readOnly}
              createNewButtonTitle={t('Add MR Hours Duration')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newMrHoursDurationShown && <NewMrHoursDurationView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeMrHoursDurationShown && (
            <ChangeMrHoursDurationView onClose={this.onEditItemClosed} currentMU={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(MrHoursDurationsListPage);
