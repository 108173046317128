import { Component } from 'react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import DeliveryMethodEditor from 'widgets/bussiness/delivery-method-editor';
import LanguageEditor from 'widgets/bussiness/language-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import CustomizationDataEditor from 'widgets/bussiness/customization-data-editor';
import { nameof } from 'utils/object';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faPencilRuler, faChalkboardTeacher, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { EventFormStore, EventTrainingViewModel } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';

interface EventDetailsTabPaneProps extends WithTranslation {
  loading: boolean;
  readOnly: boolean;
  eventFormStore?: EventFormStore;
}

@connect(['eventFormStore', EventFormStore])
class EventDetailsTabPane extends Component<EventDetailsTabPaneProps> {
  private handleOnEventTrainingChange(propertyName: string, value: any) {
    let trainingDetails = { ...this.props.eventFormStore.state.item.trainingDetails };
    trainingDetails[propertyName] = value;

    this.props.eventFormStore.change({ trainingDetails });
  }

  public render() {
    const { t, readOnly, loading } = this.props;
    const trainingDetails = this.props.eventFormStore.state.item.trainingDetails;

    return (
      <>
        {loading && (
          <div style={{ height: 200 }}>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}
        <Form className="form__event-details__container">
          <div className="form__event-details-tab__role form__event-details-tab__element">
            <FontAwesomeIcon className="form__header__row-icon" icon={faGraduationCap} />
            <p>{t('Training level')}</p>

            <TrainingLevelEditor
              readOnly={readOnly}
              className="training-tab__first-row__input-edit-mode training-tab__first-row__input-training-level"
              value={trainingDetails.trainingLevel}
              onChange={value => this.handleOnEventTrainingChange(nameof<EventTrainingViewModel>('trainingLevel'), value)}
              loadDataOnOpen
            />
          </div>

          {(!readOnly || trainingDetails.customizationData) && (
            <>
              <div className="form__event-details-tab__role form__event-details-tab__element">
                <FontAwesomeIcon className="form__header__row-icon" icon={faPencilRuler} />
                <p>{t('Customization')}</p>
                <CustomizationDataEditor
                  readOnly={readOnly}
                  nullable
                  clearable
                  className="training-tab__first-row__input-edit-mode"
                  value={trainingDetails.customizationData}
                  onChange={value => this.handleOnEventTrainingChange(nameof<EventTrainingViewModel>('customizationData'), value)}
                />
              </div>
            </>
          )}

          <div className="form__event-details-tab__role form__event-details-tab__element">
            <FontAwesomeIcon className="form__header__row-icon" icon={faChalkboardTeacher} />
            <p>{t('Delivery Method')}</p>
            <DeliveryMethodEditor
              readOnly={readOnly}
              className="training-tab__first-row__input-edit-mode"
              value={trainingDetails.deliveryMethod}
              onChange={value => this.handleOnEventTrainingChange(nameof<EventTrainingViewModel>('deliveryMethod'), value)}
              loadDataOnOpen
            />
          </div>

          {(!readOnly || trainingDetails.language) && (
            <div className="form__event-details-tab__role form__event-details-tab__element">
              <FontAwesomeIcon className="form__header__row-icon" icon={faGlobe} />
              <p>{t('Language')}</p>
              <LanguageEditor
                readOnly={readOnly}
                nullable
                clearable
                className="training-tab__first-row__input-edit-mode"
                value={trainingDetails.language}
                onChange={value => this.handleOnEventTrainingChange(nameof<EventTrainingViewModel>('language'), value)}
              />
            </div>
          )}
        </Form>
      </>
    );
  }
}

export default withTranslation()(EventDetailsTabPane);
