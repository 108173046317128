import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import InstructorVisaRestrictionListPage from './instructor-visa-restriction-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface InstructorVisaRestrictionConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface InstructorVisaRestrictionConfigurationHomeState {}

class InstructorVisaRestrictionConfigurationHomePage extends React.Component<
  InstructorVisaRestrictionConfigurationHomeProps,
  InstructorVisaRestrictionConfigurationHomeState
> {
  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Master Data')}</h3>
        <LineSeparator />
        <InstructorVisaRestrictionListPage />
      </>
    );
  }
}

export default withTranslation()(InstructorVisaRestrictionConfigurationHomePage);
