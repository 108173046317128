import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import { StudentDto } from 'stores/students/student-model';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { openInNewWindow } from 'utils/useful-functions';

interface StudentListProps extends WithTranslation {
  onStudentRemove?: (value: StudentDto) => void;
  students?: StudentDto[];
  canRemove?: boolean;
  pocLocations?: string[];
}

export interface StudentListState {
  isAdminPocInstructorOrPlanner: boolean;
  isPoc: boolean;
}

class StudentList extends Component<StudentListProps, StudentListState> {
  constructor(props) {
    super(props);
    this.state = {
      isAdminPocInstructorOrPlanner: false,
      isPoc: false
    };
  }
  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    if (this.identityService.isInRole(Roles.Employee)) this.setState({ isAdminPocInstructorOrPlanner: false, isPoc: false });
    else if (IdentityService.isAdminPocPlannerOrInstructor) {
      this.setState({ isAdminPocInstructorOrPlanner: true });
      if (this.identityService.isInRole(Roles.PoC)) this.setState({ isPoc: true });
    }
  }

  private onStudentClicked(id: string) {
    openInNewWindow(`./employee-page/${id}`);
  }

  private onStudentRemove(student: StudentDto) {
    if (this.props.onStudentRemove) this.props.onStudentRemove(student);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="students-tab__students-table" key="students-table">
        <p className="students-table__column-title" key="students-header-username">
          {t('User Name')}
        </p>
        <p className="students-table__column-title" key="students-header-name">
          {t('Name')}
        </p>
        <p className="students-table__column-title" key="students-header-surname">
          {t('Surname')}
        </p>
        <p className="students-table__column-title" key="students-header-location">
          {t('Location')}
        </p>
        <p className="students-table__column-title text__transparent" key="students-header-eye">
          X
        </p>
        <p className="students-table__column-title text__transparent" key="students-header-action">
          X
        </p>

        {this.props.students.map((student, indx) => {
          return (
            <React.Fragment key={`${indx}_row`}>
              <div className="border-bottom-gray" key={`${indx}_username`}>
                <p>{student.userName}</p>
              </div>
              <div className="border-bottom-gray" key={`${indx}_firstName`}>
                <p>{student.firstName}</p>
              </div>
              <div className="border-bottom-gray" key={`${indx}_lastName`}>
                <p>{student.lastName}</p>
              </div>

              <div className="border-bottom-gray" key={`${indx}_location`}>
                {student.location ? (
                  <span key={`${indx}_country`}>
                    <CountryFlag countryName={student.location.countryName} />
                    &nbsp;{student.location.location}
                  </span>
                ) : (
                  <p key={`${indx}_no-location`}>-</p>
                )}
              </div>

              <div className="border-bottom-gray" key={`${indx}_eye`}>
                {this.state.isAdminPocInstructorOrPlanner ? (
                  <Icon.Group key={`${indx}_icon-group`}>
                    {(!this.state.isPoc ||
                      (this.props.pocLocations &&
                        this.props.pocLocations.length > 0 &&
                        this.props.pocLocations.any(x => x === student.location.id))) && (
                      <Popup
                        key={`${indx}_eye-popup`}
                        trigger={
                          <Icon
                            className="clickable-icon"
                            name="eye"
                            onClick={e => {
                              this.onStudentClicked(student.id);
                            }}
                          />
                        }
                        position="top right"
                        content={t('Open Employee Card')}
                      />
                    )}
                  </Icon.Group>
                ) : (
                  <p key={`${indx}_no-icon`}>-</p>
                )}
              </div>

              <div className="border-bottom-gray" key={`${indx}_remove`}>
                {this.props.canRemove ? (
                  <div className="flex-center">
                    <Icon
                      key={`${indx}_remove-icon`}
                      className="clickable-icon"
                      color="red"
                      name="remove"
                      size="large"
                      onClick={() => this.onStudentRemove(student)}
                    />
                  </div>
                ) : (
                  <p key={`${indx}_no-action`}> </p>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(StudentList);
