import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDto, UserStore } from 'stores/users/users-store';
import { Grid, Button, Dropdown, Icon, Checkbox } from 'semantic-ui-react';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import ChooseAssessorsView from 'widgets/form/choose-assessors-form';
import { getProperties, getKeyValuePairs, nameof } from 'utils/object';
import { isNullOrWhiteSpaces, setEquals } from 'utils/useful-functions';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import './employees-and-assessors-step.less';
import { IwsFormListDto, IwsFormItemDto } from 'stores/assessments/forms/iws-forms-store';
import { AssessorAnswerStatus } from 'stores/assessments/forms/tna-forms-store';
import { connect } from 'redux-scaffolding-ts';
import { UserProfilesStore, UserProfilesDto } from 'stores/profile/user-profile-store';
import { Query } from 'stores/dataStore';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import { ProfTemplateDict } from '../iws-form-wizard';

export interface EmployeeAssessors {
  skillsAssessor: { [templateId: string]: string };
  userProfileIds: string[];
  newProfiles: string[];
  displayName: string;
}

export interface EmployeesAssessorsViewModel {
  employees: string[];
  assessors: string[];
  employeesAssessors: { [employeeId: string]: EmployeeAssessors };
}

export interface EmployeesAndAssessorsStepProps extends WithTranslation {
  isStepActive: boolean;
  locationId: string;
  profileIds: string[];
  onStepChanged?: () => void;
  onChange?: (info: EmployeesAssessorsViewModel) => void;
  dataFormEditMode: IwsFormListDto;
  userProfilesStore?: UserProfilesStore;
  userStore?: UserStore;
  itemToEdit: IwsFormItemDto;
  isOnEditMode: boolean;
  value: EmployeesAssessorsViewModel;
  profilesTemplates?: { [profileId: string]: ProfTemplateDict };
  isGlobalPoc?: boolean;
}

export interface EmployeesAndAssessorsStepState {
  employees: { [employeeId: string]: UserProfilesDto };
  assessors: { [employeeId: string]: any };
  employeesAssessors: { [employeeId: string]: EmployeeAssessors };
  showEmployeeModal: boolean;
  locationId: string;
  profileIds: string[];
  showAssessorModal: boolean;
  selectedAssessor: string;
  isStepActive: boolean;
  assessorFromEditMode: UserProfilesDto[];
  employeesNewProfiles: { [employeeId: string]: string[] };
}
const _allowTemplatesNotInUserProfiles: boolean = true;
@connect(['userProfilesStore', UserProfilesStore], ['userStore', UserStore])
class EmployeesAndAssessorsStep extends React.Component<EmployeesAndAssessorsStepProps, EmployeesAndAssessorsStepState> {
  constructor(props: EmployeesAndAssessorsStepProps) {
    super(props);
    this.state = {
      employees: {},
      assessors: {},
      employeesAssessors: this.props.value.employeesAssessors,
      showEmployeeModal: false,
      locationId: this.props.locationId,
      profileIds: this.props.profileIds,
      showAssessorModal: false,
      selectedAssessor: undefined,
      isStepActive: !!this.props.isStepActive,
      assessorFromEditMode: [],
      employeesNewProfiles: {}
    };
  }

  componentDidMount() {
    if (this.props.isOnEditMode) {
      const employeeIdEditMode = this.props.dataFormEditMode?.userId;
      this.props.userProfilesStore
        .getUserProfileById(employeeIdEditMode)
        .then(res => {
          this.onEmployeesSelected([res]);
        })
        .catch(error => console.error(error));

      this.toGetAssessorEditListInfo();
    }
  }

  private toGetAssessorEditListInfo = async () => {
    const assessorsIds = this.props.value.assessors || [];
    const oDataFilter = assessorsIds.length > 0 ? [`id in (${assessorsIds.join(', ')})`] : [];
    const query: Query = { searchQuery: '', orderBy: [], filter: oDataFilter, skip: 0, take: 1000 };
    const usersResponse = await this.props.userStore.getAllAsync(query);
    this.onAssessorsFromEditMode(usersResponse.items);
  };

  private stringComparer = (a: string, b: string): boolean => {
    return a === b;
  };

  UNSAFE_componentWillReceiveProps(next: EmployeesAndAssessorsStepProps) {
    if (next != null && next !== this.props) {
      const currLocationId = this.state.locationId;
      const currProfileIds = this.state.profileIds;
      const currEmployeesAssessors = this.state.employeesAssessors;
      const nextState = {} as EmployeesAndAssessorsStepState;

      let updateState = false;

      const nextProfileIds = next.profileIds;
      const nextLocationId = next.locationId;
      const nextProfTemplates = next.profilesTemplates || {};

      if (next.isStepActive !== this.props.isStepActive) {
        updateState = true;
        nextState.isStepActive = !!next.isStepActive;
      }

      let resetEmployees = false;

      if (!setEquals(nextProfileIds, currProfileIds, this.stringComparer)) {
        resetEmployees = true;
        updateState = true;
        nextState.profileIds = nextProfileIds;
      }
      if (!nextProfileIds || nextProfileIds.length === 0 || !currProfileIds || currProfileIds.length === 0) {
        resetEmployees = true;
        updateState = true;
        nextState.profileIds = nextProfileIds;
      }

      if (!this.stringComparer(nextLocationId, currLocationId)) {
        resetEmployees = true;
        updateState = true;
        nextState.locationId = nextLocationId;
      }

      if (resetEmployees) {
        nextState.employees = {};
        nextState.assessors = {};
        nextState.employeesAssessors = {};
        nextState.selectedAssessor = null;
        nextState.employeesNewProfiles = {};
        updateState = true;
      } else {
        const nextTemplatesProps = this.flatProfileTemplates(nextProfTemplates).map(x => x.tempId);
        const currTemplatesProps = this.flatProfileTemplates(this.props.profilesTemplates || {}).map(x => x.tempId);
        if (!setEquals(nextTemplatesProps, currTemplatesProps, this.stringComparer)) {
          const currentKeys = currTemplatesProps;
          const nextKeys = nextTemplatesProps;
          const toAdd = nextKeys.filter(x => !currentKeys.includes(x));
          const toRemove = currentKeys.filter(x => !nextKeys.includes(x));
          const matrixKvp = getProperties(currEmployeesAssessors);
          if ((toAdd.length > 0 || toRemove.length > 0) && matrixKvp.length > 0) {
            let changesCount = 0;
            for (let i = 0; i < matrixKvp.length; i++) {
              const emplAssessor = matrixKvp[i].value as EmployeeAssessors;
              const employeeTemplates = emplAssessor.skillsAssessor;
              if (toRemove.length > 0) {
                for (let trk of toRemove) {
                  if (Object.keys(employeeTemplates).includes(trk)) {
                    delete employeeTemplates[trk];
                    changesCount++;
                  }
                }
              }
              if (toAdd.length > 0) {
                for (let tak of toAdd) {
                  if (!Object.keys(employeeTemplates).includes(tak)) {
                    employeeTemplates[tak] = null;
                    changesCount++;
                  }
                }
              }
            }
            nextState.employeesAssessors = { ...currEmployeesAssessors };
            if (changesCount > 0) {
              this.setState({ ...nextState }, this.triggerOnChange);
            }
          }
        }
      }

      if (updateState) {
        this.setState({ ...nextState });
      }
    }
  }
  private flatProfileTemplates = (profilesTemplates: { [profileId: string]: ProfTemplateDict }) => {
    if (!profilesTemplates) return [];
    const profTempl = getKeyValuePairs<ProfTemplateDict>(profilesTemplates);
    const templatesList = profTempl.flatMap(prof => {
      const tDict = getKeyValuePairs<string[]>(prof.value.templatesDict);
      const tKeys = tDict.map(x => x.key);
      return tKeys.map(z => ({ tempId: z, profId: prof.key }));
    });
    return templatesList;
  };

  private showEmployeeModal = () => {
    this.setState({ showEmployeeModal: true });
  };

  private onCloseEmployeeModal = () => {
    this.setState({ showEmployeeModal: false });
  };

  private onEmployeesSelected = (newEmployees: UserProfilesDto[]) => {
    const { employeesAssessors: matrix, employees } = this.state;

    const profTempl = getKeyValuePairs<ProfTemplateDict>(this.props.profilesTemplates).map(x => x.value);
    const templatesProps = profTempl.flatMap(x => getKeyValuePairs<string>(x.templatesDict).map(kvp => kvp.key));

    for (let i = 0; i < newEmployees.length; i++) {
      const employeeId = newEmployees[i].userId;
      if (!this.props.isOnEditMode) {
        const profIds = newEmployees[i]?.profiles.map(x => x.profileId);
        const skillDict = {};
        for (let j = 0; j < templatesProps.length; j++) {
          const sId = templatesProps[j];
          skillDict[sId] = null;
        }

        matrix[employeeId] = {
          employee: newEmployees[i].userName,
          skillsAssessor: skillDict,
          userProfileIds: profIds,
          newProfiles: [],
          displayName: newEmployees[i].userName
        } as EmployeeAssessors;
      }
      employees[employeeId] = newEmployees[i];
    }
    this.setState({ showEmployeeModal: false, employees: { ...employees }, employeesAssessors: { ...matrix } }, this.triggerOnChange);
  };

  protected removeEmployee = (id: string) => {
    const { employees, employeesAssessors: matrix, assessors, selectedAssessor } = this.state;
    delete matrix[id];
    delete employees[id];

    let nextAssessors = assessors;
    let nextSelectedAssessor = selectedAssessor;
    if (getProperties(employees).length === 0) {
      nextAssessors = {};
      nextSelectedAssessor = null;
    }

    this.setState(
      {
        employees: { ...employees },
        employeesAssessors: { ...matrix },
        assessors: nextAssessors,
        selectedAssessor: nextSelectedAssessor
      },
      this.triggerOnChange
    );
  };

  private showAssessorModal = () => {
    this.setState({ showAssessorModal: true });
  };

  private onCloseAssessorModal = () => {
    this.setState({ showAssessorModal: false });
  };

  private onAssessorsSelected = (newAssessors: UserDto[]) => {
    const { assessors } = this.state;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }
    this.setState({ showAssessorModal: false, assessors: { ...assessors } }, this.triggerOnChange);
  };

  private onAssessorsFromEditMode = (newAssessors: UserDto[]) => {
    const { assessors } = this.state;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }
    this.setState({ showAssessorModal: false, assessors: { ...assessors } }, this.triggerOnChange);
  };

  private onAssessorSelectorChanged = (_: any, { value }) => {
    let selectedAssessor = value;
    if (isNullOrWhiteSpaces(value)) selectedAssessor = null;
    this.setState({ selectedAssessor });
  };

  private removeAssessor = (id: string) => {
    if (this.isUsed(id)) return;
    const { assessors, employeesAssessors: matrix } = this.state;
    let { selectedAssessor } = this.state;

    const matrixRows = getProperties(matrix);

    for (let i = 0; i < matrixRows.length; i++) {
      const row = matrixRows[i].value as EmployeeAssessors;

      const prop = getProperties(row.skillsAssessor);
      for (let j = 0; j < prop.length; j++) {
        const { key, value } = prop[j];
        if (value === id) {
          row.skillsAssessor[key] = null;
        }
      }
    }
    delete assessors[id];
    if (selectedAssessor === id) selectedAssessor = null;

    this.setState(
      {
        assessors: { ...assessors },
        employeesAssessors: { ...matrix },
        selectedAssessor
      },
      this.triggerOnChange
    );
  };

  private isFunctionalExpertOfThisMachineOrSkill = (assessorId: string, machineOrSkillId: string) => {
    const { itemToEdit } = this.props;

    var skillFExp = itemToEdit.qualifiers.firstOrDefault(x => x.userId === assessorId);
    if (skillFExp && (skillFExp.templates || []).any(x => x.templateId === machineOrSkillId)) {
      return true;
    }

    return false;
  };
  private hasEmployeeSameProfileForTemplates = (employeeId: string, templateId: string) => {
    const { employees, employeesAssessors: matrix } = this.state;
    const { profilesTemplates, isOnEditMode } = this.props;
    const userProfiles = isOnEditMode ? employees[employeeId].profiles.map(x => x.profileId) : matrix[employeeId].userProfileIds;
    let found = false;
    for (let i = 0; i < userProfiles.length && !found; i++) {
      const profId = userProfiles[i];
      const profTemplates = profilesTemplates[profId];
      if (profTemplates && profTemplates.templatesDict && Object.keys(profTemplates.templatesDict).length > 0) {
        found = !!profTemplates.templatesDict[templateId];
      }
    }
    return found;
  };
  private getProfileIdFromTemplateId = (tempId: string): string => {
    const { profilesTemplates } = this.props;
    if (!profilesTemplates) return null;

    const profTempl = getKeyValuePairs<ProfTemplateDict>(profilesTemplates);
    const templatesList = profTempl.flatMap(prof => {
      const tDict = getKeyValuePairs<string[]>(prof.value.templatesDict);
      const tKeys = tDict.map(x => x.key);
      return tKeys.map(z => ({ tempId: z, profId: prof.key }));
    });
    const temp = templatesList.find(x => x.tempId === tempId);
    return temp?.profId || null;
  };

  private getEmployeesCell = (id: string, prop: string, key: string, answersStatus: AssessorAnswerStatus[]) => {
    const { employeesAssessors: matrix, selectedAssessor, assessors } = this.state;

    const row = matrix[id];
    if (row == null) return null;

    let objVal = row[prop];
    if (prop === nameof<EmployeeAssessors>('skillsAssessor')) {
      objVal = objVal[key];
    }

    if (objVal == null) {
      if (selectedAssessor == null) return null;

      return (
        <Checkbox
          className="emptyAssessor"
          checked={false}
          onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)}
          disabled={false}
        />
      );
    } else if (objVal === selectedAssessor) {
      return (
        <Checkbox
          className="selectedAssessor"
          checked={true}
          onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)}
          disabled={
            answersStatus &&
            answersStatus.any(x => x.userId === selectedAssessor && x.answerStatus === 'Done') &&
            this.isFunctionalExpertOfThisMachineOrSkill(selectedAssessor, key)
          }
        />
      );
    } else {
      const a = assessors[objVal];
      return `${a?.firstName} ${a?.lastName}`;
    }
  };

  private getEmployeesHeader = (header: string, prop: string, key: string, answersStatus: AssessorAnswerStatus[]) => {
    const { employeesAssessors: matrix, selectedAssessor } = this.state;

    if (selectedAssessor == null) {
      return header;
    }

    const rows = getKeyValuePairs<EmployeeAssessors>(matrix);
    let thereAreEmpty = false;
    let thereAreSelected = false;
    let avoidCount = 0;
    let templateAvailableForEmployeeCount = 0;
    let selectedCount = 0;
    for (let i = 0; i < rows.length; i++) {
      let employeeId = rows[i].key;
      let row = rows[i].value;
      let val = row[prop];
      if (prop === nameof<EmployeeAssessors>('skillsAssessor')) val = val[key];

      const thisEmployeeHasThisTemplateSelectable = Object.keys(row[prop]).includes(key);
      templateAvailableForEmployeeCount += thisEmployeeHasThisTemplateSelectable ? 1 : 0;
      if (val == null) {
        const isFunctionalExpert = this.isFunctionalExpertOf(selectedAssessor, employeeId, key);
        if (isFunctionalExpert) {
          avoidCount++;
        } else {
          thereAreEmpty = true;
          break;
        }
      } else if (val === selectedAssessor) {
        thereAreSelected = true;
        selectedCount++;
      } else {
        avoidCount++;
      }
    }

    if (avoidCount === rows.length)
      return (
        <>
          {header} <Checkbox checked={false} disabled className="employeeHeaderAvoid" />
        </>
      );

    const chk = !thereAreEmpty && thereAreSelected && selectedCount === templateAvailableForEmployeeCount;

    return (
      <>
        {header}{' '}
        <Checkbox
          className="employeeAssessorHeaderSetAll"
          checked={chk}
          onChange={(_, { checked }) => this.setSelectedAssesorToAll(checked, prop, key)}
          disabled={
            answersStatus &&
            answersStatus.any(x => x.userId === selectedAssessor && x.answerStatus === 'Done') &&
            this.isFunctionalExpertOfThisMachineOrSkill(selectedAssessor, key)
          }
        />
      </>
    );
  };

  private setSelectedAssesorToAll = (setValue: boolean, prop: string, key: string) => {
    const { employeesAssessors: matrix, selectedAssessor, employeesNewProfiles } = this.state;
    if (selectedAssessor == null) return;
    const rows = getKeyValuePairs<EmployeeAssessors>(matrix);
    for (let i = 0; i < rows.length; i++) {
      const employeeId = rows[i].key;
      const templateInUserProfiles = this.canAssignAssessorTo(selectedAssessor, employeeId, prop, key);

      if (!templateInUserProfiles && !_allowTemplatesNotInUserProfiles) continue;

      const row = rows[i].value;
      if (prop === nameof<EmployeeAssessors>('skillsAssessor')) {
        if (setValue && row[prop][key] == null) {
          if (_allowTemplatesNotInUserProfiles && !templateInUserProfiles) {
            this.updateEmployeesNewProfilesOnSelect(setValue, employeeId, prop, key);
          }
          row[prop][key] = selectedAssessor;
        }
        if (!setValue && row[prop][key] === selectedAssessor) {
          row[prop][key] = null;
          if (_allowTemplatesNotInUserProfiles && !templateInUserProfiles) {
            this.updateEmployeesNewProfilesOnSelect(setValue, employeeId, prop, key);
          }
        }
      } else {
        if (setValue && row[prop] == null) row[prop] = selectedAssessor;
        if (!setValue && row[prop] === selectedAssessor) row[prop] = null;
      }
    }
    this.setState(
      {
        employeesAssessors: { ...matrix },
        employeesNewProfiles: { ...employeesNewProfiles }
      },
      this.triggerOnChange
    );
  };

  private setSelectedAssesorToEmployee = (setValue: boolean, id: string, prop: string, key: string) => {
    const { employeesAssessors: matrix, selectedAssessor, employeesNewProfiles } = this.state;

    if (matrix[id] == null) return;
    const templateInUserProfiles = this.canAssignAssessorTo(selectedAssessor, id, prop, key);
    if (!templateInUserProfiles && !_allowTemplatesNotInUserProfiles) return;

    if (prop === nameof<EmployeeAssessors>('skillsAssessor')) {
      matrix[id][prop][key] = setValue ? selectedAssessor : null;
      if (_allowTemplatesNotInUserProfiles && !templateInUserProfiles) {
        this.updateEmployeesNewProfilesOnSelect(setValue, id, prop, key);
      }
    } else {
      matrix[id][prop] = setValue ? selectedAssessor : null;
    }
    this.setState(
      {
        employeesAssessors: { ...matrix },
        employeesNewProfiles: { ...employeesNewProfiles }
      },
      this.triggerOnChange
    );
  };

  private updateEmployeesNewProfilesOnSelect = (setValue: boolean, id: string, prop: string, key: string) => {
    const { employeesAssessors: matrix, employeesNewProfiles } = this.state;
    const profId = this.getProfileIdFromTemplateId(key);
    if (!employeesNewProfiles[id]) employeesNewProfiles[id] = [];

    if (!setValue) {
      if (employeesNewProfiles[id].includes(profId)) {
        employeesNewProfiles[id] = employeesNewProfiles[id].filter(x => x !== profId);
      }
    } else {
      if (!employeesNewProfiles[id].includes(profId)) employeesNewProfiles[id].push(profId);
    }
    matrix[id]['newProfiles'] = employeesNewProfiles[id];
  };

  private triggerOnChange = () => {
    const { employeesAssessors: matrix, assessors, employees } = this.state;
    if (this.props.onChange) {
      this.props.onChange({
        assessors: getProperties(assessors).map(x => x.key),
        employees: getProperties(employees).map(x => x.key),
        employeesAssessors: { ...matrix }
      });
    }
  };

  private isFunctionalExpertOf = (assesorId: string, employeeId: string, templateId: string = null) => {
    if (isNullOrWhiteSpaces(assesorId) || isNullOrWhiteSpaces(employeeId)) return false;
    const { employeesAssessors: matrix } = this.state;
    if (matrix == null) return false;
    const row = matrix[employeeId];
    if (row == null) return false;
    if (templateId) {
      return row.skillsAssessor[templateId] === assesorId;
    }
    return getKeyValuePairs<string>(row.skillsAssessor || {})
      .map(({ value }) => value)
      .includes(assesorId);
  };

  private isFunctionalExpert = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    const { employeesAssessors: matrix } = this.state;
    if (matrix == null) return false;
    return getProperties(matrix).any(({ key: employeeId }) => this.isFunctionalExpertOf(assesorId, employeeId));
  };

  private isUsed = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    return this.isFunctionalExpert(assesorId);
  };

  private canAssignAssessorTo = (assesorId: string, employeeId: string, prop: string, key: string) => {
    const allowed = this.hasEmployeeSameProfileForTemplates(employeeId, key);
    return allowed;
  };
  private showEmployeeNewProfiles = (employeeId: string, profileIds: string[]) => {
    const { employees } = this.state;
    const { profilesTemplates } = this.props;

    let profiles = profileIds.map(x => profilesTemplates[x]?.profileName).join(', ');

    return { employee: employees[employeeId]?.userName, profiles };
  };

  render() {
    const { t, isOnEditMode, itemToEdit, profilesTemplates, isGlobalPoc } = this.props;
    const { employees, assessors, showEmployeeModal, locationId } = this.state;
    const { profileIds, selectedAssessor, showAssessorModal, isStepActive, employeesNewProfiles } = this.state;

    let answersStatus = itemToEdit?.assessorAnswerStatus;
    let assessorsList: UserDto[] = [];
    let employeesList: UserProfilesDto[] = [];
    let templatesList: { key: string; value: string }[] = [];

    if (isStepActive) {
      assessorsList = getKeyValuePairs<UserDto>(assessors).map(x => x.value);
      employeesList = getKeyValuePairs<UserProfilesDto>(employees).map(x => x.value);
      const profTempl = getKeyValuePairs<ProfTemplateDict>(profilesTemplates).map(x => x.value);
      templatesList = profTempl.flatMap(x => getKeyValuePairs<string>(x.templatesDict));
    }

    return (
      <div className="wizard__step wizard__step-three iws-form-general-data">
        <StepsComponent active={isStepActive} number={'3'} title={t('SELECT EMPLOYEES & ASSIGN QUALIFIERS')}>
          <div className="new-iws-form-wizard_step3__btn-dropdown flex-start" id="">
            {!isOnEditMode && (
              <Button className="new-iws-form-wizard_step3-row_btn" onClick={this.showEmployeeModal}>
                {t('Add Employees')}
              </Button>
            )}
            {showEmployeeModal && (
              <ChooseEmployeesView
                locationId={locationId}
                profileIds={profileIds}
                showEmployeeIdFilter={true}
                alreadySelectedUserIds={employeesList.map(x => x.userId)}
                onAddEmployees={this.onEmployeesSelected}
                onCloseUsersModal={this.onCloseEmployeeModal}
                showProfileInColumn={true}
                isGlobalPoc={isGlobalPoc}
              />
            )}

            {employeesList.length !== 0 && assessorsList.length !== 0 && (
              <Dropdown
                id="new-iws-form-wizard_step3-row1_assessor-select"
                fluid
                selection
                options={assessorsList.map(a => ({
                  value: a.id,
                  text: `${a?.firstName} ${a?.lastName}`
                }))}
                value={selectedAssessor}
                closeOnBlur={true}
                closeOnEscape={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                placeholder={t('Assign Assessor')}
                onChange={this.onAssessorSelectorChanged}
                clearable
              />
            )}
          </div>

          {employeesList.length !== 0 && (
            <div className="new-iws-form-wizard_step3-matrix-container tooltip-visible scroll">
              <Grid
                id="new-iws-form-wizard_step3-matrix-table"
                className="new-iws-form-wizard_step3-table new-iws-form-wizard_step3-table__assessor"
              >
                <Grid.Row className="new-iws-form-wizard_step3-table-row new-iws-form-wizard_step3-table-header">
                  <Grid.Column className="new-iws-form-wizard_step3-table-cell">{t('Last Name')}</Grid.Column>
                  <Grid.Column className="new-iws-form-wizard_step3-table-cell">{t('First Name')}</Grid.Column>
                  <Grid.Column className="new-iws-form-wizard_step3-table-cell">{t('SF Position')}</Grid.Column>
                  {/* <Grid.Column className="new-iws-form-wizard_step3-table-cell">{t('Profiles')}</Grid.Column> */}
                  {assessorsList.length !== 0 &&
                    templatesList
                      .map(kvp => (
                        <Grid.Column key={kvp.key} className="new-iws-form-wizard_step3-table-cell">
                          {this.getEmployeesHeader(kvp.value, nameof<EmployeeAssessors>('skillsAssessor'), kvp.key, answersStatus)}
                        </Grid.Column>
                      ))
                      .distinct(x => x.key)}
                  <Grid.Column
                    width={1}
                    className="new-iws-form-wizard_step3-table-cell new-iws-form-wizard_step3-table-remove-cell"
                  ></Grid.Column>
                </Grid.Row>

                {employeesList.map(e => (
                  <Grid.Row key={`matrix_${e.userId}`} className="new-iws-form-wizard_step3-table-row">
                    <Grid.Column className="new-iws-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.lastName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-iws-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.firstName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-iws-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.sfPosition || '').toUpperCase())} />}
                    </Grid.Column>
                    {/* <Grid.Column className="new-iws-form-wizard_step3-table-cell">
                      {
                        <TableTooltipCell
                          textToShow={(e.profiles || [])
                            .filter(x => profileIds.includes(x.profileId))
                            .map(pr => (
                              <span key={pr.id}> {t((pr.profileName || '').toUpperCase())}</span>
                            ))}
                        />
                      }
                    </Grid.Column> */}
                    {assessorsList.length !== 0 &&
                      templatesList
                        .map(kvp => (
                          <Grid.Column key={kvp.key} className="new-iws-form-wizard_step3-table-cell">
                            {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('skillsAssessor'), kvp.key, answersStatus)}
                          </Grid.Column>
                        ))
                        .distinct(x => x.key)}

                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-iws-form-wizard_step3-table-cell  new-iws-form-wizard_step3-table-remove-cell"
                    >
                      {!isOnEditMode && (
                        <Icon
                          size="large"
                          name="close"
                          color="red"
                          className="clickable-icon"
                          onClick={() => this.removeEmployee(e.userId)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>

              {employeesNewProfiles && false && Object.keys(employeesNewProfiles).length > 0 && (
                <div>
                  <p className="template-wizard__step-title">The following employees will be assigned to profiles:</p>
                  <ul>
                    {Object.entries(employeesNewProfiles).map(([ky, value]) => {
                      let info = this.showEmployeeNewProfiles(ky, value);
                      return (
                        <li key={ky}>
                          {' '}
                          <span className="uppercase">
                            {' '}
                            <span className="bold"> {info.employee}</span> {' -> '} <span> {info.profiles}</span>{' '}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          {employeesList.length !== 0 && (
            <>
              <Button className="new-iws-form-wizard_step3-row_btn add-assessor__btn" onClick={this.showAssessorModal}>
                {t('Add Qualifiers')}
              </Button>
              {showAssessorModal && (
                <ChooseAssessorsView
                  alreadySelectedUserIds={[...assessorsList.map(a => a.id), ...employeesList.map(e => e.userId)]}
                  onAddAssessors={this.onAssessorsSelected}
                  onCloseUsersModal={this.onCloseAssessorModal}
                  customTitle={'Qualifiers'}
                  showEmployeeIdFilter
                />
              )}
            </>
          )}
          {employeesList.length !== 0 && assessorsList.length !== 0 && (
            <Grid id="new-iws-form-wizard_step3-assessors-table" className="new-iws-form-wizard_step3-table">
              <Grid.Row columns={4} className="new-iws-form-wizard_step3-table-row new-iws-form-wizard_step3-table-header">
                <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                  {t('Last Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                  {t('First Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                  {t('SF Position')}
                </Grid.Column>
                <Grid.Column
                  width={1}
                  className="new-iws-form-wizard_step3-table-cell new-iws-form-wizard_step3-table-remove-cell"
                ></Grid.Column>
              </Grid.Row>
              {assessorsList.map(a => (
                <Fragment key={a.id}>
                  <Grid.Row columns={4} key={`assessors_${a.id}`} className="new-iws-form-wizard_step3-table-row">
                    <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                      {(a.lastName || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                      {(a.firstName || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={5} className="new-iws-form-wizard_step3-table-cell">
                      {(a.sfPosition || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-iws-form-wizard_step3-table-cell  new-iws-form-wizard_step3-table-remove-cell"
                    >
                      {!this.isUsed(a.id) && (
                        <Icon size="large" name="close" color="red" className="clickable-icon" onClick={() => this.removeAssessor(a.id)} />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Fragment>
              ))}
            </Grid>
          )}
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(EmployeesAndAssessorsStep);
