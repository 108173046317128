import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import 'assets/less/events-employee.less';
import { EventsStore, EventDto } from 'stores/events/events-store';
import { Icon, Button } from 'semantic-ui-react';

import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

interface EventsEmployeeProps extends WithTranslation {
  events?: EventsStore;
  eventsList: EventDto[];
}

interface EventsEmployeeState {
  tempEventsList: EventDto[];
  tempEvent: EventDto;
  index: number;
  leftArrowActive: boolean;
  rightArrowActive: boolean;
}

class EventsEmployee extends React.Component<EventsEmployeeProps, EventsEmployeeState> {
  maxNumEvents: number = 10;
  constructor(props: EventsEmployeeProps) {
    super(props);
    this.state = {
      tempEventsList: this.props.eventsList,
      tempEvent: null,
      index: 0,
      leftArrowActive: false,
      rightArrowActive: false
    };
  }

  @autobind
  private CalculateFirstEventsList(events: EventDto[]): EventDto[] {
    let finalEvents: EventDto[] = [];
    if (events == null || events.length === 0) {
      return finalEvents;
    }

    const now = moment(DateTimeService.now());
    let tempEvents = events.filter(x => DateTimeService.toMoment(x.endDate) >= now);

    for (let i = 0; i < this.maxNumEvents && i < tempEvents.length; i++) {
      finalEvents.push(tempEvents[i]);
    }

    if (finalEvents.length > 0) {
      this.setState({ tempEvent: finalEvents[0] });
      this.setIndexByEventId(finalEvents[0].id, events);
    } else {
      finalEvents = events.slice();
      const ev = events.firstOrDefault();
      if (ev != null) this.setIndexByEventId(ev.id, events);
    }
    return finalEvents;
  }

  private setIndexByEventId(id: string, events: EventDto[]) {
    let tempIndex: number = events.map(x => x.id).indexOf(id, 0);
    this.setState({ index: tempIndex });
    if (tempIndex >= 0 && events.length > this.maxNumEvents) this.setState({ leftArrowActive: true });
    else this.setState({ leftArrowActive: false });

    if (events.length > this.maxNumEvents + tempIndex) this.setState({ rightArrowActive: true });
    else this.setState({ rightArrowActive: false });
  }

  async UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.eventsList !== this.props.eventsList) {
      let tempEvents = next.eventsList;
      tempEvents = this.CalculateFirstEventsList(tempEvents);
      this.setState({
        tempEventsList: tempEvents
      });
    }
  }

  @autobind
  private onLeftArrowClicked() {
    let finalEvents: EventDto[] = [];
    let tempIndex = 0;
    let eventsList = this.props.eventsList;

    this.setState({ rightArrowActive: true });
    let maxIndex = this.state.index - 1 >= 0 ? this.state.index - 1 : 0;
    let minIndx = maxIndex >= this.maxNumEvents ? maxIndex - (this.maxNumEvents - 1) : 0;

    tempIndex = maxIndex - this.maxNumEvents;

    for (let i = minIndx; i <= maxIndex; i++) {
      finalEvents.push(eventsList[i]);
    }
    this.setState({ tempEventsList: finalEvents, index: tempIndex, leftArrowActive: tempIndex > 0 });
  }

  @autobind
  private onRightArrowClicked() {
    let finalEvents: EventDto[] = [];
    let eventsList = this.props.eventsList;
    let tempIndex = this.state.index + 1;

    this.setState({ leftArrowActive: true });
    let total = this.props.eventsList.length;
    let minIndx = tempIndex + this.maxNumEvents > total ? total : tempIndex + this.maxNumEvents;
    let maxIndex = minIndx + this.maxNumEvents > total ? total : minIndx + this.maxNumEvents;

    tempIndex = minIndx;

    for (let i = minIndx; i < maxIndex; i++) {
      finalEvents.push(eventsList[i]);
    }
    this.setState({ tempEventsList: finalEvents, index: tempIndex, rightArrowActive: tempIndex + this.maxNumEvents < total });
  }

  public render() {
    return (
      <>
        <div className="events-employee__pag" key={'grid-events-employee-row1'}>
          <span className="bold-text">EVENTS</span>

          <div className="events-employee__pag__btns">
            {this.state.leftArrowActive && (
              <Button onClick={this.onLeftArrowClicked} className="button-arrow-background">
                <Icon.Group>
                  <Icon name="angle left"></Icon>
                </Icon.Group>
              </Button>
            )}
            {!this.state.leftArrowActive && (
              <Button className="button-arrow-background" disabled={true}>
                <Icon.Group>
                  <Icon className="angle-disabled button-arrow-background" name="angle left"></Icon>
                </Icon.Group>
              </Button>
            )}

            {this.state.rightArrowActive && (
              <Button onClick={this.onRightArrowClicked} className="button-arrow-background">
                <Icon.Group>
                  <Icon name="angle right"></Icon>
                </Icon.Group>
              </Button>
            )}
            {!this.state.rightArrowActive && (
              <Button className="button-arrow-background" disabled={true}>
                <Icon.Group>
                  <Icon className="angle-disabled button-arrow-background" name="angle right"></Icon>
                </Icon.Group>
              </Button>
            )}
          </div>
        </div>

        <div className="employee-flex">
          {this.state.tempEventsList
            ? this.state.tempEventsList.map((item, idx) => (
                <div className="employee-card__events-wrapper" key={'employee-card-events-wrapper-' + idx}>
                  <div className="events-employee__date-wrapper" key={'grid-events-employee-row' + item.id}>
                    <div className="date-box flex-center">
                      <div className="flex-center date-box__date-wrapper">
                        <span className="date-box__day">{moment(item.startDate).format('DD')}</span>
                        <span className="date-box__month">
                          {moment(item.startDate)
                            .format('MMM')
                            .toUpperCase()}
                        </span>
                        <span className="date-box__year">{moment(item.startDate).format('YYYY')}</span>
                      </div>
                      <span className="date-box__separator">-</span>
                      <div className="flex-center date-box__date-wrapper">
                        <span className="date-box__day">{moment(item.endDate).format('DD')}</span>
                        <span className="date-box__month">
                          {moment(item.endDate)
                            .format('MMM')
                            .toUpperCase()}
                        </span>
                        <span className="date-box__year">{moment(item.endDate).format('YYYY')}</span>
                      </div>
                    </div>

                    <div className="event-box flex-column">
                      <TableTooltipCell textToShow={item.friendlyId + ' - ' + item.title} newClassFromComponent="bold-text" />

                      <span className="">{item.eventDetails && item.eventDetails.location ? item.eventDetails.location.location : ''}</span>
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </>
    );
  }
}

export default withTranslation()(EventsEmployee);
