import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { PositionCodeDto, DropDownPositionCodesStore } from 'stores/configuration/profiles/position-codes-store';
import { nameof } from '../../../utils/object';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface MultiplePositionCodeEditorProps extends WithTranslation {
  value: PositionCodeDto[];
  onChange?: (value: PositionCodeDto[]) => void;
  positionCodesStore?: DropDownPositionCodesStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  positionCodesReceived?: string[];
}

interface MultiplePositionCodeEditorState {
  options: MultipleSelectionDropdownOptions<PositionCodeDto>[];
}

@connect(['positionCodesStore', DropDownPositionCodesStore])
class MultiplePositionCodeEditor extends React.Component<MultiplePositionCodeEditorProps, MultiplePositionCodeEditorState> {
  private get positionCodeStore() {
    return this.props.positionCodesStore;
  }

  state: MultiplePositionCodeEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(positionCodes: QueryResult<PositionCodeDto>): MultipleSelectionDropdownOptions<PositionCodeDto>[] {
    return positionCodes.items.map(pc => ({
      id: pc.id,
      value: pc,
      text: pc.code
    }));
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      let filter = [];

      if (this.props.positionCodesReceived && this.props.positionCodesReceived.length > 0) {
        filter = [{ id: { in: { type: 'guid', value: this.props.positionCodesReceived } } }];
      }

      this.positionCodeStore
        .getAllAsync(
          {
            searchQuery: '',
            skip: 0,
            take: 100000,
            orderBy: [{ direction: 'Ascending', field: nameof<PositionCodeDto>('code'), useProfile: false }],
            filter: filter
          },
          this.props.positionCodesReceived
        )
        .then(positionCodes => this.setState({ options: this.mapToOptions(positionCodes) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiplePositionCodeEditor);
