import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';

import { SupportCostsStore, SupportCostDto, ChangeSupportCostStore } from 'stores/configuration/support-costs/support-costs-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

import NewSupportCostView from './new-support-cost';
import ChangeSupportCostView from './edit-support-cost';
import SupportCostChangeLogView from './view-support-cost';

export interface SupportCostsListProps extends WithTranslation, RouteComponentProps {
  supportcosts: SupportCostsStore;
  changeSupportCostStore?: ChangeSupportCostStore;
}

export interface SupportCostsListState {
  query: Query;
  newSupportCostShown: boolean;
  changeSuportCostShown: boolean;
  viewSupportCostShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['supportcosts', SupportCostsStore], ['changeSupportCostStore', ChangeSupportCostStore])
class SupportCostsListPage extends React.Component<SupportCostsListProps, SupportCostsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onViewItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'year', useProfile: false }], skip: 0, take: 10 },
      newSupportCostShown: false,
      changeSuportCostShown: false,
      viewSupportCostShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const { query } = this.state;
    const q = { ...query };
    q.filter = [...((query.filter as any[]) || []), { active: true }];
    this.props.supportcosts.getAllAsync(q);
  };

  @autobind
  private onNewItem() {
    this.setState({ newSupportCostShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newSupportCostShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeSuportCostShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeSuportCostShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onViewItem() {
    this.setState({ viewSupportCostShown: true });
  }

  @autobind
  private onViewItemClosed(isSuccess: boolean) {
    this.setState({ viewSupportCostShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: SupportCostDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.supportcosts.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: SupportCostDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.supportcosts.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  onDismiss = () => {
    const { supportcosts, changeSupportCostStore } = this.props;
    supportcosts.clearMessages();
    changeSupportCostStore.clearMessages();
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, supportcosts, changeSupportCostStore } = this.props;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const isAdmin = IdentityService.isAdmin(currentUserInfo);

    let errMsgs = (supportcosts.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeSupportCostStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          renderer: data => data.name,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<SupportCostDto>('name'), `contains(${nameof<SupportCostDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<SupportCostDto>('name'))}
              active={activeFilters.includes(nameof<SupportCostDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<SupportCostDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Year'),
          tooltipRenderer: true,
          renderer: data => data.year,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SupportCostDto>('year'),
            useProfile: false
          }
        },
        {
          title: t('Cost'),
          tooltipRenderer: true,
          renderer: data => data.cost,
          selectableHeader: true
          // sortDefinition: {
          //   field: nameof<SupportCostDto>('cost'),
          //   useProfile: false
          // }
        }
      ],
      data: this.props.supportcosts.state
    } as TableModel<SupportCostDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newSupportCostShown && !this.state.changeSuportCostShown && !this.state.viewSupportCostShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  isVisible: () => isAdmin,
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="eye" />
                      &nbsp;{t('Change Log')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onViewItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              isRowDisableLayout={(item: SupportCostDto) => !item.active}
              onRefresh={this.load}
              canEdit={false}
              // canDelete={isAdmin}
              // onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={isAdmin}
              createNewButtonTitle={t('Add Support Cost')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newSupportCostShown && <NewSupportCostView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeSuportCostShown && (
            <ChangeSupportCostView onClose={this.onEditItemClosed} currentSC={this.state.selectedItem} />
          )) ||
          (this.state.viewSupportCostShown && (
            <SupportCostChangeLogView onClose={this.onViewItemClosed} currentSC={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(SupportCostsListPage);
