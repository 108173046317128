import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface TypeHoursCodeDto extends BaseDto {
  id: string;
  code: string;
}

export interface CreateTypeHoursCodeDto {
  code: string;
}

export interface ChangeTypeHoursCodeDto {
  id: string;
  code: string;
}

export class CreateTypeHoursCodeValidator extends AbstractValidator<CreateTypeHoursCodeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.code)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Type Hour Code is required'));

    this.validateIfString(o => o.code)
      .hasMaxLength(5)
      .withFailureMessage(i18n.t('Type Hour Code has max length 5'));
  }
}

export class ChangeTypeHoursCodeValidator extends AbstractValidator<ChangeTypeHoursCodeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Type Hour Code Id is required'));

    this.validateIfString(o => o.code)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Type Hour Code  is required'));

    this.validateIfString(o => o.code)
      .hasMaxLength(5)
      .withFailureMessage(i18n.t('Type Hour Code  has max length 5'));
  }
}

@repository('@@TYPEHOURSCODES', 'typeHoursCode.summary')
export class TypeHoursCodeStore extends DataStore<TypeHoursCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-typeHoursCode';
  retrievePath = 'get-typeHoursCodes';
  updatePath = 'update-typeHoursCode';
  deletePath = 'delete-typeHoursCode';

  protected validate(item: TypeHoursCodeDto) {
    return new ChangeTypeHoursCodeValidator().validate(item);
  }

  constructor() {
    super('TYPEHOURSCODE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@TYPEHOURSCODES', 'typeHoursCode.dropdown')
export class DropDownTypeHoursCodeStore extends DataStore<TypeHoursCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-typeHoursCodes';
  updatePath = '';
  deletePath = '';

  protected validate(item: TypeHoursCodeDto) {
    return new ChangeTypeHoursCodeValidator().validate(item);
  }

  constructor() {
    super('TYPEHOURSCODEDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@TYPEHOURSCODES', 'typeHoursCode.new')
export class NewTypeHoursCodeStore extends FormStore<CreateTypeHoursCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-typeHoursCode';
  retrievePath = 'get-typeHoursCode';
  updatePath = 'update-typeHoursCode';

  protected validate(item: CreateTypeHoursCodeDto) {
    return new CreateTypeHoursCodeValidator().validate(item);
  }

  constructor() {
    super('NEW_TYPEHOURSCODE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TYPEHOURSCODES', 'typeHoursCode.change')
export class ChangeTypeHoursCodeStore extends FormStore<ChangeTypeHoursCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-typeHoursCode';
  retrievePath = 'get-typeHoursCode';
  updatePath = 'update-typeHoursCode';

  protected validate(item: ChangeTypeHoursCodeDto) {
    return new ChangeTypeHoursCodeValidator().validate(item);
  }

  constructor() {
    super('CHANGE_TYPEHOURSCODE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
