import React, { FC, useState } from 'react';
import { Input, InputProps, InputOnChangeData } from 'semantic-ui-react';

export interface TimerInputProps extends InputProps {
  ms?: number;
}

let timerInputTimeoutId: NodeJS.Timeout = null;

export const TimerInput: FC<TimerInputProps> = (props: TimerInputProps) => {
  const [value, setValue] = useState(props.value || '');

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    clearTimeout(timerInputTimeoutId);
    setValue(data.value);
    timerInputTimeoutId = setTimeout(() => {
      if (props.onChange) props.onChange(event, data);
    }, props.ms || 1000);
  };
  const onKeyPressWrapper = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget != null) {
      clearTimeout(timerInputTimeoutId);
      if (props.onChange) props.onChange(event as any, { ...props, value: event.currentTarget.value });
    } else if (props.onKeyPress) props.onKeyPress(event);
  };
  return <Input {...props} onChange={onChangeWrapper} onKeyPress={onKeyPressWrapper} value={value} />;
};
