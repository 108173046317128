import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import { Roles } from 'services/identity-service';

interface RoleSelectorProps extends WithTranslation, StrictDropdownProps {}

const ops = [];
for (const role in Roles) {
  if (Roles.hasOwnProperty(role) && Number.isNaN(+role)) {
    const text = Roles[Roles[role]];
    ops.push({ text, value: Roles[role] });
  }
}

const SimpleSystemRoleSelector: FC<RoleSelectorProps> = ({ t, className, ...rest }) => {
  let classname = 'simple-system-role-selector';
  if (className) classname += ` ${className} `;
  return <Dropdown selection search {...rest} className={classname} options={ops} />;
};

export default withTranslation()(SimpleSystemRoleSelector);
