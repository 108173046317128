import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { CreateEventTypeSfMapDto, NewEventTypeSfMapStore } from 'stores/configuration/sf-mappings/event-type-sf-map-store';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';

interface NewEventTypeMapViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newEventTypeMapStore?: NewEventTypeSfMapStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewEventTypeMapViewState {
  userClickedSave: boolean;
  eventType: ItemReference;
}

@connect(['newEventTypeMapStore', NewEventTypeSfMapStore])
class NewEventTypeMapView extends React.Component<NewEventTypeMapViewProps, NewEventTypeMapViewState> {
  private get newEventTypeMapStore() {
    return this.props.newEventTypeMapStore;
  }

  constructor(props: NewEventTypeMapViewProps) {
    super(props);
    this.state = {
      userClickedSave: false,
      eventType: null
    };
    this.newEventTypeMapStore.createNew({
      eventTypeId: null,
      mappingToSF: '',
      itemType: '',
      status: null
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewEventTypeMapViewProps) {
    if (this.newEventTypeMapStore.state.result && this.newEventTypeMapStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newEventTypeMapStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newEventTypeMapStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newEventTypeMapStore.change({ ...this.newEventTypeMapStore.state.item, ...change });
  }
  private onChangeStatus = (e, { value }) => {
    this.newEventTypeMapStore.state.item.status = value;
  };

  public render() {
    const { t } = this.props as any;
    const statusOptions = [];

    statusOptions.push({ key: 10, text: t('Draft'), value: 10 });
    statusOptions.push({ key: 20, text: t('Planned'), value: 20 });
    statusOptions.push({ key: 30, text: t('InProgress'), value: 30 });
    statusOptions.push({ key: 40, text: t('Completed'), value: 40 });
    statusOptions.push({ key: 50, text: t('Closed'), value: 50 });

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newEventTypeMapStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Event Type Map')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newEventTypeMapStore.state.result && !this.newEventTypeMapStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newEventTypeMapStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newEventTypeMapStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required error={!this.state.eventType && this.state.userClickedSave}>
                    <label>{t('Event Type Map')}</label>
                    <EventTypeEditor
                      nullable
                      value={this.state.eventType}
                      placeholder={t('Event Type Map')}
                      onChange={c => {
                        this.newEventTypeMapStore.state.item.eventTypeId = c ? c.id : null;
                        this.setState({ eventType: c });
                      }}
                    />
                  </Form.Field>
                  <Form.Field required error={!this.newEventTypeMapStore.state.item.status && this.state.userClickedSave}>
                    <label>{t('Status')}</label>
                    <Dropdown
                      search
                      selection
                      allowAdditions
                      options={statusOptions as any}
                      placeholder={t('Status')}
                      value={this.newEventTypeMapStore.state.item.status}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateEventTypeSfMapDto>('status'), value)}
                      additionLabel={t('Add ')}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newEventTypeMapStore.state.item.itemType && this.state.userClickedSave}
                    label={t('Item Type')}
                    placeholder={t('Item Type')}
                    value={this.newEventTypeMapStore.state.item.itemType}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateEventTypeSfMapDto>('itemType'), value)}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.newEventTypeMapStore.state.item.mappingToSF && this.state.userClickedSave}
                    label={t('Mapping To SF')}
                    placeholder={t('Mapping To SF')}
                    value={this.newEventTypeMapStore.state.item.mappingToSF}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateEventTypeSfMapDto>('mappingToSF'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewEventTypeMapView);
