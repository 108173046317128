import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import React from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import {
  getNextStatus,
  hasAdministrationRights,
  isUserAnEventActor,
  hasUserAlreadyPerformedValidation,
  isCurrentUserEventInstructor,
  isUserEventOwner,
  isUserAnEventActorWithValidations
} from 'utils/event-utils';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { resolve } from 'inversify-react';
import { IdentityService, Roles } from 'services/identity-service';
import ChangeStatusPopup from '../popups/change-status-popup/change-status-popup';
import { isGEA, isGMA } from 'utils/event-type-utils';
import { Modal } from 'semantic-ui-react';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { ChangeEventInstructorDto } from 'stores/events/events-store';
import { EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';

export interface CloseButtonProps extends WithTranslation {
  onClosed?: () => void;
  eventFormStore?: EventFormStore;
  readOnly: boolean;
}

export interface CloseButtonState {
  showChangeStatusModal: boolean;
  showAlertCloseModal: boolean;
}

@connect(['eventFormStore', EventFormStore])
class CloseButton extends Component<CloseButtonProps, CloseButtonState> {
  state: CloseButtonState = {
    showChangeStatusModal: false,
    showAlertCloseModal: false
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private handleOnCloseClicked = async () => {
    const { eventFormStore, onClosed, t } = this.props;
    if (isGEA(eventFormStore.state.item.eventType) || isGMA(eventFormStore.state.item.eventType)) {
      const result = await eventFormStore.validateSupprotDetailsClose();
      if (!result.isSuccess) {
        (result?.messages || []).forEach(msg => ToastComponent({ text: msg?.body, type: 'warning-toast' }));
        return;
      }
    }

    if (this.identityService.isInRole(Roles.Admin) || isUserEventOwner(eventFormStore.state.item)) {
      this.setState({ showChangeStatusModal: true });
      return;
    }
    const items: ChangeEventInstructorDto[] = [];

    if (this.identityService.isInRole(Roles.Instructor)) {
      if (!this.instructorCanClosedEvent()) {
        this.setState({ showAlertCloseModal: true });
        return;
      }
      const instructorInstances = eventFormStore.state.item.instructors.filter(({ user: { id } }) => id === this.identityService.userId);
      const wrongWDsWHs = (instructorInstances || []).some(({ workingDays: WD, workingHours: WH }, i) => !WD || !WH || WD <= 0 || WH <= 0);
      const isTypeVactions = EventTypeCategory[eventFormStore.state.item.eventType.eventTypeCategory] === EventTypeCategory.Vacations;

      if (!isTypeVactions && wrongWDsWHs) {
        eventFormStore.changeMessages([{ body: t(`Invalid Working Days or Working Hours inputs`), level: 'Error' }]);
        return;
      }

      (instructorInstances || []).forEach(
        ({ location: { id: locationId }, workingDays, workingHours, period, role, user: { id: instructorId }, travelDays, noTravel }) => {
          items.push({ instructorId, locationId, period, role, workingDays, workingHours, travelDays, noTravel });
        }
      );
    }

    if (items.length <= 0) {
      ToastComponent({ text: 'Event cannot be closed.', type: 'warning-toast' });
      return;
    } else {
      const response = await eventFormStore.closeEvent(items, this.identityService.userId);
      if (response?.isSuccess) {
        ToastComponent({ text: t('Event successfully closed!'), type: 'success-toast' });
        onClosed && onClosed();
      } else {
        (eventFormStore.state.result?.messages || []).forEach(msg => ToastComponent({ text: msg?.body, type: 'warning-toast' }));
      }
    }
  };

  private handleOnChangeStatusAcceptClicked = async () => {
    const { eventFormStore, t, onClosed } = this.props;
    this.setState({ showChangeStatusModal: false });

    const response = await eventFormStore.nextStatus();
    if (response?.isSuccess) ToastComponent({ text: t('Event successfully closed!'), type: 'success-toast' });
    else (eventFormStore.state.result?.messages || []).forEach(msg => ToastComponent({ text: msg?.body, type: 'warning-toast' }));
    onClosed && onClosed();
  };

  private isCloseButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    const { eventFormStore } = this.props;

    const userCompliance =
      this.identityService.isInRole(Roles.Admin) ||
      isUserEventOwner(eventFormStore.state.item) ||
      this.identityService.isInRole(Roles.Instructor);

    const eventCompliance =
      this.isCloseEventAllowedInCurrentEventState() &&
      (hasAdministrationRights(event) || (isUserAnEventActorWithValidations(event) && !hasUserAlreadyPerformedValidation(event))) &&
      !IdentityService.isWorker(this.identityService.getUserInfo());

    return userCompliance && eventCompliance;
  };

  private isClosedButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      this.isCloseEventAllowedInCurrentEventState() &&
      isUserAnEventActor(event) &&
      hasUserAlreadyPerformedValidation(event) &&
      !IdentityService.isWorker(this.identityService.getUserInfo())
    );
  };

  private instructorCanClosedEvent = (): boolean => {
    const event = this.props.eventFormStore.state.item;

    if (isCurrentUserEventInstructor(event) && event.allTheoreticalFormAnswered != null && !event.allTheoreticalFormAnswered) return false;
    else return true;
  };

  private closeConfirmModal = () => {
    this.setState({ showAlertCloseModal: false });
  };

  private isCloseEventAllowedInCurrentEventState = (): boolean => {
    const event = this.props.eventFormStore.state.item;

    const nextStatus = getNextStatus(event);
    return nextStatus && nextStatus.toString() === 'Closed';
  };

  render() {
    const { t, readOnly, eventFormStore } = this.props;
    const isCloseButtonVisible = this.isCloseButtonVisible();
    const isClosedButtonVisible = this.isClosedButtonVisible();
    const { showAlertCloseModal } = this.state;

    return (
      <>
        {isCloseButtonVisible && (
          <Button
            className="form__actions__button"
            disabled={!readOnly}
            positive
            content={t('Close Event')}
            onClick={this.handleOnCloseClicked}
          />
        )}
        {isClosedButtonVisible && (
          <Button className="form__actions__button" disabled positive content={t('Closed Event')} onClick={this.handleOnCloseClicked} />
        )}
        <ChangeStatusPopup
          eventFormStore={eventFormStore}
          open={this.state.showChangeStatusModal}
          onAcceptClicked={this.handleOnChangeStatusAcceptClicked}
          onCancelClicked={() => this.setState({ showChangeStatusModal: false })}
        />
        {showAlertCloseModal && (
          <Modal
            style={{ transform: 'translateY(35vh)' }}
            centered
            open={showAlertCloseModal}
            size="mini"
            className="have-warnings-popup feedback__confirm-modal"
            closeOnEscape={true}
            onClose={this.closeConfirmModal}
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{t(`All Theoretical tests must be completed before closing the event.`)}</p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="feedback-modal__cancel-button" content={t('Accept')} onClick={this.closeConfirmModal} />
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(CloseButton);
