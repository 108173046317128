import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface LocationDto extends BaseDto {
  id: string; //Guid
  location: string;
  code: string;
  countryId: string; //Guid
  countryName: string;
  isoCode: string;
  active: boolean;
  region: string;
  regionId: string;
}

export interface CreateLocationDto {
  location: string;
  code: string;
  countryId: string; //Guid
  active: boolean;
  regionId: string;
}

export interface ChangeLocationDto {
  id: string; //Guid
  location: string;
  code: string;
  countryId: string;
  active: boolean;
  regionId: string;
}

export class CreateLocationValidator extends AbstractValidator<CreateLocationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.code)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Location code is required'));

    this.validateIfString(o => o.location)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Location name is required'));

    this.validateIfString(o => o.countryId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Country is required'));

    this.validateIfString(o => o.regionId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Region is required'));
  }
}

export class ChangeLocationValidator extends AbstractValidator<ChangeLocationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIfString(o => o.code)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Location code is required'));

    this.validateIfString(o => o.location)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Location name is required'));

    this.validateIf(o => o.active)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Active field is required'));

    this.validateIfString(o => o.regionId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Region is required'));
  }
}

@repository('@@LOCATIONS', 'locations.summary')
export class LocationsStore extends DataStore<LocationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-location';
  retrievePath = 'get-locations';
  updatePath = 'update-location';
  deletePath = 'delete-location';
  retrieveOnePath = 'get-location';

  protected validate(item: LocationDto) {
    return new ChangeLocationValidator().validate(item);
  }

  constructor() {
    super('LOCATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getUserById(id: string): Promise<LocationDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<LocationDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

//
@repository('@@LOCATIONS', 'dropdownlocations.summary')
export class DropDownLocationsStore extends DataStore<LocationDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-locations';
  updatePath = '';
  deletePath = '';

  protected validate(item: LocationDto) {
    return new ChangeLocationValidator().validate(item);
  }

  constructor() {
    super('LOCATIONDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, locationIds?: string[], search?: string): Promise<QueryResult<LocationDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let locatiosDto = this.state.items.map(({ item }) => item);

      if ((locationIds || []).length > 0) {
        locatiosDto = locatiosDto.filter(x => locationIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        locatiosDto = locatiosDto.filter(x => x.location.startsWith(search));
      }

      let locationsResult = { items: locatiosDto, count: locatiosDto.length } as QueryResult<LocationDto>;
      return new Promise<QueryResult<LocationDto>>(resolve => resolve(locationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<LocationDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<LocationDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@LOCATIONS', 'locations.new')
export class NewLocationStore extends FormStore<CreateLocationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-location';
  retrievePath = 'get-locations';
  updatePath = 'update-location';

  protected validate(item: CreateLocationDto) {
    return new CreateLocationValidator().validate(item);
  }

  constructor() {
    super('NEW_LOCATION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@LOCATIONS', 'locations.change')
export class ChangeLocationStore extends FormStore<ChangeLocationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-location';
  retrievePath = 'get-locations';
  updatePath = 'update-location';

  protected validate(item: ChangeLocationDto) {
    return new ChangeLocationValidator().validate(item);
  }

  constructor() {
    super('CHANGE_LOCATION', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
