import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { FeedbackQuestionBanksStore, FeedbackQuestionBankDto } from 'stores/assessments/questionBank/feedback-questionBank-store';

interface ChooseFeedbackQuestionsViewProps extends WithTranslation {
  onAddFeedbackQuestions: (questions: FeedbackQuestionBankDto[]) => void;
  onCloseModal?: () => void;
  alreadySelectedQuestions: string[];
  feedbackQuestionsStore?: FeedbackQuestionBanksStore;
}

interface ChooseFeedbackQuestionsFilters {
  questionTitleOrId: string;
}

interface ChooseFeedbackQuestionsViewState {
  query: Query;
  selectedQuestions: FeedbackQuestionBankDto[];
  filters: ChooseFeedbackQuestionsFilters;
}

@connect(['feedbackQuestionsStore', FeedbackQuestionBanksStore])
class ChooseFeedbackQuestionsView extends Component<ChooseFeedbackQuestionsViewProps, ChooseFeedbackQuestionsViewState> {
  constructor(props: ChooseFeedbackQuestionsViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      selectedQuestions: null,
      filters: {
        questionTitleOrId: undefined
      }
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  @autobind
  private onAddFeedbackQuestions() {
    this.props.onAddFeedbackQuestions(this.state.selectedQuestions);

    this.props.onCloseModal();
  }

  @autobind
  private onCancel() {
    this.props.onCloseModal();
  }

  @autobind
  private load() {
    this.props.feedbackQuestionsStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  }

  private handleFilterByQuestionTitleOrId(value: string) {
    let filters = { ...this.state.filters };
    filters.questionTitleOrId = value;
    this.refreshTable(filters);
  }

  private selectedSSAQuestions(items: unknown[]): void {
    this.setState({ selectedQuestions: items as any });
  }

  private refreshTable(filters: ChooseFeedbackQuestionsFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChooseFeedbackQuestionsFilters) {
    const oDataFilters = [];
    const { alreadySelectedQuestions } = this.props;

    if (alreadySelectedQuestions && alreadySelectedQuestions.length > 0)
      oDataFilters.push(`not(Id in (${alreadySelectedQuestions.join(',')}))`);

    if (!isNullOrWhiteSpaces(filters.questionTitleOrId)) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QF');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QF')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    return oDataFilters;
  }

  public render() {
    const { t } = this.props as any;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<FeedbackQuestionBankDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.text}</div>,
          selectableHeader: true
        },
        {
          title: t('Subtitle'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.subtitle}</div>,
          selectableHeader: true
        }
      ],
      data: this.props.feedbackQuestionsStore.state
    } as TableModel<FeedbackQuestionBankDto>;

    return (
      <Modal className="template-search__modal" open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={(event, data) => this.handleFilterByQuestionTitleOrId(data.value)}
            />
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={questions => this.selectedSSAQuestions(questions)}
              onPageChange={this.handlePageChange}
              //onFilterChange={this.handleFilterChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddFeedbackQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseFeedbackQuestionsView);
