import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeMachineModelDto,
  ChangeMachineModelStore,
  MachineModelDto
} from '../../../../../stores/configuration/machinery/machine-models-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import EquipmentTypeEditor, { EquipmentTypeItemReference } from '../../../../../widgets/bussiness/equipment-type-editor';
import MRClusterEditor from '../../../../../widgets/bussiness/mrcluster-editor';
import OemEditor from '../../../../../widgets/bussiness/oem-editor';

interface ChangeMachineModelViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeMachineModel?: ChangeMachineModelStore;
  currentMM: MachineModelDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeMachineModelViewState {
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changeMachineModel', ChangeMachineModelStore])
class ChangeMachineModelView extends React.Component<ChangeMachineModelViewProps, ChangeMachineModelViewState> {
  private get changeMachineModelStore() {
    return this.props.changeMachineModel;
  }

  constructor(props: ChangeMachineModelViewProps) {
    super(props);

    this.state = {
      cluster: { id: this.props.currentMM.clusterId, title: this.props.currentMM.clusterName },
      equipmentType: { id: this.props.currentMM.equipmentTypeId, title: this.props.currentMM.equipmentTypeName },
      oem: { id: this.props.currentMM.oemId, title: this.props.currentMM.oemName },
      userClickedSaved: false
    };

    this.changeMachineModelStore.state.result = null;

    this.changeMachineModelStore.change({
      id: this.props.currentMM.id,
      name: this.props.currentMM.name,
      clusterId: this.props.currentMM.clusterId,
      equipmentTypeId: this.props.currentMM.equipmentTypeId,
      oemId: this.props.currentMM.oemId,
      description: this.props.currentMM.description,
      active: this.props.currentMM.active
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeMachineModelViewProps) {
    if (this.changeMachineModelStore.state.result && this.changeMachineModelStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeMachineModelStore.update();
  }

  private updateDescription(equipmentType: any, oem: any) {
    this.changeMachineModelStore.state.item.description = (equipmentType ? equipmentType.title : '') + ' ' + (oem ? oem.title : '');
  }

  @autobind
  private onCancelChangeItem() {
    this.changeMachineModelStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeMachineModelStore.change({ ...this.changeMachineModelStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeMachineModelStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Machine Model')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeMachineModelStore.state.result && !this.changeMachineModelStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeMachineModelStore.state.result.messages.map(o => {
                  return o.body === 'Machine Model already exists with the same name but different EQ Type-OEM relation.'
                    ? 'Machine Model already exists with the same name and same EQ Type-OEM relation.'
                    : o.body;
                })}
              />
            )}
            {this.changeMachineModelStore.state.item && (
              <Form>
                <Form.Field required width={8} error={!this.changeMachineModelStore.state.item.clusterId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      nullable
                      value={this.state.cluster}
                      onChange={c => {
                        this.changeMachineModelStore.state.item.clusterId = c ? c.id : null;
                        this.setState({ cluster: c, equipmentType: undefined, userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field
                  required
                  width={8}
                  error={!this.changeMachineModelStore.state.item.equipmentTypeId && this.state.userClickedSaved}
                >
                  <div className={`required field`}>
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      nullable
                      clusterId={this.state.cluster ? this.state.cluster.id : undefined}
                      value={this.state.equipmentType}
                      onChange={(c: EquipmentTypeItemReference) => {
                        this.changeMachineModelStore.state.item.equipmentTypeId = c ? c.id : null;
                        if (!this.state.cluster) {
                          this.setState({ cluster: c.cluster });
                          this.changeMachineModelStore.state.item.clusterId = c.cluster.id;
                        }
                        this.setState({ equipmentType: c, userClickedSaved: false });
                        this.updateDescription(c, this.state.oem);
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field required width={8} error={!this.changeMachineModelStore.state.item.oemId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('OEM')}</label>
                    <OemEditor
                      nullable
                      value={this.state.oem}
                      onChange={c => {
                        this.changeMachineModelStore.state.item.oemId = c ? c.id : null;
                        this.setState({ oem: c, userClickedSaved: false });
                        this.updateDescription(this.state.equipmentType, c);
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field width={8} error={!this.changeMachineModelStore.state.item.name && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('Machine Model')}
                    placeholder={t('Machine Model')}
                    value={this.changeMachineModelStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeMachineModelDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field width={8} error={!this.changeMachineModelStore.state.item.description && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('Description')}
                    placeholder={t('Description')}
                    value={this.changeMachineModelStore.state.item.description}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeMachineModelDto>('description'), value)}
                  />
                </Form.Field>

                <Form.Checkbox
                  toggle
                  label={t('Active')}
                  checked={this.changeMachineModelStore.state.item.active}
                  placeholder={t('Active')}
                  onChange={(e, { checked }) => this.handleValue(nameof<ChangeMachineModelDto>('active'), checked)}
                />
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeMachineModelView);
