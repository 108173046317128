import React from 'react';
import { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { ChangeRequestStore, RequestStatus, RequestDto } from '../../../../../stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { replaceAll } from 'utils/useful-functions';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

interface SendRequestButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestCreatorRoles: string[];
  changeRequestStore?: ChangeRequestStore;
  readOnly: boolean;
  onBeginSend?: () => void;
  onSuccess?: (request: RequestDto) => void;
  onError?: () => void;
}

@connect(['changeRequestStore', ChangeRequestStore])
class SendRequestButton extends Component<SendRequestButtonProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnClick() {
    if (this.props.onBeginSend) this.props.onBeginSend();
    this.props.changeRequestStore
      .changeStatus(this.props.requestId, RequestStatus.Pending)
      .then(x => {
        this.props.onSuccess && this.props.onSuccess(x);
        this.props.onSuccess && ToastComponent({ text: this.props.t('Send Request successfully!'), type: 'success-toast' });
      })
      .catch(() => {
        this.props.onError && this.props.onError();
        this.props.changeRequestStore.state.result.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'warning-toast' }));
      });
  }

  render() {
    const { t, readOnly } = this.props as any;
    return (
      this.isVisible() && (
        <Button
          className="form__actions__button form__next-status-accept-btn"
          disabled={!readOnly}
          onClick={() => this.handleOnClick()}
          positive
        >
          {t('Send')}
        </Button>
      )
    );
  }

  private isVisible(): boolean {
    return this.props.requestStatus.toString() === 'Draft' && this.currentUserHasPermission();
  }

  private currentUserHasPermission(): boolean {
    const userInfo = this.identityService.getUserInfo();

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    return (this.props.requestCreatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''));
  }
}

export default withTranslation()(SendRequestButton);
