import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import DeliveryTypesListPage from './delivery-types/delivery-types-list';
import TrainingLevelsListPage from './training-levels/training-levels-list';
import CustomizationDataListPage from './customization-data/customization-data-list';
import PrioritiesListPage from './priorities/priorities-list';
import RequestReasonListPage from './request-reasons/request-reasons-list';
import CheckListPage from './event-checklist/event-checklist-list';
import NmrHoursDurationListPage from './nmr-duration/nmr-hours-duration-list';
import MrHoursDurationListPage from './mr-duration/mr-hours-duration-list';
import queryString from 'query-string';
import RejectionReasons from './rejection-reasons/rejection-reasons';
import RejectedByList from './rejected-by/rejected-by-list';
import EventTypeGroupListPage from './event-type-group/event-group-list';
import TypeHoursCodeListPage from './type-hours-code/type-hours-code-list';
import EventGroupRelationshipsListPage from './event-group-relation/event-relation-list';

export interface EAndRConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface EAndRConfigurationHomeState {
  activeIndex: number;
}
const panes = [
  {
    menuItem: i18n.t('Training Levels'),
    render: () => (
      <Tab.Pane attached={false}>
        <TrainingLevelsListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Delivery Types'),
    render: () => (
      <Tab.Pane attached={false}>
        <DeliveryTypesListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Priorities'),
    render: () => (
      <Tab.Pane attached={false}>
        <PrioritiesListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Request Reasons'),
    render: () => (
      <Tab.Pane attached={false}>
        <RequestReasonListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Event Checklist'),
    render: () => (
      <Tab.Pane attached={false}>
        <CheckListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Customization'),
    render: () => (
      <Tab.Pane attached={false}>
        <CustomizationDataListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('NMR Hours Duration'),
    render: () => (
      <Tab.Pane attached={false}>
        <NmrHoursDurationListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('MR Hours Duration'),
    render: () => (
      <Tab.Pane attached={false}>
        <MrHoursDurationListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Rejection Reasons'),
    render: () => (
      <Tab.Pane attached={false}>
        <RejectionReasons />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Rejected By'),
    render: () => (
      <Tab.Pane attached={false}>
        <RejectedByList />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Event Type Groups'),
    render: () => (
      <Tab.Pane attached={false}>
        <EventTypeGroupListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Type Hours Code'),
    render: () => (
      <Tab.Pane attached={false}>
        <TypeHoursCodeListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Event Group Relations'),
    render: () => (
      <Tab.Pane attached={false}>
        <EventGroupRelationshipsListPage />
      </Tab.Pane>
    )
  }
];
class EAndRConfigurationHomePage extends Component<EAndRConfigurationHomeProps, EAndRConfigurationHomeState> {
  private getActiveIndex = () => {
    let values = queryString.parse(this.props.location.search);
    if (values == null || values.tab == null) values = this.props.match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    const l = panes.length;
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
    return 0;
  };

  state: EAndRConfigurationHomeState = { activeIndex: this.getActiveIndex() };

  private onTabChange = (_, { activeIndex }) => {
    this.props.history.replace(`/configuration/events-n-requests?tab=${activeIndex}`);
    this.setState({ activeIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const menu = { secondary: true, pointing: true };

    return (
      <>
        <h3>{t('Events & Requests')}</h3>
        <Tab className="main-tab" activeIndex={activeIndex} onTabChange={this.onTabChange} menu={menu} panes={panes} />
      </>
    );
  }
}

export default withTranslation()(EAndRConfigurationHomePage);
