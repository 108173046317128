import { Toast } from '@planit/bryntum-scheduler';

type toastCls = 'warning-toast' | 'success-toast' | 'information-toast' | 'error-toast';

interface toastProps {
  text: string;
  type: toastCls;
}

export const ToastComponent = (props: toastProps) => {
  let html = '';
  if (props.type === 'warning-toast') html = '<i class="b-fa b-fa-exclamation-triangle"></i>' + props.text;
  if (props.type === 'information-toast') html = '<i class="b-fa b-fa-info-circle"></i>' + props.text;
  if (props.type === 'success-toast') html = '<i class="b-fa b-fa-check-circle"></i>' + props.text;
  if (props.type === 'error-toast') html = '<i class="b-fa b-fa-info-circle"></i>' + props.text;
  return Toast.show({ html, cls: props.type, showProgress: true });
};
