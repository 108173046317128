import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface TtcLocationDto extends BaseDto {
  id: string; //Guid
  location: string;
  code: string;
  countryId: string; //Guid
  countryName: string;
  isoCode: string;
  active: boolean;
  region: string;
  regionId: string;
}

export class ChangeTtcLocationValidator extends AbstractValidator<TtcLocationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Ttc Location Id is required'));

    this.validateIfString(o => o.code)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Ttc Location code is required'));

    this.validateIfString(o => o.location)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Ttc Location name is required'));

    this.validateIf(o => o.active)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Active field is required'));

    this.validateIfString(o => o.regionId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Region is required'));
  }
}

@repository('@@TTCLOCATIONS', 'dropdownTtcLocations.summary')
export class DropDownTtcLocationsStore extends DataStore<TtcLocationDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-ttc-locations';
  updatePath = '';
  deletePath = '';

  protected validate(item: TtcLocationDto) {
    return new ChangeTtcLocationValidator().validate(item);
  }

  constructor() {
    super('TTCLOCATIONDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, ttcLocationIds?: string[], search?: string): Promise<QueryResult<TtcLocationDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let locatiosDto = this.state.items.map(({ item }) => item);

      if ((ttcLocationIds || []).length > 0) {
        locatiosDto = locatiosDto.filter(x => ttcLocationIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        locatiosDto = locatiosDto.filter(x => x.location.startsWith(search));
      }

      let ttcLocationsResult = { items: locatiosDto, count: locatiosDto.length } as QueryResult<TtcLocationDto>;
      return new Promise<QueryResult<TtcLocationDto>>(resolve => resolve(ttcLocationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<TtcLocationDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<TtcLocationDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}
