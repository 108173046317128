import { UserDto } from 'stores/users/users-store';
import { Component, Fragment } from 'react';
import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import UserSummaryImage from './user-summary-image';
import { CalculateSeniority, GetLocationName } from 'utils/userinfo-functions';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

export interface UserSummaryProps extends WithTranslation {
  user: UserDto;
}

class UserSummary extends Component<UserSummaryProps> {
  render() {
    const { t, user } = this.props;
    const seniority = this.props.user && CalculateSeniority(this.props.user.hireDate);
    const location = this.props.user && GetLocationName(this.props.user);
    const languages = this.props.user && this.getLanguagesList(this.props.user);

    return (
      <Grid className="user-card-box min-height-box">
        <Grid.Row columns={3}>
          <Grid.Column width={4} verticalAlign="top" textAlign="center">
            <UserSummaryImage user={user} />
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Name')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <TableTooltipCell textToShow={user != null ? `${user.firstName} ${user.lastName}` : ''} />
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('SAP ID')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user != null && user.employeeId ? user.employeeId : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Position Code')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user != null && user.positionCode != null ? user.positionCode.description : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Email')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <TableTooltipCell newClassFromComponent="bold-text" textToShow={user != null && user.email ? user.email : '-'} />
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Line Manager')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user != null && user.lineManager ? user.lineManager : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Instructor Role')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">
                {user != null && user.instructorRole != null && user.instructorRole.name ? user.instructorRole.name : '-'}
              </span>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row className="rows-user-card-box">
              <span>{t('User Name')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user != null ? user.userName : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Location')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{location ? location : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('SF Position')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user != null && user.sfPosition ? user.sfPosition : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Seniority')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{seniority ? seniority : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Languages')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">{languages ? languages : '-'}</Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  private getLanguagesList = (data: UserDto) => {
    return (
      <List>
        {(data.languages || []).map((language, index) => {
          if (language.languageCode)
            return (
              <Fragment key={language.id + '_' + index}>
                <List.Item key={`${data.id}-${language.id}-${language.languageCode}`}>
                  <span className="bold-text">
                    {language.languageCode} - {language.language}
                  </span>
                </List.Item>
              </Fragment>
            );
          return <></>;
        })}
      </List>
    );
  };
}

export default withTranslation()(UserSummary);
