import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { LocationDto, DropDownLocationsStore } from '../../stores/configuration/locations/locations-store';
import { nameof } from '../../utils/object';
import { Dropdown } from 'semantic-ui-react';
import { OrderDefinition } from 'stores/dataStore';

interface LocationEditorWithRoleLocationsProps extends WithTranslation {
  value: string;
  onChange: (value: LocationDto) => void;
  placeholder?: string;
  locationsStore?: DropDownLocationsStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  locationsReceived?: string[];
  clearable?: boolean;
  style?: React.CSSProperties;
  className?: string;
  showCountry?: boolean;
  skipDefaultClass?: boolean;
  customPlaceholder?: string;
  extraLocationOptions?: any[];
  skipAutoSelectFirstOption?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface LocationEditorWithRoleLocationsState {
  value: string;
  availableLocations: { [id: string]: LocationDto };
  locationOptions: { text: string; value: string }[];
  isSelected: boolean;
  isLoading: boolean;
}

@connect(['locationsStore', DropDownLocationsStore])
class LocationEditorWithRoleLocations extends PureComponent<LocationEditorWithRoleLocationsProps, LocationEditorWithRoleLocationsState> {
  state: LocationEditorWithRoleLocationsState = {
    value: this.props.value,
    availableLocations: {},
    locationOptions: [],
    isSelected: false,
    isLoading: false
  };

  componentDidMount() {}

  componentDidUpdate({ value: prevValue }: LocationEditorWithRoleLocationsProps) {
    const { value } = this.props;
    if (value !== prevValue) this.setState({ value }, this.initLocations);
  }

  initLocations = async () => {
    this.setState({ isLoading: true });
    const { locationsReceived, locationsStore } = this.props;
    let filter;
    if ((locationsReceived || []).length > 0) filter = [{ id: { in: { type: 'guid', value: locationsReceived } } }];
    else filter = [];

    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }];
    const locations = await locationsStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }, locationsReceived);
    const { availableLocations, locationOptions } = this.setAvailableLocations(locations?.items || []);
    this.setState({ availableLocations, locationOptions, isLoading: false });
  };

  setAvailableLocations = (locations: LocationDto[] = []) => {
    const { locationsReceived, extraLocationOptions } = this.props;
    let availableLocations: { [id: string]: LocationDto } = {};
    let locationOptions = [];
    if ((locationsReceived || []).length <= 0) {
      availableLocations = (locations || []).reduce((acc, curr) => {
        acc[curr?.id] = curr;
        return acc;
      }, {});
      locationOptions = (locations || []).map(({ location, id }) => ({ text: location, value: id }));
    } else
      locations.forEach(location => {
        const target = (locationsReceived || []).find(id => id === location.id);
        if (target) {
          availableLocations[target] = location;
          if (!this.props.showCountry || location.code === 'XXXX' || location.code === 'XXXXX' || !location.location) {
            locationOptions.push({ text: location.location, value: location.id });
          } else {
            locationOptions.push({ text: location.location + ' - ' + location.countryName, value: location.id });
          }
        }
      });
    if (!!extraLocationOptions && extraLocationOptions.length > 0) {
      let aux = [];
      extraLocationOptions.forEach(x => {
        aux.push(x);
        aux = aux.concat(locationOptions);
        locationOptions = aux;

        availableLocations[x.value] = {
          id: x.id,
          location: x.text,
          code: '',
          countryId: '',
          countryName: '',
          isoCode: '',
          active: true,
          region: '',
          regionId: '',
          createdOn: '',
          modifiedOn: '',
          modifiedByUserId: '',
          modifiedByUser: ''
        };
      });
    }
    return { availableLocations, locationOptions };
  };

  selectedLocation = (value: string) => {
    this.setState({ value, isSelected: !!value });
    const loc = this.state.availableLocations[value];
    //console.log(loc);
    this.props.onChange(loc);
  };

  onOpenDropDown = () => {
    this.initLocations();
  };

  public render() {
    const { t, className, style, locationsStore, skipDefaultClass, customPlaceholder, skipAutoSelectFirstOption } = this.props;
    const { value, isSelected, isLoading } = this.state;
    const { locationOptions } = this.setAvailableLocations((locationsStore.state.items || []).map(({ item }) => item) || []);

    return (
      <Dropdown
        search
        inline
        selection
        closeOnChange
        closeOnEscape
        clearable
        options={locationOptions}
        error={!value && isSelected}
        className={`${className || ''} planit-users-inputs planit-user-dropdown ${skipDefaultClass ? '' : 'planit-user-selector'}`}
        value={value ? value : null}
        onChange={(e, { value }) => this.selectedLocation(value as string)}
        placeholder={customPlaceholder ? t(customPlaceholder) : t('Select location')}
        style={style}
        selectOnBlur={!skipAutoSelectFirstOption}
        onOpen={this.onOpenDropDown}
        loading={isLoading}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

export default withTranslation()(LocationEditorWithRoleLocations);
