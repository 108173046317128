import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NewNMFunctionalSubareaStore,
  CreateNMFunctionalSubareaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { isNullOrWhiteSpaces, formStoreHandler } from 'utils/useful-functions';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { ItemReference } from 'stores/dataStore';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Categories } from 'stores/requests/requests-store';

interface NewNMFunctionalSubareaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newNMFunctionalSubarea?: NewNMFunctionalSubareaStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewNMFunctionalSubareaViewState {
  userClickedSaved: boolean;
  trainingNameName: ItemReference;
  functionalArea: ItemReference;
  cluster: ItemReference;
  category: Categories;
}

@connect(['newNMFunctionalSubarea', NewNMFunctionalSubareaStore])
class NewNMFunctionalSubareaView extends React.Component<NewNMFunctionalSubareaViewProps, NewNMFunctionalSubareaViewState> {
  constructor(props: NewNMFunctionalSubareaViewProps) {
    super(props);
    this.props.newNMFunctionalSubarea.createNew({ name: '', trainingNameId: '' });
    this.state = {
      category: Categories.Technical,
      trainingNameName: null,
      functionalArea: null,
      cluster: null,
      userClickedSaved: false
    };
  }

  private onCreateNewItem = async () => {
    this.setState({ userClickedSaved: true });
    const result = await this.props.newNMFunctionalSubarea.submit();
    result?.isSuccess && this.props.onClose(true);
  };

  private onCancelNewItem = () => {
    this.props.newNMFunctionalSubarea.clear();
    this.props.onClose(false);
  };

  categoryChangeHandler = (category: Categories) => {
    this.setState({ category, cluster: null, functionalArea: null, trainingNameName: null });
  };

  public render() {
    const { t, newNMFunctionalSubarea } = this.props;
    const { category, cluster, functionalArea, trainingNameName, userClickedSaved } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={newNMFunctionalSubarea.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Functional Subarea')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {newNMFunctionalSubarea.state.result && !newNMFunctionalSubarea.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={newNMFunctionalSubarea.state.result.messages.map(o => o.body)}
              />
            )}
            {newNMFunctionalSubarea.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!newNMFunctionalSubarea.state.item.name && userClickedSaved}
                    label={t('Functional Subarea')}
                    placeholder={t('Name')}
                    value={newNMFunctionalSubarea.state.item.name}
                    onChange={(e, { value }) => formStoreHandler<CreateNMFunctionalSubareaDto>(newNMFunctionalSubarea, 'name', value)}
                  />
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        placeholder={t('Cluster')}
                        nullable
                        category={category}
                        value={cluster}
                        onChange={cluster => this.setState({ cluster, functionalArea: undefined })}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Functional Area')}</label>
                      <NMFunctionalAreaEditor
                        placeholder={t('Functional Area')}
                        nullable
                        readonly={!cluster}
                        clusterId={cluster?.id}
                        value={functionalArea}
                        onChange={functionalArea => this.setState({ functionalArea })}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div
                      className={`required ${
                        (newNMFunctionalSubarea.state.item.trainingNameId == null ||
                          isNullOrWhiteSpaces(newNMFunctionalSubarea.state.item.trainingNameId)) &&
                        userClickedSaved
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Training Name')}</label>
                      <NMTrainingNameEditor
                        placeholder={t('Training Name')}
                        nullable
                        readOnly={!functionalArea}
                        funcionalAreaId={functionalArea?.id || undefined}
                        value={trainingNameName}
                        onChange={c => formStoreHandler<CreateNMFunctionalSubareaDto>(newNMFunctionalSubarea, 'trainingNameId', c?.id)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <div className="custom-actions">
          <Form.Field>
            <label>{t('Category')}</label>
            <CategoryFormEditor
              placeholder={t('Filter by Category')}
              className="form__header__dropdown-style"
              value={category}
              onChange={this.categoryChangeHandler}
            />
          </Form.Field>
          <Modal.Actions>
            <Button onClick={this.onCancelNewItem} basic>
              {t('Cancel')}
            </Button>
            <Button onClick={this.onCreateNewItem} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(NewNMFunctionalSubareaView);
