import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface RejectReasonDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateRejectReasonDto {
  name: string;
}

export interface ChangeRejectReasonDto {
  id: string;
  name: string;
}

export class CreateRejectReasonsValidator extends AbstractValidator<CreateRejectReasonDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Rejection Name is required'));
  }
}

export class ChangeRejectReasonsValidator extends AbstractValidator<ChangeRejectReasonDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Rejection Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Rejection Name is required'));
  }
}

@repository('@@REJECT_REASONS', 'reject-reasons.summary')
export class RejectReasonsStore extends DataStore<RejectReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejection-reason';
  retrievePath = 'get-rejection-reasons';
  updatePath = 'update-rejection-reason';
  deletePath = 'delete-rejection-reason';

  protected validate(item: RejectReasonDto) {
    return new ChangeRejectReasonsValidator().validate(item);
  }

  constructor() {
    super('REJECT_REASON', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@REJECT_REASONS', 'reject-reasons.new')
export class NewRejectReasonsStore extends FormStore<CreateRejectReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejection-reason';
  retrievePath = 'get-rejection-reasons';
  updatePath = 'update-rejection-reason';

  protected validate(item: CreateRejectReasonDto) {
    return new CreateRejectReasonsValidator().validate(item);
  }

  constructor() {
    super('NEW_REJECT_REASON', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@REJECT_REASONS', 'reject-reasons.change')
export class ChangeRejectReasonsStore extends FormStore<ChangeRejectReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejection-reason';
  retrievePath = 'get-rejection-reasons';
  updatePath = 'update-rejection-reason';

  protected validate(item: ChangeRejectReasonDto) {
    return new ChangeRejectReasonsValidator().validate(item);
  }

  constructor() {
    super('CHANGE_REJECT_REASON', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
