import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message, Icon, Dropdown, Input } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
import {
  NewFeedbackQuestionBankStore,
  CreateFeedbackQuestionBankDto,
  FeedbackQuestionBankDto,
  CreateFeedbackQuestionDto,
  FeedbackQuestionDto
} from 'stores/assessments/questionBank/feedback-questionBank-store';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';
import { nameof } from 'utils/object';
import { Query } from 'stores/dataStore';
import i18n from 'i18n';

interface NewFeedbackQuestionBankModalViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newQuestionBank?: NewFeedbackQuestionBankStore;
  languagesStore?: DropdownLanguagesStore;
  isClone?: boolean;
  cloneData: FeedbackQuestionBankDto;
}

interface NewFeedbackQuestionBankModalViewState {
  userClickedSave: boolean;
  questionTranslations: CreateFeedbackQuestionDto[];
  defaultLanguage: LanguageDto;
  query: Query;
}

@connect(['newQuestionBank', NewFeedbackQuestionBankStore], ['languagesStore', DropdownLanguagesStore])
class NewFeedbackQuestionBankModalView extends React.Component<
  NewFeedbackQuestionBankModalViewProps,
  NewFeedbackQuestionBankModalViewState
> {
  private get newQuestionBankStore() {
    return this.props.newQuestionBank;
  }

  constructor(props: NewFeedbackQuestionBankModalViewProps) {
    super(props);
    let query: Query = {
      searchQuery: '',
      orderBy: [],
      skip: 0,
      take: 1000,
      filter: [{ or: [{ LanguageCode: 'EN-en' }, { Language: 'English' }] }]
    };

    this.newQuestionBankStore.createNew({
      isMandatory: this.props.isClone && this.props.cloneData ? this.props.cloneData.isMandatory : null,
      answerType: this.props.isClone && this.props.cloneData ? this.props.cloneData.answerType : null,
      question:
        this.props.isClone && this.props.cloneData
          ? {
              text: this.props.cloneData.question.text,
              languageId: this.props.cloneData.question.language.id,
              subtitle: this.props.cloneData.question.subtitle
            }
          : null,
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : []
    });
    this.state = {
      query,
      userClickedSave: false,
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : [],
      defaultLanguage: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewFeedbackQuestionBankModalViewProps) {
    if (this.newQuestionBankStore.state.result && this.newQuestionBankStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  componentDidMount() {
    this.loadDefaulLanguage();
  }

  private loadDefaulLanguage() {
    this.props.languagesStore.getAllAsync(this.state.query).then(e => {
      this.setState({ defaultLanguage: e.items[0] });
    });
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
  }

  private mapToCreateQuestionDto(items: FeedbackQuestionDto[]): CreateFeedbackQuestionDto[] {
    let data: CreateFeedbackQuestionDto[] = [];
    items.forEach(i => {
      data.push({ text: i.text, subtitle: i.subtitle, languageId: i.language.id });
    });
    return data;
  }

  private onChangeQuestion(questionText: string) {
    let question = this.newQuestionBankStore.state.item.question;
    if (question) {
      question.text = questionText;
    } else {
      question = {
        text: questionText,
        subtitle: '',
        languageId: this.state.defaultLanguage ? this.state.defaultLanguage.id : null
      };
    }
    this.handleValue(nameof<CreateFeedbackQuestionBankDto>('question'), question);
  }

  private onChangeSubtitle(questionSubtitle: string) {
    let question = this.newQuestionBankStore.state.item.question;
    if (question) {
      question.subtitle = questionSubtitle;
    } else {
      question = {
        text: '',
        subtitle: questionSubtitle,
        languageId: this.state.defaultLanguage ? this.state.defaultLanguage.id : null
      };
    }
    this.handleValue(nameof<CreateFeedbackQuestionBankDto>('question'), question);
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newQuestionBankStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newQuestionBankStore.clear();
    this.props.onClose(false);
  }

  addQuestionsTranslation = () => {
    let question = this.state.questionTranslations;
    question.push({ text: '', subtitle: '', languageId: null });

    this.setState({ questionTranslations: question });
  };

  handleQuestionTransaltionTextChange = (index: number, questionText: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.text = questionText;

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateFeedbackQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  handleQuestionTransaltionSubtitleChange = (index: number, questionSubtitle: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.subtitle = questionSubtitle;

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateFeedbackQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  handleQuestionTransaltionLanguageChange = (index: number, languageId: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.languageId = languageId;
    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateFeedbackQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  removeDateRange = index => {
    let questionTranslations = this.state.questionTranslations;
    questionTranslations.splice(index, 1);

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateFeedbackQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  showQuestionTranslationRows = () => {
    return this.state.questionTranslations.map((question, index) => {
      return (
        <React.Fragment key={`${question.languageId}_${index}`}>
          <Form.Group className="feedback-question-bank__selctor-textarea">
            <Form.Field
              inline
              width={12}
              className="feedback-question-bank__label-textarea"
              error={this.state.userClickedSave && (!question.text || question.text.length === 0)}
            >
              <label className="first-label hidden">&nbsp;</label>
              <TextArea
                value={question ? question.text : ''}
                onChange={(e, { value }) => this.handleQuestionTransaltionTextChange(index, value.toString())}
              />
            </Form.Field>

            <Form.Field
              error={this.state.userClickedSave && (!question.languageId || question.languageId.length === 0)}
              inline
              width={3}
              className="feedback-question-bank__translation-lng"
            >
              <LanguageEditor
                className={'planit-user-dropdown-custom'}
                nullable
                value={question.languageId}
                onChange={c => {
                  this.handleQuestionTransaltionLanguageChange(index, c == null ? null : c.id);
                }}
                onlyForTests={true}
              />
            </Form.Field>

            <Form.Field inline width={1} className="feedback-question-bank__translation-remove">
              <Icon className="clickable-icon remove-icon" color="red" name="remove" onClick={() => this.removeDateRange(index)} />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field inline width={12} className="feedback-question-bank__label-textarea">
              <label className="first-label hidden">&nbsp;</label>
              <Input
                value={question ? question.subtitle : ''}
                onChange={(e, { value }) => this.handleQuestionTransaltionSubtitleChange(index, value.toString())}
              />
            </Form.Field>
          </Form.Group>
        </React.Fragment>
      );
    });
  };

  public render() {
    const { t } = this.props as any;
    const answerTypeOptions = [
      { key: 1, text: t('Yes/No'), value: 'YesOrNo' },
      { key: 2, text: t('Text'), value: 'Text' },
      { key: 3, text: t('Rating'), value: 'Rating' }
    ];
    const mandatoryOptions = [
      { key: 1, text: t('Yes'), value: true },
      { key: 2, text: t('No'), value: false }
    ];

    const modalTitle = this.props.isClone ? 'Clone Feedback Question' : 'New Feedback Question';
    const showErrors =
      this.newQuestionBankStore.state.result &&
      !this.newQuestionBankStore.state.result.isSuccess &&
      (this.newQuestionBankStore.state.result.messages || []).length !== 0;

    return (
      <Modal className="tna-question-bank__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newQuestionBankStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-squestion-bank__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {showErrors && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                hidden={!showErrors}
                onDismiss={this.newQuestionBankStore.clearMessages}
                error
                header={t('An error ocurred')}
                list={this.newQuestionBankStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newQuestionBankStore.state.item && (
              <Form>
                <div className="feedback-question-bank__wrapper scroll">
                  <Form.Group className="feedback-question-bank__selctor-group">
                    <Form.Field
                      width={9}
                      inline
                      required
                      error={this.state.userClickedSave && this.newQuestionBankStore.state.item.answerType == null}
                    >
                      <label className="first-label">{t('Answer Type')}</label>
                      <Dropdown
                        selection
                        inline
                        closeOnChange
                        closeOnEscape
                        closeOnBlur={true}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        multiple={false}
                        value={this.newQuestionBankStore.state.item.answerType}
                        className="planit-user-dropdown"
                        options={answerTypeOptions}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateFeedbackQuestionBankDto>('answerType'), value)}
                      />
                    </Form.Field>

                    <Form.Field
                      width={7}
                      inline
                      required
                      error={this.state.userClickedSave && this.newQuestionBankStore.state.item.isMandatory == null}
                    >
                      <label>{t('Mandatory')}</label>
                      <Dropdown
                        selection
                        inline
                        closeOnChange
                        closeOnEscape
                        closeOnBlur={true}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        multiple={false}
                        value={this.newQuestionBankStore.state.item.isMandatory}
                        className="planit-user-dropdown"
                        options={mandatoryOptions}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateFeedbackQuestionBankDto>('isMandatory'), !!value)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="feedback-question-bank__selctor-textarea">
                    <Form.Field
                      required
                      inline
                      width={12}
                      className="feedback-question-bank__label-textarea"
                      error={this.state.userClickedSave && !this.newQuestionBankStore.state.item.question}
                    >
                      <label className="first-label">{t('Question')}</label>
                      <TextArea
                        value={this.newQuestionBankStore.state.item.question ? this.newQuestionBankStore.state.item.question.text : ''}
                        onChange={(e, { value }) => this.onChangeQuestion(value.toString())}
                      />
                    </Form.Field>
                    <Button
                      className="custom-table-add-row-btn add-row-btn"
                      type="button"
                      content="Add Row"
                      onClick={this.addQuestionsTranslation}
                    >
                      {i18n.t('Add Translation')}
                    </Button>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field inline width={12} className="feedback-question-bank__label-textarea">
                      <label className="first-label">{t('Subtitle')}</label>
                      <Input
                        value={this.newQuestionBankStore.state.item.question ? this.newQuestionBankStore.state.item.question.subtitle : ''}
                        onChange={(e, { value }) => this.onChangeSubtitle(value.toString())}
                      />
                    </Form.Field>
                  </Form.Group>
                  {this.state.questionTranslations && this.state.questionTranslations.length > 0 && (
                    <>{this.showQuestionTranslationRows()}</>
                  )}
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-question-bank__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewFeedbackQuestionBankModalView);
