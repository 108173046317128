import { Provider } from 'inversify-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { container } from './inversify.config';

import App from './site/App';
import { Callback } from './widgets/auth/callback';
import { Logout } from './widgets/auth/logout';
import { LogoutCallback } from './widgets/auth/logout-callback';
import { SilentRenew } from './widgets/auth/silent-renew';
import { UnauthorizedView } from 'widgets/auth/unauthorized-view';
import WhiteScreenView from './site//white-screen-view';

export const routes = () => (
  <Provider container={container}>
    <Switch>
      <Route exact={true} path="/signin-oidc" component={Callback} />
      <Route exact={true} path="/logout" component={Logout} />
      <Route exact={true} path="/logout/callback" component={LogoutCallback} />
      <Route exact={true} path="/silentrenew" component={SilentRenew} />
      <Route exact={true} path="/unauthorized" component={UnauthorizedView} />
      <Route
        path="/"
        render={props => (
          <WhiteScreenView>
            <App {...(props as any)} />
          </WhiteScreenView>
        )}
      />
    </Switch>
  </Provider>
);
