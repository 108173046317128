import {
  RecurringTimeSpan,
  RecurringTimeSpansMixin,
  ResourceTimeRangeModel,
  ResourceTimeRangeStore,
  RecurringTimeSpans,
  GridFeatureManager
} from '@planit/bryntum-scheduler';

// export class MyTimeRange extends RecurringTimeSpan(TimeSpan) {
//   // WORKAROUND: This method override won't be needed after the next release
// }

// // Here we make a new store class extending Store with RecurringTimeSpansMixin mixin (that adds recurrence support to a store).
// // That class will use our new MyTimeRange model.
// // This store will contain time ranges.
// export class MyStore extends RecurringTimeSpansMixin(Store) {
//   static get defaultConfig() {
//     return {
//       modelClass: MyTimeRange,
//       storeId: "timeRanges"
//     };
//   }

//   construct(config) {
//     super.construct(config, true);
//     // setup recurrence support
//     this.setupRecurringTimeSpans();
//   }
// }
// Make a new model extending default ResourceTimeRangeModel w/ RecurringTimeSpan mixin
export class MyResourceTimeRange extends RecurringTimeSpan(ResourceTimeRangeModel) {}

// Make a new store extending default ResourceTimeRangeStore w/ RecurringTimeSpansMixin mixin
export class MyResourceTimeRangeStore extends RecurringTimeSpansMixin(ResourceTimeRangeStore) {
  static get defaultConfig() {
    return { modelClass: MyResourceTimeRange, id: 'resourceTimeRanges' };
  }

  construct(config) {
    super.construct(config, true);
    // setup recurrence support
    this.setupRecurringTimeSpans();
  }
}

class MyRecurringTimeSpans extends RecurringTimeSpans {
  static get $name() {
    return 'MyRecurringTimeSpans';
  }
}

// let's register the feature so scheduler was aware of it
GridFeatureManager.registerFeature(MyRecurringTimeSpans, false, 'Scheduler');
