import { ItemResult, SaSTokenInfoDto, AzureStorageUploadResult, FileInfo } from '../types';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import * as AzureStorageNpm from 'azure-storage';
import { DateTimeService } from 'services/datetime-service';

interface UserProfileAssignmentsUploaderState {
  isBusy: boolean;
  result: AzureStorageUploadResult;
}

@repository('@@USERPROFILEASSIGNMENTS', 'userprofileassignments.upload')
export class UploadUserProfileAssignmentsStore extends ReduxRepository<UserProfileAssignmentsUploaderState> {
  baseUrl = 'skills/v1';
  getSaSTokenUrl = 'get-user-profile-assignments-blob-sas-url';
  downloadTemplateUrl = 'get-user-profile-assignments-import-template';

  public SAS_TOKEN_OBTAINED = 'SAS_TOKEN_OBTAINED';
  public FILE_UPLOADED = 'FILE_UPLOADED';
  public TEMPLATE_DOWNLOADED = 'TEMPLATE_DOWNLOADED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.uploadFile.bind(this);
    this.onSaSTokenObtained.bind(this);
    this.onFileUploaded.bind(this);
    this.onTemplateDownloaded.bind(this);

    this.addReducer(this.SAS_TOKEN_OBTAINED, this.onSaSTokenObtained, 'AsyncAction');
    this.addReducer(this.FILE_UPLOADED, this.onFileUploaded, 'Simple');
    this.addReducer(this.TEMPLATE_DOWNLOADED, this.onTemplateDownloaded, 'AsyncAction');
  }

  public async uploadFile(fileInfo: FileInfo) {
    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getSaSTokenUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.SAS_TOKEN_OBTAINED, httpService.get<ItemResult<SaSTokenInfoDto>>(url, params), fileInfo);
  }

  private onSaSTokenObtained = (): AsyncAction<AxiosResponse<ItemResult<SaSTokenInfoDto>>, UserProfileAssignmentsUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: (result: AxiosResponse<ItemResult<SaSTokenInfoDto>>, fileInfo: FileInfo) => {
        let data = result.data.item;
        const blobService = AzureStorage.Blob.createBlobServiceWithSas(data.host, data.saSToken).withFilter(
          new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
        );
        const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;

        blobService.createBlockBlobFromBrowserFile(
          data.container,
          data.filePath,
          fileInfo.content,
          {
            blockSize: customBlockSize,
            metadata: {
              originalName: encodeURIComponent(fileInfo.fileName),
              userId: fileInfo.userId,
              userName: fileInfo.userName,
              timestamp: DateTimeService.toString(DateTimeService.now()),
              role: fileInfo.role
            }
          },
          (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
            const res = { error, response };
            this.dispatch(this.FILE_UPLOADED, res);
          }
        );
        return { ...this.state };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };

  protected onFileUploaded = (result: AzureStorageUploadResult): UserProfileAssignmentsUploaderState => {
    return { ...this.state, isBusy: false, result };
  };

  public async downloadTemplate() {
    const url = `${this.baseUrl}/${this.downloadTemplateUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, 'Employee Profile Assignments.xlsx'));
  }

  private onTemplateDownloaded = (): AsyncAction<AxiosResponse<any>, UserProfileAssignmentsUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };
}
