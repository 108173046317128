import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface MachineModelDto extends BaseDto {
  id: string;
  name: string;
  clusterId: string;
  clusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  description: string;
  active: boolean;
}

export interface CreateMachineModelDto {
  name: string;
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  description: string;
  active: boolean;
}

export interface ChangeMachineModelDto {
  id: string;
  name: string;
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  description: string;
  active: boolean;
}

export class CreateMachineModelValidator extends AbstractValidator<CreateMachineModelDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Model name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));
  }
}

export class ChangeMachineModelValidator extends AbstractValidator<ChangeMachineModelDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('PLC Type Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Model name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));
  }
}

@repository('@@MACHINE-MODELS', 'machineModels.summary')
export class MachineModelsStore extends DataStore<MachineModelDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-model';
  retrievePath = 'get-machine-models';
  updatePath = 'update-machine-model';
  deletePath = 'delete-machine-model';

  protected validate(item: MachineModelDto) {
    return new ChangeMachineModelValidator().validate(item);
  }

  constructor() {
    super('MACHINE_MODEL', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@MACHINE-MODELS', 'machineModels.dropdown')
export class DropDownMachineModelsStore extends DataStore<MachineModelDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = 'get-machine-models';
  updatePath = '';
  deletePath = '';

  protected validate(item: MachineModelDto) {
    return new ChangeMachineModelValidator().validate(item);
  }

  constructor() {
    super('MACHINE_MODELDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(
    query: Query,
    machineModelIds?: string[],
    search?: string,
    clusterId?: string,
    equipmentId?: string,
    oemId?: string
  ): Promise<QueryResult<MachineModelDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    if ((this.state.items || []).length > 0) {
      let machineModelsDto = this.state.items.map(({ item }) => item);

      if ((machineModelIds || []).length > 0) {
        machineModelsDto = machineModelsDto.filter(x => machineModelIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(clusterId)) {
        machineModelsDto = machineModelsDto.filter(x => x.clusterId === clusterId);
      }

      if (!isNullOrWhiteSpaces(equipmentId)) {
        machineModelsDto = machineModelsDto.filter(x => x.equipmentTypeId === equipmentId);
      }

      if (!isNullOrWhiteSpaces(oemId)) {
        machineModelsDto = machineModelsDto.filter(x => x.oemId === oemId);
      }

      if (!isNullOrWhiteSpaces(search)) {
        machineModelsDto = machineModelsDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: machineModelsDto, count: machineModelsDto.length } as QueryResult<MachineModelDto>;
      return new Promise<QueryResult<MachineModelDto>>(resolve => resolve(itemResult));
    } else {
      if (query.filter && query.filter instanceof Array && query.filter.length > 0) {
        const result = await httpService.get<QueryResult<MachineModelDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);
        return result.data;
      } else {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<MachineModelDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      }
    }
  }
}

@repository('@@MACHINE-MODELS', 'machineModels.new')
export class NewMachineModelStore extends FormStore<CreateMachineModelDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-model';
  retrievePath = 'get-machine-models';
  updatePath = 'update-machine-model';

  protected validate(item: CreateMachineModelDto) {
    return new CreateMachineModelValidator().validate(item);
  }

  constructor() {
    super('NEW_MACHINE_MODEL', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@MACHINE-MODELS', 'machineModels.change')
export class ChangeMachineModelStore extends FormStore<ChangeMachineModelDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-model';
  retrievePath = 'get-machine-models';
  updatePath = 'update-machine-model';

  protected validate(item: ChangeMachineModelDto) {
    return new ChangeMachineModelValidator().validate(item);
  }

  constructor() {
    super('CHANGE_MACHINE_MODEL', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
