import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dropdown, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/tnaSkill.less';
import { ChangeTnaSkillDto, ChangeTnaSkillStore } from 'stores/assessments/skills/tna-skills-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';

interface EditTnaSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeTnaSkill?: ChangeTnaSkillStore;
  currentTnaSkill: ChangeTnaSkillDto;
}

interface EditTnaSkillViewState {
  category: ItemReference;
}

@connect(['changeTnaSkill', ChangeTnaSkillStore])
class EditTnaSkillView extends React.Component<EditTnaSkillViewProps, EditTnaSkillViewState> {
  private get changeTnaSkillStore() {
    return this.props.changeTnaSkill;
  }

  constructor(props: EditTnaSkillViewProps) {
    super(props);
    this.changeTnaSkillStore.state.result = null;
    this.changeTnaSkillStore.change({
      id: this.props.currentTnaSkill.id,
      testCategoryId: this.props.currentTnaSkill.testCategoryId,
      description: this.props.currentTnaSkill.description,
      name: this.props.currentTnaSkill.name,
      isMachineRelated: this.props.currentTnaSkill.isMachineRelated
    });
    this.state = {
      category: { id: this.props.currentTnaSkill.testCategoryId, title: '' }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: EditTnaSkillViewProps) {
    if (this.changeTnaSkillStore.state.result && this.changeTnaSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeTnaSkillStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeTnaSkillStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeTnaSkillStore.change({ ...this.changeTnaSkillStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const isMaChineRelatedOptions = [
      { key: 1, text: t('Yes'), value: true },
      { key: 2, text: t('No'), value: false }
    ];

    return (
      <Modal className="tna-skill__modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeTnaSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-skill__modal-header">{t('Edit TNA Skill')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeTnaSkillStore.state.result && !this.changeTnaSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeTnaSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeTnaSkillStore.state.item && (
              <Form>
                <div className="tna-skill__wrapper">
                  <Form.Group className="tna-skill__selctor-group">
                    <Form.Field required className="tna-skill__label-input">
                      <label>{t('Category')}</label>
                      <TestCategorySelector
                        nullable
                        isTnaCategory={true}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Category')}
                        value={this.state.category}
                        onChange={category => this.handleValue(nameof<ChangeTnaSkillDto>('testCategoryId'), category?.id)}
                      />
                    </Form.Field>
                    <Form.Field required className="tna-skill__label-input">
                      <label>{t('Machine Related')}</label>
                      <Dropdown
                        selection
                        multiple={false}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Machine Related')}
                        options={isMaChineRelatedOptions}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeTnaSkillDto>('isMachineRelated'), value)}
                        value={this.changeTnaSkillStore.state.item.isMachineRelated}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="tna-skill__label-textarea"
                      error={this.changeTnaSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        className="tna-skill__custom-editorequest__textarea"
                        value={this.changeTnaSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeTnaSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="tna-skill__label-textarea"
                      error={this.changeTnaSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        className="tna-skill__custom-editorequest__textarea longArea"
                        value={this.changeTnaSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeTnaSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-skill__btns">
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EditTnaSkillView);
