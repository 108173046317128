import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import ProfessionRolesListPage from './profession-roles/profession-roles-list';
import PersonnelAreaListPage from './personnelArea/personnelArea-list';
import PositionCodeListPage from './positionCode/positionCode-list';
import PillarsListPage from './pillars/pillars-list';

export interface ProfilesConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface ProfilesConfigurationHomeState {
  activeTab: 'positionCodes' | 'pillar' | 'roles' | 'instructorRoles';
}

class ProfilesConfigurationHomePage extends React.Component<ProfilesConfigurationHomeProps, ProfilesConfigurationHomeState> {
  constructor(props: ProfilesConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'positionCodes'
    };
  }

  private panes = [
    {
      menuItem: i18n.t('Position Codes'),
      render: () => (
        <Tab.Pane attached={false}>
          <PositionCodeListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Personnel Areas'),
      render: () => (
        <Tab.Pane attached={false}>
          <PersonnelAreaListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Pillars'),
      render: () => (
        <Tab.Pane attached={false}>
          <PillarsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Roles'),
      render: () => (
        <Tab.Pane attached={false}>
          <ProfessionRolesListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Profiles')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(ProfilesConfigurationHomePage);
