import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './profile-definition-modal.less';
import { ChangeProfileItemStore, ProfileItemDto, CreateProfileItemDto, ProfileSkillDto } from 'stores/profile/profile-store';
import ProfileDefinitionModal, { ProfileValues } from './profile-definition-modal.component';
import { Message, Modal, Button } from 'semantic-ui-react';

interface EditProfileDefProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  currentProfile: ProfileItemDto;
  changeProfileStore: ChangeProfileItemStore;
  onFinish?: () => void;
}

interface EditProfileDefinitionState {
  profileValues: ProfileValues;
  userClickedSave: boolean;
  onConfirmToggleDisable: boolean;
  isChangedSkills: boolean;
}

class EditProfileDefinition extends Component<EditProfileDefProps, EditProfileDefinitionState> {
  state: EditProfileDefinitionState = {
    userClickedSave: false,
    profileValues: this.mapProfileItemToDetailedSkills(),
    onConfirmToggleDisable: false,
    isChangedSkills: false
  };

  private mapProfileItemToDetailedSkills(): ProfileValues {
    const initialValues = { ...this.props.currentProfile };
    delete initialValues.friendlyId;
    delete initialValues.professionName;
    this.props.changeProfileStore.createNew({ ...initialValues });
    return {
      ...initialValues,
      assessment: initialValues.assessment,
      main: null,
      substitute: null,
      skill: null,
      phase0: null,
      phase1: null,
      phase2: null,
      phase3: null,
      phase4: null
    };
  }
  private getDefaultProfileRowsValues() {
    return {
      skill: null,
      main: null,
      substitute: null,
      phase0: null,
      phase1: null,
      phase2: null,
      phase3: null,
      phase4: null
    };
  }

  sanitizedFinalObj = (frontValues: ProfileValues): CreateProfileItemDto => {
    delete frontValues.skill;
    delete frontValues.main;
    delete frontValues.substitute;
    delete frontValues.phase0;
    delete frontValues.phase1;
    delete frontValues.phase2;
    delete frontValues.phase3;
    delete frontValues.phase4;
    return frontValues;
  };

  private onCancelChangeItem = () => {
    this.props.changeProfileStore.clear();
    this.props.onClose(false);
  };

  addNewRowHandler = () => {
    const profileValuesCopy = { ...this.state.profileValues };
    const newRow: ProfileSkillDto = {
      main: profileValuesCopy.main,
      substitute: profileValuesCopy.substitute,
      assessment: profileValuesCopy.assessment,
      skillId: profileValuesCopy.skill.id,
      skillName: profileValuesCopy.skill.title,
      phase0: profileValuesCopy.phase0,
      phase1: profileValuesCopy.phase1,
      phase2: profileValuesCopy.phase2,
      phase3: profileValuesCopy.phase3,
      phase4: profileValuesCopy.phase4
    };
    if ((profileValuesCopy.skill as any).isMachineRelated != null)
      newRow.isMachineRelated = (profileValuesCopy.skill as any).isMachineRelated;
    this.setState(({ profileValues }) => ({
      profileValues: {
        ...profileValues,
        ...this.getDefaultProfileRowsValues(),
        skills: [...profileValues.skills, newRow]
      },
      isChangedSkills: true
    }));
    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(profileValuesCopy), skills: [...profileValuesCopy.skills, newRow] });
  };

  onProfileValuesChange = (profileValues: ProfileValues) => {
    this.setState({ profileValues, userClickedSave: false });
    const newState = { ...profileValues };
    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(newState) });
  };

  removeSkillRow = (index: number) => {
    const prevValues = { ...this.state.profileValues };
    const skills = prevValues.skills.filter((_, i) => index !== i);

    this.setState(prevState => ({
      userClickedSave: false,
      profileValues: { ...prevValues, skills },
      isChangedSkills: true
    }));
    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(prevValues), skills });
  };

  onToggleConfirmationBox = () => {
    this.props.changeProfileStore.clear();
    this.setState(({ onConfirmToggleDisable }) => ({ onConfirmToggleDisable: !onConfirmToggleDisable }));
  };

  onSubmit = () => {
    const { changeProfileStore, onFinish } = this.props;
    this.setState({ userClickedSave: true, onConfirmToggleDisable: false });
    changeProfileStore.update().then(_ => {
      if (changeProfileStore.state.result?.isSuccess) {
        this.onCancelChangeItem();
        onFinish && onFinish();
      }
    });
  };

  public render() {
    const { t } = this.props;

    return (
      <>
        <ProfileDefinitionModal
          mode="Edit"
          onSubmit={this.onToggleConfirmationBox}
          onNewRow={this.addNewRowHandler}
          onRemoveRow={this.removeSkillRow}
          saveTry={this.state.userClickedSave}
          values={this.state.profileValues}
          onChange={this.onProfileValuesChange}
          onClose={this.onCancelChangeItem}
          isBusy={this.props.changeProfileStore.state.isBusy}
        >
          {this.props.changeProfileStore.state.result && !this.props.changeProfileStore.state.result.isSuccess && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              list={this.props.changeProfileStore.state.result.messages.map(m => m.body)}
            />
          )}
        </ProfileDefinitionModal>
        {this.state.onConfirmToggleDisable && (
          <Modal
            open={this.state.onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.onToggleConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t('You are going to change this profile')}</p>
              {this.state.isChangedSkills && (
                <p>{t('If any Skill was removed or new added the templates associated with this profile will be disabled')}</p>
              )}
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.onToggleConfirmationBox} />
              <Button className="change-status-popup__btn change-status-popup__pending-btn" content={t('Yes')} onClick={this.onSubmit} />
            </div>
          </Modal>
        )}
      </>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EditProfileDefinition);
