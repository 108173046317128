import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal, Header, Input, List, Label, Icon } from 'semantic-ui-react';
import { Query, ItemReference } from 'stores/dataStore';
import {
  TnaTemplatesStore,
  TnaTemplateDto,
  TnaSkillTemplateSectionDto,
  TnaMachineUnitTemplateSectionDto,
  TnaSkillSectionQuestionDto
} from 'stores/assessments/templates/tna-templates-store';
import { connect } from 'redux-scaffolding-ts';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import 'site/pages/assessments/tna/templates/wizard/steps/tna-wizard-second-step.less';
import { ProfileItemDto, ProfileItemStore, ProfileSkillDto } from 'stores/profile/profile-store';
import { TnaTemplateData, TnaTemplateMachines } from 'site/pages/assessments/tna/templates/wizard/tna-templates-wizard-component';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { isNullOrUndefined } from 'util';
import ChooseTnaQuestionsView from 'widgets/form/choose-tna-questions-form';
import { TnaQuestionBankDto } from 'stores/assessments/questionBank/tna-questionBank-store';
import clone from 'clone';

interface CustomizeTnaTemplatesViewProps extends WithTranslation {
  onSaveCustomizeTemplate?: (templateData: TnaTemplateData) => void;
  onCloseTemplateModal?: () => void;
  templateDto: TnaTemplateDto;
  templateStore?: TnaTemplatesStore;
  machineModel?: ItemReference;
  profileStore?: ProfileItemStore;
  templateData: TnaTemplateData;
}

interface CustomizeTemplatesFilters {
  templateTitleOrId: string;
  machineModel: string;
}

interface CustomizeTnaTemplatesViewState {
  activeFilters: string[];
  query: Query;
  filters: CustomizeTemplatesFilters;
  cluster: ItemReference;
  //equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  machineRow: TnaTemplateMachines;
  templateData: TnaTemplateData;
  equipmentType: string;
  isOpenSelectTnaQuestionsModal: boolean;
  isMachineRelated: boolean;
  alreadySelectedQuestions: string[];
  machineUnitIndex: number;
  skillToSelectQuestions: TnaSkillTemplateSectionDto;
}

@connect(['templateStore', TnaTemplatesStore], ['profileStore', ProfileItemStore])
class CustomizeTnaTemplatesView extends React.Component<CustomizeTnaTemplatesViewProps, CustomizeTnaTemplatesViewState> {
  constructor(props: CustomizeTnaTemplatesViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      activeFilters: [],
      filters: {
        templateTitleOrId: undefined,
        machineModel: undefined
      },
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      machineRow: this.emptyMachineRow,
      templateData: this.props.templateData
        ? this.props.templateData
        : {
            profile: null,
            templateTitle: null,
            templateHeader: null,
            machines: [],
            nonMachineRelatedSkillSections: [],
            commonMachineRelatedSkillSections: [],
            machineRelatedSections: []
          },
      isOpenSelectTnaQuestionsModal: false,
      isMachineRelated: null,
      alreadySelectedQuestions: null,
      machineUnitIndex: null,
      skillToSelectQuestions: null
    };
  }

  loading = false;

  private get emptyMachineRow(): TnaTemplateMachines {
    return {
      equipmentType: null,
      machineModel: null,
      machineRelatedCluster: null,
      oem: null
    };
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { profileStore, templateDto } = this.props;
    try {
      this.loading = true;
      let profile = profileStore.state.items.find(obj => obj.item.id === templateDto.profileItemId).item;

      profile = profile ? profile : await profileStore.getProfileById(templateDto.profileItemId);

      if (profile && templateDto) this.loading = false;

      const { templateData, machineRow } = this.mapToTemplateData(templateDto, profile);

      if (this.props.templateData) {
        this.setState({ machineRow });
      } else {
        this.setState({ templateData: { ...templateData, profile }, machineRow });
      }
    } catch (error) {
      console.error({ error });
    }
  };

  @autobind
  private onSelectTnaQuestions(
    alreadySelectedQuestions: string[],
    isMachineRelated: boolean,
    equipmentType: string = null,
    machineUnitIndex: number = null,
    skill: TnaSkillTemplateSectionDto
  ) {
    this.setState({
      isOpenSelectTnaQuestionsModal: true,
      equipmentType,
      isMachineRelated,
      alreadySelectedQuestions,
      machineUnitIndex,
      skillToSelectQuestions: skill
    });
  }

  @autobind
  private handleOnCancel = () => {
    this.props.onCloseTemplateModal();
  };

  @autobind
  private onSaveCustomizeTemplate = () => {
    const { templateData } = this.state;
    if (templateData == null) return;

    this.props.onSaveCustomizeTemplate && this.props.onSaveCustomizeTemplate(clone<TnaTemplateData>(templateData));
    this.props.onCloseTemplateModal();
  };

  @autobind
  private onAddTnaQuestions(questions: TnaQuestionBankDto[]) {
    const { templateData } = this.state;
    let change = { property: null, value: null };
    if (questions && questions.length > 0) {
      if (this.state.isMachineRelated) {
        change.property = 'machineRelatedSections';
        const machineRelatedSections = [...templateData.machineRelatedSections];
        machineRelatedSections[this.state.machineUnitIndex].skillSections = machineRelatedSections[
          this.state.machineUnitIndex
        ].skillSections.map(skill => {
          if (skill === this.state.skillToSelectQuestions) {
            skill.questions = (skill.questions || []).concat(
              questions.map(({ id, question, friendlyId, type }) => ({
                id,
                question: question.text,
                friendlyId,
                source: type,
                isAddedInCustom: true
              }))
            );
          }
          return skill;
        });
        change.value = machineRelatedSections;
      } else {
        change.property = 'nonMachineRelatedSkillSections';
        change.value = templateData.nonMachineRelatedSkillSections.map(nmr => {
          if (nmr === this.state.skillToSelectQuestions) {
            nmr.questions = (nmr.questions || []).concat(
              questions.map(({ id, question, friendlyId, type }) => ({
                id,
                question: question.text,
                friendlyId,
                source: type,
                isAddedInCustom: true
              }))
            );
          }
          return nmr;
        });
      }
      //change.property && change.value && this.props.onChangeProperty(change.property, change.value);
    }

    this.setState({ isOpenSelectTnaQuestionsModal: false, equipmentType: null, machineUnitIndex: null, alreadySelectedQuestions: null });
  }

  mapDefaultCommonSkills = (sections: ProfileSkillDto[]): TnaSkillTemplateSectionDto[] =>
    sections.map(({ skillId, skillName }) => ({ skillId, skillName, questions: [] }));

  mapDefaultSkills = (commonSkills: TnaSkillTemplateSectionDto[], skills: TnaSkillTemplateSectionDto[]) => {
    const defaultSkills = [];
    let existingSkills = (skills || []).filter(({ skillId }) => (commonSkills || []).find(x => x.skillId === skillId) !== undefined);
    let nonExistingSkills = (commonSkills || []).filter(({ skillId }) => (skills || []).find(x => x.skillId === skillId) === undefined);

    (existingSkills || []).forEach(({ skillName, skillId, questions }) => {
      defaultSkills.push({ skillId, skillName, questions });
    });

    (nonExistingSkills || []).forEach(({ skillName, skillId, questions }) => {
      defaultSkills.push({ skillId, skillName, questions });
    });

    return defaultSkills;
  };

  getDefaultMachineRow = (machineRow?: TnaTemplateMachines) => ({
    ...(machineRow || this.state.machineRow),
    machineModel: null
  });

  mapToTemplateData = (rawData: TnaTemplateDto, profile: ProfileItemDto) => {
    const { header, title, nonMachineRelatedSkillSections, machineModels, machineRelatedSections } = rawData;
    const { skills } = profile;
    const commonMRSkills: TnaSkillTemplateSectionDto[] = this.mapDefaultCommonSkills(
      skills.filter(({ isMachineRelated }) => isMachineRelated)
    );

    const commonNMRSkills: TnaSkillTemplateSectionDto[] = this.mapDefaultCommonSkills(
      skills.filter(({ isMachineRelated }) => !isMachineRelated)
    );

    let nonMachineRelated = [];
    if (commonNMRSkills && commonNMRSkills.length > 0)
      nonMachineRelated = this.mapDefaultSkills(commonNMRSkills, nonMachineRelatedSkillSections);

    let machineRelated: TnaMachineUnitTemplateSectionDto[] = [];
    commonMRSkills &&
      commonMRSkills.length > 0 &&
      machineRelatedSections.forEach(({ machineUnitId, skillSections, machineUnitItemDto }) => {
        machineRelated.push({ machineUnitItemDto, machineUnitId, skillSections: this.mapDefaultSkills(commonMRSkills, skillSections) });
      });
    let machineRow = this.emptyMachineRow;
    const templateData = {
      nonMachineRelatedSkillSections: nonMachineRelated,
      machineRelatedSections: machineRelated,
      commonMachineRelatedSkillSections: commonMRSkills || [],
      machines: machineModels.map((m: MachineModelDto) => {
        machineRow = {
          equipmentType: { id: m.equipmentTypeId, title: m.equipmentTypeName },
          machineRelatedCluster: { id: m.clusterId, title: m.clusterName },
          oem: { id: m.oemId, title: m.oemName },
          machineModel: { id: m.id, title: m.name }
        };
        return machineRow;
      }),
      templateTitle: title,
      templateHeader: header || ''
    };
    machineRow = this.getDefaultMachineRow(machineRow);
    return { templateData, machineRow };
  };

  handleMoveUpSkillNonMachineRelated = (skillId, idx) => {
    const templateDataCopy = { ...this.state.templateData };
    if (idx <= 0) return;
    const nonMachineRelatedSkillsArray = this.state.templateData.nonMachineRelatedSkillSections;

    const skillToMove = nonMachineRelatedSkillsArray.find(skill => skill.skillId === skillId);

    const temporalVariable = nonMachineRelatedSkillsArray[idx - 1];

    nonMachineRelatedSkillsArray[idx] = temporalVariable;

    nonMachineRelatedSkillsArray[idx - 1] = skillToMove;

    templateDataCopy.nonMachineRelatedSkillSections = nonMachineRelatedSkillsArray;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveDownSkillNonMachineRelated = (skillId, idx) => {
    const templateDataCopy = { ...this.state.templateData };
    if (idx >= templateDataCopy.nonMachineRelatedSkillSections.length - 1) return;
    const nonMachineRelatedSkillsArray = this.state.templateData.nonMachineRelatedSkillSections;

    const skillToMove = nonMachineRelatedSkillsArray.find(skill => skill.skillId === skillId);

    const temporalVariable = nonMachineRelatedSkillsArray[idx + 1];

    nonMachineRelatedSkillsArray[idx] = temporalVariable;

    nonMachineRelatedSkillsArray[idx + 1] = skillToMove;

    templateDataCopy.nonMachineRelatedSkillSections = nonMachineRelatedSkillsArray;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveUpSkillWithMachineRelated = (skillId, sectionIndex, skillIndex) => {
    const templateDataCopy = { ...this.state.templateData };
    if (skillIndex <= 0) return;

    const machineRelatedSection = this.state.templateData.machineRelatedSections[sectionIndex];
    const skillsOnSection = machineRelatedSection.skillSections;

    const skillToMove = skillsOnSection.find(skill => skill.skillId === skillId);
    const temporalVariable = skillsOnSection[skillIndex - 1];

    skillsOnSection[skillIndex] = temporalVariable;
    skillsOnSection[skillIndex - 1] = skillToMove;

    templateDataCopy.machineRelatedSections[sectionIndex].skillSections = skillsOnSection;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveDownSkillWithMachineRelated = (skillId, sectionIndex, skillIndex) => {
    const templateDataCopy = { ...this.state.templateData };
    if (skillIndex >= templateDataCopy.machineRelatedSections[sectionIndex].skillSections.length - 1) return;

    const machineRelatedSection = this.state.templateData.machineRelatedSections[sectionIndex];
    const skillsOnSection = machineRelatedSection.skillSections;

    const skillToMove = skillsOnSection.find(skill => skill.skillId === skillId);
    const temporalVariable = skillsOnSection[skillIndex + 1];

    skillsOnSection[skillIndex] = temporalVariable;
    skillsOnSection[skillIndex + 1] = skillToMove;

    templateDataCopy.machineRelatedSections[sectionIndex].skillSections = skillsOnSection;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveUpQuestion = (questionIndex, skillId, machineUnitIndex?: number) => {
    const templateDataCopy = { ...this.state.templateData };
    const machineUnitSection = machineUnitIndex != null ? templateDataCopy.machineRelatedSections[machineUnitIndex] : null;
    const skillSections = machineUnitIndex == null ? templateDataCopy.nonMachineRelatedSkillSections : machineUnitSection.skillSections;
    const skillSection = skillSections.find(ss => ss.skillId === skillId);

    if (skillSections && skillSections.length > 0 && questionIndex <= 0) return;

    const temporalVariable = skillSection.questions[questionIndex - 1];

    skillSection.questions[questionIndex - 1] = skillSection.questions[questionIndex];

    skillSection.questions[questionIndex] = temporalVariable;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleDownQuestion = (questionIndex, skillId, machineUnitIndex?: number) => {
    const templateDataCopy = { ...this.state.templateData };
    const machineUnitSection = machineUnitIndex != null ? templateDataCopy.machineRelatedSections[machineUnitIndex] : null;
    const skillSections = machineUnitIndex == null ? templateDataCopy.nonMachineRelatedSkillSections : machineUnitSection.skillSections;
    const skillSection = skillSections.find(ss => ss.skillId === skillId);

    if (skillSections && skillSections.length > 0 && questionIndex >= skillSection.questions.length - 1) return;

    const temporalVariable = skillSection.questions[questionIndex + 1];

    skillSection.questions[questionIndex + 1] = skillSection.questions[questionIndex];

    skillSection.questions[questionIndex] = temporalVariable;

    //this.props.onChangeProperty('templateData', templateDataCopy);
  };

  onRemoveQuestion = (skillId: string, questionId: string, index: number = null) => {
    if (index != null) {
      const mrSection = [...this.state.templateData.machineRelatedSections];
      if ((mrSection || []).length > 0) {
        mrSection[index].skillSections = mrSection[index].skillSections.map(section => {
          if (section.skillId === skillId) {
            section.questions = section.questions.filter(question => question.id !== questionId);
          }
          return section;
        });

        this.setState(({ templateData }) => ({ templateData: { ...templateData, machineRelatedSections: mrSection } }));
      }
    } else {
      const nmrSection = [...this.state.templateData.nonMachineRelatedSkillSections];
      (nmrSection || []).length > 0 &&
        this.setState(({ templateData }) => ({
          templateData: {
            ...templateData,
            nonMachineRelatedSkillSections: nmrSection.map(nmrSkills => {
              if (nmrSkills.skillId === skillId) nmrSkills.questions = nmrSkills.questions.filter(question => questionId !== question.id);
              return nmrSkills;
            })
          }
        }));
    }
  };

  onGenerateQuestionsList = (skillId: string, questions: TnaSkillSectionQuestionDto[], machineUnitIndex?: number) => {
    return questions.map((question, i) => {
      return (
        <div key={question.friendlyId + i} className="tna-wizard__assign-question">
          <div className="tna-wizard__assign-question__items">
            <p className="wizard__customize-question-item">{question.friendlyId}</p>
            <p className="wizard__customize-question-item">{question.source == null ? 'Global' : question.source}</p>
            <p className="wizard__customize-question-item">{question.question}</p>
          </div>

          {question.isAddedInCustom && (
            <Icon
              className="assigned-profile-row-machine__remove"
              name="remove"
              size="large"
              color="red"
              onClick={() => this.onRemoveQuestion(skillId, question.id, machineUnitIndex)}
            />
          )}
        </div>
      );
    });
  };

  public render() {
    const { t, templateDto, machineModel } = this.props as any;
    const { templateData } = this.state;
    const { machineRelatedSections, commonMachineRelatedSkillSections, machines, nonMachineRelatedSkillSections } = templateData;

    return (
      <Modal
        size="large"
        className="template-search__modal"
        open
        closeOnEscape={true}
        onClose={this.handleOnCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Customize Template')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="wizard__steps-container">
            <div className="wizard__step wizard__step-one">
              <StepsComponent active number={'1'} title={'IDENTIFICATION OF THE TEMPLATE'}></StepsComponent>
              <div className="step-one__content flex-start wizard__step-margin-left">
                <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
                <Input value={templateDto && templateDto.title ? templateDto.title : ''} disabled={true} />
                <label style={{ transform: 'translateY(50%)' }}>{t('Profile')}</label>
                <div className="input profile-editor">
                  <Input value={templateDto && templateDto.profileName ? templateDto.profileName : ''} disabled={true} />
                </div>
              </div>
            </div>
            <div className="wizard__step wizard__step-two">
              <StepsComponent active number={'2'} title={'MACHINE MODELS'} />
              {(templateDto?.machineModels || []).map((item, index) => {
                let classNameCell = item.id === machineModel?.id ? 'machine-cell-bold' : 'machine-cell';
                return (
                  <div key={index} className="flex-center assigned-profile-row-machine__info">
                    <div className="assigned-profile-row-machine__items">
                      <p className={classNameCell}>{t(item?.clusterName || '')}</p>
                      <p className={classNameCell}>{t(item?.equipmentTypeName || '')}</p>
                      <p className={classNameCell}>{t(item?.oemName || '')}</p>
                      <p className={classNameCell}>{t(item?.name || '')}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="wizard__step wizard__step-three">
              <StepsComponent active number={'3'} title={t('SELECT QUESTIONS')} />
              <div className="step-three__skills-questions-wrapper">
                {nonMachineRelatedSkillSections && nonMachineRelatedSkillSections.length > 0 ? (
                  <>
                    <p className="text__bold">{t('Non-Machine Related Skills')}</p>
                    {nonMachineRelatedSkillSections.map((skill, idx) => (
                      <List key={skill.skillId + idx} verticalAlign="middle">
                        <div>
                          <List.Item className="edit__select-profiles__wrapper__row">
                            <Label
                              className={`skill-label${isNullOrUndefined(machines) ? ' expandable' : ''}`}
                              style={{ width: '100%', height: '38px' }}
                            >
                              <p>{skill.skillName}</p>

                              <div className="wizard__sort-icons-wrapper">
                                <Button
                                  onClick={() =>
                                    this.onSelectTnaQuestions(
                                      skill.questions && skill.questions.length > 0 && skill.questions.map(({ id }) => id),
                                      false,
                                      (skill as any).equipmentType,
                                      null,
                                      skill
                                    )
                                  }
                                  className="tna-template-wizard__add-questions-btn"
                                  type="button"
                                >
                                  {t('Add Questions')}
                                </Button>
                              </div>
                            </Label>
                          </List.Item>
                        </div>

                        {skill.questions && skill.questions.length > 0 && this.onGenerateQuestionsList(skill.skillId, skill.questions)}
                      </List>
                    ))}
                  </>
                ) : null}
              </div>

              {commonMachineRelatedSkillSections && commonMachineRelatedSkillSections.length > 0 ? (
                <>
                  <div className="step-three__skills-questions-wrapper">
                    <p className="text__bold">{t('Machine Related Skills')}</p>
                    <div className="template-wizard__machine-related">
                      {/* <Button className="add-row-btn" type="button">
                        {t('Add Machine Unit')}
                      </Button> */}
                      {machineRelatedSections.map((sections, index) => (
                        <React.Fragment key={sections.machineUnitId + index}>
                          <div className="template-wizard-customize__machineUnit-button-container editor-32px">
                            <h2>{sections.machineUnitItemDto?.name || ''}</h2>
                          </div>
                          {sections.skillSections &&
                            sections.skillSections.length > 0 &&
                            sections.skillSections.map((section, i) => (
                              <List key={section.skillId + i} verticalAlign="middle">
                                <div>
                                  <List.Item className="edit__select-profiles__wrapper__row">
                                    <Label
                                      className={`skill-label${isNullOrUndefined(machines) ? ' expandable' : ''}`}
                                      //onClick={() => this.onShowQuestionContent}
                                      style={{ width: '100%', height: '38px' }}
                                    >
                                      <p>{section.skillName}</p>

                                      <div className="wizard__sort-icons-wrapper">
                                        <Button
                                          onClick={() =>
                                            this.onSelectTnaQuestions(
                                              section.questions && section.questions.length > 0 && section.questions.map(({ id }) => id),
                                              true,
                                              null,
                                              index,
                                              section
                                            )
                                          }
                                          className="tna-template-wizard__add-questions-btn"
                                          type="button"
                                        >
                                          {t('Add Questions')}
                                        </Button>
                                      </div>
                                    </Label>
                                  </List.Item>

                                  <div className="edit__select-profiles__edit-wrapper"></div>
                                </div>

                                {section.questions &&
                                  section.questions.length > 0 &&
                                  this.onGenerateQuestionsList(section.skillId, section.questions, index)}
                              </List>
                            ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {this.state.isOpenSelectTnaQuestionsModal && (
              <ChooseTnaQuestionsView
                professionId={this.state.templateData.profile.professionId}
                equipmentTypeId={this.state.isMachineRelated && machines && machines.length > 0 ? machines[0].equipmentType.id : null}
                oemId={this.state.isMachineRelated && machines && machines.length > 0 ? machines[0].oem.id : null}
                tnaSkillId={this.state.skillToSelectQuestions.skillId}
                tnaSkillName={this.state.skillToSelectQuestions.skillName}
                alreadySelectedQuestions={this.state.alreadySelectedQuestions}
                isMachineRelated={this.state.isMachineRelated}
                onAddTnaQuestions={this.onAddTnaQuestions}
                onCloseModal={() => this.setState({ isOpenSelectTnaQuestionsModal: false })}
                fromCustomize={true}
              />
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.handleOnCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onSaveCustomizeTemplate} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(CustomizeTnaTemplatesView);
