import React, { Component } from 'react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NewTnaTheoreticalTestViewNodel } from '../new-tna-theoretical-test';
import { Button, Grid, Icon } from 'semantic-ui-react';
import './tna-theoretical-tests.less';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

export interface EmployeeDataStepProps extends WithTranslation {
  stepActive: boolean;
  viewModel: NewTnaTheoreticalTestViewNodel;
  onChange: (vm: NewTnaTheoreticalTestViewNodel) => void;
}

export interface EmployeeDataStepState {
  showModal: boolean;
}

class EmployeeDataStep extends Component<EmployeeDataStepProps, EmployeeDataStepState> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  protected removeEmployee = (id: string) => {
    const { viewModel } = this.props;
    viewModel.employees = viewModel.employees.filter(x => x.userId !== id);
    this.props.onChange(viewModel);
  };

  private onEmployeesSelected = (employees: UserProfilesDto[]) => {
    const { viewModel } = this.props;
    viewModel.employees = [...viewModel.employees, ...employees];
    this.props.onChange(viewModel);
  };

  private showModal = () => {
    this.setState({ showModal: true });
  };

  private hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { t, viewModel: value, stepActive } = this.props;
    const { showModal } = this.state;
    const employeesList = value.employees || [];

    return (
      <div className="wizard__step wizard__step-one">
        <StepsComponent active={stepActive} number={'3'} title={t('SELECT EMPLOYEES')}>
          <div className="new-tna-form-wizard_step3__btn-dropdown flex-start" id="">
            <Button className="new-tna-form-wizard_step3-row_btn" onClick={this.showModal}>
              {t('Add Employees')}
            </Button>
          </div>
          {employeesList.length !== 0 && (
            <div className="new-tna-form-wizard_step3-matrix-container tooltip-visible scroll">
              <Grid
                id="new-tna-form-wizard_step3-matrix-table"
                className="new-tna-form-wizard_step3-table new-tna-form-wizard_step3-table__assessor"
              >
                <Grid.Row className="new-tna-form-wizard_step3-table-row new-tna-form-wizard_step3-table-header">
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Last Name')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('First Name')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('SF Position')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Position Code')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Location')}</Grid.Column>
                  <Grid.Column
                    width={1}
                    className="new-tna-form-wizard_step3-table-cell new-tna-form-wizard_step3-table-remove-cell"
                  ></Grid.Column>
                </Grid.Row>

                {employeesList.map(e => (
                  <Grid.Row key={`matrix_${e.userId}`} className="new-tna-form-wizard_step3-table-row">
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.lastName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.firstName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.sfPosition || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.positionCodeName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.locationName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-tna-form-wizard_step3-table-cell  new-tna-form-wizard_step3-table-remove-cell"
                    >
                      <Icon
                        size="large"
                        name="close"
                        color="red"
                        className="clickable-icon"
                        onClick={() => this.removeEmployee(e.userId)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </div>
          )}
        </StepsComponent>
        {showModal && (
          <ChooseEmployeesView
            locationId={value.location.id}
            profileId={value.profile.id}
            clusterId={value.template?.theoreticalTemplateMRDetails?.clusterId}
            equipmentTypeId={value.template?.theoreticalTemplateMRDetails?.equipmentTypeId}
            oemId={value.template?.theoreticalTemplateMRDetails?.oemId}
            machineModelIdQueryString={value.template?.theoreticalTemplateMRDetails?.machineModelId}
            alreadySelectedUserIds={value.employees.map(x => x.userId)}
            onAddEmployees={this.onEmployeesSelected}
            onCloseUsersModal={this.hideModal}
            showEmployeeIdFilter
            showPositionCodeFilter
            showColumns={[
              {
                column: 'positionCodeName',
                title: 'Position Code',
                tooltipRenderer: true
              }
            ]}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(EmployeeDataStep);
