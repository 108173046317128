import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Input, Button } from 'semantic-ui-react';
import { StudentDto } from 'stores/students/student-model';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import StudentList from 'widgets/items-list/students-list';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import ChooseStudentsView from 'widgets/form/choose-students-form';
import './students-assign-component.less';

export interface StudentsTabViewModel {
  students: StudentDto[];
  studentsAssigned: number;
  studentsRequested?: number;
  studentsOptional?: boolean;
}

export interface StudentsAssignTableComponentProps extends WithTranslation {
  readOnly: boolean;
  value: StudentsTabViewModel;
  onChange?: (value: StudentsTabViewModel) => void;
  className?: string;
}

export interface StudentsAssignTableComponentState {
  isOpenedUsersModal: boolean;
}

class StudentsAssignTableComponent extends Component<StudentsAssignTableComponentProps, StudentsAssignTableComponentState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: StudentsAssignTableComponentState = {
    isOpenedUsersModal: false
  };

  private handleOnOpenUserModal = () => {
    this.setState({ isOpenedUsersModal: true });
  };

  private handleOnCloseUserModal = () => {
    this.setState({ isOpenedUsersModal: false });
  };

  private handleOnAssignedStudentsNumber = (studentsNumber: string) => {
    let value = { ...this.props.value };
    value.studentsAssigned = +studentsNumber;
    this.props.onChange && this.props.onChange(value);
  };

  private handleOnAddParticipants = (newStudents: StudentDto[]) => {
    let newValue = { ...this.props.value };
    newValue.students = [...newValue.students, ...newStudents];
    newValue.studentsAssigned = newValue.studentsAssigned > newValue.students.length ? newValue.studentsAssigned : newValue.students.length;

    this.props.onChange && this.props.onChange(newValue);
  };

  private handleOnRemoveStudent = (student: StudentDto) => {
    let value = { ...this.props.value };
    value.students = value.students.filter(x => student.id !== x.id);
    value.studentsAssigned = value.students.length === 0 ? value.studentsAssigned - value.students.length : value.studentsAssigned;

    this.props.onChange && this.props.onChange(value);
  };
  render() {
    const { t, readOnly, className } = this.props;
    const { isOpenedUsersModal } = this.state;
    const { studentsRequested, studentsAssigned, studentsOptional } = this.props.value;
    const students = this.props.value.students;
    const locationIds =
      this.identityService.activeRole === 'PoC' ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) : null;

    return (
      <Form
        className={
          className
            ? `form__students-tab__container wizard__form__margin ${className}`
            : 'form__students-tab__container wizard__form__margin'
        }
      >
        <FormRow icon={faUserFriends}>
          {!!studentsRequested && (
            <>
              <p>{t('Students Requested')}</p>
              <Input className="form__field-numeric" readOnly value={studentsRequested} />
            </>
          )}
          <div className={`field wizard__step-students-wrapper ${!studentsOptional ? 'required' : ''}`}>
            <label>{t('Students Assigned')}</label>
            <Input
              className="form__field-numeric "
              readOnly={readOnly}
              value={studentsAssigned != null && !Number.isNaN(studentsAssigned) ? studentsAssigned : ''}
              onChange={(c, data) => this.handleOnAssignedStudentsNumber(data.value)}
            />
          </div>
          <div className="students-tab__add-user-button">
            <Button
              disabled={readOnly}
              labelPosition={'right'}
              content={t('Assign Students')}
              basic
              icon="user plus"
              onClick={this.handleOnOpenUserModal}
            ></Button>
          </div>
        </FormRow>

        {students && students.length > 0 && (
          <StudentList canRemove={!readOnly} students={students} onStudentRemove={this.handleOnRemoveStudent} pocLocations={locationIds} />
        )}

        {isOpenedUsersModal && (
          <ChooseStudentsView
            locationIds={locationIds}
            alreadySelectedUserIds={students && students.map(x => x.id)}
            onAddParticipants={this.handleOnAddParticipants}
            onCloseUsersModal={this.handleOnCloseUserModal}
          />
        )}
      </Form>
    );
  }
}

export default withTranslation()(StudentsAssignTableComponent);
