import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Input, Message } from 'semantic-ui-react';
import {
  RejectReasonDto,
  RejectReasonsStore,
  NewRejectReasonsStore,
  ChangeRejectReasonsStore,
  CreateRejectReasonDto,
  ChangeRejectReasonDto
} from 'stores/configuration/events-n-requests/rejection-reasons-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import RejectReasonManager from './reject-reason-manager';

interface RejectionReasonsProps extends WithTranslation {
  rejectReasonsStore?: RejectReasonsStore;
  newRejectReasonsStore?: NewRejectReasonsStore;
  changeRejectReasonsStore?: ChangeRejectReasonsStore;
}

export type ModalMode = 'edit' | 'new';
interface RejectionReasonsState {
  query: Query;
  rejectReasonManager: boolean;
  activeFilters: string[];
  selectedItem: RejectReasonDto;
  mode: ModalMode;
  someFilterOpened: boolean;
}
@connect(
  ['rejectReasonsStore', RejectReasonsStore],
  ['newRejectReasonsStore', NewRejectReasonsStore],
  ['changeRejectReasonsStore', ChangeRejectReasonsStore]
)
class RejectionReasons extends Component<RejectionReasonsProps, RejectionReasonsState> {
  state: RejectionReasonsState = {
    query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
    rejectReasonManager: false,
    activeFilters: [],
    selectedItem: null,
    mode: null,
    someFilterOpened: false
  };

  handleOnEnterKeydown = item => {
    this.onEditItem(item);
  };

  handleOnRowDoubleClick = item => {
    this.onEditItem(item);
  };

  componentDidMount() {
    this.load();
  }

  private load = (query: Query = this.state.query) => this.props.rejectReasonsStore.getAllAsync(query);

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'modifiedOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);

  private onSaveRow = async (item: RejectReasonDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.rejectReasonsStore.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async (item: RejectReasonDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.rejectReasonsStore.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);
    this.setState(({ query }) => ({ query: { ...query, filter, skip: 0 }, activeFilters }), this.load);
  };

  private onNewItem = () => this.setState({ rejectReasonManager: true, mode: 'new' });

  private onEditItem = (selectedItem: RejectReasonDto) => this.setState({ selectedItem, mode: 'edit', rejectReasonManager: true });

  private onResetFormStores = () => {
    this.props.newRejectReasonsStore.reset();
    this.props.changeRejectReasonsStore.reset();
  };
  private handleOnCloseManager = () => {
    this.setState({ rejectReasonManager: false, selectedItem: null, mode: null });
    this.onResetFormStores();
  };

  private handleSaveManger = async () => {
    const { mode } = this.state;
    let response: CommandResult<ChangeRejectReasonDto | CreateRejectReasonDto>;
    if (mode === 'edit') response = await this.props.changeRejectReasonsStore.update();
    else response = await this.props.newRejectReasonsStore.submit();
    if (response?.isSuccess) {
      this.handleOnCloseManager();
      this.load();
    }
  };

  private handleChangeRejection = (name: string) => {
    const { selectedItem, mode } = this.state;
    this.setState(({ selectedItem }) => ({ selectedItem: { ...selectedItem, name } }));
    if (mode === 'edit') this.props.changeRejectReasonsStore.change({ name, id: selectedItem.id });
    else this.props.newRejectReasonsStore.change({ name });
    this.clearManagerErrMsgsHandler();
  };

  private clearManagerErrMsgsHandler = () => {
    const { newRejectReasonsStore, changeRejectReasonsStore } = this.props;
    (newRejectReasonsStore.state.result?.messages || []).length && newRejectReasonsStore.clearMessages();
    (changeRejectReasonsStore.state.result?.messages || []).length && changeRejectReasonsStore.clearMessages();
  };

  componentWillUnmount() {
    this.onResetFormStores();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, rejectReasonsStore, newRejectReasonsStore, changeRejectReasonsStore } = this.props;
    const { activeFilters, rejectReasonManager, selectedItem, mode, someFilterOpened } = this.state;
    const errMsgs = [
      ...(newRejectReasonsStore.state.result?.messages || []).map(x => x.body),
      ...(changeRejectReasonsStore.state.result?.messages || []).map(x => x.body)
    ];

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: false,
          renderer: data => <span>{data?.name}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(_, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<RejectReasonDto>('name'), `contains(${nameof<RejectReasonDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<RejectReasonDto>('name'))}
              active={activeFilters.includes(nameof<RejectReasonDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<RejectReasonDto>('name'), useProfile: false }
        }
      ],
      data: rejectReasonsStore.state
    } as TableModel<RejectReasonDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {rejectReasonsStore.state.result &&
            !rejectReasonsStore.state.result.isSuccess &&
            (rejectReasonsStore.state.result.messages || []).length && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  onDismiss={rejectReasonsStore.clearMessages}
                  error
                  header={t('An error ocurred')}
                  list={rejectReasonsStore.state.result.messages.map(o => o.body)}
                />
              </Grid.Row>
            )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!rejectReasonManager}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: this.onEditItem
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew
              createNewButtonTitle={t('Add Request Reason')}
            />
          </Grid.Row>
        </Grid>
        {rejectReasonManager && (
          <RejectReasonManager
            onChange={this.handleChangeRejection}
            onSubmit={this.handleSaveManger}
            onClose={this.handleOnCloseManager}
            onClearErrors={this.clearManagerErrMsgsHandler}
            editItem={selectedItem}
            mode={mode}
            errMsgs={errMsgs}
            isBusy={newRejectReasonsStore.state.isBusy || changeRejectReasonsStore.state.isBusy}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(RejectionReasons);
