import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, QueryResult, Query } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';

export interface OverallRecommendationDto extends BaseDto {
  id: string;
  name: string;
  description: string;
  active: boolean;
}

export interface CreateOverallRecommendationDto {
  name: string;
  description: string;
  active: boolean;
}

export interface ChangeOverallRecommendationDto {
  id: string;
  name: string;
  description: string;
  active: boolean;
}

export interface ChangeLogOverallRecommendationDto extends OverallRecommendationDto {
  createdOn: string;
}

export class CreateOverallRecommendationValidator extends AbstractValidator<CreateOverallRecommendationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));

    this.validateIfString(o => o.name)
      .hasMaxLength(250)
      .withFailureMessage(i18n.t('Name can not be larger than 250 characters'));
  }
}

export class ChangeOverallRecommendationValidator extends AbstractValidator<ChangeOverallRecommendationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

@repository('@@OVERALLRECOMMENDATIONS', 'overallRecommendations.summary')
export class OverallRecommendationsStore extends DataStore<OverallRecommendationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-overallRecommendation';
  retrievePath = 'get-overallRecommendations';
  updatePath = 'update-overallRecommendation';
  deletePath = 'delete-overallRecommendation';

  protected validate(item: OverallRecommendationDto) {
    return new ChangeOverallRecommendationValidator().validate(item);
  }

  constructor() {
    super('OVERALLRECOMMENDATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@OVERALLRECOMMENDATIONS', 'overallRecommendations.new')
export class NewOverallRecommendationStore extends FormStore<CreateOverallRecommendationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-overallRecommendation';
  retrievePath = 'get-overallRecommendations';
  updatePath = 'update-overallRecommendation';

  protected validate(item: CreateOverallRecommendationDto) {
    return new CreateOverallRecommendationValidator().validate(item);
  }

  constructor() {
    super('NEW_OVERALLRECOMMENDATION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@OVERALLRECOMMENDATIONS', 'overallRecommendations.change')
export class ChangeOverallRecommendationStore extends FormStore<ChangeOverallRecommendationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-overallRecommendation';
  retrievePath = 'get-overallRecommendations';
  updatePath = 'update-overallRecommendation';

  protected validate(item: ChangeOverallRecommendationDto) {
    return new ChangeOverallRecommendationValidator().validate(item);
  }

  constructor() {
    super('CHANGE_OVERALLRECOMMENDATION', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@OVERALLRECOMMENDATIONS', 'overallRecommendations.changelog')
export class ChangeLogOverallRecommendationStore extends DataStore<ChangeLogOverallRecommendationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-overallRecommendation';
  retrievePath = 'get-overallRecommendations';
  updatePath = 'update-overallRecommendation';
  deletePath = 'delete-overallRecommendation';

  protected validate(item: ChangeLogOverallRecommendationDto) {
    return new ChangeOverallRecommendationValidator().validate(item);
  }

  constructor() {
    super('CHANGELOG_OVERALLRECOMMENDATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllHistoryAsync(query: Query, data?: any): Promise<QueryResult<ChangeLogOverallRecommendationDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<ChangeLogOverallRecommendationDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }
}
