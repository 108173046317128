import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';

import i18n from '../../../i18n';
import { BaseDto } from '../../types';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';

export interface PatternRowDto extends BaseDto {
  id: string;
  patternId: string;
  machineRelatedClusterId: string;
  machineRelatedClusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  isOnlyRequest: boolean;
  machineModelId: string;
  machineModelName: string;
  order: number;
}

export interface CreatePatternRowDto {
  patternId: string;
  machineRelatedClusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  isOnlyRequest: boolean;
  order: number;
}

export interface ChangePatternRowDto {
  id: string;
  patternId: string;
  machineRelatedClusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  isOnlyRequest: boolean;
  order: number;
}

export class CreatePatternRowValidator extends ExtendedAbstractValidator<CreatePatternRowDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x && x.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);
  }
}

export class ChangePatternRowValidator extends AbstractValidator<ChangePatternRowDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Pattern Id is required'));

    this.validateIfString(o => o.patternId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pattern is required'));

    this.validateIfString(o => o.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));
  }
}
