import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import queryString from 'query-string';

import i18n from 'i18n';
import { RouteComponentProps } from 'react-router';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';
import FeedbacksTemplatesList from './templates/feedbacks-templates-list';
import FeedbackQuestionBankList from './question-bank/feedback-question-bank-list';

export interface FeedbacksHomeProps extends RouteComponentProps, WithTranslation {}
export interface FeedbacksHomeState {
  activeIndex: number;
}

const FeedbacksHomePage: FunctionComponent<FeedbacksHomeProps> = ({ t, match, history, location, staticContext }) => {
  const identityService: IdentityService = container.get(IdentityService);
  const panes = [
    {
      menuItem: i18n.t('Question Bank'),
      render: () => (
        <Tab.Pane attached={false}>
          <FeedbackQuestionBankList />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Templates'),
      render: () => (
        <Tab.Pane attached={false}>
          <FeedbacksTemplatesList {...routes} />
        </Tab.Pane>
      )
    }
  ];
  const getActiveIndex = (): number => {
    let values = queryString.parse(location.search);
    if (values == null || values.tab == null) values = match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < panes.length) return n;
    return 0;
  };

  const [activeIndex, setActiveIndex] = useState(getActiveIndex());

  const routes: RouteComponentProps = {
    match,
    history,
    location,
    staticContext
  };

  const isInRoleAdmin = (): boolean => {
    return IdentityService.isAdminOrPowerInstructor(identityService.getUserInfo());
  };

  const onTabChange = (_, { activeIndex }) => {
    history.replace(`/assessments/feedbacks?tab=${activeIndex}`);
    setActiveIndex(activeIndex);
  };

  return (
    <>
      <h3 id="feedback-forms-list-filters__title">{t('Feedback Forms')}</h3>
      {isInRoleAdmin() && (
        <Tab
          className="main-tab"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
        />
      )}
    </>
  );
};

export default withTranslation()(FeedbacksHomePage);
