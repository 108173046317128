import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface ChipInfoProps extends WithTranslation {
  className?: string;
  info: string[];
}

const ChipInfo: FC<ChipInfoProps> = ({ info }) => {
  let data = (info || []).filter(x => !isNullOrEmpty(x));

  if (data.length === 0) return null;

  return (
    <div className="table-cell__more-width">
      <span className="question-bank__cell__tag">{data.join(' | ')}</span>
    </div>
  );
};

export default withTranslation()(ChipInfo);
