import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { DataStore, ItemCommandResult, DataModel } from 'stores/dataStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { AxiosResponse } from 'axios';
import { IdentityService } from 'services/identity-service';
import { ItemResult, BaseDto } from 'stores/types';
import { ValidationResult } from 'fluent-ts-validator';
import { constants } from 'services/configuration-service';
import { stringify } from 'query-string';

export interface NotificationItemDto extends BaseDto {
  id: string;
  recipientUserId: string;
  senderUserId: string;
  content: string;
  title: string;
  type: string;
  read: boolean;
  pinned: boolean;
  relatedItemId?: string;
}

@repository('@@NOTIFICATIONS', 'notifications.summary')
export class UserNotificationsStore extends DataStore<NotificationItemDto> {
  CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

  baseUrl = 'notifications/v1';
  createPath = null;
  retrievePath = 'get-web-notifications';
  updatePath = 'update-notification';
  deletePath = null;
  clearAllPath = 'clear-all-notifications';

  protected validate(item: NotificationItemDto) {
    return new ValidationResult();
  }

  constructor() {
    super('NOTIFICATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });

    this.addReducer(this.CLEAR_ALL_NOTIFICATIONS, this.onClearAllNotification, 'AsyncAction');
  }

  public async clearAllAsync() {
    const httpService = container.get<HttpService>(HttpService);

    const result = await this.dispatchAsync(
      this.CLEAR_ALL_NOTIFICATIONS,
      httpService.post<void, ItemCommandResult>(`${this.baseUrl}/${this.clearAllPath}`, null)
    );

    return result.data;
  }

  protected onClearAllNotification = (): AsyncAction<AxiosResponse<ItemCommandResult>, DataModel<NotificationItemDto>> => {
    return {
      onStart: () => {
        return { ...this.state, isBusy: true };
      },
      onSuccess: result => {
        let items = this.state.items;
        if (result.data.isSuccess) {
          items.forEach(i => (i.item.read = true));
        }
        return {
          ...this.state,
          isBusy: false,
          items: [...items],
          result: {
            isSuccess: result.data.isSuccess,
            items: [],
            messages: result.data.messages
          }
        };
      },
      onError: (error, args) => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? error.response.data
            : {
                isSuccess: false,
                items: [],
                messages: [{ body: error.message || error, level: 'Error' }]
              }
      })
    };
  };
}

export interface ReadAllNotificationsDto {
  Target: string;
  Arguments: Array<object>;
  UserId: string;
}

export interface UnreadNotificationCountStoreState {
  unread: number;
  isBusy: boolean;
  result: any;
}

@repository('@@NOTIFICATIONS', 'notifications.unreadcount')
export class UnreadNotificationCountStore extends ReduxRepository<UnreadNotificationCountStoreState> {
  public DATA_LOADED = 'DATA_LOADED';
  public UNREAD_NOTIFICATIONS_COUNT_RECEIVED = 'UNREAD_NOTIFICATIONS_COUNT_RECEIVED';
  public HUB_ERROR = 'HUB_ERROR';

  baseUrl = `${constants.apiBaseUrl}/notifications/v1`;
  retrieveCountPath = 'get-unread-notification-count';
  negotiatePath = 'user-notifications';

  private signalRConnection: HubConnection;

  constructor() {
    super({
      unread: 0,
      isBusy: false,
      result: null
    });

    this.init.bind(this);

    this.addReducer(this.DATA_LOADED, this.onInit, 'AsyncAction');
    this.addReducer(this.HUB_ERROR, this.onHubError, 'Simple');
    this.addReducer(this.UNREAD_NOTIFICATIONS_COUNT_RECEIVED, this.onNewMessageReceived, 'Simple');
  }

  protected onHubError = (error: any): UnreadNotificationCountStoreState => {
    return { ...this.state, result: error };
  };

  public async init() {
    const httpService = container.get<HttpService>(HttpService);
    const identityService = container.get<IdentityService>(IdentityService);
    await this.dispatchAsync(
      this.DATA_LOADED,
      httpService.get<ItemResult<number>>(`${this.baseUrl}/${this.retrieveCountPath}`),
      identityService.activeRole
    );
  }

  protected onInit = (): AsyncAction<AxiosResponse<ItemResult<number>>, UnreadNotificationCountStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result, activeRole) => {
        if (this.signalRConnection != null) {
          this.signalRConnection.stop().catch(err => {
            console.error('HubError', 'stop', err);
          });
        }
        const params = { webrole: activeRole };
        this.signalRConnection = new HubConnectionBuilder()
          .withUrl(`${this.baseUrl}/${this.negotiatePath}?${stringify(params)}`, {
            accessTokenFactory: () => container.get<IdentityService>(IdentityService).accessToken
          })
          .build();

        this.signalRConnection.start().catch(err => {
          console.error('HubError', 'start', err);
          this.dispatch(this.HUB_ERROR, err);
        });

        this.signalRConnection.onclose(err => {
          if (err != null) {
            console.error('HubError', 'onclose', err);
            this.signalRConnection.start();
          }
        });

        this.signalRConnection.on('notificationsUnread', this.newMessageReceived);

        return {
          ...this.state,
          unread: result.data.item,
          isBusy: false,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  protected newMessageReceived = (count: number) => {
    this.dispatch(this.UNREAD_NOTIFICATIONS_COUNT_RECEIVED, count);
  };

  protected onNewMessageReceived = (count: number): UnreadNotificationCountStoreState => {
    return {
      ...this.state,
      unread: count
    };
  };
}
