import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Dropdown } from 'semantic-ui-react';

import { AdminReportsAuditLogsStore, AuditLogItemDto } from 'stores/admin-reports/admin-reports-audit-logs-store';
import { TableModel, TableView } from 'widgets/collections/table';
import { DateTimeService } from 'services/datetime-service';
import { nameof, getProperties } from 'utils/object';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import { Query, OrderDefinition } from 'stores/dataStore';
import { isNullOrEmpty, isNullOrWhiteSpaces } from 'utils/useful-functions';
import moment from 'moment';

export interface AdminReportAuditLogsListProps extends RouteComponentProps, WithTranslation {
  adminReportAuditLogsStore?: AdminReportsAuditLogsStore;
}

export interface AdminReportAuditLogsListState {
  query: Query;
  activeFilters: { [key: string]: any };
  creationDateFrom: string;
  creationDateTo: string;
}

@connect(['adminReportAuditLogsStore', AdminReportsAuditLogsStore])
class AdminReportAuditLogsList extends Component<AdminReportAuditLogsListProps, AdminReportAuditLogsListState> {
  state: AdminReportAuditLogsListState = {
    activeFilters: {},

    query: {
      searchQuery: '',
      orderBy: [{ direction: 'Descending', field: nameof<AuditLogItemDto>('timestamp'), useProfile: false }],
      skip: 0,
      take: 10,
      parameters: {}
    },

    creationDateFrom: null,
    creationDateTo: null
  };

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };

    if (Object.entries(activeFilters).length === 0) {
      activeFilters['InitialDateFilter'] = {
        Timestamp: {
          ge: { type: 'raw', value: DateTimeService.toString(moment().subtract(30, 'days'), null, 'startOfDay') }
        }
      };
    } else {
      delete activeFilters['InitialDateFilter'];
    }

    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);

    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.adminReportAuditLogsStore.getAllAsync(this.state.query));
  };

  private onUserFilterChanged = (_, { value }) => {
    const parameters = this.state.query.parameters || {};

    if (isNullOrEmpty(value)) delete parameters['UserFilter'];
    else parameters['UserFilter'] = value;

    this.setState({ query: { ...this.state.query, parameters } }, this.load);
  };

  private onDescriptionFilterChanged = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrEmpty(value)) delete activeFilters['Description'];
    else activeFilters['Description'] = `contains(tolower(Description), '${value.toLowerCase()}')`;

    this.setState({ activeFilters }, this.load);
  };

  private onRelatedItemTitleFilterChanged = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrEmpty(value)) delete activeFilters['RelatedItemTitle'];
    else activeFilters['RelatedItemTitle'] = `contains(tolower(RelatedItemTitle), '${value.toLowerCase()}')`;

    this.setState({ activeFilters }, this.load);
  };

  private onTypeFilterChanged = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrEmpty(value)) delete activeFilters['Type'];
    else activeFilters['Type'] = { Type: value };

    this.setState({ activeFilters }, this.load);
  };

  private onDatesFilterChanged = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrEmpty(from);
    const toIsNull = isNullOrEmpty(to);

    if (fromIsNull && toIsNull) {
      delete activeFilters['Timestamp'];
    } else {
      activeFilters['Timestamp'] = {
        Timestamp: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters, creationDateFrom: from, creationDateTo: to }, this.load);
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy: [...orderBy] } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private auditLogTypeOptions = [
    { key: 1, text: 'Event Creation', value: 'EventCreation' },
    { key: 2, text: 'Event Edition', value: 'EventEdition' },
    { key: 3, text: 'Event Deletion', value: 'EventDeletion' },
    { key: 4, text: 'Request Creation', value: 'RequestCreation' },
    { key: 5, text: 'Request Edition', value: 'RequestEdition' },
    { key: 6, text: 'Request Deletion', value: 'RequestDeletion' },
    { key: 7, text: 'User LogIn', value: 'UserLogIn' },
    { key: 8, text: 'Notification', value: 'Notification' },
    { key: 9, text: 'Evaluation Changed', value: 'EvaluationChanged' }
  ];

  render() {
    const {
      t,
      adminReportAuditLogsStore: { state: storeState }
    } = this.props;

    const tableModel = {
      columns: [
        {
          title: t('Timestamp'),
          renderer: data => DateTimeService.toDateTimeCleanExtended(DateTimeService.toString(data?.timestamp)),
          selectableHeader: true,
          sortDefinition: { field: nameof<AuditLogItemDto>('timestamp'), useProfile: false, active: 'Descending' }
        },
        {
          title: t('Related User ID'),
          renderer: data => (isNullOrWhiteSpaces(data?.relatedUserFriendlyId) ? data?.relatedUserId : data?.relatedUserFriendlyId)
        },
        { title: t('Related User Name'), renderer: data => data?.relatedUserName },
        {
          title: t('Related Item ID'),
          renderer: data => (isNullOrWhiteSpaces(data?.relatedItemFriendlyId) ? data?.relatedItemId : data?.relatedItemFriendlyId)
        },
        { title: t('Related Item Title'), renderer: data => data?.relatedItemTitle },
        { title: t('Description'), renderer: data => data?.description },
        { title: t('Type'), renderer: data => this.auditLogTypeOptions.firstOrDefault(x => x.value === data?.type)?.text }
      ],
      data: storeState
    } as TableModel<AuditLogItemDto>;

    return (
      <div className="admin-reports__audit-logs-container">
        <h3 className="admin-reports__audit-logs-container__title">{t('Audit Logs')}</h3>
        <div className="question-bank__wrapper">
          {storeState.result && !storeState.result.isSuccess && (storeState.result.messages || []).length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={storeState.result.messages.map(o => o.body)}
              onDismiss={this.props.adminReportAuditLogsStore.clearMessages}
            />
          )}

          <div className="filters-wrapper table__filters-share-main-actions">
            <ClearableTimerInput className="filter" icon="search" placeholder={t('Search User')} onChange={this.onUserFilterChanged} />
            <ClearableTimerInput
              className="filter"
              icon="search"
              placeholder={t('Search in Description')}
              onChange={this.onDescriptionFilterChanged}
            />
            <ClearableTimerInput
              className="filter"
              icon="search"
              placeholder={t('Search in Related Item')}
              onChange={this.onRelatedItemTitleFilterChanged}
            />

            <Dropdown
              search
              inline
              selection
              closeOnChange
              closeOnEscape
              clearable={true}
              options={this.auditLogTypeOptions}
              className="filter planit-user-dropdown"
              placeholder={t('Type')}
              onChange={this.onTypeFilterChanged}
              selectOnBlur={false}
              closeOnBlur
            />
            <DatesRangeInput
              className="filter filter-dates"
              dontUseRedClearIconColor={true}
              iconPosition="right"
              fromValue={this.state.creationDateFrom}
              toValue={this.state.creationDateTo}
              onChange={this.onDatesFilterChanged}
              placeholder={t('Dates')}
              clearable
              style={{ height: 36 }}
              allowSameEndDate
            />
          </div>
          <TableView
            model={tableModel}
            onOrderByChanged={this.handleOrderBy}
            onRefresh={this.load}
            onPageChange={this.handlePageChange}
            canCreateNew={false}
            canEdit={false}
            canDelete={false}
            fixedDimmer
            noFlexContainer
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminReportAuditLogsList);
