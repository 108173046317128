import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { nameof } from 'utils/object';
import { ProfessionDto, ProfessionsStore } from 'stores/configuration/profiles/profession-roles-store';
import { EquipmentTypeDto, EquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import { connect } from 'redux-scaffolding-ts';
import { TnaSkillsStore, TnaSkillDto } from 'stores/assessments/skills/tna-skills-store';
import { SsaSkillsStore, SsaSkillDto } from 'stores/assessments/skills/ssa-skills-store';
import { IwsSkillsStore, IwsSkillDto } from 'stores/assessments/skills/iws-skills-store';
import { TestCategoryStore, TestCategoryDto } from 'stores/assessments/testCategory-store';
import { OemsStore, OemDto } from 'stores/configuration/machinery/oems-store';

export interface TNATagsFiltersValues {
  professions: string[];
  equipmentTypes: string[];
  tnaSkills: string[];
  ssaSkills: string[];
  iwsSkills: string[];
  categories: string[];
  oems: string[];
}

export interface TNATagsFiltersProps extends WithTranslation {
  className?: string;
  placeholder?: string;
  professionsStore?: ProfessionsStore;
  equipmentTypeStore?: EquipmentTypesStore;
  tnaSkillsStore?: TnaSkillsStore;
  ssaSkillsStore?: SsaSkillsStore;
  iwsSkillsStore?: SsaSkillsStore;
  testCategoryStore?: TestCategoryStore;
  oemsStore?: OemsStore;
  onChange?: (value: TNATagsFiltersValues) => void;
  mode: 'TNA' | 'SSA' | 'IWS';
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

export interface TNATagsFiltersOptions {
  id: string;
  text: string;
  type: string;
  value: any;
}

interface TNATagsFiltersState {
  open: boolean;
  isLoading: boolean;
  options: { [id: string]: TNATagsFiltersOptions };
}
@connect(
  ['professionsStore', ProfessionsStore],
  ['equipmentTypeStore', EquipmentTypesStore],
  ['oemsStore', OemsStore],
  ['testCategoryStore', TestCategoryStore],
  ['tnaSkillsStore', TnaSkillsStore],
  ['ssaSkillsStore', SsaSkillsStore],
  ['iwsSkillsStore', IwsSkillsStore]
)
class MultiSelectorTNATagsFilters extends Component<TNATagsFiltersProps, TNATagsFiltersState> {
  state: TNATagsFiltersState = {
    open: false,
    isLoading: true,
    options: {}
  };

  private handleOnSearchChange(_, searchQuery) {
    this.setState({ open: !!searchQuery });
  }

  private handleOnOptionChange(value: any) {
    let TNATagsFiltersValues = this.TNATagsFiltersValues(value);
    this.setState({ open: false });
    this.props.onChange && this.props.onChange(TNATagsFiltersValues);
  }

  private TNATagsFiltersValues(value: any): TNATagsFiltersValues {
    let TNATagsFiltersValues: TNATagsFiltersValues = {
      professions: [],
      tnaSkills: [],
      ssaSkills: [],
      iwsSkills: [],
      equipmentTypes: [],
      oems: [],
      categories: []
    };

    (value || []).forEach((key: string) => {
      let option = this.state.options[key];
      TNATagsFiltersValues[option.type].push(option.value);
    });

    return TNATagsFiltersValues;
  }

  private toDropdownOptions() {
    return Object.keys(this.state.options).map(key => {
      let option = this.state.options[key];
      return {
        text: option.text,
        value: key
      };
    });
  }

  private init(mode: 'TNA' | 'SSA' | 'IWS') {
    let requests: any[] = [];

    if (mode === 'TNA') {
      requests = [
        this.getAllProfessions(),
        this.getAllTestCategory(),
        this.getAllOEMs(),
        this.getAllEquipmentTypes(),
        this.getAllTNASkills()
      ];
    }
    if (mode === 'SSA') {
      requests = [
        this.getAllProfessions(),
        this.getAllTestCategory(),
        Promise.resolve({ items: [] }),
        Promise.resolve({ items: [] }),
        this.getAllSSASkills()
      ];
    }

    if (mode === 'IWS') {
      requests = [
        this.getAllProfessions(),
        this.getAllTestCategory(),
        Promise.resolve({ items: [] }),
        Promise.resolve({ items: [] }),
        this.getAllIWSSkills()
      ];
    }

    Promise.all([...requests]).then(([professionsResponse, testCategoryResponse, oemResponse, equipmentTypesResponse, skills]) => {
      let allOptions = {};

      this.mapOEMs(oemResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapProfessions(professionsResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapCategories(testCategoryResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapEquipmentTypes(equipmentTypesResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));

      if (mode === 'TNA') this.mapTNASkills(skills.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      if (mode === 'SSA') this.mapSSASkills(skills.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      if (mode === 'IWS') this.mapIWSSkills(skills.items).forEach(option => (allOptions[this.buildKey(option)] = option));

      this.setState({
        isLoading: false,
        options: allOptions
      });
    });
  }

  componentDidMount() {
    this.init(this.props.mode);
  }

  UNSAFE_componentWillReceiveProps(next: TNATagsFiltersProps) {
    if (next != null && next !== this.props && next.mode !== this.props.mode) {
      this.init(next.mode);
    }
  }

  private mapTNASkills(tnaSkills: TnaSkillDto[]): TNATagsFiltersOptions[] {
    return tnaSkills.map(tnaSkill => ({
      value: tnaSkill.id,
      text: tnaSkill.name,
      id: tnaSkill.id,
      type: nameof<TNATagsFiltersValues>('tnaSkills')
    }));
  }

  private mapSSASkills(ssaSkills: SsaSkillDto[]): TNATagsFiltersOptions[] {
    return ssaSkills.map(ssaSkill => ({
      value: ssaSkill.id,
      text: ssaSkill.name,
      id: ssaSkill.id,
      type: nameof<TNATagsFiltersValues>('ssaSkills')
    }));
  }
  private mapIWSSkills(iwsSkills: IwsSkillDto[]): TNATagsFiltersOptions[] {
    return iwsSkills.map(iwsSkill => ({
      value: iwsSkill.id,
      text: iwsSkill.name,
      id: iwsSkill.id,
      type: nameof<TNATagsFiltersValues>('iwsSkills')
    }));
  }

  private mapProfessions(professions: ProfessionDto[]): TNATagsFiltersOptions[] {
    return professions.map(profession => ({
      value: profession.id,
      text: profession.profession,
      id: profession.id,
      type: nameof<TNATagsFiltersValues>('professions')
    }));
  }

  private mapCategories(categories: TestCategoryDto[]): TNATagsFiltersOptions[] {
    return categories.map(category => ({
      value: category.id,
      text: category.name,
      id: category.id,
      type: nameof<TNATagsFiltersValues>('categories')
    }));
  }

  private mapEquipmentTypes(equipmentTypes: EquipmentTypeDto[]): TNATagsFiltersOptions[] {
    return equipmentTypes.map(equipmentType => ({
      value: equipmentType.id,
      text: equipmentType.name,
      id: equipmentType.id,
      type: nameof<TNATagsFiltersValues>('equipmentTypes')
    }));
  }

  private mapOEMs(oems: OemDto[]): TNATagsFiltersOptions[] {
    return oems.map(oem => ({
      value: oem.id,
      text: oem.name,
      id: oem.id,
      type: nameof<TNATagsFiltersValues>('oems')
    }));
  }

  private buildKey(option: TNATagsFiltersOptions): string {
    return `${option.id}-${option.type}`;
  }

  private getAllProfessions() {
    return this.props.professionsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<ProfessionDto>('profession'), useProfile: false }],
      filter: []
    });
  }

  private getAllOEMs() {
    return this.props.oemsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<OemDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllTNASkills() {
    return this.props.tnaSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<TnaSkillDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllSSASkills() {
    return this.props.ssaSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<SsaSkillDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllIWSSkills() {
    return this.props.iwsSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<IwsSkillDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllTestCategory() {
    return this.props.testCategoryStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<TestCategoryDto>('name'), useProfile: false }],
      filter: [this.props.tnaSkillsStore ? { AllowedForTNA: true } : { AllowedForSSA: true }]
    });
  }

  private getAllEquipmentTypes() {
    return this.props.equipmentTypeStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<EquipmentTypeDto>('name'), useProfile: false }],
      filter: []
    });
  }

  render() {
    const { placeholder, className } = this.props;

    const dropdownOptions = this.toDropdownOptions();

    return (
      <Dropdown
        placeholder={placeholder}
        className={className}
        loading={this.state.isLoading}
        multiple
        search
        clearable
        selection
        icon="search"
        open={this.state.open}
        onSearchChange={(event, { searchQuery }) => this.handleOnSearchChange(event, searchQuery)}
        onChange={(event, { value }) => this.handleOnOptionChange(value)}
        options={dropdownOptions}
        selectOnBlur={false}
        closeOnBlur
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

export default withTranslation()(MultiSelectorTNATagsFilters);
