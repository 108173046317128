import {
  TheoreticalQuestionMRItemDto,
  NmrTheoreticalQuestionItemDto,
  MediaContent
} from 'stores/assessments/questionBank/theoretical-test-store';

export const canBeSaved = (item: TheoreticalQuestionMRItemDto | NmrTheoreticalQuestionItemDto): boolean => {
  const { professions, answerType, testCategoryId, questionTranslations, trainingLevelId, option, question } = item;
  const currentAnswer = question[`option${option}`];
  const ratingFilledOpts: any =
    answerType === 'Rating'
      ? Object.keys(question || {})
          .filter(q => q.includes('option') && question[q])
          .reduce((acc, curr) => {
            acc[curr] = question[curr];
            return acc;
          }, {})
      : // .map(o => ({ [o]: question[o] }))
        [];
  let isValid = true;
  if ((professions || []).length <= 0) isValid = false;
  if (answerType == null) isValid = false;
  if (testCategoryId == null) isValid = false;
  if (trainingLevelId == null) isValid = false;
  if (option == null) isValid = false;
  if (answerType === 'Rating') {
    if (!question.optionA || !question.optionB || !question.optionC || !currentAnswer || question.text === '') isValid = false;
  } else if (answerType === 'YesOrNo') if (question.text === '') isValid = false;
  if ((questionTranslations || []).length > 0)
    isValid = !questionTranslations.some(({ text, language: { id }, optionA, optionB, optionC, optionD, optionE }) => {
      const basics = !text || id == null;
      const opts = !optionA || !optionB || !optionC;
      const extraFilledOpts = (!ratingFilledOpts?.optionD && optionD) || (!ratingFilledOpts?.optionE && optionE);
      const extraFilledTranslations = (ratingFilledOpts?.optionD && !optionD) || (ratingFilledOpts?.optionE && !optionE);

      if (answerType === 'YesOrNo') return basics;
      else if (answerType === 'Rating') return basics || opts || extraFilledOpts || extraFilledTranslations;
      return false;
    });

  if ('equipmentTypeId' in item) {
    // MR RELATED
    const { clusterId, equipmentTypeId } = item;
    if (clusterId == null) isValid = false;
    if (equipmentTypeId == null) isValid = false;
  } else if ('functionalArea' in item) {
    // NMR RELATED
    const { nmrClusterId, functionalAreaId } = item;
    if (nmrClusterId == null) isValid = false;
    if (functionalAreaId == null) isValid = false;
  }
  return isValid;
};

export const plainQuestion = { text: '', language: null, optionA: null, optionB: null, optionC: null, optionD: null, optionE: null };

export const plainObj = {
  id: null,
  friendlyId: null,
  question: { ...plainQuestion },
  questionTranslations: [],
  professions: [],
  testCategoryId: null,
  testCategory: null,
  content: null,
  mediaContentType: 'None' as MediaContent,
  imageInfo: null,
  clusterId: null,
  cluster: null,
  trainingLevelId: null,
  trainingLevel: null,
  answerType: null,
  isMandatory: false,
  option: null
};

export const plainMR = {
  equipmentTypeId: null,
  equipmentType: null,
  oemId: null,
  oem: null,
  machineModelId: null,
  machineModel: null,
  machineUnitId: null,
  machineUnit: null,
  plcTypeId: null,
  plcType: null
};

export const plainNMR = {
  nmrClusterId: null,
  nmrCluster: null,
  functionalAreaId: null,
  functionalArea: null,
  trainingNameId: null,
  trainingName: null,
  functionalSubareaId: null,
  functionalSubArea: null
};
