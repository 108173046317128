import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import i18n from 'i18n';
import { Tab } from 'semantic-ui-react';
import UsersPerLocationsListPage from './users-per-location-list';
import MachinesForLocationsListPage from './machines-per-locations-list';

export interface LocationsHomeProps extends WithTranslation, RouteComponentProps {}

export interface LocationsHomeState {
  activeTab: 'machines per location' | 'users per location';
}

class LocationsHomePage extends React.Component<LocationsHomeProps, LocationsHomeState> {
  constructor(props: LocationsHomeProps) {
    super(props);
    this.state = {
      activeTab: 'machines per location'
    };
  }

  private panes = [
    {
      menuItem: i18n.t('Machines Per Location'),
      render: () => (
        <Tab.Pane attached={false}>
          <MachinesForLocationsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Users Per Location'),
      render: () => (
        <Tab.Pane attached={false}>
          <UsersPerLocationsListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Locations Management')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(LocationsHomePage);
