import React, { FC, useState } from 'react';
import { Icon, Input, InputProps, InputOnChangeData } from 'semantic-ui-react';

export const ClearableInput: FC<InputProps> = (props: InputProps) => {
  const [value, setValue] = useState(props.value || '');
  const isEmpty = value == null || value === '';
  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setValue(data.value);
    if (props.onChange) props.onChange(event, data);
  };
  const icon = <Icon name="delete" link onClick={props.onChange ? e => onChangeWrapper(e, { ...props, value: '' }) : undefined} />;
  return <Input {...props} onChange={onChangeWrapper} icon={isEmpty ? props.icon : icon} value={value} />;
};
