import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SsaCheckpointAnswersDto } from 'stores/assessments/forms/ssa-forms-store';
import { Accordion, Icon, AccordionTitleProps } from 'semantic-ui-react';

interface SSAFormCheckpointComponentProps extends WithTranslation {
  index: number;
  checkpoint: SsaCheckpointAnswersDto;
  activeIndex: number;
  currentLanguage: string;
  handleToggleCheckpoint: (index: number) => void;
  questionIndexOffset: number;
}

class SSAFormCheckpointComponent extends Component<SSAFormCheckpointComponentProps> {
  handleClickAcordion = (_: any, { index }: AccordionTitleProps) => {
    const { activeIndex } = this.props;
    const newIndex = activeIndex === index ? -1 : index;
    this.props.handleToggleCheckpoint(+newIndex);
  };

  getCurrentQuestionLanguage = (idx: number): string => {
    const { currentLanguage, checkpoint } = this.props;
    const question = (checkpoint.questionAnswers[idx].questionTranslations || []).find(({ languageId }) => languageId === currentLanguage);
    return question
      ? question.text
      : checkpoint.questionAnswers[idx].question
      ? checkpoint.questionAnswers[idx].question.text
      : 'Empty question';
  };

  render() {
    const { checkpoint, index, activeIndex, questionIndexOffset } = this.props;
    const icon = `angle ${activeIndex === index ? 'up' : 'down'}` as any;

    return (
      <>
        <Accordion.Title
          className="ssa-form__check-accordion__title"
          index={index}
          active={activeIndex === index}
          onClick={this.handleClickAcordion}
        >
          {checkpoint.description}
          <div className="ssa-form__checkpoint-right">
            <p>Questions</p>
            <Icon name={icon} />
          </div>
        </Accordion.Title>
        <Accordion.Content className="ssa-form__check-accordion__content" key={`ssa-form-accordion${index}`} active={activeIndex === index}>
          {(checkpoint.questionAnswers || []).map((_, i) => (
            <p>{`Question ${questionIndexOffset + i}. ${this.getCurrentQuestionLanguage(i)}`}</p>
          ))}
        </Accordion.Content>
      </>
    );
  }
}

export default withTranslation()(SSAFormCheckpointComponent);
