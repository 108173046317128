import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { PillarDto, ChangePillarDto, ChangePillarStore, PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';
import { nameof } from 'utils/object';

interface ChangePillarViewProps extends WithTranslation {
  onClose: () => void;
  changePillar?: ChangePillarStore;
  currentDto: PillarDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangePillarViewState {
  editorValue: any;
}

@connect(['changePillar', ChangePillarStore])
class ChangePillarView extends React.Component<ChangePillarViewProps, ChangePillarViewState> {
  private get changePillarStore() {
    return this.props.changePillar;
  }

  constructor(props: ChangePillarViewProps) {
    super(props);

    this.changePillarStore.state.result = null;

    this.changePillarStore.change({
      id: this.props.currentDto.id,
      name: this.props.currentDto.name,
      managedBy: this.getOption(this.props.currentDto.managedBy)
    });
  }

  private getOption(option?: PlanitPlannerTypes) {
    if (option === PlanitPlannerTypes.plannerTFT) return 1;
    if (option === PlanitPlannerTypes.plannerMTC) return 2;

    return null;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangePillarViewProps) {
    if (this.changePillarStore.state.result && this.changePillarStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onChangeItem() {
    this.changePillarStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changePillarStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changePillarStore.change({ ...this.changePillarStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result } = this.changePillarStore.state;

    const managedByTypes = [
      {
        key: 1,
        text: 'Planner TFT',
        value: 1
      },
      {
        key: 2,
        text: 'Planner MTC',
        value: 2
      }
    ];

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changePillarStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Pillar')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changePillarStore.state.result && !this.changePillarStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changePillarStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changePillarStore.state.item && (
              <Form loading={this.changePillarStore.state.isBusy}>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changePillarStore.state.item.name}
                    label={t('Pillar')}
                    placeholder={t('Name')}
                    value={this.changePillarStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangePillarDto>('name'), value)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <div className={`field inline configuration-event-n-request__modal__element`}>
                    <label>{t('Managed by')}</label>
                    <Dropdown
                      placeholder={t('Managed by')}
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(x => x.propertyName === nameof<ChangePillarDto>('managedBy'))
                      }
                      options={managedByTypes}
                      value={this.changePillarStore.state.item.managedBy}
                      onChange={(e, { value }) => this.handleValue(nameof<ChangePillarDto>('managedBy'), value)}
                    />
                  </div>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangePillarView);
