import React, { Component } from 'react';
import Step from 'widgets/form/templates-step-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import PracticalTemplateQuestionComponent from './practical-question-component';
import ChooseMrPracticalQuestionsView from './choose-mr-practical-questions';
import ChooseNmrPracticalQuestionsView from './choose-nmr-practical-questions';

import './questions-step.less';
import {
  PracticalTemplateWizardViewModel,
  PracticalTestQuestionViewModel,
  mapToMrQuestionViewModel,
  mapToNmrQuestionViewModel
} from '../../practical-template-wizard-utils';

export interface PracticalTemplateWizardQuestionsStepsProps extends WithTranslation {
  active: boolean;
  mode: 'Edit' | 'Create';
  viewModel: PracticalTemplateWizardViewModel;
  onChange: (model: PracticalTemplateWizardViewModel) => void;
}

export interface PracticalTemplateWizardQuestionsStepsState {
  showQuestionsModal: boolean;
}

class PracticalTemplateWizardQuestionsSteps extends Component<
  PracticalTemplateWizardQuestionsStepsProps,
  PracticalTemplateWizardQuestionsStepsState
> {
  state = { showQuestionsModal: false };

  private toShowQuestionsModal = () => {
    this.setState({ showQuestionsModal: true });
  };

  private hideQuestionsModal = () => {
    this.setState({ showQuestionsModal: false });
  };

  private handleMoveUpQuestion = (questionIdx: number) => {
    const { viewModel } = this.props;
    const questions = (viewModel.machineRelated ? viewModel.mrQuestions : viewModel.nmrQuestions) || [];

    if (questionIdx - 1 < 0) return;

    const tmp = questions[questionIdx - 1];
    questions[questionIdx - 1] = questions[questionIdx];
    questions[questionIdx] = tmp;

    this.props.onChange(viewModel);
  };

  private handleMoveDownQuestion = (questionIdx: number) => {
    const { viewModel } = this.props;
    const questions = (viewModel.machineRelated ? viewModel.mrQuestions : viewModel.nmrQuestions) || [];

    if (questionIdx + 1 >= questions.length) return;

    const tmp = questions[questionIdx + 1];
    questions[questionIdx + 1] = questions[questionIdx];
    questions[questionIdx] = tmp;
    this.props.onChange(viewModel);
  };

  private moveQuestionHandler = (dir: 'up' | 'down', idx: number) => {
    if (dir === 'up') this.handleMoveUpQuestion(idx);
    if (dir === 'down') this.handleMoveDownQuestion(idx);
  };

  private removeQuestionHandler = (idx: number) => {
    const { viewModel } = this.props;

    if (viewModel.machineRelated) viewModel.mrQuestions.splice(idx, 1);
    else viewModel.nmrQuestions.splice(idx, 1);

    this.props.onChange(viewModel);
  };

  private addQuestionsHandler = (questions: PracticalTestQuestionViewModel[]) => {
    const { viewModel } = this.props;

    if (viewModel.machineRelated) {
      viewModel.mrQuestions = [...viewModel.mrQuestions, ...questions];
    } else {
      viewModel.nmrQuestions = [...viewModel.nmrQuestions, ...questions];
    }
    this.props.onChange(viewModel);
  };

  render() {
    const { t, active, viewModel } = this.props;
    const { showQuestionsModal } = this.state;
    const {
      mrQuestions,
      nmrQuestions,
      machineRelated,
      profession,
      mrCluster,
      equipmentType,
      oem,
      machineModel,
      nmrCluster,
      functionalArea,
      functionalSubarea,
      trainingName
    } = viewModel;

    return (
      <div className="practical-template-wizard__questions-container">
        <Step
          active={active}
          number={3}
          title={t('SELECT QUESTIONS')}
          render={
            <div className="third-step__content">
              <div className="ssa-wizard__checkpoint-container">
                <div className="ssa-wizard__add-question-btn">
                  <Button onClick={this.toShowQuestionsModal} className="ssa-wizard__add-questions-btn" type="button">
                    {t('Add Questions')}
                  </Button>
                </div>
                {machineRelated &&
                  (mrQuestions || []).map((question: PracticalTestQuestionViewModel, i) => (
                    <PracticalTemplateQuestionComponent
                      key={i}
                      move={dir => this.moveQuestionHandler(dir, i)}
                      canGoUp={i > 0}
                      canGoDown={i + 1 < mrQuestions.length}
                      question={question}
                      onRemoveQuestion={() => this.removeQuestionHandler(i)}
                      index={i}
                    />
                  ))}
                {!machineRelated &&
                  (nmrQuestions || []).map((question: PracticalTestQuestionViewModel, i) => (
                    <PracticalTemplateQuestionComponent
                      key={i}
                      move={dir => this.moveQuestionHandler(dir, i)}
                      canGoUp={i > 0}
                      canGoDown={i + 1 < nmrQuestions.length}
                      question={question}
                      onRemoveQuestion={() => this.removeQuestionHandler(i)}
                      index={i}
                    />
                  ))}
              </div>
              {showQuestionsModal && machineRelated && (
                <ChooseMrPracticalQuestionsView
                  professionId={profession?.id ?? undefined}
                  clusterId={mrCluster?.id ?? undefined}
                  equipmentTypeId={equipmentType?.id ?? undefined}
                  oemId={oem?.id ?? undefined}
                  machineModelIds={machineModel ? machineModel.map(x => x.id) : undefined}
                  onAddPracticalQuestions={questions => this.addQuestionsHandler(mapToMrQuestionViewModel(questions))}
                  onCloseModal={this.hideQuestionsModal}
                  alreadySelectedQuestions={mrQuestions.map(({ id }) => id)}
                />
              )}
              {showQuestionsModal && !machineRelated && (
                <ChooseNmrPracticalQuestionsView
                  professionId={profession?.id}
                  functionalAreaId={functionalArea?.id ?? undefined}
                  functionalSubAreaId={functionalSubarea?.id ?? undefined}
                  clusterId={nmrCluster?.id ?? undefined}
                  trainingNameId={trainingName?.id ?? undefined}
                  onAddPracticalQuestions={questions => this.addQuestionsHandler(mapToNmrQuestionViewModel(questions))}
                  onCloseModal={this.hideQuestionsModal}
                  alreadySelectedQuestions={nmrQuestions.map(({ id }) => id)}
                />
              )}
            </div>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(PracticalTemplateWizardQuestionsSteps);
