import * as React from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PublicHolidaysTab, { PublicHolidayConf } from './config-tabs/public-holidays-tab';
import './scheduler-poc-config.style.less';
import WorkingDaysTab from './config-tabs/working-days-tab';
import { CreateWorkingDaysDto } from 'stores/working-days/working-days-store';

export interface SchedulerPoCConfigProps extends WithTranslation {
  onClose?: (data?: PublicHolidayConf | CreateWorkingDaysDto) => void;
}
const SchedulerPoCConfig: React.FC<SchedulerPoCConfigProps> = ({ t, onClose }) => {
  return (
    <Modal size="small" className="scheduler-poc-config" open closeOnEscape={true} onClose={() => onClose()}>
      <Modal.Header className="scheduler-poc-config__header">{t('Calendar Configuration')}</Modal.Header>
      <Modal.Content className="scheduler-poc-config__content">
        <Tab
          className="scheduler-poc-config__tabs"
          menu={{ secondary: true, pointing: true }}
          panes={[
            { menuItem: t('Public Holidays'), render: () => <PublicHolidaysTab onClose={onClose} /> },
            { menuItem: t('Working Days'), render: () => <WorkingDaysTab onClose={onClose} /> }
          ]}
        />
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(React.memo(SchedulerPoCConfig));
