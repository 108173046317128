import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import EventRequestChanges from '../popups/request-changes-popup/request-changes-popup';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { isUserEventOwner, isCurrentUserEventInstructor } from 'utils/event-utils';
import { resolve } from 'inversify-react';
import { IdentityService, Roles } from 'services/identity-service';

export interface RequestChangesButtonProps extends WithTranslation {
  eventFormStore?: EventFormStore;
  onRequestChangesSubmitted?: () => void;
  readOnly: boolean;
}

export interface RequestChangesButtonState {
  showRequestChangesModal: boolean;
}

@connect(['eventFormStore', EventFormStore])
class RequestChangesButton extends Component<RequestChangesButtonProps, RequestChangesButtonState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: RequestChangesButtonState = { showRequestChangesModal: false };

  private handleOnRequestChangesClicked = () => {
    this.setState({ showRequestChangesModal: true });
  };

  private handleOnRequestChangesSent = () => {
    this.closeModal();
    this.props.onRequestChangesSubmitted && this.props.onRequestChangesSubmitted();
  };

  private closeModal = () => this.setState({ showRequestChangesModal: false });

  private isRequestChangesVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      (event.status.toString() === 'InProgress' || event.status.toString() === 'Planned') &&
      !isUserEventOwner(event) &&
      (!this.identityService.isInRole(Roles.Instructor) || isCurrentUserEventInstructor(event)) &&
      !this.identityService.isInRole(Roles.Employee) &&
      !this.identityService.isInRole(Roles.Reporting) &&
      !this.identityService.isInRole(Roles.GlobalEngineering) &&
      !this.identityService.isInRole(Roles.GlobalManufacturing) &&
      !this.identityService.isInRole(Roles.RegionalManufacturingVP)
    );
  };

  render() {
    const { t, readOnly } = this.props;
    const event = this.props.eventFormStore.state.item;
    const isVisible = this.isRequestChangesVisible();

    if (!isVisible) return null;

    return (
      <>
        <Button
          basic
          disabled={!readOnly}
          className="form__actions__button form__white-btn form__request-changes-btn"
          content={t('Request Changes')}
          onClick={this.handleOnRequestChangesClicked}
        />

        <EventRequestChanges
          open={this.state.showRequestChangesModal}
          eventId={event.id}
          onCancelClicked={this.closeModal}
          onSendClicked={this.handleOnRequestChangesSent}
        />
      </>
    );
  }
}

export default withTranslation()(RequestChangesButton);
