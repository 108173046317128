import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from 'widgets/auth/private-route';
import HomePage from './home';

const App: React.FC = (props: any) => {
  return (
    <Switch location={props.location}>
      <PrivateRoute component={HomePage} />
    </Switch>
  );
};

export default App;
