import { PositionCodesStore, NewPositionCodeStore, ChangePositionCodeStore, DropDownPositionCodesStore } from './position-codes-store';
import { ProfessionsStore, NewProfessionStore, ChangeProfessionStore, DropDownProfessionsStore } from './profession-roles-store';
import { InstructorRoleStore } from './instructor-roles-store';
import { PillarsStore, DropDownPillarsStore, NewPillarStore, ChangePillarStore } from './pillars-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new PositionCodesStore());
  storeBuilder.addRepository(new NewPositionCodeStore());
  storeBuilder.addRepository(new ChangePositionCodeStore());
  storeBuilder.addRepository(new DropDownPositionCodesStore());
  storeBuilder.addRepository(new InstructorRoleStore());
  storeBuilder.addRepository(new ProfessionsStore());
  storeBuilder.addRepository(new DropDownProfessionsStore());
  storeBuilder.addRepository(new NewProfessionStore());
  storeBuilder.addRepository(new ChangeProfessionStore());
  storeBuilder.addRepository(new PillarsStore());
  storeBuilder.addRepository(new DropDownPillarsStore());
  storeBuilder.addRepository(new NewPillarStore());
  storeBuilder.addRepository(new ChangePillarStore());
}
