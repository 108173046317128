import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EventFormStore, EventSupportDetailsViewModel } from 'stores/events/event-form-store';
import SupportDetails from './support-details.component';
import NewSupportDetails from './new-support-details.component';
import { connect } from 'redux-scaffolding-ts';

export interface SupportDetailsTabPaneProps extends WithTranslation {
  readOnly: boolean;
  eventFormStore?: EventFormStore;
  isHideEditButton: boolean;
}

@connect(['eventFormStore', EventFormStore])
class SupportDetailsTabPane extends Component<SupportDetailsTabPaneProps> {
  private handleOnStudentsChange = (supportDetails: EventSupportDetailsViewModel) => {
    this.props.eventFormStore.change({ supportDetails });
  };

  render() {
    const { readOnly, eventFormStore, isHideEditButton } = this.props;
    const { item } = eventFormStore.state;
    return (
      <div>
        {!item.supportDetails?.isNewSupportPositionModel && (
          <SupportDetails
            supportDetails={item.supportDetails}
            onChange={this.handleOnStudentsChange}
            readonly={readOnly}
            isHideEditButton={isHideEditButton}
            eventFormStore={eventFormStore}
          />
        )}
        {item.supportDetails?.isNewSupportPositionModel && (
          <NewSupportDetails
            supportDetails={item.supportDetails}
            onChange={this.handleOnStudentsChange}
            readonly={readOnly}
            isHideEditButton={isHideEditButton}
            eventFormStore={eventFormStore}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(SupportDetailsTabPane);
