import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { FeedBackFormViewModelStore } from 'stores/assessments/forms/feedback-form.store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Divider, Button } from 'semantic-ui-react';
import './feedback-form.less';

export interface FeedbackFormFooterProps extends WithTranslation {
  feedBackFormAnswersStore?: FeedBackFormViewModelStore;
}

@connect(['feedBackFormAnswersStore', FeedBackFormViewModelStore])
class FeedbackFormFooter extends Component<FeedbackFormFooterProps> {
  private handleOnSendFeebackClicked = () => {
    this.props.feedBackFormAnswersStore.sendFeedbackFormAnswers().then(response => {
      if (!!response) {
        this.props.feedBackFormAnswersStore.change({ readOnly: true });
        window.close();
      }
    });
  };

  render() {
    const { t, feedBackFormAnswersStore: store } = this.props;
    const { readOnly } = store.state.item;
    return (
      <>
        <Divider />
        <footer className="feedback-form__footer">
          {!readOnly && (
            <Button className="inverted-color-btn" onClick={this.handleOnSendFeebackClicked} positive>
              {t('Send Feedback')}
            </Button>
          )}
        </footer>
      </>
    );
  }
}

export default withTranslation()(FeedbackFormFooter);
