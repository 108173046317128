import React, { Component } from 'react';
import { Input, TextArea, Dropdown } from 'semantic-ui-react';
import Step from 'widgets/form/templates-step-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import './general-data-step.less';
import RolesSelector from 'widgets/bussiness/selectors/roles-selector';
import { connect } from 'redux-scaffolding-ts';
import {
  TheoreticalTemplateWizardStore,
  TheoreticalTemplateWizardViewModel
} from 'stores/assessments/wizard/theoretical-template-wizard-store';
import BooleanEditor from 'widgets/bussiness/boolean-editor';
import TheoreticalRelatedToEditor from 'widgets/bussiness/theoretical-related-to-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { ProfileItemDto } from 'stores/profile/profile-store';

export interface GeneralDataStepProps extends WithTranslation {
  active: boolean;
  theoreticalTemplateWizardStore?: TheoreticalTemplateWizardStore;
  onStepChanged?: () => void;
  mode: string;
}

@connect(['theoreticalTemplateWizardStore', TheoreticalTemplateWizardStore])
class GeneralDataStep extends Component<GeneralDataStepProps> {
  private handleOnChange = (property: keyof TheoreticalTemplateWizardViewModel, value: any) => {
    let changes: any = {};
    if (property === 'machineRelated' && value === false) changes.relatedTo = 'Event';
    if (property === 'relatedTo' && value === 'TNA') changes.machineRelated = true;
    changes[property] = value;

    this.props.theoreticalTemplateWizardStore.resetData(changes);
    this.props.onStepChanged && this.props.onStepChanged();
  };

  private getDurationOptions = () => {
    let options = [];
    for (let i = 1; i <= 20; i++) {
      options.push({ text: i + ' hours', value: i });
    }
    return options;
  };

  private getEventStatusOptions = () => {
    return [
      { text: 'In Progress', value: 'InProgress' },
      { text: 'Completed', value: 'Completed' }
    ];
  };

  private handleOnProfileChange = (profile: ProfileItemDto) => {
    let changes: Partial<TheoreticalTemplateWizardViewModel> = {
      professionId: profile?.professionId,
      profileId: profile?.id
    };

    this.props.theoreticalTemplateWizardStore.resetData(changes);
    this.props.onStepChanged && this.props.onStepChanged();
  };

  render() {
    const { t, active, mode } = this.props;
    const {
      title,
      header,
      professionId,
      profileId,
      machineRelated,
      relatedTo,
      status,
      duration
    } = this.props.theoreticalTemplateWizardStore.state.item;
    let statusValue = null;
    if (status?.toString() === 'InProgress') statusValue = 'InProgress';
    else if (status?.toString() === 'Completed') statusValue = 'Completed';
    return (
      <div className="theoretical-template-wizard__first-step">
        <Step
          active={active}
          number={1}
          title={t('IDENTIFY YOUR NEW TEMPLATE')}
          render={
            <div className="first-step__content">
              <div className="title">
                <label>{t('Title')}</label>
                <Input value={title || ''} onChange={(c, { value }) => this.handleOnChange('title', value)} />
              </div>
              <div className="header">
                <label>{t('Header (Optional)')}</label>
                <TextArea
                  value={header || ''}
                  onChange={(c, { value }) => this.handleOnChange('header', value)}
                  className="theoretical-wizard__textarea"
                />
              </div>
              <div className="flex-start-center first-step__filters-container">
                <div className="flex-start-center">
                  <label>{t('Machine Related')}</label>
                  <BooleanEditor
                    clearable
                    placeholder={t('Machine Related')}
                    value={machineRelated}
                    className="planit-user-dropdown-custom"
                    onChange={mr => this.handleOnChange('machineRelated', mr)}
                    readOnly={mode === 'Edit'}
                  />
                </div>

                <div className="flex-start-center">
                  <label>{t('Related to')}</label>

                  <TheoreticalRelatedToEditor
                    clearable
                    value={relatedTo}
                    className="planit-user-dropdown-custom"
                    placeholder={'Select Event or TNA'}
                    onChange={relatedTo => this.handleOnChange('relatedTo', relatedTo)}
                    readOnly={mode === 'Edit'}
                  />
                </div>

                <div className="flex-start-center">
                  <label>{t('Role')}</label>
                  {relatedTo?.toString() === 'Event' ? (
                    <RolesSelector
                      clearable
                      className="planit-user-dropdown-custom"
                      placeholder="Select Role"
                      value={professionId}
                      onChange={role => this.handleOnChange('professionId', role)}
                      readOnly={mode === 'Edit' && !!professionId}
                    />
                  ) : (
                    <ProfileEditor
                      readonly={relatedTo?.toString() !== 'TNA' || mode === 'Edit'}
                      assessment="TNA"
                      placeholder="Select Role"
                      value={profileId}
                      onChange={this.handleOnProfileChange}
                    />
                  )}
                </div>

                {relatedTo && relatedTo.toString() === 'Event' && (
                  <div className="flex-start-center">
                    <label>{t('Status')}</label>
                    <Dropdown
                      selection
                      value={statusValue}
                      className="planit-user-dropdown-custom"
                      placeholder={'Event status'}
                      options={this.getEventStatusOptions()}
                      clearable
                      onChange={(_, { value: status }) => this.handleOnChange('status', status ? status : null)}
                    />
                  </div>
                )}

                <div className="flex-start-center">
                  <label>{t('Set Duration')}</label>
                  <Dropdown
                    clearable
                    selection
                    options={this.getDurationOptions()}
                    value={duration}
                    className="planit-user-dropdown-custom"
                    placeholder={'Set Duration in hours'}
                    onChange={(_, { value: duration }) => this.handleOnChange('duration', duration ? duration : null)}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(GeneralDataStep);
