import i18n from 'i18n';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import DeliveryListPage from './delivery-method-list';
import EventTypeList from './event-type-list';

export interface DeliveryMethoConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface DeliveryMethoConfigurationHomeState {}

class DeliveryMethoConfigurationHomePage extends React.Component<DeliveryMethoConfigurationHomeProps, DeliveryMethoConfigurationHomeState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  private panes = [
    {
      menuItem: i18n.t('Event Types'),
      render: () => (
        <Tab.Pane attached={false}>
          <EventTypeList />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Delivery Methods'),
      render: () => (
        <Tab.Pane attached={false}>
          <DeliveryListPage />
        </Tab.Pane>
      )
    }
  ];

  public render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('SF Mappings')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(DeliveryMethoConfigurationHomePage);
