import { ValidationResult } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import { DataStore } from '../dataStore';
import { BaseDto } from '../types';

export interface InstructorRoleDto extends BaseDto {
  id: string;
  name: string;
}

@repository('@@INSTRUCTORROLES', 'instructor-roles.summary')
export class InstructorRolesStore extends DataStore<InstructorRoleDto> {
  baseUrl = 'identity/api/v1';
  createPath = '';
  retrievePath = 'get-instructor-roles';
  updatePath = '';
  deletePath = '';

  protected validate(item: InstructorRoleDto) {
    return new ValidationResult();
  }

  constructor() {
    super('INSTRUCTORROLE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
