import { ItemReference } from 'stores/dataStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CheckListDto, ChangeCheckListStore, ChangeCheckListDto } from 'stores/configuration/events-n-requests/checklists-store';
import { connect } from 'redux-scaffolding-ts';
import React from 'react';
import * as autobind from 'autobind';
import { Modal, Container, Message, Form, Button, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';

interface ChangeCheckListViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeCheckList?: ChangeCheckListStore;
  currentCheckList: CheckListDto;
}

interface ChangeCheckListViewState {
  eventType: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changeCheckList', ChangeCheckListStore])
class ChangeCheckListView extends React.Component<ChangeCheckListViewProps, ChangeCheckListViewState> {
  private get changeCheckListStore() {
    return this.props.changeCheckList;
  }

  constructor(props: ChangeCheckListViewProps) {
    super(props);

    this.state = {
      eventType: { id: this.props.currentCheckList.eventTypeId, title: this.props.currentCheckList.eventTypeName },
      userClickedSaved: false
    };

    this.changeCheckListStore.state.result = null;

    this.changeCheckListStore.change({
      id: this.props.currentCheckList.id,
      description: this.props.currentCheckList.description,
      eventTypeId: this.props.currentCheckList.eventTypeId,
      rolesThatCreateCheckLists: this.props.currentCheckList.rolesThatCreateCheckLists
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeCheckListViewProps) {
    if (this.changeCheckListStore.state.result && this.changeCheckListStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeCheckListStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeCheckListStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeCheckListStore.change({ ...this.changeCheckListStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result } = this.changeCheckListStore.state;
    const roles = [
      { name: 'Employee', description: 'Employee' },
      { name: 'Planner', description: 'Planner TFT' },
      { name: 'PoC', description: 'PoC' },
      { name: 'Instructor', description: 'Instructor' },
      { name: 'PlannerMTC', description: 'Planner MTC' }
    ];

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeCheckListStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Check List')}</Modal.Header>
        <Modal.Content image>
          <Container className="configuration-event-n-request__modal">
            {this.changeCheckListStore.state.result && !this.changeCheckListStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeCheckListStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeCheckListStore.state.item && (
              <Form>
                <div className="configuration-event-n-request__modal__element">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeCheckListStore.state.item.description}
                    label={t('Description')}
                    placeholder={t('Description')}
                    value={this.changeCheckListStore.state.item.description}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeCheckListDto>('description'), value)}
                  />
                </div>

                <div className={`required field inline configuration-event-n-request__modal__element`}>
                  <label>{t('Event Type')}</label>
                  <EventTypeEditor
                    error={!this.changeCheckListStore.state.item.eventTypeId && this.state.userClickedSaved}
                    placeholder={t('Event Type')}
                    nullable
                    value={this.state.eventType}
                    onChange={c => {
                      this.changeCheckListStore.state.item.eventTypeId = c.id;
                      this.setState({ userClickedSaved: false });
                    }}
                    directOnly={false}
                  />
                </div>

                <div className={`required field inline configuration-event-n-request__modal__element`}>
                  <label>{t('Roles')}</label>
                  <Dropdown
                    placeholder={t('Roles')}
                    search
                    selection
                    multiple
                    closeOnChange
                    closeOnBlur
                    closeOnEscape
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      (result.messages || []).any(x => x.propertyName === nameof<ChangeCheckListDto>('rolesThatCreateCheckLists'))
                    }
                    options={roles.map(x => ({
                      key: `rolesThatCreateCheckLists-${x.name}`,
                      text: x.description,
                      value: x.name,
                      content: x.description
                    }))}
                    value={this.changeCheckListStore.state.item.rolesThatCreateCheckLists}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeCheckListDto>('rolesThatCreateCheckLists'), value)}
                  />
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeCheckListView);
