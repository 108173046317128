import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Message, Dimmer, Loader, Divider, Modal, Button } from 'semantic-ui-react';
import HeaderComponent from './containers/theoretical-form-header';
import MainComponent from './containers/theoretical-form-main';
import FooterComponent from './containers/theoretical-form-footer';
import { connect } from 'redux-scaffolding-ts';
import {
  TheoreticalTestFormStore,
  TheoreticalFormDto,
  TheoreticalFormView,
  mapToTheoreticalFormView,
  TheoreticalFormAnswerDto
} from 'stores/assessments/forms/theoretical-form-test-store';
import './theoretical-form-styles.less';
import { RouteComponentProps } from 'react-router';
import { resolve } from 'inversify-react';
import { IdentityService, Roles } from 'services/identity-service';
import { goToPage } from 'utils/useful-functions';
import { ToastComponent } from '../landing-pages/util/toast-component';
import { ItemResult } from 'stores/types';
import queryString from 'query-string';
import NotAvailableTest from 'site/not-available-component';

interface TheoreticalFormTestProps extends WithTranslation, RouteComponentProps {
  theoreticalFormStore?: TheoreticalTestFormStore;
}

interface TheoreticalFormTestState {
  noFooter: boolean;
  loading: boolean;
  isTestStudent: boolean;
  showConfirmInitUserTestModal: boolean;
  answers: TheoreticalFormAnswerDto;
  showExam: boolean;
  showTimeFinishedModal: boolean;
  noForm: boolean;
  hasTime: boolean;
  showChangeRoleModal: boolean;
}

@connect(['theoreticalFormStore', TheoreticalTestFormStore])
class TheoreticalFormTest extends Component<TheoreticalFormTestProps, TheoreticalFormTestState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: TheoreticalFormTestState = {
    isTestStudent: false,
    noForm: false,
    loading: false,
    noFooter: true,
    showConfirmInitUserTestModal: false,
    answers: null,
    showExam: false,
    showTimeFinishedModal: false,
    hasTime: true,
    showChangeRoleModal: false
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { match, theoreticalFormStore, location } = this.props;
    const id = match.params['id'].toString();

    let values = queryString.parse(location.search);
    const isEmployee = this.isEmployee();
    const fromTnaFormId = values?.FromTna as string;
    const form = await this.getForm(id);
    const answers = await this.getFormAnswers(id, fromTnaFormId);

    if (!form || !answers) return;

    const isTestStudent = this.isTestStudent(answers?.userId);
    const correctTestWrongRole = isTestStudent && !isEmployee && !answers.answered;
    this.setState({ isTestStudent, showChangeRoleModal: correctTestWrongRole });
    const newItem = mapToTheoreticalFormView(form, answers);
    theoreticalFormStore.createNew(newItem);

    this.setState({ answers, showConfirmInitUserTestModal: true, showExam: this.hasToShowExam(answers) });
  };

  hasToShowExam = (answers: TheoreticalFormAnswerDto) => {
    if (this.isAdminPocPlannerOrInstructor() && answers.answered) {
      return true;
    }
    return answers.answered;
  };

  initTheoreticalTest = async () => {
    this.setState({ showConfirmInitUserTestModal: false });
    const isEmployee = this.isEmployee();
    const { answers } = this.state;
    const { theoreticalFormStore } = this.props;

    if (!answers.endedAt) this.setState({ noFooter: false });

    if (isEmployee && !answers.endedAt) {
      const initialization = await this.submitForm(true);
      if (initialization?.isSuccess) {
        theoreticalFormStore.change({ startedAt: initialization.item.startedAt });
        this.setState({ showExam: true });
      }
    }
  };

  getForm = async (id: string): Promise<TheoreticalFormDto> => {
    this.setState({ loading: true });
    const { userId } = this.identityService.getUserInfo();
    try {
      const result = await this.props.theoreticalFormStore.getFormById(id);
      const student = userId === result?.userId && this.isEmployee();
      const noForm = !result || (student && (!!result.finishedAt || !!result.startedAt));
      this.setState({ loading: false, noForm });
      return result;
    } catch (error) {
      this.setState({ loading: false, noForm: !!this.isEmployee() });
      console.error({ error });
      !this.isEmployee() && goToPage('/NotFound');
    }
  };

  getFormAnswers = async (id: string, fromTnaFormId?: string): Promise<TheoreticalFormAnswerDto> => {
    this.setState({ loading: true });
    const isEmployee = this.isEmployee();

    try {
      const result = await this.props.theoreticalFormStore.getFormAnswerById(id, fromTnaFormId);
      this.setState({ loading: false });
      return result;
    } catch (error) {
      this.setState({ loading: false, noForm: isEmployee });
      console.error({ error });
      !isEmployee && goToPage('/NotFound');
    }
  };

  submitForm = async (first: boolean = false, finishing: boolean = false) => {
    this.setState({ loading: true });
    const { theoreticalFormStore } = this.props;
    try {
      const result = await theoreticalFormStore.submitAnswer(first, finishing);
      this.setState({ loading: false });
      return result;
    } catch (error) {
      this.setState({ loading: false });
      console.error({ error });
    }
  };

  isTestStudent = (id: string) => this.identityService.getUserInfo().userId === id;
  isEmployee = () => this.identityService.isInRole(Roles.Employee);

  submitTest = async () => {
    const result = await this.submitForm(false, true);
    if (!result?.isSuccess) return result;

    ToastComponent({ text: this.props.t('Form successfully submitted!'), type: 'success-toast' });
    this.props.history.replace('/');
  };

  submitQuestion = async (item: TheoreticalFormView): Promise<ItemResult<TheoreticalFormAnswerDto>> => {
    const { questionIdx, questionAnswers, answered, finishedTime } = item;
    const currentQuestion = questionAnswers[questionIdx];
    const isNotAnswered = !answered && !currentQuestion?.answered;
    const isEmployee = this.isEmployee();
    let result;
    if (isEmployee && isNotAnswered && !finishedTime && this.state.isTestStudent) {
      if (questionIdx === questionAnswers.length - 1) this.submitTest();
      else result = await this.submitForm();
    }
    return result;
  };

  changeQuestionHandler = async (property: keyof TheoreticalFormView, value: any) => {
    const { theoreticalFormStore } = this.props;
    const { questionIdx, questionAnswers } = theoreticalFormStore.state.item;

    if (value > questionAnswers.length || questionIdx < 0) return;
    const change: Partial<TheoreticalFormView> = { [property]: value };
    const submitResult = await this.submitQuestion(theoreticalFormStore.state.item);
    if (submitResult?.isSuccess)
      change.questionAnswers = (questionAnswers || []).map((item, i) => (i === questionIdx ? { ...item, answered: true } : item));

    theoreticalFormStore.change(change);
  };

  changeHandler = async (property: keyof TheoreticalFormView, value: any) => {
    const { theoreticalFormStore } = this.props;
    theoreticalFormStore.change({ [property as string]: value });
    if (property === 'finishedTime' && !!value) {
      this.setState({ showTimeFinishedModal: true, showExam: false });
      const result = await this.submitForm(false, true);
      if (!result?.isSuccess) return result;
    }
  };

  toggleFooter = (noFooter: boolean) => {
    this.setState({ noFooter });
  };

  private closeConfirmModalAndRedirectHome = () => {
    this.setState({ showConfirmInitUserTestModal: false });
    this.goToHome();
  };

  private isAdminPocPlannerOrInstructor = (): boolean => {
    const currentUserInfo = this.identityService.getUserInfo();
    return IdentityService.isAdminPocPlannerOrInstructor(currentUserInfo);
  };

  private timeOverAndGoToHome = () => {
    this.setState({ showTimeFinishedModal: false });
    this.goToHome();
  };

  private goToHome = () => {
    goToPage('/');
  };

  render() {
    const { noFooter, loading, showConfirmInitUserTestModal, showExam, noForm, showTimeFinishedModal, showChangeRoleModal } = this.state;
    const { theoreticalFormStore, t } = this.props;
    const { item, result } = theoreticalFormStore.state;
    const isEmployee = this.isEmployee();
    const msgs = (result?.messages || []).map(({ body }) => body);
    const isAdminPocPlannerOrInstructor = this.isAdminPocPlannerOrInstructor();
    if (noForm) return <NotAvailableTest textToShow={'This test is no longer available'} />;
    return (
      <>
        <Dimmer page active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <h2 className="edit-form__title">{item?.templateName}</h2>
        {!result?.isSuccess && (result?.messages || []).length > 0 && (
          <Message
            onDismiss={theoreticalFormStore.clearMessages}
            className="error-message__style"
            icon="exclamation circle"
            error
            list={msgs}
          />
        )}
        {item && showExam && (
          <Form>
            <div className="form__all-wrapper">
              <header className="form__all-wrapper__header">
                <HeaderComponent {...item} onChange={this.changeHandler} />
              </header>
              <main className="form__all-wrapper__main-content scroll">
                <MainComponent {...item} noFooter={this.toggleFooter} isEmployee={isEmployee} onChange={this.changeHandler} />
              </main>
              <Divider />
              <footer style={{ display: 'flex', justifyContent: 'space-between' }} className="form__all-wrapper__footer">
                <FooterComponent
                  {...item}
                  active={!noFooter}
                  isEmployee={isEmployee}
                  onNext={this.changeQuestionHandler}
                  onPrevious={this.changeHandler}
                />
              </footer>
            </div>
          </Form>
        )}

        {showConfirmInitUserTestModal && !item.answered && !isAdminPocPlannerOrInstructor && (
          <Modal
            closeOnEscape={true}
            onClose={this.closeConfirmModalAndRedirectHome}
            open
            centered
            size="mini"
            className="have-warnings-popup feedback__confirm-modal"
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{t(`You are going to answer theoretical test "${item.templateName}".`)}</p>
              <p>
                {t(
                  `You only have one opportunity to complete it, so before you start make sure you have enough time and a good Internet connection. `
                )}
              </p>

              <p>
                {t(
                  `The maximum time for this test is ${item.hoursDuration} ${
                    item.hoursDuration === 1 ? 'hour' : 'hours'
                  } . Do you want to start the test?`
                )}
              </p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="feedback-modal__cancel-button" content={t('Cancel')} onClick={this.closeConfirmModalAndRedirectHome} />
              <Button
                className="have-warnings-popup__btn feedback-modal__accept-button"
                content={t('Start test')}
                onClick={this.initTheoreticalTest}
                disabled={!!item.startedAt}
              />
            </div>
          </Modal>
        )}

        <Modal open={showTimeFinishedModal} size="mini" className="have-warnings-popup" closeOnEscape={false}>
          <Modal.Content className="have-warnings-popup__content">
            <p>{t(`Time for this test is over`)}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button
              className="have-warnings-popup__btn have-warnings-popup__pending-btn"
              content={t('Ok')}
              onClick={this.timeOverAndGoToHome}
            />
          </div>
        </Modal>
        {showChangeRoleModal && (
          <Modal open={showChangeRoleModal} size="mini" className="have-warnings-popup" closeOnEscape={false}>
            <Modal.Content className="have-warnings-popup__content">
              <p>
                {t(
                  `The test is not available under current role, please switch to Employee role in the main menu and open the test via To-Do list or follow the link from e-mail!`
                )}
              </p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="have-warnings-popup__btn have-warnings-popup__pending-btn" content={t('Ok')} onClick={this.goToHome} />
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(TheoreticalFormTest);
