import React, { Component } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import {
  QuestionViewMrModel,
  QuestionViewNmrModel,
  TheoreticalTemplateWizardStore
} from 'stores/assessments/wizard/theoretical-template-wizard-store';
import { connect } from 'redux-scaffolding-ts';

export interface TheoreticalTemplateQuestionComponentProps extends WithTranslation {
  move: (direction: 'up' | 'down') => void;
  canGoUp: boolean;
  canGoDown: boolean;
  onRemoveQuestion: () => void;
  questionMr?: QuestionViewMrModel;
  questionNmr?: QuestionViewNmrModel;
  index: number;
  theoreticalTemplateWizardStore?: TheoreticalTemplateWizardStore;
}
@connect(['theoreticalTemplateWizardStore', TheoreticalTemplateWizardStore])
class TheoreticalTemplateQuestionComponent extends Component<TheoreticalTemplateQuestionComponentProps> {
  render() {
    const { canGoUp, canGoDown, move, onRemoveQuestion, questionMr, questionNmr } = this.props;
    const question = this.props.questionMr ? this.props.questionMr : this.props.questionNmr;

    return (
      <>
        <div key={question.friendlyId} className="tna-wizard__assign-question">
          <div className="tna-wizard__assign-question__items">
            <div className="wizard__sort-icons-wrapper tna-arrow-left-margin">
              <Icon disabled={!canGoUp} onClick={() => move('up')} name="caret square up outline" />
            </div>
            <div className="wizard__sort-icons-wrapper">
              <Icon disabled={!canGoDown} onClick={() => move('down')} name="caret square down outline" />
            </div>
            <p className="wizard__question-item">{question && question.friendlyId}</p>
            <p className="wizard__question-item wizard__theoretical-question-text">{question && question.question}</p>
            <p className={question.testCategory ? 'wizard__question-item question-bank__cell__tag' : 'wizard__question-item'}>
              {question.testCategory && question.testCategory.name}
            </p>
            {questionMr && (
              <>
                <span className={questionMr.machineUnit ? 'wizard__question-item question-bank__cell__tag' : 'wizard__question-item'}>
                  {questionMr.machineUnit && questionMr.machineUnit.name}
                </span>
                <span className={questionMr.plcType ? 'wizard__question-item question-bank__cell__tag' : 'wizard__question-item'}>
                  {questionMr.plcType && questionMr.plcType.name}
                </span>
              </>
            )}

            {questionNmr && (
              <span className={questionNmr.trainingLevel ? 'wizard__question-item question-bank__cell__tag' : 'wizard__question-item'}>
                {questionNmr.trainingLevel && questionNmr.trainingLevel.name}
              </span>
            )}

            {questionMr && (
              <span className={questionMr.trainingLevel ? 'wizard__question-item question-bank__cell__tag' : 'wizard__question-item'}>
                {questionMr.trainingLevel && questionMr.trainingLevel.name}
              </span>
            )}
          </div>
          <Icon onClick={onRemoveQuestion} className="assigned-profile-row-machine__remove" name="remove" size="large" color="red" />
        </div>
      </>
    );
  }
}

export default withTranslation()(TheoreticalTemplateQuestionComponent);
