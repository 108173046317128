import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Form, Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { IdentityService, Roles } from 'services/identity-service';
import { Query } from 'stores/dataStore';
import { resolve } from 'inversify.config';
import {
  EventCheckListRoleUserStore,
  eventCheckListRoleUserDto,
  eventCheckListRoleUserCheck,
  NewEventCheckListRoleUserStore,
  ChangeEventCheckListRoleUserStore
} from 'stores/events/event-checkList-roleuser-store';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';
import './check-list-tab-pane.less';
import { EventFormStore } from 'stores/events/event-form-store';

interface CheckListTabPaneProps extends WithTranslation {
  eventFormStore?: EventFormStore;
  eventchecklistsroleuser?: EventCheckListRoleUserStore;
  neweventchecklistsroleuser?: NewEventCheckListRoleUserStore;
  changeeventchecklistsroleuser?: ChangeEventCheckListRoleUserStore;
  readOnly?: boolean;
}

export interface CheckListTabState {
  query: Query;
  isUpdate: boolean;
  hasPocManyLocations: boolean;
  showSaveCheckListButton: boolean;
  roleLocation: string;
  loading: boolean;
}

@connect(
  ['eventFormStore', EventFormStore],
  ['eventchecklistsroleuser', EventCheckListRoleUserStore],
  ['neweventchecklistsroleuser', NewEventCheckListRoleUserStore],
  ['changeeventchecklistsroleuser', ChangeEventCheckListRoleUserStore]
)
class CheckListTabPane extends Component<CheckListTabPaneProps, CheckListTabState> {
  constructor(props: Readonly<CheckListTabPaneProps>) {
    super(props);
    const event = this.props.eventFormStore.state.item;

    let query: Query = { searchQuery: '', orderBy: [], skip: 0, take: 10 };
    const currentUserInfo = this.identityService.getUserInfo();
    let hasPocManyLocations: boolean = false;
    let roleLocation: string = '';
    let searchQuery: string = '';
    if (IdentityService.isPoc(currentUserInfo)) {
      const pocLocations = currentUserInfo.locationsByRoles[currentUserInfo.activeRole] as string[];
      let eventRelatedLocationIds = this.getEventRelatedLocationIds();
      let locations = pocLocations.filter(x => eventRelatedLocationIds.includes(x));

      hasPocManyLocations = locations.length > 1;
      roleLocation = locations[0];
      searchQuery = "role eq 'PoC' and LocationId eq " + roleLocation + '  and EventId eq ' + event.id;
    }
    if (IdentityService.isInstructor(currentUserInfo)) {
      searchQuery = "role eq 'Instructor' and UserId eq " + currentUserInfo.userId + ' and EventId eq ' + event.id;
    }
    if (IdentityService.isPlannerTFT(currentUserInfo)) {
      searchQuery = "role eq 'Planner' and EventId eq " + event.id;
    }
    if (IdentityService.isPlannerMTC(currentUserInfo)) {
      searchQuery = "role eq 'Planner MTC' and EventId eq " + event.id;
    }
    if (IdentityService.isWorker(currentUserInfo)) {
      searchQuery = "role eq 'Employee' and UserId eq " + currentUserInfo.userId + ' and EventId eq ' + event.id;
    }
    if (IdentityService.isAdmin(currentUserInfo)) {
      searchQuery = 'EventId eq ' + event.id;
    }

    const filter = searchQuery;

    query = Object.assign(query, { filter, skip: 0 });

    this.state = {
      query,
      isUpdate: false,
      roleLocation,
      hasPocManyLocations,
      showSaveCheckListButton: false,
      loading: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.load();
  }

  private load() {
    this.props.eventchecklistsroleuser.getAllAsync(this.state.query).then(res => {
      this.CheckData(res.items);
    });
  }

  private CheckData(items: eventCheckListRoleUserDto[]) {
    const event = this.props.eventFormStore.state.item;
    const currentUserInfo = this.identityService.getUserInfo();

    if (this.props.eventchecklistsroleuser.state.items && items.length > 0) {
      this.props.changeeventchecklistsroleuser.change({
        id: items[0].id,
        eventId: items[0].eventId,
        userId: IdentityService.isPoc(currentUserInfo) ? '' : currentUserInfo.userId,
        locationId: items[0].locationId,
        role: currentUserInfo.activeRole,
        roleUserChecks: items[0].roleUserChecks
      });
      this.setState({ isUpdate: true });
    } else {
      let checks: eventCheckListRoleUserCheck[];
      checks = [];
      const filterByRole = event.checkLists.filter(c => {
        if (IdentityService.isAdmin(currentUserInfo)) return c.checkListConfig.rolesThatCreateCheckLists;
        else return c.checkListConfig.rolesThatCreateCheckLists.includes(currentUserInfo.activeRole);
      });

      filterByRole.forEach(c => {
        checks.push({ description: c.description, checked: false });
      });

      this.props.neweventchecklistsroleuser.createNew({
        eventId: event.id,
        userId: IdentityService.isPoc(currentUserInfo) || IdentityService.isPlanner(currentUserInfo) ? '' : currentUserInfo.userId,
        locationId: this.state.roleLocation,
        role: currentUserInfo.activeRole,
        roleUserChecks: checks
      });
      this.setState({ isUpdate: false });
    }
  }

  private selectedFromChecksList(index: number): void {
    if (this.state.isUpdate) {
      const item = this.props.changeeventchecklistsroleuser.state.item;
      const check = item.roleUserChecks[index];
      check.checked = !check.checked;

      this.props.changeeventchecklistsroleuser.change(item);
    } else {
      const item = this.props.neweventchecklistsroleuser.state.item;
      const check = item.roleUserChecks[index];
      check.checked = !check.checked;

      this.props.neweventchecklistsroleuser.change(item);
    }
    this.setState({ showSaveCheckListButton: true });
  }

  private changePocLocationFilter() {
    const event = this.props.eventFormStore.state.item;
    let filter = '';

    if (!!this.state.roleLocation) {
      const searchQuery: string = "role eq 'PoC' and LocationId eq " + this.state.roleLocation + '  and EventId eq ' + event.id;
      filter = searchQuery;

      const query = Object.assign(this.state.query, { filter, skip: 0 });
      this.setState({ query }, () => this.load());
    }
  }

  private onSavecheckList = () => {
    this.setState({ loading: true });
    if (this.state.isUpdate) {
      this.props.changeeventchecklistsroleuser.update().then(() => this.setState({ loading: false }));
      this.props.changeeventchecklistsroleuser.clear();
    } else {
      this.props.neweventchecklistsroleuser.submit().then(() => this.setState({ loading: false }));
      this.props.neweventchecklistsroleuser.clear();
    }
  };

  private CheckListToUpdate(check: eventCheckListRoleUserCheck, index: number) {
    return (
      <Checkbox
        disabled={this.isCheckEditable()}
        label={check.description}
        checked={check.checked}
        onChange={() => this.selectedFromChecksList(index)}
      />
    );
  }

  private CheckListToCreate(check: eventCheckListRoleUserCheck, index: number) {
    return (
      <Checkbox
        disabled={this.isCheckEditable()}
        label={check.description}
        checked={check.checked}
        onChange={() => this.selectedFromChecksList(index)}
      />
    );
  }

  private isCheckEditable() {
    const event = this.props.eventFormStore.state.item;
    const status = event.status.toString();
    if (
      (status === 'Completed' || status === 'InProgress') &&
      (this.identityService.isInRole(Roles.Instructor) ||
        this.identityService.isInRole(Roles.PoC) ||
        event.eventCreatorId === this.identityService.getUserInfo().userId)
    ) {
      return false;
    } else {
      return true;
    }
  }

  private getEventRelatedLocationIds() {
    const event = this.props.eventFormStore.state.item;
    let result = [];
    if (event && event.eventDetails && event.eventDetails.location) {
      result.push(event.eventDetails.location.id);
    }

    if (event && event.requests.length > 0) {
      event.requests.forEach(req => result.push(req.requestingLocationId));
    }

    return result;
  }

  public render() {
    const { t } = this.props;
    const currentUserInfo = this.identityService.getUserInfo();
    return (
      <>
        {this.state.loading && (
          <div style={{ height: 200 }}>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}
        <Form className="events-form__check-list-container">
          {this.state.hasPocManyLocations && (
            <div style={{ marginBottom: 8 }}>
              <LocationEditorWithRoleLocations
                value={this.state.roleLocation}
                locationsReceived={currentUserInfo.locationsByRoles[currentUserInfo.activeRole].toArray<string>()}
                onChange={location => this.setState({ roleLocation: location ? location.id : null }, () => this.changePocLocationFilter())}
              />
            </div>
          )}
          {this.state.isUpdate &&
            this.props.changeeventchecklistsroleuser.state.item &&
            this.props.changeeventchecklistsroleuser.state.item.roleUserChecks.map((c, i) => {
              return <Form.Field>{this.CheckListToUpdate(c, i)}</Form.Field>;
            })}
          {!this.state.isUpdate &&
            this.props.neweventchecklistsroleuser.state.item &&
            this.props.neweventchecklistsroleuser.state.item.roleUserChecks.map((c, i) => {
              return <Form.Field>{this.CheckListToCreate(c, i)}</Form.Field>;
            })}
          {this.state.showSaveCheckListButton && (
            <Form.Field>
              <Button positive icon="save" labelPosition="right" content={t('Save Check List')} onClick={this.onSavecheckList} />
            </Form.Field>
          )}
        </Form>
      </>
    );
  }
}
export default withTranslation()(CheckListTabPane);
