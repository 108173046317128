import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { FeedBackFormViewModelStore, toViewModel } from 'stores/assessments/forms/feedback-form.store';
import { RouteComponentProps } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Message, Loader, Dimmer, Form } from 'semantic-ui-react';
import { goToPage } from 'utils/useful-functions';
import FeedbackFormHeader from './feedback-form-header';
import FeedbackFormMain from './feedback-form-main';
import FeedbackFormFooter from './feedback-form-footer';
import './feedback-form.less';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';

export interface FeedbackFormProps extends WithTranslation, RouteComponentProps {
  feedBackFormAnswersStore: FeedBackFormViewModelStore;
}

@connect(['feedBackFormAnswersStore', FeedBackFormViewModelStore])
class FeedbackForm extends Component<FeedbackFormProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    document.querySelector('.planit-main-container').classList.add('employee-background');
    const id = this.props.match.params['id'].toString();

    this.props.feedBackFormAnswersStore
      .getFeedbackFormAnswers(id)
      .then(response => {
        let viewModel = toViewModel(response);
        viewModel.readOnly = viewModel.answered || this.identityService.userId !== viewModel.userId;
        this.props.feedBackFormAnswersStore.change(viewModel);
      })
      .catch(error => {
        console.error({ error });
        goToPage('./NotFound');
      });
  }

  componentWillUnmount() {
    document.querySelector('.planit-main-container').classList.remove('employee-background');
  }

  render() {
    const { t, feedBackFormAnswersStore: store } = this.props;
    const answers = store.state.item;

    if (!answers) return null;

    return (
      <>
        <h2 className="feedback-form__title">{answers.feedbackTemplateName && answers.feedbackTemplateName.toUpperCase()}</h2>

        {store.state.result && !store.state.result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={store.state.result.messages.map(m => m.body)} />
        )}
        <Dimmer active={store.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>

        <Form reply>
          <div className="form__all-wrapper">
            <header className="form__all-wrapper__header">
              <FeedbackFormHeader />
            </header>

            <main className="feedback-form__main-wrapper scroll">
              <FeedbackFormMain />
            </main>

            <FeedbackFormFooter />
          </div>
        </Form>
      </>
    );
  }
}

export default withTranslation()(FeedbackForm);
