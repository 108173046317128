import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';
import {
  NmrHoursDurationStore,
  NmrHoursDurationDto
} from 'stores/configuration/events-n-requests/non-machine-related/nmr-hours-durations-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewNmrHoursDurationView from './new-nmr-hours-duration';
import ChangeNmrHoursDurationView from './edit-nmr-hours-duration';

export interface NmrHoursDurationListProps extends WithTranslation, RouteComponentProps {
  NmrHoursDuration: NmrHoursDurationStore;
}

export interface NmrHoursDurationListState {
  query: Query;
  newNmrHoursDurationShown: boolean;
  changeNmrHoursDurationShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['NmrHoursDuration', NmrHoursDurationStore])
class NmrHoursDurationListPage extends React.Component<NmrHoursDurationListProps, NmrHoursDurationListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newNmrHoursDurationShown: false,
      changeNmrHoursDurationShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.NmrHoursDuration.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: NmrHoursDurationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.NmrHoursDuration.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: NmrHoursDurationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.NmrHoursDuration.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newNmrHoursDurationShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newNmrHoursDurationShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeNmrHoursDurationShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeNmrHoursDurationShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          renderer: data => <>{data.clusterName}</>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<NmrHoursDurationDto>('clusterName'), `contains(Cluster/Name, '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<NmrHoursDurationDto>('clusterName'))}
              active={activeFilters.includes(nameof<NmrHoursDurationDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'Cluster/Name',
            useProfile: false
          }
        },
        {
          title: t('Functional Area'),
          renderer: data => data.functionalAreaName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Functional Area')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<NmrHoursDurationDto>('functionalAreaName'), `contains(FunctionalArea/Name, '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<NmrHoursDurationDto>('functionalAreaName'))}
              active={activeFilters.includes(nameof<NmrHoursDurationDto>('functionalAreaName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'FunctionalArea/Name',
            useProfile: false
          }
        },
        {
          title: t('Training Name'),
          renderer: data => data.trainingNameName || '[ANY]',
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Training Name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<NmrHoursDurationDto>('trainingNameName'), `contains(TrainingName/Name, '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<NmrHoursDurationDto>('trainingNameName'))}
              active={activeFilters.includes(nameof<NmrHoursDurationDto>('trainingNameName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'TrainingName/Name',
            useProfile: false
          }
        },
        {
          title: t('Hours Duration'),
          renderer: data => data.hoursDuration
        }
      ],
      data: this.props.NmrHoursDuration.state
    } as TableModel<NmrHoursDurationDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.NmrHoursDuration.state.result && !this.props.NmrHoursDuration.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.NmrHoursDuration.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newNmrHoursDurationShown && !this.state.changeNmrHoursDurationShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add NMR Hours Duration')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newNmrHoursDurationShown && <NewNmrHoursDurationView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeNmrHoursDurationShown && (
            <ChangeNmrHoursDurationView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(NmrHoursDurationListPage);
