import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { LocationDto, DropDownLocationsStore } from '../../stores/configuration/locations/locations-store';
import { nameof } from '../../utils/object';
import { getOptionsFromValue, ItemReference, QueryResult } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { SelectionInput, DropdownProps, SelectionItem } from 'widgets/form/selectionInput';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface LocationItemReference extends ItemReference {
  countryName?: string;
  countryId?: string;
}

interface LocationEditorProps extends DropdownProps, WithTranslation {
  value: LocationItemReference | ItemReference;
  onChange?: (value: LocationItemReference | ItemReference) => void;
  locations?: DropDownLocationsStore;
  clearable?: boolean;
  locationsReceived?: string[];
  filterLocationsByActiveRole?: boolean;
  showCountry?: boolean;
  loadDataOnOpen?: boolean;
}

interface LocationEditorState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  value: LocationItemReference | ItemReference;
  options: SelectionItem[];
}

@connect(['locations', DropDownLocationsStore])
class LocationEditor extends React.Component<LocationEditorProps, LocationEditorState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get locationStore() {
    return this.props.locations;
  }

  constructor(props: LocationEditorProps) {
    super(props);

    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getLocations(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (
      next !== this.props &&
      next.value !== this.props.value &&
      (next.value == null || this.props.value == null || next.value.id !== this.props.value.id)
    ) {
      this.setState({
        value: next.value,
        options: getOptionsFromValue(this.state?.query, next.value)
        // query: this.getLocations()
      });
    }
  }

  @autobind
  private getLocations() {
    const method = async (search: string) => {
      let filter;
      const filterActiveAndStartswith = isNullOrWhiteSpaces(search)
        ? `active eq true`
        : `active eq true and startswith(tolower(${nameof<LocationDto>('location')}), '${search.toLowerCase()}')`;

      if (this.props.locationsReceived && this.props.locationsReceived.length > 0) {
        filter = [{ id: { in: { type: 'guid', value: this.props.locationsReceived } } }, filterActiveAndStartswith];
      } else if (this.props.filterLocationsByActiveRole) {
        let locations = this.identityService.getCurrentRoleLocations() || [];
        if (!locations || locations.length > 0) filter = [{ id: { in: { type: 'guid', value: locations } } }, filterActiveAndStartswith];
        else filter = [filterActiveAndStartswith];
      } else {
        filter = [filterActiveAndStartswith];
      }

      const result = await this.locationStore.getAllAsync(
        {
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }],
          filter: filter
        },
        this.props.locationsReceived,
        search
      );

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.id,
          title: c.location,
          code: c.code,
          countryName: c.countryName,
          countryId: c.countryId
        }))
      }) as QueryResult<LocationItemReference>;
    };
    return method;
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getLocations() });
    }
  };

  public render() {
    const query = this.state.query;
    const selectedValue = this.state.value;
    const options = this.state.options;
    return (
      <SelectionInput
        content={item => (
          <>
            {!this.props.showCountry || !item.code || item.code === 'XXXX' || item.code === 'XXXXX'
              ? item.title
              : item.title + ' - ' + item.countryName}
          </>
        )}
        searchable
        clearable={this.props.clearable}
        className={this.props.className}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        readOnly={this.props.readOnly}
        value={selectedValue}
        onChange={value => this.props.onChange(value as any)}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(LocationEditor);
