import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, Button, Divider, List, Modal } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { IwsFormListStore, IwsFormItemDto, IwsFormAnswersDto } from 'stores/assessments/forms/iws-forms-store';
import { AssessorAnswerStatus } from 'stores/assessments/forms//tna-forms-store';
import './iws-edit-form-header-styles.less';
import { AssessorType, RemainingQuestionsModel } from './iws-edit-form';
//import { IwsTemplateDto } from 'stores/assessments/templates/iws-templates-store';

interface IWSFormHeaderProps extends WithTranslation {
  isNotEmployee: boolean;
  iwsFormListStore?: IwsFormListStore;
  remainingQuestions: RemainingQuestionsModel;
  assessorType: AssessorType;
  assessorAnswerStatus: AssessorAnswerStatus;
  isAdminOrPoC: boolean;
  onAnswerChange?: (change: number | string, questionId: string, skillId: string, templateId: string, comment?: boolean) => void;
}
interface FunctionalExpertObj {
  templateName: string;
  qualifierName: string;
}

interface IWSFormHeaderState {
  remainingModal: boolean;
  accordionOpen: boolean;
}

@connect(['iwsFormListStore', IwsFormListStore])
class IWSFormHeaderComponent extends Component<IWSFormHeaderProps, IWSFormHeaderState> {
  state: IWSFormHeaderState = { remainingModal: false, accordionOpen: false };

  private get assessorType() {
    return this.props.assessorType;
  }

  private get onAnswerChange() {
    return this.props.onAnswerChange;
  }

  getTemplateQualifiers = () => {
    const item: IwsFormItemDto = this.props.iwsFormListStore.state.item;
    const answers: IwsFormAnswersDto = item.answers;
    const qualifiersTemplates: FunctionalExpertObj[][] = [];
    (item?.qualifiers || []).forEach(({ templates, user: { lastName, firstName } }) => {
      const qualifierName = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
      qualifiersTemplates.push(
        (templates || []).map(({ template }, index) => {
          let templateIndex = -1;
          if (answers && answers.templateSectionAnswers) {
            templateIndex = answers?.templateSectionAnswers.findIndex(e => e.templateId === template?.id);
          } else {
            templateIndex = index;
          }

          return {
            templateName: `Template: ${template?.title}`,
            qualifierName,
            index: templateIndex
          };
        })
      );
    });

    return qualifiersTemplates;
  };

  compareQualifiersTemplatesIndexes(a, b) {
    if (a.index < b.index) {
      return -1;
    }
    if (a.index > b.index) {
      return 1;
    }
    return 0;
  }

  showRemainingModal = () => this.setState({ remainingModal: true });

  hideRemainingModal = () => this.setState({ remainingModal: false });

  toggleAccordion = () => this.setState(({ accordionOpen }) => ({ accordionOpen: !accordionOpen }));

  CopyScoresFromEmployeeToAssessor = () => {
    const item: IwsFormItemDto = this.props.iwsFormListStore.state.item;
    const answers: IwsFormAnswersDto = item.answers;

    if (this.assessorType.type === 'qualifier') {
      var tempAnswers = answers.templateSectionAnswers.filter(x =>
        (this.assessorType.qualifierDto?.templates || []).any(temp => temp.templateId === x.templateId)
      );

      (tempAnswers || []).forEach(({ templateId, iwsSkillAnswers }) => {
        (iwsSkillAnswers || []).forEach(({ questionAnswers, skillId }) => {
          (questionAnswers || []).forEach(answerItem => {
            answerItem.qualifierAnswer = { ...answerItem.qualifierAnswer, answer: answerItem.employeeSecondAssessmentAnswer.answer };
            this.onAnswerChange(answerItem.employeeSecondAssessmentAnswer.answer, answerItem.questionId, skillId, templateId, false);
          });
        });
      });
    }

    this.props.iwsFormListStore.changeAnswers(answers);
  };

  render() {
    const { remainingModal, accordionOpen } = this.state;
    const { t, isNotEmployee, iwsFormListStore, remainingQuestions, assessorType, assessorAnswerStatus } = this.props;
    const itemState: IwsFormItemDto = iwsFormListStore.state.item;
    const { user, iwsAssessment } = itemState;
    const qualifiers = this.getTemplateQualifiers();

    const isDone = itemState.status.toString() === 'Done';
    const isEmployeeStatus = itemState.status.toString() === 'FirstAssesment' || itemState.status.toString() === 'SecondAssesment';
    const assessorReadOnlyMode = assessorType.type === 'qualifier' && isEmployeeStatus;
    const assessorPending = ['Pending', 'PendingRecommendation'].includes(assessorAnswerStatus.answerStatus);
    const assessorEditable = !this.props.isAdminOrPoC && !isDone && assessorPending && !assessorReadOnlyMode;

    return (
      <>
        <div className="header-accordion-spacer">
          <Accordion className="tna-header-accordion" panels={[]}>
            <Accordion.Title active={accordionOpen} onClick={this.toggleAccordion} className="tna-header-accordion__title" />
            <Accordion.Content active={accordionOpen}>
              <header className="form__all-wrapper__header">
                <div className="edit-form__header__data-language flex-between">
                  <div className="edit-form__header__general-info">
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Employee')}</h5>
                      <p>{`${(user?.firstName || '').toUpperCase()} ${(user?.lastName || '').toUpperCase()}`}</p>
                    </div>

                    {(iwsAssessment?.iwsTemplates || []).length !== 0 && (
                      <>
                        {/* <div className="edit-form__header__general-info__element">
                        <h5>{t('Perfiles')}</h5>
                        {(iwsAssessment.iwsTemplates.distinct(x => x.profileName) || []).toArray<IwsTemplateDto>().map(template => {
                          return <p key={template?.profileItemId}>{template?.profileName}</p>;
                        })}
                      </div> */}

                        {isNotEmployee && (
                          <>
                            {(qualifiers || []).map((expertArray, j) => (
                              <Fragment key={j + expertArray[0].templateName}>
                                {(expertArray || [])
                                  .sort(this.compareQualifiersTemplatesIndexes)
                                  .map(({ templateName, qualifierName }, i) => (
                                    <div key={templateName + qualifierName + i} className="edit-form__header__general-info__element">
                                      <h5>
                                        {t('Qualifier')} <br />
                                        {t(templateName)}
                                      </h5>
                                      <p>{qualifierName}</p>
                                    </div>
                                  ))}
                              </Fragment>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="header-right">
                    {/* <LanguageEditor
                      className="planit-user-dropdown-custom"
                      placeholder={t('Select Language')}
                      clearable
                      nullable
                      value={iwsFormListStore.state.item.currentLanguage || null}
                      onChange={val => iwsFormListStore.changeCurrentLanguage(val?.id || null)}
                      onlyForTests
                    /> */}
                    {remainingQuestions && (
                      <Button className="btn__check-questions" onClick={this.showRemainingModal}>
                        {t('Check')}
                      </Button>
                    )}
                    {assessorEditable && isNotEmployee && (
                      <Button className="btn__check-questions" onClick={this.CopyScoresFromEmployeeToAssessor}>
                        {t('Copy Scores from Employee')}
                      </Button>
                    )}
                  </div>
                </div>
              </header>
            </Accordion.Content>
          </Accordion>
        </div>
        <Divider />
        <Modal className="remaining-questions" size="small" open={remainingModal} closeOnEscape={true} onClose={this.hideRemainingModal}>
          <Modal.Header>
            <h1>{t('Check Questions')}</h1>
          </Modal.Header>
          <Modal.Content className="remaining-questions__modal-content">
            <List className="remaining-questions__all-list">
              {remainingQuestions?.templates != null && (
                <List.Item>
                  <div className="remaining-questions__title">
                    <h2>{t('Templates')}:</h2>
                    <span className="remaining-item__box">{remainingQuestions?.totalForm || ''}</span>
                  </div>
                  <List className="remaining-questions__machines-list">
                    {(remainingQuestions?.templates || []).map(({ templateId, templateName, skills, totalTemp }) => (
                      <List.Item key={templateId}>
                        <div className="remaining-questions__title">
                          <h3 className="underlined">{templateName}:</h3>
                          <span className="remaining-item__box">{totalTemp}</span>
                        </div>

                        <List ordered className="remaining-questions__skill-questions">
                          {(skills || []).map(({ skillId, skillName, remaining }) => (
                            <List.Item className="remaining-questions__remaining-item" key={skillId + templateId}>
                              <div className="remaining-questions__title">
                                <b>{skillName}:</b>
                                <span className="remaining-item__box">{remaining}</span>
                              </div>
                            </List.Item>
                          ))}
                        </List>
                      </List.Item>
                    ))}
                  </List>
                </List.Item>
              )}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideRemainingModal}>OK</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
} //end class

export default withTranslation()(IWSFormHeaderComponent);
