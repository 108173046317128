import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface NmrHoursDurationDto extends BaseDto {
  id: string;
  clusterId: string;
  clusterName: string;
  functionalAreaId: string;
  functionalAreaName: string;
  trainingNameId: string;
  trainingNameName: string;
  hoursDuration: number;
}

export interface CreateNmrHoursDurationDto {
  clusterId: string;
  functionalAreaId: string;
  trainingNameId: string;
  hoursDuration: number;
}

export interface ChangeNmrHoursDurationDto {
  id: string;
  hoursDuration: number;
}

export class CreateNmrHoursDurationValidator extends AbstractValidator<CreateNmrHoursDurationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.functionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(o => o.trainingNameId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Training Name is invalid'));

    this.validateIfNumber(o => o.hoursDuration)
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('The Hours Duration must be greater than 0'));
  }
}

export class ChangeNmrHoursDurationValidator extends AbstractValidator<ChangeNmrHoursDurationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('NmrHoursDuration Id is required'));

    this.validateIfNumber(o => o.hoursDuration)
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('The Hours Duration must be greater than 0'));
  }
}

@repository('@@NMRHOURSDURATIONS', 'nmrHoursDuration.summary')
export class NmrHoursDurationStore extends DataStore<NmrHoursDurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmr-hour';
  retrievePath = 'get-nmr-hours';
  updatePath = 'update-nmr-hour';
  deletePath = 'delete-nmr-hour';

  protected validate(item: NmrHoursDurationDto) {
    return new ChangeNmrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('NMRHOURSDURATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMRHOURSDURATIONS', 'nmrHoursDuration.new')
export class NewNmrHoursDurationStore extends FormStore<CreateNmrHoursDurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmr-hour';
  retrievePath = 'get-nmr-hours';
  updatePath = 'update-nmr-hour';

  protected validate(item: CreateNmrHoursDurationDto) {
    return new CreateNmrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('NEW_NMRHOURSDURATION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMRHOURSDURATIONS', 'nmrHoursDuration.change')
export class ChangeNmrHoursDurationStore extends FormStore<ChangeNmrHoursDurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmr-hour';
  retrievePath = 'get-nmr-hours';
  updatePath = 'update-nmr-hour';

  protected validate(item: ChangeNmrHoursDurationDto) {
    return new ChangeNmrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMRHOURSDURATION', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
