import { ItemResult } from '../types';
import { ValidationResult } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface SsaActivityTypeDto {
  id: string;
  title: string;
}

@repository('@@SSA_ACTIVITY_TYPE', 'ssaActivityType.summary')
export class SsaActivityTypeStore extends DataStore<SsaActivityTypeDto> {
  baseUrl = 'skills/v1';
  createPath = '';
  retrievePath = 'get-ssa-activity-types';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'get-ssa-activity-type/';

  protected validate(item: SsaActivityTypeDto) {
    return new ValidationResult();
  }

  constructor() {
    super('SSA_ACTIVITY_TYPE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getActivityType(id: string): Promise<SsaActivityTypeDto> {
    const httpService = container.get(HttpService);

    try {
      const response = await httpService.get<ItemResult<SsaActivityTypeDto>>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`);
      if (response && response.data && response.data.item) return response.data.item;
      return null;
    } catch {
      return null;
    }
  }
}
