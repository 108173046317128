import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import TNAFormsList from './forms/tna-forms-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface TnaSkillsConfigurationHomeProps extends RouteComponentProps, WithTranslation {}
export interface TnaSkillsConfigurationHomeState {}

class AssessorsTnaHomePage extends React.Component<TnaSkillsConfigurationHomeProps, TnaSkillsConfigurationHomeState> {
  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <h3 id="tna-forms-list-filters__title">{t('Training Need Analysis Evaluations')}</h3>
        <LineSeparator />
        <TNAFormsList isUserAssessor={true} />
      </>
    );
  }
}

export default withTranslation()(AssessorsTnaHomePage);
