import i18n from 'i18n';
import * as React from 'react';
import { Button, Divider, Form, Header, Icon, Popup, Segment } from 'semantic-ui-react';
import { OrderDefinition, QueryResult, ItemReference, Query, QueryParameters } from 'stores/dataStore';
import { SelectionInput } from 'widgets/form/selectionInput';

interface IDataStoreFilterProps<T> {
  triggerTitle: string;
  filterTitle: string;
  getItems: (query: Query) => Promise<QueryResult<T>>;
  parameters: string;
  orderBy?: OrderDefinition[];
  filterGenerator?: (search: string) => any;
  valueSelector: (item: T) => string;
  titleSelector: (item: T) => string;
  onFilter: (value: string) => void;
  onClear: () => void;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  position?: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center';
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[];
  active?: boolean;
  multiple?: boolean;
  initialValue?: ItemReference;
  preFilteredIds?: string[];
  onActivate?: (visible: boolean) => void;
}

interface IDataStoreFilterState {
  visible: boolean;
  filtered: boolean;
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

export class DataStoreFilter<T> extends React.Component<IDataStoreFilterProps<T>, IDataStoreFilterState> {
  constructor(props: IDataStoreFilterProps<T>) {
    super(props);
    this.state = {
      visible: false,
      filtered: this.props.active,
      value: this.props.initialValue || null,
      query: this.generateQuery()
    };
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.onFilter(null);
    }
  }

  private getOrderBy = () => {
    return this.props.orderBy || [];
  };

  private getFilter = (s: string) => {
    let filter = [];
    if (this.props.preFilteredIds && this.props.preFilteredIds.length > 0)
      filter = [{ id: { in: { type: 'guid', value: this.props.preFilteredIds } } }];

    if (this.props.filterGenerator) filter.push(this.props.filterGenerator(s) || {});

    return filter;
  };

  private generateQuery = () => {
    const orderBy = this.getOrderBy();
    const parameters: QueryParameters = { $select: this.props.parameters };
    const method = async (search: string) => {
      const filter = this.getFilter(search);
      const result = await this.props.getItems({ searchQuery: '', skip: 0, take: 100000, orderBy, filter, parameters });
      return Object.assign({}, result, {
        items: result.items.map(c => ({ id: this.props.valueSelector(c), title: this.props.titleSelector(c) }))
      }) as QueryResult<ItemReference>;
    };
    return method;
  };

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.active !== this.state.filtered) {
      if (next.active) this.setState({ filtered: true });
      else {
        this.setState({ filtered: false, visible: false, value: null });
        this.props.onActivate && this.props.onActivate(false);
      }
    }
  }

  private onChange = value => {
    this.setState({ value });
  };

  private onFilter = e => {
    if (e != null) e.preventDefault();
    const { value } = this.state;
    if (this.props.onFilter) {
      this.props.onFilter((value || { id: null }).id);
    }
    this.setState({ filtered: true, visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClear = () => {
    if (this.props.onClear) {
      this.props.onClear();
    }
    this.setState({ visible: false, filtered: false, value: null });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClose = () => {
    this.setState({ visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onOpen = e => {
    e.preventDefault();
    this.setState({ visible: true });
    this.props.onActivate && this.props.onActivate(true);
  };

  render() {
    const { filterTitle, triggerTitle, size, position, on } = this.props;
    const { visible, filtered, value, query } = this.state;
    return (
      <Popup
        open={visible}
        trigger={
          <a
            href="!#"
            onClick={this.onOpen}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
          >
            {triggerTitle}&nbsp;
            <Icon name="filter" size="small" disabled={!filtered} />
          </a>
        }
        content={
          <Segment size="mini" basic className="options-filter">
            <Header as="h4">{filterTitle}</Header>
            <Divider />
            <Form size="tiny">
              <Form.Group>
                <Form.Field width={13} className="select-field" onKeyPress={e => e.key === 'Enter' && e.preventDefault()}>
                  <SelectionInput
                    content={item => <div>{item.title}</div>}
                    readOnly={false}
                    searchable
                    nullable
                    placeholder={i18n.t('Select...')}
                    query={query}
                    value={value}
                    onChange={this.onChange}
                    clearable={false}
                    multiple={false}
                  />
                </Form.Field>
                <Form.Field width={3} className="filter-button-field">
                  <Button size="small" icon="filter" className="no-rounded" compact onClick={this.onFilter} disabled={value == null} />
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider />
            <Button size="tiny" compact onClick={this.onClear}>
              <Icon name="times" /> {i18n.t('Clear')}
            </Button>
          </Segment>
        }
        on={on || 'click'}
        position={position || 'bottom left'}
        size={size || 'mini'}
        onClose={this.onClose}
      />
    );
  }
}
