import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { CreatePriorityDto, NewPriorityStore } from 'stores/configuration/events-n-requests/priorities-store';
import { nameof } from 'utils/object';

interface NewPrioritiesViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newPriorities?: NewPriorityStore;
}

interface NewPrioritiesViewState {
  userClickedSaved: boolean;
}

@connect(['newPriorities', NewPriorityStore])
class NewPrioritiesView extends React.Component<NewPrioritiesViewProps, NewPrioritiesViewState> {
  private get newPrioritiesStore() {
    return this.props.newPriorities;
  }

  constructor(props: NewPrioritiesViewProps) {
    super(props);
    this.newPrioritiesStore.createNew({
      name: ''
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPrioritiesViewProps) {
    if (this.newPrioritiesStore.state.result && this.newPrioritiesStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPrioritiesStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPrioritiesStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newPrioritiesStore.change({ ...this.newPrioritiesStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPrioritiesStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Priority')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newPrioritiesStore.state.result && !this.newPrioritiesStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPrioritiesStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newPrioritiesStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newPrioritiesStore.state.item.name && this.state.userClickedSaved}
                    label={t('Priority')}
                    placeholder={t('Name')}
                    value={this.newPrioritiesStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreatePriorityDto>('name'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPrioritiesView);
