import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // .use(LanguageDetector)  // <- use it when multiple languages are used
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          Increment: 'Increment'
        }
      },
      es: {
        translations: {
          Increment: 'Incrementar'
        }
      }
    },
    fallbackLng: 'en',
    debug: false,
    lng: 'en', // <- remove when multiple languages are used

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys
    nsSeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  });

export default i18n;
