import { OemsStore, NewOemStore, ChangeOemStore, DropdownOemsStore } from './oems-store';
import { ClustersStore, ChangeClusterStore, NewClusterStore, DropDownClustersStore } from './clusters-store';
import { EquipmentTypesStore, NewEquipmentTypeStore, ChangeEquipmentTypeStore, DropDownEquipmentTypesStore } from './equipment-types-store';
import { MachineUnitsStore, NewMachineUnitStore, ChangeMachineUnitStore } from './machine-units-store';
import { ChangeMachineModelStore, MachineModelsStore, NewMachineModelStore, DropDownMachineModelsStore } from './machine-models-store';
import { PlcTypesStore, NewPlcTypeStore, ChangePlcTypeStore } from './plc-types-store';
import { PatternsStore, ChangePatternStore, NewPatternStore, HierarchyStore } from './patterns-store';
import { MachineriesStore } from './machineries-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new OemsStore());
  storeBuilder.addRepository(new DropdownOemsStore());
  storeBuilder.addRepository(new NewOemStore());
  storeBuilder.addRepository(new ChangeOemStore());
  storeBuilder.addRepository(new ClustersStore());
  storeBuilder.addRepository(new DropDownClustersStore());
  storeBuilder.addRepository(new NewClusterStore());
  storeBuilder.addRepository(new ChangeClusterStore());
  storeBuilder.addRepository(new EquipmentTypesStore());
  storeBuilder.addRepository(new DropDownEquipmentTypesStore());
  storeBuilder.addRepository(new NewEquipmentTypeStore());
  storeBuilder.addRepository(new ChangeEquipmentTypeStore());
  storeBuilder.addRepository(new MachineUnitsStore());
  storeBuilder.addRepository(new NewMachineUnitStore());
  storeBuilder.addRepository(new ChangeMachineUnitStore());
  storeBuilder.addRepository(new MachineModelsStore());
  storeBuilder.addRepository(new DropDownMachineModelsStore());
  storeBuilder.addRepository(new NewMachineModelStore());
  storeBuilder.addRepository(new ChangeMachineModelStore());
  storeBuilder.addRepository(new PatternsStore());
  storeBuilder.addRepository(new NewPatternStore());
  storeBuilder.addRepository(new ChangePatternStore());
  storeBuilder.addRepository(new PlcTypesStore());
  storeBuilder.addRepository(new NewPlcTypeStore());
  storeBuilder.addRepository(new ChangePlcTypeStore());
  storeBuilder.addRepository(new MachineriesStore());
  storeBuilder.addRepository(new HierarchyStore());
}
