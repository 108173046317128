export interface ICountryInfo {
  name: string;
  code: string;
}

export const countryInfos: ICountryInfo[] = [
  { name: 'Afghanistan', code: 'af' },
  { name: 'Aland Islands', code: 'ax' },
  { name: 'Albania', code: 'al' },
  { name: 'Algeria', code: 'dz' },
  { name: 'American Samoa', code: 'as' },
  { name: 'Andorra', code: 'ad' },
  { name: 'Angola', code: 'ao' },
  { name: 'Anguilla', code: 'ai' },
  { name: 'Antigua', code: 'ag' },
  { name: 'Argentina', code: 'ar' },
  { name: 'Armenia', code: 'am' },
  { name: 'Aruba', code: 'aw' },
  { name: 'Australia', code: 'au' },
  { name: 'Austria', code: 'at' },
  { name: 'Azerbaijan', code: 'az' },
  { name: 'Bahamas', code: 'bs' },
  { name: 'Bahrain', code: 'bh' },
  { name: 'Bangladesh', code: 'bd' },
  { name: 'Barbados', code: 'bb' },
  { name: 'Belarus', code: 'by' },
  { name: 'Belgium', code: 'be' },
  { name: 'Belize', code: 'bz' },
  { name: 'Benin', code: 'bj' },
  { name: 'Bermuda', code: 'bm' },
  { name: 'Bhutan', code: 'bt' },
  { name: 'Bolivia', code: 'bo' },
  { name: 'Bosnia', code: 'ba' },
  { name: 'Botswana', code: 'bw' },
  { name: 'Bouvet Island', code: 'bv' },
  { name: 'Brazil', code: 'br' },
  { name: 'British Virgin Islands', code: 'vg' },
  { name: 'Brunei', code: 'bn' },
  { name: 'Bulgaria', code: 'bg' },
  { name: 'Burkina Faso', code: 'bf' },
  { name: 'Burma', code: 'mm' },
  { name: 'Burundi', code: 'bi' },
  { name: 'Caicos Islands', code: 'tc' },
  { name: 'Cambodia', code: 'kh' },
  { name: 'Cameroon', code: 'cm' },
  { name: 'Canada', code: 'ca' },
  { name: 'Cape Verde', code: 'cv' },
  { name: 'Cayman Islands', code: 'ky' },
  { name: 'Central African Republic', code: 'cf' },
  { name: 'Chad', code: 'td' },
  { name: 'Chile', code: 'cl' },
  { name: 'China', code: 'cn' },
  { name: 'Christmas Island', code: 'cx' },
  { name: 'Cocos Islands', code: 'cc' },
  { name: 'Colombia', code: 'co' },
  { name: 'Comoros', code: 'km' },
  { name: 'Congo', code: 'cd' },
  { name: 'Congo Brazzaville', code: 'cg' },
  { name: 'Cook Islands', code: 'ck' },
  { name: 'Costa Rica', code: 'cr' },
  { name: 'Cote Divoire', code: 'ci' },
  { name: 'Croatia', code: 'hr' },
  { name: 'Cuba', code: 'cu' },
  { name: 'Cyprus', code: 'cy' },
  { name: 'Czech Republic', code: 'cz' },
  { name: 'Denmark', code: 'dk' },
  { name: 'Djibouti', code: 'dj' },
  { name: 'Dominica', code: 'dm' },
  { name: 'Dominican Republic', code: 'do' },
  { name: 'Ecuador', code: 'ec' },
  { name: 'Egypt', code: 'eg' },
  { name: 'El Salvador', code: 'sv' },
  { name: 'Equatorial Guinea', code: 'gq' },
  { name: 'Eritrea', code: 'er' },
  { name: 'Estonia', code: 'ee' },
  { name: 'Ethiopia', code: 'et' },
  { name: 'Europeanunion', code: 'eu' },
  { name: 'Falkland Islands', code: 'fk' },
  { name: 'Faroe Islands', code: 'fo' },
  { name: 'Fiji', code: 'fj' },
  { name: 'Finland', code: 'fi' },
  { name: 'France', code: 'fr' },
  { name: 'French Guiana', code: 'gf' },
  { name: 'French Polynesia', code: 'pf' },
  { name: 'French Territories', code: 'tf' },
  { name: 'Gabon', code: 'ga' },
  { name: 'Gambia', code: 'gm' },
  { name: 'Georgia', code: 'ge' },
  { name: 'Germany', code: 'de' },
  { name: 'Ghana', code: 'gh' },
  { name: 'Gibraltar', code: 'gi' },
  { name: 'Greece', code: 'gr' },
  { name: 'Greenland', code: 'gl' },
  { name: 'Grenada', code: 'gd' },
  { name: 'Guadeloupe', code: 'gp' },
  { name: 'Guam', code: 'gu' },
  { name: 'Guatemala', code: 'gt' },
  { name: 'Guinea', code: 'gn' },
  { name: 'Guinea-Bissau', code: 'gw' },
  { name: 'Guyana', code: 'gy' },
  { name: 'Haiti', code: 'ht' },
  { name: 'Heard Island', code: 'hm' },
  { name: 'Honduras', code: 'hn' },
  { name: 'Hong Kong', code: 'hk' },
  { name: 'Hungary', code: 'hu' },
  { name: 'Iceland', code: 'is' },
  { name: 'India', code: 'in' },
  { name: 'Indian Ocean Territory', code: 'io' },
  { name: 'Indonesia', code: 'id' },
  { name: 'Iran', code: 'ir' },
  { name: 'Iraq', code: 'iq' },
  { name: 'Ireland', code: 'ie' },
  { name: 'Israel', code: 'il' },
  { name: 'Italy', code: 'it' },
  { name: 'Jamaica', code: 'jm' },
  { name: 'Jan Mayen', code: 'sj' },
  { name: 'Japan', code: 'jp' },
  { name: 'Jordan', code: 'jo' },
  { name: 'Kazakhstan', code: 'kz' },
  { name: 'Kenya', code: 'ke' },
  { name: 'Kiribati', code: 'ki' },
  { name: 'Kuwait', code: 'kw' },
  { name: 'Kyrgyzstan', code: 'kg' },
  { name: 'Laos', code: 'la' },
  { name: 'Latvia', code: 'lv' },
  { name: 'Lebanon', code: 'lb' },
  { name: 'Lesotho', code: 'ls' },
  { name: 'Liberia', code: 'lr' },
  { name: 'Libya', code: 'ly' },
  { name: 'Liechtenstein', code: 'li' },
  { name: 'Lithuania', code: 'lt' },
  { name: 'Luxembourg', code: 'lu' },
  { name: 'Macau', code: 'mo' },
  { name: 'Macedonia', code: 'mk' },
  { name: 'Madagascar', code: 'mg' },
  { name: 'Malawi', code: 'mw' },
  { name: 'Malaysia', code: 'my' },
  { name: 'Maldives', code: 'mv' },
  { name: 'Mali', code: 'ml' },
  { name: 'Malta', code: 'mt' },
  { name: 'Marshall Islands', code: 'mh' },
  { name: 'Martinique', code: 'mq' },
  { name: 'Mauritania', code: 'mr' },
  { name: 'Mauritius', code: 'mu' },
  { name: 'Mayotte', code: 'yt' },
  { name: 'Mexico', code: 'mx' },
  { name: 'Micronesia', code: 'fm' },
  { name: 'Moldova', code: 'md' },
  { name: 'Monaco', code: 'mc' },
  { name: 'Mongolia', code: 'mn' },
  { name: 'Montenegro', code: 'me' },
  { name: 'Montserrat', code: 'ms' },
  { name: 'Morocco', code: 'ma' },
  { name: 'Mozambique', code: 'mz' },
  { name: 'Namibia', code: 'na' },
  { name: 'Nauru', code: 'nr' },
  { name: 'Nepal', code: 'np' },
  { name: 'Netherlands', code: 'nl' },
  { name: 'Netherlandsantilles', code: 'an' },
  { name: 'New Caledonia', code: 'nc' },
  { name: 'New Guinea', code: 'pg' },
  { name: 'New Zealand', code: 'nz' },
  { name: 'Nicaragua', code: 'ni' },
  { name: 'Niger', code: 'ne' },
  { name: 'Nigeria', code: 'ng' },
  { name: 'Niue', code: 'nu' },
  { name: 'Norfolk Island', code: 'nf' },
  { name: 'North Korea', code: 'kp' },
  { name: 'Northern Mariana Islands', code: 'mp' },
  { name: 'Norway', code: 'no' },
  { name: 'Oman', code: 'om' },
  { name: 'Pakistan', code: 'pk' },
  { name: 'Palau', code: 'pw' },
  { name: 'Palestine', code: 'ps' },
  { name: 'Panama', code: 'pa' },
  { name: 'Paraguay', code: 'py' },
  { name: 'Peru', code: 'pe' },
  { name: 'Philippines', code: 'ph' },
  { name: 'Pitcairn Islands', code: 'pn' },
  { name: 'Poland', code: 'pl' },
  { name: 'Portugal', code: 'pt' },
  { name: 'Puerto Rico', code: 'pr' },
  { name: 'Qatar', code: 'qa' },
  { name: 'Reunion', code: 're' },
  { name: 'Romania', code: 'ro' },
  { name: 'Russia', code: 'ru' },
  { name: 'Rwanda', code: 'rw' },
  { name: 'Saint Helena', code: 'sh' },
  { name: 'Saint Kitts and Nevis', code: 'kn' },
  { name: 'Saint Lucia', code: 'lc' },
  { name: 'Saint Pierre', code: 'pm' },
  { name: 'Saint Vincent', code: 'vc' },
  { name: 'Samoa', code: 'ws' },
  { name: 'San Marino', code: 'sm' },
  { name: 'Sandwich Islands', code: 'gs' },
  { name: 'Sao Tome', code: 'st' },
  { name: 'Saudi Arabia', code: 'sa' },
  { name: 'Scotland', code: 'gb sct' },
  { name: 'Senegal', code: 'sn' },
  { name: 'Serbia', code: 'rs' },
  { name: 'Seychelles', code: 'sc' },
  { name: 'Sierra Leone', code: 'sl' },
  { name: 'Singapore', code: 'sg' },
  { name: 'Slovakia', code: 'sk' },
  { name: 'Slovenia', code: 'si' },
  { name: 'Solomon Islands', code: 'sb' },
  { name: 'Somalia', code: 'so' },
  { name: 'South Africa', code: 'za' },
  { name: 'South Korea', code: 'kr' },
  { name: 'Spain', code: 'es' },
  { name: 'Sri Lanka', code: 'lk' },
  { name: 'Sudan', code: 'sd' },
  { name: 'Suriname', code: 'sr' },
  { name: 'Swaziland', code: 'sz' },
  { name: 'Sweden', code: 'se' },
  { name: 'Switzerland', code: 'ch' },
  { name: 'Syria', code: 'sy' },
  { name: 'Taiwan', code: 'tw' },
  { name: 'Tajikistan', code: 'tj' },
  { name: 'Tanzania', code: 'tz' },
  { name: 'Thailand', code: 'th' },
  { name: 'Timorleste', code: 'tl' },
  { name: 'Togo', code: 'tg' },
  { name: 'Tokelau', code: 'tk' },
  { name: 'Tonga', code: 'to' },
  { name: 'Trinidad', code: 'tt' },
  { name: 'Tunisia', code: 'tn' },
  { name: 'Turkey', code: 'tr' },
  { name: 'Turkmenistan', code: 'tm' },
  { name: 'Tuvalu', code: 'tv' },
  { name: 'United Arab Emirates', code: 'ae' },
  { name: 'Uganda', code: 'ug' },
  { name: 'Ukraine', code: 'ua' },
  { name: 'United Kingdom', code: 'gb' },
  { name: 'United States', code: 'us' },
  { name: 'Uruguay', code: 'uy' },
  { name: 'US Minor Islands', code: 'um' },
  { name: 'US Virgin Islands', code: 'vi' },
  { name: 'Uzbekistan', code: 'uz' },
  { name: 'Vanuatu', code: 'vu' },
  { name: 'Vatican City', code: 'va' },
  { name: 'Venezuela', code: 've' },
  { name: 'Vietnam', code: 'vn' },
  { name: 'Wales', code: 'gb wls' },
  { name: 'Wallis and Futuna', code: 'wf' },
  { name: 'Western Sahara', code: 'eh' },
  { name: 'Yemen', code: 'ye' },
  { name: 'Zambia', code: 'zm' },
  { name: 'Zimbabwe', code: 'zw' }
];

export const countryNames = countryInfos.map(x => x.name);
export const flagNames = [
  'ad',
  'andorra',
  'ae',
  'united arab emirates',
  'uae',
  'af',
  'afghanistan',
  'ag',
  'antigua',
  'ai',
  'anguilla',
  'al',
  'albania',
  'am',
  'armenia',
  'an',
  'netherlands antilles',
  'ao',
  'angola',
  'ar',
  'argentina',
  'as',
  'american samoa',
  'at',
  'austria',
  'au',
  'australia',
  'aw',
  'aruba',
  'ax',
  'aland islands',
  'az',
  'azerbaijan',
  'ba',
  'bosnia',
  'bb',
  'barbados',
  'bd',
  'bangladesh',
  'be',
  'belgium',
  'bf',
  'burkina faso',
  'bg',
  'bulgaria',
  'bh',
  'bahrain',
  'bi',
  'burundi',
  'bj',
  'benin',
  'bm',
  'bermuda',
  'bn',
  'brunei',
  'bo',
  'bolivia',
  'br',
  'brazil',
  'bs',
  'bahamas',
  'bt',
  'bhutan',
  'bv',
  'bouvet island',
  'bw',
  'botswana',
  'by',
  'belarus',
  'bz',
  'belize',
  'ca',
  'canada',
  'cc',
  'cocos islands',
  'cd',
  'congo',
  'cf',
  'central african republic',
  'cg',
  'congo brazzaville',
  'ch',
  'switzerland',
  'ci',
  'cote divoire',
  'ck',
  'cook islands',
  'cl',
  'chile',
  'cm',
  'cameroon',
  'cn',
  'china',
  'co',
  'colombia',
  'cr',
  'costa rica',
  'cs',
  'cu',
  'cuba',
  'cv',
  'cape verde',
  'cx',
  'christmas island',
  'cy',
  'cyprus',
  'cz',
  'czech republic',
  'de',
  'germany',
  'dj',
  'djibouti',
  'dk',
  'denmark',
  'dm',
  'dominica',
  'do',
  'dominican republic',
  'dz',
  'algeria',
  'ec',
  'ecuador',
  'ee',
  'estonia',
  'eg',
  'egypt',
  'eh',
  'western sahara',
  'er',
  'eritrea',
  'es',
  'spain',
  'et',
  'ethiopia',
  'eu',
  'european union',
  'fi',
  'finland',
  'fj',
  'fiji',
  'fk',
  'falkland islands',
  'fm',
  'micronesia',
  'fo',
  'faroe islands',
  'fr',
  'france',
  'ga',
  'gabon',
  'gb',
  'uk',
  'united kingdom',
  'gd',
  'grenada',
  'ge',
  'georgia',
  'gf',
  'french guiana',
  'gh',
  'ghana',
  'gi',
  'gibraltar',
  'gl',
  'greenland',
  'gm',
  'gambia',
  'gn',
  'guinea',
  'gp',
  'guadeloupe',
  'gq',
  'equatorial guinea',
  'gr',
  'greece',
  'gs',
  'sandwich islands',
  'gt',
  'guatemala',
  'gu',
  'guam',
  'gw',
  'guinea-bissau',
  'gy',
  'guyana',
  'hk',
  'hong kong',
  'hm',
  'heard island',
  'hn',
  'honduras',
  'hr',
  'croatia',
  'ht',
  'haiti',
  'hu',
  'hungary',
  'id',
  'indonesia',
  'ie',
  'ireland',
  'il',
  'israel',
  'in',
  'india',
  'io',
  'indian ocean territory',
  'iq',
  'iraq',
  'ir',
  'iran',
  'is',
  'iceland',
  'it',
  'italy',
  'jm',
  'jamaica',
  'jo',
  'jordan',
  'jp',
  'japan',
  'ke',
  'kenya',
  'kg',
  'kyrgyzstan',
  'kh',
  'cambodia',
  'ki',
  'kiribati',
  'km',
  'comoros',
  'kn',
  'saint kitts and nevis',
  'kp',
  'north korea',
  'kr',
  'south korea',
  'kw',
  'kuwait',
  'ky',
  'cayman islands',
  'kz',
  'kazakhstan',
  'la',
  'laos',
  'lb',
  'lebanon',
  'lc',
  'saint lucia',
  'li',
  'liechtenstein',
  'lk',
  'sri lanka',
  'lr',
  'liberia',
  'ls',
  'lesotho',
  'lt',
  'lithuania',
  'lu',
  'luxembourg',
  'lv',
  'latvia',
  'ly',
  'libya',
  'ma',
  'morocco',
  'mc',
  'monaco',
  'md',
  'moldova',
  'me',
  'montenegro',
  'mg',
  'madagascar',
  'mh',
  'marshall islands',
  'mk',
  'macedonia',
  'ml',
  'mali',
  'mm',
  'myanmar',
  'burma',
  'mn',
  'mongolia',
  'mo',
  'macau',
  'mp',
  'northern mariana islands',
  'mq',
  'martinique',
  'mr',
  'mauritania',
  'ms',
  'montserrat',
  'mt',
  'malta',
  'mu',
  'mauritius',
  'mv',
  'maldives',
  'mw',
  'malawi',
  'mx',
  'mexico',
  'my',
  'malaysia',
  'mz',
  'mozambique',
  'na',
  'namibia',
  'nc',
  'new caledonia',
  'ne',
  'niger',
  'nf',
  'norfolk island',
  'ng',
  'nigeria',
  'ni',
  'nicaragua',
  'nl',
  'netherlands',
  'no',
  'norway',
  'np',
  'nepal',
  'nr',
  'nauru',
  'nu',
  'niue',
  'nz',
  'new zealand',
  'om',
  'oman',
  'pa',
  'panama',
  'pe',
  'peru',
  'pf',
  'french polynesia',
  'pg',
  'new guinea',
  'ph',
  'philippines',
  'pk',
  'pakistan',
  'pl',
  'poland',
  'pm',
  'saint pierre',
  'pn',
  'pitcairn islands',
  'pr',
  'puerto rico',
  'ps',
  'palestine',
  'pt',
  'portugal',
  'pw',
  'palau',
  'py',
  'paraguay',
  'qa',
  'qatar',
  're',
  'reunion',
  'ro',
  'romania',
  'rs',
  'serbia',
  'ru',
  'russia',
  'rw',
  'rwanda',
  'sa',
  'saudi arabia',
  'sb',
  'solomon islands',
  'sc',
  'seychelles',
  'gb sct',
  'scotland',
  'sd',
  'sudan',
  'se',
  'sweden',
  'sg',
  'singapore',
  'sh',
  'saint helena',
  'si',
  'slovenia',
  'sj',
  'svalbard',
  'jan mayen',
  'sk',
  'slovakia',
  'sl',
  'sierra leone',
  'sm',
  'san marino',
  'sn',
  'senegal',
  'so',
  'somalia',
  'sr',
  'suriname',
  'st',
  'sao tome',
  'sv',
  'el salvador',
  'sy',
  'syria',
  'sz',
  'swaziland',
  'tc',
  'caicos islands',
  'td',
  'chad',
  'tf',
  'french territories',
  'tg',
  'togo',
  'th',
  'thailand',
  'tj',
  'tajikistan',
  'tk',
  'tokelau',
  'tl',
  'timorleste',
  'tm',
  'turkmenistan',
  'tn',
  'tunisia',
  'to',
  'tonga',
  'tr',
  'turkey',
  'tt',
  'trinidad',
  'tv',
  'tuvalu',
  'tw',
  'taiwan',
  'tz',
  'tanzania',
  'ua',
  'ukraine',
  'ug',
  'uganda',
  'um',
  'us minor islands',
  'us',
  'america',
  'united states',
  'uy',
  'uruguay',
  'uz',
  'uzbekistan',
  'va',
  'vatican city',
  'vc',
  'saint vincent',
  've',
  'venezuela',
  'vg',
  'british virgin islands',
  'vi',
  'us virgin islands',
  'vn',
  'vietnam',
  'vu',
  'vanuatu',
  'gb wls',
  'wales',
  'wf',
  'wallis and futuna',
  'ws',
  'samoa',
  'ye',
  'yemen',
  'yt',
  'mayotte',
  'za',
  'south africa',
  'zm',
  'zambia',
  'zw',
  'zimbabwe'
];

export function validFlag(name: string) {
  return flagNames.includes(name);
}
