import React from 'react';
import { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { ChangeRequestStore, RequestDto } from '../../../../../../stores/requests/requests-store';
import './request-reject-popup.less';
import RejectModal, { RejectProperties } from 'site/pages/shared/events-and-requests/reject-modal';

export interface RequestRejectPopupProps {
  requestId: string;
  open: boolean;
  changeRequestStore?: ChangeRequestStore;

  onCancel?: () => void;
  onSuccess?: (request: RequestDto) => void;
  onError?: () => void;
}

interface RequestRejectPopupState extends RejectProperties {
  loading: boolean;
}

@connect(['changeRequestStore', ChangeRequestStore])
class RequestRejectPopup extends Component<RequestRejectPopupProps, RequestRejectPopupState> {
  static defaultProps = { onCancel: () => {}, onSuccess: () => {}, onError: () => {} };
  state: RequestRejectPopupState = { rejectReason: '', loading: false, rejectionReasonId: null, rejectedById: null };

  handleOnSendClicked = async () => {
    const { changeRequestStore, requestId, onSuccess, onError } = this.props;
    const { rejectReason, rejectionReasonId, rejectedById } = this.state;
    this.setState({ loading: true });
    try {
      const response = await changeRequestStore.rejectRequest(requestId, rejectReason, rejectionReasonId, rejectedById);
      if (response?.isSuccess) onSuccess(response);
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      onError();
      this.setState({ loading: false });
    }
  };

  handleOnCancelClicked = () => this.props.onCancel();

  changeHandler = (property: keyof RejectProperties, value: any) => this.setState({ [property]: value } as any);

  render() {
    const { loading, rejectReason, rejectionReasonId, rejectedById } = this.state;
    const { open, changeRequestStore } = this.props;
    const errMsgs = (changeRequestStore.state.result?.messages || []).map(x => x.body);

    return (
      <RejectModal
        onClose={this.handleOnCancelClicked}
        errMsgs={errMsgs}
        loading={loading || changeRequestStore.state.isBusy}
        onChange={this.changeHandler}
        onDismiss={changeRequestStore.clearMessages}
        onSubmit={this.handleOnSendClicked}
        open={open}
        rejectReason={rejectReason}
        rejectionReasonId={rejectionReasonId}
        rejectedById={rejectedById}
      />
    );
  }
}

export default RequestRejectPopup;
