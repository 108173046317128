import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Button, Message, Dimmer, Loader, Input } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import YearEditor from 'widgets/bussiness/year-editor';
import { SsaDevelopmentPlanStore } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import SsaActivityTypeEditor from 'widgets/bussiness/ssa-activity-type-editor';
import './ssa-individual-tab-search.less';
import { ProfileItemDto } from 'stores/profile/profile-store';

export interface IndividualTabPaneSearchProps extends WithTranslation {
  ssaDevelopmentPlanStore?: SsaDevelopmentPlanStore;
  onSearch?: (filters: IndividualTabPaneSearchFilters) => void;
  onFilterChange?: (filters: IndividualTabPaneSearchFilters) => void;
  canSearch: boolean;
}

export interface IndividualTabPaneSearchFilters {
  employee: string;
  profile: ProfileItemDto;
  activity: ItemReference;
  location: LocationItemReference;
  year?: number;
}

interface IndividualTabPaneSearchState {
  filters: IndividualTabPaneSearchFilters;
  loading: boolean;
}

@connect(['ssaDevelopmentPlanStore', SsaDevelopmentPlanStore])
class IndividualTabPaneSearch extends React.Component<IndividualTabPaneSearchProps, IndividualTabPaneSearchState> {
  constructor(props: IndividualTabPaneSearchProps) {
    super(props);

    this.state = {
      filters: {
        employee: '',
        profile: null,
        activity: null,
        location: null,
        year: null
      },
      loading: false
    };
  }

  private onHandleChangeFilter = (property: string, value: any) => {
    let filters = { ...this.state.filters };
    filters[property] = value;

    this.setState({ filters });
    this.props.onFilterChange && this.props.onFilterChange(filters);
  };

  private onSearchEmployees = () => {
    this.props.onSearch && this.props.onSearch(this.state.filters);
  };

  render() {
    const { t, ssaDevelopmentPlanStore: store, canSearch } = this.props;
    const { loading, filters } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <div className="ssa-individual-tab-pane-search">
        {loading && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading')}</Loader>
          </Dimmer>
        )}

        <div className="ssa-individual-tab-pane-search__container">
          <h4 className="ssa-individual-tab-pane-search__container__title">{t('DEFINE YOUR SEARCH')}</h4>
          {messages.length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={messages.map(o => o.body)}
              onDismiss={() => this.props.ssaDevelopmentPlanStore.clearMessages()}
            />
          )}
          <Form className="ssa-individual-tab-pane-search__container__search-form">
            <div className="search-form__wrapper">
              <Form.Group className="search-form__filters">
                <Form.Field className="filters-container-field filters__employee-wrapper">
                  <label>{t('Employee')}</label>
                  <Input
                    className="custom-editor-search"
                    icon="search"
                    placeholder={t('Search Employee')}
                    value={filters.employee}
                    onChange={(_, { value }) => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('employee'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Profile')}</label>
                  <ProfileEditor
                    nullable
                    clearable
                    className="custom-editor"
                    placeholder={t('Profile')}
                    assessment="SSA"
                    value={filters.profile && filters.profile.id}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('profile'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Activity')}</label>
                  <SsaActivityTypeEditor
                    className="custom-editor"
                    clearable
                    nullable
                    placeholder={t('Activity')}
                    value={filters.activity}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('activity'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Location')}</label>
                  <LocationEditor
                    nullable
                    clearable
                    className="custom-editor"
                    placeholder={t('Location')}
                    filterLocationsByActiveRole
                    value={filters.location}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('location'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Year')}</label>
                  <YearEditor
                    clearable
                    placeholder={t('Year')}
                    className="custom-editor year-editor"
                    value={filters.year}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('year'), value)}
                  />
                </Form.Field>
              </Form.Group>
            </div>
            <div className="search-form__btn-wrapper">
              <Button className="search-form__search-employees-btn basic" onClick={this.onSearchEmployees} disabled={!canSearch} icon>
                {t('Search Employees')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(IndividualTabPaneSearch);
