import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface PerdiemDto extends BaseDto {
  id: string; //Guid
  locationId: string; //Guid
  locationName: string;
  perdiem: number;
  hardship: number;
  currency: string;
}

export interface CreatePerdiemDto {
  locationId: string; //Guid
  perdiem: number;
  hardship: number;
  currency: string;
}

export interface ChangePerdiemDto {
  id: string; //Guid
  locationId: string; //Guid
  perdiem: number;
  hardship: number;
  currency: string;
}

export class CreatePerdiemValidator extends AbstractValidator<CreatePerdiemDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIf(t => t)
      .fulfills(t => t.perdiem > 0)
      .withFailureMessage(i18n.t('The value of Perdiem must be greater than 0'));

    this.validateIfString(o => o.currency)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Currency is required'));
  }
}

export class ChangePerdiemValidator extends AbstractValidator<ChangePerdiemDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIf(t => t)
      .fulfills(t => t.perdiem > 0)
      .withFailureMessage(i18n.t('The value of Perdiem must be greater than 0'));

    this.validateIfString(o => o.currency)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Currency is required'));
  }
}

@repository('@@PERDIEMS', 'perdiems.summary')
export class PerdiemsStore extends DataStore<PerdiemDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-perdiem';
  retrievePath = 'get-perdiems';
  updatePath = 'update-perdiem';
  deletePath = 'delete-perdiem';

  protected validate(item: PerdiemDto) {
    return new ChangePerdiemValidator().validate(item);
  }

  constructor() {
    super('PERDIEM', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PERDIEMS', 'perdiems.new')
export class NewPerdiemStore extends FormStore<CreatePerdiemDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-perdiem';
  retrievePath = 'get-perdiems';
  updatePath = 'update-perdiem';

  protected validate(item: CreatePerdiemDto) {
    return new CreatePerdiemValidator().validate(item);
  }

  constructor() {
    super('NEW_PERDIEM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PERDIEMS', 'perdiems.change')
export class ChangePerdiemStore extends FormStore<ChangePerdiemDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-perdiem';
  retrievePath = 'get-perdiems';
  updatePath = 'update-perdiem';

  protected validate(item: ChangePerdiemDto) {
    return new ChangePerdiemValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PERDIEM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
