import { injectable } from 'inversify';
import { InstructorRowFilterValues } from './instructor-row-filter';
import { InstructorExpertiseStore, InstructorExpertiseDto } from 'stores/skills/instructor-expertise-store';
import { AlwaysVisibleFiltersValue } from '../../shared-scheduler-components/scheduler-header/filters/always-visible-filters';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';
import { InvisibleFiltersValue } from '../../shared-scheduler-components/scheduler-header/filters/invisible-filters';

@injectable()
export class InstructorRowFilterService {
  getDefaultFilterValues(): InstructorRowFilterValues {
    return {
      equipmentTypes: [],
      eventTypes: [],
      functionalSubareas: [],
      machineModels: [],
      professions: [],
      traininglevels: []
    };
  }

  private identityService: IdentityService;

  constructor() {
    this.identityService = container.get(IdentityService);
  }

  public hasAnyActiveFilter = (
    value: InstructorRowFilterValues,
    visibleFilters: AlwaysVisibleFiltersValue,
    invisibleFilters: InvisibleFiltersValue
  ) =>
    this.hasAnyActiveInstructorRowFilterValue(value) ||
    visibleFilters.filterByMe ||
    visibleFilters.filterByMyPillar ||
    (visibleFilters.instructors || []).length ||
    (visibleFilters.instructorLocations || []).length ||
    (invisibleFilters.pillars || []).length ||
    (visibleFilters.requestLocations || []).length ||
    visibleFilters.roleInstructor ||
    visibleFilters.pillarManagedBy;

  public hasAnyActiveInstructorRowFilterValue = (value: InstructorRowFilterValues) =>
    (value.eventTypes || []).length !== 0 ||
    (value.functionalSubareas || []).length !== 0 ||
    (value.machineModels || []).length !== 0 ||
    (value.professions || []).length !== 0 ||
    (value.traininglevels || []).length !== 0 ||
    (value.equipmentTypes || []).length !== 0;

  public fullfillsAllFilters(instructorExpertises: InstructorExpertiseDto[], values: InstructorRowFilterValues): boolean {
    return (
      values.eventTypes.all(eventTypeId => instructorExpertises.any(expertise => expertise.eventTypeId === eventTypeId)) &&
      values.functionalSubareas.all(functionalSubareaId =>
        instructorExpertises.any(expertise => expertise.nmrFunctionalSubAreaId === functionalSubareaId)
      ) &&
      values.machineModels.all(machineModelId => instructorExpertises.any(expertise => expertise.machineModelId === machineModelId)) &&
      values.professions.all(professionId => instructorExpertises.any(expertise => expertise.roleId === professionId)) &&
      values.traininglevels.all(traininglevelId => instructorExpertises.any(expertise => expertise.trainingLevelId === traininglevelId)) &&
      values.equipmentTypes.all(equipmentTypeId => instructorExpertises.any(expertise => expertise.equipmentTypeId === equipmentTypeId))
    );
  }

  public getAllInstructorsThatFullfills(
    instructorExpertiseStore: InstructorExpertiseStore,
    instructorRowFilterValues: InstructorRowFilterValues,
    alwaysVisibleFilters: AlwaysVisibleFiltersValue
  ) {
    return instructorExpertiseStore.getAllAsync(this.buildODataQuery(instructorRowFilterValues, alwaysVisibleFilters)).then(response => {
      let instructorExpertises: { [id: string]: InstructorExpertiseDto[] } = {};

      response.items.forEach(expertise => {
        if (alwaysVisibleFilters.filterByMe && expertise.instructorId !== this.identityService.userId) return;

        if (!instructorExpertises[expertise.instructorId]) instructorExpertises[expertise.instructorId] = [];

        instructorExpertises[expertise.instructorId].push(expertise);
      });

      return instructorExpertises;
    });
  }

  buildODataQuery(value: InstructorRowFilterValues, alwaysVisibleFilters: AlwaysVisibleFiltersValue) {
    let filters = [];

    if (value.eventTypes.length !== 0) filters.push({ EventTypeId: { in: { type: 'guid', value: value.eventTypes } } });
    if (value.machineModels.length !== 0) filters.push({ MachineModelId: { in: { type: 'guid', value: value.machineModels } } });
    if (value.functionalSubareas.length !== 0)
      filters.push({ NMRFunctionalSubAreaId: { in: { type: 'guid', value: value.functionalSubareas } } });
    if (value.professions.length !== 0) filters.push({ RoleId: { in: { type: 'guid', value: value.professions } } });
    if (value.traininglevels.length !== 0) filters.push({ TrainingLevelId: { in: { type: 'guid', value: value.traininglevels } } });
    if (value.equipmentTypes.length !== 0) filters.push({ EquipmentTypeId: { in: { type: 'guid', value: value.equipmentTypes } } });

    let orFilter = [{ or: filters }];

    let query = {
      searchQuery: '',
      filter: orFilter,
      orderBy: [],
      skip: 0,
      take: 100000
    };

    return query;
  }
}
