import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { DropDownEventTypeGroupsStore, EventTypeGroupDto } from 'stores/configuration/events-n-requests/event-type-group-store';
import { DropDownTypeHoursCodeStore, TypeHoursCodeDto } from 'stores/configuration/events-n-requests/type-hour-code-store';
import { nameof } from 'utils/object';

import {
  ChangeEventGroupRelationshipDto,
  ChangeEventGroupRelationshipStore,
  EventGroupRelationshipDto
} from '../../../../../stores/configuration/events-n-requests/event-group-rel-store';
// import { nameof } from '../../../../../utils/object';

interface ChangeEventGroupRelationshipViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeEventGroupRelationship?: ChangeEventGroupRelationshipStore;
  currentEventGroupRelationship: EventGroupRelationshipDto;
  dropDownEventTypeGroup?: DropDownEventTypeGroupsStore;
  dropDownTypeHoursCode?: DropDownTypeHoursCodeStore;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeEventGroupRelationshipViewState {
  eventGroupOptions: any[];
  typeHourOptions: any[];
}

@connect(
  ['changeEventGroupRelationship', ChangeEventGroupRelationshipStore],
  ['dropDownEventTypeGroup', DropDownEventTypeGroupsStore],
  ['dropDownTypeHoursCode', DropDownTypeHoursCodeStore]
)
class ChangeEventGroupRelationshipView extends React.Component<
  ChangeEventGroupRelationshipViewProps,
  ChangeEventGroupRelationshipViewState
> {
  private get changeEventGroupRelationshipStore() {
    return this.props.changeEventGroupRelationship;
  }

  constructor(props: ChangeEventGroupRelationshipViewProps) {
    super(props);

    this.changeEventGroupRelationshipStore.state.result = null;

    this.changeEventGroupRelationshipStore.change({
      id: this.props.currentEventGroupRelationship.id,
      eventGroupId: this.props.currentEventGroupRelationship.eventGroupId,
      leadTypeHoursCodeId: this.props.currentEventGroupRelationship.leadTypeHoursCodeId,
      apprenticeTypeHoursCodeId: this.props.currentEventGroupRelationship.apprenticeTypeHoursCodeId
    });

    this.state = {
      eventGroupOptions: [],
      typeHourOptions: []
    };
  }

  private get dropDownEventTypeGroupsStore() {
    return this.props.dropDownEventTypeGroup;
  }

  private get dropDownTypeHoursCodeStore() {
    return this.props.dropDownTypeHoursCode;
  }
  componentDidMount(): void {
    this.init();
  }

  private async init() {
    let eventTypes = this.getAllEventGroups();
    let hourCodes = this.getAllTypeHourCodes();

    let eventGroupResponse = await eventTypes;
    let typeHourCodeResponse = await hourCodes;
    // Promise.all([...requests]).then(([eventGroupResponse, typeHourCodeResponse]) => {
    // let allOptions = {};

    const ego = this.mapEventGroups(eventGroupResponse.items); //.forEach(option => (allOptions[this.buildKey(option)] = option));
    const tho = this.mapTypeHourCodes(typeHourCodeResponse.items); //.forEach(option => (allOptions[this.buildKey(option)] = option));
    //const tho = this.mapTypeHourCodes(typeHourCodeResponse.items, nameof<EventGroupRelationshipDto>('apprenticeTypeHourCode'));//.forEach(option => (allOptions[this.buildKey(option)] = option));

    this.setState({
      // isLoading: false,
      eventGroupOptions: ego,
      typeHourOptions: tho
    });

    // });
  }
  private getAllEventGroups() {
    return this.dropDownEventTypeGroupsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<EventTypeGroupDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllTypeHourCodes() {
    return this.dropDownTypeHoursCodeStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<TypeHoursCodeDto>('code'), useProfile: false }],
      filter: []
    });
  }

  private mapEventGroups(eventTypeGroups: EventTypeGroupDto[]): any[] {
    return eventTypeGroups.map(etg => ({
      value: etg.id,
      text: etg.name,
      id: etg.id
    }));
  }

  private mapTypeHourCodes(typeHoursCodes: TypeHoursCodeDto[]): any[] {
    return typeHoursCodes.map(thc => ({
      value: thc.id,
      text: thc.code,
      id: thc.id
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeEventGroupRelationshipViewProps) {
    if (this.changeEventGroupRelationshipStore.state.result && this.changeEventGroupRelationshipStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeEventGroupRelationshipStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeEventGroupRelationshipStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeEventGroupRelationshipStore.change({ ...this.changeEventGroupRelationshipStore.state.item, ...change });
  }

  public render() {
    const { t, currentEventGroupRelationship } = this.props as any;
    const { result } = this.changeEventGroupRelationshipStore.state;
    const { eventGroupOptions, typeHourOptions } = this.state;
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeEventGroupRelationshipStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Event Group Relation')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeEventGroupRelationshipStore.state.result && !this.changeEventGroupRelationshipStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeEventGroupRelationshipStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeEventGroupRelationshipStore.state.item && (
              <Form loading={this.changeEventGroupRelationshipStore.state.isBusy}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>{t('Event Group')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder={'Event Group'}
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(x => x.propertyName === nameof<ChangeEventGroupRelationshipDto>('eventGroupId'))
                      }
                      options={eventGroupOptions || []}
                      value={currentEventGroupRelationship.eventGroupId}
                      onChange={(_, { value }) => this.handleValue(nameof<ChangeEventGroupRelationshipDto>('eventGroupId'), value)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>{t('Lead Type Hour Code')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder="Lead Type Hour Code"
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(x => x.propertyName === nameof<ChangeEventGroupRelationshipDto>('leadTypeHoursCodeId'))
                      }
                      options={typeHourOptions || []}
                      value={currentEventGroupRelationship.leadTypeHoursCodeId}
                      onChange={(_, { value }) => this.handleValue(nameof<ChangeEventGroupRelationshipDto>('leadTypeHoursCodeId'), value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t('Apprentice Type Hour Code')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder="Apprentice Type Hour Code"
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(
                          x => x.propertyName === nameof<ChangeEventGroupRelationshipDto>('apprenticeTypeHoursCodeId')
                        )
                      }
                      options={typeHourOptions || []}
                      value={currentEventGroupRelationship.apprenticeTypeHoursCodeId}
                      onChange={(_, { value }) =>
                        this.handleValue(nameof<ChangeEventGroupRelationshipDto>('apprenticeTypeHoursCodeId'), value)
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeEventGroupRelationshipView);
