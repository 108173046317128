import React, { Component } from 'react';
import 'site/pages/assessments/tna/templates/wizard/tna-templates-wizard.less';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dimmer, Loader, Message, Button, Modal } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import LineSeparator from 'widgets/bussiness/line-separator';
import {
  PracticalTemplateWizardViewModel,
  getDefaultViewModel,
  mapToChangeDto,
  mapToCreateDto,
  mapToViewModel
} from './practical-template-wizard-utils';
import {
  PracticalTemplateItemDto,
  PracticalTemplatesStore,
  NewPracticalTemplatesStore,
  ChangePracticalTemplatesStore
} from 'stores/assessments/templates/practical-templates-store';
import { connect } from 'redux-scaffolding-ts';
import { arrayValuesCompare, isNullOrWhiteSpaces, setEquals, trim } from 'utils/useful-functions';
import { CommandResult, Message as MessageType } from 'stores/types';
import { clone } from 'utils/object';
import GeneralDataStep from './steps/general-data-step/general-data-step';
import MachineryStep from './steps/machinery-step/machinery-step';
import QuestionsStep from './steps/questions-step/questions-step';

export interface PracticalFormWizardProps extends WithTranslation, RouteComponentProps {
  practicalTemplatesStore?: PracticalTemplatesStore;
  newPracticalTemplateStore?: NewPracticalTemplatesStore;
  updatePracticalTemplateStore?: ChangePracticalTemplatesStore;
}

type ModalMode = 'cancel' | 'accept';
export interface PracticalFormWizardState {
  step: number;
  showCancelConfirm: boolean;
  confirmationModalMode: ModalMode;
  mode?: 'Create' | 'Edit';
  loading: boolean;
  viewModel: PracticalTemplateWizardViewModel;
}

@connect(
  ['practicalTemplatesStore', PracticalTemplatesStore],
  ['newPracticalTemplateStore', NewPracticalTemplatesStore],
  ['updatePracticalTemplateStore', ChangePracticalTemplatesStore]
)
class PracticalTemplateWizard extends Component<PracticalFormWizardProps, PracticalFormWizardState> {
  constructor(props: Readonly<PracticalFormWizardProps>) {
    super(props);

    this.state = {
      step: 1,
      showCancelConfirm: false,
      confirmationModalMode: null,
      mode: null,
      loading: false,
      viewModel: getDefaultViewModel()
    };
  }

  private initWizardOnCreateMode = () => {
    this.setState({ mode: 'Create', viewModel: getDefaultViewModel() });
  };

  private initWizardOnEditmode = async (id: string, data: PracticalTemplateItemDto) => {
    if (data != null) {
      this.setState({ mode: 'Edit', viewModel: mapToViewModel(data) }, this.updateStep);
    } else {
      this.setState({ loading: true });
      const template = await this.loadTemplateById(id);
      this.setState({ loading: false, mode: 'Edit', viewModel: mapToViewModel(template) }, this.updateStep);
    }
  };

  private loadTemplateById = async (id: string): Promise<PracticalTemplateItemDto> => {
    const { practicalTemplatesStore, history } = this.props;
    try {
      return await practicalTemplatesStore.getTemplateById(id);
    } catch (error) {
      console.error('load template edit mode error:', error);
      history.replace('/not-found');
    }
  };

  componentDidMount() {
    const { params } = this.props.match;
    const id = params['id'] as string;

    const { state } = this.props.location;
    const templateData = state as PracticalTemplateItemDto;

    if (id != null) this.initWizardOnEditmode(id, templateData);
    else this.initWizardOnCreateMode();
  }

  private checkStep1 = (): boolean => {
    const { title, profession, machineRelated, eventTypes } = this.state.viewModel;
    return (
      !isNullOrWhiteSpaces(title) &&
      !isNullOrWhiteSpaces(profession?.id) &&
      machineRelated != null &&
      (eventTypes || []).length !== 0 &&
      eventTypes.all(x => !isNullOrWhiteSpaces(x?.id))
    );
  };

  private checkStep2 = (): boolean => {
    const { trainingLevel, machineRelated, nmrCluster, equipmentType, mrCluster, functionalArea } = this.state.viewModel;
    if (isNullOrWhiteSpaces(trainingLevel?.id) || machineRelated == null) return false;
    if (machineRelated) return !isNullOrWhiteSpaces(mrCluster?.id) && !isNullOrWhiteSpaces(equipmentType?.id);

    return !isNullOrWhiteSpaces(nmrCluster?.id) && !isNullOrWhiteSpaces(functionalArea?.id);
  };

  private checkStep3 = (): boolean => {
    const { machineRelated, mrQuestions, nmrQuestions } = this.state.viewModel;
    if (machineRelated == null) return false;
    if (machineRelated) return (mrQuestions || []).length !== 0;
    return (nmrQuestions || []).length !== 0;
  };

  private updateStep = () => {
    const step = this.getCurrentStep();
    this.setState({ step: step });
  };

  private getCurrentStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      return 1;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      return 2;
    }
    return 3;
  };

  private canSubmit = (): boolean => {
    return this.checkStep1() && this.checkStep2() && this.checkStep3();
  };

  private onCancelChangesClicked = () => {
    const { history } = this.props;
    history.goBack();
  };

  private scrollToTop() {
    document.getElementById('root').scrollTop = 0;
  }

  private onBackClicked = () => {
    this.setState({ showCancelConfirm: false });
  };

  confirmationModalHandler = (mode: ModalMode) => {
    this.setState({ showCancelConfirm: true, confirmationModalMode: mode });
  };

  cancelConfirmationModal = () => {
    this.setState({ showCancelConfirm: false, confirmationModalMode: null });
  };

  private handleOnSubmit = async () => {
    if (!this.canSubmit()) return;
    this.setState({ loading: true, showCancelConfirm: false });
    const { viewModel, mode } = this.state;
    const { newPracticalTemplateStore, updatePracticalTemplateStore: updatePracticalTemplateSore, history } = this.props;
    let result: CommandResult<any> = null;

    try {
      if (mode === 'Create') {
        const createDto = mapToCreateDto(viewModel);
        newPracticalTemplateStore.createNew(createDto);
        result = await newPracticalTemplateStore.submit();
      } else {
        const editDto = mapToChangeDto(viewModel);
        updatePracticalTemplateSore.createNew(editDto);
        result = await updatePracticalTemplateSore.update();
      }
      if (result?.isSuccess) {
        history.goBack();
      } else {
        this.setState({ loading: false });
        this.scrollToTop();
      }
    } catch {
      this.setState({ loading: false });
      this.scrollToTop();
    }
  };

  private getHeaderTitle = () => {
    if (this.state.mode === 'Create') return 'Practical Template Creation';

    if (this.state.mode === 'Edit') return 'Practical Template Edition';

    return 'Practical Template';
  };

  private getErrorMessages = () => {
    const { newPracticalTemplateStore, updatePracticalTemplateStore, practicalTemplatesStore } = this.props;
    const { mode } = this.state;
    let messages: MessageType[] = [];
    if (mode === 'Create' && (newPracticalTemplateStore.state?.result?.messages || []).length !== 0)
      messages = [...messages, ...newPracticalTemplateStore.state.result.messages];
    if (mode === 'Edit' && (updatePracticalTemplateStore.state?.result?.messages || []).length !== 0)
      messages = [...messages, ...updatePracticalTemplateStore.state.result.messages];
    if ((practicalTemplatesStore.state?.result?.messages || []).length !== 0)
      messages = [...messages, ...practicalTemplatesStore.state.result.messages];
    return messages.map(x => x.body).filter(x => !isNullOrWhiteSpaces(x));
  };

  private onDismissErrorMessages = () => {
    const { newPracticalTemplateStore, updatePracticalTemplateStore, practicalTemplatesStore } = this.props;
    newPracticalTemplateStore.clearMessages();
    updatePracticalTemplateStore.clearMessages();
    practicalTemplatesStore.clearMessages();
  };

  private mustRegenerateTitle = (prev: PracticalTemplateWizardViewModel, next: PracticalTemplateWizardViewModel) => {
    if (
      prev.machineRelated !== next.machineRelated ||
      !setEquals(prev.eventTypes, next.eventTypes, (x, y) => x.id === y.id) ||
      prev.profession?.id !== next.profession?.id ||
      prev.trainingLevel?.id !== next.trainingLevel?.id
    )
      return true;
    if (next.machineRelated)
      return (
        prev.mrCluster?.id !== next.mrCluster?.id ||
        prev.equipmentType?.id !== next.equipmentType?.id ||
        prev.oem?.id !== next.oem?.id ||
        !arrayValuesCompare(prev.machineModel, next.machineModel)
      );

    return (
      prev.nmrCluster?.id !== next.nmrCluster?.id ||
      prev.functionalArea?.id !== next.functionalArea?.id ||
      prev.trainingName?.id !== next.trainingName?.id ||
      prev.functionalSubarea?.id !== next.functionalSubarea?.id
    );
  };

  private mustResetQuestions = (prev: PracticalTemplateWizardViewModel, next: PracticalTemplateWizardViewModel) => {
    if (prev.machineRelated !== next.machineRelated || prev.profession?.id !== next.profession?.id) return true;
    if (next.machineRelated)
      return (
        prev.mrCluster?.id !== next.mrCluster?.id ||
        prev.equipmentType?.id !== next.equipmentType?.id ||
        prev.oem?.id !== next.oem?.id ||
        !arrayValuesCompare(prev.machineModel, next.machineModel)
      );

    return (
      prev.nmrCluster?.id !== next.nmrCluster?.id ||
      prev.functionalArea?.id !== next.functionalArea?.id ||
      prev.trainingName?.id !== next.trainingName?.id ||
      prev.functionalSubarea?.id !== next.functionalSubarea?.id
    );
  };

  private generateNewTitle = (viewModel: PracticalTemplateWizardViewModel) => {
    let parts = [...(viewModel.eventTypes || []).map(x => x.title), viewModel.profession?.title, viewModel.trainingLevel?.title];

    if (viewModel.machineRelated) {
      parts = [
        ...parts,
        viewModel.mrCluster?.title,
        viewModel.equipmentType?.title,
        viewModel.oem?.title,
        (viewModel.machineModel || []).map(x => x.title).join(' ')
      ];
    } else {
      parts = [
        ...parts,
        viewModel.nmrCluster?.title,
        viewModel.functionalArea?.title,
        viewModel.trainingName?.title,
        viewModel.functionalSubarea?.title
      ];
    }

    return parts
      .map(x => trim(x ?? ''))
      .filter(x => !isNullOrWhiteSpaces(x))
      .join(' - ');
  };

  private onViewModelChanged = (next: PracticalTemplateWizardViewModel) => {
    const { viewModel: prev } = this.state;
    if (this.mustRegenerateTitle(prev, next)) {
      next.title = this.generateNewTitle(next);
    }
    if (this.mustResetQuestions(prev, next)) {
      next.nmrQuestions = [];
      next.mrQuestions = [];
    }
    this.setState({ viewModel: next }, this.updateStep);
  };

  render() {
    const { t, newPracticalTemplateStore, updatePracticalTemplateStore, practicalTemplatesStore } = this.props;

    const { mode, loading, viewModel, showCancelConfirm, step, confirmationModalMode } = this.state;
    const messages = this.getErrorMessages();
    const showDimmer =
      loading ||
      newPracticalTemplateStore.state.isBusy ||
      updatePracticalTemplateStore.state.isBusy ||
      practicalTemplatesStore.state.isBusy;

    return (
      <>
        <Dimmer page active={showDimmer} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading...')}</Loader>
        </Dimmer>
        <h3 className="template-wizard-title">{t(this.getHeaderTitle())}</h3>
        <LineSeparator />
        {messages && messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            list={messages}
            onDismiss={this.onDismissErrorMessages}
          />
        )}
        {mode != null && (
          <div className="wizard__steps-container">
            <GeneralDataStep
              active={step >= 1}
              mode={mode}
              viewModel={clone<PracticalTemplateWizardViewModel>(viewModel)}
              onChange={this.onViewModelChanged}
            />
            <MachineryStep
              active={step >= 2}
              mode={mode}
              viewModel={clone<PracticalTemplateWizardViewModel>(viewModel)}
              onChange={this.onViewModelChanged}
            />
            <QuestionsStep
              active={step >= 3}
              mode={mode}
              viewModel={clone<PracticalTemplateWizardViewModel>(viewModel)}
              onChange={this.onViewModelChanged}
            />

            <div className="tna-templates-wizard__btns">
              <Button secondary inverted onClick={() => this.confirmationModalHandler('cancel')}>
                {t('Cancel')}
              </Button>
              {this.canSubmit() && (
                <Button primary positive onClick={() => this.confirmationModalHandler('accept')} disabled={!this.canSubmit()}>
                  {t(`${mode === 'Create' ? 'Create' : 'Save'} Template`)}
                </Button>
              )}
            </div>
          </div>
        )}
        {showCancelConfirm && (
          <Modal
            open={showCancelConfirm}
            size="mini"
            className="tna-confirmation-modal"
            closeOnEscape={true}
            onClose={confirmationModalMode === 'accept' ? this.onCancelChangesClicked : this.onBackClicked}
          >
            <Modal.Content className="tna-confirmation-modal__content">
              {confirmationModalMode === 'accept' ? (
                <p>
                  {t(
                    `${
                      mode === 'Create'
                        ? 'Do you want to create the Practical Template'
                        : 'Are you sure do you want to apply this changes in this Practical Template?'
                    }`
                  )}
                </p>
              ) : (
                <>
                  <p>{t('Are you sure you want to CANCEL?')}</p>
                  <p>{t('You will lose all the changes made in this Practical Template.')}</p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="tiny"
                className="change-status-popup__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                onClick={confirmationModalMode === 'accept' ? this.onCancelChangesClicked : this.onBackClicked}
              />
              <Button
                size="tiny"
                className="change-status-popup__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                onClick={confirmationModalMode === 'accept' ? this.handleOnSubmit : this.onCancelChangesClicked}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(PracticalTemplateWizard);
