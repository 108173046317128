import React, { Component } from 'react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { WithTranslation, withTranslation } from 'react-i18next';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateInput } from 'widgets/form/dateInput';
import '../../../tna/forms/wizard/steps/general-data-step.less';
import { nameof } from 'utils/object';
import { DateTimeService } from 'services/datetime-service';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { NewTnaTheoreticalTestViewNodel } from '../new-tna-theoretical-test';
import { Input } from 'semantic-ui-react';
import ProfileEditor from 'widgets/bussiness/profile-editor';

export interface GeneralDataStepProps extends WithTranslation {
  stepActive: boolean;
  viewModel: NewTnaTheoreticalTestViewNodel;
  onChange: (vm: NewTnaTheoreticalTestViewNodel) => void;
}

class GeneralDataStep extends Component<GeneralDataStepProps, {}> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private handleOnChange = (property: string, propertyValue: any) => {
    const { viewModel, onChange } = this.props;
    viewModel[property] = propertyValue;
    onChange(viewModel);
  };

  render() {
    const { t, viewModel: value, stepActive } = this.props;

    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    return (
      <div className="wizard__step wizard__step-one tna-form-general-data">
        <StepsComponent active={stepActive} number={'1'} title={t('GENERAL DATA')}>
          <div className="step-one__content tna-form-wizard__step-one__title-wrapper editor-38px">
            <label>{t('TNA Theoretical Title')}</label>
            <Input
              value={value.title}
              onChange={(_, { value }) => this.handleOnChange(nameof<NewTnaTheoreticalTestViewNodel>('title'), value)}
            />
          </div>

          <div className="step-one__content tna-form-step-one__content">
            <div className={`editor-38px ${pocLocations.length === 1 ? 'normal-looking-disabled-input' : ''}`}>
              <label>{t('Select Location')}</label>
              <LocationEditor
                nullable={pocLocations.length !== 1}
                clearable={pocLocations.length !== 1}
                readOnly={pocLocations.length === 1}
                placeholder={t('Location')}
                value={value.location}
                onChange={l => this.handleOnChange(nameof<NewTnaTheoreticalTestViewNodel>('location'), l)}
                locationsReceived={pocLocations}
              />
            </div>
            <div className="editor-38px">
              <label>{t('Whom is this form for?')}</label>
              <ProfileEditor
                placeholder={t('Profile')}
                nullable
                value={value.profile ? value.profile.id : ''}
                onChange={profile => this.handleOnChange(nameof<NewTnaTheoreticalTestViewNodel>('profile'), profile)}
                clearable={true}
              />
            </div>
            <div className="tna-form-wizard__step-one__deadline-wrapper editor-38px">
              <label>{t('Set Deadline')}</label>
              <DateInput
                value={value.deadline}
                onChange={(_, d) => this.handleOnChange(nameof<NewTnaTheoreticalTestViewNodel>('deadline'), d)}
                iconPosition="right"
                minDate={DateTimeService.toString(DateTimeService.today())}
              />
            </div>
          </div>
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(GeneralDataStep);
