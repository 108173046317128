import { BaseDto, CommandResult } from '../../types';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult, Query } from '../../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';

import { LocationDto } from 'stores/configuration/locations/locations-store';
import { AbstractValidator, ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import i18n from 'i18n';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';

import { TnaTemplateDto } from '../templates/tna-templates-store';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';

export type TnaAssessmentType = 'Unknown' | 'SameMachineHierarchy' | 'MultipleMachineHierarchy';

export interface TnaFormListDto extends BaseDto {
  id: string;
  title: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  profileId: string;
  profileName: string;
  machineModels: MachineModelDto[];
  createAt: string;
  deadline: string;
  status: TnaFormStatus;
  outOfDate: boolean;
  isActive: boolean;
  isStarted: boolean;
  extendedStatus: string[];
}
export type AnswerStatus = 'Unknown' | 'Pending' | 'PendingRecommendation' | 'Done';
export interface AssessorAnswerStatus {
  answerStatus: AnswerStatus;
  userId: string;
}
export interface TnaFormItemDto {
  id: string;
  friendlyId: string;
  assessorAnswerStatus: AssessorAnswerStatus[];
  userId: string;
  user: SimpleUserDto;
  bypassEmployeeEvaluation: boolean;
  tnaAssessmentId: string;
  tnaAssessment: AssessmentDto;
  machineModelsSelection: MachineModelFormSelectionDto[];
  lineManagerId: string;
  lineManager: SimpleUserDto;
  bypassLineManagerEvaluation: boolean;
  machineFunctionalExpertId: string;
  machineFunctionalExpert: SimpleUserDto;
  nonMachineFunctionalExperts: FunctionalExpertDto[];
  machineFunctionalExperts: MachineFunctionalExpertDto[];
  status: TnaFormStatus;
  deadline: string;
  outOfDate: boolean;
  answers?: TnaFormAnswersDto;
  currentLanguage?: string;
  isActive: boolean;
}
export interface ChangeActiveFlagTnaFormDto {
  id: string;
  isActive: boolean;
}

export interface MachineModelFormSelectionDto {
  machineModelId: string;
  machineModel: MachineModelDto;
  selectedMachineUnits: string[];
}
export interface FunctionalExpertDto {
  userId: string;
  user: SimpleUserDto;
  skills: FunctionalExpertSkillDto[];
}

export interface FunctionalExpertSkillDto {
  id: string;
  name: string;
}
export interface MachineFunctionalExpertDto {
  userId: string;
  user: SimpleUserDto;
  machineModels: MachineFunctionalExpertMachineModelDto[];
}

export interface MachineFunctionalExpertMachineModelDto {
  id: string;
  name: string;
}

export interface TnaFormAnswersDto {
  considerForFactoryInteralTrainer: boolean;
  considerForGSCInteralTrainer: boolean;
  nonRelatedMachineSkillAnswers: SkillAnswersDto[];
  machineModelAnswers: MachineModelAnswersDto[];
  recommendationOfExpertForInternalTrainer?: InternalTrainerRecommendationDto;
  recommendationOfManagerForInternalTrainer?: InternalTrainerRecommendationDto;
}

export interface InternalTrainerRecommendationDto {
  forFactoryInteralTrainer: boolean;
  forGSCInteralTrainer: boolean;
  comment: string;
}
export interface MachineModelAnswersDto {
  machineModelId: string;
  machineModel: string;
  machineUnitAnswers: MachineUnitAnswersDto[];
  skillAverages: SkillAverages[];
  totalSkillAverage: Average;
  tnaTemplateId: string;
  overallRecommendation: string;
  overallRecommendationId: string;
}

export interface SkillAverages {
  [key: string]: Average;
}

export interface ChangeMachineModelAnswersDto {
  machineModelId: string;
  machineUnitAnswers: ChangeMachineUnitAnswersDto[];
  overallRecommendationId?: string;
  overallRecommendation?: string;
}

export interface MachineUnitAnswersDto {
  machineUnitId: string;
  machineUnitName: string;
  average: Average;
  skillAnswers: SkillAnswersDto[];
  functionalExpertRecomendation: string;
}

export interface ChangeMachineUnitAnswersDto {
  machineUnitId: string;
  skillAnswers: ChangeSkillAnswersDto[];
  functionalExpertRecomendation?: string;
}

export interface SkillAnswersDto {
  skillId: string;
  skillName: string;
  average: Average;
  questionAnswers: QuestionAnswerDto[];
  functionalExpertRecomendation: string;
}

export interface ChangeSkillAnswersDto {
  skillId: string;
  questionAnswers: ChangeQuestionAnswerDto[];
  functionalExpertRecomendation?: string;
}

export interface Average {
  employeeAverage: number;
  functionalExpertAverage: number;
  lineManagerAverage: number;
  totalAverage: number;
}

export interface QuestionAnswerDto {
  questionId: string;
  questionText: string;
  average: Average;
  employeeAnswer: UserAnswerDto;
  lineManagerAnswer: UserAnswerDto;
  functionalExpertAnswer: UserAnswerDto;
  question: QuestionDto;
  questionTranslations: QuestionDto[];
}

export interface QuestionDto {
  text: string;
  languageId: string;
}

export interface ChangeQuestionAnswerDto {
  questionId: string;
  answer: number;
  comment: string;
}

export interface ChangeTnaFormAnswerDto {
  id: string; // TNA Form Id
  nonMachineRelatedSkillAnswers: ChangeSkillAnswersDto[];
  machineModelAnswers: ChangeMachineModelAnswersDto[];
  considerForFactoryInteralTrainer: boolean;
  considerForGSCInteralTrainer: boolean;
  recommendationOfExpertForInternalTrainer?: InternalTrainerRecommendationDto;
  recommendationOfManagerForInternalTrainer?: InternalTrainerRecommendationDto;
}

export interface UserAnswerDto {
  answer: number;
  comment: string;
}
export interface AssessmentDto {
  id: string;
  title: string;
  tnaTemplateId: string;
  tnaTemplate: TnaTemplateDto;
  functionalExpert?: any;
  type: TnaAssessmentType;
}

export type TnaFormStatus = 'Employee' | 'Assessors' | 'Done';

export interface SimpleUserDto {
  firstName: string;
  lastName: string;
  locationId?: string;
  location: LocationDto;
  sfPosition: string;
  employeeId: string;
  positionCodeId?: string;
  positionCodeItem: PositionCodeDto;
  hireDate?: string;
}

//CHANGE TNA FORM WIZARD

export interface ChangeTnaFormDto {
  id: string;
  machineModelsSelection: ChangeMachineModelFormSelectionDto[];
  employee: ChangeEmployeeTnaFormDto;
  deadline: string;
  type: TnaAssessmentType;
  skillCount: number;
}

export interface ChangeMachineModelFormSelectionDto {
  machineModelId: string;
  selectedMachineUnits: string[];
}

export interface ChangeEmployeeTnaFormDto {
  userId: string;
  bypassEmployeeEvaluation: boolean;
  lineManagerId: string;
  bypassLineManagerEvaluation: boolean;
  machineFunctionalExpertId: string;
  nonMachineFunctionalExperts: ChangeFunctionalExpertDto[];
  machineFunctionalExperts: ChangeMachineFunctionalExpertDto[];
}

export interface ChangeFunctionalExpertDto {
  userId: string;
  skills: string[];
}

export interface ChangeMachineFunctionalExpertDto {
  userId: string;
  machineModelIds: string[];
}

export interface SendTnaFormBackToInstructorsDto {
  formId: string;
  assesorIds: string[];
  formStatus: TnaFormStatus;
}

export class TnaFormListValidator extends AbstractValidator<TnaFormListDto> {}

export class TnaFormChangeAnswerValidator extends ExtendedAbstractValidator<ChangeTnaFormAnswerDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(x => x.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TnaForm Id is required'));
  }
}

@repository('@@TNAFORMLISTS', 'tnaformlist.changeanswer')
export class TnaFormChangeAnswerStore extends FormStore<ChangeTnaFormAnswerDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-tna-form-answers';
  updatePath = 'v1/save-tna-form-answers';
  sendPath = 'v1/send-tna-form-answers';
  deletePath = '';
  RETRIEVE_ONE_ANSWERS = 'RETRIEVE_ONE_ANSWERS';
  public EXCEL_EXPORTED = 'EXCEL_EXPORTED';
  exportToExcelPath = 'v1/export-tna-form';
  changeStatusPath = 'v1/send-tna-form-status-back';

  protected validate(item: ChangeTnaFormAnswerDto) {
    return new TnaFormChangeAnswerValidator().validate(item as any);
  }

  constructor() {
    super('CHANGEANSWERTNAFORMLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });

    this.addReducer(
      this.RETRIEVE_ONE_ANSWERS,
      () => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => ({
            ...this.state,
            isBusy: false,
            count: value.data.count
          }),
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );
  }

  public async SendTnaForm() {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<ChangeTnaFormAnswerDto, CommandResult<ChangeTnaFormAnswerDto>>(`${this.baseUrl}/${this.sendPath}`, this.state.item)
    );
    return result.data;
  }

  public async getTnaFormAnswersByFormId(id: string): Promise<TnaFormAnswersDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_ANSWERS,
      httpService.get<TnaFormAnswersDto>(`${this.baseUrl}/${this.retrievePath}/${id}`)
    );
    return result.data;
  }

  public async exportToExcel(id: string) {
    const httpService = container.get(HttpService);
    return await this.dispatchAsync(
      this.EXCEL_EXPORTED,
      httpService.download(`${this.baseUrl}/${this.exportToExcelPath}/${id}`, `TNA-${id}`, null, this.state.filters)
    );
  }

  public async sendBackStatus(statusSelected: string, formId: string, assesors: string[]): Promise<CommandResult<TnaFormItemDto>> {
    let body = {
      formId: formId,
      assesorIds: assesors,
      formStatus: statusSelected === 'Assessors' ? 'Assessors' : 'Employee'
    } as SendTnaFormBackToInstructorsDto;
    let result = null;
    const httpService = container.get(HttpService);
    result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<SendTnaFormBackToInstructorsDto, CommandResult<TnaFormItemDto>>(`${this.baseUrl}/${this.changeStatusPath}`, body)
    );
    if (result) return result.data;
    return null;
  }
}

@repository('@@TNAFORMLISTS', 'tnaformlist.summary')
export class TnaFormListStore extends DataStore<TnaFormListDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-tna-forms';
  retrieveAssessorsPath = 'v1/get-assessor-tna-forms';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'v1/get-tna-form';
  RETRIEVE_ONE_TNA_FORM = 'RETRIEVE_ONE_TNA_FORM';
  CHANGE_ANSWER_VALUES = 'CHANGE_ANSWER_VALUES';
  CHANGE_CURRENT_LANGUAGE = 'CHANGE_CURRENT_LANGUAGE';

  protected validate(item: TnaFormListDto) {
    return new TnaFormListValidator().validate(item as any);
  }

  constructor() {
    super('TNAFORMLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {},
      item: {}
    });

    this.addReducer(
      this.RETRIEVE_ONE_TNA_FORM,
      () => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => {
            return {
              ...this.state,
              isBusy: false,
              count: value.data.count,
              rawData: value.data,
              item: value.data
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.CHANGE_ANSWER_VALUES,
      (answers: Partial<TnaFormAnswersDto>) => {
        return {
          ...this.state,
          item: { ...this.state.item, answers: { ...this.state.item.answers, ...answers } }
        };
      },
      'Simple'
    );

    this.addReducer(
      this.CHANGE_CURRENT_LANGUAGE,
      (currentLanguage: string) => {
        return {
          ...this.state,
          item: { ...this.state.item, currentLanguage }
        };
      },
      'Simple'
    );
  }

  public changeAnswers = (answers: Partial<TnaFormAnswersDto>) => this.dispatch(this.CHANGE_ANSWER_VALUES, answers);

  public changeCurrentLanguage = (language: string) => {
    this.dispatch(this.CHANGE_CURRENT_LANGUAGE, language);
  };

  public async getFormById(id: string): Promise<TnaFormItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_TNA_FORM,
      httpService.get<TnaFormItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getAssessorTnaFormList(query: Query, data?: any): Promise<QueryResult<TnaFormListDto>> {
    const httpService = container.get(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<TnaFormListDto>>(`${this.baseUrl}/${this.retrieveAssessorsPath}?${path}`, data)
    );
    return result.data;
  }
}

export interface CreateTnaFormDto {
  title: string;
  tnaTemplateId: string;
  machineModelsSelection: CreateMachineModelFormSelectionDto[];
  employees: CreateEmployeeTnaFormDto[];
  deadline: string;
  skillCount: number;
  type: TnaAssessmentType;
  nmrSkillsWithLocalQuestions?: CreateSkillWithLocalQuestionDto[]; //Local questions from non machine related when form type is "SameMachineHierarchy"
  ignoredNmrSkills?: string[];
}

export interface CreateMachineModelFormSelectionDto {
  machineModelId: string;
  selectedMachineUnits: string[];
  machineUnitsWithLocalQuestions: CreateMachineUnitWithLocalQuestionDto[];
  nmrSkillsWithLocalQuestions?: CreateSkillWithLocalQuestionDto[]; //Local questions from non machine related when form type is "MultipleMachineHierarchy"
  tnaTemplateId?: string;
}

export interface CreateMachineUnitWithLocalQuestionDto {
  machineUnitId: string;
  skillsWithLocalQuestions: CreateSkillWithLocalQuestionDto[];
}

export interface CreateSkillWithLocalQuestionDto {
  skillId: string;
  localQuestionIds: string[];
}

export interface CreateEmployeeTnaFormDto {
  userId: string;
  bypassEmployeeEvaluation: boolean;
  lineManagerId: string;
  bypassLineManagerEvaluation: boolean;
  machineFunctionalExpertId: string;
  nonMachineFunctionalExperts: CreateFunctionalExpertDto[];
  machineFunctionalExperts: CreateMachineFunctionalExpertDto[];
}

export interface CreateFunctionalExpertDto {
  userId: string;
  skills: string[];
}

export interface CreateMachineFunctionalExpertDto {
  userId: string;
  machineModelIds: string[];
}

export class CreateFunctionalExpertDtoValidator extends ExtendedAbstractValidator<CreateFunctionalExpertDto> {
  constructor(idx: number, idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1} Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfIterable(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Skills info are required')}`);

    this.validateIfEachString(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.skills != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}
export class CreateMachineFunctionalExpertDtoValidator extends ExtendedAbstractValidator<CreateMachineFunctionalExpertDto> {
  constructor(idx: number, idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1} Machine Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfIterable(x => x.machineModelIds)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('machine models info are required')}`);

    this.validateIfEachString(x => x.machineModelIds)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.machineModelIds != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine model info is wrong')}`);
  }
}

export class CreateEmployeeTnaFormDtoValidator extends ExtendedAbstractValidator<CreateEmployeeTnaFormDto> {
  constructor(
    idx: number,
    assessmentType: TnaAssessmentType,
    skillCount: number,
    machineModelsCount: number,
    onErrors?: (...failures: ValidationFailure[]) => void
  ) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Employee info is wrong')}`);

    this.validateIfString(x => x.lineManagerId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager info is required')}`);

    this.validateIf(x => x)
      .fulfills(x => x.lineManagerId !== x.machineFunctionalExpertId)
      .when(x => x != null && !isNullOrWhiteSpaces(x.lineManagerId))
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be machine functional expert too')}`);

    this.validateIfString(x => x.machineFunctionalExpertId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && assessmentType === 'SameMachineHierarchy' && machineModelsCount > 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Expert MR Skills info is required')}`);

    this.validateIf(x => x)
      .fulfills(x => !(x.nonMachineFunctionalExperts || []).any(fe => fe.userId === x.lineManagerId))
      .when(x => x != null && !isNullOrWhiteSpaces(x.lineManagerId) && (x.nonMachineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be non machine functional expert too')}`);

    this.validateIf(x => x.nonMachineFunctionalExperts)
      .fulfills(x => (x || []).all((i, j) => new CreateFunctionalExpertDtoValidator(idx, j, this.addErrors).extendValidate(i).isValid()))
      .when(x => x != null && (x.nonMachineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is wrong')}`);

    this.validateIf(x => x.nonMachineFunctionalExperts)
      .fulfills(x => x.sum(fe => ((fe && fe.skills) || []).length) === skillCount)
      .when(x => x != null && (x.nonMachineFunctionalExperts || []).length !== 0 && skillCount !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is incomplete')}`);

    this.validateIf(x => x.machineFunctionalExperts)
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy');

    this.validateIf(x => x)
      .fulfills(x => !(x.machineFunctionalExperts || []).any(fe => fe.userId === x.lineManagerId))
      .when(
        x =>
          x != null &&
          assessmentType === 'MultipleMachineHierarchy' &&
          !isNullOrWhiteSpaces(x.lineManagerId) &&
          (x.machineFunctionalExperts || []).length !== 0
      )
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be machine functional expert too')}`);

    this.validateIf(x => x.machineFunctionalExperts)
      .fulfills(x =>
        (x || []).all((i, j) => new CreateMachineFunctionalExpertDtoValidator(idx, j, this.addErrors).extendValidate(i).isValid())
      )
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy' && (x.machineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Functional Experts info is wrong')}`);

    this.validateIf(x => x.machineFunctionalExperts)
      .fulfills(x => x.sum(fe => ((fe && fe.machineModelIds) || []).length) === machineModelsCount)
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy' && (x.machineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Functional Experts info is incomplete')}`);
  }
}

export class CreateMachineModelFormSelectionDtoValidator extends ExtendedAbstractValidator<CreateMachineModelFormSelectionDto> {
  constructor(idx: number, type: TnaAssessmentType, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Machine model selection at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.machineModelId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine model info is wrong')}`);

    this.validateIfIterable(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && type === 'SameMachineHierarchy')
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit is required')}`);

    this.validateIfEachString(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.selectedMachineUnits != null && type === 'SameMachineHierarchy')
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit info is wrong')}`);
  }
}

export class CreateTnaFormValidator extends ExtendedAbstractValidator<CreateTnaFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('TNA Title is required'));

    this.validateIfString(x => x.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline) && moment(x.deadline).isValid())
      .withFailureMessage(i18n.t('Deadline date cant be in the past'));

    this.validateIfString(x => x.tnaTemplateId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('TNA Template is required'));

    this.validateIfString(x => x.type)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isIn(['SameMachineHierarchy', 'MultipleMachineHierarchy'])
      .withFailureMessage(i18n.t('Cant determine the TNA form type'));

    this.validateIfIterable(x => x.machineModelsSelection)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && (x.machineModelsSelection || []).length !== 0)
      .withFailureMessage(i18n.t('Machine model selection info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        (x.machineModelsSelection || []).all((i, idx) =>
          new CreateMachineModelFormSelectionDtoValidator(idx, x.type, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => x != null && (x.machineModelsSelection || []).length !== 0)
      .withFailureMessage(i18n.t('Machine model selection info is required'));

    this.validateIfIterable(x => x.employees)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Employees and Assesors info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        (x.employees || []).all((i, idx) =>
          new CreateEmployeeTnaFormDtoValidator(idx, x.type, x.skillCount, x.machineModelsSelection.length, this.addErrors)
            .extendValidate(i)
            .isValid()
        )
      )
      .when(x => x != null && (x.employees || []).length !== 0 && x.skillCount >= 0)
      .withFailureMessage(i18n.t('Employees and Assesors info is wrong'));
  }
}

export class ChangeMachineModelFormSelectionDtoValidator extends ExtendedAbstractValidator<ChangeMachineModelFormSelectionDto> {
  constructor(idx: number, type: TnaAssessmentType, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Machine model selection at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.machineModelId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine model info is wrong')}`);

    this.validateIfIterable(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && type === 'SameMachineHierarchy')
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit is required')}`);

    this.validateIfEachString(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.selectedMachineUnits != null && type === 'SameMachineHierarchy')
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit info is wrong')}`);
  }
}

export class ChangeFunctionalExpertDtoValidator extends ExtendedAbstractValidator<ChangeFunctionalExpertDto> {
  constructor(idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfIterable(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Skills info are required')}`);

    this.validateIfEachString(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.skills != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}
export class ChangeMachineFunctionalExpertDtoValidator extends ExtendedAbstractValidator<ChangeMachineFunctionalExpertDto> {
  constructor(idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee Machine Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfIterable(x => x.machineModelIds)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('machine models info are required')}`);

    this.validateIfEachString(x => x.machineModelIds)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.machineModelIds != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine model info is wrong')}`);
  }
}

export class ChangeEmployeeTnaFormDtoValidator extends ExtendedAbstractValidator<ChangeEmployeeTnaFormDto> {
  constructor(
    assessmentType: TnaAssessmentType,
    skillCount: number,
    machineModelsCount: number,
    onErrors?: (...failures: ValidationFailure[]) => void
  ) {
    super(onErrors);
    const preffix = i18n.t(`Employee`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Employee info is wrong')}`);

    this.validateIfString(x => x.lineManagerId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager info is required')}`);

    this.validateIf(x => x)
      .fulfills(x => x.lineManagerId !== x.machineFunctionalExpertId)
      .when(x => x != null && !isNullOrWhiteSpaces(x.lineManagerId))
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be machine functional expert too')}`);

    this.validateIfString(x => x.machineFunctionalExpertId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && assessmentType === 'SameMachineHierarchy' && machineModelsCount > 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Expert MR Skills info is required')}`);

    this.validateIf(x => x)
      .fulfills(x => !(x.nonMachineFunctionalExperts || []).any(fe => fe.userId === x.lineManagerId))
      .when(x => x != null && !isNullOrWhiteSpaces(x.lineManagerId) && (x.nonMachineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be non machine functional expert too')}`);

    this.validateIf(x => x.nonMachineFunctionalExperts)
      .fulfills(x => (x || []).all((i, j) => new ChangeFunctionalExpertDtoValidator(j, this.addErrors).extendValidate(i).isValid()))
      .when(x => x != null && (x.nonMachineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is wrong')}`);

    this.validateIf(x => x.nonMachineFunctionalExperts)
      .fulfills(x => x.sum(fe => ((fe && fe.skills) || []).length) === skillCount)
      .when(x => x != null && (x.nonMachineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is incomplete')}`);

    //--------
    this.validateIf(x => x.machineFunctionalExperts)
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy');

    this.validateIf(x => x)
      .fulfills(x => !(x.machineFunctionalExperts || []).any(fe => fe.userId === x.lineManagerId))
      .when(
        x =>
          x != null &&
          assessmentType === 'MultipleMachineHierarchy' &&
          !isNullOrWhiteSpaces(x.lineManagerId) &&
          (x.machineFunctionalExperts || []).length !== 0
      )
      .withFailureMessage(`${preffix}: ${i18n.t('Line Manager cant be machine functional expert too')}`);

    this.validateIf(x => x.machineFunctionalExperts)
      .fulfills(x => (x || []).all((i, j) => new ChangeMachineFunctionalExpertDtoValidator(j, this.addErrors).extendValidate(i).isValid()))
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy' && (x.machineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Functional Experts info is wrong')}`);

    this.validateIf(x => x.machineFunctionalExperts)
      .fulfills(x => x.sum(fe => ((fe && fe.machineModelIds) || []).length) === machineModelsCount)
      .when(x => x != null && assessmentType === 'MultipleMachineHierarchy' && (x.machineFunctionalExperts || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Functional Experts info is incomplete')}`);
  }
}

export class ChangeTnaFormWizardValidator extends ExtendedAbstractValidator<ChangeTnaFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIf(x => x.employee)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Employees and Assesors info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        new ChangeEmployeeTnaFormDtoValidator(x.type, x.skillCount, x.machineModelsSelection.length, this.addErrors)
          .validate(x.employee)
          .isValid()
      )
      .when(x => x != null && x.employee != null)
      .withFailureMessage(i18n.t('Employees and Assesors info is wrong'));

    this.validateIfIterable(x => x.machineModelsSelection)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && (x.machineModelsSelection || []).length !== 0)
      .withFailureMessage(i18n.t('Machine model selection info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        (x.machineModelsSelection || []).all((i, idx) =>
          new ChangeMachineModelFormSelectionDtoValidator(idx, x.type, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => x != null && (x.machineModelsSelection || []).length !== 0)
      .withFailureMessage(i18n.t('Machine model selection info is required'));
  }
}

@repository('@@TNAFORMLISTS', 'tnaformlist.new')
export class NewTnaFormStore extends FormStore<CreateTnaFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-forms';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateTnaFormDto) {
    return new CreateTnaFormValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_TNATEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNAFORMLISTS', 'tnaformlist.change')
export class ChangeTnaFormWizardStore extends FormStore<ChangeTnaFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-forms';
  retrievePath = '';
  updatePath = 'update-tna-form';

  protected validate(item: ChangeTnaFormDto) {
    return new ChangeTnaFormWizardValidator().extendValidate(item);
  }

  constructor() {
    super('EDIT_TNAFORM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNAFORMLISTS', 'tnaformlist.change')
export class ChangeActiveTnaFormTnaStore extends FormStore<ChangeActiveFlagTnaFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-template';
  retrievePath = 'get-tna-templates';
  updatePath = 'update-active-flag-tna-form';

  protected validate(_: ChangeActiveFlagTnaFormDto) {
    return new ValidationResult();
  }
  constructor() {
    super('CHANGE_ACTIVE_TNAFORM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
