import { setupRepositories as setupLocationsRepositories } from './locations/configuration-locations-stores.config';
import { setupRepositories as setupEventsNRequestsRepositories } from './events-n-requests/configuration-events-n-requests-stores.config';
import { setupRepositories as setupUsefulLinksRepositories } from './useful-links/configuration-useful-links-stores.config';
import { setupRepositories as setupSupportCostsRepositories } from './support-costs/configuration-support-costs-stores.config';
import { setupRepositories as setupOverallRecommendationsRepositories } from './overall-recommendations/configuration-overall-recommendations-stores.config';
import { setupRepositories as setupPlannerAssistantRepositories } from './planner-assistant/configuration-planner-assistant-stores.config';
import { setupRepositories as setupPerdiemsRepositories } from './perdiems/configuration-perdiems-stores.config';
import { setupRepositories as setupMachinerysRepositories } from './machinery/configuration-machinery-stores.config';
import { setupRepositories as setupProfilesRepositories } from './profiles/configuration-profiles-store.config';
import { setupRepositories as setupEventsWorkflowRepositories } from './events-workflow/events-workflow-stores.config';

export function setupRepositories(storeBuilder) {
  setupLocationsRepositories(storeBuilder);
  setupEventsNRequestsRepositories(storeBuilder);
  setupUsefulLinksRepositories(storeBuilder);
  setupSupportCostsRepositories(storeBuilder);
  setupOverallRecommendationsRepositories(storeBuilder);
  setupPlannerAssistantRepositories(storeBuilder);
  setupPerdiemsRepositories(storeBuilder);
  setupMachinerysRepositories(storeBuilder);
  setupProfilesRepositories(storeBuilder);
  setupEventsWorkflowRepositories(storeBuilder);
}
