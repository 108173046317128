import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { TheoreticalFormView } from 'stores/assessments/forms/theoretical-form-test-store';

interface FooterProps extends WithTranslation, TheoreticalFormView {
  onNext: (property: keyof TheoreticalFormView, value: any) => void;
  onPrevious: (property: keyof TheoreticalFormView, value: any) => void;
  isEmployee: boolean;
  active: boolean;
}

const TheoreticalFormFooter: FC<FooterProps> = props => {
  const { t, onNext, onPrevious, endedAt, questionIdx, questionAnswers, isEmployee, finishedTime, active } = props;
  if (!active) return null;

  const length = questionAnswers.length - 1;
  const currentQ = questionAnswers[questionIdx];

  const hasFinished = endedAt != null || finishedTime;
  const nextAvailable = !isEmployee ? questionIdx !== length : finishedTime ? questionIdx !== length : true;
  const nextText = isEmployee && questionIdx === length && !endedAt ? 'Finish the test' : 'Next Question';
  const nextDisabled = isEmployee ? !hasFinished && currentQ?.value == null && !currentQ?.unavailableMedia : false;

  return (
    <>
      {questionIdx > 0 && (!isEmployee || finishedTime || endedAt) && (
        <Button onClick={() => onPrevious('questionIdx', questionIdx - 1)} className="form-footer__previous-question" icon>
          <Icon name="chevron left" />
          {t('Previous Question')}
        </Button>
      )}
      <div className="spacer" />
      {nextAvailable && (
        <Button disabled={nextDisabled} onClick={() => onNext('questionIdx', questionIdx + 1)} className="form-footer__next-question" icon>
          {t(nextText)}
          <Icon name="chevron right" />
        </Button>
      )}
    </>
  );
};

export default withTranslation()(TheoreticalFormFooter);
