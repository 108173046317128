import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Popup, Form, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { Component } from 'react';
import React from 'react';
import { TrainingMaterialDto } from 'stores/events/events-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import './documentation-tab-pane.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faFile } from '@fortawesome/free-solid-svg-icons';
import { EventFormStore, EventDocumentViewModel, EventDocumentViewState, EventDocumentUploadResult } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { FileInfo } from 'stores/types';
import { hasAdministrationRights, hasInstructorRights, isPocOrFactoryLeadInExtendedWf } from 'utils/event-utils';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

interface DocumentationTabPaneProps extends WithTranslation {
  readOnly: boolean;
  eventFormStore?: EventFormStore;
}

export interface TrainingMaterialViewModel {
  title: string;
  link: string;
}

export interface DocumentationTabPaneState {
  addTrainingMaterial: TrainingMaterialViewModel;
  isAddTraningMaterialOpen: boolean;
  showLoading: boolean;
}

@connect(['eventFormStore', EventFormStore])
class DocumentationTabPane extends Component<DocumentationTabPaneProps, DocumentationTabPaneState> {
  state: DocumentationTabPaneState = {
    isAddTraningMaterialOpen: false,
    addTrainingMaterial: {
      link: '',
      title: ''
    },
    showLoading: false
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private canEditTrainingMaterial() {
    const eventForm = this.props.eventFormStore.state.item;
    return hasAdministrationRights(eventForm) || hasInstructorRights(eventForm) || isPocOrFactoryLeadInExtendedWf(eventForm);
  }

  private showSupportingDocuments() {
    return EventTypeCategory[this.props.eventFormStore.state.item.eventType.eventTypeCategory] === EventTypeCategory.Extended;
  }

  renderAddLinkButton(): React.ReactNode {
    const { t, readOnly } = this.props;

    return (
      <>
        <Popup
          className="event-form-documentation-popup"
          position="bottom left"
          wide
          trigger={
            <Button
              disabled={readOnly}
              labelPosition="right"
              className="cancel-button event-form-documentation-add-link-button"
              basic
              content={t('Add Link')}
              icon="linkify"
            />
          }
          open={this.state.isAddTraningMaterialOpen}
          onOpen={() => this.setState({ isAddTraningMaterialOpen: true })}
          on="click"
        >
          <Form>
            <Form.Input
              className="event-form-documentation-popup-input"
              inline
              label={t('Title')}
              required
              onChange={(e, { value }) => {
                this.handleTitleChange(value);
              }}
            ></Form.Input>
            <Form.Input
              className="event-form-documentation-popup-input popup-url-input"
              inline
              label={t('URL')}
              required
              onChange={(e, { value }) => {
                this.handleLinkChange(value);
              }}
            ></Form.Input>
            <div className="event-form-documentation-popup-buttons">
              <Button basic className="cancel-button" content={t('Cancel')} onClick={() => this.handleOnCancelTrainingMaterialClick()} />
              <Button
                positive
                disabled={!this.state.addTrainingMaterial.link || !this.state.addTrainingMaterial.title}
                onClick={e => this.handleOnAddTrainingMaterialClick()}
                content={t('Add Link')}
              />
            </div>
          </Form>
        </Popup>
      </>
    );
  }

  private handleTitleChange(value: any) {
    let trainingMaterial = this.state.addTrainingMaterial;
    trainingMaterial.title = value;

    this.setState({
      addTrainingMaterial: trainingMaterial
    });
  }

  private handleLinkChange(value: any) {
    let trainingMaterial = this.state.addTrainingMaterial;
    trainingMaterial.link = value;

    this.setState({
      addTrainingMaterial: trainingMaterial
    });
  }

  private handleOnCancelTrainingMaterialClick(): void {
    this.setState({
      addTrainingMaterial: {
        link: '',
        title: ''
      },
      isAddTraningMaterialOpen: false
    });
  }

  private handleOnAddTrainingMaterialClick(): void {
    let trainingMaterials = [...this.props.eventFormStore.state.item.trainingMaterials, this.state.addTrainingMaterial];

    this.setState({
      addTrainingMaterial: {
        link: '',
        title: ''
      },
      isAddTraningMaterialOpen: false
    });

    this.props.eventFormStore.change({
      trainingMaterials
    });
  }

  private handleOnRemoveTrainingMaterial(index: number, trainingMaterial: TrainingMaterialDto) {
    let trainingMaterials = [...this.props.eventFormStore.state.item.trainingMaterials];
    trainingMaterials.splice(index, 1);

    this.props.eventFormStore.change({ trainingMaterials });
  }

  private download = (doc: EventDocumentViewModel) => {
    if (doc == null || isNullOrWhiteSpaces(doc.id)) return;
    this.setState({ showLoading: true });
    const { eventFormStore } = this.props;
    const { id } = eventFormStore.state.item;
    eventFormStore
      .downloadEventDocument(id, doc.id, doc.title)
      .then(_ => {
        this.setState({ showLoading: false });
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(_ => {
        this.setState({ showLoading: false });
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });
  };

  private handleOnRemoveDocument = (d: EventDocumentViewModel) => {
    const { eventFormStore: store, readOnly } = this.props;
    if (readOnly) return;
    const next = store.state.item.supportingDocuments || [];

    const key = isNullOrWhiteSpaces(d.id) ? 'path' : 'id';
    const idx = next.findIndex(x => x[key] === d[key]);
    if (idx < 0) return;

    if (!isNullOrWhiteSpaces(d.id)) next[idx].state = EventDocumentViewState.Deleted;
    else next.splice(idx, 1);

    store.change({ supportingDocuments: next });
  };

  private onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;

    this.setState({ showLoading: true });

    const { eventFormStore: store } = this.props;
    const { id, supportingDocuments } = store.state.item;
    const usr = this.identityService.getUserInfo();

    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };

    store
      .uploadEventDocument(id, fileInfo)
      .then((result: EventDocumentUploadResult) => {
        if (result.isSuccess) {
          const next = supportingDocuments || [];
          ToastComponent({ text: this.props.t('File uploaded successfully!'), type: 'success-toast' });
          next.push({
            id: null,
            mimeType: null,
            path: result.filePath,
            state: EventDocumentViewState.Added,
            title: fileInfo.fileName
          });
          store.change({ supportingDocuments: next });
        }
        (document.getElementById('supporting_doc_upload') as HTMLInputElement).value = '';
        this.setState({ showLoading: false });
      })
      .catch(_ => {
        (document.getElementById('supporting_doc_upload') as HTMLInputElement).value = '';
        this.setState({ showLoading: false });
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  public render() {
    const { t, eventFormStore: store, readOnly } = this.props;
    const trainingMaterials = store.state.item.trainingMaterials;
    const documents = store.state.item.supportingDocuments || [];

    return (
      <>
        {this.state.showLoading && (
          <Dimmer style={{ position: 'fixed' }} active inverted>
            <Loader size="large" active inverted />
          </Dimmer>
        )}
        <div className="events-form__documentation-container">
          <div className="form__tab__icon-element">
            <div className="flex-start-center">
              <FontAwesomeIcon className="form__header__row-icon" icon={faGraduationCap} />
              <p>{t('Training Material')}</p>
              {this.canEditTrainingMaterial() && <div>{this.renderAddLinkButton()}</div>}
            </div>

            <div className="flex-column documentation__links-list">
              {trainingMaterials.map((trainingMaterial, index) => {
                return (
                  <div className="documentation__links-container">
                    <a href={trainingMaterial.link} target="_blank" rel="noopener noreferrer">
                      {trainingMaterial.title}
                    </a>
                    {this.canEditTrainingMaterial() && !readOnly && (
                      <Icon
                        className="clickable-icon"
                        color="red"
                        name="remove"
                        size="large"
                        onClick={() => this.handleOnRemoveTrainingMaterial(index, trainingMaterial)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {this.showSupportingDocuments() && (
          <div className="events-form__documentation-container events-form__documentation__supporting-docs">
            <div className="form__tab__icon-element">
              <div className="flex-start-center">
                <FontAwesomeIcon className="form__header__row-icon" icon={faFile} />
                <p>{t('Supporting Documents')}</p>
                {this.canEditTrainingMaterial() && (
                  <div>
                    <Button
                      disabled={readOnly}
                      labelPosition="right"
                      className="cancel-button event-form-documentation-add-link-button"
                      basic
                      content={t('Add Document')}
                      icon="paperclip"
                      onClick={() => document.getElementById('supporting_doc_upload').click()}
                    />
                    <input hidden style={{ display: 'none' }} id="supporting_doc_upload" type="file" onChange={this.onChangeFile} />
                  </div>
                )}
              </div>

              <div className="flex-column documentation__links-list">
                {documents
                  .filter(d => d.state !== EventDocumentViewState.Deleted)
                  .map((d, index) => {
                    return (
                      <div key={`document_${d.id || Math.random()}_${index}`} className="documentation__links-container">
                        {isNullOrWhiteSpaces(d.id) ? (
                          <div>{d.title}</div>
                        ) : (
                          <a href="javascript:void(0);" onClick={() => this.download(d)}>
                            {d.title}
                          </a>
                        )}

                        {this.canEditTrainingMaterial() && !readOnly && (
                          <Icon
                            className="clickable-icon"
                            color="red"
                            name="remove"
                            size="large"
                            onClick={() => this.handleOnRemoveDocument(d)}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(DocumentationTabPane);
