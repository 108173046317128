import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import SsaFormsList from './forms/ssa-forms-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface SsaSkillsConfigurationHomeProps extends RouteComponentProps, WithTranslation {}
export interface SsaSkillsConfigurationHomeState {}

class AssessorsSsaHomePage extends React.Component<SsaSkillsConfigurationHomeProps, SsaSkillsConfigurationHomeState> {
  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <h3 id="tna-forms-list-filters__title">{t('Shopfloor Skills Assessments Evaluations')}</h3>
        <LineSeparator />
        <SsaFormsList isUserAssessor={true} />
      </>
    );
  }
}

export default withTranslation()(AssessorsSsaHomePage);
