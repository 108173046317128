import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { BaseDto } from '../../types';
import { FormStore } from '../../formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface PositionCodeDto extends BaseDto {
  id: string;
  name: string;
  code: string;
  description: string;
  keywords: string[];
}

export interface ChangePositionCodeDto {
  id: string;
  code: string;
  description: string;
  keywords: string[];
}

export interface CreatePositionCodeDto {
  code: string;
  description: string;
  keywords: string[];
}

export class CreatePositionCodeValidator extends AbstractValidator<CreatePositionCodeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.code)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Position Code is required'));

    this.validateIfIterable(o => o.keywords)
      .isNotEmpty()
      .withFailureMessage(i18n.t('keywords are required'));
  }
}

export class ChangePositionCodeValidator extends AbstractValidator<ChangePositionCodeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('PositionCode id is required'));

    this.validateIfString(o => o.code)
      .isNotEmpty()
      .withFailureMessage(i18n.t('code is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfIterable(o => o.keywords)
      .isNotEmpty()
      .withFailureMessage(i18n.t('keywords are required'));
  }
}

@repository('@@POSITION-CODES', 'position-codes.summary')
export class PositionCodesStore extends DataStore<PositionCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-positionCode';
  retrievePath = 'get-positionCodes';
  updatePath = 'update-positionCode';
  deletePath = 'delete-positionCode';

  protected validate(item: PositionCodeDto) {
    return new ChangePositionCodeValidator().validate(item);
  }

  constructor() {
    super('POSITION_CODE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@POSITION_CODE', 'position-codes.dropdown')
export class DropDownPositionCodesStore extends DataStore<PositionCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-positionCodes';
  updatePath = '';
  deletePath = '';

  protected validate(item: PositionCodeDto) {
    return new ChangePositionCodeValidator().validate(item);
  }

  constructor() {
    super('POSITION_CODE_DROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, positionCodeIds?: string[], search?: string): Promise<QueryResult<PositionCodeDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let positionCodesDto = this.state.items.map(({ item }) => item);

      if ((positionCodeIds || []).length > 0) {
        positionCodesDto = positionCodesDto.filter(x => positionCodeIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        positionCodesDto = positionCodesDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: positionCodesDto, count: positionCodesDto.length } as QueryResult<PositionCodeDto>;
      return new Promise<QueryResult<PositionCodeDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<PositionCodeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<PositionCodeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@POSITION-CODES', 'positionCodes.new')
export class NewPositionCodeStore extends FormStore<CreatePositionCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-positionCode';
  retrievePath = 'get-positionCodes';
  updatePath = 'update-positionCode';

  protected validate(item: CreatePositionCodeDto) {
    return new CreatePositionCodeValidator().validate(item);
  }

  constructor() {
    super('NEW_POSITIONCODE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@POSITION-CODES', 'positionCodes.change')
export class ChangePositionCodeStore extends FormStore<ChangePositionCodeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-positionCode';
  retrievePath = 'get-positionCodes';
  updatePath = 'update-positionCode';

  protected validate(item: ChangePositionCodeDto) {
    return new ChangePositionCodeValidator().validate(item);
  }

  constructor() {
    super('CHANGE_POSITIONCODE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
