import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import IwsSkillsListPage from './skills/skills-list';
import IwsQuestionBankList from './question-bank/iws-question-bank-list';
import queryString from 'query-string';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import IwsTemplateList from './templates/iws-templates-list';
import IwsFormsList from './forms/iws-forms-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface IwsSkillsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface IwsSkillsConfigurationHomeState {
  activeIndex: number;
}

class IwsSkillsConfigurationHomePage extends React.Component<IwsSkillsConfigurationHomeProps, IwsSkillsConfigurationHomeState> {
  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  private panes = [];
  constructor(props: IwsSkillsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndex()
    };
  }
  componentDidMount(): void {
    this.setPanesByRole();
    this.setState({ activeIndex: this.getActiveIndex() });
  }

  private getActiveIndex = () => {
    let values = queryString.parse(this.props.location.search);
    if (values == null || values.tab == null) values = this.props.match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    let l = this.panes.length;
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
    return 0;
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isInRoleAdmin(): boolean {
    return this.identityService.isInRole(Roles.Admin);
  }

  private isInPowerInstructorRole(): boolean {
    return IdentityService.isPowerInstructor(this.identityService.getUserInfo());
  }

  private isInRoleEmployee(): boolean {
    return this.identityService.isInRole(Roles.Employee);
  }
  private IsInRolePoC(): boolean {
    return this.identityService.isInRole(Roles.PoC);
  }

  private setPanesByRole = () => {
    if (this.isInRoleAdmin() || this.isInPowerInstructorRole() || this.IsInRolePoC()) {
      this.panes.push({
        menuItem: i18n.t('Question Bank'),
        render: () => <Tab.Pane attached={false}>{<IwsQuestionBankList />}</Tab.Pane>
      });

      if (this.isInRoleAdmin() || this.isInPowerInstructorRole()) {
        this.panes.push({
          menuItem: i18n.t('Skills'),
          render: () => (
            <Tab.Pane attached={false}>
              <IwsSkillsListPage />
            </Tab.Pane>
          )
        });
        this.panes.push({
          menuItem: i18n.t('Templates'),
          render: () => <Tab.Pane attached={false}>{<IwsTemplateList {...this.routes} />}</Tab.Pane>
        });
      }

      this.panes.push({
        menuItem: i18n.t('Forms'),
        render: () => <Tab.Pane attached={false}>{<IwsFormsList {...this.routes} />}</Tab.Pane>
      });
    }
  };
  private onTabChange = (_, { activeIndex }) => {
    this.props.history.replace(`/assessments/iws?tab=${activeIndex}`);
    this.setState({ activeIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    let p = [...this.panes];
    return (
      <>
        <h3 id="tna-forms-list-filters__title">{t('IWS Step Up Cards')}</h3>
        {(this.isInRoleAdmin() || this.isInPowerInstructorRole() || this.IsInRolePoC()) && (
          <Tab
            className="main-tab"
            menu={{ secondary: true, pointing: true }}
            panes={p}
            activeIndex={activeIndex}
            onTabChange={this.onTabChange}
          />
        )}
        {this.isInRoleEmployee() && (
          <>
            <LineSeparator />
            {<IwsFormsList {...this.routes} isUserAssessor={false} />}
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(IwsSkillsConfigurationHomePage);
