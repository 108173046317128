import React from 'react';

import { container } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FullPageLoader } from '../full-page-loader';

export const Logout = () => {
  container.get(IdentityService).logout();
  return <FullPageLoader text={'Signing out'} />;
};
