import {
  TnaDevelopmentPlanStore,
  TnaDevelopmentPlanGroupingStore,
  ChangeTnaDevelopmentPlanStore,
  NewTnaDevelopmentPlanStore
} from './tna-development-plan.store';

import {
  SsaDevelopmentPlanStore,
  NewSsaDevelopmentPlanStore,
  ChangeSsaDevelopmentPlanStore,
  SsaDevelopmentPlanGroupingStore
} from './ssa-development-plan.store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new TnaDevelopmentPlanStore());
  storeBuilder.addRepository(new NewTnaDevelopmentPlanStore());
  storeBuilder.addRepository(new ChangeTnaDevelopmentPlanStore());
  storeBuilder.addRepository(new TnaDevelopmentPlanGroupingStore());
  storeBuilder.addRepository(new SsaDevelopmentPlanStore());
  storeBuilder.addRepository(new NewSsaDevelopmentPlanStore());
  storeBuilder.addRepository(new ChangeSsaDevelopmentPlanStore());
  storeBuilder.addRepository(new SsaDevelopmentPlanGroupingStore());
}
