import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';
import {
  NMFunctionalSubareasStore,
  NMFunctionalSubareaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewNMFunctionalSubareaView from './new-nm-functional-subarea';
import ChangeNMFunctionalSubareaView from './edit-nm-functional-subarea';
import { Categories } from 'stores/requests/requests-store';
import { OptionsFilter } from 'widgets/collections/table-filters/option-filter';

export interface NMFunctionalSubareaListProps extends WithTranslation, RouteComponentProps {
  nmFunctionalSubareas: NMFunctionalSubareasStore;
}

export interface NMFunctionalSubareaListState {
  query: Query;
  newNMFunctionalSubareaShown: boolean;
  changeNMFunctionalSubareaShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['nmFunctionalSubareas', NMFunctionalSubareasStore])
class NMFunctionalSubareaListPage extends React.Component<NMFunctionalSubareaListProps, NMFunctionalSubareaListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newNMFunctionalSubareaShown: false,
      changeNMFunctionalSubareaShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.nmFunctionalSubareas.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: NMFunctionalSubareaDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.nmFunctionalSubareas.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: NMFunctionalSubareaDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.nmFunctionalSubareas.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newNMFunctionalSubareaShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newNMFunctionalSubareaShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeNMFunctionalSubareaShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeNMFunctionalSubareaShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          newClassFromComponent: data => 'table__more-text-long',
          renderer: data => data.name,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<NMFunctionalSubareaDto>('name'),
                  `contains(tolower(${nameof<NMFunctionalSubareaDto>('name')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<NMFunctionalSubareaDto>('name'))}
              active={activeFilters.includes(nameof<NMFunctionalSubareaDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<NMFunctionalSubareaDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Cluster'),
          renderer: data => <>{data.clusterName}</>,
          tooltipRenderer: true,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<NMFunctionalSubareaDto>('clusterName'),
                  `contains(TrainingName/FunctionalArea/Cluster/Name, '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<NMFunctionalSubareaDto>('clusterName'))}
              active={activeFilters.includes(nameof<NMFunctionalSubareaDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'TrainingName/FunctionalArea/Cluster/Name',
            useProfile: false
          }
        },
        {
          title: t('Functional Area'),
          tooltipRenderer: true,
          newClassFromComponent: data => 'table__more-text-long',
          renderer: data => data.functionalAreaName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by functional area')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<NMFunctionalSubareaDto>('functionalAreaName'),
                  `contains(TrainingName/FunctionalArea/Name, '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<NMFunctionalSubareaDto>('functionalAreaName'))}
              active={activeFilters.includes(nameof<NMFunctionalSubareaDto>('functionalAreaName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'TrainingName/FunctionalArea/Name',
            useProfile: false
          }
        },
        {
          title: t('Training Name'),
          tooltipRenderer: true,
          newClassFromComponent: data => 'table__more-text-long',
          renderer: data => data.trainingNameName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by training name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<NMFunctionalSubareaDto>('trainingNameName'), `contains(TrainingName/Name, '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<NMFunctionalSubareaDto>('trainingNameName'))}
              active={activeFilters.includes(nameof<NMFunctionalSubareaDto>('trainingNameName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'TrainingName/Name',
            useProfile: false
          }
        },
        {
          title: t('Category'),
          tooltipRenderer: true,
          newClassFromComponent: data => 'table__more-text-long',
          renderer: data => (Categories[data.category] ? data.category : ''),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <OptionsFilter
              filterTitle={t('Filter by category')}
              options={[
                { text: t('Technical'), value: 'Technical' },
                { text: t('Functional'), value: 'Functional' }
              ]}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<NMFunctionalSubareaDto>('category'), { TrainingName: { FunctionalArea: { Cluster: { Category: value } } } })
              }
              onClear={() => onClear(nameof<NMFunctionalSubareaDto>('category'))}
              active={activeFilters.includes(nameof<NMFunctionalSubareaDto>('category'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: 'TrainingName/FunctionalArea/Cluster/Category', useProfile: false }
        }
      ],
      data: this.props.nmFunctionalSubareas.state
    } as TableModel<NMFunctionalSubareaDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.nmFunctionalSubareas.state.result && !this.props.nmFunctionalSubareas.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.nmFunctionalSubareas.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newNMFunctionalSubareaShown && !this.state.changeNMFunctionalSubareaShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Functional Subarea')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newNMFunctionalSubareaShown && <NewNMFunctionalSubareaView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeNMFunctionalSubareaShown && (
            <ChangeNMFunctionalSubareaView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(NMFunctionalSubareaListPage);
