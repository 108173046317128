import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MrclusterEditor from 'widgets/bussiness/mrcluster-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import FunctionalSubareaEditor from 'widgets/bussiness/functional-subarea-editor';
import './machinery-step.less';
import { PracticalTemplateWizardViewModel } from '../../practical-template-wizard-utils';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { Form } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import MultiMachineModelEditor from 'widgets/bussiness/multi-machine-model-editor';
import { ItemReference } from 'stores/dataStore';

export interface PracticalTemplateWizardMachineryStepProps extends WithTranslation {
  active: boolean;
  viewModel: PracticalTemplateWizardViewModel;
  onChange: (viewModel: PracticalTemplateWizardViewModel) => void;
  mode: 'Create' | 'Edit';
}

class PracticalTemplateWizardMachineryStep extends React.Component<PracticalTemplateWizardMachineryStepProps> {
  private handleOnChange = (property: keyof PracticalTemplateWizardViewModel, value: any) => {
    const { viewModel } = this.props;

    if (property === 'mrCluster' && value?.id !== viewModel.mrCluster?.id) {
      viewModel.mrCluster = value;
      viewModel.equipmentType = null;
      viewModel.oem = null;
      viewModel.machineModel = [];
    } else if (property === 'equipmentType' && value?.id !== viewModel.equipmentType?.id) {
      viewModel.equipmentType = value;
      viewModel.oem = null;
      viewModel.machineModel = [];
    } else if (property === 'oem' && value?.id !== viewModel.oem?.id) {
      viewModel.oem = value;
      viewModel.machineModel = [];
    } else if (property === 'nmrCluster' && value?.id !== viewModel.nmrCluster?.id) {
      viewModel.nmrCluster = value;
      viewModel.functionalArea = null;
      viewModel.trainingName = null;
      viewModel.functionalSubarea = null;
    } else if (property === 'functionalArea' && value?.id !== viewModel.functionalArea?.id) {
      viewModel.functionalArea = value;
      viewModel.trainingName = null;
      viewModel.functionalSubarea = null;
    } else if (property === 'trainingName' && value?.id !== viewModel.trainingName?.id) {
      viewModel.trainingName = value;
      viewModel.functionalSubarea = null;
    } else {
      viewModel[property as string] = value;
    }

    this.updateHierachy(viewModel);
    this.props.onChange(viewModel);
  };

  private updateHierachy = (model: PracticalTemplateWizardViewModel) => {
    if (model.machineRelated) {
      model.nmrCluster = null;
      model.functionalArea = null;
      model.functionalSubarea = null;
      model.trainingName = null;

      if (model.oem == null) model.machineModel = [];

      if (model.equipmentType == null) {
        model.oem = null;
        model.machineModel = [];
      }

      if (model.mrCluster == null) {
        model.oem = null;
        model.equipmentType = null;
        model.machineModel = [];
      }
    } else {
      model.mrCluster = null;
      model.equipmentType = null;
      model.oem = null;
      model.machineModel = [];

      if (model.trainingName == null) model.functionalSubarea = null;

      if (model.functionalArea == null) {
        model.trainingName = null;
        model.functionalSubarea = null;
      }

      if (model.nmrCluster == null) {
        model.functionalArea = null;
        model.trainingName = null;
        model.functionalSubarea = null;
      }
    }
  };

  render() {
    const { t, active, viewModel } = this.props;
    const {
      trainingLevel,
      mrCluster,
      nmrCluster,
      functionalArea,
      functionalSubarea,
      trainingName,
      equipmentType,
      oem,
      machineModel,
      machineRelated
    } = viewModel;

    return (
      <div className="wizard__step wizard__step-two">
        <StepsComponent active={active} number={'2'} title={t('SELECT MACHINE MODELS')} />
        {active && (
          <>
            <div className="step-two__machinery-wrapper">
              <Form.Group>
                <Form.Field>
                  <div className={`required field machinery__label-input-field editor-38px`}>
                    <label>{t('Training Level')}</label>
                    <TrainingLevelEditor
                      clearable
                      nullable
                      className="custom-editor"
                      value={trainingLevel}
                      onChange={val => this.handleOnChange('trainingLevel', val)}
                      reloadOnChange
                    />
                  </div>
                </Form.Field>
              </Form.Group>
            </div>
            <br />
            {machineRelated && (
              <div className="step-two__machinery-wrapper">
                <Form.Group>
                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Cluster')}</label>
                      <MrclusterEditor
                        clearable
                        nullable
                        value={mrCluster?.id ?? undefined}
                        onChange={value => this.handleOnChange('mrCluster', value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        readOnly={isNullOrWhiteSpaces(mrCluster?.id)}
                        clearable
                        nullable
                        clusterId={mrCluster?.id ?? undefined}
                        value={equipmentType?.id ?? undefined}
                        onChange={value => this.handleOnChange('equipmentType', value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('OEM (Optional)')}</label>
                      <OemEditor
                        readonly={isNullOrWhiteSpaces(mrCluster?.id) || isNullOrWhiteSpaces(equipmentType?.id)}
                        clearable
                        nullable
                        equipmentId={equipmentType?.id ?? undefined}
                        value={oem?.id ?? undefined}
                        onChange={value => this.handleOnChange('oem', value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field error>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Machine Model (Optional)')}</label>
                      <MultiMachineModelEditor
                        className="machinery-step__multi-machine-model-editor"
                        value={(machineModel || []).map(mm => mm.id)}
                        readOnly={
                          isNullOrWhiteSpaces(oem?.id) || isNullOrWhiteSpaces(equipmentType?.id) || isNullOrWhiteSpaces(mrCluster?.id)
                        }
                        clearable
                        clusterId={mrCluster?.id ?? undefined}
                        oemId={oem?.id ?? undefined}
                        equipmentId={equipmentType?.id ?? undefined}
                        onChange={(value: ItemReference[]) => this.handleOnChange('machineModel', value)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </div>
            )}
            {!machineRelated && (
              <div className="step-two__machinery-wrapper">
                <Form.Group>
                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        clearable
                        nullable
                        value={nmrCluster}
                        onChange={value => this.handleOnChange('nmrCluster', value)}
                        reloadOnChange
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Functional Area')}</label>
                      <NMFunctionalAreaEditor
                        readonly={isNullOrWhiteSpaces(nmrCluster?.id)}
                        clearable
                        nullable
                        clusterId={nmrCluster?.id ?? undefined}
                        value={functionalArea}
                        onChange={value => this.handleOnChange('functionalArea', value)}
                        reloadOnChange
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Training Name (Optional)')}</label>
                      <NMTrainingNameEditor
                        readOnly={isNullOrWhiteSpaces(nmrCluster?.id) || isNullOrWhiteSpaces(functionalArea?.id)}
                        clearable
                        nullable
                        funcionalAreaId={functionalArea?.id ?? undefined}
                        value={trainingName}
                        onChange={value => this.handleOnChange('trainingName', value)}
                        reloadOnChange
                      />
                    </div>
                  </Form.Field>

                  <Form.Field error>
                    <div className={`required field machinery__label-input-field editor-38px`}>
                      <label>{t('Functional Subarea (Optional)')}</label>
                      <FunctionalSubareaEditor
                        readonly={
                          isNullOrWhiteSpaces(nmrCluster?.id) ||
                          isNullOrWhiteSpaces(functionalArea?.id) ||
                          isNullOrWhiteSpaces(trainingName?.id)
                        }
                        clearable
                        nullable
                        value={functionalSubarea}
                        trainingNameId={trainingName?.id ?? undefined}
                        onChange={value => this.handleOnChange('functionalSubarea', value)}
                        reloadOnChange
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(PracticalTemplateWizardMachineryStep);
