import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import React from 'react';
import * as autobind from 'autobind';
import { Modal, Button, Message, Form, Dimmer, Loader } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import { NewEventRequestChangeStore } from 'stores/events/event-request-changes-store';
import './request-changes-popup.less';

interface NewEventRequestChangeViewProps extends WithTranslation {
  open: boolean;
  eventId: string;
  onCancelClicked: () => void;
  onSendClicked?: () => void;
  newEventRequestChange?: NewEventRequestChangeStore;
}

interface NewEventRequestChangeViewState {
  reason: ItemReference;
  comments: string;
  loading: boolean;
}

@connect(['newEventRequestChange', NewEventRequestChangeStore])
class NewEventRequestChangeView extends React.Component<NewEventRequestChangeViewProps, NewEventRequestChangeViewState> {
  private get newEventRequestChangeStore() {
    return this.props.newEventRequestChange;
  }

  constructor(props: NewEventRequestChangeViewProps) {
    super(props);

    this.newEventRequestChangeStore.createNew();

    this.state = {
      reason: null,
      comments: null,
      loading: false
    };
  }

  @autobind
  private onRequestChangesSent() {
    this.newEventRequestChangeStore.change({
      comments: this.state.comments,
      reasonId: this.state.reason ? this.state.reason.id : null
    });
    this.setState({ loading: true });
    this.newEventRequestChangeStore.createEventRequestChange(this.props.eventId).then(response => {
      if (this.newEventRequestChangeStore.state.result && this.newEventRequestChangeStore.state.result.isSuccess) {
        this.newEventRequestChangeStore.clear();
        this.setState({ reason: null, comments: null, loading: false });
        this.props.onSendClicked();
      }
    });
  }

  @autobind
  private onCancelClicked() {
    this.setState({ reason: null, comments: null });
    this.props.onCancelClicked();
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.setState({ ...this.state, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open={this.props.open} size="tiny" className="request-changes-popup" closeOnEscape={true} onClose={this.onCancelClicked}>
        <Dimmer active={this.state.loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Content className="request-changes-popup__content">
          {this.newEventRequestChangeStore.state.result && !this.newEventRequestChangeStore.state.result.isSuccess && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={this.newEventRequestChangeStore.state.result.messages.map(o => o.body)}
            />
          )}
          <div className="request-changes-popup__description">
            <p>{t('You are Requesting Changes in this Event.')}</p>
            <p>{t('Please, write down the reason for the change, or any comment related to it.')}</p>
          </div>
          <Form loading={this.newEventRequestChangeStore.state.isBusy}>
            <Form.TextArea
              required
              className="request-changes-popup__reason-description"
              value={this.state.comments}
              onChange={(e, { value }) => this.handleValue(nameof<NewEventRequestChangeViewState>('comments'), value)}
            />
          </Form>
        </Modal.Content>
        <div className="request-changes-popup__buttons-container">
          <Button
            className="request-changes-popup__btn request-changes-popup__cancel-btn"
            content={t('Cancel')}
            onClick={this.onCancelClicked}
          />
          <Button
            className="request-changes-popup__btn request-changes-popup__pending-btn"
            content={t('Send')}
            onClick={this.onRequestChangesSent}
          />
        </div>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewEventRequestChangeView);
