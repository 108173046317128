import React from 'react';

const RequestDragComponent: React.FC<any> = requestEl => {
  const { eventsColor, title, eventType } = requestEl;
  return (
    <div className="drag-element-wrapper">
      <h3 style={{ fontWeight: 'bold', color: eventsColor }}>{eventType.name}</h3>
      <p>{title}</p>
    </div>
  );
};
export default RequestDragComponent;
