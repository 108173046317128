import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import UsefulLinksListPage from './useful-links/useful-links-list';

// import { resolve } from '../../../../inversify.config';
// import { IdentityService } from '../../../../services/identity-service';
export interface UsefulLinksConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface UsefulLinksConfigurationHomeState {
  activeTab: 'usefulLinks';
}

class UsefulLinksConfigurationHomePage extends React.Component<UsefulLinksConfigurationHomeProps, UsefulLinksConfigurationHomeState> {
  constructor(props: UsefulLinksConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'usefulLinks'
    };
  }
  private panes = [
    {
      menuItem: i18n.t('Useful Links'),
      render: () => (
        <Tab.Pane attached={false}>
          <UsefulLinksListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Useful Links')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(UsefulLinksConfigurationHomePage);
