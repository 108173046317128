import React, { Component } from 'react';
import RatingAnswer from './rating-answer.component';
import TextAnswer from './text-answer.component';
import YesNoAnswer from './yes-no-answer.component';
import { QuestionAnswerViewModel } from 'stores/assessments/forms/feedback-form.store';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface QuestionProps extends WithTranslation {
  index: number;
  questionAnswer: QuestionAnswerViewModel;
  readOnly: boolean;
  displayLanguage: string;

  onAnswerChange?: (value: string) => void;
}

class Question extends Component<QuestionProps> {
  private handleOnAnswerChange = (value: string) => {
    this.props.onAnswerChange && this.props.onAnswerChange(value);
  };

  private renderAnswer = () => {
    const { answerType, value } = this.props.questionAnswer;
    const { readOnly } = this.props;

    switch (answerType.toString()) {
      case 'Rating':
        return <RatingAnswer readOnly={readOnly} value={value} onChange={this.handleOnAnswerChange} />;

      case 'Text':
        return <TextAnswer readOnly={readOnly} value={value} onChange={this.handleOnAnswerChange} />;

      case 'YesOrNo':
        return <YesNoAnswer readOnly={readOnly} value={value} onChange={this.handleOnAnswerChange} />;

      default:
        return null;
    }
  };

  render() {
    const { t, index, questionAnswer, displayLanguage } = this.props;
    const translatedQuestionText = !!displayLanguage
      ? questionAnswer.questionTranslations.find(x => x.languageId === displayLanguage)
      : null;
    const questionToDisplay = displayLanguage && !!translatedQuestionText ? translatedQuestionText : questionAnswer.question;

    return (
      <>
        <div className="feedback-form__question">
          <p className="text__bold">
            {index + 1}. {questionToDisplay.text} {questionAnswer.isMandatory ? '' : t('(Optional)')}
          </p>
          {questionToDisplay.subtitle && <p>{questionToDisplay.subtitle}</p>}
        </div>
        {this.renderAnswer()}
      </>
    );
  }
}

export default withTranslation()(Question);
