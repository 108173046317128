import React from 'react';
import * as autobind from 'autobind';
import { connect } from 'redux-scaffolding-ts';
import { NewPatternStore } from '../../../../../stores/configuration/machinery/patterns-store';
import PatternRowItem from './pattern-row-item';
import { CreatePatternRowDto } from 'stores/configuration/machinery/pattern-rows-store';

interface PatternRowsListProps {
  newPattern?: NewPatternStore;
}

interface PatternRowsListState {}

@connect(['newPattern', NewPatternStore])
class PatternRowsList extends React.Component<PatternRowsListProps, PatternRowsListState> {
  private get newPatternStore() {
    return this.props.newPattern;
  }

  @autobind
  private onDelete(index: number, row: CreatePatternRowDto) {
    const item = this.newPatternStore.state.item;
    item.patternRows = this.newPatternStore.state.item.patternRows.filter((p, i) => {
      return i !== index;
    });
    this.newPatternStore.change(item);
  }

  private itemChange = (nextPattern, idx) => {
    const item = this.newPatternStore.state.item;

    item.patternRows[idx] = nextPattern;

    this.newPatternStore.change(item);
  };

  render() {
    if (this.newPatternStore.state.item.patternRows && this.newPatternStore.state.item.patternRows.length > 0) {
      const tagsHtml = this.newPatternStore.state.item.patternRows.map((item, index) => {
        return (
          <div className="four wide column" key={index}>
            <PatternRowItem index={index} patternRow={item} onDelete={this.onDelete} onChange={this.itemChange} />
          </div>
        );
      });

      return <div>{tagsHtml}</div>;
    } else {
      return <div></div>;
    }
  }
}

export default PatternRowsList;
