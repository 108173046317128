import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Form, TextArea, Input } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { RequestWizardStore, RequestWizardData } from 'stores/requests/create-request-wizard-store';
import './step6-comments.less';
import moment from 'moment';
import { isExtendedWorkflow } from 'utils/event-type-utils';

interface NewRequestViewProps extends WithTranslation {
  requestWizardStore?: RequestWizardStore;
}

@connect(['requestWizardStore', RequestWizardStore])
class StepComments extends Component<NewRequestViewProps, any> {
  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.requestWizardStore.change({ ...this.requestWizardStore.state.item, ...change });
  }

  private onManuallyChangeTitle = (val: string) => {
    if (!this.state.titleFocused) {
      const current = this.requestWizardStore.state.item.title;
      if (current !== val) {
        this.setState({ manualTitle: true, originalTitle: val }, () => this.onValueChanged(['title', val]));
      }
    } else {
      this.onValueChanged(['title', val]);
    }
  };

  private onValueChanged = (...changes: [string, any][]) => {
    const change = {};
    changes.forEach(([prop, value]) => {
      change[prop] = value;
    });
    this.requestWizardStore.change({
      ...this.requestWizardStore.state.item,
      ...change
    });
  };

  private getDateWithOutTimeZone = (date: Date) => {
    const myFormat = 'YYYY-MM-DD';
    return moment(date, 'YYYYMMDDTHHmmss')
      .format(myFormat)
      .split('-');
  };

  componentDidMount() {
    const { item } = this.requestWizardStore.state;
    const extendedWorkflow = isExtendedWorkflow(item.eventType.eventTypeCategory);

    if (extendedWorkflow) {
      const startDate = this.getDateWithOutTimeZone(item.startDate);
      const endDate = this.getDateWithOutTimeZone(item.endDate);

      let compoundTitle = '';
      compoundTitle = `${item.requestLocation?.title ?? ''} / ${item.eventType.name} / ${startDate[1]} - ${endDate[1]} / ${
        startDate[0]
      }`.trim();
      this.onManuallyChangeTitle(compoundTitle);
    }
  }

  render() {
    const { t } = this.props as any;

    return (
      <Form className="request-wizard__step6-comments__container" loading={this.requestWizardStore.state.isBusy}>
        <div className="flex-start-center">
          <label>{t('Request Title')}</label>
          <Input
            className="request-title__input"
            fluid
            value={this.requestWizardStore.state.item.title}
            onChange={(e, { value }) => this.onManuallyChangeTitle(value)}
            onFocus={() => this.setState({ titleFocused: true })}
            onBlur={() => this.setState({ titleFocused: false })}
          />
        </div>

        <div className="flex-start-center">
          <label>{t('Comments')}</label>
          <TextArea
            className="custom-editor request__textarea"
            style={{ minHeight: '30vh', maxHeight: '30vh', resize: 'none' }}
            value={this.requestWizardStore.state.item.comments}
            onChange={(e, { value }) => this.handleValue(nameof<RequestWizardData>('comments'), value)}
          />
        </div>
      </Form>
    );
  }
}

export default withTranslation()(StepComments);
