import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';

import { OemDto, OemsStore } from '../../../../../stores/configuration/machinery/oems-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import ChangeOemView from './edit-oem';
import NewOemView from './new-oem';

export interface OemsListProps extends WithTranslation, RouteComponentProps {
  oems: OemsStore;
  readOnly: boolean;
}

export interface OemsListState {
  query: Query;
  newOemShown: boolean;
  changeOemShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['oems', OemsStore])
class OemsListPage extends React.Component<OemsListProps, OemsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<OemDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newOemShown: false,
      changeOemShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.oems.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: OemDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.oems.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: OemDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.oems.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newOemShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newOemShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeOemShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeOemShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: false,
          renderer: data => <span>{data.name}</span>,
          //editor: (data, onChange) => <Input value={data.name} fluid onChange={(e, { value }) => { data.name = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<OemDto>('name'), `contains(${nameof<OemDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<OemDto>('name'))}
              active={activeFilters.includes(nameof<OemDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<OemDto>('name'),
            useProfile: false,
            active: 'Ascending'
          }
        }
      ],
      data: this.props.oems.state
    } as TableModel<OemDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.readOnly && <h2>{t('OEMs')}</h2>}
          {this.props.oems.state.result && !this.props.oems.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.oems.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newOemShown && !this.state.changeOemShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraActions={
                !this.props.readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={!this.props.readOnly}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!this.props.readOnly}
              createNewButtonTitle={t('Add OEM')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newOemShown && <NewOemView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeOemShown && <ChangeOemView onClose={this.onEditItemClosed} currentOem={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(OemsListPage);
