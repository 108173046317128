import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { Button } from 'semantic-ui-react';

import ChangeStatusPopup from '../popups/change-status-popup/change-status-popup';
import { getNextStatus, hasAdministrationRightsOrIsEventStatusPlannedInProgressAndCurrentUserIsInstructor } from 'utils/event-utils';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface NextStateButtonProps extends WithTranslation {
  onNext?: () => void;
  eventFormStore?: EventFormStore;
  readOnly: boolean;
}

interface NextStateButtonState {
  showChangeStatusModal: boolean;
}
@connect(['eventFormStore', EventFormStore])
class NextStateButton extends Component<NextStateButtonProps, NextStateButtonState> {
  state: NextStateButtonState = {
    showChangeStatusModal: false
  };

  private handleOnNextStatusClicked = async () => {
    const { eventFormStore } = this.props;
    await eventFormStore.updateEvent();
    if (eventFormStore.state.result == null || eventFormStore.state.result.isSuccess) this.setState({ showChangeStatusModal: true });
  };

  private handleOnAcceptClicked = async () => {
    const { onNext, eventFormStore, t } = this.props;
    this.setState({ showChangeStatusModal: false });
    const response = await eventFormStore.nextStatus();
    onNext && onNext();
    if (response?.isSuccess) ToastComponent({ text: t('Event successfully changed to next status!'), type: 'success-toast' });
    else eventFormStore.state.result.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'warning-toast' }));
  };

  private isNextStatusButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    if (!hasAdministrationRightsOrIsEventStatusPlannedInProgressAndCurrentUserIsInstructor(event)) {
      return false;
    }

    const nextStatus = getNextStatus(event);
    return nextStatus && nextStatus.toString() !== 'Closed';
  };

  render() {
    const { t, readOnly, eventFormStore } = this.props;
    const { showChangeStatusModal: showChangeStatus } = this.state;
    const isVisible = this.isNextStatusButtonVisible();
    if (!isVisible) return null;

    return (
      <>
        <Button
          className="form__actions__button form__next-status-btn"
          disabled={!readOnly}
          positive
          content={t('Next Status')}
          onClick={this.handleOnNextStatusClicked}
        />

        <ChangeStatusPopup
          eventFormStore={eventFormStore}
          open={showChangeStatus}
          onAcceptClicked={this.handleOnAcceptClicked}
          onCancelClicked={() => this.setState({ showChangeStatusModal: false })}
        />
      </>
    );
  }
}

export default withTranslation()(NextStateButton);
