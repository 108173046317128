import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface UsefulLinkDto extends BaseDto {
  id: string;
  name: string;
  link: string;
  role: string;
  active: boolean;
}

export interface CreateUsefulLinkDto {
  name: string;
  link: string;
  role: string;
  active: boolean;
}

export interface ChangeUsefulLinkDto {
  id: string;
  name: string;
  link: string;
  role: string;
  active: boolean;
}

export class CreateUsefulLinkValidator extends AbstractValidator<CreateUsefulLinkDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Name is required'));

    this.validateIfString(o => o.link)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Description is required'));

    this.validateIfString(o => o.role)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Role is required'));
  }
}

export class ChangeUsefulLinkValidator extends AbstractValidator<ChangeUsefulLinkDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('UsefulLink Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Name is required'));

    this.validateIfString(o => o.link)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Description is required'));

    this.validateIfString(o => o.role)
      .isNotEmpty()
      .withFailureMessage(i18n.t('UsefulLink Role is required'));
  }
}

@repository('@@USEFULLINKS', 'usefulLinks.summary')
export class UsefulLinksStore extends DataStore<UsefulLinkDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-usefulLink';
  retrievePath = 'get-usefulLinks';
  updatePath = 'update-usefulLink';
  deletePath = 'delete-usefulLink';

  protected validate(item: UsefulLinkDto) {
    return new ChangeUsefulLinkValidator().validate(item);
  }

  constructor() {
    super('USEFULLINK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@USEFULLINKS', 'usefulLinks.new')
export class NewUsefulLinkStore extends FormStore<CreateUsefulLinkDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-usefulLink';
  retrievePath = 'get-usefulLinks';
  updatePath = 'update-usefulLink';

  protected validate(item: CreateUsefulLinkDto) {
    return new CreateUsefulLinkValidator().validate(item);
  }

  constructor() {
    super('NEW_USEFULLINK', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@USEFULLINKS', 'usefulLinks.change')
export class ChangeUsefulLinkStore extends FormStore<ChangeUsefulLinkDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-usefulLink';
  retrievePath = 'get-usefulLinks';
  updatePath = 'update-usefulLink';

  protected validate(item: ChangeUsefulLinkDto) {
    return new ChangeUsefulLinkValidator().validate(item);
  }

  constructor() {
    super('CHANGE_USEFULLINK', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
