import * as React from 'react';
import * as autobind from 'autobind';
import { Dropdown } from 'semantic-ui-react';
import { ItemReference, QueryResult } from 'stores/dataStore';
import 'utils/linq';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface DropdownProps {
  searchable?: boolean;
  nullable?: boolean;
  clearable?: boolean;
  multiple?: boolean;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  minWidth?: number;
  direction?: 'left' | 'right';
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface SelectionInputProps extends DropdownProps {
  searchQuery?: string;
  value: ItemReference | ItemReference[] | string | string[];
  options?: SelectionItem[];
  hideText?: boolean;
  overrideOptionsWithValue?: boolean;
  sortOptions?: boolean;
  fluid?: boolean;
  error?: boolean;
  clearOnReload?: boolean;
  direction?: 'left' | 'right';
  content?: (item: any) => React.ReactElement<any>;
  onQueryChange?: (searchQuery: string) => void;
  onChange: (item: ItemReference | ItemReference[] | string | string[]) => void;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  isDisabled?: (item: any) => boolean;
  onOpenDropDown?: () => void;
}

interface SelectionInputState {
  isFetching: boolean;
  isDirty: boolean;
  searchQuery: string;
  options: SelectionItem[];
  value: string | string[];
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

export interface SelectionItem {
  key: string;
  text: string;
  value: string;
  object?: any;
  disabled?: boolean;
}

export class SelectionInput extends React.Component<SelectionInputProps, SelectionInputState> {
  static defaultProps: Partial<SelectionInputProps> = { clearable: true, nullable: true };
  state = {
    isFetching: false,
    isDirty: false,
    searchQuery: '',
    options: [],
    value: null,
    query: null
  };

  constructor(props: SelectionInputProps) {
    super(props);

    if (!props.options && !props.query) {
      throw Error("One of the 'options' or 'query' properties must be specified");
    }

    if (props.options && props.query) {
      throw Error("Only one of the 'options' or 'query' properties must be specified at the same time");
    }

    let value: string | string[];
    if (props.value) {
      if (props.value instanceof Array) {
        value = (this.props.value as ItemReference[]).map(o => o.id);
      } else {
        if ((this.props.value as ItemReference).id) {
          value = (this.props.value as ItemReference).id;
        } else {
          value = this.props.value as string;
        }
      }
    }

    this.state = {
      isFetching: false,
      isDirty: false,
      searchQuery: props.searchQuery,
      options: props.options || ([] as SelectionItem[]),
      value,
      query: this.props.query
    };
  }

  @autobind
  private handleChange(e, { value }) {
    if (this.props.multiple) {
      const filteredOptions = this.state.options.filter(o => value.some(v => v === o.value));
      const items: ItemReference[] | string[] = filteredOptions.map(itm => itm.object || itm.value);
      this.setState({ value: filteredOptions.map(itm => itm.value) }, () => this.props.onChange(items));
    } else {
      const filteredOptions = this.state.options.filter(o => o.value === value);
      if (filteredOptions.length > 0) {
        const item: ItemReference | string = filteredOptions[0].object || filteredOptions[0].value;
        this.setState({ value: filteredOptions[0].value }, () => this.props.onChange(item));
      } else if (this.props.nullable) {
        this.setState({ value: null }, () => this.props.onChange(null));
      }
    }
  }

  @autobind
  private handleSearchChange(e, { searchQuery }) {
    this.setState({ searchQuery });
    if (this.props.onQueryChange) this.props.onQueryChange(searchQuery);
  }

  @autobind
  private async reload(mounted: boolean = true) {
    const updateState = (state, callback?: () => void) => this.setState(state, callback);

    const addContent = (item: any, obj: any) => {
      item.searchabletext = 'countryName' in obj ? item.text + ' - ' + obj.countryName : item.text;
      if (this.props.content) {
        item.text = this.props.content(obj);
      }
      return item;
    };

    if (this.props.options && this.state.searchQuery) {
      updateState({
        isDirty: false,
        isFetching: false,
        options: this.props.options.filter(o => o.text.includes(this.state.searchQuery)).map(o => addContent(o, o))
      });
      return;
    }

    if (this.state.isFetching) {
      updateState({ isDirty: true });
      return;
    }

    updateState({ isFetching: true });

    let queryResult: QueryResult<ItemReference> = { items: [], count: 0 };
    if (this.state.options.length > 0) {
      queryResult.items = this.state.options.map(x => ({ id: x.key, title: x.text } as ItemReference));
    }
    if (this.state.query) queryResult = await this.state.query(this.state.searchQuery);

    const valueAsItemReference = this.props.value as ItemReference;

    if (valueAsItemReference?.title && !valueAsItemReference?.id) {
      const target = (queryResult?.items || []).find(x => x?.title === valueAsItemReference?.title);
      target && this.setState({ value: target.id });
    }

    const emptyitem = { id: null, title: '' };
    if (queryResult?.items) queryResult.items = [emptyitem, ...(queryResult?.items || [])];

    if (this.props.value && queryResult?.items) {
      const same = queryResult.items.filter(o => o.id === valueAsItemReference.id);
      if (
        same.length === 1 &&
        !this.props.multiple &&
        isNullOrEmpty(valueAsItemReference.title) &&
        valueAsItemReference.title !== same[0].title
      ) {
        valueAsItemReference.title = same[0].title;
        this.props.onChange(same[0]);
      }

      if (same.length === 0) {
        const item = this.props.value as ItemReference;
        if (this.props.nullable && this.props.clearOnReload) {
          // this.props.onChange(null);
        } else {
          queryResult.items.push({
            id: item.id,
            title: item.title
          });
        }
      } else if (!!this.props.overrideOptionsWithValue) {
        const item = this.props.value as ItemReference;
        same.forEach(itm => {
          itm.title = item.title;
        });
      }
    }
    // If record is null -> change value to null to not display any text
    if (this.props.value == null) {
      this.setState({ ...this.state, value: null });
    }

    let options = (queryResult?.items || []).map(o =>
      addContent(
        {
          key: o.id == null ? `NULL_VALUE_${o.title}` : o.id,
          value: o.id,
          text: o.title,
          object: o,
          disabled: this.props.isDisabled && this.props.isDisabled(o)
        } as SelectionItem,
        o
      )
    );

    if (this.state.options) {
      this.state.options.forEach(o => {
        if (options.filter(e => e.value === o.value).length === 0) {
          options.push(o);
        }
      });
      if (this.props.sortOptions) {
        options = options.orderBy(o => o.searchabletext) as any[];
      }
    }

    if (!this.props.nullable && (this.state.value == null || this.state.value.length === 0) && options.length > 0) {
      const item: ItemReference = options[0].object || { id: options[0].value, title: options[0].text };
      if (this.props.multiple) {
        this.props.onChange([item]);
        updateState({ value: [options[0].value] });
      } else {
        this.props.onChange(item);
        updateState({ value: options[0].value });
      }
    }

    const isDirty = this.state.isDirty;
    updateState({ options, isFetching: false, isDirty: false });

    if (isDirty) {
      setTimeout(() => this.reload());
    }
  }

  @autobind
  private searchFunction(options, query) {
    return options.filter(o => (o.searchabletext || '').toUpperCase().includes((query || '').toUpperCase()));
  }

  UNSAFE_componentWillReceiveProps(nextProps: SelectionInputProps) {
    if (nextProps === this.props) return;

    if (
      nextProps.query !== this.props.query ||
      nextProps.options !== this.props.options ||
      nextProps.value !== this.props.value ||
      nextProps.searchQuery !== this.props.searchQuery
    ) {
      let value: string | string[];
      if (nextProps.value) {
        if (nextProps.value instanceof Array) {
          value = (nextProps.value as ItemReference[]).map(o => o.id);
        } else {
          if ((nextProps.value as ItemReference).id) {
            value = (nextProps.value as ItemReference).id;
          } else {
            value = nextProps.value as string;
          }
        }
      }

      this.setState(
        {
          query: nextProps.query,
          isFetching: false,
          isDirty: false,
          searchQuery: nextProps.searchQuery,
          options: nextProps.options || ([] as SelectionItem[]),
          value
        },
        () => {
          this.reload();
        }
      );
    }
  }

  componentDidMount() {
    this.reload();
  }

  shouldComponentUpdate(nextProp, nextState) {
    if (
      this.props.searchable !== nextProp.searchable ||
      this.props.nullable !== nextProp.nullable ||
      this.props.clearable !== nextProp.clearable ||
      this.props.multiple !== nextProp.multiple ||
      this.props.readOnly !== nextProp.readOnly ||
      this.props.className !== nextProp.className ||
      this.props.placeholder !== nextProp.placeholder ||
      this.props.minWidth !== nextProp.minWidth
    ) {
      return true;
    }

    if (
      this.props.options !== nextProp.options ||
      this.props.hideText !== nextProp.hideText ||
      this.props.overrideOptionsWithValue !== nextProp.overrideOptionsWithValue ||
      this.props.sortOptions !== nextProp.sortOptions ||
      this.props.fluid !== nextProp.fluid ||
      this.props.error !== nextProp.error ||
      this.props.value !== nextProp.value
    ) {
      return true;
    }
    if (
      this.state.isFetching !== nextState.isFetching ||
      this.state.isDirty !== nextState.isDirty ||
      this.state.value !== nextState.value ||
      this.state.options.length !== nextState.options.length
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { fluid, multiple, searchable, placeholder, direction, hideText, readOnly, error, minWidth, className, clearable } = this.props;
    const { isFetching, options, value } = this.state;

    return (
      <Dropdown
        direction={direction}
        readOnly={readOnly}
        error={error || false}
        style={minWidth ? { minWidth } : {}}
        className={!className ? 'custom-editor' : className}
        fluid={fluid}
        clearable={clearable}
        selection
        multiple={multiple}
        options={options}
        value={value}
        search={searchable ? this.searchFunction : false}
        placeholder={placeholder || 'Please select...'}
        onChange={this.handleChange}
        onSearchChange={this.handleSearchChange}
        disabled={isFetching || readOnly}
        loading={isFetching}
        selectOnBlur={false}
        selectOnNavigation={false}
        text={!!hideText ? ' ' : null}
        additionPosition={'bottom'}
        onOpen={this.props.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
        onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
      />
    );
  }
}
