import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { ItemReference, Query } from 'stores/dataStore';
import { TnaDevelopmentPlanGroupingStore, TnaDevelopmentPlanGroupingDto } from 'stores/development-plan/tna-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ProfileItemDto } from 'stores/profile/profile-store';
import i18n from 'i18n';
import ChipInfo from 'widgets/bussiness/chip-info';
import YearEditor from 'widgets/bussiness/year-editor';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import { nameof } from 'utils/object';
import './location-tab-pane.less';

interface LocationTabPaneFilters {
  eventTypeId: string;
  locationId: string;
  profileId: string;
  year?: number;
}

interface LocationTabPaneState {
  query: Query;
  filters: LocationTabPaneFilters;
}

export interface LocationTabPaneProps extends WithTranslation {
  tnaDevelopmentPlanGroupingStore?: TnaDevelopmentPlanGroupingStore;
}

@connect(['tnaDevelopmentPlanGroupingStore', TnaDevelopmentPlanGroupingStore])
class LocationTabPane extends Component<LocationTabPaneProps, LocationTabPaneState> {
  state: LocationTabPaneState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    filters: {
      eventTypeId: null,
      locationId: null,
      profileId: null,
      year: null
    }
  };

  componentDidMount() {
    this.load();
  }

  private columns: TableColumn<TnaDevelopmentPlanGroupingDto>[] = [
    {
      title: i18n.t('Location'),
      tooltipRenderer: true,
      renderer: data => data.locationName
    },
    {
      title: i18n.t('Profile'),
      tooltipRenderer: true,
      renderer: data => data.profileName
    },
    {
      title: i18n.t('Machine Model'),
      renderer: data =>
        data.machineModel && (
          <ChipInfo info={[data.machineModel.clusterName, data.machineModel.equipmentTypeName, data.machineModel.name]} />
        )
    },
    {
      title: i18n.t('Level'),
      tooltipRenderer: true,
      renderer: data => data.trainingLevelName
    },
    {
      title: i18n.t('Activity'),
      tooltipRenderer: true,
      renderer: data => data.eventTypeName
    },
    {
      title: i18n.t('Year'),
      tooltipRenderer: true,
      renderer: data => data.deadlineYear
    },
    {
      title: i18n.t('Planned'),
      tooltipRenderer: true,
      renderer: data => data.plannedCount
    },
    {
      title: i18n.t('In Progress'),
      tooltipRenderer: true,
      renderer: data => data.inProgressCount
    },
    {
      title: i18n.t('Completed'),
      tooltipRenderer: true,
      renderer: data => data.completedCount
    },
    {
      title: i18n.t('Out of Date'),
      tooltipRenderer: true,
      renderer: data => data.outOfDateCount
    }
  ];

  private handleFilterByEventType = (eventType: ItemReference) => {
    const eventTypeId = eventType ? eventType.id : null;
    this.handleFilterChange(nameof<LocationTabPaneFilters>('eventTypeId'), eventTypeId);
  };

  private handleFilterByLocation = (location: LocationItemReference) => {
    const locationId = location ? location.id : null;
    this.handleFilterChange(nameof<LocationTabPaneFilters>('locationId'), locationId);
  };

  private handleFilterByProfile = (profile: ProfileItemDto) => {
    const profileId = profile ? profile.id : null;
    this.handleFilterChange(nameof<LocationTabPaneFilters>('profileId'), profileId);
  };

  private handleFilterByYear = (year?: number): void => {
    this.handleFilterChange(nameof<LocationTabPaneFilters>('year'), year);
  };

  private handleFilterChange(property: string, value: any) {
    const filters = { ...this.state.filters };
    filters[property] = value;

    this.setState({ filters: filters }, this.load);
  }

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.tnaDevelopmentPlanGroupingStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: LocationTabPaneFilters): Query => {
    const queryParameters = {};
    const filter = [];

    if (!isNullOrWhiteSpaces(filters.eventTypeId)) filter.push({ EventTypeId: { eq: { type: 'guid', value: filters.eventTypeId } } });
    if (!isNullOrWhiteSpaces(filters.profileId)) filter.push({ ProfileItemId: { eq: { type: 'guid', value: filters.profileId } } });
    if (!isNullOrWhiteSpaces(filters.locationId)) queryParameters['LocationId'] = filters.locationId;
    if (!!filters.year) filter.push({ DeadlineYear: { eq: filters.year } });

    return { ...this.state.query, filter: filter, parameters: queryParameters };
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  render() {
    const { t, tnaDevelopmentPlanGroupingStore: store } = this.props;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.tnaDevelopmentPlanGroupingStore.state
    } as TableModel<TnaDevelopmentPlanGroupingDto>;

    return (
      <div className="tna-development-plan-by-location__wrapper">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body)}
            onDismiss={() => this.props.tnaDevelopmentPlanGroupingStore.clearMessages()}
          />
        )}

        <div className="tna-development-plan-by-location__filters">
          <EventTypeEditor
            clearable
            className="custom-editor"
            placeholder={t('Activity')}
            nullable
            useOriginalEventTypeIdAsValue
            value={null}
            onChange={this.handleFilterByEventType}
          />

          <LocationEditor
            clearable
            className="custom-editor"
            placeholder={t('Location')}
            nullable
            value={null}
            filterLocationsByActiveRole
            onChange={this.handleFilterByLocation}
          />

          <YearEditor
            clearable
            className="custom-editor year-editor"
            placeholder={t('Year')}
            value={this.state.filters.year}
            onChange={this.handleFilterByYear}
          />

          <ProfileEditor
            clearable
            placeholder={t('Profile')}
            value={this.state.filters.profileId}
            nullable
            className="custom-editor"
            assessment="TNA"
            onChange={this.handleFilterByProfile}
          />
        </div>
        <TableView model={tableModel} onRefresh={this.load} onPageChange={this.handlePageChange} hiddeMenu></TableView>
      </div>
    );
  }
}

export default withTranslation()(LocationTabPane);
