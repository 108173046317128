import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';

interface RemainingTimeProps {
  start: string;
  duration: number;
  onFinished?: () => void;
  finishedTime?: boolean;
}

const zeroTime = '00:00:00';

const tick = (startedAt: string, hourDuration: number) => {
  const now = DateTimeService.now();
  const end = DateTimeService.toMoment(startedAt).add(hourDuration, 'hours');
  const diff = moment(end.diff(now)).utc();
  const isAfter = now.isAfter(end);
  if (isAfter) return zeroTime;
  return diff.format('HH:mm:ss');
};

const ReaminingTime: FC<RemainingTimeProps> = ({ finishedTime: finished, start, duration, onFinished }) => {
  const [remainingTime, setRemainingTime] = useState(finished ? zeroTime : '-');
  let interval =
    !finished &&
    setInterval(() => {
      setRemainingTime(tick(start, duration));
    }, 1000);

  if (!finished && remainingTime === zeroTime) onFinished && onFinished();

  useEffect(() => {
    return () => interval && clearInterval(interval);
  }, [interval]);

  return <>{remainingTime}</>;
};
export default ReaminingTime;
