import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface DeliveryTypeDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateDeliveryTypeDto {
  name: string;
}

export interface ChangeDeliveryTypeDto {
  id: string;
  name: string;
}

export class CreateDeliveryTypeValidator extends AbstractValidator<CreateDeliveryTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Delivery Type name is required'));
  }
}

export class ChangeDeliveryTypeValidator extends AbstractValidator<ChangeDeliveryTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Delivery Type Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Delivery Type name is required'));
  }
}

@repository('@@DELIVERY-TYPES', 'delivery-types.summary')
export class DeliveryTypesStore extends DataStore<DeliveryTypeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-delivery-type';
  retrievePath = 'get-delivery-types';
  updatePath = 'update-delivery-type';
  deletePath = 'delete-delivery-type';

  protected validate(item: DeliveryTypeDto) {
    return new ChangeDeliveryTypeValidator().validate(item);
  }

  constructor() {
    super('DELIVERY_TYPE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@DELIVERY-TYPES', 'delivery-types.dropdown')
export class DropDownDeliveryTypesStore extends DataStore<DeliveryTypeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-delivery-type';
  retrievePath = 'get-delivery-types';
  updatePath = 'update-delivery-type';
  deletePath = 'delete-delivery-type';

  protected validate(item: DeliveryTypeDto) {
    return new ChangeDeliveryTypeValidator().validate(item);
  }

  constructor() {
    super('DELIVERY_TYPE_DROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, deliveryTypesIds?: string[], search?: string): Promise<QueryResult<DeliveryTypeDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let deliveryTypesDto = this.state.items.map(({ item }) => item);

      if ((deliveryTypesIds || []).length > 0) {
        deliveryTypesDto = deliveryTypesDto.filter(x => deliveryTypesIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        deliveryTypesDto = deliveryTypesDto.filter(x => x.name.startsWith(search));
      }

      let locationsResult = { items: deliveryTypesDto, count: deliveryTypesDto.length } as QueryResult<DeliveryTypeDto>;
      return new Promise<QueryResult<DeliveryTypeDto>>(resolve => resolve(locationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<DeliveryTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<DeliveryTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@DELIVERY-TYPES', 'delivery-types.new')
export class NewDeliveryTypeStore extends FormStore<CreateDeliveryTypeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-delivery-type';
  retrievePath = 'get-delivery-types';
  updatePath = 'update-delivery-type';

  protected validate(item: CreateDeliveryTypeDto) {
    return new CreateDeliveryTypeValidator().validate(item);
  }

  constructor() {
    super('NEW_DELIVERY_TYPE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@DELIVERY-TYPES', 'delivery-types.change')
export class ChangeDeliveryTypeStore extends FormStore<ChangeDeliveryTypeDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-delivery-type';
  retrievePath = 'get-delivery-types';
  updatePath = 'update-delivery-type';

  protected validate(item: ChangeDeliveryTypeDto) {
    return new ChangeDeliveryTypeValidator().validate(item);
  }

  constructor() {
    super('CHANGE_DELIVERY_TYPE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
