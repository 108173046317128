import { PresetManager } from '@planit/bryntum-scheduler';
import { DateTimeService } from 'services/datetime-service';

PresetManager.registerPreset('weekAndDayJTI', {
  name: 'weekAndDayJTI',
  tickWidth: 100,
  rowHeight: 32,
  displayDateFormat: 'HH:mm',
  shiftIncrement: 1,
  shiftUnit: 'day',
  timeResolution: { unit: 'day', increment: 1 },
  defaultSpan: 24,
  headers: [
    { unit: 'week', increment: 1, align: 'center', dateFormat: 'WW / MMMM' },
    { unit: 'day', increment: 1, align: 'center', dateFormat: 'ddd, Do' }
  ]
});
PresetManager.registerPreset('weekAndMonthJTI', {
  name: 'weekAndMonthJTI',
  tickWidth: 100,
  rowHeight: 32,
  displayDateFormat: 'YYYY-MM-DD',
  shiftIncrement: 1,
  shiftUnit: 'hour',
  timeResolution: { unit: 'day', increment: 1 },
  defaultSpan: 12,
  headers: [
    { unit: 'month', increment: 1, align: 'center', dateFormat: 'MMMM YYYY' },
    {
      unit: 'week',
      increment: 1,
      align: 'center',
      renderer: data => {
        const startOfWeek = DateTimeService.toSchedulerPresetView(DateTimeService.toMoment(data).day(1));
        const endOfWeek = DateTimeService.toSchedulerPresetView(DateTimeService.toMoment(data).day(7));
        return startOfWeek + ' - ' + endOfWeek;
      }
    }
  ]
});
PresetManager.registerPreset('monthAndYearJTI', {
  name: 'monthAndYearJTI',
  tickWidth: 100,
  rowHeight: 32,
  displayDateFormat: 'YYYY-MM-DD',
  shiftIncrement: 1,
  shiftUnit: 'day',
  timeResolution: { unit: 'day', increment: 1 },
  defaultSpan: 24,
  headers: [
    { unit: 'year', increment: 1, align: 'center', dateFormat: 'YYYY' },
    { unit: 'month', increment: 1, align: 'center', dateFormat: 'MMMM' }
  ]
});
