import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Header, Icon } from 'semantic-ui-react';
import i18n from 'i18n';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';

export class UnauthorizedView extends React.Component<any, any> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    setTimeout(() => {
      this.identityService.signinRedirect('/');
    }, 3000);
  }
  render() {
    return (
      <div className="planit-general-error-container">
        <Container>
          <Header icon>
            <Icon name="frown outline" />
            {i18n.t('Unauthorized')}
          </Header>
          <p>{i18n.t('You dont have access to this resource. Check your permissions or the current active role and try again')}</p>
          <small>{i18n.t('Redirecting to home in 3s')}</small>
        </Container>
      </div>
    );
  }
}

// frown outline Wire up the React component to the Redux store
export default withTranslation()(UnauthorizedView);
