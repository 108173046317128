import React from 'react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { ChangeRequestStore, RequestStatus, RequestDto, ExtendedStatusValidation } from '../../../../../../stores/requests/requests-store';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import RequestRejectPopup from './request-reject-popup';
import { replaceAll } from 'utils/useful-functions';
import { userCanRejectGeaOrGmaRequest } from 'utils/extended-requests-utils';

interface RequestRejectButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestValidatorRoles: string[];
  changeRequestStore?: ChangeRequestStore;
  isGea: boolean;
  isGma: boolean;
  extendedStatusValidation: ExtendedStatusValidation;
  readOnly: boolean;

  onSuccess?: (request: RequestDto) => void;
  onError?: () => void;
}

interface RequestRejectButtonState {
  showRequestRejectPopup: boolean;
}

class RequestRejectButton extends Component<RequestRejectButtonProps, RequestRejectButtonState> {
  static defaultProps = { onSuccess: () => {}, onError: () => {} };
  state: RequestRejectButtonState = { showRequestRejectPopup: false };

  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnCancel = () => this.setState({ showRequestRejectPopup: false });

  handleOnClick = () => this.setState({ showRequestRejectPopup: true });

  isVisible = (): boolean => this.isVisibleInCurrentStatus() && this.currentUserHasPermission();

  isVisibleInCurrentStatus = (): boolean => this.props.requestStatus.toString() === 'Pending';

  currentUserHasPermission = (): boolean => {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma, extendedStatusValidation, requestValidatorRoles, requestStatus } = this.props;

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    if ((requestValidatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''))) {
      if (isGma) return userCanRejectGeaOrGmaRequest('GMA', extendedStatusValidation, requestValidatorRoles, requestStatus, userInfo);

      if (isGea) return userCanRejectGeaOrGmaRequest('GEA', extendedStatusValidation, requestValidatorRoles, requestStatus, userInfo);

      return true;
    }
    return false;
  };

  render() {
    const { t, readOnly, onError, onSuccess, requestId } = this.props;
    const { showRequestRejectPopup } = this.state;
    return (
      this.isVisible() && (
        <>
          <Button className="form__actions__button request-form__reject-button" disabled={!readOnly} onClick={this.handleOnClick}>
            {t('Reject')}
          </Button>
          {showRequestRejectPopup && (
            <RequestRejectPopup
              requestId={requestId}
              open={showRequestRejectPopup}
              onCancel={this.handleOnCancel}
              onSuccess={onSuccess}
              onError={onError}
            />
          )}
        </>
      )
    );
  }
}

export default withTranslation()(RequestRejectButton);
