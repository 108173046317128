import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { DropDownLocationsStore, LocationDto } from '../../stores/configuration/locations/locations-store';
import { nameof } from '../../utils/object';
import { Dropdown } from 'semantic-ui-react';

interface MultiLocationEditorProps extends WithTranslation {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  locations?: DropDownLocationsStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  filteredLocations?: string[];
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultiLocationEditorState {
  value: string[];
  availableLocations: { [id: string]: LocationDto };
  locationOptions: { text: string; value: string }[];
}

@connect(['locations', DropDownLocationsStore])
class MultiLocationEditor extends React.Component<MultiLocationEditorProps, MultiLocationEditorState> {
  private get locationStore() {
    return this.props.locations;
  }

  constructor(props: MultiLocationEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      availableLocations: {},
      locationOptions: []
    };

    this.initLocations();
  }

  private async initLocations() {
    let filter;
    if (this.props.filteredLocations && this.props.filteredLocations.length > 0) {
      filter = [{ id: { in: { type: 'guid', value: this.props.filteredLocations } } }];
    } else {
      filter = ['active eq true'];
    }

    return await this.locationStore
      .getAllAsync(
        {
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }],
          filter: filter
        },
        this.props.filteredLocations
      )
      .then(locations => {
        const dict = {};
        const options = [];

        locations.items.forEach(location => {
          dict[location.id] = location;
          options.push({ text: location.location, value: location.id });
        });

        this.setState({
          availableLocations: dict,
          locationOptions: options
        });
      });
  }

  public render() {
    const { t } = this.props as any;
    const locationOptions = this.state.locationOptions;

    return (
      <Dropdown
        search
        inline
        selection
        closeOnChange
        closeOnEscape
        clearable={true}
        multiple={true}
        options={locationOptions}
        error={!this.state.value}
        className="planit-users-inputs planit-user-dropdown planit-user-selector"
        value={this.state.value ? this.state.value : null}
        onChange={(e, data) => this.selectedLocation(data)}
        placeholder={t('Select location')}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }

  selectedLocation(data: any) {
    this.setState({
      value: data.value
    });

    this.props.onChange(data.value);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiLocationEditor);
