import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  OverallRecommendationDto,
  ChangeOverallRecommendationDto,
  ChangeOverallRecommendationStore
} from 'stores/configuration/overall-recommendations/overall-recommendations-store';
import { nameof } from 'utils/object';

interface ChangeOverallRecommendationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeOverallRecommendation?: ChangeOverallRecommendationStore;
  currentOverallRecommendation: OverallRecommendationDto;
}

interface ChangeOverallRecommendationViewState {
  userClickedSaved: boolean;
}

@connect(['changeOverallRecommendation', ChangeOverallRecommendationStore])
class ChangeOverallRecommendationView extends React.Component<ChangeOverallRecommendationViewProps, ChangeOverallRecommendationViewState> {
  private get changeOverallRecommendationStore() {
    return this.props.changeOverallRecommendation;
  }

  constructor(props: ChangeOverallRecommendationViewProps) {
    super(props);

    this.state = {
      userClickedSaved: false
    };

    this.changeOverallRecommendationStore.state.result = null;

    this.changeOverallRecommendationStore.change({
      id: this.props.currentOverallRecommendation.id,
      name: this.props.currentOverallRecommendation.name,
      description: this.props.currentOverallRecommendation.description,
      active: this.props.currentOverallRecommendation.active
    });
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps: ChangeOverallRecommendationViewProps) {
    if (this.changeOverallRecommendationStore.state.result && this.changeOverallRecommendationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeOverallRecommendationStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeOverallRecommendationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeOverallRecommendationStore.change({ ...this.changeOverallRecommendationStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeOverallRecommendationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t(`Edit ${this.props.currentOverallRecommendation.name}`)}</Modal.Header>
        <Modal.Content image>
          <Container>
            <Dimmer active={this.changeOverallRecommendationStore.state.isBusy} inverted>
              <Loader size="large" indeterminate inverted></Loader>
            </Dimmer>
            {this.changeOverallRecommendationStore.state.result && !this.changeOverallRecommendationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeOverallRecommendationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeOverallRecommendationStore.state.item && (
              <Form>
                <Form.Group widths="equal" className="overall-recommendation-form-group">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeOverallRecommendationStore.state.item.name}
                    label={t('Name')}
                    placeholder={t('Name')}
                    value={this.changeOverallRecommendationStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeOverallRecommendationDto>('name'), value)}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.changeOverallRecommendationStore.state.item.description}
                    label={t('Description')}
                    placeholder={t('Description')}
                    value={this.changeOverallRecommendationStore.state.item.description}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeOverallRecommendationDto>('description'), value)}
                  />
                  {/* <Form.Checkbox
                    style={{ top: 25 }}
                    toggle
                    checked={this.changeOverallRecommendationStore.state.item.active}
                    label={t('Active')}
                    placeholder={t('Active')}
                    onChange={(e, { checked }) => this.handleValue(nameof<ChangeOverallRecommendationDto>('active'), checked)}
                  /> */}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeOverallRecommendationView);
