import React from 'react';

class LineSeparator extends React.Component {
  public render() {
    return (
      <div className="planit-line-separator" style={{ marginBottom: '12px', paddingBottom: '12px' }}>
        <hr style={{ borderBottom: '3px solid #474b4f', opacity: 1 }} />
      </div>
    );
  }
}

export default LineSeparator;
