import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, ResponsiveContainer, Tooltip, PolarRadiusAxis } from 'recharts';
import './employee-page.less';
import './background-employee.less';

export interface RadarChartComponentProps extends WithTranslation {
  data: any[];
  comparisonViewModels: ComparisonViewModel[];
  maxPoint: number;
  extraKeysPrefix?: string;
  extraKeysColors?: { [key: string]: string };
  disableActual?: boolean;
  customColorActual?: string;
}

export interface ComparisonViewModel {
  name: string;
  dataKey: string;
  stroke: string;
  fill: string;
  fillBorder: number;
}
class RadarChartComponent extends Component<RadarChartComponentProps> {
  private getExtraKeyColor = (num: number, key: string, colors: { [key: string]: string }): string => {
    let color = '';
    if (colors && colors[key]) {
      color = colors[key];
    } else {
      var randomColor = Math.floor((Math.random() * (0xffffff - num)) / num).toString(16);
      color = '#' + randomColor;
    }

    return color;
  };
  public render() {
    const { t, data, maxPoint, extraKeysPrefix, disableActual, extraKeysColors, customColorActual } = this.props;

    const insideObj = (data || []).firstOrDefault();
    let hasMoreKeys = insideObj && extraKeysPrefix ? Object.keys(insideObj).filter(x => x.startsWith(extraKeysPrefix)) : [];

    return (
      <>
        <ResponsiveContainer className="responsive-container__radar-chart">
          <RadarChart debounce={1} scale="auto" dot={true} data={data}>
            <PolarGrid dot={true} />
            <PolarAngleAxis dot={true} dataKey="subject" />
            <PolarRadiusAxis domain={[0, maxPoint]} tick={false} tickCount={maxPoint + 1} axisLine={false} />
            {!disableActual && (
              <Radar
                wrapWidth={20}
                isAnimationActive
                animationEasing="ease-in"
                animationDuration={1200}
                name={t('Actual')}
                dataKey="actual"
                fill={customColorActual ? customColorActual : '#CCF0EE'}
                fillOpacity={0.6}
              />
            )}
            {(hasMoreKeys || []).map((extraKey, index) => (
              <Radar
                key={'extra-key-' + index}
                wrapWidth={20}
                isAnimationActive
                animationEasing="ease-in"
                animationDuration={1200}
                name={t(extraKey.replace(extraKeysPrefix, ''))}
                dataKey={extraKey}
                fill={this.getExtraKeyColor(index + 1, extraKey, extraKeysColors)}
                fillOpacity={0.6}
              />
            ))}
            {(this.props.comparisonViewModels || []).map((comparisonViewModel, index) => (
              <Radar
                key={'radar-' + index}
                wrapWidth={20}
                isAnimationActive
                animationEasing="ease-in"
                animationDuration={1500 + 200 * index}
                name={comparisonViewModel.name}
                dataKey={comparisonViewModel.dataKey}
                stroke={comparisonViewModel.stroke}
                strokeWidth={2}
                fill={comparisonViewModel.fill}
                fillOpacity={0}
                fillBorder={comparisonViewModel.fillBorder}
              />
            ))}
            <Tooltip contentStyle={{ color: 'white', backgroundColor: '#474B4F', borderRadius: '4px' }} />
            <Legend iconSize={8} iconType="circle" />
          </RadarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

export default withTranslation()(RadarChartComponent);
