import { faFile, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jtiWhite from 'assets/img/jti-white.svg';
import jtiIcon from 'assets/img/jti.svg';
import { resolve } from 'inversify.config';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Divider, Dropdown, Grid, Icon, Image, Menu, Label, Popup } from 'semantic-ui-react';
import { IdentityService, Roles } from 'services/identity-service';
import { getGravatar } from 'utils/gravatar';
import { PrivateRoute } from 'widgets/auth/private-route';
import { FullPageLoader } from 'widgets/full-page-loader';
import { connect } from 'redux-scaffolding-ts';
import NotFoundView from './not-found-view';
import EventsNRequestsConfigurationHomePage from './pages/configuration/events-n-requests/events-n-requests-configuration-home';
import EventTypesListPage from './pages/configuration/events-workflow/event-types-list';
import LocationsConfigurationHomePage from './pages/configuration/locations/locations-configuration-home';
import MachineryConfigurationHomePage from './pages/configuration/machinery/machinery-configuration-home';
import NonMachineRelatedConfigurationHomePage from './pages/configuration/non-machinery/non-machine-related-configuration-home';
import SFMappingHomePage from './pages/configuration/sf-mappings/sf-mappings-home';
import PerdiemsConfigurationHomePage from './pages/configuration/perdiems/perdiems-configuration-home';
import ProfilesConfigurationHomePage from './pages/configuration/profiles/profiles-configuration-home';
import RestrictionWarningConfigurationHomePage from './pages/configuration/planner-assistant/restrictions-warnings/restrictions-warnings-configuration-home';
import UsefulLinksConfigurationHomePage from './pages/configuration/useful-links/useful-links-configuration-home';
import SupportCostsConfigurationHomePage from './pages/configuration/support-costs/support-costs-configuration-home';
import OverallRecommendationsConfigurationHomePage from './pages/configuration/overall-recommendations/overall-recommendations-configuration-home';
import EventsHomePage from './pages/events/events-home-page';
import LocationsHomePage from './pages/locations-management/locations-home-page';
import TnaHomePage from './pages/assessments/tna/tna-configuration-home';
import AssessorsTnaHomePage from './pages/assessments/tna/assessors-tna-home';
import AssessorsSsaHomePage from './pages/assessments/ssa/assessors-ssa-home';
import AssessorsIwsHomePage from './pages/assessments/iws/assessors-iws-home';
import SsaHomePage from './pages/assessments/ssa/ssa-configuration-home';
import ChangeTnaFormView from './pages/assessments/tna/forms/tna-edit-form';
import RequestsListPage from './pages/requests/requests-list';
import UsersHomePage from './pages/users/users-home-page';
import profileDefinitionPage from './pages/profile/profile-definition-page/profile-definition';
import ImportExportHomePage from './pages/users/import-export-home-page';
import { UserStore, UserDto } from 'stores/users/users-store';
import { isNullOrWhiteSpaces, customEqualCompare } from 'utils/useful-functions';
import SchedulerPage from './pages/landing-pages/scheduler-page';
import Notifications from './notifications/notifications';
import { Query } from 'stores/dataStore';
import * as autobind from 'autobind';
import { UserNotificationsStore, UnreadNotificationCountStore } from '../stores/events/event-notifications-store';
import { UserTodoStore, UndoneToDoCountStore } from '../stores/events/event-todo-store';
import EmployeeViewPage from './pages/landing-pages/employee-page/employee-page';
import ProfileAssigmentHome from './pages/profile/profile-assignment/profile-assigment-home';
import EvaluationsHomePage from './pages/evaluations/evaluations-home-page';
import TnaTemplatesWizardComponent from './pages/assessments/tna/templates/wizard/tna-templates-wizard-component';
import CreateTnaFormWizard from './pages/assessments/tna/forms/wizard/create-tna-form-wizard';
import CreateSsaFormWizard from './pages/assessments/ssa/forms/wizard/create-ssa-form-wizard';
import SsaFormEditionWizard from './pages/assessments/ssa/forms/wizard/edit-ssa-form-wizard';
import SsaTemplatesWizardComponent from './pages/assessments/ssa/templates/wizard/ssa-templates-wizard-component';
import FeedbacksHome from './pages/assessments/feedbacks/feedbacks-home';
import ChangeSsaFormView from './pages/assessments/ssa/forms/ssa-edit-form';
import TnaGlobalDevelopmentPlanPage from './pages/development-plan/global/tna/tna-global-development-plan-page';
import IndividualDevelopmentPlanPage from './pages/development-plan/employee/employee-development-plans-page';
import SsaGlobalDevelopmentPlanPage from './pages/development-plan/global/ssa/ssa-global-development-plan-page';
import TheoreticalTemplateWizard from './pages/assessments/theoreticals/templates/wizard/theoretical-template-wizard';
import TheoreticalTestHome from './pages/assessments/theoretical-test/theoretical-test-home';
import FeedbackForm from './pages/assessments/feedbacks/forms/feedback-form';
import NewTnaTheoreticalTest from './pages/assessments/theoretical-test/tna-theoretical-tests/new-tna-theoretical-test';
import TheoreticalFormTest from './pages/theoretical-form-test/theoretical-form-test';
import AdminReportsLandingPage from './pages/landing-pages/admin-reports/admin-reports-landing-page';
import FeedbacksTemplatesWizardComponent from './pages/assessments/feedbacks/templates/wizard/feedbacks-templates-wizard.component';
import PracticalTestHome from './pages/assessments/practical-test/practical-test-home';
import PracticalTemplateWizard from './pages/assessments/practical-test/templates/wizard/practical-template-wizard';
import PracticalFormTest from './pages/assessments/practical-test/form/form-test/containers/practical-form-test';
import InstructorViewPage from './pages/landing-pages/instructor-page/instructor-page';
import IwsHomePage from './pages/assessments/iws/iws-configuration-home';
import IwsTemplatesWizardComponent from './pages/assessments/iws/templates/wizard/iws-templates-wizard-component';
import CreateIwsFormWizard from './pages/assessments/iws/forms/wizard/create-iws-form-wizard';
import ChangeIwsFormView from './pages/assessments/iws/forms/iws-edit-form';
import InstructorVisaRestrictionConfigurationHomePage from './pages/configuration/planner-assistant/instructor-visa-restriction/instructor-visa-restriction-configuration-home';

export interface HomePageProps extends WithTranslation, RouteComponentProps {
  currentUser: UserStore;
  toggleNotification: (any) => void;
  notificationStore?: UserNotificationsStore;
  unreadNotificationsStore?: UnreadNotificationCountStore;
  UserTodoStore?: UserTodoStore;
  undoneToDoCountStore?: UndoneToDoCountStore;
  containerToTakeHeight?: any;

  loading: boolean;
  menuExpanded: boolean;
  activeItem: string;
  menuOpened: boolean;
  user: UserDto;
  showNotifications: boolean;
}

export interface HomePageState {
  loading: boolean;
  menuExpanded: boolean;
  activeItem: string;
  menuOpened: boolean;
  secondLevelMenuOpened: boolean;
  user: UserDto;
  file: any;
  showNotifications: boolean;
  query: Query;
}

const notShowingLateralMenu = ['/employee-page/', '/theoretical-test/', '/practical-test/', '/assessments/feedbacks/form/'];

const notExpandedLateralMenu = [
  '/assessments/tna/tna-edit-form/',
  '/assessments/ssa/ssa-edit-form/',
  '/assessments/iws/iws-edit-form/',
  '/employee-page/',
  '/theoretical-test/',
  '/practical-test/',
  '/assessments/feedbacks/form/'
];

@connect(
  ['currentUser', UserStore],
  ['notificationStore', UserNotificationsStore],
  ['unreadNotificationsStore', UnreadNotificationCountStore],
  ['UserTodoStore', UserTodoStore],
  ['undoneToDoCountStore', UndoneToDoCountStore]
)
class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      menuExpanded: true,
      activeItem: '',
      menuOpened: false,
      secondLevelMenuOpened: false,
      user: null,
      file: null,
      showNotifications: false,
      query: {
        searchQuery: '',
        orderBy: [],
        skip: 0,
        take: 10
      }
    };

    this.focusinHandler = this.focusinHandler.bind(this);
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  focusinHandler(e) {
    const keycode = e.keyCode;
    const sidebarItems = document.querySelectorAll('.sidebar-menu');
    const sidebarItemsFocused = Array.from(sidebarItems).filter((item: HTMLElement) => item === document.activeElement);
    const anySidebarItemFocused = Array.from(sidebarItemsFocused).some((item: HTMLElement) => item === document.activeElement);

    if (keycode === 0 || keycode === 9) {
      e.preventDefault();

      if (anySidebarItemFocused) {
        const itemFocused = sidebarItemsFocused.firstOrDefault();
        const itemFocusedIndex = Array.from(sidebarItems).indexOf(itemFocused);
        if (itemFocusedIndex !== -1 && itemFocusedIndex < sidebarItems.length - 1) {
          const nextItem = sidebarItems[itemFocusedIndex + 1] as HTMLElement;
          nextItem.focus();
        } else {
          const nextItem = sidebarItems[0] as HTMLElement;
          nextItem.focus();
        }
      } else {
        const firstSidebarItem = document.querySelector('.sidebar-menu');
        if (firstSidebarItem instanceof HTMLElement) {
          firstSidebarItem.focus();
        }
      }
    }
  }

  private isAssessor = (): boolean => {
    const user = this.identityService.getUserInfo();
    if (user != null && user.isAssessor && !this.identityService.isInRole(Roles.Admin)) return true;
    else return false;
  };

  private isPowerInstructor = (): boolean => {
    const user = this.identityService.getUserInfo();
    return IdentityService.isPowerInstructor(user);
  };

  private getRoleItemTitle = (r: string) => {
    if (r === Roles[Roles.Instructor] && this.isPowerInstructor()) return 'Power Instructor';
    else if (r === Roles[Roles.Planner]) return 'Planner TFT';
    return r;
  };

  private canSeeRequests = (): boolean => {
    const currentUserInfo = this.identityService.getUserInfo();
    return (
      IdentityService.isAdminPocPlannerOrInstructor(currentUserInfo) ||
      IdentityService.isReportingOrFactoryLeadOrGlobalEngineeringOrManufacturingOrVP(currentUserInfo)
    );
  };

  private generateUserDto = () => {
    const currentUserInfo = this.identityService.getUserInfo();

    return {
      id: currentUserInfo?.userId,
      firstName: currentUserInfo?.firstName,
      lastName: currentUserInfo?.lastName,
      userName: currentUserInfo?.userName,
      email: currentUserInfo?.email,
      roles: currentUserInfo?.roles.map(r => ({ role: { name: r }, location: null })),
      acronym: currentUserInfo?.acronym
    } as UserDto;
  };

  componentDidMount() {
    const { menuExpanded } = this.state;
    const { location } = this.props;

    this.setState({ loading: true });
    this.identityService.loadUser().then(() => {
      if (!isNullOrWhiteSpaces(this.identityService.userId)) {
        this.props.currentUser
          .getUserById(this.identityService.userId)
          .then(item => {
            if (isNullOrWhiteSpaces(item.acronym)) item.acronym = this.identityService.getUserInfo().acronym;
            this.identityService.setCurrentUserDto(item);
            this.setState({
              user: item,
              loading: false
            });
          })
          .catch(_ => {
            const generatedItem = this.generateUserDto();
            this.identityService.setCurrentUserDto(generatedItem);
            this.setState({
              user: generatedItem,
              loading: false
            });
          });
      } else {
        this.props.history.replace('/unauthorized');
      }
    });

    const url = this.props.location.pathname;
    let active = '';

    if (url === '/') {
      active = 'home';
    } else {
      const split = url.slice(1).split('/');

      if (split.length === 1) {
        active = split[0];
      } else {
        active = `${split[0]}/${split[1]}`;
      }
    }

    this.identityService.userManager.getUser().then(user => {});

    notExpandedLateralMenu.forEach(partialPath => {
      if (location.pathname.includes(partialPath)) {
        this.setState({ menuExpanded: !menuExpanded });
      }
    });

    this.setState({ activeItem: active });
    this.load();

    document.addEventListener('keydown', this.focusinHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.focusinHandler, false);
  }

  componentDidUpdate(prev) {
    const { activeItem } = this.state;
    const url = this.props.location.pathname;
    if (customEqualCompare(url, prev.location.pathname)) return;

    let active = '';

    if (url === '/') {
      active = 'home';
    } else {
      const split = url.slice(1).split('/');

      if (split.length === 1) {
        active = split[0];
      } else {
        active = `${split[0]}/${split[1]}`;
      }
    }

    if (!customEqualCompare(activeItem, active)) this.setState({ activeItem: active });
  }

  @autobind
  private load() {
    this.props.unreadNotificationsStore.init();
    this.props.undoneToDoCountStore.init();
  }

  static getDerivedStateFromProps(next: HomePageProps, currentState: HomePageState) {
    const { location } = next;
    const { activeItem } = currentState;
    if ((location.pathname === '/' || location.pathname === '') && activeItem !== 'home') {
      return {
        ...currentState,
        activeItem: 'home'
      };
    }
    return null;
  }

  logout = () => {
    this.props.history.replace('/logout');
  };

  expandSecondLevelMenu = () => {
    this.setState({
      secondLevelMenuOpened: true
    });
  };

  handleItemClick = (e, { name }) => {
    e.preventDefault();
    switch (name) {
      case 'home':
        this.props.history.replace('/');
        this.setState({ activeItem: name, menuOpened: false, secondLevelMenuOpened: false });
        break;
      default:
        this.props.history.push(`/${name}`);
        this.setState({ activeItem: name, menuOpened: false, secondLevelMenuOpened: false });
        break;
    }
  };

  //NOTIFICATIONS TO EXPORT
  handleBellNotificationsClick = () => {
    this.setState({
      showNotifications: !this.state.showNotifications
    });
  };

  private menuWithSubmenuClick = (e, { name }) => {
    if (e.target.className === '' && !this.state.activeItem.startsWith(name)) {
      this.setState({ activeItem: '', menuOpened: true });
    }
  };

  private changeRole = (e, { name }) => {
    this.identityService.changeActiveRole(Roles[(name as string).replace(/ +/g, '')]);
    this.setState({ activeItem: 'home', menuOpened: false });
    this.props.history.replace('/reload');
    setTimeout(() => {
      this.props.history.replace('/');
    });
  };

  private getLocationsList() {
    const currentUserInfo = this.identityService.getUserInfo();

    if (this.state.user.roles == null) return null;

    const result = this.state.user.roles
      .filter(({ role, location, region }) => role.name === currentUserInfo?.activeRole && (location?.active || region))
      .map(res => {
        var id: string;
        var countryName: string;
        var locationorRegionName: string;

        if (res.location) {
          id = res.location.id;
          countryName = res.location.countryName;
          locationorRegionName = res.location.location;
        } else {
          id = res.region.id;
          countryName = 'Region';
          locationorRegionName = res.region.name;
        }
        return <div key={id} className={'role-location-list'}>{`${countryName} - ${locationorRegionName}`}</div>;
      });

    return result;
  }

  private getRoleColor(r: string) {
    const response = this.state.user.roles.find(item => {
      return item.role.name === r;
    });
    return response != null && response.role != null && response.role.color ? response.role.color : '#EAAA00';
  }

  private isAdminPocPlannerOrInstructorOrAssessor = (): boolean => {
    const currentUserInfo = this.identityService.getUserInfo();
    return IdentityService.isAdminPocPlannerOrInstructor(currentUserInfo) || this.isAssessor();
  };

  render() {
    if (this.state.loading) {
      return <FullPageLoader text={'Loading'} />;
    }

    const { activeItem, menuExpanded, user } = this.state;

    const { t, location, history, match: m, unreadNotificationsStore, undoneToDoCountStore } = this.props;

    const match = m.url;

    const currentUserInfo = this.identityService.getUserInfo();
    const isAdminPocOrPlanner = IdentityService.isAdminPocOrPlanner(currentUserInfo);
    const isAdminOrPocOrWorker = IdentityService.isAdminOrPocOrWorker(currentUserInfo);
    const isInstructor = IdentityService.isInstructor(currentUserInfo);
    const isAdmin = IdentityService.isAdmin(currentUserInfo);
    const isPlannerTFT = IdentityService.isPlannerTFT(currentUserInfo);
    const isWorker = IdentityService.isWorker(currentUserInfo);
    const isAdminOrPoc = IdentityService.isAdminOrPoc(currentUserInfo);
    const isAdminPocPlannerOrInstructor = IdentityService.isAdminPocPlannerOrInstructor(currentUserInfo);
    const isReportingOrFactoryLeadOrGlobalEngineeringOrManufacturingOrVP = IdentityService.isReportingOrFactoryLeadOrGlobalEngineeringOrManufacturingOrVP(
      currentUserInfo
    );
    const isAssessor = this.isAssessor();
    const isPocInstructorOrWorker = IdentityService.isWorkerPoCOrInstructor(currentUserInfo);
    const isPlanner = IdentityService.isPlanner(currentUserInfo);
    let mainContainerClass = 'planit-main-container';
    if (menuExpanded) {
      if (document.querySelector('.planit-main-container')) document.querySelector('.planit-main-container').classList.remove('expanded');
    } else {
      if (document.querySelector('.planit-main-container')) document.querySelector('.planit-main-container').classList.add('expanded');
    }

    let renderMenu = true;

    notShowingLateralMenu.forEach(partialPath => {
      if (location.pathname.includes(partialPath)) renderMenu = false;
    });

    const s = { height: !renderMenu ? '70%' : 'unset' };
    const isExpanded = menuExpanded ? '' : 'shrunk';
    const userDto = this.identityService.getCurrentUserDto();

    const photoSrc =
      userDto.userPhoto && userDto.userPhoto.hasPhoto
        ? 'data:image/png;base64, ' + userDto.userPhoto.photo
        : getGravatar(this.identityService.email, 80, 'identicon');

    return (
      <React.Fragment>
        <Menu size={'huge'} id="planit-left-menu" className={isExpanded} vertical inverted fixed="left">
          <Menu.Item id="jti-white-container" style={s} className={isExpanded} disabled onClick={this.handleItemClick}>
            <img alt="JTI" id="jti-white" src={jtiWhite} /> {/*<span><small>Planit v0.0.1</small></span>*/}
          </Menu.Item>
          {renderMenu && (
            <>
              <Dropdown
                tabIndex={0}
                onClick={this.menuWithSubmenuClick}
                id="avatar-container"
                className={`sidebar-menu link item ${isExpanded}`}
                trigger={
                  <>
                    <Popup
                      trigger={<Image id="user-avatar" src={photoSrc} circular alt="avatar" avatar />}
                      position="top left"
                      content={this.identityService.userName}
                      disabled={menuExpanded}
                    />
                    <span className="planit__user-name">{menuExpanded && this.identityService.userName}</span>
                  </>
                }
              >
                <Dropdown.Menu className="role-switch__menu">
                  <Dropdown.Header content={`${currentUserInfo?.firstName} ${currentUserInfo?.lastName}`} />
                  <Dropdown.Header className="role-switch__menu__locations" content={<div>{this.getLocationsList()}</div>} />

                  <Dropdown.Divider />
                  {currentUserInfo?.roles.map(
                    role =>
                      role && (
                        <Dropdown.Item
                          className={currentUserInfo?.activeRole === role ? 'user-item__active' : ''}
                          key={role}
                          name={role}
                          onClick={currentUserInfo?.activeRole === role ? undefined : this.changeRole}
                          tabIndex="0"
                        >
                          <Fragment>
                            <Label circular style={{ backgroundColor: this.getRoleColor(role) }}>
                              {user.acronym || 'UN'}
                            </Label>
                            {currentUserInfo?.activeRole === role} {this.getRoleItemTitle(role)}
                          </Fragment>
                        </Dropdown.Item>
                      )
                  )}
                  <Dropdown.Divider id="role-switch__menu__logout-divider" />
                  <Dropdown.Item onClick={this.logout}>{t('Logout')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Item
                name="home"
                active={activeItem === 'home'}
                onClick={this.handleItemClick}
                tabIndex={0}
                className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('home') ? 'active' : ''}`}
              >
                <span>
                  <Popup trigger={<Icon name="home" />} position="top left" content={t('Home')} disabled={menuExpanded} />
                  {menuExpanded && t('Home')}
                </span>
              </Menu.Item>

              {isAdmin && (
                <Menu.Item
                  name="calendar"
                  active={activeItem === 'calendar'}
                  onClick={this.handleItemClick}
                  tabIndex={0}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('calendar') ? 'active' : ''}`}
                >
                  <span>
                    <Popup trigger={<Icon name="calendar" />} position="top left" content={t('Calendar')} disabled={menuExpanded} />
                    {menuExpanded && t('Calendar')}
                  </span>
                </Menu.Item>
              )}

              {isAdminPocPlannerOrInstructor && (
                <>
                  {isAdminOrPoc && (
                    <Dropdown
                      tabIndex={0}
                      name="users"
                      onClick={this.menuWithSubmenuClick}
                      className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('users') ? 'active' : ''}`}
                      trigger={
                        <span>
                          <Popup trigger={<Icon name="users" />} position="top left" content={t('Users')} disabled={menuExpanded} />
                          {menuExpanded && t('Users')}
                        </span>
                      }
                    >
                      <Dropdown.Menu className="menu__collapsable-dropdown">
                        <Dropdown.Item
                          name="users/users"
                          className={activeItem === 'users/users' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Users')}
                        </Dropdown.Item>

                        {isAdmin && (
                          <Dropdown.Item
                            name="users/import-export-log"
                            className={activeItem === 'users/import-export-log' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Import/Export Log')}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {this.isPowerInstructor() && (
                    <Menu.Item
                      name="users/users"
                      active={activeItem === 'users/users'}
                      onClick={this.handleItemClick}
                      tabIndex={0}
                      className={`sidebar-menu sidebar-menu left-menu-item link item ${isExpanded} ${
                        activeItem.startsWith('users/users') ? 'active' : ''
                      }`}
                    >
                      <span>
                        <Popup trigger={<Icon name="users" />} position="top left" content={t('Users')} disabled={menuExpanded} />
                        {menuExpanded && t('Instructors')}
                      </span>
                    </Menu.Item>
                  )}

                  {this.isPowerInstructor() && (
                    <Menu.Item
                      name="users/expertises"
                      active={activeItem === 'users/expertises'}
                      onClick={this.handleItemClick}
                      tabIndex={0}
                      className={`sidebar-menu left-menu-item link item ${isExpanded} ${
                        activeItem.startsWith('users/expertises') ? 'active' : ''
                      }`}
                    >
                      <span>
                        <Popup trigger={<Icon name="star" />} position="top left" content={t('Expertise')} disabled={menuExpanded} />
                        {menuExpanded && t('Expertise')}
                      </span>
                    </Menu.Item>
                  )}

                  {isInstructor && !this.isPowerInstructor() && (
                    <Dropdown
                      tabIndex={0}
                      name="users"
                      onClick={this.menuWithSubmenuClick}
                      className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('users') ? 'active' : ''}`}
                      trigger={
                        <span>
                          <Popup trigger={<Icon name="star" />} position="top left" content={t('Expertise')} disabled={menuExpanded} />
                          {menuExpanded && t('Expertise')}
                        </span>
                      }
                    >
                      <Dropdown.Menu className="menu__collapsable-dropdown">
                        <Dropdown.Item
                          name="users/expertises"
                          className={activeItem === 'users/expertises' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Instructor Card')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          name="users/users"
                          className={activeItem === 'users/users' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Requested Expertise')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {isPlanner && (
                    <Dropdown
                      tabIndex={0}
                      name="users/instructors-expertise"
                      onClick={this.menuWithSubmenuClick}
                      className={`sidebar-menu left-menu-item link item ${isExpanded} ${
                        activeItem.startsWith('users/instructors-expertise') ? 'active' : ''
                      }`}
                      trigger={
                        <span>
                          <Popup trigger={<Icon name="star" />} position="top left" content={t('Expertise')} disabled={menuExpanded} />
                          {menuExpanded && t('Expertise')}
                        </span>
                      }
                    >
                      <Dropdown.Menu className="menu__collapsable-dropdown">
                        <Dropdown.Item
                          name="users/instructors-expertises"
                          className={activeItem === 'users/instructors-expertises' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Instructor´s Expertise')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {isAdminPocPlannerOrInstructor && (
                    <Dropdown
                      tabIndex={0}
                      name="profile-assignment"
                      onClick={this.menuWithSubmenuClick}
                      className={`sidebar-menu left-menu-item link item ${isExpanded} ${
                        activeItem.startsWith('profile-assignment') ? 'active' : ''
                      }`}
                      trigger={
                        <span>
                          <Popup
                            trigger={<Icon name="address card" />}
                            position="top left"
                            content={t('Profiles')}
                            disabled={menuExpanded}
                          />
                          {menuExpanded && t('Profiles')}
                        </span>
                      }
                    >
                      <Dropdown.Menu className="menu__collapsable-dropdown">
                        {isAdmin && (
                          <Dropdown.Item
                            name="profile-assignment/configuration"
                            className={activeItem === 'profile-assignment/configuration' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Profile Definition')}
                          </Dropdown.Item>
                        )}
                        {(isAdminOrPoc || this.isPowerInstructor()) && (
                          <Dropdown.Item
                            name="profile-assignment/employee-profiles"
                            className={activeItem === 'profile-assignment/employee-profiles' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Employee Profile Assignment')}
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          name="profile-assignment/evaluations"
                          className={activeItem === 'profile-assignment/evaluations' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Evaluations')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              )}

              {isAdminPocOrPlanner && (
                <Menu.Item
                  name="locations"
                  active={activeItem === 'locations'}
                  onClick={this.handleItemClick}
                  tabIndex={0}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('locations') ? 'active' : ''}`}
                >
                  <span>
                    <Popup trigger={<Icon name="factory" />} position="top left" content={t('Locations')} disabled={menuExpanded} />
                    {menuExpanded && t('Locations')}
                  </span>
                </Menu.Item>
              )}

              <Menu.Item
                name="events"
                active={activeItem === 'events'}
                onClick={this.handleItemClick}
                tabIndex={0}
                className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('events') ? 'active' : ''}`}
              >
                <span>
                  <Popup trigger={<Icon name="calendar check" />} position="top left" content={t('Events')} disabled={menuExpanded} />
                  {menuExpanded && t('Events')}
                </span>
              </Menu.Item>

              {(isAdminPocOrPlanner || isInstructor || isReportingOrFactoryLeadOrGlobalEngineeringOrManufacturingOrVP) && (
                <Menu.Item
                  name="requests"
                  active={activeItem === 'requests'}
                  onClick={this.handleItemClick}
                  tabIndex={0}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('requests') ? 'active' : ''}`}
                >
                  <span>
                    <Popup trigger={<Icon name="file alternate" />} position="top left" content={t('Requests')} disabled={menuExpanded} />
                    {menuExpanded && t('Requests')}
                  </span>
                </Menu.Item>
              )}

              {(this.identityService.isInAnyRole([
                Roles.Admin,
                Roles.Planner,
                Roles.PlannerMTC,
                Roles.PoC,
                Roles.FactoryLead,
                Roles.Employee
              ]) ||
                this.isPowerInstructor()) && (
                <Dropdown
                  tabIndex={0}
                  name="assessments"
                  onClick={this.menuWithSubmenuClick}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('assessments') ? 'active' : ''}`}
                  trigger={
                    <span>
                      <Popup
                        trigger={
                          <span id="assessments-composite-icon" className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faFile} />
                            <FontAwesomeIcon inverse icon={faTasks} transform="shrink-6" />
                          </span>
                        }
                        position="top left"
                        content={t('Assessments')}
                        disabled={menuExpanded}
                      />
                      {menuExpanded && <span>{t('Assessments')}</span>}
                    </span>
                  }
                >
                  <Dropdown.Menu className="menu__collapsable-dropdown">
                    {(this.identityService.isInAnyRole([Roles.Admin, Roles.PoC, Roles.Employee]) || this.isPowerInstructor()) && (
                      <>
                        <Dropdown.Item
                          name="assessments/tna"
                          className={activeItem === 'assessments/tna' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('TNA')}
                        </Dropdown.Item>
                        {(this.identityService.isInAnyRole([Roles.Admin, Roles.PoC]) || this.isPowerInstructor()) && (
                          <Dropdown.Item
                            name="assessments/ssa"
                            className={activeItem === 'assessments/ssa' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('SSA')}
                          </Dropdown.Item>
                        )}
                        {(this.identityService.isInAnyRole([Roles.Admin, Roles.PoC, Roles.Employee]) || this.isPowerInstructor()) && (
                          <Dropdown.Item
                            name="assessments/iws"
                            className={activeItem === 'assessments/iws' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('IWS')}
                          </Dropdown.Item>
                        )}
                        {(isAdminOrPoc || this.isPowerInstructor()) && (
                          <Dropdown.Item
                            name="assessments/theoretical-test"
                            className={activeItem === 'assessments/theoretical-test' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Theoretical Test')}
                          </Dropdown.Item>
                        )}
                        {(this.isPowerInstructor() || isAdminOrPoc) && (
                          //INTRODUCE POWER INSTRUCTOR
                          <Dropdown.Item
                            name="assessments/practical-test"
                            className={activeItem === 'assessments/practical-test' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Practical Test')}
                          </Dropdown.Item>
                        )}

                        {(isAdmin || this.isPowerInstructor()) && (
                          <Dropdown.Item
                            name="assessments/feedbacks"
                            className={activeItem === 'assessments/feedbacks' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            tabIndex="0"
                          >
                            {t('Feedbacks')}
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                    {this.identityService.isInRole(Roles.Employee) && (
                      <Dropdown.Item
                        name="assessments/development-plan"
                        className={activeItem === 'assessments/development-plan' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        content={t('Development Plan')}
                        tabIndex="0"
                      />
                    )}
                    {this.identityService.isInAnyRole([Roles.Admin, Roles.Planner, Roles.PlannerMTC, Roles.PoC, Roles.FactoryLead]) && (
                      <Dropdown
                        tabIndex={-1}
                        item
                        icon={<Icon className="clickable-icon" name={'angle right'} size="large" />}
                        text={t('Development Plan')}
                        direction="right"
                        className={`left-menu-item secondary-menu link item ${isExpanded} ${
                          activeItem.indexOf('assessments/development-plan') >= 0 ? 'active' : ''
                        }`}
                      >
                        <Dropdown.Menu className="menu__collapsable-dropdown">
                          <Dropdown.Item
                            name="assessments/development-plan/tna"
                            className={activeItem === 'assessments/development-plan/tna' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            content={t('TNA Development Plan')}
                            tabIndex="0"
                          />
                          <Dropdown.Item
                            name="assessments/development-plan/ssa"
                            className={activeItem === 'assessments/development-plan/ssa' ? 'active' : ''}
                            onClick={this.handleItemClick}
                            content={t('SSA Development Plan')}
                            tabIndex="0"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {isWorker && isAssessor && (
                <Dropdown
                  tabIndex={0}
                  name="evaluations"
                  onClick={this.menuWithSubmenuClick}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('evaluations') ? 'active' : ''}`}
                  trigger={
                    <span>
                      <Popup
                        trigger={<Icon name="file alternate" />}
                        position="top left"
                        content={t('Evaluations')}
                        disabled={menuExpanded}
                      />
                      {menuExpanded && <span>{t('Evaluations')}</span>}
                    </span>
                  }
                >
                  <Dropdown.Menu className="menu__collapsable-dropdown">
                    <Dropdown.Item
                      name="evaluations/tna"
                      className={activeItem === 'evaluations/tna' ? 'active' : ''}
                      onClick={this.handleItemClick}
                      tabIndex="0"
                    >
                      {t('TNA')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="evaluations/ssa"
                      className={activeItem === 'evaluations/ssa' ? 'active' : ''}
                      onClick={this.handleItemClick}
                      tabIndex="0"
                    >
                      {t('SSA')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="evaluations/iws"
                      className={activeItem === 'evaluations/iws' ? 'active' : ''}
                      onClick={this.handleItemClick}
                      tabIndex="0"
                    >
                      {t('IWS')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {(isAdmin || isPlannerTFT) && (
                <Dropdown
                  tabIndex={0}
                  name="planner-assistant"
                  onClick={this.menuWithSubmenuClick}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${
                    activeItem.startsWith('planner-assistant') ? 'active' : ''
                  }`}
                  trigger={
                    <span>
                      <Popup
                        trigger={<Icon name="superpowers" />}
                        position="top left"
                        content={t('Planner Assistant')}
                        disabled={menuExpanded}
                      />
                      {menuExpanded && t('Planner Assistant')}
                    </span>
                  }
                >
                  <Dropdown.Menu className="menu__collapsable-dropdown">
                    {(isAdmin || isPlannerTFT) && (
                      <>
                        <Dropdown.Item
                          name="planner-assistant/restrictions-warnings"
                          className={activeItem === 'planner-assistant/restrictions-warnings' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Restrictions & Warnings')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          name="planner-assistant/instructor-visa-restriction"
                          className={activeItem === 'planner-assistant/instructor-visa-restriction' ? 'active' : ''}
                          onClick={this.handleItemClick}
                          tabIndex="0"
                        >
                          {t('Master Data')}
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {isAdmin && (
                <Dropdown
                  tabIndex={0}
                  name="configuration"
                  onClick={this.menuWithSubmenuClick}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${
                    activeItem.startsWith('configuration') ? 'active' : ''
                  }`}
                  trigger={
                    <span>
                      <Popup trigger={<Icon name="cogs" />} position="top left" content={t('Configuration')} disabled={menuExpanded} />
                      {menuExpanded && t('Configuration')}
                    </span>
                  }
                >
                  <Dropdown.Menu>
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/machinery"
                        className={activeItem === 'configuration/machinery' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Machine Related')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/non-machinery"
                        className={activeItem === 'configuration/non-machinery' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Non-Machine Related')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/locations"
                        className={activeItem === 'configuration/locations' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Locations')}
                      </Dropdown.Item>
                    )}

                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/profiles"
                        className={activeItem === 'configuration/profiles' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Profiles')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/events-workflow"
                        className={activeItem === 'configuration/events-workflow' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t("Events' Workflow")}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/events-n-requests"
                        className={activeItem === 'configuration/events-n-requests' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Events & Requests')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/sf-mappings"
                        className={activeItem === 'configuration/sf-mappings' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('SF Mapping')}
                      </Dropdown.Item>
                    )}
                    {(isAdminOrPocOrWorker || isInstructor) && (
                      <Dropdown.Item
                        name="configuration/useful-links"
                        className={activeItem === 'configuration/useful-links' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Useful Links')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/perdiems"
                        className={activeItem === 'configuration/perdiems' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Per Diems')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/support-costs"
                        className={activeItem === 'configuration/support-costs' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Support Costs')}
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        name="configuration/overall-recommendations"
                        className={activeItem === 'configuration/overall-recommendations' ? 'active' : ''}
                        onClick={this.handleItemClick}
                        tabIndex="0"
                      >
                        {t('Overall Recommendations')}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {isPocInstructorOrWorker && (
                <Menu.Item
                  name="useful-links"
                  active={activeItem === 'useful-links'}
                  onClick={this.handleItemClick}
                  tabIndex={0}
                  className={`sidebar-menu left-menu-item link item ${isExpanded} ${activeItem.startsWith('useful-links') ? 'active' : ''}`}
                >
                  <span>
                    <Popup trigger={<Icon name="linkify" />} position="top left" content={t('Useful Links')} disabled={menuExpanded} />
                    {menuExpanded && t('Useful Links')}
                  </span>
                </Menu.Item>
              )}
              <Menu.Item className={`left-menu-item link item ${isExpanded}`}>
                <span>
                  <Popup trigger={<Icon name="anchor" />} position="top left" content={t('Training Portal')} disabled={menuExpanded} />
                  <a
                    tabIndex={0}
                    style={{ color: 'inherit' }}
                    href="https://jticorp.sharepoint.com/sites/Planit_Learning/site/index.html#ENG"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t('Training Portal')}
                    className="sidebar-menu"
                  >
                    {menuExpanded && t('Training Portal')}
                  </a>
                </span>
              </Menu.Item>
            </>
          )}
          <p
            tabIndex={0}
            className={`sidebar-menu left-menu-item link item ${isExpanded}`}
            id={`expand-menu-icon${isExpanded ? '' : '-expanded'}`}
          >
            <Icon
              className="clickable-icon"
              name={menuExpanded ? 'angle left' : 'angle right'}
              onClick={() => this.setState({ menuExpanded: !menuExpanded })}
            />
            <span onClick={() => this.setState({ menuExpanded: !menuExpanded })}>{!isExpanded && t('Collapse')}</span>
          </p>
          <Menu.Item disabled={true} name="" active={false} />
        </Menu>
        <div className={mainContainerClass}>
          <Grid id="main-container-grid">
            <Grid.Row id="notifications-row" textAlign="right">
              {this.state.showNotifications && (
                <Notifications history={history} location={location} match={m} toggleNotification={this.handleBellNotificationsClick} />
              )}
              <p>
                <Icon id="notification-icon" name="bell" circular inverted size="large" onClick={this.handleBellNotificationsClick} />
              </p>
              {unreadNotificationsStore.state.unread || undoneToDoCountStore.state.unread ? (
                <Label className="notifications__bell-alert" color="red" circular floating></Label>
              ) : null}
            </Grid.Row>
            <Grid.Row id="content-container">
              <Switch location={location}>
                <PrivateRoute
                  allowUser={IdentityService.isAdminPocOrInstructor}
                  exact
                  path={`${match}users/users`}
                  component={UsersHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdmin}
                  exact
                  path={`${match}calendar`}
                  component={SchedulerPage}
                  props={{ ...this.props }}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}users/import-export-log`}
                  component={ImportExportHomePage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}profile-assignment/configuration`}
                  component={profileDefinitionPage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}profile-assignment/employee-profiles`}
                  component={ProfileAssigmentHome}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminPocPlannerOrInstructor}
                  exact
                  path={`${match}profile-assignment/evaluations`}
                  component={EvaluationsHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminPocOrPlanner}
                  exact
                  path={`${match}locations`}
                  component={LocationsHomePage}
                />

                <PrivateRoute
                  allowUser={IdentityService.isInstructor}
                  exact
                  path={`${match}users/expertises`}
                  component={InstructorViewPage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isPlanner}
                  exact
                  path={`${match}users/instructors-expertises`}
                  component={UsersHomePage}
                />

                <PrivateRoute exact path={`${match}events/:id?`} component={EventsHomePage} />
                <PrivateRoute allowUser={this.canSeeRequests} exact path={`${match}requests/:id?`} component={RequestsListPage} />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna?tab=:tab?`}
                  component={TnaHomePage}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna/tna-edit-form/:id`}
                  component={ChangeTnaFormView}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna/template/:id?`}
                  component={TnaTemplatesWizardComponent}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna/form/create`}
                  component={CreateTnaFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna/form/edit-wizard/:id?`}
                  component={CreateTnaFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPoc}
                  exact
                  path={`${match}assessments/tna/form/:id?`}
                  component={CreateTnaFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna`}
                  component={TnaHomePage}
                />

                <PrivateRoute allowUser={this.isAssessor} exact path={`${match}evaluations/tna`} component={AssessorsTnaHomePage} />
                <PrivateRoute allowUser={this.isAssessor} exact path={`${match}evaluations/ssa`} component={AssessorsSsaHomePage} />
                <PrivateRoute allowUser={this.isAssessor} exact path={`${match}evaluations/iws`} component={AssessorsIwsHomePage} />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa`}
                  component={SsaHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws`}
                  component={IwsHomePage}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws?tab=:tab?`}
                  component={IwsHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/theoretical-test`}
                  component={TheoreticalTestHome}
                />
                <PrivateRoute exact path={`${match}assessments/practical-test/:id`} component={PracticalFormTest} />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/practical-test`}
                  component={PracticalTestHome}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/practical/template/:id?`}
                  component={PracticalTemplateWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/theoretical-test?tab=:tab?`}
                  component={TheoreticalTestHome}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/tna-theoretical-test/create`}
                  component={NewTnaTheoreticalTest}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/feedbacks`}
                  component={FeedbacksHome}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/feedbacks?tab=:tab?`}
                  component={FeedbacksHome}
                />

                <PrivateRoute exact path={`${match}assessments/feedbacks/form/:id`} component={FeedbackForm} />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa/ssa-edit-form/:id`}
                  component={ChangeSsaFormView}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa/form/create`}
                  component={CreateSsaFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa/form/edit-wizard/:id?`}
                  component={SsaFormEditionWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa/template/:id?`}
                  component={SsaTemplatesWizardComponent}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws/template/:id?`}
                  component={IwsTemplatesWizardComponent}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws/form/create`}
                  component={CreateIwsFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws/form/edit-wizard/:id?`}
                  component={CreateIwsFormWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/iws/iws-edit-form/:id`}
                  component={ChangeIwsFormView}
                />
                <PrivateRoute
                  allowedRoles={[Roles.Admin, Roles.Planner, Roles.PlannerMTC, Roles.PoC, Roles.Employee, Roles.Instructor]}
                  exact
                  path={`${match}assessments/theoretical-test/:id?`}
                  component={TheoreticalFormTest}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/feedbacks/template/:id?`}
                  component={FeedbacksTemplatesWizardComponent}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPocOrWorkerOrPowerInstructor}
                  exact
                  path={`${match}assessments/ssa?tab=:tab?`}
                  component={SsaHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/theoretical/template/create`}
                  component={TheoreticalTemplateWizard}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdminOrPowerInstructor}
                  exact
                  path={`${match}assessments/theoretical/template/:id?`}
                  component={TheoreticalTemplateWizard}
                />

                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/machinery`}
                  component={MachineryConfigurationHomePage}
                />

                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/machinery?tab=:tab?`}
                  component={MachineryConfigurationHomePage}
                />

                <PrivateRoute
                  allowedRoles={[Roles.Employee]}
                  exact
                  path={`${match}assessments/development-plan`}
                  component={IndividualDevelopmentPlanPage}
                />

                <PrivateRoute
                  allowedRoles={[Roles.Admin, Roles.Planner, Roles.PlannerMTC, Roles.PoC, Roles.FactoryLead]}
                  exact
                  path={`${match}assessments/development-plan/tna`}
                  component={TnaGlobalDevelopmentPlanPage}
                />

                <PrivateRoute
                  allowedRoles={[Roles.Admin, Roles.Planner, Roles.PlannerMTC, Roles.PoC, Roles.FactoryLead]}
                  exact
                  path={`${match}assessments/development-plan/ssa`}
                  component={SsaGlobalDevelopmentPlanPage}
                />

                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/non-machinery`}
                  component={NonMachineRelatedConfigurationHomePage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/locations`}
                  component={LocationsConfigurationHomePage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/profiles`}
                  component={ProfilesConfigurationHomePage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/events-workflow`}
                  component={EventTypesListPage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/events-n-requests`}
                  component={EventsNRequestsConfigurationHomePage}
                />

                <PrivateRoute
                  allowUser={IdentityService.isAdmin}
                  exact
                  path={`${match}configuration/useful-links`}
                  component={UsefulLinksConfigurationHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdmin}
                  exact
                  path={`${match}configuration/sf-mappings`}
                  component={SFMappingHomePage}
                />
                <PrivateRoute
                  userInRoleOnly={Roles.Admin}
                  exact
                  path={`${match}configuration/perdiems`}
                  component={PerdiemsConfigurationHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdmin}
                  exact
                  path={`${match}configuration/support-costs`}
                  component={SupportCostsConfigurationHomePage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdmin}
                  exact
                  path={`${match}configuration/overall-recommendations`}
                  component={OverallRecommendationsConfigurationHomePage}
                />
                <PrivateRoute
                  allowUser={this.isAdminPocPlannerOrInstructorOrAssessor}
                  exact
                  path={`${match}employee-page/:id?/:tnaFormId?`}
                  component={EmployeeViewPage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isAdminPocPlannerOrInstructor}
                  exact
                  path={`${match}employee-page/:id?`}
                  component={EmployeeViewPage}
                />
                <PrivateRoute
                  allowUser={IdentityService.isWorkerPoCOrInstructor}
                  exact
                  path={`${match}useful-links`}
                  component={UsefulLinksConfigurationHomePage}
                />
                <PrivateRoute
                  allowedRoles={[Roles.Admin, Roles.Planner]}
                  exact
                  path={`${match}planner-assistant/restrictions-warnings`}
                  component={RestrictionWarningConfigurationHomePage}
                />
                <PrivateRoute
                  allowedRoles={[Roles.Admin, Roles.Planner]}
                  exact
                  path={`${match}planner-assistant/instructor-visa-restriction`}
                  component={InstructorVisaRestrictionConfigurationHomePage}
                />
                <Route exact path={`${match}reload`} component={FullPageLoader} />
                <Route
                  exact
                  path={`${match}`}
                  render={props => {
                    if (this.identityService.isInRole(Roles.Admin)) return <AdminReportsLandingPage {...props} />;

                    if (!this.identityService.isInRole(Roles.Employee) && !this.identityService.isInRole(Roles.Admin))
                      return <SchedulerPage {...props} />;

                    if (this.identityService.isInRole(Roles.Employee)) return <EmployeeViewPage {...props} />;
                    return (
                      <>
                        <div id="planit-home-div">
                          <div id="img">
                            <img src={jtiIcon} alt="JTI" />
                          </div>
                          <div id="text">
                            <h1>Planit</h1>
                          </div>
                          <Divider />
                          <p id="subtitle">
                            {t('Welcome')} <strong>{this.identityService.userName}</strong>. {t('Please select an item in the left menu')}
                          </p>
                        </div>
                      </>
                    );
                  }}
                />
                <Route component={NotFoundView} />
              </Switch>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
// export
export default withTranslation()(HomePage);
