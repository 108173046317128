import { TnaTemplatesStore, ChangeTnaTemplateStore, NewTnaTemplateStore, DownloadTnaTemplatesStore } from './tna-templates-store';
import { SsaTemplatesStore, ChangeSsaTemplateStore, NewSsaTemplateStore } from './ssa-templates-store';
import { FeedbacksTemplatesListStore, FeedbacksTemplatesStore } from './feedbacks-templates-store';
import { TheoreticalTemplatesStore, ChangeTheoreticalTemplatesStore, NewTheoreticalTemplatesStore } from './theoretical-templates-store';
import { PracticalTemplatesStore, NewPracticalTemplatesStore, ChangePracticalTemplatesStore } from './practical-templates-store';
import { IwsTemplatesStore, ChangeIwsTemplateStore, NewIwsTemplateStore } from './iws-templates-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new TnaTemplatesStore());
  storeBuilder.addRepository(new ChangeTnaTemplateStore());
  storeBuilder.addRepository(new NewTnaTemplateStore());
  storeBuilder.addRepository(new SsaTemplatesStore());
  storeBuilder.addRepository(new ChangeSsaTemplateStore());
  storeBuilder.addRepository(new NewSsaTemplateStore());
  storeBuilder.addRepository(new FeedbacksTemplatesListStore());
  storeBuilder.addRepository(new FeedbacksTemplatesStore());
  storeBuilder.addRepository(new TheoreticalTemplatesStore());
  storeBuilder.addRepository(new NewTheoreticalTemplatesStore());
  storeBuilder.addRepository(new ChangeTheoreticalTemplatesStore());

  storeBuilder.addRepository(new PracticalTemplatesStore());
  storeBuilder.addRepository(new NewPracticalTemplatesStore());
  storeBuilder.addRepository(new ChangePracticalTemplatesStore());

  storeBuilder.addRepository(new DownloadTnaTemplatesStore());

  storeBuilder.addRepository(new IwsTemplatesStore());
  storeBuilder.addRepository(new ChangeIwsTemplateStore());
  storeBuilder.addRepository(new NewIwsTemplateStore());
}
