import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { MachineModelDto, MachineModelsStore } from '../../../stores/configuration/machinery/machine-models-store';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';
import { OrderDefinition } from 'stores/dataStore';

interface MachineModelsSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  machineModels?: MachineModelsStore;
  direction?: 'left' | 'right';
}

@connect(['machineModels', MachineModelsStore])
class MachineModelsSelector extends React.Component<MachineModelsSelectorProps> {
  state = { options: [], isLoading: true };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { machineModels } = this.props;
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<MachineModelDto>('name'), useProfile: false }];
    const response = await machineModels.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter: [`active eq true`] });
    this.setState({ isLoading: false, options: response.items.map(item => ({ value: item.id, text: item.name })) });
  };

  public render() {
    const { value, direction, readOnly, searchable, clearable, className, placeholder, onChange } = this.props;
    const { options, isLoading } = this.state;
    const selectedValue = value || null;

    return (
      <Dropdown
        direction={direction}
        selection
        disabled={readOnly}
        loading={isLoading}
        search={searchable}
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => onChange(value as string)}
        selectOnBlur={false}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MachineModelsSelector);
