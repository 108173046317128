import { WithTranslation, withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { YearInput } from '@planit/semantic-ui-calendar-react';

interface YearEditorProps extends WithTranslation {
  value?: number;
  onChange?: (value?: number) => void;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  readOnly?: boolean;
}

class YearEditor extends Component<YearEditorProps> {
  private handleOnValueChange = (value: string) => {
    if (!value) {
      this.props.onChange && this.props.onChange(null);
      return;
    }

    this.props.onChange && this.props.onChange(Number.parseInt(value));
  };

  render() {
    const { placeholder, className, readOnly, clearable } = this.props;
    const value = this.props.value ? this.props.value.toString() : '';

    return (
      <YearInput
        closable
        className={className}
        disabled={readOnly}
        icon={null}
        clearable={clearable}
        animation={null}
        placeholder={placeholder}
        value={value}
        onChange={(_, { value }) => this.handleOnValueChange(value)}
      />
    );
  }
}

export default withTranslation()(YearEditor);
