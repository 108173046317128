import * as React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface NumberInputProps {
  value: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  disallowFormatting?: boolean;
  readOnly?: boolean;
  allowNegative?: boolean;
  disabled?: boolean;
  suffix?: string;
  className?: string;
  decimalPlaces?: number;
}

interface NumberInputState {
  value: number;
  decimalSeparator: string;
  thousandSeparator: string;
}

export class NumberInput extends React.Component<NumberInputProps, NumberInputState> {
  decimalSeparator = '.';
  thousandSeparator = ',';
  decimalPlaces = 4;

  constructor(props: NumberInputProps) {
    super(props);
    // tslint:disable-next-line:variable-name
    let number = Number.parseFloat(`${props.value}`);
    number = Number.isNaN(number) ? 0 : number;

    const decimalSeparator = this.props.disallowFormatting ? '' : '.';
    const thousandSeparator = this.props.disallowFormatting ? '' : ',';
    this.state = {
      value: number,
      decimalSeparator,
      thousandSeparator
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NumberInputProps) {
    if (this.props !== nextProps && this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  private handleNumberFormatValue = (values: NumberFormatValues) => {
    if (this.props.onChange) {
      this.props.onChange(values.floatValue);
    }
  };

  public render() {
    return (
      <NumberFormat
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        allowNegative={!!this.props.allowNegative}
        decimalScale={this.props.decimalPlaces || this.decimalPlaces}
        value={this.props.value}
        thousandSeparator={this.thousandSeparator}
        decimalSeparator={this.decimalSeparator}
        onValueChange={this.handleNumberFormatValue}
        className={this.props.className}
        inputMode="numeric"
      />
    );
  }
}
