import i18n from 'i18n';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { DropdownProps } from 'widgets/form/selectionInput';

interface MachineRelatedEditorProps extends DropdownProps, WithTranslation {
  value: boolean;
  onChange: (value?: boolean) => void;
}

const getOptions = () => [
  { key: 'NonMachineRelated', text: i18n.t('Non Machine Related'), value: false },
  { key: 'MachineRelated', text: i18n.t('Machine Related'), value: true }
];

const MachineRelatedEditor: React.FC<MachineRelatedEditorProps> = ({ onChange, ...props }) => {
  const { placeholder, className, clearable, nullable, value, direction } = props;
  const handleOnSelection = (value: any) => {
    let propagatedValue = typeof value === 'boolean' ? value : null;
    onChange && onChange(propagatedValue);
  };

  return (
    <Dropdown
      direction={direction}
      placeholder={placeholder}
      className={!className ? 'user-dropdown' : className}
      clearable={clearable}
      nullable={nullable}
      selection
      options={getOptions()}
      value={typeof value === 'boolean' ? value : ''}
      onChange={(_, { value }) => handleOnSelection(value)}
    />
  );
};

export default withTranslation()(React.memo(MachineRelatedEditor));
