import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Categories } from 'stores/requests/requests-store';
import { Form, Button, Grid, Input, TextArea, Checkbox } from 'semantic-ui-react';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import PriorityEditor from 'widgets/bussiness/priority-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import {
  EventFieldGroups,
  EventTypesStore,
  EventTypeInstructorConfiguration,
  EventTypeCategory
} from 'stores/configuration/events-workflow/event-types-store';
import { RequestWizardStore, RequestWizardData } from 'stores/requests/create-request-wizard-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';
import './step1-basic.less';
import ReasonLinkedEventsPopup from './reason-linked-events-popup';

import { LocationsStore } from 'stores/configuration/locations/locations-store';
import { Query, ItemReference } from 'stores/dataStore';
import RequestReasonEditor from 'widgets/bussiness/request-reason-editor';
import { isExtendedWorkflow } from 'utils/event-type-utils';
import { RequestReasonDto } from 'stores/configuration/events-n-requests/request-reasons-store';

interface NewRequestViewProps extends WithTranslation {
  onRestartSteps: () => void;
  requestWizardStore?: RequestWizardStore;
  selectLocation: LocationsStore;
  selectedEventType?: EventTypesStore;
  onSelectEventTypeBegin?: () => void;
  onSelectEventTypeEnd?: () => void;
}

interface NewRequestViewState {}

interface NewRequestViewState {
  query: Query;
  selectedLocation: string;
  locationsRecived: string[];
  isMachineRelated: boolean;
  isRequestDetails: boolean;
  isEventDetails: boolean;
  isInstructorYes: boolean;
  selectedReasonRequest: boolean;
  reasonRequestEventTypes: string[];
  isOpenedUsersModal: boolean;
}

@connect(['selectedEventType', EventTypesStore], ['requestWizardStore', RequestWizardStore], ['selectLocation', LocationsStore])
class StepBasic extends Component<NewRequestViewProps, NewRequestViewState> {
  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  private get selectedEventType() {
    return this.props.selectedEventType;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);
    let query: Query = { searchQuery: '', orderBy: [], skip: 0, take: 1000, parameters: { $select: 'id' } };
    this.state = {
      query,
      selectedLocation: '',
      locationsRecived: [],
      isMachineRelated: false,
      isRequestDetails: false,
      isEventDetails: false,
      isInstructorYes: false,
      selectedReasonRequest: false,
      reasonRequestEventTypes: [],
      isOpenedUsersModal: false
    };
  }

  private handleOnOpenUserModal = () => {
    this.setState({ isOpenedUsersModal: true });
  };

  private handleOnCloseUserModal = () => {
    this.setState({ isOpenedUsersModal: false });
  };

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const currentUserInfo = this.identityService.getUserInfo();

    if (IdentityService.isRoleWithLocations(currentUserInfo)) {
      const rolesLocations = currentUserInfo.locationsByRoles[currentUserInfo.activeRole] as string[];
      if (this.identityService.hasRoleMultipleLocations(currentUserInfo)) {
        this.setState({ locationsRecived: rolesLocations });
      } else {
        this.props.selectLocation.getUserById(rolesLocations[0]).then(({ location, id }) => {
          this.setState({ selectedLocation: location }, () => this.requestWizardStore.change({ requestingLocationId: id }));
          this.changeLocationEvent({ id: id, title: location });
        });
      }
    }
  };

  private changeLocationEvent = (location: ItemReference | LocationItemReference) => {
    this.handleValue('requestingLocationId', location ? location.id : null);
    this.handleValue('requestLocation', location);
  };

  private handleValue = (property: keyof RequestWizardData, value: any) => {
    let change: Partial<RequestWizardData> = {};
    change[property as string] = value;
    change['title'] = '';
    if (property === 'isMachineRelated' || property === 'category') {
      change.nmrCluster = null;
      change.nmrFunctionalArea = null;
      change.nmrTrainingName = null;
      change.nmrFunctionalSubArea = null;
    }

    if (property === 'eventType') {
      if (change.eventType && EventTypeCategory[change.eventType.eventTypeCategory] === EventTypeCategory.Extended) {
        change.isMachineRelated = true;
        change.isTechnical = true;
        change.category = Categories.Technical;
      } else {
        change.isMachineRelated = null;
        change.isTechnical = null;
        change.category = null;
      }
      change = { ...change, ...this.refreshStep(value) };
    }
    this.requestWizardStore.change({ ...this.requestWizardStore.state.item, ...change });
  };

  private toggleCategory = (category: number) => {
    const { category: cat } = this.requestWizardStore.state.item;
    if (category === 10 && (!cat || cat === Categories.Functional)) {
      this.handleValue('category', Categories.Technical);
      this.handleValue('isTechnical', true);
    } else if (category === 20 && (!cat || cat === Categories.Technical)) {
      this.setState({ isMachineRelated: false });
      this.handleValue('isMachineRelated', false);
      this.handleValue('category', Categories.Functional);
      this.handleValue('isTechnical', false);
    }
  };

  private toggleMachineRelated = (mr: number) => {
    const { isMachineRelated } = this.requestWizardStore.state.item;
    if (mr === 0 && !isMachineRelated) {
      this.handleValue('category', Categories.Technical);
      this.handleValue('isTechnical', true);
      this.handleValue('isMachineRelated', true);
      this.setState({ isMachineRelated: true });
    } else if (mr === 1 && (isMachineRelated == null || isMachineRelated)) {
      this.handleValue('isMachineRelated', false);
      this.setState({ isMachineRelated: false });
    }
  };

  private refreshStep = (response: any): Partial<RequestWizardData> => {
    const isRequestDetails = (response?.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]);
    const isEventDetails = (response?.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.EventDetails]);
    const isInstructorYes = response?.instructor === EventTypeInstructorConfiguration[EventTypeInstructorConfiguration.Yes];
    const changes: Partial<RequestWizardData> = {};

    changes.isParticipantYes = !!response?.participants;
    changes.hasSupportDetails = (response?.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.SupportDetails]);
    changes.isEventDetails = isEventDetails;
    changes.isInstructorYes = isInstructorYes;
    changes.isRequestDetails = isRequestDetails;

    if (!isRequestDetails) {
      changes.eventLocation = null;
      changes.priority = null;
      changes.category = null;
      changes.isTechnical = null;
      changes.role = null;
      changes.isMachineRelated = null;
    }

    this.setState({ isInstructorYes, isRequestDetails, isEventDetails });
    return changes;
  };

  private isAdminPlannerOrPocMultipleLocs(): boolean {
    const t = IdentityService.isAdminOrPlanner(this.identityService.getUserInfo()) || this.identityService.hasPocMultipleLocations();
    return t;
  }

  handleOnAdddRequestReason = (requestReason: RequestReasonDto) => {
    let r: ItemReference = { id: requestReason.id, title: requestReason.name };
    this.handleValue('requestReason', r);
    this.setState({
      reasonRequestEventTypes: requestReason ? requestReason.eventTypes.map(i => i.originalEventTypeId) : [],
      selectedReasonRequest: requestReason != null
    });
  };

  render() {
    const { t, requestWizardStore } = this.props;
    const { isBusy, item } = requestWizardStore.state;
    const currentUser = this.identityService.getUserInfo();
    const isRoleWithLocations = IdentityService.isRoleWithLocations(currentUser);
    const showMultipleLocations = !IdentityService.isRoleWithLocations(currentUser);
    const { isOpenedUsersModal } = this.state;

    return (
      <>
        <Form className="wizard__basic-step__container" loading={isBusy}>
          <Grid id="new-event-form-grid" columns={4}>
            <Grid.Row>
              <Grid.Column width={3} textAlign="right" verticalAlign="top">
                <div className={`required field inline  label-flex-start`}>
                  <label>{t('Request Reason')}</label>
                  <Button basic icon="info" onClick={this.handleOnOpenUserModal}></Button>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <RequestReasonEditor
                  nullable
                  value={item.requestReason}
                  onChange={r => this.handleValue('requestReason', r)}
                  onSelected={r => {
                    this.setState({
                      reasonRequestEventTypes: r ? r.eventTypes.map(i => i.originalEventTypeId) : [],
                      selectedReasonRequest: r != null
                    });
                  }}
                />
              </Grid.Column>

              {item.eventType && EventTypeCategory[item.eventType.eventTypeCategory] === EventTypeCategory.Extended && (
                <Grid.Column width={3} textAlign="right" verticalAlign="top">
                  <div className={`required field inline  label-flex-start`}>
                    <label>{t('Support Need Explanation')}</label>
                  </div>
                </Grid.Column>
              )}
              {item.eventType && EventTypeCategory[item.eventType.eventTypeCategory] === EventTypeCategory.Extended && (
                <Grid.Column width={6}>
                  <Form.Field>
                    <TextArea
                      className="form__comment-textarea scroll-not-visible"
                      value={item.requestReasonComments ? item.requestReasonComments : ''}
                      onChange={(_, { value }) => this.handleValue('requestReasonComments', value as string)}
                      placeholder="Please explain the driver of this request briefly"
                    ></TextArea>
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                <div className={`required field inline`}>
                  <label>{t('Event Type')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className={`required field inline`}>
                  <EventTypeEditor
                    nullable
                    reloadOnChange
                    readOnly={!this.state.selectedReasonRequest}
                    value={item.eventType}
                    onChange={c => this.handleValue('eventType', c?.id ? c : null)}
                    filterRequestCreator={true}
                    filterEventTypesIds={this.state.reasonRequestEventTypes}
                  />
                </div>
              </Grid.Column>

              <Grid.Column
                width={item.eventType && isExtendedWorkflow(item.eventType.eventTypeCategory) ? 2 : 3}
                textAlign="right"
                verticalAlign="middle"
              >
                <div className={`required field inline`}>
                  <label>{t('Request Location')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={item.eventType && isExtendedWorkflow(item.eventType.eventTypeCategory) ? 3 : 4}>
                {showMultipleLocations && (
                  <LocationEditor
                    nullable
                    value={item.requestLocation}
                    onChange={location => this.changeLocationEvent(location)}
                    showCountry={true}
                  />
                )}

                {isRoleWithLocations && this.identityService.hasRoleMultipleLocations(currentUser) && (
                  <LocationEditorWithRoleLocations
                    className="min-width-100pc"
                    value={item.requestingLocationId}
                    locationsReceived={this.state.locationsRecived}
                    onChange={location => this.changeLocationEvent({ id: location.id, title: location.location })}
                    showCountry={true}
                  />
                )}
                {isRoleWithLocations && !this.identityService.hasRoleMultipleLocations(currentUser) && (
                  <Input className="min-width-16em" readOnly={true} value={this.state.selectedLocation} />
                )}
              </Grid.Column>

              {item.eventType && isExtendedWorkflow(item.eventType.eventTypeCategory) && (
                <Grid.Column width={3}>
                  <div className="support-details__pre-approved">
                    <Checkbox
                      disabled={false}
                      label={'Request pre-approved during ASP'}
                      checked={item.requestPreApprovedDuringASPCheck}
                      onChange={(_, { checked }) => this.handleValue('requestPreApprovedDuringASPCheck', checked)}
                    />
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              {item.isRequestDetails && (
                <>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field inline`}>
                      <label>{t('Event Location')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className={`required field inline`}>
                      <LocationEditor
                        nullable
                        value={item.eventLocation}
                        onChange={c => this.handleValue('eventLocation', c)}
                        showCountry={true}
                      />
                    </div>
                  </Grid.Column>

                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`field inline`}>
                      <label>{t('Priority')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className={`field inline`}>
                      <PriorityEditor nullable clearable={true} value={item.priority} onChange={c => this.handleValue('priority', c)} />
                    </div>
                  </Grid.Column>
                </>
              )}
            </Grid.Row>

            {item.isRequestDetails && item.eventType && EventTypeCategory[item.eventType.eventTypeCategory] !== EventTypeCategory.Extended && (
              <>
                <Grid.Row>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field inline`}>
                      <label>{t('Category')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className={`required field inline`}>
                      <Button.Group className="filter-button-group green" style={{ width: 195 }}>
                        <Button
                          className={item.isTechnical === true ? `active gray-border` : `gray-border`}
                          onClick={() => this.toggleCategory(10)}
                        >
                          {t('Technical')}
                        </Button>
                        <Button
                          className={item.isTechnical === false ? `active gray-border` : `gray-border`}
                          onClick={() => this.toggleCategory(20)}
                        >
                          {t('Functional')}
                        </Button>
                      </Button.Group>
                    </div>
                  </Grid.Column>

                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field inline`}>
                      <label>{t('Role')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className={`field inline`}>
                      <ProfessionEditor nullable value={item.role} onChange={c => this.handleValue('role', c)} />
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="machine-related-request-reason__row">
                  <Grid.Column width={3} textAlign="right" verticalAlign="top">
                    <div className={`required field inline label-flex-start`}>
                      <label>{t('Machine related')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div className={`required field inline`}>
                      <Button.Group className="filter-button-group green" style={{ width: 195 }}>
                        <Button
                          className={item.isMachineRelated === true ? `active gray-border` : `gray-border`}
                          onClick={() => this.toggleMachineRelated(0)}
                        >
                          {t('Yes')}
                        </Button>
                        <Button
                          className={item.isMachineRelated === false ? `active gray-border` : `gray-border`}
                          onClick={() => this.toggleMachineRelated(1)}
                        >
                          {t('No')}
                        </Button>
                      </Button.Group>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Form>

        {isOpenedUsersModal && (
          <ReasonLinkedEventsPopup
            selectedRequestReasonId={item.requestReason?.id}
            onCloseUsersModal={this.handleOnCloseUserModal}
            onAdddRequestReason={this.handleOnAdddRequestReason}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(StepBasic);
