import * as React from 'react';
import DeliveryMethodSelector from 'widgets/bussiness/selectors/delivery-type-selector';
import TrainingLevelSelector from 'widgets/bussiness/selectors/training-level-selector';
import EventTypeSelector from 'widgets/bussiness/selectors/event-type-selector';
import MachineModelSelector from 'widgets/bussiness/selectors/machine-model-selector';
import LocationSelector from 'widgets/bussiness/selectors/location-selector';
import MachineRelatedEditor from 'widgets/bussiness/machine-related-editor';
import { Input, Popup } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PriorityEditor from 'widgets/bussiness/priority-editor';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import { Category } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { Categories } from 'stores/requests/requests-store';
import { useToggle } from 'utils/react-custom-hooks';
import { SelectionItem } from 'widgets/form/selectionInput';

export interface RequestPopupFilterValues {
  title: string;
  eventType: SelectionItem;
  location: string;
  machineModel: string;
  isMachineRelated: boolean;
  trainingLevel: string;
  deliveryMethod: string;
  friendlyId: string;
  priority: string;
  category: Category;
  cluster: string;
  functionalArea: string;
}

interface RequestPopupFiltersProps extends WithTranslation {
  onSubmit?: (value: RequestPopupFilterValues) => void;
  optionsFromRequests?: SelectionItem[];
}

export function getDefaultFilterValues(): RequestPopupFilterValues {
  return {
    friendlyId: '',
    title: '',
    eventType: { value: '', text: '' } as SelectionItem,
    location: '',
    machineModel: '',
    isMachineRelated: null,
    deliveryMethod: '',
    trainingLevel: '',
    priority: '',
    category: null,
    cluster: '',
    functionalArea: ''
  };
}

type StateReducer = (state: RequestPopupFilterValues, newState: Partial<RequestPopupFilterValues>) => RequestPopupFilterValues;

const RequestPopupFilters: React.FC<RequestPopupFiltersProps> = ({ t, ...props }) => {
  const { active: open, setActive: setOpen } = useToggle(false);
  const [filters, setFilters] = React.useReducer<StateReducer, RequestPopupFilterValues>(
    (state, newState) => ({ ...state, ...newState }),
    getDefaultFilterValues(),
    getDefaultFilterValues
  );

  const handleValueChange = (propertyName: keyof RequestPopupFilterValues, propertyValue: any) => {
    let defaultFilters = filters;
    if (defaultFilters[propertyName] !== propertyValue) {
      const newFilter = { [propertyName]: propertyValue };
      const filterMerged = { ...defaultFilters, ...newFilter };
      setFilters(filterMerged);
    }
  };
  const { isMachineRelated, deliveryMethod, functionalArea, trainingLevel, machineModel, friendlyId, ...rest } = filters;
  const { eventType, location, priority, category, cluster, title } = rest;

  React.useEffect(() => {
    if (machineModel) setFilters({ isMachineRelated: true, cluster: '', functionalArea: '' });
  }, [machineModel]);

  React.useEffect(() => {
    const newState: Partial<RequestPopupFilterValues> = { functionalArea: '' };
    if (cluster) {
      newState.isMachineRelated = false;
      newState.machineModel = '';
    }
    setFilters(newState);
  }, [cluster]);

  React.useEffect(() => {
    setFilters({ cluster: '', functionalArea: '' });
  }, [category]);

  const onSubmit = (e): void => {
    e.preventDefault();
    setOpen(false);
    props.optionsFromRequests = [];
    props.onSubmit && props.onSubmit({ ...filters });
  };

  const resetFilters = (): void => setFilters(getDefaultFilterValues());

  return (
    <Popup
      className="request-popup-filter"
      position="left center"
      on="click"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      basic={true}
      trigger={
        <button>
          <FontAwesomeIcon icon={faFilter} />
        </button>
      }
    >
      <div className="filter-form">
        <div className="filter-form-title">{t('Request Cards Filters')}</div>
        <form className="filter-form-content">
          <div className="filter-form__item">
            <Input
              className="filter-form__item-input"
              type="text"
              placeholder={t('Request ID')}
              icon="search"
              clearable
              value={friendlyId}
              onChange={(_, { value }) => handleValueChange('friendlyId', value)}
            />
          </div>
          <div className="filter-form__item">
            <EventTypeSelector
              direction="left"
              className="filter-form__item-input"
              placeholder={t('Event Type')}
              nullable
              clearable
              searchable
              value={eventType?.value}
              onChange={item => handleValueChange('eventType', item)}
              optionsFromRequests={props.optionsFromRequests}
            />
          </div>
          <div className="filter-form__item">
            <Input
              className="filter-form__item-input"
              type="text"
              placeholder={t('Request Title')}
              icon="search"
              clearable
              value={title}
              onChange={(_, { value }) => handleValueChange('title', value)}
            />
          </div>
          <div className="filter-form__item">
            <TrainingLevelSelector
              placeholder={t('Level')}
              direction="left"
              clearable
              nullable
              searchable
              className="filter-form__item-input"
              value={trainingLevel}
              onChange={value => handleValueChange('trainingLevel', value)}
            />
          </div>
          <div className="filter-form__item">
            <LocationSelector
              direction="left"
              className="filter-form__item-input"
              nullable
              clearable
              searchable
              placeholder={t('Request Location')}
              value={location}
              onChange={value => handleValueChange('location', value)}
            />
          </div>
          <div className="filter-form__item">
            <DeliveryMethodSelector
              direction="left"
              className="filter-form__item-input"
              placeholder={t('Delivery Method')}
              nullable
              clearable
              searchable
              value={deliveryMethod}
              onChange={value => handleValueChange('deliveryMethod', value)}
            />
          </div>
          <div className="filter-form__item">
            <PriorityEditor
              direction="left"
              value={priority ? { id: priority, title: '' } : null}
              clearable
              className="filter-form__item-input"
              placeholder={t('Priority')}
              reloadOnChange
              onChange={p => handleValueChange('priority', p?.id)}
            />
          </div>
          <div className="filter-form__item">
            <CategoryFormEditor
              direction="left"
              className="filter-form__item-input"
              placeholder={t('Category')}
              value={category}
              onChange={c => handleValueChange('category', c)}
              clearable
            />
          </div>
          <div className="filter-form__item">
            <MachineModelSelector
              direction="left"
              placeholder={t('Machine Model')}
              className="filter-form__item-input"
              nullable
              clearable
              searchable
              value={machineModel}
              onChange={value => handleValueChange('machineModel', value)}
            />
          </div>
          <div className="filter-form__item">
            <MachineRelatedEditor
              direction="left"
              placeholder={t('Machine Related')}
              nullable
              clearable
              searchable
              className="filter-form__item-input"
              value={isMachineRelated}
              onChange={value => handleValueChange('isMachineRelated', value)}
            />
          </div>
          <div className="filter-form__item">
            <NMClusterEditor
              direction="left"
              className="filter-form__item-input"
              placeholder={t('Cluster')}
              nullable
              clearable
              reloadOnChange
              category={category ? Categories[category] : null}
              value={cluster ? { id: cluster, title: '' } : null}
              onChange={c => handleValueChange('cluster', c?.id)}
            />
          </div>
          <div className="filter-form__item">
            <NMFunctionalAreaEditor
              direction="left"
              nullable
              clearable
              clearOnReload
              className="filter-form__item-input"
              placeholder={t('Functional Area')}
              readonly={!cluster}
              clusterId={cluster}
              value={functionalArea ? { id: functionalArea, title: '' } : null}
              onChange={f => handleValueChange('functionalArea', f?.id)}
            />
          </div>
          <div className="filter-form-actions">
            <div className="filter-form-actions__button clear-filters-button">
              <button type="reset" onClick={resetFilters}>
                {t('Clear Filters')}
              </button>
            </div>
            <div className="filter-form-actions__button ok-button">
              <button type="submit" onClick={onSubmit}>
                {t('Ok')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
};

RequestPopupFilters.defaultProps = { onSubmit: () => {} };

export default withTranslation()(React.memo(RequestPopupFilters));
