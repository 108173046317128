import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeMachineUnitDto,
  ChangeMachineUnitStore,
  MachineUnitDto
} from '../../../../../stores/configuration/machinery/machine-units-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';

interface ChangeMachineUnitViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeMachineUnit?: ChangeMachineUnitStore;
  currentMU: MachineUnitDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeMachineUnitViewState {
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changeMachineUnit', ChangeMachineUnitStore])
class ChangeMachineUnitView extends React.Component<ChangeMachineUnitViewProps, ChangeMachineUnitViewState> {
  private get changeMachineUnitStore() {
    return this.props.changeMachineUnit;
  }

  constructor(props: ChangeMachineUnitViewProps) {
    super(props);

    this.state = {
      cluster: { id: this.props.currentMU.clusterId, title: this.props.currentMU.clusterName },
      equipmentType: { id: this.props.currentMU.equipmentTypeId, title: this.props.currentMU.equipmentTypeName },
      oem: { id: this.props.currentMU.oemId, title: this.props.currentMU.oemName },
      userClickedSaved: false
    };

    this.changeMachineUnitStore.state.result = null;

    this.changeMachineUnitStore.change({
      id: this.props.currentMU.id,
      name: this.props.currentMU.name,
      equipmentTypeId: this.props.currentMU.equipmentTypeId,
      oemId: this.props.currentMU.oemId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeMachineUnitViewProps) {
    if (this.changeMachineUnitStore.state.result && this.changeMachineUnitStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeMachineUnitStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeMachineUnitStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeMachineUnitStore.change({ ...this.changeMachineUnitStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeMachineUnitStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Machine Unit')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeMachineUnitStore.state.result && !this.changeMachineUnitStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeMachineUnitStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeMachineUnitStore.state.item && (
              <Form>
                <Form.Field error={!this.changeMachineUnitStore.state.item.name && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('Machine Unit')}
                    placeholder={t('Name')}
                    value={this.changeMachineUnitStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeMachineUnitDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field required error={!this.state.cluster && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      placeholder={t('Cluster')}
                      nullable
                      value={this.state.cluster}
                      onChange={c => {
                        this.setState({ cluster: c, userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field required error={!this.changeMachineUnitStore.state.item.equipmentTypeId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      nullable
                      value={this.state.equipmentType}
                      readOnly={!this.state.cluster}
                      clusterId={this.state.cluster ? this.state.cluster.id : null}
                      onChange={c => {
                        this.changeMachineUnitStore.state.item.equipmentTypeId = c ? c.id : null;
                        this.setState({ equipmentType: c, userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field required error={!this.changeMachineUnitStore.state.item.oemId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('OEM')}</label>
                    <OemEditor
                      nullable
                      value={this.state.oem}
                      onChange={c => {
                        this.changeMachineUnitStore.state.item.oemId = c ? c.id : null;
                        this.setState({ oem: c, userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeMachineUnitView);
