import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import { BaseDto } from '../types';
import { DateTimeService } from 'services/datetime-service';

export interface DateTimeOffset {
  startDate: string;
  endDate: string;
}

export interface PublicHolidayDto extends BaseDto {
  id: string;
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  locationId: string;
}

export interface CountryPublicHolidayDto extends BaseDto {
  id: string;
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  countryId: string;
}

export interface CreatePublicHolidayConfigurationDto {
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  locationId: string;
}

export interface CreateCountryPublicHolidayConfigurationDto {
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  countryId: string;
}

export interface ChangePublicHolidayConfigurationDto {
  id: string;
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  locationId: string;
}

export interface ChangeCountryPublicHolidayConfigurationDto {
  id: string;
  publicHolidayPeriods: DateTimeOffset[];
  year: number;
  countryId: string;
}

export class CreatePublicHolidayValidator extends AbstractValidator<CreatePublicHolidayConfigurationDto> {
  constructor() {
    super();

    this.validateIf(o => o.publicHolidayPeriods)
      .isNotNull()
      .withFailureMessage(i18n.t('Null public holidays'));

    this.validateIfEach(o => o.publicHolidayPeriods)
      .isNotNull()
      .fulfills(o => o.endDate && o.startDate && o.startDate <= o.endDate)
      .withFailureMessage(i18n.t('There is at least one date wrong'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(({ publicHolidayPeriods }) => {
        return (publicHolidayPeriods || []).all((y, i) => {
          const currStart = DateTimeService.toMoment(y?.startDate);
          const nextStart = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].startDate) : null;
          const currEnd = DateTimeService.toMoment(y?.endDate);
          const nextEnd = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].endDate) : null;
          if (nextStart && nextEnd) {
            if (
              nextStart.isSame(currStart, 'day') ||
              nextEnd.isSame(currEnd, 'day') ||
              nextStart.isSame(currEnd, 'day') ||
              nextEnd.isSame(currStart, 'day')
            )
              return false;

            return !(nextStart.isSameOrAfter(currStart, 'days') && nextEnd.isSameOrBefore(currEnd, 'days'));
          }
          return true;
        });
      })
      .withFailureMessage(i18n.t('There is a conflict between the dates ranges!'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

export class CreateCountryPublicHolidayValidator extends AbstractValidator<CreateCountryPublicHolidayConfigurationDto> {
  constructor() {
    super();

    this.validateIf(o => o.publicHolidayPeriods)
      .isNotNull()
      .withFailureMessage(i18n.t('Null public holidays'));

    this.validateIfEach(o => o.publicHolidayPeriods)
      .isNotNull()
      .fulfills(o => o.endDate && o.startDate && o.startDate <= o.endDate)
      .withFailureMessage(i18n.t('There is at least one date wrong'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(({ publicHolidayPeriods }) => {
        return (publicHolidayPeriods || []).all((y, i) => {
          const currStart = DateTimeService.toMoment(y?.startDate);
          const nextStart = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].startDate) : null;
          const currEnd = DateTimeService.toMoment(y?.endDate);
          const nextEnd = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].endDate) : null;
          if (nextStart && nextEnd) {
            if (
              nextStart.isSame(currStart, 'day') ||
              nextEnd.isSame(currEnd, 'day') ||
              nextStart.isSame(currEnd, 'day') ||
              nextEnd.isSame(currStart, 'day')
            )
              return false;

            return !(nextStart.isSameOrAfter(currStart, 'days') && nextEnd.isSameOrBefore(currEnd, 'days'));
          }
          return true;
        });
      })
      .withFailureMessage(i18n.t('There is a conflict between the dates ranges!'));

    this.validateIfString(o => o.countryId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Country is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

export class ChangePublicHolidayValidator extends AbstractValidator<ChangePublicHolidayConfigurationDto> {
  constructor() {
    super();

    this.validateIf(o => o.publicHolidayPeriods)
      .isNotNull()
      .withFailureMessage(i18n.t('Null public holidays'));

    this.validateIfEach(o => o.publicHolidayPeriods)
      .isNotNull()
      .fulfills(o => o.endDate && o.startDate && o.startDate <= o.endDate)
      .withFailureMessage(i18n.t('There is at least one date wrong'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(({ publicHolidayPeriods }) => {
        return (publicHolidayPeriods || []).all((y, i) => {
          const currStart = DateTimeService.toMoment(y?.startDate);
          const nextStart = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].startDate) : null;
          const currEnd = DateTimeService.toMoment(y?.endDate);
          const nextEnd = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].endDate) : null;
          if (nextStart && nextEnd) {
            if (
              nextStart.isSame(currStart, 'day') ||
              nextEnd.isSame(currEnd, 'day') ||
              nextStart.isSame(currEnd, 'day') ||
              nextEnd.isSame(currStart, 'day')
            )
              return false;

            return !(nextStart.isSameOrAfter(currStart, 'days') && nextEnd.isSameOrBefore(currEnd, 'days'));
          }
          return true;
        });
      })
      .withFailureMessage(i18n.t('There is a conflict between the dates ranges!'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

export class ChangeCountryPublicHolidayValidator extends AbstractValidator<ChangeCountryPublicHolidayConfigurationDto> {
  constructor() {
    super();

    this.validateIf(o => o.publicHolidayPeriods)
      .isNotNull()
      .withFailureMessage(i18n.t('Null public holidays'));

    this.validateIfEach(o => o.publicHolidayPeriods)
      .isNotNull()
      .fulfills(o => o.endDate && o.startDate && o.startDate <= o.endDate)
      .withFailureMessage(i18n.t('There is at least one date wrong'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(({ publicHolidayPeriods }) => {
        return (publicHolidayPeriods || []).all((y, i) => {
          const currStart = DateTimeService.toMoment(y?.startDate);
          const nextStart = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].startDate) : null;
          const currEnd = DateTimeService.toMoment(y?.endDate);
          const nextEnd = publicHolidayPeriods[i + 1] ? DateTimeService.toMoment(publicHolidayPeriods[i + 1].endDate) : null;
          if (nextStart && nextEnd) {
            if (
              nextStart.isSame(currStart, 'day') ||
              nextEnd.isSame(currEnd, 'day') ||
              nextStart.isSame(currEnd, 'day') ||
              nextEnd.isSame(currStart, 'day')
            )
              return false;

            return !(nextStart.isSameOrAfter(currStart, 'days') && nextEnd.isSameOrBefore(currEnd, 'days'));
          }
          return true;
        });
      })
      .withFailureMessage(i18n.t('There is a conflict between the dates ranges!'));

    this.validateIfString(o => o.countryId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Country is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

@repository('@@PUBLICHOLIDAYCONFIGURATIONS', 'publicholidayconfiguration.summary')
export class PublicHolidayConfigurationStore extends DataStore<PublicHolidayDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-publicHolidaysConfiguration';
  retrievePath = 'get-publicHolidaysConfigurations';
  retrieveOnePath = 'get-publicHolidaysConfiguration';
  updatePath = 'update-publicHolidaysConfiguration';
  deletePath = 'delete-publicHolidaysConfiguration';

  protected validate(item: PublicHolidayDto) {
    return new ChangePublicHolidayValidator().validate(item as any);
  }

  constructor() {
    super('PUBLICHOLIDAY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@COUNTRYPUBLICHOLIDAYCONFIGURATIONS', 'countrypublicholidayconfiguration.summary')
export class CountryPublicHolidayConfigurationStore extends DataStore<CountryPublicHolidayDto> {
  baseUrl = 'master-data/v1';
  createPath = 'create-countryPublicHolidaysConfiguration';
  retrievePath = 'get-countriespublicHolidaysConfigurations';
  retrieveOnePath = 'get-countrypublicHolidaysConfiguration';
  updatePath = 'update-countrypublicHolidaysConfiguration';
  deletePath = 'delete-countrypublicHolidaysConfiguration';

  protected validate(item: CountryPublicHolidayDto) {
    return new ChangeCountryPublicHolidayValidator().validate(item as any);
  }

  constructor() {
    super('COUNTRYPUBLICHOLIDAY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PUBLICHOLIDAYCONFIGURATIONS', 'publicholidayconfiguration.new')
export class NewPublicHolidayConfigurationStore extends FormStore<CreatePublicHolidayConfigurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-publicHolidaysConfiguration';
  retrievePath = 'get-publicHolidaysConfigurations';
  retrieveOnePath = 'get-publicHolidaysConfiguration';
  updatePath = 'update-publicHolidaysConfiguration';
  deletePath = 'delete-publicHolidaysConfiguration';

  protected validate(item: CreatePublicHolidayConfigurationDto) {
    return new CreatePublicHolidayValidator().validate(item);
  }

  constructor() {
    super('NEW_PUBLICHOLIDAY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@COUNTRYPUBLICHOLIDAYCONFIGURATIONS', 'countrypublicholidayconfiguration.new')
export class NewCountryPublicHolidayConfigurationStore extends FormStore<CreateCountryPublicHolidayConfigurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'create-countryPublicHolidaysConfiguration';
  retrievePath = 'get-countriespublicHolidaysConfigurations';
  retrieveOnePath = 'get-countrypublicHolidaysConfiguration';
  updatePath = 'update-countrypublicHolidaysConfiguration';
  deletePath = 'delete-countrypublicHolidaysConfiguration';

  protected validate(item: CreateCountryPublicHolidayConfigurationDto) {
    return new CreateCountryPublicHolidayValidator().validate(item);
  }

  constructor() {
    super('NEW_COUNTRYPUBLICHOLIDAY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PUBLICHOLIDAYCONFIGURATIONS', 'publicholidayconfiguration.change')
export class ChangePublicHolidayConfigurationStore extends FormStore<ChangePublicHolidayConfigurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-publicHolidaysConfiguration';
  retrievePath = 'get-publicHolidaysConfigurations';
  retrieveOnePath = 'get-publicHolidaysConfiguration';
  updatePath = 'update-publicHolidaysConfiguration';
  deletePath = 'delete-publicHolidaysConfiguration';

  protected validate(item: ChangePublicHolidayConfigurationDto) {
    return new ChangePublicHolidayValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PUBLICHOLIDAY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@COUNTRYPUBLICHOLIDAYCONFIGURATIONS', 'countrypublicholidayconfiguration.change')
export class ChangeCountryPublicHolidayConfigurationStore extends FormStore<ChangeCountryPublicHolidayConfigurationDto> {
  baseUrl = 'master-data/v1';
  createPath = 'create-countryPublicHolidaysConfiguration';
  retrievePath = 'get-countriespublicHolidaysConfigurations';
  retrieveOnePath = 'get-countrypublicHolidaysConfiguration';
  updatePath = 'update-countrypublicHolidaysConfiguration';
  deletePath = 'delete-countrypublicHolidaysConfiguration';

  protected validate(item: ChangeCountryPublicHolidayConfigurationDto) {
    return new ChangeCountryPublicHolidayValidator().validate(item);
  }

  constructor() {
    super('CHANGE_COUNTRYPUBLICHOLIDAY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
