import React from 'react';
import { Flag } from 'semantic-ui-react';

const LocationTableComponent = props => {
  return (
    <div className="locations-component__wrapper">
      <Flag name={props.isoCode.toLowerCase()} />
      <span>
        {props.location} - {props.country}
      </span>
    </div>
  );
};

export default LocationTableComponent;
