import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { CreateProfessionDto, NewProfessionStore } from 'stores/configuration/profiles/profession-roles-store';
import { nameof } from 'utils/object';

interface NewProfessionViewProps extends WithTranslation {
  onClose: () => void;
  newProfession?: NewProfessionStore;
}

interface NewProfessionViewState {
  userClickedSaved: boolean;
}

@connect(['newProfession', NewProfessionStore])
class NewProfessionView extends React.Component<NewProfessionViewProps, NewProfessionViewState> {
  private get newProfessionStore() {
    return this.props.newProfession;
  }

  constructor(props: NewProfessionViewProps) {
    super(props);
    this.newProfessionStore.createNew({
      profession: ''
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewProfessionViewProps) {
    if (this.newProfessionStore.state.result && this.newProfessionStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newProfessionStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newProfessionStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newProfessionStore.change({ ...this.newProfessionStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newProfessionStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Role')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newProfessionStore.state.result && !this.newProfessionStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newProfessionStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newProfessionStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newProfessionStore.state.item.profession && this.state.userClickedSaved}
                    label={t('Role')}
                    placeholder={t('Name')}
                    value={this.newProfessionStore.state.item.profession}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateProfessionDto>('profession'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewProfessionView);
