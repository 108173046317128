import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { CreateRequestReasonDto, NewRequestReasonStore } from 'stores/configuration/events-n-requests/request-reasons-store';
import { EventTypeDto, EventTypesStore } from 'stores/configuration/events-workflow/event-types-store';
import { nameof } from 'utils/object';

interface NewRequestReasonsViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newRequestReasons?: NewRequestReasonStore;
  eventTypes: EventTypesStore;
}

interface NewRequestReasonsViewState {
  userClickedSaved: boolean;
}

@connect(['newRequestReasons', NewRequestReasonStore], ['eventTypes', EventTypesStore])
class NewRequestReasonView extends React.Component<NewRequestReasonsViewProps, NewRequestReasonsViewState> {
  private get newRequestReasonsStore() {
    return this.props.newRequestReasons;
  }

  constructor(props: NewRequestReasonsViewProps) {
    super(props);
    this.newRequestReasonsStore.createNew({
      name: '',
      eventTypes: []
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewRequestReasonsViewProps) {
    if (this.newRequestReasonsStore.state.result && this.newRequestReasonsStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  loadEventTypes = () => {
    this.props.eventTypes.getAllAsync({
      searchQuery: '',
      filter: ['active eq true', 'EventTypeConfiguration/RolesThatCreateRequests/any()'],
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<EventTypeDto>('name'), useProfile: false }]
    });
  };

  componentDidMount() {
    this.loadEventTypes();
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newRequestReasonsStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newRequestReasonsStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newRequestReasonsStore.change({ ...this.newRequestReasonsStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result } = this.newRequestReasonsStore.state;
    const eventTypesOptions = this.props.eventTypes.state.items.map(({ item }) => ({
      key: item.originalEventTypeId,
      text: item.name,
      value: item.originalEventTypeId
    }));

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newRequestReasonsStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Request Reason')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newRequestReasonsStore.state.result && !this.newRequestReasonsStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newRequestReasonsStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newRequestReasonsStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newRequestReasonsStore.state.item.name && this.state.userClickedSaved}
                    label={t('Request Reason')}
                    placeholder={t('Name')}
                    value={this.newRequestReasonsStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateRequestReasonDto>('name'), value)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <div className={`field inline configuration-event-n-request__modal__element`}>
                    <label>{t('Event types')}</label>
                    <Dropdown
                      placeholder={t('Event types')}
                      search
                      selection
                      multiple
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      error={
                        result &&
                        !result.isSuccess &&
                        this.state.userClickedSaved &&
                        (result.messages || []).any(x => x.propertyName === nameof<CreateRequestReasonDto>('eventTypes'))
                      }
                      options={eventTypesOptions}
                      value={this.newRequestReasonsStore.state.item.eventTypes}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateRequestReasonDto>('eventTypes'), value)}
                    />
                  </div>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(NewRequestReasonView);
