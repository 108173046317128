import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { TnaQuestionBankDto, TnaQuestionBanksStore } from 'stores/assessments/questionBank/tna-questionBank-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';
import { LocationDto } from 'stores/configuration/locations/locations-store';

interface ChooseTnaQuestionsViewProps extends WithTranslation {
  onAddTnaQuestions: (questions: TnaQuestionBankDto[]) => void;
  onCloseModal?: () => void;
  alreadySelectedQuestions: string[];
  tnaQuestionsStore?: TnaQuestionBanksStore;
  professionId?: string;
  isMachineRelated?: boolean;
  equipmentTypeId?: string;
  oemId?: string;
  tnaSkillId?: string;
  tnaSkillName?: string;
  fromCustomize?: boolean;
}

interface ChooseTnaQuestionsFilters {
  questionTitleOrId: string;
  location: LocationDto;
}

interface ChooseTnaQuestionsViewState {
  query: Query;
  selectedQuestions: TnaQuestionBankDto[];
  filters: ChooseTnaQuestionsFilters;
}

@connect(['tnaQuestionsStore', TnaQuestionBanksStore])
class ChooseTnaQuestionsView extends React.Component<ChooseTnaQuestionsViewProps, ChooseTnaQuestionsViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private globalLocationOption = { text: 'Global', value: 'Global', id: null };

  constructor(props: ChooseTnaQuestionsViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      selectedQuestions: null,
      filters: {
        questionTitleOrId: undefined,
        location: null
      }
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  @autobind
  private onAddTnaQuestions() {
    this.props.onAddTnaQuestions(this.state.selectedQuestions);

    this.props.onCloseModal();
  }

  @autobind
  private onCancel() {
    this.props.onCloseModal();
  }

  @autobind
  private load() {
    this.props.tnaQuestionsStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  }

  private handleFilterByQuestionTitleOrId(value: string) {
    let filters = { ...this.state.filters };
    filters.questionTitleOrId = value;
    this.refreshTable(filters);
  }

  private onLocationChanged = (location: LocationDto) => {
    const filters = { ...this.state.filters };
    if (!location) filters.location = null;
    else filters.location = location;

    this.setState({ filters });
    this.refreshTable(filters);
  };

  private selectedTnaQuestions(items: unknown[]): void {
    this.setState({ selectedQuestions: items as any });
  }

  private refreshTable(filters: ChooseTnaQuestionsFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChooseTnaQuestionsFilters) {
    const oDataFilters = [];
    const currentUserInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(currentUserInfo);
    const isAdminOrPowerInst = IdentityService.isAdminOrPowerInstructor(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    if (this.props.alreadySelectedQuestions && this.props.alreadySelectedQuestions.length > 0)
      oDataFilters.push(`not(Id in (${this.props.alreadySelectedQuestions.join(',')}))`);

    if (!isNullOrWhiteSpaces(this.props.professionId)) {
      oDataFilters.push({ Professions: { any: { id: { eq: { value: this.props.professionId, type: 'guid' } } } } });
    }

    if (this.props.isMachineRelated != null) {
      oDataFilters.push({ isMachineRelated: { eq: this.props.isMachineRelated } });
    }

    if (!isNullOrWhiteSpaces(this.props.equipmentTypeId)) {
      oDataFilters.push({ EquipmentTypeId: { eq: { type: 'guid', value: this.props.equipmentTypeId } } });
    }

    if (!isNullOrWhiteSpaces(this.props.oemId)) {
      oDataFilters.push({ OemId: { eq: { type: 'guid', value: this.props.oemId } } });
    }

    if (!isNullOrWhiteSpaces(this.props.tnaSkillId)) {
      oDataFilters.push({
        or: [{ TnaSkillId: { eq: { type: 'guid', value: this.props.tnaSkillId } } }, { TnaSkillId: null }]
      });
    }

    if (!isNullOrWhiteSpaces(filters.questionTitleOrId)) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QT');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QT')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    if (filters.location != null) {
      oDataFilters.push({ LocationId: { eq: { value: filters.location.id, type: 'guid' } } });
    } else {
      if (isPoc && pocLocations && !pocLocations.isEmpty()) {
        oDataFilters.push(`LocationId in (${pocLocations.join(',')})`);
      } else if (isAdminOrPowerInst && this.props.fromCustomize) {
        oDataFilters.push({ LocationId: { ne: { type: 'guid', value: null } } });
      }
    }

    return oDataFilters;
  }

  public render() {
    const { t } = this.props as any;
    const currentUserInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(currentUserInfo);
    const isAdminOrPowerInst = IdentityService.isAdminOrPowerInstructor(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<TnaQuestionBankDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.text}</div>,
          selectableHeader: true
        },
        {
          title: t('Question Source'),
          tooltipRenderer: false,
          selectableHeader: true,
          renderer: data => (
            <span key={data.id} className="question-bank__cell__tag">
              {data.type}
            </span>
          )
        }
      ],
      data: this.props.tnaQuestionsStore.state
    } as TableModel<TnaQuestionBankDto>;

    return (
      <Modal className="template-search__modal" open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={(event, data) => this.handleFilterByQuestionTitleOrId(data.value)}
            />
            <LocationEditorWithRoleLocations
              key={'location-editor-tna-question-list-filter'}
              locationsReceived={isPoc ? pocLocations : []}
              value={null}
              onChange={location => this.onLocationChanged(location)}
              nullable
              customPlaceholder={'Question Source'}
              skipAutoSelectFirstOption
              extraLocationOptions={isAdminOrPowerInst ? [this.globalLocationOption] : []}
            />
            <span className="keep-right skill-name">
              {t('Skill')}: {this.props.tnaSkillName}
            </span>
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={questions => this.selectedTnaQuestions(questions)}
              onPageChange={this.handlePageChange}
              //onFilterChange={this.handleFilterChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddTnaQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseTnaQuestionsView);
