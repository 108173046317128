import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from '../../stores/dataStore';
import { SelectionInput, DropdownProps } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces, customEqualCompare } from '../../utils/useful-functions';
import { MachineUnitsStore, MachineUnitDto } from 'stores/configuration/machinery/machine-units-store';

interface MachineUnitEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange?: (value: ItemReference) => void;
  machineUnits?: MachineUnitsStore;
  reloadOnChange?: boolean;
  clearOnReload?: boolean;
  clearable?: boolean;
  equipmentId?: string;
  oemId?: string;
  readonly?: boolean;
  error?: boolean;
}

interface MachineUnitEditorState {
  value: ItemReference;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['machineUnits', MachineUnitsStore])
class MachineUnitEditor extends React.Component<MachineUnitEditorProps, MachineUnitEditorState> {
  private get machineUnitsStore() {
    return this.props.machineUnits;
  }

  constructor(props: MachineUnitEditorProps) {
    super(props);
    this.state = {
      searchQuery: null,
      value: this.props.value,
      query: this.getMachineUnitMethod()
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange, oemId, equipmentId } = this.props;
    if (!customEqualCompare(prevProps.value, value) && reloadOnChange) {
      this.setState({
        value,
        searchQuery: null,
        query: this.getMachineUnitMethod()
      });
    }

    if (!customEqualCompare(oemId, prevProps.oemId) || !customEqualCompare(equipmentId, prevProps.equipmentId)) {
      this.setState({
        value: value,
        searchQuery: null,
        query: this.getMachineUnitMethod()
      });
    }
  }

  @autobind
  private getMachineUnitMethod() {
    const method = async (search: string) => {
      const filters = isNullOrWhiteSpaces(search)
        ? []
        : [`startswith(tolower(${nameof<MachineUnitDto>('name')}), '${search.toLowerCase()}')`];

      if (this.props.equipmentId) filters.push(`${nameof<MachineUnitDto>('equipmentTypeId')} eq ${this.props.equipmentId}`);
      if (this.props.oemId) {
        filters.push(`${nameof<MachineUnitDto>('oemId')} eq ${this.props.oemId}`);
      }
      const result = await this.machineUnitsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<MachineUnitDto>('name'), useProfile: false }],
        filter: filters
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    return (
      <SelectionInput
        error={this.props.error || false}
        content={item => <div>{item.title}</div>}
        searchable
        clearable={this.props.clearable}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        readOnly={this.props.readonly}
        value={val}
        searchQuery={this.state.searchQuery}
        onQueryChange={q => this.setState({ searchQuery: q })}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
        clearOnReload={this.props.clearOnReload}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MachineUnitEditor);
