import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Header } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { UserProfilesStore, UserProfilesDto } from 'stores/profile/user-profile-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { isNullOrWhiteSpaces, firstToUpperCase } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import '../../assets/less/choose-employees-form.less';
import { decamelCase } from 'utils/event-utils';
import { ColumnsNeeds } from 'widgets/collections/util-table';

interface ChooseEmployeesViewProps extends WithTranslation {
  onAddEmployees?: (employees: UserProfilesDto[]) => void;
  onCloseUsersModal?: () => void;
  alreadySelectedUserIds?: string[];
  userProfilesStore?: UserProfilesStore;
  locationId?: string;
  showProfileInColumn?: boolean;
  profileId?: string;
  machineModelIds?: string[];
  showEmployeeIdFilter?: boolean;
  showPositionCodeFilter?: boolean;
  /** Default columns are firstName lastName sapId sfPosition with the exception of profiles */
  showColumns?: ColumnsNeeds<UserProfilesDto>[];
  clusterId?: string;
  equipmentTypeId?: string;
  oemId?: string;
  machineModelIdQueryString?: string;
  profileIds?: string[];
  isGlobalPoc?: boolean;
}
interface ChooseEmployeesFilters {
  locationId: string;
  profileId?: string;
  machineModelIds?: string[];
  firstNameOrLastName: string;
  sfPosition: string;
  employeeId: string;
  positionCode: PositionCodeDto;
  role: ItemReference;

  clusterId?: string;
  equipmentTypeId?: string;
  oemId?: string;
  machineModelId?: string;
  profileIds?: string[];
}

interface ChooseEmployeesViewState {
  activeFilters: string[];
  query: Query;
  selectedUsers: UserProfilesDto[];
  filters: ChooseEmployeesFilters;
}

@connect(['userProfilesStore', UserProfilesStore])
class ChooseEmployeesView extends React.Component<ChooseEmployeesViewProps, ChooseEmployeesViewState> {
  constructor(props: ChooseEmployeesViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10,
        parameters: { IsActive: 'true' }
      },
      activeFilters: [],
      selectedUsers: [],
      filters: {
        locationId: this.props.locationId,
        machineModelIds: this.props.machineModelIds,
        profileId: this.props.profileId,
        firstNameOrLastName: null,
        sfPosition: null,
        employeeId: null,
        role: null,
        positionCode: null,
        clusterId: this.props.clusterId,
        equipmentTypeId: this.props.equipmentTypeId,
        oemId: this.props.oemId,
        machineModelId: this.props.machineModelIdQueryString,
        profileIds: this.props.profileIds
      }
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  componentDidUpdate() {
    const { selectedUsers } = this.state;
    if ((selectedUsers || []).length === 0) {
      let items: UserProfilesDto[] = [];
      const item = (this.props.userProfilesStore.state.items || []).firstOrDefault();
      if (item && item.item) {
        items.push(item.item);
        this.setState({ selectedUsers: items as any });
      }
    }
  }

  private selectedUsers = (items: unknown[]): void => {
    if (items && items.length > 0) this.setState({ selectedUsers: items as any });
  };

  @autobind
  private onAddEmployees() {
    const { selectedUsers } = this.state;
    if ((selectedUsers || []).length === 0) return;
    if (this.props.onAddEmployees) this.props.onAddEmployees(selectedUsers);
    this.props.onCloseUsersModal();
  }

  @autobind
  private onCancel() {
    this.props.onCloseUsersModal();
  }

  @autobind
  private load() {
    this.props.userProfilesStore.getAlltUserProfile(
      this.state.query,
      this.state.filters.firstNameOrLastName,
      this.state.filters.locationId,
      null,
      this.state.filters.positionCode && this.state.filters.positionCode.id,
      this.state.filters.sfPosition,
      this.state.filters.employeeId,
      this.state.filters.clusterId,
      this.state.filters.equipmentTypeId,
      this.state.filters.oemId,
      this.state.filters.machineModelId
    );
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  }

  @autobind
  private onEmployeeNameFilterChanged = (e, { value }) => {
    let filters = { ...this.state.filters };
    filters.firstNameOrLastName = value;
    this.refreshTable(filters);
  };

  @autobind
  private onSFPositionFilterChanged = (e, { value }) => {
    let filters = { ...this.state.filters };
    filters.sfPosition = value;
    this.refreshTable(filters);
  };

  private refreshTable(filters: ChooseEmployeesFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter = (filters: ChooseEmployeesFilters) => {
    let oDataFilter = [];

    if ((this.props.alreadySelectedUserIds || []).length !== 0)
      oDataFilter.push(`not(userId in (${this.props.alreadySelectedUserIds.join(',')}))`);

    if (!isNullOrWhiteSpaces(filters.profileId))
      oDataFilter.push({ Profiles: { any: { ProfileId: { eq: { type: 'guid', value: filters.profileId } } } } });

    if ((filters.profileIds || []).length > 0) {
      let profilesFilter = { Profiles: { any: { ProfileId: { in: [] } } } };
      profilesFilter.Profiles.any.ProfileId.in = filters.profileIds.map(x => ({ type: 'guid', value: x }));
      oDataFilter.push(profilesFilter);
    }

    if ((filters.machineModelIds || []).length > 0)
      oDataFilter.push({
        Profiles: { any: { Machines: { any: { MachineModelId: { in: { type: 'guid', value: filters.machineModelIds } } } } } }
      });

    return oDataFilter;
  };

  private handleOnPositionCodeChange = (positionCode: PositionCodeDto) => {
    let filters = { ...this.state.filters };
    filters.positionCode = positionCode;
    this.refreshTable(filters);
  };

  private handleOnEmployeeIdChange = (_, { value }) => {
    let filters = { ...this.state.filters };
    filters.employeeId = value;
    this.refreshTable(filters);
  };

  public render() {
    const {
      t,
      showColumns,
      showProfileInColumn: showProfileColumn,
      profileId,
      showEmployeeIdFilter,
      showPositionCodeFilter,
      profileIds
    } = this.props;
    const { selectedUsers, filters } = this.state;

    const tableModel = {
      columns: [
        { title: t('Last Name'), tooltipRenderer: true, renderer: data => data.lastName },
        { title: t('First Name'), tooltipRenderer: true, renderer: data => data.firstName },
        { title: t('SAP ID'), tooltipRenderer: true, renderer: data => data.employeeId },
        { title: t('SF Position'), tooltipRenderer: true, renderer: data => data.sfPosition }
      ],
      keySelector: data => data.userId,
      data: this.props.userProfilesStore.state
    } as TableModel<UserProfilesDto>;

    if (showColumns && showColumns.length > 0) {
      (showColumns || []).forEach(({ column, tooltipRenderer, title }) => {
        title = title ? title : firstToUpperCase(decamelCase(column)).replace(' Name', '');
        if (column !== 'lastName' && column !== 'firstName' && column !== 'employeeId' && column !== 'sfPosition' && column !== 'profiles')
          tableModel.columns.push({
            title,
            renderer: item => item[column] && typeof item[column] !== 'object' && item[column],
            tooltipRenderer
          });
      });
    }

    if (showProfileColumn && profileId)
      tableModel.columns.push({
        title: t('Profile'),
        tooltipRenderer: true,
        renderer: ({ profiles }) => {
          let profile = (profiles || []).find(x => x.profileId === profileId);
          return profile && profile.profileName;
        }
      });
    if (showProfileColumn && (profileIds || []).length > 0) {
      tableModel.columns.push({
        title: t('Profiles'),
        tooltipRenderer: true,
        renderer: ({ profiles }) => {
          let profs = (profiles || []).filter(x => profileIds.includes(x.profileId));
          return profs && profs.length > 0 && profs.map(pr => <span key={pr.id}> {t((pr.profileName || '').toUpperCase())}</span>);
        }
      });
    }
    return (
      <Modal
        size={'large'}
        className="template-search__modal choose-employees"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Select Employees')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters choose-employees">
            <ClearableTimerInput
              className="long-input"
              icon="search"
              placeholder={t('Search Employee')}
              onChange={this.onEmployeeNameFilterChanged}
            />

            {showEmployeeIdFilter && (
              <ClearableTimerInput icon="search" placeholder={t('Search SAP ID')} onChange={this.handleOnEmployeeIdChange} />
            )}

            <ClearableTimerInput icon="search" placeholder={t('Search SF Position')} onChange={this.onSFPositionFilterChanged} />
            {showPositionCodeFilter && (
              <PositionCodeEditor
                className="custom-editor"
                placeholder={t('Position Code')}
                clearable
                nullable
                value={filters.positionCode && filters.positionCode.id}
                onChange={this.handleOnPositionCodeChange}
              />
            )}
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              hiddeMenu={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSelectionChange={this.selectedUsers}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={(selectedUsers || []).length === 0} onClick={this.onAddEmployees} positive>
            {t('Select Employees')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseEmployeesView);
