import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView } from 'widgets/collections/table';

export interface InstructorExpertisesProps extends WithTranslation, RouteComponentProps {
  instructorId?: string;
  instructorExpertiseStore?: InstructorExpertiseStore;
}

export interface InstructorExpertisesState {}

@connect(['instructorExpertiseStore', InstructorExpertiseStore])
class InstructorExpertisesMrTable extends React.Component<InstructorExpertisesProps, InstructorExpertisesState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const { instructorId } = this.props;
    const filter = [{ InstructorId: { eq: { type: 'guid', value: instructorId } } }, { IsMachineRelated: true }];

    let queryExpertises = { searchQuery: '', skip: 0, take: 100000, orderBy: [], filter, parameters: { hideDeprecated: 'true' } };

    this.props.instructorExpertiseStore.getAllAsync(queryExpertises);
  };

  private getMRTableModel = () => {
    const { t } = this.props;

    const tableModel = {
      columns: [
        {
          title: t('Event Type'),
          renderer: data => data.eventTypeName
        },
        {
          title: t('Role'),
          renderer: data => data.roleName
        },
        {
          title: t('Level'),
          renderer: data => data.trainingLevelName
        },
        {
          title: t('Cluster Machine Related'),
          renderer: data => data.machineRelatedClusterName
        },
        {
          title: t('Equipment Type'),
          renderer: data => data.equipmentTypeName
        },
        {
          title: t('OEM'),
          renderer: data => data.oemName
        },
        {
          title: t('Machine Model'),
          renderer: data => data.machineModelName
        }
      ],
      data: this.props.instructorExpertiseStore.state
    } as TableModel<InstructorExpertiseDto>;

    return tableModel;
  };

  public render() {
    if (!this.props.instructorId) return null;

    const mrTableModel = this.getMRTableModel();
    return (
      <TableView
        onRefresh={this.load}
        model={mrTableModel}
        canCreateNew={false}
        canEdit={false}
        canDelete={false}
        fixedDimmer
        noFlexContainer
      />
    );
  }
}
export default withTranslation()(InstructorExpertisesMrTable);
