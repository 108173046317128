import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { LanguageDto, DropdownLanguagesStore } from '../../../stores/configuration/locations/languages-store';
import { nameof } from '../../../utils/object';
import { Dropdown } from 'semantic-ui-react';

interface MultiLanguageEditorProps extends WithTranslation {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  languages?: DropdownLanguagesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  filteredLanguages?: string[];
  id?: string;
  className?: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultiLanguageEditorState {
  value: string[];
  availableLanguages: { [id: string]: LanguageDto };
  languageOptions: { text: string; value: string }[];
}

@connect(['languages', DropdownLanguagesStore])
class MultiLanguageEditor extends React.Component<MultiLanguageEditorProps, MultiLanguageEditorState> {
  private get languageStore() {
    return this.props.languages;
  }

  constructor(props: MultiLanguageEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      availableLanguages: {},
      languageOptions: []
    };

    this.initLanguages();
  }

  private async initLanguages() {
    let filter;
    if (this.props.filteredLanguages && this.props.filteredLanguages.length > 0) {
      filter = [{ id: { in: { type: 'guid', value: this.props.filteredLanguages } } }];
    } else {
      filter = [];
    }

    return await this.languageStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<LanguageDto>('language'), useProfile: false }],
        filter: filter
      })
      .then(languages => {
        const dict = {};
        const options = [];

        languages.items.forEach(language => {
          dict[language.id] = language;
          options.push({ text: language.language, value: language.id });
        });

        this.setState({
          availableLanguages: dict,
          languageOptions: options
        });
      });
  }

  public render() {
    const { t, placeholder, className } = this.props;
    const languageOptions = this.state.languageOptions;

    return (
      <Dropdown
        id={this.props.id}
        search
        inline
        selection
        closeOnChange
        closeOnEscape
        clearable={true}
        multiple={true}
        options={languageOptions}
        error={!this.state.value}
        className={className ? className : 'planit-users-inputs planit-user-dropdown planit-user-selector'}
        value={this.state.value ? this.state.value : null}
        onChange={(e, data) => this.selectedLanguage(data)}
        placeholder={placeholder || t('Select language')}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
        onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
      />
    );
  }

  selectedLanguage(data: any) {
    this.setState({
      value: data.value
    });

    this.props.onChange(data.value);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiLanguageEditor);
