import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dropdown, Input, Loader, Dimmer } from 'semantic-ui-react';
import { CreatePerdiemDto, NewPerdiemStore } from 'stores/configuration/perdiems/perdiems-store';
import { nameof } from 'utils/object';
import CountryEditor from 'widgets/bussiness/location-editor';
import { ItemReference } from 'stores/dataStore';

interface NewPerdiemViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newPerdiem?: NewPerdiemStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewPerdiemViewState {
  location: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newPerdiem', NewPerdiemStore])
class NewPerdiemView extends React.Component<NewPerdiemViewProps, NewPerdiemViewState> {
  private get newPerdiemStore() {
    return this.props.newPerdiem;
  }

  constructor(props: NewPerdiemViewProps) {
    super(props);
    this.newPerdiemStore.createNew({
      locationId: null,
      currency: null,
      perdiem: null,
      hardship: null
    });
    this.state = {
      location: null,
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPerdiemViewProps) {
    const { result } = this.newPerdiemStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPerdiemStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPerdiemStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const { item } = this.newPerdiemStore.state;
    const change = {};
    change[property] = value;
    this.newPerdiemStore.change({ ...item, ...change });
  }

  private onCurrencyChange = (e, { value }) => {
    this.handleValue(nameof<CreatePerdiemDto>('currency'), value);
  };

  getValues() {
    const currencies = {
      AED: 'United Arab Emirates Dirham',
      AFN: 'Afghan Afghani',
      ALL: 'Albanian Lek',
      AMD: 'Armenian Dram',
      ANG: 'Netherlands Antillean Guilder',
      AOA: 'Angolan Kwanza',
      ARS: 'Argentine Peso',
      AUD: 'Australian Dollar',
      AWG: 'Aruban Florin',
      AZN: 'Azerbaijani Manat',
      BAM: 'Bosnia-Herzegovina Convertible Mark',
      BBD: 'Barbadian Dollar',
      BDT: 'Bangladeshi Taka',
      BGN: 'Bulgarian Lev',
      BHD: 'Bahraini Dinar',
      BIF: 'Burundian Franc',
      BMD: 'Bermudan Dollar',
      BND: 'Brunei Dollar',
      BOB: 'Bolivian Boliviano',
      BRL: 'Brazilian Real',
      BSD: 'Bahamian Dollar',
      BTC: 'Bitcoin',
      BTN: 'Bhutanese Ngultrum',
      BWP: 'Botswanan Pula',
      BYN: 'Belarusian Ruble',
      BZD: 'Belize Dollar',
      CAD: 'Canadian Dollar',
      CDF: 'Congolese Franc',
      CHF: 'Swiss Franc',
      CLF: 'Chilean Unit of Account (UF)',
      CLP: 'Chilean Peso',
      CNH: 'Chinese Yuan (Offshore)',
      CNY: 'Chinese Yuan',
      COP: 'Colombian Peso',
      CRC: 'Costa Rican Colón',
      CUC: 'Cuban Convertible Peso',
      CUP: 'Cuban Peso',
      CVE: 'Cape Verdean Escudo',
      CZK: 'Czech Republic Koruna',
      DJF: 'Djiboutian Franc',
      DKK: 'Danish Krone',
      DOP: 'Dominican Peso',
      DZD: 'Algerian Dinar',
      EGP: 'Egyptian Pound',
      ERN: 'Eritrean Nakfa',
      ETB: 'Ethiopian Birr',
      EUR: 'Euro',
      FJD: 'Fijian Dollar',
      FKP: 'Falkland Islands Pound',
      GBP: 'British Pound Sterling',
      GEL: 'Georgian Lari',
      GGP: 'Guernsey Pound',
      GHS: 'Ghanaian Cedi',
      GIP: 'Gibraltar Pound',
      GMD: 'Gambian Dalasi',
      GNF: 'Guinean Franc',
      GTQ: 'Guatemalan Quetzal',
      GYD: 'Guyanaese Dollar',
      HKD: 'Hong Kong Dollar',
      HNL: 'Honduran Lempira',
      HRK: 'Croatian Kuna',
      HTG: 'Haitian Gourde',
      HUF: 'Hungarian Forint',
      IDR: 'Indonesian Rupiah',
      ILS: 'Israeli New Sheqel',
      IMP: 'Manx pound',
      INR: 'Indian Rupee',
      IQD: 'Iraqi Dinar',
      IRR: 'Iranian Rial',
      ISK: 'Icelandic Króna',
      JEP: 'Jersey Pound',
      JMD: 'Jamaican Dollar',
      JOD: 'Jordanian Dinar',
      JPY: 'Japanese Yen',
      KES: 'Kenyan Shilling',
      KGS: 'Kyrgystani Som',
      KHR: 'Cambodian Riel',
      KMF: 'Comorian Franc',
      KPW: 'North Korean Won',
      KRW: 'South Korean Won',
      KWD: 'Kuwaiti Dinar',
      KYD: 'Cayman Islands Dollar',
      KZT: 'Kazakhstani Tenge',
      LAK: 'Laotian Kip',
      LBP: 'Lebanese Pound',
      LKR: 'Sri Lankan Rupee',
      LRD: 'Liberian Dollar',
      LSL: 'Lesotho Loti',
      LYD: 'Libyan Dinar',
      MAD: 'Moroccan Dirham',
      MDL: 'Moldovan Leu',
      MGA: 'Malagasy Ariary',
      MKD: 'Macedonian Denar',
      MMK: 'Myanma Kyat',
      MNT: 'Mongolian Tugrik',
      MOP: 'Macanese Pataca',
      MRO: 'Mauritanian Ouguiya (pre-2018)',
      MRU: 'Mauritanian Ouguiya',
      MUR: 'Mauritian Rupee',
      MVR: 'Maldivian Rufiyaa',
      MWK: 'Malawian Kwacha',
      MXN: 'Mexican Peso',
      MYR: 'Malaysian Ringgit',
      MZN: 'Mozambican Metical',
      NAD: 'Namibian Dollar',
      NGN: 'Nigerian Naira',
      NIO: 'Nicaraguan Córdoba',
      NOK: 'Norwegian Krone',
      NPR: 'Nepalese Rupee',
      NZD: 'New Zealand Dollar',
      OMR: 'Omani Rial',
      PAB: 'Panamanian Balboa',
      PEN: 'Peruvian Nuevo Sol',
      PGK: 'Papua New Guinean Kina',
      PHP: 'Philippine Peso',
      PKR: 'Pakistani Rupee',
      PLN: 'Polish Zloty',
      PYG: 'Paraguayan Guarani',
      QAR: 'Qatari Rial',
      RON: 'Romanian Leu',
      RSD: 'Serbian Dinar',
      RUB: 'Russian Ruble',
      RWF: 'Rwandan Franc',
      SAR: 'Saudi Riyal',
      SBD: 'Solomon Islands Dollar',
      SCR: 'Seychellois Rupee',
      SDG: 'Sudanese Pound',
      SEK: 'Swedish Krona',
      SGD: 'Singapore Dollar',
      SHP: 'Saint Helena Pound',
      SLL: 'Sierra Leonean Leone',
      SOS: 'Somali Shilling',
      SRD: 'Surinamese Dollar',
      SSP: 'South Sudanese Pound',
      STD: 'São Tomé and Príncipe Dobra (pre-2018)',
      STN: 'São Tomé and Príncipe Dobra',
      SVC: 'Salvadoran Colón',
      SYP: 'Syrian Pound',
      SZL: 'Swazi Lilangeni',
      THB: 'Thai Baht',
      TJS: 'Tajikistani Somoni',
      TMT: 'Turkmenistani Manat',
      TND: 'Tunisian Dinar',
      TOP: "Tongan Pa'anga",
      TRY: 'Turkish Lira',
      TTD: 'Trinidad and Tobago Dollar',
      TWD: 'New Taiwan Dollar',
      TZS: 'Tanzanian Shilling',
      UAH: 'Ukrainian Hryvnia',
      UGX: 'Ugandan Shilling',
      USD: 'United States Dollar',
      UYU: 'Uruguayan Peso',
      UZS: 'Uzbekistan Som',
      VEF: 'Venezuelan Bolívar Fuerte (Old)',
      VES: 'Venezuelan Bolívar Soberano',
      VND: 'Vietnamese Dong',
      VUV: 'Vanuatu Vatu',
      WST: 'Samoan Tala',
      XAF: 'CFA Franc BEAC',
      XAG: 'Silver Ounce',
      XAU: 'Gold Ounce',
      XCD: 'East Caribbean Dollar',
      XDR: 'Special Drawing Rights',
      XOF: 'CFA Franc BCEAO',
      XPD: 'Palladium Ounce',
      XPF: 'CFP Franc',
      XPT: 'Platinum Ounce',
      YER: 'Yemeni Rial',
      ZAR: 'South African Rand',
      ZMW: 'Zambian Kwacha',
      ZWL: 'Zimbabwean Dollar'
    };
    let arr = [];
    Object.keys(currencies).forEach(key => arr.push({ key: key, text: currencies[key], value: key }));
    return arr.orderBy(t => t.text);
  }

  public render() {
    const { t } = this.props;
    const { result, item } = this.newPerdiemStore.state;
    const currencyOptions = this.getValues();
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPerdiemStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Perdiem')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field
                    className=""
                    inline
                    required
                    error={this.newPerdiemStore.state.result && !this.newPerdiemStore.state.item.locationId && this.state.userClickedSaved}
                  >
                    <label className="first-child-form-modal">{t('Location')}</label>
                    <CountryEditor
                      nullable
                      placeholder={t('Location')}
                      value={this.state.location}
                      onChange={location => (this.newPerdiemStore.state.item.locationId = location.id)}
                    />
                  </Form.Field>

                  <Form.Field required error={(!item.perdiem || item.perdiem <= 0) && this.state.userClickedSaved}>
                    <label>{t('Perdiem')}</label>
                    <Input
                      type="number"
                      placeholder={t('Name')}
                      value={item.perdiem}
                      onChange={(e, { value }) => this.handleValue(nameof<CreatePerdiemDto>('perdiem'), value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t('HardShip')}</label>
                    <Input
                      type="number"
                      placeholder={t('HardShip')}
                      value={item.hardship}
                      onChange={(e, { value }) => this.handleValue(nameof<CreatePerdiemDto>('hardship'), value)}
                    />
                  </Form.Field>

                  <Form.Field required error={!item.currency && this.state.userClickedSaved}>
                    <label>{t('Currency')}</label>
                    <Dropdown
                      search
                      selection
                      allowAdditions
                      options={currencyOptions as any}
                      placeholder={t('Currency')}
                      value={item.currency}
                      onChange={this.onCurrencyChange}
                      additionLabel={t('Add ')}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPerdiemView);
