import React, { memo, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDto } from 'stores/users/users-store';
import { CountryFlag } from 'widgets/bussiness/country-flag';

export interface SchedulerInstructorRowProps extends WithTranslation {
  showStar: boolean;
  instructor: UserDto;
}

const SchedulerInstructorRow: FC<SchedulerInstructorRowProps> = ({ instructor: { roles, location, firstName, lastName }, showStar }) => {
  const instructorLocation = (roles || []).find(item => item.role.name === 'Instructor');
  return (
    <div className="instructor-component__wrapper">
      <div className="instructor-component__star-wrapper">
        {showStar && <FontAwesomeIcon className="instructor-matches" icon={faStar} />}
      </div>
      <div className="instructor-location__wrapper">
        <b className="instructor-location__name">
          {lastName}, {firstName}
        </b>
        <div className="instructor-location__title">
          <CountryFlag countryName={instructorLocation.location?.isoCode} />
          <p className="instructor-location__info">{instructorLocation?.location?.location || ''}</p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(memo(SchedulerInstructorRow));
