import ReactDOMServer from 'react-dom/server';
import React from 'react';
import RequestDragComponent from '../shared-scheduler-components/request-component/request-drag.component';
import { DragHelper, DomHelper, Rectangle } from '@planit/bryntum-scheduler';
import { ToastComponent } from './toast-component';
import { DateTimeService } from 'services/datetime-service';
import i18n from 'i18n';

export default class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      // Don't drag the actual row element, clone it
      cloneTarget: true,
      mode: 'translateXY',
      hideOriginalElement: false,
      // Only allow drops on the schedule area
      dropTargetSelector: this.dropTargetSelector,

      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: this.targetSelector
    };
  }

  construct(config) {
    super.construct(config);

    this.on({
      dragstart: this.onTaskDragStart,
      drag: this.onTaskDrag,
      drop: this.onTaskDrop,
      thisObj: this
    });
  }

  onTaskDragStart(data) {
    // this.onTaskDragStart(data);
    const { context } = data;
    const proxy = context.element;
    const me = this;

    if (!proxy.classList.contains('draft')) {
      document.body.style = 'cursor: grabbing';
      const mouseX = context.clientX;

      // const el = context.task ? context.task[0] : context.grabbed.id;
      let el;
      if (context.task) {
        el = context.task[0];
      } else {
        el = context.grabbed.id;
      }
      const task = context.task && context.task.length > 0 ? context.task[0] : me.data.filter(item => item.id === el);
      // const newWidth = me.schedule.timeAxisViewModel.getDistanceForDuration(task.durationMS);
      const newWidth = 50;
      // save a reference to the task so we can access it later
      context.task = task;
      proxy.id = context.grabbed.id;
      const grabbed = context.grabbed;
      context.initialClass = grabbed.className;
      grabbed.className = 'dragged-request';
      // Mutate dragged element (grid row) into an event bar
      proxy.classList.remove(this.targetSelector.substring(1, this.targetSelector.length + 1));
      proxy.classList.add('b-sch-drag-request');
      proxy.innerHTML = ReactDOMServer.renderToString(<RequestDragComponent {...task[0]} />);

      // If the new width is narrower than the grabbed element...
      if (context.grabbed.offsetWidth > newWidth) {
        const proxyRect = Rectangle.from(context.element);

        // If the mouse is off (nearly or) the end, centre the element on the mouse
        if (mouseX > proxyRect.x + newWidth - 20) {
          context.newX = context.elementStartX = context.elementX = mouseX - newWidth / 2;
          DomHelper.setTranslateX(proxy, context.newX);
        }
      }
      // Prevent tooltips from showing while dragging
      me.schedule.element.classList.add('b-dragging-event');
    } else {
      me.abort();
    }
  }

  onTaskDrag(data) {
    const { context } = data;
    let schTargetElement = context.target.closest('.b-grid-subgrid');
    let previousPlace = context.target.closest('.dragged-request');
    let targetElement = context.target.closest(this.targetSelector);

    const me = this,
      resource = context.target && me.schedule.resolveResourceRecord(context.target);
    // Drop allowed area
    context.valid = context.valid && Boolean(schTargetElement || previousPlace || targetElement);
    // Save reference to resource so we can use it in onTaskDrop
    context.resource = resource;
  }

  // Drop callback after a mouse up, take action and transfer the unplanned task to the real EventStore (if it's valid)
  onTaskDrop({ context: { task, target, element, grabbed, initialClass } }) {
    let mergeRequests = target.closest(this.targetSelector);
    let previousPlace = target.closest('.dragged-request');
    let schTargetElement = target.closest('.b-grid-subgrid');
    const me = this;

    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    const date = me.schedule.getDateFromCoordinate(DomHelper.getTranslateX(element), 'round', false);
    const resource = target && me.schedule.resolveResourceRecord(target);
    if (mergeRequests || previousPlace || schTargetElement || (date && resource)) {
      // if (context.valid && targetElement) {
      // Try resolving event record from target element, to determine if drop was on another event
      let targetEventRecord = me.schedule.resolveEventRecord(target);

      if (schTargetElement || mergeRequests) {
        // Dropped on a scheduled event, display toast
        if (targetEventRecord) this.onRequestEventMerge(task[0], targetEventRecord.event);
        else if (mergeRequests) {
          const targetRequest = this.data.find(({ id }) => id === mergeRequests.id);
          this.onRequestsMerge(task[0], targetRequest);
        } else if (resource && resource.instructor)
          this.onNewEventFromRequestDragOnInstructor(task[0], resource.instructor, DateTimeService.toString(date));
        else if (resource && resource.location) this.onNewEventFromRequestDragOnLocation(task[0], resource, DateTimeService.toString(date));
      }

      me.context.finalize ? me.context.finalize() : console.error('something bad happened with drag and drop');
    } else {
      ToastComponent({ text: i18n.t('Invalid drop position'), type: 'error-toast' });
      // WidgetHelper.toast(``);
      me.abort();
    }
    document.body.style = 'cursor: auto';
    grabbed.className = initialClass;
    me.schedule.element.classList.remove('b-dragging-event');
  }
}

// eof
