import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import i18n from 'i18n';
import { FormStore } from 'stores/formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface VisaDto extends BaseDto {
  id: string;
  name: string;
  internationalTreatyRequired;
}

export interface CreateVisaDto {
  name: string;
  internationalTreatyRequired: boolean;
}

export interface ChangeVisaDto {
  id: string;
  name: string;
  internationalTreatyRequired: boolean;
}

export class VisaValidator extends AbstractValidator<VisaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

export class CreateVisaValidator extends AbstractValidator<CreateVisaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

export class ChangeVisaValidator extends AbstractValidator<ChangeVisaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

@repository('@@VISAS', 'visas.summary')
export class VisaStore extends DataStore<VisaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-visa';
  retrievePath = 'get-visas';
  updatePath = 'update-visa';
  deletePath = '';

  protected validate(item: VisaDto) {
    return new VisaValidator().validate(item);
  }

  constructor() {
    super('VISA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@VISAS', 'visas.new')
export class NewVisaStore extends FormStore<CreateVisaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-visa';
  retrievePath = 'get-visas';
  updatePath = 'update-visa';

  protected validate(item: CreateVisaDto) {
    return new CreateVisaValidator().validate(item);
  }

  constructor() {
    super('NEW_VISA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@VISAS', 'visas.change')
export class ChangeVisaStore extends FormStore<ChangeVisaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-visa';
  retrievePath = 'get-visas';
  updatePath = 'update-visa';

  protected validate(item: ChangeVisaDto) {
    return new ChangeVisaValidator().validate(item);
  }

  constructor() {
    super('CHANGE_VISA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@VISAS', 'dropdownvisas.summary')
export class DropDownVisasStore extends DataStore<VisaDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-visas';
  updatePath = '';
  deletePath = '';

  protected validate(item: VisaDto) {
    return new VisaValidator().validate(item);
  }

  constructor() {
    super('DROPDOWN_VISA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, visasIds?: string[], search?: string): Promise<QueryResult<VisaDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let visasDto = this.state.items.map(({ item }) => item);

      if ((visasIds || []).length > 0) {
        visasDto = visasDto.filter(x => visasIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        visasDto = visasDto.filter(x => x.name.startsWith(search));
      }

      visasDto = visasDto.filter(x => x.internationalTreatyRequired);

      let visasResult = { items: visasDto, count: visasDto.length } as QueryResult<VisaDto>;

      return new Promise<QueryResult<VisaDto>>(resolve => resolve(visasResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<VisaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<VisaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}
