import React, { FC, Fragment, memo, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SkillAnswersDto, MachineUnitAnswersDto, TnaFormItemDto } from 'stores/assessments/forms/tna-forms-store';
import { TNAFormContext } from './tna-edit-form';
import OverallRecommendationEditor from 'widgets/bussiness/overall-recommendation-editor';
import { ItemReference } from 'stores/dataStore';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';

interface TnaFormTableScoreProps extends WithTranslation {
  scoreTitle?: string;
  section: SkillAnswersDto[] | MachineUnitAnswersDto[];
  isSkill: boolean;
  onRecommendationChange: (value: string, id: string, isMachineUnit: boolean) => void;
  onOverallRecommendationChange?: (value: ItemReference /*, machinemodelId: string*/) => void;
  overallRecommendationId?: string;
  overallRecommendation?: string;
  machineModelId?: string;
}

const TnaFormTableScore: FC<TnaFormTableScoreProps> = ({
  t,
  section,
  scoreTitle,
  isSkill,
  onRecommendationChange,
  onOverallRecommendationChange,
  overallRecommendationId,
  overallRecommendation,
  machineModelId
}) => {
  const { getAStatus, isAdminOrPoC, getAssessorType, ...contextValues } = useContext(TNAFormContext);
  const isLineManager = getAssessorType()?.type === 'lineManager';
  const isFunctionalExpert = getAssessorType()?.type === 'functionalExpert';

  const { isOnlyAdmin } = contextValues;
  const status = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => (summary.item as TnaFormItemDto).status) as string;

  const overallRecom =
    (overallRecommendationId && {
      id: overallRecommendationId || '',
      title: overallRecommendation || ''
    }) ||
    (null as ItemReference);

  const getTableSection = () =>
    (section as SkillAnswersDto[]).map(({ average, skillId, skillName, functionalExpertRecomendation, ...s }) => (
      <Fragment key={skillId}>
        <p>{t(skillName)}</p>
        <p>{average?.employeeAverage != null ? Number(average.employeeAverage).toFixed(1) : t('n/a')}</p>
        <p>{average?.functionalExpertAverage != null ? Number(average.functionalExpertAverage).toFixed(1) : t('n/a')}</p>
        <p>{average?.lineManagerAverage != null ? Number(average.lineManagerAverage).toFixed(1) : t('n/a')}</p>
        <p>{average?.totalAverage != null ? Number(average.totalAverage).toFixed(1) : t('n/a')}</p>
        {!(s as any).isTotal && isSkill && !isLineManager && (
          <input
            value={functionalExpertRecomendation || ''}
            onChange={({ target: { value } }) => onRecommendationChange(value, skillId, false)}
          />
        )}
        {(s as any).isTotal && isSkill && !isLineManager && (
          <div>
            <OverallRecommendationEditor
              nullable
              clearable={true}
              value={overallRecom}
              onChange={value => onOverallRecommendationChange(value)}
              placeholder={t('Overall Recommendation')}
              reloadOnChange={true}
              loadDataOnOpen={true}
              machineModelId={machineModelId}
              readOnly={
                !(((!isAdminOrPoC && (isFunctionalExpert || isLineManager) && status !== 'Done') || isOnlyAdmin) /* && status === 'Done'*/)
              }
            />
          </div>
        )}
      </Fragment>
    ));

  const defaultClass = 'machine-related__score-by-skills-table';
  const className = isLineManager || !isSkill ? defaultClass + ' line-manager-view' : defaultClass;
  return (
    section &&
    section.length > 0 && (
      <div className={className}>
        <h2>{t(scoreTitle)}</h2>
        <p>{t('Employee Self-Evaluation')}</p>
        <p>{t('Functional Expert Evaluation')}</p>
        <p>{t('Line Manager Evaluation')}</p>
        <p>{t('Total Score')}</p>
        {isSkill && !isLineManager && <p>{t('Recommendations from Functional Expert')}</p>}
        {getTableSection()}
      </div>
    )
  );
};

export default withTranslation()(memo(TnaFormTableScore));
