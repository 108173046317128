import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import './user-profile-list.less';

export interface UserProfileListViewModel {
  id: string;
  employee: string;
  employeeId: string;
  sfPosition: string;
  location: string;
  profile: string;
}

interface UserProfileListProps extends WithTranslation {
  canRemove?: boolean;
  onChange?: (value: UserProfileListViewModel[]) => void;
  userProfiles?: UserProfileListViewModel[];
}

class UserProfileList extends Component<UserProfileListProps> {
  private handleOnUserRemove = (index: number) => {
    const employees = [...this.props.userProfiles];
    employees.splice(index, 1);
    this.props.onChange && this.props.onChange(employees);
  };

  render() {
    const { t, userProfiles: usersProfiles } = this.props;
    return (
      <div className="user-profile-list">
        <p className="user-profile-list__column-title">{t('Employee')}</p>
        <p className="user-profile-list__column-title">{t('SAP ID')}</p>
        <p className="user-profile-list__column-title">{t('SF Position')}</p>
        <p className="user-profile-list__column-title">{t('Location')}</p>
        <p className="user-profile-list__column-title">{t('Profile')}</p>
        <p className="user-profile-list__column-title text__transparent">X</p>

        {usersProfiles.map((userProfile, index) => {
          return (
            <Fragment key={`user-profile-${userProfile.id}`}>
              <div className="border-bottom-gray">
                <p>{userProfile.employee}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{userProfile.employeeId}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{userProfile.sfPosition}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{userProfile.location}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{userProfile.profile}</p>
              </div>

              <div className="border-bottom-gray">
                {this.props.canRemove ? (
                  <div className="flex-center">
                    <Icon className="clickable-icon" color="red" name="remove" size="large" onClick={_ => this.handleOnUserRemove(index)} />
                  </div>
                ) : (
                  <p> </p>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(UserProfileList);
