import { AxiosResponse } from 'axios';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import { container } from 'inversify.config';
import { AsyncAction, repository } from 'redux-scaffolding-ts';
import HttpService from 'services/http-service';
import { DataModel, DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { BaseDto, CommandResult, ItemResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { isNullOrWhiteSpaces, firstToLowerCase } from 'utils/useful-functions';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { titlelizeCamelCase } from 'utils/event-utils';

export interface StatusConfig {
  active: boolean;
  actorRoles: string[];
  rolesNotifiedOnStatusChanged: { [key: string]: string[] };
  rolesNotifiedOnChanges: { [key: string]: string[] };
  rolesRequiredToValidateStatus: string[];
  pastTime?: string;
  rolesNotifiedPastTime?: { [key: string]: string[] };
}

export enum EventFieldGroups {
  Basic = 10,
  RequestDetails = 20,
  EventDetails = 30,
  SupportDetails = 40
}

export enum EventTypeInstructorConfiguration {
  Yes = 10,
  EventOnly = 20,
  No = 30
}

export enum EventUserRoles {
  Planner = 10,
  PoC = 20,
  Instructor = 30,
  Participants = 40,
  FactoryLead = 60,
  GlobalEngineering = 70,
  GlobalManufacturing = 80,
  RegionalManufacturingVP = 90,
  PlannerMTC = 100
}

export enum NotificationType {
  ToDo = 10,
  Email = 20
}

export enum EventTypeCategory {
  Custom = 10,
  Vacations = 20,
  Extended = 30
}

export enum EventTypePriorityLevel {
  P1 = 10,
  P2 = 20,
  P3 = 30
}

export const getStatusDefault = (pastTime: boolean = false) => {
  return (pastTime
    ? {
        active: true,
        actorRoles: [],
        rolesNotifiedOnStatusChanged: {},
        rolesNotifiedPastTime: {},
        pastTime: '180.00:00:00'
      }
    : {
        active: true,
        actorRoles: [],
        rolesNotifiedOnStatusChanged: {},
        rolesNotifiedOnChanges: {},
        rolesRequiredToValidateStatus: []
      }) as StatusConfig;
};

export interface EventTypeDto extends BaseDto {
  id: string;
  name: string;
  originalName: string;
  parentEventTypeId: string;
  parentName: string;
  active: boolean;
  eventsColor: string;
  eventsIcon: string;
  feedbacks: boolean;
  preEventTest: boolean;
  practicalTest: boolean;
  postEventTest: boolean;
  participants: boolean;
  participantsOptional: boolean;
  directEventsAllowed: boolean;
  instructor: string;
  priorityLevel: string;
  rolesThatCreateRequests: string[];
  roleThatValidateRequests: string;
  rolesThatCreateDirectEvents: string[];
  roleThatOwnEvents: string;

  draft: StatusConfig;
  planned: StatusConfig;
  inProgress: StatusConfig;
  completed: StatusConfig;
  closed: StatusConfig;

  eventTypeCategory: string;
  requestFieldGroups: string[];

  rolesThatValidateRequestsExtended: string[];
  rolesThatOwnEventsExtended: string[];
  title: string;

  originalEventTypeId: string; // all related eventtypes has the same originalEventTypeId, the root event type
  eventGroupRelationId: string;
}

export interface CreateEventTypeDto {
  id: string;
  name: string;
  eventsColor: string;
  eventsIcon: string;
  feedbacks: boolean;
  preEventTest: boolean;
  practicalTest: boolean;
  postEventTest: boolean;
  participants: boolean;
  participantsOptional: boolean;
  directEventsAllowed: boolean;
  instructor: string;
  priorityLevel: string;

  rolesThatCreateRequests: string[];
  roleThatValidateRequests: string;
  rolesThatCreateDirectEvents: string[];
  roleThatOwnEvents: string;

  draft: StatusConfig;
  planned: StatusConfig;
  inProgress: StatusConfig;
  completed: StatusConfig;
  closed: StatusConfig;

  requestFieldGroups: string[];
  parentId: string;
  category: string;

  rolesThatValidateRequestsExtended: string[];
  rolesThatOwnEventsExtended: string[];

  eventGroupRelationId: string;
}

export interface ChangeEventTypeDto {
  id: string;
  active: boolean;
}

export const rolesWFConst: string[] = ['Planner', 'PlannerMTC', 'PoC', 'Instructor', 'Employee'];
export const newRolesWFConst: string[] = ['FactoryLead', 'GlobalEngineering', 'GlobalManufacturing', 'RegionalManufacturingVP'];
export const extendedRolesWFConst: string[] = rolesWFConst.concat(newRolesWFConst);
export const rolesRequestConst: string[] = ['Planner', 'PlannerMTC', 'PoC', 'Instructor'];
export const roleToTitleDict: { [key: string]: string } = {
  Planner: 'Planner TFT',
  PoC: 'PoC',
  Instructor: 'Instructor',
  Employee: 'Employee',
  FactoryLead: 'Factory Lead',
  GlobalEngineering: 'Global Engineering',
  GlobalManufacturing: 'Global Manufacturing',
  RegionalManufacturingVP: 'Regional Manufacturing VP',
  PlannerMTC: 'Planner MTC'
};

class NotificationConfigurationValidator extends ExtendedAbstractValidator<{ key: string; value: string[] }> {
  private rolesMin = rolesWFConst.map(x => firstToLowerCase(x));
  private notificationTypes = ['ToDo', 'Email'];

  constructor(name: string, prop: string, onErrors?: (...failures: ValidationFailure[]) => void, moreRoles?: string[]) {
    super(onErrors);

    this.validateIf(x => x)
      .fulfills(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.key) &&
          (moreRoles ? moreRoles.map(rr => firstToLowerCase(rr)) : this.rolesMin).includes(x.key) &&
          x.value != null
      )
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status ${prop} is invalid`));

    this.validateIfEachString(x => x.value)
      .isIn(this.notificationTypes)
      .when(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.key) &&
          (moreRoles ? moreRoles.map(rr => firstToLowerCase(rr)) : this.rolesMin).includes(x.key) &&
          x.value != null
      )
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status ${prop} has invalid data`));
  }
}

class StatusConfigValidator extends ExtendedAbstractValidator<StatusConfig> {
  private rolesCustom = rolesWFConst;
  private rolesExtended = [...rolesWFConst, 'FactoryLead'];
  constructor(name: string, onErrors?: (...failures: ValidationFailure[]) => void, moreRoles?: string[]) {
    super(onErrors);

    this.validateIfIterable(x => x.actorRoles)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x.active)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status actor roles are required`));

    this.validateIfIterable(x => x.actorRoles)
      .isDefined()
      .isNotNull()
      .isEmpty()
      .when(x => !x.active)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status actor roles is invalid`));

    this.validateIfEach(x => x.actorRoles)
      .isIn(moreRoles ? moreRoles : this.rolesCustom)
      .when(x => x.active)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status actor roles contains invalid data`));

    this.validateIfIterable(x => x.rolesRequiredToValidateStatus)
      .isDefined()
      .isNotNull()
      .when(_ => name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status validator roles is invalid`));

    this.validateIfIterable(x => x.rolesRequiredToValidateStatus)
      .isUndefined()
      .when(_ => name === 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status validator roles is invalid`));

    this.validateIfIterable(x => x.rolesRequiredToValidateStatus)
      .isEmpty()
      .when(x => x != null && x.rolesRequiredToValidateStatus != null && !x.active && name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status validator roles is invalid`));

    this.validateIfEach(x => x.rolesRequiredToValidateStatus)
      .isIn(moreRoles ? this.rolesExtended : this.rolesCustom)
      .when(x => x != null && x.rolesRequiredToValidateStatus != null && x.active && name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status validator roles contains invalid data`));

    this.validateIf(x => x.rolesNotifiedPastTime)
      .isDefined()
      .isNotNull()
      .when(_ => name === 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status 6 months notification roles is invalid`));

    this.validateIf(x => x.rolesNotifiedPastTime)
      .isUndefined()
      .when(_ => name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status 6 months notification roles is invalid`));

    this.validateIfIterable(x => getProperties(x.rolesNotifiedPastTime))
      .isEmpty()
      .when(x => x != null && x.rolesNotifiedPastTime != null && !x.active)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status 6 months notification roles is invalid`));

    this.validateIf(x => x)
      .fulfills(x =>
        (getProperties(x.rolesNotifiedPastTime) || []).every(p =>
          new NotificationConfigurationValidator(
            name,
            'on status 6 months notification roles',
            this.addErrors,
            moreRoles ? this.rolesExtended : this.rolesCustom
          )
            .extendValidate(p)
            .isValid()
        )
      )
      .when(x => x != null && (x.rolesNotifiedPastTime || []).length !== 0)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status status 6 months notification roles contains invalid data`));

    this.validateIf(x => x.rolesNotifiedOnStatusChanged)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on status changed roles is invalid`));

    this.validateIfIterable(x => getProperties(x.rolesNotifiedOnStatusChanged))
      .isEmpty()
      .when(x => x != null && x.rolesNotifiedOnStatusChanged != null && !x.active)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on status changed roles is invalid`));

    this.validateIf(x => x)
      .fulfills(x =>
        (getProperties(x.rolesNotifiedOnStatusChanged) || []).every(p =>
          new NotificationConfigurationValidator(name, 'on status changed roles', this.addErrors, moreRoles).extendValidate(p).isValid()
        )
      )
      .when(x => x != null && (x.rolesNotifiedOnStatusChanged || []).length !== 0)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on status changed roles contains invalid data`));

    this.validateIf(x => x.rolesNotifiedOnChanges)
      .isDefined()
      .isNotNull()
      .when(_ => name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on changes roles is invalid`));

    this.validateIfIterable(x => getProperties(x.rolesNotifiedOnChanges))
      .isEmpty()
      .when(x => x != null && x.rolesNotifiedOnChanges != null && !x.active && name !== 'closed')
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on changes roles is invalid`));

    this.validateIf(x => x)
      .fulfills(x =>
        (getProperties(x.rolesNotifiedOnChanges) || []).every(p =>
          new NotificationConfigurationValidator(name, 'on changes roles', this.addErrors, moreRoles).extendValidate(p).isValid()
        )
      )
      .when(x => x != null && !x.active && name !== 'closed' && (x.rolesNotifiedOnChanges || []).length !== 0)
      .withFailureMessage(i18n.t(`${titlelizeCamelCase(name)} status on changes roles contains invalid data`));
  }
}

export class CreateEventTypeValidator extends ExtendedAbstractValidator<CreateEventTypeDto> {
  private roles = rolesWFConst;
  private instructorChoices = ['Yes', 'No', 'EventOnly'];
  private fieldGroups = ['Basic', 'RequestDetails', 'EventDetails'];
  private extendedRoles = extendedRolesWFConst;
  private extendedfieldGroups = this.fieldGroups.concat(['SupportDetails']);

  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIfString(o => o.eventsColor)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Color is required'));

    this.validateIf(x => x)
      .fulfills(x => {
        const owners = [
          ...new Set([...(x.roleThatOwnEvents ? [x.roleThatOwnEvents] : []), ...(x.rolesThatOwnEventsExtended || [])])
        ].filter(r => !isNullOrWhiteSpaces(r));
        return owners.length !== 0;
      })
      .withFailureMessage(i18n.t('Event owner is required'));

    this.validateIf(x => x)
      .fulfills(x => {
        const owners = [
          ...new Set([...(x.roleThatOwnEvents ? [x.roleThatOwnEvents] : []), ...(x.rolesThatOwnEventsExtended || [])])
        ].filter(r => !isNullOrWhiteSpaces(r));
        if (owners.length === 0) return true;
        if (EventTypeCategory[x.category] === EventTypeCategory.Extended) {
          return owners.all(r => this.extendedRoles.includes(r));
        }
        return owners.all(r => this.roles.includes(r));
      })
      .withFailureMessage(i18n.t('Event owner is wrong'));

    this.validateIfString(o => o.roleThatValidateRequests)
      .isNotEmpty()
      .when(o => o.rolesThatCreateRequests.length > 0 && EventTypeCategory[o.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Request validator is required when request creator is added.'));

    this.validateIfString(o => o.instructor)
      .isNotEmpty()
      .isIn(this.instructorChoices)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Instructor prop is invalid'));

    this.validateIfEach(o => o.rolesThatCreateRequests)
      .isIn(this.roles)
      .whenDefined()
      .whenNotNull()
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Request Creator role is invalid'));

    this.validateIfEach(o => o.rolesThatCreateDirectEvents)
      .isIn(this.roles)
      .whenDefined()
      .whenNotNull()
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Direct Event Creator role is invalid'));

    this.validateIfString(o => o.roleThatValidateRequests)
      .isIn(this.roles)
      .whenNotEmpty()
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Request validator prop is invalid'));

    this.validateIfIterable(o => o.requestFieldGroups)
      .isNotEmpty()
      .contains('Basic')
      .withFailureMessage(i18n.t('Request Fields are invalid'));

    this.validateIfEach(o => o.requestFieldGroups)
      .isIn(this.fieldGroups)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Request Fields are invalid'));

    this.validateIfAny(x => x.draft)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('draft', this.addErrors).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Draft status config is invalid'));

    this.validateIfAny(x => x.planned)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('planned', this.addErrors).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Planned status config is invalid'));

    this.validateIfAny(x => x.inProgress)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('inProgress', this.addErrors).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('In Progress status config is invalid'));

    this.validateIfAny(x => x.completed)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('completed', this.addErrors).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Completed status config is invalid'));

    this.validateIfAny(x => x.closed)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('closed', this.addErrors).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('Closed status config is invalid'));

    this.validateIf(x => x)
      .fulfills(x => (x.rolesThatCreateRequests || []).length !== 0 || (x.rolesThatCreateDirectEvents || []).length !== 0)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Custom)
      .withFailureMessage(i18n.t('No one can create a request or event'));

    this.validateIfString(x => x.parentId)
      .isUuid('4')
      .whenDefined()
      .whenNotNull()
      .withFailureMessage(i18n.t('Invalid parent Id'));

    /** Validations for extended */
    this.validateIfEach(o => o.requestFieldGroups)
      .isIn(this.extendedfieldGroups)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Request Fields are invalid'));

    this.validateIfAny(x => x.draft)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('draft', this.addErrors, this.extendedRoles).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Draft status config is invalid'));

    this.validateIfAny(x => x.planned)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('planned', this.addErrors, this.extendedRoles).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Planned status config is invalid'));

    this.validateIfAny(x => x.inProgress)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('inProgress', this.addErrors, this.extendedRoles).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('In Progress status config is invalid'));

    this.validateIfAny(x => x.completed)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('completed', this.addErrors, this.extendedRoles).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Completed status config is invalid'));

    this.validateIfAny(x => x.closed)
      .isDefined()
      .isNotNull()
      .fulfills(x => new StatusConfigValidator('closed', this.addErrors, this.extendedRoles).extendValidate(x).isValid())
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Closed status config is invalid'));

    this.validateIfEach(x => x.rolesThatCreateRequests)
      .isNotNull()
      .isIn(this.extendedRoles)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Request Creator prop is invalid'));

    this.validateIf(x => x.rolesThatCreateRequests)
      .fulfills(x => x.length > 0 && x.some(y => y === 'FactoryLead'))
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Factory Lead must be in as Request creator'));

    this.validateIfEach(x => x.rolesThatValidateRequestsExtended)
      .isNotNull()
      .isIn(this.extendedRoles)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Request Validator prop is invalid'));

    this.validateIf(x => x.rolesThatValidateRequestsExtended)
      .fulfills(x => x.length > 0)
      .when(x => EventTypeCategory[x.category] === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Request Validator is required'));

    this.validateIfString(o => o.eventGroupRelationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Group is required'));
  }
}

class EventTypesStoreActions {
  public static EVENT_TYPE_TOGGLE_ACTIVE = 'EVENT_TYPE_TOGGLE_ACTIVE';
}

@repository('@@EVENT_TYPES', 'event-types.summary')
export class EventTypesStore extends DataStore<EventTypeDto> {
  EVENT_TYPE_TOGGLE_ACTIVE = 'EVENT_TYPE_TOGGLE_ACTIVE';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-event-type';
  retrievePath = 'get-event-types';
  retrieveOnePath = 'get-event-type';
  updatePath = 'update-event-type';
  deletePath = null;

  protected validate(_: EventTypeDto) {
    return new ValidationResult();
  }

  public async getById(id: string): Promise<EventTypeDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<EventTypeDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('EVENT_TYPE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: _ => {}
    });

    this.addReducer(this.EVENT_TYPE_TOGGLE_ACTIVE, this.onToggleAvailability, 'AsyncAction');
  }

  public async toggleAvailability(item: EventTypeDto) {
    const httpService = container.get(HttpService);

    const dto = { id: item.id, active: !item.active } as ChangeEventTypeDto;
    return await this.dispatchAsync(
      EventTypesStoreActions.EVENT_TYPE_TOGGLE_ACTIVE,
      httpService.put<ChangeEventTypeDto, CommandResult<EventTypeDto>>(`${this.baseUrl}/${this.updatePath}`, dto)
    );
  }

  protected onToggleAvailability = (): AsyncAction<AxiosResponse<ItemResult<EventTypeDto>>, DataModel<EventTypeDto>> => {
    return {
      onStart: () => {
        return { ...this.state, isBusy: true };
      },
      onSuccess: result => {
        let items = this.state.items;
        if (result.data.isSuccess) {
          const item = result.data.item;
          const model = items.firstOrDefault(i => i.item.id === item.id);
          if (model != null) {
            model.item = item;
            items = [...items];
          }
        }
        return {
          ...this.state,
          isBusy: false,
          items,
          result: {
            isSuccess: result.data.isSuccess,
            items: [],
            messages: result.data.messages
          }
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? error.response.data
            : {
                isSuccess: false,
                items: [],
                messages: [{ body: error.message || error, level: 'Error' }]
              }
      })
    };
  };
}

@repository('@@EVENT_TYPES', 'event-types.dropdown')
export class DropDownEventTypesStore extends DataStore<EventTypeDto> {
  EVENT_TYPE_TOGGLE_ACTIVE = 'EVENT_TYPE_TOGGLE_ACTIVE';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-event-types';
  retrieveOnePath = '';
  updatePath = '';
  deletePath = null;

  protected validate(_: EventTypeDto) {
    return new ValidationResult();
  }

  constructor() {
    super('EVENT_TYPEDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: _ => {}
    });
  }

  public async getAllAsync(query: Query, eventTypesIds?: string[], search?: string): Promise<QueryResult<EventTypeDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let eventTypesDto = this.state.items.map(({ item }) => item);

      if ((eventTypesIds || []).length > 0) {
        eventTypesDto = eventTypesDto.filter(x => eventTypesIds.includes(x.originalEventTypeId));
      }

      if (!isNullOrWhiteSpaces(search)) {
        eventTypesDto = eventTypesDto.filter(x => x.name.startsWith(search));
      }

      let locationsResult = { items: eventTypesDto, count: eventTypesDto.length } as QueryResult<EventTypeDto>;
      return new Promise<QueryResult<EventTypeDto>>(resolve => resolve(locationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<EventTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<EventTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@EVENT_TYPES', 'event-types.new')
export class NewEventTypeStore extends FormStore<CreateEventTypeDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-event-type';
  retrievePath = 'get-event-types';
  retrieveOnePath = 'get-event-type';
  updatePath = null;
  deletePath = null;

  protected validate(item: CreateEventTypeDto) {
    return new CreateEventTypeValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_EVENT_TYPE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
