import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Header, InputOnChangeData } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { UserStore, UserDto } from 'stores/users/users-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import { Roles } from 'services/identity-service';
import { ColumnsNeeds } from 'widgets/collections/util-table';
import { firstToUpperCase, isNullOrWhiteSpaces } from 'utils/useful-functions';
import { decamelCase } from 'utils/event-utils';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import RoleSelector from 'widgets/bussiness/selectors/system-role-selector';
import { GetLocationNameAndCountry } from 'utils/userinfo-functions';
// import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';

interface ChooseUserProps extends WithTranslation {
  onAddUsers?: (users: UserDto[]) => void;
  onCloseUsersModal?: () => void;
  alreadySelectedUserIds?: string[];
  title?: string;
  acceptBtnTitle?: string;
  usersStore?: UserStore;
  positionCodeId?: string;
  noFilters?: boolean;
  /** If specificLocation is provided, roleLocation should match availables roles*/
  specificLocation?: boolean;
  /** If specificLocation is provided, roleLocation should match availables roles*/
  roleLocation?: Roles;
  columns?: ColumnsNeeds<UserDto>[];
  showEmployeeIdFilter?: boolean;
  showPositionCodeFilter?: boolean;
  showRoleFilter?: boolean;
  showLocationFilter?: boolean;
}

interface UserFilters {
  firstNameOrLastName: string;
  sapId: string;
  sfPosition: string;
  personnelArea: string;
  role: ItemReference;
  location: LocationItemReference | ItemReference;
  positionCode: ItemReference;
}
interface ChooseUserState {
  query: Query;
  activeFilters: string[];
  selectedUsers: UserDto[];
  filters: UserFilters;
}

@connect(['usersStore', UserStore])
class ChooseUser extends React.Component<ChooseUserProps, ChooseUserState> {
  state: ChooseUserState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10,
      parameters: { IsActive: 'true' }
    },
    activeFilters: [],
    selectedUsers: [],
    filters: {
      firstNameOrLastName: '',
      sapId: '',
      sfPosition: '',
      personnelArea: '',
      role: null,
      location: null,
      positionCode: null
    }
  };

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  private buildODataFilter = (filters: UserFilters) => {
    const oDataFilter: any = [{ enabled: true }];

    if (!isNullOrWhiteSpaces(filters.firstNameOrLastName))
      oDataFilter.push(
        `(contains(tolower(surname),'${filters.firstNameOrLastName.toLocaleLowerCase()}') or contains(tolower(name),'${filters.firstNameOrLastName.toLocaleLowerCase()}'))`
      );
    if (!isNullOrWhiteSpaces(filters.sapId)) oDataFilter.push({ 'tolower(EmployeeId)': { contains: filters.sapId } });

    if (!isNullOrWhiteSpaces(filters.sfPosition)) oDataFilter.push({ sfPosition: { contains: filters.sfPosition } });

    if (filters.positionCode) oDataFilter.push(`PositionCodeId eq ${filters.positionCode.id}`);

    if ((this.props.alreadySelectedUserIds || []).length !== 0)
      oDataFilter.push(`not(id in (${this.props.alreadySelectedUserIds.join(',')}))`);

    return oDataFilter;
  };

  private onAddUsers = () => {
    const { selectedUsers } = this.state;
    if ((selectedUsers || []).length === 0) return;
    if (this.props.onAddUsers) this.props.onAddUsers(selectedUsers);
    this.props.onCloseUsersModal();
  };

  private onCancel = () => {
    this.props.onCloseUsersModal();
  };

  private load = async () => {
    const { query, filters } = this.state;
    try {
      if (filters?.location?.id) this.props.usersStore.getEmployeeLocationsOData(query, [filters.location.id], filters?.role?.title);
      else if (filters.role) this.props.usersStore.getAllUsersWithRoleAsync(query, filters.role.title);
      else this.props.usersStore.getAllAsync(query);
    } catch (error) {
      console.error({ error });
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);

  private handlePageChange = (skip: number, take: number) => this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);

  private handleFilterChange = (filters, oDatafilters) =>
    this.setState(({ query }) => ({ filters, query: { ...query, filter: oDatafilters, skip: 0 } }), this.load);

  private onEmployeeNameFilterChanged = (_, { value }: InputOnChangeData) =>
    this.refreshTable({ ...this.state.filters, firstNameOrLastName: value });

  private onSFPositionFilterChanged = (_, { value }: InputOnChangeData) => this.refreshTable({ ...this.state.filters, sfPosition: value });

  private refreshTable = (filters: UserFilters) => {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  };

  private handleOnPositionCodeChange = (positionCode: PositionCodeDto) =>
    this.refreshTable({ ...this.state.filters, positionCode: positionCode ? { id: positionCode.id, title: positionCode.code } : null });

  private handleOnEmployeeIdChange = (_, { value }: InputOnChangeData) => this.refreshTable({ ...this.state.filters, sapId: value });

  private selectedUsersHandler = (selectedUsers: UserDto[]): void => this.setState({ selectedUsers });

  private handleRoleChange = (_, { value: id }): void =>
    this.refreshTable({ ...this.state.filters, role: id ? { id, title: Roles[id] } : null });

  private locationChange = (location: ItemReference): void => this.refreshTable({ ...this.state.filters, location });

  public render() {
    const { showEmployeeIdFilter, showPositionCodeFilter, showLocationFilter, acceptBtnTitle, ...rest } = this.props;
    const { showRoleFilter, columns: cols, usersStore, title, t } = rest;
    const existCols = (cols || []).length > 0;
    const { filters, selectedUsers } = this.state;

    const tableModel = {
      columns: existCols
        ? cols.map(({ column, tooltipRenderer, title }) => ({
            tooltipRenderer,
            title: title || firstToUpperCase(decamelCase(column)).replace(' Name', ''),
            renderer: item =>
              column === 'positionCode' ? item[column]?.code : column === 'location' ? GetLocationNameAndCountry(item) : item[column]
          }))
        : [
            { title: t('Name'), tooltipRenderer: true, renderer: data => data.firstName },
            { title: t('Surname'), tooltipRenderer: true, renderer: data => data.lastName },
            { title: t('Location'), tooltipRenderer: true, renderer: data => <span>{GetLocationNameAndCountry(data)}</span> }
          ],
      data: usersStore.state
    } as TableModel<UserDto>;

    return (
      <Modal
        className="template-search__modal choose-employees"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {title ? t(title) : t('Select Users')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters choose-employees">
            <ClearableTimerInput
              className="feedback-form__long-input"
              icon="search"
              placeholder={t('Search User Name/Last Name')}
              onChange={this.onEmployeeNameFilterChanged}
            />

            {showEmployeeIdFilter && (
              <ClearableTimerInput
                className="feedback-form__short-input"
                icon="search"
                placeholder={t('Search SAP ID')}
                onChange={this.handleOnEmployeeIdChange}
              />
            )}

            <ClearableTimerInput
              className="feedback-form__short-input"
              icon="search"
              placeholder={t('Search SF Position')}
              onChange={this.onSFPositionFilterChanged}
            />

            {showPositionCodeFilter && (
              <PositionCodeEditor
                className="custom-editor feedback-form__dropdown-input"
                placeholder={t('Position Code')}
                inline={false}
                clearable
                nullable
                value={filters.positionCode?.id}
                onChange={this.handleOnPositionCodeChange}
              />
            )}
            {showRoleFilter && (
              <RoleSelector
                className="custom-editor feedback-form__dropdown-input"
                value={filters.role?.id || null}
                selectOnBlur={false}
                onChange={this.handleRoleChange}
                clearable
                placeholder={t('Role')}
              />
            )}
            {showLocationFilter && (
              <LocationEditor
                className="custom-editor feedback-form__dropdown-input"
                placeholder={t('Location')}
                clearable
                nullable
                value={filters.location}
                onChange={this.locationChange}
              />
            )}
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable
              hiddeMenu
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSelectionChange={this.selectedUsersHandler}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            />
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={(selectedUsers || []).length === 0} onClick={this.onAddUsers} positive>
            {acceptBtnTitle ? t(acceptBtnTitle) : t('Select Users')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChooseUser);
