import React, { Component } from 'react';
import { Dropdown, Button } from 'semantic-ui-react';
import ScoreList from './score-list';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserProfileDto, UserAssessmentScoreDto, ScoreItemDto, SkillDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './score-summary';
import { openInNewWindow, guidIsNullOrEmpty } from 'utils/useful-functions';
import TnaRadarChartComponent from '../tna-radar-chart-component';
import * as H from 'history';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface TnaUserProfileListingDetailsProps extends WithTranslation {
  userProfile: UserProfileDto;
  selectFirstByDefault?: boolean;
  history?: H.History;
}

export interface TnaUserProfileListingDetailsState {
  scoreSummary: ScoreSummary;
  selectedUserMachineProfileId: string;
}

class TnaUserProfileListingDetails extends Component<TnaUserProfileListingDetailsProps, TnaUserProfileListingDetailsState> {
  state = { scoreSummary: null, selectedUserMachineProfileId: null };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isThisUserAdminOrPoc = (): boolean => this.identityService.isInRole(Roles.Admin) || this.identityService.isInRole(Roles.PoC);

  private isThisUserAnEmployee = (): boolean => this.identityService.isInRole(Roles.Employee);

  componentDidMount() {
    this.onUserProfileLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile !== prevProps.userProfile) this.onUserProfileLoaded();
  }

  onUserProfileLoaded() {
    if (this.props.selectFirstByDefault && this.props.userProfile.assessment.toString() === 'TNA') {
      const selectedMachineId = (this.props.userProfile?.userMachineProfiles || [])[0]?.id;
      this.onUserMachineProfileSelected(selectedMachineId);
      return;
    }
    this.setState({ scoreSummary: this.initScoreSummary() });
  }

  initScoreSummary = (): ScoreSummary =>
    this.props.userProfile.assessment.toString() === 'SSA' ? this.toScoreSummary(this.props.userProfile.ssaCurrentScores) : null;

  onUserMachineProfileSelected = (id: any): void => {
    if (id == null) {
      this.setState({ selectedUserMachineProfileId: null, scoreSummary: null });
      return;
    }

    const userMachineProfileSelected = this.props.userProfile.userMachineProfiles.firstOrDefault(x => x.id === id);
    if (userMachineProfileSelected == null) return;

    let scoreSummary = null;
    let userAssessment = null;
    if (!userMachineProfileSelected.tnaCurrentScores) {
      userAssessment = this.setEmptySkills(this.props.userProfile.skills);
    } else {
      userAssessment = userMachineProfileSelected.tnaCurrentScores;
    }
    scoreSummary = this.toScoreSummary(userAssessment);
    this.setState({ selectedUserMachineProfileId: id, scoreSummary: scoreSummary });
  };

  toScoreSummary = (userAssessmentScoreDto: UserAssessmentScoreDto): ScoreSummary => ({
    skillScores: userAssessmentScoreDto && this.toSkillScores(userAssessmentScoreDto.scores),
    totalScore: userAssessmentScoreDto.totalScore
  });

  toSkillScores(skillScores: { [key: string]: ScoreItemDto }): SkillScore[] {
    var skills: SkillScore[] = [];
    let userSkills = this.props.userProfile.skills;
    userSkills.forEach(({ skillId, skillName, isMachineRelated }) => {
      skills.push({
        id: skillId,
        name: skillName,
        score: skillScores[skillId] ? skillScores[skillId].score : 0,
        isUpdated: skillScores[skillId]?.isUpdated,
        isMachineRelated: isMachineRelated
      });
    });
    return skills;
  }

  setEmptySkills = (skills: SkillDto[]): UserAssessmentScoreDto => {
    let userScores = {} as UserAssessmentScoreDto;
    let skillScores: any = {};
    for (let i = 0; i < skills.length; i++) {
      let key = skills[i].skillId;
      skillScores[key] = {} as ScoreItemDto;
      skillScores[key].skillId = skills[i].skillId;
      skillScores[key].score = 0;
    }
    userScores.totalScore = 0;
    userScores.scores = skillScores;
    return userScores;
  };

  onReviewLastTnaFormClicked = (id: string) => id && openInNewWindow(`./assessments/tna/tna-edit-form/${id}`);

  onGoToTnaDevPlan = () => {
    const { history } = this.props;
    if (this.isThisUserAdminOrPoc()) history?.replace && history.replace('/assessments/development-plan/tna');
    else if (this.isThisUserAnEmployee()) history?.replace && history.replace('/assessments/development-plan?tab=0');
  };

  render() {
    const { selectedUserMachineProfileId, scoreSummary } = this.state;
    const { t, userProfile } = this.props;
    const lastRelatedTNA = (userProfile?.userMachineProfiles || []).find(x => x?.id === selectedUserMachineProfileId)?.lastTnaFormId;
    const lastRelatedOverallRecommendation = (userProfile?.userMachineProfiles || []).find(x => x?.id === selectedUserMachineProfileId)
      ?.lastOverallRecommendation;
    const options = userProfile.userMachineProfiles.map(x => ({ text: x.machineModelName, value: x.id }));

    return (
      <>
        <div className="employee__-scores-radar">
          <div className="profile-machinery__machinery-list flex-column">
            {userProfile.assessment.toString() === 'TNA' && (
              <Dropdown
                search
                inline
                clearable
                selection
                closeOnChange
                closeOnEscape
                options={options}
                className="planit-users-inputs planit-user-dropdown planit-user-selector"
                placeholder={t('Machinery by profile')}
                onChange={(_, { value }) => this.onUserMachineProfileSelected(value)}
                selectOnBlur={false}
                value={selectedUserMachineProfileId}
                closeOnBlur
              />
            )}
            {scoreSummary && (
              <>
                <div className="profile-machinery__scores-title">
                  <p>{t('Total Score')}</p>
                  <p>{Number(scoreSummary.totalScore).toFixed(1)}</p>
                </div>
                <ScoreList scoreSummary={scoreSummary} source={'tna'} hasLastRelatedForm={!guidIsNullOrEmpty(lastRelatedTNA)} />
              </>
            )}
            {scoreSummary && !guidIsNullOrEmpty(lastRelatedOverallRecommendation) && (
              <div className="employee-card__overall-wrapper">
                <p>
                  <b>{t('Overall Recommendation: ')}</b>
                  <span>{lastRelatedOverallRecommendation}</span>
                </p>
              </div>
            )}
            <div className="employee-card__btns-wrapper">
              {scoreSummary && (
                <Button
                  className={guidIsNullOrEmpty(lastRelatedTNA) ? 'disabled-inverted-save-btn' : 'employee-card__btn'}
                  disabled={guidIsNullOrEmpty(lastRelatedTNA)}
                  onClick={() => this.onReviewLastTnaFormClicked(lastRelatedTNA)}
                >
                  {t('Review last TNA')}
                </Button>
              )}

              <Button className="employee-card__btn employee-card__check-btn" onClick={this.onGoToTnaDevPlan}>
                {t('Check TNA Development Plan')}
              </Button>
            </div>
          </div>
          {scoreSummary && <TnaRadarChartComponent skills={userProfile.skills} scoreSummary={scoreSummary} />}
        </div>
      </>
    );
  }
}

export default withTranslation()(TnaUserProfileListingDetails);
