import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { ItemReference } from 'stores/dataStore';

import { CreateEquipmentTypeDto, NewEquipmentTypeStore } from '../../../../../stores/configuration/machinery/equipment-types-store';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from '../../../../../widgets/bussiness/mrcluster-editor';

interface NewEquipmentTypeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newEquipmentType?: NewEquipmentTypeStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewEquipmentTypeViewState {
  cluster: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newEquipmentType', NewEquipmentTypeStore])
class NewEquipmentTypeView extends React.Component<NewEquipmentTypeViewProps, NewEquipmentTypeViewState> {
  private get newEquipmentTypeStore() {
    return this.props.newEquipmentType;
  }

  constructor(props: NewEquipmentTypeViewProps) {
    super(props);

    this.state = { cluster: null, userClickedSaved: false };

    this.newEquipmentTypeStore.createNew({
      name: '',
      clusterId: ''
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewEquipmentTypeViewProps) {
    if (this.newEquipmentTypeStore.state.result && this.newEquipmentTypeStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newEquipmentTypeStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newEquipmentTypeStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newEquipmentTypeStore.change({ ...this.newEquipmentTypeStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newEquipmentTypeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Equipment Type')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newEquipmentTypeStore.state.result && !this.newEquipmentTypeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newEquipmentTypeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newEquipmentTypeStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    error={!this.newEquipmentTypeStore.state.item.name && this.state.userClickedSaved}
                    fluid
                    label={t('Equipment Type')}
                    placeholder={t('Name')}
                    value={this.newEquipmentTypeStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateEquipmentTypeDto>('name'), value)}
                  />
                </Form.Group>

                <Form.Field required error={!this.newEquipmentTypeStore.state.item.clusterId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      placeholder={t('Cluster')}
                      nullable
                      value={this.state.cluster}
                      onChange={c => {
                        this.newEquipmentTypeStore.state.item.clusterId = c ? c.id : null;
                        this.setState({ userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewEquipmentTypeView);
