import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { QueryResult } from '../../stores/dataStore';
import { SelectionInput, DropdownProps } from '../form/selectionInput';
import { ItemReference } from '../../stores/dataStore';
import { MachineModelAnswersDto } from 'stores/assessments/forms/tna-forms-store';

interface CopyAnswersEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference | string;
  onChange?: (value: ItemReference) => void;
  cascade?: string;
  reloadOnChange?: boolean;
  clearOnReload?: boolean;
  clearable?: boolean;
  readonly?: boolean;
  error?: boolean;
  direction?: 'left' | 'right';
  otherMachinesModelAnswers?: MachineModelAnswersDto[];
}

interface CopyAnswersEditorState {
  value: ItemReference | string;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

class CopyAnswersEditor extends React.Component<CopyAnswersEditorProps, CopyAnswersEditorState> {
  constructor(props: CopyAnswersEditorProps) {
    super(props);
    this.state = {
      searchQuery: null,
      value: this.props.value,
      query: this.getCopyAnswersMethod()
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        query: this.getCopyAnswersMethod()
      });
    }
  }

  @autobind
  private getCopyAnswersMethod() {
    const method = async (search: string) => {
      var items = { items: (this.props.otherMachinesModelAnswers || []).map(c => ({ id: c.machineModelId, title: c.machineModel })) };
      return items as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    return (
      <SelectionInput
        direction={this.props.direction}
        error={this.props.error || false}
        content={item => <div>{item.title}</div>}
        searchable
        clearable={this.props.clearable}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        readOnly={this.props.readonly}
        value={val}
        searchQuery={this.state.searchQuery}
        onQueryChange={q => this.setState({ searchQuery: q })}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
        clearOnReload={this.props.clearOnReload}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(CopyAnswersEditor);
