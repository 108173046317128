import * as React from 'react';

export const useToggle = (initialState: boolean = false) => {
  const [active, setActive] = React.useState<boolean>(() => initialState);
  const toggle = () => setActive(prv => !prv);
  return { active, setActive, toggle };
};

export type NewState<T> = Partial<T> | ((state: T) => T);
export type ReducerType<T> = (state: T, newState: NewState<T>) => T;
export const useStateReducer = <T extends object>(state: T, newState: NewState<T>): T =>
  typeof newState === 'function' ? newState(state) : { ...state, ...newState };

export const useRendersRef = () => {
  const count = React.useRef(0);
  console.log('count: ', count.current++);
};
