import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Checkbox } from 'semantic-ui-react';

import { CreateMrHoursDurationDto, NewMrHoursDurationStore } from 'stores/configuration/events-n-requests/mr-hours-durations-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import MachineUnitEditor from 'widgets/bussiness/machine-unit-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';

interface NewMrHoursDurationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newMrHoursDuration?: NewMrHoursDurationStore;
}

interface NewMrHoursDurationViewState {
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  machineUnit: ItemReference;
  profession: ItemReference;
  trainingLevel: ItemReference;
  originalEventType: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newMrHoursDuration', NewMrHoursDurationStore])
class NewMrHoursDurationView extends React.Component<NewMrHoursDurationViewProps, NewMrHoursDurationViewState> {
  private get newMrHoursDurationStore() {
    return this.props.newMrHoursDuration;
  }

  constructor(props: NewMrHoursDurationViewProps) {
    super(props);

    this.state = {
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      machineUnit: null,
      profession: null,
      trainingLevel: null,
      userClickedSaved: false,
      originalEventType: null
    };

    this.newMrHoursDurationStore.createNew({
      clusterId: '',
      equipmentTypeId: '',
      oemId: '',
      machineModelId: '',
      machineUnitId: '',
      professionId: '',
      trainingLevelId: '',
      hoursDuration: 0,
      originalEventTypeId: '',
      isFamilyCreationRequest: false
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewMrHoursDurationViewProps) {
    if (this.newMrHoursDurationStore.state.result && this.newMrHoursDurationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newMrHoursDurationStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newMrHoursDurationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newMrHoursDurationStore.change({ ...this.newMrHoursDurationStore.state.item, ...change });
  }

  private handleValues = (...changes: [string, any][]) => {
    if ((changes || []).length === 0) return;
    const change = {};
    changes.forEach(([prop, value]) => {
      change[prop] = value;
    });
    this.newMrHoursDurationStore.change({ ...this.newMrHoursDurationStore.state.item, ...change });
    this.setState({ userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newMrHoursDurationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New MR Hours Duration')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newMrHoursDurationStore.state.result && !this.newMrHoursDurationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newMrHoursDurationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newMrHoursDurationStore.state.item && (
              <Form>
                <Form.Group widths={3}>
                  <div className="inline field">
                    <Checkbox
                      toggle
                      className="bold-label-checkbox"
                      label={t('Create for all Machine Units')}
                      checked={!!this.newMrHoursDurationStore.state.item.isFamilyCreationRequest}
                      onChange={(_, { checked }) => {
                        this.handleValue(nameof<CreateMrHoursDurationDto>('isFamilyCreationRequest'), checked);
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Field required error={!this.state.originalEventType && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Event Type')}</label>
                      <EventTypeEditor
                        nullable
                        searchable
                        value={null}
                        useOriginalEventTypeIdAsValue
                        placeholder={t('Event Type')}
                        onChange={c => {
                          this.handleValue('originalEventTypeId', c == null ? null : c.id);
                          this.setState({ originalEventType: c, userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.state.cluster && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <MRClusterEditor
                        placeholder={t('Cluster')}
                        nullable
                        value={this.state.cluster}
                        onChange={c => {
                          this.handleValues(
                            ['clusterId', c == null ? null : c.id],
                            ['equipmentTypeId', null],
                            ['oemId', null],
                            ['machineModelId', null],
                            ['machineUnitId', null]
                          );

                          this.setState({
                            cluster: c,
                            equipmentType: null,
                            oem: null,
                            machineModel: null,
                            machineUnit: null,
                            userClickedSaved: false
                          });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newMrHoursDurationStore.state.item.equipmentTypeId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        placeholder={t('Equipment Type')}
                        nullable
                        value={this.state.equipmentType}
                        readOnly={this.state.cluster?.id == null}
                        clusterId={this.state.cluster ? this.state.cluster.id : null}
                        onChange={c => {
                          this.handleValues(
                            ['equipmentTypeId', c == null ? null : c.id],
                            ['oemId', null],
                            ['machineModelId', null],
                            ['machineUnitId', null]
                          );

                          this.setState({
                            equipmentType: c,
                            oem: null,
                            machineModel: null,
                            machineUnit: null,
                            userClickedSaved: false
                          });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Field required error={!this.newMrHoursDurationStore.state.item.oemId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('OEM')}</label>
                      <OemEditor
                        placeholder={t('OEM')}
                        nullable
                        clearable
                        readonly={this.state.equipmentType?.id == null}
                        value={this.state.oem}
                        equipmentId={this.state.equipmentType ? this.state.equipmentType.id : null}
                        onChange={c => {
                          this.handleValues(['oemId', c == null ? null : c.id], ['machineModelId', null], ['machineUnitId', null]);

                          this.setState({
                            oem: c,
                            machineModel: null,
                            machineUnit: null,
                            userClickedSaved: false
                          });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newMrHoursDurationStore.state.item.machineModelId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Machine Model')}</label>
                      <MachineModelEditor
                        nullable
                        placeholder={t('Machine Model')}
                        clearable
                        readonly={this.state.oem?.id == null}
                        value={this.state.machineModel}
                        oemId={this.state.oem ? this.state.oem.id : null}
                        equipmentId={this.state.equipmentType ? this.state.equipmentType.id : null}
                        onChange={c => {
                          this.handleValues(['machineModelId', c == null ? null : c.id], ['machineUnitId', null]);

                          this.setState({
                            machineModel: c,
                            machineUnit: null,
                            userClickedSaved: false
                          });
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    {!this.newMrHoursDurationStore.state.item.isFamilyCreationRequest && (
                      <div className="field">
                        <label>{t('Machine Unit')}</label>
                        <MachineUnitEditor
                          nullable
                          clearable
                          placeholder={t('Machine Unit')}
                          reloadOnChange
                          readonly={this.state.machineModel?.id == null}
                          value={this.state.machineUnit}
                          oemId={this.state.oem ? this.state.oem.id : null}
                          equipmentId={this.state.equipmentType ? this.state.equipmentType.id : null}
                          onChange={c => {
                            this.handleValue('machineUnitId', c == null ? null : c.id);

                            this.setState({
                              machineUnit: c,
                              userClickedSaved: false
                            });
                          }}
                        />
                      </div>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Field>
                    <div className="field">
                      <label>{t('Role')}</label>
                      <ProfessionEditor
                        placeholder={t('Role')}
                        clearable
                        nullable
                        value={this.state.profession}
                        onChange={c => {
                          this.newMrHoursDurationStore.state.item.professionId = c == null ? null : c.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="field">
                      <label>{t('Training Level')}</label>
                      <TrainingLevelEditor
                        nullable
                        clearable
                        placeholder={t('Training Level')}
                        value={this.state.trainingLevel}
                        onChange={c => {
                          this.newMrHoursDurationStore.state.item.trainingLevelId = c == null ? null : c.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>

                  {!this.newMrHoursDurationStore.state.item.isFamilyCreationRequest && (
                    <Form.Input
                      required
                      fluid
                      type="number"
                      error={this.newMrHoursDurationStore.state.item.hoursDuration <= 0 && this.state.userClickedSaved}
                      label={t('Hours Duration')}
                      placeholder={t('Hours Duration')}
                      value={this.newMrHoursDurationStore.state.item.hoursDuration}
                      onChange={(_, { value }) =>
                        this.handleValue(nameof<CreateMrHoursDurationDto>('hoursDuration'), Math.abs(parseInt(value) || 0))
                      }
                    />
                  )}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewMrHoursDurationView);
