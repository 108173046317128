import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestFormStore } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import { resolve } from 'inversify-react';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import InstructorEditor, { InstructorReference } from 'widgets/bussiness/request-instructor-editor';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'semantic-ui-react';
import { IdentityService } from 'services/identity-service';

export interface InstructorsTabPaneContainerProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
}

@connect(['requestFormStore', RequestFormStore])
class InstructorsTabPaneContainer extends Component<InstructorsTabPaneContainerProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private handleOnLocationChange = (newLocation: LocationItemReference) => {
    this.props.requestFormStore.change({
      instructorLocation: newLocation,
      instructor: null
    });
  };

  private handleOnInstructorChange = (newInstructor: InstructorReference) => {
    this.props.requestFormStore.change({
      instructorLocation: newInstructor ? newInstructor.location : null,
      instructor: newInstructor
    });
  };

  private isPlannerTFT = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerTFT(userinfo);
  };

  private isPlannerMTC = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerMTC(userinfo);
  };

  render() {
    const { t, readOnly } = this.props;
    const { instructorLocation, instructor } = this.props.requestFormStore.state.item;

    return (
      <Form className="form__modal__header-content">
        <div className="form__header__data">
          <FormRow className="form__event-details-tab__element" label={t("Instructor's Location")} icon={faUserFriends}>
            <LocationEditor
              nullable
              clearable
              className="form__header__dropdown-style"
              readOnly={readOnly}
              value={instructorLocation}
              onChange={this.handleOnLocationChange}
            />

            <p>{t("Instructor's Name")}</p>
            <InstructorEditor
              nullable
              clearable
              activeOnly
              className="form__header__dropdown-style"
              readOnly={readOnly}
              role={'Instructor'}
              location={instructorLocation}
              value={instructor}
              onChange={this.handleOnInstructorChange}
              onlyInstructorsPlannerTFT={this.isPlannerTFT()}
              onlyInstructorsPlannerMTC={this.isPlannerMTC()}
            />
          </FormRow>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(InstructorsTabPaneContainer);
