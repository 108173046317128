//import React, { Component, Fragment } from 'react';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StepsComponent from '../../../../../../shared/tna-and-ssa/wizard/wizard-step';
import './iws-template-step.less';
import { IwsTemplateDto, IwsTemplatesStore } from 'stores/assessments/templates/iws-templates-store';
import { connect } from 'redux-scaffolding-ts';
//import { Button, Icon, Label } from 'semantic-ui-react';
import { Button, Icon } from 'semantic-ui-react';
import CustomizeIwsTemplatesView from 'widgets/form/customize-iws-template-form';
import { IwsTemplateData } from 'site/pages/assessments/iws/templates/wizard/iws-templates-wizard-component';
// import { isNullOrEmpty } from 'utils/useful-functions';

export interface IwsTemplateViewModel {
  name: string;
  templates: IwsTemplateDto[];
  skillCount: number;
}
export interface IwsTemplateStepViewModel {
  [profileId: string]: IwsTemplateViewModel;
}

export interface IwsTemplateStepProps extends WithTranslation {
  isStepActive: boolean;
  value: IwsTemplateStepViewModel;
  profileIds: string[];
  iwsTemplatesStore?: IwsTemplatesStore;
  //onChange?: (value: IwsTemplateStepViewModel, duplicatedSkills?: boolean) => void;
  onChange?: (value: IwsTemplateStepViewModel) => void;
  onChangeOrderedTemplate?: (value: IwsTemplateDto[]) => void;
  isOnEditMode: boolean;
}

export interface IwsTemplateStepState {
  isStepActive: boolean;
  showCustomizeTemplateModal: boolean;
  seletionToCutomize: IwsTemplateDto;
  templatesProfile: IwsTemplateStepViewModel;
  templates: IwsTemplateDto[];
  areSkillsDuplicated: boolean;
  warningMsg: string;
}

@connect(['iwsTemplatesStore', IwsTemplatesStore])
class IwsTemplateStep extends Component<IwsTemplateStepProps, IwsTemplateStepState> {
  constructor(props: IwsTemplateStepProps) {
    super(props);
    this.state = {
      isStepActive: !!this.props.isStepActive,
      showCustomizeTemplateModal: false,
      seletionToCutomize: null,
      templatesProfile: this.props.value,
      templates: null,
      areSkillsDuplicated: false,
      warningMsg: ''
    };
  }
  UNSAFE_componentWillReceiveProps(next: IwsTemplateStepProps) {
    if (next != null && next !== this.props) {
      let change = false;
      let isStepActive = this.state.isStepActive;

      if (next.isStepActive !== this.props.isStepActive) {
        isStepActive = !!next.isStepActive;
        change = true;
      }
      if (change) {
        this.setState({
          isStepActive
        });
      }
    }
  }

  componentDidUpdate(prev) {
    const prevProfilesIds = prev.profileIds;
    const profilesIds = this.props.profileIds;
    const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    if (prevProfilesIds && profilesIds && profilesIds.length > 0 && !equals(prevProfilesIds, profilesIds)) {
      var promiseTemplatesProfile = this.props.iwsTemplatesStore.getAllByProfileAsync(profilesIds);
      promiseTemplatesProfile.then(value => {
        this.handleOnAddTemplate(value.items, profilesIds);
      });
    }
  }

  handleOnAddTemplate = (templateListItems: IwsTemplateDto[], profilesId?: any) => {
    let templatesProfileFromProps = { ...this.props.value };

    profilesId.forEach(profileId => {
      const templates = templateListItems;

      if (templatesProfileFromProps[profileId]) {
        templatesProfileFromProps[profileId].templates = [...templates];
      }
    });

    this.setState({ templatesProfile: templatesProfileFromProps });
    this.props.onChange && this.props.onChange(templatesProfileFromProps);
    this.handleProfileTemplates();
  };

  removeTemplate = (id: string) => {
    const { profileIds } = this.props;
    let templatesProfile = { ...this.state.templatesProfile };

    profileIds.forEach(profileId => {
      if (templatesProfile[profileId]) {
        const removed = templatesProfile[profileId].templates.filter(x => x.id !== id);
        templatesProfile[profileId].templates = removed;
      }
    });

    this.setState({ templatesProfile: templatesProfile });
    this.handleProfileTemplates();
    this.props.onChange && this.props.onChange(templatesProfile);
  };

  private onCustomizeTemplateModel = (template: IwsTemplateDto) => {
    this.setState({ showCustomizeTemplateModal: true, seletionToCutomize: template });
  };

  onSaveCustomizeTemplate = (templateData: IwsTemplateData) => {
    let templatesProfile = { ...this.state.templatesProfile };
    const { profileIds } = this.props;

    profileIds.forEach(profileId => {
      if (templatesProfile[profileId]) {
        const templateDto = templatesProfile[profileId].templates.firstOrDefault(x => x.id === templateData.id);
        templateDto.skillSection = templateData.skillSection;
      }
    });

    this.props.onChange && this.props.onChange(templatesProfile);
  };

  handleProfileTemplates = () => {
    let iwsTemplateListDto: IwsTemplateDto[] = [];

    //let { templates, areSkillsDuplicated, warningMsg } = { ...this.state };
    let { templates } = { ...this.state };

    if ((this.props.profileIds || []).length > 0 && this.state.templatesProfile)
      this.props.profileIds.forEach(profileId => {
        if (this.state.templatesProfile[profileId]) {
          iwsTemplateListDto = iwsTemplateListDto.concat(this.state.templatesProfile[profileId].templates);
        }

        templates = (iwsTemplateListDto || []).distinct(x => x.id).toArray();
        //const skills = (templates || []).filter(x => !isNullOrEmpty(x?.skillSection?.skillId)).map(x => x?.skillSection?.skillId);
        //const duplicates = (skills || []).filter((item, index) => (skills || []).indexOf(item) !== index);
        //areSkillsDuplicated = (duplicates || []).length > 0;

        // if (areSkillsDuplicated) {
        //   const templatesWithDuplicates = (templates || []).filter(x => duplicates.includes(x.skillSection.skillId));
        //   const templateNamesDuplicated = templatesWithDuplicates
        //     .map(x => x.title)
        //     .reduce((accumulator, currentValue) => accumulator + ', ' + currentValue);

        //   const skillNamesDuplicated = [...new Set(templatesWithDuplicates.map(x => x.skillSection.skillName))].reduce(
        //     (accumulator, currentValue) => accumulator + ', ' + currentValue
        //   );

        //   warningMsg = `${templateNamesDuplicated} had ${skillNamesDuplicated} repeated.`;
        // }

        //this.setState({ templates, areSkillsDuplicated, warningMsg });
        this.setState({ templates });
        // this.props.onChange && this.props.onChange(this.state.templatesProfile, areSkillsDuplicated);
        this.props.onChange && this.props.onChange(this.state.templatesProfile);
        this.props.onChangeOrderedTemplate(templates);
      });
  };

  handleMoveUpQuestion = (questionIdx: number) => {
    const templates = [...this.state.templates];
    const temporalVariable = { ...templates[questionIdx - 1] };
    templates[questionIdx - 1] = { ...templates[questionIdx] };
    templates[questionIdx] = { ...temporalVariable };
    this.setState({ templates });

    this.props.onChange && this.props.onChange(this.state.templatesProfile);
    this.props.onChangeOrderedTemplate(templates);
  };

  handleMoveDownQuestion = (questionIdx: number) => {
    const templates = [...this.state.templates];
    const temporalVariable = { ...templates[questionIdx + 1] };
    templates[questionIdx + 1] = { ...templates[questionIdx] };
    templates[questionIdx] = { ...temporalVariable };
    this.setState({ templates });
    this.props.onChange && this.props.onChange(this.state.templatesProfile);
    this.props.onChangeOrderedTemplate(templates);
  };

  render() {
    const { t, isOnEditMode } = this.props;
    // const { isStepActive, templates, areSkillsDuplicated, warningMsg } = this.state;
    const { isStepActive, templates } = this.state;

    return (
      <div className="wizard__step wizard__step-two iws-form-iws-template">
        <StepsComponent active={isStepActive} number={'2'} title={t('SELECT TEMPLATES')}>
          {/* {areSkillsDuplicated && (
              <Label className="modal__action-warning wizard__action-warning">
                <Icon name="exclamation triangle" />
                <div>
                  <Fragment key={'date-warning-'}>{<p className="modal__action-warning__text"> {`${warningMsg}`} </p>}</Fragment>
                </div>
              </Label>
            )} */}

          <div className="step2__select-iws-wrapper">
            {(templates || []).length === 0 && (
              <div key="wrapper" className="step2__select-iws-wrapper">
                <div className="step2__select-iws-wrapper__content">
                  <p>The selected profile does not have any template.</p>
                </div>
              </div>
            )}
            {(templates || []).length !== 0 &&
              (templates || []).map((pId, i) => (
                <div key={`${pId.id}-wrapper`} className="step2__select-iws-wrapper">
                  <div className="step2__select-iws-wrapper__content">
                    <ul>
                      {
                        <li key={`${pId.id}`} className="step2__select-iws-wrapper__content__element">
                          <h4 className={''}>
                            <div className="wizard__sort-icons-wrapper tna-arrow-left-margin">
                              <Icon disabled={i <= 0} onClick={() => this.handleMoveUpQuestion(i)} name="caret square up outline" />
                            </div>
                            <div className="wizard__sort-icons-wrapper">
                              <Icon
                                disabled={i >= templates.length - 1}
                                onClick={() => this.handleMoveDownQuestion(i)}
                                name="caret square down outline"
                              />
                            </div>
                            {`${pId.friendlyId} - ${pId.title}`}
                            {!isOnEditMode && (
                              <>
                                <Icon
                                  size="large"
                                  name="close"
                                  color="red"
                                  className="clickable-icon"
                                  onClick={() => this.removeTemplate(pId.id)}
                                />
                                <div className="flex-column">
                                  <Button className="search-tna-template__btn" onClick={() => this.onCustomizeTemplateModel(pId)}>
                                    {t('Customize')}
                                  </Button>
                                </div>
                              </>
                            )}
                          </h4>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </StepsComponent>
        {this.state.showCustomizeTemplateModal && (
          <CustomizeIwsTemplatesView
            templateDto={this.state.seletionToCutomize}
            onSaveCustomizeTemplate={this.onSaveCustomizeTemplate}
            onCloseTemplateModal={() => this.setState({ showCustomizeTemplateModal: false })}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(IwsTemplateStep);
