import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { AxiosResponse } from 'axios';
import { ItemResult, Message } from 'stores/types';

export interface AdminReportCounters {
  draftEventsCount: number;
  plannedEventsCount: number;
  inProgressEventsCount: number;
  completedEventsCount: number;
  openRequestsCount: number;
  openTheoreticalsCount: number;
  openFeedbacksCount: number;
  openPracticalsCount: number;

  lastWeekTotalLoggedUsersCount: number;
  lastMonthTotalLoggedUsersCount: number;
  lastYearTotalLoggedUsersCount: number;

  lastWeekLoggedUsersCount: LoginsAuditItemDataDto[];
  lastMonthLoggedUsersCount: LoginsAuditItemDataDto[];
  lastYearLoggedUsersCount: LoginsAuditItemDataDto[];

  openTnas: number;
  openSsas: number;
}

export interface EventsAuditLogsDto {
  draftEventsCount: number;
  plannedEventsCount: number;
  inProgressEventsCount: number;
  completedEventsCount: number;
  openRequestsCount: number;
  openTheoreticalsCount: number;
  openFeedbacksCount: number;
  openPracticalsCount: number;
}

export interface LoginsAuditItemDataDto {
  key: number;
  title: string;
  count: number;
}

export interface LoginsAuditLogDto {
  lastWeekTotalLoggedUsersCount: number;
  lastMonthTotalLoggedUsersCount: number;
  lastYearTotalLoggedUsersCount: number;

  lastWeekLoggedUsersCount: LoginsAuditItemDataDto[];
  lastMonthLoggedUsersCount: LoginsAuditItemDataDto[];
  lastYearLoggedUsersCount: LoginsAuditItemDataDto[];
}

export interface SkillsAuditLogsDto {
  openTnas: number;
  openSsas: number;
}

export interface AdminReportCountersState {
  counters: AdminReportCounters;
  isBusy: boolean;
  result: {
    isSuccess: boolean;
    messages: Message[];
  };
}

@repository('@@ADMIN_REPORTS', 'adminReports.counters')
export class AdminReportCountersStore extends ReduxRepository<AdminReportCountersState> {
  eventsAuditLogsUrl = 'events/v1/get-events-audit-log-data';
  skillsAuditLogsUrl = 'skills/v1/get-skills-audit-log-data';
  loginsAuditLogsUrl = 'notifications/v1/get-logins-audit-log-data';

  public CLEAR_MESSAGES = 'ADMIN_REPORTS_MESSAGES_CLEARED';
  public COUNTERS_LOADED = 'ADMIN_REPORTS_COUNTERS_LOADED';

  constructor() {
    super({
      isBusy: false,
      result: null,
      counters: {
        draftEventsCount: 0,
        plannedEventsCount: 0,
        inProgressEventsCount: 0,
        completedEventsCount: 0,
        openRequestsCount: 0,
        openTheoreticalsCount: 0,
        openFeedbacksCount: 0,
        openPracticalsCount: 0,

        lastWeekTotalLoggedUsersCount: 0,
        lastMonthTotalLoggedUsersCount: 0,
        lastYearTotalLoggedUsersCount: 0,

        lastWeekLoggedUsersCount: [],
        lastMonthLoggedUsersCount: [],
        lastYearLoggedUsersCount: [],

        openTnas: 0,
        openSsas: 0
      }
    });

    this.loadCounters.bind(this);
    this.addReducer(this.COUNTERS_LOADED, this.onCountersLoaded, 'AsyncAction');
    this.addReducer(
      this.CLEAR_MESSAGES,
      (): AdminReportCountersState => ({
        ...this.state,
        result: this.state.result == null ? this.state.result : { ...this.state.result, messages: [] }
      }),
      'Simple'
    );
  }

  public clearMessages = () => {
    this.dispatch(this.CLEAR_MESSAGES);
  };

  public async loadCounters() {
    const httpService = container.get<HttpService>(HttpService);

    const eventsCounters = httpService.get<ItemResult<EventsAuditLogsDto>>(this.eventsAuditLogsUrl);
    const skillsCounters = httpService.get<ItemResult<SkillsAuditLogsDto>>(this.skillsAuditLogsUrl);
    const loginsCounters = httpService.get<ItemResult<LoginsAuditLogDto>>(this.loginsAuditLogsUrl);

    const result = await this.dispatchAsync(this.COUNTERS_LOADED, Promise.all([eventsCounters, skillsCounters, loginsCounters]));
    return result.map(x => x.data);
  }

  protected onCountersLoaded = (): AsyncAction<
    [
      AxiosResponse<ItemResult<EventsAuditLogsDto>>,
      AxiosResponse<ItemResult<SkillsAuditLogsDto>>,
      AxiosResponse<ItemResult<LoginsAuditLogDto>>
    ],
    AdminReportCountersState
  > => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: result => {
        const eventCounters = result[0].data.item;
        const skillsounters = result[1].data.item;
        const loginsCounters = result[2].data.item;

        return {
          ...this.state,
          isBusy: false,
          result: undefined,
          counters: { ...eventCounters, ...skillsounters, ...loginsCounters }
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };
}
