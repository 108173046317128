import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import { nameof } from '../../../../../utils/object';
import { RegionDto, ChangeRegionStore, ChangeRegionDto } from 'stores/configuration/locations/regions-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface ChangeRegionViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeRegion?: ChangeRegionStore;
  currentRegion: RegionDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeRegionViewState {}

@connect(['changeRegion', ChangeRegionStore])
class ChangeRegionView extends React.Component<ChangeRegionViewProps, ChangeRegionViewState> {
  private get changeRegionStore() {
    return this.props.changeRegion;
  }

  constructor(props: ChangeRegionViewProps) {
    super(props);

    this.changeRegionStore.state.result = null;

    this.changeRegionStore.change({
      id: this.props.currentRegion.id,
      name: this.props.currentRegion.name
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeRegionViewProps) {
    if (this.changeRegionStore.state.result && this.changeRegionStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeRegionStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeRegionStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeRegionStore.change({ ...this.changeRegionStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result, item } = this.changeRegionStore.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeRegionStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Edit Region')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group>
                  <Form.Field width={6} required error={isNullOrWhiteSpaces(item.name)}>
                    <label>{t('Name')}</label>
                    <Input
                      placeholder={t('Name')}
                      value={item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<ChangeRegionDto>('name'), value)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeRegionView);
