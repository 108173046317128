import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { UserStore, UserDto } from 'stores/users/users-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import '../../assets/less/choose-assessors-form.less';

interface ChooseAssessorsViewProps extends WithTranslation {
  onAddAssessors?: (assessors: UserDto[]) => void;
  onCloseUsersModal?: () => void;
  alreadySelectedUserIds?: string[];
  userStore?: UserStore;
  showEmployeeIdFilter?: boolean;
  showPositionCode?: boolean;
  showLocation?: boolean;
  customTitle?: string;
}

interface ChooseAssessorsFilters {
  firstNameOrLastName: string;
  sfPosition: string;
  employeeId: string;
  positionCode: PositionCodeDto;
  location: LocationItemReference;
}

interface ChooseAssessorsViewState {
  activeFilters: string[];
  query: Query;
  selectedUsers: UserDto[];
  filters: ChooseAssessorsFilters;
}

@connect(['userStore', UserStore])
class ChooseAssessorsView extends React.Component<ChooseAssessorsViewProps, ChooseAssessorsViewState> {
  state: ChooseAssessorsViewState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    activeFilters: [],
    selectedUsers: [],
    filters: {
      firstNameOrLastName: '',
      sfPosition: '',
      employeeId: '',
      location: null,
      positionCode: null
    }
  };

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  componentDidUpdate() {
    const { selectedUsers } = this.state;
    if ((selectedUsers || []).length === 0) {
      let items: UserDto[] = [];
      const item = (this.props.userStore.state.items || []).firstOrDefault();
      if (item && item.item) {
        items.push(item.item);
        this.setState({ selectedUsers: items as any });
      }
    }
  }

  private selectedUsers = (items: unknown[]): void => {
    if (items && items.length > 0) this.setState({ selectedUsers: items as any });
  };

  private onAddAssessors = () => {
    const { selectedUsers } = this.state;
    if ((selectedUsers || []).length === 0) return;
    if (this.props.onAddAssessors) this.props.onAddAssessors(selectedUsers);
    this.props.onCloseUsersModal();
  };

  private onCancel = () => {
    this.props.onCloseUsersModal();
  };

  private load = () => {
    this.props.userStore.getAllAsync(this.state.query);
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleFilterChange = (filters, oDatafilters) => {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  };

  private onEmployeeNameFilterChanged = (_, { value }) => {
    let filters = { ...this.state.filters };
    filters.firstNameOrLastName = value;
    this.refreshTable(filters);
  };

  private onSFPositionFilterChanged = (_, { value }) => {
    let filters = { ...this.state.filters };
    filters.sfPosition = value;
    this.refreshTable(filters);
  };

  private handleOnEmployeeIdChange = (_, { value }) => {
    let filters = { ...this.state.filters };
    filters.employeeId = value;
    this.refreshTable(filters);
  };

  private handleOnLocationChange = (location: LocationItemReference) => {
    let filters = { ...this.state.filters };
    filters.location = location;
    this.refreshTable(filters);
  };

  private handleOnPositionCodeChange = (positionCode: PositionCodeDto) => {
    let filters = { ...this.state.filters };
    filters.positionCode = positionCode;
    this.refreshTable(filters);
  };

  private refreshTable = (filters: ChooseAssessorsFilters) => {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  };

  private buildODataFilter = (filters: ChooseAssessorsFilters) => {
    let oDataFilter = [];

    if ((this.props.alreadySelectedUserIds || []).length !== 0)
      oDataFilter.push(`not(Id in (${this.props.alreadySelectedUserIds.join(',')}))`);

    if (!isNullOrWhiteSpaces(filters.firstNameOrLastName)) {
      oDataFilter.push({
        or: [
          { 'tolower(name)': { contains: filters.firstNameOrLastName } },
          { 'tolower(surname)': { contains: filters.firstNameOrLastName } }
        ]
      });
    }

    if (!isNullOrWhiteSpaces(filters.sfPosition)) oDataFilter.push({ 'tolower(SFPosition)': { contains: filters.sfPosition } });
    if (!isNullOrWhiteSpaces(filters.employeeId)) oDataFilter.push({ 'tolower(EmployeeId)': { contains: filters.employeeId } });
    if (filters.location && !isNullOrWhiteSpaces(filters.location.id))
      oDataFilter.push({ LocationId: { eq: { type: 'guid', value: filters.location.id } } });
    if (filters.positionCode && !isNullOrWhiteSpaces(filters.positionCode.id))
      oDataFilter.push({ PositionCodeId: { eq: { type: 'guid', value: filters.positionCode.id } } });

    oDataFilter.push('Enabled eq true');

    return oDataFilter;
  };

  public render() {
    const { t, showEmployeeIdFilter, showPositionCode, showLocation, customTitle } = this.props;
    const { selectedUsers, filters } = this.state;
    const defaultAssessorTitle = 'Assessors';
    const tableModel = {
      columns: [
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => data.lastName
        },
        {
          title: t('First Name'),
          tooltipRenderer: true,
          renderer: data => data.firstName
        },
        {
          title: t('SAP ID'),
          tooltipRenderer: true,
          renderer: data => data.employeeId
        },
        {
          title: t('SF Position'),
          tooltipRenderer: true,
          renderer: data => data.sfPosition
        }
      ],
      data: this.props.userStore.state
    } as TableModel<UserDto>;

    if (showPositionCode) {
      tableModel.columns.push({
        title: t('Position Code'),
        tooltipRenderer: true,
        renderer: data => data.positionCode && data.positionCode.code
      });
    }

    if (showLocation) {
      tableModel.columns.push({
        title: t('Location'),
        tooltipRenderer: true,
        renderer: data => data.location && data.location.location
      });
    }

    return (
      <Modal
        className="template-search__modal choose-assessors"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Select ' + (customTitle ? customTitle : defaultAssessorTitle))}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters choose-assessors">
            <ClearableTimerInput
              className="long-input"
              icon="search"
              placeholder={t('Search Employee Name/Last Name')}
              onChange={this.onEmployeeNameFilterChanged}
            />

            {showEmployeeIdFilter && (
              <ClearableTimerInput icon="search" placeholder={t('Search SAP ID')} onChange={this.handleOnEmployeeIdChange} />
            )}

            <ClearableTimerInput icon="search" placeholder={t('Search SF Position')} onChange={this.onSFPositionFilterChanged} />

            {showPositionCode && (
              <PositionCodeEditor
                className="custom-editor"
                placeholder={t('Position Code')}
                clearable
                nullable
                value={filters.positionCode && filters.positionCode.id}
                onChange={this.handleOnPositionCodeChange}
              />
            )}
            {showLocation && (
              <LocationEditor
                className="inline custom-editor"
                placeholder={t('Location')}
                clearable
                nullable
                value={filters.location}
                onChange={this.handleOnLocationChange}
              />
            )}
          </div>

          <Container className="template-list-table scroll">
            <TableView
              model={tableModel}
              selectable={true}
              hiddeMenu={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSelectionChange={this.selectedUsers}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={(selectedUsers || []).length === 0} onClick={this.onAddAssessors} positive>
            {t('Select ' + (customTitle ? customTitle : defaultAssessorTitle))}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseAssessorsView);
