import React from 'react';
import ReactDOMServer from 'react-dom/server';
import SchedulerVacationsEvent from '../shared-scheduler-components/events/scheduler-vacations-event';
import SchedulerEvent from '../shared-scheduler-components/events/scheduler-event';
import SchedulerEventTooltip from '../shared-scheduler-components/events/scheduler-event-tooltip';
import InstructorsLocationComponent from './scheduler-instructor-row';
import { DateHelper, Scheduler } from '@planit/bryntum-scheduler';
import { DateTimeService } from 'services/datetime-service';

export type PausePeriod = { left: number; width: number } | {};

const SchedulerInstructorsConfig = {
  eventLayout: 'stack',
  columns: [
    {
      searchable: false,
      text: 'Instructors',
      width: 200,
      editor: false,
      locked: true,
      cellCls: 'instructors-component',
      renderer: ({ record }) => <InstructorsLocationComponent showStar={record.showStar} instructor={record.instructor} />,
      headerRenderer: () => `<p>Instructors</p><div id="InstructorsFilters" />`
    }
  ],
  timeRangesFeature: { enableResizing: false, showCurrentTimeLine: false, currentDateFormat: 'DD/MM/YYYY', showHeaderElements: true },
  zoomOnMouseWheel: false,
  weekStartDay: 1,
  eventBodyTemplate: ({ eventRecord, pausePeriods }) => {
    if (eventRecord.event.eventType.eventTypeCategory === 'Vacations')
      return ReactDOMServer.renderToString(<SchedulerVacationsEvent event={eventRecord.event} />);

    return ReactDOMServer.renderToString(<SchedulerEvent pausePeriods={pausePeriods} event={eventRecord.event} />);
  },

  eventRenderer: ({ eventRecord, tplData }, schedulerEngine: Scheduler) => {
    const { event, startDate, endDate } = eventRecord;
    const { eventTypeCategory, eventsColor } = event.eventType;
    const pausePeriods: { left: number; width: number }[] = [];
    tplData.cls['b-milestone'] = false;
    tplData.cls['b-sch-event-narrow'] = false;
    tplData.wrapperCls['b-milestone-wrap'] = false;

    if (event.travelStart || event.travelEnd) {
      tplData.cls['travel-event'] = true;
      tplData.wrapperCls['b-sch-travel-event'] = true;
    }

    if (tplData.width < 24) tplData.cls['mini-event'] = true;

    if ((event.pausePeriods || []).length) {
      const dateToPx = date => schedulerEngine.getCoordinateFromDate(date) - tplData.left;
      const start = new Date(startDate);
      const end = new Date(endDate);
      const ppRange: { left: number; width: number }[] = [];
      event.pausePeriods.forEach(pause => {
        const ppFrom = DateTimeService.toMoment(pause.from, true);
        const ppTo = DateTimeService.toMoment(pause.to, true);
        const isFromBetweenPeriod = ppFrom.isBetween(start, end, 'day', '[]');
        const isToBetweenPeriod = ppTo.isBetween(start, end, 'day', '[]');

        if (!isFromBetweenPeriod && !isToBetweenPeriod) return;
        let to = new Date(DateTimeService.toSchedulerToString(pause.to));
        let from = new Date(DateTimeService.toSchedulerFromString(pause.from));
        if (isFromBetweenPeriod && !isToBetweenPeriod) to = end;
        if (isToBetweenPeriod && !isFromBetweenPeriod) from = start;

        const startOffset = DateHelper.diff(start, from);
        const endOffset = DateHelper.diff(from, to);
        const startPixels = DateHelper.add(start, startOffset);
        const endPixels = DateHelper.add(start, endOffset);
        const left = dateToPx(startPixels) - 10; // -10px offset of container left border
        const width = dateToPx(endPixels);
        ppRange.push({ left, width });
      });
      pausePeriods.push(...ppRange);
    }

    if (tplData.width < 24) tplData.cls['mini-event'] = true;

    if (eventTypeCategory === 'Vacations') {
      tplData.style += 'border-style: none';
      return { eventRecord, tplData };
    }

    if (event.status === 'Draft') tplData.cls += 'draft';

    tplData.style += `;border-color: ${eventsColor}`;

    return { eventRecord, tplData, pausePeriods: pausePeriods };
  },

  eventTooltipFeature: {
    cls: 'scheduler-tooltip',
    startDate: Date,
    endDate: Date,
    align: 't-b',
    template: ({ eventRecord: { event } }) => ReactDOMServer.renderToString(<SchedulerEventTooltip event={event} />)
  }
}; // eo scheduler config

export default SchedulerInstructorsConfig;
