import React, { FunctionComponent } from 'react';
import { Respondent, FeedbackTemplateItemDto } from 'stores/assessments/templates/feedbacks-templates-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import Step from 'widgets/form/templates-step-component';
import '../feedbacks-templates-wizard.styles.less';
import { Input, TextArea, Dropdown } from 'semantic-ui-react';
export interface FirstStepProps extends WithTranslation {
  active: boolean;
  title: string;
  header: string;
  respondent: Respondent;
  onChange: (change: Partial<FeedbackTemplateItemDto>) => void;
}
const respondents = [
  { value: 10, text: 'Trainees' },
  { value: 20, text: 'Manual Assignment' }
];

const FirstStep: FunctionComponent<FirstStepProps> = ({ t, active, title, header, respondent, onChange }) => {
  return (
    <div className="feedback-wizard__first-step">
      <Step
        active={active}
        number={1}
        title={t('IDENTIFY THE NEW TEMPLATE')}
        render={
          <div className="first-step__content">
            <div className="title">
              <label>{t('Title')}</label>
              <Input value={title || ''} onChange={(_, { value }) => onChange({ title: value })} />
            </div>
            <div className="header">
              <label>{t('Header (Optional)')}</label>
              <TextArea
                value={header || ''}
                onChange={(_, { value }) => onChange({ header: '' + value })}
                className="feedback-wizard__textarea"
              />
            </div>
            <div className="respondents">
              <label>{t('Who is going to answer this Feedback?')}</label>
              <Dropdown
                value={respondent === 'Trainees' ? 10 : respondent === 'ManualAssignment' ? 20 : null}
                className="filter filter-respondents"
                clearable
                closeOnBlur
                selectOnBlur={false}
                closeOnEscape
                options={respondents}
                onChange={(_, { value }) => onChange({ respondent: value === 10 ? 'Trainees' : value === 20 ? 'ManualAssignment' : null })}
                placeholder={t('Respondents')}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default withTranslation()(FirstStep);
