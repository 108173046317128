import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import i18n from 'i18n';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';

export interface SsaTemplateDto {
  id: string; //Guid
  friendlyId: string;
  profileItemId: string;
  profileName: string;
  title: string;
  header: string;
  isActive: boolean;
  skillSectionCategories: SsaSkillTemplateSectionCategoryDto[];
  [key: string]: any;
}

export interface SsaTemplateFilters {
  templateName: string;
  templateId: string;
  profileName: string;
  profileItemId: string;
}

export interface CreateSsaTemplateDto {
  profileItemId: string;
  title: string;
  header: string;
  isActive: boolean;
  skillTemplateSectionCategories: CreateSsaSkillTemplateSectionCategoryDto[];
}

export interface CreateSsaSkillTemplateSectionCategoryDto {
  name: string;
  skillSections: CreateSsaSkillTemplateSectionDto[];
}

export interface ChangeSsaTemplateDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  skillTemplateSectionCategories: CreateSsaSkillTemplateSectionCategoryDto[];
}

export interface SsaSkillTemplateSectionCategoryDto {
  name: string;
  skillSections: SsaSkillTemplateSectionDto[];
}

export interface SsaSkillTemplateSectionDto {
  skillId: string;
  skillName: string;
  linkUrl: string;
  checkpoints: SsaCheckpointDto[];
}

export interface CreateSsaSkillTemplateSectionDto {
  skillId: string;
  urlLink: string;
  checkpoints: CreateSsaCheckpointDto[];
}

export interface SsaCheckpointDto {
  description: string;
  questions: SsaSkillSectionQuestionDto[];
}

export interface CreateSsaCheckpointDto {
  description: string;
  questions: string[];
}

export interface SsaSkillSectionQuestionDto {
  id: string;
  friendlyId: string;
  question: string;
}

export class CreateSsaTemplateValidator extends ExtendedAbstractValidator<CreateSsaTemplateDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIfString(o => o.profileItemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Profile is required'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.skillTemplateSectionCategories)
      .isNotEmpty()
      .fulfills(sSC =>
        sSC.every(
          ({ skillSections, name }) =>
            skillSections.length > 0 &&
            skillSections.every(
              ({ checkpoints }, idx) =>
                checkpoints.length > 0 &&
                checkpoints.every(
                  (item, i) =>
                    item != null && new ChangeSsaCheckpointsValidator(i, idx, name, this.addErrors).extendValidate(item).isValid()
                )
            )
        )
      )
      .withFailureMessage(i18n.t('Something bad happend'));
  }
}
export class ChangeSsaCheckpointsValidator extends ExtendedAbstractValidator<CreateSsaCheckpointDto> {
  constructor(idx: number, skillIdx: number, category: string, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Checkpoint at position ${idx + 1} of skill at position${skillIdx + 1}`);
    this.validateIf(x => x.description)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('The description field is empty')}`);

    this.validateIf(x => x.questions)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('There should be at least one question per checkpoint')}`);
  }
}

export class ChangeSsaTemplateValidator extends ExtendedAbstractValidator<ChangeSsaTemplateDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(o => o.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template id is required'));

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.skillTemplateSectionCategories)
      .isNotEmpty()
      .fulfills(sSC =>
        sSC.every(
          ({ skillSections, name }) =>
            skillSections.length > 0 &&
            skillSections.every(
              ({ checkpoints }, idx) =>
                checkpoints.length > 0 &&
                checkpoints.every(
                  (item, i) =>
                    item != null && new ChangeSsaCheckpointsValidator(i, idx, name, this.addErrors).extendValidate(item).isValid()
                )
            )
        )
      )
      .withFailureMessage(i18n.t('Something bad happend'));
  }
}
export class EmptyValidator extends AbstractValidator<any> {}

@repository('@@SSATEMPLATES', 'ssa-templates.summary')
export class SsaTemplatesStore extends DataStore<SsaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-template';
  retrievePath = 'get-ssa-templates';
  updatePath = 'update-ssa-template';
  deletePath = '';
  retrieveOnePath = 'get-ssa-template';

  protected validate(item: SsaTemplateDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('SSATEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getTemplateById(id: string): Promise<SsaTemplateDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<SsaTemplateDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@SSATEMPLATES', 'ssa-templates.new')
export class NewSsaTemplateStore extends FormStore<CreateSsaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-template';
  retrievePath = 'get-ssa-templates';
  updatePath = 'update-ssa-template';

  protected validate(item: CreateSsaTemplateDto) {
    return new CreateSsaTemplateValidator().validate(item);
  }

  constructor() {
    super('NEW_SSATEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@SSATEMPLATES', 'ssa-templates.change')
export class ChangeSsaTemplateStore extends FormStore<ChangeSsaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-template';
  retrievePath = 'get-ssa-templates';
  updatePath = 'update-ssa-template';

  protected validate(item: ChangeSsaTemplateDto) {
    return new ChangeSsaTemplateValidator().validate(item);
  }

  constructor() {
    super('CHANGE_SSATEMPLATE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
