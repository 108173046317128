import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SsaStepsComponent from '../../../wizards/ssa-wizard-step';
import { SsaSkillTemplateSectionDto } from 'stores/assessments/templates/ssa-templates-store';
import { SsaSkillTemplateSectionCategoryDto } from 'stores/assessments/templates/ssa-templates-store';
import SsaSecondStepSkillSectionComponent from './ssa-wizard-second-step-skill-component';
import './ssa-wizard-second-step.less';

export interface SsaSecondStepProps extends WithTranslation {
  isStepActive?: boolean;
  onChangeProperty: (property: string, value: any) => void;
  onAddNewMachineRelatedSkillsSections?: () => void;
  categoriesSection: SsaSkillTemplateSectionCategoryDto[];
  profession: string;
}

const defaultCheckpoint = {
  description: '',
  questions: []
};
class SsaThirdStepContent extends React.Component<SsaSecondStepProps> {
  state = {};

  changePropertyHandler = (skillSectionCategories: SsaSkillTemplateSectionCategoryDto[]) => {
    this.props.onChangeProperty('skillSectionCategories', skillSectionCategories);
  };

  addCheckpointHandler = (skillId: string, idx: number) => {
    const { categoriesSection } = this.props;
    const skillSectionCategories = categoriesSection.map((categorySection, i) => {
      if (i === idx)
        categorySection.skillSections = categorySection.skillSections.map(skillSection => {
          if (skillSection.skillId === skillId) skillSection.checkpoints = [...skillSection.checkpoints, { ...defaultCheckpoint }];
          return skillSection;
        });
      return categorySection;
    });
    this.changePropertyHandler(skillSectionCategories);
  };

  onChange = (
    skillSection: number,
    categorySection: number,
    property: keyof SsaSkillTemplateSectionDto,
    value: Partial<SsaSkillTemplateSectionDto>
  ) => {
    const skillSectionCategories = this.props.categoriesSection.map((category, i) => {
      if (i === categorySection)
        category.skillSections = category.skillSections.map((sSection, idx) => {
          if (skillSection === idx) sSection[property as string] = value;
          return sSection;
        });
      return category;
    });
    this.changePropertyHandler(skillSectionCategories);
  };

  handleMoveUpSkill = (questionIdx: number, categoryIdx: number) => {
    const skillSectionCategories = this.props.categoriesSection.map((category, i) => {
      if (i === categoryIdx) {
        const skillSections = [...category.skillSections];
        const temporalVariable = { ...skillSections[questionIdx - 1] };
        skillSections[questionIdx - 1] = { ...skillSections[questionIdx] };
        skillSections[questionIdx] = { ...temporalVariable };
        category = { ...category, skillSections };
      }
      return category;
    });
    this.changePropertyHandler(skillSectionCategories);
  };

  handleMoveDownSkill = (questionIdx: number, categoryIdx: number) => {
    const skillSectionCategories = this.props.categoriesSection.map((category, i) => {
      if (i === categoryIdx) {
        const skillSections = [...category.skillSections];
        const temporalVariable = { ...skillSections[questionIdx + 1] };
        skillSections[questionIdx + 1] = { ...skillSections[questionIdx] };
        skillSections[questionIdx] = { ...temporalVariable };
        category = { ...category, skillSections };
      }
      return category;
    });
    this.changePropertyHandler(skillSectionCategories);
  };

  moveSkillHandler = (dir: 'up' | 'down', skillIdx: number, categoryIdx: number) => {
    const { skillSections } = this.props.categoriesSection[categoryIdx];
    if ((skillSections || []).length <= 1) return;
    if (dir === 'up' && skillIdx > 0) this.handleMoveUpSkill(skillIdx, categoryIdx);
    else if (dir === 'down' && skillIdx < skillSections.length - 1) this.handleMoveDownSkill(skillIdx, categoryIdx);
  };

  render() {
    const { t, isStepActive, categoriesSection, profession } = this.props;

    return isStepActive ? (
      <div className="ssa-wizard__step ssa-wizard__step-second">
        <SsaStepsComponent isStepActive stepNumber={'2'} stepTitle={t('SELECT QUESTIONS')} />
        <div className="step-two__category-skills-questions__wrapper">
          {(categoriesSection || []).map((categorySection, index) => (
            <Fragment key={categorySection.name + index}>
              <p className="text__bold">{categorySection.name + t(' Skills')}</p>
              {(categorySection.skillSections || []).map((skillSection, idx) => (
                <SsaSecondStepSkillSectionComponent
                  profession={profession}
                  key={categorySection.name + skillSection.skillId}
                  category={categorySection.name}
                  onAddCheckPoint={id => this.addCheckpointHandler(id, index)}
                  skillSection={skillSection}
                  onChange={(name, val) => this.onChange(idx, index, name, val)}
                  move={dir => this.moveSkillHandler(dir, idx, index)}
                  canGoUp={idx > 0}
                  canGoDown={idx + 1 < categorySection.skillSections.length}
                />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    ) : (
      <div className="ssa-wizard__step ssa-wizard__step-second">
        <SsaStepsComponent isStepActive={isStepActive} stepNumber={'2'} stepTitle={t('SELECT QUESTIONS')} />
      </div>
    );
  }
}

export default withTranslation()(SsaThirdStepContent);
