import { Divider, StrictTabProps } from 'semantic-ui-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './tab-with-header.less';

interface TabWithHeaderProps extends StrictTabProps, WithTranslation {
  header: JSX.Element;
  footer?: JSX.Element;
}

interface TabWithHeaderState {
  activeTabIndex: number;
}

class TabWithHeader extends Component<TabWithHeaderProps, TabWithHeaderState> {
  state = {
    activeTabIndex: 0
  };

  render() {
    const { activeTabIndex } = this.state;
    const { panes, footer, header } = this.props;
    return (
      <div className="tab-pane-with-header">
        <div className="tab-pane-with-header__lateral-menu">
          {(panes || []).map((x, index) => {
            let className = 'item';
            if (index === activeTabIndex) className += ' active';
            return (
              <a key={index} className={className} onClick={() => this.setState({ activeTabIndex: index })}>
                {x.menuItem}
              </a>
            );
          })}
        </div>
        <div className="tab-pane-with-header__content">
          <div className="tab-pane-with-header__tab-header-content">
            {header}
            <Divider></Divider>
            <h4>{panes[activeTabIndex]?.menuItem}</h4>
            {panes[activeTabIndex]?.render()}
          </div>
          {footer && <div className="tab-pane-with-header__footer-content">{footer}</div>}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TabWithHeader);
