import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  EventTypeSfMapDto,
  ChangeEventTypeSfMapDto,
  ChangeEventTypeSfMapStore
} from 'stores/configuration/sf-mappings/event-type-sf-map-store';
import { nameof } from 'utils/object';

interface ChangeEventTypeMapViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  eventTypeMapsStore?: ChangeEventTypeSfMapStore;
  currentDto: EventTypeSfMapDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeEventTypeMapViewState {
  eventType: any;
  userClickedSave: boolean;
}

@connect(['eventTypeMapsStore', ChangeEventTypeSfMapStore])
class ChangeEventTypeMapView extends React.Component<ChangeEventTypeMapViewProps, ChangeEventTypeMapViewState> {
  private get changeEventTypeMapStore() {
    return this.props.eventTypeMapsStore;
  }

  constructor(props: ChangeEventTypeMapViewProps) {
    super(props);

    this.changeEventTypeMapStore.state.result = null;

    this.changeEventTypeMapStore.change({
      id: this.props.currentDto.id,
      eventTypeId: this.props.currentDto.eventTypeId,
      mappingToSF: this.props.currentDto.mappingToSF,
      itemType: this.props.currentDto.itemType,
      status: this.props.currentDto.status,
      eventTypeName: this.props.currentDto.eventTypeName
    });

    this.state = {
      eventType: this.props.currentDto.eventTypeId
        ? { id: this.props.currentDto.eventTypeId, title: this.props.currentDto.eventTypeName }
        : null,
      userClickedSave: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeEventTypeMapViewProps) {
    if (this.changeEventTypeMapStore.state.result && this.changeEventTypeMapStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSave: true });
    this.changeEventTypeMapStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeEventTypeMapStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.changeEventTypeMapStore.change({ ...this.changeEventTypeMapStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeEventTypeMapStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Event Type Map')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeEventTypeMapStore.state.result && !this.changeEventTypeMapStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeEventTypeMapStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeEventTypeMapStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    className="normal-looking-disabled-input"
                    disabled
                    fluid
                    label={t('Event Type Map')}
                    placeholder={t('Event Type Map')}
                    value={this.changeEventTypeMapStore.state.item.eventTypeName}
                  />
                  <Form.Input
                    className="normal-looking-disabled-input"
                    fluid
                    label={t('Status')}
                    placeholder={t('Status')}
                    value={this.changeEventTypeMapStore.state.item.status}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeEventTypeMapStore.state.item.itemType && this.state.userClickedSave}
                    label={t('Item Type')}
                    placeholder={t('Item Type')}
                    value={this.changeEventTypeMapStore.state.item.itemType}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeEventTypeSfMapDto>('itemType'), value)}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.changeEventTypeMapStore.state.item.mappingToSF && this.state.userClickedSave}
                    label={t('Mapping To SF')}
                    placeholder={t('Mapping To SF')}
                    value={this.changeEventTypeMapStore.state.item.mappingToSF}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeEventTypeSfMapDto>('mappingToSF'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeEventTypeMapView);
