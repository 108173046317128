import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import GeneralDataStep from './steps/general-data/general-data-step';
import { Button, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { RouteComponentProps } from 'react-router';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LineSeparator from 'widgets/bussiness/line-separator';
import SsaTemplateStep from './steps/ssa-template-step/ssa-template-step';
import EmployeesAndAssessorsStep from 'site/pages/assessments/ssa/forms/wizard/steps/employees-and-assessors/employees-and-assessors-step';
import {
  SsaFormWizardStore,
  SsaFormWizardViewModelValidator,
  ChangeSsaFormWizardStore
} from 'stores/assessments/wizard/ssa-form-wizard-store';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormItemDto } from 'stores/assessments/forms/ssa-forms-store';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import { DateTimeService } from 'services/datetime-service';

export interface SsaFormWizardProps extends WithTranslation, RouteComponentProps {
  onSubmit?: () => void;
  ssaFormWizardStore?: SsaFormWizardStore;
  isOnEditMode?: boolean;
  formToEdit?: SsaFormItemDto;
  userFormInfo?: UserProfilesDto;
  assessorsInfo?: any;
  changeSsaFormWizardStore?: ChangeSsaFormWizardStore;
  loading?: boolean;
}

export interface SsaFormWizardState {
  loading: boolean;
  step: number;
  showCancelConfirm: boolean;
  isGlobalPoc: boolean;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
@connect(['changeSsaFormWizardStore', ChangeSsaFormWizardStore])
class SsaFormWizard extends Component<SsaFormWizardProps, SsaFormWizardState> {
  constructor(props: Readonly<SsaFormWizardProps>) {
    super(props);
    this.state = {
      loading: props.isOnEditMode ? true : false,
      step: 1,
      showCancelConfirm: false,
      isGlobalPoc: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    this.setState({
      isGlobalPoc
    });
  }

  handleOnSubmit = () => {
    this.props.onSubmit && this.props.onSubmit();
  };

  private checkStep1 = () => {
    const { location, profileId, deadline } = this.props.ssaFormWizardStore.state.item;
    return (
      !isNullOrWhiteSpaces(profileId) &&
      !isNullOrWhiteSpaces(deadline) &&
      DateTimeService.isValid(deadline) &&
      location != null &&
      !isNullOrWhiteSpaces(location.id)
    );
  };

  private checkStep2 = () => {
    const { template } = this.props.ssaFormWizardStore.state.item;
    return !!template;
  };

  private updateStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      this.setState({ step: 1 });
      return;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      this.setState({ step: 2 });
      return;
    }
    this.setState({ step: 3 });
  };

  private onCancelChangesClicked = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/ssa?tab=3' });
  };

  private canSubmit = () => {
    const validationResult = new SsaFormWizardViewModelValidator().extendValidate(this.props.ssaFormWizardStore.state.item);
    return validationResult.isValid();
  };

  private onCancelClicked = () => {
    this.setState({ showCancelConfirm: true });
  };

  private onBackClicked = () => {
    this.setState({ showCancelConfirm: false });
  };

  private toReturnMessages = () => {
    let message;
    if (!this.props.isOnEditMode) {
      message = (this.props.ssaFormWizardStore.state.result && this.props.ssaFormWizardStore.state.result.messages) || [];
    } else if (this.props.isOnEditMode) {
      message = message =
        (this.props.changeSsaFormWizardStore.state.result && this.props.changeSsaFormWizardStore.state.result.messages) || [];
    }
    return message;
  };

  render() {
    const { t, isOnEditMode, formToEdit, userFormInfo, assessorsInfo } = this.props;
    const { step, showCancelConfirm, isGlobalPoc } = this.state;
    const canSubmit = this.canSubmit();
    const messages = this.toReturnMessages();

    return (
      <>
        <h3 className="template-wizard-title">{t(`Shopfloor Skills Assessments`)}</h3>
        <LineSeparator />
        {messages && messages.length > 0 && (
          <Message className="error-message__style" icon="exclamation circle" error list={messages.map(m => m.body)} />
        )}

        <div className="ssa-wizard__steps-container ssa-form-wizzard">
          <GeneralDataStep
            isEditMode={isOnEditMode}
            active={!isOnEditMode ? step >= 1 : true}
            onStepChanged={this.updateStep}
            isGlobalPoc={isGlobalPoc}
          />
          <SsaTemplateStep isEditMode={isOnEditMode} active={!isOnEditMode ? step >= 2 : true} onStepChanged={this.updateStep} />
          <EmployeesAndAssessorsStep
            assessorsInfo={assessorsInfo}
            userFormInfo={userFormInfo}
            formToEdit={formToEdit}
            isEditMode={isOnEditMode}
            active={!isOnEditMode ? step >= 3 : true}
            isGlobalPoc={isGlobalPoc}
          />
        </div>
        <div className="ssa-templates-wizard__btns">
          <Button secondary inverted onClick={this.onCancelClicked}>
            {t('Cancel')}
          </Button>
          <Button primary positive onClick={this.handleOnSubmit} disabled={!canSubmit}>
            {isOnEditMode ? t('Save Changes') : t('Generate SSA')}
          </Button>
        </div>
        {showCancelConfirm && (
          <Modal open={showCancelConfirm} size="mini" className="ssa-confirmation-modal" closeOnEscape={true} onClose={this.onBackClicked}>
            <Modal.Content className="ssa-confirmation-modal__content">
              <p>
                {t('Are you sure you want to CANCEL?')}
                <br />
                {t('You will lose all the changes made in this SSA.')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button size="tiny" className="ssa-confirmation-modal__btn cancel" content={t('Back')} onClick={this.onBackClicked} />
              <Button
                size="tiny"
                className="ssa-confirmation-modal__btn"
                content={t('Cancel Changes')}
                onClick={this.onCancelChangesClicked}
              />
            </Modal.Actions>
          </Modal>
        )}
        <Dimmer
          active={this.props.ssaFormWizardStore.state.isBusy}
          style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}
        >
          <Loader indeterminate></Loader>
        </Dimmer>
      </>
    );
  }
}

export default withTranslation()(SsaFormWizard);
