import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';

import { TrainingLevelDto, TrainingLevelsStore } from '../../stores/configuration/events-n-requests/training-levels-store';
import { nameof } from '../../utils/object';
import { SelectionInput, DropdownProps, SelectionItem } from 'widgets/form/selectionInput';
import { getOptionsFromValue, ItemReference, QueryResult } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import React, { Component } from 'react';

interface TrainingLevelEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange?: (value: ItemReference) => void;
  trainingLevels?: TrainingLevelsStore;
  reloadOnChange?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  loadDataOnOpen?: boolean;
}

interface TrainingLevelEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['trainingLevels', TrainingLevelsStore])
class TrainingLevelEditor extends Component<TrainingLevelEditorProps, TrainingLevelEditorState> {
  private get trainingLevelStore() {
    return this.props.trainingLevels;
  }

  constructor(props: TrainingLevelEditorProps) {
    super(props);

    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getTrainingLevels(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange } = this.props;

    if (prevProps.value?.id !== value?.id) {
      if (reloadOnChange)
        this.setState({
          value,
          // query: this.getTrainingLevels()
          options: getOptionsFromValue(this.state?.query, value)
        });
      else this.setState({ value });
    }
  }

  private getTrainingLevels = () => {
    const method = async (search: string) => {
      const result = await this.trainingLevelStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<TrainingLevelDto>('name'), useProfile: false }],
        filter: isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<TrainingLevelDto>('name')}), '${search.toLowerCase()}')`]
      });

      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  };

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getTrainingLevels() });
    }
  };

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        content={item => <>{item.title}</>}
        searchable
        className={this.props.className}
        nullable={this.props.nullable}
        clearable={this.props.clearable}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(TrainingLevelEditor);
