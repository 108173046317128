import { useState, FC, memo } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import { DateTimePeriod } from 'stores/events/events-store';
import { DateInput } from './dateInput';
import { DateTimeService } from 'services/datetime-service';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface PeriodInputProps extends WithTranslation {
  readOnly?: boolean;
  clearable?: boolean;
  onChange: (period: DateTimePeriod) => void;
  value: DateTimePeriod;
  minMaxDate?: boolean;
  max?: string;
  yearRangeAvailable?: boolean;
  /** If FALSE won't be render an icon */
  icon?: SemanticICONS | false;
  iconPosition?: any;
  id?: string;
  min?: string;
  defaultStartDate?: string;
  defaultEndDate?: string;
}

const PeriodInput: FC<PeriodInputProps> = props => {
  const {
    readOnly,
    value,
    minMaxDate,
    yearRangeAvailable,
    icon,
    clearable,
    iconPosition,
    min,
    max,
    defaultStartDate,
    defaultEndDate
  } = props;
  const [openPopup, setOpenPopup] = useState(() => !!value?.from || false);

  const handleFromDate = (date: string) => {
    const value = props.value ? { ...props.value } : { from: null, to: null };
    value.from = date;
    setOpenPopup(DateTimeService.isValid(date));
    props.onChange(value);
  };

  const handleToDate = (date: string) => {
    const value = props.value ? { ...props.value } : { from: null, to: null };
    value.to = date;
    setOpenPopup(false);
    props.onChange(value);
  };

  const fromMin =
    yearRangeAvailable && value?.to ? DateTimeService.toString(DateTimeService.toMoment(value.to).subtract(1, 'years')) : null;
  const toMax = yearRangeAvailable && value?.from ? DateTimeService.toString(DateTimeService.toMoment(value.from).add(1, 'years')) : null;
  return (
    <>
      <Form.Group>
        <Form.Field>
          <DateInput
            iconPosition={iconPosition}
            className="planit-eventform-dates-period from-date"
            readOnly={readOnly}
            value={value?.from || ''}
            initialValue={defaultStartDate ? defaultStartDate : null}
            onChange={(_, data) => handleFromDate(data)}
            maxDate={max || (minMaxDate && value?.to) || null}
            minDate={fromMin}
            icon={icon || null}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <DateInput
            id={'period-to-' + props.id}
            iconPosition={iconPosition}
            className="planit-eventform-dates-period to-date"
            readOnly={readOnly}
            value={value?.to || ''}
            forceOpen={openPopup}
            initialValue={value?.from ? value?.from : defaultEndDate ? defaultEndDate : null}
            onChange={(_, data) => handleToDate(data)}
            minDate={min || (minMaxDate && value?.from) || null}
            maxDate={toMax}
            icon={icon || null}
          />
        </Form.Field>
      </Form.Group>
      {clearable && !readOnly && (
        <Icon style={{ marginLeft: 8, cursor: 'pointer' }} color="red" size="large" name="times" onClick={() => props.onChange(null)} />
      )}
    </>
  );
};

export default withTranslation()(memo(PeriodInput));
