import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface PlcTypeDto extends BaseDto {
  id: string;
  name: string;
  clusterId: string;
  clusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
}

export interface CreatePlcTypeDto {
  name: string;
  equipmentTypeId: string;
  oemId: string;
}

export interface ChangePlcTypeDto {
  id: string;
  name: string;
  equipmentTypeId: string;
  oemId: string;
}

export class CreatePlcTypeValidator extends AbstractValidator<CreatePlcTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('PLC Type name is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));
  }
}

export class ChangePlcTypeValidator extends AbstractValidator<ChangePlcTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('PLC Type Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('PLC Type name is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));
  }
}

@repository('@@PLC-TYPES', 'plcTypes.summary')
export class PlcTypesStore extends DataStore<PlcTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-plc-type';
  retrievePath = 'get-plc-types';
  updatePath = 'update-plc-type';
  deletePath = 'delete-plc-type';

  protected validate(item: PlcTypeDto) {
    return new ChangePlcTypeValidator().validate(item);
  }

  constructor() {
    super('PLC_TYPE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PLC-TYPES', 'plcTypes.new')
export class NewPlcTypeStore extends FormStore<CreatePlcTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-plc-type';
  retrievePath = 'get-plc-types';
  updatePath = 'update-plc-type';

  protected validate(item: CreatePlcTypeDto) {
    return new CreatePlcTypeValidator().validate(item);
  }

  constructor() {
    super('NEW_PLC_TYPE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PLC-TYPES', 'plcTypes.change')
export class ChangePlcTypeStore extends FormStore<ChangePlcTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-plc-type';
  retrievePath = 'get-plc-types';
  updatePath = 'update-plc-type';

  protected validate(item: ChangePlcTypeDto) {
    return new ChangePlcTypeValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PLC_TYPE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
