import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import '../templates/wizard/ssa-templates-wizard.less';

export interface SsaTemplateWizardStepProps extends WithTranslation {
  stepNumber: string;
  stepTitle: string;
  isStepActive: boolean;
}

export interface SsaTemplateWizardStep1State {}

class SsaStepsComponent extends React.Component<SsaTemplateWizardStepProps, SsaTemplateWizardStep1State> {
  constructor(props: SsaTemplateWizardStepProps) {
    super(props);
    this.state = {
      loading: null,
      data: null
    };
  }

  render() {
    const { t, isStepActive } = this.props;

    return (
      <>
        <div className={isStepActive ? 'ssa-wizard__label-title flex-start' : 'ssa-wizard__label-title flex-start inactive-step'}>
          <Label className="step__label-style" circular>
            {this.props.stepNumber}
          </Label>
          <p className="template-wizard__step-title">{t(this.props.stepTitle)}</p>
        </div>
      </>
    );
  }
}

export default withTranslation()(SsaStepsComponent);
