import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SsaSkillSectionQuestionDto } from 'stores/assessments/templates/ssa-templates-store';
import { Icon } from 'semantic-ui-react';

export interface SsaQuestionComponentProps extends WithTranslation {
  question: SsaSkillSectionQuestionDto;
  move: (direction: 'up' | 'down') => void;
  canGoUp: boolean;
  canGoDown: boolean;
  onRemoveQuestion: () => void;
}

const SsaQuestionComponent: FunctionComponent<SsaQuestionComponentProps> = ({ question, canGoUp, canGoDown, move, onRemoveQuestion }) => {
  return (
    <>
      <div key={question.friendlyId} className="tna-wizard__assign-question">
        <div className="tna-wizard__assign-question__items">
          <div className="wizard__sort-icons-wrapper tna-arrow-left-margin">
            <Icon disabled={!canGoUp} onClick={() => move('up')} name="caret square up outline" />
          </div>
          <div className="wizard__sort-icons-wrapper">
            <Icon disabled={!canGoDown} onClick={() => move('down')} name="caret square down outline" />
          </div>
          <p className="wizard__question-item">{question.friendlyId}</p>
          <p className="wizard__question-item">{question.question}</p>
        </div>
        <Icon onClick={onRemoveQuestion} className="assigned-profile-row-machine__remove" name="remove" size="large" color="red" />
      </div>
    </>
  );
};

export default withTranslation()(SsaQuestionComponent);
