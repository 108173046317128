import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../utils/object';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import { customEqualCompare } from 'utils/useful-functions';
import { ItemReference, OrderDefinition } from 'stores/dataStore';
import { MachineModelDto, MachineModelsStore } from '../../stores/configuration/machinery/machine-models-store';

interface MultiMachineModelEditorProps extends WithTranslation, StrictDropdownProps {
  value: string[];
  onChange: (value: any) => void;
  placeholder?: string;
  clearable?: boolean;
  machineModels?: MachineModelsStore;
  multiple?: boolean;
  readOnly?: boolean;
  className?: string;
  equipmentId?: string;
  oemId?: string;
  clusterId?: string;
}

interface MultiMachineModelEditorState {
  machineModelOptions: { text: string; value: string }[];
}

@connect(['machineModels', MachineModelsStore])
class MultiMachineModelEditor extends Component<MultiMachineModelEditorProps, MultiMachineModelEditorState> {
  static defaultProps = { multiple: true };
  private get machineModelStore() {
    return this.props.machineModels;
  }

  state: MultiMachineModelEditorState = { machineModelOptions: [] };

  componentDidMount() {
    this.loadMachineModels();
  }

  componentDidUpdate({ equipmentId, oemId, clusterId }: MultiMachineModelEditorProps) {
    if (
      !customEqualCompare(clusterId, this.props.clusterId) ||
      !customEqualCompare(equipmentId, this.props.equipmentId) ||
      !customEqualCompare(oemId, this.props.oemId)
    )
      this.loadMachineModels();
  }

  private loadMachineModels = async () => {
    const filter = [];
    const { equipmentId, oemId, clusterId } = this.props;

    if (clusterId) filter.push(`${nameof<MachineModelDto>('clusterId')} eq ${this.props.clusterId}`);
    if (equipmentId) filter.push(`${nameof<MachineModelDto>('equipmentTypeId')} eq ${this.props.equipmentId}`);
    if (oemId) filter.push(`${nameof<MachineModelDto>('oemId')} eq ${this.props.oemId}`);
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<MachineModelDto>('name'), useProfile: false }];

    return await this.machineModelStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }).then(machineModels => {
      const options = [];
      machineModels.items.forEach(machineModel => options.push({ text: machineModel.name, value: machineModel.id }));
      this.setState({ machineModelOptions: options });
    });
  };

  private handleOnChange = (values: string[] = []) => {
    const { machineModelOptions } = this.state;
    const result: ItemReference[] = (machineModelOptions || [])
      .filter(({ value }) => values.find(x => x === value) != null)
      .map(x => ({ id: x.value, title: x.text }));
    this.props.onChange(result);
  };

  public render() {
    const { machineModelOptions } = this.state;
    const { t, readOnly, multiple, error, className, value, placeholder } = this.props;
    const classname = className;
    return (
      <Dropdown
        search
        selection
        closeOnChange
        closeOnEscape
        selectOnBlur={false}
        clearable
        multiple={multiple}
        options={machineModelOptions}
        error={error}
        className={classname}
        value={multiple ? value || [] : value}
        onChange={(_, { value }) => this.handleOnChange(value as string[])}
        disabled={readOnly}
        placeholder={placeholder || t('Select Machine Model')}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiMachineModelEditor);
