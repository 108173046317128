import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Modal, Button, Header, Grid } from 'semantic-ui-react';
import { EmployeeTrackLogItem, EmployeeTrackLogStore } from 'stores/employee-track-log/employee-track-log-store';
import { OrderDefinition, Query } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { DateTimeService } from 'services/datetime-service';
import { DatesFilter } from 'widgets/collections/table-filters/dates-filter';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

export interface EmployeeTrackLogsPageProps extends WithTranslation, RouteComponentProps {
  employeeTrackLogStore: EmployeeTrackLogStore;
}

export interface EmployeeTrackLogsPageState {
  query: Query;
  activeFilters: string[];
  selectedItem: EmployeeTrackLogItem;
  showMessagesModal: boolean;
  someFilterOpened: boolean;
}

@connect(['employeeTrackLogStore', EmployeeTrackLogStore])
class EmployeeTrackLogsPage extends React.Component<EmployeeTrackLogsPageProps, EmployeeTrackLogsPageState> {
  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<EmployeeTrackLogItem>('executionTimestamp'), useProfile: false }],
        skip: 0,
        take: 10
      },
      showMessagesModal: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.employeeTrackLogStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, this.load);
  }

  private onSeeMessages = (item: EmployeeTrackLogItem) => {
    this.setState({
      showMessagesModal: true,
      selectedItem: item
    });
  };

  private onCloseMessagesModal = () => {
    this.setState({
      showMessagesModal: false,
      selectedItem: undefined
    });
  };

  private getMessages = (item: EmployeeTrackLogItem) => {
    const { t } = this.props;
    if (item == null || item.messages == null || item.messages.length === 0) return <li>{t('No messages to show')}</li>;
    const msgs = item.messages.filter(m => m.startsWith('[WRN]') || m.startsWith('[ERR]'));
    if (msgs.length === 0) return <li>{t('No messages to show')}</li>;
    return msgs.map((m, idx) => <li key={`${item.id}_${idx}`}>{m}</li>);
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, showMessagesModal, selectedItem, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Execution Timestamp'),
          tooltipRenderer: true,
          renderer: data => DateTimeService.toDateTimeCleanExtended(DateTimeService.toString(data.executionTimestamp)),
          newClassFromComponent: data => 'table__more-text-long',
          selectableHeader: true,
          sortDefinition: {
            field: nameof<EmployeeTrackLogItem>('executionTimestamp'),
            useProfile: false,
            active: 'Descending'
          },
          headerRenderer: (title: string, onFilter, onClear) => (
            <DatesFilter
              filterTitle={t('Filter by Execution Timestamp')}
              triggerTitle={title}
              onFilter={(start, end) => {
                onFilter(nameof<EmployeeTrackLogItem>('executionTimestamp'), {
                  executionTimestamp: {
                    ge: { value: start, type: 'raw' },
                    le: { value: end, type: 'raw' }
                  }
                });
              }}
              onClear={() => onClear(nameof<EmployeeTrackLogItem>('executionTimestamp'))}
              active={activeFilters.includes(nameof<EmployeeTrackLogItem>('executionTimestamp'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Result'),
          tooltipRenderer: false,
          renderer: data =>
            data.success ? (
              <>
                <Icon color="green" name="check circle"></Icon> {t('Success')}
              </>
            ) : (
              <>
                <Icon color="red" name="remove circle"></Icon> {t('Failure')}
              </>
            ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<EmployeeTrackLogItem>('success'),
            useProfile: false
          },
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Result')}
              trueLabel={t('Success')}
              falseLabel={t('Failure')}
              triggerTitle={title}
              onFilter={(value: boolean) => onFilter(nameof<EmployeeTrackLogItem>('success'), { success: value })}
              onClear={() => onClear(nameof<EmployeeTrackLogItem>('success'))}
              active={activeFilters.includes(nameof<EmployeeTrackLogItem>('success'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Events Found'),
          tooltipRenderer: false,
          renderer: data => `${data.eventsFound} ${t('event')}${data.eventsFound === 1 ? '' : 's'}`,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<EmployeeTrackLogItem>('eventsFound'),
            useProfile: false
          }
        },
        {
          title: t('Users Sent'),
          tooltipRenderer: false,
          renderer: data => `${data.usersSent} ${t('user')}${data.usersSent === 1 ? '' : 's'}`,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<EmployeeTrackLogItem>('usersSent'),
            useProfile: false
          }
        }
      ],
      data: this.props.employeeTrackLogStore.state
    } as TableModel<EmployeeTrackLogItem>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.employeeTrackLogStore.state.result && !this.props.employeeTrackLogStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.employeeTrackLogStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!showMessagesModal}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.onSeeMessages}
              onRowDoubleClick={this.onSeeMessages}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="list" />
                      &nbsp;{t('Extra log messages')}
                    </>
                  ),
                  onClick: this.onSeeMessages
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              canCreateNew={false}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
            />
          </Grid.Row>
        </Grid>
        {showMessagesModal && selectedItem && (
          <Modal open={this.state.showMessagesModal} closeOnEscape={true} onClose={this.onCloseMessagesModal} size="small">
            <Header icon="list" content={t('Extra log messages')} />
            <Modal.Content>
              <ul style={{ overflow: 'auto', maxHeight: '25vh', padding: '0 1em' }}>{this.getMessages(selectedItem)}</ul>
            </Modal.Content>
            <Modal.Actions>
              <Button basic onClick={this.onCloseMessagesModal}>
                {t('OK')}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(EmployeeTrackLogsPage);
