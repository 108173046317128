import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { isUserAnEventActor, hasUserAlreadyPerformedValidation, getNextStatus } from 'utils/event-utils';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface ValidateButtonProps extends WithTranslation {
  onValidated?: () => void;
  eventFormStore?: EventFormStore;
  readOnly: boolean;
}

@connect(['eventFormStore', EventFormStore])
class ValidateButton extends Component<ValidateButtonProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private isValidationAllowedInCurrentEventState(): boolean {
    const event = this.props.eventFormStore.state.item;
    return event.status.toString() !== 'Closed' && event.status.toString() !== 'Completed';
  }

  private isValidateButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      this.isValidationAllowedInCurrentEventState() &&
      isUserAnEventActor(event) &&
      !hasUserAlreadyPerformedValidation(event) &&
      !IdentityService.isWorker(this.identityService.getUserInfo()) &&
      !this.isCurrentEventStateTheLastState()
    );
  };

  private isValidatedButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      this.isValidationAllowedInCurrentEventState() &&
      isUserAnEventActor(event) &&
      hasUserAlreadyPerformedValidation(event) &&
      !IdentityService.isWorker(this.identityService.getUserInfo())
    );
  };

  private isCurrentEventStateTheLastState = (): boolean => {
    const event = this.props.eventFormStore.state.item;

    const nextStatus = getNextStatus(event);
    return nextStatus && nextStatus.toString() === 'Closed';
  };

  private handleOnValidateClick = () => {
    this.props.eventFormStore.validateStatus().then(({ isSuccess }) => {
      isSuccess && this.props.onValidated && this.props.onValidated();
      isSuccess && ToastComponent({ text: this.props.t('Event successfully validated!'), type: 'success-toast' });
      if (!isSuccess) {
        this.props.eventFormStore.state.result.messages.forEach(
          ({ body }) => body && ToastComponent({ text: body, type: 'warning-toast' })
        );
      }
    });
  };

  render() {
    const { t, readOnly } = this.props;
    const isValidateButtonVisible = this.isValidateButtonVisible();
    const isValidatedButtonVisible = this.isValidatedButtonVisible();

    if (isValidateButtonVisible)
      return (
        <Button
          disabled={!readOnly}
          className="form__actions__button"
          positive
          content={t('Validate')}
          onClick={this.handleOnValidateClick}
        />
      );
    if (isValidatedButtonVisible) return <Button className="form__actions__button" disabled positive content={t('Validated')} />;
    return null;
  }
}

export default withTranslation()(ValidateButton);
