import { ItemReference } from 'stores/dataStore';
import {
  PracticalTemplateItemDto,
  ChangePracticalTemplateItemDto,
  CreatePracticalTemplateItemDto
} from 'stores/assessments/templates/practical-templates-store';
import { MrPracticalTestQuestionItemDto, NmrPracticalTestQuestionItemDto } from 'stores/assessments/questionBank/practical-test-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';

export interface PracticalTemplateWizardViewModel {
  id: string;
  title: string;
  header: string;

  profession: ItemReference;

  machineRelated?: boolean;

  trainingLevel: ItemReference;
  mrCluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;

  machineModel: ItemReference[];

  nmrCluster: ItemReference;
  functionalArea: ItemReference;
  trainingName: ItemReference;
  functionalSubarea: ItemReference;
  mrQuestions: PracticalTestQuestionViewModel[];
  nmrQuestions: PracticalTestQuestionViewModel[];
  eventTypes: ItemReference[];

  isActive: boolean;
}

export interface PracticalTestQuestionViewModel {
  id: string;
  friendlyId: string;
  question: string;
  trainingLevelTitle: string;

  machineRelated: boolean;

  machineUnitTitle: string;
  plcTypeTitle: string;

  nmrClusterTitle: string;
  functionalAreaTitle: string;
}

export function getDefaultViewModel(): PracticalTemplateWizardViewModel {
  return {
    id: null,
    title: '',
    header: null,
    profession: null,
    machineRelated: null,
    trainingLevel: null,
    mrCluster: null,
    equipmentType: null,
    oem: null,
    machineModel: [],
    nmrCluster: null,
    functionalArea: null,
    trainingName: null,
    functionalSubarea: null,
    mrQuestions: [],
    nmrQuestions: [],
    eventTypes: [],
    isActive: true
  };
}

export function mapToViewModel(dto: PracticalTemplateItemDto): PracticalTemplateWizardViewModel {
  return {
    id: dto.id,
    title: dto.title,
    header: dto.header,
    profession: { id: dto.profession?.id, title: dto.profession?.profession },
    machineRelated: dto.isMachineRelated,
    trainingLevel: { id: dto.trainingLevelId, title: dto.trainingLevel?.name },
    mrCluster:
      dto.mrPracticalTemplateDetails != null
        ? { id: dto.mrPracticalTemplateDetails.clusterId, title: dto.mrPracticalTemplateDetails.cluster?.name }
        : null,
    equipmentType:
      dto.mrPracticalTemplateDetails != null
        ? { id: dto.mrPracticalTemplateDetails.equipmentTypeId, title: dto.mrPracticalTemplateDetails.equipmentType?.name }
        : null,
    oem:
      dto.mrPracticalTemplateDetails?.oem != null
        ? { id: dto.mrPracticalTemplateDetails.oemId, title: dto.mrPracticalTemplateDetails.oem?.name }
        : null,
    machineModel:
      dto.mrPracticalTemplateDetails?.machineModels != null
        ? (dto.mrPracticalTemplateDetails.machineModels as MachineModelDto[]).map(m => ({ id: m.id, title: m.name }))
        : null,
    nmrCluster:
      dto.nmrPracticalTemplateDetails != null
        ? { id: dto.nmrPracticalTemplateDetails.nmrClusterId, title: dto.nmrPracticalTemplateDetails.nmrCluster?.name }
        : null,
    functionalArea:
      dto.nmrPracticalTemplateDetails != null
        ? { id: dto.nmrPracticalTemplateDetails.functionalAreaId, title: dto.nmrPracticalTemplateDetails.functionalArea?.name }
        : null,
    trainingName:
      dto.nmrPracticalTemplateDetails?.trainingName != null
        ? { id: dto.nmrPracticalTemplateDetails.trainingNameId, title: dto.nmrPracticalTemplateDetails.trainingName?.name }
        : null,
    functionalSubarea:
      dto.nmrPracticalTemplateDetails?.trainingName != null
        ? { id: dto.nmrPracticalTemplateDetails.functionalSubareaId, title: dto.nmrPracticalTemplateDetails.functionalArea?.name }
        : null,
    mrQuestions: mapToMrQuestionViewModel(dto.mrPracticalTemplateDetails?.mrPracticalQuestions || []),
    nmrQuestions: mapToNmrQuestionViewModel(dto.nmrPracticalTemplateDetails?.nmrPracticalQuestions || []),
    eventTypes: (dto.eventTypes || []).map(x => ({ id: x.originalId, title: x.name })),
    isActive: dto.isActive
  };
}

export function mapToMrQuestionViewModel(questions: MrPracticalTestQuestionItemDto[]): PracticalTestQuestionViewModel[] {
  return (questions || []).map(q => ({
    id: q.id,
    friendlyId: q.friendlyId,
    question: q.question?.text,
    trainingLevelTitle: q.trainingLevel?.name,
    machineRelated: true,
    machineUnitTitle: q.machineUnit?.name,
    plcTypeTitle: q.plcType?.name,
    nmrClusterTitle: null,
    functionalAreaTitle: null
  }));
}

export function mapToNmrQuestionViewModel(questions: NmrPracticalTestQuestionItemDto[]): PracticalTestQuestionViewModel[] {
  return (questions || []).map((q, i) => ({
    id: q.id,
    friendlyId: q.friendlyId,
    question: q.question?.text,
    trainingLevelTitle: q.trainingLevel?.name,
    machineRelated: false,

    machineUnitTitle: null,
    plcTypeTitle: null,

    nmrClusterTitle: q.nmrCluster?.name,
    functionalAreaTitle: q.functionalArea?.name
  }));
}

export function mapToChangeDto(viewModel: PracticalTemplateWizardViewModel): ChangePracticalTemplateItemDto {
  return {
    id: viewModel.id,
    eventTypes: (viewModel.eventTypes || []).map(x => x.id),
    header: viewModel.header,
    isActive: viewModel.isActive,
    title: viewModel.title,
    trainingLevelId: viewModel.trainingLevel?.id,
    mrPracticalTemplateDetails: viewModel.machineRelated
      ? {
          clusterId: viewModel.mrCluster?.id,
          equipmentTypeId: viewModel.equipmentType?.id,
          machineModelsId: (viewModel.machineModel || []).map(mm => mm.id),
          oemId: viewModel.oem?.id,
          mrPracticalQuestions: (viewModel.mrQuestions || []).map(x => x.id)
        }
      : null,
    nmrPracticalTemplateDetails: viewModel.machineRelated
      ? null
      : {
          nmrClusterId: viewModel.nmrCluster?.id,
          functionalAreaId: viewModel.functionalArea?.id,
          functionalSubareaId: viewModel.functionalSubarea?.id,
          trainingNameId: viewModel.trainingName?.id,
          nmrPracticalQuestions: (viewModel.nmrQuestions || []).map(x => x.id)
        }
  };
}

export function mapToCreateDto(viewModel: PracticalTemplateWizardViewModel): CreatePracticalTemplateItemDto {
  return {
    eventTypes: (viewModel.eventTypes || []).map(x => x.id),
    header: viewModel.header,
    isActive: viewModel.isActive,
    title: viewModel.title,
    trainingLevelId: viewModel.trainingLevel?.id,
    isMachineRelated: !!viewModel.machineRelated,
    professionId: viewModel.profession?.id,
    mrPracticalTemplateDetails: viewModel.machineRelated
      ? {
          clusterId: viewModel.mrCluster?.id,
          equipmentTypeId: viewModel.equipmentType?.id,
          machineModelsId: viewModel.machineModel.map(mm => mm.id),
          oemId: viewModel.oem?.id,
          mrPracticalQuestions: (viewModel.mrQuestions || []).map(x => x.id)
        }
      : null,
    nmrPracticalTemplateDetails: viewModel.machineRelated
      ? null
      : {
          nmrClusterId: viewModel.nmrCluster?.id,
          functionalAreaId: viewModel.functionalArea?.id,
          functionalSubareaId: viewModel.functionalSubarea?.id,
          trainingNameId: viewModel.trainingName?.id,
          nmrPracticalQuestions: (viewModel.nmrQuestions || []).map(x => x.id)
        }
  };
}
