import React, { Component } from 'react';
import { Rating } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface RatingAnswerProps extends WithTranslation {
  value: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}

class RatingAnswer extends Component<RatingAnswerProps> {
  private handleOnChangeRating = (value: string) => {
    if (!this.props.readOnly) this.props.onChange && this.props.onChange(value);
  };

  private handleOnNAChangeRating = () => {
    const rating = this.props.value === 'N/A' ? null : 'N/A';
    this.handleOnChangeRating(rating);
  };

  render() {
    const { t, readOnly, value } = this.props;

    return (
      <div className="feedback-form_stars-container">
        <Rating
          disabled={readOnly}
          rating={value === 'N/A' ? '0' : value}
          className="feedback-form_stars"
          maxRating={4}
          icon="star"
          size="massive"
          onRate={(_, { rating }) => this.handleOnChangeRating(rating as string)}
        />
        <Rating
          disabled={readOnly}
          rating={value === 'N/A' ? '1' : null}
          className="feedback-form_star-na"
          icon="star"
          size="massive"
          onRate={this.handleOnNAChangeRating}
        />
        <p>{t('N/A')}</p>
      </div>
    );
  }
}

export default withTranslation()(RatingAnswer);
