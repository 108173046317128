import React from 'react';
import './notifications.less';
import { Button, List, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { UserNotificationsStore, UnreadNotificationCountStore, NotificationItemDto } from 'stores/events/event-notifications-store';
import { UserTodoStore, UndoneToDoCountStore } from 'stores/events/event-todo-store';
import { Query } from 'stores/dataStore';
import NotificationsTypeIcon from './notification-type-icon';
import { RouteComponentProps } from 'react-router';
import { nameof } from 'utils/object';
import i18n from 'i18n';
import emptyNotImage from '../../assets/img/envelope.svg';
import { DateTimeService } from 'services/datetime-service';
import { withTranslation, WithTranslation } from 'react-i18next';

interface NotificationTabProps extends RouteComponentProps, WithTranslation {
  toggleNotification: (any?) => void;
  notificationStore?: UserNotificationsStore;
  unreadNotificationsStore?: UnreadNotificationCountStore;
  UserTodoStore?: UserTodoStore;
  undoneToDoCountStore?: UndoneToDoCountStore;
}

interface NotificationTabState {
  activeTab: 'Notifications' | 'To-do list';
  query: Query;
  activeFilters: { [notificationType: string]: boolean };
  offsetShow: number;
  isBusy: boolean;
}

@connect(['notificationStore', UserNotificationsStore], ['unreadNotificationsStore', UnreadNotificationCountStore])
class NotificationTab extends React.Component<NotificationTabProps, NotificationTabState> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Notifications',
      activeFilters: this.initActiveFilters(),
      query: {
        searchQuery: '',
        orderBy: [
          { direction: 'Descending', field: nameof<NotificationItemDto>('pinned'), useProfile: false },
          { direction: 'Descending', field: nameof<NotificationItemDto>('createdOn'), useProfile: false }
        ],
        skip: 0,
        take: this.elementsPerPage
      },
      offsetShow: 0,
      isBusy: true
    };
  }

  private elementsPerPage = 7;

  initActiveFilters(): { [notificationType: string]: boolean } {
    let activeFilters = {};
    let filters = ['SystemInfo', 'EventChanges', 'RequestChanges', 'EventChatMessage', 'RequestChatMessage'];
    filters.forEach(filter => (activeFilters[filter] = false));
    return activeFilters;
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    this.props.notificationStore
      .getAllAsync(this.state.query)
      .then(res => this.setState({ isBusy: false }))
      .catch(error => {
        this.setState({ isBusy: false });
      });
  };

  handlePinnedNotification = ({ currentTarget: { id: pinnedClickedId } }) => {
    const { notificationStore } = this.props;
    const pinnedId = pinnedClickedId.replace('pin-', '');
    const notificationToChange = notificationStore.state.items.find(({ item: { id } }) => id === pinnedId);
    notificationToChange.item.pinned = !notificationToChange.item.pinned;
    notificationStore.saveAsync(notificationToChange.item, 'Changed');

    this.load();
  };

  handleClickNotification = (id?) => {
    const notificationToChange = this.props.notificationStore.state.items.find(notification => notification.item.id === id);
    notificationToChange.item.read = true;
    this.props.notificationStore.saveAsync(notificationToChange.item, 'Changed');

    const eventType = notificationToChange.item.type;
    const itemId = notificationToChange.item.relatedItemId;

    if (eventType === 'EventChatMessage' || eventType === 'EventChanges') {
      this.props.history.push(`/events/${itemId}`);
      this.props.toggleNotification();
    } else if (eventType === 'RequestChatMessage' || eventType === 'RequestChanges') {
      this.props.history.push(`/requests/${itemId}`);
      this.props.toggleNotification();
    }
    // else if(eventType === "SystemInfo"){
    // }
  };

  handleMarkAllNotificationRead = e => {
    this.setState({ isBusy: true });
    this.props.notificationStore.clearAllAsync().then(res => {
      this.setState({ isBusy: false });
    });
  };

  handleClickMessageFromChatFilter = e => {
    let activeFilters = this.state.activeFilters;
    activeFilters['EventChatMessage'] = !activeFilters['EventChatMessage'];
    activeFilters['RequestChatMessage'] = !activeFilters['RequestChatMessage'];

    this.getNotificationItems(activeFilters);
  };

  handleClickSystemMessageFilter = e => {
    let activeFilters = this.state.activeFilters;
    activeFilters['SystemInfo'] = !activeFilters['SystemInfo'];

    this.getNotificationItems(activeFilters);
  };

  private getNotificationItems(activeFilters) {
    let filters = [];

    if (activeFilters['RequestChanges']) filters.push({ Type: 'RequestChanges' });

    if (activeFilters['EventChanges']) filters.push({ Type: 'EventChanges' });

    if (activeFilters['SystemInfo']) filters.push({ Type: 'SystemInfo' });

    if (activeFilters['EventChatMessage']) filters.push({ Type: 'EventChatMessage' });

    if (activeFilters['RequestChatMessage']) filters.push({ Type: 'RequestChatMessage' });

    let typeFilter = filters.length !== 0 ? { or: filters } : [];
    let newQuery = { ...this.state.query };
    newQuery.skip = 0;
    newQuery.filter = typeFilter;

    this.setState({ activeFilters, query: newQuery });

    this.props.notificationStore.getAllAsync(newQuery);
  }

  handleClickEventRequestMessageFilter = e => {
    let activeFilters = this.state.activeFilters;
    activeFilters['RequestChanges'] = !activeFilters['RequestChanges'];
    activeFilters['EventChanges'] = !activeFilters['EventChanges'];

    this.getNotificationItems(activeFilters);
  };

  filterByNotificationType(notification: NotificationItemDto) {
    if (this.state.activeFilters['SystemInfo'] && notification.type === 'SystemInfo') return true;

    if (this.state.activeFilters['EventChatMessage'] && notification.type === 'EventChatMessage') return true;

    if (this.state.activeFilters['RequestChatMessage'] && notification.type === 'RequestChatMessage') return true;

    if (this.state.activeFilters['RequestChanges'] && notification.type === 'RequestChanges') return true;

    if (this.state.activeFilters['EventChanges'] && notification.type === 'EventChanges') return true;

    return (
      !this.state.activeFilters['SystemInfo'] &&
      !this.state.activeFilters['EventChatMessage'] &&
      !this.state.activeFilters['RequestChatMessage'] &&
      !this.state.activeFilters['RequestChanges'] &&
      !this.state.activeFilters['EventChanges']
    );
  }

  // Go next on pagination
  goNextPage = () => {
    if (this.props.notificationStore.state.isBusy) return;

    let nextSkipPositionPage = this.state.offsetShow + this.elementsPerPage;
    // cant query more than existent notifications
    if (nextSkipPositionPage > this.props.notificationStore.state.count) {
      nextSkipPositionPage = this.props.notificationStore.state.count - this.elementsPerPage;
    }

    let newQuery = { ...this.state.query };
    newQuery.skip = nextSkipPositionPage;

    this.setState({ query: newQuery, offsetShow: nextSkipPositionPage, isBusy: true });

    this.props.notificationStore
      .getAllAsync(newQuery)
      .then(res => {
        this.setState({ isBusy: false });
      })
      .catch(err => this.setState({ isBusy: false }));
  };

  // Go forward on pagination
  goPreviousPage = e => {
    if (this.props.notificationStore.state.isBusy) return;

    let nextSkipPositionPage = this.state.offsetShow - this.elementsPerPage;
    // Cant query less than 0 index
    if (nextSkipPositionPage < 0) {
      nextSkipPositionPage = 0;
    }

    let newQuery = { ...this.state.query };
    newQuery.skip = nextSkipPositionPage;

    this.setState({
      query: newQuery,
      offsetShow: nextSkipPositionPage,
      isBusy: true
    });

    this.props.notificationStore
      .getAllAsync(newQuery)
      .then(res => {
        this.setState({ isBusy: false });
      })
      .catch(err => this.setState({ isBusy: false }));
  };

  render() {
    const { t, notificationStore } = this.props;
    const { count, items, storeBusy } = notificationStore.state;
    const { offsetShow, isBusy, activeFilters } = this.state;
    let content = (
      <Dimmer inverted active>
        <Loader indeterminate inverted>
          {i18n.t('Loading notification')}
        </Loader>
      </Dimmer>
    );

    let pagination = (
      <div className="notification__pagination-wrapper">
        <div className="pagination__display-flex-elements">
          <div className="notif__pag__link notif__pag__previous">
            <div onClick={this.goPreviousPage} className={offsetShow <= 0 ? 'notif__previous-page hidden' : 'notif__previous-page'}>
              <Icon name="arrow left" />
              Previous
            </div>
          </div>
          <div className="notif__pag__link notif__pag__next">
            <div
              onClick={this.goNextPage}
              className={offsetShow + this.elementsPerPage >= count ? 'notif__next-page hidden' : 'notif__next-page'}
            >
              Next
              <Icon name="arrow right" />
            </div>
          </div>
        </div>
      </div>
    );

    if (!isBusy) {
      content = (
        <>
          <div className="notif__header__buttons-container">
            <div className="notif__header__buttons-container__icons-wrapper">
              <Button
                size="mini"
                className={
                  activeFilters['EventChatMessage'] || activeFilters['RequestChatMessage']
                    ? 'notification__filter-btn notification__filter-btn-is-active'
                    : 'notification__filter-btn'
                }
                icon="comments"
                onClick={this.handleClickMessageFromChatFilter}
              />
              <Button
                size="mini"
                className={
                  activeFilters['SystemInfo'] ? 'notification__filter-btn notification__filter-btn-is-active' : 'notification__filter-btn'
                }
                icon="info"
                onClick={this.handleClickSystemMessageFilter}
              />
              <Button
                size="mini"
                className={
                  activeFilters['RequestChanges'] || activeFilters['EventChanges']
                    ? 'notification__filter-btn notification__filter-btn-is-active'
                    : 'notification__filter-btn'
                }
                icon="star"
                onClick={this.handleClickEventRequestMessageFilter}
              />
            </div>
            <div className="notif__header__buttons-container__mark-all-wrapper">
              <Button className="ui button notif__mark-all-btn" onClick={this.handleMarkAllNotificationRead}>
                Mark all as read
              </Button>
            </div>
          </div>
          {items.length <= 0 && (
            <div className="notifications-not-found__wrapper">
              <img className="notif__empty__image" src={emptyNotImage} alt="envelope empty" />
              <p>{i18n.t("You don't have any notifications")}</p>{' '}
            </div>
          )}
          {items.length >= 1 && (
            <List className="notifications__container">
              {items
                .filter(notification => this.filterByNotificationType(notification.item))
                .map(({ item }) => (
                  <List.Item
                    id={item.id}
                    loading={storeBusy}
                    className={
                      item.read ? 'notification__message-box notification-was-read' : 'notification__message-box  notification__is-not-read'
                    }
                  >
                    <div className="notification__date-hour">{DateTimeService.toNotificationString(item.createdOn)}</div>

                    <div
                      className="notification__icon-event__wrapper"
                      onClick={() => this.handleClickNotification(item.id)}
                      id={`icon-event-title-${item.id}`}
                    >
                      <div className="notification__icon">
                        <NotificationsTypeIcon notificationType={item.type} />
                      </div>
                      <p className="notification__message-title">{item.title ? item.title : item.content}</p>
                    </div>
                    <div className="notification__pinned-go-to__wrapper">
                      <Icon
                        name="pin"
                        className={item.pinned ? 'pin__active' : 'pin__not-active'}
                        id={`pin-${item.id}`}
                        onClick={this.handlePinnedNotification}
                      />

                      <p
                        className="notification__message-go-to-event"
                        onClick={() => this.handleClickNotification(item.id)}
                        id={`go-to-modal-${item.id}`}
                      >
                        {t('Go to the chat')}
                      </p>
                    </div>
                  </List.Item>
                ))}
            </List>
          )}
          {pagination}
        </>
      );
    }
    return content;
  }
}

export default withTranslation()(NotificationTab);
