import React, { Fragment, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PracticalFormItemDto } from 'stores/assessments/forms/practical-form-store';
import './practical-tab.less';

interface PracticalTableEmployeeProps extends WithTranslation {
  practicalFormAnswers: PracticalFormItemDto[];
  templateId: string;
  currentUserId: string;
}

const PracticalEmployeeTable: FC<PracticalTableEmployeeProps> = ({ t, practicalFormAnswers, currentUserId }) => {
  const items = (practicalFormAnswers || []).filter(({ userId }: PracticalFormItemDto) => userId === currentUserId);
  if (items.length <= 0) return null;
  return (
    <div className="form__practical-table form__practical-table__employee-view">
      <p className="feedback-table__name-column">{t('User Name')}</p>
      <p className="feedback-table__name-column">{t('Last Name')}</p>
      <p className="feedback-table__name-column">{t('First Name')}</p>
      <p className="feedback-table__name-column">{t('Location')}</p>
      <p className="feedback-table__name-column">{t('Total Actual Duration')}</p>
      <p className="feedback-table__name-column">{t('Total Target Level')}</p>
      <p className="feedback-table__name-column">{t('Result')}</p>

      {items.map(form => {
        if (!form) return null;
        const { user, id, userId, answerDetails } = form;
        const { answered, numberOfFailedQuestions, numberOfPassedQuestions, totalActualDuration, totalTargetDuration } = answerDetails;

        return (
          <Fragment key={`theoretical-table__${id}-${userId}`}>
            <p className="feedback-table__cell">{user.userName}</p>
            <p className="feedback-table__cell">{user.lastName}</p>
            <p className="feedback-table__cell">{user.firstName}</p>
            <p className="feedback-table__cell">{user.location ? user.location.location : '-'}</p>
            <p className="feedback-table__cell">{totalActualDuration ? totalActualDuration : '-'}</p>
            <p className="feedback-table__cell">{totalTargetDuration ? totalTargetDuration : '-'}</p>
            <p className="feedback-table__cell">
              {answered ? `${numberOfPassedQuestions} Passed /${numberOfFailedQuestions} Failed` : '-'}
            </p>
          </Fragment>
        );
      })}
    </div>
  );
};

export default withTranslation()(PracticalEmployeeTable);
