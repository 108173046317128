import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';
import { ItemReference } from 'stores/dataStore';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import ProfesionEditor from 'widgets/bussiness/profession-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import MachineMOdelEditor from 'widgets/bussiness/machine-model-editor';
import FunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import ClusterEditor from 'widgets/bussiness/cluster-editor';
import FunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import TrainingNameEditor from 'widgets/bussiness/training-name-editor';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import { NewInstructorExpertiseRequestStore } from 'stores/skills/instructor-expertise-request-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

interface NewInstructorExpertiseRequestProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newExpertiseRequest?: NewInstructorExpertiseRequestStore;
}

interface NewInstructorExpertiseRequestState {
  eventType: any;
  role: ItemReference;
  level: ItemReference;
  cluster: ItemReference;
  isMachineRelated: boolean;
  equipmentType: ItemReference;
  machineModel: ItemReference;
  functionalSubArea: ItemReference;
  oem: ItemReference;
  nmrcluster: ItemReference;
  nmrfunctionalarea: ItemReference;
  nrmTrainingName: ItemReference;
  plcTypes: string[];
}

@connect(['newExpertiseRequest', NewInstructorExpertiseRequestStore])
class NewInstructorExpertiseRequestView extends Component<NewInstructorExpertiseRequestProps, NewInstructorExpertiseRequestState> {
  private get newInstructorExpertiseRequestStore() {
    return this.props.newExpertiseRequest;
  }
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: NewInstructorExpertiseRequestState = {
    eventType: null,
    role: null,
    level: null,
    isMachineRelated: true,
    cluster: null,
    equipmentType: null,
    machineModel: null,
    functionalSubArea: null,
    oem: null,
    nmrcluster: null,
    nmrfunctionalarea: null,
    nrmTrainingName: null,
    plcTypes: null
  };

  private onCreateNewItem = async () => {
    const { eventType, role, level, isMachineRelated, cluster, equipmentType, machineModel } = this.state;
    const { functionalSubArea, oem, nmrcluster, nmrfunctionalarea, nrmTrainingName, plcTypes } = this.state;

    this.newInstructorExpertiseRequestStore.createNew({
      eventTypeId: eventType?.id,
      originalEventTypeId: eventType?.originalEventTypeId,
      nmrFunctionalSubAreaId: functionalSubArea?.id,
      roleId: role?.id,
      trainingLevelId: level?.id,
      machineModelId: machineModel?.id,
      equipmentTypeId: equipmentType?.id,
      isMachineRelated,
      machineRelatedClusterId: cluster?.id,
      nmrClusterId: nmrcluster?.id,
      nmrTrainingNameId: nrmTrainingName?.id,
      nmrFunctionalAreaId: nmrfunctionalarea?.id,
      oemId: oem?.id,
      plcTypes: plcTypes
    });

    await this.newInstructorExpertiseRequestStore.submit();
    if (this.newInstructorExpertiseRequestStore.state.result?.isSuccess) this.props.onClose(true);
  };

  private onCancelNewItem = () => {
    this.newInstructorExpertiseRequestStore.clear();
    this.newInstructorExpertiseRequestStore.clearMessages();
    this.props.onClose(false);
    this.setState({
      eventType: null,
      role: null,
      level: null,
      isMachineRelated: true,
      cluster: null,
      equipmentType: null,
      machineModel: null,
      functionalSubArea: null,
      oem: null,
      nmrcluster: null,
      nmrfunctionalarea: null,
      nrmTrainingName: null,
      plcTypes: null
    });
  };

  private isMachineRelated(mode: boolean) {
    this.setState({
      isMachineRelated: mode,
      cluster: null,
      equipmentType: null,
      machineModel: null,
      functionalSubArea: null,
      oem: null,
      nmrcluster: null,
      nmrfunctionalarea: null,
      nrmTrainingName: null,
      plcTypes: null
    });
  }

  private getInstructorName = () => {
    const user = this.identityService.getCurrentUserDto();
    return `${user.firstName} ${user.lastName}`.trim();
  };

  public render() {
    const { t } = this.props;
    const { eventType, role, level, isMachineRelated, cluster, equipmentType, nmrcluster } = this.state;
    const { nmrfunctionalarea, nrmTrainingName, oem, plcTypes, functionalSubArea, machineModel } = this.state;
    const { result, isBusy } = this.newInstructorExpertiseRequestStore.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New Instructor Expertise Request')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (result.messages || []).length > 0 && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}

            <Form>
              <Form.Group widths={2}>
                <Form.Field required>
                  <div className={`field normal-looking-disabled-input`}>
                    <label>{t('Instructor')}</label>
                    <Input disabled value={this.getInstructorName()}></Input>
                  </div>
                </Form.Field>
                <Form.Field required>
                  <div className={`required field`}>
                    <label>{t('Event Type')}</label>
                    <EventTypeEditor
                      nullable
                      value={eventType}
                      onChange={c => {
                        this.setState({ eventType: c });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form.Group>

              <Form.Group widths={2}>
                <Form.Field required>
                  <div className={`field`}>
                    <label>{t('Role')}</label>
                    <ProfesionEditor
                      nullable
                      value={role}
                      onChange={c => {
                        this.setState({ role: c });
                      }}
                    />
                  </div>
                </Form.Field>
                <Form.Field required>
                  <div className={` field`}>
                    <label>{t('Training Level')}</label>
                    <TrainingLevelEditor
                      nullable
                      value={level}
                      onChange={c => {
                        this.setState({ level: c });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form.Group>

              <Form.Group width={2}>
                <Form.Field>
                  <label>{t('Is machine related')}</label>
                  <Button.Group className="filter-button-group green" style={{ width: 120 }}>
                    <Button
                      className={isMachineRelated === true ? `active gray-border` : `gray-border`}
                      onClick={() => this.isMachineRelated(true)}
                    >
                      {t('Yes')}
                    </Button>
                    <Button
                      className={isMachineRelated === false ? `active gray-border` : `gray-border`}
                      onClick={() => this.isMachineRelated(false)}
                    >
                      {t('No')}
                    </Button>
                  </Button.Group>
                </Form.Field>
              </Form.Group>
              {isMachineRelated && (
                <>
                  <Form.Group widths={5}>
                    <Form.Field>
                      <label>{t('Cluster')}</label>
                      <div className={`field inline`}>
                        <MRClusterEditor
                          nullable
                          clearable
                          value={cluster}
                          onChange={c => this.setState({ cluster: c, equipmentType: null, oem: null, machineModel: null, plcTypes: null })}
                          placeholder={t('Cluster')}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('Equipment Type')}</label>
                      <div className={`field inline`}>
                        <EquipmentTypeEditor
                          nullable
                          clearable
                          clusterId={cluster ? cluster.id : undefined}
                          value={equipmentType}
                          onChange={c => this.setState({ equipmentType: c, oem: null, machineModel: null, plcTypes: null })}
                          placeholder={t('Equipment Type')}
                          readOnly={!cluster?.id}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('OEM')}</label>
                      <div className={`field inline`}>
                        <OemEditor
                          nullable
                          clearable
                          value={oem}
                          onChange={c => {
                            this.setState({ oem: c, machineModel: null, plcTypes: null });
                          }}
                          equipmentId={equipmentType != null ? equipmentType.id : undefined}
                          placeholder={t('OEM')}
                          readonly={!equipmentType?.id}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('Machine Model')}</label>
                      <div className={`field inline`}>
                        <MachineMOdelEditor
                          cascade="true"
                          nullable
                          clearable
                          value={machineModel}
                          equipmentId={equipmentType ? equipmentType.id : undefined}
                          oemId={oem ? oem.id : undefined}
                          onChange={c => {
                            this.setState({ machineModel: c });
                          }}
                          placeholder={t('Machine Model')}
                          readonly={!oem?.id}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('PLC Types')}</label>
                      <div className={`field`}>
                        <MultiPlcTypeEditor
                          className="multi-plc__dropdown"
                          placeholder=" "
                          value={plcTypes}
                          oemId={oem?.id}
                          multiple
                          equipmentId={equipmentType?.id}
                          onChange={c => this.setState({ plcTypes: c })}
                          readOnly={!(oem?.id && equipmentType?.id)}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                </>
              )}

              {!isMachineRelated && (
                <>
                  <Form.Group widths={4}>
                    <Form.Field>
                      <label>{t('Cluster')}</label>
                      <div className={`field inline`}>
                        <ClusterEditor
                          nullable
                          value={nmrcluster}
                          onChange={c =>
                            this.setState({ nmrcluster: c, nmrfunctionalarea: null, nrmTrainingName: null, functionalSubArea: null })
                          }
                          reloadOnChange
                          placeholder={t('Cluster')}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('Functional Area')}</label>
                      <div className={`field inline`}>
                        <FunctionalAreaEditor
                          nullable
                          clusterId={nmrcluster?.id || null}
                          value={nmrfunctionalarea}
                          onChange={c => this.setState({ nmrfunctionalarea: c, nrmTrainingName: null, functionalSubArea: null })}
                          clearable
                          clearOnReload
                          placeholder={t('Functional Area')}
                          readonly={!nmrcluster?.id}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('Training Name')}</label>
                      <div className={`field inline`}>
                        <TrainingNameEditor
                          nullable
                          funcionalAreaId={nmrfunctionalarea?.id || null}
                          value={nrmTrainingName}
                          onChange={c => this.setState({ nrmTrainingName: c, functionalSubArea: null })}
                          clearOnReload
                          clearable
                          readOnly={!nmrfunctionalarea?.id}
                          placeholder={t('Training Name')}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('Functional Subarea')}</label>
                      <div className={`field required inline`}>
                        <FunctionalSubAreaEditor
                          nullable
                          trainingNameId={nrmTrainingName?.id || null}
                          value={functionalSubArea}
                          onChange={c => this.setState({ functionalSubArea: c })}
                          clearable
                          reloadOnChange
                          clearOnReload
                          placeholder={t('Functional Subarea')}
                          readonly={!nrmTrainingName?.id}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                </>
              )}
            </Form>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Create')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewInstructorExpertiseRequestView);
