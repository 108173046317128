import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NewSsaDevelopmentPlanStore } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Message, Loader, Dimmer, TextArea } from 'semantic-ui-react';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { ItemReference } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import YearEditor from 'widgets/bussiness/year-editor';
import UserProfileList, { UserProfileListViewModel } from './user-profile-list';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import './new-development-plans.modal.less';
import TestCategorySelector, { CategoryItemReference } from 'widgets/bussiness/selectors/test-category-selector';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';
import SsaActivityTypeEditor from 'widgets/bussiness/ssa-activity-type-editor';

export interface NewDevelopmentPlansModalProps extends WithTranslation {
  newSsaDevelopmentPlanStore?: NewSsaDevelopmentPlanStore;
}

interface NewDevelopmentPlansModalState {
  open: boolean;
  openChooseEmployeesModal: boolean;

  profile: ProfileItemDto;
  employees: UserProfileListViewModel[];
  category: CategoryItemReference;
  skill: ItemReference;
  activity: ItemReference;
  activityDetails: string;
  year?: number;
}

@connect(['newSsaDevelopmentPlanStore', NewSsaDevelopmentPlanStore])
class NewDevelopmentPlansModal extends Component<NewDevelopmentPlansModalProps, NewDevelopmentPlansModalState> {
  state: NewDevelopmentPlansModalState = {
    open: false,
    openChooseEmployeesModal: false,
    profile: null,
    employees: [],
    category: null,
    skill: null,
    activity: null,
    activityDetails: null,
    year: null
  };

  private handleOnValueChanged = (property: string, value: any) => {
    let newState = { ...this.state };
    newState[property] = value;

    this.setState(newState);
  };

  private handleOnProfileChanged = (profile: ProfileItemDto) => {
    const currentProfile = this.state.profile;

    if (!currentProfile || !profile || profile.id !== currentProfile.id) this.setState({ profile: profile, employees: [] });
  };

  private handleOnClose = () => {
    this.setState({
      open: false,
      profile: null,
      employees: [],
      category: null,
      skill: null,
      activity: null,
      activityDetails: null,
      year: null
    });
    this.props.newSsaDevelopmentPlanStore.reset();
  };

  private handleOnAccept = () => {
    const { profile, employees, skill, activity, activityDetails, year } = this.state;

    this.props.newSsaDevelopmentPlanStore.createNew({
      profileItemId: profile && profile.id,
      usersId: employees.map(x => x.id),
      skillId: skill && skill.id,
      ssaActivityId: activity && activity.id,
      activityDetails: activityDetails,
      deadlineYear: year
    });

    this.props.newSsaDevelopmentPlanStore.submit().then(x => {
      if (x.isSuccess)
        this.setState({
          open: false,
          profile: null,
          employees: [],
          category: null,
          skill: null,
          activity: null,
          activityDetails: null,
          year: null
        });
    });
  };

  private handleOnAddEmployees = (newEmployees: UserProfilesDto[]) => {
    const employeeListViewModels: UserProfileListViewModel[] = (newEmployees || []).map(x => ({
      id: x.userId,
      employeeId: x.employeeId,
      employee: `${x.lastName}, ${x.firstName}`,
      sfPosition: x.sfPosition,
      location: `${x.locationName} - ${x.locationCountryName}`,
      profile: this.state.profile && this.state.profile.name
    }));

    this.setState({ employees: [...this.state.employees, ...employeeListViewModels] });
  };

  private handleOnCloseEmployeesModal = () => {
    this.setState({ openChooseEmployeesModal: false });
  };

  private handleOnSelectEmployeesClicked = () => {
    this.setState({ openChooseEmployeesModal: true });
  };

  private handleOnChangeEmployees = (newEmployees: UserProfileListViewModel[]) => {
    this.setState({ employees: [...newEmployees] });
  };

  render() {
    const { t, newSsaDevelopmentPlanStore: store } = this.props;
    const { open, openChooseEmployeesModal, profile, employees, category, skill, activity, activityDetails, year } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <>
        <Button className="new-entry-btn inverted-color-btn" icon onClick={() => this.setState({ open: true })}>
          {t('Add New Entry')}
        </Button>
        <Modal
          style={{ transform: 'translateY(5%)' }}
          open={open}
          className="ssa-new-development-plan-modal"
          closeOnEscape={true}
          onClose={this.handleOnClose}
          centered={false}
        >
          <Dimmer active={store.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate />
          </Dimmer>
          <Modal.Header className="ssa-new-development-plan-modal__header">{t('New Entry in SSA Development Plan')}</Modal.Header>
          <Modal.Content className="ssa-new-development-plan-modal__content">
            {messages.length > 0 && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={messages.map(o => o.body)}
                onDismiss={() => this.props.newSsaDevelopmentPlanStore.clearMessages()}
              />
            )}
            <div className="ssa-new-development-plan-modal__field">
              <label>{t('Select Profile')}</label>
              <ProfileEditor
                className="custom-editor"
                placeholder={t('Profile')}
                assessment="SSA"
                nullable
                value={profile && profile.id}
                onChange={value => this.handleOnProfileChanged(value)}
              />
            </div>
            <div className="ssa-new-development-plan-modal__field">
              <label>{t('Select Employees')}</label>
              <Button
                basic
                className="employee-select-btn"
                icon="user plus"
                disabled={!profile || !profile.id}
                onClick={this.handleOnSelectEmployeesClicked}
              ></Button>
              {openChooseEmployeesModal && (
                <ChooseEmployeesView
                  showProfileInColumn
                  profileId={profile && profile.id}
                  alreadySelectedUserIds={employees.map(x => x.id)}
                  onAddEmployees={this.handleOnAddEmployees}
                  onCloseUsersModal={this.handleOnCloseEmployeesModal}
                />
              )}
            </div>
            {employees.length > 0 && <UserProfileList userProfiles={employees} canRemove onChange={this.handleOnChangeEmployees} />}
            <div className="ssa-new-development-plan-modal__field">
              <label>{t('Select SSA Skill')}</label>
              <div className="flex-column">
                <label>{t('Category')}</label>
                <TestCategorySelector
                  clearable
                  nullable
                  placeholder={t('SSA Skill Category')}
                  isTnaCategory={false}
                  value={category}
                  onChange={value => this.handleOnValueChanged(nameof<NewDevelopmentPlansModalState>('category'), value)}
                />
              </div>
              <div className="flex-column">
                <label>{t('SSA Skill')}</label>
                <SkillsEditor
                  clearable
                  nullable
                  readOnly={!category || !category.id}
                  categoryId={category && category.id}
                  placeholder={t('SSA Skill')}
                  skillType={SkillType.SSA}
                  value={skill}
                  onChange={value => this.handleOnValueChanged(nameof<NewDevelopmentPlansModalState>('skill'), value)}
                />
              </div>
            </div>
            <div className="ssa-new-development-plan-modal__field flex-start">
              <label>{t('Select Activity')}</label>
              <SsaActivityTypeEditor
                clearable
                nullable
                className="custom-editor"
                placeholder={t('Activity')}
                value={activity}
                onChange={value => this.handleOnValueChanged(nameof<NewDevelopmentPlansModalState>('activity'), value)}
              />
              <TextArea
                className="ssa-development-plan-modal__textarea"
                placeholder={t('Details about the activity')}
                value={activityDetails}
                onChange={(_, { value }) =>
                  this.handleOnValueChanged(nameof<NewDevelopmentPlansModalState>('activityDetails'), value as string)
                }
              />
            </div>
            <div className="ssa-new-development-plan-modal__field">
              <label>{t('Select Deadline')}</label>
              <YearEditor
                className="custom-editor year-editor"
                placeholder={t('Year')}
                value={year}
                clearable
                onChange={value => this.handleOnValueChanged(nameof<NewDevelopmentPlansModalState>('year'), value)}
              />
            </div>
          </Modal.Content>
          <Modal.Actions className="ssa-new-development-plan-modal__actions">
            <Button className="basic" onClick={this.handleOnClose}>
              {t('Cancel')}
            </Button>
            <Button className="positive" onClick={this.handleOnAccept}>
              {t('Accept')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(NewDevelopmentPlansModal);
