import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { DropdownProps, SelectionInput, SelectionItem } from '../../widgets/form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { customEqualCompare, isNullOrWhiteSpaces } from '../../utils/useful-functions';
import { ClusterDto, DropDownClustersStore } from 'stores/configuration/machinery/clusters-store';

interface ClusterEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference | string;
  onChange?: (value: ItemReference) => void;
  placeholder?: string;
  clusters?: DropDownClustersStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  clearable?: boolean;
  className?: string;
  readOnly?: boolean;
  loadDataOnOpen?: boolean;
  oemId?: string;
  relatedClusterIds?: string[];
}

interface ClusterEditorState {
  value: ItemReference | string;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
  isAlreadyQuerying: boolean;
  selectedOemId?: string;
  currentQuery?: QueryResult<ClusterDto>;
}

@connect(['clusters', DropDownClustersStore])
class MRClusterEditor extends React.Component<ClusterEditorProps, ClusterEditorState> {
  private get clustersStore() {
    return this.props.clusters;
  }

  constructor(props: ClusterEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      searchQuery: null,
      query: this.props.loadDataOnOpen ? null : this.getClusterMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value),
      isAlreadyQuerying: false,
      selectedOemId: null,
      currentQuery: null
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange, relatedClusterIds } = this.props;

    if (reloadOnChange && !customEqualCompare(prevProps.value, value)) {
      this.setState({
        value,
        searchQuery: null,
        options: getOptionsFromValue(this.state?.query, value)
      });
    }

    if (!customEqualCompare(prevProps.relatedClusterIds, relatedClusterIds)) {
      this.setState({
        value,
        searchQuery: null,
        query: this.getClusterMethod(),
        options: getOptionsFromValue(this.state?.query, value)
      });
    }
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getClusterMethod() });
    }
  };

  @autobind
  private getClusterMethod() {
    const method = async (search: string) => {
      if (this.state.isAlreadyQuerying) return;
      this.setState({ isAlreadyQuerying: true });

      const filters = isNullOrWhiteSpaces(search) ? [] : [`contains(tolower(${nameof<ClusterDto>('name')}), '${search.toLowerCase()}')`];

      if (this.props.oemId && (this.props.relatedClusterIds || []).length > 0) {
        filters.push(`${nameof<ClusterDto>('id')} in (${(this.props.relatedClusterIds || []).join(', ')})`);
        this.setState({ selectedOemId: this.props.oemId });
      } else {
        this.setState({ selectedOemId: undefined });
      }

      const result = await this.clustersStore.getAllClustersAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<ClusterDto>('name'), useProfile: false }],
        filter: filters
      });
      this.setState({ currentQuery: result, isAlreadyQuerying: false });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        searchable
        readOnly={this.props.readOnly}
        className={this.props.className}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        searchQuery={this.state.searchQuery}
        onQueryChange={q => this.setState({ searchQuery: q })}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MRClusterEditor);
