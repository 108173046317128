import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import SchedulerPoCConfig from '../../scheduler-poc-config/scheduler-poc-config';
import './scheduler-buttons-component.less';
import CreateEventButton from './create-event-button';
import CreateRequestButton from './create-request-button';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { PublicHolidayConf } from '../../scheduler-poc-config/config-tabs/public-holidays-tab';
import { CreateWorkingDaysDto } from 'stores/working-days/working-days-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import i18n from 'i18n';
import { connect } from 'redux-scaffolding-ts';
import { EventsSchedulerStore, EventsStore } from 'stores/events/events-store';

export interface SchedulerButtonComponentProps extends WithTranslation {
  handleOnHideFilters?: () => void;
  handleOnFilterByUpdateFlag?: (value: boolean) => void;
  handleOnCreatedEvent?: (isSucess: boolean) => void;
  handleOnCreatedRequest?: (isSucess: boolean) => void;
  handleAlertFilter?: (value: boolean) => void;
  onReloadWDPH: (data?: PublicHolidayConf | CreateWorkingDaysDto) => void;
  eventsSchedule?: EventsSchedulerStore;
  events?: EventsStore;
}
export interface SchedulerButtonComponentState {
  hideFilters: boolean;
  showPoCConfig: boolean;
  filterByUpdateFlag: boolean;
  alertFilter: boolean;
}

@connect(['events', EventsStore], ['eventsSchedule', EventsSchedulerStore])
class SchedulerButtonsComponent extends React.Component<SchedulerButtonComponentProps, SchedulerButtonComponentState> {
  @resolve(IdentityService)
  private identityService: IdentityService;
  state = {
    hideFilters: false,
    filterByUpdateFlag: false,
    showPoCConfig: false,
    alertFilter: false
  };

  handleOnHideFilters = () => {
    this.setState({ hideFilters: !this.state.hideFilters });
    this.props.handleOnHideFilters && this.props.handleOnHideFilters();
  };

  handleOnCreatedEvent = (isSucess: boolean) => {
    this.props.handleOnCreatedEvent && this.props.handleOnCreatedEvent(isSucess);
  };

  handleOnCreatedRequest = (isSucess: boolean) => {
    this.props.handleOnCreatedRequest && this.props.handleOnCreatedRequest(isSucess);
  };

  handleOnFilterByUpdateFlag = () => {
    let value = !this.state.filterByUpdateFlag;
    this.setState({ filterByUpdateFlag: value });
    this.props.handleOnFilterByUpdateFlag && this.props.handleOnFilterByUpdateFlag(value);
  };

  handleAlertFilter = () => {
    let value = !this.state.alertFilter;
    this.setState({ alertFilter: value });
    this.props.handleAlertFilter && this.props.handleAlertFilter(value);
  };

  onShowPoCConfig = (data: PublicHolidayConf | CreateWorkingDaysDto = null) => {
    this.state.showPoCConfig && this.props.onReloadWDPH(data);
    this.setState(prevState => ({ showPoCConfig: !prevState.showPoCConfig }));
  };

  private CanSeeCreateRequestButton = (): boolean => {
    return (
      this.identityService.isInRole(Roles.Planner) ||
      this.identityService.isInRole(Roles.PlannerMTC) ||
      this.identityService.isInRole(Roles.PoC) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.FactoryLead) ||
      this.identityService.isInRole(Roles.GlobalEngineering) ||
      this.identityService.isInRole(Roles.GlobalManufacturing) ||
      this.identityService.isInRole(Roles.RegionalManufacturingVP)
    );
  };

  private CanSeeCreateEventButton = (): boolean => {
    return (
      !this.identityService.isInRole(Roles.Employee) &&
      !this.identityService.isInRole(Roles.Reporting) &&
      !this.identityService.isInRole(Roles.GlobalEngineering) &&
      !this.identityService.isInRole(Roles.GlobalManufacturing) &&
      !this.identityService.isInRole(Roles.RegionalManufacturingVP)
    );
  };

  async exportElements() {
    this.props.events
      .downloadAll('?requestedFromScheduler=true&$filter=eventInstructors/any()')
      .then(res => {
        ToastComponent({ text: i18n.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        ToastComponent({ text: i18n.t('File download failed'), type: 'error-toast' });
      });
  }

  render() {
    const t = this.props.t;
    let poCConfigModal = null;
    poCConfigModal = this.state.showPoCConfig && <SchedulerPoCConfig onClose={this.onShowPoCConfig} />;
    return (
      <div className="sch-instructors__header-right-buttons">
        <Popup
          content={t('Close additional filters')}
          trigger={
            <button onClick={this.handleOnHideFilters} className="event-type-hide-filters-btn">
              <Icon.Group>
                <Icon name="filter" />
                {!this.state.hideFilters && <Icon size="small" corner className="hide-filter" name="remove" />}
              </Icon.Group>
            </button>
          }
        />

        {!this.identityService.isInRole(Roles.Instructor) && (
          <Popup
            content={t('Events with Changes')}
            trigger={
              <button
                onClick={this.handleOnFilterByUpdateFlag}
                className={`event-type-filter-by-update-flag-btn ${this.state.filterByUpdateFlag ? 'active' : ''}`}
              >
                <Icon.Group>
                  <Icon name="circle" />
                </Icon.Group>
              </button>
            }
          />
        )}

        {(this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)) && (
          <Popup
            content={t('Events with Warnings')}
            trigger={
              <button onClick={this.handleAlertFilter} className={`event-type-filter-icon-btn ${this.state.alertFilter ? ' active' : ''}`}>
                <Icon.Group>
                  <Icon name="exclamation triangle" />
                </Icon.Group>
              </button>
            }
          />
        )}

        {this.CanSeeCreateEventButton() && <CreateEventButton onCreatedEvent={this.handleOnCreatedEvent} />}
        {this.CanSeeCreateRequestButton() && <CreateRequestButton onCreateRequest={this.handleOnCreatedRequest} />}

        {poCConfigModal}
        {this.identityService.isInRole(Roles.PoC) && (
          <Popup
            content={t('Calendar configuration')}
            position="top right"
            trigger={
              <button onClick={() => this.onShowPoCConfig()} className="event-type-filter-icon-btn">
                <Icon.Group>
                  <Icon className="calendar-gears" name="cogs" />
                </Icon.Group>
              </button>
            }
          />
        )}
        {IdentityService.isPowerInstructor(this.identityService.getUserInfo()) && (
          <Popup
            trigger={
              <Button icon size="medium" onClick={() => this.exportElements()} className="custom-table-upload-btn black-btn">
                <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
              </Button>
            }
            content={t('Export')}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(SchedulerButtonsComponent);
