import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { ItemReference, Query, QueryParameters } from 'stores/dataStore';
import { TnaDevelopmentPlanStore, TnaDevelopmentPlanDto } from 'stores/development-plan/tna-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import i18n from 'i18n';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import { nameof } from 'utils/object';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import YearEditor from 'widgets/bussiness/year-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import ChipInfo from 'widgets/bussiness/chip-info';
import DevelopmentPlanStatus from 'site/pages/development-plan/shared/development-plan-status';

interface TnaTabPaneFilters {
  eventType: ItemReference;
  year?: number;
  trainingLevel: ItemReference;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
}

interface TnaTabPaneState {
  query: Query;
  filters: TnaTabPaneFilters;
}

export interface TnaTabPaneProps extends WithTranslation {
  tnaDevelopmentPlanStore?: TnaDevelopmentPlanStore;
}

@connect(['tnaDevelopmentPlanStore', TnaDevelopmentPlanStore])
class TnaTabPane extends Component<TnaTabPaneProps, TnaTabPaneState> {
  state: TnaTabPaneState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10,
      parameters: {}
    },
    filters: {
      eventType: null,
      year: null,
      trainingLevel: null,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null
    }
  };

  componentDidMount() {
    this.load();
  }

  private columns: TableColumn<TnaDevelopmentPlanDto>[] = [
    {
      title: i18n.t('Profile'),
      tooltipRenderer: true,
      renderer: data => data.profileName
    },
    {
      title: i18n.t('Machine Model'),
      renderer: data =>
        data.machineModel && (
          <ChipInfo info={[data.machineModel.clusterName, data.machineModel.equipmentTypeName, data.machineModel.name]} />
        )
    },
    {
      title: i18n.t('Level'),
      tooltipRenderer: true,
      renderer: data => data.trainingLevelName
    },
    {
      title: i18n.t('Activity'),
      tooltipRenderer: true,
      renderer: data => data.eventType && data.eventType.name
    },
    {
      title: i18n.t('Year'),
      tooltipRenderer: true,
      renderer: data => data.deadlineYear
    },
    {
      title: i18n.t('Status'),
      tooltipRenderer: true,
      renderer: data => <DevelopmentPlanStatus outOfDate={data.outOfDate} status={data.status} />
    }
  ];

  private handleFilterChange = (property: string, value: any) => {
    const filters = { ...this.state.filters };
    filters[property] = value;

    if (property === nameof<TnaTabPaneFilters>('cluster')) {
      filters.equipmentType = undefined;
      filters.oem = undefined;
      filters.machineModel = undefined;
    }

    if (property === nameof<TnaTabPaneFilters>('equipmentType')) {
      filters.oem = undefined;
      filters.machineModel = undefined;
    }

    if (property === nameof<TnaTabPaneFilters>('oem')) {
      filters.machineModel = undefined;
    }

    this.setState({ filters: filters }, this.load);
  };

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.tnaDevelopmentPlanStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: TnaTabPaneFilters) => {
    const query: Query = Object.assign(this.state.query);
    const queryParameters: QueryParameters = {};
    const oDataFilter = [];

    if (filters.eventType && filters.eventType.id) oDataFilter.push({ EventTypeId: { eq: { type: 'guid', value: filters.eventType.id } } });
    if (filters.trainingLevel && filters.trainingLevel.id)
      oDataFilter.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.trainingLevel.id } } });
    if (!!filters.year) oDataFilter.push({ DeadlineYear: { eq: filters.year } });

    if (filters.cluster && filters.cluster.id) queryParameters['ClusterId'] = filters.cluster.id;
    if (filters.equipmentType && filters.equipmentType.id) queryParameters['EquipmentTypeId'] = filters.equipmentType.id;
    if (filters.oem && filters.oem.id) queryParameters['OemId'] = filters.oem.id;
    if (filters.machineModel && filters.machineModel.id) queryParameters['MachineModelId'] = filters.machineModel.id;

    query.filter = oDataFilter;
    query.parameters = queryParameters;

    return query;
  };

  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  render() {
    const { t, tnaDevelopmentPlanStore: store } = this.props;
    const { filters } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.tnaDevelopmentPlanStore.state
    } as TableModel<TnaDevelopmentPlanDto>;

    return (
      <div className="development-plan-by-location__wrapper">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body)}
            onDismiss={() => this.props.tnaDevelopmentPlanStore.clearMessages()}
          />
        )}

        <div className="development-plan-by-location__filters">
          <EventTypeEditor
            clearable
            className="custom-editor"
            placeholder={t('Activity')}
            nullable
            useOriginalEventTypeIdAsValue
            value={filters.eventType}
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('eventType'), value)}
          />
          <YearEditor
            clearable
            placeholder={t('Year')}
            value={filters.year}
            className="custom-editor year-editor"
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('year'), value)}
          />
          <TrainingLevelEditor
            clearable
            placeholder={t('Training Level')}
            value={filters.trainingLevel}
            nullable
            className="custom-editor"
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('trainingLevel'), value)}
          />
          <MRClusterEditor
            className="custom-editor"
            clearable
            nullable
            placeholder={t('Cluster')}
            value={filters.cluster}
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('cluster'), value)}
          />
          <EquipmentTypeEditor
            className="custom-editor"
            clearable
            nullable
            placeholder={t('Equipment Type')}
            readOnly={!filters.cluster || !filters.cluster.id}
            clusterId={filters.cluster ? filters.cluster.id : null}
            value={filters.equipmentType}
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('equipmentType'), value)}
          />
          <OemEditor
            className="custom-editor"
            nullable
            clearable
            placeholder={t('OEM')}
            readonly={!filters.cluster || !filters.cluster.id || !filters.equipmentType || !filters.equipmentType.id}
            equipmentId={filters.equipmentType ? filters.equipmentType.id : null}
            value={filters.oem}
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('oem'), value)}
          />
          <MachineModelEditor
            className="custom-editor"
            nullable
            clearable
            placeholder={t('Machine Model')}
            value={filters.machineModel}
            readonly={
              !filters.equipmentType ||
              !filters.equipmentType.id ||
              !filters.oem ||
              !filters.oem.id ||
              !filters.cluster ||
              !filters.cluster.id
            }
            equipmentId={filters.equipmentType ? filters.equipmentType.id : null}
            oemId={filters.oem ? filters.oem.id : null}
            clusterId={filters.cluster ? filters.cluster.id : null}
            onChange={value => this.handleFilterChange(nameof<TnaTabPaneFilters>('machineModel'), value)}
          />
        </div>
        <TableView model={tableModel} onRefresh={this.load} onPageChange={this.handlePageChange}></TableView>
      </div>
    );
  }
}

export default withTranslation()(TnaTabPane);
