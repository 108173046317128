import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import { ChangePlcTypeDto, ChangePlcTypeStore, PlcTypeDto } from '../../../../../stores/configuration/machinery/plc-types-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import EquipmentTypeEditor from '../../../../../widgets/bussiness/equipment-type-editor';
import OemEditor from '../../../../../widgets/bussiness/oem-editor';
import ClusterEditor from 'widgets/bussiness/mrcluster-editor';

interface ChangePlcTypeViewProps extends WithTranslation {
  onClose: () => void;
  changePlcType?: ChangePlcTypeStore;
  currentPLC: PlcTypeDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangePlcTypeViewState {
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changePlcType', ChangePlcTypeStore])
class ChangePlcTypeView extends React.Component<ChangePlcTypeViewProps, ChangePlcTypeViewState> {
  private get changePlcTypeStore() {
    return this.props.changePlcType;
  }

  constructor(props: ChangePlcTypeViewProps) {
    super(props);

    this.state = {
      cluster: { id: this.props.currentPLC.clusterId, title: this.props.currentPLC.clusterName },
      equipmentType: { id: this.props.currentPLC.equipmentTypeId, title: this.props.currentPLC.equipmentTypeName },
      oem: { id: this.props.currentPLC.oemId, title: this.props.currentPLC.oemName },
      userClickedSaved: false
    };

    this.changePlcTypeStore.state.result = null;

    this.changePlcTypeStore.change({
      id: this.props.currentPLC.id,
      name: this.props.currentPLC.name,
      equipmentTypeId: this.props.currentPLC.equipmentTypeId,
      oemId: this.props.currentPLC.oemId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangePlcTypeViewProps) {
    if (this.changePlcTypeStore.state.result && this.changePlcTypeStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changePlcTypeStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changePlcTypeStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changePlcTypeStore.change({ ...this.changePlcTypeStore.state.item, ...change });
  }

  private onChangeCluster = (cluster: ItemReference) => {
    this.changePlcTypeStore.change({ ...this.changePlcTypeStore.state.item, ...{ equipmentTypeId: null } });
    this.setState({ cluster, equipmentType: null, userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changePlcTypeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit PLC Type')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changePlcTypeStore.state.result && !this.changePlcTypeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changePlcTypeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changePlcTypeStore.state.item && (
              <Form>
                <Form.Field error={!this.changePlcTypeStore.state.item.name && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('PLC Type')}
                    placeholder={t('Name')}
                    value={this.changePlcTypeStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangePlcTypeDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Group widths={'equal'}>
                  <Form.Field required error={!(this.state.cluster && this.state.cluster.id) && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <ClusterEditor placeholder={t('Cluster')} nullable value={this.state.cluster} onChange={this.onChangeCluster} />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changePlcTypeStore.state.item.equipmentTypeId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        readOnly={!(this.state.cluster && this.state.cluster.id)}
                        clusterId={this.state.cluster && this.state.cluster.id ? this.state.cluster.id : undefined}
                        nullable
                        value={this.state.equipmentType}
                        onChange={c => {
                          this.changePlcTypeStore.state.item.equipmentTypeId = c ? c.id : null;
                          this.setState({ equipmentType: c, userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changePlcTypeStore.state.item.oemId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('OEM')}</label>
                      <OemEditor
                        nullable
                        value={this.state.oem}
                        onChange={c => {
                          this.changePlcTypeStore.state.item.oemId = c ? c.id : null;
                          this.setState({ oem: c, userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangePlcTypeView);
