import React from 'react';

import { container } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FullPageLoader } from '../full-page-loader';

export const Callback = () => {
  container.get(IdentityService).signinRedirectCallback();
  return <FullPageLoader text={'Sign in redirect'} />;
};
