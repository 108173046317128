import React, { Component, RefObject } from 'react';
import BryntumScheduler from 'widgets/scheduler/BryntumScheduler';
import { WidgetHelper } from '@planit/bryntum-scheduler';
import Drag from '../util/Drag';
import { UserStore, UserDto } from 'stores/users/users-store';
import { EventsSchedulerStore, EventDto, EventInstructorDto } from 'stores/events/events-store';
import { connect } from 'redux-scaffolding-ts';
import schedulerConfig from './scheduler-instructors-config';
import SchedulerRequestList from '../shared-scheduler-components/request-component/scheduler-request-list';
import { InvisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/invisible-filters';
import { AlwaysVisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/always-visible-filters';
import { SchedulerEventFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-event-filter-service';
import { resolve } from 'inversify.config';
import { FilterValues } from '../shared-scheduler-components/scheduler-header/scheduler-header.component';
import { DatePeriod, SchedulerPeriodService } from '../shared-scheduler-components/events/scheduler-period-service';
import { InstructorRowFilterValues } from './instructor-row-filter/instructor-row-filter';
import { InstructorRowFilterService } from './instructor-row-filter/instructor-row-filter-service';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PublicHolidayConfigurationStore, PublicHolidayDto } from 'stores/public-holidays/public-holidays-store';
import { WorkingDaysStore, WorkingDayDto } from 'stores/working-days/working-days-store';
import { getAllLocationPublicHolidays } from '../shared-scheduler-components/scheduler-methods';
import { RequestDto } from 'stores/requests/requests-store';
import { OrderDefinition, Query, QueryResult } from 'stores/dataStore';
import { Roles, IdentityService } from 'services/identity-service';
import { DateTimeService } from 'services/datetime-service';
import { isCurrentUserEventOwner } from 'utils/event-utils';
import { Dimmer, Loader } from 'semantic-ui-react';
import { SchedulerResourceFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-resource-filter-service';
import './scheduler-instructor-style.less';

//Based on src/site/pages/landing-pages/scheduler-instructor-page/scheduler-instructor-page.tsx

function getMinStartDate(suggestedEvents: EventDto[]): string {
  const dates = suggestedEvents.map(x => new Date(x.startDate));
  const minDate = dates.reduce((min, current) => (current < min ? current : min));

  return minDate.toISOString();
}

function getMaxEndDate(suggestedEvents: EventDto[]): string {
  const dates = suggestedEvents.map(x => new Date(x.endDate));
  const maxDate = dates.reduce((max, current) => (current > max ? current : max), new Date(0));

  return maxDate.toISOString();
}

interface EventPerInstructor {
  event: EventDto;
  instructor: EventInstructorDto;
}

export interface SchedulerInstructorPageProps extends WithTranslation {
  users?: UserStore;
  events?: EventsSchedulerStore;
  publicHolidayConfiguration?: PublicHolidayConfigurationStore;
  workingDays?: WorkingDaysStore;
  suggestedEvents: EventDto[];
}
export interface SchedulerInstructorPageState {
  barMargin: number;
  selectedEvent: EventDto;
  eventsVersion: number;
  resourcesVersion: number;
  events: any;
  resources: any;
  filterValues: FilterValues;
  showRequestColumn: boolean;
  resourceTimeRanges: any;
  requestsRef: RefObject<any>;
  filtersDisabled: boolean;
  showAdditionalFilters: boolean;
  requests: RequestDto[];
  currentRequestsQuery: Query;
  hasMoreRequest: boolean;
  eventPreview: EventDto;
  mainRequest: RequestDto;
  mergeRequestIds: string[];
  maskRequestTable: any;
  locations?: any;
  eventEdition: boolean;
  loading: boolean;
  instructorRowFilterValues: InstructorRowFilterValues;
  instructors: QueryResult<UserDto>;
}

@connect(
  ['users', UserStore],
  ['events', EventsSchedulerStore],
  ['publicHolidayConfiguration', PublicHolidayConfigurationStore],
  ['workingDays', WorkingDaysStore]
)
class SchedulerAllPlannerAssistantSuggestionPage extends Component<SchedulerInstructorPageProps, SchedulerInstructorPageState> {
  @resolve(SchedulerEventFilterService)
  private schedulerEventFilterService: SchedulerEventFilterService;

  @resolve(SchedulerResourceFilterService)
  private schedulerResourceFilterService: SchedulerResourceFilterService;

  @resolve(IdentityService)
  private identityService: IdentityService;

  @resolve(SchedulerPeriodService)
  private schedulerPeriodService: SchedulerPeriodService;

  @resolve(InstructorRowFilterService)
  private instructorRowFilterService: InstructorRowFilterService;

  _isMounted: boolean = false;
  dragHelper = new Drag({});
  activeRole: string;

  private minDate = getMinStartDate(this.props.suggestedEvents);
  private maxDate = getMaxEndDate(this.props.suggestedEvents);

  constructor(props) {
    super(props);
    this.activeRole = this.identityService.activeRole;

    this.state = {
      maskRequestTable: null,
      barMargin: 5,
      eventsVersion: 0,
      resourcesVersion: 0,
      events: [],
      resources: [],
      showAdditionalFilters: false,
      showRequestColumn: false,
      requests: [],
      requestsRef: null,
      filterValues: this.schedulerEventFilterService.getDefaultFilterValues(this.minDate, this.maxDate),
      resourceTimeRanges: null,
      filtersDisabled: true,
      selectedEvent: null,
      currentRequestsQuery: null,
      hasMoreRequest: true,
      eventPreview: null,
      mainRequest: null,
      mergeRequestIds: [],
      instructorRowFilterValues: this.instructorRowFilterService.getDefaultFilterValues(),
      eventEdition: null,
      instructors: null,
      loading: false
    };
  }

  myUser: UserDto = null;
  pageSize = 15;

  refs: { scheduler: BryntumScheduler };

  public get schedulerDatePeriod() {
    const period = { ...this.state.filterValues.alwaysVisibleFilterValues.period };
    return this.schedulerPeriodService.toSchedulerPeriod(period);
  }

  getEvents = (eventPeriods: DatePeriod, additionalFilters = []) => {
    const { suggestedEvents } = this.props;
    const instructorIds = suggestedEvents.selectMany(x => x.instructors.map(x => x.instructorId));

    const filters = [
      { eventInstructors: { any: [{ InstructorId: { in: { type: 'guid', value: instructorIds } } }] } },
      { startDate: { le: new Date(eventPeriods.to) } },
      { endDate: { ge: new Date(eventPeriods.from) } }
    ];

    const queryEvents = {
      searchQuery: '',
      filter: [...filters, ...additionalFilters],
      orderBy: [],
      skip: 0,
      take: 100000,
      select:
        'Id, title, EventTypeId, EventTypeItem, EventStatus, pausePeriods, plannedDuration, startDate, endDate, eventWarnings,eventUpdatedFlag, FriendlyEventId, EventInstructors, EventTrainingDetails/NumStudentsAssigned, EventTrainingDetails/DeliveryMethodId, EventDetails/ProfessionId, EventDetails/PriorityId, EventDetails/LocationId,Requests/RequestingLocationId, SupportDetails/SupportPositions'
    };
    var events = this.props.events.getAllFromSchedulerAsync(queryEvents);

    return events;
  };

  buildSchedulerEventsForInstructors(newEvents: QueryResult<EventDto>): EventDto[] {
    let newEventItems: EventDto[] = [];
    const { suggestedEvents } = this.props;

    if (suggestedEvents) {
      newEventItems.push(...suggestedEvents);
    }

    const suggestedEventIds = newEventItems.selectMany(x => x.id).toArray();

    if (newEvents && newEvents.items) {
      newEvents.items
        .filter(x => !suggestedEventIds.includes(x.id))
        .map(e => {
          newEventItems.push(e);
          return newEventItems;
        });
    }

    return newEventItems;
  }

  initScheduler = async () => {
    this._isMounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine.element, 'FETCHING DATA');
    const orderBy: OrderDefinition[] = [
      { direction: 'Ascending', field: 'enabled', useProfile: false },
      { direction: 'Ascending', field: 'surname', useProfile: false }
    ];

    let additionalInstructorsFilters = [];
    let additionalEventsFilters = [];
    additionalInstructorsFilters = this.getAdditionalInstructorsFilters();

    const queryInstructors = { searchQuery: '', orderBy, skip: 0, take: 1000, filter: additionalInstructorsFilters };
    const allQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };

    const newEventsPromise = this.getEvents(this.schedulerDatePeriod, additionalEventsFilters);
    const instructorsPromise = this.props.users.getAllUsersWithRoleAsync(queryInstructors, 'Instructor');
    const publicHolidaysPromise = this.props.publicHolidayConfiguration.getAllAsync(allQuery);
    const workingDaysPromise = this.props.workingDays.getAllAsync(allQuery);

    let promises = [newEventsPromise, instructorsPromise, publicHolidaysPromise, workingDaysPromise];

    let newEvents: QueryResult<EventDto> = { count: 0, items: [] };
    let instructors: QueryResult<UserDto> = { count: 0, items: [] };
    let publicHolidays: QueryResult<PublicHolidayDto> = { count: 0, items: [] };
    let workingDays: QueryResult<WorkingDayDto> = { count: 0, items: [] };

    let promiseResp = await Promise.allSettled(promises);

    if (promiseResp.every(x => x.status === 'fulfilled' && x.value)) {
      let results = promiseResp.map(x => (x as PromiseFulfilledResult<QueryResult<any>>).value);
      newEvents = results[0];
      instructors = results[1];
      publicHolidays = results[2];
      workingDays = results[3];
    }

    let newEventItems: EventDto[] = this.buildSchedulerEventsForInstructors(newEvents);

    this.myUser = instructors?.items?.find(({ id }) => id === this.identityService.userId);
    const resourceTimeRanges = this.mapToResourceTimeRanges(instructors.items, publicHolidays.items, workingDays.items);
    this.refs.scheduler?.setResourceTimeRanges(resourceTimeRanges);
    const eventsPerInstructor = this.mapToEventInstructor(newEventItems);
    const events = this.mapToBryntumEvents(eventsPerInstructor);
    const resources = this.mapToBryntumResources(instructors.items);

    const data = { events, resources, eventsVersion: 1, resourcesVersion: 1, instructors, resourceTimeRanges };
    this._isMounted && this.setState({ ...data, loading: false, filtersDisabled: false });
    maskScheduler?.close();
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.refs.scheduler) {
      this.refs.scheduler.rowHeightHandler(64);
      this.initScheduler();
    }
  }

  setEvents = events => {
    this._isMounted &&
      this.setState(
        ({ eventsVersion }) => ({ eventsVersion: eventsVersion + 1, events }),
        () => this.filterEvents(this.state.filterValues.alwaysVisibleFilterValues, this.state.filterValues.invisibleFilterValues)
      );
  };

  getEventsPerInstructor = (period: DatePeriod): Promise<EventPerInstructor[]> => {
    var result = this.getEvents(period, []).then(events => {
      let eventItems: EventDto[] = this.buildSchedulerEventsForInstructors(events);
      var instructors = this.mapToEventInstructor(eventItems);
      var map = this.mapToBryntumEvents(instructors);

      return map;
    });

    return result;
  };

  mapToEventInstructor = (events: EventDto[]) => {
    const eventsPerInstructor: EventPerInstructor[] = [];
    events.forEach(event => eventsPerInstructor.push(...event.instructors.map(instructor => ({ event, instructor }))));
    return eventsPerInstructor;
  };

  mapToBryntumResources = (items: UserDto[]) => (items || []).map(instructor => ({ id: instructor.id, instructor }));

  mapToResourceTimeRanges = (instructors: UserDto[], publicHolidays: PublicHolidayDto[], workingDaysArr: WorkingDayDto[]) => {
    let resourceTimeRanges = [];
    (instructors || []).forEach(({ id, roles }) => {
      const instructor = (roles || []).filter(x => x.role.name === 'Instructor').firstOrDefault();
      let locationPublicHolidays = publicHolidays.find(x => x.locationId === instructor.location.id);
      if (locationPublicHolidays) {
        resourceTimeRanges.push(...getAllLocationPublicHolidays(locationPublicHolidays, id));
      }
    });
    return resourceTimeRanges;
  };

  mapToBryntumEvents = (eventsPerInstructor: EventPerInstructor[]) =>
    (eventsPerInstructor || []).map(({ event: e, instructor }) => {
      const status = e.status.toString();
      let draggable = false;
      if (isCurrentUserEventOwner(e) && (status === 'Draft' || status === 'Planned' || status === 'InProgress')) draggable = true;
      const event: any = { ...e };
      if (instructor.travelDays?.departure?.from || instructor.travelDays?.departure?.to)
        event.travelStart = instructor.travelDays.departure;
      if (instructor.travelDays?.arrival?.from || instructor.travelDays?.arrival?.to) event.travelEnd = instructor.travelDays.arrival;
      const startDate = DateTimeService.toSchedulerFromString(instructor.period.from);
      const endDate = DateTimeService.toSchedulerToString(instructor.period.to);
      const resourceId = instructor.instructorId;

      return { event, instructor, startDate, endDate, resourceId, draggable };
    });

  onScrollEndHandler = ({ x, maxX }) => {
    const period = { ...this.state.filterValues.alwaysVisibleFilterValues.period };
    const hasMak = this.refs.scheduler?.schedulerEngine?.element?.hasOwnProperty('mask') || false;
    if (hasMak) {
      const entries = Object.entries(this.refs.scheduler?.schedulerEngine?.element);
      const plain = Object.fromEntries(entries.map(([k, v]) => [k, v]));
      const maskVal: any = plain['mask'];
      if (maskVal && maskVal._text) {
        return;
      }
    }
    const distance = 50;
    if (maxX - distance < x) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .add(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .add(1, 'months')
        .toISOString();

      this._isMounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            eventsVersion: eventsVersion + 1,
            events: [],
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    } else if (x < distance) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .subtract(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .subtract(1, 'months')
        .toISOString();

      this._isMounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            eventsVersion: eventsVersion + 1,
            events: [],
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    }
  };

  private filterEvents = (alwaysVisibleFilterValues: AlwaysVisibleFiltersValue, invisibleFilterValues: InvisibleFiltersValue) => {
    this.refs.scheduler?.eventStore?.filter?.({
      filters: eventRecord =>
        this.schedulerEventFilterService.fullfillsAllFilters(eventRecord, alwaysVisibleFilterValues, invisibleFilterValues),
      replace: true
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.dragHelper.destroy();
  };

  reloadEvents = async (newEventId?: string) => {
    this._isMounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine?.element, 'FETCHING DATA');

    const events = await this.getEventsPerInstructor(this.schedulerDatePeriod);

    if (newEventId) {
      let selectedEvent = (events || []).find(({ event }) => event?.id === newEventId)?.event;
      if (!selectedEvent) selectedEvent = await this.props.events.getById(newEventId);
      this._isMounted && this.setState({ selectedEvent });
    }

    this.setEvents(events);
    maskScheduler.close();
    this._isMounted && this.setState({ loading: false });
  };

  getAdditionalInstructorsFilters = () => {
    let filter = [];
    const { suggestedEvents } = this.props;
    const instructorIds = suggestedEvents.selectMany(x => x.instructors.map(x => x.instructorId));
    filter = [{ Id: { in: { type: 'guid', value: instructorIds } } }];

    return filter;
  };

  shouldComponentUpdate(nextState) {
    const { eventsVersion, resourcesVersion, showRequestColumn, requests, selectedEvent, eventPreview } = this.state;
    if (
      nextState.eventsVersion === eventsVersion &&
      resourcesVersion === nextState.resourceVersion &&
      selectedEvent === nextState.selectedEvent &&
      requests === nextState.requests &&
      showRequestColumn === nextState.showRequestColumn &&
      eventPreview === nextState.eventPreview
    )
      return false;

    return true;
  }

  render() {
    const { t } = this.props;
    const { resources, showRequestColumn, requests, loading, resourceTimeRanges } = this.state;
    const { showAdditionalFilters, barMargin, eventsVersion, resourcesVersion, events } = this.state;
    const containerClassName = `sch-instructors__container${showAdditionalFilters ? '' : ' grow'}`;

    let schedulerClassName = 'b-react-scheduler-container';
    if (this.identityService.isInRole(Roles.Instructor)) schedulerClassName += ' full';
    else if (!this.identityService.isInRole(Roles.Instructor) && showRequestColumn)
      document.querySelector('.b-react-scheduler-container')?.classList.remove('collapse');
    else document.querySelector('.b-react-scheduler-container')?.classList.add('collapse');

    const requestsToggleClassName = `grid-splitter b-fa b-fa-angle-${showRequestColumn ? 'right' : 'left'}`;

    var render = (
      <div className={containerClassName}>
        <Dimmer active={loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading...')}</Loader>
        </Dimmer>

        <div className={`sch-instructors__scheduler-component`} style={{ minHeight: '350px' }}>
          <BryntumScheduler
            {...schedulerConfig}
            className={schedulerClassName}
            rtr={resourceTimeRanges}
            ref={'scheduler'}
            barMargin={barMargin}
            eventsVersion={eventsVersion}
            resourcesVersion={resourcesVersion}
            events={events}
            eventRenderer={data => schedulerConfig.eventRenderer(data, this.refs.scheduler?.schedulerEngine)}
            resources={resources}
            startDate={this.schedulerDatePeriod.from}
            endDate={this.schedulerDatePeriod.to}
            onScrollEnd={this.onScrollEndHandler}
            eventDragFeature={false}
            today={this.minDate}
          />

          <>
            <div className={`request-table-container${!showRequestColumn ? ' collapse' : ''}`}>
              <SchedulerRequestList requests={requests} />
            </div>
            <i className={requestsToggleClassName}></i>
          </>
        </div>
      </div>
    );

    return render;
  }
}

export default withTranslation()(SchedulerAllPlannerAssistantSuggestionPage);
