import React, { FC, useState, useEffect, useReducer } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  Modal,
  Dimmer,
  Container,
  Loader,
  Message,
  Form,
  Dropdown,
  TextArea,
  Button,
  Icon,
  Accordion,
  TextAreaProps,
  Popup,
  List
} from 'semantic-ui-react';
import {
  TheoreticalQuestionMRItemDto,
  NmrTheoreticalQuestionItemDto,
  TheoreticalQuestionDto,
  MediaContent
} from 'stores/assessments/questionBank/theoretical-test-store';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import NMRClusterEditor from 'widgets/bussiness/cluster-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OEM from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import TrainingNameEditor from 'widgets/bussiness/training-name-editor';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';
import TheoreticalQuestionTranslation from './theoretical-question-translation';
import AnswertypeSelector from './theoretical-answertype-selector';
import TheoreticalQuestionOption from './theoretical-question-option';
import MultiMachineUnitEditor from 'widgets/bussiness/multi-machine-unit-editor';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import FunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import FunctionalSubareaEditor from 'widgets/bussiness/functional-subarea-editor';
import '../theoretical-test.style.less';
import { plainQuestion, canBeSaved } from './util';
import TheoreticalMediaInput from './theoretical-media-input';
import { ImageInfo } from 'stores/types';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { openInNewWindow } from 'utils/useful-functions';

export type ModalMode = 'New' | 'Edit' | 'Clone';
interface QuestionManagerProps extends WithTranslation {
  data?: TheoreticalQuestionMRItemDto | NmrTheoreticalQuestionItemDto;
  type: 'MR' | 'NMR';
  mode: ModalMode;
  loading: boolean;
  errMsg?: { body: string; level: string }[];
  onClose: () => void;
  onSubmit: (item: TheoreticalQuestionMRItemDto | NmrTheoreticalQuestionItemDto) => void;
  onDismiss: () => void;
  onDownloadHandler?: () => void;
  uploadFile: (file: File) => Promise<ImageInfo>;
}

interface QuestionManagerState {
  item: TheoreticalQuestionMRItemDto | NmrTheoreticalQuestionItemDto;
}

const TheoreticalQuestionManager: FC<QuestionManagerProps> = props => {
  const { type, mode, loading, errMsg, data, onDismiss, onSubmit, t, onClose, uploadFile, onDownloadHandler } = props;
  const [savePressed, setSavePressed] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [videoArea, setVideoArea] = useState(false);
  const [state, setState]: [QuestionManagerState, (newState: Partial<QuestionManagerState>) => void] = useReducer(
    (state: QuestionManagerState, newState: Partial<QuestionManagerState>) => ({ ...state, ...newState }),
    { item: data }
  );
  const { item } = state;
  const { professions, answerType, testCategoryId, trainingLevelId, imageInfo } = item;
  const { content, mediaContentType, option, question, questionTranslations } = item;

  const { clusterId, equipmentTypeId, oemId, machineModelId, machineUnitId, plcTypeId } = item as TheoreticalQuestionMRItemDto;
  const { nmrClusterId, functionalAreaId, trainingNameId, functionalSubareaId } = item as NmrTheoreticalQuestionItemDto;

  useEffect(() => {
    if (!item) return setCanSave(false);
    setCanSave(canBeSaved(item));
  }, [item]);

  const changeHandler = (
    property: keyof TheoreticalQuestionMRItemDto | keyof NmrTheoreticalQuestionItemDto,
    value: any,
    type?: 'mr' | 'nmr'
  ) => {
    setSavePressed(false);
    let machineryChanges = {};
    if (type === 'mr') machineryChanges = setMRMachinery(property as keyof TheoreticalQuestionMRItemDto);
    if (type === 'nmr') machineryChanges = setNMRMachinery(property as keyof NmrTheoreticalQuestionItemDto);
    const newChange: any = { [property as string]: value, ...machineryChanges };

    if (property === 'answerType') newChange.option = null;
    setState({ item: { ...state.item, ...newChange } });
  };

  const setMRMachinery = (property: keyof TheoreticalQuestionMRItemDto): Partial<TheoreticalQuestionMRItemDto> => {
    let changes: any = {};
    if (property === 'clusterId') {
      changes.equipmentTypeId = null;
      changes.oemId = null;
      changes.machineModelId = null;
      changes.machineUnitId = null;
      changes.plcTypeId = null;
    }
    if (property === 'equipmentTypeId') {
      changes.oemId = null;
      changes.machineModelId = null;
      changes.machineUnitId = null;
      changes.plcTypeId = null;
    }
    if (property === 'oemId') {
      changes.machineModelId = null;
      changes.machineUnitId = null;
      changes.plcTypeId = null;
    }
    if (property === 'machineModelId') {
      changes.machineUnitId = null;
      changes.plcTypeId = null;
    }
    if (property === 'machineUnitId') changes.plcTypeId = null;
    return changes;
  };
  const setNMRMachinery = (property: keyof NmrTheoreticalQuestionItemDto): Partial<NmrTheoreticalQuestionItemDto> => {
    let changes: any = {};
    if (property === 'nmrClusterId') {
      changes.functionalAreaId = null;
      changes.trainingNameId = null;
      changes.functionalSubareaId = null;
    }
    if (property === 'functionalAreaId') {
      changes.trainingNameId = null;
      changes.functionalSubareaId = null;
    }
    if (property === 'trainingNameId') changes.functionalSubareaId = null;

    return changes;
  };

  const questionHandler = (property: keyof TheoreticalQuestionDto, value: any) => {
    if (!state.item) return;
    const newQuestionItem: TheoreticalQuestionDto = { ...state.item.question, [property as string]: value };
    changeHandler('question', newQuestionItem);
  };

  const translationHandler = (property: keyof TheoreticalQuestionDto, value: any, i: number) => {
    if (!state.item) return;
    const translationChange = (questionTranslations || []).map((item, idx) =>
      idx === i ? { ...item, [property as string]: value } : item
    );
    changeHandler('questionTranslations', translationChange);
  };

  const languageHandler = (item: ItemReference, i: number) => {
    if (item == null) translationHandler('language', item, i);
    else {
      const languageItem = questionTranslations[i];
      translationHandler('language', { ...languageItem.language, id: item.id, languageId: item.id, language: item.title }, i);
    }
  };

  const onSave = () => {
    setSavePressed(true);
    onSubmit(item);
  };

  const fileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event?.target?.files[0]) file = event.target.files[0];
    else return;
    const imageInfo = await uploadFile(file);
    imageInfo != null && setState({ item: { ...item, imageInfo, content: imageInfo?.path, mediaContentType: 'Image' } });
  };

  const handleNewTranslation = () => {
    changeHandler('questionTranslations', [{ ...plainQuestion, language: {} as LanguageDto, active: true }, ...questionTranslations]);
  };

  const handleRemoveTranslation = (i: number) => {
    changeHandler(
      'questionTranslations',
      (questionTranslations || []).filter((_, idx) => i !== idx)
    );
  };
  const upload = () => {
    document.getElementById('add-theoretical-image').click();
  };

  const clearMedia = () => {
    setState({ item: { ...item, content: null, imageInfo: null, mediaContentType: 'None' } });
  };

  const videoChange = (_, { value }: TextAreaProps) => {
    const change: any = { content: value };
    if (value === '') change.mediaContentType = null;
    else change.mediaContentType = 'Video' as MediaContent;
    setState({ item: { ...item, ...change } });
  };

  const toggleVideoArea = () => {
    if (videoArea) setState({ item: { ...item, content: '', mediaContentType: 'None' } });
    else setState({ item: { ...item, mediaContentType: 'Video' } });
    setVideoArea(!videoArea);
  };

  const downloadHandler = () => {
    onDownloadHandler && onDownloadHandler();
  };

  const testCategoryValue = testCategoryId ? { id: testCategoryId, title: '' } : null;
  const functionalAreaValue = functionalAreaId ? { id: functionalAreaId, title: '' } : null;
  const functionalSubareaValue = functionalSubareaId ? { id: functionalSubareaId, title: '' } : null;
  const trainingLevelIdValue = trainingLevelId ? { id: trainingLevelId, title: '' } : null;
  const nmrClusterIdValue = nmrClusterId ? { id: nmrClusterId, title: '' } : null;
  const trainingNameIdValue = trainingNameId ? { id: trainingNameId, title: '' } : null;
  return (
    <Modal open onClose={onClose} closeOnDimmerClick={false} closeOnDocumentClick={false} /*closeOnEscape={false}*/ closeOnEscape={true}>
      <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
        <Loader indeterminate>{t('Loading')}</Loader>
      </Dimmer>
      <Modal.Header>{t(`${mode} ${type} Theoretical Question`)}</Modal.Header>
      <Modal.Content>
        <Container className="theoretical-question-manager-container">
          {(errMsg || []).length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              onDismiss={onDismiss}
              header={t('An error ocurred')}
              list={errMsg.map(err => err.body ?? err)}
            />
          )}
          <Form>
            <Form.Group>
              <Form.Field width={8} inline required error={savePressed && (professions || []).length <= 0}>
                <label>{t('Roles')}</label>
                <MultiProfessionEditor
                  placeholder={t('Roles')}
                  value={(professions || []).map(({ id }) => id)}
                  onChange={roles => changeHandler('professions', (roles || []).length > 0 ? roles.map(id => ({ id })) : null)}
                />
              </Form.Field>

              <Form.Field width={8} inline required error={savePressed && !answerType}>
                <label>{t('Answer Type')}</label>
                <Dropdown
                  className="planit-user-dropdown"
                  selection
                  inline
                  closeOnChange
                  closeOnEscape
                  closeOnBlur={true}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  multiple={false}
                  value={answerType}
                  placeholder={t('Answer Type')}
                  options={[
                    { text: 'Yes/No', value: 'YesOrNo' },
                    { text: 'Rating', value: 'Rating' }
                  ]}
                  onChange={(_, { value }) => changeHandler('answerType', value)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} inline required error={savePressed && !testCategoryValue}>
                <label>{t('Category')}</label>
                <TestCategorySelector
                  nullable={true}
                  isTnaCategory
                  clearable
                  className="planit-user-dropdown-custom"
                  placeholder={t('Category')}
                  value={testCategoryValue}
                  onChange={category => changeHandler('testCategoryId', category?.id || null)}
                />
              </Form.Field>

              <Form.Field width={8} inline required error={savePressed && !trainingLevelId}>
                <label>{t('Training Level')}</label>
                <TrainingLevelEditor
                  value={trainingLevelIdValue}
                  onChange={trainingLevel => changeHandler('trainingLevelId', trainingLevel?.id || null)}
                  placeholder={t('Training Level')}
                  searchable
                  clearable
                  nullable
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              {type === 'MR' ? (
                <Form.Field width={8} inline required error={savePressed && !clusterId}>
                  <label>{t('Cluster')}</label>
                  <MRClusterEditor
                    nullable
                    clearable
                    placeholder={t('Cluster')}
                    value={clusterId}
                    onChange={cluster => changeHandler('clusterId', cluster?.id || null, 'mr')}
                  />
                </Form.Field>
              ) : (
                <Form.Field width={8} inline required error={savePressed && !nmrClusterId}>
                  <label>{t('Cluster')}</label>
                  <NMRClusterEditor
                    nullable
                    placeholder={t('Cluster')}
                    clearable
                    value={nmrClusterIdValue}
                    onChange={cluster => changeHandler('nmrClusterId', cluster?.id || null)}
                  />
                </Form.Field>
              )}

              {type === 'MR' ? (
                <Form.Field width={8} inline required error={savePressed && !equipmentTypeId}>
                  <label>{t('Equipment Type')}</label>
                  <EquipmentTypeEditor
                    nullable
                    clearable
                    readOnly={!clusterId}
                    clusterId={clusterId}
                    value={equipmentTypeId}
                    onChange={value => changeHandler('equipmentTypeId', value?.id || null, 'mr')}
                    placeholder={t('Equipment Type')}
                  />
                </Form.Field>
              ) : (
                <Form.Field width={8} inline required error={savePressed && !functionalAreaId}>
                  <label>{t('Functional Area')}</label>
                  <FunctionalAreaEditor
                    clusterId={nmrClusterId}
                    value={functionalAreaValue}
                    clearOnReload
                    clearable
                    nullable
                    placeholder={t('Functional Area')}
                    readonly={!nmrClusterId}
                    onChange={item => changeHandler('functionalAreaId', item?.id ?? null)}
                  />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} inline>
                {type === 'MR' ? (
                  <>
                    <label>{t('OEM')}</label>
                    <OEM
                      value={oemId}
                      nullable
                      clearable
                      equipmentId={equipmentTypeId}
                      readonly={!clusterId || !equipmentTypeId}
                      onChange={oem => changeHandler('oemId', oem?.id || null, 'mr')}
                      placeholder={t('OEM')}
                    />
                  </>
                ) : (
                  <>
                    <label>{t('Training Name')}</label>
                    <TrainingNameEditor
                      funcionalAreaId={functionalAreaId}
                      value={trainingNameIdValue}
                      nullable
                      clearOnReload
                      clearable
                      readOnly={!functionalAreaId || !nmrClusterId}
                      onChange={item => changeHandler('trainingNameId', item?.id || null)}
                      placeholder={t('Training Name')}
                    />
                  </>
                )}
              </Form.Field>

              <Form.Field width={8} inline>
                {type === 'MR' ? (
                  <>
                    <label>{t('Machine Model')}</label>
                    <MachineModelEditor
                      equipmentId={equipmentTypeId}
                      oemId={oemId}
                      clusterId={clusterId}
                      clearable
                      nullable
                      value={machineModelId}
                      readonly={!clusterId || !oemId || !equipmentTypeId}
                      onChange={machineModel => changeHandler('machineModelId', machineModel?.id || null, 'mr')}
                      placeholder={t('Machine Model')}
                    />
                  </>
                ) : (
                  <>
                    <label>{t('Functional Subarea')}</label>
                    <FunctionalSubareaEditor
                      trainingNameId={trainingNameId}
                      readonly={!trainingNameId || !functionalAreaId || !nmrClusterId}
                      clearOnReload
                      clearable
                      reloadOnChange
                      nullable
                      value={functionalSubareaValue}
                      placeholder={t('Functional Subarea')}
                      onChange={item => changeHandler('functionalSubareaId', item?.id ?? null)}
                    />
                  </>
                )}
              </Form.Field>
            </Form.Group>
            {type === 'MR' && (
              <Form.Group>
                <Form.Field className="manager__machine-unit-plc-field" width={8} inline>
                  <label>{t('Machine Unit')}</label>
                  <MultiMachineUnitEditor
                    className="manager__machine-unit-plc__editor"
                    equipmentId={equipmentTypeId}
                    oemId={oemId}
                    readOnly={!oemId || !equipmentTypeId}
                    value={machineUnitId || ''}
                    onChange={val => changeHandler('machineUnitId', val, 'mr')}
                  />
                </Form.Field>

                <Form.Field className="manager__machine-unit-plc-field" width={8} inline>
                  <label>{t('PLC Type')}</label>
                  <MultiPlcTypeEditor
                    equipmentId={equipmentTypeId}
                    oemId={oemId}
                    multiple={false}
                    readOnly={!oemId || !equipmentTypeId}
                    value={plcTypeId || ''}
                    onChange={val => changeHandler('plcTypeId', val, 'mr')}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group className="media-box">
              <Form.Field disabled={mediaContentType === 'Video' || videoArea} required inline width={4}>
                <Button icon htmlFor="add-theoretical-image" onClick={upload}>
                  {t('Upload Image')}
                  <input id="add-theoretical-image" type="file" hidden onChange={fileHandler} />
                  <Icon name="image" />
                </Button>
              </Form.Field>

              <Form.Field required inline width={12}>
                {mediaContentType === 'Image' && !videoArea && (
                  <TheoreticalMediaInput onDownload={downloadHandler} onClear={clearMedia} file={imageInfo?.title ?? content} />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group className="media-box">
              <Form.Field disabled={mediaContentType === 'Image'} required inline width={4}>
                <Button onClick={toggleVideoArea} icon>
                  {t('Link Video')}
                  <Icon name="video" />
                </Button>
              </Form.Field>

              <Form.Field disabled={mediaContentType === 'Image'} required inline width={12}>
                {(videoArea || mediaContentType === 'Video') && (
                  <TextArea className="scroll" value={content ?? ''} onChange={videoChange} />
                )}
              </Form.Field>
              {(videoArea || mediaContentType === 'Video') && (
                <Popup
                  flowing
                  hoverable
                  size="small"
                  trigger={<Icon className="clickable-icon" size="large" color="grey" name="question circle outline" />}
                  position="top right"
                  header={'Link video from Microsoft Stream'}
                  content={
                    <>
                      <List ordered>
                        <List.Item>{t('Go to video page')}</List.Item>
                        <List.Item>
                          {t('Click on')} <strong>{t('Share')}</strong> {t('icon')}
                        </List.Item>
                        <List.Item>
                          {t('Go to the')} <strong>{t('Embed')}</strong> {t('tab')}
                        </List.Item>
                        <List.Item>{t('Adjust the visual styles of the video')}</List.Item>
                        <List.Item>
                          {t('Click on')} <strong>{t('Copy')}</strong> {t('button')}
                        </List.Item>
                        <List.Item>{t('Paste here the embed code')}</List.Item>
                      </List>
                      <div style={{ textAlign: 'right' }}>
                        <small>
                          <a
                            href="javasript:void(0)"
                            onClick={() => openInNewWindow('https://docs.microsoft.com/en-us/stream/portal-embed-video')}
                          >
                            {t('More info ')} <Icon name="external" />
                          </a>
                        </small>
                      </div>
                    </>
                  }
                />
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field required inline width={12} error={savePressed && !question.text}>
                <label>{t('Question')}</label>
                <TextArea value={question?.text || ''} onChange={(_, { value }) => questionHandler('text', value)} />
              </Form.Field>
              <Form.Field required inline width={4}>
                <Button className="add-translation-btn" type="button" onClick={handleNewTranslation}>
                  {t('Add Translation')}
                </Button>
              </Form.Field>
            </Form.Group>
            <Form.Field required inline width={5} error={savePressed && !option}>
              <label>{t('Answer')}</label>
              <AnswertypeSelector
                placeholder={t('Answer Option')}
                value={option}
                onChange={(_, { value }) => changeHandler('option', value)}
                answerType={answerType}
              />
            </Form.Field>
            {answerType === 'Rating' && (
              <>
                <Form.Field className="theoretical-options-box" required>
                  <label>{t('Options')}</label>
                  <div className="options-group">
                    <TheoreticalQuestionOption
                      value={question.optionA}
                      title={'Option A'}
                      onChange={(_, { value }) => questionHandler('optionA', value)}
                    />
                    <TheoreticalQuestionOption
                      value={question.optionB}
                      title={'Option B'}
                      onChange={(_, { value }) => questionHandler('optionB', value)}
                    />
                    <TheoreticalQuestionOption
                      value={question.optionC}
                      title={'Option C'}
                      onChange={(_, { value }) => questionHandler('optionC', value)}
                    />
                    <TheoreticalQuestionOption
                      value={question.optionD}
                      title={'Option D'}
                      onChange={(_, { value }) => questionHandler('optionD', value)}
                    />

                    <TheoreticalQuestionOption
                      value={question.optionE}
                      title={'Option E'}
                      onChange={(_, { value }) => questionHandler('optionE', value)}
                    />
                  </div>
                </Form.Field>
              </>
            )}

            {(questionTranslations || []).length > 0 && (
              <Accordion>
                {questionTranslations.map((item, index) => (
                  <TheoreticalQuestionTranslation
                    key={index + item?.language?.id ?? ''}
                    {...item}
                    index={index}
                    onChange={(prop, value) => translationHandler(prop, value, index)}
                    onRemove={() => handleRemoveTranslation(index)}
                    answerType={answerType}
                    onLanguageChange={item => languageHandler(item, index)}
                  />
                ))}
              </Accordion>
            )}
          </Form>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          {t('Cancel')}
        </Button>
        <Button disabled={!canSave} onClick={onSave} positive>
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default withTranslation()(TheoreticalQuestionManager);
