import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { BaseDto } from 'stores/types';

export interface RegionDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateRegionDto {
  name: string;
}

export interface ChangeRegionDto {
  id: string;
  name: string;
}

export class CreateRegionValidator extends AbstractValidator<CreateRegionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Region name is required'));
  }
}

export class ChangeRegionValidator extends AbstractValidator<ChangeRegionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Region id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Region name is required'));
  }
}

@repository('@@REGIONS', 'regions.summary')
export class RegionsStore extends DataStore<RegionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-region';
  retrievePath = 'get-regions';
  updatePath = 'update-region';
  deletePath = 'delete-region';

  protected validate(item: RegionDto) {
    return new ChangeRegionValidator().validate(item);
  }

  constructor() {
    super('REGION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@REGIONS', 'regions.new')
export class NewRegionStore extends FormStore<CreateRegionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-region';
  retrievePath = 'get-regions';
  updatePath = 'update-region';

  protected validate(item: CreateRegionDto) {
    return new CreateRegionValidator().validate(item);
  }

  constructor() {
    super('NEW_REGION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@REGIONS', 'regions.change')
export class ChangeRegionStore extends FormStore<ChangeRegionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-region';
  retrievePath = 'get-regions';
  updatePath = 'update-region';

  protected validate(item: ChangeRegionDto) {
    return new ChangeRegionValidator().validate(item);
  }

  constructor() {
    super('CHANGE_REGION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
