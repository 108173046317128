import i18n from 'i18n';
import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import queryString from 'query-string';

import NMClusterListPage from './nm-clusters-list';
import NMFunctionalAreaListPage from './nm-functional-areas-list';
import NMFunctionalSubareaListPage from './nm-functional-subareas-list';
import NMTrainingNameListPage from './nm-training-names-list';
import { Location } from 'history';

export interface NMRConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface NMRConfigurationHomeState {
  activeTab: 'clusters' | 'non-machine-related';
}
const getActiveIndex = (location: Location<any>, match) => {
  let values = queryString.parse(location.search);
  if (values == null || values.tab == null) values = match.params;

  if (values == null || values.tab == null) return 0;

  let v: string;
  if (values.tab.constructor === Array) v = values.tab[0];
  else v = values.tab as string;
  const n = Number.parseInt(v);
  const l = panes.length;
  if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
  return 0;
};

const panes = [
  {
    menuItem: i18n.t('Clusters'),
    render: () => (
      <Tab.Pane attached={false}>
        <NMClusterListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Functional Areas'),
    render: () => (
      <Tab.Pane attached={false}>
        <NMFunctionalAreaListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Training Names'),
    render: () => (
      <Tab.Pane attached={false}>
        <NMTrainingNameListPage />
      </Tab.Pane>
    )
  },
  {
    menuItem: i18n.t('Functional Subareas'),
    render: () => (
      <Tab.Pane attached={false}>
        <NMFunctionalSubareaListPage />
      </Tab.Pane>
    )
  }
];

const NMRConfigurationHomePage: FC<NMRConfigurationHomeProps> = ({ t, match, history, location }) => {
  const [activeIndex, setActiveIndex] = useState(() => getActiveIndex(location, match));

  const onTabChange = (_, { activeIndex }) => {
    history.replace(`/configuration/non-machinery?tab=${activeIndex}`);
    setActiveIndex(activeIndex);
  };

  return (
    <>
      <h3>{t('Non-Machine Related')}</h3>
      <Tab
        className="main-tab"
        activeIndex={activeIndex}
        onTabChange={onTabChange}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  );
};

export default withTranslation()(NMRConfigurationHomePage);
