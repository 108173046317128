import React, { Component } from 'react';
import { RequestFormStore } from 'stores/requests/request-form-store';
import { withTranslation, WithTranslation } from 'react-i18next';
import StudentsAssignTableComponent, { StudentsTabViewModel } from '../../../shared/events-and-requests/tabs/students-assign-component';
import { connect } from 'redux-scaffolding-ts';

export interface StudentsAssignTableComponentContainerProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
}

@connect(['requestFormStore', RequestFormStore])
class StudentsAssignTableComponentContainer extends Component<StudentsAssignTableComponentContainerProps> {
  private handleOnStudentsChange = (newValue: StudentsTabViewModel) => {
    this.props.requestFormStore.change({
      students: newValue.students,
      studentsNumber: newValue.studentsAssigned
    });
  };

  render() {
    const { readOnly } = this.props;
    const request = this.props.requestFormStore.state.item;
    const value: StudentsTabViewModel = {
      students: request.students,
      studentsAssigned: request.studentsNumber
    };

    return <StudentsAssignTableComponent readOnly={readOnly} value={value} onChange={this.handleOnStudentsChange} />;
  }
}

export default withTranslation()(StudentsAssignTableComponentContainer);
