import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Button, Popup, Menu, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult, FileInfo } from 'stores/types';
import { TableModel, TableView } from 'widgets/collections/table';
import './assigment-employees-no-profile.less';
import { ItemReference } from 'stores/dataStore';
import { UserStore, UserDto } from 'stores/users/users-store';
import LocationEditor from 'widgets/bussiness/location-editor';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import { AssignmentMode } from '../profile-assigment-home';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { openInNewWindow } from 'utils/useful-functions';
import { GetLocationName } from 'utils/userinfo-functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { faFileUpload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { UploadUserProfileAssignmentsStore } from 'stores/profile/user-profile-upload-store';

export interface UserProfilesAssigmentProps extends WithTranslation, RouteComponentProps {
  onAssignment: (mode: AssignmentMode, data: any) => void;
  usersWithoutProfileStore?: UserStore;
  uploadUserProfileAssignmentsStore: UploadUserProfileAssignmentsStore;
}

interface EmployeeWithOutProfileFilters {
  employee: string;
  locationId: string;
  positionCodeId: string;
  sFPosition: string;
}

export interface UserProfilesAssigmentState {
  query: Query;
  newUserProfileShown: boolean;
  changeUserProfileShown: boolean;
  selectedItem: any;
  isclone: boolean;
  cloneData: UserDto;
  location: ItemReference;
  filters: EmployeeWithOutProfileFilters;
  selectedRows: any;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  uploadLoadingMessage: string;
}

@connect(['usersWithoutProfileStore', UserStore], ['uploadUserProfileAssignmentsStore', UploadUserProfileAssignmentsStore])
class AssigmentEmployeesWithoutProfile extends React.Component<UserProfilesAssigmentProps, UserProfilesAssigmentState> {
  timer: any = null;
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10, filter: [{ hasProfileAssigned: false }] },
      newUserProfileShown: false,
      changeUserProfileShown: false,
      //activeFilters: ,
      filters: {
        employee: undefined,
        locationId: undefined,
        positionCodeId: undefined,
        sFPosition: undefined
      },
      selectedItem: null,
      cloneData: null,
      isclone: false,
      location: null,
      selectedRows: null,
      uploadMenuOpen: false,
      showUploadMsg: false,
      uploadLoadingMessage: ''
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  load = () => {
    const { filters, query } = this.state;

    let locationIds: string[] = null;
    if (filters.locationId != null) {
      locationIds = [filters.locationId];
    } else if (IdentityService.isPoc(this.identityService.getUserInfo())) {
      locationIds = (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || [];
    }
    this.props.usersWithoutProfileStore.getUsersByRoleAndLocationsAndOData(query, locationIds, 'Employee');
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: UserDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.usersWithoutProfileStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: UserDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.usersWithoutProfileStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, () => this.load());
  }

  private onEditItem = item => {
    this.props.onAssignment('new', [item]);
    // this.setState({ changeUserProfileShown: true });
  };

  @autobind
  private onEmployeeNameFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.employee = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private onSFPositionFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.sFPosition = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private handleFilterByLocation(location: ItemReference) {
    let filters = { ...this.state.filters };
    filters.locationId = location ? location.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByPositionCode(positionCode: PositionCodeDto) {
    let filters = { ...this.state.filters };
    filters.positionCodeId = positionCode ? positionCode.id : null;
    this.refreshTable(filters);
  }

  private refreshTable(filters: EmployeeWithOutProfileFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters: EmployeeWithOutProfileFilters) {
    let oDataFilter = [];
    oDataFilter.push({ hasProfileAssigned: false });

    if (filters.employee) {
      oDataFilter.push({
        or: [{ 'tolower(name)': { contains: filters.employee } }, { 'tolower(surname)': { contains: filters.employee } }]
      });
    }

    if (filters.positionCodeId) {
      oDataFilter.push({ PositionCodeId: { eq: { type: 'guid', value: filters.positionCodeId } } });
    }

    if (filters.sFPosition) {
      oDataFilter.push({ 'tolower(SFPosition)': { contains: filters.sFPosition } });
    }

    return oDataFilter;
  }
  selectedRowsHandler = selectedRows => {
    this.setState({ selectedRows });
  };

  onNewProfileHandler = () => {
    this.state.selectedRows && this.state.selectedRows.length > 0 && this.props.onAssignment('multiAssign', this.state.selectedRows);
  };

  private onEmployeeCardClicked(id: string) {
    openInNewWindow(`./employee-page/${id}`);
  }

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.uploadUserProfileAssignmentsStore
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.uploadUserProfileAssignmentsStore
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  public render() {
    const { t } = this.props as any;
    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const tableModel = {
      columns: [
        {
          title: t('User Name'),
          tooltipRenderer: true,
          renderer: data => data.userName,
          selectableHeader: true
        },
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => <div>{data.lastName.toUpperCase()}</div>,
          selectableHeader: true
        },
        {
          title: t('First Name'),

          tooltipRenderer: true,
          renderer: data => data.firstName.toUpperCase(),
          selectableHeader: true
        },
        {
          title: t('SAP ID'),
          tooltipRenderer: true,
          renderer: data => data.employeeId
        },

        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: GetLocationName
        },
        {
          title: t('Position Code'),
          tooltipRenderer: true,
          renderer: data => (data.positionCode ? data.positionCode.code.toUpperCase() : '')
        },
        {
          title: t('SF Position'),
          tooltipRenderer: true,
          renderer: data => data.sfPosition && data.sfPosition.toUpperCase()
        },
        {
          title: t('User Status'),
          tooltipRenderer: false,
          renderer: data => <span className="question-bank__cell__tag">{data.enabled ? 'Active' : 'Inactive'}</span>,
          selectableHeader: true
        }
      ],
      data: this.props.usersWithoutProfileStore.state
    } as TableModel<UserDto>;

    return (
      <div className="assigment-employees-not-profiles__wrapper">
        <Grid className="event-types-list-grid">
          {this.props.usersWithoutProfileStore.state.result && !this.props.usersWithoutProfileStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.usersWithoutProfileStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          {
            <div className="assigment-employees-profiles__filters">
              <Input
                clearable
                className="custom-editor-search"
                icon="search"
                placeholder={t('Search Employee')}
                onChange={this.onEmployeeNameFilterChanged}
              />

              <LocationEditor
                clearable
                className="custom-editor"
                placeholder={t('Location')}
                nullable
                value={null}
                locationsReceived={pocLocations}
                onChange={location => this.handleFilterByLocation(location)}
              />

              <PositionCodeEditor
                clearable
                placeholder={t('Position Code')}
                onChange={positionCode => this.handleFilterByPositionCode(positionCode)}
                value={null}
                nullable
                className="custom-editor"
                inline={false}
              />

              <Input
                clearable
                className="custom-editor-search"
                icon="search"
                placeholder={t('Search SF Position')}
                onChange={this.onSFPositionFilterChanged}
              />
            </div>
          }
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              model={tableModel}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Popup
                      on="click"
                      position="bottom center"
                      open={this.state.uploadMenuOpen}
                      onOpen={() => this.setState({ uploadMenuOpen: true })}
                      onClose={() => this.setState({ uploadMenuOpen: false })}
                      trigger={
                        <Button
                          icon
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={() => this.setState({ uploadMenuOpen: true })}
                          data-tooltip={t('Import')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                        </Button>
                      }
                    >
                      <Menu vertical secondary>
                        <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                          <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                          <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                          &nbsp;{t('Upload File')}
                        </Menu.Item>

                        <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                          <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                          &nbsp;{t('Download Template')}
                        </Menu.Item>
                      </Menu>
                    </Popup>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Assign Profile')}</span>}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem(item));
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="eye" />
                      {<span className="text__bold">{t('Open Employee Card')}</span>}
                    </>
                  ),
                  onClick: (item: UserDto) => this.onEmployeeCardClicked(item.id)
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              canCreateNew={true}
              createButtonIcon={{ hasIcon: false }}
              createButtonDisabled={!this.state.selectedRows || this.state.selectedRows.length <= 0}
              onRefresh={this.load}
              canEdit={false}
              selectable
              onSelectionChange={rows => this.selectedRowsHandler(rows)}
              deleteLabel="Remove Profile"
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onNewItem={this.onNewProfileHandler}
              createNewButtonTitle={t('Assign to Multiple Users')}
              createButtonPopup={t('Multiassignment')}
              isRowDisableLayout={item => !(item as any).enabled}
              areActionsDisabled={item => !(item as any).enabled}
              unselectFirstRow
            ></TableView>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(AssigmentEmployeesWithoutProfile);
