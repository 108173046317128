import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NMClusterDto,
  ChangeNMClusterDto,
  ChangeNMClusterStore
} from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { Categories } from 'stores/requests/requests-store';
import { nameof } from 'utils/object';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';

interface ChangeNMClusterViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeNMCluster?: ChangeNMClusterStore;
  currentDto: NMClusterDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeNMClusterViewState {
  editorValue: any;
}

@connect(['changeNMCluster', ChangeNMClusterStore])
class ChangeNMClusterView extends React.Component<ChangeNMClusterViewProps, ChangeNMClusterViewState> {
  private get changeNMClusterStore() {
    return this.props.changeNMCluster;
  }

  constructor(props: ChangeNMClusterViewProps) {
    super(props);

    this.changeNMClusterStore.state.result = null;

    this.changeNMClusterStore.change({
      id: this.props.currentDto.id,
      name: this.props.currentDto.name,
      category: this.props.currentDto.category
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeNMClusterViewProps) {
    if (this.changeNMClusterStore.state.result && this.changeNMClusterStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeNMClusterStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeNMClusterStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeNMClusterStore.change({ ...this.changeNMClusterStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeNMClusterStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Cluster')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeNMClusterStore.state.result && !this.changeNMClusterStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeNMClusterStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeNMClusterStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <div
                      className={`required ${
                        this.changeNMClusterStore.state.item.category == null ||
                        ![Categories.Functional, Categories.Technical].includes(Categories[this.changeNMClusterStore.state.item.category])
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Category')}</label>
                      <CategoryFormEditor
                        className="form__header__dropdown-style"
                        value={this.changeNMClusterStore.state.item.category}
                        onChange={value => this.handleValue(nameof<ChangeNMClusterDto>('category'), value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Input
                    required
                    fluid
                    error={!this.changeNMClusterStore.state.item.name}
                    label={t('Cluster')}
                    placeholder={t('Name')}
                    value={this.changeNMClusterStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeNMClusterDto>('name'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeNMClusterView);
