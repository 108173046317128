import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Component } from 'react';
import { resolve } from 'inversify-react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { isUserEventOwner, isCurrentUserEventInstructor, isCurrentUserEventLeadInstructor } from 'utils/event-utils';
import { IdentityService, Roles } from 'services/identity-service';
import { EventFormStore } from 'stores/events/event-form-store';
import {
  EventPracticalTabStore,
  NewPracticalFormUserStore,
  CreatePracticalFormDto,
  EventPracticalTemplatesDto
} from 'stores/events/practical-form-store';
import PracticalTable from './practical-table-form';
import { openInNewWindow } from 'utils/useful-functions';
import PracticalEmployeeTable from './practical-table-form-employee-view';
import './practical-tab.less';
import { PracticalFormItemDto } from 'stores/assessments/forms/practical-form-store';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

interface PracticalTabProps extends WithTranslation {
  eventFormStore?: EventFormStore;
  practicalStore?: EventPracticalTabStore;
  newPracticalStore?: NewPracticalFormUserStore;
}
export interface PracticalTabState {
  showConfirmPracticalModal: boolean;
  showConfirmInitUserTestModal: boolean;
  practicalFormIdToGenerate: string;
  practicalFormTitleToGenerate: string;
  formSelected: any;
  showDeleteConfirmModal: boolean;
  selectedPractical: PracticalFormItemDto;
}

@connect(['eventFormStore', EventFormStore], ['practicalStore', EventPracticalTabStore], ['newPracticalStore', NewPracticalFormUserStore])
class PracticalTab extends Component<PracticalTabProps, PracticalTabState> {
  state: PracticalTabState = {
    showConfirmPracticalModal: false,
    showConfirmInitUserTestModal: false,
    practicalFormIdToGenerate: null,
    practicalFormTitleToGenerate: null,
    formSelected: null,
    showDeleteConfirmModal: false,
    selectedPractical: null
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isEventOwnerInstructorAdminOrPoc = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      isUserEventOwner(event) ||
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventInstructor(event)) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.PoC)
    );
  };

  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const { id, status } = this.props.eventFormStore.state.item;
    const statusString = status.toString();
    try {
      await this.props.practicalStore.getTemplatesByEventId(id, statusString);
    } catch (error) {
      console.error({ error });
    }
  };

  private isCurrentUserEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  private getCurrentUserId = (): string => {
    const { userId } = this.identityService.getUserInfo();
    return userId;
  };

  private closeConfirmModal = () => {
    this.setState({ showConfirmPracticalModal: false });
  };

  private onGeneratePracticalForm = async () => {
    const { eventFormStore, practicalStore, newPracticalStore } = this.props;
    const eventId = eventFormStore.state.item.id;
    const storedData = practicalStore.state.items.find(({ item }) => item.templateId === this.state.practicalFormIdToGenerate);

    if (!storedData.item) return;
    const newItem: CreatePracticalFormDto = {
      practicalTemplateId: storedData.item.templateId,
      eventId: eventId
    };

    newPracticalStore.createNew(newItem);
    try {
      const newForms = await this.props.newPracticalStore.createForms();
      if (newForms.isSuccess) {
        practicalStore.state.items = [];
        this.load();
      }
    } catch (error) {
      console.error({ error });
      if (error?.response?.status === 400 && (error?.response?.data?.messages || []).length > 0) {
        (error?.response?.data?.messages || []).map(item => ToastComponent({ text: this.props.t(item?.body), type: 'error-toast' }));
      }
    }

    this.setState({ showConfirmPracticalModal: false, showConfirmInitUserTestModal: false, showDeleteConfirmModal: false });
  };

  private onOpenEmployeePracticalForm = (form: PracticalFormItemDto) => {
    openInNewWindow(`./assessments/practical-test/${form.id}`);
  };

  private isEventInProgress = (): boolean => {
    return this.props.eventFormStore.state.item.status.toString() === 'InProgress';
  };

  private handleGenerateForms = (templateId: string, title: string) => {
    this.setState({ practicalFormIdToGenerate: templateId, showConfirmPracticalModal: true, practicalFormTitleToGenerate: title });
  };

  private showDeleteConfirmationModal = (form: PracticalFormItemDto) => {
    this.setState({ selectedPractical: form, showDeleteConfirmModal: true });
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false, selectedPractical: null });
  };

  private userCanDelete = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventLeadInstructor(event)) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.Planner) ||
      this.identityService.isInRole(Roles.PlannerMTC)
    );
  };

  private onDelete = async (id: string) => {
    this.hideConfirmModal();
    const deleteResponse = await this.props.practicalStore.deleteAsync(id);

    if (deleteResponse.isSuccess) {
      this.props.practicalStore.state.items = [];
      this.load();
    }
  };

  public render() {
    const { t, practicalStore, newPracticalStore } = this.props;
    const { items } = practicalStore.state;
    const { showConfirmPracticalModal, selectedPractical, showDeleteConfirmModal, practicalFormTitleToGenerate } = this.state;
    const currentUserId = this.getCurrentUserId();
    const isUserEmployee = this.isCurrentUserEmployee();
    const isEventOwnerInstructorAdminOrPoc = this.isEventOwnerInstructorAdminOrPoc();
    const isEventInProgress = this.isEventInProgress();

    return (
      <>
        <Dimmer
          active={practicalStore.state.isBusy || newPracticalStore.state.isBusy}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}
        >
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <div className="form__feedback-container">
          {(items || []).map(item => {
            const { templateTitle, templateId, practicalForms } = item.item as EventPracticalTemplatesDto;
            const feedbackTypeClass =
              (practicalForms || []).length > 0 ? 'form__feedback-item flex-column' : 'form__feedback-item flex-start-center';
            return (
              <>
                {isEventOwnerInstructorAdminOrPoc && (practicalForms || []).length > 0 && (
                  <div key={templateId} className={feedbackTypeClass}>
                    <p className="feedback-tab__item-title">{templateTitle}</p>
                    <PracticalTable
                      templateId={templateId}
                      practicalFormAnswers={practicalForms || []}
                      onOpenEmployeePracticalForm={this.onOpenEmployeePracticalForm}
                      showDeleteConfirmationModal={this.showDeleteConfirmationModal}
                      userCanDeleteAndResend={this.userCanDelete()}
                    />
                  </div>
                )}

                {isEventOwnerInstructorAdminOrPoc && isEventInProgress && item && (!practicalForms || practicalForms.length <= 0) && (
                  <div key={templateId} className={feedbackTypeClass}>
                    <p className="feedback-tab__item-title">{templateTitle}</p>
                    <Button onClick={() => this.handleGenerateForms(templateId, templateTitle)}>{t('Generate Test')}</Button>
                  </div>
                )}

                {isUserEmployee && !isEventOwnerInstructorAdminOrPoc && (practicalForms || []) && (
                  <PracticalEmployeeTable
                    currentUserId={currentUserId}
                    templateId={templateId}
                    practicalFormAnswers={practicalForms || []}
                  />
                )}
              </>
            );
          })}
          {showConfirmPracticalModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              centered
              open={showConfirmPracticalModal}
              size="mini"
              className="have-warnings-popup feedback__confirm-modal"
              closeOnEscape={true}
              onClose={this.closeConfirmModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>
                  {t(
                    `
                    You are going to generate Practical test "${practicalFormTitleToGenerate}". Are you sure you want to generate it?
                    `
                  )}
                </p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="feedback-modal__cancel-button" content={t('Cancel')} onClick={this.closeConfirmModal} />
                <Button
                  className="have-warnings-popup__btn feedback-modal__accept-button"
                  content={t('Accept')}
                  onClick={this.onGeneratePracticalForm}
                />
              </div>
            </Modal>
          )}

          {showDeleteConfirmModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              open={showDeleteConfirmModal}
              size="mini"
              centered
              className="have-warnings-popup feedback__confirm-modal"
              closeOnEscape={true}
              onClose={this.hideConfirmModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>{t(`This practical test will be deleted. Are you sure that you want to delete this item?`)}</p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="theoretical-modal__cancel-button" content={t('No')} onClick={this.hideConfirmModal} />
                <Button
                  className="have-warnings-popup__btn feedback-modal__accept-button"
                  content={t('Yes')}
                  onClick={() => this.onDelete(selectedPractical?.id)}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(PracticalTab);
