import { AbstractValidator, ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces, isNullOrEmpty } from 'utils/useful-functions';
import { ItemResult, Message, CommandResult } from 'stores/types';
import { AxiosResponse } from 'axios';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { nameof, getProperties } from 'utils/object';
import { OrderDefinition, SortDirection } from 'stores/dataStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';

export interface DynamicScoreTable {
  skillColumns: { [key: string]: string };
  rows: ScoreRow[];
}
export interface ScoreItem {
  skillName: string;
  skillId: string;
  score?: number;
  isUpdated: boolean;
}
export interface ScoreRow {
  id: string;
  userId: string;
  fullName: string;
  userName: string;
  level: string;
  machineModelId: string;
  totalScore?: number;
  scores: { [key: string]: ScoreItem };
  positionCode: string;
  lastTestTemplateName: string;
  userEnabled?: boolean;
}

export enum Assessment {
  Unknown = 0,
  TNA = 10,
  SSA = 20,
  IWS = 30
}

export interface EvaluationsSearchFilters {
  assessment: string;
  profileId: string;
  profile: ProfileItemDto;
  locationsId: string[];
  level: string;
  oemId: string;
  equipmentTypeId: string;
  clusterId: string;
  machineModelId: string;
  machineModelName: string;
  showDisabled: string;
  positionCodesId: string[];
}

export interface EvaluationDynamicTableColumnFilters {
  [key: string]: string;
}

export interface EvaluationDynamicTableStoreState {
  isBusy: boolean;
  result: ItemResult<DynamicScoreTable>;
  filters: EvaluationsSearchFilters;
  rows: ScoreRow[];
  minmax: { [key: string]: { max: number; min: number } };
}

export class EvaluationsSearchFiltersValidator extends AbstractValidator<EvaluationsSearchFilters> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No filter provided'));

    this.validateIf(x => x.assessment)
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Assessment is required'));

    this.validateIf(x => x.assessment)
      .isIn(['TNA', 'SSA', 'IWS'])
      .when(x => x != null && !isNullOrEmpty(x.assessment))
      .withFailureMessage(i18n.t('Assessment filter is wrong'));

    this.validateIfString(x => x.profileId)
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Profile is required'));

    this.validateIfString(x => x.profileId)
      .isUuid('4')
      .when(x => x != null && !isNullOrEmpty(x.profileId))
      .withFailureMessage(i18n.t('Profile filter is wrong'));

    this.validateIfIterable(x => x.locationsId)
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Locations is required'));

    this.validateIfEachString(x => x.locationsId)
      .isUuid('4')
      .when(x => x != null && (x.locationsId || []).length !== 0)
      .withFailureMessage(i18n.t('Locations filter is wrong'));

    this.validateIf(x => x.clusterId)
      .isNotEmpty()
      .when(x => x != null && !isNullOrWhiteSpaces(x.assessment) && x.assessment === Assessment[Assessment.TNA].toString())
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.clusterId)
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.assessment) &&
          x.assessment === Assessment[Assessment.SSA].toString() &&
          !isNullOrEmpty(x.clusterId)
      )
      .withFailureMessage(i18n.t('Cluster filter is wrong'));

    this.validateIf(x => x.equipmentTypeId)
      .isNotEmpty()
      .when(x => x != null && !isNullOrWhiteSpaces(x.assessment) && x.assessment === Assessment[Assessment.TNA].toString())
      .withFailureMessage(i18n.t('Equipment type is required'));

    this.validateIfString(x => x.equipmentTypeId)
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.assessment) &&
          x.assessment === Assessment[Assessment.SSA].toString() &&
          !isNullOrEmpty(x.equipmentTypeId)
      )
      .withFailureMessage(i18n.t('Equipment type filter is wrong'));

    this.validateIf(x => x.oemId)
      .isNotEmpty()
      .when(x => x != null && !isNullOrWhiteSpaces(x.assessment) && x.assessment === Assessment[Assessment.TNA].toString())
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(x => x.oemId)
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.assessment) &&
          x.assessment === Assessment[Assessment.TNA].toString() &&
          !isNullOrEmpty(x.oemId)
      )
      .withFailureMessage(i18n.t('OEM filter is wrong'));

    this.validateIf(x => x.machineModelId)
      .isNotEmpty()
      .when(x => x != null && !isNullOrWhiteSpaces(x.assessment) && x.assessment === Assessment[Assessment.TNA].toString())
      .withFailureMessage(i18n.t('Machine model is required'));

    this.validateIfString(x => x.machineModelId)
      .isUuid('4')
      .when(
        x =>
          x != null &&
          !isNullOrWhiteSpaces(x.assessment) &&
          x.assessment === Assessment[Assessment.TNA].toString() &&
          !isNullOrEmpty(x.machineModelId)
      )
      .withFailureMessage(i18n.t('Machine model filter is wrong'));

    this.validateIfEachString(x => x.positionCodesId)
      .isUuid('4')
      .when(x => (x.positionCodesId || []).length !== 0)
      .withFailureMessage(i18n.t('Position Codes filter is wrong'));
  }
}

export class UpdateScoreRowValidator extends ExtendedAbstractValidator<ScoreRow> {
  constructor(idx?: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    let preffix = '';
    if (idx != null) {
      preffix = i18n.t(`Row at position ${idx}`) + ': ';
    }

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}${i18n.t('User Id is required')}`);

    this.validateIfString(x => x.level)
      .isIn(['Main', 'Substitute'])
      .when(x => x != null && !isNullOrWhiteSpaces(x.level))
      .withFailureMessage(`${preffix}${i18n.t('Level is required')}`);

    this.validateIfString(x => x.machineModelId)
      .isUuid('4')
      .when(x => x != null && x.level === Assessment[Assessment.TNA])
      .withFailureMessage(`${preffix}${i18n.t('Machine Model Id is required')}`);

    this.validateIf(x => x.totalScore)
      .fulfills(x => x >= 0 && x <= 4)
      .when(x => x != null && x.totalScore != null)
      .withFailureMessage(`${preffix}${i18n.t('Invalid Total Score (should be between [0, 4])')}`);

    this.validateIf(x => x.scores)
      .fulfills(x =>
        getProperties(x).all(({ value }) => {
          let v = value as ScoreItem;
          return !isNullOrWhiteSpaces(v.skillId) && (v.score == null || (v.score >= 0 && v.score <= 4));
        })
      )
      .when(x => x != null && x.scores != null)
      .withFailureMessage(`${preffix}${i18n.t('At least one score is wrong (should be between [0, 4])')}`);
  }
}

export class UpdateScoreRowsValidator extends ExtendedAbstractValidator<{ rows: ScoreRow[] }> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .fulfills(x => (x.rows || []).all((r, idx) => new UpdateScoreRowValidator(idx + 1, this.addErrors).extendValidate(r).isValid()))
      .withFailureMessage(`At least one row is wrong`);
  }
}

@repository('@@EVALUATIONS', 'evaluations.dynamicTable')
export class EvaluationsDynamicTableStore extends ReduxRepository<EvaluationDynamicTableStoreState> {
  baseUrl = 'skills/v1';
  retrievePath = 'get-current-scores-dynamic-table';
  exportToExcelPath = 'get-current-scores-dynamic-table-excel';
  updatePath = 'update-current-score';
  updateBulkPath = 'update-current-scores';

  public TABLE_LOADED = 'TABLE_LOADED';
  public FILTER_VALIDATED = 'FILTER_VALIDATED';
  public EXCEL_EXPORTED = 'EXCEL_EXPORTED';
  public CLEARED = 'CLEARED';
  public ORDER_AND_FILTERS_APPLIED = 'ORDER_AND_FILTERS_APPLIED';
  public ROW_UPDATED = 'ROW_UPDATED';
  public ROWS_UPDATED = 'ROWS_UPDATED';
  public ROWS_VALIDATED = 'ROWS_VALIDATED';

  public validateSearchFilters(item: EvaluationsSearchFilters) {
    return new EvaluationsSearchFiltersValidator().validate(item);
  }

  public validateRow(item: ScoreRow) {
    return new UpdateScoreRowValidator().validate(item);
  }

  public validateRows(rows: ScoreRow[]) {
    return new UpdateScoreRowsValidator().extendValidate({ rows });
  }

  constructor() {
    super({
      isBusy: false,
      result: undefined,
      filters: undefined,
      rows: [],
      minmax: {}
    });

    this.getCurrentScores.bind(this);
    this.exportToExcel.bind(this);
    this.orderAndFilter.bind(this);
    this.applyOrderAndFilter.bind(this);
    this.onTableLoaded.bind(this);
    this.onFilterValidated.bind(this);
    this.clear.bind(this);
    this.onClear.bind(this);
    this.onExcelExported.bind(this);
    this.onOrderAndFilter.bind(this);
    this.updateRow.bind(this);
    this.onRowUpdated.bind(this);
    this.onRowsValidated.bind(this);
    this.onRowsUpdated.bind(this);

    this.addReducer(this.TABLE_LOADED, this.onTableLoaded, 'AsyncAction');
    this.addReducer(this.EXCEL_EXPORTED, this.onExcelExported, 'AsyncAction');
    this.addReducer(this.ROW_UPDATED, this.onRowUpdated, 'AsyncAction');
    this.addReducer(this.ROWS_UPDATED, this.onRowsUpdated, 'AsyncAction');
    this.addReducer(this.ORDER_AND_FILTERS_APPLIED, this.onOrderAndFilter, 'AsyncAction');
    this.addReducer(this.FILTER_VALIDATED, this.onFilterValidated, 'Simple');
    this.addReducer(this.ROWS_VALIDATED, this.onRowsValidated, 'Simple');
    this.addReducer(this.CLEARED, this.onClear, 'Simple');
  }

  public clear() {
    this.dispatch(this.CLEARED);
  }

  protected onClear() {
    return {
      isBusy: false,
      result: undefined,
      filters: undefined,
      rows: [],
      minmax: {}
    };
  }

  public async getCurrentScores(filters: EvaluationsSearchFilters) {
    const validationResult = this.validateSearchFilters(filters);

    if (validationResult.isInvalid()) {
      this.dispatch(this.FILTER_VALIDATED, validationResult);
    } else {
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.TABLE_LOADED,
        httpService.get<ItemResult<DynamicScoreTable>>(`${this.baseUrl}/${this.retrievePath}`, filters),
        filters
      );
      return result.data;
    }
  }
  protected onFilterValidated(result: ValidationResult) {
    return {
      ...this.state,
      isBusy: false,
      result: {
        item: undefined,
        isSuccess: false,
        messages: result.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as ItemResult<DynamicScoreTable>,
      filters: undefined,
      rows: [],
      minmax: {}
    };
  }

  private onTableLoaded = (): AsyncAction<AxiosResponse<ItemResult<DynamicScoreTable>>, EvaluationDynamicTableStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result: AxiosResponse<ItemResult<DynamicScoreTable>>, filters: EvaluationsSearchFilters) => {
        return {
          ...this.state,
          isBusy: false,
          filters,
          result: result.data,
          rows: result.data.item.rows,
          minmax: this.calculateMinMaxs(result.data.item.rows, result.data.item.skillColumns)
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        filters: undefined,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? (error.response.data as ItemResult<DynamicScoreTable>)
            : {
                isSuccess: false,
                item: undefined,
                messages: [{ body: error.message || error, level: 'Error' }]
              },
        rows: [],
        minmax: {}
      })
    };
  };

  public async exportToExcel() {
    const httpService = container.get(HttpService);
    return await this.dispatchAsync(
      this.EXCEL_EXPORTED,
      httpService.download(`${this.baseUrl}/${this.exportToExcelPath}`, null, null, this.state.filters)
    );
  }

  private onExcelExported = (): AsyncAction<AxiosResponse<any>, EvaluationDynamicTableStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: () => ({ ...this.state, isBusy: false }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: {
          isSuccess: false,
          item: this.state.result.item,
          messages:
            error == null
              ? [{ body: 'Something went wrong', level: 'Error' }]
              : error.response && error.response.data && error.response.data.messages
              ? (error.response.data as ItemResult<DynamicScoreTable>).messages
              : [{ body: String(error.message || error), level: 'Error' }]
        }
      })
    };
  };

  private stringComparer = (x: string, y: string): number => {
    if (isNullOrWhiteSpaces(x) && isNullOrWhiteSpaces(y)) return 0;
    if (isNullOrWhiteSpaces(x)) return -1;
    if (isNullOrWhiteSpaces(y)) return 1;
    return x.localeCompare(y);
  };

  private numberComparer = (x?: number, y?: number): number => {
    if (x == null && y == null) return 0;
    if (x == null) return -1;
    if (y == null) return 1;
    if (x === y) return 0;
    return x < y ? -1 : 1;
  };

  private skillComparer = (key: string) => {
    return (a: ScoreRow, b: ScoreRow) => {
      const objA = (a.scores && a.scores[key]) || { score: null };
      const objB = (b.scores && b.scores[key]) || { score: null };
      return this.numberComparer(objA.score, objB.score);
    };
  };

  private generalComparer = (sorter: (a: ScoreRow, b: ScoreRow) => number, direction: SortDirection) => {
    return (a: ScoreRow, b: ScoreRow) => {
      const cmp = sorter(a, b);
      if (direction === 'Descending') return -cmp;
      return cmp;
    };
  };

  private combineComparers = (sorter: ((a: ScoreRow, b: ScoreRow) => number)[]) => {
    return (a: ScoreRow, b: ScoreRow) => {
      let result = 0;
      for (let i = 0; i < sorter.length; i++) {
        result = sorter[i](a, b);
        if (result !== 0) return result;
      }
      return result;
    };
  };

  private sorter: { [key: string]: (a: ScoreRow, b: ScoreRow) => number } = {
    [nameof<ScoreRow>('fullName')]: (a, b) => this.stringComparer(a.fullName, b.fullName),
    [nameof<ScoreRow>('userName')]: (a, b) => this.stringComparer(a.userName, b.userName),
    [nameof<ScoreRow>('level')]: (a, b) => this.stringComparer(a.level, b.level),
    [nameof<ScoreRow>('positionCode')]: (a, b) => this.stringComparer(a.positionCode, b.positionCode),
    [nameof<ScoreRow>('lastTestTemplateName')]: (a, b) => this.stringComparer(a.lastTestTemplateName, b.lastTestTemplateName),
    [nameof<ScoreRow>('totalScore')]: (a, b) => this.numberComparer(a.totalScore, b.totalScore)
  };

  public async orderAndFilter(orderby: OrderDefinition[], columnFilter: EvaluationDynamicTableColumnFilters) {
    return await this.dispatchAsync(
      this.ORDER_AND_FILTERS_APPLIED,
      this.applyOrderAndFilter(orderby, columnFilter, (this.state.result && this.state.result.item && this.state.result.item.rows) || [])
    );
  }

  private applyOrderAndFilter = (
    orderby: OrderDefinition[],
    columnFilter: EvaluationDynamicTableColumnFilters,
    items: ScoreRow[]
  ): Promise<ScoreRow[]> => {
    return new Promise(resolve => {
      if (items == null || items.length === 0) resolve([]);
      else {
        let filters = getProperties(columnFilter);
        let result: ScoreRow[];
        if (filters.length === 0) result = [...items];
        else {
          result = items.filter((x: ScoreRow) => {
            for (let i = 0; i < filters.length; i++) {
              const { key, value } = filters[i];
              const v: string = (x[key] || '').toString().toLocaleLowerCase();
              let ok = v.includes((value || '').toString().toLocaleLowerCase());
              if (!ok) return false;
            }
            return true;
          });
        }

        if (result.length === 0) resolve([]);
        else if (orderby == null || orderby.length === 0) resolve(result);
        else {
          let comparers = [];
          for (let i = 0; i < orderby.length; i++) {
            const ob = orderby[i];
            const sorter = this.sorter[ob.field] || this.skillComparer(ob.field);
            comparers = [...comparers, this.generalComparer(sorter, ob.direction)];
          }
          result.sort(this.combineComparers(comparers));
          resolve(result);
        }
      }
    });
  };

  private onOrderAndFilter = (): AsyncAction<ScoreRow[], EvaluationDynamicTableStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result: ScoreRow[]) => {
        return {
          ...this.state,
          isBusy: false,
          rows: result,
          minmax: this.calculateMinMaxs(result, this.state.result.item.skillColumns)
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: {
          isSuccess: false,
          item: this.state.result.item,
          messages:
            error == null ? [{ body: 'Something went wrong', level: 'Error' }] : [{ body: String(error.message || error), level: 'Error' }]
        }
      })
    };
  };

  public async updateRow(row: ScoreRow) {
    const validationResult = this.validateRow(row);

    if (validationResult.isInvalid()) {
      this.dispatch(this.ROWS_VALIDATED, validationResult);
    } else {
      const httpService = container.get(HttpService);
      const profileId = this.state.filters.profileId;
      const assessment = this.state.filters.assessment;
      const result = await this.dispatchAsync(
        this.ROW_UPDATED,
        httpService.put<any, ItemResult<ScoreRow>>(`${this.baseUrl}/${this.updatePath}`, { profileId, assessment, ...row })
      );
      return result.data;
    }
  }

  private onRowUpdated = (): AsyncAction<AxiosResponse<ItemResult<ScoreRow>>, EvaluationDynamicTableStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result: AxiosResponse<ItemResult<ScoreRow>>) => {
        const newRow = result.data.item;
        let userIdpredicate: (value: ScoreRow, index: number, obj: ScoreRow[]) => unknown = x => x.userId === newRow.userId;
        if (
          newRow.id &&
          (this.state.result.item.rows.filter(userIdpredicate).length > 1 || this.state.rows.filter(userIdpredicate).length > 1)
        ) {
          userIdpredicate = x => x.id === newRow.id;
        }

        const resultRowIdx = this.state.result.item.rows.findIndex(userIdpredicate);
        const rowsRowIdx = this.state.rows.findIndex(userIdpredicate);
        if (resultRowIdx >= 0) this.state.result.item.rows[resultRowIdx] = { ...newRow };
        if (rowsRowIdx >= 0) this.state.rows[rowsRowIdx] = { ...newRow };

        return {
          ...this.state,
          isBusy: false,
          result: {
            isSuccess: true,
            item: {
              rows: [...this.state.result.item.rows],
              skillColumns: this.state.result.item.skillColumns
            },
            messages: []
          },
          rows: [...this.state.rows],
          minmax: this.calculateMinMaxs([...this.state.rows], this.state.result.item.skillColumns)
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: {
          isSuccess: false,
          item: this.state.result.item,
          messages:
            error && error.response && error.response.data && error.response.data.messages
              ? error.response.data.messages
              : [{ body: error.message || error, level: 'Error' }]
        }
      })
    };
  };

  private calculateMinMaxs = (rows: ScoreRow[], columns: { [key: string]: string }) => {
    const result = {};
    getProperties(columns).forEach(c => {
      result[c.key] = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };
      const column = rows.map(x => ((x.scores && x.scores[c.key]) || { score: null }).score).filter(x => x != null);
      if (column.length !== 0) {
        result[c.key].min = Math.min(...column);
        result[c.key].max = Math.max(...column);
      }
    });
    result[nameof<ScoreRow>('totalScore')] = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };
    const tsColumn = rows.map(x => x.totalScore).filter(x => x != null);
    if (tsColumn.length !== 0) {
      result[nameof<ScoreRow>('totalScore')].min = Math.min(...tsColumn);
      result[nameof<ScoreRow>('totalScore')].max = Math.max(...tsColumn);
    }
    return result;
  };

  public async updateRows(rows: ScoreRow[]) {
    const validationResult = this.validateRows(rows);

    if (validationResult.isInvalid()) {
      this.dispatch(this.ROWS_VALIDATED, validationResult);
    } else {
      const httpService = container.get(HttpService);
      const profileId = this.state.filters.profileId;
      const assessment = this.state.filters.assessment;
      const result = await this.dispatchAsync(
        this.ROWS_UPDATED,
        httpService.put<any, CommandResult<ScoreRow>>(
          `${this.baseUrl}/${this.updateBulkPath}`,
          rows.map(row => ({ profileId, assessment, ...row }))
        )
      );
      return result.data;
    }
  }

  protected onRowsValidated(result: ValidationResult) {
    return {
      ...this.state,
      isBusy: false,
      result: {
        ...this.state.result,
        isSuccess: false,
        messages: result.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as ItemResult<DynamicScoreTable>
    };
  }

  private onRowsUpdated = (): AsyncAction<AxiosResponse<CommandResult<ScoreRow>>, EvaluationDynamicTableStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result: AxiosResponse<CommandResult<ScoreRow>>) => {
        const items = result.data.items;
        for (let i = 0; i < items.length; i++) {
          const newRow = items[i];
          const resultRowIdx = this.state.result.item.rows.findIndex(x => x.userId === newRow.userId);
          const rowsRowIdx = this.state.rows.findIndex(x => x.userId === newRow.userId);
          if (resultRowIdx >= 0) this.state.result.item.rows[resultRowIdx] = { ...newRow };
          if (rowsRowIdx >= 0) this.state.rows[rowsRowIdx] = { ...newRow };
        }

        return {
          ...this.state,
          isBusy: false,
          result: {
            isSuccess: true,
            item: {
              rows: [...this.state.result.item.rows],
              skillColumns: this.state.result.item.skillColumns
            },
            messages: []
          },
          rows: [...this.state.rows],
          minmax: this.calculateMinMaxs([...this.state.rows], this.state.result.item.skillColumns)
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: {
          isSuccess: false,
          item: this.state.result.item,
          messages:
            error && error.response && error.response.data && error.response.data.messages
              ? error.response.data.messages
              : [{ body: error.message || error, level: 'Error' }]
        }
      })
    };
  };
}
