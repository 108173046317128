import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { EventStatus } from 'stores/events/events-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

interface MultipleEventStatusEditorProps extends WithTranslation {
  value: EventStatus[];
  onChange?: (value: EventStatus[]) => void;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  statusFilterActive?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  requestedFromScheduler?: boolean;
}

interface MultipleEventStatusEditorState {
  options: MultipleSelectionDropdownOptions[];
}

class MultipleEventStatusEditor extends React.Component<MultipleEventStatusEditorProps, MultipleEventStatusEditorState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: MultipleEventStatusEditorState = {
    options: this.getAllEventStatus()
  };

  private getAllEventStatus() {
    const { statusFilterActive, requestedFromScheduler } = this.props;
    const currentUserInfo = this.identityService.getUserInfo();
    let map: { id: string; text: string; value: string }[] = [];

    if (!requestedFromScheduler) {
      for (let n in EventStatus) {
        if (typeof EventStatus[n] === 'number') {
          if (statusFilterActive) {
            if (IdentityService.isPoc(currentUserInfo)) {
              if (
                EventStatus[n].toString() === '10' ||
                EventStatus[n].toString() === '20' ||
                EventStatus[n].toString() === '30' ||
                EventStatus[n].toString() === '40'
              ) {
                map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
              }
            } else if (IdentityService.isPlannerMTC(currentUserInfo)) {
              if (EventStatus[n].toString() === '10') {
                map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
              }
            } else {
              if (
                EventStatus[n].toString() === '10' ||
                EventStatus[n].toString() === '20' ||
                EventStatus[n].toString() === '30' ||
                EventStatus[n].toString() === '40'
              ) {
                map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
              }
            }
          } else {
            if (IdentityService.isPlannerMTC(currentUserInfo)) {
              if (
                EventStatus[n].toString() === '50' ||
                EventStatus[n].toString() === '20' ||
                EventStatus[n].toString() === '30' ||
                EventStatus[n].toString() === '40'
              ) {
                map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
              }
            } else {
              if (EventStatus[n].toString() === '50') {
                map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
              }
            }
          }
        }
      }
    }

    if (requestedFromScheduler) {
      for (let n in EventStatus) {
        if (typeof EventStatus[n] === 'number') {
          map.push({ id: EventStatus[n], text: n, value: EventStatus[n] });
        }
      }
    }

    return map.sort((a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
      if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
      return 0;
    });
  }

  private handleOnSelection(value: string[]) {
    const eventInstructorRole: EventStatus[] = value.map(x => EventStatus[x]);
    this.props.onChange && this.props.onChange(eventInstructorRole);
  }

  public render() {
    const { className } = this.props;

    return (
      <MultipleSelectionDropdown
        searchable
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        options={this.state.options.sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
          if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
          return 0;
        })}
        value={(this.props.value || []).map(x => EventStatus[x])}
        onChange={(value: string[]) => this.handleOnSelection(value)}
        className={className ? className : ''}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleEventStatusEditor);
