import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { constants } from 'services/configuration-service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Query, QueryResult, DataStore } from 'stores/dataStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { AxiosResponse } from 'axios';
import { IdentityService } from 'services/identity-service';
import { ItemResult } from 'stores/types';
import { DateTimeService } from 'services/datetime-service';
import { stringify } from 'query-string';

export interface EventChatMessageDto {
  id: string;
  eventId: string;
  timestamp: string;
  fromUserId: string;
  fromUsername: string;
  fromFirstName: string;
  fromLastName: string;
  eventStatus: string;
  message: string;
  channel: string;
}

export interface CreateEventChatMessageDto {
  eventId: string;
  timestamp: string;
  message: string;
  channel: string;
}

export interface EventChatState {
  eventId: string;
  channel: string;
  messages: EventChatMessageDto[];
  isBusy: boolean;
  result: any;
}

@repository('@@EVENTS', 'events.chat')
export class EventChatStore extends ReduxRepository<EventChatState> {
  public DATA_LOADED = 'EVENT_CHAT_DATA_LOADED';
  public NEW_MESSAGE_RECEIVED = 'EVENT_CHAT_NEW_MESSAGE_RECEIVED';
  public MESSAGE_SENT = 'EVENT_CHAT_MESSAGE_SENT';
  public HUB_ERROR = 'EVENT_CHAT_HUB_ERROR';
  public RESET = 'EVENT_CHAT_RESET';

  baseUrl = `${constants.apiBaseUrl}/events/v1/event-chat`;
  addMessagePath = 'send-message';
  retrieveMessagesPath = 'get-messages';

  private signalRConnection: HubConnection;

  constructor() {
    super({
      eventId: null,
      channel: 'Channel1',
      messages: [],
      isBusy: false,
      result: null
    });

    this.loadChat.bind(this);
    this.reset.bind(this);
    this.addMessage.bind(this);

    this.addReducer(this.DATA_LOADED, this.onChatLoaded, 'AsyncAction');
    this.addReducer(this.HUB_ERROR, this.onHubError, 'Simple');
    this.addReducer(this.NEW_MESSAGE_RECEIVED, this.onNewMessageReceived, 'Simple');
    this.addReducer(this.MESSAGE_SENT, this.onMessageSent, 'AsyncAction');
    this.addReducer(this.RESET, this.onReset, 'Simple');
  }

  protected onHubError = (error: any): EventChatState => {
    return { ...this.state, result: error };
  };

  public reset() {
    if (this.signalRConnection != null) {
      this.signalRConnection.stop().catch(err => {
        console.error('HubError', 'manual stop', err);
      });
    }
    this.dispatch(this.RESET);
  }

  protected onReset = () => {
    return {
      eventId: null,
      channel: 'Channel1',
      messages: [],
      isBusy: false,
      result: null
    };
  };

  public async loadChat(eventId: string, channel: string, connectToSignalR: boolean = true, filters: any[] = []) {
    const finalFilter = [...(filters || []), { EventId: { eq: { type: 'guid', value: eventId } } }];
    const query = {
      searchQuery: '',
      orderBy: [{ direction: 'Ascending', field: 'timestamp', useProfile: false }],
      skip: 0,
      take: 1000000,
      filter: finalFilter
    } as Query;

    const httpService = container.get<HttpService>(HttpService);
    const identityService = container.get<IdentityService>(IdentityService);

    const result = await this.dispatchAsync(
      this.DATA_LOADED,
      httpService.get<QueryResult<EventChatMessageDto>>(
        `${this.baseUrl}/${eventId}/${channel}/${this.retrieveMessagesPath}?${DataStore.buildUrl(query)}`
      ),
      eventId,
      channel,
      connectToSignalR,
      identityService.activeRole
    );
    return result.data;
  }

  protected onChatLoaded = (): AsyncAction<AxiosResponse<QueryResult<EventChatMessageDto>>, EventChatState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result, eventId, channel, connectToSignalR, activeRole) => {
        if (this.signalRConnection != null) {
          this.signalRConnection.stop().catch(err => {
            console.error('HubError', 'stop', err);
          });
        }

        if (connectToSignalR) {
          const params = { webrole: activeRole };
          this.signalRConnection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}/${eventId}/${channel}?${stringify(params)}`, {
              accessTokenFactory: () => container.get<IdentityService>(IdentityService).accessToken
            })
            .build();

          this.signalRConnection.start().catch(err => {
            console.error('HubError', 'start', err);
            this.dispatch(this.HUB_ERROR, err);
          });

          this.signalRConnection.onclose(err => {
            if (err != null) {
              console.error('HubError', 'onclose', err);
              this.signalRConnection.start();
            }
          });

          this.signalRConnection.on('newMessage', this.newMessageReceived);
        }

        return {
          ...this.state,
          isBusy: false,
          messages: result.data.items,
          eventId,
          channel,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  protected newMessageReceived = (message: EventChatMessageDto) => {
    const { eventId } = this.state;
    if (message == null || message.eventId !== eventId) return;

    this.dispatch(this.NEW_MESSAGE_RECEIVED, message);
  };

  protected onNewMessageReceived = (message: EventChatMessageDto): EventChatState => {
    const { eventId } = this.state;
    const messages = (this.state.messages || []).filter(m => m.eventId === eventId && m.id !== message.id);
    messages.push(message);
    messages.sort(this.compareByDateTime);
    return {
      ...this.state,
      messages
    };
  };

  public async addMessage(dto: CreateEventChatMessageDto) {
    const eventId = dto.eventId;
    const channel = dto.channel;
    const httpService = container.get<HttpService>(HttpService);
    const result = await this.dispatchAsync(
      this.MESSAGE_SENT,
      httpService.post<CreateEventChatMessageDto, ItemResult<EventChatMessageDto>>(
        `${this.baseUrl}/${eventId}/${channel}/${this.addMessagePath}`,
        dto
      )
    );
    return result.data;
  }

  protected onMessageSent = (): AsyncAction<AxiosResponse<ItemResult<EventChatMessageDto>>, EventChatState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: result => {
        const message = result.data.item;
        const messages = (this.state.messages || []).filter(m => m.id !== message.id);
        messages.push(message);
        messages.sort(this.compareByDateTime);

        return {
          ...this.state,
          messages,
          isBusy: false,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  private compareByDateTime = (x: EventChatMessageDto, y: EventChatMessageDto): number => {
    const d1 = DateTimeService.toMoment(x.timestamp);
    const d2 = DateTimeService.toMoment(y.timestamp);
    if (d1.isSame(d2)) {
      return 0;
    }
    return d1.isBefore(d2) ? -1 : 1;
  };
}
