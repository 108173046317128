import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { TableModel, TableView } from 'widgets/collections/table';
import { TnaSkillsStore } from 'stores/assessments/skills/tna-skills-store';
import './assigment-employees-profile.less';
import LocationEditor from 'widgets/bussiness/location-editor';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { ItemReference } from 'stores/dataStore';

import { UserProfilesStore, UserProfilesDto } from 'stores/profile/user-profile-store';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { AssignmentMode } from '../profile-assigment-home';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { openInNewWindow } from 'utils/useful-functions';

export interface UserProfilesAssigmentProps extends WithTranslation, RouteComponentProps {
  userProfilesAssigmentStore: UserProfilesStore;
  tnaSkillsStore: TnaSkillsStore;
  onEditProfile: (mode: AssignmentMode, data: any) => void;
}

interface EmployeeWithProfileFilters {
  employee: string;
  locationId: string;
  machineModelName: string;
  profileId: string;
  positionCodeId: string;
  sFPosition: string;
}

export interface UserProfilesAssigmentState {
  query: Query;
  newUserProfileShown: boolean;
  changeUserProfileShown: boolean;
  selectedItem: any;
  isclone: boolean;
  cloneData: UserProfilesDto;
  location: ItemReference;
  filters: EmployeeWithProfileFilters;
  someFilterOpened: boolean;
}

@connect(['userProfilesAssigmentStore', UserProfilesStore])
class AssigmentEmployeesWithProfile extends React.Component<UserProfilesAssigmentProps, UserProfilesAssigmentState> {
  timer: any = null;
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [], filter: [], skip: 0, take: 10 },
      newUserProfileShown: false,
      changeUserProfileShown: false,
      filters: {
        employee: undefined,
        locationId: undefined,
        machineModelName: undefined,
        profileId: undefined,
        positionCodeId: undefined,
        sFPosition: undefined
      },
      selectedItem: null,
      cloneData: null,
      isclone: false,
      location: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    this.props.userProfilesAssigmentStore.getAlltUserProfile(
      this.state.query,
      this.state.filters.employee,
      this.state.filters.locationId,
      this.state.filters.machineModelName,
      this.state.filters.positionCodeId,
      this.state.filters.sFPosition,
      null
    );
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: UserProfilesDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.userProfilesAssigmentStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: UserProfilesDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.userProfilesAssigmentStore.deleteAsync(item.userId, state).then(res => {
        this.load();
      });
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, () => this.load());
  }

  @autobind
  private onEmployeeNameFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.employee = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private onSFPositionFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.sFPosition = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private onMachineModelFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.machineModelName = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private handleFilterByLocation(location: ItemReference) {
    let filters = { ...this.state.filters };
    filters.locationId = location ? location.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByPositionCode(positionCode: PositionCodeDto) {
    let filters = { ...this.state.filters };
    filters.positionCodeId = positionCode ? positionCode.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByProfile(profile: ProfileItemDto) {
    let filters = { ...this.state.filters };
    filters.profileId = profile ? profile.id : null;
    this.refreshTable(filters);
  }

  private refreshTable(filters: EmployeeWithProfileFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters: EmployeeWithProfileFilters) {
    let oDataFilter = [];

    if (filters.profileId) {
      oDataFilter.push({ Profiles: { any: { ProfileId: { eq: { type: 'guid', value: filters.profileId } } } } });
    }

    return oDataFilter;
  }

  @autobind
  private onEditItem = (item: UserProfilesDto) => {
    this.props.onEditProfile('edit', [item]);
  };

  private onEmployeeCardClicked(id: string) {
    openInNewWindow(`./employee-page/${id}`);
  }

  @autobind
  private handleOnEnterKeydown(item: UserProfilesDto) {
    this.onEmployeeCardClicked(item.userId);
  }

  public render() {
    const { t } = this.props as any;
    const { someFilterOpened } = this.state;
    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const tableModel = {
      columns: [
        {
          title: t('User Name'),
          tooltipRenderer: true,
          renderer: data => data.userName,
          selectableHeader: true
        },
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => <div>{data.lastName}</div>,
          selectableHeader: true
        },
        {
          title: t('First Name'),

          tooltipRenderer: true,
          renderer: data => data.firstName,
          selectableHeader: true
        },
        {
          title: t('SAP ID'),
          tooltipRenderer: true,
          renderer: data => data.employeeId
        },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data => data.locationName
        },
        {
          title: t('Position Code'),
          tooltipRenderer: true,
          renderer: data => data.positionCodeName
        },
        {
          title: t('SF Position'),
          tooltipRenderer: true,
          renderer: data => data.sfPosition
        },
        {
          title: t('Profile'),
          tooltipRenderer: true,
          renderer: data =>
            data.profiles.map(profile => (
              <>{<div className={profile.profileIsActive ? '' : 'assigment-profile__label__inactive'}>{profile.profileName + '\n'}</div>}</>
            ))
        },
        {
          title: t('User Status'),
          tooltipRenderer: false,
          renderer: data => <span className="question-bank__cell__tag">{data.enabled ? 'Active' : 'Inactive'}</span>,
          selectableHeader: true
        }
      ],
      data: this.props.userProfilesAssigmentStore.state
    } as TableModel<UserProfilesDto>;

    return (
      <div className="assigment-employees-profiles__wrapper">
        <Grid className="event-types-list-grid">
          {this.props.userProfilesAssigmentStore.state.result && !this.props.userProfilesAssigmentStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.userProfilesAssigmentStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          {
            <div className="assigment-employees-profiles__filters">
              <Input
                clearable
                className="custom-editor-search"
                icon="search"
                placeholder={t('Search Employee')}
                onChange={this.onEmployeeNameFilterChanged}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <LocationEditor
                clearable
                className="custom-editor"
                placeholder={t('Location')}
                nullable
                value={null}
                locationsReceived={pocLocations}
                onChange={location => this.handleFilterByLocation(location)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <PositionCodeEditor
                clearable
                placeholder={t('Position Code')}
                onChange={positionCode => this.handleFilterByPositionCode(positionCode)}
                value={null}
                nullable
                className="custom-editor"
                inline={false}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <Input
                clearable
                className="custom-editor-search"
                icon="search"
                placeholder={t('Search SF Position')}
                onChange={this.onSFPositionFilterChanged}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <ProfileEditor
                clearable
                placeholder={t('Profile')}
                value={null}
                nullable
                className="custom-editor"
                onChange={profile => this.handleFilterByProfile(profile)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <Input
                clearable
                className="custom-editor-search"
                icon="search"
                placeholder={t('Search Machine Model')}
                onChange={this.onMachineModelFilterChanged}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </div>
          }
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={true}
              maxSelection={1}
              //onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit Profile')}</span>}
                    </>
                  ),
                  onClick: (item: UserProfilesDto) => this.onEditItem(item)
                },
                {
                  content: (
                    <>
                      <Icon name="eye" />
                      {<span className="text__bold">{t('Open Employee Card')}</span>}
                    </>
                  ),
                  onClick: (item: UserProfilesDto) => this.onEmployeeCardClicked(item.userId)
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              canCreateNew={true}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              deleteLabel="Remove Profile"
              deleteConfirmationMessage={t("Are you sure you want to unassign this user's profiles?")}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              isRowDisableLayout={item => !(item as any).enabled}
              areActionsDisabled={item => !(item as any).enabled}
              hiddeMenu
            ></TableView>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(AssigmentEmployeesWithProfile);
