import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import { CreateVisaDto, NewVisaStore } from 'stores/configuration/planner-assistant/visas-store';
import { nameof } from 'utils/object';

interface NewVisaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newVisa?: NewVisaStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewVisaViewState {
  userClickedSaved: boolean;
}

@connect(['newVisa', NewVisaStore])
class NewVisaView extends React.Component<NewVisaViewProps, NewVisaViewState> {
  private get newVisaStore() {
    return this.props.newVisa;
  }

  constructor(props: NewVisaViewProps) {
    super(props);
    this.newVisaStore.createNew({
      name: '',
      internationalTreatyRequired: false
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewVisaViewProps) {
    const { result } = this.newVisaStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newVisaStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newVisaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const { item } = this.newVisaStore.state;
    const change = {};
    change[property] = value;
    this.newVisaStore.change({ ...item, ...change });
  }

  private onVisaChange = (e, { value }) => {
    this.handleValue(nameof<CreateVisaDto>('name'), value);
  };

  public render() {
    const { t } = this.props;
    const { result, item } = this.newVisaStore.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newVisaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Visa')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required error={!item.name && this.state.userClickedSaved}>
                    <label>{t('Visa')}</label>
                    <Input
                      placeholder={t('Visa')}
                      value={item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateVisaDto>('name'), value)}
                    />
                  </Form.Field>
                  {/* <Form.Field required error={!item.isoCode && this.state.userClickedSaved}>
                    <label>{t('Code')}</label>
                    <Input
                      placeholder={t('Code')}
                      value={item.isoCode}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateVisaDto>('isoCode'), value)}
                    />
                  </Form.Field> */}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewVisaView);
