import { injectable } from 'inversify';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';

const moment = extendMoment(Moment);

export type MomentUnit = Moment.unitOfTime.DurationConstructor;

@injectable()
export class DateTimeService {
  static dateInputFormat: string = 'DD/MM/YYYY';
  static chatFormat: string = 'dddd, Do MMMM YYYY, h:mm A';
  static notificationFormat: Moment.CalendarSpec = {
    sameDay: '[Today] HH:mm',
    lastDay: '[Yesterday] HH:mm',
    sameElse: 'DD/MM/YYYY HH:mm'
  };
  static toCalendarTooltip: string = 'MMM DD';
  static toSchedulerMiddleHeader: string = 'DD/MM';
  static toSchedulerRequestList: string = 'dddd Do';
  static simpleDateInputRegex = /[^0-9/]/g;
  static dateRangeInputRegex = /[^0-9/-]/g;
  static minCoherentYear = 1900;
  static strictISOFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

  static toDateInputString(value: string, format: string = this.dateInputFormat): string {
    if (!value) return '';
    return Moment.utc(value).format(format);
  }

  static toDateTimeCleanExtended(value: string | Date | Moment.Moment, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return this.toMoment(value).format(format);
  }

  static toCalendarString(value: string | Date | Moment.Moment): string {
    return this.toMoment(value).format(this.toCalendarTooltip);
  }

  static toSchedulerRequestItem(value: string | Date | Moment.Moment): string {
    return this.toMoment(value).format(this.toSchedulerRequestList);
  }

  static toSchedulerPresetView(value: string | Date | Moment.Moment): string {
    return this.toMoment(value).format(this.toSchedulerMiddleHeader);
  }

  static toSchedulerFromString(value: string | Date | Moment.Moment): string {
    return this.toMoment(value, true)
      .startOf('day')
      .toString();
  }

  static toSchedulerToString(value: string | Date | Moment.Moment): string {
    return this.toMoment(value, true)
      .endOf('day')
      .toString();
  }

  static toSchedulerTooltip(value: string | Date | Moment.Moment, format: string = 'MMM DD'): string {
    return this.toMoment(value).format(format);
  }

  static toNotificationString(value: string | Date | Moment.Moment): string {
    return this.toMoment(value, true).calendar(null, this.notificationFormat);
  }

  static toChatString(value: string | Date | Moment.Moment): string {
    return this.toMoment(value, true).format(this.chatFormat);
  }

  static toString(
    value: string | Date | Moment.Moment,
    fromFormat: string = null,
    momentOfDay?: 'startOfDay' | 'endOfDay',
    locale: boolean = false
  ) {
    const momentObj = locale ? moment(value, fromFormat) : Moment.utc(value, fromFormat);
    if (momentOfDay === 'startOfDay') momentObj.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    else if (momentOfDay === 'endOfDay') momentObj.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    return momentObj.toISOString();
  }

  static now(locale: boolean = false): Moment.Moment {
    return locale ? moment() : Moment.utc();
  }
  static today(locale: boolean = false): Moment.Moment {
    return locale ? moment().startOf('day') : Moment.utc().startOf('day');
  }

  static toMoment(value: string | Date | Moment.Moment, toLocalDate: boolean = false): Moment.Moment {
    if (Moment.isMoment(value)) return value;
    return toLocalDate ? moment(value) : Moment.utc(value);
  }

  static isGreaterThan(value: string | Date | Moment.Moment, valueToCompare: string | Date | Moment.Moment) {
    return moment(value).isAfter(valueToCompare, 'day');
  }

  static dateRangeOverlaps(range1: DateRange, range2: DateRange) {
    return range1.overlaps(range2);
  }

  static toDateRange(start: string | Date | Moment.Moment, end: string | Date | Moment.Moment) {
    let range = moment.range(this.toMoment(start), this.toMoment(end));
    return range;
  }

  static isLocaleValid(value: string | Date | Moment.Moment, fromFormat: string = 'D/M/YYYY', strictParse: boolean = true): boolean {
    return moment(value, fromFormat, strictParse).isValid();
  }
  static localeMoment(value: string | Date | Moment.Moment, fromFormat: string = 'D/M/YYYY', strictParse: boolean = true): Moment.Moment {
    return moment(value, fromFormat, strictParse);
  }
  static isDefaultValid(value: string | Date | Moment.Moment, strictParse: boolean = true): boolean {
    return moment(value, this.dateInputFormat, strictParse).isValid();
  }
  static defaultMoment(value: string | Date | Moment.Moment, strictParse: boolean = true): Moment.Moment {
    return moment(value, this.dateInputFormat, strictParse);
  }
  static isNoDashValid(value: string | Date | Moment.Moment, fromFormat: string = 'DDMMYYYY', strictParse: boolean = true): boolean {
    return moment(value, fromFormat, strictParse).isValid();
  }
  static noDashMoment(value: string | Date | Moment.Moment, fromFormat: string = 'DDMMYYYY', strictParse: boolean = true): Moment.Moment {
    return moment(value, fromFormat, strictParse);
  }
  static isIsoValid(value: string | Date | Moment.Moment, strictParse: boolean = true): boolean {
    let replaced = this.replaceToMomentIsoFormat(value);
    return moment(value, this.strictISOFormat, strictParse).isValid() || moment(replaced, this.strictISOFormat, strictParse).isValid();
  }
  static isoMoment(value: string | Date | Moment.Moment, strictParse: boolean = true): Moment.Moment {
    let replaced = this.replaceToMomentIsoFormat(value);
    return moment(replaced, this.strictISOFormat, strictParse);
  }
  static replaceToMomentIsoFormat(value: string | Date | Moment.Moment) {
    if (!value) return '';
    return value.toString().replace(/\+\d\d:\d\d/g, '.000Z');
  }
  static isValid(value: string | Date | Moment.Moment, fromFormat: string = 'D/M/YYYY', strictParse: boolean = true): boolean {
    const localeValid = this.isLocaleValid(value, fromFormat, strictParse);
    const defaultValid = this.isDefaultValid(value, strictParse);
    const noDashValid = this.isNoDashValid(value);
    const isoValid = this.isIsoValid(value);
    const valid = defaultValid || noDashValid || localeValid || isoValid;
    return valid;
  }

  static isCoherent(value: string | Date | Moment.Moment, fromFormat?: string, strictParse?: boolean): boolean {
    let coherent =
      this.isValid(value) &&
      ((this.isLocaleValid(value, fromFormat) && this.localeMoment(value, fromFormat).year() > this.minCoherentYear) ||
        (this.isDefaultValid(value) && this.defaultMoment(value, strictParse).year() > this.minCoherentYear) ||
        (this.isNoDashValid(value) && this.noDashMoment(value, fromFormat, strictParse).year() > this.minCoherentYear) ||
        (this.isIsoValid(value) && this.isoMoment(value).year() > this.minCoherentYear));
    return coherent;
  }
}
