import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface RejectedByDto extends BaseDto {
  id: string;
  name: string;
}
export interface CreateRejectedByDto {
  name: string;
}

export interface ChangeRejectedByDto {
  id: string;
  name: string;
}

export class CreateRejectedByValidator extends AbstractValidator<CreateRejectedByDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Rejected By Name is required'));
  }
}

export class ChangeRejectedByValidator extends AbstractValidator<ChangeRejectedByDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Rejected By Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Rejected By Name is required'));
  }
}

@repository('@@REJECTED-BY', 'rejected-by.summary')
export class RejectedByStore extends DataStore<RejectedByDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejected-by';
  retrievePath = 'get-rejecteds-by';
  updatePath = 'update-rejected-by';
  deletePath = 'delete-rejected-by';

  protected validate(item: RejectedByDto) {
    return new ChangeRejectedByValidator().validate(item);
  }

  constructor() {
    super('REJECTED-BY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@REJECTED-BY', 'rejected-by.new')
export class NewRejectedByStore extends FormStore<CreateRejectedByDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejected-by';
  retrievePath = 'get-rejecteds-by';
  updatePath = 'update-rejected-by';

  protected validate(item: CreateRejectedByDto) {
    return new CreateRejectedByValidator().validate(item);
  }

  constructor() {
    super('NEW_REJECTED-BY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@REJECTED-BY', 'rejected-by.change')
export class ChangeRejectedByStore extends FormStore<ChangeRejectedByDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-rejected-by';
  retrievePath = 'get-rejecteds-by';
  updatePath = 'update-rejected-by';

  protected validate(item: ChangeRejectedByDto) {
    return new ChangeRejectedByValidator().validate(item);
  }

  constructor() {
    super('CHANGE_REJECTED-BY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
