import React, { Component } from 'react';
import SsaFormWizard from './ssa-form-wizard';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormWizardStore } from 'stores/assessments/wizard/ssa-form-wizard-store';
import { resolve } from 'inversify-react';
import { IdentityService, Roles } from 'services/identity-service';
import { RouteComponentProps } from 'react-router';

export interface SsaFormCreationWizardProps extends WithTranslation, RouteComponentProps {
  ssaFormWizardStore?: SsaFormWizardStore;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
class SsaFormCreationWizard extends Component<SsaFormCreationWizardProps> {
  @resolve(IdentityService)
  identityService: IdentityService;

  constructor(props: Readonly<SsaFormCreationWizardProps>) {
    super(props);

    this.props.ssaFormWizardStore.change({
      profileId: null,
      template: null,
      title: '',
      location: null,
      deadline: null,
      assessors: {},
      employees: {},
      employeesAssessors: {}
    });
  }

  componentDidMount() {
    let location =
      this.identityService.isInRole(Roles.PoC) && this.identityService.getUserInfo().locationsByRoles['PoC'].length === 1
        ? { id: this.identityService.getUserInfo().locationsByRoles['PoC'][0], title: '' }
        : null;

    this.props.ssaFormWizardStore.change({
      location: location
    });
  }

  handleOnSubmit = () => {
    this.props.ssaFormWizardStore.createSsaForm().then(result => {
      if (result && result.isSuccess) this.props.history.push({ pathname: '/assessments/ssa?tab=3' });
      else this.scrollToTop();
    });
  };

  private scrollToTop() {
    document.getElementById('root').scrollTop = 0;
  }

  render() {
    return <SsaFormWizard {...this.props} onSubmit={this.handleOnSubmit} />;
  }
}

export default withTranslation()(SsaFormCreationWizard);
