import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../utils/object';
import { Dropdown, DropdownProps, StrictDropdownProps } from 'semantic-ui-react';
import { OrderDefinition } from 'stores/dataStore';
import { RejectReasonDto, RejectReasonsStore } from 'stores/configuration/events-n-requests/rejection-reasons-store';

interface RejectReasonComponentProps extends WithTranslation, StrictDropdownProps {
  value: string;
  onChange: (value: any) => void;
  placeholder?: string;
  clearable?: boolean;
  rejectReasonsStore?: RejectReasonsStore;
  readOnly?: boolean;
  className?: string;
}

interface RejectReasonComponentState {
  rejectReasons: { text: string; value: string }[];
}

@connect(['rejectReasonsStore', RejectReasonsStore])
class RejectReasonComponent extends Component<RejectReasonComponentProps, RejectReasonComponentState> {
  private get rejectReasonsStore() {
    return this.props.rejectReasonsStore;
  }

  state: RejectReasonComponentState = { rejectReasons: [] };

  componentDidMount() {
    this.loadRejectReasonsStore();
  }

  private loadRejectReasonsStore = async () => {
    const filter = [];

    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<RejectReasonDto>('name'), useProfile: false }];

    return await this.rejectReasonsStore
      .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter })
      .then(rejectReasonsStore => {
        const options = [];
        rejectReasonsStore.items.forEach(rejectReason => options.push({ text: rejectReason.name, value: rejectReason.id }));
        this.setState({ rejectReasons: options });
      });
  };

  private handleOnChange = (_, { value }: DropdownProps) => {
    this.props.onChange(value as string);
  };

  public render() {
    const { rejectReasons } = this.state;
    const { t, readOnly, error, className, loading, value, placeholder, rejectReasonsStore } = this.props;
    return (
      <Dropdown
        search
        selection
        closeOnChange
        loading={loading || rejectReasonsStore.state.isBusy}
        closeOnEscape
        selectOnBlur={false}
        clearable
        options={rejectReasons}
        error={error}
        className={className}
        value={value ?? ''}
        onChange={this.handleOnChange}
        disabled={readOnly}
        placeholder={placeholder || t('Select Reject Reason')}
      />
    );
  }
}

export default withTranslation()(RejectReasonComponent);
