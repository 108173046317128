import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dropdown, Dimmer, Loader, Message, Icon } from 'semantic-ui-react';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import LanguageEditor from 'widgets/bussiness/language-editor';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';
import {
  NewTnaQuestionBankStore,
  CreateTnaQuestionBankDto,
  TnaQuestionBankDto,
  CreateQuestionDto,
  QuestionDto
} from 'stores/assessments/questionBank/tna-questionBank-store';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { Query, ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import i18n from 'i18n';
import OemEditor from 'widgets/bussiness/oem-editor';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';

interface NewTnaQuestionBankViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newQuestionBank?: NewTnaQuestionBankStore;
  languagesStore?: DropdownLanguagesStore;
  isClone?: boolean;
  cloneData: TnaQuestionBankDto;
}

interface NewTnaQuestionBankState {
  category: ItemReference;
  skill: ItemReference;
  userClickedSave: boolean;
  questionTranslations: CreateQuestionDto[];
  defaultLanguage: LanguageDto;
  query: Query;
}

@connect(['newQuestionBank', NewTnaQuestionBankStore], ['languagesStore', DropdownLanguagesStore])
class NewTnaQuestionBankView extends React.Component<NewTnaQuestionBankViewProps, NewTnaQuestionBankState> {
  private get newQuestionBankStore() {
    return this.props.newQuestionBank;
  }
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: NewTnaQuestionBankViewProps) {
    super(props);
    let query: Query = {
      searchQuery: '',
      orderBy: [],
      skip: 0,
      take: 1000,
      filter: [{ or: [{ LanguageCode: 'EN-en' }, { Language: 'English' }] }]
    };

    this.newQuestionBankStore.createNew({
      question:
        this.props.isClone && this.props.cloneData
          ? { text: this.props.cloneData.question.text, languageId: this.props.cloneData.question.language.id }
          : null,
      testCategoryId: this.props.isClone && this.props.cloneData ? this.props.cloneData.testCategoryId : '',
      clusterId:
        this.props.isClone && this.props.cloneData
          ? this.props.cloneData.equipmentType
            ? this.props.cloneData.equipmentType.clusterId
            : null
          : null,
      professions: this.props.isClone && this.props.cloneData ? this.props.cloneData.professions.map(p => p.id) : [],
      equipmentTypeId: this.props.isClone && this.props.cloneData ? this.props.cloneData.equipmentTypeId : null,
      oemId: this.props.isClone && this.props.cloneData ? this.props.cloneData.oemId : null,
      tnaSkillId: this.props.isClone && this.props.cloneData && this.props.cloneData.tnaSkillId ? this.props.cloneData.tnaSkillId : '',
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : [],
      isMachineRelated: this.props.isClone && this.props.cloneData ? this.props.cloneData.isMachineRelated : null,
      locationId: this.props.isClone && this.props.cloneData ? this.props.cloneData.locationId : null,
      locationRequired: false
    });
    this.state = {
      query,
      category:
        this.props.isClone && this.props.cloneData
          ? Object.assign({ id: this.props.cloneData.testCategoryId, title: this.props.cloneData.testCategory.name })
          : null,
      skill:
        this.props.isClone && this.props.cloneData && this.props.cloneData.tnaSkillId
          ? Object.assign({ id: this.props.cloneData.tnaSkillId, title: this.props.cloneData.tnaSkill.name })
          : null,
      userClickedSave: false,
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : [],
      defaultLanguage: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewTnaQuestionBankViewProps) {
    if (this.newQuestionBankStore.state.result && this.newQuestionBankStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  componentDidMount() {
    this.loadDefaulLanguage();
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    if (areYouPoc || (this.props.isClone && this.props.cloneData && this.props.cloneData.locationId)) {
      const change = {};
      change['locationRequired'] = true;
      this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
    }
  }

  private loadDefaulLanguage() {
    this.props.languagesStore.getAllAsync(this.state.query).then(e => {
      var defLanguage = e.items.firstOrDefault(x => x.languageCode === 'EN-en' || x.language === 'English');
      this.setState({ defaultLanguage: defLanguage ? defLanguage : e.items[0] });
    });
  }

  private handleValue = (property: keyof CreateTnaQuestionBankDto, value: any) => {
    this.setState({ userClickedSave: false });
    const change = {};
    if (property === nameof<CreateTnaQuestionBankDto>('tnaSkillId') && value != null) {
      if (!isNullOrWhiteSpaces(value.id)) {
        change['tnaSkillId'] = value.id;
        this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
        if (this.newQuestionBankStore.state.item.isMachineRelated !== value.isMachineRelated)
          this.onChangeIsMachineRelated(!!value.isMachineRelated);
      } else {
        change[property] = null;
        this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
      }
    } else {
      change[property] = value;
      this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
    }
  };

  private mapToCreateQuestionDto(items: QuestionDto[]): CreateQuestionDto[] {
    let data: CreateQuestionDto[] = [];
    items.forEach(i => {
      data.push({ text: i.text, languageId: i.language.id });
    });
    return data;
  }

  private onChangeQuestion = (q: string) => {
    let question = { ...this.newQuestionBankStore.state.item.question };
    question = { text: q, languageId: this.state.defaultLanguage ? this.state.defaultLanguage.id : null };

    this.handleValue('question', question);
  };

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newQuestionBankStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newQuestionBankStore.clear();
    this.props.onClose(false);
  }

  addQuestionsTranslation = () => {
    let question = this.state.questionTranslations;
    question.push({ text: '', languageId: null });

    this.setState({ questionTranslations: question });
  };

  handleQuestionTransaltionTextChange = (index: number, questionText: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.text = questionText;

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateTnaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  handleQuestionTransaltionLanguageChange = (index: number, languageId: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.languageId = languageId;
    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateTnaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  removeDateRange = index => {
    let questionTranslations = this.state.questionTranslations;
    questionTranslations.splice(index, 1);

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateTnaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  showQuestionTranslationRows = () => {
    return this.state.questionTranslations.map((question, index) => {
      return (
        <Form.Group className="tna-question-bank__new-rows" key={`${question.languageId}_${index}`}>
          <Form.Field
            required
            className="tna-question-bank__label-textarea"
            error={this.state.userClickedSave && question.text.length === 0}
          >
            <TextArea
              maxLength="500"
              className="tna-question-bank__new-rows__textarea"
              value={question ? question.text : ''}
              onChange={(e, { value }) => this.handleQuestionTransaltionTextChange(index, value.toString())}
            />
          </Form.Field>
          <div className="tna-question-bank__new-rows__drop-remove">
            <LanguageEditor
              className={'planit-user-dropdown-custom'}
              nullable
              value={question.languageId}
              onChange={c => {
                this.handleQuestionTransaltionLanguageChange(index, c == null ? null : c.id);
              }}
              onlyForTests={true}
            />

            <div className="new-pattern__remove-icon">
              <Icon className="clickable-icon remove-icon" color="red" name="remove" onClick={() => this.removeDateRange(index)} />
            </div>
          </div>
        </Form.Group>
      );
    });
  };

  private onChangeIsMachineRelated(value: boolean) {
    this.setState({ userClickedSave: false });
    const change = { isMachineRelated: value };
    if (!value) {
      change['clusterId'] = null;
      change['equipmentTypeId'] = null;
      change['oemId'] = null;
    }
    this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
  }

  private onChangeCluster(value: ItemReference) {
    this.setState({ userClickedSave: false });
    const change = { clusterId: value ? value.id : null };
    if (value == null) {
      change['equipmentTypeId'] = null;
      change['oemId'] = null;
    }
    this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
  }

  private onChangeEquipmentTypeChange(value: ItemReference) {
    this.setState({ userClickedSave: false });
    const change = { equipmentTypeId: value ? value.id : null };
    if (value == null) {
      change['equipmentTypeId'] = null;
    }
    this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props;
    const isMaChineRelatedOptions = [
      { key: 1, text: t('Yes'), value: true },
      { key: 2, text: t('No'), value: false }
    ];
    const modalTitle = this.props.isClone ? 'Clone TNA Question' : 'New TNA Question';

    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const adminOrPowerInst = IdentityService.isAdminOrPowerInstructor(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    return (
      <Modal className="tna-question-bank__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newQuestionBankStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-squestion-bank__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newQuestionBankStore.state.result && !this.newQuestionBankStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newQuestionBankStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newQuestionBankStore.state.item && (
              <Form>
                <div className="tna-question-bank__wrapper scroll">
                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field className="tna-question-bank__label-input">
                      <label>{t('TNA Skill')}</label>
                      <SkillsEditor
                        nullable
                        skillType={SkillType.TNA}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select TNA Skill')}
                        value={this.state.skill}
                        onChange={skill => this.handleValue(nameof<CreateTnaQuestionBankDto>('tnaSkillId'), skill)}
                        clearable
                      />
                    </Form.Field>

                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        (this.newQuestionBankStore.state.item.testCategoryId == null ||
                          isNullOrWhiteSpaces(this.newQuestionBankStore.state.item.testCategoryId)) &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Category')}</label>
                      <TestCategorySelector
                        nullable
                        isTnaCategory={true}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Category')}
                        value={this.state.category}
                        onChange={category => this.handleValue(nameof<CreateTnaQuestionBankDto>('testCategoryId'), category?.id)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        (this.newQuestionBankStore.state.item.professions == null ||
                          this.newQuestionBankStore.state.item.professions.length === 0) &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Roles')}</label>
                      <MultiProfessionEditor
                        className="planit-user-dropdown"
                        placeholder={t('Roles')}
                        value={this.props.cloneData ? this.props.cloneData.professions.map(p => p.id) : []}
                        onChange={value => this.handleValue(nameof<CreateTnaQuestionBankDto>('professions'), value)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={this.state.userClickedSave && this.newQuestionBankStore.state.item.isMachineRelated == null}
                    >
                      <label>{t('Machine Related')}</label>
                      <Dropdown
                        selection
                        value={this.newQuestionBankStore.state.item.isMachineRelated}
                        multiple={false}
                        className="planit-user-dropdown-custom normal-look-readonly-select"
                        placeholder={t('Select a skill to fill')}
                        options={isMaChineRelatedOptions}
                        onChange={(e, { value }) => this.onChangeIsMachineRelated(!!value)}
                        disabled={!isNullOrWhiteSpaces(this.newQuestionBankStore.state.item.tnaSkillId)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        this.newQuestionBankStore.state.item.isMachineRelated &&
                        !this.newQuestionBankStore.state.item.clusterId &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Cluster')}</label>
                      <MRClusterEditor
                        placeholder={t('Cluster')}
                        className="planit-user-dropdown-custom"
                        nullable
                        clearable
                        readOnly={!this.newQuestionBankStore.state.item.isMachineRelated}
                        value={this.newQuestionBankStore.state.item.clusterId}
                        onChange={c => this.onChangeCluster(c)}
                      />
                    </Form.Field>

                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        this.newQuestionBankStore.state.item.isMachineRelated &&
                        !this.newQuestionBankStore.state.item.equipmentTypeId &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        placeholder={t('Equipment Type')}
                        className="planit-user-dropdown-custom"
                        nullable
                        readOnly={!this.newQuestionBankStore.state.item.isMachineRelated || !this.newQuestionBankStore.state.item.clusterId}
                        clearable
                        clusterId={this.newQuestionBankStore.state.item.clusterId}
                        value={this.newQuestionBankStore.state.item.equipmentTypeId}
                        onChange={c => this.onChangeEquipmentTypeChange(c)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        this.newQuestionBankStore.state.item.isMachineRelated &&
                        !this.newQuestionBankStore.state.item.oemId &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('OEM')}</label>
                      <OemEditor
                        placeholder={t('OEM')}
                        className="planit-user-dropdown-custom"
                        nullable
                        clearable
                        equipmentId={this.newQuestionBankStore.state.item.equipmentTypeId}
                        readonly={
                          !this.newQuestionBankStore.state.item.isMachineRelated ||
                          !this.newQuestionBankStore.state.item.clusterId ||
                          !this.newQuestionBankStore.state.item.equipmentTypeId
                        }
                        value={this.newQuestionBankStore.state.item.oemId}
                        onChange={c => this.handleValue(nameof<CreateTnaQuestionBankDto>('oemId'), c ? c.id : null)}
                      />
                    </Form.Field>
                    {(areYouPoc || (adminOrPowerInst && this.props.isClone && this.props.cloneData && this.props.cloneData.locationId)) && (
                      <Form.Field
                        key={'location-editor-field-new-tna'}
                        className="tna-question-bank__label-input"
                        required
                        error={this.newQuestionBankStore.state.result && !this.newQuestionBankStore.state.item.locationId}
                      >
                        <label>{t('Location')}</label>
                        <LocationEditorWithRoleLocations
                          key={'location-editor-new-tna'}
                          className="planit-user-dropdown-custom"
                          value={this.newQuestionBankStore.state.item.locationId}
                          locationsReceived={areYouPoc ? pocLocations : []}
                          onChange={location =>
                            this.handleValue(nameof<CreateTnaQuestionBankDto>('locationId'), location ? location.id : null)
                          }
                          skipDefaultClass
                          customPlaceholder={"PoC's Location"}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-textarea">
                    <Form.Field
                      required
                      className="tna-question-bank__label-textarea"
                      error={this.state.userClickedSave && !this.newQuestionBankStore.state.item.question?.text}
                    >
                      <label>{t('Question')}</label>
                      <TextArea
                        maxLength="500"
                        className="tna-question-bank__custom-editorequest__textarea"
                        value={this.newQuestionBankStore.state.item.question?.text || ''}
                        onChange={(e, { value }) => this.onChangeQuestion(value.toString())}
                      />

                      <Button
                        className="custom-table-add-row-btn add-row-btn"
                        type="button"
                        content="Add Row"
                        onClick={this.addQuestionsTranslation}
                      >
                        {i18n.t('Add Translation')}
                      </Button>
                    </Form.Field>
                  </Form.Group>
                  {this.state.questionTranslations.length > 0 && <>{this.showQuestionTranslationRows()}</>}
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-question-bank__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewTnaQuestionBankView);
