import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Label, Icon, Input, Dimmer, Loader } from 'semantic-ui-react';
import {
  ChangePositionCodeStore,
  PositionCodeDto,
  ChangePositionCodeDto
} from '../../../../../stores/configuration/profiles/position-codes-store';
import { nameof } from '../../../../../utils/object';
import './positionCode.less';

interface ChangePositionCodeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changePositionCode?: ChangePositionCodeStore;
  currentPositionCode: PositionCodeDto;
}

interface ChangePositionCodeViewState {
  keyword: string;
  userClickedSaved: boolean;
}

@connect(['changePositionCode', ChangePositionCodeStore])
class ChangeLocationView extends React.Component<ChangePositionCodeViewProps, ChangePositionCodeViewState> {
  private get changePositionCodeStore() {
    return this.props.changePositionCode;
  }

  constructor(props: ChangePositionCodeViewProps) {
    super(props);

    this.changePositionCodeStore.state.result = null;

    this.changePositionCodeStore.change({
      id: this.props.currentPositionCode.id,
      code: this.props.currentPositionCode.code,
      description: this.props.currentPositionCode.description,
      keywords: this.props.currentPositionCode.keywords
    });
    this.state = { keyword: null, userClickedSaved: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangePositionCodeViewProps) {
    if (this.changePositionCodeStore.state.result && this.changePositionCodeStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changePositionCodeStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changePositionCodeStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changePositionCodeStore.change({ ...this.changePositionCodeStore.state.item, ...change });
  }

  private sendKeyword = () => {
    if (this.state.keyword === '' || this.state.keyword == null) {
      return;
    }

    const item = this.changePositionCodeStore.state.item;
    const keywords = item.keywords;
    keywords.push(this.state.keyword);
    this.changePositionCodeStore.change(item);

    this.setState({ keyword: '' });
  };

  private deleteTag(index: number) {
    const item = this.changePositionCodeStore.state.item;
    item.keywords = this.changePositionCodeStore.state.item.keywords.filter((p, i) => {
      return i !== index;
    });
    this.changePositionCodeStore.change(item);
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changePositionCodeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Position Code')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changePositionCodeStore.state.result && !this.changePositionCodeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changePositionCodeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changePositionCodeStore.state.item && (
              <Form>
                <div className="position-code_all-content__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="position-code__label-input__wrapper"
                      error={!this.changePositionCodeStore.state.item.code && this.state.userClickedSaved}
                    >
                      <label>{t('Position Code')}</label>
                      <Input
                        id="position-code__input-code"
                        required
                        fluid
                        placeholder={t('Name')}
                        value={this.changePositionCodeStore.state.item.code}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangePositionCodeDto>('code'), value)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      className="position-code__label-input__wrapper"
                      error={!this.changePositionCodeStore.state.item.description && this.state.userClickedSaved}
                    >
                      <label>{t('Description')}</label>
                      <Input
                        id="position-code__input-description"
                        fluid
                        placeholder={t('Description')}
                        value={this.changePositionCodeStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangePositionCodeDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <div className="position-code-info__wrapper">
                    <Form.Field
                      required
                      error={
                        (!this.changePositionCodeStore.state.item.keywords ||
                          this.changePositionCodeStore.state.item.keywords.length === 0) &&
                        this.state.userClickedSaved
                      }
                    >
                      <label>{t('keywords')}</label>
                      <Input type="hidden" />
                    </Form.Field>
                    <div className="newPositionCode__tags-container">
                      {this.changePositionCodeStore.state.item.keywords.map((k, i) => {
                        return (
                          <Label circular className="event-type-label label-4">
                            {k}
                            <Icon name="times circle" className="clickable-icon" onClick={() => this.deleteTag(i)} />
                          </Label>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="position-code_all-content__wrapper add-container">
                  <Form.Group>
                    <div className="keyword-input-button__wrapper footer-modal">
                      <Form.Field>
                        <Form.Input value={this.state.keyword} onChange={(e, { value }) => this.setState({ keyword: value })} />
                      </Form.Field>
                      <Form.Field>
                        <Button className="inverted-color-btn" style={{ marginLeft: 10 }} icon onClick={this.sendKeyword}>
                          {t('Add Tag')}&nbsp;
                          <Icon name="add circle" />
                        </Button>
                      </Form.Field>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeLocationView);
