import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Input, Icon } from 'semantic-ui-react';
import InfoSupportDetailedComponent from './info-support-detailed.component';
import './events-support-details.style.less';
import InputCostComponent from 'widgets/form/input-cost.component';
import { UserDto } from 'stores/users/users-store';
import { EventSupportPositionsDto, EventPositionDto, TravelRangeDto, DateTimePeriod } from 'stores/events/events-store';
import { EventFormStore, EventSupportDetailsViewModel } from 'stores/events/event-form-store';
import { DatePeriod } from 'site/pages/landing-pages/shared-scheduler-components/events/scheduler-period-service';

interface EventsSupportDetailsProps extends WithTranslation {
  readonly?: boolean;
  supportDetails: EventSupportDetailsViewModel;
  onChange?: (value: EventSupportDetailsViewModel) => void;
  isHideEditButton: boolean;
  eventFormStore?: EventFormStore;
}

const EventsSupportDetails: FunctionComponent<EventsSupportDetailsProps> = props => {
  const { t, readonly, supportDetails, onChange, isHideEditButton, eventFormStore } = props;
  const { supportPositions, positionsRequested, totalCost } = supportDetails;

  const onChangeHandler = (newPosition: EventSupportPositionsDto, index: number) => {
    const supportPositions = supportDetails.supportPositions.map((position, i) => (index === i ? (position = newPosition) : position));
    onChange({ ...supportDetails, supportPositions });
  };
  const sumTotalCosts = supportPositions.reduce(
    (prev, { eventPositions }) => (eventPositions || []).reduce((p, { actualCost }) => Number(actualCost) + p, 0) + prev,
    0
  );

  useEffect(() => {
    totalCost !== sumTotalCosts && onChange({ ...supportDetails, totalCost: sumTotalCosts });
  }, [sumTotalCosts, onChange, totalCost, supportDetails]);

  const onNewPositionHandler = (user: UserDto, dates: DatePeriod, supportPosition: number) => {
    const nextSupportPositions = supportDetails.supportPositions.map((supportPos, i) => {
      if (supportPosition === i) {
        const newEventPosition: EventPositionDto = {
          actualCost: '',
          actualDays: '',
          actualHours: '',
          startDate: dates ? dates.from : null,
          endDate: dates ? dates.to : null,
          // travelDateFrom: null,
          // travelDateTo: null,
          user,
          userId: user.id,
          userLocationId: user.roles.find(x => x.role.name === 'Employee').location.id,
          travelDays: null,
          noTravel: false
        };

        supportPos.eventPositions = [...supportPos.eventPositions, newEventPosition];
      }
      return supportPos;
    });
    onChange({ ...supportDetails, supportPositions: nextSupportPositions });
  };

  const handleTravelDays = (idx: number, property: keyof TravelRangeDto, period: DateTimePeriod, supportPosition: number) => {
    const supportPositions = supportDetails.supportPositions.map((supportPos, i) => {
      if (idx === i) {
        const eventPosition = { ...supportPos.eventPositions[supportPosition] };
        if (eventPosition.travelDays == null)
          eventPosition.travelDays = { departure: { from: null, to: null }, arrival: { from: null, to: null } };
        if (property === 'departure') {
          if (period.from !== supportPos.eventPositions[supportPosition].travelDays?.departure?.from)
            eventPosition.travelDays.departure.from = period.from;
          if (period.to !== supportPos.eventPositions[supportPosition].travelDays?.departure?.to)
            eventPosition.travelDays.departure.to = period.to;
        } else if (property === 'arrival') {
          if (period.from !== supportPos.eventPositions[supportPosition].travelDays?.arrival?.from)
            eventPosition.travelDays.arrival.from = period.from;
          if (period.to !== supportPos.eventPositions[supportPosition].travelDays?.arrival?.to)
            eventPosition.travelDays.arrival.to = period.to;
        }

        supportPos.eventPositions[supportPosition] = eventPosition;
      }
      return supportPos;
    });
    onChange({ ...supportDetails, supportPositions });
  };

  return (
    <div className="events-support-details__container">
      <div className="events-support-details__main-info">
        <Icon name="cogs" />
        <div className="events-support-details__main-data">
          <span>{t('Position Requested')}</span>
          <Input disabled value={positionsRequested} />
        </div>
        <div className="events-support-details__main-data">
          <span>{t('Total Cost')}</span>
          <InputCostComponent onChange={null} disabled disabledLayout value={sumTotalCosts} />
        </div>
      </div>
      {(supportPositions || []).map((supportPosition, i) => (
        <InfoSupportDetailedComponent
          key={'event-support-position' + i}
          readonly={readonly}
          onNewPosition={(user, dates) => onNewPositionHandler(user, dates, i)}
          {...supportPosition}
          onChange={value => onChangeHandler(value, i)}
          handleTravelDays={(property, period, idx) => handleTravelDays(i, property, period, idx)}
          isHideEditButton={isHideEditButton}
          eventFormStore={eventFormStore}
        />
      ))}
    </div>
  );
};

export default withTranslation()(EventsSupportDetails);
