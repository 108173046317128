import { UserDto } from 'stores/users/users-store';
import { Component } from 'react';
import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CalculateSeniority, GetLocationName } from 'utils/userinfo-functions';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import UserSummaryImage from '../../employee-page/user-summary/user-summary-image';

export interface UserSummaryProps extends WithTranslation {
  user: UserDto;
}

class InstructorSummary extends Component<UserSummaryProps> {
  render() {
    const { t, user } = this.props;
    const seniority = user && CalculateSeniority(user.hireDate);
    const location = user && GetLocationName(user);
    const languages = user && getLanguagesList(user);

    return (
      <Grid className="user-card-box min-height-box">
        <Grid.Row columns={3}>
          <Grid.Column width={4} verticalAlign="top" textAlign="center">
            <UserSummaryImage user={user} />
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Name')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('SAP ID')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.employeeId || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Position Code')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.positionCode?.description || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Email')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <TableTooltipCell newClassFromComponent="bold-text" textToShow={user?.email || '-'} />
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Line Manager')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.lineManager || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Instructor Role')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.instructorRole?.name || '-'}</span>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row className="rows-user-card-box">
              <span>{t('User Name')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.userName || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Location')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{location || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('SF Position')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{user?.sfPosition || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Seniority')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span className="bold-text">{seniority || '-'}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">
              <span>{t('Languages')}</span>
            </Grid.Row>
            <Grid.Row className="rows-user-card-box">{languages || '-'}</Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const getLanguagesList = (data: UserDto) => {
  if (!(data.languages || []).length) return;
  return (
    <List>
      {data.languages.map(({ languageCode, id, language }) =>
        languageCode ? (
          <List.Item key={`${data.id}-${id}-${languageCode}`}>
            <span className="bold-text">
              {languageCode} - {language}
            </span>
          </List.Item>
        ) : null
      )}
    </List>
  );
};
export default withTranslation()(InstructorSummary);
