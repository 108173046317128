import React, { Fragment, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './feedback-tab.less';
import { Icon } from 'semantic-ui-react';
import { SimpleFeedbackFormAnswerDto } from 'stores/events/feedback-forms-store';

interface feedbackTableProps extends WithTranslation {
  readOnly?: boolean;
  forms: SimpleFeedbackFormAnswerDto[];
  handleOnRemoveFeedBack: (feedbackFormAnswerClicked: SimpleFeedbackFormAnswerDto) => void;
  templateId: string;
  onOpenEmployeeFeedBackForm: (form: SimpleFeedbackFormAnswerDto) => void;
  isEventClosed?: boolean;
  canRemoveFeedback?: boolean;
}

const FeedbackTable: FC<feedbackTableProps> = ({
  t,
  forms,
  handleOnRemoveFeedBack,
  onOpenEmployeeFeedBackForm,
  isEventClosed,
  canRemoveFeedback
}) => {
  return (
    <div className="form__feedback-table">
      <p className="feedback-table__name-column">{t('User Name')}</p>
      <p className="feedback-table__name-column">{t('Last Name')}</p>
      <p className="feedback-table__name-column">{t('First Name')}</p>
      <p className="feedback-table__name-column">{t('SF Position')}</p>
      <p className="feedback-table__name-column">{t('Location')}</p>
      <p className="feedback-table__name-column text__transparent">X</p>
      <p className="feedback-table__name-column text__transparent">X</p>
      {(forms || []).map(form => {
        const { user, id, userId, answered } = form;
        return (
          <Fragment key={id + userId}>
            <p className="feedback-table__cell">{user.userName}</p>
            <p className="feedback-table__cell">{user.lastName}</p>
            <p className="feedback-table__cell">{user.firstName}</p>
            <p className="feedback-table__cell">{user.sfPosition}</p>
            <p className="feedback-table__cell">{user?.location?.location}</p>
            <p className="feedback-table__cell">
              <Icon
                onClick={() => answered && onOpenEmployeeFeedBackForm(form)}
                className={answered ? 'clickable-icon feedback__comments-icon__answered' : 'clickable-icon feedback-table__comments-icon'}
                name="comments"
                size="large"
              />
            </p>

            <p className="feedback-table__cell">
              {!isEventClosed && canRemoveFeedback && (
                <Icon onClick={() => handleOnRemoveFeedBack(form)} className="clickable-icon" color="red" name="remove" size="large" />
              )}
            </p>
          </Fragment>
        );
      })}
    </div>
  );
};

export default withTranslation()(FeedbackTable);
