import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { IdentityService } from 'services/identity-service';
import { Dropdown } from 'semantic-ui-react';
import { resolve } from 'inversify-react';
import { ItemReference } from 'stores/dataStore';
import { CountryDto } from 'stores/configuration/locations/countries-store';
import { DropDownVisasStore } from 'stores/configuration/planner-assistant/visas-store';

interface VisaSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  onChangeVisa?: (value: ItemReference) => void;
  visas?: DropDownVisasStore;
  filterByRole?: boolean;
  direction?: 'left' | 'right';
}

@connect(['visas', DropDownVisasStore])
class VisaSelector extends React.Component<VisaSelectorProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    let filter: any = [];

    filter = [{ InternationalTreatyRequired: { eq: true } }];

    this.props.visas
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<CountryDto>('name'), useProfile: false }],
        filter
      })
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.name }))
        });
      });
  }

  handleOnChange(id: string) {
    if (this.props.onChange) {
      this.props.onChange(id);
      return;
    }

    if (this.props.onChangeVisa) {
      if (!id) {
        this.props.onChangeVisa(null);
        return;
      }

      let option = this.state.options.find(x => x.value === id);
      this.props.onChangeVisa({
        id: option.value,
        title: option.text
      });
    }
  }

  public render() {
    const { options, isLoading } = this.state;
    const { value, placeholder, direction, readOnly, className, clearable } = this.props;
    const selectedValue = value || null;

    return (
      <Dropdown
        direction={direction}
        selection
        loading={isLoading}
        search
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => this.handleOnChange(value as string)}
        selectOnBlur={false}
        disabled={readOnly}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(VisaSelector);
