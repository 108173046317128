export const scoreNames = [
  'Inexperienced',
  'Need Assistance',
  'Regular Daily Support',
  'Expert on the Job',
  'Train Others',
  'Not Applicable'
];

export const iwsScoreNames = [
  'Doesn’t know; can’t do',
  'Need assistance',
  'Has solid practice',
  'Can teach others',
  'Assessor/developer',
  'Not Applicable'
];

export const gradesNames = ['Unawareness', 'Awareness', 'Knowledge', 'Proficiency', 'Mastery', 'Not Applicable'];
