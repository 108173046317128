import React, { FC, Fragment, memo, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import GradeComponent from '../../tna/forms/tna-form-score-component';
import TNAFormScores from '../../tna/forms/tna-form-scores.component';
import { IwsSkillAnswerDto, QuestionDto, UserAnswerDto, IwsFormItemDto } from 'stores/assessments/forms/iws-forms-store';
import { iwsScoreNames } from '../../tna/forms/score-names-constants';
import { IWSFormContext } from './iws-edit-form';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import { Accordion } from 'semantic-ui-react';

interface IWSFormSectionComponentProps extends WithTranslation {
  skillSections: IwsSkillAnswerDto[];
  onQuestionAnswered: (answer: number | string, questionId: string, skillId: string, templateId: string, comment: boolean) => void;
  iwsTemplateId?: string;
}

const IWSFormSectionComponent: FC<IWSFormSectionComponentProps> = ({ t, ...props }) => {
  const { getAssessorType, isAdminOrPoC, isAssessor, isEmployee } = useContext(IWSFormContext);

  const status = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).status) as string;
  const currentLanguage = useSelector<ApplicationState>(
    ({ iwsformlist: { summary } }) => (summary?.item as IwsFormItemDto)?.currentLanguage
  ) as string;

  const assessorType = getAssessorType();
  const { skillSections } = props;

  const isEmployeeStatus = status === 'FirstAssesment' || status === 'SecondAssesment';
  const assessorReadOnlyMode = isAssessor && isEmployeeStatus;

  const getQuestionCurrLanguage = (questionTranslations: QuestionDto[], question: QuestionDto) => {
    const neededLanguage = questionTranslations.find(x => x.languageId === currentLanguage);
    return neededLanguage ? neededLanguage.text : question.text;
  };

  const getActiveGrade = (firstEmployeeAnswer: UserAnswerDto, secondEmployeeAnswer: UserAnswerDto, qualifierAnswer: UserAnswerDto) => {
    if (isEmployee && status === 'SecondAssesment') return secondEmployeeAnswer?.answer;
    if (isEmployee && status === 'FirstAssesment') return firstEmployeeAnswer?.answer;
    if (assessorType.type === 'qualifier' && qualifierAnswer != null) return qualifierAnswer?.answer;
    return null;
  };

  const itsDone = status === 'Done';

  const iwsSkillspanels =
    (skillSections || []).length > 0 &&
    skillSections.map(({ skillId, skillName, questionAnswers, templateId }, i) => ({
      key: `panel-${i}`,
      title: skillName,
      content:
        (questionAnswers || []).length > 0 &&
        questionAnswers.map(
          (
            { question, questionId, employeeFirstAssessmentAnswer, employeeSecondAssessmentAnswer, qualifierAnswer, questionTranslations },
            index
          ) => {
            employeeFirstAssessmentAnswer =
              employeeFirstAssessmentAnswer?.answer === -1
                ? { ...employeeFirstAssessmentAnswer, answer: 5 }
                : employeeFirstAssessmentAnswer;
            employeeSecondAssessmentAnswer =
              employeeSecondAssessmentAnswer?.answer === -1
                ? { ...employeeSecondAssessmentAnswer, answer: 5 }
                : employeeSecondAssessmentAnswer;
            qualifierAnswer = qualifierAnswer?.answer === -1 ? { ...qualifierAnswer, answer: 5 } : qualifierAnswer;
            return (
              <Fragment key={templateId + questionId}>
                <p className="skill-section__question">{`Question ${index + 1}. ${getQuestionCurrLanguage(
                  questionTranslations,
                  question
                )}`}</p>

                {!isAdminOrPoC && !(isEmployee && status === 'Qualifier') && !assessorReadOnlyMode ? (
                  <>
                    <div className="skill-section__scores-list">
                      <TNAFormScores
                        activeGrade={getActiveGrade(employeeFirstAssessmentAnswer, employeeSecondAssessmentAnswer, qualifierAnswer)}
                        onGradeChange={answer => !itsDone && props.onQuestionAnswered(answer, questionId, skillId, templateId, false)}
                      />

                      {(isAssessor || (isEmployee && status === 'SecondAssesment')) && employeeFirstAssessmentAnswer?.answer != null && (
                        <div className="flex-column assesor__result-score-container">
                          <p className="employee-self-text">{t('1st Employee Self-Evaluation')}</p>
                          <GradeComponent
                            active
                            isToggleable={false}
                            className={'scores-list__assesor__result-score'}
                            scoreName={t(iwsScoreNames[employeeFirstAssessmentAnswer.answer])}
                            scoreNumber={t(employeeFirstAssessmentAnswer.answer.toString())}
                          />
                        </div>
                      )}
                      {isAssessor && employeeSecondAssessmentAnswer?.answer != null && (
                        <div className="flex-column assesor__result-score-container">
                          <p className="employee-self-text">{t('2nd Employee Self-Evaluation')}</p>
                          <GradeComponent
                            active
                            isToggleable={false}
                            className={'scores-list__assesor__result-score'}
                            scoreName={t(iwsScoreNames[employeeSecondAssessmentAnswer.answer])}
                            scoreNumber={t(employeeSecondAssessmentAnswer.answer.toString())}
                          />
                        </div>
                      )}
                    </div>
                    {isAssessor && (
                      <div className="skill-section__poc-admin__comments-area">
                        <div className="flex-column">
                          <p>{t("Qualifier's comments")}</p>
                          <textarea
                            value={qualifierAnswer?.comment || ''}
                            readOnly={itsDone}
                            onChange={({ target: { value } }) => props.onQuestionAnswered(value, questionId, skillId, templateId, true)}
                            className="skill-section__comments"
                          ></textarea>
                        </div>
                        <div className="flex-column">
                          <p className="text__grey ">{t("1st Employee's comments")}</p>
                          <textarea
                            value={employeeFirstAssessmentAnswer ? employeeFirstAssessmentAnswer.comment : ''}
                            readOnly
                            className="skill-section__comments"
                          ></textarea>
                        </div>
                        <div className="flex-column">
                          <p className="text__grey ">{t("2nd Employee's comments")}</p>
                          <textarea
                            value={employeeSecondAssessmentAnswer ? employeeSecondAssessmentAnswer.comment : ''}
                            readOnly
                            className="skill-section__comments"
                          ></textarea>
                        </div>
                      </div>
                    )}
                    {isEmployee && status === 'FirstAssesment' && (
                      <div>
                        <p>{t('Comments')}</p>
                        <textarea
                          readOnly={itsDone}
                          value={employeeFirstAssessmentAnswer ? employeeFirstAssessmentAnswer.comment : ''}
                          onChange={({ target: { value } }) => props.onQuestionAnswered(value, questionId, skillId, templateId, true)}
                          className="skill-section__comments"
                        ></textarea>
                      </div>
                    )}
                    {isEmployee && status === 'SecondAssesment' && (
                      <div className="skill-section__poc-admin__comments-area">
                        <div className="flex-column">
                          <p>{t('Comments')}</p>
                          <textarea
                            readOnly={itsDone}
                            value={employeeSecondAssessmentAnswer ? employeeSecondAssessmentAnswer.comment : ''}
                            onChange={({ target: { value } }) => props.onQuestionAnswered(value, questionId, skillId, templateId, true)}
                            className="skill-section__comments"
                          ></textarea>
                        </div>
                        <div className="flex-column">
                          <p className="text__grey ">{t("1st Employee's comments")}</p>
                          <textarea
                            value={employeeFirstAssessmentAnswer ? employeeFirstAssessmentAnswer.comment : ''}
                            readOnly
                            className="skill-section__comments"
                          ></textarea>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="skill-section__poc-admin__scores-list">
                      {employeeFirstAssessmentAnswer && employeeFirstAssessmentAnswer.answer != null && (
                        <div className="flex-column">
                          <p style={{ width: '60%' }}>{t('1st Employee Self-Evaluation')}</p>
                          <GradeComponent
                            active
                            isToggleable={false}
                            scoreName={t(iwsScoreNames[employeeFirstAssessmentAnswer.answer])}
                            scoreNumber={t(employeeFirstAssessmentAnswer.answer.toString())}
                          />
                        </div>
                      )}
                      {employeeSecondAssessmentAnswer && employeeSecondAssessmentAnswer.answer != null && (
                        <div className="flex-column">
                          <p style={{ width: '60%' }}>{t('2nd Employee Self-Evaluation')}</p>
                          <GradeComponent
                            active
                            isToggleable={false}
                            scoreName={t(iwsScoreNames[employeeSecondAssessmentAnswer.answer])}
                            scoreNumber={t(employeeSecondAssessmentAnswer.answer.toString())}
                          />
                        </div>
                      )}
                      {qualifierAnswer && qualifierAnswer.answer != null && (
                        <div className="flex-column">
                          <p style={{ width: '60%' }}>{t('Qualifier Evaluation')}</p>
                          <GradeComponent
                            active
                            isToggleable={false}
                            scoreName={t(iwsScoreNames[qualifierAnswer.answer])}
                            scoreNumber={t(qualifierAnswer.answer.toString())}
                          />
                        </div>
                      )}
                    </div>

                    <div className="skill-section__poc-admin__comments-area">
                      <div className="flex-column">
                        <p>{t("1st Employee's comments")}</p>
                        <textarea
                          readOnly
                          className="skill-section__comments"
                          value={employeeFirstAssessmentAnswer?.comment || ''}
                        ></textarea>
                      </div>
                      <div className="flex-column">
                        <p>{t("2nd Employee's comments")}</p>
                        <textarea
                          readOnly
                          className="skill-section__comments"
                          value={employeeSecondAssessmentAnswer?.comment || ''}
                        ></textarea>
                      </div>
                      <div className="flex-column">
                        <p>{t("Qualifier's comments")}</p>
                        <textarea readOnly className="skill-section__comments" value={qualifierAnswer?.comment || ''}></textarea>
                      </div>
                    </div>
                  </>
                )}
              </Fragment>
            );
          }
        )
    }));

  return (
    <div className="tna-form__main-tab__skill-section">
      <Accordion defaultActiveIndex={[0]} panels={iwsSkillspanels} exclusive={false} fluid />
    </div>
  );
};
export default withTranslation()(memo(IWSFormSectionComponent));
