import { ValidationResult } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import { DataStore } from '../dataStore';
import { BaseDto } from '../types';

export interface RoleDto extends BaseDto {
  id: string;
  name: string;
  description: string;
  color: string;
}

@repository('@@ROLES', 'roles.summary')
export class RolesStore extends DataStore<RoleDto> {
  baseUrl = 'identity/api/v1';
  createPath = '';
  retrievePath = 'get-roles';
  updatePath = '';
  deletePath = '';

  protected validate(item: RoleDto) {
    return new ValidationResult();
  }

  constructor() {
    super('ROLE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@ROLES', 'dropdownroles.summary')
export class DropDownRolesStore extends DataStore<RoleDto> {
  baseUrl = 'identity/api/v1';
  createPath = '';
  retrievePath = 'get-roles';
  updatePath = '';
  deletePath = '';

  protected validate(item: RoleDto) {
    return new ValidationResult();
  }

  constructor() {
    super('DROPDOWNROLE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
