import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChooseTnaTemplatesView from 'widgets/form/choose-tna-template-form';
import { TnaTemplateDto } from 'stores/assessments/templates/tna-templates-store';

export interface ChooseTnaTemplateModalButtonProps extends WithTranslation {
  onAddTemplate?: (template: TnaTemplateDto) => void;
  size: 'Normal' | 'Mini';
  profileId: string;
  selectedTemplateId: string;
  isEditMode: boolean;
}

export interface ChooseTnaTemplateModalButtonState {
  showTemplateSearchModal: boolean;
}

class ChooseTnaTemplateModalButton extends Component<ChooseTnaTemplateModalButtonProps, ChooseTnaTemplateModalButtonState> {
  state = {
    showTemplateSearchModal: false
  };

  onCancel = () => {
    this.setState({ showTemplateSearchModal: false });
  };

  handleOnAddTemplate = (template: TnaTemplateDto) => {
    this.props.onAddTemplate && this.props.onAddTemplate(template);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.size === 'Mini' && (
          <Button
            className="step2__select-tna__btn"
            circular
            icon="pencil"
            onClick={() => this.setState({ showTemplateSearchModal: true })}
          />
        )}
        {this.props.size === 'Normal' && (
          <Button className="search-tna-template__btn" onClick={() => this.setState({ showTemplateSearchModal: true })}>
            {t('Search TNA Templates')}
          </Button>
        )}
        {this.state.showTemplateSearchModal && (
          <ChooseTnaTemplatesView
            profileId={this.props.profileId}
            alreadySelectedTemplateId={this.props.selectedTemplateId}
            onAddTemplate={this.handleOnAddTemplate}
            onCloseTemplateModal={() => this.setState({ showTemplateSearchModal: false })}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(ChooseTnaTemplateModalButton);
