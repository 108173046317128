import { AbstractValidator, ValidationResult, ValidationFailure } from 'fluent-ts-validator';

export default abstract class ExtendedAbstractValidator<T> extends AbstractValidator<T> {
  protected extendedValidationResult: ValidationResult;
  protected externAddErrors: (...failures: ValidationFailure[]) => void;
  protected id: string = 'IDENTIFIER';

  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super();
    this.extendedValidationResult = new ValidationResult();
    this.externAddErrors = onErrors;
    this.id = this.constructor.name;
  }

  protected addErrors = (...failures: ValidationFailure[]) => {
    this.extendedValidationResult.addFailures(failures);
  };

  public extendValidate = (input: T): ValidationResult => {
    this.extendedValidationResult = new ValidationResult();
    const errors = this.validate(input).getFailures();
    this.addErrors(...errors);
    if (this.externAddErrors) {
      this.externAddErrors(...this.extendedValidationResult.getFailures());
    }
    return this.extendedValidationResult;
  };
}
