import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Placeholder } from 'semantic-ui-react';
import './counter-box.less';

export interface CounterBoxProps extends WithTranslation {
  loading: boolean;
  count: number;
  title: string;
  color: string;
  clickable?: boolean;
  onClick?: () => void;
  className?: string;
  id?: string;
  selected?: boolean;
}

const CounterBox: FC<CounterBoxProps> = props => {
  const [hovered, setHover] = useState(false);

  if (props.loading)
    return (
      <Placeholder className={`admin-report__counter-box__placeholder ${props.className || ''}`} id={props.id}>
        <Placeholder.Header>
          <Placeholder.Line className="counter-placeholder" length="full" />
          <Placeholder.Line className="title-placeholder" length="full" />
        </Placeholder.Header>
      </Placeholder>
    );

  const style: React.CSSProperties = {};

  if (hovered || props.selected) {
    style.backgroundColor = props.color;
    style.color = 'white';
    style.cursor = 'pointer';
  } else {
    style.color = props.color;
    style.backgroundColor = 'white';
    style.cursor = 'default';
  }

  const onMouseOver = () => {
    if (props.clickable) {
      setHover(true);
    }
  };

  const onMouseOut = () => {
    if (props.clickable) {
      setHover(false);
    }
  };

  const onClick = () => {
    if (props.clickable && props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={`admin-report__counter-box ui statistic ${props.className || ''}`}
      id={props.id}
      onClick={onClick}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div className="admin-report__counter-box__counter value" style={style}>
        {props.count}
      </div>
      <div className="admin-report__counter-box__title label" style={style}>
        {props.title}
      </div>
    </div>
  );
};

export default withTranslation()(CounterBox);
