import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';

export interface EmployeeTrackLogItem {
  id: string;
  executionTimestamp: string;
  eventsFound: number;
  usersSent: number;
  success: boolean;
  messages: string[];
}

@repository('@@EMPLOYEE_TRACK_LOG', 'employeeTrackLog.summary')
export class EmployeeTrackLogStore extends DataStore<EmployeeTrackLogItem> {
  baseUrl = '/success-factors/v1';
  createPath = '';
  retrievePath = 'get-connector-logs';
  updatePath = '';
  deletePath = '';

  protected validate(item: EmployeeTrackLogItem) {
    return undefined;
  }

  constructor() {
    super('EMPLOYEE_TRACK_LOG', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
