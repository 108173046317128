import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dimmer, Loader, Button, Message, Modal } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import IwsFirstStepContent from './steps/iws-wizard-first-step';
import { Query, ItemReference } from 'stores/dataStore';
import {
  IwsTemplatesStore,
  NewIwsTemplateStore,
  ChangeIwsTemplateStore,
  IwsTemplateDto,
  ChangeIwsTemplateDto,
  CreateIwsTemplateDto,
  IwsSkillTemplateSectionDto,
  CreateIwsSkillTemplateSectionDto,
  ChangeIwsSkillTemplateSectionDto
} from 'stores/assessments/templates/iws-templates-store';
import { RouteComponentProps } from 'react-router';
import { ProfileItemDto, ProfileItemStore } from 'stores/profile/profile-store';
import LineSeparator from 'widgets/bussiness/line-separator';
import IwsSecondStepContent from './steps/iws-wizard-second-step';
import './iws-templates-wizard.less';

export interface IwsTemplateWizardComponentProps extends WithTranslation, RouteComponentProps {
  iwsTemplateStore?: IwsTemplatesStore;
  newTemplateStore?: NewIwsTemplateStore;
  changeTemplateStore?: ChangeIwsTemplateStore;
  profileStore?: ProfileItemStore;
}
type ModalMode = 'cancel' | 'accept';
export interface IwsTemplateWizardComponentState {
  query: Query;
  templateData: IwsTemplateData;
  showConfirmationModal: boolean;
  confirmationModalMode: ModalMode;
}
export interface IwsTemplateData {
  profile: ProfileItemDto;
  title: string;
  header?: string;
  id?: string;
  skillSection: IwsSkillTemplateSectionDto;
}
@connect(
  ['iwsTemplateStore', IwsTemplatesStore],
  ['newTemplateStore', NewIwsTemplateStore],
  ['changeTemplateStore', ChangeIwsTemplateStore],
  ['profileStore', ProfileItemStore]
)
class IwsTemplateWizardComponent extends React.Component<IwsTemplateWizardComponentProps, IwsTemplateWizardComponentState> {
  constructor(props: IwsTemplateWizardComponentProps) {
    super(props);
    this.state = {
      confirmationModalMode: null,
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10, filter: [] },
      templateData: {
        profile: null,
        title: null,
        header: null,
        skillSection: null
      },
      showConfirmationModal: false
    };
  }
  loading = false;
  private get modeStore(): NewIwsTemplateStore | ChangeIwsTemplateStore {
    return this.isOnEditMode() ? this.props.changeTemplateStore : this.props.newTemplateStore;
  }
  private get plainNewItem(): IwsTemplateDto {
    return {
      id: null,
      friendlyId: null,
      profileItemId: null,
      profileItemIds: [],
      profileName: null,
      title: null,
      header: null,
      isActive: true,
      skillSection: null
    };
  }
  private get templates() {
    return this.props.iwsTemplateStore;
  }
  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state || match.params['id']) return true;
    else return false;
  };

  componentDidMount() {
    const { params } = this.props.match;
    const id = params['id'];
    if (id != null) this.loadTemplate(id);
    else this.createNewItemStore(this.plainNewItem);
  }

  loadTemplate = async (id: string) => {
    const { profileStore, history } = this.props;
    this.loading = true;
    try {
      const templData = await this.templates.getTemplateById(id);

      let profile: any = (profileStore.state.items || []).find(({ item }) => item.id === templData.profileItemId);
      profile = profile ? profile.item : templData.profileItemId ? await profileStore.getProfileById(templData.profileItemId) : null; //;

      if (templData) this.loading = false;

      const mappedData = this.mapToTemplateData(templData);

      this.setState({ templateData: { ...mappedData, profile } }, () => this.createNewItemStore(templData));
    } catch (error) {
      history.replace('/not-found');
    }
  };

  mapToTemplateData = (rawData: IwsTemplateDto): IwsTemplateData => {
    const { skillSection, header, title } = rawData;
    return { ...this.state.templateData, header: header, title: title, skillSection };
  };

  mapToCreateSkillSection = (skillSection: IwsSkillTemplateSectionDto): CreateIwsSkillTemplateSectionDto => {
    return { skillId: skillSection?.skillId, questions: (skillSection?.questions || []).map(x => x.id) };
  };

  mapToChangeSkillSection = (skillSection: IwsSkillTemplateSectionDto): ChangeIwsSkillTemplateSectionDto => {
    return { skillId: skillSection?.skillId, questions: (skillSection?.questions || []).map(x => x.id) };
  };

  mapItemReferenceToSkillSection = (skillItemReference: ItemReference): IwsSkillTemplateSectionDto => {
    return { skillId: skillItemReference?.id, skillName: skillItemReference?.title, questions: [] };
  };

  changeStoreItem = () => {
    const storedItem: CreateIwsTemplateDto | ChangeIwsTemplateDto = { ...this.modeStore.state.item };
    const { header, title, skillSection, profile }: IwsTemplateData = { ...this.state.templateData };
    const editItem: any = {
      ...storedItem,
      skillSection: this.mapToChangeSkillSection(skillSection)
    };
    if (profile && profile.id) editItem.profileItemId = profile.id;
    if (header !== storedItem.header) editItem.header = header;
    if (title !== storedItem.title) editItem.title = title;

    this.modeStore.change(editItem);
  };

  createNewItemStore = ({ id, title, header, isActive, profileItemId, skillSection }: IwsTemplateDto) => {
    //initialize skillSectionCreateDto
    let skillSectionCreateDto = null;

    if (skillSection != null) skillSectionCreateDto = this.mapToCreateSkillSection(skillSection);

    const newItem = { profileItemId, id, title, header, isActive, skillSection: skillSectionCreateDto };

    if (this.isOnEditMode()) {
      if (newItem.profileItemId) {
        delete newItem.profileItemId;
      }
      this.modeStore.change(newItem);
    } else {
      delete newItem.id;
      (this.modeStore as NewIwsTemplateStore).createNew(newItem);
    }
  };

  onChangeProperty = (property: string, value: any) => {
    const templateData = { ...this.state.templateData };
    templateData[property] = value;
    this.setState({ templateData }, this.changeStoreItem);
  };

  isStepTwoActive = () => {
    const { skillSection, title } = this.state.templateData;
    return title && skillSection && skillSection?.skillId != null;
  };

  goBack = () => {
    const { history } = this.props;
    this.modeStore.clear();
    history.goBack();
  };

  submit = () => {
    this.cancelConfirmationModal();
    const { modeStore } = this;
    if (this.isOnEditMode())
      (modeStore as ChangeIwsTemplateStore).update().then(_ => {
        modeStore.clear();
        modeStore.state.result && modeStore.state.result.isSuccess ? this.goBack() : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
    else
      (modeStore as NewIwsTemplateStore).submit().then(_ => {
        modeStore.clear();
        modeStore.state.result && modeStore.state.result.isSuccess ? this.goBack() : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
  };

  confirmationModalHandler = (mode: ModalMode) => {
    this.setState({ showConfirmationModal: true, confirmationModalMode: mode });
  };

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, confirmationModalMode: null });
  };

  getUniqueValues = (value: any, index: number, self: any[]) => {
    return self.indexOf(value) === index;
  };

  onChangeSkill = (skillItemReference: ItemReference) => {
    const skillSection = skillItemReference && skillItemReference.id ? this.mapItemReferenceToSkillSection(skillItemReference) : null;
    this.setState(pS => ({ templateData: { ...pS.templateData, skillSection } }), this.changeStoreItem);
  };

  isAbleToSubmit = (): boolean => {
    const { skillSection } = this.state.templateData;
    const ableToSecStep = this.isStepTwoActive();
    const ableToSubmit = skillSection && skillSection.questions.length > 0;

    return ableToSecStep && ableToSubmit;
  };

  render() {
    const { t } = this.props;
    const { isBusy, result } = this.modeStore.state;
    const { isBusy: busy } = this.templates.state;
    const { skillSection, profile } = this.state.templateData;
    const { templateData, confirmationModalMode, showConfirmationModal } = this.state;
    const mode = this.isOnEditMode();

    return (
      <>
        <h3 className="template-wizard-title">{t(`Step Up Card Template ${mode ? 'Edition' : 'Creation'}`)}</h3>
        <LineSeparator />
        {result && !result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={result.messages.map(m => m.body)} />
        )}
        <div className="iws-wizard__steps-container">
          <Dimmer page active={isBusy || busy || this.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          <IwsFirstStepContent
            isEditMode={mode}
            data={templateData}
            onChangeProperty={this.onChangeProperty}
            onChangeSkill={this.onChangeSkill}
          />

          <IwsSecondStepContent
            categoriesSection={skillSection}
            onChangeProperty={this.onChangeProperty}
            profession={profile && profile.professionId}
            isStepActive={this.isStepTwoActive()}
          />
        </div>

        <div className="iws-templates-wizard__btns">
          <Button onClick={() => this.confirmationModalHandler('cancel')} secondary inverted>
            {t('Cancel')}
          </Button>
          <Button disabled={!this.isAbleToSubmit()} onClick={() => this.confirmationModalHandler('accept')} primary positive>
            {t(`${mode ? 'Save' : 'Create'} Template`)}
          </Button>
        </div>
        {showConfirmationModal && (
          <Modal
            open={showConfirmationModal}
            size="mini"
            className="iws-confirmation-modal"
            closeOnEscape={true}
            onClose={this.cancelConfirmationModal}
          >
            <Modal.Content className="iws-confirmation-modal__content">
              {confirmationModalMode === 'accept' ? (
                <p>
                  {t(
                    `${
                      mode
                        ? 'Are you sure do you want to apply this changes in this IWS Template?'
                        : 'Do you want to create the IWS Template?'
                    }`
                  )}
                </p>
              ) : (
                <>
                  <p>{t('Are you sure you want to CANCEL?')}</p>
                  <p>{t('You will lose all the changes made in this IWS template.')}</p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="tiny"
                className="iws-confirmation-modal__btn cancel"
                content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                onClick={this.cancelConfirmationModal}
              />
              <Button
                size="tiny"
                className="iws-confirmation-modal__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                onClick={confirmationModalMode === 'accept' ? this.submit : this.goBack}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(IwsTemplateWizardComponent);
