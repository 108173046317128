import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { ValidationResult } from 'fluent-ts-validator';

export interface AuditLogItemDto {
  id: string;
  relatedUserId: string;
  relatedUserName: string;
  relatedUserFriendlyId: string;
  relatedItemId: string;
  relatedItemTitle: string;
  relatedItemFriendlyId: string;
  description: string;
  type:
    | 'EventCreation'
    | 'EventEdition'
    | 'EventDeletion'
    | 'RequestCreation'
    | 'RequestEdition'
    | 'RequestDeletion'
    | 'UserLogIn'
    | 'Notification'
    | 'EvaluationChanged';
  timestamp: string;
}

@repository('@@ADMIN_REPORTS', 'adminReports.auditLogs')
export class AdminReportsAuditLogsStore extends DataStore<AuditLogItemDto> {
  baseUrl = 'notifications/v1';
  createPath = '';
  retrievePath = 'get-logs';
  updatePath = '';
  deletePath = '';

  protected validate(item: AuditLogItemDto) {
    return new ValidationResult();
  }

  constructor() {
    super('AUDIT_LOGS_LIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
