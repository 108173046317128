import React, { Component } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import './profile-definition-modal.less';
import { NewProfileItemStore, CreateProfileItemDto, ProfileSkillDto, Assessment } from 'stores/profile/profile-store';
import ProfileDefinitionModal, { ProfileValues } from './profile-definition-modal.component';
import { Message } from 'semantic-ui-react';

interface NewProfileDefProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newProfileStore?: NewProfileItemStore;
  onRefresh?: () => void;
}

interface NewProfileDefinitionState {
  profileValues: ProfileValues;
  userClickedSave: boolean;
}

@connect(['newProfileStore', NewProfileItemStore])
class NewProfileDefinition extends Component<NewProfileDefProps, NewProfileDefinitionState> {
  state: NewProfileDefinitionState = {
    userClickedSave: false,
    profileValues: this.getDefaultProfileValues()
  };

  private get newProfileItemStore() {
    return this.props.newProfileStore;
  }

  private getDefaultProfileValues(): ProfileValues {
    const initialState = {
      professionId: null,
      assessment: Assessment.Unknown,
      description: '',
      isActive: true,
      skills: []
    } as any;
    this.newProfileItemStore.createNew({ ...initialState });
    initialState.skill = null;
    initialState.main = null;
    initialState.substitute = null;
    initialState.phase0 = null;
    initialState.phase1 = null;
    initialState.phase2 = null;
    initialState.phase3 = null;
    initialState.phase4 = null;

    return initialState;
  }

  private getDefaultProfileRowsValues() {
    return {
      skill: null,
      main: null,
      substitute: null,
      phase0: null,
      phase1: null,
      phase2: null,
      phase3: null,
      phase4: null
    };
  }

  private onCancelChangeItem = () => {
    this.newProfileItemStore.clear();
    this.props.onClose(false);
  };

  sanitizedFinalObj = (frontValues: ProfileValues): CreateProfileItemDto => {
    delete frontValues.skill;
    delete frontValues.main;
    delete frontValues.substitute;
    delete frontValues.phase0;
    delete frontValues.phase1;
    delete frontValues.phase2;
    delete frontValues.phase3;
    delete frontValues.phase4;
    return frontValues;
  };

  handleNewProfileRow = () => {
    const profileValuesCopy = { ...this.state.profileValues };
    const newRow: ProfileSkillDto = {
      main: profileValuesCopy.main,
      substitute: profileValuesCopy.substitute,
      skillId: profileValuesCopy.skill.id,
      assessment: profileValuesCopy.assessment,
      skillName: profileValuesCopy.skill.title,
      phase0: profileValuesCopy.phase0,
      phase1: profileValuesCopy.phase1,
      phase2: profileValuesCopy.phase2,
      phase3: profileValuesCopy.phase3,
      phase4: profileValuesCopy.phase4
    };
    if ((profileValuesCopy.skill as any).isMachineRelated != null)
      newRow.isMachineRelated = (profileValuesCopy.skill as any).isMachineRelated;
    this.setState(prevState => ({
      profileValues: {
        ...prevState.profileValues,
        ...this.getDefaultProfileRowsValues(),
        skills: [...prevState.profileValues.skills, newRow]
      }
    }));
    this.newProfileItemStore.change({ ...this.sanitizedFinalObj(profileValuesCopy), skills: [...profileValuesCopy.skills, newRow] });
  };

  onProfileValuesChange = (profileValues: ProfileValues) => {
    this.setState({ profileValues, userClickedSave: false });
    const newState = { ...profileValues };

    this.newProfileItemStore.change({ ...this.sanitizedFinalObj(newState) });
  };

  removeSkillRow = (index: number) => {
    const prevValues = { ...this.state.profileValues };
    const skills = prevValues.skills.filter((_, i) => index !== i);
    this.setState({ userClickedSave: false, profileValues: { ...prevValues, skills } });
    delete prevValues.skill;
    delete prevValues.main;
    delete prevValues.substitute;
    this.newProfileItemStore.change({ ...this.sanitizedFinalObj(prevValues), skills });
  };

  onSubmit = () => {
    this.setState({ userClickedSave: true });
    this.newProfileItemStore.submit().then(_ => {
      if (this.newProfileItemStore.state.result && this.newProfileItemStore.state.result.isSuccess) {
        this.onCancelChangeItem();
        this.props.onRefresh && this.props.onRefresh();
      }
    });
  };

  public render = () => (
    <ProfileDefinitionModal
      mode="New"
      onSubmit={this.onSubmit}
      onNewRow={this.handleNewProfileRow}
      onRemoveRow={this.removeSkillRow}
      saveTry={this.state.userClickedSave}
      values={this.state.profileValues}
      onChange={this.onProfileValuesChange}
      onClose={this.onCancelChangeItem}
      isBusy={this.newProfileItemStore.state.isBusy}
    >
      {this.newProfileItemStore.state.result && !this.newProfileItemStore.state.result.isSuccess && (
        <Message
          className="error-message__style"
          icon="exclamation circle"
          error
          list={this.newProfileItemStore.state.result.messages.map(m => m.body)}
        />
      )}
    </ProfileDefinitionModal>
  );
}

// Wire up the React component to the Redux store
export default withTranslation()(NewProfileDefinition);
