import React from 'react';

export default function withDelayedInput<P extends object & { onChange?: Function }>(
  WrappedComponent: React.ComponentType<P>,
  ms?: number,
  clearable?: boolean
) {
  return class extends React.Component<P> {
    timerId: NodeJS.Timeout = null;
    ms = 1000;
    constructor(props: P) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.ms = ms == null || ms < 0 ? 1000 : ms;
    }

    componentDidMount() {
      clearTimeout(this.timerId);
    }

    componentWillUnmount() {
      clearTimeout(this.timerId);
    }

    private isClear(...args: any[]) {
      if ((args || []).length === 0) return false;
      return args.any(x => x == null || x.length === 0 || ('value' in x && (x.value == null || x.value === '' || x.value.length === 0)));
    }

    handleChange(...args: any[]) {
      clearTimeout(this.timerId);
      if (!!clearable && this.isClear(...args)) {
        if (this.props.onChange) this.props.onChange(...args);
      } else {
        this.timerId = setTimeout(() => {
          if (this.props.onChange) this.props.onChange(...args);
        }, this.ms || 1000);
      }
    }

    render() {
      return <WrappedComponent {...this.props} onChange={this.handleChange} />;
    }
  };
}
