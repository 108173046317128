import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/ssaSkill.less';
import { ChangeIwsSkillDto, ChangeIwsSkillStore } from 'stores/assessments/skills/iws-skills-store';
import { nameof } from 'utils/object';

interface EditIwsSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeIwsSkill?: ChangeIwsSkillStore;
  currentIwsSkill: ChangeIwsSkillDto;
}

@connect(['changeIwsSkill', ChangeIwsSkillStore])
class EditIwsSkillView extends React.Component<EditIwsSkillViewProps> {
  private get changeIwsSkillStore() {
    return this.props.changeIwsSkill;
  }

  constructor(props: EditIwsSkillViewProps) {
    super(props);
    this.changeIwsSkillStore.state.result = null;
    this.changeIwsSkillStore.change({
      id: this.props.currentIwsSkill.id,
      description: this.props.currentIwsSkill.description,
      name: this.props.currentIwsSkill.name
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: EditIwsSkillViewProps) {
    if (this.changeIwsSkillStore.state.result && this.changeIwsSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeIwsSkillStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeIwsSkillStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeIwsSkillStore.change({ ...this.changeIwsSkillStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    return (
      <Modal className="ssa-skill__modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeIwsSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="ssa-skill__modal-header">{t('Edit IWS Skill')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeIwsSkillStore.state.result && !this.changeIwsSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeIwsSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeIwsSkillStore.state.item && (
              <Form>
                <div className="ssa-skill__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.changeIwsSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea"
                        value={this.changeIwsSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeIwsSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.changeIwsSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea longArea"
                        value={this.changeIwsSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeIwsSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-ssa-skill__btns">
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EditIwsSkillView);
