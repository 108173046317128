import { CancelEventDto, EventDto, CloseEventDto, ChangeEventDto, DateTimePeriod } from './events-store';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { ItemResult } from 'stores/types';
import { injectable } from 'inversify';
import { CreateEventDto, CreateRequestMergeEventItemDto } from './creation-wizard-store';
import * as Axios from 'axios';

export interface CalculateDurationMachineryRequestDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  machineUnitsId: string[];
}

export interface DatesCalculationRequestDto {
  startDate: string;
  endDate: string;
  durationDays: number;
  locationId: string;
  pausePeriods: DateTimePeriod[];
}

export interface DatesCalculationResultDto {
  startDate: string;
  endDate: string;
  durationDays: number;
}

export interface EventDurationDto {
  hours: number;
  days: number;
}

export interface EventDurationRequestDto {
  isMachineRelated: boolean;

  nmrRequest: NmrRequestDto;
  mrRequest: MrRequestDto;
}

export interface MrRequestDto {
  professionId: string;
  trainingLevelId: string;
  originalEventTypeId: string;
  rows: CalculateDurationMachineryRequestDto[];
}

export interface NmrRequestDto {
  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrTrainingNameId: string;
}

@injectable()
export default class EventService {
  //public static baseUrl = 'http://localhost:7071/api';
  public static baseUrl = 'events';
  public static createPath = 'v1/create-event';
  public static retrievePath = 'v2/get-events';
  public static updatePath = 'v1/update-event';
  public static nextStatusPath = 'v1/next-event-status';
  public static previousStatusPath = 'v1/previous-event-status';
  public static validateStatusPath = 'v1/validate-status';
  public static closeEventPath = 'v1/close-event';
  public static cancelEventPath = 'v1/cancel-event';
  public static retrieveOnePath = 'v1/get-event';
  public static removeRequestFromEventPath = 'v1/remove-request-event';
  public static createEventMergePath = 'v1/create-event-merge';

  public static calculateEventDurationPath = 'v1/calculate-event-duration';
  public static calculateEventDatesPath = 'v1/calculate-event-dates';

  public async cancelEvent(cancelEventDto: CancelEventDto) {
    const httpService = container.get(HttpService);
    return (await httpService.delete<CancelEventDto, any>(`${EventService.baseUrl}/${EventService.cancelEventPath}`, cancelEventDto)).data;
  }

  public async previousStatus(eventId: string) {
    const httpService = container.get(HttpService);
    return httpService.post<void, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.previousStatusPath}/${eventId}`, null);
  }

  public async nextStatus(eventId: string) {
    const httpService = container.get(HttpService);
    return httpService.post<void, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.nextStatusPath}/${eventId}`, null);
  }

  public async validateStatus(eventId: string) {
    const httpService = container.get(HttpService);
    return httpService.post<void, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.validateStatusPath}/${eventId}`, null);
  }

  public async mergeRequestToEvent(requestId: string, eventId: string) {
    let httpService = container.get<HttpService>(HttpService);
    return httpService.post<void, boolean>(`events/v1/merge-request-event/${requestId}/${eventId}`, null);
  }

  public async closeEvent(closeEventDto: CloseEventDto) {
    const httpService = container.get(HttpService);
    return httpService.post<CloseEventDto, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.closeEventPath}`, closeEventDto);
  }

  public async removeRequestFromEvent(eventId: string, requestId: string) {
    const httpService = container.get(HttpService);
    return httpService.delete(`${EventService.baseUrl}/${EventService.removeRequestFromEventPath}/${eventId}/${requestId}`);
  }

  public async updateEvent(changeEventDto: ChangeEventDto) {
    const httpService = container.get(HttpService);
    return httpService.put<ChangeEventDto, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.updatePath}`, changeEventDto);
  }

  public async createEvent(createEventDto: CreateEventDto) {
    const httpService = container.get(HttpService);
    return httpService.post<CreateEventDto, ItemResult<EventDto>>(`${EventService.baseUrl}/${EventService.createPath}`, createEventDto);
  }

  public async createMergeRequest(createRequestMergeEventItemDto: CreateRequestMergeEventItemDto) {
    const httpService = container.get(HttpService);
    return httpService.post<CreateRequestMergeEventItemDto, ItemResult<EventDto>>(
      `${EventService.baseUrl}/${EventService.createEventMergePath}`,
      createRequestMergeEventItemDto
    );
  }

  public calculateEventDuration(req: EventDurationRequestDto): Promise<EventDurationDto> {
    const httpService = container.get(HttpService);
    return new Promise<EventDurationDto>((resolve, reject) => {
      httpService
        .post<EventDurationRequestDto, ItemResult<EventDurationDto>>(
          `${EventService.baseUrl}/${EventService.calculateEventDurationPath}`,
          req
        )
        .then(response => {
          if (response?.data?.isSuccess && response?.data?.item) resolve(response.data.item);
          else reject(new Error('Something went wrong'));
        })
        .catch((e: Axios.AxiosError) => reject(e));
    });
  }

  public calculateEventDates(req: DatesCalculationRequestDto): Promise<ItemResult<DatesCalculationResultDto>> {
    const httpService = container.get(HttpService);
    return new Promise<ItemResult<DatesCalculationResultDto>>((resolve, reject) => {
      httpService
        .post<DatesCalculationRequestDto, ItemResult<DatesCalculationResultDto>>(
          `${EventService.baseUrl}/${EventService.calculateEventDatesPath}`,
          req
        )
        .then(response => {
          if (response && response.data && response.data.isSuccess && response.data.item) {
            resolve(response.data);
          } else {
            reject(new Error('Something went wrong'));
          }
        })
        .catch((e: Axios.AxiosError) => reject(e));
    });
  }
}
