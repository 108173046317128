import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TheoreticalFormView } from 'stores/assessments/forms/theoretical-form-test-store';
import { Container, Label, Checkbox, Image, Tab, CheckboxProps } from 'semantic-ui-react';
import AnswerComponent from '../components/theoretical-form-answer';
import { OptionSelected } from 'stores/assessments/questionBank/theoretical-test-store';
import Summary from '../components/theoretical-form-summary';
interface MainProps extends WithTranslation, TheoreticalFormView {
  onChange: (property: keyof TheoreticalFormView, value: any) => void;
  noFooter: (bool: boolean) => void;
  isEmployee: boolean;
}

const TheoreticalFormMain: FC<MainProps> = props => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { t, onChange, templateHeader, questionAnswers, isEmployee } = props;

  const { questionIdx, endedAt, noFooter, currentLanguage, answered } = props;

  let item = (questionAnswers || [])[questionIdx];

  if (!item) return null;

  let translatedItem = (item?.questionTranslations || []).find(({ language }) => language.id === currentLanguage);

  if (!translatedItem) translatedItem = item?.question;

  const onAnswered = (value: OptionSelected | 'unavailableMedia') => {
    const newMRQuestions = (questionAnswers || []).map((item, i) => (i === questionIdx ? { ...item, value } : item));
    onChange && onChange('questionAnswers', newMRQuestions);
  };

  const onUnavailableMedia = (_, { checked }: CheckboxProps) => {
    const newMRQuestions = (questionAnswers || []).map((item, i) => (i === questionIdx ? { ...item, unavailableMedia: checked } : item));
    onChange && onChange('questionAnswers', newMRQuestions);
  };

  const questionComponent = (
    <Container className="form-main-question">
      <Label className="form-main-question__label">
        {questionIdx + 1}. {translatedItem?.text}
      </Label>
      <Container className="form-main-question__container">
        {item.mediaContentType === 'Video' && (
          <Checkbox
            disabled={item.answered || !isEmployee}
            className="form-main-question__available-checkbox"
            checked={item.unavailableMedia}
            onChange={onUnavailableMedia}
            label={t('Please, select if video is not available to review.')}
          />
        )}
        {item.mediaContentType === 'Image' ? (
          <Image bordered size="big" centered src={item.imageInfo?.url} alt={item.imageInfo?.title ?? 'Image'} />
        ) : (
          <div className="form-main-question__media-container" dangerouslySetInnerHTML={{ __html: item.content }}></div>
        )}
        <AnswerComponent
          type={item.answerType}
          disabled={item.answered || !isEmployee}
          item={translatedItem}
          value={item.value}
          onChange={onAnswered}
        />
      </Container>
    </Container>
  );

  const evaluationCriteria = (
    <div className="feedback-form__header__evaluation-criteria">
      {templateHeader && templateHeader !== '' ? (
        <pre style={{ fontFamily: 'inherit', whiteSpace: 'break-spaces' }}>{templateHeader}</pre>
      ) : (
        <p></p>
      )}
    </div>
  );

  const panes = [
    {
      menuItem: t('Summary'),
      render: () => (
        <Tab.Pane key="summary" attached={false}>
          <Summary {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('Questions'),
      render: () => (
        <Tab.Pane key="questionComponent" attached={false}>
          {questionComponent}
        </Tab.Pane>
      )
    }
  ];

  const onTabChange = (_, { activeIndex }) => {
    if (endedAt && activeIndex === 0) noFooter(true);
    else noFooter(false);
    setActiveIndex(activeIndex);
  };

  if (endedAt || answered)
    return (
      <Container className="form-main-content">
        {evaluationCriteria}

        <Tab
          className="form-main-tabs"
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </Container>
    );

  return (
    <Container className="form-main-content">
      {evaluationCriteria}
      {questionComponent}
    </Container>
  );
};

export default withTranslation()(TheoreticalFormMain);
