import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Label, Grid } from 'semantic-ui-react';
import { PositionCodeDto, PositionCodesStore } from '../../../../../stores/configuration/profiles/position-codes-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import NewPositionCodeView from '../positionCode/new-positionCode';
import ChangePositionCodeView from '../positionCode/edit-positionCode';

export interface PositionCodeListProps extends WithTranslation, RouteComponentProps {
  positionCodes: PositionCodesStore;
}

export interface PositionCodeListState {
  query: Query;
  newPositionCodeShown: boolean;
  changePositionCodeShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  filteredByLinked: boolean;
  someFilterOpened: boolean;
}

@connect(['positionCodes', PositionCodesStore])
class PositionCodeListPage extends React.Component<PositionCodeListProps, PositionCodeListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newPositionCodeShown: false,
      changePositionCodeShown: false,
      activeFilters: [],
      selectedItem: null,
      filteredByLinked: false,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.positionCodes.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: PositionCodeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.positionCodes.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: PositionCodeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.positionCodes.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newPositionCodeShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newPositionCodeShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changePositionCodeShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changePositionCodeShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onFilterLinked() {
    let searchQuery: string = '';

    if (this.state.filteredByLinked === false) {
      searchQuery = 'locationId eq ' + null;
      this.setState({ filteredByLinked: true });
    } else this.setState({ filteredByLinked: false });

    const activeFilters = [searchQuery];
    const filter = searchQuery;

    const query = Object.assign(this.state.query, { filter, skip: 0 });

    this.setState({ query, activeFilters }, () => this.load());
  }

  private getKeywordsInPositionCode = (data: PositionCodeDto) => {
    if (data.keywords) {
      return (
        <>
          {data.keywords.map((keyword, index) => {
            return (
              <React.Fragment key={`${data.id}- ${index}`}>
                {' '}
                <Label style={{ marginTop: 3, marginBottom: 3 }} circular className="event-type-label label-4">
                  {keyword}
                </Label>{' '}
              </React.Fragment>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Code'),
          tooltipRenderer: true,
          renderer: data => data.code,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Code')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<PositionCodeDto>('code'), { Code: { contains: value } })}
              onClear={() => onClear(nameof<PositionCodeDto>('code'))}
              active={activeFilters.includes(nameof<PositionCodeDto>('code'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PositionCodeDto>('code'),
            useProfile: false
          }
        },
        {
          title: t('Description'),
          tooltipRenderer: true,
          renderer: data => data.description,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Description')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<PositionCodeDto>('description'), { Description: { contains: value } })}
              onClear={() => onClear(nameof<PositionCodeDto>('description'))}
              active={activeFilters.includes(nameof<PositionCodeDto>('description'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PositionCodeDto>('description'),
            useProfile: false
          }
        },
        {
          title: t('Keywords'),
          renderer: data => this.getKeywordsInPositionCode(data)
        }
      ],
      data: this.props.positionCodes.state
    } as TableModel<PositionCodeDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.positionCodes.state.result && !this.props.positionCodes.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.positionCodes.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newPositionCodeShown && !this.state.changePositionCodeShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil alternate" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              onNewItem={this.onNewItem}
              createNewButtonTitle={t('New Position Code')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newPositionCodeShown && <NewPositionCodeView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changePositionCodeShown && (
            <ChangePositionCodeView onClose={this.onEditItemClosed} currentPositionCode={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(PositionCodeListPage);
