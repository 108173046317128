import { WithTranslation, withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface CreateEventButtonProps extends WithTranslation {
  text: string;
  onCreatedEvent?: (event?) => void;
  eventFormStore?: EventFormStore;
}

@connect(['eventFormStore', EventFormStore])
class CreateEventButton extends Component<CreateEventButtonProps> {
  private handleOnCreateClicked = async () => {
    try {
      const { isSuccess, item } = await this.props.eventFormStore.createMergeRequest();
      if (isSuccess) {
        this.props.onCreatedEvent && this.props.onCreatedEvent(item);
        ToastComponent({ text: 'Operation completed', type: 'success-toast' });
      }
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    }
  };

  render() {
    const { text } = this.props;

    return <Button className="form__actions__button form__next-status-btn" positive content={text} onClick={this.handleOnCreateClicked} />;
  }
}

export default withTranslation()(CreateEventButton);
