import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { TnaQuestionBankDto } from 'stores/assessments/questionBank/tna-questionBank-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { TheoreticalQuestionMRItemDto, MRTheoreticalQuestionBanksStore } from 'stores/assessments/questionBank/theoretical-test-store';
import TestCategorySelector, { CategoryItemReference } from 'widgets/bussiness/selectors/test-category-selector';
import TrainingLevelEditor from 'widgets/bussiness/selectors/training-level-selector';
import PlcTypeEditor from 'widgets/bussiness/plc-type-editor';
import MachineUnitEditor from 'widgets/bussiness/machine-unit-editor';

interface ChooseMrTheoreticalQuestionsViewProps extends WithTranslation {
  mrTheoreticalQuestionBanksStore?: MRTheoreticalQuestionBanksStore;
  alreadySelectedQuestions: string[];
  professionId?: string;
  clusterId?: string;
  machineModelId?: string;
  equipmentTypeId?: string;
  oemId?: string;
  onAddTheoreticalQuestions: (questions: TheoreticalQuestionMRItemDto[]) => void;
  onCloseModal?: () => void;
}

interface ChooseTheoreticalQuestionsFilters {
  questionTitleOrId: string;
  category: CategoryItemReference;
  trainingLevelId: string;
  machineUnit: ItemReference;
  plcType: ItemReference;
}

interface ChooseTheoreticalQuestionsViewState {
  query: Query;
  selectedQuestions: TheoreticalQuestionMRItemDto[];
  filters: ChooseTheoreticalQuestionsFilters;
}

@connect(['mrTheoreticalQuestionBanksStore', MRTheoreticalQuestionBanksStore])
class ChooseMrTheoreticalQuestionsView extends Component<ChooseMrTheoreticalQuestionsViewProps, ChooseTheoreticalQuestionsViewState> {
  state: ChooseTheoreticalQuestionsViewState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    selectedQuestions: null,
    filters: {
      questionTitleOrId: '',
      category: null,
      trainingLevelId: null,
      machineUnit: null,
      plcType: null
    }
  };

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  private onAddQuestions = () => {
    this.props.onAddTheoreticalQuestions(this.state.selectedQuestions);
    this.props.onCloseModal();
  };

  private onCancel = () => {
    this.props.onCloseModal();
  };

  private load = async () => {
    await this.props.mrTheoreticalQuestionBanksStore.getAllAsync(this.state.query);
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleFilterChange = (filters, oDatafilters) => {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  };

  private handleOnFiltersChange = (property: keyof ChooseTheoreticalQuestionsFilters, value: any) => {
    const filters = { ...this.state.filters };
    filters[property as string] = value;
    this.refreshTable(filters);
  };

  private selectedTnaQuestions = (selectedQuestions: TheoreticalQuestionMRItemDto[]) => {
    this.setState({ selectedQuestions });
  };

  private refreshTable = (filters: ChooseTheoreticalQuestionsFilters) => {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  };

  private buildODataFilter = (filters?: ChooseTheoreticalQuestionsFilters) => {
    const { alreadySelectedQuestions, professionId, equipmentTypeId, oemId, clusterId, machineModelId } = this.props;
    const oDataFilters = [];

    if ((alreadySelectedQuestions || []).length > 0) oDataFilters.push(`not(Id in (${alreadySelectedQuestions.join(',')}))`);

    if (professionId) oDataFilters.push({ Professions: { any: { id: { eq: { value: professionId, type: 'guid' } } } } });

    if (equipmentTypeId) oDataFilters.push({ EquipmentTypeId: { eq: { type: 'guid', value: equipmentTypeId } } });

    if (oemId) oDataFilters.push({ OemId: { eq: { type: 'guid', value: oemId } } });

    if (clusterId) oDataFilters.push({ ClusterId: { eq: { type: 'guid', value: clusterId } } });

    if (machineModelId) oDataFilters.push({ MachineModelId: { eq: { type: 'guid', value: machineModelId } } });

    if (!!filters.category) oDataFilters.push({ TestCategoryId: { eq: { type: 'guid', value: filters.category.id } } });

    if (!!filters.trainingLevelId) oDataFilters.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.trainingLevelId } } });

    if (!!filters.machineUnit) oDataFilters.push({ MachineUnitId: { eq: { type: 'guid', value: filters.machineUnit.id } } });

    if (!!filters.plcType) oDataFilters.push({ PlcTypeId: { eq: { type: 'guid', value: filters.plcType.id } } });

    if (!isNullOrWhiteSpaces(filters.questionTitleOrId)) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QMRTh');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QMRTh')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    return oDataFilters;
  };

  public render() {
    const { t, equipmentTypeId, oemId, mrTheoreticalQuestionBanksStore } = this.props;
    const { category, machineUnit, plcType, questionTitleOrId, trainingLevelId } = this.state.filters;
    const qbCellTag = 'question-bank__cell__tag';

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data?.friendlyId || '',
          selectableHeader: true,
          sortDefinition: { field: nameof<TnaQuestionBankDto>('friendlyId'), useProfile: false }
        },
        { title: t('Questions'), tooltipRenderer: false, renderer: data => <div>{data?.question?.text || ''}</div>, electableHeader: true },
        {
          title: t('Category'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.testCategory ? qbCellTag : 'text__center'}>{data?.testCategory?.name || '-'}</span>,
          selectableHeader: true
        },
        {
          title: t('Machine Unit'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.machineUnit ? qbCellTag : 'text__center'}>{data?.machineUnit?.name || '-'}</span>,
          selectableHeader: true
        },
        {
          title: t('PLC Type'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.plcType ? qbCellTag : 'text__center'}>{data?.plcType?.name || '-'}</span>,
          selectableHeader: true
        },
        {
          title: t('Training Level'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.trainingLevel ? qbCellTag : 'text__center'}>{data?.trainingLevel?.name || '-'}</span>,
          selectableHeader: true
        }
      ],
      data: mrTheoreticalQuestionBanksStore.state
    } as TableModel<TheoreticalQuestionMRItemDto>;

    const modalClass = 'template-search__modal theoretical__questions-modal';

    return (
      <Modal size={'large'} className={modalClass} open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content scroll">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              value={questionTitleOrId}
              onChange={(_, { value }) => this.handleOnFiltersChange('questionTitleOrId', value)}
            />
            <TestCategorySelector
              clearable
              nullable
              isTnaCategory={true}
              className="planit-user-dropdown-custom"
              placeholder={t('Category')}
              value={category}
              onChange={value => this.handleOnFiltersChange('category', value)}
            />

            <TrainingLevelEditor
              clearable
              nullable
              value={trainingLevelId}
              onChange={value => this.handleOnFiltersChange('trainingLevelId', value)}
              placeholder={t('Training Level')}
              searchable
            />

            <MachineUnitEditor
              clearable
              nullable
              className="template-wizard__machine-unit"
              value={machineUnit}
              oemId={oemId}
              equipmentId={equipmentTypeId}
              placeholder={t('Machine Unit')}
              onChange={value => this.handleOnFiltersChange('machineUnit', value)}
            />

            <PlcTypeEditor
              clearable
              nullable
              className="template-wizard__machine-unit"
              value={plcType}
              oemId={oemId}
              equipmentId={equipmentTypeId}
              onChange={value => this.handleOnFiltersChange('plcType', value)}
              placeholder={t('PLC Type')}
            />
          </div>

          <Container className="template-list-table theoretical-questions-list">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={this.selectedTnaQuestions}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseMrTheoreticalQuestionsView);
