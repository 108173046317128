import { BaseDto } from '../../types';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { QuestionDto, CreateQuestionDto, ChangeQuestionDto, QuestionDtoValidator } from './tna-questionBank-store';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { SsaSkillDto } from '../skills/ssa-skills-store';
import { TestCategoryDto } from '../testCategory-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';

export interface SsaQuestionBankDto extends BaseDto {
  id: string; //Guid
  friendlyId: string;
  question: QuestionDto;
  ssaSkillId: string;
  ssaSkill: SsaSkillDto;
  testCategoryId: string;
  testCategory: TestCategoryDto;
  professions: ProfessionDto[];
  questionTranslations: QuestionDto[];
}

export interface CreateSsaQuestionBankDto {
  question: CreateQuestionDto;
  ssaSkillId: string;
  testCategoryId: string;
  professions: string[];
  questionTranslations: CreateQuestionDto[];
}

export interface ChangeSsaQuestionBankDto {
  id: string; //Guid
  friendlyId: string;
  question: ChangeQuestionDto;
  ssaSkillId: string;
  testCategoryId: string;
  professions: string[];
  questionTranslations: ChangeQuestionDto[];
}

export class CreateSsaQuestionBankValidator extends ExtendedAbstractValidator<CreateSsaQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.testCategoryId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIf(o => o.professions)
      .isNotEmpty()
      .withFailureMessage(i18n.t('role(s) required'));

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new QuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new QuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors).extendValidate(t).isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

export class ChangeSsaQuestionBankValidator extends ExtendedAbstractValidator<ChangeSsaQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Question Bank Id is required'));

    this.validateIfString(o => o.testCategoryId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIf(o => o.professions)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role(s) required'));

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new QuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new QuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors).extendValidate(t).isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

@repository('@@SSAQUESTIONBANKS', 'ssaquestionbanks.summary')
export class SsaQuestionBanksStore extends DataStore<SsaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-question';
  retrievePath = 'get-ssa-questions';
  updatePath = 'update-ssa-question';
  deletePath = 'delete-ssa-question';
  retrieveOnePath = 'get-ssa-question';
  retrieveCountPath = 'get-used-ssa-question-count';

  protected validate(item: SsaQuestionBankDto) {
    return new ValidationResult();
  }

  constructor() {
    super('SSAQUESTIONBANK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getQuestionBankById(id: string): Promise<SsaQuestionBankDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<SsaQuestionBankDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedTnaQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`);

    return result.data;
  }
}

@repository('@@SSAQUESTIONBANKS', 'ssaquestionbanks.new')
export class NewSsaQuestionBankStore extends FormStore<CreateSsaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-question';
  retrievePath = 'get-ssa-questions';
  updatePath = 'update-ssa-question';

  protected validate(item: CreateSsaQuestionBankDto) {
    return new CreateSsaQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_SSAQUESTIONBANKS', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@SSAQUESTIONBANKS', 'ssaquestionbanks.change')
export class ChangeSsaQuestionBankStore extends FormStore<ChangeSsaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-question';
  retrievePath = 'get-ssa-questions';
  updatePath = 'update-ssa-question';

  protected validate(item: ChangeSsaQuestionBankDto) {
    return new ChangeSsaQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('CHANGE_SSAQUESTIONBANKS', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
