import React, { Component } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import EquipmentTypeEditor from '../../../../../widgets/bussiness/equipment-type-editor';
import { ChangePatternRowDto } from '../../../../../stores/configuration/machinery/pattern-rows-store';
import OemEditor from '../../../../../widgets/bussiness/oem-editor';
import MachineModelEditor from '../../../../../widgets/bussiness/machine-model-editor';
import MRClusterEditor from '../../../../../widgets/bussiness/mrcluster-editor';

interface PaternRowItemProps extends WithTranslation {
  patternRow?: ChangePatternRowDto;
  index: number;
  onDelete: (index: number, row: ChangePatternRowDto) => void;
  onChange?: (patternRow: ChangePatternRowDto, idx: number) => void;
}

interface PaternRowItemState {
  oem: string;
  equipmentType: string;
  machineModel: string;
  machineRelatedCluster: string;
  index: number;
  patternRow: ChangePatternRowDto;
}

class PaternRowEditItem extends Component<PaternRowItemProps, PaternRowItemState> {
  private get changePatternRowStore() {
    return this.state.patternRow;
  }

  UNSAFE_componentWillReceiveProps(next: PaternRowItemProps) {
    if (next != null && next !== this.props) {
      const { index, patternRow } = next;
      const { equipmentTypeId, oemId, machineModelId, machineRelatedClusterId } = patternRow;
      this.setState({
        index,
        patternRow,
        oem: oemId,
        equipmentType: equipmentTypeId,
        machineModel: machineModelId,
        machineRelatedCluster: machineRelatedClusterId
      });
    }
  }

  constructor(props: PaternRowItemProps) {
    super(props);

    this.state = {
      oem: this.props.patternRow.oemId,
      equipmentType: this.props.patternRow.equipmentTypeId,
      machineModel: this.props.patternRow.machineModelId,
      machineRelatedCluster: this.props.patternRow.machineRelatedClusterId,
      patternRow: this.props.patternRow,
      index: this.props.index
    };
  }

  private onChangeEqType = id => {
    this.setState(
      {
        equipmentType: id
      },
      () => {
        if (this.props.onChange) {
          const nextPattern = Object.assign({}, this.changePatternRowStore, { equipmentTypeId: id });
          this.props.onChange(nextPattern, this.state.index);
        }
      }
    );
  };

  private onChangeOem = id => {
    this.setState(
      {
        oem: id
      },
      () => {
        if (this.props.onChange) {
          const nextPattern = Object.assign({}, this.changePatternRowStore, { oemId: id });
          this.props.onChange(nextPattern, this.state.index);
        }
      }
    );
  };

  private onChangeMModel = id => {
    this.setState(
      {
        machineModel: id
      },
      () => {
        if (this.props.onChange) {
          const nextPattern = Object.assign({}, this.changePatternRowStore, { machineModelId: id });
          this.props.onChange(nextPattern, this.state.index);
        }
      }
    );
  };

  private onChangeCluster = id => {
    this.setState(
      {
        machineRelatedCluster: id,
        equipmentType: undefined
      },
      () => {
        if (this.props.onChange) {
          const nextPattern = Object.assign({}, this.changePatternRowStore, { machineRelatedClusterId: id, equipmentTypeId: undefined });
          this.props.onChange(nextPattern, this.state.index);
        }
      }
    );
  };

  render() {
    const { t } = this.props as any;

    return (
      <Form.Group key={this.props.index}>
        <Form.Field required width={4}>
          <div className={`required field`}>
            <label>{t('Cluster')}</label>
            <MRClusterEditor
              placeholder={t('Cluster')}
              nullable
              value={this.state.machineRelatedCluster}
              onChange={c => {
                this.onChangeCluster(c ? c.id : null);
              }}
            />
          </div>
        </Form.Field>

        <Form.Field required width={12} disabled={this.state.machineRelatedCluster == null}>
          <div className={`required field`}>
            <label>{t('Equipment Type')}</label>
            <EquipmentTypeEditor
              placeholder={t('Equipment Type')}
              nullable
              clusterId={this.state.machineRelatedCluster ? this.state.machineRelatedCluster : undefined}
              value={this.state.equipmentType}
              onChange={c => this.onChangeEqType(c == null ? null : c.id)}
            />
          </div>
        </Form.Field>

        <Form.Field width={4} disabled={this.state.equipmentType == null}>
          <div className={`field`}>
            <label>{t('OEM')}</label>
            <OemEditor
              equipmentId={this.state.equipmentType ? this.state.equipmentType : undefined}
              placeholder={t('OEM')}
              nullable
              value={this.state.oem}
              onChange={c => this.onChangeOem(c == null ? null : c.id)}
            />
          </div>
        </Form.Field>

        <Form.Field width={4} disabled={this.state.equipmentType == null}>
          <div className={`field`}>
            <label>{t('Machine Model')}</label>
            <MachineModelEditor
              placeholder={t('Machine Model')}
              nullable
              value={this.state.machineModel}
              oemId={this.state.oem ? this.state.oem : undefined}
              equipmentId={this.state.equipmentType ? this.state.equipmentType : undefined}
              onChange={c => this.onChangeMModel(c == null ? null : c.id)}
            />
          </div>
        </Form.Field>
        <div className="new-pattern__remove-icon">
          <Icon
            className="clickable-icon remove-icon"
            color="red"
            name="remove"
            onClick={() => this.props.onDelete(this.props.index, this.props.patternRow)}
          />
        </div>
      </Form.Group>
    );
  }
}

export default withTranslation()(PaternRowEditItem);
