import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import { CreateMachineUnitDto, NewMachineUnitStore } from '../../../../../stores/configuration/machinery/machine-units-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';

interface NewMachineUnitViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newMachineUnit?: NewMachineUnitStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewMachineUnitViewState {
  cluster: ItemReference;
  oem: ItemReference;
  equipmentType: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newMachineUnit', NewMachineUnitStore])
class NewMachineUnitView extends React.Component<NewMachineUnitViewProps, NewMachineUnitViewState> {
  private get newMachineUnitStore() {
    return this.props.newMachineUnit;
  }

  constructor(props: NewMachineUnitViewProps) {
    super(props);

    this.state = {
      cluster: null,
      equipmentType: null,
      oem: null,
      userClickedSaved: false
    };

    this.newMachineUnitStore.createNew({
      name: '',
      equipmentTypeId: '',
      oemId: ''
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewMachineUnitViewProps) {
    if (this.newMachineUnitStore.state.result && this.newMachineUnitStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newMachineUnitStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newMachineUnitStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newMachineUnitStore.change({ ...this.newMachineUnitStore.state.item, ...change });
  }

  private onChangeCluster(value: ItemReference) {
    this.setState({ cluster: value });
    if (!value) {
      this.setState({ equipmentType: null });
    }
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newMachineUnitStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Machine Unit')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newMachineUnitStore.state.result && !this.newMachineUnitStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newMachineUnitStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newMachineUnitStore.state.item && (
              <Form>
                <Form.Field widths="equal" error={!this.newMachineUnitStore.state.item.name && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('Machine Unit')}
                    placeholder={t('Name')}
                    value={this.newMachineUnitStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateMachineUnitDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field required error={!this.state.cluster && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      placeholder={t('Cluster')}
                      nullable
                      value={this.state.cluster}
                      onChange={c => {
                        this.setState({ cluster: c, userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field required error={!this.newMachineUnitStore.state.item.equipmentTypeId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      placeholder={t('Equipment Type')}
                      nullable
                      value={this.state.equipmentType}
                      readOnly={!this.state.cluster}
                      clusterId={this.state.cluster ? this.state.cluster.id : null}
                      onChange={c => {
                        this.newMachineUnitStore.state.item.equipmentTypeId = c == null ? null : c.id;
                        this.setState({ userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>

                <Form.Field required error={!this.newMachineUnitStore.state.item.oemId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('OEM')}</label>
                    <OemEditor
                      placeholder={t('OEM')}
                      nullable
                      value={this.state.oem}
                      onChange={c => {
                        this.newMachineUnitStore.state.item.oemId = c == null ? null : c.id;
                        this.setState({ userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewMachineUnitView);
