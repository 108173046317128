import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from '../../stores/dataStore';
import { SelectionInput, DropdownProps } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { SsaActivityTypeStore, SsaActivityTypeDto } from 'stores/assessments/ssa-activity-type-store';
import { isNullOrEmpty } from 'utils/useful-functions';

interface SsaActivityTypeEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  ssaActivityTypesStore?: SsaActivityTypeStore;
  reloadOnChange?: boolean;
  clearable?: boolean;
  error?: boolean;
}

interface SsaActivityTypeEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['ssaActivityTypesStore', SsaActivityTypeStore])
class SsaActivityTypeEditor extends React.Component<SsaActivityTypeEditorProps, SsaActivityTypeEditorState> {
  private get ssaActivityTypesStore() {
    return this.props.ssaActivityTypesStore;
  }

  constructor(props: SsaActivityTypeEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.getSsaActivityTypeMethod()
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.value !== this.props.value && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        query: this.getSsaActivityTypeMethod()
      });
    }
  }

  @autobind
  private getSsaActivityTypeMethod() {
    const method = async (search: string) => {
      const result = await this.ssaActivityTypesStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<SsaActivityTypeDto>('title'), useProfile: false }],
        filter: isNullOrEmpty(search) ? [] : [`startswith(tolower(${nameof<SsaActivityTypeDto>('title')}), '${search.toLowerCase()}')`]
      });
      return result as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    return (
      <SelectionInput
        error={this.props.error}
        content={item => <div>{item.title}</div>}
        clearable={this.props.clearable}
        readOnly={this.props.readOnly}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(SsaActivityTypeEditor);
