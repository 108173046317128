import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { NewPersonnelAreaStore, CreatePersonnelAreaDto } from 'stores/configuration/locations/personnelArea-store';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import LocationEditor from 'widgets/bussiness/location-editor';
import { ItemReference } from 'stores/dataStore';

interface NewLocationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newPersonnelArea?: NewPersonnelAreaStore;
}

interface NewPersonelAreaViewState {
  location: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newPersonnelArea', NewPersonnelAreaStore])
class NewPersonnelAreaView extends React.Component<NewLocationViewProps, NewPersonelAreaViewState> {
  private get newPersonnelAreaStore() {
    return this.props.newPersonnelArea;
  }

  constructor(props: NewLocationViewProps) {
    super(props);
    this.newPersonnelAreaStore.createNew({
      name: '',
      locationId: ''
    });
    this.state = { location: null, userClickedSaved: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewLocationViewProps) {
    if (this.newPersonnelAreaStore.state.result && this.newPersonnelAreaStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPersonnelAreaStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPersonnelAreaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newPersonnelAreaStore.change({ ...this.newPersonnelAreaStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPersonnelAreaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Personnel Area')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newPersonnelAreaStore.state.result && !this.newPersonnelAreaStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPersonnelAreaStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newPersonnelAreaStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required error={!this.newPersonnelAreaStore.state.item.name && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid={!this.newPersonnelAreaStore.state.item.name}
                      label={t('Personnel Area')}
                      placeholder={t('Name')}
                      value={this.newPersonnelAreaStore.state.item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<CreatePersonnelAreaDto>('name'), value)}
                    />
                  </Form.Field>
                  <Form.Field required error={!this.newPersonnelAreaStore.state.item.locationId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newPersonnelAreaStore.state.item.locationId == null ||
                        isNullOrWhiteSpaces(this.newPersonnelAreaStore.state.item.locationId)} field`}
                    >
                      <label>{t('Location')}</label>
                      <LocationEditor
                        value={this.state.location}
                        placeholder={t('Location')}
                        onChange={location => {
                          this.newPersonnelAreaStore.state.item.locationId = location.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPersonnelAreaView);
