import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import RestrictionWarningListPage from './restrictions-warnings-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface RestrictionWarningConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface RestrictionWarningConfigurationHomeState {}

class RestrictionWarningConfigurationHomePage extends React.Component<
  RestrictionWarningConfigurationHomeProps,
  RestrictionWarningConfigurationHomeState
> {
  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Restrictions & Warnings')}</h3>
        <LineSeparator />
        <RestrictionWarningListPage />
      </>
    );
  }
}

export default withTranslation()(RestrictionWarningConfigurationHomePage);
