import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import ChangeStatusPopup from '../popups/change-status-popup/change-status-popup';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { getPreviousStatus, hasAdministrationRights } from 'utils/event-utils';

export interface PreviousStateButtonProps extends WithTranslation {
  onPrevious?: () => void;
  eventFormStore?: EventFormStore;
  readOnly: boolean;
}

export interface PreviousStateButtonState {
  showChangeStatusModal: boolean;
}

@connect(['eventFormStore', EventFormStore])
class PreviousStateButton extends Component<PreviousStateButtonProps, PreviousStateButtonState> {
  state: PreviousStateButtonState = {
    showChangeStatusModal: false
  };

  private handleOnPreviousStatusClicked = () => {
    this.setState({ showChangeStatusModal: true });
  };

  private handleOnAcceptedClicked = () => {
    this.setState({ showChangeStatusModal: false });
    this.props.eventFormStore.previousStatus().then(() => {
      this.props.onPrevious && this.props.onPrevious();
    });
  };

  private isPreviousStatusButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    if (!hasAdministrationRights(event)) return false;

    const previousStatus = getPreviousStatus(event);
    return !!previousStatus;
  };

  render() {
    const { t, readOnly, eventFormStore } = this.props;
    const { showChangeStatusModal } = this.state;
    const isVisible = this.isPreviousStatusButtonVisible();

    if (!isVisible) return null;

    return (
      <>
        <Button
          className="form__actions__button form__prev-status-btn"
          disabled={!readOnly}
          icon="arrow left"
          content={t('Previous Status')}
          labelPosition="left"
          onClick={this.handleOnPreviousStatusClicked}
        />

        <ChangeStatusPopup
          eventFormStore={eventFormStore}
          open={showChangeStatusModal}
          onAcceptClicked={this.handleOnAcceptedClicked}
          onCancelClicked={() => this.setState({ showChangeStatusModal: false })}
        />
      </>
    );
  }
}

export default withTranslation()(PreviousStateButton);
