import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Button, Message, Dimmer, Loader, Input } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import LocationEditor, { LocationItemReference } from 'widgets/bussiness/location-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import YearEditor from 'widgets/bussiness/year-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import './tna-individual-tab-search.less';
import { TnaDevelopmentPlanStore } from 'stores/development-plan/tna-development-plan.store';
import { connect } from 'redux-scaffolding-ts';

export interface IndividualTabPaneSearchProps extends WithTranslation {
  tnaDevelopmentPlanStore?: TnaDevelopmentPlanStore;
  onSearch?: (filters: IndividualTabPaneSearchFilters) => void;
  onFilterChange?: (filters: IndividualTabPaneSearchFilters) => void;
  canSearch: boolean;
}

export interface IndividualTabPaneSearchFilters {
  employee: string;
  eventType: ItemReference;
  location: LocationItemReference;
  year?: number;
  level: ItemReference;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
}

interface IndividualTabPaneSearchState {
  filters: IndividualTabPaneSearchFilters;
  loading: boolean;
}

@connect(['tnaDevelopmentPlanStore', TnaDevelopmentPlanStore])
class IndividualTabPaneSearch extends React.Component<IndividualTabPaneSearchProps, IndividualTabPaneSearchState> {
  constructor(props: IndividualTabPaneSearchProps) {
    super(props);

    this.state = {
      filters: {
        cluster: null,
        employee: '',
        equipmentType: null,
        eventType: null,
        level: null,
        location: null,
        machineModel: null,
        oem: null,
        year: null
      },
      loading: false
    };
  }

  private onHandleChangeFilter = (property: string, value: any) => {
    let filters = { ...this.state.filters };
    filters[property] = value;

    if (property === nameof<IndividualTabPaneSearchFilters>('cluster')) {
      filters.equipmentType = undefined;
      filters.oem = undefined;
      filters.machineModel = undefined;
    }

    if (property === nameof<IndividualTabPaneSearchFilters>('equipmentType')) {
      filters.oem = undefined;
      filters.machineModel = undefined;
    }

    if (property === nameof<IndividualTabPaneSearchFilters>('oem')) {
      filters.machineModel = undefined;
    }

    this.setState({ filters });
    this.props.onFilterChange && this.props.onFilterChange(filters);
  };

  private onSearchEmployees = () => {
    this.props.onSearch && this.props.onSearch(this.state.filters);
  };

  render() {
    const { t, tnaDevelopmentPlanStore: store, canSearch } = this.props;
    const { loading, filters } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <div className="individual-tab-pane-search">
        {loading && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading')}</Loader>
          </Dimmer>
        )}

        <div className="individual-tab-pane-search__container">
          <h4 className="individual-tab-pane-search__container__title">{t('DEFINE YOUR SEARCH')}</h4>
          {messages.length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={messages.map(o => o.body)}
              onDismiss={() => this.props.tnaDevelopmentPlanStore.clearMessages()}
            />
          )}
          <Form className="individual-tab-pane-search__container__search-form">
            <div className="search-form__wrapper">
              <div className="search-form__filters">
                <Form.Field className="filters-container-field filters__employee-wrapper">
                  <label>{t('Employee')}</label>
                  <Input
                    className="custom-editor-search"
                    icon="search"
                    placeholder={t('Search Employee')}
                    value={filters.employee}
                    onChange={(_, { value }) => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('employee'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Activity')}</label>
                  <EventTypeEditor
                    nullable
                    clearable
                    className="custom-editor"
                    placeholder={t('Event Type')}
                    useOriginalEventTypeIdAsValue
                    value={filters.eventType}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('eventType'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Location')}</label>
                  <LocationEditor
                    nullable
                    clearable
                    className="custom-editor"
                    placeholder={t('Location')}
                    filterLocationsByActiveRole
                    value={filters.location}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('location'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Year')}</label>
                  <YearEditor
                    clearable
                    placeholder={t('Year')}
                    className="custom-editor year-editor"
                    value={filters.year}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('year'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Level')}</label>
                  <TrainingLevelEditor
                    nullable
                    clearable
                    placeholder={t('Level')}
                    className="custom-editor"
                    value={filters.level}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('level'), value)}
                  />
                </Form.Field>
                <div className="break"></div>
                <Form.Field className="filters-container-field">
                  <label>{t('Cluster')}</label>
                  <MRClusterEditor
                    className="custom-editor"
                    clearable
                    nullable
                    placeholder={t('Cluster')}
                    value={filters.cluster}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('cluster'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Equipment Type')}</label>
                  <EquipmentTypeEditor
                    className="custom-editor"
                    clearable
                    nullable
                    placeholder={t('Equipment Type')}
                    readOnly={!filters.cluster || !filters.cluster.id}
                    clusterId={filters.cluster ? filters.cluster.id : null}
                    value={filters.equipmentType}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('equipmentType'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('OEM')}</label>
                  <OemEditor
                    className="custom-editor"
                    nullable
                    clearable
                    placeholder={t('OEM')}
                    readonly={!filters.cluster || !filters.cluster.id || !filters.equipmentType || !filters.equipmentType.id}
                    equipmentId={filters.equipmentType ? filters.equipmentType.id : null}
                    value={filters.oem}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('oem'), value)}
                  />
                </Form.Field>
                <Form.Field className="filters-container-field">
                  <label>{t('Machine Model')}</label>
                  <MachineModelEditor
                    className="custom-editor"
                    nullable
                    clearable
                    placeholder={t('Machine Model')}
                    value={filters.machineModel}
                    readonly={
                      !filters.equipmentType ||
                      !filters.equipmentType.id ||
                      !filters.oem ||
                      !filters.oem.id ||
                      !filters.cluster ||
                      !filters.cluster.id
                    }
                    oemId={filters.oem ? filters.oem.id : null}
                    equipmentId={filters.equipmentType ? filters.equipmentType.id : null}
                    clusterId={filters.cluster ? filters.cluster.id : null}
                    onChange={value => this.onHandleChangeFilter(nameof<IndividualTabPaneSearchFilters>('machineModel'), value)}
                  />
                </Form.Field>
              </div>
            </div>
            <div className="search-form__btn-wrapper">
              <Button className="search-form__search-employees-btn basic" onClick={this.onSearchEmployees} disabled={!canSearch} icon>
                {t('Search Employees')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(IndividualTabPaneSearch);
