import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import { EventWizardData } from 'stores/events/creation-wizard-store';
import DeliveryMethodEditor from 'widgets/bussiness/delivery-method-editor';
import LanguageEditor from 'widgets/bussiness/language-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import CustomizationDataEditor from 'widgets/bussiness/customization-data-editor';
import { nameof } from 'utils/object';
import { StudentDto } from 'stores/students/student-model';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

interface EventDetailsStepProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, string][]) => void;
}

interface EventDetailsStepState {
  wizardData: EventWizardData;
  isOpenedUsersModal: boolean;
  originalTitle: string;
  titleFocused: boolean;
}

class EventDetailStep extends Component<EventDetailsStepProps, EventDetailsStepState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);

    this.state = {
      wizardData: props.wizardData,
      isOpenedUsersModal: false,
      originalTitle: '',
      titleFocused: false
    };
  }

  private onValueChanged = (...changes: [string, any][]) => {
    const { wizardData } = this.state;
    changes.forEach(([prop, value]) => {
      wizardData[prop] = value;
    });
    this.setState(
      {
        wizardData
      },
      () => this.props.onValueChanged(...changes)
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps: EventDetailsStepProps) {
    if (nextProps && nextProps !== this.props) {
      this.setState({
        wizardData: nextProps.wizardData
      });
    }
  }

  componentDidMount() {
    const { wizardData } = this.state;

    let parts = [];

    if (wizardData.isMachineRelated) {
      if (wizardData.pattern == null) {
        const mModel =
          wizardData.requestMachines != null &&
          wizardData.requestMachines.length !== 0 &&
          wizardData.requestMachines[0] != null &&
          wizardData.requestMachines[0].machineModel != null &&
          !isNullOrWhiteSpaces(wizardData.requestMachines[0].machineModel.title)
            ? wizardData.requestMachines[0].machineModel.title.trim()
            : '';
        const plc = wizardData.plcNames != null ? wizardData.plcNames.join(' ').trim() : '';
        const role =
          wizardData.profession != null && !isNullOrWhiteSpaces(wizardData.profession.title) ? wizardData.profession.title.trim() : '';
        parts = [mModel, plc, role];
      } else {
        const pattern = wizardData.pattern != null && !isNullOrWhiteSpaces(wizardData.pattern.title) ? wizardData.pattern.title.trim() : '';
        const role =
          wizardData.profession != null && !isNullOrWhiteSpaces(wizardData.profession.title) ? wizardData.profession.title.trim() : '';
        parts = [pattern, role];
      }
    } else {
      const nmrCluster =
        wizardData.nmrCluster != null && !isNullOrWhiteSpaces(wizardData.nmrCluster.title) ? wizardData.nmrCluster.title.trim() : '';
      const nmrFunctionalArea =
        wizardData.nmrFunctionalArea != null && !isNullOrWhiteSpaces(wizardData.nmrFunctionalArea.title)
          ? wizardData.nmrFunctionalArea.title.trim()
          : '';
      const nmrTrainingName =
        wizardData.nmrTrainingName != null && !isNullOrWhiteSpaces(wizardData.nmrTrainingName.title)
          ? wizardData.nmrTrainingName.title.trim()
          : '';
      const nmrFunctionalSubArea =
        wizardData.nmrFunctionalSubArea != null && !isNullOrWhiteSpaces(wizardData.nmrFunctionalSubArea.title)
          ? wizardData.nmrFunctionalSubArea.title.trim()
          : '';
      const role =
        wizardData.profession != null && !isNullOrWhiteSpaces(wizardData.profession.title) ? wizardData.profession.title.trim() : '';

      parts = [nmrCluster, nmrFunctionalArea, nmrTrainingName, nmrFunctionalSubArea, role];
    }

    let originalTitle = '';
    parts.forEach(s => {
      originalTitle = `${originalTitle} ${s}`.trim();
    });

    let compoundTitle = originalTitle;
    let trLvl = '';
    if (wizardData.trainingLevel && !isNullOrWhiteSpaces(wizardData.trainingLevel.title)) {
      trLvl = wizardData.trainingLevel.title.trim();
    }

    compoundTitle = `${compoundTitle} ${trLvl}`.trim();

    if (wizardData.isMachineRelated && wizardData.customizedData != null) {
      compoundTitle = `${compoundTitle} Custom`;
    }

    compoundTitle = compoundTitle.trim();

    if (!wizardData.manualTitle) {
      this.setState({ originalTitle });
      this.onValueChanged(['eventTitle', compoundTitle]);
    } else {
      this.setState({ originalTitle: wizardData.eventTitle });
    }
  }

  private onManuallyChangeTitle = (val: string) => {
    if (!this.state.titleFocused) {
      const current = this.state.wizardData.eventTitle;
      if (current !== val) {
        this.setState({ originalTitle: val }, () => this.onValueChanged(['eventTitle', val], ['manualTitle', true]));
      }
    } else {
      this.onValueChanged(['eventTitle', val], ['manualTitle', true]);
    }
  };

  private onCustomizationChange = (c: ItemReference) => {
    const { manualTitle } = this.state.wizardData;
    if (manualTitle) {
      this.onValueChanged([nameof<EventWizardData>('customizedData'), c]);
    } else {
      const { originalTitle } = this.state;
      const { wizardData } = this.state;
      let trLvl = '';
      if (wizardData.trainingLevel && !isNullOrWhiteSpaces(wizardData.trainingLevel.title)) trLvl = wizardData.trainingLevel.title.trim();

      let changeTitle = `${originalTitle || ''} ${trLvl}`.trim();

      if (wizardData.isMachineRelated) changeTitle = `${changeTitle} ${c != null ? 'Custom' : ''}`;
      this.onValueChanged([nameof<EventWizardData>('customizedData'), c], [nameof<EventWizardData>('eventTitle'), changeTitle.trim()]);
    }
  };

  private onTrainingLevelChange = (c: ItemReference) => {
    const { manualTitle } = this.state.wizardData;
    if (manualTitle) {
      this.onValueChanged([nameof<EventWizardData>('trainingLevel'), c]);
    } else {
      const { originalTitle } = this.state;
      const { wizardData } = this.state;
      let trLvl = '';
      if (c != null && !isNullOrWhiteSpaces(c.title)) trLvl = c.title.trim();

      let changeTitle = `${originalTitle || ''} ${trLvl}`.trim();

      if (wizardData.isMachineRelated) changeTitle = `${changeTitle} ${wizardData.customizedData != null ? 'Custom' : ''}`;
      this.onValueChanged([nameof<EventWizardData>('trainingLevel'), c], [nameof<EventWizardData>('eventTitle'), changeTitle.trim()]);
    }
  };

  render() {
    const { t } = this.props as any;
    const { wizardData } = this.state;

    return (
      <Form className="wizard__event-details__3step">
        <div className="event-details__3step__row__element field">
          <label>{t('Event Title')}</label>
          <Input
            className="request-title__input"
            fluid
            value={this.state.wizardData.eventTitle}
            onChange={(_, { value }) => this.onManuallyChangeTitle(value)}
            onFocus={() => this.setState({ titleFocused: true })}
            onBlur={() => this.setState({ titleFocused: false })}
          />
        </div>

        <div className="event-details-row__wrapper">
          <div className="event-details__row__element">
            <label>{t('Customization')}</label>

            <CustomizationDataEditor nullable clearable={true} value={wizardData.customizedData} onChange={this.onCustomizationChange} />
          </div>

          <div className="event-details__row__element required field">
            <label>{t('Training Level')}</label>

            <TrainingLevelEditor
              className="custom-editor"
              nullable
              value={wizardData.trainingLevel}
              onChange={this.onTrainingLevelChange}
            />
          </div>
        </div>

        <div className="event-details-row__wrapper">
          <div className="event-details__row__element">
            <label>{t('Language')}</label>
            <LanguageEditor nullable clearable={true} value={wizardData.language} onChange={c => this.onValueChanged(['language', c])} />
          </div>

          <div className="event-details__row__element required field">
            <label>{t('Delivery Method')}</label>
            <DeliveryMethodEditor nullable value={wizardData.deliveryMethod} onChange={c => this.onValueChanged(['deliveryMethod', c])} />
          </div>
        </div>
      </Form>
    );
  }

  private handleOnStudentRemove(student: StudentDto) {
    let currentStudents = this.state.wizardData.students.filter(s => s.id !== student.id);
    const max = Math.max(this.state.wizardData.numStudentsAssigned ? this.state.wizardData.numStudentsAssigned : 0, currentStudents.length);

    this.onValueChanged(['students', currentStudents], ['numStudentsAssigned', max]);
  }

  private handleOnAddParticipants(students: StudentDto[]) {
    let currentStudents = this.state.wizardData.students || [];
    currentStudents = currentStudents.concat(students);
    const max = Math.max(this.state.wizardData.numStudentsAssigned ? this.state.wizardData.numStudentsAssigned : 0, currentStudents.length);
    this.onValueChanged([nameof<EventWizardData>('numStudentsAssigned'), max], [nameof<EventWizardData>('students'), currentStudents]);
  }

  getFilterPocUserLocation(): string[] {
    if (this.identityService.activeRole === 'PoC') {
      const locationIds = this.identityService.getUserInfo().locationsByRoles['PoC'] as any;
      return locationIds;
    }
    return null;
  }
}

export default withTranslation()(EventDetailStep);
