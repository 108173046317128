import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { PersonnelAreaDto, ChangePersonnelAreaStore, ChangePersonnelAreaDto } from 'stores/configuration/locations/personnelArea-store';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';

interface ChangePersonnelAreaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changePersonnelArea?: ChangePersonnelAreaStore;
  currentPersonnelArea: PersonnelAreaDto;
}

interface ChangePersonnelAreaViewState {
  location: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changePersonnelArea', ChangePersonnelAreaStore])
class ChangeLocationView extends React.Component<ChangePersonnelAreaViewProps, ChangePersonnelAreaViewState> {
  private get changepersonnelAreaStore() {
    return this.props.changePersonnelArea;
  }

  constructor(props: ChangePersonnelAreaViewProps) {
    super(props);

    this.state = {
      location: {
        id: this.props.currentPersonnelArea.locationId,
        title: this.props.currentPersonnelArea.locationName
      },
      userClickedSaved: false
    };

    this.changepersonnelAreaStore.state.result = null;

    this.changepersonnelAreaStore.change({
      id: this.props.currentPersonnelArea.id,
      name: this.props.currentPersonnelArea.name,
      locationId: this.props.currentPersonnelArea.locationId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangePersonnelAreaViewProps) {
    if (this.changepersonnelAreaStore.state.result && this.changepersonnelAreaStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changepersonnelAreaStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changepersonnelAreaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changepersonnelAreaStore.change({ ...this.changepersonnelAreaStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changepersonnelAreaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Personnel Area')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changepersonnelAreaStore.state.result && !this.changepersonnelAreaStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changepersonnelAreaStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changepersonnelAreaStore.state.item && (
              <Form>
                <Form.Group required widths="equal">
                  <Form.Field required error={!this.changepersonnelAreaStore.state.item.name && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid
                      error={!this.changepersonnelAreaStore.state.item.name}
                      label={t('Personnel Area')}
                      placeholder={t('Name')}
                      value={this.changepersonnelAreaStore.state.item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<ChangePersonnelAreaDto>('name'), value)}
                    />
                  </Form.Field>
                  <Form.Field required error={!this.changepersonnelAreaStore.state.item.locationId && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid
                      className={`${this.changepersonnelAreaStore.state.item.locationId == null ||
                        isNullOrWhiteSpaces(this.changepersonnelAreaStore.state.item.locationId)} field`}
                      label={t('Location')}
                    >
                      <LocationEditor
                        nullable
                        value={this.state.location}
                        onChange={location => {
                          this.changepersonnelAreaStore.state.item.locationId = location.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </Form.Input>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeLocationView);
