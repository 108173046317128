import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SsaSkillTemplateSectionDto, SsaSkillSectionQuestionDto } from 'stores/assessments/templates/ssa-templates-store';
import { List, Label, Button, Icon, Popup, Form } from 'semantic-ui-react';
import SsaCheckpointComponent from './ssa-wizard-second-step-checkpoint-component';

export interface SsaSecondStepSkillSectionComponentProps extends WithTranslation {
  skillSection: SsaSkillTemplateSectionDto;
  onAddCheckPoint: (skillId: string) => void;
  onChange: (propertyName: keyof SsaSkillTemplateSectionDto, value: any) => void;
  category: string;
  canGoUp: boolean;
  canGoDown: boolean;
  profession: string;
  move: (dir: 'up' | 'down') => void;
}

export interface SsaSecondStepSkillSectionComponentstate {
  isAddLinkWizardOpen: boolean;
  skillLink: string;
}

const SsaSecondStepSkillSectionComponent: FunctionComponent<SsaSecondStepSkillSectionComponentProps> = props => {
  const { skillSection, onAddCheckPoint, category, profession, canGoUp, canGoDown, onChange, move, t } = props;
  const { linkUrl, checkpoints, skillId, skillName } = skillSection;
  const [showAddLink, setShowAddLink] = useState(false);
  const [link, setLink]: [string, (value: string) => void] = useState(skillSection.linkUrl || '');

  const handleLinkCancelChange = () => {
    setShowAddLink(false);
    setLink('');
    onChange('linkUrl', '');
  };

  const removeCheckpointHandler = (i: number) => {
    const checks = (checkpoints || []).filter((_, idx) => idx !== i);
    onChange('checkpoints', checks);
  };

  const descriptionChangeHandler = (i: number, value: string) => {
    const checks = (checkpoints || []).map((checkpoint, idx) => {
      if (idx === i) checkpoint = { ...checkpoint, description: value };
      return checkpoint;
    });
    onChange('checkpoints', checks);
  };

  const questionsChangeHandler = (i: number, questions: SsaSkillSectionQuestionDto[]) => {
    const checks = (checkpoints || []).map((checkpoint, idx) => {
      if (idx === i) checkpoint = { ...checkpoint, questions };
      return checkpoint;
    });
    onChange('checkpoints', checks);
  };

  const handleAcceptLink = () => {
    setShowAddLink(false);
    onChange('linkUrl', link);
  };

  const handleLinkChange = (value: string) => {
    setLink(value);
  };

  const openLinkModal = () => {
    setShowAddLink(true);
  };

  const closeLinkModal = () => {
    setShowAddLink(false);
  };

  const alreadySelectedQuestions = [];
  (checkpoints || []).forEach(({ questions }) =>
    (questions || []).forEach(question => question?.id && alreadySelectedQuestions.push(question.id))
  );

  const resetLink = () => {
    setLink('');
    onChange('linkUrl', '');
  };

  return (
    <List verticalAlign="middle">
      <List.Item className="edit__select-profiles__wrapper__row">
        <Label className="skill-label">
          <p>{skillName}</p>
          <div className="wizard__sort-icons-wrapper">
            <Popup
              className="event-form-documentation-popup"
              position="bottom left"
              trigger={
                <Button labelPosition="right" className="ssa-wizard__add-link-button" basic>
                  <Icon name="linkify" /> {linkUrl ? t('Edit Link') : t('Add Link')}
                </Button>
              }
              open={showAddLink}
              onOpen={openLinkModal}
              openOnTriggerClick
              onClose={closeLinkModal}
              closeOnDocumentClick
              closeOnEscape
              closeOnTriggerClick
              on="click"
            >
              <Form>
                <Form.Input
                  className="event-form-documentation-popup-input popup-url-input"
                  inline
                  label={t('URL')}
                  value={link}
                  onChange={(_, { value }) => handleLinkChange(value)}
                ></Form.Input>
                <div className="event-form-documentation-popup-buttons">
                  <Button basic className="cancel-button" content={t('Cancel')} onClick={handleLinkCancelChange} />
                  <Button positive onClick={handleAcceptLink} disabled={!link} content={linkUrl ? t('Edit Link') : t('Add Link')} />
                </div>
              </Form>
            </Popup>
            {skillSection?.linkUrl && (
              <span style={{ cursor: 'pointer', color: '#E03C31', marginRight: 16 }} onClick={resetLink}>
                {t('Remove')}
              </span>
            )}
            <Button onClick={() => onAddCheckPoint(skillId)} className="tna-template-wizard__add-questions-btn" type="button">
              {t('Add Checkpoint')}
            </Button>
            <Icon disabled={!canGoUp} onClick={() => move('up')} name="caret square up outline" />
            <Icon disabled={!canGoDown} onClick={() => move('down')} name="caret square down outline" />
          </div>
        </Label>

        {(checkpoints || []).map((checkpoint, i) => (
          <SsaCheckpointComponent
            key={i}
            skill={{ id: skillId, name: skillName }}
            category={category}
            alreadySelectedQuestions={alreadySelectedQuestions}
            profession={profession}
            onDescriptionChange={value => descriptionChangeHandler(i, value)}
            onRemoveCheckpoint={() => removeCheckpointHandler(i)}
            checkpoint={checkpoint}
            onQuestionsChange={value => questionsChangeHandler(i, value)}
          />
        ))}
      </List.Item>
    </List>
  );
};

export default withTranslation()(SsaSecondStepSkillSectionComponent);
