import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import TrainingLevelEditor from '../../../../widgets/bussiness/training-level-editor';
import CustomizationDataEditor from '../../../../widgets/bussiness/customization-data-editor';
import DeliveryMethodEditor from '../../../../widgets/bussiness/delivery-method-editor';
import LanguageEditor from '../../../../widgets/bussiness/language-editor';
import { nameof } from 'utils/object';
import { RequestWizardStore, RequestWizardData } from 'stores/requests/create-request-wizard-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import './step3-event-details.less';

interface NewRequestViewProps extends WithTranslation {
  onCloseUsersModal: () => void;
  requestWizardStore?: RequestWizardStore;
}

interface NewRequestViewState {
  isOpenedUsersModal: boolean;
  originalTitle: string;
  titleFocused: boolean;
}

@connect(['requestWizardStore', RequestWizardStore])
class StepEventsDetails extends Component<NewRequestViewProps, NewRequestViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpenedUsersModal: false,
      originalTitle: '',
      titleFocused: false
    };
  }

  private onValueChanged = (...changes: [string, any][]) => {
    const change = {};
    changes.forEach(([prop, value]) => {
      change[prop] = value;
    });
    this.requestWizardStore.change({
      ...this.requestWizardStore.state.item,
      ...change
    });
  };

  handleMaxValue(value: any) {
    if (typeof value === 'string' && value.includes('-')) {
      return value.replace('-', '');
    }
    this.onValueChanged([nameof<RequestWizardData>('studentsNumber'), value]);
  }

  componentDidMount() {
    const { item } = this.requestWizardStore.state;
    let parts = [];

    if (item.isMachineRelated) {
      if (item.pattern == null) {
        const mModel =
          item.machineModels != null &&
          item.machineModels.length !== 0 &&
          item.machineModels[0] != null &&
          !isNullOrWhiteSpaces(item.machineModels[0].machineModelName)
            ? item.machineModels[0].machineModelName.trim()
            : '';
        const plc = item.plcNames != null ? item.plcNames.join(' ').trim() : '';
        const role = item.role != null && !isNullOrWhiteSpaces(item.role.title) ? item.role.title.trim() : '';
        parts = [mModel, plc, role];
      } else {
        const pattern = item.pattern != null && !isNullOrWhiteSpaces(item.pattern.title) ? item.pattern.title.trim() : '';
        const role = item.role != null && !isNullOrWhiteSpaces(item.role.title) ? item.role.title.trim() : '';
        parts = [pattern, role];
      }
    } else {
      const nmrCluster = item.nmrCluster != null && !isNullOrWhiteSpaces(item.nmrCluster.title) ? item.nmrCluster.title.trim() : '';
      const nmrFunctionalArea =
        item.nmrFunctionalArea != null && !isNullOrWhiteSpaces(item.nmrFunctionalArea.title) ? item.nmrFunctionalArea.title.trim() : '';
      const nmrTrainingName =
        item.nmrTrainingName != null && !isNullOrWhiteSpaces(item.nmrTrainingName.title) ? item.nmrTrainingName.title.trim() : '';
      const nmrFunctionalSubArea =
        item.nmrFunctionalSubArea != null && !isNullOrWhiteSpaces(item.nmrFunctionalSubArea.title)
          ? item.nmrFunctionalSubArea.title.trim()
          : '';
      const role = item.role != null && !isNullOrWhiteSpaces(item.role.title) ? item.role.title.trim() : '';

      parts = [nmrCluster, nmrFunctionalArea, nmrTrainingName, nmrFunctionalSubArea, role];
    }

    let originalTitle = '';
    parts.forEach(s => {
      originalTitle = `${originalTitle} ${s}`.trim();
    });

    let compoundTitle = originalTitle;
    let trLvl = '';
    if (item.trainingLevel && !isNullOrWhiteSpaces(item.trainingLevel.title)) {
      trLvl = item.trainingLevel.title.trim();
    }

    compoundTitle = `${compoundTitle} ${trLvl}`.trim();

    if (item.isMachineRelated && item.customization != null) {
      compoundTitle = `${compoundTitle} Custom`;
    }

    compoundTitle = compoundTitle.trim();

    if (!item.manualTitle) {
      this.setState({ originalTitle });
      this.onValueChanged(['title', compoundTitle]);
    } else {
      this.setState({ originalTitle: item.title });
    }
  }

  private onManuallyChangeTitle = (val: string) => {
    if (!this.state.titleFocused) {
      const current = this.requestWizardStore.state.item.title;
      if (current !== val) {
        this.setState({ originalTitle: val }, () => this.onValueChanged(['title', val], ['manualTitle', true]));
      }
    } else {
      this.onValueChanged(['title', val], ['manualTitle', true]);
    }
  };

  private onCustomizationChange = (c: ItemReference) => {
    const { manualTitle } = this.props.requestWizardStore.state.item;
    if (manualTitle) {
      this.onValueChanged([nameof<RequestWizardData>('customization'), c]);
    } else {
      const { originalTitle } = this.state;
      const { item } = this.requestWizardStore.state;
      let trLvl = '';
      if (item.trainingLevel && !isNullOrWhiteSpaces(item.trainingLevel.title)) trLvl = item.trainingLevel.title.trim();

      let changeTitle = `${originalTitle || ''} ${trLvl}`.trim();

      if (item.isMachineRelated) changeTitle = `${changeTitle} ${c != null ? 'Custom' : ''}`;
      this.onValueChanged([nameof<RequestWizardData>('customization'), c], ['title', changeTitle.trim()]);
    }
  };

  private onTrainingLevelChange = (c: ItemReference) => {
    const { manualTitle } = this.props.requestWizardStore.state.item;
    if (manualTitle) {
      this.onValueChanged([nameof<RequestWizardData>('trainingLevel'), c]);
    } else {
      const { originalTitle } = this.state;
      const { item } = this.requestWizardStore.state;
      let trLvl = '';
      if (c != null && !isNullOrWhiteSpaces(c.title)) trLvl = c.title.trim();

      let changeTitle = `${originalTitle || ''} ${trLvl}`.trim();

      if (item.isMachineRelated) changeTitle = `${changeTitle} ${item.customization != null ? 'Custom' : ''}`;
      this.onValueChanged([nameof<RequestWizardData>('trainingLevel'), c], ['title', changeTitle.trim()]);
    }
  };

  render() {
    const { t } = this.props as any;
    return (
      <>
        <Form className="wizard__event-details__3step" loading={this.requestWizardStore.state.isBusy}>
          <div className="event-details__3step__row__element required field">
            <label>{t('Request Title')}</label>
            <Input
              className="request-title__input"
              fluid
              value={this.requestWizardStore.state.item.title}
              onChange={(e, { value }) => this.onManuallyChangeTitle(value)}
              onFocus={() => this.setState({ titleFocused: true })}
              onBlur={() => this.setState({ titleFocused: false })}
            />
          </div>

          <div className="event-details-row__wrapper">
            <div className="event-details__row__element">
              <label>{t('Customization')}</label>
              <CustomizationDataEditor
                nullable
                clearable={true}
                value={this.requestWizardStore.state.item.customization}
                onChange={this.onCustomizationChange}
              />
            </div>

            <div className="event-details__row__element">
              <label>{t('Language')}</label>
              <LanguageEditor
                nullable
                clearable={true}
                value={this.requestWizardStore.state.item.language}
                onChange={c => this.onValueChanged([nameof<RequestWizardData>('language'), c])}
              />
            </div>
          </div>

          <div className="event-details-row__wrapper">
            <div className="event-details__row__element required field">
              <label>{t('Training Level')}</label>
              <TrainingLevelEditor
                nullable
                value={this.requestWizardStore.state.item.trainingLevel}
                onChange={this.onTrainingLevelChange}
              />
            </div>
            <div className="event-details__row__element required field">
              <label>{t('Delivery Method')}</label>
              <DeliveryMethodEditor
                nullable
                value={this.requestWizardStore.state.item.deliveryMethod}
                onChange={c => this.onValueChanged([nameof<RequestWizardData>('deliveryMethod'), c])}
              />
            </div>
          </div>
        </Form>
      </>
    );
  }
  getFilterPocUserLocation(): string[] {
    if (this.identityService.activeRole === 'PoC') {
      const locationIds = this.identityService.getUserInfo().locationsByRoles['PoC'] as any;
      return locationIds;
    }
    return null;
  }
}

export default withTranslation()(StepEventsDetails);
