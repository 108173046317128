import React, { Component } from 'react';
import ScoreList from './score-list';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserProfileDto, UserAssessmentScoreDto, ScoreItemDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './score-summary';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { ProfileItemDto } from 'stores/profile/profile-store';
import SsaRadarChartComponent from '../ssa-radar-chart-component';
import { guidIsNullOrEmpty, openInNewWindow } from 'utils/useful-functions';
import { Button } from 'semantic-ui-react';
import * as H from 'history';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface SsaUserProfileListingDetailsProps extends WithTranslation {
  userProfile: UserProfileDto;
  selectFirstByDefault?: boolean;
  history?: H.History;
}

export interface SsaUserProfileListingDetailsState {
  scoreSummary: ScoreSummary;
  targetProfile: ProfileItemDto;
}

class SsaUserProfileListingDetails extends Component<SsaUserProfileListingDetailsProps, SsaUserProfileListingDetailsState> {
  state: SsaUserProfileListingDetailsState = {
    scoreSummary: null,
    targetProfile: null
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isThisUserAdminOrPoc = (): boolean => {
    return this.identityService.isInRole(Roles.Admin) || this.identityService.isInRole(Roles.PoC);
  };

  private isThisUserAnEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  componentDidMount() {
    const initScoreSummary = this.toScoreSummary(this.props.userProfile.ssaCurrentScores);
    this.setState({ scoreSummary: initScoreSummary });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile !== prevProps.userProfile) {
      let currentScores = this.toScoreSummary(this.props.userProfile.ssaCurrentScores);
      this.setState({ scoreSummary: currentScores });
    }
  }
  toScoreSummary(userAssessmentScoreDto: UserAssessmentScoreDto): ScoreSummary {
    if (userAssessmentScoreDto) {
      return {
        skillScores: this.toSkillScores(userAssessmentScoreDto.scores),
        totalScore: userAssessmentScoreDto.totalScore
      };
    }
  }

  toSkillScores(skillScores: { [key: string]: ScoreItemDto }): SkillScore[] {
    var skills: SkillScore[] = [];
    let userSkills = this.props.userProfile.skills;
    userSkills.forEach(skill => {
      skills.push({
        id: skill.skillId,
        name: skill.skillName,
        score: skillScores[skill.skillId] ? skillScores[skill.skillId].score : 0,
        isUpdated: skillScores[skill.skillId]?.isUpdated
      });
    });
    return skills;
  }

  onReviewLastSsaFormClicked(id: string) {
    openInNewWindow(`./assessments/ssa/ssa-edit-form/${id}`);
  }

  onChangeProfile = (profile: ProfileItemDto) => {
    this.setState({ targetProfile: profile });
  };

  onGoToSsaDevPlan = () => {
    if (this.isThisUserAdminOrPoc()) {
      this.props.history && this.props.history.replace('/assessments/development-plan/ssa');
    } else if (this.isThisUserAnEmployee()) {
      this.props.history && this.props.history.replace('/assessments/development-plan?tab=1');
    }
  };

  render() {
    const { t } = this.props;
    const { scoreSummary } = this.state;
    return (
      <>
        <div className="employee__-scores-radar">
          <div className="profile-machinery__machinery-list flex-column">
            <ProfileEditor
              assessment={'SSA'}
              value={this.state.targetProfile && this.state.targetProfile.id}
              nullable
              className="custom-editor"
              clearable
              onChange={profile => this.onChangeProfile(profile)}
              placeholder={t('Profile')}
            />

            {scoreSummary && <ScoreList scoreSummary={this.state.scoreSummary} />}

            <div className="employee-card__btns-wrapper">
              {scoreSummary && (
                <Button
                  className={guidIsNullOrEmpty(this.props.userProfile.lastSsaFormId) ? 'disabled-inverted-save-btn' : 'employee-card__btn'}
                  disabled={guidIsNullOrEmpty(this.props.userProfile.lastSsaFormId)}
                  onClick={e => {
                    this.onReviewLastSsaFormClicked(this.props.userProfile.lastSsaFormId);
                  }}
                >
                  {t('Review last SSA')}
                </Button>
              )}

              <Button
                className="employee-card__btn employee-card__check-btn"
                onClick={e => {
                  this.onGoToSsaDevPlan();
                }}
              >
                {t('Check SSA Development Plan')}
              </Button>
            </div>
          </div>

          <SsaRadarChartComponent
            skillsByProfileAssigned={this.props.userProfile.skills}
            scoreSummary={scoreSummary}
            targetProfile={this.state.targetProfile}
          />
        </div>
      </>
    );
  }
}

export default withTranslation()(SsaUserProfileListingDetails);
