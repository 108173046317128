import { BaseDto } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface TestCategoryDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateTestCategoryDto {
  name: string;
}

export interface ChangeTestCategoryDto {
  id: string; //Guid
  name: string;
}

export class CreateTestCategoryValidator extends AbstractValidator<CreateTestCategoryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));
  }
}

export class ChangeTestCategoryValidator extends AbstractValidator<ChangeTestCategoryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Question Bank Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));
  }
}

@repository('@@TESTCATEGORY', 'testcategory.summary')
export class TestCategoryStore extends DataStore<TestCategoryDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-test-category';
  retrievePath = 'get-test-categories';
  updatePath = 'update-test-category';
  deletePath = 'delete-test-category';
  retrieveOnePath = 'get-test-category';

  protected validate(item: TestCategoryDto) {
    return new ChangeTestCategoryValidator().validate(item);
  }

  constructor() {
    super('TESTCATEGORY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getQuestionBankById(id: string): Promise<TestCategoryDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TestCategoryDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@TESTCATEGORY', 'testcategory.new')
export class NewTestCategoryStore extends FormStore<CreateTestCategoryDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-test-category';
  retrievePath = 'get-test-categories';
  updatePath = 'update-test-category';

  protected validate(item: CreateTestCategoryDto) {
    return new CreateTestCategoryValidator().validate(item);
  }

  constructor() {
    super('NEW_TESTCATEGORY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TESTCATEGORY', 'testcategory.change')
export class ChangeTestCategoryStore extends FormStore<ChangeTestCategoryDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-test-category';
  retrievePath = 'get-test-categories';
  updatePath = 'update-test-category';

  protected validate(item: ChangeTestCategoryDto) {
    return new ChangeTestCategoryValidator().validate(item);
  }

  constructor() {
    super('CHANGE_TESTCATEGORY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
