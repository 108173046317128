import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SsaStepsComponent from '../../../wizards/ssa-wizard-step';
import { Input, TextArea } from 'semantic-ui-react';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import './ssa-wizard-first-step.less';
import { SsaTemplateData } from '../ssa-templates-wizard-component';
import { ProfileItemDto } from 'stores/profile/profile-store';

export interface SsaFirstStepProps extends WithTranslation {
  data: SsaTemplateData;
  onChangeProperty: (property: string, value: any) => void;
  onChangeProfile: (profile: ProfileItemDto) => void;
  isEditMode: boolean;
}

class SsaFirstStepContent extends React.Component<SsaFirstStepProps> {
  shouldComponentUpdate(nextProps, nextState) {
    const { profile, title, header } = this.props.data;
    if (
      profile !== nextProps.profile ||
      title !== nextProps.title ||
      header !== nextProps.header ||
      this.props.isEditMode !== nextProps.isEditMode
    )
      return true;
    else return false;
  }

  render() {
    const { t, data, isEditMode } = this.props;
    return (
      <div className="ssa-wizard__step wizard__step-one">
        <SsaStepsComponent isStepActive stepNumber={'1'} stepTitle={'IDENTIFY THE NEW TEMPLATE'}></SsaStepsComponent>
        <div className="step-one__content flex-start-center">
          <label>{t('Who is this template for?')}</label>
          <div className="input profile-editor">
            <ProfileEditor
              readonly={isEditMode}
              assessment={'SSA'}
              value={data && data.profile && data.profile.id}
              nullable
              className="custom-editor"
              clearable
              onChange={profile => this.props.onChangeProfile(profile)}
              placeholder={t('Profile')}
              notLoadDataOnOpen
            />
          </div>
        </div>

        <div className="step-one__content flex-start">
          <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
          <Input value={data && data.title ? data.title : ''} onChange={(_, { value }) => this.props.onChangeProperty('title', value)} />
          <label>{t('Header (Optional)')}</label>
          <TextArea
            value={!data ? '' : data.header ? data.header : ''}
            onChange={(_, { value }) => this.props.onChangeProperty('header', value)}
            className="ssa-wizard__textarea"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SsaFirstStepContent);
