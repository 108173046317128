import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/ssaSkill.less';
import { NewSsaSkillStore, CreateSsaSkillDto, SsaSkillDto } from 'stores/assessments/skills/ssa-skills-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface NewSsaSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newSsaSkill?: NewSsaSkillStore;
  isClone?: boolean;
  cloneData: SsaSkillDto;
}

interface NewSsaSkillViewState {
  category: ItemReference;
  userClickedSave: boolean;
}

@connect(['newSsaSkill', NewSsaSkillStore])
class NewSsaSkillView extends React.Component<NewSsaSkillViewProps, NewSsaSkillViewState> {
  private get newSsaSkillStore() {
    return this.props.newSsaSkill;
  }

  constructor(props: NewSsaSkillViewProps) {
    super(props);
    this.newSsaSkillStore.createNew({
      testCategoryId: this.props.isClone && this.props.cloneData ? this.props.cloneData.testCategoryId : '',
      description: this.props.isClone && this.props.cloneData ? this.props.cloneData.description : '',
      name: this.props.isClone && this.props.cloneData ? this.props.cloneData.name : ''
    });
    this.state = {
      category:
        this.props.isClone && this.props.cloneData
          ? Object.assign({ id: this.props.cloneData.testCategoryId, title: this.props.cloneData.testCategory.name })
          : null,
      userClickedSave: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewSsaSkillViewProps) {
    if (this.newSsaSkillStore.state.result && this.newSsaSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newSsaSkillStore.change({ ...this.newSsaSkillStore.state.item, ...change });
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newSsaSkillStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newSsaSkillStore.clear();
    this.props.onClose(false);
  }

  public render() {
    const { t } = this.props as any;

    const modalTitle = this.props.isClone ? 'Clone SSA Skill' : 'New SSA Skill';
    return (
      <Modal className="ssa-skill__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newSsaSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="ssa-skill__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newSsaSkillStore.state.result && !this.newSsaSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newSsaSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newSsaSkillStore.state.item && (
              <Form>
                <Form.Group className="ssa-skill__selctor-group">
                  <Form.Field
                    required
                    className="ssa-skill__label-input"
                    error={
                      (this.newSsaSkillStore.state.item.testCategoryId == null ||
                        isNullOrWhiteSpaces(this.newSsaSkillStore.state.item.testCategoryId)) &&
                      this.state.userClickedSave
                    }
                  >
                    <label>{t('Category')}</label>
                    <TestCategorySelector
                      nullable
                      isTnaCategory={false}
                      className="planit-user-dropdown-custom"
                      placeholder={t('Select Category')}
                      value={this.state.category}
                      onChange={category => this.handleValue(nameof<CreateSsaSkillDto>('testCategoryId'), category?.id)}
                    />
                  </Form.Field>
                </Form.Group>
                <div className="ssa-skill__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.state.userClickedSave && this.newSsaSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea"
                        value={this.newSsaSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateSsaSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.state.userClickedSave && this.newSsaSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea longArea"
                        value={this.newSsaSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateSsaSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-ssa-skill__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewSsaSkillView);
