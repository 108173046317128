import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import ChatForm from '../chat/chat';
import { RequestStatus } from 'stores/requests/requests-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface RequestFormChatProps extends WithTranslation {
  requestId: string;
  status: RequestStatus;
}

class RequestFormChat extends Component<RequestFormChatProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private hasToBeInReadOnly(): boolean {
    const { status } = this.props;
    if (this.identityService.isInRole(Roles.Reporting)) return true;
    else return status.toString() === 'Draft' || status.toString() === 'Accepted' || status.toString() === 'Rejected';
  }

  render() {
    const readOnly = this.hasToBeInReadOnly();
    return <ChatForm requestId={this.props.requestId} readonly={readOnly} />;
  }
}

export default withTranslation()(RequestFormChat);
