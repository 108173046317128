import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ChangeTnaDevelopmentPlanStore, TnaDevelopmentPlanDto } from 'stores/development-plan/tna-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Message, Loader, Dimmer } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { UserProfileListViewModel } from './user-profile-list';
import { ItemReference } from 'stores/dataStore';
import YearEditor from 'widgets/bussiness/year-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import DevelopmentPlanStatusEditor from 'widgets/bussiness/development-plan-status-editor';
import UserProfileList from './user-profile-list';
import './edit-development-plan.modal.less';
import { DevelopmentPlanStatus } from 'stores/development-plan/development-plan-status.enum';

export interface EditDevelopmentPlanModalProps extends WithTranslation {
  open: boolean;
  developmentPlan: TnaDevelopmentPlanDto;
  onClose?: () => void;
  changeTnaDevelopmentPlanStore?: ChangeTnaDevelopmentPlanStore;
}

interface EditDevelopmentPlanModalState {
  employee: UserProfileListViewModel;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  trainingLevel: ItemReference;
  activity: ItemReference;
  status: DevelopmentPlanStatus;
}

@connect(['changeTnaDevelopmentPlanStore', ChangeTnaDevelopmentPlanStore])
class EditDevelopmentPlanModal extends Component<EditDevelopmentPlanModalProps, EditDevelopmentPlanModalState> {
  constructor(props: Readonly<EditDevelopmentPlanModalProps>) {
    super(props);

    const { developmentPlan } = props;

    this.state = {
      employee: {
        id: developmentPlan.userId,
        employeeId: developmentPlan.user && developmentPlan.user.employeeId,
        employee: developmentPlan.user && `${developmentPlan.user.lastName}, ${developmentPlan.user.firstName}`,
        sfPosition: developmentPlan.user && developmentPlan.user.sfPosition,
        location:
          developmentPlan.user &&
          developmentPlan.user.location &&
          `${developmentPlan.user.location.location} - ${developmentPlan.user.location.countryName}`,
        profile: developmentPlan.profileName
      },
      cluster: developmentPlan.machineModel && {
        id: developmentPlan.machineModel.clusterId,
        title: developmentPlan.machineModel.clusterName
      },
      equipmentType: developmentPlan.machineModel && {
        id: developmentPlan.machineModel.equipmentTypeId,
        title: developmentPlan.machineModel.equipmentTypeName
      },
      oem: developmentPlan.machineModel && {
        id: developmentPlan.machineModel.oemId,
        title: developmentPlan.machineModel.oemName
      },
      machineModel: developmentPlan.machineModel && {
        id: developmentPlan.machineModel.id,
        title: developmentPlan.machineModel.name
      },
      activity: developmentPlan.eventType && { id: developmentPlan.eventType.id, title: developmentPlan.eventType.name },
      status: developmentPlan.status,
      trainingLevel: developmentPlan.trainingLevelId && { id: developmentPlan.trainingLevelId, title: developmentPlan.trainingLevelName }
    };
  }

  private handleOnValueChanged = (property: string, value: any) => {
    let newState = { ...this.state };
    newState[property] = value;

    this.setState(newState);
  };

  private handleOnClose = () => {
    this.props.changeTnaDevelopmentPlanStore.reset();
    this.props.onClose && this.props.onClose();
  };

  private handleOnAccept = () => {
    const { developmentPlan } = this.props;
    const { status } = this.state;

    this.props.changeTnaDevelopmentPlanStore.createNew({
      id: developmentPlan.id,
      nextStatus: status
    });

    this.props.changeTnaDevelopmentPlanStore.update().then(x => {
      if (x.isSuccess) this.props.onClose && this.props.onClose();
    });
  };

  render() {
    const { t, open, developmentPlan, changeTnaDevelopmentPlanStore: store } = this.props;
    const { employee, cluster, equipmentType, oem, machineModel, trainingLevel, activity, status } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <Modal
        open={open}
        className="edit-development-plan-modal"
        onClose={this.handleOnClose}
        centered={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        //closeOnEscape={false}
        closeOnEscape={true}
        closeOnPortalMouseLeave={false}
      >
        <Dimmer active={store.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate />
        </Dimmer>
        <Modal.Header className="edit-development-plan-modal__header">{t('Edit TNA Development Plan')}</Modal.Header>
        <Modal.Content className="edit-development-plan-modal__content">
          {messages.length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={messages.map(o => o.body)}
              onDismiss={() => this.props.changeTnaDevelopmentPlanStore.clearMessages()}
            />
          )}
          <div className="edit-development-plan-modal__field">
            <label>{t('Employee')}</label>
          </div>
          <UserProfileList userProfiles={[employee]} />
          <div className="edit-development-plan-modal__field edit-development-plan-modal__machinery">
            <label>{t('Select Machinery')}</label>
            <div className="edit-development-plan-modal__machinery__field">
              <div className="flex-column">
                <label>{t('Cluster')}</label>
                <MRClusterEditor readOnly className="custom-editor" placeholder={t('Cluster')} value={cluster} />
              </div>
              <div className="flex-column">
                <label>{t('Equipment Type')}</label>
                <EquipmentTypeEditor readOnly className="custom-editor" placeholder={t('Equipment Type')} value={equipmentType} />
              </div>
              <div className="flex-column">
                <label>{t('OEM')}</label>
                <OemEditor readonly className="custom-editor" placeholder={t('OEM')} value={oem} />
              </div>
              <div className="flex-column">
                <label>{t('Machine Model')}</label>
                <MachineModelEditor readonly className="custom-editor" placeholder={t('Machine Model')} value={machineModel} />
              </div>
              <div className="flex-column">
                <label>{t('Training Level')}</label>
                <TrainingLevelEditor readOnly className="custom-editor" placeholder={t('Training Level')} value={trainingLevel} />
              </div>
            </div>
          </div>
          <div className="edit-development-plan-modal__field flex-start">
            <label>{t('Select Activity')}</label>
            <EventTypeEditor
              readOnly
              className="custom-editor"
              placeholder={t('Activity')}
              useOriginalEventTypeIdAsValue
              value={activity}
            />
          </div>
          <div className="edit-development-plan-modal__field">
            <label>{t('Set Deadline')}</label>
            <YearEditor readOnly className="custom-editor year-editor" placeholder={t('Year')} value={developmentPlan.deadlineYear} />
          </div>
          <div className="edit-development-plan-modal__field">
            <label>{t('Set Status')}</label>
            <DevelopmentPlanStatusEditor
              className="custom-editor"
              placeholder={t('Status')}
              value={status}
              onChange={value => this.handleOnValueChanged(nameof<EditDevelopmentPlanModalState>('status'), value)}
            />
          </div>
        </Modal.Content>
        <Modal.Actions className="edit-development-plan-modal__actions">
          <Button className="basic" onClick={this.handleOnClose}>
            {t('Cancel')}
          </Button>
          <Button className="confirm-changes-btn positive" onClick={this.handleOnAccept}>
            {t('Confirm Changes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(EditDevelopmentPlanModal);
