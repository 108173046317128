import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid } from 'semantic-ui-react';
import { UserDto, UserStore } from 'stores/users/users-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import './employee-page.less';
import './background-employee.less';
import ProfileListingComponent from './profile-listing-component';
import UserSummary from './user-summary/user-summary-component';
import { EventsStore, EventDto } from 'stores/events/events-store';
import EventsEmployee from 'widgets/bussiness/events-employee';
import { Query, OrderDefinition } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { goToPage } from 'utils/useful-functions';
import { TnaSkillsStore } from 'stores/assessments/skills/tna-skills-store';

export interface EmployeeViewProps extends WithTranslation, RouteComponentProps {
  users?: UserStore;
  events?: EventsStore;
  tnaSkillsStore?: TnaSkillsStore;
}

export interface EmployeeViewState {
  currentUser: UserDto;
  eventsList: EventDto[];
  employeeLoggedOrFounded: boolean;
  isAssessor: boolean;
  isAdminOrPoc: boolean;
}

@connect(['users', UserStore], ['events', EventsStore], ['tnaSkillsStore', TnaSkillsStore])
class EmployeeViewPage extends React.Component<EmployeeViewProps, EmployeeViewState> {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      eventsList: null,
      employeeLoggedOrFounded: true,
      isAssessor: this.isThisUserAssessor(),
      isAdminOrPoc: this.isThisUserAdminOrPoc()
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  isThisUserAdminOrPoc = (): boolean => {
    return this.identityService.isInRole(Roles.Admin) || this.identityService.isInRole(Roles.PoC);
  };

  private isThisUserAssessor = (): boolean => {
    const user = this.identityService.getUserInfo();
    if (user.isAssessor) return true;
    else return false;
  };

  private getEmployee = async (id: string): Promise<boolean> => {
    try {
      const employeeData = await this.props.users.getUserById(id);
      this.setState({ currentUser: employeeData, employeeLoggedOrFounded: true });
    } catch {
      this.setState({ employeeLoggedOrFounded: false });
      return false;
    }
    return true;
  };

  private getEventsByEmployee = async (): Promise<EventDto[]> => {
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<EventDto>('startDate'), useProfile: false }];
    const query: Query = { searchQuery: '', orderBy, skip: 0, take: 100000 };
    const events = await this.getEventsEmployeeStore().getAllAsync(query);
    return events.items;
  };

  private getEventsByEmployeeId = async (id: string): Promise<EventDto[]> => {
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<EventDto>('startDate'), useProfile: false }];
    const query: Query = { searchQuery: '', orderBy, skip: 0, take: 100000 };
    const events = await this.getEventsEmployeeStore().getAllAsyncByEmployeeId(query, id);
    return events.items;
  };

  private getEventsByEmployeeIdAndFormId = async (id: string, formId: string): Promise<EventDto[]> => {
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<EventDto>('startDate'), useProfile: false }];
    const query: Query = { searchQuery: '', orderBy, skip: 0, take: 100000 };
    const eventsAssessor = await this.props.tnaSkillsStore.getEventsByEmployeeAndFormIds(query, id, formId);
    return eventsAssessor.items;
  }; //TODO iws store ?

  private getEventsEmployeeStore = () => this.props.events;

  componentDidMount() {
    const { match } = this.props;
    let id = null;
    let formId = null;
    if (match?.params && match.params['id'] != null) id = match.params['id'].toString();
    if (match?.params && match.params['tnaFormId'] != null) formId = match.params['tnaFormId'].toString();

    if (this.identityService.isInRole(Roles.Employee) && id == null) {
      const employee = this.identityService.getUserInfo();
      this.getEmployee(employee.userId);
      this.getEventsByEmployee().then(eventsList => this.setState({ eventsList, employeeLoggedOrFounded: true }));
    } else if (id != null) {
      this.getEmployee(id).then(result => {
        if (result && formId != null && !this.state.isAdminOrPoc && this.state.isAssessor)
          this.getEventsByEmployeeIdAndFormId(id, formId).then(eventsList => this.setState({ eventsList, employeeLoggedOrFounded: true }));
        else if (result) this.getEventsByEmployeeId(id).then(eventsList => this.setState({ eventsList, employeeLoggedOrFounded: true }));
        else goToPage('./NotFound');
      });
    }

    document.querySelector('.planit-main-container').classList.add('employee-background');
  }

  componentWillUnmount() {
    document.querySelector('.planit-main-container').classList.remove('employee-background');
  }

  public render() {
    const { employeeLoggedOrFounded, currentUser, eventsList } = this.state;
    return (
      employeeLoggedOrFounded && (
        <Grid className="padding-margin-right-none employee-page__container" columns={3}>
          <Grid.Row>
            <Grid.Column width={6} textAlign="left" verticalAlign="middle">
              <UserSummary user={currentUser}></UserSummary>
            </Grid.Column>

            <Grid.Column className="min-height-box" width={10} textAlign="center" verticalAlign="middle">
              <EventsEmployee eventsList={eventsList} />
            </Grid.Column>
          </Grid.Row>

          {currentUser?.hasProfileAssigned && (
            <Grid.Row>
              <Grid.Column
                className="profile-card__profiles-container padding-margin-right-none"
                width={16}
                textAlign="center"
                verticalAlign="middle"
              >
                <ProfileListingComponent history={this.props.history} userId={currentUser.id} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )
    );
  }
}
export default withTranslation()(EmployeeViewPage);
