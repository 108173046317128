import { BaseDto } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import i18n from '../../i18n';

export interface UserProfilesDto extends BaseDto {
  userId: string; //Guid
  userName: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  locationId: string; //Guid
  locationName: string;
  locationCountryId: string;
  locationCountryName: string;
  positionCodeId: string; //Guid
  positionCodeName: string;
  sfPosition: string;
  enabled: boolean;
  profiles: UserProfileDto[];
}

export interface UserProfileDto {
  id: string; //Guid
  profileId: string; //Guid
  profileName: string;
  userMachineProfiles: UserMachineProfileItemDto[];
  assessment: Assessment;
  skills: SkillDto[];
  ssaCurrentScores: UserAssessmentScoreDto;
  profileIsActive: boolean;
  lastSsaFormId: string;
  userTemplateProfiles: UserTemplateProfileItemDto[];
}

export interface UserMachineProfileItemDto {
  id: string; //Guid
  machineModelId: string; //Guid
  machineModelName: string;
  level: Level;
  lastTnaFormId: string; //Guid
  lastOverallRecommendation: string;
  totalScore: number;
  tnaCurrentScores: UserAssessmentScoreDto;
  oemId: string;
  oemName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  clusterId: string;
  clusterName: string;
}
export interface UserTemplateProfileItemDto {
  id: string; //Guid
  templateId: string; //Guid
  templateName: string;
  iwsCurrentScores: UserIwsAssessmentScoreDto;
}
export interface UserAssessmentScoreDto {
  userId: string;
  fullName: string;
  userName: string;
  level: Level;
  machineModelId: string;
  totalScore: number;
  scores: { [key: string]: ScoreItemDto };
}
export interface UserIwsAssessmentScoreDto {
  userId: string;
  fullName: string;
  userName: string;
  scores: { [key: string]: IwsScoreItemDto };
}
export interface TemplateSkillsDto {
  templateId: string;
  templateName: string;
  skillIds: string[];
}
export interface SkillDto {
  skillId: string;
  skillName: string;
  substitute: number;
  main: number;
  assessment: Assessment;
  isMachineRelated?: boolean;
  phase0: number;
  phase1: number;
  phase2: number;
  phase3: number;
  phase4: number;
}

export interface ScoreItemDto {
  skillId: string;
  score: number;
  isUpdated: boolean;
  isCompleted: boolean;
}
export interface IwsScoreItemDto {
  skillId: string;
  employeeFirstAssessmentScore: ScoreItemDto;
  employeeSecondAssessmentScore: ScoreItemDto;
  qualifierScore: ScoreItemDto;
}

export enum Level {
  Main = 10,
  Substitute = 20
}

export enum Assessment {
  Unknown = 0,
  TNA = 10,
  SSA = 20,
  IWS = 30
}

export interface CreateUserProfilesDto {
  userIds: string[];
  profiles: UserProfileItemDto[];
}
export interface UserProfileItemDto {
  profileId: string;
  machines: CreateUserMachineProfileItemDto[];
}
export interface CreateUserMachineProfileItemDto {
  machineModelId: string;
  level: Level;
}

export interface ChangeUserProfilesDto {
  userId: string;
  profiles: UserProfileItemDto[];
}

export class CreateUserProfileItemValidator extends AbstractValidator<CreateUserProfilesDto> {
  constructor() {
    super();

    this.validateIf(p => p.userIds)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t("Users ID's are required"));

    this.validateIf(p => p.profiles)
      .isNotEmpty()
      .withFailureMessage(i18n.t('There should be at least one profile assigned'));
  }
}

export class ChangeUserProfileItemValidator extends AbstractValidator<ChangeUserProfilesDto> {
  constructor() {
    super();

    this.validateIf(p => p.userId)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t("Users ID's are required"));

    this.validateIf(p => p.profiles)
      .isNotEmpty()
      .withFailureMessage(i18n.t('There should be at least one profile assigned'));
  }
}

@repository('@@USER_PROFILES_STORE', 'user-profiles-store.summary')
export class UserProfilesStore extends DataStore<UserProfilesDto> {
  baseUrl = 'skills/v1';
  createPath = '';
  retrievePath = 'get-users-profiles';
  updatePath = '';
  deletePath = 'delete-user-profiles';
  retrieveOnePath = 'get-user-profiles';

  protected validate(item: UserProfilesDto) {
    return new ChangeUserProfileItemValidator().validate(item as any);
  }

  constructor() {
    super('USER_PROFILES_STORE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getUserProfileById(id: string, showScores: boolean = false): Promise<UserProfilesDto> {
    const httpService = container.get(HttpService);
    let url = `${this.baseUrl}/${this.retrieveOnePath}/${id}`;
    if (showScores) url += '?ShowScores=true';
    const result = await this.dispatchAsync(this.retrieveOnePath, httpService.get<UserProfilesDto>(url));
    return result.data;
  }

  public async getAlltUserProfile(
    query: Query,
    employee: string,
    locationId: string,
    machineModelName: string,
    positionCodeId: string,
    sFPosition: string,
    EmployeeId: string,
    clusterId?: string,
    equipmentTypeId?: string,
    oemId?: string,
    machineModelId?: string,
    data?: any
  ): Promise<QueryResult<UserProfilesDto>> {
    const httpService = container.get(HttpService);
    let url = `${this.baseUrl}/${this.retrievePath}?${DataStore.buildUrl(query)}`;

    const params = data != null ? data : {};

    if (employee) params['Employee'] = employee;
    if (locationId) params['LocationId'] = locationId;
    if (machineModelName) params['MachineModelFilter'] = machineModelName;
    if (positionCodeId) params['PositionCodeId'] = positionCodeId;
    if (sFPosition) params['SFPosition'] = sFPosition;
    if (EmployeeId) params['EmployeeId'] = EmployeeId;
    if (clusterId) params['ClusterId'] = clusterId;
    if (equipmentTypeId) params['EquipmentTypeId'] = equipmentTypeId;
    if (oemId) params['OemId'] = oemId;
    if (machineModelId) params['MachineModelId'] = machineModelId;

    const result = await this.dispatchAsync(this.ENTITY_LIST_UPDATE, httpService.get<QueryResult<UserProfilesDto>>(url, params));
    return result.data;
  }
}

@repository('@@USER-PROFILES-STORE', 'user-profiles-store.new')
export class NewProfileItemAssigmentStore extends FormStore<CreateUserProfilesDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-users-profiles';
  retrievePath = 'get-users-profiles';
  updatePath = '';

  protected validate(item: CreateUserProfilesDto) {
    return new CreateUserProfileItemValidator().validate(item);
  }

  constructor() {
    super('NEW_USER_PROFILES_STORE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@USER_PROFILES_STORE', 'user-profiles-store.change')
export class ChangeUserProfileItemStore extends FormStore<ChangeUserProfilesDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-users-profiles';
  retrievePath = 'get-users-profiles';
  updatePath = 'update-user-profiles';

  protected validate(item: ChangeUserProfilesDto) {
    return new ChangeUserProfileItemValidator().validate(item);
  }

  constructor() {
    super('CHANGE_USER_PROFILES_STORE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
