import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import '../../../../assets/less/edit-user-modal.less';
import { Query } from 'stores/dataStore';
import { getProperties } from 'utils/object';
import {
  InstructorHistoryStore,
  InstructorHistoryDto,
  InstructorDto,
  ChangeInstructorHistoryStore
} from 'stores/instructors/instructors-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { TableModel, TableView } from 'widgets/collections/table';
import { DateTimeService } from 'services/datetime-service';
import { DateInput } from 'widgets/form/dateInput';

interface InstructorHistoryViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  instructorHistory?: InstructorHistoryStore;
  currentInstructor: InstructorDto;
  changeInstructorHistory?: ChangeInstructorHistoryStore;
}

interface InstructorHistoryViewState {
  query: Query;
}

@connect(['instructorHistory', InstructorHistoryStore], ['changeInstructorHistory', ChangeInstructorHistoryStore])
class InstructorHistoryView extends React.Component<InstructorHistoryViewProps, InstructorHistoryViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get InstructorHistoryStore() {
    return this.props.instructorHistory;
  }

  private get ChangeInstructorStore() {
    return this.props.changeInstructorHistory;
  }

  constructor(props: InstructorHistoryViewProps) {
    super(props);

    this.InstructorHistoryStore.state.result = null;

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }],
        filter: null,
        skip: 0,
        take: 10
      }
    };
    this.load();
  }

  private load = () => {
    const tableFilter = this.buildODataFilter();
    const filter = getProperties(tableFilter)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    const query = { ...this.state.query, filter };
    this.props.instructorHistory.getAllHistoryAsync(query);
  };
  private buildODataFilter(filters?: InstructorHistoryDto) {
    const oDataFilters = [];

    oDataFilters.push({
      userId: { eq: { type: 'guid', value: this.props.currentInstructor.userId } }
    });
    return oDataFilters;
  }

  private onSaveRow = async (item: InstructorHistoryDto): Promise<any> => {
    this.ChangeInstructorStore.change({
      historyId: item.id,
      fromDate: item.fromDate
    });
    await this.ChangeInstructorStore.update();
    this.load();
    return { isSuccess: true, items: [], messages: [] };
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  };

  @autobind
  private onCancelChangeItem() {
    this.props.onClose(false);
  }

  public render() {
    const { t, instructorHistory } = this.props as any;

    const tableModel: TableModel<InstructorHistoryDto> = {
      columns: [
        {
          title: t('Effective Date'),
          tooltipRenderer: true,
          renderer: data => <span>{DateTimeService.toDateTimeCleanExtended(data.fromDate)}</span>,
          editor: (data, onChange) => (
            <div className="ui input evaluation_number-input">
              <DateInput
                maxDate={data.fromDate}
                value={data.fromDate}
                onChange={(_, d) => {
                  data.fromDate = d;
                  onChange();
                }}
              />
            </div>
          )
        },
        { title: t('Role'), tooltipRenderer: true, renderer: data => data.instructorRole?.name },
        { title: t('Pillar'), tooltipRenderer: true, renderer: data => data.pillar?.name },
        { title: t('Disabled as Instructor'), tooltipRenderer: true, renderer: data => (data.deleted ? t('Yes') : t('No')) }
      ],
      data: instructorHistory.state
    };

    return (
      <Modal className="edit-instructor__modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.InstructorHistoryStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Instructor Changes History')}</Modal.Header>
        <Modal.Content image>
          <Container className="modal__wrapper">
            {this.InstructorHistoryStore.state.result && !this.InstructorHistoryStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.InstructorHistoryStore.state.result.messages.map(o => o.body)}
              />
            )}
            <div className="">
              <TableView
                hiddeMenu
                model={tableModel}
                onRefresh={this.load}
                canEdit={true}
                canDelete={false}
                onPageChange={this.handlePageChange}
                onSaveRow={this.onSaveRow}
              />
            </div>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(InstructorHistoryView);
