import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import { BaseDto } from '../types';
import { UserDto } from 'stores/users/users-store';
import { DateTimeService } from 'services/datetime-service';

export interface InstructorVisaDto extends BaseDto {
  id: string;
  instructorId: string;
  instructor: string;
  user: UserDto;
  ttcLocationId: string;
  ttcLocationName: string;
  travelingLocationId: string;
  travelingLocationName: string;
  visaId: string;
  visaName: string;
  visaTreatyRequired: boolean;
  startDate: string;
  expireDate: string;
  isInstructorRestriction: boolean;
  instructorComments: string;
}

export interface CreateInstructorVisaDto {
  instructorId: string;
  ttcLocationId: string;
  travelingLocationId: string;
  visaId: string;
  visaName: string;
  visaTreatyRequired: boolean;
  startDate: string;
  expireDate: string;
  isInstructorRestriction: boolean;
  instructorComments: string;
}

export interface ChangeInstructorVisaDto extends CreateInstructorVisaDto {
  id: string;
}

export class CreateInstructorVisaValidator extends AbstractValidator<CreateInstructorVisaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    this.validateIf(o => o.startDate)
      .isNotEmpty()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Start Date is required'));

    this.validateIf(o => o.expireDate)
      .isNotEmpty()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIfDate(o => new Date(o.startDate))
      .isDefined()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Start Date is required'));

    this.validateIfDate(o => new Date(o.expireDate))
      .isDefined()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o => DateTimeService.toMoment(new Date(o.expireDate)).isSame(DateTimeService.toMoment(new Date(o.startDate)), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o => DateTimeService.toMoment(new Date(o.expireDate)).isBefore(DateTimeService.toMoment(new Date(o.startDate)), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));
  }
}

export class ChangeInstructorVisaValidator extends AbstractValidator<ChangeInstructorVisaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    this.validateIf(o => o.startDate)
      .isNotEmpty()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Start Date is required'));

    this.validateIf(o => o.expireDate)
      .isNotEmpty()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIfDate(o => new Date(o.startDate))
      .isDefined()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Start Date is required'));

    this.validateIfDate(o => new Date(o.expireDate))
      .isDefined()
      .isNotNull()
      .when(x => x.visaName !== 'None')
      .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o => DateTimeService.toMoment(new Date(o.expireDate)).isSame(DateTimeService.toMoment(new Date(o.startDate)), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o => DateTimeService.toMoment(new Date(o.expireDate)).isBefore(DateTimeService.toMoment(new Date(o.startDate)), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));
  }
}

@repository('@@INSTRUCTORVISA', 'instructorVisa.summary')
export class InstructorVisaStore extends DataStore<InstructorVisaDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-visa';
  retrievePath = 'v1/get-instructor-visas';
  updatePath = 'v1/update-instructor-visa';
  deletePath = '';
  retrieveOnePath = 'v1/get-instructor-visa';

  protected validate(item: InstructorVisaDto) {
    return new ChangeInstructorVisaValidator().validate(item as any);
  }

  constructor() {
    super('INSTRUCTORVISA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@INSTRUCTORVISA', 'instructorVisa.new')
export class NewInstructorVisaStore extends FormStore<CreateInstructorVisaDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-visa';
  retrievePath = 'v1/get-instructor-visas';
  updatePath = 'v1/update-instructor-visa';
  deletePath = '';

  protected validate(item: CreateInstructorVisaDto) {
    return new CreateInstructorVisaValidator().validate(item);
  }

  constructor() {
    super('NEW_INSTRUCTORVISA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@INSTRUCTORVISA', 'instructorVisa.change')
export class ChangeInstructorVisaStore extends FormStore<ChangeInstructorVisaDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-visa';
  retrievePath = 'v1/get-instructor-visas';
  updatePath = 'v1/update-instructor-visa';
  deletePath = '';

  protected validate(item: ChangeInstructorVisaDto) {
    return new ChangeInstructorVisaValidator().validate(item);
  }

  constructor() {
    super('CHANGE_INSTRUCTORVISA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
