import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Icon, Checkbox, Input, Form } from 'semantic-ui-react';
import './request-new-support-details.style.less';
import CostsComponent from 'widgets/form/costs.component';
import PeriodInput from 'widgets/form/period-input';
import { DateTimePeriod } from 'stores/events/events-store';
import './request-new-support-details.style.less';
import { NewSupportPositions, RequestMachinesDto, SupportDetails } from 'stores/requests/requests-store';
import { DurationInput } from 'widgets/form/durationInput';
import { ItemReference } from 'stores/dataStore';
import { MoneyInput } from 'widgets/form/moneyInput';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import { connect } from 'redux-scaffolding-ts';
import { SupportCostsStore } from 'stores/configuration/support-costs/support-costs-store';
import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';

interface NewRequestSupportDetailsProps extends WithTranslation {
  isForm?: boolean;
  readOnly: boolean;
  onChange: (values) => void;
  supportDetails: SupportDetails;
  startDate: string;
  endDate: string;
  supportCosts?: SupportCostsStore;
  requestLocationId: string;
}

@connect(['supportCosts', SupportCostsStore])
class NewRequestSupportDetails extends Component<NewRequestSupportDetailsProps> {
  private get supportCostStore() {
    return this.props.supportCosts;
  }
  getRawSupportPosition = () => {
    const { startDate, endDate } = this.props;
    const machine: RequestMachinesDto = {
      machineRelatedClusterId: null,
      machineRelatedClusterName: '',
      equipmentTypeId: null,
      equipmentTypeName: '',
      oemId: null,
      oemName: '',
      machineModelId: null,
      machineModelName: '',
      plcTypeRequestMachines: [],
      machineUnitRequestMachines: []
    };
    const tempItems = [];
    tempItems.push(machine);
    return {
      endDate: endDate,
      startDate: startDate,
      patternId: null,
      patternName: null,
      machineModels: tempItems,
      supportPositionRoleId: null,
      requestedHC: 0,
      requestedManDays: 0,
      requestedWorkingManHours: 0,
      theoreticalCost: 0
    };
  };

  private handleOnPatternMachinesChange = ({ pattern, machines }: PatternMachinesViewModel, index: number) => {
    const newSupportPositions = this.props.supportDetails.newSupportPositions.map((supportPos, idx) => {
      if (idx === index) {
        supportPos.patternId = pattern?.id ?? null;
        supportPos.patternName = pattern?.title ?? null;
        supportPos.machineModels = (machines || []).map(({ cluster, equipmentType, machineModel, machineUnits, oem, plcTypes }) => ({
          machineRelatedClusterId: cluster?.id,
          machineRelatedClusterName: cluster?.title,
          equipmentTypeId: equipmentType?.id,
          equipmentTypeName: equipmentType?.title,
          oemId: oem?.id,
          oemName: oem?.title,
          machineModelId: machineModel?.id,
          machineModelName: machineModel?.title,
          plcTypeRequestMachines: plcTypes,
          machineUnitRequestMachines: machineUnits
        }));
      }

      return supportPos;
    });
    this.props.onChange({ newSupportPositions });
  };

  handleNewSupportPosition = () => {
    const supportDetails = this.props.supportDetails;
    let newSupportPosition;
    if ((supportDetails.newSupportPositions || []).length <= 0) {
      newSupportPosition = { ...this.getRawSupportPosition() };
    } else {
      newSupportPosition = {
        ...this.getRawSupportPosition()
      };
    }
    this.props.onChange({ newSupportPositions: [...supportDetails.newSupportPositions, newSupportPosition] });
  };

  removeSupportPosition = (index: number) => {
    const newSupportPositions = this.props.supportDetails.newSupportPositions.filter((_, i) => i !== index);
    const totalRequestedManDays = newSupportPositions.reduce((prevVal, acc) => prevVal + Number(acc['requestedManDays']), 0);
    const totalRequestedManMonths = totalRequestedManDays / 30;
    const totalRequestedWorkingManHours = newSupportPositions.reduce(
      (prevVal, acc) => prevVal + Number(acc['requestedWorkingManHours']),
      0
    );
    const totalTheoreticalCost = newSupportPositions.reduce((prevVal, acc) => prevVal + Number(acc['theoreticalCost']), 0);

    this.props.onChange({
      newSupportPositions,
      totalRequestedWorkingManHours: totalRequestedWorkingManHours,
      totalRequestedManDays: totalRequestedManDays,
      totalRequestedManMonths: totalRequestedManMonths,
      totalTheoreticalCost: totalTheoreticalCost
    });
  };

  roleChange = (value: ItemReference, index: number) => {
    const newSupportPositions = this.props.supportDetails.newSupportPositions.map((supportPos, idx) => {
      if (idx === index) {
        supportPos = { ...supportPos, supportPositionRoleId: value ? value.id : null, supportPositionRoleName: value ? value.title : '' };
      }
      return supportPos;
    });
    this.props.onChange({ newSupportPositions });
  };

  capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  inputChangeHandler = (property: string, idx: number, value: string) => {
    const newSupportPositions = this.props.supportDetails.newSupportPositions.map((supportPos, i) => {
      if (i === idx) {
        supportPos = { ...supportPos, [property]: property !== 'theoreticalCost' ? value : Number(value) };
      }
      return supportPos;
    });

    if (property === 'requestedHC' && value !== '') {
      this.calculateTheoreticalCost(idx, Number(value));
    } else {
      this.props.onChange({ newSupportPositions });
    }
  };

  calculateTheoreticalCost = async (idx: number, value: number, from?: string, to?: string) => {
    this.props.onChange({ readOnly: true });
    const newSupportPositions = await Promise.all(
      this.props.supportDetails.newSupportPositions.map(
        async (supportPos, i): Promise<NewSupportPositions> => {
          if (i === idx) {
            const data = await this.supportCostStore.getSupportPositionCost(
              this.props.requestLocationId,
              from || supportPos.startDate,
              to || supportPos.endDate,
              value.toString()
            );
            supportPos = {
              ...supportPos,
              requestedManDays: data?.requestedManDays,
              requestedWorkingManHours: data?.requestedWorkingManHours,
              theoreticalCost: data?.theoreticalCost,
              requestedHC: value,
              startDate: from || supportPos.startDate,
              endDate: to || supportPos.endDate
            };
          }
          return supportPos;
        }
      )
    );
    const totalRequestedManDays = newSupportPositions.reduce((prevVal, acc) => prevVal + Number(acc['requestedManDays']), 0);
    const totalRequestedManMonths = totalRequestedManDays / 30;
    const totalRequestedWorkingManHours = newSupportPositions.reduce(
      (prevVal, acc) => prevVal + Number(acc['requestedWorkingManHours']),
      0
    );
    const totalTheoreticalCost = newSupportPositions.reduce((prevVal, acc) => prevVal + Number(acc['theoreticalCost']), 0);

    this.props.onChange({
      readOnly: false,
      newSupportPositions,
      totalRequestedWorkingManHours: totalRequestedWorkingManHours,
      totalRequestedManDays: totalRequestedManDays,
      totalRequestedManMonths: totalRequestedManMonths,
      totalTheoreticalCost: totalTheoreticalCost
    });
  };

  datesChangeHandler = ({ from, to }: DateTimePeriod, idx: number) => {
    const { startDate, endDate, requestedHC } = this.props.supportDetails.newSupportPositions[idx];
    let newSupportPositions;
    if (startDate && from !== startDate) {
      newSupportPositions = this.props.supportDetails.newSupportPositions.map((supportPos, i) => {
        if (i === idx) supportPos = { ...supportPos, startDate: from };
        return supportPos;
      });
    } else if (endDate && to !== endDate) {
      newSupportPositions = this.props.supportDetails.newSupportPositions.map((supportPos, i) => {
        if (i === idx) supportPos = { ...supportPos, endDate: to };
        return supportPos;
      });
    } else return;
    this.props.onChange({ newSupportPositions });
    if (from && to && requestedHC > 0) {
      this.calculateTheoreticalCost(idx, requestedHC, from, to);
    }
  };

  render() {
    const { t, readOnly } = this.props;
    const { supportDetails } = this.props;
    const { isForm } = this.props;
    const {
      totalRequestedWorkingManHours,
      totalRequestedManDays,
      totalRequestedManMonths,
      totalTheoreticalCost,
      requestPreApprovedDuringASP,
      newSupportPositions
    } = supportDetails;
    const divHiddenStyle = {
      display: 'none'
    };
    return (
      <div className={`support-details-container${isForm ? ' isform' : ''}`}>
        <div className="support-details__pre-approved" style={isForm ? {} : divHiddenStyle}>
          <Checkbox
            disabled={readOnly}
            label={'Request pre-approved during ASP'}
            checked={requestPreApprovedDuringASP}
            onChange={(_, { checked }) => this.props.onChange({ requestPreApprovedDuringASP: checked })}
          />
        </div>
        <div className="support-details__main">
          <div className="support-details__add-position">
            {isForm && <Icon size="large" name="cogs" />}
            <Button disabled={readOnly} onClick={this.handleNewSupportPosition} icon className="support-details__add-btn">
              {t('Add Support Position')}
              <Icon name="user plus" />
            </Button>
            <div className="costs">
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Requested')}
                subLabel={t('Working Man Hours')}
                value={totalRequestedWorkingManHours}
                hideIcon
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Requested')}
                subLabel={t('Man Days')}
                value={totalRequestedManDays}
                hideIcon
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Requested')}
                subLabel={t('Man Months')}
                value={totalRequestedManMonths}
                hideIcon
                decimals={2}
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total')}
                subLabel={t('Theoretical Cost')}
                value={totalTheoreticalCost}
                decimals={2}
              />
            </div>
          </div>
          {(newSupportPositions || []).length > 0 &&
            newSupportPositions.map(
              (
                {
                  requestedHC,
                  startDate,
                  endDate,
                  requestedManDays,
                  requestedWorkingManHours,
                  theoreticalCost,
                  supportPositionRoleId,
                  patternId,
                  patternName,
                  machineModels
                },
                i
              ) => {
                const myPattern = patternId ? ({ id: patternId, title: patternName ?? '' } as ItemReference) : null;
                const myMachines = (machineModels || []).map(m => ({
                  cluster: m.machineRelatedClusterId ? { id: m.machineRelatedClusterId, title: m.machineRelatedClusterName } : null,
                  equipmentType: m.equipmentTypeId ? { id: m.equipmentTypeId, title: m.equipmentTypeName } : null,
                  oem: m.oemId ? { id: m.oemId, title: m.oemName } : null,
                  machineModel: m.machineModelId ? { id: m.machineModelId, title: m.machineModelName } : null,
                  machineUnits: m.machineUnitRequestMachines,
                  plcTypes: m.plcTypeRequestMachines
                }));
                const patternMachines: PatternMachinesViewModel = { pattern: myPattern, machines: myMachines };
                return (
                  <div key={i} className="support-details__item">
                    <div className="support-details__item-collection">
                      <div className="new_support-details__item-main">
                        <div className="vis-borde-01">
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">{t('Dates')}</label>
                            <PeriodInput
                              id={'request-suppport-details-' + i}
                              readOnly={readOnly}
                              onChange={period => this.datesChangeHandler(period, i)}
                              icon={false}
                              value={{ from: startDate, to: endDate }}
                            />
                          </div>
                        </div>
                        <Form key={'Form' + i} className="wizard__step2__machine-related">
                          <Form.Group className="wizard__step2__machine-related__content">
                            <div className="form__event-details-tab__mr__pattern form__event-details-tab__element wizard__mr-table mr-table-component__common-styles">
                              <PatternMachines
                                readOnly={readOnly}
                                value={patternMachines}
                                onChange={p => this.handleOnPatternMachinesChange(p, i)}
                                loadPatternsOnOpen={isForm}
                              />
                            </div>
                          </Form.Group>
                        </Form>
                        <div className="vis-borde-03">
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">{t('Role')}</label>
                            <ProfessionEditor
                              readOnly={readOnly}
                              placeholder={t('Role')}
                              value={supportPositionRoleId ? { id: supportPositionRoleId, title: '' } : null}
                              onChange={role => this.roleChange(role, i)}
                            />
                          </div>
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">Requested HC</label>
                            <Input
                              disabled={readOnly}
                              type="number"
                              value={requestedHC}
                              onChange={(_, { value }) => this.inputChangeHandler('requestedHC', i, value)}
                            />
                          </div>
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">{t('Requested Man Days')}</label>
                            <Input disabled={true} type="number">
                              <DurationInput
                                onChange={value => this.inputChangeHandler('requestedManDays', i, value)}
                                value={`${requestedManDays}`}
                              />
                            </Input>
                          </div>
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">{t('Requested Working Man hours')}</label>
                            <Input
                              disabled={true}
                              type="number"
                              onChange={value => this.inputChangeHandler('requestedWorkingManHours', i, `${value}`)}
                              value={`${requestedWorkingManHours}`}
                            ></Input>
                          </div>
                          <div className="supppor-details__item-main-segment">
                            <label className="support-details__segment-label">{t('Theoretical Cost')}</label>
                            <Input disabled={true} type="number">
                              <MoneyInput
                                onChange={value => this.inputChangeHandler('theoreticalCost', i, `${value}`)}
                                value={theoreticalCost}
                                currencyChar="$"
                                decimals={2}
                              />
                            </Input>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!readOnly && <Icon color="red" name="remove" onClick={() => this.removeSupportPosition(i)} />}
                  </div>
                );
              }
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(NewRequestSupportDetails);
