import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { NewLocationStore, CreateLocationDto } from 'stores/configuration/locations/locations-store';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import CountryEditor from 'widgets/bussiness/country-editor';
import RegionEditor from 'widgets/bussiness/region-editor';

interface NewLocationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newLocation?: NewLocationStore;
}

interface NewLocationViewState {
  userClickedSave: boolean;
}

@connect(['newLocation', NewLocationStore])
class NewLocationView extends React.Component<NewLocationViewProps, NewLocationViewState> {
  private get newLocationStore() {
    return this.props.newLocation;
  }

  constructor(props: NewLocationViewProps) {
    super(props);
    this.newLocationStore.createNew({
      countryId: '',
      location: '',
      code: '',
      active: true,
      regionId: null
    });

    this.state = { userClickedSave: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewLocationViewProps) {
    if (this.newLocationStore.state.result && this.newLocationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newLocationStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newLocationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newLocationStore.change({ ...this.newLocationStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newLocationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Location')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newLocationStore.state.result && !this.newLocationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newLocationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newLocationStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    error={!this.newLocationStore.state.item.code && this.state.userClickedSave}
                    required
                    fluid={!this.newLocationStore.state.item.code}
                    label={t('Code')}
                    placeholder={t('Code')}
                    value={this.newLocationStore.state.item.code}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateLocationDto>('code'), value)}
                  />
                  <Form.Input
                    error={!this.newLocationStore.state.item.location && this.state.userClickedSave}
                    required
                    fluid={!this.newLocationStore.state.item.location}
                    label={t('Location')}
                    placeholder={t('Location')}
                    value={this.newLocationStore.state.item.location}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateLocationDto>('location'), value)}
                  />

                  <Form.Checkbox
                    style={{ top: 25 }}
                    toggle
                    checked={this.newLocationStore.state.item.active}
                    label={t('Active')}
                    placeholder={t('Active')}
                    onChange={(e, { checked }) => {
                      this.handleValue(nameof<CreateLocationDto>('active'), checked);
                    }}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field required error={!this.newLocationStore.state.item.countryId && this.state.userClickedSave}>
                    <div className={`required ${isNullOrWhiteSpaces(this.newLocationStore.state.item.countryId)} field`}>
                      <label>{t('Country')}</label>
                      <CountryEditor
                        placeholder={t('Country')}
                        nullable
                        value={
                          isNullOrWhiteSpaces(this.newLocationStore.state.item.countryId)
                            ? null
                            : { id: this.newLocationStore.state.item.countryId, title: '' }
                        }
                        onChange={value => {
                          this.handleValue(nameof<CreateLocationDto>('countryId'), value.id);
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field`}>
                      <label>{t('Region')}</label>
                      <RegionEditor
                        placeholder={t('Region')}
                        nullable
                        value={
                          isNullOrWhiteSpaces(this.newLocationStore.state.item.regionId)
                            ? null
                            : { id: this.newLocationStore.state.item.regionId, title: '' }
                        }
                        onChange={value => {
                          this.handleValue(nameof<CreateLocationDto>('regionId'), value == null ? null : value.id);
                        }}
                        clearable={true}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required></Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewLocationView);
