import React from 'react';
import * as autobind from 'autobind';
import { WithTranslation, withTranslation } from 'react-i18next';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import { List, Label, Button, Icon } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { connect } from 'redux-scaffolding-ts';
import { TnaTemplateData, TnaTemplateMachines } from '../tna-templates-wizard-component';
import './tna-wizard-third-step.less';
import { ProfileItemStore } from 'stores/profile/profile-store';
import ChooseTnaQuestionsView from 'widgets/form/choose-tna-questions-form';
import { TnaQuestionBankDto } from 'stores/assessments/questionBank/tna-questionBank-store';
import { TnaSkillSectionQuestionDto, TnaSkillTemplateSectionDto } from 'stores/assessments/templates/tna-templates-store';
import MultiMachineUnitEditor from 'widgets/bussiness/multi-machine-unit-editor';
import { nameof } from 'utils/object';
import { MachineUnitDto, MachineUnitsStore } from 'stores/configuration/machinery/machine-units-store';
import { OrderDefinition } from 'stores/dataStore';

export interface ThirdStepProps extends WithTranslation {
  isStepActive?: boolean;
  profileItemStore?: ProfileItemStore;
  onChangeProperty: (property: string, value: any) => void;
  onAddNewMachineRelatedSkillsSections: () => void;
  onRemoveQuestion: (skillId: string, questionId: string, index?: number) => void;
  templateData: TnaTemplateData;
  machineRow: TnaTemplateMachines;
  machineUnitStore?: MachineUnitsStore;
  stepNumber: string;
}

export interface ThirdStepState {
  onShowQuestionContent: boolean;
  isOpenSelectTnaQuestionsModal: boolean;
  isMachineRelated: boolean;
  equipmentType: string;
  machineUnitIndex: number;
  alreadySelectedQuestions: string[];
  skillToSelectQuestions: TnaSkillTemplateSectionDto;
  machineUnitsOptions?: MachineUnitDto[];
}

@connect(['profileItemStore', ProfileItemStore], ['machineUnitStore', MachineUnitsStore])
class ThirdStepContent extends React.Component<ThirdStepProps, ThirdStepState> {
  constructor(props: ThirdStepProps) {
    super(props);
    this.state = {
      alreadySelectedQuestions: null,
      onShowQuestionContent: false,
      isMachineRelated: null,
      isOpenSelectTnaQuestionsModal: false,
      equipmentType: null,
      machineUnitIndex: null,
      skillToSelectQuestions: null,
      machineUnitsOptions: null
    };
  }

  private get machineUnitStore() {
    return this.props.machineUnitStore;
  }

  componentDidMount() {
    this.loadMachineUnits();
  }

  onShowQuestionContent = () => {
    this.setState({
      onShowQuestionContent: true
    });
  };

  @autobind
  private onSelectTnaQuestions(
    alreadySelectedQuestions: string[],
    isMachineRelated: boolean,
    equipmentType: string = null,
    machineUnitIndex: number = null,
    skill: TnaSkillTemplateSectionDto
  ) {
    this.setState({
      isOpenSelectTnaQuestionsModal: true,
      equipmentType,
      isMachineRelated,
      alreadySelectedQuestions,
      machineUnitIndex,
      skillToSelectQuestions: skill
    });
  }

  @autobind
  private onAddTnaQuestions(questions: TnaQuestionBankDto[]) {
    const { templateData } = this.props;
    let change = { property: null, value: null };
    if (questions && questions.length > 0) {
      if (this.state.isMachineRelated) {
        change.property = 'machineRelatedSections';
        const machineRelatedSections = [...templateData.machineRelatedSections];
        machineRelatedSections[this.state.machineUnitIndex].skillSections = machineRelatedSections[
          this.state.machineUnitIndex
        ].skillSections.map(skill => {
          if (skill === this.state.skillToSelectQuestions) {
            skill.questions = (skill.questions || []).concat(
              questions.map(({ id, question, friendlyId }) => ({ id, question: question.text, friendlyId }))
            );
          }
          return skill;
        });
        change.value = machineRelatedSections;
      } else {
        change.property = 'nonMachineRelatedSkillSections';
        change.value = templateData.nonMachineRelatedSkillSections.map(nmr => {
          if (nmr === this.state.skillToSelectQuestions) {
            nmr.questions = (nmr.questions || []).concat(
              questions.map(({ id, question, friendlyId }) => ({ id, question: question.text, friendlyId }))
            );
          }
          return nmr;
        });
      }
      change.property && change.value && this.props.onChangeProperty(change.property, change.value);
    }

    this.setState({ isOpenSelectTnaQuestionsModal: false, equipmentType: null, machineUnitIndex: null, alreadySelectedQuestions: null });
  }

  handleMoveUpQuestion = (questionIndex, skillId, machineUnitIndex?: number) => {
    const templateDataCopy = { ...this.props.templateData };
    const machineUnitSection = machineUnitIndex != null ? templateDataCopy.machineRelatedSections[machineUnitIndex] : null;
    const skillSections = machineUnitIndex == null ? templateDataCopy.nonMachineRelatedSkillSections : machineUnitSection.skillSections;
    const skillSection = skillSections.find(ss => ss.skillId === skillId);

    if (skillSections && skillSections.length > 0 && questionIndex <= 0) return;

    const temporalVariable = skillSection.questions[questionIndex - 1];

    skillSection.questions[questionIndex - 1] = skillSection.questions[questionIndex];

    skillSection.questions[questionIndex] = temporalVariable;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleDownQuestion = (questionIndex, skillId, machineUnitIndex?: number) => {
    const templateDataCopy = { ...this.props.templateData };
    const machineUnitSection = machineUnitIndex != null ? templateDataCopy.machineRelatedSections[machineUnitIndex] : null;
    const skillSections = machineUnitIndex == null ? templateDataCopy.nonMachineRelatedSkillSections : machineUnitSection.skillSections;
    const skillSection = skillSections.find(ss => ss.skillId === skillId);

    if (skillSections && skillSections.length > 0 && questionIndex >= skillSection.questions.length - 1) return;

    const temporalVariable = skillSection.questions[questionIndex + 1];

    skillSection.questions[questionIndex + 1] = skillSection.questions[questionIndex];

    skillSection.questions[questionIndex] = temporalVariable;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveUpSkillNonMachineRelated = (skillId, idx) => {
    const templateDataCopy = { ...this.props.templateData };
    if (idx <= 0) return;
    const nonMachineRelatedSkillsArray = this.props.templateData.nonMachineRelatedSkillSections;

    const skillToMove = nonMachineRelatedSkillsArray.find(skill => skill.skillId === skillId);

    const temporalVariable = nonMachineRelatedSkillsArray[idx - 1];

    nonMachineRelatedSkillsArray[idx] = temporalVariable;

    nonMachineRelatedSkillsArray[idx - 1] = skillToMove;

    templateDataCopy.nonMachineRelatedSkillSections = nonMachineRelatedSkillsArray;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveDownSkillNonMachineRelated = (skillId, idx) => {
    const templateDataCopy = { ...this.props.templateData };
    if (idx >= templateDataCopy.nonMachineRelatedSkillSections.length - 1) return;
    const nonMachineRelatedSkillsArray = this.props.templateData.nonMachineRelatedSkillSections;

    const skillToMove = nonMachineRelatedSkillsArray.find(skill => skill.skillId === skillId);

    const temporalVariable = nonMachineRelatedSkillsArray[idx + 1];

    nonMachineRelatedSkillsArray[idx] = temporalVariable;

    nonMachineRelatedSkillsArray[idx + 1] = skillToMove;

    templateDataCopy.nonMachineRelatedSkillSections = nonMachineRelatedSkillsArray;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveUpSkillWithMachineRelated = (skillId, sectionIndex, skillIndex) => {
    const templateDataCopy = { ...this.props.templateData };
    if (skillIndex <= 0) return;

    const machineRelatedSection = this.props.templateData.machineRelatedSections[sectionIndex];
    const skillsOnSection = machineRelatedSection.skillSections;

    const skillToMove = skillsOnSection.find(skill => skill.skillId === skillId);
    const temporalVariable = skillsOnSection[skillIndex - 1];

    skillsOnSection[skillIndex] = temporalVariable;
    skillsOnSection[skillIndex - 1] = skillToMove;

    templateDataCopy.machineRelatedSections[sectionIndex].skillSections = skillsOnSection;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  handleMoveDownSkillWithMachineRelated = (skillId, sectionIndex, skillIndex) => {
    const templateDataCopy = { ...this.props.templateData };
    if (skillIndex >= templateDataCopy.machineRelatedSections[sectionIndex].skillSections.length - 1) return;

    const machineRelatedSection = this.props.templateData.machineRelatedSections[sectionIndex];
    const skillsOnSection = machineRelatedSection.skillSections;

    const skillToMove = skillsOnSection.find(skill => skill.skillId === skillId);
    const temporalVariable = skillsOnSection[skillIndex + 1];

    skillsOnSection[skillIndex] = temporalVariable;
    skillsOnSection[skillIndex + 1] = skillToMove;

    templateDataCopy.machineRelatedSections[sectionIndex].skillSections = skillsOnSection;

    this.props.onChangeProperty('templateData', templateDataCopy);
  };

  onRemoveMachineUnitSkillGroup = (machineUnitIndex: number) => {
    this.props.onChangeProperty(
      nameof<TnaTemplateData>('machineRelatedSections'),
      this.props.templateData.machineRelatedSections.filter((_, i) => i !== machineUnitIndex)
    );
  };

  onGenerateQuestionsList = (skillId: string, questions: TnaSkillSectionQuestionDto[], machineUnitIndex?: number) => {
    return questions.map((question, i) => {
      return (
        <div key={question.friendlyId + i} className="tna-wizard__assign-question">
          <div className="tna-wizard__assign-question__items">
            <div className="wizard__sort-icons-wrapper tna-arrow-left-margin">
              <Icon
                disabled={i <= 0}
                onClick={() => this.handleMoveUpQuestion(i, skillId, machineUnitIndex)}
                name="caret square up outline"
              />
            </div>
            <div className="wizard__sort-icons-wrapper">
              <Icon
                disabled={i >= questions.length - 1}
                onClick={() => this.handleDownQuestion(i, skillId, machineUnitIndex)}
                name="caret square down outline"
              />
            </div>
            <p className="wizard__question-item">{question.friendlyId}</p>
            <p className="wizard__question-item">{question.question}</p>
          </div>
          <Icon
            className="assigned-profile-row-machine__remove"
            name="remove"
            size="large"
            color="red"
            onClick={() => this.props.onRemoveQuestion(skillId, question.id, machineUnitIndex)}
          />
        </div>
      );
    });
  };

  onMachineUnitChange = (id: string, idx: number) => {
    const machineRelatedSections = [...this.props.templateData.machineRelatedSections];
    machineRelatedSections[idx].machineUnitId = id;
    this.props.onChangeProperty(nameof<TnaTemplateData>('machineRelatedSections'), machineRelatedSections);
  };
  shouldComponentUpdate(nextProps, nextState) {
    const { profile }: { profile: any } = this.props.templateData;
    const { machineRow } = this.props;
    if (
      nextProps.isStepActive &&
      nextProps.profile &&
      (profile.id === nextProps.profile.id || nextProps.profile.templateTitle !== profile.templateTitle) &&
      (machineRow.equipmentType === nextProps.machineRow.equipmentType ||
        machineRow.oem === nextProps.machineRow.oem ||
        machineRow.machineRelatedCluster === nextProps.machineRow.machineRelatedCluster)
    ) {
      return false;
    }
    return true;
  }

  private loadMachineUnits = async () => {
    const filter = [];
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<MachineUnitDto>('name'), useProfile: false }];

    return await this.machineUnitStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }).then(machineUnits => {
      const options = [];
      machineUnits.items.forEach(machineUnit => options.push(machineUnit));
      this.setState({ machineUnitsOptions: options });
    });
  };

  render() {
    const { t, isStepActive, templateData, stepNumber } = this.props;
    const { machineRelatedSections, commonMachineRelatedSkillSections, machines, nonMachineRelatedSkillSections } = templateData;
    if (isStepActive) {
      return (
        <>
          <div className="wizard__step wizard__step-three">
            <StepsComponent active number={t(stepNumber)} title={t('SELECT QUESTIONS')} />
            <div className="step-three__skills-questions-wrapper">
              {nonMachineRelatedSkillSections && nonMachineRelatedSkillSections.length > 0 ? (
                <>
                  <p className="text__bold">{t('Non-Machine Related Skills')}</p>
                  {nonMachineRelatedSkillSections.map((skill, idx) => (
                    <List key={skill.skillId + idx} verticalAlign="middle">
                      <div>
                        <List.Item className="edit__select-profiles__wrapper__row">
                          <Label
                            className={`skill-label${isNullOrUndefined(machines) ? ' expandable' : ''}`}
                            onClick={() => this.onShowQuestionContent}
                            style={{ width: '100%', height: '38px' }}
                          >
                            <p>{skill.skillName}</p>

                            <div className="wizard__sort-icons-wrapper">
                              <Button
                                onClick={() =>
                                  this.onSelectTnaQuestions(
                                    skill.questions && skill.questions.length > 0 && skill.questions.map(({ id }) => id),
                                    false,
                                    (skill as any).equipmentType,
                                    null,
                                    skill
                                  )
                                }
                                className="tna-template-wizard__add-questions-btn"
                                type="button"
                              >
                                {t('Add Questions')}
                              </Button>
                              <Icon
                                disabled={idx <= 0}
                                onClick={() => this.handleMoveUpSkillNonMachineRelated(skill.skillId, idx)}
                                name="caret square up outline"
                              />
                              <Icon
                                disabled={idx >= nonMachineRelatedSkillSections.length - 1}
                                onClick={() => this.handleMoveDownSkillNonMachineRelated(skill.skillId, idx)}
                                name="caret square down outline"
                              />
                            </div>
                          </Label>
                        </List.Item>
                      </div>

                      {skill.questions && skill.questions.length > 0 && this.onGenerateQuestionsList(skill.skillId, skill.questions)}
                    </List>
                  ))}
                </>
              ) : null}
            </div>

            {commonMachineRelatedSkillSections && commonMachineRelatedSkillSections.length > 0 ? (
              <>
                <div className="step-three__skills-questions-wrapper">
                  <p className="text__bold">{t('Machine Related Skills')}</p>
                  <div className="template-wizard__machine-related">
                    <Button onClick={this.props.onAddNewMachineRelatedSkillsSections} className="add-row-btn" type="button">
                      {t('Add Machine Unit')}
                    </Button>
                    {machineRelatedSections.map((sections, index) => (
                      <React.Fragment key={sections.machineUnitId + index}>
                        <div className="template-wizard__machineUnit-button-container editor-32px">
                          <MultiMachineUnitEditor
                            className="template-wizard__machine-unit"
                            value={sections.machineUnitId}
                            multiple={false}
                            selection={false}
                            oemId={machines && machines.length > 0 ? machines[0].oem.id : null}
                            equipmentId={machines && machines.length > 0 ? machines[0].equipmentType.id : null}
                            onChange={(machineUnitId: string) => this.onMachineUnitChange(machineUnitId, index)}
                            machineUnitDtos={this.state.machineUnitsOptions}
                          />
                          {machineRelatedSections.length > 1 && (
                            <Button
                              onClick={() => this.onRemoveMachineUnitSkillGroup(index)}
                              className="tna-template-wizard__remove-group-btn"
                              type="button"
                            >
                              {t('Remove')}
                            </Button>
                          )}
                        </div>
                        {sections.skillSections &&
                          sections.skillSections.length > 0 &&
                          sections.skillSections.map((section, i) => (
                            <List key={section.skillId + i} verticalAlign="middle">
                              <div>
                                <List.Item className="edit__select-profiles__wrapper__row">
                                  <Label
                                    className={`skill-label${isNullOrUndefined(machines) ? ' expandable' : ''}`}
                                    onClick={() => this.onShowQuestionContent}
                                    style={{ width: '100%', height: '38px' }}
                                  >
                                    <p>{section.skillName}</p>

                                    <div className="wizard__sort-icons-wrapper">
                                      <Button
                                        onClick={() =>
                                          this.onSelectTnaQuestions(
                                            section.questions && section.questions.length > 0 && section.questions.map(({ id }) => id),
                                            true,
                                            null,
                                            index,
                                            section
                                          )
                                        }
                                        className="tna-template-wizard__add-questions-btn"
                                        type="button"
                                      >
                                        {t('Add Questions')}
                                      </Button>
                                      <Icon
                                        disabled={i <= 0}
                                        onClick={() => this.handleMoveUpSkillWithMachineRelated(section.skillId, index, i)}
                                        name="caret square up outline"
                                      />
                                      <Icon
                                        disabled={i >= sections.skillSections.length - 1}
                                        onClick={() => this.handleMoveDownSkillWithMachineRelated(section.skillId, index, i)}
                                        name="caret square down outline"
                                      />
                                    </div>
                                  </Label>
                                </List.Item>

                                <div className="edit__select-profiles__edit-wrapper"></div>
                              </div>

                              {section.questions &&
                                section.questions.length > 0 &&
                                this.onGenerateQuestionsList(section.skillId, section.questions, index)}
                            </List>
                          ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {this.state.isOpenSelectTnaQuestionsModal && (
            <ChooseTnaQuestionsView
              professionId={this.props.templateData.profile.professionId}
              equipmentTypeId={this.state.isMachineRelated && machines && machines.length > 0 ? machines[0].equipmentType.id : null}
              oemId={this.state.isMachineRelated && machines && machines.length > 0 ? machines[0].oem.id : null}
              tnaSkillId={this.state.skillToSelectQuestions.skillId}
              tnaSkillName={this.state.skillToSelectQuestions.skillName}
              alreadySelectedQuestions={this.state.alreadySelectedQuestions}
              isMachineRelated={this.state.isMachineRelated}
              onAddTnaQuestions={this.onAddTnaQuestions}
              onCloseModal={() => this.setState({ isOpenSelectTnaQuestionsModal: false })}
            />
          )}
        </>
      );
    } else {
      return (
        <div className="wizard__step wizard__step-one">
          <StepsComponent active={isStepActive} number={t(stepNumber)} title={t('SELECT QUESTIONS')} />
        </div>
      );
    }
  }
}

export default withTranslation()(ThirdStepContent);
