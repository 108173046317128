import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import IWSFormsList from './forms/iws-forms-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface IwsSkillsConfigurationHomeProps extends RouteComponentProps, WithTranslation {}
export interface IwsSkillsConfigurationHomeState {}

class AssessorsIwsHomePage extends React.Component<IwsSkillsConfigurationHomeProps, IwsSkillsConfigurationHomeState> {
  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <h3 id="tna-forms-list-filters__title">{t('IWS Step Up Cards')}</h3>
        <LineSeparator />
        <IWSFormsList isUserAssessor={true} />
      </>
    );
  }
}

export default withTranslation()(AssessorsIwsHomePage);
