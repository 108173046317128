import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { EventTypeDto, DropDownEventTypesStore } from '../../../stores/configuration/events-workflow/event-types-store';
import { nameof } from '../../../utils/object';
import { Dropdown } from 'semantic-ui-react';
import { QueryParameters, ItemReference } from 'stores/dataStore';
import MultipleSelectionDropdown from 'widgets/editors/multiple-selection-dropdown';

interface MultiEventTypeEditorProps extends WithTranslation {
  value: string[];
  onChange: (value: string[], items: ItemReference[]) => void;
  placeholder?: string;
  eventTypesStore?: DropDownEventTypesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  filteredEventTypes?: string[];
  id?: string;
  className?: string;
  directOnly?: boolean;
  filterDirectEventCreator?: boolean;
  filterRequestCreator?: boolean;
  practicalTestsOnly?: boolean;
  error?: boolean;
  useOriginalEventTypeIdAsValue?: boolean;
  schedulerRender?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultiEventTypeEditorState {
  value: string[];
  availableEventTypes: { [id: string]: EventTypeDto };
  eventTypeOptions: { text: string; value: string }[];
}

@connect(['eventTypesStore', DropDownEventTypesStore])
class MultiEventTypeEditor extends React.Component<MultiEventTypeEditorProps, MultiEventTypeEditorState> {
  private get eventTypeStore() {
    return this.props.eventTypesStore;
  }

  constructor(props: MultiEventTypeEditorProps) {
    super(props);
    this.state = { value: this.props.value, availableEventTypes: {}, eventTypeOptions: [] };
  }

  private initEventTypes = async () => {
    let filter = [];
    if (this.props.filteredEventTypes && this.props.filteredEventTypes.length > 0) {
      if (!!this.props.useOriginalEventTypeIdAsValue) {
        filter.push({ OriginalEventTypeId: { in: { type: 'guid', value: this.props.filteredEventTypes } } });
      } else {
        filter.push({ id: { in: { type: 'guid', value: this.props.filteredEventTypes } } });
      }
    }

    if (this.props.directOnly) {
      filter.push({ DirectEventsAllowed: true });
    }
    if (this.props.practicalTestsOnly) {
      filter.push({ PracticalTest: true });
    }
    filter.push({ Active: true });
    filter.push({ Disabled: false });

    const parameters: QueryParameters =
      !!this.props.filterDirectEventCreator || !!this.props.filterRequestCreator ? ({} as QueryParameters) : undefined;

    if (!!this.props.filterDirectEventCreator) parameters['filterDirectEventCreator'] = 'true';

    if (!!this.props.filterRequestCreator) parameters['filterRequestCreator'] = 'true';

    const key = !!this.props.useOriginalEventTypeIdAsValue ? 'originalEventTypeId' : 'id';

    return await this.eventTypeStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }],
        filter,
        parameters
      })
      .then(eTypes => {
        const dict = {};
        const options = [];

        eTypes.items.forEach(et => {
          dict[et[key]] = et;
          options.push({ text: et.name, value: et[key] });
        });

        this.setState({ availableEventTypes: dict, eventTypeOptions: options });
      });
  };

  selectedEventType = (value: any) => {
    this.setState({ value });
    const items = ((value || []) as string[]).map(x => ({ id: x, title: this.state.availableEventTypes?.[x]?.name }));
    this.props.onChange(value, items);
  };

  onOpenDropDown = () => {
    if ((this.state.eventTypeOptions || []).length <= 0) {
      this.initEventTypes();
    }
  };

  public render() {
    const { t, placeholder, className, schedulerRender } = this.props;
    const { eventTypeOptions, value } = this.state;
    if (schedulerRender)
      return (
        <MultipleSelectionDropdown
          searchable
          clearable
          placeholder={placeholder}
          options={(eventTypeOptions || []).map(x => ({ ...x, id: x.value }))}
          value={value || []}
          onChange={this.selectedEventType}
          className={className}
          onOpenDropDown={this.onOpenDropDown}
          onBlur={e => this.props.onBlur && this.props.onBlur(e)}
          onFocus={e => this.props.onFocus && this.props.onFocus(e)}
        />
      );

    return (
      <Dropdown
        id={this.props.id}
        search
        inline
        selection
        closeOnChange
        closeOnEscape
        clearable
        multiple
        options={eventTypeOptions}
        error={!value || !!this.props.error}
        className={`${className || ''} planit-users-inputs planit-user-dropdown planit-user-selector`}
        value={value || null}
        onChange={(e, { value }) => this.selectedEventType(value)}
        placeholder={placeholder || t('Select Event Type')}
        onOpen={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

export default withTranslation()(MultiEventTypeEditor);
