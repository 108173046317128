import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateInput } from 'widgets/form/dateInput';
import './general-data-step.less';
import { DateTimeService } from 'services/datetime-service';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { trim, isNullOrEmpty } from 'utils/useful-functions';
import { MachineModelSelectionViewModel } from './select-machinery-step-types';
import { ProfileItemDto } from 'stores/profile/profile-store';

export interface GeneralDataViewModel {
  title: string;
  location: ItemReference;
  profileId: string;
  deadline: string;
  machineModelsSelection: MachineModelSelectionViewModel[];
}

export interface GeneralDataStepProps extends WithTranslation {
  isStepActive: boolean;
  value: GeneralDataViewModel;
  onChange?: (value: GeneralDataViewModel, profile?: ProfileItemDto) => void;
  isOnEditMode: boolean;
  isGlobalPoc: boolean;
}

export interface GeneralDataStepState {
  isStepActive: boolean;
  profile: string;
  isGlobalPoc;
}

class GeneralDataStep extends Component<GeneralDataStepProps, GeneralDataStepState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  static defaultProps = { onChange: (...args) => {} };

  constructor(props: GeneralDataStepProps) {
    super(props);
    this.state = {
      isStepActive: !!this.props.isStepActive,
      profile: '',
      isGlobalPoc: false
    };
  }

  UNSAFE_componentWillReceiveProps(next: GeneralDataStepProps) {
    if (next != null && next !== this.props) {
      if (next.isStepActive !== this.props.isStepActive) {
        this.setState({
          isStepActive: !!next.isStepActive
        });
      }
      this.generateTitle();
    }
  }

  private handleOnChange = (property: keyof GeneralDataViewModel, propertyValue: any) => {
    const value = { ...this.props.value };
    let profile = this.state.profile;
    let profileDto = null;
    let profileChanged = false;

    if (property === 'profileId') {
      value.profileId = propertyValue == null ? null : propertyValue.id;
      profile = propertyValue == null ? '' : propertyValue.name;
      profileChanged = true;
      profileDto = propertyValue;
    } else value[property] = propertyValue;

    value.title = this.generateTitle();
    this.props.onChange(value, profileDto);
    if (profileChanged) this.setState({ profile });
  };

  private generateTitle = () => {
    const { location, machineModelsSelection } = this.props.value;
    const profile = this.state.profile;
    const parts: string[] = [];
    if (location != null && !isNullOrEmpty(location.title)) parts.push(trim(location.title));

    if (!isNullOrEmpty(profile)) parts.push(trim(profile));

    parts.push('|');

    if ((machineModelsSelection || []).length !== 0) {
      machineModelsSelection
        .filter(mms => (mms.machineUnitSelection || []).length !== 0)
        .forEach(mms => {
          parts.push(mms.machineModel.equipmentTypeName);
          parts.push(mms.machineModel.name);
          const selected = mms.machineUnitSelection.filter(mus => mus.isSelected);
          if (selected.length !== mms.machineUnitSelection.length) {
            selected.forEach(mus => {
              parts.push(mus.machineUnit.name);
            });
          }
          parts.push('|');
        });
    }

    if (parts.length > 0 && parts[0] === '|') parts.shift();
    if (parts.length > 0 && parts[parts.length - 1] === '|') parts.pop();

    const nextTitle = parts
      .map(x => (x == null ? '' : trim(x)))
      .filter(x => !isNullOrEmpty(x))
      .join(' ');
    if (nextTitle !== this.props.value.title) {
      this.props.value.title = nextTitle;
      this.props.onChange(this.props.value);
    }
    return nextTitle;
  };

  render() {
    const { t, isOnEditMode, isGlobalPoc } = this.props;
    const { isStepActive } = this.state;
    const value = !!this.props.value ? this.props.value : { title: '', location: null, profileId: null, deadline: '' };
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !isGlobalPoc
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    return (
      <div className="wizard__step wizard__step-one tna-form-general-data">
        <StepsComponent active={isStepActive} number={'1'} title={t('GENERAL DATA')}>
          <div className="step-one__content tna-form-wizard__step-one__title-wrapper editor-38px">
            <label>{t('TNA Title')}</label>
            <Input value={value.title} disabled className="normal-looking-disabled-input" />
          </div>

          <div className="step-one__content tna-form-step-one__content">
            <div className={`editor-38px ${pocLocations.length === 1 ? 'normal-looking-disabled-input' : ''}`}>
              <label>{t('Select Location')}</label>
              <LocationEditor
                loadDataOnOpen={true}
                nullable={pocLocations.length !== 1}
                clearable={pocLocations.length !== 1}
                readOnly={pocLocations.length === 1 || isOnEditMode}
                placeholder={t('Location')}
                value={value.location}
                onChange={l => this.handleOnChange('location', l)}
                locationsReceived={pocLocations}
              />
            </div>
            <div className="editor-38px">
              <label>{t('Who is this TNA for?')}</label>
              <ProfileEditor
                readonly={isOnEditMode}
                assessment={'TNA'}
                nullable
                value={value.profileId}
                className="custom-editor"
                clearable
                placeholder={t('Profile')}
                onChange={profile => this.handleOnChange('profileId', profile)}
              />
            </div>
            <div className="tna-form-wizard__step-one__deadline-wrapper editor-38px">
              <label>{t('Set Deadline')}</label>
              <DateInput
                propagateOnInvalid
                value={value.deadline}
                onChange={(_, d) => this.handleOnChange('deadline', d)}
                iconPosition="right"
                minDate={isOnEditMode ? null : DateTimeService.toString(DateTimeService.today())}
              />
            </div>
          </div>
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(GeneralDataStep);
