import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  NewTnaFormStore,
  CreateEmployeeTnaFormDto,
  CreateFunctionalExpertDto,
  CreateMachineModelFormSelectionDto,
  CreateTnaFormDto,
  CreateTnaFormValidator,
  ChangeTnaFormWizardValidator,
  ChangeTnaFormWizardStore,
  ChangeTnaFormDto,
  TnaFormListDto,
  ChangeEmployeeTnaFormDto,
  TnaFormListStore,
  TnaFormItemDto,
  ChangeMachineModelFormSelectionDto,
  CreateMachineFunctionalExpertDto,
  TnaAssessmentType,
  CreateSkillWithLocalQuestionDto,
  CreateMachineUnitWithLocalQuestionDto
} from 'stores/assessments/forms/tna-forms-store';
import { connect } from 'redux-scaffolding-ts';
import TnaFormWizard, { TnaFormWizardViewModel } from './tna-form-wizard';
import { RouteComponentProps } from 'react-router';
import { EmployeesAssessorsViewModel, EmployeeAssessors } from './steps/employees-and-assessors-step';
import { Dimmer, Loader } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { getKeyValuePairs, clone } from 'utils/object';
import { UserProfilesStore } from 'stores/profile/user-profile-store';
import { IGroup } from 'utils/linq';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import { Message } from 'stores/types';
import { MachineModelSelectionViewModel, TnaTemplateStepViewModel } from './steps/select-machinery-step-types';
import { TnaTemplateData } from '../../templates/wizard/tna-templates-wizard-component';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';

export interface CreateTnaFormWizardProps extends WithTranslation, RouteComponentProps {
  newTnaFormStore?: NewTnaFormStore;
  changeTnaFormWizardStore?: ChangeTnaFormWizardStore;
  tnaFormListStore: TnaFormListStore;
  userProfilesStore: UserProfilesStore;
}
export interface CreateTnaFormWizardState {
  loading: boolean;
  mode: tnaWizardMode;
  itemToEdit: TnaFormItemDto;
}

export type tnaWizardMode = 'New' | 'Edit';

@connect(
  ['newTnaFormStore', NewTnaFormStore],
  ['changeTnaFormWizardStore', ChangeTnaFormWizardStore],
  ['tnaFormListStore', TnaFormListStore],
  ['userProfilesStore', UserProfilesStore]
)
class CreateTnaFormWizard extends Component<CreateTnaFormWizardProps, CreateTnaFormWizardState> {
  constructor(props: CreateTnaFormWizardProps) {
    super(props);
    const editMode = this.isOnEditMode();
    this.state = {
      loading: false,
      mode: editMode ? 'Edit' : 'New',
      itemToEdit: null
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    if (this.state.mode === 'Edit') this.load();
  }

  private load = async () => {
    const { history, tnaFormListStore } = this.props;
    const dataFormEditMode = this.props.history.location.state as TnaFormListDto;
    const formIdClickedFromTnaFormList = dataFormEditMode?.id;
    try {
      this.setState({ loading: true });
      const tnaFormData = await tnaFormListStore.getFormById(formIdClickedFromTnaFormList);
      this.setState({ loading: false, itemToEdit: tnaFormData });
    } catch (error) {
      history.replace('/not-found');
    }
  };

  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state !== undefined || match.params['id'] !== undefined) {
      return true;
    } else return false;
  };

  private mapToDto = (x: TnaFormWizardViewModel, inEditMode: boolean): CreateTnaFormDto => {
    const tnaFormWizardViewModel = clone<TnaFormWizardViewModel>(x);
    return {
      title: tnaFormWizardViewModel.generalStepViewModel.title,
      deadline: tnaFormWizardViewModel.generalStepViewModel.deadline,
      tnaTemplateId: tnaFormWizardViewModel.tnaTemplateStepViewModel.template
        ? tnaFormWizardViewModel.tnaTemplateStepViewModel.template.id
        : null,
      machineModelsSelection: this.mapToCreateMachineModelSelectionDto(
        tnaFormWizardViewModel.tnaTemplateStepViewModel,
        inEditMode,
        x.tnaTemplateStepViewModel?.type
      ),
      employees: this.mapToCreateEmployeeTnaFormDto(tnaFormWizardViewModel.employeesAssessorViewModel, x.tnaTemplateStepViewModel.type),
      skillCount:
        tnaFormWizardViewModel.tnaTemplateStepViewModel.skillCount - (x?.employeesAssessorViewModel?.ignoredNmrSkills || []).length,
      type: x.tnaTemplateStepViewModel?.type,
      nmrSkillsWithLocalQuestions:
        x.tnaTemplateStepViewModel?.type === 'SameMachineHierarchy'
          ? this.mapToNmrSkillsWithLocalQuestions(tnaFormWizardViewModel?.tnaTemplateStepViewModel?.templateData)
          : null,
      ignoredNmrSkills: x.employeesAssessorViewModel.ignoredNmrSkills
    };
  };

  private isValidViewModel = (tnaFormWizardViewModel: TnaFormWizardViewModel) => {
    const {
      tnaTemplateStepViewModel: { template },
      employeesAssessorViewModel: { employeesAssessors, ignoredNmrSkills }
    } = tnaFormWizardViewModel;

    const isEditMode = this.state.mode === 'Edit';
    let validation: ValidationResult;
    if (isEditMode) {
      validation = new ChangeTnaFormWizardValidator().extendValidate(this.mapToChangeDtoEditMode(tnaFormWizardViewModel, false));
    } else {
      validation = new CreateTnaFormValidator().extendValidate(this.mapToDto(tnaFormWizardViewModel, isEditMode));
    }
    const requiredNMRAssessorsLength = (template?.nonMachineRelatedSkillSections || []).length;

    const failures: ValidationFailure[] = [];
    if (requiredNMRAssessorsLength > 0)
      Object.keys(employeesAssessors || {}).forEach((empId, i) => {
        const currentEmployee = employeesAssessors[empId];
        const assignedAssessorToEmployee = Object.keys(currentEmployee?.skillsAssessor || {}).every(
          skill => currentEmployee.skillsAssessor[skill] != null || ignoredNmrSkills.any(id => id === skill)
        );
        if (!assignedAssessorToEmployee)
          failures.push({
            attemptedValue: null,
            code: null,
            message: this.props.t(`Employee at position ${i + 1}: Non-Machine Related Functional Experts info is required `),
            propertyName: 'nonMachineFunctionalExperts',
            severity: null,
            target: null
          });
      });

    failures.length > 0 && validation.addFailures(failures);

    return validation.isValid();
  };

  private handleOnSubmit = (tnaFormWizardViewModel: TnaFormWizardViewModel) => {
    const { history } = this.props;
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);

    this.setState({ loading: true });

    if (this.state.mode === 'New') {
      this.props.newTnaFormStore.createNew(this.mapToDto(tnaFormWizardViewModel, false));
      this.props.newTnaFormStore
        .submit()
        .then(r => {
          this.setState({ loading: false });
          if (r != null && r.isSuccess) {
            let tabForm = isPoc ? 1 : 3;
            history.push({ pathname: '/assessments/tna?tab=' + tabForm });
          } else {
            document.getElementById('root').scrollTop = 0;
          }
        })
        .catch(_ => {
          document.getElementById('root').scrollTop = 0;
          this.setState({ loading: false });
        });
    } else if (this.state.mode === 'Edit') {
      this.props.changeTnaFormWizardStore.change(this.mapToChangeDtoEditMode(tnaFormWizardViewModel, true));
      this.props.changeTnaFormWizardStore
        .submit(true)
        .then(r => {
          this.setState({ loading: false });
          if (r != null && r.isSuccess) {
            let tabForm = isPoc ? 1 : 3;
            history.push({ pathname: '/assessments/tna?tab=' + tabForm });
          } else {
            document.getElementById('root').scrollTop = 0;
          }
        })
        .catch(_ => {
          document.getElementById('root').scrollTop = 0;
          this.setState({ loading: false });
        });
    }
  };

  //EDIT MODE MAPS
  private mapToChangeDtoEditMode = (tnaFormWizardViewModel: TnaFormWizardViewModel, onSubmit: boolean): ChangeTnaFormDto => {
    const { itemToEdit } = this.state;

    const machineModelDto: ChangeMachineModelFormSelectionDto[] = this.mapToChangeMachineModelSelectionDto(
      tnaFormWizardViewModel.tnaTemplateStepViewModel.machineModelsSelection,
      true,
      onSubmit
    ) as ChangeMachineModelFormSelectionDto[];

    const assessorDtos: ChangeEmployeeTnaFormDto = this.mapToChangeEmployeeDto(
      tnaFormWizardViewModel.employeesAssessorViewModel,
      tnaFormWizardViewModel.tnaTemplateStepViewModel.type,
      tnaFormWizardViewModel.generalStepViewModel.machineModelsSelection
    );

    return {
      id: itemToEdit.id,
      machineModelsSelection: machineModelDto,
      employee: assessorDtos,
      deadline: tnaFormWizardViewModel.generalStepViewModel.deadline,
      skillCount: tnaFormWizardViewModel.tnaTemplateStepViewModel.skillCount,
      type: tnaFormWizardViewModel.tnaTemplateStepViewModel.type
    };
  };

  private mapToChangeEmployeeDto = (
    viewModel: EmployeesAssessorsViewModel,
    type: TnaAssessmentType,
    machineModelSelection: MachineModelSelectionViewModel[]
  ): ChangeEmployeeTnaFormDto => {
    const { userId } = this.state.itemToEdit;
    const machineModelSelectionIds = machineModelSelection.map(x => x.machineModel.id);

    const kvp = getKeyValuePairs<string>(viewModel.employeesAssessors[userId].skillsAssessor);
    const nonMachineFunctionalExperts = kvp
      .groupBy(x => x.value)
      .toArray<IGroup<string, { key: string; value: string }>>()
      .map(x => ({ userId: x.key, skills: x.items.map(y => y.key) }));

    const kvp2 = getKeyValuePairs<string>(viewModel.employeesAssessors[userId].machineFunctionalExperts || {});
    const machineFunctionalExperts =
      type === 'MultipleMachineHierarchy'
        ? kvp2
            .groupBy(x => x.value)
            .toArray<IGroup<string, { key: string; value: string }>>()
            .map(x => ({
              userId: x.key,
              machineModelIds: x.items
                .filter(q => machineModelSelectionIds.length <= 0 || machineModelSelectionIds.includes(q.key))
                .map(y => y.key)
            }))
        : undefined;

    (machineFunctionalExperts || []).forEach(function(item, index, object) {
      if (item.machineModelIds.length <= 0) {
        object.splice(index, 1);
      }
    });

    return {
      userId: userId,
      bypassEmployeeEvaluation: viewModel.employeesAssessors[userId].bypassEmployeeEvaluation,
      lineManagerId: viewModel.employeesAssessors[userId].lineManagerId,
      bypassLineManagerEvaluation: viewModel.employeesAssessors[userId].bypassLineManagerEvaluation,
      machineFunctionalExpertId:
        type === 'SameMachineHierarchy' ? viewModel.employeesAssessors[userId].machineFunctionalExpertId : undefined,
      nonMachineFunctionalExperts,
      machineFunctionalExperts
    };
  };

  //CREATE MODE MAPS
  private mapToChangeMachineModelSelectionDto = (
    machineModelsSelection: MachineModelSelectionViewModel[],
    inEditMode: boolean,
    onSubmit: boolean = false
  ): ChangeMachineModelFormSelectionDto[] => {
    const machineModelsSelected = machineModelsSelection.filter(x => x.hasMachineUnitSelected);
    if (!inEditMode || (inEditMode && onSubmit)) {
      machineModelsSelected.forEach(x => (x.machineUnitSelection = x.machineUnitSelection.filter(mu => mu.isSelected)));
    }
    return machineModelsSelected.map(x => ({
      machineModelId: x.machineModel.id,
      selectedMachineUnits: x.machineUnitSelection.map(mu => mu.machineUnit.id)
    }));
  };

  private mapToCreateMachineModelSelectionDto = (
    templateViewModel: TnaTemplateStepViewModel,
    inEditMode: boolean,
    formType: TnaAssessmentType,
    onSubmit: boolean = false
  ): CreateMachineModelFormSelectionDto[] => {
    const machineModelsSelected = templateViewModel.machineModelsSelection.filter(x => x.hasMachineUnitSelected);
    if (!inEditMode || (inEditMode && onSubmit)) {
      machineModelsSelected.forEach(x => (x.machineUnitSelection = x.machineUnitSelection.filter(mu => mu.isSelected)));
    }
    return machineModelsSelected.map(x => ({
      machineModelId: x.machineModel.id,
      selectedMachineUnits: x.machineUnitSelection.map(mu => mu.machineUnit.id),
      machineUnitsWithLocalQuestions: this.mapTomachineUnitsWithLocalQuestions(
        formType === 'MultipleMachineHierarchy'
          ? templateViewModel.multipleMachineModelsSelection.firstOrDefault(mvm => mvm?.machineModel?.id === x.machineModel?.id)
              ?.templateData
          : templateViewModel.templateData
      ),
      nmrSkillsWithLocalQuestions:
        formType === 'MultipleMachineHierarchy'
          ? this.mapToNmrSkillsWithLocalQuestions(
              templateViewModel.multipleMachineModelsSelection.firstOrDefault(mvm => mvm.machineModel.id === x.machineModel.id)
                ?.templateData
            )
          : null,
      tnaTemplateId:
        formType === 'MultipleMachineHierarchy'
          ? templateViewModel.multipleMachineModelsSelection.firstOrDefault(mvm => mvm?.machineModel?.id === x.machineModel?.id)
              ?.templateData?.id
          : null
    }));
  };

  private mapTomachineUnitsWithLocalQuestions = (templateData: TnaTemplateData): CreateMachineUnitWithLocalQuestionDto[] => {
    return (templateData?.machineRelatedSections || []).map(mrs => ({
      machineUnitId: mrs.machineUnitId,
      skillsWithLocalQuestions: this.mapToSkillWithLocalQuestions(mrs.machineUnitId, templateData)
    }));
  };

  private mapToSkillWithLocalQuestions = (machineUnitId: string, templateData: TnaTemplateData): CreateSkillWithLocalQuestionDto[] => {
    const mrSection = templateData?.machineRelatedSections.firstOrDefault(x => x.machineUnitId === machineUnitId);

    return (mrSection?.skillSections || []).map(skillSection => ({
      skillId: skillSection.skillId,
      localQuestionIds: (skillSection.questions.filter(q => q.isAddedInCustom) || []).map(x => x.id)
    }));
  };

  private mapToNmrSkillsWithLocalQuestions = (templateData: TnaTemplateData): CreateSkillWithLocalQuestionDto[] => {
    return (templateData?.nonMachineRelatedSkillSections || []).map(skillSection => ({
      skillId: skillSection.skillId,
      localQuestionIds: (skillSection.questions.filter(q => q.isAddedInCustom) || []).map(x => x.id)
    }));
  };

  private mapToCreateEmployeeTnaFormDto = (
    employeesAssessorViewModel: EmployeesAssessorsViewModel,
    type: TnaAssessmentType
  ): CreateEmployeeTnaFormDto[] => {
    const employeesAssessors: CreateEmployeeTnaFormDto[] = [];

    const kvps = getKeyValuePairs<EmployeeAssessors>(employeesAssessorViewModel.employeesAssessors).filter(
      ({ key, value }) => !isNullOrWhiteSpaces(key) && value != null
    );

    kvps.forEach(({ key, value }) => {
      employeesAssessors.push({
        userId: key,
        bypassEmployeeEvaluation: value.bypassEmployeeEvaluation,
        bypassLineManagerEvaluation: value.bypassLineManagerEvaluation,
        lineManagerId: value.lineManagerId,
        machineFunctionalExpertId: type === 'SameMachineHierarchy' ? value.machineFunctionalExpertId : undefined,
        nonMachineFunctionalExperts: this.mapToCreateFunctionalExpertDto(value.skillsAssessor, employeesAssessorViewModel.ignoredNmrSkills),
        machineFunctionalExperts:
          type === 'MultipleMachineHierarchy' ? this.mapToCreateMachineFunctionalExpertDto(value.machineFunctionalExperts || {}) : undefined
      });
    });

    return employeesAssessors;
  };

  private mapToCreateMachineFunctionalExpertDto = (experts: { [machineModelId: string]: string }): CreateMachineFunctionalExpertDto[] => {
    const kvp2 = getKeyValuePairs<string>(experts);
    return kvp2
      .groupBy(x => x.value)
      .toArray<IGroup<string, { key: string; value: string }>>()
      .map(x => ({ userId: x.key, machineModelIds: x.items.map(y => y.key) }));
  };

  private mapToCreateFunctionalExpertDto = (
    skills: { [skillId: string]: string },
    ignoredNmrSkills: string[]
  ): CreateFunctionalExpertDto[] => {
    const assessorsSkills: { [assessorId: string]: string[] } = {};
    const kvps = getKeyValuePairs<string>(skills).filter(
      ({ key, value }) => !isNullOrWhiteSpaces(key) && !isNullOrWhiteSpaces(value) && !ignoredNmrSkills.any(id => id === key)
    );

    kvps.forEach(({ key, value }) => {
      let assessorSkills = assessorsSkills[value] || [];
      assessorSkills.push(key);
      assessorsSkills[value] = assessorSkills;
    });

    const createFunctionExpertsDto: CreateFunctionalExpertDto[] = [];
    getKeyValuePairs<string[]>(assessorsSkills).forEach(({ key, value }) => {
      createFunctionExpertsDto.push({
        userId: key,
        skills: value
      });
    });

    return createFunctionExpertsDto;
  };

  toReturnMessages = () => {
    let message: Message[];
    if (this.state.mode === 'Edit') {
      message =
        this.props.changeTnaFormWizardStore.state.result && !this.props.changeTnaFormWizardStore.state.result.isSuccess
          ? (this.props.changeTnaFormWizardStore.state.result.messages || []).filter(x => !isNullOrWhiteSpaces(x.body))
          : [];
    } else if (this.state.mode === 'New') {
      message =
        this.props.newTnaFormStore.state.result && !this.props.newTnaFormStore.state.result.isSuccess
          ? (this.props.newTnaFormStore.state.result.messages || []).filter(x => !isNullOrWhiteSpaces(x.body))
          : [];
    }
    return message;
  };

  render() {
    const { loading, mode, itemToEdit } = this.state;
    const dataFormEditMode = this.props.history.location.state as TnaFormListDto;
    const messages = this.toReturnMessages();
    const { tnaFormListStore, changeTnaFormWizardStore, newTnaFormStore, userProfilesStore } = this.props;

    if (!this.isOnEditMode() || (this.isOnEditMode() && this.state.itemToEdit)) {
      return (
        <>
          <TnaFormWizard
            dataFormEditMode={dataFormEditMode}
            itemToEdit={itemToEdit}
            tnaWizardMode={mode}
            onSubmit={this.handleOnSubmit}
            messages={messages}
            isValidViewModel={this.isValidViewModel}
            {...this.props}
          />

          <Dimmer
            active={
              newTnaFormStore.state.isBusy ||
              changeTnaFormWizardStore.state.isBusy ||
              tnaFormListStore.state.isBusy ||
              userProfilesStore.state.isBusy ||
              loading
            }
            style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}
          >
            <Loader indeterminate>{changeTnaFormWizardStore.state.isBusy ? 'Saving TNA form' : 'Loading TNA form'}</Loader>
          </Dimmer>
        </>
      );
    } else return <></>;
  }
}

export default withTranslation()(CreateTnaFormWizard);
