import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { PersonnelAreaDto, PersonnelAreasStore } from '../../stores/configuration/locations/personnelArea-store';
import { nameof } from '../../utils/object';
import { ItemReference, QueryResult } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { SelectionInput, DropdownProps } from 'widgets/form/selectionInput';

export interface PersonnelAreaItemReference extends ItemReference {
  locationId?: string;
  locationName?: string;
}

interface PersonnelAreaEditorProps extends DropdownProps, WithTranslation {
  value: PersonnelAreaItemReference;
  onChange?: (value: PersonnelAreaItemReference) => void;
  personnelAreas?: PersonnelAreasStore;
  clearable?: boolean;
}

interface PersonnelAreaEditorState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['personnelAreas', PersonnelAreasStore])
class PersonnelAreaEditor extends React.Component<PersonnelAreaEditorProps, PersonnelAreaEditorState> {
  private get personnelAreaStore() {
    return this.props.personnelAreas;
  }

  constructor(props: PersonnelAreaEditorProps) {
    super(props);

    this.state = {
      query: this.getPersonnelAreas()
    };
  }

  @autobind
  private getPersonnelAreas() {
    const method = async (search: string) => {
      const result = await this.personnelAreaStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<PersonnelAreaDto>('name'), useProfile: false }],
        filter: isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<PersonnelAreaDto>('name')}), '${search.toLowerCase()}')`]
      });

      return Object.assign({}, result, {
        items: result.items.map(c => ({ id: c.id, title: c.name, locationId: c.locationId, locationName: c.locationName }))
      }) as QueryResult<PersonnelAreaItemReference>;
    };
    return method;
  }

  public render() {
    const query = this.state.query;
    const selectedValue = this.props.value ? this.props.value : null;
    return (
      <SelectionInput
        content={item => <>{item.title}</>}
        searchable
        clearable={this.props.clearable}
        className={this.props.className}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={selectedValue}
        onChange={value => this.props.onChange(value as any)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(PersonnelAreaEditor);
