import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TextArea, Input, Label, Dropdown } from 'semantic-ui-react';
import { PracticalQuestionView, ResultPracticalQuestion } from 'stores/assessments/forms/practical-form-store';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import { isNullOrEmpty } from 'utils/useful-functions';

interface PracticalQuestionProps extends WithTranslation, PracticalQuestionView {
  onChange: (value: any) => void;
  readonly: boolean;
}

interface PracticalQuestionState {
  resultManuallyChanged: boolean;
  resultManuallyValue: any;
}

class PracticalQuestion extends Component<PracticalQuestionProps, PracticalQuestionState> {
  state: PracticalQuestionState = { resultManuallyChanged: false, resultManuallyValue: null };

  private toReturnResult = (targetLevel, timeSpent, result = null) => {
    const resultManuallyChanged = this.state.resultManuallyChanged;
    if (resultManuallyChanged) {
      return this.state.resultManuallyValue;
    } else if (!isNullOrEmpty(result)) {
      return result;
    } else if (!resultManuallyChanged && timeSpent > targetLevel) {
      return 'Failed';
    } else return 'Passed';
  };
  private handleTimeChange(targetLevel, timeSpent) {
    const value = this.toReturnResult(targetLevel, timeSpent);
    this.setState({ resultManuallyChanged: false });
    let obj = {
      timeSpent: timeSpent,
      result: value
    };
    this.props.onChange(obj);
    return value;
  }

  render() {
    const options: { value: ResultPracticalQuestion; text: string }[] = [
      { value: 'Failed', text: 'Failed' },
      { value: 'Passed', text: 'Passed' }
    ];
    const { result, observations, targetLevel, timeSpent, readonly, onChange, t } = this.props;
    const newCalculatedResult = this.toReturnResult(targetLevel, timeSpent, result);

    return (
      <>
        <div className="practical-question__wrapper">
          <div className="practical-question__info">
            <FormRow className="practical-question__info-item" label={t('Time Spent')}>
              <Input
                type="number"
                className="minutes-input no-arrows"
                label="minutes"
                labelPosition="right"
                value={timeSpent ?? ''}
                disabled={readonly}
                onChange={(_, { value }) => {
                  this.handleTimeChange(targetLevel, value);
                }}
              />
            </FormRow>
            <FormRow className="practical-question__info-item" label={t('Target Level')}>
              <Label>
                {targetLevel} {t('minutes')}
              </Label>
            </FormRow>
            <FormRow className="practical-question__info-item" label={t('Result')}>
              <Dropdown
                disabled={readonly}
                className="planit-users-inputs planit-user-dropdown planit-user-selector"
                search
                inline
                selection
                closeOnChange
                closeOnEscape
                clearable
                closeOnBlur
                selectOnBlur={false}
                selectOnNavigation={false}
                options={options}
                value={newCalculatedResult}
                placeholder={t('Result')}
                onChange={(_, { value }) => {
                  onChange({ result: value });
                  this.setState({ resultManuallyChanged: true, resultManuallyValue: value });
                }}
              />
            </FormRow>
          </div>
          <div className="practical-question__observations">
            <div className="label">{t('Observations')}</div>
            <TextArea
              disabled={readonly}
              className="scroll"
              value={observations}
              onChange={(_, { value }) => onChange({ observations: value })}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PracticalQuestion);
