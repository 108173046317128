import React, { Component } from 'react';
import { Button, Comment, Form, Header, Icon, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RequestChatStore } from 'stores/requests/requests-chat-stores';
import { connect } from 'redux-scaffolding-ts';
import { IdentityService, IUserInfo } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { DateTimeService } from 'services/datetime-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface ChatFormViewProps extends WithTranslation {
  requestId: string;
  chat?: RequestChatStore;
  readonly?: boolean;
}

export interface chatFormState {
  messageToSend: string;
  currentUserInfo: IUserInfo;
  loading: boolean;
  sending: boolean;
}

@connect(['chat', RequestChatStore])
class ChatForm extends Component<ChatFormViewProps, chatFormState> {
  constructor(props) {
    super(props);
    this.state = {
      messageToSend: '',
      currentUserInfo: this.identityService.getUserInfo(),
      loading: true,
      sending: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.props.chat.clearChatMessages();
    this.props.chat.loadChat(this.props.requestId, !this.props.readonly);
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.props.chat.clearChatMessages();
  }

  sendMessage = () => {
    const { t, requestId } = this.props;
    if (this.state.sending || isNullOrWhiteSpaces(this.state.messageToSend)) return;

    const messageDto = {
      requestId,
      timestamp: DateTimeService.toString(DateTimeService.now()),
      message: this.state.messageToSend
    };
    this.setState({ messageToSend: t('Sending...'), sending: true });

    this.props.chat.addMessage(messageDto).then(response => {
      this.setState({ messageToSend: '', sending: false });
      let requestChatMessagesList = document.getElementById('chatRequestScroll');
      requestChatMessagesList.scrollIntoView({ behavior: 'smooth' });
      requestChatMessagesList.scrollTop = requestChatMessagesList.scrollHeight;
    });
  };

  keypress = e => {
    if (e.key === 'Enter') {
      this.sendMessage();
    }
  };

  public render() {
    return (
      <>
        <div className="form__modal__right-container__chat form__request-chat-container">
          <Dimmer.Dimmable as={Segment} dimmed>
            <Dimmer active={this.state.loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <Loader indeterminate>{this.props.t('Loading...')}</Loader>
            </Dimmer>
            <div id="chatRequestScroll" className="chat-div scroll">
              <Comment.Group>
                <Header as="h3" dividing>
                  <Button className="chat-div-request__users-icon-btn" icon>
                    <Icon.Group>
                      <FontAwesomeIcon className="solid chat-div__users-icon" icon={faUserFriends} />
                    </Icon.Group>
                  </Button>
                </Header>
                <li>
                  {this.props.chat.state.messages.map(m => (
                    <>
                      {m.fromUsername === this.state.currentUserInfo.userName && m.fromUserId !== '00000000-0000-0000-0000-000000000000' && (
                        <ul className="comment-own-color">
                          <Comment>
                            <Comment.Content key={m.id}>
                              <Comment.Text></Comment.Text>
                              <Comment.Author>{m.message}</Comment.Author>
                              <Comment.Metadata className="comment-metadata">
                                {m.fromUsername}, {m.fromLastName} {m.fromFirstName}, {DateTimeService.toChatString(m.timestamp)}
                              </Comment.Metadata>
                            </Comment.Content>
                          </Comment>
                        </ul>
                      )}

                      {m.fromUsername !== this.state.currentUserInfo.userName && m.fromUserId !== '00000000-0000-0000-0000-000000000000' && (
                        <ul className="comment-basic-color">
                          <Comment>
                            <Comment.Content key={m.id}>
                              <Comment.Text></Comment.Text>
                              <Comment.Author>{m.message}</Comment.Author>
                              <Comment.Metadata className="comment-metadata">
                                {m.fromUsername}, {m.fromLastName} {m.fromFirstName}, {DateTimeService.toChatString(m.timestamp)}
                              </Comment.Metadata>
                            </Comment.Content>
                          </Comment>
                        </ul>
                      )}

                      {m.fromUserId === '00000000-0000-0000-0000-000000000000' && (
                        <ul className="comment-system-color">
                          <Comment>
                            <Comment.Content key={m.id}>
                              <Comment.Text></Comment.Text>
                              <Comment.Author>{m.message}</Comment.Author>
                              <Comment.Metadata className="comment-metadata">{DateTimeService.toChatString(m.timestamp)}</Comment.Metadata>
                            </Comment.Content>
                          </Comment>
                        </ul>
                      )}
                    </>
                  ))}
                </li>
              </Comment.Group>
            </div>
          </Dimmer.Dimmable>
          <div>
            <Form className="events-chat__write-section" reply>
              <Form.Field>
                <div className="chat-input-div">
                  {!this.props.readonly && (
                    <textarea
                      onKeyPress={this.keypress}
                      value={this.state.messageToSend}
                      wrap="hard"
                      // rows={4}
                      className="chat__textarea-message"
                      onChange={value => this.setState({ messageToSend: value.target.value })}
                    />
                  )}

                  <div className="chat-button-div">
                    {!this.props.readonly && (
                      <Button
                        active={!this.state.sending}
                        className="chat-button inverted-color-btn"
                        icon="send"
                        onClick={this.sendMessage}
                      />
                    )}
                  </div>
                </div>
              </Form.Field>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ChatForm);
