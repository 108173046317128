import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Icon, Message, Checkbox, Grid } from 'semantic-ui-react';

import { LanguagesStore, LanguageDto, ChangeLanguageStore } from 'stores/configuration/locations/languages-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewLanguageView from './new-language';
import ChangeLanguageView from './edit-language';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

export interface LanguagesListProps extends WithTranslation, RouteComponentProps {
  languages: LanguagesStore;
  changeLanguageStore?: ChangeLanguageStore;
}

export interface LanguagesListState {
  query: Query;
  newLanguageShown: boolean;
  changeLanguageShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['languages', LanguagesStore], ['changeLanguageStore', ChangeLanguageStore])
class LanguagesListPage extends React.Component<LanguagesListProps, LanguagesListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newLanguageShown: false,
      changeLanguageShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.languages.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: LanguageDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.languages.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: LanguageDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.languages.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newLanguageShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newLanguageShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeLanguageShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeLanguageShown: false });
    if (isSuccess) this.load();
  }

  handleToggleAvailability = async (item: LanguageDto) => {
    if (!item) return;
    const { id, language, languageCode, hasTnaAvailable } = item;
    this.props.changeLanguageStore.change({ id, language, languageCode, hasTnaAvailable: !hasTnaAvailable });
    await this.props.changeLanguageStore.update();
    this.load();
  };

  onDismiss = () => {
    const { languages, changeLanguageStore } = this.props;
    languages.clearMessages();
    changeLanguageStore.clearMessages();
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, languages, changeLanguageStore } = this.props;
    const { activeFilters, someFilterOpened } = this.state;
    let errMsgs = (languages.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeLanguageStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Language'),
          tooltipRenderer: false,
          renderer: data => data.language,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by language')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<LanguageDto>('language'), `contains(${nameof<LanguageDto>('language')}, '${value}')`)}
              onClear={() => onClear(nameof<LanguageDto>('language'))}
              active={activeFilters.includes(nameof<LanguageDto>('language'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<LanguageDto>('language'),
            useProfile: false
          }
        },
        {
          title: t('Code'),
          tooltipRenderer: true,
          renderer: data => data.languageCode,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by code')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<LanguageDto>('languageCode'), `contains(${nameof<LanguageDto>('languageCode')}, '${value}')`)
              }
              onClear={() => onClear(nameof<LanguageDto>('languageCode'))}
              active={activeFilters.includes(nameof<LanguageDto>('languageCode'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<LanguageDto>('languageCode'),
            useProfile: false
          }
        },
        {
          title: t('Available for tests'),
          renderer: data => <Checkbox toggle checked={data.hasTnaAvailable} onChange={() => this.handleToggleAvailability(data)} />,
          editor: (data, onChange) => (
            <Checkbox
              toggle
              checked={data.hasTnaAvailable}
              onChange={(e, { checked }) => {
                data.hasTnaAvailable = !!checked;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by tests Availability')}
              trueLabel={t('Available for tests')}
              falseLabel={t('Not Available for tests')}
              triggerTitle={title}
              onFilter={(value: boolean) => onFilter(nameof<LanguageDto>('hasTnaAvailable'), { hasTnaAvailable: value })}
              onClear={() => onClear(nameof<LanguageDto>('hasTnaAvailable'))}
              active={activeFilters.includes(nameof<LanguageDto>('hasTnaAvailable'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<LanguageDto>('hasTnaAvailable'),
            useProfile: false
          }
        }
      ],
      data: languages.state
    } as TableModel<LanguageDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newLanguageShown && !this.state.changeLanguageShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              isRowDisableLayout={(item: LanguageDto) => !item.hasTnaAvailable}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Language')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newLanguageShown && <NewLanguageView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeLanguageShown && <ChangeLanguageView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(LanguagesListPage);
