import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dropdown, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/tnaSkill.less';
import { NewTnaSkillStore, CreateTnaSkillDto, TnaSkillDto } from 'stores/assessments/skills/tna-skills-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface NewTnaSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newTnaSkill?: NewTnaSkillStore;
  isClone?: boolean;
  cloneData: TnaSkillDto;
}

interface NewTnaSkillViewState {
  category: ItemReference;
  userClickedSave: boolean;
}

@connect(['newTnaSkill', NewTnaSkillStore])
class NewTnaSkillView extends React.Component<NewTnaSkillViewProps, NewTnaSkillViewState> {
  private get newTnaSkillStore() {
    return this.props.newTnaSkill;
  }

  constructor(props: NewTnaSkillViewProps) {
    super(props);
    this.newTnaSkillStore.createNew({
      testCategoryId: this.props.isClone && this.props.cloneData ? this.props.cloneData.testCategoryId : '',
      description: this.props.isClone && this.props.cloneData ? this.props.cloneData.description : '',
      name: this.props.isClone && this.props.cloneData ? this.props.cloneData.name : '',
      isMachineRelated: this.props.isClone && this.props.cloneData ? this.props.cloneData.isMachineRelated : null
    });
    this.state = {
      category:
        this.props.isClone && this.props.cloneData
          ? Object.assign({ id: this.props.cloneData.testCategoryId, title: this.props.cloneData.testCategory.name })
          : null,
      userClickedSave: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewTnaSkillViewProps) {
    if (this.newTnaSkillStore.state.result && this.newTnaSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newTnaSkillStore.change({ ...this.newTnaSkillStore.state.item, ...change });
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newTnaSkillStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newTnaSkillStore.clear();
    this.props.onClose(false);
  }

  public render() {
    const { t } = this.props as any;
    const isMaChineRelatedOptions = [
      { key: 1, text: t('Yes'), value: true },
      { key: 2, text: t('No'), value: false }
    ];
    const modalTitle = this.props.isClone ? 'Clone TNA Skill' : 'New TNA Skill';
    return (
      <Modal className="tna-skill__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newTnaSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-skill__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newTnaSkillStore.state.result && !this.newTnaSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newTnaSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newTnaSkillStore.state.item && (
              <Form>
                <div className="tna-skill__wrapper">
                  <Form.Group className="tna-skill__selctor-group">
                    <Form.Field
                      required
                      className="tna-skill__label-input"
                      error={
                        (this.newTnaSkillStore.state.item.testCategoryId == null ||
                          isNullOrWhiteSpaces(this.newTnaSkillStore.state.item.testCategoryId)) &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Category')}</label>
                      <TestCategorySelector
                        nullable
                        isTnaCategory={true}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Category')}
                        value={this.state.category}
                        onChange={category => this.handleValue(nameof<CreateTnaSkillDto>('testCategoryId'), category?.id)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      className="tna-skill__label-input"
                      error={this.state.userClickedSave && this.newTnaSkillStore.state.item.isMachineRelated == null}
                    >
                      <label>{t('Machine Related')}</label>
                      <Dropdown
                        selection
                        value={this.newTnaSkillStore.state.item.isMachineRelated}
                        multiple={false}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Machine Related')}
                        options={isMaChineRelatedOptions}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateTnaSkillDto>('isMachineRelated'), value)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="tna-skill__label-textarea"
                      error={this.state.userClickedSave && this.newTnaSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        className="tna-skill__custom-editorequest__textarea"
                        value={this.newTnaSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateTnaSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="tna-skill__label-textarea"
                      error={this.state.userClickedSave && this.newTnaSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        className="tna-skill__custom-editorequest__textarea longArea"
                        value={this.newTnaSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateTnaSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-skill__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewTnaSkillView);
