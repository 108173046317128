import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { RegionDto, RegionsStore } from '../../../stores/configuration/locations/regions-store';
import { nameof } from '../../../utils/object';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface RegionEditorProps extends WithTranslation {
  value: RegionDto[];
  onChange?: (value: RegionDto[]) => void;
  regions?: RegionsStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  regionsReceived?: string[];
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface RegionEditorState {
  options: MultipleSelectionDropdownOptions<RegionDto>[];
}

@connect(['regions', RegionsStore])
class MultipleRegionEditor extends React.Component<RegionEditorProps, RegionEditorState> {
  private get regionStore() {
    return this.props.regions;
  }

  state: RegionEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions = (regions: QueryResult<RegionDto>): MultipleSelectionDropdownOptions<RegionDto>[] =>
    (regions?.items || []).map(region => ({ id: region.id, value: region, text: region.name }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      let filter;
      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<RegionDto>('name'), useProfile: false }];

      if (this.props.regionsReceived && this.props.regionsReceived.length > 0)
        filter = [{ id: { in: { type: 'guid', value: this.props.regionsReceived } } }];

      this.regionStore
        .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter })
        .then(regions => this.setState({ options: this.mapToOptions(regions) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleRegionEditor);
