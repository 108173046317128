import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDto, UserStore } from 'stores/users/users-store';
import { Grid, Button, Dropdown, Icon, Checkbox, Popup } from 'semantic-ui-react';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import ChooseAssessorsView from 'widgets/form/choose-assessors-form';
import { getProperties, getKeyValuePairs, nameof } from 'utils/object';
import { isNullOrWhiteSpaces, setEquals } from 'utils/useful-functions';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import './employees-and-assessors-step.less';
import { TnaFormListDto, TnaFormItemDto, TnaAssessmentType, AssessorAnswerStatus } from 'stores/assessments/forms/tna-forms-store';
import { connect } from 'redux-scaffolding-ts';
import { UserProfilesStore, UserProfilesDto } from 'stores/profile/user-profile-store';
import { Query } from 'stores/dataStore';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

export interface EmployeeAssessors {
  lineManagerId: string;
  bypassEmployeeEvaluation: boolean;
  bypassLineManagerEvaluation: boolean;
  machineFunctionalExpertId: string;
  machineFunctionalExperts: { [machineModelId: string]: string };
  skillsAssessor: { [skillId: string]: string };
}

export interface EmployeesAssessorsViewModel {
  employees: string[];
  assessors: string[];
  employeesAssessors: { [employeeId: string]: EmployeeAssessors };
  ignoredNmrSkills: string[];
}

export interface EmployeesAndAssessorsStepProps extends WithTranslation {
  isStepActive: boolean;
  locationId: string;
  profileId: string;
  machineModelIds: string[];
  nonMachineRelatedSkills: { [skillId: string]: string };
  onStepChanged?: () => void;
  onChange?: (info: EmployeesAssessorsViewModel) => void;
  dataFormEditMode: TnaFormListDto;
  userProfilesStore?: UserProfilesStore;
  userStore?: UserStore;
  itemToEdit: TnaFormItemDto;
  isOnEditMode: boolean;
  value: EmployeesAssessorsViewModel;
  formType: TnaAssessmentType;
  machineModelsDict: { [machineModelId: string]: string };
  isMachineRelatedProfile: boolean;
  isGlobalPoc: boolean;
}

export interface EmployeesAndAssessorsStepState {
  employees: { [employeeId: string]: UserProfilesDto };
  assessors: { [employeeId: string]: any };
  employeesAssessors: { [employeeId: string]: EmployeeAssessors };
  showEmployeeModal: boolean;
  locationId: string;
  profileId: string;
  machineModelIds: string[];
  showAssessorModal: boolean;
  selectedAssessor: string;
  isStepActive: boolean;
  assessorFromEditMode: UserProfilesDto[];
  ignoredNmrSkills: string[];
}

@connect(['userProfilesStore', UserProfilesStore], ['userStore', UserStore])
class EmployeesAndAssessorsStep extends React.Component<EmployeesAndAssessorsStepProps, EmployeesAndAssessorsStepState> {
  constructor(props: EmployeesAndAssessorsStepProps) {
    super(props);
    this.state = {
      employees: {},
      assessors: {},
      employeesAssessors: this.props.value.employeesAssessors,
      showEmployeeModal: false,
      locationId: this.props.locationId,
      profileId: this.props.profileId,
      machineModelIds: this.props.machineModelIds,
      showAssessorModal: false,
      selectedAssessor: undefined,
      isStepActive: !!this.props.isStepActive,
      assessorFromEditMode: [],
      ignoredNmrSkills: []
    };
  }

  componentDidMount() {
    if (this.props.isOnEditMode) {
      const employeeIdEditMode = this.props.dataFormEditMode?.userId;
      this.props.userProfilesStore
        .getUserProfileById(employeeIdEditMode)
        .then(res => {
          this.onEmployeesSelected([res]);
        })
        .catch(error => console.error(error));

      this.toGetAssessorEditListInfo();
    }
  }

  private toGetAssessorEditListInfo = async () => {
    const assessorsIds = (this.props.value.assessors || []).filter(e => e !== undefined);
    const oDataFilter = [`id in (${assessorsIds.join(', ')})`];
    const query: Query = { searchQuery: '', orderBy: [], filter: oDataFilter, skip: 0, take: 1000 };
    const usersResponse = await this.props.userStore.getAllAsync(query);
    this.onAssessorsFromEditMode(usersResponse.items);
  };

  private kvpComparer = (kvp1: { key: any; value: any }, kvp2: { key: any; value: any }): boolean => {
    return kvp1.key === kvp2.key && kvp1.value === kvp2.value;
  };

  private stringComparer = (a: string, b: string): boolean => {
    return a === b;
  };

  UNSAFE_componentWillReceiveProps(next: EmployeesAndAssessorsStepProps) {
    if (next != null && next !== this.props) {
      const currLocationId = this.state.locationId;
      const currMachineModelIds = this.state.machineModelIds;
      const currProfileId = this.state.profileId;
      const currEmployeesAssessors = this.state.employeesAssessors;
      const nextState = {} as EmployeesAndAssessorsStepState;

      let updateState = false;

      let nextSkills = {};
      if (this.props.isOnEditMode) {
        nextSkills = this.props.nonMachineRelatedSkills;
      } else {
        nextSkills = next.nonMachineRelatedSkills || {};
      }

      const nextProfileId = next.profileId;
      const nextLocationId = next.locationId;
      const machineModelIds = next.machineModelIds || [];
      const nextMachineModels = next.machineModelsDict || {};

      if (next.isStepActive !== this.props.isStepActive) {
        updateState = true;
        nextState.isStepActive = !!next.isStepActive;
      }

      let resetEmployees = false;

      if (!setEquals(machineModelIds, currMachineModelIds, this.stringComparer)) {
        if (!next.isOnEditMode) resetEmployees = true;
        nextState.machineModelIds = machineModelIds;
      }
      if (!this.stringComparer(nextProfileId, currProfileId)) {
        resetEmployees = true;
        nextState.profileId = nextProfileId;
      }
      if (!this.stringComparer(nextLocationId, currLocationId)) {
        resetEmployees = true;
        nextState.locationId = nextLocationId;
      }

      if (resetEmployees) {
        nextState.employees = {};
        nextState.assessors = {};
        nextState.employeesAssessors = {};
        nextState.selectedAssessor = null;
        updateState = true;
      } else {
        const nextSkillsProps = getProperties(nextSkills);
        const currSkillsProps = getProperties(this.props.nonMachineRelatedSkills || {});

        if (!setEquals(nextSkillsProps, currSkillsProps, this.kvpComparer)) {
          updateState = true;
          if (!this.props.isOnEditMode) {
            const nextSkillAssesor = {};
            for (let i = 0; i < nextSkillsProps.length; i++) {
              const key = nextSkillsProps[i].key;
              nextSkillAssesor[key] = null;
            }

            const matrixKvp = getProperties(currEmployeesAssessors);
            for (let i = 0; i < matrixKvp.length; i++) {
              const value = matrixKvp[i].value as EmployeeAssessors;
              if (value == null) continue;
              value.skillsAssessor = { ...nextSkillAssesor };
            }
            nextState.employeesAssessors = { ...currEmployeesAssessors };
          }
        }

        const nextMachineModelsProps = getProperties(nextMachineModels);
        const currMachineModelsProps = getProperties(this.props.machineModelsDict || {});

        if (!setEquals(nextMachineModelsProps, currMachineModelsProps, this.kvpComparer)) {
          updateState = true;
          const currMachineExpert = currEmployeesAssessors[this.props.itemToEdit.userId]?.machineFunctionalExperts;
          const nextMachineExpert = {};
          for (let i = 0; i < nextMachineModelsProps.length; i++) {
            const key = nextMachineModelsProps[i].key;
            nextMachineExpert[key] = currMachineExpert ? currMachineExpert[key] : undefined;
          }

          const matrixKvp = getProperties(currEmployeesAssessors);
          for (let i = 0; i < matrixKvp.length; i++) {
            const value = matrixKvp[i].value as EmployeeAssessors;
            if (value == null) continue;
            value.machineFunctionalExperts = { ...nextMachineExpert };
          }
          nextState.employeesAssessors = { ...currEmployeesAssessors };
        }
      }

      if (updateState) {
        this.setState({ ...nextState });
      }
    }
  }

  private showEmployeeModal = () => {
    this.setState({ showEmployeeModal: true });
  };

  private onCloseEmployeeModal = () => {
    this.setState({ showEmployeeModal: false });
  };

  private onEmployeesSelected = (newEmployees: UserProfilesDto[]) => {
    const { employeesAssessors: matrix, employees } = this.state;
    const skillsProps = getProperties(this.props.nonMachineRelatedSkills).map(kvp => kvp.key);
    const machinesProps = getProperties(this.props.machineModelsDict).map(kvp => kvp.key);

    for (let i = 0; i < newEmployees.length; i++) {
      const employeeId = newEmployees[i].userId;
      if (!this.props.isOnEditMode) {
        const skillDict = {};
        for (let j = 0; j < skillsProps.length; j++) {
          const sId = skillsProps[j];
          skillDict[sId] = null;
        }

        const machineDict = {};
        for (let j = 0; j < machinesProps.length; j++) {
          const mId = machinesProps[j];
          machineDict[mId] = null;
        }

        matrix[employeeId] = {
          lineManagerId: null,
          machineFunctionalExpertId: null,
          skillsAssessor: skillDict,
          machineFunctionalExperts: machineDict
        } as EmployeeAssessors;
      }

      employees[employeeId] = newEmployees[i];
    }
    this.setState({ showEmployeeModal: false, employees: { ...employees }, employeesAssessors: { ...matrix } }, this.triggerOnChange);
  };

  protected removeEmployee = (id: string) => {
    const { employees, employeesAssessors: matrix, assessors, selectedAssessor } = this.state;
    delete matrix[id];
    delete employees[id];

    let nextAssessors = assessors;
    let nextSelectedAssessor = selectedAssessor;
    if (getProperties(employees).length === 0) {
      nextAssessors = {};
      nextSelectedAssessor = null;
    }

    this.setState(
      {
        employees: { ...employees },
        employeesAssessors: { ...matrix },
        assessors: nextAssessors,
        selectedAssessor: nextSelectedAssessor
      },
      this.triggerOnChange
    );
  };

  private showAssessorModal = () => {
    this.setState({ showAssessorModal: true });
  };

  private onCloseAssessorModal = () => {
    this.setState({ showAssessorModal: false });
  };

  private onAssessorsSelected = (newAssessors: UserDto[]) => {
    const { assessors } = this.state;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }
    this.setState({ showAssessorModal: false, assessors: { ...assessors } }, this.triggerOnChange);
  };

  private onAssessorsFromEditMode = (newAssessors: UserDto[]) => {
    const { assessors } = this.state;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }
    this.setState({ showAssessorModal: false, assessors: { ...assessors } }, this.triggerOnChange);
  };

  private onAssessorSelectorChanged = (_: any, { value }) => {
    let selectedAssessor = value;
    if (isNullOrWhiteSpaces(value)) selectedAssessor = null;
    this.setState({ selectedAssessor });
  };

  private removeAssessor = (id: string) => {
    if (this.isUsed(id)) return;
    const { assessors, employeesAssessors: matrix } = this.state;
    let { selectedAssessor } = this.state;

    const matrixRows = getProperties(matrix);

    for (let i = 0; i < matrixRows.length; i++) {
      const row = matrixRows[i].value as EmployeeAssessors;
      if (row.lineManagerId === id) row.lineManagerId = null;
      if (row.machineFunctionalExpertId === id) row.machineFunctionalExpertId = null;
      const prop = getProperties(row.skillsAssessor);
      for (let j = 0; j < prop.length; j++) {
        const { key, value } = prop[j];
        if (value === id) {
          row.skillsAssessor[key] = null;
        }
      }
      const prop2 = getProperties(row.machineFunctionalExperts);
      for (let j = 0; j < prop2.length; j++) {
        const { key, value } = prop2[j];
        if (value === id) {
          row.machineFunctionalExperts[key] = null;
        }
      }
    }
    delete assessors[id];
    if (selectedAssessor === id) selectedAssessor = null;

    this.setState(
      {
        assessors: { ...assessors },
        employeesAssessors: { ...matrix },
        selectedAssessor
      },
      this.triggerOnChange
    );
  };

  private isFunctionalExpertOfThisMachineOrSkill = (assessorId: string, machineOrSkillId: string) => {
    const { itemToEdit } = this.props;

    if (machineOrSkillId === null && itemToEdit.lineManagerId === assessorId) return true;
    if (machineOrSkillId === null && itemToEdit.machineFunctionalExpertId === assessorId) return true;

    var machineFExp = itemToEdit.machineFunctionalExperts.firstOrDefault(x => x.userId === assessorId);
    if (machineFExp && (machineFExp.machineModels || []).any(x => x.id === machineOrSkillId)) {
      return true;
    }

    var skillFExp = itemToEdit.nonMachineFunctionalExperts.firstOrDefault(x => x.userId === assessorId);
    if (skillFExp && (skillFExp.skills || []).any(x => x.id === machineOrSkillId)) {
      return true;
    }

    return false;
  };

  private getEmployeesCell = (id: string, prop: string, key: string, answersStatus: AssessorAnswerStatus[]) => {
    const { employeesAssessors: matrix, selectedAssessor, assessors } = this.state;

    const row = matrix[id];
    const isSelectedAsIgnoredNmrSkills = this.isSelectedAsIgnoredNmrSkills(key);

    if (row == null || isSelectedAsIgnoredNmrSkills) return null;

    if (prop === nameof<EmployeeAssessors>('bypassEmployeeEvaluation')) {
      return (
        <Checkbox
          checked={matrix[id].bypassEmployeeEvaluation}
          disabled={this.props.dataFormEditMode?.status === 'Assessors' || this.props.dataFormEditMode?.status === 'Done'}
          onChange={(_, { checked }) => this.setBypassEvaluations(checked, id, prop)}
        />
      );
    } else if (prop === nameof<EmployeeAssessors>('bypassLineManagerEvaluation')) {
      return (
        <Checkbox
          checked={matrix[id].bypassLineManagerEvaluation}
          disabled={
            (this.props.dataFormEditMode?.status === 'Assessors' &&
              (this.props.dataFormEditMode?.extendedStatus || []).all(x => x === 'Assessment Expert') &&
              !matrix[id].bypassLineManagerEvaluation) ||
            this.props.dataFormEditMode?.status === 'Done'
          }
          onChange={(_, { checked }) => this.setBypassEvaluations(checked, id, prop)}
        />
      );
    }

    let objVal = row[prop];
    if (prop === nameof<EmployeeAssessors>('skillsAssessor') || prop === nameof<EmployeeAssessors>('machineFunctionalExperts')) {
      objVal = objVal[key];
    }

    if (objVal == null) {
      if (selectedAssessor == null) return null;
      if (prop !== nameof<EmployeeAssessors>('lineManagerId') && this.isLineManagerOf(selectedAssessor, id))
        return <Checkbox disabled checked={false} />;
      if (prop === nameof<EmployeeAssessors>('lineManagerId') && this.isFunctionalExpertOf(selectedAssessor, id))
        return <Checkbox disabled checked={false} />;
      return <Checkbox checked={false} onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)} />;
    } else if (objVal === selectedAssessor) {
      return (
        <Checkbox
          checked={true}
          onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)}
          disabled={
            answersStatus &&
            answersStatus.any(x => x.userId === selectedAssessor && x.answerStatus === 'Done') &&
            this.isFunctionalExpertOfThisMachineOrSkill(selectedAssessor, key)
          }
        />
      );
    } else {
      const a = assessors[objVal];
      return `${a?.firstName} ${a?.lastName}`;
    }
  };

  private getEmployeesHeader = (header: string, prop: string, key: string, answersStatus: AssessorAnswerStatus[]) => {
    const { employeesAssessors: matrix, selectedAssessor } = this.state;
    const { t, isOnEditMode } = this.props;

    const isSelectedAsIgnoredNmrSkills = this.isSelectedAsIgnoredNmrSkills(key);

    if (selectedAssessor == null) {
      if (prop === nameof<EmployeeAssessors>('skillsAssessor') && !isOnEditMode) {
        return (
          <Popup
            wide
            position="top center"
            content={
              <span>
                {t('If this checkbox is unchecked, questions related to the skill ')}
                <strong>{header}</strong>
                {t(' will be removed from the form.')}
              </span>
            }
            trigger={
              <Checkbox
                style={{ marginRight: 5 }}
                checked={!isSelectedAsIgnoredNmrSkills}
                onChange={(_, { checked }) => this.setSelectedIgnoredNmrSkills(checked, key)}
                label={header}
              />
            }
          />
        );
      } else return header;
    }

    if (isSelectedAsIgnoredNmrSkills) return header;

    const rows = getKeyValuePairs<EmployeeAssessors>(matrix);
    let thereAreEmpty = false;
    let thereAreSelected = false;
    let avoidCount = 0;
    for (let i = 0; i < rows.length; i++) {
      let employeeId = rows[i].key;
      let row = rows[i].value;
      let val = row[prop];
      if (prop === nameof<EmployeeAssessors>('skillsAssessor') || prop === nameof<EmployeeAssessors>('machineFunctionalExperts'))
        val = val[key];

      if (val == null) {
        if (prop === nameof<EmployeeAssessors>('lineManagerId') && this.isFunctionalExpertOf(selectedAssessor, employeeId)) {
          avoidCount++;
        } else if (prop !== nameof<EmployeeAssessors>('lineManagerId') && this.isLineManagerOf(selectedAssessor, employeeId)) {
          avoidCount++;
        } else {
          thereAreEmpty = true;
          break;
        }
      } else if (val === selectedAssessor) {
        thereAreSelected = true;
      } else {
        avoidCount++;
      }
    }

    if (avoidCount === rows.length)
      return (
        <>
          {header} <Checkbox checked={false} disabled />
        </>
      );

    if (!thereAreSelected && !thereAreEmpty) return header;

    const chk = !thereAreEmpty && thereAreSelected;

    return (
      <>
        {header}{' '}
        <Checkbox
          checked={chk}
          onChange={(_, { checked }) => this.setSelectedAssesorToAll(checked, prop, key)}
          disabled={
            answersStatus &&
            answersStatus.any(x => x.userId === selectedAssessor && x.answerStatus === 'Done') &&
            this.isFunctionalExpertOfThisMachineOrSkill(selectedAssessor, key)
          }
        />
      </>
    );
  };

  private setSelectedIgnoredNmrSkills = (setValue: boolean, key: string) => {
    const { ignoredNmrSkills } = this.state;
    if (setValue) {
      let index = ignoredNmrSkills.indexOf(key);
      ignoredNmrSkills.splice(index, 1);
    } else {
      if (!ignoredNmrSkills.any(skillId => skillId === key)) ignoredNmrSkills.push(key);
    }

    this.setState({ ignoredNmrSkills: ignoredNmrSkills }, this.triggerOnChange);
  };

  private isSelectedAsIgnoredNmrSkills = (key: string) => {
    const { ignoredNmrSkills } = this.state;
    return ignoredNmrSkills.any(skillId => skillId === key);
  };

  private setSelectedAssesorToAll = (setValue: boolean, prop: string, key: string) => {
    const { employeesAssessors: matrix, selectedAssessor } = this.state;
    if (selectedAssessor == null) return;
    const rows = getKeyValuePairs<EmployeeAssessors>(matrix);
    for (let i = 0; i < rows.length; i++) {
      const employeeId = rows[i].key;
      if (!this.canAssignAssessorTo(selectedAssessor, employeeId, prop)) continue;

      const row = rows[i].value;
      if (prop === nameof<EmployeeAssessors>('skillsAssessor') || prop === nameof<EmployeeAssessors>('machineFunctionalExperts')) {
        if (setValue && row[prop][key] == null) row[prop][key] = selectedAssessor;
        if (!setValue && row[prop][key] === selectedAssessor) row[prop][key] = null;
      } else {
        if (setValue && row[prop] == null) row[prop] = selectedAssessor;
        if (!setValue && row[prop] === selectedAssessor) row[prop] = null;
      }
    }
    this.setState(
      {
        employeesAssessors: { ...matrix }
      },
      this.triggerOnChange
    );
  };

  private setSelectedAssesorToEmployee = (setValue: boolean, id: string, prop: string, key: string) => {
    const { employeesAssessors: matrix, selectedAssessor } = this.state;

    if (matrix[id] == null) return;

    if (!this.canAssignAssessorTo(selectedAssessor, id, prop)) return;

    if (prop === nameof<EmployeeAssessors>('skillsAssessor') || prop === nameof<EmployeeAssessors>('machineFunctionalExperts')) {
      matrix[id][prop][key] = setValue ? selectedAssessor : null;
    } else {
      matrix[id][prop] = setValue ? selectedAssessor : null;
    }
    this.setState(
      {
        employeesAssessors: { ...matrix }
      },
      this.triggerOnChange
    );
  };

  private setBypassEvaluations = (setValue: boolean, id: string, prop: string) => {
    const { employeesAssessors: matrix } = this.state;

    if (matrix[id] == null) return;

    if (prop === nameof<EmployeeAssessors>('bypassEmployeeEvaluation')) {
      matrix[id].bypassEmployeeEvaluation = setValue;
    } else if (prop === nameof<EmployeeAssessors>('bypassLineManagerEvaluation')) {
      matrix[id].bypassLineManagerEvaluation = setValue;
    }
    this.setState(
      {
        employeesAssessors: { ...matrix }
      },
      this.triggerOnChange
    );
  };

  private triggerOnChange = () => {
    const { employeesAssessors: matrix, assessors, employees, ignoredNmrSkills } = this.state;
    if (this.props.onChange)
      this.props.onChange({
        assessors: getProperties(assessors).map(x => x.key),
        employees: getProperties(employees).map(x => x.key),
        employeesAssessors: { ...matrix },
        ignoredNmrSkills: ignoredNmrSkills
      });
  };

  private isLineManagerOf = (assesorId: string, employeeId: string) => {
    if (isNullOrWhiteSpaces(assesorId) || isNullOrWhiteSpaces(employeeId)) return false;
    const { employeesAssessors: matrix } = this.state;
    return matrix != null && matrix[employeeId] != null && matrix[employeeId].lineManagerId === assesorId;
  };

  private isFunctionalExpertOf = (assesorId: string, employeeId: string) => {
    if (isNullOrWhiteSpaces(assesorId) || isNullOrWhiteSpaces(employeeId)) return false;
    const { employeesAssessors: matrix } = this.state;
    if (matrix == null) return false;
    const row = matrix[employeeId];
    if (row == null) return false;
    if (row.machineFunctionalExpertId === assesorId) return true;
    return (
      getKeyValuePairs<string>(row.skillsAssessor || {})
        .map(({ value }) => value)
        .includes(assesorId) ||
      getKeyValuePairs<string>(row.machineFunctionalExperts || {})
        .map(({ value }) => value)
        .includes(assesorId)
    );
  };

  private isLineManager = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    const { employeesAssessors: matrix } = this.state;
    if (matrix == null) return false;
    return getProperties(matrix).any(({ key: employeeId }) => this.isLineManagerOf(assesorId, employeeId));
  };

  private isFunctionalExpert = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    const { employeesAssessors: matrix } = this.state;
    if (matrix == null) return false;
    return getProperties(matrix).any(({ key: employeeId }) => this.isFunctionalExpertOf(assesorId, employeeId));
  };

  private isUsed = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    return this.isLineManager(assesorId) || this.isFunctionalExpert(assesorId);
  };

  private canAssignAssessorTo = (assesorId: string, employeeId: string, prop: string) => {
    if (prop === nameof<EmployeeAssessors>('lineManagerId') && this.isFunctionalExpertOf(assesorId, employeeId)) return false;
    if (prop !== nameof<EmployeeAssessors>('lineManagerId') && this.isLineManagerOf(assesorId, employeeId)) return false;
    return true;
  };

  render() {
    const {
      t,
      nonMachineRelatedSkills,
      isOnEditMode,
      formType,
      machineModelsDict,
      itemToEdit,
      isMachineRelatedProfile,
      isGlobalPoc
    } = this.props;
    const { employees, assessors, showEmployeeModal, locationId } = this.state;
    const { profileId, machineModelIds, selectedAssessor, showAssessorModal, isStepActive } = this.state;

    let answersStatus = itemToEdit?.assessorAnswerStatus;
    let assessorsList: UserDto[] = [];
    let employeesList: UserProfilesDto[] = [];
    let skillsList: { key: string; value: string }[] = [];
    let machineModelsList: { key: string; value: string }[] = [];
    if (isStepActive) {
      assessorsList = getKeyValuePairs<UserDto>(assessors).map(x => x.value);
      employeesList = getKeyValuePairs<UserProfilesDto>(employees).map(x => x.value);
      skillsList = getKeyValuePairs<string>(nonMachineRelatedSkills);
      machineModelsList = getKeyValuePairs<string>(machineModelsDict);
    }

    return (
      <div className="wizard__step wizard__step-three tna-form-general-data">
        <StepsComponent active={isStepActive} number={'3'} title={t('SELECT EMPLOYEES & ASSIGN ASSESSORS')}>
          <div className="new-tna-form-wizard_step3__btn-dropdown flex-start" id="">
            {!isOnEditMode && (
              <Button className="new-tna-form-wizard_step3-row_btn" onClick={this.showEmployeeModal}>
                {t('Add Employees')}
              </Button>
            )}
            {showEmployeeModal && (
              <ChooseEmployeesView
                locationId={locationId}
                profileId={profileId}
                machineModelIds={machineModelIds}
                alreadySelectedUserIds={employeesList.map(x => x.userId)}
                onAddEmployees={this.onEmployeesSelected}
                onCloseUsersModal={this.onCloseEmployeeModal}
                isGlobalPoc={isGlobalPoc}
              />
            )}

            {employeesList.length !== 0 && assessorsList.length !== 0 && (
              <Dropdown
                id="new-tna-form-wizard_step3-row1_assessor-select"
                fluid
                selection
                options={assessorsList.map(a => ({
                  value: a.id,
                  text: `${a?.firstName} ${a?.lastName}`
                  //disabled: answersStatus && answersStatus.any(x => x.userId === a.id && x.answerStatus === 'Done')
                }))}
                value={selectedAssessor}
                closeOnBlur={true}
                closeOnEscape={true}
                selectOnBlur={false}
                selectOnNavigation={false}
                placeholder={t('Assign Assessor')}
                onChange={this.onAssessorSelectorChanged}
                clearable
              />
            )}
          </div>

          {employeesList.length !== 0 && (
            <div className="new-tna-form-wizard_step3-matrix-container tooltip-visible scroll">
              <Grid
                id="new-tna-form-wizard_step3-matrix-table"
                className="new-tna-form-wizard_step3-table new-tna-form-wizard_step3-table__assessor"
              >
                <Grid.Row className="new-tna-form-wizard_step3-table-row new-tna-form-wizard_step3-table-header">
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Last Name')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('First Name')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('SF Position')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Bypass Employee Evaluation')}</Grid.Column>
                  <Grid.Column className="new-tna-form-wizard_step3-table-cell">{t('Bypass Line Manager Evaluation')}</Grid.Column>
                  {assessorsList.length !== 0 && (
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {this.getEmployeesHeader(t('Line Manager'), nameof<EmployeeAssessors>('lineManagerId'), null, answersStatus)}
                    </Grid.Column>
                  )}
                  {isMachineRelatedProfile && formType === 'SameMachineHierarchy' && assessorsList.length !== 0 && (
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {this.getEmployeesHeader(t('Functional Expert MR Skills'), 'machineFunctionalExpertId', null, answersStatus)}
                    </Grid.Column>
                  )}
                  {isMachineRelatedProfile &&
                    formType === 'MultipleMachineHierarchy' &&
                    assessorsList.length !== 0 &&
                    machineModelsList.map(kvp => (
                      <Grid.Column key={kvp.key} className="new-tna-form-wizard_step3-table-cell">
                        {this.getEmployeesHeader(
                          `${t('Functional Expert')} ${kvp.value}`,
                          nameof<EmployeeAssessors>('machineFunctionalExperts'),
                          kvp.key,
                          answersStatus
                        )}
                      </Grid.Column>
                    ))}
                  {assessorsList.length !== 0 &&
                    skillsList.map(kvp => (
                      <Grid.Column key={kvp.key} className="new-tna-form-wizard_step3-table-cell">
                        {this.getEmployeesHeader(kvp.value, nameof<EmployeeAssessors>('skillsAssessor'), kvp.key, answersStatus)}
                      </Grid.Column>
                    ))}
                  <Grid.Column
                    width={1}
                    className="new-tna-form-wizard_step3-table-cell new-tna-form-wizard_step3-table-remove-cell"
                  ></Grid.Column>
                </Grid.Row>

                {employeesList.map(e => (
                  <Grid.Row key={`matrix_${e.userId}`} className="new-tna-form-wizard_step3-table-row">
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.lastName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.firstName || '').toUpperCase())} />}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {<TableTooltipCell textToShow={t((e.sfPosition || '').toUpperCase())} />}
                    </Grid.Column>

                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('bypassEmployeeEvaluation'), null, null)}
                    </Grid.Column>
                    <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                      {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('bypassLineManagerEvaluation'), null, null)}
                    </Grid.Column>

                    {assessorsList.length !== 0 && (
                      <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                        {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('lineManagerId'), null, answersStatus)}
                      </Grid.Column>
                    )}

                    {isMachineRelatedProfile && formType === 'SameMachineHierarchy' && assessorsList.length !== 0 && (
                      <Grid.Column className="new-tna-form-wizard_step3-table-cell">
                        {this.getEmployeesCell(e.userId, 'machineFunctionalExpertId', null, answersStatus)}
                      </Grid.Column>
                    )}
                    {isMachineRelatedProfile &&
                      formType === 'MultipleMachineHierarchy' &&
                      assessorsList.length !== 0 &&
                      machineModelsList.map(kvp => (
                        <Grid.Column key={kvp.key} className="new-tna-form-wizard_step3-table-cell">
                          {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('machineFunctionalExperts'), kvp.key, answersStatus)}
                        </Grid.Column>
                      ))}
                    {assessorsList.length !== 0 &&
                      skillsList.map(kvp => (
                        <Grid.Column key={kvp.key} className="new-tna-form-wizard_step3-table-cell">
                          {this.getEmployeesCell(e.userId, nameof<EmployeeAssessors>('skillsAssessor'), kvp.key, answersStatus)}
                        </Grid.Column>
                      ))}

                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-tna-form-wizard_step3-table-cell  new-tna-form-wizard_step3-table-remove-cell"
                    >
                      {!isOnEditMode && (
                        <Icon
                          size="large"
                          name="close"
                          color="red"
                          className="clickable-icon"
                          onClick={() => this.removeEmployee(e.userId)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </div>
          )}
          {employeesList.length !== 0 && (
            <>
              <Button className="new-tna-form-wizard_step3-row_btn add-assessor__btn" onClick={this.showAssessorModal}>
                {t('Add Assessors')}
              </Button>
              {showAssessorModal && (
                <ChooseAssessorsView
                  alreadySelectedUserIds={[...assessorsList.map(a => a.id), ...employeesList.map(e => e.userId)]}
                  onAddAssessors={this.onAssessorsSelected}
                  onCloseUsersModal={this.onCloseAssessorModal}
                  showEmployeeIdFilter
                />
              )}
            </>
          )}
          {employeesList.length !== 0 && assessorsList.length !== 0 && (
            <Grid id="new-tna-form-wizard_step3-assessors-table" className="new-tna-form-wizard_step3-table">
              <Grid.Row columns={4} className="new-tna-form-wizard_step3-table-row new-tna-form-wizard_step3-table-header">
                <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                  {t('Last Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                  {t('First Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                  {t('SF Position')}
                </Grid.Column>
                <Grid.Column
                  width={1}
                  className="new-tna-form-wizard_step3-table-cell new-tna-form-wizard_step3-table-remove-cell"
                ></Grid.Column>
              </Grid.Row>
              {assessorsList.map(a => (
                <Fragment key={a.id}>
                  <Grid.Row columns={4} key={`assessors_${a.id}`} className="new-tna-form-wizard_step3-table-row">
                    <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                      {(a.lastName || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                      {(a.firstName || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={5} className="new-tna-form-wizard_step3-table-cell">
                      {(a.sfPosition || '').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-tna-form-wizard_step3-table-cell  new-tna-form-wizard_step3-table-remove-cell"
                    >
                      {!this.isUsed(a.id) && (
                        <Icon size="large" name="close" color="red" className="clickable-icon" onClick={() => this.removeAssessor(a.id)} />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Fragment>
              ))}
            </Grid>
          )}
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(EmployeesAndAssessorsStep);
