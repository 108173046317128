import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface IwsSkillDto extends BaseDto {
  id: string; //Guid
  friendlyId: string;
  name: string;
  description: string;
}

export interface CreateIwsSkillDto {
  description: string;
  name: string;
}

export interface ChangeIwsSkillDto {
  id: string;
  description: string;
  name: string;
}

export class CreateIwsSkillValidator extends AbstractValidator<CreateIwsSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));
  }
}

export class ChangeIwsSkillValidator extends AbstractValidator<ChangeIwsSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Skill Id is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));
  }
}

@repository('@@IWSSKILLS', 'iwsskills.summary')
export class IwsSkillsStore extends DataStore<IwsSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-skill';
  retrievePath = 'get-iws-skills';
  updatePath = 'update-iws-skill';
  deletePath = 'delete-iws-skill';
  retrieveOnePath = 'get-iws-skill';
  retrieveCountPath = 'get-used-iws-skill-count';

  protected validate(item: IwsSkillDto) {
    return new ChangeIwsSkillValidator().validate(item);
  }

  constructor() {
    super('IWSSKILL', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getIwsSkillById(id: string): Promise<IwsSkillDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<IwsSkillDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedSkillCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@IWSSKILLS', 'iwsskills.new')
export class NewIwsSkillStore extends FormStore<CreateIwsSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-skill';
  retrievePath = 'get-iws-skills';
  updatePath = 'update-iws-skill';

  protected validate(item: CreateIwsSkillDto) {
    return new CreateIwsSkillValidator().validate(item);
  }

  constructor() {
    super('NEW_IWSSKILL', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@IWSSKILLS', 'iwsskills.change')
export class ChangeIwsSkillStore extends FormStore<ChangeIwsSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-skill';
  retrievePath = 'get-iws-skills';
  updatePath = 'update-iws-skill';

  protected validate(item: ChangeIwsSkillDto) {
    return new ChangeIwsSkillValidator().validate(item);
  }

  constructor() {
    super('CHANGE_IWSSKILL', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
