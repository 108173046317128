import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab, Dimmer, Loader } from 'semantic-ui-react';

import i18n from 'i18n';
import AssigmentEmployeesWithProfile from './employees-with-profile/assigment-employees-with-profile';
import AssigmentEmployeesWithoutProfile from './employees-without-profile/assigment-employees-without-profile';
import AssigmentEmployeesWithProfileEditMode from './employees-assignment.component';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface TnaSkillsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface TnaSkillsConfigurationHomeState {
  activeTab: 'Employees Profile' | 'Employees without Profile';
  loading: boolean;
  onAssign: boolean;
  assignmentData: UserProfilesDto[];
  mode: AssignmentMode;
  activeIndex: number;
}

export type AssignmentMode = 'edit' | 'new' | 'multiAssign';

class ProfileAssigmentHome extends React.Component<TnaSkillsConfigurationHomeProps, TnaSkillsConfigurationHomeState> {
  constructor(props: TnaSkillsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'Employees Profile',
      loading: false,
      onAssign: false,
      assignmentData: null,
      mode: null,
      activeIndex: 0
    };
  }

  onAssignProfile = (mode: AssignmentMode, data: UserProfilesDto[]) => {
    this.setState({ onAssign: true, mode, assignmentData: data });
  };

  private panes = [
    {
      menuItem: i18n.t('Employees Profile'),
      render: () => <Tab.Pane attached={false}>{<AssigmentEmployeesWithProfile onEditProfile={this.onAssignProfile} />}</Tab.Pane>
    },
    {
      menuItem: i18n.t('Employees without Profile'),
      render: () => <Tab.Pane attached={false}>{<AssigmentEmployeesWithoutProfile onAssignment={this.onAssignProfile} />}</Tab.Pane>
    }
  ];
  onCancelAssignment = () => {
    this.setState({ onAssign: false });
  };
  handleTabChange = (_e: any, { activeIndex }: any) => this.setState({ activeIndex });

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    if (this.state.onAssign) {
      return (
        <>
          <h3 id="assigment-employees-profiles-edit__title">{t('Employee Profile Assignment')}</h3>
          <LineSeparator />
          <AssigmentEmployeesWithProfileEditMode
            onCancel={this.onCancelAssignment}
            mode={this.state.mode}
            data={this.state.assignmentData}
          />
        </>
      );
    } else {
      return (
        <>
          <h3>{t('Employee Profile Assignment')}</h3>
          <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)', position: 'fixed' }}>
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          <Tab
            className="main-tab"
            menu={{ secondary: true, pointing: true }}
            panes={this.panes}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
          />
        </>
      );
    }
  }
}

export default withTranslation()(ProfileAssigmentHome);
