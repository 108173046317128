import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { TrainingLevelDto, TrainingLevelsStore } from '../../../stores/configuration/events-n-requests/training-levels-store';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';

interface TrainingLevelsSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  trainingLevels?: TrainingLevelsStore;
  direction?: 'left' | 'right';
}

@connect(['trainingLevels', TrainingLevelsStore])
class TrainingLevelsSelector extends React.Component<TrainingLevelsSelectorProps> {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    this.props.trainingLevels
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<TrainingLevelDto>('name'), useProfile: false }],
        filter: []
      })
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.name }))
        });
      });
  }

  public render() {
    const { options, isLoading } = this.state;
    const { value, placeholder, direction, searchable, className, clearable, onChange } = this.props;
    const selectedValue = value || null;
    return (
      <Dropdown
        direction={direction}
        selection
        loading={isLoading}
        search={searchable}
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => onChange(value as string)}
        selectOnBlur={false}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(TrainingLevelsSelector);
