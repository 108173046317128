import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SkillDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './user-profile-listing-details/score-summary';
import './employee-page.less';
import './background-employee.less';
import RadarChartComponent, { ComparisonViewModel } from './radar-chart-component';

export interface TnaRadarChartComponentProps extends WithTranslation {
  skills: SkillDto[];
  scoreSummary: ScoreSummary;
}

class TnaRadarChartComponent extends Component<TnaRadarChartComponentProps> {
  generateData(skills: SkillDto[], skillScores: SkillScore[]) {
    let maxPoint = -1;

    const points = skills.map(skill => {
      const skillScore = skillScores.firstOrDefault(score => score.id === skill.skillId);
      const p = {
        subject: skill.skillName,
        actual: skillScore ? skillScore.score : 0,
        substitute: skill.substitute,
        main: skill.main,
        fullMark: 4
      };
      maxPoint = Math.max(...[maxPoint, p.actual, p.substitute, p.main]);
      return p;
    });
    if (maxPoint <= 0) maxPoint = 4;
    points.forEach(p => (p.fullMark = maxPoint));

    return { points, maxPoint };
  }

  public render() {
    const { t } = this.props;
    const { points: data, maxPoint } = this.generateData(this.props.skills, this.props.scoreSummary.skillScores);
    const comparisonViewModels: ComparisonViewModel[] = [
      {
        name: t('Substitute target'),
        dataKey: 'substitute',
        stroke: '#B777D7',
        fill: '#8884d8',
        fillBorder: 4
      },

      {
        name: t('Main Target'),
        dataKey: 'main',
        stroke: '#EAAA00',
        fill: '#00B2A933',
        fillBorder: 4
      }
    ];
    return <RadarChartComponent data={data} comparisonViewModels={comparisonViewModels} maxPoint={maxPoint} />;
  }
}

export default withTranslation()(TnaRadarChartComponent);
