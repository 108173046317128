import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dimmer, Loader, Button, Message, Modal } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import SsaFirstStepContent from './steps/ssa-wizard-first-step';
import { Query } from 'stores/dataStore';
import {
  SsaTemplatesStore,
  NewSsaTemplateStore,
  ChangeSsaTemplateStore,
  SsaTemplateDto,
  ChangeSsaTemplateDto,
  CreateSsaTemplateDto,
  SsaSkillTemplateSectionDto,
  CreateSsaSkillTemplateSectionDto,
  SsaSkillTemplateSectionCategoryDto,
  CreateSsaSkillTemplateSectionCategoryDto,
  SsaCheckpointDto,
  CreateSsaCheckpointDto
} from 'stores/assessments/templates/ssa-templates-store';
import { RouteComponentProps } from 'react-router';
import { Assessment, ProfileItemDto, ProfileItemStore, ProfileSkillDto } from 'stores/profile/profile-store';
import LineSeparator from 'widgets/bussiness/line-separator';
import SsaSecondStepContent from './steps/ssa-wizard-second-step';
import './ssa-templates-wizard.less';

export interface SsaTemplateWizardComponentProps extends WithTranslation, RouteComponentProps {
  ssaTemplateStore?: SsaTemplatesStore;
  newTemplateStore?: NewSsaTemplateStore;
  changeTemplateStore?: ChangeSsaTemplateStore;
  profileStore?: ProfileItemStore;
}
type ModalMode = 'cancel' | 'accept';
export interface SsaTemplateWizardComponentState {
  query: Query;
  templateData: SsaTemplateData;
  showConfirmationModal: boolean;
  confirmationModalMode: ModalMode;
}
export interface SsaTemplateData {
  profile: ProfileItemDto;
  title: string;
  header?: string;
  id?: string;
  skillSectionCategories: SsaSkillTemplateSectionCategoryDto[];
}
@connect(
  ['ssaTemplateStore', SsaTemplatesStore],
  ['newTemplateStore', NewSsaTemplateStore],
  ['changeTemplateStore', ChangeSsaTemplateStore],
  ['profileStore', ProfileItemStore]
)
class SsaTemplateWizardComponent extends React.Component<SsaTemplateWizardComponentProps, SsaTemplateWizardComponentState> {
  constructor(props: SsaTemplateWizardComponentProps) {
    super(props);
    this.state = {
      confirmationModalMode: null,
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10, filter: [] },
      templateData: {
        profile: null,
        title: null,
        header: null,
        skillSectionCategories: []
      },
      showConfirmationModal: false
    };
  }
  loading = false;
  private get modeStore(): NewSsaTemplateStore | ChangeSsaTemplateStore {
    return this.isOnEditMode() ? this.props.changeTemplateStore : this.props.newTemplateStore;
  }
  private get plainNewItem(): SsaTemplateDto {
    return {
      id: null,
      friendlyId: null,
      profileItemId: null,
      profileName: null,
      title: null,
      header: null,
      isActive: true,
      skillSectionCategories: null
    };
  }
  private get templates() {
    return this.props.ssaTemplateStore;
  }
  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state || match.params['id']) return true;
    else return false;
  };

  componentDidMount() {
    const { params } = this.props.match;
    const id = params['id'];
    if (id != null) this.loadTemplate(id);
    else this.createNewItemStore(this.plainNewItem);
  }

  loadTemplate = async (id: string) => {
    const { profileStore, history } = this.props;
    this.loading = true;
    try {
      const templData = await this.templates.getTemplateById(id);

      let profile: any = (profileStore.state.items || []).find(({ item }) => item.id === templData.profileItemId);

      profile = profile ? profile.item : await profileStore.getProfileById(templData.profileItemId);

      if (profile && templData) this.loading = false;

      const mappedData = this.mapToTemplateData(templData);

      this.setState({ templateData: { ...mappedData, profile } }, () => this.createNewItemStore(templData));
    } catch (error) {
      history.replace('/not-found');
    }
  };

  mapToTemplateData = (rawData: SsaTemplateDto): SsaTemplateData => {
    const { skillSectionCategories, header, title } = rawData;
    return { ...this.state.templateData, header: header, title: title, skillSectionCategories };
  };

  mapToCreateCheckPoints = (checkpoints: SsaCheckpointDto[]): CreateSsaCheckpointDto[] => {
    return (checkpoints || []).map(({ description, questions }) => ({ questions: questions.map(({ id }) => id), description }));
  };

  mapToCreateSkillSection = (skillSection: SsaSkillTemplateSectionDto[]): CreateSsaSkillTemplateSectionDto[] =>
    (skillSection || []).map(({ checkpoints, linkUrl: urlLink, ...rest }) => ({
      ...rest,
      urlLink,
      checkpoints: this.mapToCreateCheckPoints(checkpoints)
    }));

  mapToCreateCategorySection = (skillCategory: SsaSkillTemplateSectionCategoryDto[]): CreateSsaSkillTemplateSectionCategoryDto[] => {
    return (skillCategory || []).map(({ name, skillSections }) => ({
      name,
      skillSections: this.mapToCreateSkillSection(skillSections)
    }));
  };

  changeStoreItem = () => {
    const storedItem: CreateSsaTemplateDto | ChangeSsaTemplateDto = { ...this.modeStore.state.item };
    const { header, title, skillSectionCategories, profile }: SsaTemplateData = { ...this.state.templateData };
    const editItem: any = {
      ...storedItem,
      skillTemplateSectionCategories: this.mapToCreateCategorySection(skillSectionCategories)
    };
    if (profile && profile.id) editItem.profileItemId = profile.id;
    if (header !== storedItem.header) editItem.header = header;
    if (title !== storedItem.title) editItem.title = title;

    this.modeStore.change(editItem);
  };

  createNewItemStore = ({ id, title, header, isActive, profileItemId, skillSectionCategories }: SsaTemplateDto) => {
    let skillTemplateSectionCategories = [];

    if ((skillSectionCategories || []).length > 0) skillTemplateSectionCategories = this.mapToCreateCategorySection(skillSectionCategories);

    const newItem = { profileItemId, id, title, header, isActive, skillTemplateSectionCategories };
    if (this.isOnEditMode()) {
      delete newItem.profileItemId;
      this.modeStore.change(newItem);
    } else {
      delete newItem.id;
      (this.modeStore as NewSsaTemplateStore).createNew(newItem);
    }
  };

  onChangeProperty = (property: string, value: any) => {
    const templateData = { ...this.state.templateData };
    templateData[property] = value;
    this.setState({ templateData }, this.changeStoreItem);
  };

  isStepTwoActive = () => {
    const { profile, title } = this.state.templateData;
    return title && profile != null;
  };

  goBack = () => {
    const { history } = this.props;
    this.modeStore.clear();
    history.goBack();
  };

  submit = () => {
    this.cancelConfirmationModal();
    const { modeStore } = this;
    if (this.isOnEditMode())
      (modeStore as ChangeSsaTemplateStore).update().then(_ => {
        modeStore.clear();
        modeStore.state.result && modeStore.state.result.isSuccess ? this.goBack() : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
    else
      (modeStore as NewSsaTemplateStore).submit().then(_ => {
        modeStore.clear();
        modeStore.state.result && modeStore.state.result.isSuccess ? this.goBack() : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
  };

  confirmationModalHandler = (mode: ModalMode) => {
    this.setState({ showConfirmationModal: true, confirmationModalMode: mode });
  };

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, confirmationModalMode: null });
  };

  getUniqueValues = (value: any, index: number, self: any[]) => {
    return self.indexOf(value) === index;
  };

  mapToChangeCategorySection = (skills: ProfileSkillDto[]): SsaSkillTemplateSectionCategoryDto[] => {
    let ssaCategories = skills.filter(({ assessment }) => assessment === Assessment[Assessment.SSA]);
    if ((ssaCategories || []).length === 0) return [];
    let categories = ssaCategories.map(({ testCategory }) => testCategory.name).filter(this.getUniqueValues);

    const skillCategories: SsaSkillTemplateSectionCategoryDto[] = [];

    categories.forEach(name => {
      const skillSections: SsaSkillTemplateSectionDto[] = [];
      ssaCategories.forEach(({ testCategory, skillId, skillName }) => {
        if (name === testCategory.name) skillSections.push({ skillId, skillName, checkpoints: [], linkUrl: '' });
      });
      skillCategories.push({ name, skillSections });
    });
    return skillCategories;
  };

  onChangeProfile = (profile: ProfileItemDto) => {
    const skillSectionCategories = profile && profile.id ? this.mapToChangeCategorySection(profile.skills) : [];
    this.setState(
      pS => ({ templateData: { ...pS.templateData, skillSectionCategories, profile: profile && profile.id ? { ...profile } : null } }),
      this.changeStoreItem
    );
  };
  isAbleToSubmit = (): boolean => {
    const { skillSectionCategories } = this.state.templateData;
    const ableToSecStep = this.isStepTwoActive();
    const ableToSubmit =
      skillSectionCategories &&
      skillSectionCategories.length > 0 &&
      skillSectionCategories.every(
        ({ skillSections }) =>
          skillSections.length > 0 &&
          skillSections.every(
            ({ checkpoints }) =>
              checkpoints.length > 0 && checkpoints.every(({ description, questions }) => description && questions.length > 0)
          )
      );
    return ableToSecStep && ableToSubmit;
  };

  render() {
    const { t } = this.props;
    const { isBusy, result } = this.modeStore.state;
    const { isBusy: busy } = this.templates.state;
    const { skillSectionCategories, profile } = this.state.templateData;
    const { templateData, confirmationModalMode, showConfirmationModal } = this.state;
    const mode = this.isOnEditMode();

    return (
      <>
        <h3 className="template-wizard-title">{t(`SSA Template ${mode ? 'Edition' : 'Creation'}`)}</h3>
        <LineSeparator />
        {result && !result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={result.messages.map(m => m.body)} />
        )}
        <div className="ssa-wizard__steps-container">
          <Dimmer page active={isBusy || busy || this.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          <SsaFirstStepContent
            isEditMode={mode}
            data={templateData}
            onChangeProperty={this.onChangeProperty}
            onChangeProfile={this.onChangeProfile}
          />

          <SsaSecondStepContent
            categoriesSection={skillSectionCategories}
            onChangeProperty={this.onChangeProperty}
            profession={profile && profile.professionId}
            isStepActive={this.isStepTwoActive()}
          />
        </div>

        <div className="ssa-templates-wizard__btns">
          <Button onClick={() => this.confirmationModalHandler('cancel')} secondary inverted>
            {t('Cancel')}
          </Button>
          <Button disabled={!this.isAbleToSubmit()} onClick={() => this.confirmationModalHandler('accept')} primary positive>
            {t(`${mode ? 'Save' : 'Create'} Template`)}
          </Button>
        </div>
        {showConfirmationModal && (
          <Modal
            open={showConfirmationModal}
            size="mini"
            className="ssa-confirmation-modal"
            closeOnEscape={true}
            onClose={this.cancelConfirmationModal}
          >
            <Modal.Content className="ssa-confirmation-modal__content">
              {confirmationModalMode === 'accept' ? (
                <p>
                  {t(
                    `${
                      mode
                        ? 'Are you sure do you want to apply this changes in this SSA Template?'
                        : 'Do you want to create the SSA Template'
                    }`
                  )}
                </p>
              ) : (
                <>
                  <p>{t('Are you sure you want to CANCEL?')}</p>
                  <p>{t('You will lose all the changes made in this SSA template.')}</p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="tiny"
                className="ssa-confirmation-modal__btn cancel"
                content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                onClick={this.cancelConfirmationModal}
              />
              <Button
                size="tiny"
                className="ssa-confirmation-modal__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                onClick={confirmationModalMode === 'accept' ? this.submit : this.goBack}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(SsaTemplateWizardComponent);
