import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';

import { ClusterDto, ClustersStore } from '../../../../../stores/configuration/machinery/clusters-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import ChangeClusterView from './edit-cluster';
import NewClusterView from './new-cluster';

export interface ClustersListProps extends WithTranslation, RouteComponentProps {
  clusters: ClustersStore;
  readOnly: boolean;
}

export interface ClustersListState {
  query: Query;
  newClusterShown: boolean;
  changeClusterShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['clusters', ClustersStore])
class ClustersListPage extends React.Component<ClustersListProps, ClustersListState> {
  handleOnEnterKeydown = item => {
    !this.props.readOnly && this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<ClusterDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newClusterShown: false,
      changeClusterShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.clusters.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) });
    this.load();
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: ClusterDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.clusters.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: ClusterDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.clusters.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private onEditItem() {
    this.setState({ changeClusterShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeClusterShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newClusterShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newClusterShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: false,
          renderer: data => <span>{data.name}</span>,
          //editor: (data, onChange) => <Input value={data.name} fluid onChange={(e, { value }) => { data.name = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<ClusterDto>('name'), `contains(${nameof<ClusterDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<ClusterDto>('name'))}
              active={activeFilters.includes(nameof<ClusterDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<ClusterDto>('name'),
            useProfile: false,
            active: 'Ascending'
          }
        }
      ],
      data: this.props.clusters.state
    } as TableModel<ClusterDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.readOnly && <h2>{t('Clusters')}</h2>}
          {this.props.clusters.state.result && !this.props.clusters.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.clusters.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newClusterShown && !this.state.changeClusterShown && !this.props.readOnly}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraActions={
                !this.props.readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={!this.props.readOnly}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!this.props.readOnly}
              createNewButtonTitle={t('Add Cluster')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newClusterShown && <NewClusterView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeClusterShown && <ChangeClusterView onClose={this.onEditItemClosed} currentCluster={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(ClustersListPage);
