import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface CustomizationDataDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateCustomizationDataDto {
  name: string;
}

export interface ChangeCustomizationDataDto {
  id: string;
  name: string;
}

export class CreateCustomizationDataValidator extends AbstractValidator<CreateCustomizationDataDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Customization Data name is required'));
  }
}

export class ChangeCustomizationDataValidator extends AbstractValidator<ChangeCustomizationDataDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Customization Data Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Customization Data name is required'));
  }
}

@repository('@@CUSTOMIZATIONDATA', 'customization-data.summary')
export class CustomizationDataStore extends DataStore<CustomizationDataDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-customization-data';
  retrievePath = 'get-customization-data';
  updatePath = 'update-customization-data';
  deletePath = 'delete-customization-data';

  protected validate(item: CustomizationDataDto) {
    return new ChangeCustomizationDataValidator().validate(item);
  }

  constructor() {
    super('CUSTOMIZATION_DATA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@CUSTOMIZATIONDATA', 'customization-data.new')
export class NewCustomizationDataStore extends FormStore<CreateCustomizationDataDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-customization-data';
  retrievePath = 'get-customization-data';
  updatePath = 'update-customization-data';

  protected validate(item: CreateCustomizationDataDto) {
    return new CreateCustomizationDataValidator().validate(item);
  }

  constructor() {
    super('NEW_CUSTOMIZATION_DATA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@DELIVERY-TYPES', 'delivery-types.change')
export class ChangeCustomizationDataStore extends FormStore<ChangeCustomizationDataDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-customization-data';
  retrievePath = 'get-customization-data';
  updatePath = 'update-customization-data';

  protected validate(item: ChangeCustomizationDataDto) {
    return new ChangeCustomizationDataValidator().validate(item);
  }

  constructor() {
    super('CHANGE_CUSTOMIZATION_DATA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
