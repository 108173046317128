import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { DeliveryTypeDto } from 'stores/configuration/events-n-requests/delivery-types-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import MultipleProfessionsEditor from 'widgets/bussiness/multiple-selector/multiple-professions-editor';
import MultipleDeliveryMethodsEditor from 'widgets/bussiness/multiple-selector/multiple-delivery-methods-editor';
import MultipleTrainingLevelsEditor from 'widgets/bussiness/multiple-selector/multiple-training-level-editor';
import MultipleFunctionalAreaEditor from 'widgets/bussiness/multiple-selector/multiple-functional-area-editor';
import MultiplePillarsEditor from 'widgets/bussiness/multiple-selector/multiple-pillar-editor';
import { PillarDto } from 'stores/configuration/profiles/pillars-store';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { RegionDto } from 'stores/configuration/locations/regions-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import MultipleMrClustersEditor from 'widgets/bussiness/multiple-selector/multiple-mr-cluster-editor';
import MultipleNmrClustersEditor from 'widgets/bussiness/multiple-selector/multiple-nmr-cluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import MultipleRegionEditor from 'widgets/bussiness/multiple-selector/multiple-region-editor';
import { Categories } from 'stores/requests/requests-store';

export interface InvisibleFiltersValue {
  professions: ProfessionDto[];
  deliveryMethods: DeliveryTypeDto[];
  trainingLevels: TrainingLevelDto[];
  functionalAreas: NMFunctionalAreaDto[];
  machineModel: string;
  pillars: PillarDto[];
  regions: RegionDto[];
  mrClusters: ClusterDto[];
  nmrClusters: NMClusterDto[];
  category: Categories;
  eventLocationRegions: RegionDto[];
}

export interface InvisibleFiltersProps extends WithTranslation {
  value: InvisibleFiltersValue;
  disabled?: boolean;
  visible: boolean;
  onChange?: (filterValues: InvisibleFiltersValue) => void;
  showInstructorsFilters?: boolean;
}

const InvisibleFilters: React.FC<InvisibleFiltersProps> = props => {
  const handleFilterValueChange = (property: keyof InvisibleFiltersValue, value: any): void => {
    const changes: Partial<InvisibleFiltersValue> = {};
    // if (property === 'category') {
    //   changes.nmrClusters = [];
    //   changes.functionalAreas = [];
    //   changes.machineModel = '';
    // }
    // if (property === 'nmrClusters' || property === 'functionalAreas' || property === 'category') {
    if (property === 'nmrClusters') changes.functionalAreas = [];
    //   changes.mrClusters = [];
    //   changes.machineModel = '';
    // }
    // if (property === 'mrClusters' || property === 'machineModel') {
    if (property === 'mrClusters') changes.machineModel = '';
    //   changes.nmrClusters = [];
    //   changes.functionalAreas = [];
    // }
    props.onChange && props.onChange({ ...props.value, [property]: value, ...changes });
  };

  const { t, visible, showInstructorsFilters, value } = props;

  if (!visible) return <></>;

  return (
    <div className="inputs-hidden-wrapper">
      {showInstructorsFilters && (
        <MultiplePillarsEditor
          clearable
          placeholder={t("Instructor's Pillar")}
          value={value.pillars}
          onChange={pillars => handleFilterValueChange('pillars', pillars)}
        />
      )}
      <MultipleProfessionsEditor
        clearable
        placeholder={t('Roles')}
        value={value.professions}
        onChange={professions => handleFilterValueChange('professions', professions)}
      />
      {!showInstructorsFilters && (
        <MultipleRegionEditor
          clearable
          placeholder={t('Event Location Region')}
          value={value.eventLocationRegions}
          onChange={value => handleFilterValueChange('eventLocationRegions', value)}
        />
      )}

      <MultipleDeliveryMethodsEditor
        clearable
        placeholder={t('Delivery Method')}
        value={value.deliveryMethods}
        onChange={deliveryMethods => handleFilterValueChange('deliveryMethods', deliveryMethods)}
      />

      <MultipleTrainingLevelsEditor
        clearable
        placeholder={t('Training Level')}
        value={value.trainingLevels}
        onChange={trainingLevels => handleFilterValueChange('trainingLevels', trainingLevels)}
      />

      <MultipleMrClustersEditor
        clearable
        placeholder={t('Cluster MR')}
        // disabled={(value.nmrClusters || []).length > 0 || !!value.category}
        value={value.mrClusters}
        onChange={mrClusters => handleFilterValueChange('mrClusters', mrClusters)}
      />

      <MachineModelEditor
        className="planit-multi-dropdown"
        clearable
        clusterId={value.mrClusters[0]?.id}
        direction="left"
        placeholder={t('Machine Model')}
        // readonly={(value.nmrClusters || []).length > 0 || !!value.category}
        value={value.machineModel}
        onChange={m => handleFilterValueChange('machineModel', m?.id)}
        loadDataOnOpen={true}
      />

      <CategoryFormEditor
        className="planit-multi-dropdown"
        placeholder={t('Category')}
        value={value.category}
        // readOnly={!!value.machineModel || (value.nmrClusters || []).length > 0}
        clearable
        onChange={value => handleFilterValueChange('category', value)}
      />

      <MultipleNmrClustersEditor
        clearable
        category={value.category}
        // disabled={(value.mrClusters || []).length > 0 || !!value.machineModel}
        placeholder={t('Cluster NMR')}
        value={value.nmrClusters}
        onChange={nmrClusters => handleFilterValueChange('nmrClusters', nmrClusters)}
      />

      <MultipleFunctionalAreaEditor
        clusterId={value.nmrClusters[0]?.id}
        clearable
        placeholder={t('Functional Area')}
        // disabled={!!value.machineModel || (value.mrClusters || []).length > 0}
        value={value.functionalAreas}
        onChange={functionalAreas => handleFilterValueChange('functionalAreas', functionalAreas)}
      />
    </div>
  );
};
// InvisibleFilters.defaultProps = { onChange: () => {}, disabled: false };
export default withTranslation()(React.memo(InvisibleFilters));
