import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import i18n from 'i18n';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import TnaTabPane from './tna-tab-pane/tna-tab-pane';
import SsaTabPane from './ssa-tab-pane/ssa-tab-pane';

export interface EmployeeDevelopmentPlansPageProps extends WithTranslation, RouteComponentProps {}

interface EmployeeDevelopmentPlansPageState {
  activeIndex: number;
}

class EmployeeDevelopmentPlansPage extends Component<EmployeeDevelopmentPlansPageProps, EmployeeDevelopmentPlansPageState> {
  constructor(props: Readonly<EmployeeDevelopmentPlansPageProps>) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndex()
    };
  }

  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  private getActiveIndex = () => {
    let values = queryString.parse(this.props.location.search);

    if (values == null || values.tab == null) values = this.props.match.params;
    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < this.panes.length) return n;
    return 0;
  };

  private panes = [
    {
      menuItem: i18n.t('TNA'),
      render: () => (
        <Tab.Pane attached={false}>
          <TnaTabPane />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('SSA'),
      render: () => (
        <Tab.Pane attached={false}>
          <SsaTabPane />
        </Tab.Pane>
      )
    }
  ];

  private onTabChange = (_, { activeIndex }) => {
    this.props.history.replace(this.props.history.location.pathname + `?tab=${activeIndex}`);
    this.setState({ activeIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    return (
      <>
        <h3>{t('Development Plan')}</h3>
        <Tab
          className="main-tab"
          menu={{ secondary: true, pointing: true }}
          panes={this.panes}
          activeIndex={activeIndex}
          onTabChange={this.onTabChange}
        />
      </>
    );
  }
}

export default withTranslation()(EmployeeDevelopmentPlansPage);
