import React, { FC, memo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { Category } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';

import { Categories, getAllCategories } from 'stores/requests/requests-store';

interface CategoryFormEditorProps extends WithTranslation {
  value: Categories | Category;
  onChange?: (value: Categories) => void;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  readOnly?: boolean;
  direction?: 'left' | 'right';
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}
const CategoryFormEditor: FC<CategoryFormEditorProps> = ({ t, onChange, ...props }) => {
  const { direction, value, className, placeholder, readOnly, clearable } = props;
  const [categories] = useState<DropdownItemProps[]>(getAllCategories);
  return (
    <Dropdown
      direction={direction}
      placeholder={t(placeholder)}
      className={className}
      selection
      selectOnBlur={false}
      options={categories}
      disabled={readOnly}
      value={value === 'Functional' || value === 'Technical' ? Categories[value] : value}
      onChange={(_, { value }) => onChange(value ? Categories[value as string] : value)}
      clearable={clearable}
      onBlur={e => props.onBlur && props.onBlur(e)}
      onFocus={e => props.onFocus && props.onFocus(e)}
    />
  );
};

CategoryFormEditor.defaultProps = { clearable: false, onChange: () => {}, placeholder: 'Select Category', className: 'user-dropdown' };

export default withTranslation()(memo(CategoryFormEditor));
