import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Form, Grid } from 'semantic-ui-react';
import { resolve } from 'inversify-react';
import LocationEditor from '../../../../widgets/bussiness/location-editor';
import InstructorEditor from '../../../../widgets/bussiness/request-instructor-editor';
import { RequestWizardStore, RequestWizardData } from 'stores/requests/create-request-wizard-store';
import { nameof } from 'utils/object';
import { IdentityService } from 'services/identity-service';

interface NewRequestViewProps extends WithTranslation {
  requestWizardStore?: RequestWizardStore;
}

//interface NewRequestViewState {}

@connect(['requestWizardStore', RequestWizardStore])
class StepInstructors extends Component<NewRequestViewProps, any> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.requestWizardStore.change({ ...this.requestWizardStore.state.item, ...change });
  }

  private isPlannerTFT = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerTFT(userinfo);
  };

  private isPlannerMTC = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerMTC(userinfo);
  };

  render() {
    const { t } = this.props as any;

    return (
      <Form className="request-wizard__step5-instructors__container" loading={this.requestWizardStore.state.isBusy}>
        <Grid id="new-event-form-grid" columns={4}>
          <Grid.Row>
            <Grid.Column width={4} textAlign="right" verticalAlign="middle">
              <div className={`field inline`}>
                <label>{t("Instructor's Location")}</label>
              </div>
            </Grid.Column>

            <Grid.Column width={4}>
              <div className={`field inline`}>
                <LocationEditor
                  clearable
                  nullable
                  value={this.requestWizardStore.state.item.instructorLocation}
                  onChange={location => {
                    this.handleValue(nameof<RequestWizardData>('instructorLocation'), location);
                    this.handleValue(nameof<RequestWizardData>('instructor'), null);
                  }}
                />
              </div>
            </Grid.Column>

            <Grid.Column width={3} textAlign="right" verticalAlign="middle">
              <div className={`field inline`}>
                <label>{t("Instructor's Name")}</label>
              </div>
            </Grid.Column>

            <Grid.Column width={4}>
              <div className={`field inline`}>
                <InstructorEditor
                  clearable
                  role={'Instructor'}
                  nullable
                  location={this.requestWizardStore.state.item.instructorLocation}
                  value={this.requestWizardStore.state.item.instructor}
                  onChange={instructor => {
                    this.handleValue(nameof<RequestWizardData>('instructor'), instructor);
                    !instructor
                      ? this.requestWizardStore.clear()
                      : this.handleValue(nameof<RequestWizardData>('instructorLocation'), instructor.location);
                  }}
                  activeOnly={true}
                  onlyInstructorsPlannerTFT={this.isPlannerTFT()}
                  onlyInstructorsPlannerMTC={this.isPlannerMTC()}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default withTranslation()(StepInstructors);
