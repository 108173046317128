import * as autobind from 'autobind';
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Input, Header } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference, DataModel } from 'stores/dataStore';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import FunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import LocationEditor from 'widgets/bussiness/location-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { EventInstructorRow } from 'site/pages/events/event-instructors';
import { UserStore } from 'stores/users/users-store';
import './choose-instructors-form.less';
import { PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';

interface ChooseInstructorsWithGhostsViewProps extends WithTranslation {
  onCloseModal?: () => void;
  alreadySelected?: EventInstructorRow[];
  instructorExpertisesStore?: InstructorExpertiseStore;
  onSelectInstructors?: (selectedInstructors: InstructorExpertiseDto[]) => void;
  users?: UserStore;
  onlyInstructorsPlannerTFT?: boolean;
  onlyInstructorsPlannerMTC?: boolean;
}

interface ChooseInstructorsFilters {
  instructor: string;
  eventType: string;
  machineModel: string;
  role: string;
  training: string;
  subarea: string;
  location: string;
}

interface ChooseInstructorsWithGhostsViewState {
  query: Query;
  instructorExpertises: InstructorExpertiseDto[];
  selectedInstructors: InstructorExpertiseDto[];
  filters: ChooseInstructorsFilters;
  filteredExpertises: DataModel<InstructorExpertiseDto>;
}

@connect(['instructorExpertisesStore', InstructorExpertiseStore], ['users', UserStore])
class ChooseInstructorsWithGhostsView extends PureComponent<ChooseInstructorsWithGhostsViewProps, ChooseInstructorsWithGhostsViewState> {
  timer: any = null;

  constructor(props: ChooseInstructorsWithGhostsViewProps) {
    super(props);

    this.state = {
      selectedInstructors: [],
      instructorExpertises: [],
      filters: {
        instructor: undefined,
        eventType: undefined,
        machineModel: undefined,
        role: undefined,
        training: undefined,
        subarea: undefined,
        location: undefined
      },
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 100000
      },
      filteredExpertises: undefined
    };
  }

  private get instructorsExpertiseStore() {
    return this.props.instructorExpertisesStore;
  }

  componentDidMount = () => {
    this.load();
  };

  @autobind
  private onAddParticipants() {
    if (this.props.onSelectInstructors) this.props.onSelectInstructors(this.state.selectedInstructors);

    if (this.props.onCloseModal) this.props.onCloseModal();
  }

  @autobind
  private onCancel() {
    if (this.props.onCloseModal) this.props.onCloseModal();
  }

  @autobind
  private load() {
    this.instructorsExpertiseStore.getAllWithGhostsAsync(
      this.state.query,
      true,
      this.state.filters.instructor,
      this.state.filters.location
    );
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: { ...this.state.query, skip, take } }, () => this.load());
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    const query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, () => this.load());
  }

  @autobind
  private handleOnInstructorNameFilterChanged = (e, { value }) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let filters = { ...this.state.filters };
      filters.instructor = value;
      this.refreshTable(filters);
    }, 1000);
  };

  private handleFilterByMachineModel(machineModel: ItemReference) {
    let filters = { ...this.state.filters };
    filters.machineModel = machineModel ? machineModel.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByEventType(eventType: ItemReference) {
    let filters = { ...this.state.filters };
    filters.eventType = eventType ? eventType.id : null;
    this.refreshTable(filters);
  }
  private handleFilterByTraining(training: ItemReference) {
    let filters = { ...this.state.filters };
    filters.training = training ? training.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByRole(role: ItemReference) {
    let filters = { ...this.state.filters };
    filters.role = role ? role.id : null;
    this.refreshTable(filters);
  }
  private handleFunctionalSubArea(funcionalsubArea: ItemReference) {
    let filters = { ...this.state.filters };
    filters.subarea = funcionalsubArea ? funcionalsubArea.id : null;
    this.refreshTable(filters);
  }

  private handleFilterByLocation(location: ItemReference) {
    let filters = { ...this.state.filters };
    filters.location = location ? location.id : null;
    this.refreshTable(filters);
  }

  private refreshTable(filters: ChooseInstructorsFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters: ChooseInstructorsFilters) {
    // Pending Gino
    // if (filters.instructor) {
    //   filterExpertises = filterExpertises.filter(x => x.instructor.includes(filters.instructor));
    // }

    // Pending Gino
    // if (filters.location) {
    //   filterExpertises = filterExpertises.filter(x =>
    //     x.user.roles.any(r => (r.location ? r.location.id : '') === filters.location && (r.role ? r.role.name : '') === 'Instructor')
    //   );
    // }

    let oDataFilter = [];

    if (filters.eventType) {
      oDataFilter.push({ EventTypeId: { eq: { type: 'guid', value: filters.eventType } } });
    }

    if (filters.role) {
      oDataFilter.push({ RoleId: { eq: { type: 'guid', value: filters.role } } });
    }

    if (filters.training) {
      oDataFilter.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.training } } });
    }

    if (filters.subarea) {
      oDataFilter.push({ NMRFunctionalSubAreaId: { eq: { type: 'guid', value: filters.subarea } } });
    }

    if (filters.machineModel) {
      oDataFilter.push({ MachineModelId: { eq: { type: 'guid', value: filters.machineModel } } });
    }

    return oDataFilter;
  }

  public render() {
    const { t } = this.props as any;

    let isRowDisabled: (item: InstructorExpertiseDto) => boolean = undefined;

    if (!!this.props.onlyInstructorsPlannerTFT || !!this.props.onlyInstructorsPlannerMTC) {
      if (this.props.onlyInstructorsPlannerTFT) {
        isRowDisabled = item =>
          !(item.user.pillar && (item.user.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !item.user.pillar.managedBy));
      }

      if (this.props.onlyInstructorsPlannerMTC) {
        isRowDisabled = item =>
          !(item.user.pillar && (item.user.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !item.user.pillar.managedBy));
      }
    }

    const tableModel = {
      columns: [
        {
          title: t('Instructor'),
          tooltipRenderer: true,
          renderer: data => data.instructor,
          selectableHeader: false
        },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data =>
            data.user.roles.find(({ role }) => role.name === 'Instructor')
              ? data.user.roles.find(({ role }) => role.name === 'Instructor')?.location?.location
              : '',

          selectableHeader: false
        }
      ],
      data: this.props.instructorExpertisesStore.state
    } as TableModel<InstructorExpertiseDto>;

    return (
      <Modal
        className="modal-transparent choose-instructors__modal"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        onCloseUsersModal={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="search__modal__header">
          <Header as="h2" className="modal-header-title">
            {t('Instructor Search')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="flex-start-center flex-wrap">
            <Input
              className="choose-instructors__modal__dropdown"
              icon="search"
              placeholder={t("Instructor's Name")}
              onChange={this.handleOnInstructorNameFilterChanged}
            />

            <LocationEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              placeholder={t('Location')}
              onChange={eventType => this.handleFilterByLocation(eventType)}
              clearable
            />

            <EventTypeEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              placeholder={t('Event Type')}
              onChange={eventType => this.handleFilterByEventType(eventType)}
              clearable
            />

            <MachineModelEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              cascade="true"
              placeholder={t('Machine Model')}
              onChange={machineModel => this.handleFilterByMachineModel(machineModel)}
              clearable
            />

            <ProfessionEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              placeholder={t('Role')}
              onChange={role => this.handleFilterByRole(role)}
              clearable
            />

            <TrainingLevelEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              placeholder={t('Training Level')}
              onChange={training => this.handleFilterByTraining(training)}
              clearable
            />

            <FunctionalSubAreaEditor
              className="choose-instructors__modal__dropdown"
              nullable
              value={null}
              placeholder={t('Functional Subarea')}
              onChange={subarea => this.handleFunctionalSubArea(subarea)}
              clearable
            />
          </div>

          <Container className="student-list-table choose-instructor-form__table-wrapper">
            <TableView
              isRowDisabled={isRowDisabled}
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              selectable={true}
              onSelectionChange={users => this.selectedInsctructor(users)}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-participants-button" onClick={this.onAddParticipants} positive>
            {t('Add Instructor')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  selectedInsctructor(items: unknown[]): void {
    this.setState({ selectedInstructors: items as any });
  }

  renderSelectedIcon(isSelected: boolean) {
    if (!isSelected) return <></>;

    return <Icon name="check" color="black" />;
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseInstructorsWithGhostsView);
