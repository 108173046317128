import * as React from 'react';
import './request-popup-filters.less';
import RequestPopupFilters, { RequestPopupFilterValues } from './request-popup-filters';
import { Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faCalendarAlt, faCircle, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestDto, RequestsStore } from 'stores/requests/requests-store';
import { Query } from 'stores/dataStore';
import { Icon, Popup } from 'semantic-ui-react';
import SchedulerRequestItemTooltip from './scheduler-request-item-tooltip';
import RequestItemMenu from './request-item-menu';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { DateTimeService } from 'services/datetime-service';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';
import { GetRoleName } from 'utils/userinfo-functions';
import { RequestFormActions } from 'site/pages/requests/request-form/request-form';
import { SelectionItem } from 'widgets/form/selectionInput';

export interface RequestListProps extends WithTranslation {
  hide?: boolean;
  requests: RequestDto[];
  onFilterValueChanged?: (value: RequestPopupFilterValues) => void;
  onRequestChanged?: (request?: RequestDto, action?: RequestFormActions) => void;
  onScrollEnd?: () => void;
  requestsStore?: RequestsStore;
  updateLoadingState?: (isLoadind: boolean) => void;
  optionsFromRequests?: SelectionItem[];
}

export interface RequestListState {
  currentQuery: Query;
  hasMoreRequest: boolean;
  localOptions?: SelectionItem[];
}

const SchedulerRequestList: React.FC<RequestListProps> = ({ t, hide, requests, requestsStore, updateLoadingState, ...props }) => {
  const onScrollHandler = event => {
    let { scrollHeight, scrollTop } = event.target;
    if (scrollTop + 800 > scrollHeight) props && props.onScrollEnd();
  };

  const handleOnRequestChanged = (request?: RequestDto, action?: RequestFormActions) => props.onRequestChanged(request, action);

  const loadRequestWithFilter = (value: RequestPopupFilterValues) => {
    props.onFilterValueChanged(value);
  };

  const identityService: IdentityService = container.get(IdentityService);
  const userinfo = identityService.getUserInfo();

  const isPlannerTFT = () => {
    return IdentityService.isPlannerTFT(userinfo);
  };
  const isPlannerMTC = () => {
    return IdentityService.isPlannerMTC(userinfo);
  };

  return (
    <div hidden={hide} className={`request-table${hide ? ' hide' : ''}`}>
      <header className="request-table-header">
        <div className="request-table-title">{t('Request')}</div>
        <div className="request-table-filter">
          <RequestPopupFilters onSubmit={loadRequestWithFilter} optionsFromRequests={props.optionsFromRequests} />
        </div>
      </header>
      {(requests || []).length > 0 ? (
        <ul className="request-table-list" onScroll={onScrollHandler}>
          {requests.map(request => {
            let borderLeftColor = request.eventType.eventsColor;
            let liClass = 'request-table-list-item';

            const requestCreators = request.eventType.rolesThatCreateRequests;
            if (
              request.status.toString() === 'InProgress' ||
              (isPlannerTFT() && !requestCreators?.any(x => GetRoleName(x) === 'Planner TFT')) ||
              (isPlannerMTC() && !requestCreators?.any(x => GetRoleName(x) === 'Planner MTC'))
            ) {
              borderLeftColor += '66';
              liClass += ' draft';
            }
            return (
              <li key={request.id + request?.title} id={request.id} className={liClass} style={{ borderLeftColor }}>
                <Popup
                  on="hover"
                  className="request-table-list-item__tooltip"
                  trigger={
                    <Grid className="request-component__detail-wrapper">
                      <Grid.Row>
                        <b>{request?.friendlyId}</b>
                      </Grid.Row>
                      <Grid.Row>
                        <FontAwesomeIcon style={{ color: request?.eventType.eventsColor }} icon={faCircle} />
                        <b>{request?.eventType.name}</b>
                      </Grid.Row>
                      <Grid.Row>
                        <FontAwesomeIcon icon={faFile} />
                        {request?.title}
                      </Grid.Row>
                      <Grid.Row>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        {DateTimeService.toDateInputString(request?.startDate)}
                      </Grid.Row>
                      <Grid.Row>
                        {request?.requestLocation?.location && request?.requestLocation?.countryName && (
                          <>
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                            {request.requestLocation.location}, {request.requestLocation.countryName}
                          </>
                        )}
                      </Grid.Row>
                    </Grid>
                  }
                  mouseLeaveDelay={1}
                  mouseEnterDelay={1}
                  basic
                  style={{ backgroundColor: '#474b4f', color: 'white', border: 'none!important', fontSize: '1.15rem', zIndex: 999 }}
                  inverted
                  content={<SchedulerRequestItemTooltip request={request} key={request.id} />}
                />
                <div className="request-component__options">
                  <RequestItemMenu
                    request={request}
                    onRequestChanged={handleOnRequestChanged}
                    requestsStore={requestsStore}
                    updateLoadingState={updateLoadingState}
                  />
                  {request?.eventType?.eventsIcon && (
                    <div className="request-component__options-icon">
                      <Icon name={request?.eventType?.eventsIcon as SemanticICONS} />
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <span style={{ alignSelf: 'center' }}>{t('No records to show')}</span>
      )}
    </div>
  );
};

SchedulerRequestList.defaultProps = { onFilterValueChanged: () => {}, onRequestChanged: () => {}, onScrollEnd: () => {}, hide: false };

export default withTranslation()(React.memo(SchedulerRequestList));
