import {
  ChangeRestrictionWarningStore,
  DownloadRestrictionWarningStore,
  NewRestrictionWarningStore,
  RestrictionWarningsStore,
  UploadRestrictionWarningStore
} from './restrictions-warnings-store';
import { ChangeVisaStore, DropDownVisasStore, NewVisaStore, VisaStore } from './visas-store';
import { ContractStore } from './contracts-store';
import { FlightCostStore } from './flight-costs-store';
import {
  ChangeInstructorVisaRestrictionStore,
  DownloadInstructorVisaRestrictionStore,
  InstructorVisaRestrictionStore,
  UploadInstructorVisaRestrictionStore
} from './instructor-visa-restriction-store';
import { PlannerAssistantStore } from './planner-assistant-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new FlightCostStore());
  storeBuilder.addRepository(new ContractStore());
  storeBuilder.addRepository(new VisaStore());
  storeBuilder.addRepository(new NewVisaStore());
  storeBuilder.addRepository(new ChangeVisaStore());
  storeBuilder.addRepository(new DropDownVisasStore());
  storeBuilder.addRepository(new RestrictionWarningsStore());
  storeBuilder.addRepository(new NewRestrictionWarningStore());
  storeBuilder.addRepository(new ChangeRestrictionWarningStore());
  storeBuilder.addRepository(new InstructorVisaRestrictionStore());
  storeBuilder.addRepository(new ChangeInstructorVisaRestrictionStore());
  storeBuilder.addRepository(new PlannerAssistantStore());
  storeBuilder.addRepository(new DownloadRestrictionWarningStore());
  storeBuilder.addRepository(new UploadRestrictionWarningStore());
  storeBuilder.addRepository(new DownloadInstructorVisaRestrictionStore());
  storeBuilder.addRepository(new UploadInstructorVisaRestrictionStore());
}
