import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  DeliveryMethodSfMapDto,
  ChangeDeliveryMethodSfMapDto,
  ChangeDeliveryMethodSfMapStore
} from 'stores/configuration/sf-mappings/delivery-method-sf-map-store';
import { nameof } from 'utils/object';

interface ChangeDeliveryMethodMapViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  deliveryMethodMapsStore?: ChangeDeliveryMethodSfMapStore;
  currentDto: DeliveryMethodSfMapDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeDeliveryMethodMapViewState {
  userClickedSave: boolean;
  deliveryMethod: any;
}

@connect(['deliveryMethodMapsStore', ChangeDeliveryMethodSfMapStore])
class ChangeDeliveryMethodMapView extends React.Component<ChangeDeliveryMethodMapViewProps, ChangeDeliveryMethodMapViewState> {
  private get changeDeliveryMethodMapStore() {
    return this.props.deliveryMethodMapsStore;
  }

  constructor(props: ChangeDeliveryMethodMapViewProps) {
    super(props);

    this.changeDeliveryMethodMapStore.state.result = null;

    this.changeDeliveryMethodMapStore.change({
      id: this.props.currentDto.id,
      deliveryTypeId: this.props.currentDto.deliveryTypeId,
      mappingToSF: this.props.currentDto.mappingToSF,
      deliveryTypeName: this.props.currentDto.deliveryTypeName
    });

    this.state = {
      deliveryMethod: this.props.currentDto.deliveryTypeId
        ? { id: this.props.currentDto.deliveryTypeId, title: this.props.currentDto.deliveryTypeName }
        : null,
      userClickedSave: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeDeliveryMethodMapViewProps) {
    if (this.changeDeliveryMethodMapStore.state.result && this.changeDeliveryMethodMapStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSave: true });
    this.changeDeliveryMethodMapStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeDeliveryMethodMapStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.changeDeliveryMethodMapStore.change({ ...this.changeDeliveryMethodMapStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeDeliveryMethodMapStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Delivery Method Map')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeDeliveryMethodMapStore.state.result && !this.changeDeliveryMethodMapStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeDeliveryMethodMapStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeDeliveryMethodMapStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    className="normal-looking-disabled-input"
                    disabled
                    fluid
                    label={t('Delivery Method Map')}
                    value={this.changeDeliveryMethodMapStore.state.item.deliveryTypeName}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.changeDeliveryMethodMapStore.state.item.mappingToSF && this.state.userClickedSave}
                    label={t('Mapping To SF')}
                    placeholder={t('Mapping To SF')}
                    value={this.changeDeliveryMethodMapStore.state.item.mappingToSF}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeDeliveryMethodSfMapDto>('mappingToSF'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeDeliveryMethodMapView);
