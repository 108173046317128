import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestWizardStore } from 'stores/requests/create-request-wizard-store';
import { connect } from 'redux-scaffolding-ts';
import { isExtendedWorkflow } from 'utils/event-type-utils';
import StudentsAssignTableComponent, { StudentsTabViewModel } from 'site/pages/shared/events-and-requests/tabs/students-assign-component';

export interface StudentsStepProps extends WithTranslation {
  requestWizardStore?: RequestWizardStore;
}

@connect(['requestWizardStore', RequestWizardStore])
class StudentsStep extends Component<StudentsStepProps> {
  componentDidMount() {
    const { studentsNumber, manualStudents, supportDetails, eventType } = this.props.requestWizardStore.state.item;
    let studentsAssigned = studentsNumber;
    if (isExtendedWorkflow(eventType.eventTypeCategory) && !manualStudents) {
      studentsAssigned = (supportDetails.supportPositions || []).reduce((prev, curr) => +curr.requestedHC + prev, 0);
      this.props.requestWizardStore.change({ studentsNumber: studentsAssigned, manualStudents: false });
    }
  }

  private handleOnStudentsChange = (newValue: StudentsTabViewModel) => {
    this.props.requestWizardStore.change({ students: newValue.students, manualStudents: true, studentsNumber: newValue.studentsAssigned });
  };

  render() {
    const { studentsNumber: studentsAssigned, students } = this.props.requestWizardStore.state.item;

    const value: StudentsTabViewModel = { students, studentsAssigned };
    return (
      <StudentsAssignTableComponent
        className="wizard__students-step__container"
        readOnly={false}
        value={value}
        onChange={this.handleOnStudentsChange}
      />
    );
  }
}
export default withTranslation()(StudentsStep);
