import { CountriesStore, NewCountryStore, ChangeCountryStore, DropDownCountriesStore } from './countries-store';
import { LanguagesStore, NewLanguageStore, ChangeLanguageStore, DropdownLanguagesStore } from './languages-store';
import { LocationsStore, NewLocationStore, ChangeLocationStore, DropDownLocationsStore } from './locations-store';
import { PersonnelAreasStore, NewPersonnelAreaStore, ChangePersonnelAreaStore } from './personnelArea-store';
import { RegionsStore, NewRegionStore, ChangeRegionStore } from './regions-store';
import { DropDownTtcLocationsStore } from './ttc-locations-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new CountriesStore());
  storeBuilder.addRepository(new NewCountryStore());
  storeBuilder.addRepository(new ChangeCountryStore());
  storeBuilder.addRepository(new DropDownCountriesStore());
  storeBuilder.addRepository(new LanguagesStore());
  storeBuilder.addRepository(new NewLanguageStore());
  storeBuilder.addRepository(new ChangeLanguageStore());
  storeBuilder.addRepository(new LocationsStore());
  storeBuilder.addRepository(new DropDownLocationsStore());
  storeBuilder.addRepository(new NewLocationStore());
  storeBuilder.addRepository(new ChangeLocationStore());
  storeBuilder.addRepository(new PersonnelAreasStore());
  storeBuilder.addRepository(new NewPersonnelAreaStore());
  storeBuilder.addRepository(new ChangePersonnelAreaStore());
  storeBuilder.addRepository(new DropdownLanguagesStore());
  storeBuilder.addRepository(new RegionsStore());
  storeBuilder.addRepository(new NewRegionStore());
  storeBuilder.addRepository(new ChangeRegionStore());
  storeBuilder.addRepository(new DropDownTtcLocationsStore());
}
