import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DevelopmentPlanStatus } from 'stores/development-plan/development-plan-status.enum';

export interface DevelopmentPlanStatusProps extends WithTranslation {
  status: DevelopmentPlanStatus;
  outOfDate: boolean;
}

export class DevelopmentPlanStatusComponent extends Component<DevelopmentPlanStatusProps> {
  private getTextFromStatus = (status: DevelopmentPlanStatus): string => {
    if (status.toString() === 'Completed') return 'Completed';
    if (status.toString() === 'InProgress') return 'In Progress';
    if (status.toString() === 'Planned') return 'Planned';
    return '';
  };

  render() {
    const { t, status, outOfDate } = this.props;
    if (outOfDate) return <span className="question-bank__cell__tag">{t('Out of Date')}</span>;
    return <span className="question-bank__cell__tag">{t(this.getTextFromStatus(status))}</span>;
  }
}

export default withTranslation()(DevelopmentPlanStatusComponent);
