import i18n from 'i18n';
import * as React from 'react';
import { DatesRangeInput } from '@planit/semantic-ui-calendar-react';
import { Button, Divider, Form, Header, Icon, Popup, Segment } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from '../../../utils/useful-functions';
import 'widgets/form/dateInput.less';
import { DateTimeService } from 'services/datetime-service';

interface IDatesFilterProps {
  triggerTitle: string;
  filterTitle: string;
  onFilter: (start: string, end: string) => void;
  onClear: () => void;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  position?: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center';
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[];
  active?: boolean;
  onActivate?: (visible: boolean) => void;
}

interface IDatesFilterState {
  visible: boolean;
  filtered: boolean;
  start: string;
  end: string;
  datesRange: string;
}

export class DatesFilter extends React.Component<IDatesFilterProps, IDatesFilterState> {
  constructor(props: IDatesFilterProps) {
    super(props);
    this.state = {
      visible: false,
      filtered: this.props.active,
      start: null,
      end: null,
      datesRange: ''
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.active !== this.state.filtered) {
      if (next.active) {
        this.setState({ filtered: true });
      } else {
        this.setState({ filtered: false, visible: false, start: null, end: null, datesRange: '' });
        this.props.onActivate && this.props.onActivate(false);
      }
    }
  }

  private handleChange = (event, { value }) => {
    let start;
    let end;
    if (isNullOrWhiteSpaces(value)) {
      start = null;
      end = null;
    } else {
      const split = value.split(' - ').map(d => d.trim());
      start = isNullOrWhiteSpaces(split[0]) ? null : DateTimeService.toString(split[0], null, 'startOfDay');
      end = isNullOrWhiteSpaces(split[1]) ? null : DateTimeService.toString(split[1], null, 'endOfDay');
    }
    this.setState({ datesRange: value, start, end });
  };

  private onFilter = e => {
    e.preventDefault();
    if (this.props.onFilter) {
      this.props.onFilter(this.state.start, this.state.end);
    }
    this.setState({ filtered: true, visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClear = () => {
    if (this.props.onClear) {
      this.props.onClear();
    }
    this.setState({ visible: false, filtered: false, start: null, end: null, datesRange: '' });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClose = () => {
    this.setState({ visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onOpen = e => {
    e.preventDefault();
    this.setState({ visible: true }, () => {
      const node = document.getElementById('dates-filter-field');
      if (node) {
        const table = node.children[0];
        table.className = `${table.className} small`;
      }
    });
    this.props.onActivate && this.props.onActivate(true);
  };

  render() {
    const { filterTitle, triggerTitle, size, position, on } = this.props;
    const { visible, filtered, start, end, datesRange } = this.state;
    return (
      <Popup
        open={visible}
        trigger={
          <a
            href="!#"
            onClick={this.onOpen}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
          >
            {triggerTitle}&nbsp;
            <Icon name="filter" size="small" disabled={!filtered} />
          </a>
        }
        content={
          <Segment size="mini" basic className="dates-filter">
            <Header as="h4">{filterTitle}</Header>
            <Divider />
            <Form size="tiny">
              <Header as="h5">
                <small>{i18n.t('Select dates range')}</small>
              </Header>
              <Form.Group>
                <Form.Field width={14} id="dates-filter-field">
                  <DatesRangeInput
                    // size='large'
                    name="datesRange"
                    dateFormat="DD/MM/YYYY"
                    inline
                    value={datesRange}
                    iconPosition="left"
                    popupNodeClass={'jti-popupNode'}
                    popupNodeId={'jtiDateInput'}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field width={2} className="filter-button-field">
                  <Button size="small" icon="filter" compact onClick={this.onFilter} disabled={start == null || end == null} />
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider />
            <Button size="tiny" compact onClick={this.onClear}>
              <Icon name="times" /> {i18n.t('Clear')}
            </Button>
          </Segment>
        }
        on={on || 'click'}
        position={position || 'bottom left'}
        size={size || 'mini'}
        onClose={this.onClose}
      />
    );
  }
}
