import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { OemDto, OemsStore } from '../../stores/configuration/machinery/oems-store';
import { DropdownProps, SelectionInput, SelectionItem } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces, customEqualCompare } from '../../utils/useful-functions';

export interface OemItemReference extends ItemReference {
  relatedClusterIds: string[];
  relatedEqIds: string[];
}

interface OemEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference | string;
  onChange?: (value: ItemReference | OemItemReference) => void;
  placeholder?: string;
  oems?: OemsStore;
  equipmentId?: string;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  clearOnReload?: boolean;
  className?: string;
  clearable?: boolean;
  readonly?: boolean;
  loadDataOnOpen?: boolean;
  clusterId?: string;
}

interface OemEditorState {
  value: OemItemReference | ItemReference | string;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  readonly?: boolean;
  options: SelectionItem[];
  selectedEquipmentTypeId?: string;
  currentQuery?: QueryResult<OemDto>;
  isAlreadyQuerying: boolean;
  selectedClusterId?: string;
}

@connect(['oems', OemsStore])
class OemEditor extends React.Component<OemEditorProps, OemEditorState> {
  private get oemsStore() {
    return this.props.oems;
  }

  constructor(props: OemEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      searchQuery: null,
      query: this.props.loadDataOnOpen ? null : this.getOemMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value),
      selectedEquipmentTypeId: null,
      currentQuery: null,
      isAlreadyQuerying: false,
      selectedClusterId: null
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange, equipmentId, clusterId } = this.props;

    if (reloadOnChange && !customEqualCompare(prevProps.value, value)) {
      this.setState({
        value,
        searchQuery: null,
        options: getOptionsFromValue(this.state?.query, value)
      });
    }

    if (!customEqualCompare(prevProps.equipmentId, equipmentId) || clusterId !== prevProps.clusterId) {
      this.setState({
        value,
        searchQuery: null,
        query: this.getOemMethod(),
        options: getOptionsFromValue(this.state?.query, value)
      });
    }
  }
  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getOemMethod() });
    }
  };

  @autobind
  private getOemMethod() {
    const method = async (search: string) => {
      if (this.state.isAlreadyQuerying) return;
      this.setState({ isAlreadyQuerying: true });
      const filters = isNullOrWhiteSpaces(search) ? [] : [`contains(tolower(${nameof<OemDto>('name')}), '${search.toLowerCase()}')`];

      if (
        (this.props.clusterId || this.props.equipmentId) &&
        this.state?.currentQuery?.items &&
        this.props.clusterId === this.state.selectedClusterId &&
        this.props.equipmentId === this.state.selectedEquipmentTypeId
      ) {
        this.setState({ isAlreadyQuerying: false });

        return Object.assign({}, this.state.currentQuery, {
          items: this.state.currentQuery.items.map(c => ({
            id: c.id,
            title: c.name,
            relatedClusterIds: c.relatedClusterIds,
            relatedEqIds: c.relatedEqIds
          }))
        }) as QueryResult<OemItemReference>;
      }

      if (this.props.clusterId) {
        filters.push(`${nameof<OemDto>('MachineModels')}/any(c: c/Cluster/Id eq ${this.props.clusterId})`);
        this.setState({ selectedClusterId: this.props.clusterId });
      } else {
        this.setState({ selectedClusterId: undefined });
      }

      if (this.props.equipmentId) {
        filters.push(`${nameof<OemDto>('MachineModels')}/any(c: c/EquipmentType/Id eq ${this.props.equipmentId})`);
        this.setState({ selectedEquipmentTypeId: this.props.equipmentId });
      } else {
        this.setState({ selectedEquipmentTypeId: undefined });
      }

      const result = await this.oemsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<OemDto>('name'), useProfile: false }],
        filter: filters
      });
      this.setState({ currentQuery: this.props.clusterId || this.props.equipmentId ? result : null, isAlreadyQuerying: false });

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.id,
          title: c.name,
          relatedClusterIds: c.relatedClusterIds,
          relatedEqIds: c.relatedEqIds
        }))
      }) as QueryResult<OemItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        content={item => item.title}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        readOnly={this.props.readonly}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
        clearable={this.props.clearable}
        clearOnReload={this.props.clearOnReload}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(OemEditor);
