import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView } from 'widgets/collections/table';

export interface InstructorExpertisesProps extends WithTranslation, RouteComponentProps {
  instructorExpertiseStore?: InstructorExpertiseStore;
  instructorId?: string;
}

export interface InstructorExpertisesState {}

@connect(['instructorExpertiseStore', InstructorExpertiseStore])
class InstructorExpertisesNoMrTable extends React.Component<InstructorExpertisesProps, InstructorExpertisesState> {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      expertisesList: null,
      instructorLoggedOrFounded: true,
      activeIndex: 0
    };
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const { instructorId } = this.props;
    const filter = [{ InstructorId: { eq: { type: 'guid', value: instructorId } } }, { IsMachineRelated: { eq: false } }];

    let queryExpertises = { searchQuery: '', skip: 0, take: 100000, orderBy: [], filter, parameters: { hideDeprecated: 'true' } };

    this.props.instructorExpertiseStore.getAllAsync(queryExpertises);
  };

  private getMRTableModel = () => {
    const { t } = this.props;

    const tableModel = {
      columns: [
        { title: t('Event Type'), renderer: data => data.eventTypeName },
        { title: t('Role'), renderer: data => data.roleName },
        { title: t('Level'), renderer: data => data.trainingLevelName },
        { title: t('Cluster'), renderer: data => data.nmrClusterName },
        { title: t('Functional Area'), renderer: data => data.nmrFunctionalAreaName },
        { title: t('Training Name'), renderer: data => data.nmrTrainingName },
        { title: t('Functional Subarea'), renderer: data => data.nmrFunctionalSubAreaName }
      ],
      data: this.props.instructorExpertiseStore.state
    } as TableModel<InstructorExpertiseDto>;
    return tableModel;
  };

  public render() {
    const nmrTableModel = this.getMRTableModel();
    return (
      <TableView
        onRefresh={this.load}
        model={nmrTableModel}
        canCreateNew={false}
        canEdit={false}
        canDelete={false}
        fixedDimmer
        noFlexContainer
      />
    );
  }
}
export default withTranslation()(InstructorExpertisesNoMrTable);
