import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { FormStore } from 'stores/formStore';
import { ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { SsaActivityTypeDto } from 'stores/assessments/ssa-activity-type-store';
import { SsaSkillDto } from 'stores/assessments/skills/ssa-skills-store';
import { DevelopmentPlanStatus } from './development-plan-status.enum';
import { SimpleUserDto } from 'stores/assessments/forms/tna-forms-store';

export interface UserFilters {
  employee: string;
  locationId: string;
}

export interface SsaDevelopmentPlanDto {
  id: string;
  friendlyId: string;
  profileName: string;
  profileItemId: string;
  userId: string;
  user: SimpleUserDto;
  ssaActivityId: string;
  ssaActivityItem: SsaActivityTypeDto;
  skillId: string;
  ssaSkill: SsaSkillDto;
  deadlineYear: number;
  status: DevelopmentPlanStatus;
  outOfDate: boolean;
  activityDetails: string;
}

@repository('@@SSADEVELOPMENTPLAN', 'ssa-development-plan.summary')
export class SsaDevelopmentPlanStore extends DataStore<SsaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-ssa-development-plans';
  updatePath = '';
  deletePath = 'v1/delete-ssa-development-plan';

  protected validate(item: SsaDevelopmentPlanDto) {
    return undefined;
  }

  constructor() {
    super('SSADEVELOPMENTPLAN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {}
    });
  }
}

export interface SsaDevelopmentPlanGroupingDto {
  locationId: string;
  locationName: string;
  profileItemId: string;
  profileName: string;
  ssaActivityId: string;
  ssaActivityName: string;
  skillId: string;
  skillName: string;
  skillCategoryId: string;
  skillCategoryName: string;
  deadlineYear: number;
  plannedCount: number;
  inProgressCount: number;
  completedCount: number;
  outOfDateCount: number;
}

@repository('@@SSADEVELOPMENTPLAN', 'ssa-development-plan.grouped-summary')
export class SsaDevelopmentPlanGroupingStore extends DataStore<SsaDevelopmentPlanGroupingDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-ssa-development-plans-grouped';
  updatePath = '';
  deletePath = '';

  protected validate(item: SsaDevelopmentPlanGroupingDto) {
    return undefined;
  }

  constructor() {
    super('SSADEVELOPMENTPLANGROUPING', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {}
    });
  }
}

export interface CreateSsaDevelopmentPlanDto {
  profileItemId: string;
  usersId: string[];
  ssaActivityId: string;
  activityDetails: string;
  skillId: string;
  deadlineYear: number;
}

class CreateSsaDevelopmentPlanValidator extends ExtendedAbstractValidator<CreateSsaDevelopmentPlanDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.profileItemId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Profile is required'));

    this.validateIfString(o => o.skillId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Skill is required'));

    this.validateIfString(o => o.ssaActivityId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Activity is required'));

    this.validateIfIterable(o => o.usersId)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Users are required'));

    this.validateIfEachString(o => o.usersId)
      .isDefined()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t("There's a least a bad UserId"));

    this.validateIfNumber(x => x.deadlineYear)
      .isDefined()
      .isNotNull()
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

@repository('@@SSADEVELOPMENTPLAN', 'ssa-development-plan.new')
export class NewSsaDevelopmentPlanStore extends FormStore<CreateSsaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = 'v1/create-ssa-development-plan';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateSsaDevelopmentPlanDto) {
    return new CreateSsaDevelopmentPlanValidator().validate(item);
  }

  constructor() {
    super('NEW_PROFILE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

export interface UpdateSsaDevelopmentPlanDto {
  id: string;
  nextStatus: DevelopmentPlanStatus;
}

class UpdateSsaDevelopmentPlanValiador extends ExtendedAbstractValidator<UpdateSsaDevelopmentPlanDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('SSA DEvelopment Id is required'));

    this.validateIf(o => o.nextStatus)
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(i18n.t('Status is required'));
  }
}

export interface UpdateSsaDevelopmentPlanDto {
  id: string;
  activityId: string;
  activityDetails: string;
  nextStatus: DevelopmentPlanStatus;
}

@repository('@@SSADEVELOPMENTPLAN', 'ssa-development-plan.change')
export class ChangeSsaDevelopmentPlanStore extends FormStore<UpdateSsaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = '';
  updatePath = 'v1/update-ssa-development-plan';

  protected validate(item: UpdateSsaDevelopmentPlanDto) {
    return new UpdateSsaDevelopmentPlanValiador().validate(item);
  }

  constructor() {
    super('CHANGE_PROFILE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
