import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult, QueryParameters } from '../../stores/dataStore';
import { SelectionInput, DropdownProps } from '../../widgets/form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';
import { DropDownEventTypesStore, EventTypeDto } from '../../stores/configuration/events-workflow/event-types-store';

interface EventTypeEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange?: (value: ItemReference) => void;
  eventTypes?: DropDownEventTypesStore;
  directOnly?: boolean;
  filterDirectEventCreator?: boolean;
  filterRequestCreator?: boolean;
  filterEventTypesIds?: string[];
  clearable?: boolean;
  error?: boolean;
  useOriginalEventTypeIdAsValue?: boolean;
  reloadOnChange?: boolean;
}

interface EventTypeEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['eventTypes', DropDownEventTypesStore])
class EventTypeEditor extends React.Component<EventTypeEditorProps, EventTypeEditorState> {
  private get eventTypesStore() {
    return this.props.eventTypes;
  }

  constructor(props: EventTypeEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.getEventTypeMethod()
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.value !== this.props.value && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        query: this.getEventTypeMethod()
      });
    } else if (next.filterEventTypesIds !== this.props.filterEventTypesIds) {
      this.setState(
        {
          value: null,
          query: this.getEventTypeMethod()
        },
        () => {
          if (this.props.onChange) this.props.onChange(this.state.value);
        }
      );
    }
  }

  @autobind
  private getEventTypeMethod() {
    const method = async (search: string) => {
      const filter = [];
      let result: QueryResult<EventTypeDto> = null;

      if (!isNullOrWhiteSpaces(search)) {
        filter.push(`startswith(tolower(${nameof<EventTypeDto>('name')}), '${search.toLowerCase()}')`);
      }
      if (this.props.directOnly) {
        filter.push({ DirectEventsAllowed: true });
      }
      filter.push({ Active: true });
      const parameters: QueryParameters =
        !!this.props.filterDirectEventCreator || !!this.props.filterRequestCreator ? ({} as QueryParameters) : undefined;

      if (!!this.props.filterDirectEventCreator) parameters['filterDirectEventCreator'] = 'true';

      if (!!this.props.filterRequestCreator) parameters['filterRequestCreator'] = 'true';

      if (!!this.props.filterEventTypesIds && this.props.filterEventTypesIds.length > 0) {
        let filterById = '';
        this.props.filterEventTypesIds.forEach((eventTypeId, index) => {
          if (index > 0) {
            filterById = `${filterById} Or OriginalEventTypeId eq ${eventTypeId}`;
          } else {
            filterById = `OriginalEventTypeId eq ${eventTypeId}`;
          }
        });
        filter.push(`(${filterById})`);
      } else if (!!this.props.filterEventTypesIds && this.props.filterEventTypesIds.length === 0) {
        result = {
          count: 0,
          items: []
        };
      }

      if (result === null) {
        result = await this.eventTypesStore.getAllAsync(
          {
            searchQuery: '',
            skip: 0,
            take: 100000,
            orderBy: [{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }],
            filter,
            parameters
          },
          this.props.filterEventTypesIds,
          search
        );
      }

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          ...c,
          id: !!this.props.useOriginalEventTypeIdAsValue ? c.originalEventTypeId : c.id,
          title: c.name,
          realId: c.id
        }))
      }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;

    return (
      <SelectionInput
        error={this.props.error}
        content={item => <div>{item.title}</div>}
        clearable={this.props.clearable}
        readOnly={this.props.readOnly}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => {
          if (this.props.onChange) this.props.onChange(value as any);
        }}
        className={this.props.className}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EventTypeEditor);
