import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/ssaSkill.less';
import { ChangeSsaSkillDto, ChangeSsaSkillStore } from 'stores/assessments/skills/ssa-skills-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';

interface EditSsaSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeSsaSkill?: ChangeSsaSkillStore;
  currentSsaSkill: ChangeSsaSkillDto;
}

interface EditSsaSkillViewState {
  category: ItemReference;
}

@connect(['changeSsaSkill', ChangeSsaSkillStore])
class EditSsaSkillView extends React.Component<EditSsaSkillViewProps, EditSsaSkillViewState> {
  private get changeSsaSkillStore() {
    return this.props.changeSsaSkill;
  }

  constructor(props: EditSsaSkillViewProps) {
    super(props);
    this.changeSsaSkillStore.state.result = null;
    this.changeSsaSkillStore.change({
      id: this.props.currentSsaSkill.id,
      testCategoryId: this.props.currentSsaSkill.testCategoryId,
      description: this.props.currentSsaSkill.description,
      name: this.props.currentSsaSkill.name
    });
    this.state = {
      category: { id: this.props.currentSsaSkill.testCategoryId, title: '' }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: EditSsaSkillViewProps) {
    if (this.changeSsaSkillStore.state.result && this.changeSsaSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeSsaSkillStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeSsaSkillStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeSsaSkillStore.change({ ...this.changeSsaSkillStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    return (
      <Modal className="ssa-skill__modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeSsaSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="ssa-skill__modal-header">{t('Edit SSA Skill')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeSsaSkillStore.state.result && !this.changeSsaSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeSsaSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeSsaSkillStore.state.item && (
              <Form>
                <Form.Group className="ssa-skill__selctor-group">
                  <Form.Field required className="ssa-skill__label-input">
                    <label>{t('Category')}</label>
                    <TestCategorySelector
                      nullable
                      isTnaCategory={false}
                      className="planit-user-dropdown-custom"
                      placeholder={t('Select Category')}
                      value={this.state.category}
                      onChange={category => this.handleValue(nameof<ChangeSsaSkillDto>('testCategoryId'), category?.id)}
                    />
                  </Form.Field>
                </Form.Group>
                <div className="ssa-skill__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.changeSsaSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea"
                        value={this.changeSsaSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeSsaSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.changeSsaSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        className="ssa-skill__custom-editorequest__textarea longArea"
                        value={this.changeSsaSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeSsaSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-ssa-skill__btns">
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EditSsaSkillView);
