import React from 'react';
import './notifications.less';
import { Icon } from 'semantic-ui-react';

interface NotificationsTypeIconProps {
  notificationType?: string;
}

class NotificationsTypeIcon extends React.Component<NotificationsTypeIconProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  printNotificationIcon = () => {
    const { notificationType } = this.props;

    if (notificationType === 'SystemInfo') {
      return 'info';
    } else if (notificationType === 'EventChatMessage' || notificationType === 'RequestChatMessage') {
      return 'comments';
    } else if (notificationType === 'RequestChanges' || notificationType === 'EventChanges') {
      return 'star';
    }
  };

  render() {
    return (
      <React.Fragment>
        <Icon name={this.printNotificationIcon()} />
      </React.Fragment>
    );
  }
}

export default NotificationsTypeIcon;
