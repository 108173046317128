import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult, Query } from '../../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { ValidationResult, AbstractValidator } from 'fluent-ts-validator';
import { SimpleUserDto } from './tna-forms-store';
import { FormStore } from 'stores/formStore';
import i18n from 'i18n';
import moment from 'moment';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DateTimeService } from 'services/datetime-service';

export interface TnaTheoreticalTestItemDto {
  id: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  startedAt?: string;
  finishedAt?: string;
  templateId: string;
  createOn: string;
  tnaDetails: TheoreticalFormTnaDetailsDto;
}

export interface TheoreticalFormTnaDetailsDto {
  title: string;
  profileId: string;
  profileName: string;
  professionId: string;
  professionName: string;
  deadline: string; //Date
  outOfDate: boolean;
  clusterId: string;
  clusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  machineModelId: string;
  machineModelName: string;
}

export interface CreateTnaTheoreticalFormDto {
  templateId: string;
  typeRelated: 'TNA';
  tnaDetails: {
    title: string;
    profileId: string;
    deadline: string;
    users: string[];
  };
}

export class CreateTnaTheoreticalFormValidator extends AbstractValidator<CreateTnaTheoreticalFormDto> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIf(x => x.typeRelated)
      .isDefined()
      .isNotNull()
      .isEqualTo('TNA')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Invalid data provided'));

    this.validateIf(x => x.tnaDetails)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Invalid data provided'));

    this.validateIfString(o => o.tnaDetails.title)
      .isNotEmpty()
      .when(x => x != null && x.tnaDetails != null)
      .withFailureMessage(i18n.t('Test title is required'));

    this.validateIfString(x => x.tnaDetails.profileId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.tnaDetails != null)
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfString(x => x.tnaDetails.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && x.tnaDetails != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.tnaDetails.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && x.tnaDetails != null && !isNullOrWhiteSpaces(x.tnaDetails.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIfString(x => x.tnaDetails.deadline)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(
        x => x != null && x.tnaDetails != null && !isNullOrWhiteSpaces(x.tnaDetails.deadline) && moment(x.tnaDetails.deadline).isValid()
      )
      .withFailureMessage(i18n.t('Deadline date cant be in the past'));

    this.validateIfString(x => x.templateId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Template is required'));

    this.validateIfIterable(x => x.tnaDetails.users)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null && x.tnaDetails != null)
      .withFailureMessage(i18n.t('Employees are required'));

    this.validateIfEachString(x => x.tnaDetails.users)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.tnaDetails != null && (x.tnaDetails.users || []).length !== 0)
      .withFailureMessage(i18n.t('At leat one employee is wrong'));
  }
}

@repository('@@TNA_THEORETICAL_TEST_LIST', 'tnaTheoreticalTests.summary')
export class TnaTheoreticalTestListStore extends DataStore<TnaTheoreticalTestItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-theoretical-forms';
  updatePath = '';
  deletePath = 'delete-theoretical-form';
  retrieveOnePath = 'get-tna-theoretical-forms';

  protected validate(item: TnaTheoreticalTestItemDto) {
    return new ValidationResult();
  }

  getTheoreticalsByFormId = async (query: Query, id: string): Promise<QueryResult<TnaTheoreticalTestItemDto>> => {
    const { path } = DataStore.getRequestParts(query);

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<QueryResult<TnaTheoreticalTestItemDto>>(`${this.baseUrl}/${this.retrieveOnePath}/${id}?${path}`)
    );
    return result.data;
  };

  constructor() {
    super('TABLE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {},
      item: {}
    });
  }
}

@repository('@@TNA_THEORETICAL_TEST_WIZARD', 'tnaTheoreticalTests.new')
export class NewTnaTheoreticalTestStore extends FormStore<CreateTnaTheoreticalFormDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-form';
  retrievePath = '';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = '';

  protected validate(item: CreateTnaTheoreticalFormDto) {
    return new CreateTnaTheoreticalFormValidator().validate(item);
  }

  constructor() {
    super('WIZARD', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
