import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import { ReduxRepository } from 'redux-scaffolding-ts';
import { CommandModel, Message, CommandResult } from './types';
import * as buildQuery from 'odata-query';
import { ValidationResult } from 'fluent-ts-validator';
// import { createPatch } from 'rfc6902';

export interface DefaultMetadata {
  modifiedOn: Date;
  modifiedById: string;
  modifiedByName: string;
}

export type SortDirection = 'Ascending' | 'Descending';

export interface OrderDefinition {
  field: string;
  direction: SortDirection;
  useProfile: boolean;
}

export interface QueryParameters {
  [key: string]: string | string[] | number | number[] | undefined;
}

export interface SortProfile {
  profile: string;
  direction: SortDirection;
}

export interface Query {
  searchQuery: string;
  orderBy?: OrderDefinition[];
  skip: number;
  take: number;
  parameters?: QueryParameters;
  filter?: object;
  toBody?: boolean;
  select?: string;
}

export interface QueryResult<T> {
  count: number;
  items: T[];
}

export interface QueryActions {
  load: (query: Query) => void;
}

export interface QueryState<T> {
  isBusy: boolean;
  data: QueryResult<T>;
}

export interface ItemIdentity {
  id: string;
}

export interface ItemReference extends ItemIdentity {
  title: string;
}

export interface ItemCommandResult {
  isSuccess: boolean;
  messages: Message[];
  item: ItemReference;
}

export type ItemState = 'Unchanged' | 'New' | 'Changed';

export interface ItemModel<T> {
  state: ItemState;
  isBusy: boolean;
  item: T;
  result: ItemCommandResult | undefined;
}

export interface DataModel<T> extends CommandModel<T> {
  items: ItemModel<T>[];
  item?: ItemModel<T> | any;
  itemById?: ItemModel<T>;
  count: number;
  discard: (item: T) => void;
  [key: string]: any;
}
export function getOptionsFromValue(query: any, value: string | ItemReference) {
  const objVal = value as ItemReference;
  const validObj = objVal != null && objVal.id && objVal.title;
  const options = !query && validObj ? [{ key: objVal.id, text: objVal.title, value: objVal.id }] : [];
  return options;
}

export abstract class DataStore<T extends any> extends ReduxRepository<DataModel<T>> {
  public ENTITY_LIST_UPDATE: string | undefined = undefined;
  public ENTITY_DELETED: string | undefined = undefined;
  public ENTITY_UPDATED: string | undefined = undefined;
  public ENTITY_CREATED: string | undefined = undefined;
  public ENTITY_VALIDATED: string | undefined = undefined;
  public CLEAR_MESSAGES: string | undefined = undefined;
  public CHANGE_MESSAGES: string | undefined = 'CHANGE_MESSAGES';

  protected abstract get baseUrl(): string;
  protected abstract get createPath(): string;
  protected abstract get retrievePath(): string;
  protected abstract get updatePath(): string;
  protected abstract get deletePath(): string;

  protected abstract validate(item: T): ValidationResult;

  protected get rowKey(): string {
    return 'id';
  }

  public get isDirty() {
    if (!this.state.items || this.state.items.length === 0) return false;
    return this.state.items.any(o => o.state !== 'Unchanged');
  }

  constructor(entityName: string, initialState: DataModel<T>) {
    super(initialState);
    this.ENTITY_LIST_UPDATE = `${entityName}_LIST_UPDATE`;
    this.ENTITY_DELETED = `${entityName}_DELETED`;
    this.ENTITY_CREATED = `${entityName}_CREATED`;
    this.ENTITY_UPDATED = `${entityName}_UPDATED`;
    this.ENTITY_VALIDATED = `${entityName}_VALIDATED`;
    this.CLEAR_MESSAGES = `${entityName}_CLEAR_MESSAGES`;

    //this.addReducer(this.ENTITY_LIST_UPDATE, (): AsyncAction<AxiosResponse<QueryResult<T>>, DataModel<T>> =>
    this.addReducer(
      this.ENTITY_LIST_UPDATE,
      (): any => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => {
            return {
              ...this.state,
              isBusy: false,
              count: value.data.count,
              result: undefined,
              items: (value.data.items || []).map(
                (item: any) =>
                  ({
                    state: 'Unchanged',
                    isBusy: false,
                    item,
                    result: undefined
                  } as ItemModel<T>)
              )
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );
    this.addReducer(
      this.ENTITY_CREATED,
      (): any => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => {
            return {
              ...this.state,
              isBusy: false,
              count: this.state.count + 1,
              result: undefined,
              items: [
                {
                  isBusy: false,
                  item: value,
                  result: undefined,
                  state: 'New'
                }
              ].concat((this.state.items as any) || [])
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: this.state.items,
                    count: this.state.count,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );
    this.addReducer(
      this.ENTITY_UPDATED,
      (): any => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (result: any, item: any) => {
            return {
              ...this.state,
              items: this.state.items.map(o => {
                if (o.item[this.rowKey] === item[this.rowKey]) {
                  o.state = 'Unchanged';
                  o.item = Object.assign(o.item, result.data.item || item);
                }
                return o;
              }),
              isBusy: false
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: this.state.items,
                    count: this.state.count,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );
    this.addReducer(
      this.ENTITY_DELETED,
      (): any => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any, id: string) => {
            return {
              ...this.state,
              isBusy: false,
              count: this.state.count - 1,
              result: undefined,
              items: this.state.items.filter(o => o.item[this.rowKey] !== id)
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.ENTITY_VALIDATED,
      (result: ValidationResult): DataModel<T> => ({
        ...this.state,
        result: {
          isSuccess: false,
          items: [],
          messages: result.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
        }
      }),
      'Simple'
    );

    this.addReducer(
      this.CLEAR_MESSAGES,
      (): DataModel<T> => ({
        ...this.state,
        result: this.state.result == null ? this.state.result : { ...this.state.result, messages: [] }
      }),
      'Simple'
    );
    this.addReducer(this.CHANGE_MESSAGES, this.msgsChangeHandler, 'Simple');
  }

  public changeMessages = (messages: Message[], type: 'ADD' | 'REPLACE') => {
    this.dispatch(this.CHANGE_MESSAGES, messages, type);
  };

  public msgsChangeHandler = (messages: Message[], type: 'ADD' | 'REPLACE'): DataModel<T> => ({
    ...this.state,
    result: this.state.result
      ? {
          ...this.state.result,
          messages:
            type === 'ADD'
              ? [...(this.state.result.messages || []), ...messages]
              : type === 'REPLACE'
              ? [...messages]
              : this.state.result.messages
        }
      : { items: [], isSuccess: false, messages }
  });

  public clearMessages = () => {
    this.dispatch(this.CLEAR_MESSAGES);
  };

  private static isURLEncoded(s) {
    try {
      return decodeURIComponent(s) !== s;
    } catch (e) {
      return false;
    }
  }

  private static escapeIllegalChars(s) {
    if (DataStore.isURLEncoded(s)) return s;
    s = s.replace(/%/g, '%25');
    s = s.replace(/\+/g, '%2B');
    // s = s.replace(/\//g, '%2F');
    s = s.replace(/\?/g, '%3F');
    s = s.replace(/#/g, '%23');
    s = s.replace(/&/g, '%26');
    // s = s.replace(/'/g, "''");
    return s;
  }

  public static buildUrl(query: Query) {
    const parts = [];
    if (query.searchQuery) {
      parts.push(`$search=${query.searchQuery}`);
    }
    let skip = query.skip || 0;
    skip = skip < 0 ? 0 : skip;

    let top = query.take || 10;
    top = top < 0 ? 10 : top;

    const oDataQuery = { skip, top } as any;

    if (query.orderBy && query.orderBy.length > 0) {
      const sortProfile = query.orderBy.filter(o => o.useProfile);
      if (sortProfile.length > 0) {
        parts.push(`sortProfile=${sortProfile[0].field} ${sortProfile[0].direction}`);
      } else {
        const order = [];
        for (let i = 0; i < query.orderBy.length; i++) {
          let direction = query.orderBy[i].direction === 'Ascending' ? 'asc' : 'desc';
          order.push(`${query.orderBy[i].field} ${direction}`);
        }
        oDataQuery['orderBy'] = order;
      }
    }
    if (query.filter) {
      if (query.filter instanceof Array) {
        const filt = query.filter.map(f => {
          if (f instanceof String || typeof f === 'string') {
            return DataStore.escapeIllegalChars(f);
          }
          return f;
        });
        oDataQuery['filter'] = filt;
      } else {
        oDataQuery['filter'] = query.filter;
      }
    }

    if (query.select) {
      oDataQuery['select'] = query.select;
    }

    parts.push(buildQuery.default(oDataQuery).substr(1));

    if (query.parameters) {
      for (let prop in query.parameters as any) {
        if (query.parameters[prop] && query.parameters[prop]!.constructor === Array) {
          for (let idx = 0; idx < (query.parameters[prop] as any)!.length; idx++) {
            if ((query.parameters[prop] as any)![idx]) parts.push(`${prop}=${encodeURIComponent((query.parameters[prop] as any)![idx])}`);
          }
        } else {
          if (query.parameters[prop]) parts.push(`${prop}=${encodeURIComponent(query.parameters[prop] as string)}`);
        }
      }
    }
    return parts.join('&');
  }

  public static hasFilterOrParameters(query: Query): boolean {
    if (query.parameters) return true;

    if (query.filter && query.filter instanceof Array) {
      if (query.filter.length > 2) return true;
      if (query.filter.length === 2 && (!this.isActiveFilter(query.filter[0]) || !this.isDisabledFilter(query.filter[1]))) return true;
      if (query.filter.length === 1 && !this.isActiveFilter(query.filter[0])) return true;
    }

    return false;
  }

  private static isActiveFilter(filter: any): boolean {
    if ((filter instanceof String || typeof filter === 'string') && (filter as string).toLowerCase().includes('active')) return true;
    if (filter?.Active !== null) return true;
    else return false;
  }

  private static isDisabledFilter(filter: any): boolean {
    if ((filter instanceof String || typeof filter === 'string') && (filter as string).toLowerCase().includes('disable')) return true;
    if (filter?.Disabled !== null) return true;
    else return false;
  }

  public static getRequestParts(query: Query): { path: string; body: any } {
    const parts = [];
    let body = null;

    if (query.searchQuery) {
      parts.push(`$search=${query.searchQuery}`);
    }
    let skip = query.skip || 0;
    skip = skip < 0 ? 0 : skip;

    let top = query.take || 10;
    top = top < 0 ? 10 : top;

    const oDataQuery = { skip, top } as any;

    if (query.orderBy && query.orderBy.length > 0) {
      const sortProfile = query.orderBy.filter(o => o.useProfile);
      if (sortProfile.length > 0) {
        parts.push(`sortProfile=${sortProfile[0].field} ${sortProfile[0].direction}`);
      } else {
        const order = [];
        for (let i = 0; i < query.orderBy.length; i++) {
          let direction = query.orderBy[i].direction === 'Ascending' ? 'asc' : 'desc';
          order.push(`${query.orderBy[i].field} ${direction}`);
        }
        oDataQuery['orderBy'] = order;
      }
    }
    if (query.filter) {
      if (query.filter instanceof Array) {
        const filt = query.filter.map(f => {
          if (f instanceof String || typeof f === 'string') {
            return DataStore.escapeIllegalChars(f);
          }
          return f;
        });
        oDataQuery['filter'] = filt;
      } else {
        oDataQuery['filter'] = query.filter;
      }
    }

    if (query.select) {
      oDataQuery['select'] = query.select;
    }
    const odata = buildQuery.default(oDataQuery).substr(1);

    if (!!query.toBody || odata.length > 1500) {
      body = { oDataQueryString: odata };
    } else {
      parts.push(odata);
    }

    if (query.parameters) {
      for (let prop in query.parameters as any) {
        if (query.parameters[prop] && query.parameters[prop]!.constructor === Array) {
          for (let idx = 0; idx < (query.parameters[prop] as any)!.length; idx++) {
            if ((query.parameters[prop] as any)![idx]) parts.push(`${prop}=${encodeURIComponent((query.parameters[prop] as any)![idx])}`);
          }
        } else {
          if (query.parameters[prop]) parts.push(`${prop}=${encodeURIComponent(query.parameters[prop] as string)}`);
        }
      }
    }
    return { path: parts.join('&'), body };
  }

  public async getAllAsync(query: Query, data?: any): Promise<QueryResult<T>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,

      httpService.get<QueryResult<T>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }

  public async deleteAsync(id: string, params?: any): Promise<CommandResult<T>> {
    const item = this.state.items.firstOrDefault(o => o.item[this.rowKey] === id);
    if (item && item.state === 'New') {
      const data = {
        aggregateRootId: id,
        identifier: id,
        isSuccess: true,
        items: [],
        messages: [],
        title: id
      } as CommandResult<T>;
      const result = ({
        status: 200,
        data: data
      } as unknown) as AxiosResponse<CommandResult<T>>;
      await this.dispatchAsync(this.ENTITY_DELETED, Promise.resolve(result), id);
      return data;
    } else {
      let httpService = container.get<HttpService>(HttpService);
      const res = await this.dispatchAsync(
        this.ENTITY_DELETED,
        httpService.delete<any, CommandResult<T>>(`${this.baseUrl}/${this.deletePath}/${encodeURIComponent(id)}`),
        id
      );
      return res.data;
    }
  }

  public async saveAsync(item: T, state: ItemState) {
    const validationResult = this.validate(item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages: validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    } else {
      const httpService = container.get<HttpService>(HttpService);
      let result: AxiosResponse<any> | undefined;
      if (state === 'New') {
        result = await this.dispatchAsync(this.ENTITY_UPDATED, httpService.post(`${this.baseUrl}/${this.createPath}`, item), item);
      } else {
        result = await this.dispatchAsync(this.ENTITY_UPDATED, httpService.put(`${this.baseUrl}/${this.updatePath}`, item), item);
      }
      if (result) return result.data;
      return null;
    }
  }

  public createAsync(partial: Partial<T>) {
    return this.dispatchAsync(this.ENTITY_CREATED, Promise.resolve(partial), partial);
  }

  // protected patch(actionName: string, path: string, partial: Partial<T>) {
  //     const httpService = container.get<HttpService>(HttpService);
  //     const item = this.state.items.firstOrDefault(o => (o.item as any)[this.rowKey] == path);
  //     if (item) {
  //         return this.dispatchAsync(actionName, httpService.patch(`${this.baseUrl}/${encodeURIComponent(path)}`, createPatch(item, partial)), partial);
  //     }
  //     return this.dispatchAsync(actionName, httpService.put(`${this.baseUrl}/${encodeURIComponent(path)}`, partial), partial);
  // }

  // protected onPatch(key?: string): any {
  //     //protected onPatch(): AsyncAction<AxiosResponse<CommandResult<T>>, FormModel<T>> {
  //     if (!key)
  //         key = 'id';
  //     return {
  //         onStart: (args: any) => ({ ...this.state, isBusy: true }),
  //         onSuccess: (result: any, partial: Partial<T>) => {
  //             const items = (this.state || {} as any).items.map(o => {
  //                 if (o.item[key as string] == partial[key as string]) {
  //                     o.item = Object.assign(o.item, partial);
  //                     return o;
  //                 }
  //                 return o;
  //             });
  //             return {
  //                 ...this.state,
  //                 items: items,
  //                 isBusy: false,
  //                 status: 'Unchanged',
  //                 result: result.data
  //             } as DataModel<T>;
  //         },
  //         onError: (error: any, args: any) => ({
  //             ...this.state,
  //             isBusy: false,
  //             result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : {
  //                 isSuccess: false,
  //                 items: [],
  //                 messages: [{ body: error.message || error, level: 'Error' }]
  //             }
  //         } as DataModel<T>)
  //     };
  // }
}
