import { repository } from 'redux-scaffolding-ts';
import { FormStore, FormModel } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { ItemResult, BaseDto } from 'stores/types';
import { SimpleUserDto } from 'stores/users/users-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { PracticalQuestionDto } from '../questionBank/practical-test-store';
import { DataStore } from 'stores/dataStore';
import { AxiosResponse } from 'axios';
import i18n from 'i18n';
import { IdentityService } from 'services/identity-service';

export interface PracticalFormView {
  id: string;
  header: string;
  practicalFormItemId: string;
  currentLanguage: string;
  role: string;
  user: string;
  templateTitle: string;
  eventTitle: string;
  trainingLevel: string;
  questionAnswers: PracticalQuestionView[];
  readonly: boolean;
  allAnswersCompleted: boolean;
}

export interface PracticalQuestionView {
  questionId: string;
  question: PracticalQuestionDto;
  questionTranslations: PracticalQuestionDto[];
  targetLevel: number;
  timeSpent: number;
  result: ResultPracticalQuestion;
  observations: string;
  answered: boolean;
}

export type ResultPracticalQuestion = 'Unknown' | 'Passed' | 'Failed';

export interface PracticalFormItemDto extends BaseDto {
  id: string;
  friendlyId: string;
  title: string;
  userId: string;
  user: SimpleUserDto;
  templateId: string;
  eventDetails: PracticalFormEventDetailsDto;
  profileId: string;
  profile: ProfileItemDto;
  answerDetails: PracticalFormAnswerDetailsDto;
  allAnswersCompleted: boolean;
}

export interface PracticalFormEventDetailsDto {
  eventId: string;
  friendlyId: string;
  title: string;
}

export interface PracticalFormAnswerDetailsDto {
  answered: boolean;
  totalNumberOfQuestions: number;
  numberOfPassedQuestions: number;
  numberOfFailedQuestions: number;
  totalActualDuration: string;
  totalTargetDuration: string;
}

export interface PracticalFormAnswerItemDto {
  id: string;
  practicalFormItemId: string;
  practicalForm: PracticalFormItemDto;
  templateDetails: PracticalTemplateDetailsDto;
  questionAnswers: PracticalFormQuestionAnswerDto[];
}

export interface PracticalTemplateDetailsDto {
  id: string;
  friendlyId: string;
  title: string;
  header: string;
  trainingLevelId: string;
  trainingLevelName: string;
  professionId: string;
  professionName: string;
}

export interface PracticalFormQuestionAnswerDto {
  questionId: string;
  question: PracticalQuestionDto;
  questionTranslations: PracticalQuestionDto[];
  targetLevel: number;
  targetLevelFormatted: string;
  timeSpent: number;
  timeSpentFormatted: string;
  result: ResultPracticalQuestion;
  observations: string;
  answered: boolean;
}

export interface ChangePracticalFormAnswerItemDto {
  id: string;
  practicalFormItemId: string;
  questionAnswers: ChangePracticalFormQuestionAnswerDto[];
}

export interface ChangePracticalFormQuestionAnswerDto {
  questionId: string;
  timeSpent: number;
  result: ResultPracticalQuestion;
  observations: string;
}

export class PracticalFormViewValidator extends ExtendedAbstractValidator<PracticalFormView> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x.id)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('There should be and form identifier'));

    this.validateIf(x => x.questionAnswers)
      .isNotEmpty()
      .fulfills(questions =>
        (questions || []).every((question, index) => new QuestionAnswerValidator(index, this.addErrors).extendValidate(question).isValid())
      );
  }
}

export class QuestionAnswerValidator extends ExtendedAbstractValidator<PracticalQuestionView> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`For question at position ${idx + 1}`);

    this.validateIfNumber(x => x.timeSpent)
      .isGreaterThan(0)
      .when(x => x.result !== 'Unknown')
      .withFailureMessage(`${preffix}: ${i18n.t('Time spent should be greater than 0')}`);

    this.validateIf(x => x.result)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('There should be a result')}`);
  }
}

@repository('@@PRACTICAL_FORM', 'practical-form-test.summary')
export class PracticalFormListStore extends DataStore<PracticalFormItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-practical-forms';
  retrieveOnePath = 'get-practical-form';
  updatePath = '';
  deletePath = 'delete-practical-form';

  protected validate(item: PracticalFormItemDto) {
    return new ValidationResult();
  }

  constructor() {
    super('PRACTICAL_FORM', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PRACTICAL_FORM_TEST', 'practical-form-test.wizard')
export class PracticalFormWizardStore extends FormStore<PracticalFormView> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-practical-form';
  retrievePath = 'get-practical-forms';
  retrieveAnswersPath = 'get-practical-form-answers';
  updatePath = 'save-practical-form-answers';
  deletePath = '';
  retrieveOnePath = 'get-practical-form';
  MAP_TO_VIEW_MODEL = 'MAP_TO_VIEW_MODEL';

  constructor() {
    super('PRACTICAL_FORM_TEST', { isBusy: false, status: 'New', item: undefined, result: undefined });
    this.addReducer(this.MAP_TO_VIEW_MODEL, this.mapToViewModel, 'AsyncAction');
  }

  public mapToViewModel = () => ({
    onStart: (): FormModel<PracticalFormView> => ({ ...this.state, isBusy: true }),
    onSuccess: ({ data }: AxiosResponse<PracticalFormAnswerItemDto>): FormModel<PracticalFormView> => {
      const { id, questionAnswers: answers, practicalForm, templateDetails, practicalFormItemId } = data;
      const identityService = container.get(IdentityService);
      const userInfo = identityService.getUserInfo();
      const isPoC = IdentityService.isPoc(userInfo);
      const isAdmin = IdentityService.isAdmin(userInfo);
      const isPowerInstructor = IdentityService.isPowerInstructor(userInfo);
      const { eventDetails, user, allAnswersCompleted } = practicalForm;
      const { trainingLevelName, professionName, header, title: templateTitle } = templateDetails;
      const item: PracticalFormView = {
        id,
        practicalFormItemId,
        templateTitle,
        trainingLevel: trainingLevelName,
        user: `${user?.firstName} ${user?.lastName}`,
        eventTitle: `${eventDetails?.friendlyId} - ${eventDetails?.title}`,
        header,
        currentLanguage: null,
        role: professionName,
        questionAnswers: (answers || []).map(({ targetLevelFormatted: _0, timeSpentFormatted: _1, ...rest }) => ({ ...rest })),
        readonly: (!isAdmin && !isPowerInstructor && (answers || []).length > 0 && answers.every(({ answered }) => answered)) || isPoC,
        allAnswersCompleted
      };

      return { ...this.state, isBusy: false, item, status: 'Unchanged', result: undefined };
    },
    onError: (error: any): FormModel<PracticalFormView> => {
      return {
        ...this.state,
        isBusy: false,
        result: error?.response?.data?.messages
          ? error.response.data
          : {
              isSuccess: false,
              items: [],
              count: 0,
              messages: [{ body: error?.message || error, level: 'Error' }]
            }
      };
    }
  });

  protected validate(item: PracticalFormView) {
    return new PracticalFormViewValidator().extendValidate(item as any);
  }

  public async getFormAnswerById(id: string): Promise<PracticalFormAnswerItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.MAP_TO_VIEW_MODEL,
      httpService.get<PracticalFormAnswerItemDto>(`${this.baseUrl}/${this.retrieveAnswersPath}/${id}`)
    );
    return result.data;
  }

  public submitAnswer = async () => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);

    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }

    const newItem = mapToPracticalDto(this.state.item);
    const query = `${this.baseUrl}/${this.updatePath}`;

    const result = await this.dispatchAsync(
      this.updatePath,
      httpService.put<ChangePracticalFormAnswerItemDto, ItemResult<PracticalFormAnswerItemDto>>(query, newItem)
    );
    return result.data;
  };
}

export const mapToPracticalDto = ({ questionAnswers, id, practicalFormItemId }: PracticalFormView): ChangePracticalFormAnswerItemDto => {
  return {
    id,
    practicalFormItemId,
    questionAnswers: (questionAnswers || []).map(({ questionId, result, timeSpent, observations }) => ({
      questionId,
      observations,
      result,
      timeSpent
    }))
  };
};
