import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Checkbox, Input, Message, List, Grid } from 'semantic-ui-react';
import { CheckboxFilter } from '../../../widgets/collections/table-filters/checkbox-filter';

import { ItemState, OrderDefinition, Query } from '../../../stores/dataStore';
import { CommandResult } from '../../../stores/types';
import { UserDto, UserStore } from '../../../stores/users/users-store';
import { nameof } from '../../../utils/object';
import { TableModel, TableView } from '../../../widgets/collections/table';
import { TextBoxFilter } from '../../../widgets/collections/table-filters/textbox-filter';
import { LocationDto, DropDownLocationsStore } from '../../../stores/configuration/locations/locations-store';
import { CountryFlag } from '../../../widgets/bussiness/country-flag';
import MultiLocationEditor from 'widgets/bussiness/multi-location-editor';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { DropDownRolesStore, RoleDto } from 'stores/roles/roles-store';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import { GetRoleName } from 'utils/userinfo-functions';

export interface UsersPerLocationsListProps extends WithTranslation, RouteComponentProps {
  users: UserStore;
  dropdownlocations: DropDownLocationsStore;
  dropdownroles: DropDownRolesStore;
}

export interface UsersPerLocationsListState {
  query: Query;
  newUserShown: boolean;
  changeUserShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  locationsIdentifiers: string[];
  multiLocationIds: string[];
  locationIdentifier: string;
}

@connect(['users', UserStore], ['dropdownlocations', DropDownLocationsStore], ['dropdownroles', DropDownRolesStore])
class UsersPerLocationsListPage extends React.Component<UsersPerLocationsListProps, UsersPerLocationsListState> {
  constructor(props) {
    super(props);
    const currentUserInfo = this.identityService.getUserInfo();
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    this.state = {
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10 },
      newUserShown: false,
      changeUserShown: false,
      selectedItem: null,
      activeFilters: [],
      locationIdentifier: '',
      locationsIdentifiers: [],
      multiLocationIds: []
    };

    if (IdentityService.isPoc(currentUserInfo)) {
      const filter = pocLocations.map(l => `locationId eq ${l}`).join(' or ');

      Object.assign(this.state.query, { filter, skip: 0 });
    }

    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private multiChangeLocationsFilters = (location: string[] = []) => {
    let multiLocationIds: string[] = location;

    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    if (areYouPoc && multiLocationIds.length === 0) multiLocationIds = [...multiLocationIds, ...pocLocations];

    this.setState(({ query }) => ({ query: { ...query, skip: 0 }, multiLocationIds }), this.load);
  };

  private load = (locationIds: any[] = []) => {
    const { query: q, multiLocationIds } = this.state;
    let locations;
    if ((locationIds || []).length > 0) locations = locationIds;
    else if ((multiLocationIds || []).length > 0) locations = multiLocationIds;
    if (locations) {
      let query: Query = { ...q };
      if (q?.parameters?.role === 'Employee') {
        const { role, ...parameters } = q.parameters;
        query = { ...q, parameters };
      }
      this.props.users.getEmployeeLocationsOData(query, locations, null);
    } else this.props.users.getAllAsync(q);
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) });
    this.load();
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  };

  private onSaveRow = async (item: UserDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.users.saveAsync(item, state);
    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async (item: UserDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.users.deleteAsync(item.id, state);
    return { isSuccess: true, items: [], messages: [] };
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.filter(x => x.id !== 'roles').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);
    let parameters = {};
    if (filter.length !== filters.length) {
      parameters = filters.first(x => x.id === 'roles').filter;
    }

    const currentUserInfo = this.identityService.getUserInfo();

    if (IdentityService.isPoc(currentUserInfo)) {
      let filterStr: string;
      let pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

      if (this.state.locationsIdentifiers && this.state.locationsIdentifiers.length > 0) {
        let locationsFilter = '(' + this.state.locationsIdentifiers.map(l => `locationId eq ${l}`).join(' or ') + ')';
        filter.push(locationsFilter);
      } else {
        filterStr = '(' + pocLocations.map(l => `LocationId eq ${l}`).join(' or ') + ')';
        filter.push(filterStr);
      }

      const query = Object.assign(this.state.query, { filter, skip: 0, parameters });
      this.setState({ query, activeFilters }, () => this.load());
    } else if (IdentityService.isAdminOrPlanner(currentUserInfo)) {
      if (this.state.locationsIdentifiers && this.state.locationsIdentifiers.length > 0) {
        let locationsFilter = '(' + this.state.locationsIdentifiers.map(l => `locationId eq ${l}`).join(' or ') + ')';
        filter.push(locationsFilter);
      }
      const query = Object.assign(this.state.query, { filter, skip: 0, parameters });
      this.setState({ query, activeFilters }, () => this.load());
    } else {
      const query = Object.assign(this.state.query, { filter, skip: 0, parameters });
      this.setState({ query, activeFilters }, () => this.load());
    }
  };

  private onNewItem = () => this.setState({ newUserShown: true });

  public render() {
    const { t, users, dropdownroles, dropdownlocations } = this.props;
    const { activeFilters } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    const tableModel = {
      columns: [
        {
          title: t('User ID'),
          renderer: data => <>{data.employeeId}</>,
          selectableHeader: false,
          sortDefinition: { field: nameof<UserDto>('employeeId'), useProfile: false }
        },
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => <>{data.lastName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.lastName}
              fluid
              onChange={(_, { value }) => {
                data.lastName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Last Name')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo))
                  if (this.state.locationIdentifier)
                    onFilter(
                      nameof<UserDto>('lastName'),
                      `contains(tolower(surname), '${value.toLowerCase()}') and locationId eq ${this.state.locationIdentifier}`
                    );
                  else onFilter(nameof<UserDto>('lastName'), `contains(tolower(surname), '${value.toLowerCase()}')`);
                else onFilter(nameof<UserDto>('lastName'), `contains(tolower(surname), '${value.toLowerCase()}')`);
              }}
              onClear={() => onClear(nameof<UserDto>('lastName'))}
              active={activeFilters.includes(nameof<UserDto>('lastName'))}
            />
          ),
          sortDefinition: {
            field: 'surname',
            useProfile: false
          }
        },
        {
          title: t('First Name'),
          tooltipRenderer: true,
          renderer: data => <>{data.firstName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.firstName}
              fluid
              onChange={(_, { value }) => {
                data.firstName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by First Name')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier)
                    onFilter(
                      nameof<UserDto>('firstName'),
                      `contains(tolower(name), '${value.toLowerCase()}') and locationId eq ${this.state.locationIdentifier}`
                    );
                  else onFilter(nameof<UserDto>('firstName'), `contains(tolower(name), '${value.toLowerCase()}')`);
                } else onFilter(nameof<UserDto>('firstName'), `contains(tolower(name), '${value.toLowerCase()}')`);
              }}
              onClear={() => onClear(nameof<UserDto>('firstName'))}
              active={activeFilters.includes(nameof<UserDto>('firstName'))}
            />
          )
        },
        { title: t('User Name'), tooltipRenderer: true, renderer: data => <>{data.userName}</>, selectableHeader: false },
        {
          title: t('Email'),
          tooltipRenderer: true,
          renderer: data => <>{data.email}</>,
          editor: (data, onChange) => (
            <Input
              value={data.email}
              fluid
              onChange={(_, { value }) => {
                data.email = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true
        },
        {
          title: t('Roles'),
          renderer: data => this.getRolesInLocation(data),
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<RoleDto>
              filterTitle={t('Filter by Role')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<UserDto>('roles'), { role: value })}
              onClear={() => onClear(nameof<UserDto>('roles'))}
              active={activeFilters.includes(nameof<UserDto>('roles'))}
              getItems={q => dropdownroles.getAllAsync(q)}
              parameters="id,name"
              orderBy={[{ direction: 'Ascending', field: nameof<RoleDto>('name'), useProfile: false }]}
              filterGenerator={search => (!search ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: RoleDto) => u.name}
              titleSelector={(u: RoleDto) => GetRoleName(u.name)}
            />
          )
        },
        { title: t('Country'), renderer: data => this.getCountryWithFlag(data.location), selectableHeader: true },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data => data?.location?.location || null,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LocationDto>
              filterTitle={t('Filter by Location')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<UserDto>('location'), { locationId: { eq: { type: 'guid', value } } })}
              onClear={() => onClear(nameof<LocationDto>('location'))}
              active={activeFilters.includes(nameof<LocationDto>('location'))}
              getItems={q => dropdownlocations.getAllAsync(q)}
              parameters="id,location"
              orderBy={[{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(location)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: LocationDto) => l.id}
              titleSelector={(l: LocationDto) => l.location}
            />
          )
        },
        {
          title: t('Active'),
          renderer: data => <Checkbox toggle checked={data.enabled} readOnly />,
          editor: (data, onChange) => (
            <Checkbox
              toggle
              checked={data.enabled}
              onChange={(_, { checked }) => {
                data.enabled = !!checked;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Active/Inactive')}
              trueLabel={t('Active')}
              falseLabel={t('Inactive')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier)
                    onFilter(nameof<UserDto>('enabled'), `(enabled eq ${value}) and locationId eq ${this.state.locationIdentifier}`);
                  else onFilter(nameof<UserDto>('enabled'), `(enabled eq ${value})`);
                } else onFilter(nameof<UserDto>('enabled'), { enabled: value });
              }}
              onClear={() => onClear(nameof<UserDto>('enabled'))}
              active={activeFilters.includes(nameof<UserDto>('enabled'))}
            />
          ),
          sortDefinition: { field: nameof<UserDto>('enabled'), useProfile: false }
        }
      ],
      data: users.state
    } as TableModel<UserDto>;
    return (
      <div className="machines-per-location">
        <Grid className="event-types-list-grid">
          {users.state.result && !users.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={users.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <div className="filters-wrapper table__filters-share-main-actions">
            <MultiLocationEditor
              value={this.state.locationsIdentifiers}
              filteredLocations={areYouPoc ? pocLocations : null}
              onChange={location => this.multiChangeLocationsFilters(location)}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={false}
            ></TableView>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  @autobind
  onEditItem(): void {
    this.setState({ changeUserShown: true });
  }

  private getRolesInLocation = (data: UserDto) => {
    const content = data.roles.map(roleInLocation => {
      if (roleInLocation.region) {
        return (
          <List.Item key={`${data.id}-${roleInLocation.role.id}-${roleInLocation.region.id}`}>
            <TableTooltipCell textToShow={`${GetRoleName(roleInLocation.role.name)} - ${roleInLocation.region.name}`}></TableTooltipCell>
          </List.Item>
        );
      } else if (roleInLocation.location) {
        return (
          <List.Item key={`${data.id}-${roleInLocation.role.id}-${roleInLocation.location.id}`}>
            <TableTooltipCell
              textToShow={`${GetRoleName(roleInLocation.role.name)} - ${roleInLocation.location.location}`}
            ></TableTooltipCell>
          </List.Item>
        );
      } else {
        return (
          <List.Item key={`${data.id}-${roleInLocation.role.id}`}>
            <TableTooltipCell textToShow={GetRoleName(roleInLocation.role.name)}></TableTooltipCell>
          </List.Item>
        );
      }
    });

    return content;
  };

  private getCountryWithFlag = (location: LocationDto) => {
    if (!location) return <></>;

    return (
      <span>
        <CountryFlag countryName={location.countryName} countryIsoCode={location.isoCode} />
        &nbsp;{location.countryName}
      </span>
    );
  };
}

export default withTranslation()(UsersPerLocationsListPage);
