import { withTranslation, WithTranslation } from 'react-i18next';
import { Component } from 'react';
import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';

interface EventWarningsProps extends WithTranslation {
  eventFormStore?: EventFormStore;
}

@connect(['eventFormStore', EventFormStore])
class EventWarnings extends Component<EventWarningsProps> {
  public render() {
    const { t, eventFormStore: store } = this.props;
    const event = store.state.item;

    return (
      <>
        {event.status &&
          (event.status.toString() === 'Draft' || event.status.toString() === 'Planned') &&
          event.warningMessages.length > 0 && (
            <Label className={'modal__action-warning'}>
              <Icon name="exclamation triangle" />
              <div>
                {event.warningMessages.map(
                  (messageToShow, index) =>
                    messageToShow && (
                      <p key={'warning-' + index} className="modal__action-warning__text">
                        {t(messageToShow)}
                      </p>
                    )
                )}
              </div>
            </Label>
          )}
      </>
    );
  }
}
export default withTranslation()(EventWarnings);
