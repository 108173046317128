import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface NMCategoryDto extends BaseDto {
  id: string;
  name: string;
  clusterId: string;
  clusterName: string;
}

export interface CreateNMCategoryDto {
  name: string;
  clusterId: string;
}

export interface ChangeNMCategoryDto {
  id: string;
  name: string;
  clusterId: string;
}

export class CreateNMCategoryValidator extends AbstractValidator<CreateNMCategoryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category Cluster Id is required'));
  }
}

export class ChangeNMCategoryValidator extends AbstractValidator<ChangeNMCategoryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category name is required'));
  }
}

@repository('@@NMCATEGORIES', 'nmCategories.summary')
export class NMCategoriesStore extends DataStore<NMCategoryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCategory';
  retrievePath = 'get-nmCategories';
  updatePath = 'update-nmCategory';
  deletePath = 'delete-nmCategory';

  protected validate(item: NMCategoryDto) {
    return new ChangeNMCategoryValidator().validate(item);
  }

  constructor() {
    super('NMCATEGORY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMCATEGORIES', 'nmCategories.new')
export class NewNMCategoryStore extends FormStore<CreateNMCategoryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCategory';
  retrievePath = 'get-nmCategories';
  updatePath = 'update-nmCategory';

  protected validate(item: CreateNMCategoryDto) {
    return new CreateNMCategoryValidator().validate(item);
  }

  constructor() {
    super('NEW_NMCATEGORY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMCATEGORIES', 'nmCategories.change')
export class ChangeNMCategoryStore extends FormStore<ChangeNMCategoryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCategory';
  retrievePath = 'get-nmCategories';
  updatePath = 'update-nmCategory';

  protected validate(item: ChangeNMCategoryDto) {
    return new ChangeNMCategoryValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMCATEGORY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
