import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message } from 'semantic-ui-react';
import '../../../../../assets/less/ssaSkill.less';
import { NewIwsSkillStore, CreateIwsSkillDto, IwsSkillDto } from 'stores/assessments/skills/iws-skills-store';
import { nameof } from 'utils/object';

interface NewIwsSkillViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newIwsSkill?: NewIwsSkillStore;
  isClone?: boolean;
  cloneData: IwsSkillDto;
}

interface NewIwsSkillViewState {
  userClickedSave: boolean;
}

@connect(['newIwsSkill', NewIwsSkillStore])
class NewIwsSkillView extends React.Component<NewIwsSkillViewProps, NewIwsSkillViewState> {
  private get newIwsSkillStore() {
    return this.props.newIwsSkill;
  }

  constructor(props: NewIwsSkillViewProps) {
    super(props);
    this.newIwsSkillStore.createNew({
      description: this.props.isClone && this.props.cloneData ? this.props.cloneData.description : '',
      name: this.props.isClone && this.props.cloneData ? this.props.cloneData.name : ''
    });
    this.state = {
      userClickedSave: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewIwsSkillViewProps) {
    if (this.newIwsSkillStore.state.result && this.newIwsSkillStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newIwsSkillStore.change({ ...this.newIwsSkillStore.state.item, ...change });
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newIwsSkillStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newIwsSkillStore.clear();
    this.props.onClose(false);
  }

  public render() {
    const { t } = this.props as any;

    const modalTitle = this.props.isClone ? 'Clone IWS Skill' : 'New IWS Skill';
    return (
      <Modal className="ssa-skill__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newIwsSkillStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="ssa-skill__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newIwsSkillStore.state.result && !this.newIwsSkillStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newIwsSkillStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newIwsSkillStore.state.item && (
              <Form>
                <div className="ssa-skill__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.state.userClickedSave && this.newIwsSkillStore.state.item.name.length === 0}
                    >
                      <label>{t('Skill')}</label>
                      <TextArea
                        maxLength="100"
                        className="ssa-skill__custom-editorequest__textarea"
                        value={this.newIwsSkillStore.state.item.name}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateIwsSkillDto>('name'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="ssa-skill__label-textarea"
                      error={this.state.userClickedSave && this.newIwsSkillStore.state.item.description.length === 0}
                    >
                      <label>{t('Description')}</label>
                      <TextArea
                        maxLength="500"
                        className="ssa-skill__custom-editorequest__textarea longArea"
                        value={this.newIwsSkillStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateIwsSkillDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-ssa-skill__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewIwsSkillView);
