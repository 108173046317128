import React, { FunctionComponent } from 'react';
import { Grid, List, Checkbox, TextArea } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReturnObj } from '../ssa-edit-form';

export interface ReturnItemProps extends WithTranslation, ReturnObj {
  onChange: (change: any) => void;
}

const ReturnItem: FunctionComponent<ReturnItemProps> = ({ name, skills, checked, comment, onChange }) => {
  const handleChange = (property: string, value: any) => {
    onChange({ [property]: value });
  };

  return (
    <Grid.Row stretched columns="16">
      <Grid.Column width="4" verticalAlign="middle">
        <b>{name}</b>
      </Grid.Column>
      <Grid.Column width="5" verticalAlign="middle">
        <List>
          {(skills || []).map((skill, idx) => (
            <List.Item key={'ssa-skill' + idx}>{skill}</List.Item>
          ))}
        </List>
      </Grid.Column>
      <Grid.Column width="2" verticalAlign="middle">
        <Checkbox checked={checked} onChange={(_, { checked }) => handleChange('checked', checked)} toggle label="Edit" />
      </Grid.Column>
      <Grid.Column width="5" verticalAlign="middle">
        <TextArea
          // disabled={!checked}
          className="returned-item__textarea"
          value={comment}
          onChange={(_, { value }) => checked && handleChange('comment', value)}
          placeholder="Comments"
        />
      </Grid.Column>
    </Grid.Row>
  );
};
export default withTranslation()(ReturnItem);
