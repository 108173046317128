import {
  InstructorExpertiseStore,
  ChangeInstructorExpertiseStore,
  NewInstructorExpertiseStore,
  InstructorTabExpertiseStore
} from './instructor-expertise-store';
import {
  InstructorExpertiseRequestsStore,
  NewInstructorExpertiseRequestStore,
  ChangeInstructorExpertiseRequestStore
} from './instructor-expertise-request-store';
import {
  DownloadMRInstructorExpertiseStore,
  DownloadNMRInstructorExpertiseStore,
  //DownloadInstructorExpertiseStore,
  UploadMRInstructorExpertiseStore,
  UploadNMRInstructorExpertiseStore
  //UploadInstructorExpertiseStore
} from './instructor-expertise-upload-store';
import { InstructorVisaStore, NewInstructorVisaStore, ChangeInstructorVisaStore } from './instructor-visa-store';
import { InstructorExpertiseRequestFormStore } from './instructor-expertise-request-form-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new InstructorExpertiseStore());
  storeBuilder.addRepository(new ChangeInstructorExpertiseStore());
  storeBuilder.addRepository(new NewInstructorExpertiseStore());
  storeBuilder.addRepository(new InstructorTabExpertiseStore());

  storeBuilder.addRepository(new InstructorExpertiseRequestsStore());
  storeBuilder.addRepository(new ChangeInstructorExpertiseRequestStore());
  storeBuilder.addRepository(new NewInstructorExpertiseRequestStore());
  //storeBuilder.addRepository(new UploadInstructorExpertiseStore());
  storeBuilder.addRepository(new UploadMRInstructorExpertiseStore());
  storeBuilder.addRepository(new UploadNMRInstructorExpertiseStore());
  storeBuilder.addRepository(new DownloadMRInstructorExpertiseStore());
  storeBuilder.addRepository(new DownloadNMRInstructorExpertiseStore());
  //storeBuilder.addRepository(new DownloadInstructorExpertiseStore());

  storeBuilder.addRepository(new InstructorVisaStore());
  storeBuilder.addRepository(new NewInstructorVisaStore());
  storeBuilder.addRepository(new ChangeInstructorVisaStore());

  storeBuilder.addRepository(new InstructorExpertiseRequestFormStore());
}
