import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Icon } from 'semantic-ui-react';

import { ChangePatternRowDto, PatternRowDto } from '../../../../../stores/configuration/machinery/pattern-rows-store';
import {
  CreatePatternDto,
  ChangePatternStore,
  ChangePatternDto,
  PatternDto,
  ChangePatternValidator
} from '../../../../../stores/configuration/machinery/patterns-store';
import PatternRowsListEdit from '../pattern-rows/pattern.rows-list-edit';
import i18n from 'i18n';

interface ChangePatternViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changePattern?: ChangePatternStore;
  currentPattern: PatternDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangePatternViewState {
  rowsList: ChangePatternRowDto[];
  errorMessages: any[];
}

@connect(['changePattern', ChangePatternStore])
class ChangePatternView extends React.Component<ChangePatternViewProps, ChangePatternViewState> {
  private get changePatternStore() {
    return this.props.changePattern;
  }

  constructor(props: ChangePatternViewProps) {
    super(props);

    this.state = {
      rowsList: this.props.currentPattern.patternRows,
      errorMessages: []
    };

    this.changePatternStore.change({
      id: this.props.currentPattern.id,
      name: this.props.currentPattern.name,
      patternRows: this.props.currentPattern.patternRows,
      active: this.props.currentPattern.active
    });

    this.changePatternStore.state.result = null;
  }

  private orderPatternRows(patternsRows: PatternRowDto[]): PatternRowDto[] {
    let orderedPatternRows: PatternRowDto[] = patternsRows.orderBy(x => x.machineModelName).toArray();

    return orderedPatternRows;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangePatternViewProps) {
    if (this.changePatternStore.state.result && this.changePatternStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    const validator = new ChangePatternValidator();
    if (validator != null) {
      const result = validator.extendValidate(this.changePatternStore.state.item);
      const errorMessages = result.isValid() ? [] : result.getFailureMessages().map(x => ({ level: 'Error', body: x }));
      this.setState({ errorMessages });
    }

    this.changePatternStore.update();
  }

  @autobind
  private onChange(dto: ChangePatternDto) {
    this.changePatternStore.change(dto);
  }

  @autobind
  private onCancelChangeItem() {
    this.changePatternStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: keyof CreatePatternDto | keyof ChangePatternDto, value: any) {
    const change = {};
    change[property] = value;
    this.changePatternStore.change({ ...this.changePatternStore.state.item, ...change });
  }

  @autobind
  private addRow() {
    const row = {
      id: null,
      patternId: null,
      machineRelatedClusterId: null,
      equipmentTypeId: null,
      oemId: null,
      machineModelId: null,
      isOnlyRequest: true,
      order: 0
    };

    const item = this.changePatternStore.state.item;
    row.order = item.patternRows.length;

    item.patternRows = [...item.patternRows, row];
    this.changePatternStore.change(item);
    //this.setState({rowsList: this.changePatternStore.state.item.patternRows});
  }

  public render() {
    const { t, changePattern } = this.props;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changePatternStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Pattern')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.state.errorMessages.length > 0 && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.state.errorMessages.map(o => o.body).filter(x => x !== '')}
              />
            )}
            {this.changePatternStore.state.result &&
              this.state.errorMessages.length === 0 &&
              !this.changePatternStore.state.result.isSuccess && (
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={this.changePatternStore.state.result.messages.map(o => o.body)}
                />
              )}
            {this.changePatternStore.state.item && (
              <Form>
                <Form.Group className="new-pattern-modal__name-btn-wrapper">
                  <Form.Input
                    width={6}
                    required
                    fluid
                    label={t('Pattern')}
                    placeholder={t('Name')}
                    value={this.changePatternStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue('name', value)}
                  />

                  <Button
                    className="custom-table-add-row-btn-inverted"
                    icon
                    size="mini"
                    type="button"
                    content="Add Row"
                    onClick={this.addRow}
                  >
                    {i18n.t('Add Row ')}
                    <Icon circular size="tiny" name="add" />
                  </Button>
                </Form.Group>

                <PatternRowsListEdit changePattern={changePattern} onChange={dto => this.onChange(dto)} />

                <Form.Checkbox
                  toggle
                  label={t('Active')}
                  checked={this.changePatternStore.state.item.active}
                  placeholder={t('Active')}
                  onChange={(e, { checked }) => this.handleValue('active', checked)}
                />
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangePatternView);
