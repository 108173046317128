import React from 'react';
import './notifications.less';
import { List, Icon, Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { ToDoListItemDto, UserTodoStore, UndoneToDoCountStore } from 'stores/events/event-todo-store';
import { Query } from 'stores/dataStore';
import { RouteComponentProps } from 'react-router';
import emptyNotImage from '../../assets/img/envelope.svg';
import { DateTimeService } from 'services/datetime-service';
import { openInNewWindow } from 'utils/useful-functions';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ToDoProps extends RouteComponentProps, WithTranslation {
  UserTodoStore?: UserTodoStore;
  undoneToDoCountStore?: UndoneToDoCountStore;
  containerToTakeHeight?: any;
  toggleNotification?: (any?) => void;
}

interface NotificationsState {
  activeTab: 'Notifications' | 'To-do list';
  query: Query;
  offsetShow: number;
  isBusy: boolean;
}

@connect(['UserTodoStore', UserTodoStore], ['undoneToDoCountStore', UndoneToDoCountStore])
class ToDoTab extends React.Component<ToDoProps, NotificationsState> {
  private elementsPerPage = 7;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'To-do list',
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }],
        skip: 0,
        take: this.elementsPerPage
      },
      offsetShow: 0,
      isBusy: true
    };
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    this.props.UserTodoStore.getAllAsync(this.state.query)
      .then(res => this.setState({ isBusy: false }))
      .catch(error => {
        this.setState({ isBusy: false });
      });
  };

  handleChangeCheckbox = (item: ToDoListItemDto) => {
    const toDoItemCheckedId = item.id;
    const toDoItemToChange = this.props.UserTodoStore.state.items.find(toDo => toDo.item.id === toDoItemCheckedId);
    toDoItemToChange.item.done = !toDoItemToChange.item.done;
    this.props.UserTodoStore.saveAsync(toDoItemToChange.item, 'Changed').then(() => this.load());
  };

  handleMarkAllTodoDone = e => {
    this.props.UserTodoStore.clearAllAsync();
  };

  goPreviousPageToDo = () => {
    if (this.props.UserTodoStore.state.isBusy) return;

    let nextSkipPositionPage = this.state.offsetShow - this.elementsPerPage;
    // Cant query less than 0 index
    if (nextSkipPositionPage < 0) {
      nextSkipPositionPage = 0;
    }

    let newQuery = { ...this.state.query };
    newQuery.skip = nextSkipPositionPage;

    this.setState({
      query: newQuery,
      offsetShow: nextSkipPositionPage,
      isBusy: true
    });

    this.props.UserTodoStore.getAllAsync(newQuery)
      .then(res => {
        this.setState({ isBusy: false });
      })
      .catch(err => this.setState({ isBusy: false }));
  };

  goNextPageToDo = () => {
    if (this.props.UserTodoStore.state.isBusy) return;

    let nextSkipPositionPage = this.state.offsetShow + this.elementsPerPage;
    // cant query more than existent notifications
    if (nextSkipPositionPage > this.props.UserTodoStore.state.count) {
      nextSkipPositionPage = this.props.UserTodoStore.state.count - this.elementsPerPage;
    }

    let newQuery = { ...this.state.query };
    newQuery.skip = nextSkipPositionPage;

    this.setState({ query: newQuery, offsetShow: nextSkipPositionPage, isBusy: true });

    this.props.UserTodoStore.getAllAsync(newQuery)
      .then(res => {
        this.setState({ isBusy: false });
      })
      .catch(err => this.setState({ isBusy: false }));
  };

  handleClickToDoItem = (id: string) => {
    const { UserTodoStore, history, toggleNotification } = this.props;
    const todoFromStore = UserTodoStore.state.items.find(item => item.item.id === id);
    const { relatedItemId, type } = todoFromStore.item;

    if (type === 'Event') history.push(`/events/${relatedItemId}`);
    else if (type === 'Request') history.push(`/requests/${relatedItemId}`);
    else if (type === 'Admin_SuccessFactor' || type === 'Admin_UserImport') history.push(`/users/import-export-log/`);
    else if (type === 'TnaForm') openInNewWindow(`/assessments/tna/tna-edit-form/${relatedItemId}`);
    else if (type === 'FeedBackForm') openInNewWindow(`/assessments/feedbacks/form/${relatedItemId}`);
    else if (type === 'TheoreticalForm') openInNewWindow(`/assessments/theoretical-test/${relatedItemId}`);
    else if (type === 'SsaForm') openInNewWindow(`/assessments/ssa/ssa-edit-form/${relatedItemId}`);
    else if (type === 'IwsForm') openInNewWindow(`/assessments/iws/iws-edit-form/${relatedItemId}`);
    toggleNotification();
  };

  returnNewArray = () => {
    return this.props.UserTodoStore.state.items;
  };

  getGoToLinkTitle = (todoItem: ToDoListItemDto) => {
    if (todoItem.type === 'TnaForm') return 'TNA Form';

    if (todoItem.type === 'TheoreticalForm') return 'Theoretical Form';

    if (todoItem.type === 'SsaForm') return 'SSA Form';

    if (todoItem.type === 'IwsForm') return 'IWS SuC';

    return todoItem.type;
  };

  render() {
    const { UserTodoStore, t } = this.props;
    const { offsetShow, isBusy: stateBusy } = this.state;
    const { isBusy, count, items } = UserTodoStore.state;
    let content = (
      <Dimmer inverted active>
        <Loader indeterminate inverted>
          {t('Loading to-do list')}
        </Loader>
      </Dimmer>
    );

    const pagination = (
      <div className="notification__pagination-wrapper to-do__pagination">
        <div className="pagination__display-flex-elements">
          <div className="notif__pag__link notif__pag__previous">
            <div onClick={this.goPreviousPageToDo} className={offsetShow <= 0 ? 'notif__previous-page hidden' : 'notif__previous-page'}>
              <Icon name="arrow left" />
              {t('Previous')}
            </div>
          </div>
          <div className="notif__pag__link notif__pag__next">
            <div
              onClick={this.goNextPageToDo}
              className={offsetShow + this.elementsPerPage >= count ? 'notif__next-page hidden' : 'notif__next-page'}
            >
              {t('Next')}
              <Icon name="arrow right" />
            </div>
          </div>
        </div>
      </div>
    );

    if (!stateBusy) {
      content = (
        <React.Fragment>
          <div className="to-do-list__container scroll-not-visible">
            <div className="to-dolist__header">
              <label className="field-title inverted-chckbx to-do-list__checkbox">
                <span>{t('Mark All as Done')}&nbsp;</span>
                <Checkbox onChange={this.handleMarkAllTodoDone} />
              </label>
            </div>
            {this.returnNewArray().map(({ item }: { item: ToDoListItemDto }) => (
              <List.Item className={item.done ? 'to-do__message-box notification-was-read' : 'to-do__message-box'}>
                <Dimmer inverted active={isBusy}>
                  <Loader indeterminate inverted>
                    {t('Loading ToDo list')}
                  </Loader>
                </Dimmer>

                <div className="notification__date-hour">{DateTimeService.toNotificationString(item.createdOn)}</div>
                <div className="to-do__checkbox-event__wrapper">
                  <Checkbox checked={item.done} onChange={() => this.handleChangeCheckbox(item)} />
                  <p className="notification__message-title">{item.task}</p>
                </div>
                <p className="todo__reason" onClick={() => this.handleClickToDoItem(item.id)}>
                  <p>
                    {t('Go to ')} {this.getGoToLinkTitle(item)}
                  </p>
                </p>
              </List.Item>
            ))}
          </div>
          {pagination}
        </React.Fragment>
      );
    }

    if (stateBusy) return content;
    else if (items.length <= 0 && count <= 0)
      return (
        <div className="notifications-not-found__wrapper">
          <img className="notif__empty__image" src={emptyNotImage} alt="envelope empty" />
          <p>{t("You don't have any pending to do")}</p>
        </div>
      );
    else return content;
  }
}

export default withTranslation()(ToDoTab);
