import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dimmer, Loader, Button, Message, Modal } from 'semantic-ui-react';
import './tna-templates-wizard.less';
import { connect } from 'redux-scaffolding-ts';
import FirstStepContent from './steps/tna-wizard-first-step';
import SecondStepContent from './steps/tna-wizard-second-step';
import ThirdStepContent from './steps/tna-wizard-third-step';
import { Query, ItemReference } from 'stores/dataStore';
import {
  TnaTemplatesStore,
  NewTnaTemplateStore,
  ChangeTnaTemplateStore,
  TnaTemplateDto,
  ChangeTnaTemplateDto,
  CreateTnaTemplateDto,
  TnaSkillTemplateSectionDto,
  TnaMachineUnitTemplateSectionDto,
  CreateTnaSkillTemplateSectionDto,
  CreateTnaMachineUnitTemplateSectionDto,
  DownloadTnaTemplatesStore
} from 'stores/assessments/templates/tna-templates-store';
import { RouteComponentProps } from 'react-router';
import { ProfileItemDto, ProfileItemStore, ProfileSkillDto } from 'stores/profile/profile-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface TnaTemplateWizardComponentProps extends WithTranslation, RouteComponentProps {
  tnaTemplateStore?: TnaTemplatesStore;
  newTemplateStore?: NewTnaTemplateStore;
  changeTemplateStore?: ChangeTnaTemplateStore;
  profileStore?: ProfileItemStore;
  downloadTnaTemplateStore?: DownloadTnaTemplatesStore;
}

export interface TnaTemplateMachines {
  machineRelatedCluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
}

type ModalMode = 'cancel' | 'accept';
export interface TnaTemplateWizardComponentState {
  templateData: TnaTemplateData;
  query: Query;
  machineRow: TnaTemplateMachines;
  showConfirmationModal: boolean;
  confirmationModalMode: ModalMode;
  showDownloadMsg: boolean;
  activateSaveButton: boolean;
}

export interface TnaTemplateData {
  profile: ProfileItemDto;
  templateTitle: string;
  templateHeader?: string;
  id?: string;
  machines: TnaTemplateMachines[];
  nonMachineRelatedSkillSections: TnaSkillTemplateSectionDto[];
  commonMachineRelatedSkillSections: TnaSkillTemplateSectionDto[];
  machineRelatedSections: TnaMachineUnitTemplateSectionDto[];
}

@connect(
  ['tnaTemplateStore', TnaTemplatesStore],
  ['newTemplateStore', NewTnaTemplateStore],
  ['changeTemplateStore', ChangeTnaTemplateStore],
  ['profileStore', ProfileItemStore],
  ['downloadTnaTemplateStore', DownloadTnaTemplatesStore]
)
class TnaTemplateWizardComponent extends React.Component<TnaTemplateWizardComponentProps, TnaTemplateWizardComponentState> {
  constructor(props: TnaTemplateWizardComponentProps) {
    super(props);
    this.state = {
      confirmationModalMode: null,
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10, filter: [] },
      templateData: {
        profile: null,
        templateTitle: null,
        templateHeader: null,
        machines: [],
        nonMachineRelatedSkillSections: [],
        commonMachineRelatedSkillSections: [],
        machineRelatedSections: []
      },
      machineRow: this.emptyMachineRow,
      showConfirmationModal: false,
      showDownloadMsg: false,
      activateSaveButton: false
    };
  }

  loading = false;

  private get modeStore(): NewTnaTemplateStore | ChangeTnaTemplateStore {
    return this.isOnEditMode() ? this.props.changeTemplateStore : this.props.newTemplateStore;
  }

  private get plainNewItem(): TnaTemplateDto {
    return {
      id: null,
      friendlyId: null,
      profileItemId: null,
      profileName: null,
      title: null,
      header: null,
      isActive: true,
      machineModelIds: null,
      machineModels: null,
      nonMachineRelatedSkillSectionIds: null,
      nonMachineRelatedSkillSections: null,
      machineRelatedSectionIds: null,
      machineRelatedSections: null
    };
  }

  private get emptyMachineRow(): TnaTemplateMachines {
    return {
      equipmentType: null,
      machineModel: null,
      machineRelatedCluster: null,
      oem: null
    };
  }

  private get templates() {
    return this.props.tnaTemplateStore;
  }

  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state || match.params['id']) return true;
    else return false;
  };

  componentDidMount() {
    const { match } = this.props;
    if (!this.isNullOrUndefined(match.params['id'])) {
      const id = match.params['id'];
      this.load(id);
    } else {
      this.createNewItemStore(this.plainNewItem);
    }
  }

  getDefaultMachineRow = (machineRow?: TnaTemplateMachines) => ({
    ...(machineRow || this.state.machineRow),
    machineModel: null
  });

  load = async (id: string) => {
    const { profileStore, history } = this.props;
    try {
      this.loading = true;
      const fetchedTemplateData = await this.templates.getTemplateById(id);

      let profile = profileStore.state.items.find(obj => obj.item.id === fetchedTemplateData.profileItemId)?.item;

      profile = profile ? profile : await profileStore.getProfileById(fetchedTemplateData.profileItemId);

      if (profile && fetchedTemplateData) this.loading = false;

      const { templateData, machineRow } = this.mapToTemplateData(fetchedTemplateData, profile);

      this.setState({ templateData: { ...templateData, profile }, machineRow, showDownloadMsg: false, activateSaveButton: false }, () =>
        this.createNewItemStore(fetchedTemplateData)
      );
    } catch (error) {
      console.error({ error });
      history.replace('/not-found');
    }
  };

  mapDefaultCommonSkills = (sections: ProfileSkillDto[]): TnaSkillTemplateSectionDto[] =>
    sections.map(({ skillId, skillName }) => ({ skillId, skillName, questions: [] }));

  mapDefaultSkills = (commonSkills: TnaSkillTemplateSectionDto[], skills: TnaSkillTemplateSectionDto[]) => {
    const defaultSkills = [];
    let existingSkills = (skills || []).filter(({ skillId }) => (commonSkills || []).find(x => x.skillId === skillId) !== undefined);
    let nonExistingSkills = (commonSkills || []).filter(({ skillId }) => (skills || []).find(x => x.skillId === skillId) === undefined);

    (existingSkills || []).forEach(({ skillName, skillId, questions }) => {
      defaultSkills.push({ skillId, skillName, questions });
    });

    (nonExistingSkills || []).forEach(({ skillName, skillId, questions }) => {
      defaultSkills.push({ skillId, skillName, questions });
    });

    return defaultSkills;
  };

  mapToTemplateData = (rawData: TnaTemplateDto, profile: ProfileItemDto) => {
    const { header, title, nonMachineRelatedSkillSections, machineModels, machineRelatedSections } = rawData;
    const { skills } = profile;
    const commonMRSkills: TnaSkillTemplateSectionDto[] = this.mapDefaultCommonSkills(
      skills.filter(({ isMachineRelated }) => isMachineRelated)
    );

    const commonNMRSkills: TnaSkillTemplateSectionDto[] = this.mapDefaultCommonSkills(
      skills.filter(({ isMachineRelated }) => !isMachineRelated)
    );

    let nonMachineRelated = [];
    if (commonNMRSkills && commonNMRSkills.length > 0)
      nonMachineRelated = this.mapDefaultSkills(commonNMRSkills, nonMachineRelatedSkillSections);

    let machineRelated: TnaMachineUnitTemplateSectionDto[] = [];
    commonMRSkills &&
      commonMRSkills.length > 0 &&
      machineRelatedSections.forEach(({ machineUnitId, skillSections, machineUnitItemDto }) => {
        machineRelated.push({ machineUnitItemDto, machineUnitId, skillSections: this.mapDefaultSkills(commonMRSkills, skillSections) });
      });
    let machineRow = this.emptyMachineRow;
    const templateData = {
      nonMachineRelatedSkillSections: nonMachineRelated,
      machineRelatedSections: machineRelated,
      commonMachineRelatedSkillSections: commonMRSkills || [],
      machines: machineModels.map((m: MachineModelDto) => {
        machineRow = {
          equipmentType: { id: m.equipmentTypeId, title: m.equipmentTypeName },
          machineRelatedCluster: { id: m.clusterId, title: m.clusterName },
          oem: { id: m.oemId, title: m.oemName },
          machineModel: { id: m.id, title: m.name }
        };
        return machineRow;
      }),
      templateTitle: title,
      templateHeader: header || ''
    };
    machineRow = this.getDefaultMachineRow(machineRow);
    return { templateData, machineRow };
  };

  createNewItemStore = (rawData: TnaTemplateDto) => {
    const { id, title, header, isActive, machineModels: mModels } = rawData;
    const { nonMachineRelatedSkillSections, machineRelatedSections, profileItemId } = rawData;
    const { profile } = this.state.templateData;

    const skills = profile?.skills;
    const nmrSkills = (skills || []).length > 0 && skills.filter(({ isMachineRelated }) => !isMachineRelated);
    const mrSkills = (skills || []).length > 0 && skills.filter(({ isMachineRelated }) => isMachineRelated);

    const commonNMRSkillSections: TnaSkillTemplateSectionDto[] = (nmrSkills || []).length > 0 && this.mapDefaultCommonSkills(nmrSkills);
    const commonMRSkillSections: TnaSkillTemplateSectionDto[] = (mrSkills || []).length > 0 && this.mapDefaultCommonSkills(mrSkills);

    let nmrSkillSections = [];
    let mrSkillSections = [];

    if ((commonNMRSkillSections || []).length > 0) {
      if ((nonMachineRelatedSkillSections || []).length > 0)
        nmrSkillSections = this.mapDefaultSkills(commonNMRSkillSections, nonMachineRelatedSkillSections);
      else nmrSkillSections = commonNMRSkillSections;
    }

    if ((commonMRSkillSections || []).length > 0) {
      if ((machineRelatedSections || []).length > 0)
        machineRelatedSections.forEach(({ machineUnitId, machineUnitItemDto, skillSections: sections }) => {
          const skillSections = (sections || []).length > 0 ? this.mapDefaultSkills(commonMRSkillSections, sections) : [];
          mrSkillSections.push({ machineUnitId, machineUnitItemDto, skillSections });
        });
      else mrSkillSections = [{ machineUnitId: null, machineUnitItemDto: null, skillSections: commonMRSkillSections }];
    }

    const machineModels = (mModels || []).map(x => x.id);
    const newItem: any = { profileItemId, id, title, header, isActive, machineModels };
    newItem.nonMachineRelatedSkillSections = nmrSkillSections;
    newItem.machineRelatedSections = mrSkillSections;

    if (this.isOnEditMode()) {
      delete newItem.profileItemId;
      (this.modeStore as ChangeTnaTemplateStore).change(newItem);
    } else {
      delete newItem.id;
      (this.modeStore as NewTnaTemplateStore).createNew(newItem);
    }
  };

  changeMachineRelatedMachineUnitModel = (mrCommonSkills: TnaSkillTemplateSectionDto[]) => ({
    machineUnitItemDto: null,
    machineUnitId: null,
    skillSections:
      mrCommonSkills && mrCommonSkills.length > 0
        ? mrCommonSkills.map(common => ({
            skillId: common.skillId,
            skillName: common.skillName,
            questions: []
          }))
        : []
  });

  onChangeProperty = (property: string, value: any) => {
    const templateData = { ...this.state.templateData };

    this.setState({ activateSaveButton: true });

    if (property === 'profile' && value) {
      templateData.commonMachineRelatedSkillSections = value.skills
        .filter(({ isMachineRelated }) => isMachineRelated)
        .map(({ skillId, skillName }) => ({ questions: [], skillId, skillName }));

      templateData.machineRelatedSections = [
        { machineUnitItemDto: null, machineUnitId: null, skillSections: templateData.commonMachineRelatedSkillSections }
      ];

      templateData.nonMachineRelatedSkillSections = value.skills
        .filter(({ isMachineRelated }) => !isMachineRelated)
        .map(({ skillId, skillName }) => ({ questions: [], skillId, skillName }));
      templateData.machines = [];
      if (
        !this.state.machineRow.machineRelatedCluster ||
        !this.state.machineRow.equipmentType ||
        !this.state.machineRow.oem ||
        !this.state.machineRow.machineModel
      ) {
        let machineEmpty = this.emptyMachineRow;
        this.setState({ machineRow: machineEmpty });
      }
    }
    templateData[property] = value;
    this.setState({ templateData }, this.changeStoreItem);
  };

  mapFromSummaryToNewChangeSection = (sections: TnaSkillTemplateSectionDto[]): CreateTnaSkillTemplateSectionDto[] => {
    const newSkillSection = [];
    sections.length > 0 &&
      sections.forEach(section => {
        if (section.questions && section.questions.length > 0) {
          newSkillSection.push({ skillId: section.skillId, questions: section.questions.map(({ id }) => id) });
        } else {
          newSkillSection.push({ skillId: section.skillId, questions: [] });
        }
      });
    return newSkillSection;
  };

  mapFromSummaryToNewChangeMRSections = (sections: TnaMachineUnitTemplateSectionDto[]): CreateTnaMachineUnitTemplateSectionDto[] => {
    const machineRelatedSections = [];
    if ((sections || []).length > 0)
      sections.forEach(section =>
        // section.machineUnitId &&
        machineRelatedSections.push({
          machineUnitId: section.machineUnitId,
          skillSections: this.mapFromSummaryToNewChangeSection(section.skillSections)
        })
      );
    return machineRelatedSections;
  };

  changeStoreItem = () => {
    const storedItem: CreateTnaTemplateDto | ChangeTnaTemplateDto = { ...this.modeStore.state.item };
    const templateData: TnaTemplateData = { ...this.state.templateData };
    const nonMachineRelatedSkillSections =
      (templateData.nonMachineRelatedSkillSections &&
        templateData.nonMachineRelatedSkillSections.length > 0 &&
        this.mapFromSummaryToNewChangeSection(templateData.nonMachineRelatedSkillSections)) ||
      [];
    const machineRelatedSections =
      (this.AnyProfileSkillIsMachineRelated() &&
        templateData.machineRelatedSections &&
        templateData.machineRelatedSections.length &&
        this.mapFromSummaryToNewChangeMRSections(templateData.machineRelatedSections)) ||
      [];

    const editItem = {
      header: templateData.templateHeader,
      profileItemId: templateData.profile && templateData.profile.id,
      id: (storedItem as ChangeTnaTemplateDto).id,
      title: templateData.templateTitle,
      isActive: (storedItem as ChangeTnaTemplateDto).isActive,
      machineModels: templateData.machines && templateData.machines.length > 0 ? templateData.machines.map(m => m.machineModel.id) : [],
      nonMachineRelatedSkillSections,
      machineRelatedSections
    };
    if (this.isOnEditMode()) {
      delete editItem.profileItemId;
      (this.modeStore as ChangeTnaTemplateStore).change(editItem);
    } else {
      delete editItem.id;
      (this.modeStore as NewTnaTemplateStore).change(editItem);
    }
  };

  AnyProfileSkillIsMachineRelated = (): boolean => {
    const { templateData } = this.state;
    return templateData != null && templateData?.profile != null && templateData?.profile.skills.any(s => s.isMachineRelated);
  };

  hideStep2 = (): boolean => {
    const { templateData } = this.state;
    var hide: boolean = false;

    if (templateData?.templateTitle && templateData?.profile != null) hide = !this.AnyProfileSkillIsMachineRelated();

    return hide;
  };

  getStepNumber = (): string => {
    var stepNumber: string = '3';

    if (this.hideStep2()) stepNumber = '2';

    return stepNumber;
  };

  isStepTwoActive = () => {
    const { templateData } = this.state;
    return templateData?.templateTitle && templateData?.profile != null && this.AnyProfileSkillIsMachineRelated();
  };

  isStepThreeActive = (): boolean => {
    const { templateData } = this.state;
    return (
      templateData != null &&
      templateData.templateTitle &&
      templateData.templateTitle !== '' &&
      templateData.profile != null &&
      ((templateData.machines != null && templateData.machines.length > 0) || !this.AnyProfileSkillIsMachineRelated())
    );
  };

  getMachinesRow = (machineRow: any) => {
    this.setState(
      ({ templateData }) => ({
        templateData: { ...templateData, machines: [...(templateData?.machines || []), machineRow] },
        machineRow: this.getDefaultMachineRow()
      }),
      this.changeStoreItem
    );
  };

  removeMachine = (index: number) => {
    this.setState({ activateSaveButton: true });

    if (index < 0 || index >= this.state.templateData.machines.length) return;
    const machines = this.state.templateData.machines.filter((_, i) => index !== i);
    if ((machines || []).length <= 0)
      this.setState(
        ({ templateData }) => ({ templateData: { ...templateData, machineRelatedSections: [], machines: [] } }),
        this.changeStoreItem
      );
    else this.setState(({ templateData }) => ({ templateData: { ...templateData, machines } }), this.changeStoreItem);
  };

  onRemoveQuestion = (skillId: string, questionId: string, index: number = null) => {
    this.setState({ activateSaveButton: true });

    if (index != null) {
      const mrSection = [...this.state.templateData.machineRelatedSections];
      if ((mrSection || []).length > 0) {
        mrSection[index].skillSections = mrSection[index].skillSections.map(section => {
          if (section.skillId === skillId) {
            section.questions = section.questions.filter(question => question.id !== questionId);
          }
          return section;
        });

        this.setState(
          ({ templateData }) => ({ templateData: { ...templateData, machineRelatedSections: mrSection } }),
          this.changeStoreItem
        );
      }
    } else {
      const nmrSection = [...this.state.templateData.nonMachineRelatedSkillSections];
      (nmrSection || []).length > 0 &&
        this.setState(
          ({ templateData }) => ({
            templateData: {
              ...templateData,
              nonMachineRelatedSkillSections: nmrSection.map(nmrSkills => {
                if (nmrSkills.skillId === skillId) nmrSkills.questions = nmrSkills.questions.filter(question => questionId !== question.id);
                return nmrSkills;
              })
            }
          }),
          this.changeStoreItem
        );
    }
  };

  goBack = () => {
    const { history } = this.props;
    this.modeStore.clear();
    history.goBack();
  };

  submit = () => {
    this.cancelConfirmationModal();
    if (this.isOnEditMode())
      (this.modeStore as ChangeTnaTemplateStore).update().then(response => {
        this.modeStore.clear();
        this.modeStore.state.result && this.modeStore.state.result.isSuccess
          ? this.goBack()
          : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
    else
      (this.modeStore as NewTnaTemplateStore).submit().then(response => {
        this.modeStore.clear();
        response && response.isSuccess ? this.goBack() : document.querySelector('#root').scrollTo(0, 0); // scrolling top in order to see the warning/error messages
      });
  };

  machineRowChangesHandler = machineRow => {
    this.setState({ machineRow });
  };

  addNewMachineRelatedSkillsHandler = () => {
    const machineRelatedSections = [...this.state.templateData.machineRelatedSections];
    machineRelatedSections.push(this.changeMachineRelatedMachineUnitModel(this.state.templateData.commonMachineRelatedSkillSections));
    this.setState(prevState => ({ templateData: { ...prevState.templateData, machineRelatedSections } }), this.changeStoreItem);
  };

  canSaveTemplate = (): boolean => {
    const item: CreateTnaTemplateDto | ChangeTnaTemplateDto = { ...this.modeStore.state.item };
    const { title, machineModels, machineRelatedSections, nonMachineRelatedSkillSections } = item;
    const activate = this.state.activateSaveButton;

    var canSave = false;

    if (this.AnyProfileSkillIsMachineRelated()) {
      canSave =
        activate &&
        (title || '').length > 0 &&
        (machineModels || []).length > 0 &&
        (machineRelatedSections || []).length > 0 &&
        ((nonMachineRelatedSkillSections || []).length > 0
          ? nonMachineRelatedSkillSections.every(nmrSections => (nmrSections?.questions || []).length > 0)
          : true) &&
        machineRelatedSections.every(
          mrSections =>
            mrSections.machineUnitId != null &&
            mrSections.machineUnitId.length > 0 &&
            mrSections.skillSections.every(skills => skills.questions && skills.questions.length > 0)
        );
    } else {
      canSave =
        activate &&
        (title || '').length > 0 &&
        nonMachineRelatedSkillSections.every(nmrSections => (nmrSections?.questions || []).length > 0);
    }

    return canSave;
  };

  isNullOrUndefined = (value): boolean => value === null || value === undefined;

  confirmationModalHandler = (confirmationModalMode: ModalMode) => {
    this.setState({ showConfirmationModal: true, confirmationModalMode });
  };

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, confirmationModalMode: null });
  };

  setShowDownloadMsg = shownMsg => {
    this.setState({ showDownloadMsg: shownMsg });
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadTnaTemplateStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = result.messages.map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  render() {
    const { t } = this.props;
    const { templateData, machineRow, confirmationModalMode, showConfirmationModal, showDownloadMsg } = this.state;
    const { result, isBusy } = this.modeStore.state;
    const mode = this.isOnEditMode();
    const templateId = this.props.match?.params['id'];
    return (
      <>
        <h3 className="template-wizard-title">{t(`TNA Template ${mode ? 'Edition' : 'Creation'}`)}</h3>
        <LineSeparator />
        {result && !result.isSuccess && (result.messages || []).length > 0 && (
          <Message className="error-message__style" icon="exclamation circle" error list={result.messages.map(m => m.body)} />
        )}

        {showDownloadMsg && this.getBackgroundDownloadMessage()}

        <div className="wizard__steps-container">
          <Dimmer
            page
            active={isBusy || this.templates.state.isBusy || this.loading}
            style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}
          >
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          <FirstStepContent isEditMode={mode} data={templateData} onChangeProperty={this.onChangeProperty} />
          <SecondStepContent
            machineRow={machineRow}
            machines={templateData?.machines || []}
            onAddMachineRow={this.getMachinesRow}
            onMachineRowChanges={this.machineRowChangesHandler}
            onRemoveMachine={this.removeMachine}
            isStepActive={this.isStepTwoActive()}
            tnaTemplateId={templateId}
            showDownloadMsg={this.setShowDownloadMsg}
            hide={this.hideStep2()}
          />
          <ThirdStepContent
            machineRow={machineRow}
            templateData={templateData}
            onChangeProperty={this.onChangeProperty}
            onRemoveQuestion={this.onRemoveQuestion}
            onAddNewMachineRelatedSkillsSections={this.addNewMachineRelatedSkillsHandler}
            isStepActive={this.isStepThreeActive()}
            stepNumber={this.getStepNumber()}
          />
        </div>

        <div className="tna-templates-wizard__btns">
          <Button onClick={() => this.confirmationModalHandler('cancel')} secondary inverted>
            {t('Cancel')}
          </Button>
          <Button disabled={!this.canSaveTemplate()} onClick={() => this.confirmationModalHandler('accept')} primary positive>
            {t(`${mode ? 'Save' : 'Create'} Template`)}
          </Button>
        </div>
        {showConfirmationModal && (
          <Modal
            closeOnEscape={true}
            onClose={this.cancelConfirmationModal}
            open={showConfirmationModal}
            size="mini"
            className="tna-confirmation-modal"
          >
            <Modal.Content className="tna-confirmation-modal__content">
              {confirmationModalMode === 'accept' ? (
                <p>
                  {t(
                    `${
                      mode
                        ? 'Are you sure do you want to apply this changes in this TNA Template?'
                        : 'Do you want to create the TNA Template'
                    }`
                  )}
                </p>
              ) : (
                <>
                  <p>{t('Are you sure you want to CANCEL?')}</p>
                  <p>{t('You will lose all the changes made in this TNA template.')}</p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="tiny"
                className="tna-confirmation-modal__btn cancel"
                content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                onClick={this.cancelConfirmationModal}
              />
              <Button
                size="tiny"
                className="tna-confirmation-modal__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                onClick={confirmationModalMode === 'accept' ? this.submit : this.goBack}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(TnaTemplateWizardComponent);
