import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Modal, Dimmer, Loader, Dropdown, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import './feedbacks-templates.styles.less';
import {
  FeedbacksTemplatesListStore,
  FeedbackTemplateItemDto,
  FeedbacksTemplatesStore,
  Respondent
} from 'stores/assessments/templates/feedbacks-templates-store';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';

export interface FeedbacksTemplateListProps extends WithTranslation, RouteComponentProps {
  feedbacksTemplateListStore?: FeedbacksTemplatesListStore;
  feedbacksTemplateItemStore?: FeedbacksTemplatesStore;
}

export interface FeedbacksTemplateFilters {
  respondent: Respondent;
  evenTypeOriginalIds: string[];
  templateNameOrID: string;
  isActive: boolean;
}

export interface FeedbacksTemplateListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  filters: FeedbacksTemplateFilters;
  confirmationModal: { type: 'clone' | 'delete'; show: boolean };
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(['feedbacksTemplateListStore', FeedbacksTemplatesListStore], ['feedbacksTemplateItemStore', FeedbacksTemplatesStore])
class FeedbacksTemplateList extends React.Component<FeedbacksTemplateListProps, FeedbacksTemplateListState> {
  state: FeedbacksTemplateListState = {
    selectedItem: null,
    confirmationModal: { show: false, type: null },
    query: {
      searchQuery: '',
      orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
      filter: [],
      skip: 0,
      take: 10
    },
    activeFilters: [],
    filters: {
      evenTypeOriginalIds: [],
      templateNameOrID: null,
      respondent: null,
      isActive: true
    },
    loading: false,
    someFilterOpened: false
  };

  respondents = [
    { value: 10, text: 'Trainees' },
    { value: 20, text: 'Manual Assignment' }
  ];

  componentDidMount() {
    this.init();
  }

  private init = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, this.load);
  };

  private load = (query?: Query) => {
    try {
      if (!query) this.props.feedbacksTemplateListStore.getAllAsync(this.state.query);
      else this.props.feedbacksTemplateListStore.getAllAsync(query);
    } catch (error) {
      console.error({ error });
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.init
    );
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.init();
    });
  };

  private onSaveRow = async (item: FeedbackTemplateItemDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') {
      await this.props.feedbacksTemplateListStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  private async onDelete(item: FeedbackTemplateItemDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.feedbacksTemplateListStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  private onNewTemplate = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/feedbacks/template/' });
  };

  private onEditItem = (selectedItem: FeedbackTemplateItemDto) => {
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/feedbacks/template/' + selectedItem.id, state: selectedItem });
  };

  private onFilterTemplateOrId = (_, { value }) => {
    const filters = { ...this.state.filters };
    if (!value) {
      delete filters['templateNameOrID'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TF');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TF')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      filters['templateNameOrID'] = `(${parts.join(' or ')})`;
    }

    this.setState({ filters }, () => this.refreshTable(filters));
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters;

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, this.init);
  };

  private refreshTable(filters: FeedbacksTemplateFilters) {
    const tableFilter = this.buildODataFilter(filters);

    this.setState({ filters });
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters: FeedbacksTemplateFilters) {
    let oDataFilters = [];
    const { respondent, templateNameOrID, evenTypeOriginalIds } = filters;
    if (respondent) {
      oDataFilters.push({ respondent });
    }

    if (!isNullOrWhiteSpaces(templateNameOrID)) {
      oDataFilters.push(templateNameOrID);
    }
    if ((evenTypeOriginalIds || []).length !== 0) {
      oDataFilters.push({
        EventTypes: {
          any: {
            OriginalId: { in: { type: 'guid', value: evenTypeOriginalIds } }
          }
        }
      });
    }

    oDataFilters.push({ isActive: true });
    return oDataFilters;
  }

  onHandleChangeFilter = (property: string, value: string | boolean) => {
    let filters = { ...this.state.filters };
    if (value == null) {
      delete filters[property];
    } else {
      filters[property] = value;
    }

    this.setState({
      filters
    });
  };

  private onDeleteItem = async (item: FeedbackTemplateItemDto): Promise<CommandResult<any>> => {
    await this.props.feedbacksTemplateListStore.deleteAsync(item.id);
    this.setState({ confirmationModal: { show: false, type: null } });
    return { isSuccess: true, items: [], messages: [] };
  };

  closeConfirmationBox = () => {
    this.setState({ selectedItem: null, confirmationModal: { type: null, show: false } });
  };

  onCloneItem = async () => {
    this.closeConfirmationBox();
    this.setState({ loading: true });
    const { feedbacksTemplateItemStore } = this.props;
    feedbacksTemplateItemStore.change(this.state.selectedItem);
    try {
      await feedbacksTemplateItemStore.newTemplate();
      this.setState({ confirmationModal: { show: false, type: null }, loading: false });
      this.refreshTable(this.state.filters);
      this.setState({ confirmationModal: { show: false, type: null }, loading: false });
    } catch (error) {
      console.error({ error });
    }
  };

  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [];
    buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="pencil" />
            {<span className="text__bold">{t('Edit')}</span>}
          </>
        ),
        onClick: (item: FeedbackTemplateItemDto) => {
          this.setState({ selectedItem: item }, () => this.onEditItem(item));
        }
      },
      {
        content: (
          <>
            <Icon name="clone" />
            {<span className="text__bold">{t('Clone')}</span>}
          </>
        ),
        onClick: (selectedItem: FeedbackTemplateItemDto) => {
          this.setState({ selectedItem, confirmationModal: { type: 'clone', show: true } });
        }
      },
      {
        content: (
          <>
            <Icon color="red" name="trash alternate" />
            {<span className="text__red text__bold">{t('Delete')}</span>}
          </>
        ),
        onClick: item => {
          this.setState({ selectedItem: item, confirmationModal: { type: 'delete', show: true } });
        }
      }
    ];

    return buttonMenuOptions;
  };

  private eventTypeFilterChange = (value: string[]) => {
    let filters = { ...this.state.filters };

    filters.evenTypeOriginalIds = value ? value : [];
    this.refreshTable(filters);
  };

  private respondentChange = value => {
    let filters = { ...this.state.filters };
    filters.respondent = value === 10 ? 'Trainees' : value === 20 ? 'ManualAssignment' : null;
    this.refreshTable(filters);
  };

  handleOnEnterKeydown = (item: FeedbackTemplateItemDto) => {
    this.setState({ selectedItem: item }, () => this.onEditItem(item));
  };

  handleOnRowDoubleClick = (item: FeedbackTemplateItemDto) => {
    this.handleOnEnterKeydown(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, feedbacksTemplateListStore, feedbacksTemplateItemStore } = this.props;
    const { confirmationModal, selectedItem, filters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: ({ friendlyId }) => friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<FeedbackTemplateItemDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Template'),
          tooltipRenderer: false,
          renderer: ({ title }) => title,
          selectableHeader: true
        },
        {
          title: t('Event Type'),
          tooltipRenderer: false,
          renderer: ({ eventTypes }) =>
            (eventTypes || []).map(({ name }) => <span className="table__span-max-width-fit-content">{name}</span>)
        },
        {
          title: t('Respondents'),
          tooltipRenderer: false,
          renderer: ({ respondent }) => (respondent === 'ManualAssignment' ? t('Manual Assignment') : t(respondent))
        }
      ],
      data: feedbacksTemplateListStore.state
    } as TableModel<FeedbackTemplateItemDto>;

    const messages = [];
    if (feedbacksTemplateListStore.state.result && !feedbacksTemplateListStore.state.result.isSuccess)
      feedbacksTemplateListStore.state.result.messages.forEach(({ body }) => messages.push(body));

    return (
      <div className="question-bank__wrapper feedbacks-template__wrapper">
        <Dimmer active={feedbacksTemplateItemStore.state.isBusy} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>

        <Grid className="event-types-list-grid">
          {messages.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message className="error-message__style" icon="exclamation circle" error header={t('An error ocurred')} list={messages} />
            </Grid.Row>
          )}
          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              className="filter filter-template"
              icon="search"
              placeholder={t('Search in Template or ID')}
              onChange={this.onFilterTemplateOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiEventTypeEditor
              className="filter filter-eventtype"
              nullable
              value={filters.evenTypeOriginalIds}
              useOriginalEventTypeIdAsValue
              placeholder={t('Event Types')}
              onChange={this.eventTypeFilterChange}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <Dropdown
              className="filter filter-respondents"
              clearable
              closeOnBlur
              selectOnBlur={false}
              closeOnEscape
              options={this.respondents}
              onChange={(_, { value }) => this.respondentChange(value)}
              placeholder={t('Respondents')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!confirmationModal.show}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              isRowDisableLayout={item => !(item as any).isActive}
              onRefresh={this.init}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewTemplate}
              canCreateNew={true}
              canEdit={false}
              canDelete={true}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('New Feedback Template')}
            />
          </Grid.Row>
        </Grid>

        {confirmationModal.show && (
          <Modal
            open={confirmationModal.show}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>
                {t(
                  `You are going to ${confirmationModal.type === 'clone' ? 'clone' : 'delete'} this template with id ${
                    selectedItem.friendlyId
                  }`
                )}
              </p>

              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={() => (confirmationModal.type === 'clone' ? this.onCloneItem() : this.onDeleteItem(this.state.selectedItem))}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(FeedbacksTemplateList);
