import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, Divider } from 'semantic-ui-react';
//import LanguageEditor from 'widgets/bussiness/language-editor';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormStore, SsaFormViewModel } from 'stores/assessments/forms/ssa-forms-store';

interface TNAFormHeaderComponentProps extends WithTranslation {
  ssaFormStore?: SsaFormStore;
  isInRoleAdminOrPoC: boolean;
}

interface SsaFunctionalExpertObj {
  skillName: string;
  className: string;
  functionalExpertName: string;
}

interface TNAFormHeaderComponentState {
  ssaFunctionalExpert: SsaFunctionalExpertObj[][];
  accordionOpen: boolean;
}

@connect(['ssaFormStore', SsaFormStore])
class TNAFormHeaderComponent extends Component<TNAFormHeaderComponentProps, TNAFormHeaderComponentState> {
  getFunctionalExpertsWithSkill = (): SsaFunctionalExpertObj[][] => {
    const item: SsaFormViewModel = { ...this.props.ssaFormStore.state.item };
    const functionalExpertSkills: SsaFunctionalExpertObj[][] = [];
    (item.functionalExperts || []).forEach(functionalExpert => {
      const status = this.getAssessorAnswerStatus(functionalExpert.userId);
      const className = this.getassessorClassName(status);
      functionalExpertSkills.push(
        functionalExpert.skills.map(skill => ({
          skillName: skill.name,
          className,
          functionalExpertName: `${functionalExpert.user.firstName.toUpperCase()} ${functionalExpert.user.lastName.toUpperCase()}`
        }))
      );
    });

    return functionalExpertSkills;
  };

  getassessorClassName = assessorStatus => {
    const { item } = this.props.ssaFormStore.state;
    const isReturnedOrAssessorFormStatus = item.status === 'Returned' || item.status === 'Assessors';
    const className =
      assessorStatus !== 'Done' && isReturnedOrAssessorFormStatus
        ? 'edit-form__header__general-info__element header__status-not-done'
        : 'edit-form__header__general-info__element';

    return className;
  };

  getAssessorAnswerStatus = (id: string) => {
    const user = this.props.ssaFormStore.state.item.assessorAnswerStatus.find(({ userId }) => userId === id);
    if (!user) return;
    return user.answerStatus || 'Unknown';
  };

  toggleAccordion = () => this.setState(({ accordionOpen }) => ({ accordionOpen: !accordionOpen }));

  state: TNAFormHeaderComponentState = { ssaFunctionalExpert: this.getFunctionalExpertsWithSkill(), accordionOpen: false };

  render() {
    const { t, ssaFormStore, isInRoleAdminOrPoC } = this.props;
    const { item: itemState } = ssaFormStore.state;
    const { ssaFunctionalExpert, accordionOpen } = this.state;
    return (
      <>
        <div className="header-accordion-spacer">
          <Accordion className="tna-header-accordion">
            <Accordion.Title
              active={accordionOpen}
              onClick={this.toggleAccordion}
              className="tna-header-accordion__title"
              style={{ backgroundColor: '#ccf0ee' }}
            />
            <Accordion.Content active={accordionOpen}>
              <header className="form__all-wrapper__header">
                <div className="edit-form__header__data-language flex-between">
                  <div className="edit-form__header__general-info">
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Employee')}</h5>
                      <p>{itemState.user.name ? `${itemState.user.name.toUpperCase()}` : ''}</p>
                    </div>
                    <div className="edit-form__header__general-info__element">
                      <h5>{t('Profile')}</h5>
                      <p>{itemState.profile}</p>
                    </div>

                    {isInRoleAdminOrPoC && (ssaFunctionalExpert || []).length > 0 && (
                      <>
                        {ssaFunctionalExpert.map((functionalExpert, j) => (
                          <Fragment key={j + functionalExpert[0].skillName}>
                            {functionalExpert.map(({ skillName, className, functionalExpertName }, i) => (
                              <div className="edit-form__header__general-info__element" key={skillName + functionalExpertName + i}>
                                <h5>{`${skillName}`}</h5>
                                <p>{functionalExpertName}</p>
                              </div>
                            ))}
                          </Fragment>
                        ))}
                      </>
                    )}
                  </div>
                  {/* <div className="header-right">
                    <LanguageEditor
                      className="planit-user-dropdown-custom"
                      placeholder={t('Select Language')}
                      clearable
                      nullable
                      value={itemState.currentLanguage}
                      onChange={val => ssaFormStore.changeCurrentLanguage(val ? val.id : null)}
                      onlyForTests
                    />
                  </div> */}
                </div>
              </header>
            </Accordion.Content>
          </Accordion>
        </div>
        <Divider />
      </>
    );
  }
}

export default withTranslation()(TNAFormHeaderComponent);
