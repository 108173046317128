import { TheoreticalFormTnaDetailsDto } from './tna-theoretical-tests-store';
import { repository } from 'redux-scaffolding-ts';
import { FormStore } from 'stores/formStore';
import { ValidationFailure } from 'fluent-ts-validator';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { TheoreticalQuestionDto, MediaContent, AnswerType, OptionSelected } from '../questionBank/theoretical-test-store';
import { ImageInfo, ItemResult } from 'stores/types';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { DateTimeService } from 'services/datetime-service';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { SimpleUserDto } from 'stores/users/users-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export type RelatedToType = 'Unknown' | 'Event' | 'TNA';

export interface SimpleQuestionView {
  id: string;
  friendlyId: string;
  question: TheoreticalQuestionDto;
  questionTranslations: TheoreticalQuestionDto[];
  content: string;
  mediaContentType: MediaContent;
  imageInfo: ImageInfo;
  answerType: AnswerType;
  unavailableMedia: boolean;
  option: OptionSelected;
}
export interface TheoreticalFormView {
  id: string;
  friendlyId: string;
  formId: string;
  answered: boolean;
  time: string;
  result: string;
  templateName: string;
  templateHeader: string;
  user: SimpleUserDto;
  userAnswer: TheoreticalUserDto;
  startedAt: string;
  hoursDuration: number;
  numberOfQuestions: number;
  endedAt: string;
  typeRelated: RelatedToType;
  eventDetails: TheoreticalFormEventDetailsDto;
  tnaDetails: TheoreticalFormTnaDetailsDto;
  questionAnswers: TheoreticalFormQuestionAnswerDto[];
  questionIdx: number;
  currentLanguage: string; //GUID
  finishedTime: boolean;
}

export interface TheoreticalFormDto {
  id: string;
  title: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  startedAt: string;
  finishedAt: string;
  endedAt: string;
  templateId: string;
  typeRelated: RelatedToType;
  createOn: string;
  eventDetails: TheoreticalFormEventDetailsDto;
  tnaDetails: TheoreticalFormTnaDetailsDto;
}

export interface TheoreticalFormAnswerDto {
  id: string;
  answered: boolean;
  userId: string;
  user: TheoreticalUserDto;
  formId: string;
  form: GenericEventFormTemplateDto;
  numberOfQuestions: number;
  startedAt: string;
  endedAt: string;
  time: string;
  hoursDuration: number;
  result: string;
  questionAnswers: TheoreticalFormQuestionAnswerDto[];
}

export interface EventUserItemDto {
  id: string;
  firstName: string;
  lastName: string;
  location: LocationDto;
}
export interface TheoreticalUserDto extends EventUserItemDto {
  averageTime: string;
  totalTime: string;
  result: string;
  numberOfQuestions: string;
}

export interface TheoreticalFormQuestionAnswerDto extends GenericFormQuestionAnswerDto<TheoreticalQuestionDto> {
  content: string;
  mediaContentType: MediaContent;
  imageInfo: ImageInfo;
  time: string;
}

export interface GenericFormQuestionAnswerDto<T> {
  friendlyId: string;
  unavailableMedia: boolean;
  questionId: string;
  question: T;
  answerType: AnswerType;
  questionTranslations: T[];
  value: OptionSelected | 'unavailableMedia';
  isMandatory: boolean;
  answered: boolean;
  correctAnswer: boolean;
}

export interface GenericEventFormTemplateDto {
  id: string;
  eventId: string;
  title: string;
  friendlyId: string;
  templateId: string;
  templateName: string;
  templateHeader: string;
}

export interface TheoreticalFormEventDetailsDto {
  eventId: string;
  title: string;
  generatedAtStatus: string; //EventStatus
}

export interface ChangeTheoreticalFormAnswerDto {
  id: string;
  questionAnswers: GenericChangeFormQuestionAnswerDto[];
}
export interface GenericChangeFormQuestionAnswerDto {
  questionId: string;
  value?: OptionSelected | 'unavailableMedia';
}

export class TheoreticalFormValidator extends ExtendedAbstractValidator<TheoreticalFormView> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}

@repository('@@THEORETICAL_TEST_FORM', 'theoreticalTestsForm.form')
export class TheoreticalTestFormStore extends FormStore<TheoreticalFormView> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-form';
  retrievePath = 'get-theoretical-forms';
  retrieveAnswersPath = 'get-theoretical-form-answers';
  updatePath = 'save-theoretical-form-answers';
  sendPath = 'send-theoretical-form-answers';
  deletePath = '';
  MAP_TO_VIEW_MODEL = 'MAP_TO_VIEW_MODEL';
  retrieveOnePath = 'get-theoretical-form';

  constructor() {
    super('THEORETICAL_TEST_FORM', {
      isBusy: false,
      item: undefined,
      count: 0,
      status: 'Modified',
      result: undefined,
      discard: () => {}
    });
  }

  protected validate(item: TheoreticalFormView) {
    return new TheoreticalFormValidator().extendValidate(item as any);
  }

  public async getFormById(id: string): Promise<TheoreticalFormDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TheoreticalFormDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getFormAnswerById(id: string, fromTnaFormId?: string): Promise<TheoreticalFormAnswerDto> {
    let path = '';
    if (!isNullOrWhiteSpaces(fromTnaFormId)) path = `FromTnaForm=${fromTnaFormId}`;
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveAnswersPath,
      httpService.get<TheoreticalFormAnswerDto>(`${this.baseUrl}/${this.retrieveAnswersPath}/${id}?${path}`)
    );
    return result.data;
  }

  public submitAnswer = async (init: boolean = false, finish: boolean = false) => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);

    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }

    const newItem = mapToTheoreticalDto(this.state.item, init);
    const query = `${this.baseUrl}/${finish ? this.sendPath : this.updatePath}`;

    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      finish
        ? httpService.post<ChangeTheoreticalFormAnswerDto, ItemResult<TheoreticalFormAnswerDto>>(query, newItem)
        : httpService.put<ChangeTheoreticalFormAnswerDto, ItemResult<TheoreticalFormAnswerDto>>(query, newItem)
    );
    return result.data;
  };
}

export const mapToTheoreticalDto = (item: TheoreticalFormView, init: boolean = false): ChangeTheoreticalFormAnswerDto => ({
  id: item?.id,
  questionAnswers: (item?.questionAnswers || [])
    .filter((_, i) => (init ? i === 0 : i === item.questionIdx))
    .map(({ questionId, unavailableMedia, value }) =>
      init ? { questionId } : { questionId, value: unavailableMedia ? 'unavailableMedia' : value }
    )
});

export const mapToTheoreticalFormView = (formItem: TheoreticalFormDto, answers: TheoreticalFormAnswerDto): TheoreticalFormView => {
  if (!formItem || !answers) return;
  const { id: formId, friendlyId, user, typeRelated, tnaDetails, eventDetails } = formItem;
  const { id, startedAt, endedAt, user: userAnswer, time, result } = answers;
  const { numberOfQuestions, questionAnswers, answered, hoursDuration, form } = answers;

  let questionIdx =
    endedAt == null ? (questionAnswers || []).indexOf(questionAnswers.find(({ answered, value }) => !answered && value == null)) : 0;
  if (questionIdx === -1) questionIdx = 0;
  const now = DateTimeService.now();
  const end = DateTimeService.toMoment(startedAt).add(hoursDuration, 'hours');
  const finishedTime = !endedAt && !answered ? end.isBefore(now) : true;

  return {
    id,
    finishedTime,
    numberOfQuestions,
    formId,
    friendlyId,
    answered,
    userAnswer,
    currentLanguage: null,
    questionIdx,
    questionAnswers: (questionAnswers || []).map(item =>
      item.value === 'unavailableMedia'
        ? { ...item, value: null, unavailableMedia: true }
        : { ...item, value: item.value, unavailableMedia: false }
    ),
    endedAt,
    startedAt,
    user,
    result,
    typeRelated,
    time,
    tnaDetails,
    eventDetails,
    templateName: form.templateName,
    templateHeader: form.templateHeader,
    hoursDuration
  };
};
export const mapToSaveTheoreticalDTO = ({ id, questionAnswers }: TheoreticalFormView): ChangeTheoreticalFormAnswerDto => ({
  id,
  questionAnswers: (questionAnswers || []).map(({ questionId, value, unavailableMedia }) => ({
    questionId,
    value: unavailableMedia ? 'unavailableMedia' : value
  }))
});
