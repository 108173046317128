import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult, Query, OrderDefinition } from '../../stores/dataStore';
import { UserStore } from '../../stores/users/users-store';
import { DropdownProps, SelectionInput } from '../form/selectionInput';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';

interface UserByRoleEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  role: string;
  inverseRoles?: boolean;
  onChange: (value: ItemReference) => void;
  className?: string;
  placeholder?: string;
  users?: UserStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  activeOnly?: boolean;
  pillarIdFilter?: string;
}

interface UserByRoleEditorState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['users', UserStore])
class UserByRoleEditor extends React.Component<UserByRoleEditorProps, UserByRoleEditorState> {
  private get usersStore() {
    return this.props.users;
  }

  constructor(props: UserByRoleEditorProps) {
    super(props);
    this.state = {
      query: this.getUserMethod()
    };
  }

  @autobind
  private getUserMethod() {
    const { activeOnly, role, inverseRoles, pillarIdFilter } = this.props;
    const method = async (search: string) => {
      const filter: any[] = isNullOrWhiteSpaces(search)
        ? []
        : [`contains(tolower(Name), '${search.toLowerCase()}') or contains(tolower(Surname), '${search.toLowerCase()}')`];
      if (activeOnly != null) filter.push({ Enabled: !!activeOnly });
      if (pillarIdFilter) filter.push({ PillarId: { eq: { value: pillarIdFilter, type: 'guid' } } });

      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: 'name', useProfile: false }];
      const query: Query = { searchQuery: '', skip: 0, take: 100000, orderBy, filter };
      const result = await this.usersStore.getAllUsersWithRoleAsync(query, role, null, inverseRoles);

      const data = pillarIdFilter ? (result.items || []).filter(x => pillarIdFilter === x?.pillarId) : result.items;
      const items = data.map(c => ({ id: c.id, title: c.firstName + ' ' + c.lastName, ...c }));

      return Object.assign({}, result, { items }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const query = this.state.query;
    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        searchable
        className={this.props.className}
        readOnly={this.props.readonly}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={this.props.value}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(UserByRoleEditor);
