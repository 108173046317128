import React, { Component } from 'react';
import { DatesRangeInput as SemanticDatesRangeInput } from '@planit/semantic-ui-calendar-react';
import { Icon } from 'semantic-ui-react';
import './dateInput.less';
import { DateTimeService } from 'services/datetime-service';
import 'moment/locale/en-gb';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface DatesRangeInputProps {
  fromValue: string;
  toValue: string;
  initialValue?: string;
  onChange: (fromDate: string, toDate: string) => void;
  localeDate?: boolean;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  clearable?: boolean;
  forceOpen?: boolean;
  id?: string;
  required?: boolean;
  minDate?: string;
  maxDate?: string;
  iconPosition?: 'left' | 'right';
  dontUseRedClearIconColor?: boolean;
  style?: React.CSSProperties;
  allowSameEndDate?: boolean;
  onlySelectableFromCalendar?: boolean;
  icon?: SemanticICONS | false;
  getEveryChange?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface DatesRangeInputState {
  fromValue: string;
  toValue: string;
}

export class DatesRangeInput extends Component<DatesRangeInputProps, DatesRangeInputState> {
  dateElement: SemanticDatesRangeInput = undefined;
  dateSeparator: string = ' - ';
  state: DatesRangeInputState = { fromValue: this.props.fromValue, toValue: this.props.toValue };

  componentDidUpdate(nextProps: DatesRangeInputProps) {
    if (this.props.forceOpen !== nextProps.forceOpen) {
      if (this.dateElement) {
        if (this.props.forceOpen) {
          if (this.props.id) document.getElementById(this.props.id).focus();
          setTimeout(() => this.dateElement.openPopup(), 400);
        } else this.dateElement.closePopup();
      }
    }
  }

  private handleValue = (value: string) => {
    const { onChange, getEveryChange, onlySelectableFromCalendar } = this.props;
    let start: string;
    let end: string;
    let startDateOk: boolean = false;
    let endDateOk: boolean = false;
    const dash = '-';

    let date = value.replace(DateTimeService.dateRangeInputRegex, '');
    const typingEndDate = date.indexOf(dash) > -1;

    if (isNullOrWhiteSpaces(date)) {
      start = null;
      end = null;
      startDateOk = true;
      endDateOk = true;
    } else {
      const split = date.split(dash).map(d => d.trim());
      const from = split[0];
      const to = split[1];
      start = from;
      end = to;

      if (onlySelectableFromCalendar || DateTimeService.isValid(from)) {
        start = DateTimeService.toString(from, DateTimeService.dateInputFormat);
        startDateOk = true;
      }
      if (onlySelectableFromCalendar || DateTimeService.isValid(to)) {
        end = DateTimeService.toString(to, DateTimeService.dateInputFormat);
        endDateOk = true;
      }
    }

    if (startDateOk && (endDateOk || !typingEndDate || getEveryChange)) {
      if (onChange) {
        if (DateTimeService.isGreaterThan(end, start)) onChange(start, end);
        else this.props.onChange(end, start);
      }
      if (startDateOk && endDateOk) this.dateElement.closePopup();
    }
    this.setState({ fromValue: start, toValue: end });
  };

  private getValueStr = (from, to) => {
    let parts = [];
    if (!isNullOrWhiteSpaces(from)) {
      parts.push(from);
      if (!isNullOrWhiteSpaces(to) || DateTimeService.isValid(from)) {
        parts.push(to);
        if (DateTimeService.isValid(from) && DateTimeService.isValid(to)) {
          if (DateTimeService.isGreaterThan(from, to)) {
            parts = [];
            parts.push(to);
            parts.push(from);
          }
        }
      }
    }
    return parts.join(this.dateSeparator);
  };

  public render() {
    const { fromValue, toValue } = this.state;
    const { onlySelectableFromCalendar, dontUseRedClearIconColor, iconPosition: iconPos, initialValue: initialVal, ...rest } = this.props;
    const { allowSameEndDate, minDate: min, maxDate: max, placeholder, icon, className, clearable, readOnly, id, required, style } = rest;
    const fromVal = fromValue
      ? !onlySelectableFromCalendar && !DateTimeService.isValid(fromValue)
        ? fromValue
        : (!min && !initialVal) || DateTimeService.isGreaterThan(fromValue, min)
        ? DateTimeService.toDateInputString(fromValue)
        : DateTimeService.toDateInputString(min)
      : '';
    const toVal = toValue
      ? !onlySelectableFromCalendar && !DateTimeService.isValid(toValue)
        ? toValue
        : (!max && !initialVal) || !DateTimeService.isGreaterThan(toValue, max)
        ? DateTimeService.toDateInputString(toValue)
        : DateTimeService.toDateInputString(max)
      : '';
    const minDate = min && DateTimeService.toDateInputString(min);
    const maxDate = max && DateTimeService.toDateInputString(max);
    const iconPosition = iconPos ? iconPos : 'left';
    const initialValue = initialVal ? DateTimeService.toDateInputString(initialVal) : '';

    return (
      <SemanticDatesRangeInput
        ref={input => (this.dateElement = input)}
        minDate={minDate}
        maxDate={maxDate}
        placeholder={placeholder}
        className={className}
        animation={null}
        popupPosition="bottom left"
        name="date"
        closeOnMouseLeave={false}
        closable={true}
        clearIcon={<Icon name="remove" color={dontUseRedClearIconColor != null && !!dontUseRedClearIconColor ? undefined : 'red'} />}
        clearable={clearable}
        icon={icon}
        duration={200}
        hideMobileKeyboard
        iconPosition={iconPosition}
        autoComplete="off"
        readOnly={readOnly}
        dateFormat={DateTimeService.dateInputFormat}
        value={this.getValueStr(fromVal, toVal)}
        popupNodeClass={'jti-popupNode'}
        id={id}
        onChange={(_, d) => this.handleValue(d.value)}
        required={required}
        localization={'en-gb'}
        initialDate={initialValue}
        style={style}
        allowSameEndDate={allowSameEndDate}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}
