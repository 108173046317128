import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NewTnaDevelopmentPlanStore } from 'stores/development-plan/tna-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Message, Loader, Dimmer } from 'semantic-ui-react';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { ItemReference } from 'stores/dataStore';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import YearEditor from 'widgets/bussiness/year-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import UserProfileList, { UserProfileListViewModel } from './user-profile-list';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import './new-development-plans.modal.less';

export interface NewDevelopmentPlansModalProps extends WithTranslation {
  newTnaDevelopmentPlanStore?: NewTnaDevelopmentPlanStore;
}

interface NewDevelopmentPlansModalState {
  open: boolean;
  openChooseEmployeesModal: boolean;

  profile: ProfileItemDto;
  employees: UserProfileListViewModel[];
  activity: ItemReference;
  year?: number;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  trainingLevel: ItemReference;
}

const initialState = {
  open: false,
  employees: [],
  activity: null,
  profile: null,
  year: null,
  cluster: null,
  equipmentType: null,
  machineModel: null,
  trainingLevel: null,
  oem: null
};

@connect(['newTnaDevelopmentPlanStore', NewTnaDevelopmentPlanStore])
class NewDevelopmentPlansModal extends Component<NewDevelopmentPlansModalProps, NewDevelopmentPlansModalState> {
  state: NewDevelopmentPlansModalState = {
    open: false,
    openChooseEmployeesModal: false,
    employees: [],
    activity: null,
    profile: null,
    year: null,
    cluster: null,
    equipmentType: null,
    machineModel: null,
    trainingLevel: null,
    oem: null
  };

  private handleOnValueChanged = (property: keyof NewDevelopmentPlansModalState, value: any) => {
    let newState = { ...this.state };
    const { cluster, oem, equipmentType } = this.state;
    newState[property as string] = value;

    if (property === 'cluster' && value?.id !== cluster?.id) {
      newState.equipmentType = null;
      newState.oem = null;
      newState.machineModel = null;
    }
    if (property === 'equipmentType' && value?.id !== equipmentType?.id) {
      newState.oem = null;
      newState.machineModel = null;
    }
    if (property === 'oem' && value?.id !== oem?.id) newState.machineModel = null;

    this.setState(newState);
  };

  private handleOnProfileChanged = (profile: ProfileItemDto) => {
    const currentProfile = this.state.profile;

    if (!currentProfile || !profile || profile.id !== currentProfile.id) this.setState({ profile: profile, employees: [] });
  };

  private handleOnClose = () => {
    this.setState({ ...initialState });
    this.props.newTnaDevelopmentPlanStore.reset();
  };

  private handleOnAccept = () => {
    const { profile, employees, machineModel, trainingLevel, activity, year } = this.state;

    this.props.newTnaDevelopmentPlanStore.createNew({
      deadlineYear: year,
      eventTypeId: activity?.id,
      machineModelId: machineModel?.id,
      profileItemId: profile?.id,
      trainingLevelId: trainingLevel?.id,
      usersId: employees.map(x => x.id)
    });

    this.props.newTnaDevelopmentPlanStore.submit().then(x => {
      if (x.isSuccess) this.setState({ ...initialState });
    });
  };

  private handleOnAddEmployees = (newEmployees: UserProfilesDto[]) => {
    const { profile, trainingLevel } = this.state;
    const employeeListViewModels: UserProfileListViewModel[] = (newEmployees || []).map(x => {
      const { userId, employeeId, lastName, firstName, sfPosition, locationName, locationCountryName } = x;
      const employee = `${lastName}, ${firstName}`;
      const location = `${locationName} - ${locationCountryName}`;
      return { id: userId, employeeId, employee, sfPosition, location, profile: profile?.name, trainingLevel: trainingLevel?.title || '' };
    });

    this.setState(({ employees }) => ({ employees: [...employees, ...employeeListViewModels] }));
  };

  private handleOnCloseEmployeesModal = () => {
    this.setState({ openChooseEmployeesModal: false });
  };

  private handleOnSelectEmployeesClicked = () => {
    this.setState({ openChooseEmployeesModal: true });
  };

  private handleOnChangeEmployees = (newEmployees: UserProfileListViewModel[]) => {
    this.setState({ employees: [...newEmployees] });
  };

  render() {
    const { t, newTnaDevelopmentPlanStore: store } = this.props;
    const { openChooseEmployeesModal, equipmentType, machineModel, trainingLevel } = this.state;
    const { open, profile, employees, activity, year, cluster, oem } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <>
        <Button className="new-entry-btn inverted-color-btn" icon onClick={() => this.setState({ open: true })}>
          {t('Add New Entry')}
        </Button>
        <Modal
          style={{ transform: 'translateY(5%)' }}
          open={open}
          className="new-development-plan-modal"
          closeOnEscape={true}
          onClose={this.handleOnClose}
          centered={false}
        >
          <Dimmer active={store.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate />
          </Dimmer>
          <Modal.Header className="new-development-plan-modal__header">{t('New Entry in TNA Development Plan')}</Modal.Header>
          <Modal.Content className="new-development-plan-modal__content">
            {messages.length > 0 && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={messages.map(o => o.body)}
                onDismiss={store.clearMessages}
              />
            )}
            <div className="new-development-plan-modal__field">
              <label>{t('Select Profile')}</label>
              <ProfileEditor
                className="custom-editor"
                placeholder={t('Profile')}
                assessment="TNA"
                nullable
                value={profile?.id}
                onChange={value => this.handleOnProfileChanged(value)}
              />
            </div>
            <div className="new-development-plan-modal__field">
              <label>{t('Select Employees')}</label>
              <Button
                basic
                className="employee-select-btn"
                icon="user plus"
                disabled={!profile || !profile.id}
                onClick={this.handleOnSelectEmployeesClicked}
              ></Button>
              {openChooseEmployeesModal && (
                <ChooseEmployeesView
                  showProfileInColumn
                  profileId={profile?.id}
                  alreadySelectedUserIds={employees.map(x => x.id)}
                  onAddEmployees={this.handleOnAddEmployees}
                  onCloseUsersModal={this.handleOnCloseEmployeesModal}
                />
              )}
            </div>
            {employees.length > 0 && <UserProfileList userProfiles={employees} canRemove onChange={this.handleOnChangeEmployees} />}
            <div className="new-development-plan-modal__field new-development-plan-modal__machinery">
              <label>{t('Select Machinery')}</label>
              <div className="new-development-plan-modal__machinery__field">
                <div className="flex-column">
                  <label>{t('Cluster')}</label>
                  <MRClusterEditor
                    className="custom-editor"
                    clearable
                    nullable
                    placeholder={t('Cluster')}
                    value={cluster}
                    onChange={value => this.handleOnValueChanged('cluster', value)}
                  />
                </div>
                <div className="flex-column">
                  <label>{t('Equipment Type')}</label>
                  <EquipmentTypeEditor
                    className="custom-editor"
                    clearable
                    nullable
                    placeholder={t('Equipment Type')}
                    readOnly={!cluster || !cluster.id}
                    clusterId={cluster?.id || null}
                    value={equipmentType}
                    onChange={value => this.handleOnValueChanged('equipmentType', value)}
                    clearOnReload
                  />
                </div>
                <div className="flex-column">
                  <label>{t('OEM')}</label>
                  <OemEditor
                    className="custom-editor"
                    nullable
                    clearable
                    placeholder={t('OEM')}
                    readonly={!equipmentType || !equipmentType.id}
                    equipmentId={equipmentType?.id || null}
                    value={oem}
                    onChange={value => this.handleOnValueChanged('oem', value)}
                    clearOnReload
                  />
                </div>
                <div className="flex-column">
                  <label>{t('Machine Model')}</label>
                  <MachineModelEditor
                    className="custom-editor"
                    nullable
                    clearable
                    placeholder={t('Machine Model')}
                    value={machineModel}
                    readonly={!equipmentType || !equipmentType.id || !oem || !oem.id}
                    oemId={oem?.id || null}
                    equipmentId={equipmentType?.id || null}
                    onChange={value => this.handleOnValueChanged('machineModel', value)}
                    clearOnReload
                  />
                </div>
                <div className="flex-column">
                  <label>{t('Training Level')}</label>
                  <TrainingLevelEditor
                    className="custom-editor"
                    nullable
                    clearable
                    placeholder={t('Training Level')}
                    value={trainingLevel}
                    readOnly={!machineModel || !machineModel.id}
                    onChange={value => this.handleOnValueChanged('trainingLevel', value)}
                  />
                </div>
              </div>
            </div>
            <div className="new-development-plan-modal__field flex-start">
              <label>{t('Select Activity')}</label>
              <EventTypeEditor
                className="custom-editor"
                placeholder={t('Activity')}
                nullable
                clearable
                useOriginalEventTypeIdAsValue
                onChange={value => this.handleOnValueChanged('activity', value)}
                value={activity}
              />
            </div>
            <div className="new-development-plan-modal__field">
              <label>{t('Select Deadline')}</label>
              <YearEditor
                className="custom-editor year-editor"
                placeholder={t('Year')}
                value={year}
                clearable
                onChange={value => this.handleOnValueChanged('year', value)}
              />
            </div>
          </Modal.Content>
          <Modal.Actions className="new-development-plan-modal__actions">
            <Button className="basic" onClick={this.handleOnClose}>
              {t('Cancel')}
            </Button>
            <Button className="positive" onClick={this.handleOnAccept}>
              {t('Accept')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(NewDevelopmentPlansModal);
