import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Input, Message } from 'semantic-ui-react';
import { MachineForLocationDto, MachinesForLocationsStore } from 'stores/locationsmanagement/machines-per-location-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import NewMachineForLocationView from './new-machine-per-location';
import MultiLocationEditor from 'widgets/bussiness/multi-location-editor';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface MachinesForLocationsListProps extends WithTranslation, RouteComponentProps {
  machinesforlocations: MachinesForLocationsStore;
}

export interface MachinesForLocationsListState {
  query: Query;
  newMachineForLocationShown: boolean;
  activeFilters: string[];
  tableFilters: string[];
  locationsIdentifiers: string[];
  locationIdentifier: string;
  someFilterOpened: boolean;
}

@connect(['machinesforlocations', MachinesForLocationsStore])
class MachinesForLocationsListPage extends React.Component<MachinesForLocationsListProps, MachinesForLocationsListState> {
  constructor(props) {
    super(props);

    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newMachineForLocationShown: false,
      activeFilters: [],
      tableFilters: [],
      locationsIdentifiers: [],
      locationIdentifier: '',
      someFilterOpened: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.load();
  }

  @autobind
  private setFilterLocationPoc() {
    const currentUserInfo = this.identityService.getUserInfo();

    if (IdentityService.isPoc(currentUserInfo) && this.isFilterEmpty()) {
      const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
      const filter = pocLocations.map(l => `locationId eq ${l}`).join(' or ');
      Object.assign(this.state.query, { filter, skip: 0 });
    }
  }

  @autobind
  private isFilterEmpty() {
    if (!this.state.query.filter) return true;

    let filter = this.state.query.filter as any[];

    if (typeof filter === 'string') {
      return false;
    } else {
      if ((filter || []).any(x => !isNullOrEmpty(x))) return false;
      else return true;
    }
  }

  @autobind
  private load() {
    this.setFilterLocationPoc();
    this.props.machinesforlocations.getAllAsync(this.state.query);
  }

  @autobind
  private changeLocationFilter() {
    const currentUserInfo = this.identityService.getUserInfo();
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    let filter = '';

    if (this.state.locationIdentifier != null) {
      const searchQuery: string = 'locationId eq ' + this.state.locationIdentifier;
      const activeFilters = [searchQuery];
      filter = searchQuery;

      const query = Object.assign(this.state.query, { filter, skip: 0 });
      this.setState({ query, activeFilters }, () => this.load());
    } else {
      const searchQuery: string = '';
      const activeFilters = [searchQuery];
      filter = searchQuery;

      const query = Object.assign(this.state.query, { filter, skip: 0 });

      if (IdentityService.isPoc(currentUserInfo)) {
        filter = pocLocations.map(l => `locationId eq ${l}`).join(' or ');
        Object.assign(this.state.query, { filter, skip: 0 });
      }

      this.setState({ query, activeFilters }, () => this.load());
    }
  }

  @autobind private multiChangeLocationsFilters(locationIds: string[]) {
    if (locationIds != null) {
      const locationFilter = locationIds.length > 0 ? `(${locationIds.map(l => `locationId eq ${l}`).join(' or ')})` : '';
      const filter = [...this.state.tableFilters, locationFilter];
      const query = Object.assign(this.state.query, { filter, skip: 0 });
      this.setState({ query, locationsIdentifiers: locationIds }, () => this.load());
    }
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: MachineForLocationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.machinesforlocations.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: MachineForLocationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.machinesforlocations.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filtersWithId: { id: string; filter: any }[]) {
    let filters = filtersWithId.map(f => f.filter);
    let tableFilters = filtersWithId.map(f => f.filter);
    const activeFilters = filtersWithId.map(f => f.id);

    const currentUserInfo = this.identityService.getUserInfo();
    if (IdentityService.isPoc(currentUserInfo) && filters.length === 0 && activeFilters.length === 0) {
      let filterStr: string;
      let query: Query;

      if (this.state.locationsIdentifiers) {
        filterStr = this.getLocationsFilter();
      } else {
        let pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
        filterStr = pocLocations.map(l => `LocationId eq ${l}`).join(' or ');
      }

      query = Object.assign(this.state.query, { filter: filterStr, skip: 0 });
      this.setState({ query, activeFilters, tableFilters }, () => this.load());
      return;
    }

    if (this.state.locationsIdentifiers) {
      filters.push(this.getLocationsFilter());
    }
    const finalQuery = Object.assign(this.state.query, { filter: filters, skip: 0 });
    this.setState({ query: finalQuery, activeFilters, tableFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newMachineForLocationShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newMachineForLocationShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private getLocationsFilter(): string {
    const locationIds = this.state.locationsIdentifiers;
    return locationIds && locationIds.length > 0 ? `(${this.state.locationsIdentifiers.map(l => `locationId eq ${l}`).join(' or ')})` : '';
  }

  @autobind
  private handleOnEnterKeydown(item: MachineForLocationDto, state: ItemState) {
    //return this.onDelete(item, state);
  }

  handleOnRowDoubleClick: (item: unknown) => void;

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    const areYouAdminOrPoC = IdentityService.isAdminOrPoc(currentUserInfo);

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          tooltipRenderer: true,
          renderer: data => <>{data.clusterName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.clusterName}
              fluid
              onChange={(e, { value }) => {
                data.clusterName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier) {
                    onFilter(
                      nameof<MachineForLocationDto>('clusterName'),
                      `contains(tolower(machineModel/Cluster/Name), '${value.toLowerCase()}') and locationId eq ${
                        this.state.locationIdentifier
                      }`
                    );
                  } else {
                    onFilter(
                      nameof<MachineForLocationDto>('clusterName'),
                      `contains(tolower(machineModel/Cluster/Name), '${value.toLowerCase()}') and (${pocLocations
                        .map(l => `LocationId eq ${l}`)
                        .join(' or ')})`
                    );
                  }
                } else {
                  onFilter(
                    nameof<MachineForLocationDto>('clusterName'),
                    `contains(tolower(machineModel/Cluster/Name), '${value.toLowerCase()}')`
                  );
                }
              }}
              onClear={() => onClear(nameof<MachineForLocationDto>('clusterName'))}
              active={activeFilters.includes(nameof<MachineForLocationDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: true,
          renderer: data => <>{data.equipmentName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.equipmentName}
              fluid
              onChange={(e, { value }) => {
                data.equipmentName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier) {
                    onFilter(
                      nameof<MachineForLocationDto>('equipmentName'),
                      `contains(tolower(machineModel/equipmentType/Name), '${value.toLowerCase()}') and locationId eq ${
                        this.state.locationIdentifier
                      }`
                    );
                  } else {
                    onFilter(
                      nameof<MachineForLocationDto>('equipmentName'),
                      `contains(tolower(machineModel/equipmentType/Name), '${value.toLowerCase()}') and (${pocLocations
                        .map(l => `LocationId eq ${l}`)
                        .join(' or ')})`
                    );
                  }
                } else {
                  onFilter(
                    nameof<MachineForLocationDto>('equipmentName'),
                    `contains(tolower(machineModel/equipmentType/Name), '${value.toLowerCase()}')`
                  );
                }
              }}
              onClear={() => onClear(nameof<MachineForLocationDto>('equipmentName'))}
              active={activeFilters.includes(nameof<MachineForLocationDto>('equipmentName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('OEM'),
          tooltipRenderer: true,
          renderer: data => <>{data.oemName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.oemName}
              fluid
              onChange={(e, { value }) => {
                data.oemName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by OEM')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier) {
                    onFilter(
                      nameof<MachineForLocationDto>('oemName'),
                      `contains(tolower(machineModel/oem/Name), '${value.toLowerCase()}') and locationId eq ${
                        this.state.locationIdentifier
                      }`
                    );
                  } else {
                    onFilter(
                      nameof<MachineForLocationDto>('oemName'),
                      `contains(tolower(machineModel/oem/Name), '${value.toLowerCase()}') and (${pocLocations
                        .map(l => `LocationId eq ${l}`)
                        .join(' or ')})`
                    );
                  }
                } else {
                  onFilter(nameof<MachineForLocationDto>('oemName'), `contains(tolower(machineModel/oem/Name), '${value.toLowerCase()}')`);
                }
              }}
              onClear={() => onClear(nameof<MachineForLocationDto>('oemName'))}
              active={activeFilters.includes(nameof<MachineForLocationDto>('oemName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: true,
          renderer: data => <>{data.machineModelName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.machineModelName}
              fluid
              onChange={(e, { value }) => {
                data.machineModelName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Machine Model')}
              triggerTitle={title}
              onFilter={value => {
                if (IdentityService.isPoc(currentUserInfo)) {
                  if (this.state.locationIdentifier) {
                    onFilter(
                      nameof<MachineForLocationDto>('machineModelName'),
                      `contains(tolower(machineModel/Name), '${value.toLowerCase()}') and locationId eq ${this.state.locationIdentifier}`
                    );
                  } else {
                    onFilter(
                      nameof<MachineForLocationDto>('machineModelName'),
                      `contains(tolower(machineModel/Name), '${value.toLowerCase()}') and (${pocLocations
                        .map(l => `LocationId eq ${l}`)
                        .join(' or ')})`
                    );
                  }
                } else {
                  onFilter(
                    nameof<MachineForLocationDto>('machineModelName'),
                    `contains(tolower(machineModel/Name), '${value.toLowerCase()}')`
                  );
                }
              }}
              onClear={() => onClear(nameof<MachineForLocationDto>('machineModelName'))}
              active={activeFilters.includes(nameof<MachineForLocationDto>('machineModelName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data => <>{data.locationName}</>,
          editor: (data, onChange) => (
            <Input
              value={data.locationName}
              fluid
              onChange={(e, { value }) => {
                data.locationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true
        },
        {
          title: t('Country'),
          tooltipRenderer: true,
          renderer: data => this.getCountryWithFlag(data.location),
          selectableHeader: true
        }
      ],
      data: this.props.machinesforlocations.state
    } as TableModel<MachineForLocationDto>;

    return (
      <>
        <div className="machines-per-location">
          <Grid className="event-types-list-grid">
            {this.props.machinesforlocations.state.result && !this.props.machinesforlocations.state.result.isSuccess && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={this.props.machinesforlocations.state.result.messages.map(o => o.body)}
                />
              </Grid.Row>
            )}
            <div className="filters-wrapper table__filters-share-main-actions">
              <MultiLocationEditor
                value={this.state.locationsIdentifiers}
                filteredLocations={areYouPoc ? pocLocations : null}
                onChange={location => this.multiChangeLocationsFilters(location)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </div>
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={!this.state.newMachineForLocationShown}
                maxSelection={1}
                onHideCheckbox={true}
                selectionType={'allRow'}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                showActionsConfirmModal={true}
                preventEnterKeyDownEvent={someFilterOpened}
                /////////////////For build table keyboard navegation/////////////////
                model={tableModel}
                onOrderByChanged={this.handleOrderBy}
                onNewItem={this.onNewItem}
                onRefresh={this.load}
                canEdit={false}
                canDelete={areYouAdminOrPoC} // Admin and PoC can create New and Delete
                onDeleteRow={this.onDelete}
                onSaveRow={this.onSaveRow}
                onPageChange={this.handlePageChange}
                onFilterChange={this.handleFilterChange}
                canCreateNew={areYouAdminOrPoC} // Admin and Poc can create New and Delete
                createNewButtonTitle={t('Add Machine Per Location')}
              ></TableView>
            </Grid.Row>
          </Grid>
        </div>
        {this.state.newMachineForLocationShown && <NewMachineForLocationView onClose={this.onNewItemClosed} {...this.props} />}
      </>
    );
  }

  private getCountryWithFlag = (location: LocationDto) => {
    if (!location) return <></>;
    return (
      <>
        <CountryFlag countryName={location.countryName} countryIsoCode={location.isoCode} />
        &nbsp;{location.countryName}
      </>
    );
  };
}

export default withTranslation()(MachinesForLocationsListPage);
