export const nameof = <T>(name: keyof T) => name;

export const clone = <T>(object: any): T => {
  //if (typeof object === 'object')
  //    return Object.assign({}, object) as T;
  //return object as T;

  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == object || 'object' != typeof object) return object;

  // Handle Date
  if (object instanceof Date) {
    copy = new Date();
    copy.setTime(object.getTime());
    return (copy as unknown) as T;
  }

  // Handle Array
  if (object instanceof Array) {
    copy = [];
    for (let i = 0, len = object.length; i < len; i++) {
      copy[i] = clone(object[i]);
    }
    return (copy as unknown) as T;
  }

  // Handle Object
  if (object instanceof Object) {
    copy = {} as any;
    for (let attr in object) {
      if (object.hasOwnProperty(attr)) copy[attr] = clone(object[attr]);
    }
    return (copy as unknown) as T;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

// tslint:disable-next-line:variable-name
export const formatDecimal = (number: number) => {
  return number ? number.toLocaleString() : 0;
};

export const getProperties = (o: any): { key: string; value: any }[] => {
  const result = [];
  for (const key in o) {
    if (o.hasOwnProperty(key)) {
      result.push({ key, value: o[key] });
    }
  }

  return result;
};

export const getKeyValuePairs = <V>(o: any): { key: string; value: V }[] => {
  const result = [];
  for (const key in o) {
    if (o.hasOwnProperty(key)) {
      result.push({ key, value: (o[key] as unknown) as V });
    }
  }

  return result;
};

const StringIsNumber = value => isNaN(Number(value)) === false && Number(value) > 0;

export const EnumToArray = (enumme): any[] => {
  let options = Object.keys(enumme)
    .filter(StringIsNumber)
    .map(key => ({ key: Number(key), value: Number(key), text: enumme[key] }));
  return options;
};

export const ArrayToObjectStringDict = (array: any[]): any => {
  const objStringict = array.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
  return objStringict;
};
