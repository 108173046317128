import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import './wizard-step.less';

export interface StepsComponentProps extends WithTranslation {
  number: string;
  title: string;
  active: boolean;
}

class StepsComponent extends React.Component<StepsComponentProps> {
  constructor(props: StepsComponentProps) {
    super(props);
    this.state = {
      loading: null,
      data: null
    };
  }

  render() {
    const { t, active } = this.props;

    return (
      <>
        <div className={active ? 'wizard__label-title flex-start' : 'wizard__label-title flex-start inactive-step'}>
          <Label className="step__label-style" circular>
            {this.props.number}
          </Label>
          <p className="template-wizard__step-title">{t(this.props.title)}</p>
        </div>
        {active && this.props.children}
      </>
    );
  }
}

export default withTranslation()(StepsComponent);
