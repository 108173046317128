import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateInput } from 'widgets/form/dateInput';
import './general-data-step.less';
import { DateTimeService } from 'services/datetime-service';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { trim, isNullOrEmpty } from 'utils/useful-functions';

export interface GeneralDataViewModel {
  title: string;
  location: ItemReference;
  profileIds: string[];
  deadline1: string;
  deadline2: string;
  deadline3: string;
  profilesDict: { [profId: string]: string };
}

export interface GeneralDataStepProps extends WithTranslation {
  isStepActive: boolean;
  value: GeneralDataViewModel;
  onChange?: (value: GeneralDataViewModel) => void;
  isOnEditMode: boolean;
  isGlobalPoc: boolean;
}

export interface GeneralDataStepState {
  isStepActive: boolean;
  profileNames: string[];
}
const titleSeparator: string = ' | ';

class GeneralDataStep extends Component<GeneralDataStepProps, GeneralDataStepState> {
  @resolve(IdentityService)
  private identityService: IdentityService;
  static defaultProps = { onChange: (...args) => {} };

  constructor(props: GeneralDataStepProps) {
    super(props);
    let pNames = Object.values(this.props.value.profilesDict);
    this.state = {
      isStepActive: !!this.props.isStepActive,
      profileNames: pNames
    };
  }

  UNSAFE_componentWillReceiveProps(next: GeneralDataStepProps) {
    if (next != null && next !== this.props) {
      if (next.isStepActive !== this.props.isStepActive) {
        this.setState({
          isStepActive: !!next.isStepActive
        });
      }
      this.generateTitle();
    }
  }

  private handleOnChange = (property: keyof GeneralDataViewModel, propertyValue: any) => {
    const value = { ...this.props.value };
    let profiles = this.state.profileNames;
    let profilesChanged = false;

    if (property === 'profileIds') {
      value.profileIds = propertyValue == null ? [] : propertyValue.map(x => x.id);
      value.profilesDict = propertyValue.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {});
      profiles = propertyValue == null ? [] : propertyValue.map(x => x.name);
      profilesChanged = true;
    } else value[property] = propertyValue;

    value.title = this.generateTitle();
    this.props.onChange(value);
    if (profilesChanged) this.setState({ profileNames: profiles });
  };

  private generateTitle = () => {
    const { location } = this.props.value;
    const profiles = this.state.profileNames;
    const parts: string[] = [];
    if (location != null && !isNullOrEmpty(location.title)) parts.push(trim(location.title));

    if (profiles != null && profiles.length > 0) parts.push(profiles.map(profile => trim(profile)).join(titleSeparator));

    const nextTitle = parts
      .map(x => (x == null ? '' : trim(x)))
      .filter(x => !isNullOrEmpty(x))
      .join(' ');
    if (nextTitle !== this.props.value.title) {
      this.props.value.title = nextTitle;
      this.props.onChange(this.props.value);
    }
    return nextTitle;
  };

  render() {
    const { t, isOnEditMode, isGlobalPoc } = this.props;
    const { isStepActive } = this.state;
    const value = !!this.props.value
      ? this.props.value
      : { title: '', location: null, profileIds: [], deadline1: '', deadline2: '', deadline3: '' };
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !isGlobalPoc
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    return (
      <div className="wizard__step wizard__step-one iws-form-general-data">
        <StepsComponent active={isStepActive} number={'1'} title={t('GENERAL DATA')}>
          <div className="step-one__content iws-form-wizard__step-one__title-wrapper editor-38px">
            <label>{t('SuC Title')}</label>
            <Input value={value.title} disabled className="normal-looking-disabled-input" />
          </div>

          <div className="step-one__content iws-form-step-one__content">
            <div className="content_line">
              <div className={`editor-38px w-33 ${pocLocations.length === 1 ? 'normal-looking-disabled-input' : ''}`}>
                <label>{t('Select Location')}</label>
                <LocationEditor
                  loadDataOnOpen={true}
                  nullable={pocLocations.length !== 1}
                  clearable={pocLocations.length !== 1}
                  readOnly={pocLocations.length === 1 || isOnEditMode}
                  placeholder={t('Location')}
                  value={value.location}
                  onChange={l => this.handleOnChange('location', l)}
                  locationsReceived={pocLocations}
                />
              </div>
              <div className="editor-38px w-66">
                {!isOnEditMode && (
                  <>
                    <label>{t('Who is this SuC for?')}</label>
                    <ProfileEditor
                      readonly={isOnEditMode}
                      assessment={'IWS'}
                      nullable
                      values={value.profileIds}
                      className="custom-editor w-xl"
                      clearable
                      placeholder={t('Profile')}
                      multiple={true}
                      onChangeMulti={profiles => this.handleOnChange('profileIds', profiles)}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="content_line mt-20">
              <div className="iws-form-wizard__step-one__deadline-wrapper editor-38px w-33">
                <label>{t('1st Assessment Deadline')}</label>
                <DateInput
                  propagateOnInvalid
                  value={value.deadline1}
                  onChange={(_, d) => this.handleOnChange('deadline1', d)}
                  iconPosition="right"
                  minDate={isOnEditMode ? null : DateTimeService.toString(DateTimeService.today())}
                />
              </div>
              <div className="iws-form-wizard__step-one__deadline-wrapper editor-38px w-33">
                <label>{t('2nd Assessment Deadline')}</label>
                <DateInput
                  propagateOnInvalid
                  value={value.deadline2}
                  onChange={(_, d) => this.handleOnChange('deadline2', d)}
                  iconPosition="right"
                  minDate={isOnEditMode ? null : value.deadline1 ? value.deadline1 : DateTimeService.toString(DateTimeService.today())}
                />
              </div>
              <div className="iws-form-wizard__step-one__deadline-wrapper editor-38px w-33">
                <label>{t('3rd Assessment Deadline')}</label>
                <DateInput
                  propagateOnInvalid
                  value={value.deadline3}
                  onChange={(_, d) => this.handleOnChange('deadline3', d)}
                  iconPosition="right"
                  minDate={isOnEditMode ? null : value.deadline2 ? value.deadline2 : DateTimeService.toString(DateTimeService.today())}
                />
              </div>
            </div>
          </div>
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(GeneralDataStep);
