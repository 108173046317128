export interface IConstants {
  authority: string;
  clientId: string;
  clientRoot: string;
  clientScope: string;
  apiBaseUrl: string;
}

const constants: IConstants = {
  apiBaseUrl: (window.SERVER_DATA || {}).apiBaseUrl || process.env.REACT_APP_apiBaseUrl || undefined,
  authority: (window.SERVER_DATA || {}).authority || process.env.REACT_APP_authority || undefined,
  clientId: (window.SERVER_DATA || {}).clientId || process.env.REACT_APP_clientId || undefined,
  clientRoot: (window.SERVER_DATA || {}).clientRoot || process.env.REACT_APP_clientRoot || undefined,
  clientScope: (window.SERVER_DATA || {}).clientScope || process.env.REACT_APP_clientScope || undefined
};

export { constants };
