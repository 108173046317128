import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
import './feedback-form.less';
import { FeedBackFormViewModelStore } from 'stores/assessments/forms/feedback-form.store';
import { connect } from 'redux-scaffolding-ts';
import { ItemReference } from 'stores/dataStore';

export interface FeedbackFormHeaderProps extends WithTranslation {
  feedBackFormViewModelStore?: FeedBackFormViewModelStore;
}

@connect(['feedBackFormViewModelStore', FeedBackFormViewModelStore])
class FeedbackFormHeader extends Component<FeedbackFormHeaderProps> {
  private onLanguageChanged = (language: ItemReference) => {
    this.props.feedBackFormViewModelStore.change({
      selectedLanguage: language
    });
  };

  render() {
    const { t, feedBackFormViewModelStore: store } = this.props;
    const { userFirstName, userLastName, eventFriendlyId, eventTitle, selectedLanguage } = store.state.item;

    return (
      <>
        <div className="feedback-form__header__data-language flex-between">
          <div className="feedback-form__header__general-info">
            <div className="feedback-form__header__general-info__element">
              <h5>{t('Employee')}</h5>
              <p>
                {userFirstName} {userLastName}
              </p>
            </div>

            <div className="feedback-form__header__general-info__element">
              <h5>{t('Event')}</h5>
              <p>
                {eventFriendlyId} - {eventTitle}
              </p>
            </div>
          </div>

          <LanguageEditor
            className="planit-user-dropdown-custom"
            placeholder={t('Select Language')}
            clearable
            nullable
            value={selectedLanguage}
            onChange={value => this.onLanguageChanged(value)}
            onlyForTests
          />
        </div>
        <Divider />
      </>
    );
  }
}

export default withTranslation()(FeedbackFormHeader);
