import React from 'react';
import Step from 'widgets/form/templates-step-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MrclusterEditor from 'widgets/bussiness/mrcluster-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import FunctionalSubareaEditor from 'widgets/bussiness/functional-subarea-editor';
import './machinery-step.less';
import {
  TheoreticalTemplateWizardViewModel,
  TheoreticalTemplateWizardStore
} from 'stores/assessments/wizard/theoretical-template-wizard-store';
import { connect } from 'redux-scaffolding-ts';

export interface MachineryStepProps extends WithTranslation {
  active: boolean;
  onStepChanged?: () => void;
  theoreticalTemplateWizardStore?: TheoreticalTemplateWizardStore;
  mode: string;
}

export interface MachineryStepState {}

@connect(['theoreticalTemplateWizardStore', TheoreticalTemplateWizardStore])
class MachineryStep extends React.Component<MachineryStepProps, MachineryStepState> {
  private handleOnChange = (property: keyof TheoreticalTemplateWizardViewModel, propertyValue: any, mr?: boolean) => {
    let changes: any = {};

    changes = {
      ...changes,
      ...this.handleMrHandleOnChange(property, propertyValue, mr),
      ...this.handleNMrHandleOnChange(property, propertyValue, mr)
    };

    changes[property] = propertyValue;

    this.props.theoreticalTemplateWizardStore.resetData(changes);
    this.props.onStepChanged && this.props.onStepChanged();
  };

  handleMrHandleOnChange = (property: keyof TheoreticalTemplateWizardViewModel, value, mr) => {
    const changes: Partial<TheoreticalTemplateWizardViewModel> = {};
    const { machineRelated, mrCluster, equipmentType, oem } = this.props.theoreticalTemplateWizardStore.state.item;
    if (!machineRelated) return;

    if (property === 'mrCluster' && value?.id !== mrCluster?.id) {
      changes.questionsMr = [];
      changes.questionsNMr = [];
      if (value?.id == null || value == null) {
        changes.equipmentType = null;
        changes.oem = null;
        changes.machineModel = null;
      }
    }

    if (property === 'equipmentType' && value?.id !== equipmentType?.id) {
      changes.oem = null;
      changes.machineModel = null;
      changes.questionsMr = [];
      changes.questionsNMr = [];
    }

    if (property === 'oem' && value?.id !== oem?.id) changes.machineModel = null;
    return changes;
  };

  handleNMrHandleOnChange = (property: keyof TheoreticalTemplateWizardViewModel, value, mr) => {
    const changes: any = {};
    const { machineRelated } = this.props.theoreticalTemplateWizardStore.state.item;
    if (machineRelated) return;
    if (property === 'nmrCluster' && (value?.id == null || value == null)) {
      changes.questionsMr = [];
      changes.questionsNMr = [];
      if (value?.id == null || value == null) {
        changes.functionalArea = null;
        changes.trainingName = null;
        changes.functionalSubarea = null;
      }
    }

    if (property === 'functionalArea' && (value?.id == null || value == null)) {
      changes.trainingName = null;
      changes.functionalSubarea = null;
    }
    if (property === 'trainingName' && (value?.id == null || value == null)) changes.functionalSubarea = null;
    return changes;
  };

  render() {
    const { t, active } = this.props;
    const {
      machineRelated,
      trainingLevel,
      mrCluster,
      nmrCluster,
      functionalArea,
      functionalSubarea,
      trainingName,
      equipmentType,
      oem,
      machineModel,
      relatedTo
    } = this.props.theoreticalTemplateWizardStore.state.item;
    const hasToShowMachineModel: boolean = !mrCluster?.id || !equipmentType?.id || !oem?.id;

    return (
      <Step
        active={active}
        number="2"
        title={t('SELECT MACHINERY OR RELATION')}
        render={
          <>
            {relatedTo && relatedTo.toString() === 'Event' && (
              <div className="flex-column template-wizard__machinery__training">
                <label>{t('Training Level')}</label>
                <TrainingLevelEditor
                  clearable
                  nullable
                  className="custom-editor"
                  placeholder={t('Training Level')}
                  value={trainingLevel}
                  onChange={trainingLevel => this.handleOnChange('trainingLevel', trainingLevel)}
                  reloadOnChange
                />
              </div>
            )}

            <div className="flex-start-center template-wizard__machinery__wrapper">
              <div className="flex-column">
                <label>{t('Cluster')}</label>
                {machineRelated ? (
                  <MrclusterEditor
                    className="request-list__filter-dropdown"
                    clearable
                    nullable
                    value={mrCluster}
                    onChange={mrCluster => this.handleOnChange('mrCluster', mrCluster)}
                    placeholder={t('Cluster')}
                    reloadOnChange
                  />
                ) : (
                  <NMClusterEditor
                    placeholder={t('Cluster')}
                    clearable
                    nullable
                    value={nmrCluster}
                    onChange={nmrCluster => this.handleOnChange('nmrCluster', nmrCluster)}
                    reloadOnChange
                  />
                )}
              </div>

              {!machineRelated && (
                <>
                  <div className="flex-column">
                    <label>{t('Functional Area')}</label>
                    <NMFunctionalAreaEditor
                      placeholder={t('Functional Area')}
                      clearable
                      nullable
                      clusterId={nmrCluster && nmrCluster.id}
                      value={functionalArea}
                      onChange={functionalArea => this.handleOnChange('functionalArea', functionalArea)}
                      reloadOnChange
                      readonly={!nmrCluster?.id}
                      clearOnReload
                    />
                  </div>

                  <div className="flex-column">
                    <label>{t('Training Name (Optional)')}</label>
                    <NMTrainingNameEditor
                      placeholder={t('Training Name')}
                      nullable
                      clearable
                      funcionalAreaId={functionalArea && functionalArea.id}
                      value={trainingName}
                      onChange={trainingName => this.handleOnChange('trainingName', trainingName)}
                      reloadOnChange
                      readOnly={!nmrCluster?.id || !functionalArea?.id}
                      clearOnReload
                    />
                  </div>

                  <div className="flex-column">
                    <label>{t('Functional Subarea (Optional)')}</label>
                    <FunctionalSubareaEditor
                      placeholder={t('Functional Subarea')}
                      clearable
                      nullable
                      trainingNameId={trainingName && trainingName.id}
                      value={functionalSubarea}
                      onChange={functionalSubarea => this.handleOnChange('functionalSubarea', functionalSubarea)}
                      readonly={!nmrCluster?.id || !functionalArea?.id || !trainingName?.id}
                      clearOnReload
                    />
                  </div>
                </>
              )}

              {machineRelated && (
                <>
                  <div className="flex-column">
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      nullable
                      clearable
                      clusterId={mrCluster && mrCluster.id}
                      value={equipmentType}
                      onChange={equipmentType => this.handleOnChange('equipmentType', equipmentType)}
                      placeholder={t('Equipment Type')}
                      clearOnReload
                      readOnly={!mrCluster?.id}
                    />
                  </div>

                  <div className="flex-column">
                    <label>{t('OEM (Optional)')}</label>
                    <OemEditor
                      nullable
                      clearable
                      value={oem}
                      onChange={oem => this.handleOnChange('oem', oem)}
                      equipmentId={equipmentType && equipmentType.id}
                      placeholder={t('OEM')}
                      readonly={!equipmentType?.id}
                    />
                  </div>

                  <div className="flex-column">
                    <label>{t('Machine Model (Optional)')}</label>
                    <MachineModelEditor
                      cascade="true"
                      nullable
                      clearable
                      oemId={oem && oem.id}
                      equipmentId={equipmentType && equipmentType.id}
                      value={machineModel}
                      onChange={machineModel => this.handleOnChange('machineModel', machineModel)}
                      placeholder={t('Machine Model')}
                      readonly={hasToShowMachineModel}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        }
      />
    );
  }
}

export default withTranslation()(MachineryStep);
