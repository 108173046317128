import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { nameof } from 'utils/object';
import { EquipmentTypeDto, EquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import { connect } from 'redux-scaffolding-ts';

import { OemsStore, OemDto } from 'stores/configuration/machinery/oems-store';
import { ClustersStore, ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { MachineModelsStore, MachineModelDto } from 'stores/configuration/machinery/machine-models-store';

export interface MrTagsFiltersValues {
  clusters: string[];
  equipmentTypes: string[];
  oems: string[];
  machineModels: string[];
}

export interface MRMachineryTagsFiltersProps extends WithTranslation {
  className?: string;
  placeholder?: string;
  clusterStore?: ClustersStore;
  equipmentTypeStore?: EquipmentTypesStore;
  oemsStore?: OemsStore;
  machineModelsStore?: MachineModelsStore;
  onChange?: (value: MrTagsFiltersValues) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

export interface MRTagsFiltersOptions {
  id: string;
  text: string;
  type: string;
  value: any;
}

interface MRMachineryTagsFiltersState {
  open: boolean;
  isLoading: boolean;
  options: { [id: string]: MRTagsFiltersOptions };
}
@connect(
  ['clusterStore', ClustersStore],
  ['equipmentTypeStore', EquipmentTypesStore],
  ['oemsStore', OemsStore],
  ['machineModelsStore', MachineModelsStore]
)
@connect()
class MultiSelectorMRMachineryFilters extends Component<MRMachineryTagsFiltersProps, MRMachineryTagsFiltersState> {
  state: MRMachineryTagsFiltersState = {
    open: false,
    isLoading: true,
    options: {}
  };

  private handleOnSearchChange(event, searchQuery) {
    this.setState({ open: !!searchQuery });
  }

  private handleOnOptionChange(value: any) {
    let values = this.mrTagsFiltersValues(value);
    this.setState({ open: false });
    this.props.onChange && this.props.onChange(values);
  }

  private mrTagsFiltersValues(value: any): MrTagsFiltersValues {
    let values: MrTagsFiltersValues = {
      clusters: [],
      equipmentTypes: [],
      oems: [],
      machineModels: []
    };

    (value || []).forEach(key => {
      let option = this.state.options[key];
      values[option.type].push(option.value);
    });

    return values;
  }

  private toDropdownOptions() {
    return Object.keys(this.state.options).map(key => {
      let option = this.state.options[key];
      return {
        text: option.text,
        value: key
      };
    });
  }

  componentDidMount() {
    const clusters = this.getAllMrClusters();
    const equipmentTypesRequest = this.getAllEquipmentTypes();
    const oems = this.getAllOEMs();
    const machineModels = this.getAllMachineModels();

    let requests: any = [clusters, equipmentTypesRequest, oems, machineModels];

    Promise.all([...requests]).then(([clustersResponse, equipmentTypesResponse, oemResponse, machineModelsResponse]) => {
      let allOptions = {};

      this.mapClusters(clustersResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapEquipmentTypes(equipmentTypesResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapOEMs(oemResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapMachineModels(machineModelsResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));

      this.setState({
        isLoading: false,
        options: allOptions
      });
    });
  }

  private mapClusters(clusters: ClusterDto[]): MRTagsFiltersOptions[] {
    return clusters.map(cluster => ({
      value: cluster.id,
      text: cluster.name,
      id: cluster.id,
      type: nameof<MrTagsFiltersValues>('clusters')
    }));
  }

  private mapEquipmentTypes(equipmentTypes: EquipmentTypeDto[]): MRTagsFiltersOptions[] {
    return equipmentTypes.map(equipmentType => ({
      value: equipmentType.id,
      text: equipmentType.name,
      id: equipmentType.id,
      type: nameof<MrTagsFiltersValues>('equipmentTypes')
    }));
  }

  private mapOEMs(oems: OemDto[]): MRTagsFiltersOptions[] {
    return oems.map(oem => ({
      value: oem.id,
      text: oem.name,
      id: oem.id,
      type: nameof<MrTagsFiltersValues>('oems')
    }));
  }

  private mapMachineModels(machineModels: MachineModelDto[]): MRTagsFiltersOptions[] {
    return machineModels.map(machineModel => ({
      value: machineModel.id,
      text: machineModel.name,
      id: machineModel.id,
      type: nameof<MrTagsFiltersValues>('machineModels')
    }));
  }

  private buildKey(option: MRTagsFiltersOptions): string {
    return `${option.id}-${option.type}`;
  }

  private getAllMrClusters = () => {
    return this.props.clusterStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<ClusterDto>('name'), useProfile: false }],
      filter: []
    });
  };

  private getAllEquipmentTypes() {
    return this.props.equipmentTypeStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<EquipmentTypeDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllOEMs() {
    return this.props.oemsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<OemDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllMachineModels = () => {
    return this.props.machineModelsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<MachineModelDto>('name'), useProfile: false }],
      filter: []
    });
  };

  render() {
    const { placeholder, className } = this.props;

    const dropdownOptions = this.toDropdownOptions();

    return (
      <Dropdown
        placeholder={placeholder}
        className={className}
        loading={this.state.isLoading}
        multiple
        search
        clearable
        selection
        icon="search"
        open={this.state.open}
        onSearchChange={(event, { searchQuery }) => this.handleOnSearchChange(event, searchQuery)}
        onChange={(_, { value }) => this.handleOnOptionChange(value)}
        onClose={() => this.setState({ open: false })}
        options={dropdownOptions}
        selectOnBlur={false}
        closeOnBlur
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

export default withTranslation()(MultiSelectorMRMachineryFilters);
