import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

export interface YesNoAnswerProps extends WithTranslation {
  value: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}

class YesNoAnswer extends Component<YesNoAnswerProps> {
  private onChangeValue = (value: string) => {
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { t, readOnly, value } = this.props;

    return (
      <div className="feedback-form__question__buttons">
        <Button disabled={readOnly} toggle active={value === 'Yes'} onClick={() => this.onChangeValue('Yes')}>
          {t('Yes')}
        </Button>
        <Button disabled={readOnly} toggle active={value === 'No'} onClick={() => this.onChangeValue('No')}>
          {t('No')}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(YesNoAnswer);
