import React, { FunctionComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateTimePeriod, EventPositionDto, TravelRangeDto } from 'stores/events/events-store';
import './new-events-support-details.style.less';
import { Checkbox, Icon, Input } from 'semantic-ui-react';
import PeriodInput from 'widgets/form/period-input';
import moment from 'moment';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import { EventFormStore } from 'stores/events/event-form-store';

interface EventNewModelPositionComponentProps extends WithTranslation {
  eventNewModelPositions: EventPositionDto[];
  isHideEditButton: boolean;
  readonly: boolean;
  datesChangeHandler: ({ from, to }: DateTimePeriod, idx: number, travelDays: boolean) => void;
  handleTravelDays: (index: number, property: keyof TravelRangeDto, { from, to }: DateTimePeriod) => void;
  inputChangeHandler: (property: string, value: any, idx: number) => void;
  changeEmployeeHandler: (idx: number) => void;
  formatInput: (e: any) => boolean;
  removeEventNewModelPositionHandler: (index: number) => void;
  supportPositionId: string;
  machine: string;
  pattern: string;
  eventFormStore?: EventFormStore;
}

const EventNewModelPositionComponent: FunctionComponent<EventNewModelPositionComponentProps> = props => {
  const identityService: IdentityService = container.get(IdentityService);
  const isAdminPocOrPlanner = (): boolean => {
    return IdentityService.isAdminPocOrPlanner(identityService.getUserInfo());
  };
  const isInstructor = (): boolean => {
    return IdentityService.isInstructor(identityService.getUserInfo());
  };

  const canChangePosition = (userId: string): boolean => {
    const currentUser = identityService.getUserInfo();
    return isAdminPocOrPlanner() || (isInstructor() && currentUser && currentUser.userId === userId);
  };

  const handleNoTravel = (index: number, value: boolean) => {
    inputChangeHandler('noTravel', value, index);
  };

  const {
    eventNewModelPositions,
    readonly,
    isHideEditButton,
    datesChangeHandler,
    handleTravelDays,
    inputChangeHandler,
    changeEmployeeHandler,
    formatInput,
    removeEventNewModelPositionHandler,
    supportPositionId,
    machine,
    pattern,
    eventFormStore,
    t
  } = props;

  const { status } = eventFormStore.state.item;
  return (
    <div className="new-events-support-details__container">
      {(eventNewModelPositions || []).map((ep, idx) => {
        const { actualCost, actualHours, actualDays, endDate, startDate, travelDays, user, userId, plannedDays, noTravel } = ep;
        const employeeRole = user.roles.find(x => x.role.name === 'Employee');
        const employeeLocation = employeeRole?.location?.location || t('LOCATION');
        return (
          <div key={'event-position' + supportPositionId + pattern + machine + userId + idx} className="main-separator">
            <div className="events-support-details__detail">
              <div className="events-support-details__detail-position">
                <div className="info-support-details__employee-info">
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Employee')}</span>
                    <Input disabled value={t(user.lastName + ', ' + user.firstName)} />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('SAP ID')}</span>
                    <Input disabled value={user.employeeId} />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Position Code')}</span>
                    <Input disabled value={user.positionCode?.code || ''} />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Location')}</span>
                    <Input disabled value={employeeLocation} />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Line Manager')}</span>
                    <Input disabled value={user.lineManager} />
                  </div>
                </div>
                <div className="info-support-details__employee-info">
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Dates')}</span>
                    <PeriodInput
                      id={'info-support-details-dates-' + supportPositionId + pattern + machine + +userId + idx}
                      readOnly={readonly || !canChangePosition(userId)}
                      onChange={period => datesChangeHandler(period, idx, false)}
                      value={{ from: startDate, to: endDate }}
                    />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Planned Days')}</span>
                    <Input
                      labelPosition="right"
                      type="number"
                      label={{ basic: true, content: '' }}
                      disabled
                      onChange={(_, { value }) => inputChangeHandler('plannedDays', value, idx)}
                      value={
                        ((plannedDays === null || plannedDays === undefined) &&
                        startDate &&
                        endDate &&
                        moment(startDate).isSameOrBefore(moment(endDate))
                          ? inputChangeHandler('plannedDays', moment(endDate).diff(moment(startDate), 'days') + 1, idx)
                          : plannedDays || 0) || 0
                      }
                      onKeyDown={formatInput}
                    />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Actual Days')}</span>
                    <Input
                      labelPosition="right"
                      type="number"
                      label={{ basic: true, content: 'w/d' }}
                      disabled={readonly || !canChangePosition(userId)}
                      onChange={(_, { value }) => inputChangeHandler('actualDays', value, idx)}
                      value={actualDays}
                      onKeyDown={formatInput}
                    />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Actual Hours')}</span>
                    <Input
                      labelPosition="right"
                      type="number"
                      label={{ basic: true, content: 'h' }}
                      disabled={readonly || !canChangePosition(userId)}
                      onChange={(_, { value }) => inputChangeHandler('actualHours', value, idx)}
                      value={actualHours}
                      onKeyDown={formatInput}
                    />
                  </div>
                  <div className="info-support-details__employee-detailed hzt-section">
                    <span>{t('Actual Cost')}</span>
                    <Input
                      className="enlarged-input"
                      labelPosition="right"
                      label={{ basic: true, content: '$' }}
                      type="number"
                      disabled={readonly || !canChangePosition(userId)}
                      onChange={(_, { value }) => inputChangeHandler('actualCost', value, idx)}
                      value={actualCost}
                      onKeyDown={formatInput}
                    />
                  </div>
                  {status.toString() !== 'Draft' && (
                    <>
                      <div className="info-support-details__employee-detailed hzt-section">
                        <span>{t('Travel Days')}</span>
                        <DatesRangeInput
                          className="travel-days__departure"
                          dontUseRedClearIconColor
                          iconPosition="right"
                          readOnly={readonly}
                          fromValue={travelDays?.departure?.from}
                          toValue={travelDays?.departure?.to}
                          onChange={(from, to) => handleTravelDays(idx, 'departure', { from, to })}
                          placeholder={t('Departure')}
                          clearable
                          icon={false}
                          allowSameEndDate
                          getEveryChange
                        />
                        <DatesRangeInput
                          className="travel-days__arrival"
                          dontUseRedClearIconColor
                          iconPosition="right"
                          readOnly={readonly}
                          fromValue={travelDays?.arrival?.from}
                          toValue={travelDays?.arrival?.to}
                          onChange={(from, to) => handleTravelDays(idx, 'arrival', { from, to })}
                          placeholder={t('Arrival')}
                          clearable
                          icon={false}
                          allowSameEndDate
                          getEveryChange
                        />
                      </div>
                      <div className="info-support-details__employee-detailed hzt-section">
                        <span>{t('No Travel')}</span>
                        <Checkbox
                          readOnly={readonly}
                          checked={noTravel}
                          onChange={(_, data) => handleNoTravel(idx, data.checked)}
                        ></Checkbox>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isAdminPocOrPlanner() && !readonly && !isHideEditButton && <Icon onClick={() => changeEmployeeHandler(idx)} name="edit" />}
              {isAdminPocOrPlanner() && !readonly && !isHideEditButton && (
                <Icon onClick={() => removeEventNewModelPositionHandler(idx)} name="remove" color="red" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(EventNewModelPositionComponent);
