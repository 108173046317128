import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import i18n from 'i18n';
import PerdiemsListPage from './perdiems/perdiems-list';

export interface PerdiemsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface PerdiemsConfigurationHomeState {
  activeTab: 'Per Diems';
}

class PerdiemsConfigurationHomePage extends React.Component<PerdiemsConfigurationHomeProps, PerdiemsConfigurationHomeState> {
  constructor(props: PerdiemsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'Per Diems'
    };
  }
  private panes = [
    {
      menuItem: i18n.t('Per Diems'),
      render: () => (
        <Tab.Pane attached={false}>
          <PerdiemsListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Per Diems')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(PerdiemsConfigurationHomePage);
