import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { BaseDto } from 'stores/types';

export interface ProfessionDto extends BaseDto {
  id: string;
  profession: string;
}

export interface ChangeProfessionDto {
  id: string;
  profession: string;
}

export class ChangeProfessionValidator extends AbstractValidator<ChangeProfessionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Profession id is required'));

    this.validateIfString(o => o.profession)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Profession name is required'));
  }
}

@repository('@@PROFESSIONS', 'professions.summary')
export class ProfessionsStore extends DataStore<ProfessionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-profession';
  retrievePath = 'get-professions';
  updatePath = 'update-profession';
  deletePath = 'delete-profession';

  protected validate(item: ProfessionDto) {
    return new ChangeProfessionValidator().validate(item);
  }

  constructor() {
    super('PROFESSION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
