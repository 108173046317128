import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Grid, Checkbox } from 'semantic-ui-react';
import * as dataStore from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewVisaView from './new-visa';
import ChangeVisaView from './edit-visa';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';
import { VisaDto, VisaStore } from 'stores/configuration/planner-assistant/visas-store';

export interface VisasListProps extends WithTranslation, RouteComponentProps {
  visas: VisaStore;
}

export interface VisasListState {
  query: dataStore.Query;
  newVisaShown: boolean;
  changeVisaShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['visas', VisaStore])
class VisasListPage extends React.Component<VisasListProps, VisasListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<VisaDto>('modifiedOn'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newVisaShown: false,
      changeVisaShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.visas.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: dataStore.OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: VisaDto, state: dataStore.ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.visas.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: VisaDto, state: dataStore.ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.visas.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newVisaShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newVisaShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeVisaShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeVisaShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          renderer: data => data.name,
          newClassFromComponent: data => 'table__more-text-long',
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<VisaDto>('name'), `contains(${nameof<VisaDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<VisaDto>('name'))}
              active={activeFilters.includes(nameof<VisaDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<VisaDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('International Treaty'),
          renderer: data => <Checkbox toggle disabled checked={data.internationalTreatyRequired} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by treaty required')}
              trueLabel={t('Yes')}
              falseLabel={t('No')}
              triggerTitle={title}
              onFilter={(value: boolean) =>
                onFilter(nameof<VisaDto>('internationalTreatyRequired'), { internationalTreatyRequired: value })
              }
              onClear={() => onClear(nameof<VisaDto>('internationalTreatyRequired'))}
              active={activeFilters.includes(nameof<VisaDto>('internationalTreatyRequired'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<VisaDto>('internationalTreatyRequired'), useProfile: false }
        }
      ],
      data: this.props.visas.state
    } as TableModel<VisaDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.visas.state.result && !this.props.visas.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.visas.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newVisaShown && !this.state.changeVisaShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Visa')}
            ></TableView>
          </Grid.Row>
        </Grid>

        {(this.state.newVisaShown && <NewVisaView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeVisaShown && <ChangeVisaView onClose={this.onEditItemClosed} currentVisa={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(VisasListPage);
