import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import { Form, Button, Icon } from 'semantic-ui-react';
import MrclusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { TnaTemplateMachines } from '../tna-templates-wizard-component';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { isNullOrUndefined } from 'util';
import './tna-wizard-second-step.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'redux-scaffolding-ts';
import { RouteComponentProps } from 'react-router';
import { DownloadTnaTemplatesStore, TnaTemplatesStore } from 'stores/assessments/templates/tna-templates-store';

export interface SecondStepProps extends WithTranslation, RouteComponentProps {
  isStepActive?: boolean;
  onAddMachineRow: (machineRow: any) => void;
  onMachineRowChanges: (machineRow: any) => void;
  machines: TnaTemplateMachines[];
  onRemoveMachine: (index: number) => void;
  machineRow?: TnaTemplateMachines;
  profile?: ProfileItemDto;
  downloadTnaTemplateStore?: DownloadTnaTemplatesStore;
  tnaTemplateStore?: TnaTemplatesStore;
  tnaTemplateId?: string;
  showDownloadMsg?: (showMsg: boolean) => void;
  hide?: boolean;
}

export interface templateMachinery {
  machineRow?: TnaTemplateMachines;
  machines?: TnaTemplateMachines[];
  checked: boolean;
}

export interface TnaTemplateSecondStepState {
  showDownloadMsg: boolean;
}

@connect(['downloadTnaTemplateStore', DownloadTnaTemplatesStore], ['tnaTemplateStore', TnaTemplatesStore])
class SecondStepContent extends React.Component<SecondStepProps> {
  constructor(props) {
    super(props);
    this.state = {
      showDownloadMsg: false
    };
  }

  handleChanges(property: keyof TnaTemplateMachines, value: any): void {
    const machineRowChanged = { ...this.props.machineRow };
    machineRowChanged[property] = value;

    if (property === 'machineRelatedCluster') {
      machineRowChanged.equipmentType = null;
      machineRowChanged.oem = null;
      machineRowChanged.machineModel = null;
    }
    if (property === 'equipmentType') {
      machineRowChanged.oem = null;
      machineRowChanged.machineModel = null;
    }
    if (property === 'oem') machineRowChanged.machineModel = null;
    this.props.onMachineRowChanges(machineRowChanged);
  }

  isAddMachineDisabled = (): boolean => {
    const { machineRow, machines } = this.props;
    return (
      machineRow.equipmentType?.id == null ||
      machineRow.machineRelatedCluster?.id == null ||
      machineRow.oem?.id == null ||
      machineRow.machineModel?.id == null ||
      ((machines || []).length > 0 && !isNullOrUndefined(machines.find(m => m.machineModel.id === machineRow.machineModel?.id)))
    );
  };

  private handleNewMachineProfileRow = () => {
    this.props.onAddMachineRow({ ...this.props.machineRow });
  };

  onRemoveRow = (index: number) => {
    this.props.onRemoveMachine(index);
  };
  shouldComponentUpdate(nextProps, nextState) {
    const { machines, isStepActive, machineRow } = this.props;
    if (
      machines.length !== nextProps.machines.length ||
      isStepActive !== nextProps.isStepActive ||
      (nextProps.machineRow && machineRow !== nextProps.machineRow) ||
      machineRow.oem !== nextProps.machineRow.oem ||
      machineRow.machineRelatedCluster !== nextProps.machineRow.machineRelatedCluster ||
      machineRow.equipmentType !== nextProps.machineRow.equipmentType
    ) {
      return true;
    }
    return false;
  }

  private onExportExcel = () => {
    const { tnaTemplateId, showDownloadMsg } = this.props;
    showDownloadMsg(true);

    if (tnaTemplateId) this.props.downloadTnaTemplateStore.initBackgroundDownload(tnaTemplateId);
  };

  render() {
    const { t, isStepActive, machines, machineRow, tnaTemplateId, hide } = this.props;

    if (isStepActive) {
      return (
        <>
          <div className="wizard__step wizard__step-two">
            <StepsComponent active number={'2'} title={'SELECT MACHINE MODELS'} />

            <div className="step-two__machinery-wrapper wizard__step-margin-left">
              <Form.Group>
                <Form.Field>
                  <div className={`required field machinery__label-input-field editor-32px`}>
                    <label>{t('Cluster')}</label>

                    <MrclusterEditor
                      clearable
                      nullable
                      readOnly={(machines || []).length > 0}
                      value={machineRow.machineRelatedCluster?.id}
                      onChange={value => this.handleChanges('machineRelatedCluster', value)}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <div className={`required field machinery__label-input-field editor-32px`}>
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      readOnly={(machines || []).length > 0 ? true : null == machineRow.machineRelatedCluster?.id}
                      clearable
                      nullable
                      clusterId={machineRow.machineRelatedCluster?.id || undefined}
                      value={machineRow.equipmentType?.id || null}
                      onChange={value => this.handleChanges('equipmentType', value)}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <div className={`required field machinery__label-input-field editor-32px`}>
                    <label>{t('OEM')}</label>
                    <OemEditor
                      readonly={
                        (machines || []).length > 0
                          ? true
                          : null == machineRow.equipmentType?.id || null == machineRow.machineRelatedCluster?.id
                      }
                      clearable
                      nullable
                      equipmentId={machineRow.equipmentType?.id || null}
                      value={machineRow.oem?.id || null}
                      onChange={value => this.handleChanges('oem', value)}
                    />
                  </div>
                </Form.Field>

                <Form.Field error>
                  <div className={`required field machinery__label-input-field editor-32px`}>
                    <label>{t('Machine Model')}</label>
                    <MachineModelEditor
                      error={(machines || []).length > 0 && null != machines.find(m => m.machineModel.id === machineRow.machineModel?.id)}
                      readonly={
                        (machines || []).length > 0
                          ? false
                          : null == machineRow.oem?.id ||
                            null == machineRow.equipmentType?.id ||
                            null == machineRow.machineRelatedCluster?.id
                      }
                      clearable
                      nullable
                      value={machineRow.machineModel?.id || undefined}
                      oemId={machineRow.oem?.id || undefined}
                      equipmentId={machineRow.equipmentType?.id || undefined}
                      onChange={value => this.handleChanges('machineModel', value)}
                    />
                  </div>
                </Form.Field>

                <Form.Field className="tna-w__button-export-excel__wrapper">
                  <Button
                    disabled={this.isAddMachineDisabled()}
                    onClick={() => this.handleNewMachineProfileRow()}
                    className="add-row-btn"
                    type="button"
                  >
                    {t('Add Machine')}
                  </Button>
                  {tnaTemplateId && (
                    <Button
                      icon
                      size="medium"
                      onClick={this.onExportExcel}
                      className="custom-table-upload-btn black-btn"
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )}
                </Form.Field>
              </Form.Group>
            </div>

            {(machines || []).map((assignedMachine, index) => (
              <div key={index} className="flex-center assigned-profile-row-machine__info">
                <div className="assigned-profile-row-machine__items">
                  <p className="machine-cell">{t(assignedMachine?.machineRelatedCluster?.title || '')}</p>
                  <p className="machine-cell">{t(assignedMachine?.equipmentType?.title || '')}</p>
                  <p className="machine-cell">{t(assignedMachine?.oem?.title || '')}</p>
                  <p className="machine-cell">{t(assignedMachine?.machineModel?.title || '')}</p>
                </div>
                <Icon
                  className="assigned-profile-row-machine__remove"
                  name="remove"
                  size="large"
                  color="red"
                  onClick={() => this.onRemoveRow(index)}
                />
              </div>
            ))}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="wizard__step wizard__step-one" hidden={hide}>
            <StepsComponent active={isStepActive} number={'2'} title={'SELECT MACHINE MODELS'} />
          </div>
        </>
      );
    }
  }
}

export default withTranslation()(SecondStepContent);
