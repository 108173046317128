import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormStore, SsaFormStatus } from 'stores/assessments/forms/ssa-forms-store';
import SSAFormSkillComponent from './ssa-form-tab-skill-component';
import { Accordion, Button } from 'semantic-ui-react';
import { AssessorAnswerStatusDto } from 'stores/assessments/forms/ssa-forms-store';
import LanguageEditor from 'widgets/bussiness/language-editor';

interface SSAFormTabComponentProps extends WithTranslation {
  category: string;
  ssaFormStore?: SsaFormStore;
  isInRoleAdminOrPoC: boolean;
  assessorAnswerStatus: AssessorAnswerStatusDto;
  skillsIds: string[];
  formStatus: SsaFormStatus;
  isCurrentUserAssessor: boolean;
  onExportExcel?: () => void;
}

interface SSAFormMainState {
  activeIndex: number;
}

@connect(['ssaFormStore', SsaFormStore])
class SSAFormTabComponent extends Component<SSAFormTabComponentProps, SSAFormMainState> {
  state: SSAFormMainState = { activeIndex: this.props.isCurrentUserAssessor || this.props.isInRoleAdminOrPoC ? -1 : 0 };

  handleClickSkill = (activeIndex: number) => {
    this.setState({ activeIndex });
  };

  handleChange = (id: string, change: any) => {
    const { ssaFormStore, category: categoryName } = this.props;
    const { state } = ssaFormStore;
    const { item } = state;
    const categorySections = item.categorySections.map((category, i) => {
      if (categoryName === category.name)
        category.skillSections = category.skillSections.map(skill => {
          if (skill.skillId === id) skill = { ...skill, ...change };
          return skill;
        });
      return category;
    });
    ssaFormStore.change({ ...item, categorySections });
  };

  getTableSection = () => {
    const { category, ssaFormStore, skillsIds } = this.props;
    return (ssaFormStore.state.item.categorySections.find(({ name }) => name === category).skillSections || [])
      .filter(({ skillId }) => skillsIds.find(id => id === skillId))
      .map(({ skillId, skillName, answer, strengths, areasForImprovement, recommendations }, index) => (
        <Fragment key={skillId + index}>
          <p>{skillName} </p>
          <p>{answer == null ? '-' : answer === 5 || answer === -1 ? 'n/a' : answer} </p>
          <p className="ssa-form__table__comment">{strengths || '-'} </p>
          <p className="ssa-form__table__comment">{areasForImprovement || '-'} </p>
          <p className="ssa-form__table__comment">{recommendations || '-'} </p>
        </Fragment>
      ));
  };

  render() {
    const { category, ssaFormStore, skillsIds, formStatus, onExportExcel } = this.props;
    const { t, isInRoleAdminOrPoC, assessorAnswerStatus, isCurrentUserAssessor } = this.props;

    const currentLanguage = ssaFormStore.state.item?.currentLanguage;
    const item = ssaFormStore.state.item?.categorySections.find(({ name }) => name === category);
    return (
      <>
        {(formStatus === 'Done' || formStatus === 'Review') && isInRoleAdminOrPoC && (
          <div className="machine-related__score-by-skills-table__container">
            <h2>{t('SCORE BY SKILL')}</h2>
            <div className="ssa-form__poc-admin-table">
              <p className="ssa-form__table-not-border">{t('Skill')}</p>
              <p className="ssa-form__table-not-border">{t('Evaluation')}</p>
              <p className="ssa-form__table-not-border">{t("Employee's Strengths")}</p>
              <p className="ssa-form__table-not-border">{t("Employee's Areas for Improvement")}</p>
              <p className="ssa-form__table-not-border">{t('Recommendations')}</p>
              {this.getTableSection()}
            </div>
          </div>
        )}
        <div id="ssaLanguageSelector" className="ssa-form-language-selector">
          <LanguageEditor
            className="planit-user-dropdown-custom"
            placeholder={t('Select Language')}
            clearable
            nullable
            value={ssaFormStore.state.item?.currentLanguage}
            onChange={val => ssaFormStore.changeCurrentLanguage(val ? val.id : null)}
            onlyForTests
          />
        </div>
        {isInRoleAdminOrPoC && (
          <Button className="ssa-form-btn" onClick={onExportExcel}>
            {t(`Export to Excel`)}
          </Button>
        )}

        <Accordion className="ssa-form__skills-section-wrapper">
          {(item?.skillSections || [])
            .filter(({ skillId }) => skillsIds.find(id => id === skillId))
            .map((skillSection, i) => (
              <SSAFormSkillComponent
                isCurrentUserAssessor={isCurrentUserAssessor}
                isInRoleAdminOrPoC={isInRoleAdminOrPoC}
                assessorAnswerStatus={assessorAnswerStatus}
                formStatus={ssaFormStore.state.item.status}
                onChange={this.handleChange}
                key={category + skillSection.skillName + +i}
                active={this.state.activeIndex}
                onSkillToggle={this.handleClickSkill}
                skillSection={skillSection}
                currentLanguage={currentLanguage}
                skillIndex={i}
              />
            ))}
        </Accordion>
      </>
    );
  }
}

export default withTranslation()(SSAFormTabComponent);
