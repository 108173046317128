import React, { Component } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { PracticalTestQuestionViewModel } from '../../practical-template-wizard-utils';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface PracticalTemplateQuestionComponentProps extends WithTranslation {
  move: (direction: 'up' | 'down') => void;
  canGoUp: boolean;
  canGoDown: boolean;
  onRemoveQuestion: () => void;
  question: PracticalTestQuestionViewModel;
  index: number;
}

class PracticalTemplateQuestionComponent extends Component<PracticalTemplateQuestionComponentProps> {
  render() {
    const { canGoUp, canGoDown, move, onRemoveQuestion, question } = this.props;

    return (
      <>
        <div key={question.friendlyId} className="tna-wizard__assign-question">
          <div className="tna-wizard__assign-question__items">
            <div className="wizard__sort-icons-wrapper tna-arrow-left-margin">
              <Icon disabled={!canGoUp} onClick={() => move('up')} name="caret square up outline" />
            </div>
            <div className="wizard__sort-icons-wrapper">
              <Icon disabled={!canGoDown} onClick={() => move('down')} name="caret square down outline" />
            </div>
            <p className="wizard__question-item practical-template-friendly-id">{question.friendlyId}</p>
            <p className="wizard__question-item wizard__theoretical-question-text">{question.question}</p>

            <div className="practical-template-tag-container">
              <div className="sub-container">
                {question.machineRelated && !isNullOrEmpty(question.machineUnitTitle) && (
                  <span className={'wizard__question-item question-bank__cell__tag'}>{question.machineUnitTitle}</span>
                )}
                {!question.machineRelated && !isNullOrEmpty(question.nmrClusterTitle) && (
                  <span className={'wizard__question-item question-bank__cell__tag'}>{question.nmrClusterTitle}</span>
                )}
              </div>
              <div className="sub-container">
                {question.machineRelated && !isNullOrEmpty(question.plcTypeTitle) && (
                  <span className={'wizard__question-item question-bank__cell__tag'}>{question.plcTypeTitle}</span>
                )}
                {!question.machineRelated && !isNullOrEmpty(question.functionalAreaTitle) && (
                  <span className={'wizard__question-item question-bank__cell__tag'}>{question.functionalAreaTitle}</span>
                )}
              </div>
              <div className="sub-container">
                {!isNullOrEmpty(question.trainingLevelTitle) && (
                  <span className={'wizard__question-item question-bank__cell__tag'}>{question.trainingLevelTitle}</span>
                )}
              </div>
            </div>
          </div>
          <Icon onClick={onRemoveQuestion} className="assigned-profile-row-machine__remove" name="remove" size="large" color="red" />
        </div>
      </>
    );
  }
}

export default withTranslation()(PracticalTemplateQuestionComponent);
