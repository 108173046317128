import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Icon, Label, Input, Dimmer, Loader } from 'semantic-ui-react';
import { NewPositionCodeStore, CreatePositionCodeDto } from '../../../../../stores/configuration/profiles/position-codes-store';
import { nameof } from '../../../../../utils/object';
import './positionCode.less';

interface NewPositionCodeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newPositionCode?: NewPositionCodeStore;
}

interface NewPositionCodeViewState {
  keyword: string;
  userClickedSaved: boolean;
}

@connect(['newPositionCode', NewPositionCodeStore])
class NewPositionCodeView extends React.Component<NewPositionCodeViewProps, NewPositionCodeViewState> {
  private get newPositionCodeStore() {
    return this.props.newPositionCode;
  }

  constructor(props: NewPositionCodeViewProps) {
    super(props);
    this.newPositionCodeStore.createNew({
      code: '',
      description: '',
      keywords: []
    });
    this.state = { keyword: null, userClickedSaved: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPositionCodeViewProps) {
    if (this.newPositionCodeStore.state.result && this.newPositionCodeStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPositionCodeStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPositionCodeStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newPositionCodeStore.change({ ...this.newPositionCodeStore.state.item, ...change });
  }
  private sendKeyword = () => {
    if (this.state.keyword === '' || this.state.keyword == null) {
      return;
    }
    const item = this.newPositionCodeStore.state.item;
    const keywords = item.keywords;
    keywords.push(this.state.keyword);
    this.newPositionCodeStore.change(item);

    this.setState({ keyword: '' });
  };

  private deleteTag(index: number) {
    const item = this.newPositionCodeStore.state.item;
    item.keywords = this.newPositionCodeStore.state.item.keywords.filter((p, i) => {
      return i !== index;
    });
    this.newPositionCodeStore.change(item);
  }

  public render() {
    const { t } = this.props as any;
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPositionCodeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Position Code')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newPositionCodeStore.state.result && !this.newPositionCodeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPositionCodeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newPositionCodeStore.state.item && (
              <Form>
                <div className="position-code_all-content__wrapper">
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      className="position-code__label-input__wrapper"
                      error={!this.newPositionCodeStore.state.item.code && this.state.userClickedSaved}
                    >
                      <label>{t('Position Code')}</label>
                      <Input
                        id="position-code__input-code"
                        required
                        fluid
                        placeholder={t('Name')}
                        value={this.newPositionCodeStore.state.item.code}
                        onChange={(e, { value }) => this.handleValue(nameof<CreatePositionCodeDto>('code'), value)}
                      />
                    </Form.Field>

                    <Form.Field
                      required
                      className="position-code__label-input__wrapper"
                      error={!this.newPositionCodeStore.state.item.description && this.state.userClickedSaved}
                    >
                      <label>{t('Description')}</label>
                      <Input
                        id="position-code__input-description"
                        fluid
                        placeholder={t('Description')}
                        value={this.newPositionCodeStore.state.item.description}
                        onChange={(e, { value }) => this.handleValue(nameof<CreatePositionCodeDto>('description'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>

                <div className="position-code_all-content__wrapper">
                  <div className="position-code-info__wrapper">
                    <Form.Field
                      required
                      error={
                        (!this.newPositionCodeStore.state.item.keywords || this.newPositionCodeStore.state.item.keywords.length === 0) &&
                        this.state.userClickedSaved
                      }
                    >
                      <label>{t('keywords')}</label>
                      <Input
                        type="hidden"
                        error={
                          (!this.newPositionCodeStore.state.item.keywords || this.newPositionCodeStore.state.item.keywords.length === 0) &&
                          this.state.userClickedSaved
                        }
                      />
                    </Form.Field>
                    <div className="newPositionCode__tags-container">
                      {this.newPositionCodeStore.state.item.keywords.map((k, i) => {
                        return (
                          <Label circular className="event-type-label label-4">
                            {k}
                            <Icon name="times circle" className="clickable-icon" onClick={() => this.deleteTag(i)} />
                          </Label>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="position-code_all-content__wrapper add-container">
                  <Form.Group>
                    <div className="keyword-input-button__wrapper footer-modal">
                      <Form.Field>
                        <Form.Input value={this.state.keyword} onChange={(e, { value }) => this.setState({ keyword: value })} />
                      </Form.Field>
                      <Form.Field>
                        <Button className="inverted-color-btn" style={{ marginLeft: 10 }} icon onClick={this.sendKeyword}>
                          {t('Add Tag')}&nbsp;
                          <Icon name="add circle" />
                        </Button>
                      </Form.Field>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPositionCodeView);
