import React, { Fragment, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import './theoretical-tab.less';
import { SimpleTheoreticalFormAnswerDto } from 'stores/events/theoretical-form-store';

interface TheoreticalTableProps extends WithTranslation {
  readOnly?: boolean;
  theoreticalFormAnswers: SimpleTheoreticalFormAnswerDto[];
  templateId: string;
  onOpenEmployeeTheoreticalForm: (form: SimpleTheoreticalFormAnswerDto) => void;
  showDeleteConfirmationModal: (form: SimpleTheoreticalFormAnswerDto) => void;
  showResendConfirmationModal: (form: SimpleTheoreticalFormAnswerDto) => void;
  isEventClosed?: boolean;
  userCanDeleteAndResend?: boolean;
}

const TheoreticalTable: FC<TheoreticalTableProps> = ({
  t,
  theoreticalFormAnswers,
  onOpenEmployeeTheoreticalForm,
  showDeleteConfirmationModal,
  showResendConfirmationModal,
  userCanDeleteAndResend
}) => {
  const tableClassName = userCanDeleteAndResend ? 'form__theoretical-table-admin' : 'form__theoretical-table';
  return (
    <div className={tableClassName}>
      <p className="theoretical-table__name-column">{t('User Name')}</p>
      <p className="theoretical-table__name-column">{t('Last Name')}</p>
      <p className="theoretical-table__name-column">{t('First Name')}</p>
      <p className="theoretical-table__name-column">{t('Location')}</p>
      <p className="theoretical-table__name-column">{t('Average Time')}</p>
      <p className="theoretical-table__name-column">{t('Total Time')}</p>
      <p className="theoretical-table__name-column">{t('Result')}</p>
      <p className="theoretical-table__name-column">{t('Number of Questions')}</p>
      <p className="theoretical-table__name-column text__transparent">X</p>
      {userCanDeleteAndResend && <p className="theoretical-table__name-column text__transparent">X</p>}
      {userCanDeleteAndResend && <p className="theoretical-table__name-column text__transparent">X</p>}

      {(theoreticalFormAnswers || []).map(form => {
        if (!form) return null;
        const { user, id, userId, answered, averageTime, questionsAnswered, result, totalQuestions, totalTime } = form;
        const iconClassName = answered
          ? 'clickable-icon theoretical__comments-icon__answered'
          : 'clickable-icon theoretical-table__comments-icon';
        return (
          <Fragment key={`theoretical-table__${id}-${userId} scroll`}>
            <p className="theoretical-table__cell">{user.userName}</p>
            <p className="theoretical-table__cell">{user.lastName}</p>
            <p className="theoretical-table__cell">{user.firstName}</p>
            <p className="theoretical-table__cell">{user?.location?.location}</p>
            <p className="theoretical-table__cell">{averageTime ? averageTime : '-'}</p>
            <p className="theoretical-table__cell">{totalTime ? totalTime : '-'}</p>
            <p className="theoretical-table__cell">{result != null && result !== 0 ? `${result} %` : '-'}</p>
            <p className="theoretical-table__cell">
              {questionsAnswered == null || questionsAnswered === 0 ? '-' : `${questionsAnswered}/${totalQuestions}`}
            </p>

            <p className="theoretical-table__cell">
              <Icon onClick={() => onOpenEmployeeTheoreticalForm(form)} className={iconClassName} name="comments" size="large" />
            </p>
            {userCanDeleteAndResend && (
              <p className="theoretical-table__cell">
                <Icon onClick={() => showResendConfirmationModal(form)} className="clickable-icon" name="redo" size="large" />
              </p>
            )}
            {userCanDeleteAndResend && (
              <p className="theoretical-table__cell">
                <Icon onClick={() => showDeleteConfirmationModal(form)} className="clickable-icon" color="red" name="remove" size="large" />
              </p>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default withTranslation()(TheoreticalTable);
