import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Dropdown } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { ProfileItemStore, ProfileItemDto } from 'stores/profile/profile-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface ProfileEditorProps extends WithTranslation {
  value?: string;
  onChange?: (value: ProfileItemDto) => void;
  placeholder?: string;
  profile?: ProfileItemStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  clearable?: boolean;
  className?: string;
  assessment?: 'TNA' | 'SSA' | 'IWS' | string;
  readonly?: boolean;
  multiple?: boolean;
  values?: string[];
  onChangeMulti?: (values: ProfileItemDto[]) => void;
  notLoadDataOnOpen?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface ProfileEditorState {
  value: string;
  values: string[];
  availableProfile: { [id: string]: ProfileItemDto };
  profileOptions: { text: string; value: string }[];
  loading: boolean;
}

@connect(['profile', ProfileItemStore])
class ProfileEditor extends React.Component<ProfileEditorProps, ProfileEditorState> {
  private get profileStore() {
    return this.props.profile;
  }

  private get classNamePros() {
    if (this.props.className) return this.props.className;
    else return '';
  }

  constructor(props: ProfileEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      values: this.props.values,
      availableProfile: {},
      profileOptions: [],
      loading: false
    };

    if (this.props.assessment !== 'IWS') this.initProfiles(props, true);
  }

  UNSAFE_componentWillReceiveProps(next: ProfileEditorProps) {
    if (next !== this.props) {
      if (next.assessment !== this.props.assessment) {
        this.setState({ value: next.value });
        //this.initProfiles(next, false);
      } else if (next.value !== this.props.value) this.setState({ value: next.value });
    }
  }

  private async initProfiles(props: ProfileEditorProps, ctor: boolean) {
    if (ctor) this.setState({ ...this.state, loading: true });
    else this.setState({ loading: true });

    const filter = {};
    if (!isNullOrWhiteSpaces(props.assessment)) {
      filter['assessment'] = props.assessment;
    }
    return await this.profileStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        filter,
        orderBy: [{ direction: 'Ascending', field: nameof<ProfileItemDto>('name'), useProfile: false }]
      })
      .then(profiles => {
        const dict = {};
        const options = [];

        profiles.items
          .filter(x => x.isActive || this.props.readonly)
          .forEach(profile => {
            dict[profile.id] = profile;
            options.push({ text: profile.name, value: profile.id });
          });

        this.setState({ availableProfile: dict, profileOptions: options, loading: false });
      });
  }
  selectedProfileCode({ value }: any) {
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(this.state.availableProfile[value]);

    if (this.props.onChangeMulti) {
      let profiles = value.map(x => this.state.availableProfile[x]);
      this.props.onChangeMulti(profiles);
      //console.log(profiles);
    }
  }

  onOpenDropDown = () => {
    if ((this.state.profileOptions || []).length <= 0) this.initProfiles(this.props, false);
  };

  public render() {
    let profileOptions = this.state.profileOptions;
    const { loading } = this.state;

    return (
      <Dropdown
        clearable={this.props.clearable || false}
        search
        selection
        options={profileOptions}
        selectOnBlur={false}
        className={this.classNamePros}
        value={this.state.value ? this.state.value : this.state.values ? this.state.values : null}
        onChange={(e, data) => this.selectedProfileCode(data)}
        placeholder={this.props.placeholder}
        disabled={!!this.props.readonly}
        loading={loading}
        multiple={this.props.multiple}
        onOpen={this.props.notLoadDataOnOpen ? null : this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ProfileEditor);
