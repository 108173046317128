import { UserDto } from 'stores/users/users-store';
import * as React from 'react';
import { getGravatar } from 'utils/gravatar';

export interface UserSummaryImageProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  user: UserDto;
}

const UserSummaryImage: React.FC<UserSummaryImageProps> = ({ user, className, style, ...restProps }) => {
  if (!user) return null;

  const src = user.userPhoto?.hasPhoto ? 'data:image/png;base64,' + user.userPhoto?.photo : getGravatar(user.email, 80, 'identicon');

  return (
    <div className={className || 'flex-c-c basic-padding'} {...restProps}>
      <div className="avatar" style={{ backgroundImage: `url(${src})`, ...style }} />
    </div>
  );
};

export default React.memo(UserSummaryImage);
