import * as React from 'react';
import { CSSProperties } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export interface DurationInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  suffix?: string;
  className?: string;
}

interface DurationInputState {
  value: string;
}

export class DurationInput extends React.Component<DurationInputProps, DurationInputState> {
  suffix = this.props.suffix || ' w/d';
  decimalSeparator = '';
  constructor(props: DurationInputProps) {
    super(props);
    // tslint:disable-next-line:variable-name
    this.state = {
      value: this.onValueReceived(this.props.value)
    };
  }
  private onValueReceived = (val: string): string => {
    return val && val !== typeof undefined ? val.replace(this.suffix, '') : '';
  };

  private handleNumberFormatValue = (values: NumberFormatValues) => {
    if (this.props.onChange) {
      this.props.onChange(values.value);
    }
  };

  public render() {
    const value = this.onValueReceived(this.props.value);
    return (
      <NumberFormat
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        allowNegative={false}
        suffix={this.suffix}
        style={this.props.style}
        value={value}
        decimalSeparator={false}
        onValueChange={this.handleNumberFormatValue}
        className={this.props.className}
        isNumericString={true}
        inputMode="numeric"
      />
    );
  }
}
