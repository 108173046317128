import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import 'assets/less/evaluation-search.less';
import { Button, Message, Label, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import {
  EvaluationsDynamicTableStore,
  ScoreRow,
  Assessment,
  ScoreItem,
  EvaluationDynamicTableColumnFilters,
  EvaluationsSearchFilters
} from 'stores/evaluations/evaluations-store';
import { connect } from 'redux-scaffolding-ts';
import { ProfileSkillDto } from 'stores/profile/profile-store';
import { TableModel, TableColumn, TableView } from 'widgets/collections/table';
import { nameof, getProperties } from 'utils/object';
import { DataModel, ItemModel, OrderDefinition, ItemState } from 'stores/dataStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';

import { NumberInput } from 'widgets/form/numberInput';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrEmpty, openInNewWindow } from 'utils/useful-functions';
import { ToastComponent } from '../landing-pages/util/toast-component';
import { OptionsFilter } from 'widgets/collections/table-filters/option-filter';

export interface EvaluationDynamicTableProps extends WithTranslation {
  evaluationsStore?: EvaluationsDynamicTableStore;
  onModifySearch: () => void;
}

export interface EvaluationDynamicTableState {
  orderBy: OrderDefinition[];
  columnFilters: EvaluationDynamicTableColumnFilters;
}

@connect(['evaluationsStore', EvaluationsDynamicTableStore])
class EvaluationDynamicTable extends React.Component<EvaluationDynamicTableProps, EvaluationDynamicTableState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props: EvaluationDynamicTableProps) {
    super(props);

    this.state = {
      orderBy: [],
      columnFilters: {}
    };
  }

  private onModifySearch = () => {
    if (this.props.onModifySearch) this.props.onModifySearch();
  };

  private onRefresh = () => {
    const { evaluationsStore } = this.props;
    const { orderBy, columnFilters } = this.state;
    evaluationsStore.getCurrentScores(evaluationsStore.state.filters).then(_ => evaluationsStore.orderAndFilter(orderBy, columnFilters));
  };

  private onExportExcel = () => {
    this.props.evaluationsStore
      .exportToExcel()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });
  };

  private onEmployeeCardClicked = (item: ScoreRow) => {
    openInNewWindow(`./employee-page/${item.userId}`);
  };

  private getScore = (data: ScoreRow, key: string, isTna: boolean, skillProfile: ProfileSkillDto): string => {
    let result: string;
    if (isTna && !skillProfile?.isMachineRelated) {
      if (data.scores[key] && data.scores[key].score != null) {
        if (data.scores[key].score === 0 && !isNullOrEmpty(data.lastTestTemplateName) && !data.scores[key].isUpdated) result = 'N/A';
        else result = data.scores[key].score.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      } else {
        result = '';
      }
    } else {
      result =
        data.scores[key] && data.scores[key].score != null
          ? data.scores[key].score.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          : '';
    }

    return result;
  };

  private getDynamicTableModel = (): TableModel<ScoreRow> => {
    const { state } = this.props.evaluationsStore;
    const { columnFilters } = this.state;
    const { t } = this.props;
    const isTna = state.filters.assessment === Assessment[Assessment.TNA];
    const isIws = state.filters.assessment === Assessment[Assessment.IWS];
    const skillsProfile = state.filters.profile.skills;

    var templateColumnTitle = t("Test's Template");
    if (isIws) templateColumnTitle = t('Template');

    let columns: TableColumn<ScoreRow>[] = [
      {
        title: t('Employee'),
        tooltipRenderer: true,
        renderer: data => data.fullName,
        selectableHeader: true,
        headerRenderer: (title: string, onFilter, onClear) => (
          <TextBoxFilter
            filterTitle={t('Filter by Full Name')}
            triggerTitle={title}
            onFilter={value => onFilter(nameof<ScoreRow>('fullName'), (value || '').toLocaleLowerCase())}
            onClear={() => onClear(nameof<ScoreRow>('fullName'))}
            active={!!columnFilters[nameof<ScoreRow>('fullName')]}
          />
        ),
        sortDefinition: { field: nameof<ScoreRow>('fullName'), useProfile: false }
      },
      {
        title: t('User Name'),
        tooltipRenderer: true,
        renderer: data => data.userName,
        selectableHeader: true,
        headerRenderer: (title: string, onFilter, onClear) => (
          <TextBoxFilter
            filterTitle={t('Filter by User Name')}
            triggerTitle={title}
            onFilter={value => onFilter(nameof<ScoreRow>('userName'), (value || '').toLocaleLowerCase())}
            onClear={() => onClear(nameof<ScoreRow>('userName'))}
            active={!!columnFilters[nameof<ScoreRow>('userName')]}
          />
        ),
        sortDefinition: { field: nameof<ScoreRow>('userName'), useProfile: false }
      }
    ];
    if (isTna)
      columns.push({
        title: t('Level'),
        tooltipRenderer: true,
        renderer: data => data.level,
        selectableHeader: true,
        headerRenderer: (title: string, onFilter, onClear) => (
          <OptionsFilter
            filterTitle={t('Filter by Level')}
            options={[
              { text: 'Main', value: 'Main' },
              { text: 'Substitute', value: 'Substitute' }
            ]}
            triggerTitle={title}
            onFilter={value => onFilter(nameof<ScoreRow>('level'), (value || '').toLocaleLowerCase())}
            onClear={() => onClear(nameof<ScoreRow>('level'))}
            active={!!columnFilters[nameof<ScoreRow>('level')]}
          />
        ),
        sortDefinition: {
          field: nameof<ScoreRow>('level'),
          useProfile: false
        }
      });
    else {
      !isIws &&
        columns.push({
          title: t('Position Code'),
          tooltipRenderer: true,
          renderer: data => data.positionCode,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Position Code')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<ScoreRow>('positionCode'), (value || '').toLocaleLowerCase())}
              onClear={() => onClear(nameof<ScoreRow>('positionCode'))}
              active={!!columnFilters[nameof<ScoreRow>('positionCode')]}
            />
          ),
          sortDefinition: {
            field: nameof<ScoreRow>('positionCode'),
            useProfile: false
          }
        });
      columns.push({
        title: templateColumnTitle,
        tooltipRenderer: true,
        renderer: data => data.lastTestTemplateName,
        selectableHeader: true,
        newClassFromComponent: data => (isIws ? 'table__more-text-long-350' : undefined),
        headerClassName: isIws ? 'custom-table thead tr th text-long' : undefined,
        headerRenderer: (title: string, onFilter, onClear) => (
          <TextBoxFilter
            filterTitle={t("Filter by Test's Template")}
            triggerTitle={title}
            onFilter={value => onFilter(nameof<ScoreRow>('lastTestTemplateName'), (value || '').toLocaleLowerCase())}
            onClear={() => onClear(nameof<ScoreRow>('lastTestTemplateName'))}
            active={!!columnFilters[nameof<ScoreRow>('lastTestTemplateName')]}
          />
        ),
        sortDefinition: {
          field: nameof<ScoreRow>('lastTestTemplateName'),
          useProfile: false
        }
      });
    }

    !isIws &&
      columns.push({
        title: t('Total Score'),
        tooltipRenderer: true,
        renderer: data => (
          <strong>
            {data.totalScore == null
              ? ''
              : data.totalScore.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
          </strong>
        ),
        newClassFromComponent: data => `${this.getColorClass(data, nameof<ScoreRow>('totalScore'))}`,
        editor: (data, onChange) => (
          <div className="ui input evaluation_number-input">
            <NumberInput
              value={data.totalScore}
              allowNegative={false}
              onChange={value => {
                data.totalScore = value;
                onChange();
              }}
            />
          </div>
        ),
        selectableHeader: true,
        sortDefinition: {
          field: nameof<ScoreRow>('totalScore'),
          useProfile: false
        }
      });

    if (state.result && state.result.item)
      columns = columns.concat(
        getProperties(state.result.item.skillColumns).map(({ key, value }) => {
          return {
            title: t(value),
            tooltipRenderer: true,
            renderer: data =>
              this.getScore(
                data,
                key,
                isTna,
                skillsProfile.firstOrDefault(x => x.skillId === key)
              ),
            newClassFromComponent: data =>
              this.getScore(
                data,
                key,
                isTna,
                skillsProfile.firstOrDefault(x => x.skillId === key)
              ) === 'N/A'
                ? ''
                : `${this.getColorClass(data, key)}`,
            editor: (data, onChange) => (
              <div className="ui input evaluation_number-input">
                <NumberInput
                  decimalPlaces={1}
                  value={data.scores[key] && data.scores[key].score}
                  onChange={v => {
                    if (data.scores[key]) data.scores[key].score = v;
                    else data.scores[key] = { score: v, skillId: key, skillName: value } as ScoreItem;
                    onChange();
                  }}
                />
              </div>
            ),
            selectableHeader: true,
            sortDefinition: { field: key, useProfile: false }
          };
        })
      );

    columns.push({
      title: '',
      excludeFromColumnHideTool: true,
      renderer: data => (
        <div>
          <Icon.Group>
            <Popup
              trigger={
                <Icon
                  style={{
                    fontSize: '14px',
                    color: '#474b4f'
                  }}
                  className="clickable-icon"
                  name="eye"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onEmployeeCardClicked(data);
                  }}
                />
              }
              position="top right"
              content={t('Open Employee Card')}
            />
          </Icon.Group>
        </div>
      ),
      headerClassName: 'custom-table thead tr th small-icon'
    });

    const items = (state.rows || []).map(
      (x: ScoreRow) => ({ state: 'Unchanged', isBusy: false, item: x, result: undefined } as ItemModel<ScoreRow>)
    );

    const dataModel: DataModel<ScoreRow> = { count: items.length, items, isBusy: state.isBusy, discard: () => {}, result: undefined };

    const tableModel: TableModel<ScoreRow> = { columns, data: dataModel, keySelector: data => data.id || data.userId };

    return tableModel;
  };

  private getColorClass = (data: ScoreRow, key: string) => {
    const { minmax } = this.props.evaluationsStore.state;
    if (data == null || (data[key] == null && data.scores[key] == null) || minmax[key] == null) return '';
    const val = data[key] != null ? data[key] : data.scores[key].score;
    if (val == null) return '';
    const min = minmax[key].min;
    const max = minmax[key].max;

    if (Math.abs(max - val) < 0.001) return 'max-column-score';
    if (Math.abs(min - val) < 0.001) return 'min-column-score';
    return '';
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    const { columnFilters } = this.state;
    this.setState({ orderBy }, () => this.props.evaluationsStore.orderAndFilter(orderBy, columnFilters));
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const { orderBy } = this.state;

    const columnFilters: EvaluationDynamicTableColumnFilters = {};
    for (let i = 0; i < filters.length; i++) {
      const f = filters[i];
      columnFilters[f.id] = f.filter;
    }
    this.setState({ columnFilters }, () => this.props.evaluationsStore.orderAndFilter(orderBy, columnFilters));
  };

  private onSaveRow = async (item: ScoreRow, state: ItemState): Promise<any> => {
    const { orderBy, columnFilters } = this.state;
    const { evaluationsStore } = this.props;
    if (state !== 'New') {
      const result = await evaluationsStore.updateRow(item);
      if (result && result.isSuccess) await evaluationsStore.orderAndFilter(orderBy, columnFilters);
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  private onSaveRows = async (items: { item: ScoreRow; state: ItemState }[]): Promise<any> => {
    const { orderBy, columnFilters } = this.state;
    const { evaluationsStore } = this.props;
    const toUpdate = items.filter(x => x.state !== 'New');
    if (toUpdate.length !== 0) {
      const result = await evaluationsStore.updateRows(toUpdate.map(x => x.item));
      if (result && result.isSuccess) await evaluationsStore.orderAndFilter(orderBy, columnFilters);
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  getProfileDefinitionTable(stateFilters: EvaluationsSearchFilters) {
    return (
      <>
        <Grid columns={4}>
          {(stateFilters.profile.skills || [])
            .reduce((result: ProfileSkillDto[][], value: ProfileSkillDto, index: number, array: ProfileSkillDto[]) => {
              if (index % 2 === 0) result.push(array.slice(index, index + 2));
              return result;
            }, [] as ProfileSkillDto[][])
            .map((s: ProfileSkillDto[]) => (
              <Grid.Row key={`${s[0].skillId}-${s[1] && s[1].skillId}`}>
                <Grid.Column width={5}>{s[0].skillName}</Grid.Column>
                <Grid.Column width={3}>{s[0][(stateFilters.level && stateFilters.level.toLowerCase()) || 'main']}</Grid.Column>
                <Grid.Column width={5}>{s[1] && s[1].skillName}</Grid.Column>
                <Grid.Column width={3}>{s[1] && s[1][(stateFilters.level && stateFilters.level.toLowerCase()) || 'main']}</Grid.Column>
              </Grid.Row>
            ))}
        </Grid>
      </>
    );
  }

  getIWSProfileDefinitionTable(stateFilters: EvaluationsSearchFilters) {
    let tableHeaders = ['Phase 0', 'Phase 1', 'Phase 2', 'Phase 3', 'Phase 4'];
    return (
      <Table size="small" key={'score-list-table'}>
        <Table.Header key={'table-header'}>
          <Table.Row key={'table-header-row'}>
            <Table.HeaderCell className="evaluation-search-iws-profile-skill-table-header" key={'table-header-empty'} />
            {(tableHeaders || []).map((header, index) => (
              <Table.HeaderCell className="evaluation-search-iws-profile-skill-table-header" key={'header-cell-' + index}>
                {header}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body key={'table-body'}>
          {(stateFilters.profile.skills || []).map((skillProfile, index) => (
            <Table.Row key={skillProfile.skillId + 'row-' + index}>
              <Table.Cell key={'row-skillName-' + index} width="7">
                {skillProfile.skillName}
              </Table.Cell>
              <Table.Cell textAlign="center" key={'key-phase0-' + index}>
                {(skillProfile.phase0 || -1) >= 0 ? skillProfile.phase0 : 'N/A'}
              </Table.Cell>
              <Table.Cell textAlign="center" key={'key-phase1-' + index}>
                {(skillProfile.phase1 || -1) >= 0 ? skillProfile.phase1 : 'N/A'}
              </Table.Cell>
              <Table.Cell textAlign="center" key={'key-phase2-' + index}>
                {(skillProfile.phase2 || -1) >= 0 ? skillProfile.phase2 : 'N/A'}
              </Table.Cell>
              <Table.Cell textAlign="center" key={'key-phase3-' + index}>
                {(skillProfile.phase3 || -1) >= 0 ? skillProfile.phase3 : 'N/A'}
              </Table.Cell>
              <Table.Cell textAlign="center" key={'key-phase4-' + index}>
                {(skillProfile.phase4 || -1) >= 0 ? skillProfile.phase4 : 'N/A'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  private handleOnEnterKeydown(item: ScoreRow) {
    openInNewWindow(`./employee-page/${item.userId}`);
  }

  render() {
    const { t, evaluationsStore } = this.props;
    const { state } = evaluationsStore;

    return (
      <>
        <div id="evaluation-search-container_evaluation-result" className="evaluation-search-container">
          <div id="evaluation-result-filters">
            <div id="evaluation-result-filters_profile">
              <Label color={'teal'}>
                {state.filters.profile.name} {state.filters.machineModelName} {state.filters.level}
              </Label>
            </div>
            <div id="evaluation-result-filters_profile-definition">
              <h4>{t('PROFILE DEFINITION')}</h4>
              {state.filters.assessment === Assessment[Assessment.IWS]
                ? this.getIWSProfileDefinitionTable(state.filters)
                : this.getProfileDefinitionTable(state.filters)}
            </div>
          </div>
          <div id="evaluation-result_modify-search-btn-container">
            <Button id="evaluation-result-filters_modify-search-btn" className="inverted-color-btn" onClick={this.onModifySearch} icon>
              {t('Modify Search')}&nbsp;
            </Button>
          </div>
        </div>
        <Grid className="event-types-list-grid">
          {state.result && !state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <div id="dynamic-evaluation-table">
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={true}
                maxSelection={1}
                onHideCheckbox={true}
                selectionType={'allRow'}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                /////////////////For build table keyboard navegation/////////////////

                isRowDisableLayout={(item: ScoreRow) => !item.userEnabled}
                model={this.getDynamicTableModel()}
                canEdit={IdentityService.isAdmin(this.identityService.getUserInfo())}
                canDelete={false}
                canCreateNew={false}
                allowHidingColumns={true}
                onRefresh={this.onRefresh}
                extraMenu={[
                  {
                    className: 'menu-item-download',
                    content: (
                      <Button icon basic size="medium" onClick={this.onExportExcel} className="evaluation_download-btn">
                        <div className="button-text">{t('Export to Excel')}</div>
                        <div className="button-icon">
                          <FontAwesomeIcon className="solid" icon={faFileDownload} size="sm" />
                        </div>
                      </Button>
                    )
                  }
                ]}
                onOrderByChanged={this.handleOrderBy}
                onFilterChange={this.handleFilterChange}
                onSaveRow={this.onSaveRow}
                canBulkEdit={true}
                onSaveRows={this.onSaveRows}
                enableInlineEditionV2={true}
              />
            </Grid.Row>
          </div>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(EvaluationDynamicTable);
