import React, { Component } from 'react';
import { Dropdown, List, Icon, Checkbox } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import '../../feedbacks-templates.styles.less';
import { EventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import Step from 'widgets/form/templates-step-component';
import { FeedbackTemplateItemDto, Respondent } from 'stores/assessments/templates/feedbacks-templates-store';
import { QueryParameters } from 'stores/dataStore';
import { nameof } from 'utils/object';
export interface SecondStepProps extends WithTranslation {
  eventTypesStore?: EventTypesStore;
  active: boolean;
  onChange: (change: Partial<FeedbackTemplateItemDto>) => void;
  respondent: Respondent;
  selectedOptions: string[];
  directOnly?: boolean;
  filterDirectEventCreator?: boolean;
  filterRequestCreator?: boolean;
  clearable?: boolean;
  error?: boolean;
}

export interface SecondStepState {
  loading: boolean;
  options: EventTypesListViewModel[];
  list: EventTypesListViewModel[];
  search: any;
}

export interface EventTypesListViewModel {
  value: string;
  text: string;
  checked: boolean;
}

@connect(['eventTypesStore', EventTypesStore])
class SecondStep extends Component<SecondStepProps, SecondStepState> {
  state: SecondStepState = { list: [], options: [], loading: false, search: '' };

  handleOptionChange = item => {
    this.setState(
      ({ list, options }) => ({
        list: !item.checked ? [...list, item] : list.filter(({ value }) => value !== item.value),
        options: options.map(opt => {
          if (item.value === opt.value) opt = { ...opt, checked: !opt.checked };
          return opt;
        })
      }),
      () => this.props.onChange({ eventTypes: (this.state.list || []).map(({ text, value }) => ({ name: text, originalId: value })) })
    );
  };

  componentDidMount() {
    const { directOnly, filterDirectEventCreator, filterRequestCreator, eventTypesStore, selectedOptions } = this.props;
    let filter = [];
    if (directOnly) {
      filter.push({ DirectEventsAllowed: true });
    }
    filter.push({ Active: true });
    const parameters: QueryParameters = !!filterDirectEventCreator || !!filterRequestCreator ? ({} as QueryParameters) : undefined;

    if (!!filterDirectEventCreator) parameters['filterDirectEventCreator'] = 'true';

    if (!!filterRequestCreator) parameters['filterRequestCreator'] = 'true';
    eventTypesStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }],
        filter
      })
      .then(response => {
        const newData = response.items.map(({ originalEventTypeId: value, name: text }) => {
          const checked = (selectedOptions || []).find(id => id === value) != null;
          return { value, text, checked };
        });
        this.setState({
          loading: false,
          options: newData,
          list: newData.filter(({ checked }) => !!checked)
        });
      });
  }

  componentDidUpdate({ selectedOptions: prevOpts }: SecondStepProps, { options }: SecondStepState) {
    const { selectedOptions } = this.props;
    const { options: newOpts } = this.state;
    if (prevOpts.length !== selectedOptions.length || newOpts.length !== options.length) {
      const updatedOptions = this.state.options.map(opt =>
        selectedOptions.find(id => id === opt.value) ? { ...opt, checked: true } : { ...opt, checked: false }
      );
      this.setState({ options: updatedOptions, list: updatedOptions.filter(({ checked }) => !!checked) });
    }
  }

  handleRemoveOption = item => {
    this.setState(
      ({ list, options }) => ({
        options: options.map(opt => (opt.value === item.value ? { ...opt, checked: !opt.checked } : opt)),
        list: list.filter(({ value }) => item.value !== value)
      }),
      () => this.props.onChange({ eventTypes: (this.state.list || []).map(({ text, value }) => ({ name: text, originalId: value })) })
    );
  };

  searchEventTypeInput = (options, textToFilter) => {
    const { search } = this.state;

    if (search !== textToFilter) this.setState({ search: textToFilter });

    return options;
  };

  onClose = () => this.setState({ search: '' });

  render() {
    const { t, active } = this.props;
    const { list, options, loading, search } = this.state;
    const filteredOptions = (options || []).filter(x => (search ? x.text.toLowerCase().includes(search.toLowerCase()) : true));

    return (
      <Step
        active={active}
        number="2"
        title={t('SELECT EVENT TYPES')}
        render={
          <>
            <Dropdown
              selectOnBlur={false}
              className="eventtype-selector"
              loading={loading}
              placeholder={t('Select Event Types')}
              deburr
              options={filteredOptions}
              search={this.searchEventTypeInput}
              onClose={this.onClose}
            >
              {filteredOptions.length > 0 ? (
                <Dropdown.Menu>
                  {filteredOptions.map(option => (
                    <Dropdown.Item key={option.value} onClick={() => this.handleOptionChange(option)}>
                      <Checkbox checked={option.checked} />
                      {option.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              ) : null}
            </Dropdown>

            <List className="event-type-list">
              {(list || []).map(item => (
                <List.Item key={item.value}>
                  <span className="event-type-list__item">{item.text}</span>
                  <Icon size="large" name="times" onClick={() => this.handleRemoveOption(item)} />
                </List.Item>
              ))}
            </List>
          </>
        }
      />
    );
  }
}

export default withTranslation()(SecondStep);
