import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import {
  NewEventGroupRelationshipStore,
  CreateEventGroupRelationshipDto
  //EventGroupRelationshipDto
} from 'stores/configuration/events-n-requests/event-group-rel-store';
import { nameof } from 'utils/object';
import { DropDownEventTypeGroupsStore, EventTypeGroupDto } from 'stores/configuration/events-n-requests/event-type-group-store';
import { DropDownTypeHoursCodeStore, TypeHoursCodeDto } from 'stores/configuration/events-n-requests/type-hour-code-store';

interface NewEventGroupRelationshipViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newEventGroupRelationship?: NewEventGroupRelationshipStore;
  dropDownEventTypeGroup?: DropDownEventTypeGroupsStore;
  dropDownTypeHoursCode?: DropDownTypeHoursCodeStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewEventGroupRelationshipViewState {
  userClickedSaved: boolean;
  isLoading: boolean;
  options: { [id: string]: any };
  eventGroupOptions: any[];
  typeHourOptions: any[];
}

@connect(
  ['newEventGroupRelationship', NewEventGroupRelationshipStore],
  ['dropDownEventTypeGroup', DropDownEventTypeGroupsStore],
  ['dropDownTypeHoursCode', DropDownTypeHoursCodeStore]
)
class NewEventGroupRelationshipView extends React.Component<NewEventGroupRelationshipViewProps, NewEventGroupRelationshipViewState> {
  private get newEventGroupRelationshipStore() {
    return this.props.newEventGroupRelationship;
  }

  private get dropDownEventTypeGroupsStore() {
    return this.props.dropDownEventTypeGroup;
  }

  private get dropDownTypeHoursCodeStore() {
    return this.props.dropDownTypeHoursCode;
  }

  constructor(props: NewEventGroupRelationshipViewProps) {
    super(props);
    this.newEventGroupRelationshipStore.createNew({
      eventGroupId: null,
      leadTypeHoursCodeId: null,
      apprenticeTypeHoursCodeId: null
    });
    this.state = {
      userClickedSaved: false,
      options: {},
      isLoading: false,
      eventGroupOptions: [],
      typeHourOptions: []
    };
  }
  componentDidMount(): void {
    this.init();
  }

  private async init() {
    let eventTypes = this.getAllEventGroups();
    let hourCodes = this.getAllTypeHourCodes();

    let eventGroupResponse = await eventTypes;
    let typeHourCodeResponse = await hourCodes;
    // Promise.all([...requests]).then(([eventGroupResponse, typeHourCodeResponse]) => {
    // let allOptions = {};

    const ego = this.mapEventGroups(eventGroupResponse.items); //.forEach(option => (allOptions[this.buildKey(option)] = option));
    const tho = this.mapTypeHourCodes(typeHourCodeResponse.items); //.forEach(option => (allOptions[this.buildKey(option)] = option));
    //const tho = this.mapTypeHourCodes(typeHourCodeResponse.items, nameof<EventGroupRelationshipDto>('apprenticeTypeHourCode'));//.forEach(option => (allOptions[this.buildKey(option)] = option));

    this.setState({
      isLoading: false,
      eventGroupOptions: ego,
      typeHourOptions: tho
    });

    // });
  }
  private getAllEventGroups() {
    return this.dropDownEventTypeGroupsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<EventTypeGroupDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllTypeHourCodes() {
    return this.dropDownTypeHoursCodeStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<TypeHoursCodeDto>('code'), useProfile: false }],
      filter: []
    });
  }

  private mapEventGroups(eventTypeGroups: EventTypeGroupDto[]): any[] {
    return eventTypeGroups.map(etg => ({
      value: etg.id,
      text: etg.name,
      id: etg.id
    }));
  }

  private mapTypeHourCodes(typeHoursCodes: TypeHoursCodeDto[]): any[] {
    return typeHoursCodes.map(thc => ({
      value: thc.id,
      text: thc.code,
      id: thc.id
    }));
  }

  private buildKey(option: any): string {
    return `${option.id}-${option.type}`;
  }
  UNSAFE_componentWillReceiveProps(nextProps: NewEventGroupRelationshipViewProps) {
    if (this.newEventGroupRelationshipStore.state.result && this.newEventGroupRelationshipStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newEventGroupRelationshipStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newEventGroupRelationshipStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newEventGroupRelationshipStore.change({ ...this.newEventGroupRelationshipStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result, item } = this.newEventGroupRelationshipStore.state;
    const { eventGroupOptions, typeHourOptions } = this.state;
    console.log(eventGroupOptions);
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newEventGroupRelationshipStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Event Type Group')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newEventGroupRelationshipStore.state.result && !this.newEventGroupRelationshipStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newEventGroupRelationshipStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newEventGroupRelationshipStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>{t('Event Group')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder={'Event Group'}
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(x => x.propertyName === nameof<CreateEventGroupRelationshipDto>('eventGroupId'))
                      }
                      options={eventGroupOptions || []}
                      value={item.eventGroupId}
                      onChange={(_, { value }) => this.handleValue(nameof<CreateEventGroupRelationshipDto>('eventGroupId'), value)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>{t('Lead Type Hour Code')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder="Lead Type Hour Code"
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(x => x.propertyName === nameof<CreateEventGroupRelationshipDto>('leadTypeHoursCodeId'))
                      }
                      options={typeHourOptions || []}
                      value={item.leadTypeHoursCodeId}
                      onChange={(_, { value }) => this.handleValue(nameof<CreateEventGroupRelationshipDto>('leadTypeHoursCodeId'), value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t('Apprentice Type Hour Code')}</label>
                    <Dropdown
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      placeholder="Apprentice Type Hour Code"
                      error={
                        result &&
                        !result.isSuccess &&
                        (result.messages || []).any(
                          x => x.propertyName === nameof<CreateEventGroupRelationshipDto>('apprenticeTypeHoursCodeId')
                        )
                      }
                      options={typeHourOptions || []}
                      value={item.apprenticeTypeHoursCodeId}
                      onChange={(_, { value }) =>
                        this.handleValue(nameof<CreateEventGroupRelationshipDto>('apprenticeTypeHoursCodeId'), value)
                      }
                    />
                  </Form.Field>
                  {/* <Form.Input
                    required
                    fluid
                    error={!this.newEventGroupRelationshipStore.state.item.name && this.state.userClickedSaved}
                    label={t('Event Type Group')}
                    placeholder={t('Name')}
                    value={this.newEventGroupRelationshipStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateEventGroupRelationshipDto>('name'), value)}
                  /> */}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewEventGroupRelationshipView);
