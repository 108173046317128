import React, { Component } from 'react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChooseSsaTemplateModalButton from './choose-ssa-template-button';
import { connect } from 'redux-scaffolding-ts';
import './ssa-template-step.less';
import { SsaFormWizardStore } from 'stores/assessments/wizard/ssa-form-wizard-store';
import { SsaTemplateDto } from 'stores/assessments/templates/ssa-templates-store';

export interface SsaTemplateStepProps extends WithTranslation {
  active: boolean;
  ssaFormWizardStore?: SsaFormWizardStore;
  onStepChanged?: () => void;
  isEditMode?: boolean;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
class SsaTemplateStep extends Component<SsaTemplateStepProps> {
  private handleOnTemplateChange = (template: SsaTemplateDto) => {
    this.props.ssaFormWizardStore.change({ template: template });
    this.props.onStepChanged && this.props.onStepChanged();
  };

  componentDidMount() {
    const { template } = this.props.ssaFormWizardStore.state.item;
    if (this.props.isEditMode && this.props.ssaFormWizardStore.state.item.template) {
      this.handleOnTemplateChange(template);
    }
  }

  render() {
    const { t, ssaFormWizardStore: store, active, isEditMode } = this.props;
    const { profileId, template } = store.state.item;

    return (
      <div className="wizard__step wizard__step-two ssa-form-ssa-template">
        <StepsComponent active={active} number={'2'} title={t('SELECT SSA TEMPLATE')}>
          <div className="step2__select-ssa-wrapper">
            <div className="step2__select-ssa-id-name">
              <ChooseSsaTemplateModalButton
                isEditMode={isEditMode}
                size={!template ? 'Normal' : 'Mini'}
                onAddTemplate={this.handleOnTemplateChange}
                profileId={profileId}
                selectedTemplateId={template ? template.id : undefined}
              />
              {template && (
                <>
                  <p>{template?.friendlyId}</p>
                  <p>{template?.title}</p>
                </>
              )}
            </div>
          </div>
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(SsaTemplateStep);
