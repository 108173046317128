import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SkillDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './user-profile-listing-details/score-summary';
import './employee-page.less';
import './background-employee.less';
import RadarChartComponent, { ComparisonViewModel } from './radar-chart-component';
import { ProfileItemDto, ProfileSkillDto } from 'stores/profile/profile-store';

export interface SsaRadarChartComponentProps extends WithTranslation {
  skillsByProfileAssigned: SkillDto[];
  scoreSummary: ScoreSummary;
  targetProfile: ProfileItemDto;
}

class SsaRadarChartComponent extends Component<SsaRadarChartComponentProps> {
  generateData(skills: SkillDto[], skillScores: SkillScore[], targetSkills: ProfileSkillDto[]) {
    let maxPoint = -1;

    const points = skills.map(skill => {
      const skillScore = skillScores.firstOrDefault(score => score.id === skill.skillId);
      const comparisonTargetSkill = targetSkills.find(targetSkill => targetSkill.skillId === skill.skillId);
      const p = {
        subject: skill.skillName,
        actual: skillScore ? skillScore.score : 0,
        targetLevel: skill.main,
        comparisonTargetLevel: comparisonTargetSkill ? comparisonTargetSkill.main : 0,
        fullMark: 4
      };
      maxPoint = Math.max(...[maxPoint, p.actual, p.targetLevel, p.comparisonTargetLevel]);
      return p;
    });

    if (maxPoint <= 0) maxPoint = 4;
    points.forEach(p => (p.fullMark = maxPoint));

    return { points, maxPoint };
  }
  public render() {
    const { t, targetProfile, skillsByProfileAssigned, scoreSummary } = this.props;
    const skillScores = scoreSummary ? scoreSummary.skillScores || [] : [];
    const targetProfileSkills = targetProfile ? targetProfile.skills || [] : [];
    const { points: data, maxPoint } = this.generateData(skillsByProfileAssigned, skillScores, targetProfileSkills);
    const comparisonViewModels: ComparisonViewModel[] = [
      {
        name: t('Target Level'),
        dataKey: 'targetLevel',
        stroke: '#EAAA00',
        fill: '#8884d8',
        fillBorder: 4
      }
    ];

    if (targetProfile) {
      comparisonViewModels.push({
        name: t(`${targetProfile.name} Target Level`),
        dataKey: 'comparisonTargetLevel',
        stroke: '#B777D7',
        fill: '#00B2A933',
        fillBorder: 4
      });
    }

    return <RadarChartComponent data={data} comparisonViewModels={comparisonViewModels} maxPoint={maxPoint} />;
  }
}

export default withTranslation()(SsaRadarChartComponent);
