import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { ProfessionsStore, ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';

interface MultipleProfessionsEditorProps extends WithTranslation {
  value: ProfessionDto[];
  onChange?: (value: ProfessionDto[]) => void;
  professions?: ProfessionsStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultipleProfessionsEditorState {
  options: MultipleSelectionDropdownOptions<ProfessionDto>[];
}

@connect(['professions', ProfessionsStore])
class MultipleProfessionsEditor extends React.Component<MultipleProfessionsEditorProps, MultipleProfessionsEditorState> {
  private get professionsStore() {
    return this.props.professions;
  }

  state: MultipleProfessionsEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(professions: QueryResult<ProfessionDto>): MultipleSelectionDropdownOptions<ProfessionDto>[] {
    return professions.items.map(profession => ({
      id: profession.id,
      value: profession,
      text: profession.profession
    }));
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.professionsStore
        .getAllAsync({
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<ProfessionDto>('profession'), useProfile: false }],
          filter: []
        })
        .then(professions => this.setState({ options: this.mapToOptions(professions) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        className={this.props.className}
        searchable
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleProfessionsEditor);
