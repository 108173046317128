import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message, Icon } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
import SkillsEditor, { SkillItemReference, SkillType } from 'widgets/bussiness/skills-editor';
import {
  NewIwsQuestionBankStore,
  CreateIwsQuestionBankDto,
  IwsQuestionBankDto
} from 'stores/assessments/questionBank/iws-questionBank-store';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';
import { nameof } from 'utils/object';
import { Query } from 'stores/dataStore';
import i18n from 'i18n';
import { CreateQuestionDto, QuestionDto } from 'stores/assessments/questionBank/tna-questionBank-store';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';

interface NewIwsQuestionBankViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newQuestionBank?: NewIwsQuestionBankStore;
  languagesStore?: DropdownLanguagesStore;
  isClone?: boolean;
  cloneData: IwsQuestionBankDto;
}

interface NewIwsQuestionBankState {
  skill: SkillItemReference;
  userClickedSave: boolean;
  questionTranslations: CreateQuestionDto[];
  defaultLanguage: LanguageDto;
  query: Query;
}

@connect(['newQuestionBank', NewIwsQuestionBankStore], ['languagesStore', DropdownLanguagesStore])
class NewIwsQuestionBankView extends React.Component<NewIwsQuestionBankViewProps, NewIwsQuestionBankState> {
  private get newQuestionBankStore() {
    return this.props.newQuestionBank;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: NewIwsQuestionBankViewProps) {
    super(props);
    let query: Query = {
      searchQuery: '',
      orderBy: [],
      skip: 0,
      take: 1000,
      filter: [{ or: [{ LanguageCode: 'EN-en' }, { Language: 'English' }] }]
    };

    this.newQuestionBankStore.createNew({
      question:
        this.props.isClone && this.props.cloneData
          ? { text: this.props.cloneData.question.text, languageId: this.props.cloneData.question.language.id }
          : null,
      iwsSkillId: this.props.isClone && this.props.cloneData && this.props.cloneData.iwsSkillId ? this.props.cloneData.iwsSkillId : '',
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : [],
      locationId: this.props.isClone && this.props.cloneData ? this.props.cloneData.locationId : null,
      locationRequired: false
    });
    this.state = {
      query,
      skill:
        this.props.isClone && this.props.cloneData && this.props.cloneData.iwsSkillId
          ? Object.assign({ id: this.props.cloneData.iwsSkillId, title: this.props.cloneData.iwsSkill.name })
          : null,
      userClickedSave: false,
      questionTranslations:
        this.props.isClone && this.props.cloneData ? this.mapToCreateQuestionDto(this.props.cloneData.questionTranslations) : [],
      defaultLanguage: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewIwsQuestionBankViewProps) {
    if (this.newQuestionBankStore.state.result && this.newQuestionBankStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  componentDidMount() {
    this.loadDefaulLanguage();
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    if (areYouPoc || (this.props.isClone && this.props.cloneData && this.props.cloneData.locationId)) {
      const change = {};
      change['locationRequired'] = true;
      this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
    }
  }

  private loadDefaulLanguage() {
    this.props.languagesStore.getAllAsync(this.state.query).then(e => {
      var defLanguage = e.items.firstOrDefault(x => x.languageCode === 'EN-en' || x.language === 'English');
      this.setState({ defaultLanguage: defLanguage ? defLanguage : e.items[0] });
    });
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.newQuestionBankStore.change({ ...this.newQuestionBankStore.state.item, ...change });
  }

  private mapToCreateQuestionDto(items: QuestionDto[]): CreateQuestionDto[] {
    let data: CreateQuestionDto[] = [];
    items.forEach(i => {
      data.push({ text: i.text, languageId: i.language.id });
    });
    return data;
  }

  private onChangeQuestion(q: string) {
    let question = this.newQuestionBankStore.state.item.question;
    if (question) {
      question.text = q;
    } else {
      question = { text: q, languageId: this.state.defaultLanguage ? this.state.defaultLanguage.id : null };
    }
    this.handleValue(nameof<CreateIwsQuestionBankDto>('question'), question);
  }

  private onChangeSkill(s: SkillItemReference) {
    let id = s != null ? s.id : null;
    this.handleValue(nameof<CreateIwsQuestionBankDto>('iwsSkillId'), id);
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newQuestionBankStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newQuestionBankStore.clear();
    this.props.onClose(false);
  }

  addQuestionsTranslation = () => {
    let question = this.state.questionTranslations;
    question.push({ text: '', languageId: null });

    this.setState({ questionTranslations: question });
  };

  handleQuestionTransaltionTextChange = (index: number, questionText: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.text = questionText;

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateIwsQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  handleQuestionTransaltionLanguageChange = (index: number, languageId: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.languageId = languageId;
    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateIwsQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  removeDateRange = index => {
    let questionTranslations = this.state.questionTranslations;
    questionTranslations.splice(index, 1);

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<CreateIwsQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  showQuestionTranslationRows = () => {
    return this.state.questionTranslations.map((question, index) => {
      return (
        <Form.Group className="tna-question-bank__new-rows" key={`${question.languageId}_${index}`}>
          <Form.Field
            required
            className="tna-question-bank__label-textarea"
            error={this.state.userClickedSave && question.text.length === 0}
          >
            <TextArea
              maxLength="500"
              className="tna-question-bank__new-rows__textarea"
              value={question ? question.text : ''}
              onChange={(e, { value }) => this.handleQuestionTransaltionTextChange(index, value.toString())}
            />
          </Form.Field>
          <div className="tna-question-bank__new-rows__drop-remove">
            <LanguageEditor
              className={'planit-user-dropdown-custom'}
              nullable
              value={question.languageId}
              onChange={c => {
                this.handleQuestionTransaltionLanguageChange(index, c == null ? null : c.id);
              }}
              onlyForTests={true}
            />

            <div className="new-pattern__remove-icon">
              <Icon className="clickable-icon remove-icon" color="red" name="remove" onClick={() => this.removeDateRange(index)} />
            </div>
          </div>
        </Form.Group>
      );
    });
  };

  public render() {
    const { t } = this.props as any;

    const modalTitle = this.props.isClone ? 'Clone IWS Question' : 'New IWS Question';

    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const adminOrPowerInst = IdentityService.isAdminOrPowerInstructor(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    return (
      <Modal className="tna-question-bank__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newQuestionBankStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-squestion-bank__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newQuestionBankStore.state.result && !this.newQuestionBankStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newQuestionBankStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newQuestionBankStore.state.item && (
              <Form>
                <div className="tna-question-bank__wrapper scroll">
                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field className="tna-question-bank__label-input">
                      <label>{t('IWS Skill')}</label>
                      <SkillsEditor
                        nullable
                        skillType={SkillType.IWS}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select IWS Skill')}
                        value={this.state.skill}
                        onChange={s => this.onChangeSkill(s)}
                        clearable
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-group">
                    {(areYouPoc || (adminOrPowerInst && this.newQuestionBankStore.state.item.locationRequired)) && (
                      <Form.Field
                        key={'location-editor-field-new-iws'}
                        className="tna-question-bank__label-input"
                        required
                        error={this.newQuestionBankStore.state.result && !this.newQuestionBankStore.state.item.locationId}
                      >
                        <label>{t('Location')}</label>
                        <LocationEditorWithRoleLocations
                          key={'location-editor-new-iws'}
                          className="planit-user-dropdown-custom"
                          value={this.newQuestionBankStore.state.item.locationId}
                          locationsReceived={areYouPoc ? pocLocations : []}
                          onChange={location =>
                            this.handleValue(nameof<CreateIwsQuestionBankDto>('locationId'), location ? location.id : null)
                          }
                          skipDefaultClass
                          customPlaceholder={"PoC's Location"}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                  <Form.Group className="tna-question-bank__selctor-textarea">
                    <Form.Field
                      required
                      className="tna-question-bank__label-textarea"
                      error={this.state.userClickedSave && !this.newQuestionBankStore.state.item.question}
                    >
                      <label>{t('Question')}</label>
                      <TextArea
                        maxLength="500"
                        className="tna-question-bank__custom-editorequest__textarea"
                        value={this.newQuestionBankStore.state.item.question ? this.newQuestionBankStore.state.item.question.text : ''}
                        onChange={(e, { value }) => this.onChangeQuestion(value.toString())}
                      />

                      <Button
                        className="custom-table-add-row-btn add-row-btn"
                        type="button"
                        content="Add Row"
                        onClick={this.addQuestionsTranslation}
                      >
                        {i18n.t('Add Translation')}
                      </Button>
                    </Form.Field>
                  </Form.Group>
                  {this.state.questionTranslations.length > 0 && <>{this.showQuestionTranslationRows()}</>}
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-question-bank__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewIwsQuestionBankView);
