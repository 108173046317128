import { resolve } from 'inversify.config';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IdentityService } from 'services/identity-service';
import { RequestStatus } from 'stores/requests/requests-store';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface MultipleRequestStatusEditorProps extends WithTranslation {
  value: RequestStatus[];
  onChange?: (value: RequestStatus[]) => void;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  statusFilterActive?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultipleRequestStatusEditorState {
  options: MultipleSelectionDropdownOptions[];
}

class MultipleRequestStatusEditor extends React.Component<MultipleRequestStatusEditorProps, MultipleRequestStatusEditorState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: MultipleRequestStatusEditorState = {
    options: this.getAllRequestStatus()
  };

  private getAllRequestStatus() {
    const { statusFilterActive } = this.props;
    const currentUserInfo = this.identityService.getUserInfo();

    let map: { id: string; text: string; value: string }[] = [];

    for (let n in RequestStatus) {
      if (typeof RequestStatus[n] === 'number') {
        if (statusFilterActive) {
          if (IdentityService.isPlanner(currentUserInfo)) {
            if (RequestStatus[n].toString() === '20') {
              map.push({ id: RequestStatus[n], text: n, value: RequestStatus[n] });
            }
          } else {
            if (RequestStatus[n].toString() === '10' || RequestStatus[n].toString() === '20' || RequestStatus[n].toString() === '30') {
              map.push({ id: RequestStatus[n], text: n, value: RequestStatus[n] });
            }
          }
        }
        if (!statusFilterActive && (RequestStatus[n].toString() === '40' || RequestStatus[n].toString() === '50')) {
          map.push({ id: RequestStatus[n], text: n, value: RequestStatus[n] });
        }
      }
    }

    return map.sort((a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
      if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
      return 0;
    });
  }

  private handleOnSelection(value: string[]) {
    const eventInstructorRole: RequestStatus[] = value.map(x => RequestStatus[x]);
    this.props.onChange && this.props.onChange(eventInstructorRole);
  }

  public render() {
    const { className } = this.props;
    return (
      <MultipleSelectionDropdown
        searchable
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        options={this.state.options.sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
          if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
          return 0;
        })}
        value={(this.props.value || []).map(x => RequestStatus[x])}
        onChange={(value: string[]) => this.handleOnSelection(value)}
        className={className ? className : ''}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleRequestStatusEditor);
