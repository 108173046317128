import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CountrySelector from 'widgets/bussiness/selectors/country-selector';
import { Grid, Button, Input, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import { Query, QueryResult } from 'stores/dataStore';
import { connect } from 'redux-scaffolding-ts';
import PeriodInput from 'widgets/form/period-input';
import { DateTimePeriod } from 'stores/events/events-store';
import {
  DateTimeOffset,
  CreateCountryPublicHolidayConfigurationDto,
  CountryPublicHolidayDto,
  CountryPublicHolidayConfigurationStore,
  ChangeCountryPublicHolidayConfigurationStore,
  NewCountryPublicHolidayConfigurationStore
} from 'stores/public-holidays/public-holidays-store';
import { DateTimeService } from 'services/datetime-service';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { CountriesStore, CountryDto } from 'stores/configuration/locations/countries-store';

export interface CountryPublicHolidayConf {
  id: string;
  countryId: string;
  year: number;
  periods: DateTimePeriod[];
}

export interface SchedulerCountryPublicHolidaysProps extends WithTranslation {
  onClose?: (data?: CountryPublicHolidayConf) => void;
  countries?: CountriesStore;
  country?: CountryDto;
  countryPublicHolidaysConfigurationStore?: CountryPublicHolidayConfigurationStore;
  changeCountryPublicHolidaysConfigurationStore?: ChangeCountryPublicHolidayConfigurationStore;
  newCountryPublicHolidaysConfigurationStore?: NewCountryPublicHolidayConfigurationStore;
}

export interface SchedulerCountryPublicHolidaysState {
  countryPublicHolidayConfiguration: CountryPublicHolidayConf;
  loading: boolean;
}
const year = DateTimeService.now().year();

@connect(
  ['countryPublicHolidaysConfigurationStore', CountryPublicHolidayConfigurationStore],
  ['changeCountryPublicHolidaysConfigurationStore', ChangeCountryPublicHolidayConfigurationStore],
  ['newCountryPublicHolidaysConfigurationStore', NewCountryPublicHolidayConfigurationStore]
)
class SchedulerCountryPublicHolidaysComponent extends Component<SchedulerCountryPublicHolidaysProps, SchedulerCountryPublicHolidaysState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      countryPublicHolidayConfiguration: { ...this.defaultCountryPublicHolidaysConfiguration }
    };
  }

  get defaultCountryPublicHolidaysConfiguration() {
    return { id: null, periods: [], year, countryId: null };
  }

  componentDidMount() {
    if (this.props?.country) this.handleOneCountry(this.props?.country);
  }

  handleOneCountry = (country: CountryDto) => {
    if (!country) return;

    const filter: any = [{ CountryId: { eq: { type: 'guid', value: country.id } } }];
    const query: Query = { searchQuery: '', skip: 0, take: 1, filter };

    this.setState(
      ({ countryPublicHolidayConfiguration }) => ({
        countryPublicHolidayConfiguration: { ...countryPublicHolidayConfiguration, countryId: country?.id }
      }),
      async () => this.manageResponse(await this.getAllAsync(query))
    );
  };

  manageResponse = (response: QueryResult<CountryPublicHolidayDto>) => {
    if (!response) return;
    const previousConfiguration = (response?.items || []).length > 0;
    const periods = previousConfiguration ? this.mapToDateTimePeriod(response.items[0].publicHolidayPeriods) : [];
    const id = previousConfiguration ? response.items[0].id : null;
    this.setState(({ countryPublicHolidayConfiguration }) => ({
      countryPublicHolidayConfiguration: { ...countryPublicHolidayConfiguration, periods, id }
    }));
  };

  mapToDateTimePeriod(items: DateTimeOffset[]): DateTimePeriod[] {
    return items.map(({ startDate, endDate }) => ({ from: startDate, to: endDate }));
  }

  getAllAsync = async (query: Query): Promise<QueryResult<CountryPublicHolidayDto>> => {
    this.setState({ loading: true });
    try {
      return await this.props.countryPublicHolidaysConfigurationStore.getAllAsync(query);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  handleCountryChange = async (country: string) => {
    if (!country) {
      this.setState({ countryPublicHolidayConfiguration: this.defaultCountryPublicHolidaysConfiguration });
      return;
    }
    let countryPublicHolidayConfiguration = { ...this.state.countryPublicHolidayConfiguration };
    countryPublicHolidayConfiguration.countryId = country;

    const filter: any = [{ CountryId: { eq: { type: 'guid', value: countryPublicHolidayConfiguration.countryId } } }];
    const query: Query = { searchQuery: '', skip: 0, take: 1, filter };
    this.setState({ countryPublicHolidayConfiguration });

    const response = await this.getAllAsync(query);
    this.manageResponse(response);
  };

  handlePeriodInputChange = (index: number, dateTimePeriod: DateTimePeriod) => {
    const change: DateTimePeriod = { ...dateTimePeriod };

    if (change?.from && change?.to) {
      const from = DateTimeService.toMoment(change.from);
      if (from.isAfter(change.to)) change.to = change.from;
    }

    this.setState(({ countryPublicHolidayConfiguration: { periods, ...rest } }) => ({
      countryPublicHolidayConfiguration: { ...rest, periods: (periods || []).map((period, i) => (i === index ? change : period)) }
    }));
  };

  addDatesRange = () =>
    this.setState(({ countryPublicHolidayConfiguration: { periods, ...conf } }) => ({
      countryPublicHolidayConfiguration: { ...conf, periods: [...periods, { from: '', to: '' }] }
    }));

  removeDateRange = (index: number) =>
    this.setState(({ countryPublicHolidayConfiguration: { periods, ...conf } }) => ({
      countryPublicHolidayConfiguration: { ...conf, periods: periods.filter((_, i) => i !== index) }
    }));

  handleOnSaveClicked = async () => {
    const { newCountryPublicHolidaysConfigurationStore, changeCountryPublicHolidaysConfigurationStore, onClose, t } = this.props;
    const { countryPublicHolidayConfiguration } = this.state;
    const { id, countryId, periods, year } = countryPublicHolidayConfiguration;

    if (!id) {
      newCountryPublicHolidaysConfigurationStore.createNew(this.mapToCreatePublicationHolidayDto(countryPublicHolidayConfiguration));

      await newCountryPublicHolidaysConfigurationStore.submit();

      if (newCountryPublicHolidaysConfigurationStore.state.result?.isSuccess) {
        ToastComponent({ text: t('Public holiday saved'), type: 'success-toast' });
        onClose && onClose(countryPublicHolidayConfiguration);
      }
    } else {
      changeCountryPublicHolidaysConfigurationStore.change({ id, countryId, publicHolidayPeriods: this.toDateTimeOffset(periods), year });

      await changeCountryPublicHolidaysConfigurationStore.update();

      if (changeCountryPublicHolidaysConfigurationStore.state.result?.isSuccess) {
        ToastComponent({ text: t('Public holiday saved'), type: 'success-toast' });
        onClose && onClose(countryPublicHolidayConfiguration);
      }
    }
  };

  private mapToCreatePublicationHolidayDto = ({
    countryId,
    periods,
    year
  }: CountryPublicHolidayConf): CreateCountryPublicHolidayConfigurationDto => ({
    countryId,
    publicHolidayPeriods: this.toDateTimeOffset(periods),
    year
  });

  toDateTimeOffset = (dateTimePeriods: DateTimePeriod[]) => dateTimePeriods.map(({ from, to }) => ({ startDate: from, endDate: to }));

  onDismiss = () => {
    this.props.countryPublicHolidaysConfigurationStore.clearMessages();
    this.props.newCountryPublicHolidaysConfigurationStore.clearMessages();
    this.props.changeCountryPublicHolidaysConfigurationStore.clearMessages();
  };

  handleClose = () => {
    this.onDismiss();
    this.props.onClose();
  };

  render() {
    const { t, countryPublicHolidaysConfigurationStore, country } = this.props;
    const { newCountryPublicHolidaysConfigurationStore, changeCountryPublicHolidaysConfigurationStore } = this.props;
    const { isBusy, result } = countryPublicHolidaysConfigurationStore.state;
    const { isBusy: newIsBusy, result: newResult } = newCountryPublicHolidaysConfigurationStore.state;
    const { isBusy: changeIsBusy, result: changeResult } = changeCountryPublicHolidaysConfigurationStore.state;
    const { countryId, periods } = this.state.countryPublicHolidayConfiguration;
    const messages = [
      ...(result?.messages || []).map(x => x.body),
      ...(newResult?.messages || []).map(x => x.body),
      ...(changeResult?.messages || []).map(x => x.body)
    ];
    const msgClass = 'error-message__style';
    const msgIcon = 'exclamation circle';

    return (
      <div className="poc-config-modal__container">
        <Dimmer page active={newIsBusy || changeIsBusy || isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {messages.length > 0 && (
          <Message onDismiss={this.onDismiss} className={msgClass} icon={msgIcon} error header={t('An error ocurred')} list={messages} />
        )}

        <Grid.Row>
          {country?.name ? (
            <h2>
              <b>{country?.name}</b>
            </h2>
          ) : (
            <CountrySelector
              className="filter-form__item-input"
              nullable
              filterByRole
              clearable
              searchable
              placeholder={t('Select Country')}
              value={countryId}
              onChange={this.handleCountryChange}
            />
          )}
        </Grid.Row>
        <div className="poc-config-modal__scrollable-content">
          {(countryId || country?.name) && (
            <>
              <Grid.Row>
                <Grid.Column>{t('Start Date')}</Grid.Column>
                <Grid.Column>{t('End Date')}</Grid.Column>
                <Grid.Column>{t('Days')}</Grid.Column>
              </Grid.Row>
              {(periods || []).map((period, index) => {
                const inputValue =
                  period?.from && period?.to
                    ? DateTimeService.toMoment(period?.to).diff(DateTimeService.toMoment(period?.from), 'days') + 1
                    : 0;

                return (
                  <Grid.Row className="poc-config-modal__ranges-container" key={period?.from + index}>
                    <PeriodInput
                      id={'public-holidays' + period?.from + index + period?.to}
                      value={period}
                      min={period?.from}
                      onChange={p => this.handlePeriodInputChange(index, p)}
                    />
                    <Grid.Column>
                      <Input disabled value={inputValue} />
                    </Grid.Column>
                    <Grid.Column verticalAlign="bottom">
                      <Icon className="remove-row__times" size="big" color="red" name="times" onClick={() => this.removeDateRange(index)} />
                    </Grid.Column>
                  </Grid.Row>
                );
              })}
            </>
          )}
        </div>
        <Grid.Row className="poc-config-modal__action-buttons">
          <div className="left">
            <Button
              disabled={!countryId && !!!country?.name}
              className="inverted-color-btn add"
              content={t('Add Dates')}
              onClick={this.addDatesRange}
            />
          </div>
          <div className="right">
            <Button basic className="cancel" content={t('Cancel')} onClick={this.handleClose} />
            <Button positive disabled={!countryId} className="save" content={t('Save')} onClick={this.handleOnSaveClicked} />
          </div>
        </Grid.Row>
      </div>
    );
  }
}
export default withTranslation()(SchedulerCountryPublicHolidaysComponent);
