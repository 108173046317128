import React from 'react';
import ReactDOMServer from 'react-dom/server';
import LocationsTableComponent from '../shared-scheduler-components/locations-table-component';
import { DateHelper } from '@planit/bryntum-scheduler';
import SchedulerEvent from '../shared-scheduler-components/events/scheduler-event';
import SchedulerEventTooltip from '../shared-scheduler-components/events/scheduler-event-tooltip';
import { DateTimeService } from 'services/datetime-service';

const SchedulerLocationsConfig = {
  eventLayout: 'stack',
  columns: [
    {
      searchable: true,
      text: 'Locations',
      width: 200,
      editor: false,
      locked: true,
      cellCls: 'instructors-component',
      renderer: ({ record }) => <LocationsTableComponent isoCode={record.isoCode} location={record.location} country={record.countryName} />
    }
  ],
  timeRangesFeature: { enableResizing: false, showCurrentTimeLine: false, currentDateFormat: 'DD/MM/YYYY', showHeaderElements: true },
  zoomOnMouseWheel: false,
  weekStartDay: 1,
  eventBodyTemplate: ({ eventRecord, pausePeriods }) =>
    ReactDOMServer.renderToStaticMarkup(<SchedulerEvent event={eventRecord.event} pausePeriods={pausePeriods} />),

  eventRenderer: ({ eventRecord, tplData }, schedulerEngine) => {
    const { travelDays, eventType, status, requestedLocation, ...rest } = eventRecord.event;
    tplData.cls['b-milestone'] = false;
    tplData.cls['b-sch-event-narrow'] = false;
    tplData.wrapperCls['b-milestone-wrap'] = false;

    if (travelDays) {
      tplData.cls['travel-event'] = true;
      tplData.wrapperCls['b-sch-travel-event'] = true;
    }

    if (tplData.width < 24) tplData.cls['mini-event'] = true;

    if (eventRecord.event.travelStart || eventRecord.event.travelEnd) {
      tplData.cls['travel-event'] = true;
      tplData.style = 'border: none!important';
      return { eventRecord, pausePeriods: null };
    } else {
      let eColor = eventType ? eventType.eventsColor : '#eee';
      const startDate = new Date(eventRecord.startDate);
      const newPausePeriods = [];
      if (status === 'Draft') tplData.cls += 'draft';

      if ((rest.pausePeriods || []).length > 0) {
        const ppRange: { left: number; width: number }[] = [];
        rest.pausePeriods.forEach(pause => {
          const from = new Date(DateTimeService.toSchedulerFromString(pause.from));
          const to = new Date(DateTimeService.toSchedulerToString(pause.to));
          const startOffset = DateHelper.diff(startDate, from);
          const endOffset = DateHelper.diff(from, to);

          const dateToPx = date => schedulerEngine.getCoordinateFromDate(date) - tplData.left;
          const startPixels = DateHelper.add(startDate, startOffset);
          const endPixels = DateHelper.add(startDate, endOffset);
          const left = dateToPx(startPixels) - 10; // -10px offset of container left border;
          const width = dateToPx(endPixels);
          ppRange.push({ left, width });
        });
        newPausePeriods.push(...ppRange);
      }
      tplData.style = `border-color: ${eColor}`;

      if (requestedLocation) tplData.cls += ' requested-location';

      return { eventRecord, pausePeriods: newPausePeriods };
    }
  },
  eventTooltipFeature: {
    cls: 'scheduler-tooltip',
    startDate: Date,
    endDate: Date,
    align: 't-b',
    template: ({ eventRecord: { event } }) => ReactDOMServer.renderToString(<SchedulerEventTooltip event={event} />)
  }
};

export default SchedulerLocationsConfig;
