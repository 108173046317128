import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeCustomizationDataDto,
  ChangeCustomizationDataStore,
  CustomizationDataDto
} from '../../../../../stores/configuration/events-n-requests/customization-data-store';
import { nameof } from '../../../../../utils/object';

interface ChangeCustomizationDataViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeCustomizationData?: ChangeCustomizationDataStore;
  currentCustomizationData: CustomizationDataDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeCustomizationDataViewState {}

@connect(['changeCustomizationData', ChangeCustomizationDataStore])
class ChangeCustomizationDataView extends React.Component<ChangeCustomizationDataViewProps, ChangeCustomizationDataViewState> {
  private get changeCustomizationDataStore() {
    return this.props.changeCustomizationData;
  }

  constructor(props: ChangeCustomizationDataViewProps) {
    super(props);

    this.changeCustomizationDataStore.state.result = null;

    this.changeCustomizationDataStore.change({
      id: this.props.currentCustomizationData.id,
      name: this.props.currentCustomizationData.name
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeCustomizationDataViewProps) {
    if (this.changeCustomizationDataStore.state.result && this.changeCustomizationDataStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeCustomizationDataStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeCustomizationDataStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeCustomizationDataStore.change({ ...this.changeCustomizationDataStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeCustomizationDataStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Customization Data')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeCustomizationDataStore.state.result && !this.changeCustomizationDataStore.state.result.isSuccess && (
              <Message
                error
                className="error-message__style"
                icon="exclamation circle"
                header={t('An error ocurred')}
                list={this.changeCustomizationDataStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeCustomizationDataStore.state.item && (
              <Form>
                <Form.Field>
                  <Form.Input
                    required
                    fluid
                    label={t('Customization Data')}
                    placeholder={t('Name')}
                    value={this.changeCustomizationDataStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeCustomizationDataDto>('name'), value)}
                  />
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeCustomizationDataView);
