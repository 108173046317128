import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { NewNmrHoursDurationStore } from 'stores/configuration/events-n-requests/non-machine-related/nmr-hours-durations-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { ItemReference } from 'stores/dataStore';

interface NewNmrHoursDurationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newNmrHoursDuration?: NewNmrHoursDurationStore;
}

interface NewNmrHoursDurationViewState {
  userClickedSaved: boolean;
  trainingNameName: ItemReference;
  functionalArea: ItemReference;
  cluster: ItemReference;
}

@connect(['newNmrHoursDuration', NewNmrHoursDurationStore])
class NewNmrHoursDurationView extends React.Component<NewNmrHoursDurationViewProps, NewNmrHoursDurationViewState> {
  private get newNmrHoursDurationStore() {
    return this.props.newNmrHoursDuration;
  }

  constructor(props: NewNmrHoursDurationViewProps) {
    super(props);
    this.newNmrHoursDurationStore.createNew({
      clusterId: '',
      functionalAreaId: '',
      trainingNameId: '',
      hoursDuration: 0
    });
    this.state = {
      trainingNameName: null,
      functionalArea: null,
      cluster: null,
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewNmrHoursDurationViewProps) {
    if (this.newNmrHoursDurationStore.state.result && this.newNmrHoursDurationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newNmrHoursDurationStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newNmrHoursDurationStore.clear();
    this.props.onClose(false);
  }

  private handleValues = (...changes: [string, any][]) => {
    if ((changes || []).length === 0) return;
    const change = {};
    changes.forEach(([prop, value]) => {
      change[prop] = value;
    });
    this.newNmrHoursDurationStore.change({ ...this.newNmrHoursDurationStore.state.item, ...change });
    this.setState({ userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newNmrHoursDurationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New NMR Hours Duration')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newNmrHoursDurationStore.state.result && !this.newNmrHoursDurationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newNmrHoursDurationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newNmrHoursDurationStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <div
                      className={`required ${
                        (this.newNmrHoursDurationStore.state.item.clusterId == null ||
                          isNullOrWhiteSpaces(this.newNmrHoursDurationStore.state.item.clusterId)) &&
                        this.state.userClickedSaved
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        placeholder={t('Cluster')}
                        nullable
                        value={this.state.cluster}
                        onChange={c => {
                          this.setState({ cluster: c, functionalArea: null, trainingNameName: null });
                          this.handleValues(['clusterId', c?.id], ['functionalAreaId', null], ['trainingNameId', null]);
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div
                      className={`required ${
                        (this.newNmrHoursDurationStore.state.item.functionalAreaId == null ||
                          isNullOrWhiteSpaces(this.newNmrHoursDurationStore.state.item.functionalAreaId)) &&
                        this.state.userClickedSaved
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Functional Area')}</label>
                      <NMFunctionalAreaEditor
                        placeholder={t('Functional Area')}
                        nullable
                        readonly={this.state.cluster?.id == null}
                        clusterId={this.state.cluster ? this.state.cluster.id : undefined}
                        value={this.state.functionalArea}
                        onChange={fa => {
                          this.setState({ functionalArea: fa, trainingNameName: null });
                          this.handleValues(['functionalAreaId', fa?.id], ['trainingNameId', null]);
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className="field">
                      <label>{t('Training Name')}</label>
                      <NMTrainingNameEditor
                        placeholder={t('Training Name')}
                        nullable
                        clearable
                        readOnly={this.state.functionalArea?.id == null}
                        funcionalAreaId={this.state.functionalArea ? this.state.functionalArea.id : undefined}
                        value={this.state.trainingNameName}
                        onChange={c => {
                          this.setState({ trainingNameName: c });
                          this.handleValues(['trainingNameId', c?.id]);
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Input
                    required
                    fluid
                    type="number"
                    error={this.newNmrHoursDurationStore.state.item.hoursDuration <= 0 && this.state.userClickedSaved}
                    label={t('Hours Duration')}
                    placeholder={t('Hours Duration')}
                    value={this.newNmrHoursDurationStore.state.item.hoursDuration}
                    onChange={(_, { value }) => {
                      this.handleValues(['hoursDuration', Math.abs(parseInt(value) || 0)]);
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewNmrHoursDurationView);
