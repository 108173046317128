import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IwsSkillTemplateSectionDto, IwsSkillSectionQuestionDto } from 'stores/assessments/templates/iws-templates-store';
import { List, Label, Button, Icon } from 'semantic-ui-react';
import ChooseIWSQuestionsView from 'widgets/form/choose-iws-questions-form';
import { IwsQuestionBankDto } from 'stores/assessments/questionBank/iws-questionBank-store';
import IwsQuestionComponent from './iws-wizard-second-step-question-component';

export interface IwsSecondStepSkillSectionComponentProps extends WithTranslation {
  skillSection: IwsSkillTemplateSectionDto;
  onChange: (propertyName: keyof IwsSkillTemplateSectionDto, value: any) => void;
  category: string;
  canGoUp: boolean;
  canGoDown: boolean;
  profession: string;
  move: (dir: 'up' | 'down') => void;
}

export interface IwsSecondStepSkillSectionComponentstate {
  isAddLinkWizardOpen: boolean;
  skillLink: string;
}

const IwsSecondStepSkillSectionComponent: FunctionComponent<IwsSecondStepSkillSectionComponentProps> = props => {
  const { skillSection, canGoUp, canGoDown, onChange, move, t } = props;
  const { skillId, skillName } = skillSection;

  const [showQuestions, setShowQuestions] = useState(false);
  const showQuestionsModal = () => {
    setShowQuestions(true);
  };
  const hideQuestionsModal = () => {
    setShowQuestions(false);
  };

  const addQuestionsHandler = (questions: IwsQuestionBankDto[]) => {
    const newQuestions: IwsSkillSectionQuestionDto[] = questions.map(({ id, friendlyId, question }) => ({
      id,
      friendlyId,
      question: question.text
    }));
    let aux = [...skillSection.questions, ...newQuestions];
    onQuestionsChange(aux);
  };
  const onQuestionsChange = (questions: IwsSkillSectionQuestionDto[]) => {
    skillSection.questions = questions;
    alreadySelectedQuestions = [...skillSection.questions];
    onChange('questions', skillSection.questions);
  };

  let alreadySelectedQuestions = [];
  (skillSection.questions || []).forEach(question => question?.id && alreadySelectedQuestions.push(question.id));

  const handleMoveUpQuestion = (questionIdx: number) => {
    const questions = [...skillSection.questions];
    const temporalVariable = { ...questions[questionIdx - 1] };
    questions[questionIdx - 1] = { ...questions[questionIdx] };
    questions[questionIdx] = { ...temporalVariable };
    onQuestionsChange(questions);
  };

  const handleMoveDownQuestion = (questionIdx: number) => {
    const questions = [...skillSection.questions];
    const temporalVariable = { ...questions[questionIdx + 1] };
    questions[questionIdx + 1] = { ...questions[questionIdx] };
    questions[questionIdx] = { ...temporalVariable };
    onQuestionsChange(questions);
  };
  const moveQuestionHandler = (dir: 'up' | 'down', idx: number) => {
    const { questions } = skillSection;
    if (questions.length <= 1) return;
    if (dir === 'up' && idx > 0) handleMoveUpQuestion(idx);
    else if (dir === 'down' && idx < questions.length - 1) handleMoveDownQuestion(idx);
  };

  const removeQuestionHandler = (idx: number) => {
    onQuestionsChange(skillSection.questions.filter((_, i) => i !== idx));
  };

  return (
    <List verticalAlign="middle">
      <List.Item className="edit__select-profiles__wrapper__row">
        <Label className="skill-label">
          <p>{skillName}</p>
          <div className="wizard__sort-icons-wrapper">
            <Button onClick={showQuestionsModal} className="iws-template-wizard__add-questions-btn" type="button">
              {t('Add Questions')}
            </Button>
            <Icon disabled={!canGoUp} onClick={() => move('up')} name="caret square up outline" />
            <Icon disabled={!canGoDown} onClick={() => move('down')} name="caret square down outline" />
          </div>
        </Label>
        {(skillSection.questions || []).map((question, i) => (
          <IwsQuestionComponent
            key={i}
            move={dir => moveQuestionHandler(dir, i)}
            canGoUp={i > 0}
            canGoDown={i + 1 < skillSection.questions.length}
            question={question}
            onRemoveQuestion={() => removeQuestionHandler(i)}
          />
        ))}

        {showQuestions && (
          <ChooseIWSQuestionsView
            onCloseModal={hideQuestionsModal}
            iwsSkillId={skillId}
            iwsSkillName={skillName}
            onAddIWSQuestions={addQuestionsHandler}
            alreadySelectedQuestions={alreadySelectedQuestions}
          />
        )}
      </List.Item>
    </List>
  );
};

export default withTranslation()(IwsSecondStepSkillSectionComponent);
