import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { DateInput } from 'widgets/form/dateInput';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface EventFormHeaderEventDatesProps extends WithTranslation {
  readOnly: boolean;
  startDate: string;
  endDate: string;
  onStartDateChange?: (newStartDate: string) => void;
  onEndDateChange?: (newEndDate: string) => void;
  onRecalculate: (recalculate: 'StartDate' | 'EndDate' | 'Duration') => void;
  hasDuration: boolean;
}

interface EventFormHeaderEventDatesState {}

class EventFormHeaderEventDates extends Component<EventFormHeaderEventDatesProps> {
  state: EventFormHeaderEventDatesState = {};

  private handleOnStartDateChange = (newStartDate: string) => {
    this.props.onStartDateChange && this.props.onStartDateChange(newStartDate);
  };

  private handleOnEndDateChange = (newEndDate: string) => {
    this.props.onEndDateChange && this.props.onEndDateChange(newEndDate);
  };

  render() {
    const { t, readOnly, startDate, endDate, hasDuration, onRecalculate } = this.props;
    const canRecalculateFrom = !readOnly && hasDuration && !isNullOrWhiteSpaces(endDate);
    const canRecalculateTo = !readOnly && hasDuration && !isNullOrWhiteSpaces(startDate);
    return (
      <>
        <div className="form__header__second-row__event-dates">
          <p>{t('Event Dates')}</p>

          <div className="generic-date-picker-modificable__wrapper recalc-container">
            <DateInput
              className="planit-eventform-dates eventform-dates__first"
              readOnly={readOnly}
              value={startDate}
              onChange={(_, value) => this.handleOnStartDateChange(value)}
            />

            <DateInput
              className="planit-eventform-dates eventform-dates__second"
              id="planit-eventform-dates eventform-dates__right"
              readOnly={readOnly}
              value={endDate}
              forceOpen={!!startDate}
              onChange={(_, value) => this.handleOnEndDateChange(value)}
              minDate={startDate || null}
              initialValue={startDate}
            />
            {canRecalculateFrom && (
              <span id="recalc-start-date" className="recalc-command" onClick={() => onRecalculate('StartDate')}>
                {t('Recalculate')}
              </span>
            )}
            {canRecalculateTo && (
              <span id="recalc-end-date" className="recalc-command" onClick={() => onRecalculate('EndDate')}>
                {t('Recalculate')}
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(EventFormHeaderEventDates);
