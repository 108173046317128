import React, { useRef } from 'react';

import { Store } from '@planit/bryntum-scheduler';
import './viewpreset-style.less';
import BryntumWidget from 'widgets/scheduler/BryntumWidget';
const week = 'b-fa b-fa-calendar-week',
  month = 'b-fa b-fa-calendar-alt',
  year = 'b-fa b-fa-calendar',
  handler = 'onTriggerClick',
  align = 'end',
  cls = 'preset-icon';

const ViewPreset = props => {
  const combo = useRef();
  const triggers = {
    month: { cls: month, handler, align, hidden: false },
    week: { cls: week, handler, align, hidden: true },
    year: { cls: year, handler, align, hidden: true }
  };

  const presets = new Store({
    // idField: 'value',

    data: [
      { name: 'Month', preset: 'weekAndMonthJTI', cls, icon: month },
      { name: 'Week', preset: 'weekAndDayJTI', cls, icon: week },
      { name: 'Year', preset: 'monthAndYearJTI', cls, icon: year }
    ]
  });

  const listItemTpl = ({ name, icon }) => {
    return `<div>${name}</div>
    <i class="${icon}"></i>`;
  };

  const iconChangeHandler = eventObj => {
    if (!combo || !combo?.current) return;
    const iconSrcName = eventObj.source.inputValue.toLowerCase();
    let storedTriggers = (combo.current as any).widget.triggers;
    for (const key in storedTriggers) {
      if (!storedTriggers[key].hidden) {
        storedTriggers[key].hide();
      }
    }
    storedTriggers[iconSrcName].show();
  };

  return (
    <BryntumWidget
      ref={combo}
      type="combo"
      placeholder="Preset"
      listItemTpl={listItemTpl}
      editable={false}
      listCls="style-list"
      triggers={triggers}
      cls="viewpreset"
      store={presets}
      valueField="preset"
      displayField="name"
      value="weekAndMonthJTI"
      picker={{ maxHeight: 150, maxWidth: 100 }}
      onChange={eventObj => {
        iconChangeHandler(eventObj);
        props.onViewPresetChange(eventObj.value);
      }}
    />
  );
}; // eo function viewPresetDropdown

export default ViewPreset;
