import React, { Component } from 'react';
import { TextArea } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface TextAnswerProps extends WithTranslation {
  value: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}

class TextAnswer extends Component<TextAnswerProps> {
  private handleOnChange = (value: string) => {
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { value, readOnly } = this.props;

    return (
      <TextArea
        readOnly={readOnly}
        name="strengths"
        className="feedback-form__comment"
        placeholder={'Write here your comments'}
        value={value}
        onChange={(_, { value }) => this.handleOnChange(value as string)}
      />
    );
  }
}

export default withTranslation()(TextAnswer);
