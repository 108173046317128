import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

export interface CancelMergeButtonProps extends WithTranslation {
  text: string;
  onCancel?: () => void;
}

class CancelButton extends Component<CancelMergeButtonProps> {
  handleOnCancelClick = () => {
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    const { text } = this.props;

    return <Button className="form__actions__button form__white-btn" content={text} onClick={this.handleOnCancelClick} />;
  }
}

export default withTranslation()(CancelButton);
