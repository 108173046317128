import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import UsersListPage from '../users/users-list';
import InstructorsListPage from '../users/instructors-list';
import MRInstructorExpertisesListPage from '../users/instructors-expertise/mr-instructor-expertise-list';
import NMRInstructorExpertisesListPage from '../users/instructors-expertise/nmr-instructor-expertise-list';
import InstructorExpertiseRequestsListPage from '../users/instructor-expertise-requests/instructor-expertise-requests-list';
import i18n from '../../../i18n';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface UsersHomeProps extends WithTranslation, RouteComponentProps {}

export interface UsersHomeState {}

class UsersHomePage extends React.Component<UsersHomeProps, UsersHomeState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: UsersHomeProps) {
    super(props);
    this.state = {};
  }
  private panes = [
    {
      menuItem: i18n.t('Users'),
      render: () => (
        <Tab.Pane attached={false}>
          <UsersListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Instructors'),
      render: () => (
        <Tab.Pane attached={false}>
          <InstructorsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t("MR Instructors' Expertise"),
      render: () => (
        <Tab.Pane attached={false}>
          <MRInstructorExpertisesListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t("NMR Instructors' Expertise"),
      render: () => (
        <Tab.Pane attached={false}>
          <NMRInstructorExpertisesListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Requested Expertise'),
      render: () => (
        <Tab.Pane attached={false}>
          <InstructorExpertiseRequestsListPage />
        </Tab.Pane>
      )
    }
  ];

  private isInstructor = () => {
    const userinfo = this.identityService.getUserInfo();
    return !this.isPowerInstructor() && IdentityService.isInstructor(userinfo);
  };

  private isPowerInstructor = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPowerInstructor(userinfo);
  };

  private isPlanner = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlanner(userinfo);
  };

  private getPanes = () => {
    if (this.isPowerInstructor()) {
      return this.panes.slice(1);
    } else if (this.isInstructor()) {
      return [this.panes[this.panes.length - 1]];
    }

    if (this.identityService.isInRole(Roles.PoC)) return this.panes.slice(0, 2);

    if (this.isPlanner()) {
      const plannerPanes = this.panes.slice(2);
      return plannerPanes.slice(0, 2);
    }

    return this.panes.slice(0, -1);
  };

  render() {
    const { t } = this.props;

    if (this.isInstructor()) {
      return (
        <>
          <h3>{t('Requested Expertise')}</h3>
          <LineSeparator />
          <div className="expertise-request-wrapper ui segment active tab">
            <InstructorExpertiseRequestsListPage />
          </div>
        </>
      );
    }

    if (this.isPlanner()) {
      return (
        <>
          <h3>{t("Instructors' Expertise")}</h3>
          <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.getPanes()} />
        </>
      );
    }

    return (
      <>
        {this.isPowerInstructor() ? <h3>{t('Instructors')}</h3> : <h3>{t('Users')}</h3>}

        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.getPanes()} />
      </>
    );
  }
}

export default withTranslation()(UsersHomePage);
