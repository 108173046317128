import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { CreatePillarDto, NewPillarStore } from 'stores/configuration/profiles/pillars-store';
import { nameof } from 'utils/object';

interface NewPillarViewProps extends WithTranslation {
  onClose: () => void;
  newPillar?: NewPillarStore;
}

interface NewPillarViewState {
  userClickedSaved: boolean;
}

@connect(['newPillar', NewPillarStore])
class NewPillarView extends React.Component<NewPillarViewProps, NewPillarViewState> {
  private get newPillarStore() {
    return this.props.newPillar;
  }

  constructor(props: NewPillarViewProps) {
    super(props);
    this.newPillarStore.createNew({
      name: ''
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPillarViewProps) {
    if (this.newPillarStore.state.result && this.newPillarStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPillarStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPillarStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newPillarStore.change({ ...this.newPillarStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result } = this.newPillarStore.state;

    const managedByTypes = [
      {
        key: 1,
        text: 'Planner TFT',
        value: 1
      },
      {
        key: 2,
        text: 'Planner MTC',
        value: 2
      }
    ];

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPillarStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Pillar')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newPillarStore.state.result && !this.newPillarStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPillarStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newPillarStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newPillarStore.state.item.name && this.state.userClickedSaved}
                    label={t('Pillar')}
                    placeholder={t('Name')}
                    value={this.newPillarStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreatePillarDto>('name'), value)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <div className={`field inline configuration-event-n-request__modal__element`}>
                    <label>{t('Managed by')}</label>
                    <Dropdown
                      placeholder={t('Managed by')}
                      search
                      selection
                      closeOnChange
                      closeOnBlur
                      closeOnEscape
                      selectOnNavigation
                      error={
                        result &&
                        !result.isSuccess &&
                        this.state.userClickedSaved &&
                        (result.messages || []).any(x => x.propertyName === nameof<CreatePillarDto>('managedBy'))
                      }
                      options={managedByTypes}
                      value={this.newPillarStore.state.item.managedBy}
                      onChange={(e, { value }) => this.handleValue(nameof<CreatePillarDto>('managedBy'), value)}
                    />
                  </div>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPillarView);
