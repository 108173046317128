import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import i18n from 'i18n';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface IwsTemplateDto {
  id: string; //Guid
  friendlyId: string;
  profileItemId: string;
  profileItemIds: string[];
  profileName: string;
  title: string;
  header: string;
  isActive: boolean;
  skillSection: IwsSkillTemplateSectionDto;
  [key: string]: any;
}

export interface IwsTemplateFilters {
  templateName: string;
  templateId: string;
  profileName: string;
  profileItemId: string;
}

export interface CreateIwsTemplateDto {
  profileItemId: string;
  title: string;
  header: string;
  isActive: boolean;
  skillSection: CreateIwsSkillTemplateSectionDto;
}

export interface CreateIwsSkillTemplateSectionCategoryDto {
  name: string;
  skillSections: CreateIwsSkillTemplateSectionDto[];
}

export interface ChangeIwsTemplateDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  skillSection: ChangeIwsSkillTemplateSectionDto;
}

export interface IwsSkillTemplateSectionCategoryDto {
  name: string;
  skillSections: IwsSkillTemplateSectionDto[];
}

export interface IwsSkillTemplateSectionDto {
  skillId: string;
  skillName: string;
  questions: IwsSkillSectionQuestionDto[];
}

export interface CreateIwsSkillTemplateSectionDto {
  skillId: string;
  questions: string[];
}

export interface ChangeIwsSkillTemplateSectionDto {
  skillId: string;
  questions: string[];
}

export interface IwsCheckpointDto {
  description: string;
  questions: IwsSkillSectionQuestionDto[];
}

export interface IwsSkillSectionQuestionDto {
  id: string;
  friendlyId: string;
  question: string;
  source?: string;
  isAddedInCustom?: boolean;
}

export class CreateIwsTemplateValidator extends ExtendedAbstractValidator<CreateIwsTemplateDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.skillSection && o.skillSection.skillId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));

    this.validateIf(o => o.skillSection)
      .isNotEmpty()
      .fulfills(sSC => sSC != null && sSC.questions != null && sSC.questions.length > 0)
      .withFailureMessage(i18n.t('There should be at least one question per skill'));
  }
}

export class IwsQuestionValidator extends ExtendedAbstractValidator<string[]> {
  constructor(idx: number, skillIdx: number, skill: string, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Question at position ${idx + 1} of skill  ${skill}`);
    this.validateIfEach(x => x)
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('There should be at least one question per skill')}`);
  }
}
export class ChangeIwsTemplateValidator extends ExtendedAbstractValidator<ChangeIwsTemplateDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(o => o.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template id is required'));

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.skillSection && o.skillSection.skillId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));

    this.validateIf(o => o.skillSection)
      .isNotEmpty()
      .fulfills(sSC => sSC != null && sSC.questions != null && sSC.questions.length > 0)
      .withFailureMessage(i18n.t('There should be at least one question per skill'));
  }
}
export class EmptyValidator extends AbstractValidator<any> {}

@repository('@@IWSTEMPLATES', 'iws-templates.summary')
export class IwsTemplatesStore extends DataStore<IwsTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-template';
  retrievePath = 'get-iws-templates';
  retrievePathByProfile = 'get-iws-templates-by-profiles';
  updatePath = 'update-iws-template';
  deletePath = '';
  retrieveOnePath = 'get-iws-template';

  protected validate(item: IwsTemplateDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('IWSTEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getTemplateById(id: string): Promise<IwsTemplateDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<IwsTemplateDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getAllByProfileAsync(profilesId?: any): Promise<QueryResult<IwsTemplateDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path } = this.getParts(profilesId);
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<IwsTemplateDto>>(`${this.baseUrl}/${this.retrievePathByProfile}?${path}`)
    );

    return result.data;
  }

  private getParts(profilesId?: string[]): { path: string } {
    const parts = [];

    if (profilesId && profilesId.length > 0 && profilesId.every(x => !isNullOrWhiteSpaces(x))) {
      profilesId.forEach(i => {
        parts.push(`profilesId=${i}`);
      });
    }

    return { path: parts.join('&') };
  }
}

@repository('@@IWSTEMPLATES', 'iws-templates.new')
export class NewIwsTemplateStore extends FormStore<CreateIwsTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-template';
  retrievePath = 'get-iws-templates';
  retrievePathByProfile = 'get-iws-templates-by-profiles';
  updatePath = 'update-iws-template';

  protected validate(item: CreateIwsTemplateDto) {
    return new CreateIwsTemplateValidator().validate(item);
  }

  constructor() {
    super('NEW_IWSTEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@IWSTEMPLATES', 'iws-templates.change')
export class ChangeIwsTemplateStore extends FormStore<ChangeIwsTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-template';
  retrievePath = 'get-iws-templates';
  retrievePathByProfile = 'get-iws-templates-by-profiles';
  updatePath = 'update-iws-template';

  protected validate(item: ChangeIwsTemplateDto) {
    return new ChangeIwsTemplateValidator().validate(item);
  }

  constructor() {
    super('CHANGE_IWSTEMPLATE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
