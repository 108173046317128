import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Form, Input, Message, Modal, Checkbox, Dimmer, Loader, Dropdown, Container } from 'semantic-ui-react';
import LocationEditor from 'widgets/bussiness/location-editor';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import RoleEditor from 'widgets/bussiness/roles-editor';
import PersonnelAreaEditor, { PersonnelAreaItemReference } from 'widgets/bussiness/personnel-area-editor';
import { RoleLocationsOrRegionsSelection } from 'widgets/bussiness/roles-editor';
import '../../../assets/less/edit-user-modal.less';

import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { DateInput } from 'widgets/form/dateInput';
import { ChangeUserDto, ChangeUserStore, UserDto, CreateRoleInLocationDto } from 'stores/users/users-store';
import { LanguageDto, DropdownLanguagesStore, ChangeLanguageDto } from 'stores/configuration/locations/languages-store';
import { nameof } from '../../../utils/object';
import { ItemReference } from 'stores/dataStore';
import { CalculateSeniority } from 'utils/userinfo-functions';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';

interface ChangeUserViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  onChange?: (value: ChangeLanguageDto[]) => void;
  changeUser?: ChangeUserStore;
  languageStore?: DropdownLanguagesStore;
  currentUser: UserDto;
}

interface ChangeUserViewState {
  location: ItemReference;
  personnelArea: PersonnelAreaItemReference;
  positionCode: ItemReference;
  selectedLanguages: LanguageDto[];
  selectedTrainingLanguages: LanguageDto[];
  languageOptions: { text: string; value: string }[];
  trainingLanguageOptions: { text: string; value: string }[];
  availableLanguages: { [id: string]: LanguageDto };
  availableTrainingLanguages: { [id: string]: LanguageDto };
  userItem: ChangeUserDto;
}

@connect(['changeUser', ChangeUserStore], ['languageStore', DropdownLanguagesStore])
class ChangeUserView extends Component<ChangeUserViewProps, ChangeUserViewState> {
  private get changeUserStore() {
    return this.props.changeUser;
  }

  private get languageStore() {
    return this.props.languageStore;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: ChangeUserViewProps) {
    super(props);
    const { modifiedByUserId, modifiedByUser, instructorRole, ...rest } = this.props.currentUser;
    const { modifiedOn, isAssessor, rowVersion, friendlyId, deletedOn, ...rest2 } = rest;
    const { userPhoto, pillarId, createdOn, deleted, pillar, ...currentUser } = rest2;

    const roles = (currentUser.roles || []).map(({ location, region, role }) => ({
      locationId: location?.id || null,
      regionId: region?.id || null,
      roleName: role.name
    }));

    this.changeUserStore.state.result = null;
    const userDto: ChangeUserDto = {
      ...(currentUser as UserDto),
      positionCode: currentUser.positionCode?.name || '',
      locationId: currentUser.location?.id,
      location: currentUser.location?.location,
      roles
    };
    this.changeUserStore.change(userDto);
    const personnelArea =
      currentUser?.personnelArea || currentUser?.personnelAreaId
        ? { id: currentUser?.personnelAreaId, title: currentUser?.personnelArea }
        : null;
    this.state = {
      personnelArea,
      location: null,
      positionCode: null,
      selectedLanguages: currentUser.languages,
      availableLanguages: {},
      languageOptions: [],
      selectedTrainingLanguages: currentUser.trainingLanguages,
      availableTrainingLanguages: {},
      trainingLanguageOptions: [],
      userItem: userDto
    };
    this.initLanguages();
  }

  private onChangeItem = async () => {
    const { userItem } = this.state;
    this.changeUserStore.change(userItem);
    await this.changeUserStore.update();
    if (this.changeUserStore.state.result?.isSuccess) {
      this.props.onClose(true);
      if (userItem.id === this.identityService.userId) {
        alert(this.props.t(`Updating current user's data. Now you will be redirected to home page due to credentials renew needs`));
        this.identityService.signinSilent(true);
      }
    }
  };

  private onCancelChangeItem = () => {
    this.changeUserStore.clear();
    this.props.onClose(false);
  };

  private handleValue = (property: keyof ChangeUserDto, value: any) => {
    if (property === 'roles') {
      if (!value.any(r => r.roleName === 'PoC')) {
        const userItem = { ...this.state.userItem };
        userItem.isGlobalPoc = false;
        this.setState({ userItem });
      }
    }

    this.setState(({ userItem }) => ({ userItem: { ...userItem, [property]: value } }));
  };

  private handleLanguageValue = (data: any) => {
    let newSelectedLanguages = this.state.selectedLanguages;

    let languages = data.multiple
      ? data.value.map(x => this.state.availableLanguages[x])
      : data.value === ''
      ? null
      : [this.state.availableLanguages[data.value]];

    newSelectedLanguages = this.mapLanguages(languages);

    this.setState({
      selectedLanguages: newSelectedLanguages
    });
    this.handleValue('languages', newSelectedLanguages);
    this.props.onChange(newSelectedLanguages);
  };

  private handleTrainingLanguageValue = (data: any) => {
    let newSelectedTrainingLanguages = this.state.selectedTrainingLanguages;

    let trainingLanguages = data.multiple
      ? data.value.map(x => this.state.availableTrainingLanguages[x])
      : data.value === ''
      ? null
      : [this.state.availableTrainingLanguages[data.value]];

    newSelectedTrainingLanguages = this.mapLanguages(trainingLanguages);

    this.setState({
      selectedTrainingLanguages: newSelectedTrainingLanguages
    });

    this.handleValue('trainingLanguages', newSelectedTrainingLanguages);

    this.props.onChange(newSelectedTrainingLanguages);
  };

  private handleLocation = (property: string, location: any) => {
    this.setState({ location: location });
    this.handleValue(property as any, location ? location.id : null);
  };

  private mapLanguages = (languages: any[]) => {
    languages.forEach(item => {
      if (!item.languageId) item.languageId = item.id;
      if (!item.id) item.id = item.languageId;
    });
    return languages;
  };

  private initLanguages = async () => {
    return await this.languageStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<LanguageDto>('language'),
            useProfile: false
          }
        ]
      })
      .then(languages => {
        const dict = {};
        const options = [];

        languages.items.forEach(language => {
          language.languageId = language.id;
          dict[language.id] = language;
          options.push({ text: language.language, value: language.id });
        });

        this.setState({
          availableLanguages: dict,
          languageOptions: options,
          selectedLanguages: this.props.currentUser.languages,
          availableTrainingLanguages: dict,
          trainingLanguageOptions: options,
          selectedTrainingLanguages: this.props.currentUser.trainingLanguages
        });
      });
  };

  private selectedLanguages = () => {
    if (this.state.selectedLanguages) return this.state.selectedLanguages.map(x => (x.languageId ? x.languageId : x.id));
    return null;
  };

  private selectedTrainingLanguages = () => {
    if (this.state.selectedTrainingLanguages) return this.state.selectedTrainingLanguages.map(x => (x.languageId ? x.languageId : x.id));
    return null;
  };

  componentDidMount() {
    this.setState({
      location: { id: this.state.userItem.locationId, title: this.state.userItem.location },
      positionCode: {
        id: this.state.userItem.positionCodeId,
        title: this.state.userItem.positionCode
      }
    });
  }

  isValidRoleField = () => this.state.userItem.roles.length > 0;

  isSapUser = () => !!this.state.userItem.employeeId;

  canEditBasicUserInformation = (): boolean => {
    if (this.identityService.isInRole(Roles.PoC)) return this.state.userItem.updateFlag;
    else return !this.isSapUser() || this.canEditSapUser();
  };

  canEditActive = (): boolean => {
    if (this.identityService.isInRole(Roles.PoC)) return true;
    else return !this.isSapUser() || this.canEditSapUser();
  };

  canEditUpdateFlag = (): boolean => {
    return this.identityService.isInRole(Roles.Admin) || this.identityService.isInRole(Roles.PoC);
  };

  canEditSapUser = (): boolean => {
    return this.isSapUser() && this.state.userItem.updateFlag && this.identityService.isInRole(Roles.Admin);
  };

  canEditPositionCodeOrLanguages = (): boolean => {
    if (this.identityService.isInRole(Roles.PoC)) return true;
    else return !this.isSapUser() || (this.isSapUser() && this.identityService.isInRole(Roles.Admin));
  };

  canEditNonSapUserFields = (): boolean => {
    return !this.isSapUser() && (this.identityService.isInRole(Roles.PoC) || this.identityService.isInRole(Roles.Admin));
  };

  canEditOriginOrRegion = (): boolean => {
    if (this.identityService.isInRole(Roles.PoC)) return false;
    else return !this.isSapUser() || this.canEditSapUser();
  };

  mapToCreateRoleInLocation = (roleInLocations: RoleLocationsOrRegionsSelection[]): CreateRoleInLocationDto[] => {
    let createRoleInLocationDtos = [];

    roleInLocations.forEach(roleInLocation => {
      if (!roleInLocation.selected) return;

      if (!roleInLocation.getLocationsOrRegions() || roleInLocation.getLocationsOrRegions().length <= 0) {
        let roleWithoutLocationDto: CreateRoleInLocationDto = {
          locationId: null,
          regionId: null,
          roleName: roleInLocation.role.name
        };

        createRoleInLocationDtos.push(roleWithoutLocationDto);
        return;
      }

      roleInLocation.getLocationsOrRegions().forEach(item => {
        if (!item) return;
        let createRoleInLocationDto: CreateRoleInLocationDto = {
          locationId: 'location' in item ? item.id : null,
          regionId: 'location' in item ? null : item.id,
          roleName: roleInLocation.role.name
        };

        createRoleInLocationDtos.push(createRoleInLocationDto);
      });
    });

    return createRoleInLocationDtos;
  };

  handlePersonnelArea = (personnelArea: PersonnelAreaItemReference) => {
    let location: ItemReference = null;

    if (personnelArea?.locationId) location = { id: personnelArea?.locationId, title: personnelArea?.locationName };

    const changes = { locationId: location?.id || '', personnelAreaId: personnelArea?.id || '', personnelArea: personnelArea?.title || '' };

    this.setState(({ userItem }) => ({ personnelArea, location, userItem: { ...userItem, ...changes } }));
  };

  public render() {
    const { t } = this.props;
    const { userItem, personnelArea, location, languageOptions, trainingLanguageOptions } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouAdmin = IdentityService.isAdmin(currentUserInfo);
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    return (
      <Modal className="user-modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeUserStore.state.isBusy} page style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Edit user')}</Modal.Header>
        <Modal.Content image>
          <Container className="user-modal__wrapper">
            {this.changeUserStore.state.result && !this.changeUserStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeUserStore.state.result.messages.map(o => o.body)}
              />
            )}
            {userItem && (
              <Form>
                <div className="new-user-modal__section-label">{t('Personal Information')}</div>
                <div className="user-modal_checkbox-container">
                  <Form.Field className="user-modal_checkbox-field" inline disabled={!this.canEditActive()}>
                    <label>{t('Active')}</label>
                    <Checkbox
                      className="user-modal__checkbox-toggle"
                      toggle
                      checked={userItem.enabled}
                      onChange={(_, { checked }) => this.handleValue('enabled', checked)}
                    />
                  </Form.Field>
                  <Form.Field className="user-modal_checkbox-field" inline disabled={!this.canEditUpdateFlag()}>
                    <label>{t('Update Flag')}</label>
                    <Checkbox
                      className="user-modal__checkbox-toggle"
                      size="tiny"
                      toggle
                      checked={userItem.updateFlag}
                      onChange={(_, { checked }) => this.handleValue('updateFlag', checked)}
                    />
                  </Form.Field>
                </div>
                <div className="new-user-modal__inputs-container">
                  <div className="new-user-modal__inputs-grid-container">
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={!userItem.firstName}
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('First Name')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('First Name')}
                        value={userItem.firstName}
                        onChange={(_, { value }) => this.handleValue('firstName', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={!userItem.lastName}
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Last Name')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Last Name')}
                        value={userItem.lastName}
                        onChange={(_, { value }) => this.handleValue('lastName', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('User Name')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('User Name')}
                        value={userItem.userName}
                        onChange={(_, { value }) => this.handleValue('userName', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={!userItem.email}
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Email')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Email')}
                        value={userItem.email}
                        onChange={(_, { value }) => this.handleValue('email', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                      error={this.changeUserStore.state.result && !userItem.locationId}
                    >
                      <label>{t('Location')}</label>
                      {areYouAdmin && (
                        <LocationEditor
                          nullable
                          clearable
                          className="planit-user-fields__location-select  planit-users-inputs"
                          onChange={location => this.handleLocation('locationId', location)}
                          value={location}
                        />
                      )}
                      {areYouPoc && (
                        <LocationEditorWithRoleLocations
                          className="planit-user-fields__location-select  planit-users-inputs"
                          value={userItem.locationId}
                          locationsReceived={
                            pocLocations.includes(userItem.locationId) ? pocLocations : pocLocations.concat(userItem.locationId)
                          }
                          onChange={location =>
                            this.handleLocation('locationId', location ? { id: location.id, title: location.location } : null)
                          }
                        />
                      )}
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={!userItem.lineManager}
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Line Manager')}</label>
                      <Input
                        className="planit-users-inputs"
                        error={!userItem.lineManager}
                        placeholder={t('Line Manager')}
                        value={userItem.lineManager}
                        onChange={(_, { value }) => this.handleValue('lineManager', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Hire Date')}</label>
                      <DateInput
                        propagateOnInvalid
                        className="planit-users-inputs planit-users-inputs__date-time"
                        placeholder={t('Hire Date')}
                        value={userItem.hireDate}
                        onChange={(_, value) => this.handleValue('hireDate', value)}
                      />
                    </Form.Field>

                    <Form.Field className="planit-users-fields" inline disabled>
                      <label>{t('Seniority')}</label>
                      <Input disabled className="planit-users-inputs" value={CalculateSeniority(userItem?.hireDate)} />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields new-user-modal__language"
                      inline
                      disabled={!this.canEditPositionCodeOrLanguages()}
                    >
                      <label>{t('Languages')}</label>
                      <div>
                        <Dropdown
                          search
                          inline
                          selection
                          clearable
                          options={languageOptions}
                          multiple={true}
                          value={this.selectedLanguages()}
                          className="planit-user-dropdown  planit-users-inputs"
                          onChange={(_, data) => this.handleLanguageValue(data)}
                          placeholder={t('Select languages')}
                        />
                      </div>
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required={!userItem.enabled}
                      error={!userItem.enabled && !userItem.leavingDate}
                      disabled={!(this.canEditBasicUserInformation() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Leaving Date')}</label>
                      <DateInput
                        propagateOnInvalid
                        className="planit-users-inputs planit-users-inputs__date-time"
                        placeholder={t('Leaving Date')}
                        value={userItem.leavingDate}
                        onChange={(_, value) => this.handleValue('leavingDate', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields new-user-modal__language"
                      inline
                      disabled={!this.canEditPositionCodeOrLanguages()}
                    >
                      <label>{t('Training Languages')}</label>
                      <div>
                        <Dropdown
                          search
                          inline
                          selection
                          clearable
                          options={trainingLanguageOptions}
                          multiple={true}
                          value={this.selectedTrainingLanguages()}
                          className="planit-user-dropdown  planit-users-inputs"
                          onChange={(_, data) => this.handleTrainingLanguageValue(data)}
                          placeholder={t('Select training languages')}
                        />
                      </div>
                    </Form.Field>
                  </div>
                </div>
                <div className="new-user-modal__section-label">SAP Information</div>
                <div className="new-user-modal__inputs-container">
                  <div className="new-user-modal__inputs-grid-container">
                    <Form.Field className="planit-users-fields" inline disabled>
                      <label>{t('Employee SAP ID')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Employee SAP ID')}
                        value={userItem.employeeId}
                        onChange={(_, { value }) => this.handleValue('employeeId', value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      disabled={!(this.canEditSapUser() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Personnel Area')}</label>
                      <PersonnelAreaEditor
                        className="location-editor__wrapper planit-users-inputs"
                        nullable
                        placeholder={t('Select Personnel Area')}
                        value={personnelArea}
                        onChange={this.handlePersonnelArea}
                      />
                    </Form.Field>
                    <Form.Field className="planit-users-fields" inline disabled={!this.canEditNonSapUserFields()}>
                      <label>{t('SF Position')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('SF Position')}
                        value={userItem.sfPosition}
                        onChange={(_, { value }) => this.handleValue('sfPosition', value)}
                      />
                    </Form.Field>

                    <Form.Field className="planit-users-fields" inline disabled={!this.canEditPositionCodeOrLanguages()}>
                      <label>{t('Position Code')}</label>
                      <PositionCodeEditor
                        onChange={positionCode => this.handleValue('positionCodeId', positionCode ? positionCode.id : null)}
                        value={userItem.positionCodeId}
                      />
                    </Form.Field>
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      disabled={!(this.canEditOriginOrRegion() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Origin')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Origin')}
                        value={userItem.origin}
                        onChange={(_, { value }) => this.handleValue('origin', value)}
                      />
                    </Form.Field>
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      disabled={!(this.canEditOriginOrRegion() || this.canEditNonSapUserFields())}
                    >
                      <label>{t('Region')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Region')}
                        value={userItem.region}
                        onChange={(_, { value }) => this.handleValue('region', value)}
                      />
                    </Form.Field>
                  </div>
                </div>
                <div className="new-user-modal__section-label">Roles</div>
                <div className="new-user-modal__roles-wrapper">
                  <RoleEditor
                    userAction={'Edit'}
                    isSubmited={!!this.changeUserStore.state.result}
                    value={userItem.roles}
                    isActiveFilter={true}
                    hideLabel={true}
                    onChange={roleInLocation => this.handleValue('roles', this.mapToCreateRoleInLocation(roleInLocation))}
                    userItem={userItem}
                    onChangeGlobalPoc={isGlobalPoc => this.handleValue('isGlobalPoc', isGlobalPoc)}
                  />
                </div>
                <Modal.Actions>
                  <Button onClick={this.onCancelChangeItem} basic>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={this.onChangeItem} positive>
                    {t('Save')}
                  </Button>
                </Modal.Actions>
              </Form>
            )}
          </Container>
        </Modal.Content>
      </Modal>
    );
  }
}
// Wire up the React component to the Redux store
export default withTranslation()(ChangeUserView);
