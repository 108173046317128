import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import { BaseDto } from '../types';

export interface WorkingDayDto extends BaseDto {
  id: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  year: number;
  locationId: string;
  locationName: string;
}

export interface CreateWorkingDaysDto {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  year: number;
  locationId: string;
}

export interface ChangeWorkingDaysDto {
  id: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  year: number;
  locationId: string;
}

export class CreateWorkingDayValidator extends AbstractValidator<CreateWorkingDaysDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

export class ChangeWorkingDayValidator extends AbstractValidator<ChangeWorkingDaysDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

@repository('@@WORKINGDAYS', 'workingdays.summary')
export class WorkingDaysStore extends DataStore<WorkingDayDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-workingDays';
  retrievePath = 'get-all-workingDays';
  retrieveOnePath = 'get-workingDays';
  updatePath = 'update-workingDays';
  deletePath = 'delete-workingDays';

  protected validate(item: WorkingDayDto) {
    return new ChangeWorkingDayValidator().validate(item as any);
  }

  constructor() {
    super('WORKINGDAY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@WORKINGDAYS', 'workingdays.new')
export class NewWorkingDaysStore extends FormStore<CreateWorkingDaysDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-workingDays';
  retrievePath = 'get-all-workingDays';
  retrieveOnePath = 'get-workingDays';
  updatePath = 'update-workingDays';
  deletePath = 'delete-workingDays';

  protected validate(item: CreateWorkingDaysDto) {
    return new CreateWorkingDayValidator().validate(item);
  }

  constructor() {
    super('NEW_WORKINGDAY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@WORKINGDAYS', 'workingdays.change')
export class ChangeWorkingDaysStore extends FormStore<ChangeWorkingDaysDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-workingDays';
  retrievePath = 'get-all-workingDays';
  retrieveOnePath = 'get-workingDays';
  updatePath = 'update-workingDays';
  deletePath = 'delete-workingDays';

  protected validate(item: ChangeWorkingDaysDto) {
    return new ChangeWorkingDayValidator().validate(item);
  }

  constructor() {
    super('CHANGE_WORKINGDAY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
