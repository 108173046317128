import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeEquipmentTypeDto,
  ChangeEquipmentTypeStore,
  EquipmentTypeDto
} from '../../../../../stores/configuration/machinery/equipment-types-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from '../../../../../widgets/bussiness/mrcluster-editor';

interface ChangeEquipmentTypeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeEquipmentType?: ChangeEquipmentTypeStore;
  currentET: EquipmentTypeDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeEquipmentTypeViewState {
  cluster: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changeEquipmentType', ChangeEquipmentTypeStore])
class ChangeEquipmentTypeView extends React.Component<ChangeEquipmentTypeViewProps, ChangeEquipmentTypeViewState> {
  private get changeEquipmentTypeStore() {
    return this.props.changeEquipmentType;
  }

  constructor(props: ChangeEquipmentTypeViewProps) {
    super(props);

    this.state = {
      cluster: { id: this.props.currentET.clusterId, title: this.props.currentET.clusterName },
      userClickedSaved: false
    };

    this.changeEquipmentTypeStore.state.result = null;

    this.changeEquipmentTypeStore.change({
      id: this.props.currentET.id,
      name: this.props.currentET.name,
      clusterId: this.props.currentET.clusterId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeEquipmentTypeViewProps) {
    if (this.changeEquipmentTypeStore.state.result && this.changeEquipmentTypeStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeEquipmentTypeStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeEquipmentTypeStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeEquipmentTypeStore.change({ ...this.changeEquipmentTypeStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeEquipmentTypeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Equipment Type')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeEquipmentTypeStore.state.result && !this.changeEquipmentTypeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeEquipmentTypeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeEquipmentTypeStore.state.item && (
              <Form>
                <Form.Field>
                  <Form.Input
                    error={!this.changeEquipmentTypeStore.state.item.name && this.state.userClickedSaved}
                    required
                    fluid
                    label={t('Equipment Type')}
                    placeholder={t('Name')}
                    value={this.changeEquipmentTypeStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeEquipmentTypeDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field required error={!this.changeEquipmentTypeStore.state.item.clusterId && this.state.userClickedSaved}>
                  <div className={`required field`}>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      nullable
                      value={this.state.cluster}
                      onChange={c => {
                        this.changeEquipmentTypeStore.state.item.clusterId = c ? c.id : null;
                        this.setState({ userClickedSaved: false });
                      }}
                    />
                  </div>
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeEquipmentTypeView);
