import React from 'react';
import './notifications.less';
import { Tab, Button, Icon, Label, MenuItem } from 'semantic-ui-react';
import i18n from 'i18n';
import { connect } from 'redux-scaffolding-ts';
import { UserNotificationsStore, UnreadNotificationCountStore } from '../../stores/events/event-notifications-store';
import { UserTodoStore, UndoneToDoCountStore } from 'stores/events/event-todo-store';
import { Query } from 'stores/dataStore';
import NotificationTab from './notifications-tab';
import ToDoTab from './to-do-tab';
import { RouteComponentProps } from 'react-router';

interface NotificationsProps extends RouteComponentProps {
  toggleNotification: (any?) => void;
  notificationStore?: UserNotificationsStore;
  unreadNotificationsStore?: UnreadNotificationCountStore;
  UserTodoStore?: UserTodoStore;
  undoneToDoCountStore?: UndoneToDoCountStore;
  containerToTakeHeight?: any;
}

interface NotificationsState {
  activeTab: 'Notifications' | 'To-do list';
  query: Query;
  activeFilters: { [notificationType: string]: boolean };
  offsetShow: number;
  panes?: any;
}

@connect(
  ['notificationStore', UserNotificationsStore],
  ['unreadNotificationsStore', UnreadNotificationCountStore],
  ['UserTodoStore', UserTodoStore],
  ['undoneToDoCountStore', UndoneToDoCountStore]
)
class Notifications extends React.Component<NotificationsProps, NotificationsState> {
  private elementsPerPage = 7;

  initActiveFilters(): { [notificationType: string]: boolean } {
    let activeFilters = {};
    let filters = ['SystemInfo', 'EventChanges', 'RequestChanges', 'EventChatMessage', 'RequestChatMessage'];
    filters.forEach(filter => (activeFilters[filter] = false));
    return activeFilters;
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Notifications',
      activeFilters: this.initActiveFilters(),
      query: {
        searchQuery: '',
        orderBy: [],
        skip: 0,
        take: this.elementsPerPage
      },
      offsetShow: 0
    };
  }

  private generatePanes = () => [
    {
      menuItem: (
        <MenuItem>
          {i18n.t('Notifications')}
          {this.props.unreadNotificationsStore.state.unread ? (
            <Label className="notifications__number" color="red" circular floating size="mini">
              {this.props.unreadNotificationsStore.state.unread}
            </Label>
          ) : null}
        </MenuItem>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <NotificationTab
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
            toggleNotification={this.props.toggleNotification}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <MenuItem>
          {i18n.t('to-do list')}
          {this.props.undoneToDoCountStore.state.unread ? (
            <Label className="notifications__number" color="red" circular floating size="mini">
              {this.props.undoneToDoCountStore.state.unread}
            </Label>
          ) : null}
        </MenuItem>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <React.Fragment>
            <ToDoTab
              history={this.props.history}
              match={this.props.match}
              location={this.props.location}
              toggleNotification={this.props.toggleNotification}
            />
          </React.Fragment>
        </Tab.Pane>
      )
    }
  ];

  render() {
    return (
      <React.Fragment>
        <div className="notifications-max-container">
          <div className="notifications__pop-up">
            <div className="notifications__header">
              <div className="notif__header__remove-box">
                <Button className="notification__remove-btn" onClick={this.props.toggleNotification}>
                  <Icon name="remove" />
                </Button>
              </div>

              <Tab
                className="main-notification-tab"
                menuPosition="left"
                menu={{ secondary: true, pointing: true }}
                panes={this.generatePanes()}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Notifications;
