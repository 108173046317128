import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { ProfessionDto, ChangeProfessionDto, ChangeProfessionStore } from 'stores/configuration/profiles/profession-roles-store';
import { nameof } from 'utils/object';

interface ChangeProfessionViewProps extends WithTranslation {
  onClose: () => void;
  changeProfession?: ChangeProfessionStore;
  currentDto: ProfessionDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeProfessionViewState {
  editorValue: any;
}

@connect(['changeProfession', ChangeProfessionStore])
class ChangeProfessionView extends React.Component<ChangeProfessionViewProps, ChangeProfessionViewState> {
  private get changeProfessionStore() {
    return this.props.changeProfession;
  }

  constructor(props: ChangeProfessionViewProps) {
    super(props);

    this.changeProfessionStore.state.result = null;

    this.changeProfessionStore.change({
      id: this.props.currentDto.id,
      profession: this.props.currentDto.profession
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeProfessionViewProps) {
    if (this.changeProfessionStore.state.result && this.changeProfessionStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onChangeItem() {
    this.changeProfessionStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeProfessionStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeProfessionStore.change({ ...this.changeProfessionStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeProfessionStore.state.isBusy} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Role')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeProfessionStore.state.result && !this.changeProfessionStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeProfessionStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeProfessionStore.state.item && (
              <Form loading={this.changeProfessionStore.state.isBusy}>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeProfessionStore.state.item.profession}
                    label={t('Role')}
                    placeholder={t('Name')}
                    value={this.changeProfessionStore.state.item.profession}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeProfessionDto>('profession'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeProfessionView);
