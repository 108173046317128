import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { EventWizardData } from 'stores/events/creation-wizard-store';
import EventInstructors, { EventInstructorRow } from '../../event-instructors';
import './instructor-step.less';

interface InstructorsStepProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, any][]) => void;
}

class InstructorsStep extends Component<InstructorsStepProps> {
  private handleValue = (value: EventInstructorRow[]) => this.props.onValueChanged(['instructors', value]);

  render() {
    return (
      <Form className="wizard__form__margin">
        <EventInstructors
          instructorRows={this.props.wizardData?.instructors}
          defaultDateFrom={(this.props.wizardData?.dateFrom || '').toString()}
          defaultDateTo={(this.props.wizardData?.dateTo || '').toString()}
          onEditMode
          showWorkingDays={false}
          eventTypeId={this.props.wizardData?.eventType?.id}
          eventType={this.props.wizardData?.eventType}
          machineModelIds={(this.props.wizardData?.requestMachines || []).filter(x => x.machineModel != null).map(x => x.machineModel.id)}
          functionalSubAreaId={this.props.wizardData?.nMRFunctionalSubAreaId}
          roleId={this.props.wizardData?.profession?.id}
          trainingLevelId={this.props.wizardData?.trainingLevel?.id}
          onChange={this.handleValue}
          isMachineRelated={this.props.wizardData?.isMachineRelated}
          machines={this.props.wizardData?.requestMachines || []}
        />
      </Form>
    );
  }
}

export default withTranslation()(InstructorsStep);
