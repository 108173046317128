import { EventDto } from 'stores/events/events-store';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

export interface SchedulerVacationsEventProps extends WithTranslation {
  event: EventDto;
  pausePeriods?: any;
}

export class SchedulerVacationsEvent extends Component<SchedulerVacationsEventProps> {
  render() {
    const { t } = this.props;

    return (
      <div className="event-vacation-template__wrapper">
        <FontAwesomeIcon icon={faCalendar} size="2x" />
        <div className="event-vacation-template__info-title">
          <b>{t('Vacations')}</b>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SchedulerVacationsEvent);
