import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RequestFormStore, RequestFormViewModel } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';

import FormRow from 'site/pages/shared/events-and-requests/form-row';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import CustomizationDataEditor from 'widgets/bussiness/customization-data-editor';
import DeliveryMethodEditor from 'widgets/bussiness/delivery-method-editor';
import LanguageEditor from 'widgets/bussiness/language-editor';
import { faChalkboardTeacher, faGlobe, faPencilRuler, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

export interface EventDetailsTabPaneProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
}

@connect(['requestFormStore', RequestFormStore])
class EventDetailsTabPane extends Component<EventDetailsTabPaneProps> {
  private handleOnChange = (property: string, value: any) => {
    let change = {};
    change[property] = value;
    this.props.requestFormStore.change(change);
  };

  render() {
    const { t, readOnly } = this.props;
    const { trainingLevel, customization, deliveryMethod, language } = this.props.requestFormStore.state.item;

    return (
      <div className="form__modal__header-content">
        <div className="form__header__data">
          <FormRow className="form__event-details-tab__element" label={t('Training Level')} icon={faGraduationCap}>
            <TrainingLevelEditor
              className="form__header__dropdown-style"
              readOnly={readOnly}
              value={trainingLevel}
              onChange={value => this.handleOnChange(nameof<RequestFormViewModel>('trainingLevel'), value)}
            />
          </FormRow>

          {(customization || !readOnly) && (
            <FormRow className="form__event-details-tab__element" label={t('Customization')} icon={faPencilRuler}>
              <CustomizationDataEditor
                nullable
                clearable
                className="form__header__dropdown-style"
                readOnly={readOnly}
                value={customization}
                onChange={value => this.handleOnChange(nameof<RequestFormViewModel>('customization'), value)}
              />
            </FormRow>
          )}

          <FormRow className="form__event-details-tab__element" label={t('Delivery Method')} icon={faChalkboardTeacher}>
            <DeliveryMethodEditor
              className="form__header__dropdown-style"
              readOnly={readOnly}
              value={deliveryMethod}
              onChange={value => this.handleOnChange(nameof<RequestFormViewModel>('deliveryMethod'), value)}
            />
          </FormRow>

          {(language || !readOnly) && (
            <FormRow className="form__event-details-tab__element" label={t('Language')} icon={faGlobe}>
              <LanguageEditor
                nullable
                clearable
                className="form__header__dropdown-style"
                readOnly={readOnly}
                value={language}
                onChange={value => this.handleOnChange(nameof<RequestFormViewModel>('language'), value)}
              />
            </FormRow>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EventDetailsTabPane);
