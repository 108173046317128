import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Form, Loader, Dimmer } from 'semantic-ui-react';
import { Component } from 'react';
import React from 'react';
import * as autobind from 'autobind';
import { connect } from 'redux-scaffolding-ts';
import { EventValidationUsers } from 'stores/events/events-store';
import { Query, QueryResult } from 'stores/dataStore';
import { UserDto, UserStore } from 'stores/users/users-store';
import './validation-tab-pane.less';
import { EventFormStore, EventInstructorViewModel } from 'stores/events/event-form-store';
import { GetRoleName } from 'utils/userinfo-functions';

interface ValidationTabPaneProps extends WithTranslation {
  usersStore?: UserStore;
  eventFormStore?: EventFormStore;
}

export interface ValidationTabPaneState {
  query: Query;
  currentState: string;
  usersPlannerRole: UserDto[];
  usersPlannerMTCRole: UserDto[];
  usersPocRole: UserDto[];
  usersFactoryLeadRole: UserDto[];
  listUsersRolerValidationStatus: EventValidationUsers[];
  loading: boolean;
}

@connect(['usersStore', UserStore], ['eventFormStore', EventFormStore])
class ValidationTabPane extends Component<ValidationTabPaneProps, ValidationTabPaneState> {
  state: ValidationTabPaneState = {
    query: { searchQuery: '', orderBy: [], skip: 0, take: 1000, select: 'id,name,surname,roles,locationId' },
    currentState: '',
    usersPlannerRole: [],
    usersPlannerMTCRole: [],
    usersPocRole: [],
    usersFactoryLeadRole: [],
    listUsersRolerValidationStatus: [],
    loading: false
  };

  private getRolesRequiredToValidate(): string[] {
    const event = { ...this.props.eventFormStore.state.item };
    const eventStatus = event.status.toString();

    let rolesRequiredToValidateStatus = null;

    switch (eventStatus) {
      case 'Planned':
        rolesRequiredToValidateStatus = event.eventType.planned.rolesRequiredToValidateStatus;
        break;
      case 'Completed':
        rolesRequiredToValidateStatus = event.eventType.completed.rolesRequiredToValidateStatus;
        break;
      case 'InProgress':
        rolesRequiredToValidateStatus = event.eventType.inProgress.rolesRequiredToValidateStatus;
        break;
      case 'Draft':
        rolesRequiredToValidateStatus = event.eventType.draft.rolesRequiredToValidateStatus;
        break;
      case 'Closed':
      default:
        rolesRequiredToValidateStatus = null;
    }

    return rolesRequiredToValidateStatus;
  }

  private data(rolesRequiredToValidateStatus: string[]) {
    if (rolesRequiredToValidateStatus === null) {
      this.setState({ loading: false });
      return null;
    }

    this.dataRolesValidatedCases(rolesRequiredToValidateStatus);
  }

  private dataRolesValidatedCases(rolesRequiredToValidateStatus: string[]) {
    const statusValidation = this.props.eventFormStore.state.item.statusValidation;
    const event = this.props.eventFormStore.state.item;

    let rolesThatValidatedStatus: string[] = [];
    let eventValitadionUsersDto: EventValidationUsers[] = [];
    let validated: boolean;
    rolesRequiredToValidateStatus.forEach(role => {
      if (role === 'Instructor') {
        rolesThatValidatedStatus = rolesThatValidatedStatus.concat(statusValidation.validatedByInstructors);
        const eventInstructors = this.removeInstructorDuplicates(event.instructors);

        eventInstructors.forEach(i => {
          if (rolesRequiredToValidateStatus && !rolesThatValidatedStatus.includes(i.user.id)) {
            validated = false;
          } else if (rolesRequiredToValidateStatus && rolesThatValidatedStatus.includes(i.user.id)) {
            validated = true;
            rolesThatValidatedStatus = rolesThatValidatedStatus.filter(rol => rol !== i.user.id);
          }
          eventValitadionUsersDto.push({
            id: i.user.id,
            firstName: i.user.firstName,
            lastName: i.user.lastName,
            role: 'Instructor',
            hasValidated: validated
          });
        });
      }
      if (role === 'Planner') {
        let validatedbyplanners = statusValidation.validatedByPlanners;

        this.state.usersPlannerRole.forEach(p => {
          if (!validatedbyplanners.includes(p.id)) {
            validated = false;
          } else if (validatedbyplanners.includes(p.id)) {
            validated = true;
            validatedbyplanners = validatedbyplanners.filter(x => x !== p.id);
          }
          if (validated || p.enabled) {
            eventValitadionUsersDto.push({
              id: p.id,
              firstName: p.firstName,
              lastName: p.lastName,
              role: 'Planner',
              hasValidated: validated
            });
          }
        });
        rolesThatValidatedStatus = rolesThatValidatedStatus.concat(validatedbyplanners);
      }

      if (role === 'PlannerMTC') {
        let validatedbyplannersMTC = statusValidation.validatedByPlannersMTC;

        this.state.usersPlannerMTCRole.forEach(p => {
          if (!validatedbyplannersMTC.includes(p.id)) {
            validated = false;
          } else if (validatedbyplannersMTC.includes(p.id)) {
            validated = true;
            validatedbyplannersMTC = validatedbyplannersMTC.filter(x => x !== p.id);
          }
          if (validated || p.enabled) {
            eventValitadionUsersDto.push({
              id: p.id,
              firstName: p.firstName,
              lastName: p.lastName,
              role: 'Planner MTC',
              hasValidated: validated
            });
          }
        });
        rolesThatValidatedStatus = rolesThatValidatedStatus.concat(validatedbyplannersMTC);
      }

      if (role === 'PoC') {
        let validatedbyPocLoc = statusValidation.validatedByPoCLocations;
        validatedbyPocLoc = validatedbyPocLoc.concat(statusValidation.validatedByPoC);

        let pocs = [];

        let pocsByEventLocation: UserDto[] = this.state.usersPocRole.filter(u =>
          u.roles.any(x => x.role.name === 'PoC' && event.eventDetails.location && x.location?.id === event.eventDetails.location?.id)
        );

        pocs = [...pocsByEventLocation];

        let locationsToValidate = event.requests.map(l => l.requestLocation.id);

        if (event.supportDetails)
          locationsToValidate = !event.supportDetails.isNewSupportPositionModel
            ? locationsToValidate
                .concat((event.supportDetails.supportPositions || []).flatMap(x => x.eventPositions).map(x => x.userLocationId))
                .distinct(x => x)
                .toArray()
            : locationsToValidate
                .concat((event.supportDetails.newSupportPositions || []).flatMap(x => x.eventNewModelPositions).map(x => x.userLocationId))
                .distinct(x => x)
                .toArray();

        locationsToValidate.forEach(reqLoc => {
          let pocsByRequestingLocation = this.state.usersPocRole.filter(p =>
            p.roles.any(f => f.role.name === 'PoC' && f.location?.id === reqLoc)
          );

          pocsByRequestingLocation.forEach(poc => {
            if (poc) {
              pocs.push(poc);
              if (!validatedbyPocLoc.includes(poc.id)) {
                validated = false;
              } else if (validatedbyPocLoc.includes(poc.id)) {
                validated = true;
              }
              if (validated || poc.enabled) {
                eventValitadionUsersDto.push({
                  id: poc.id,
                  firstName: poc.firstName,
                  lastName: poc.lastName,
                  role: this.getRoleLocation(poc, reqLoc),
                  hasValidated: validated
                });
              }
            }
          });
        });

        pocsByEventLocation.forEach(p => {
          if (!validatedbyPocLoc.includes(p.id)) {
            validated = false;
          } else if (validatedbyPocLoc.includes(p.id)) {
            validated = true;
          }
          if (
            (validated || p.enabled) &&
            !eventValitadionUsersDto.any(
              user => user.id === p.id && event.eventDetails.location && locationsToValidate.includes(event.eventDetails.location.id)
            )
          ) {
            eventValitadionUsersDto.push({
              id: p.id,
              firstName: p.firstName,
              lastName: p.lastName,
              role: this.getRoleLocation(p, event.eventDetails.location && event.eventDetails.location.id),
              hasValidated: validated
            });
          }
        });
        rolesThatValidatedStatus = rolesThatValidatedStatus.concat(validatedbyPocLoc);
      }
      if (role === 'FactoryLead') {
        let validatedbyFactoryLeadLoc = statusValidation.validatedByFactoryLeadLocations || [];
        validatedbyFactoryLeadLoc = validatedbyFactoryLeadLoc.concat(statusValidation.validatedByFactoryLead || []);

        let factoryleads = [];

        let factoryLeadsByEventLocation: UserDto[] = this.state.usersFactoryLeadRole.filter(u =>
          u.roles.any(
            x => x.role.name === 'Factory Lead' && event.eventDetails.location && x.location.id === event.eventDetails.location.id
          )
        );

        factoryleads = [...factoryLeadsByEventLocation];

        let locationsToValidate = event.requests.map(l => l.requestLocation.id);

        if (event.supportDetails)
          locationsToValidate = !event.supportDetails.isNewSupportPositionModel
            ? locationsToValidate
                .concat((event.supportDetails.supportPositions || []).flatMap(x => x.eventPositions).map(x => x.userLocationId))
                .distinct(x => x)
                .toArray()
            : locationsToValidate
                .concat((event.supportDetails.newSupportPositions || []).flatMap(x => x.eventNewModelPositions).map(x => x.userLocationId))
                .distinct(x => x)
                .toArray();

        locationsToValidate.forEach(reqLoc => {
          let factroyLeadsByRequestingLocation = this.state.usersFactoryLeadRole.filter(p =>
            p.roles.any(f => f.role.name === 'Factory Lead' && f.location.id === reqLoc)
          );

          factroyLeadsByRequestingLocation.forEach(factoryLead => {
            if (factoryLead) {
              factoryleads.push(factoryLead);
              if (!validatedbyFactoryLeadLoc.includes(factoryLead.id)) {
                validated = false;
              } else if (validatedbyFactoryLeadLoc.includes(factoryLead.id)) {
                validated = true;
              }
              if (validated || factoryLead.enabled) {
                eventValitadionUsersDto.push({
                  id: factoryLead.id,
                  firstName: factoryLead.firstName,
                  lastName: factoryLead.lastName,
                  role: this.getFactoryLeadRoleLocation(factoryLead, reqLoc),
                  hasValidated: validated
                });
              }
            }
          });
        });

        factoryLeadsByEventLocation.forEach(factoryLead => {
          if (!validatedbyFactoryLeadLoc.includes(factoryLead.id)) {
            validated = false;
          } else if (validatedbyFactoryLeadLoc.includes(factoryLead.id)) {
            validated = true;
          }
          if (
            (validated || factoryLead.enabled) &&
            !eventValitadionUsersDto.any(
              user =>
                user.id === factoryLead.id && event.eventDetails.location && locationsToValidate.includes(event.eventDetails.location.id)
            )
          ) {
            eventValitadionUsersDto.push({
              id: factoryLead.id,
              firstName: factoryLead.firstName,
              lastName: factoryLead.lastName,
              role: this.getFactoryLeadRoleLocation(factoryLead, event.eventDetails.location && event.eventDetails.location.id),
              hasValidated: validated
            });
          }
        });
        rolesThatValidatedStatus = rolesThatValidatedStatus.concat(validatedbyFactoryLeadLoc);
      }
    });

    this.setState({ listUsersRolerValidationStatus: eventValitadionUsersDto.orderBy(role => role.role).toArray(), loading: false });
  }

  private removeInstructorDuplicates(instructors: EventInstructorViewModel[]): EventInstructorViewModel[] {
    return instructors.filter(
      (elem, index, self) =>
        self.findIndex(t => {
          return t.user.id === elem.user.id;
        }) === index
    );
  }

  private getRoleLocation(user: UserDto, locationId: string): string {
    const roleLocation = user.roles.find(r => r.role.name === 'PoC' && r.location?.id === locationId);
    return roleLocation?.role?.name + ' - ' + roleLocation?.location?.location;
  }

  private getFactoryLeadRoleLocation(user: UserDto, locationId: string): string {
    const roleLocation = user.roles.find(r => r.role.name === 'Factory Lead' && r.location.id === locationId);
    return roleLocation.role.name + ' - ' + roleLocation.location.location;
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.setState({ loading: true });

    let rolesRequiredToValidateStatus = this.getRolesRequiredToValidate();

    if (rolesRequiredToValidateStatus === null) {
      this.setState({ loading: false });
      return;
    }

    let planerProm = this.props.usersStore.getAllUsersWithRoleValidationAsync(this.state.query, 'Planner', rolesRequiredToValidateStatus);
    let planerMtcProm = this.props.usersStore.getAllUsersWithRoleValidationAsync(
      this.state.query,
      'Planner MTC',
      rolesRequiredToValidateStatus
    );
    let PoCProm = this.props.usersStore.getAllUsersWithRoleValidationAsync(this.state.query, 'PoC', rolesRequiredToValidateStatus);
    let factoryLeadProm = this.props.usersStore.getAllUsersWithRoleValidationAsync(
      this.state.query,
      'Factory Lead',
      rolesRequiredToValidateStatus
    );
    let promises = [planerProm, planerMtcProm, PoCProm, factoryLeadProm];

    Promise.allSettled(promises).then(results => {
      if (results.every(x => x.status === 'fulfilled' && x.value)) {
        let result = results.map(x => (x as PromiseFulfilledResult<QueryResult<UserDto>>).value);

        this.setState(
          {
            usersPlannerRole: result[0].items,
            usersPlannerMTCRole: result[1].items,
            usersPocRole: result[2].items,
            usersFactoryLeadRole: result[3].items
          },
          () => this.data(rolesRequiredToValidateStatus)
        );
      }
    });
  }

  public render() {
    return (
      <>
        {this.state.loading && (
          <div style={{ height: 200 }}>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}
        <Form className="events-form__validation-tab-container">
          {this.state.listUsersRolerValidationStatus.map((u, indx) => {
            return (
              <Form.Field key={indx}>
                <div className="events-form__validation-tab__row">
                  <label>{`${GetRoleName(u.role)} ${u.lastName}, ${u.firstName}`}</label>

                  <Button
                    className={u.hasValidated ? 'validation-tab__validated-btn' : 'validation-tab__pending-btn'}
                    disabled={true}
                    icon={false}
                    content={u.hasValidated ? 'Validated' : 'Pending'}
                  />
                </div>
              </Form.Field>
            );
          })}
        </Form>
      </>
    );
  }
}
export default withTranslation()(ValidationTabPane);
