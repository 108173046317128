import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateTimeService } from 'services/datetime-service';
import { RequestDto, RequestMachinesDto } from 'stores/requests/requests-store';

interface SchedulerEventTooltipProps extends WithTranslation {
  request: RequestDto;
}
const SchedulerEventTooltip: React.FC<SchedulerEventTooltipProps> = ({ request, t }) => {
  if (!request) return;
  const { eventType, friendlyId, status, title, priorityName, nmrFunctionalAreaName, nmrTrainingNameName, ...rest } = request;
  const { startDate, endDate, desiredEventDuration, requestLocation, roleName, customizationDataId, ...rest2 } = rest;
  const { deliveryMethodName, studentsNumber, trainingLevelName, isMachineRelated, requestMachines: machines } = rest2;
  const mLength = (machines || []).length;

  const requestMachinesMapper = ({ machineModelName, machineModelId }: RequestMachinesDto, i: number) =>
    machineModelId && machineModelName && i <= 9 ? (
      <>
        <div key={machineModelId + machineModelName + i} className="tooltip__section-item">
          <p className="tooltip__instructor-customer">{machineModelName}</p>
        </div>
        {i + 1 !== mLength && mLength !== 1 && machines[i + 1]?.machineModelName && i < 9 && <span>|</span>}
      </>
    ) : i === 10 ? (
      '...'
    ) : null;

  return (
    <div className="scheduler-tooltip__wrapper">
      <div className="tooltip__header">
        <p className="tooltip__id">{friendlyId}</p>
        <div className="tooltip__header-event-type">
          <style>
            {`.scheduler-tooltip__wrapper .b-fa-circle:before {
               color: ${eventType.eventsColor};
              }
            `}
          </style>
          <i className="b-fa b-fa-circle" />
          <p className="first-row__content">{eventType.name}</p>
        </div>
        {priorityName && (
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-star" />
            <p className="tooltip__event-code">{priorityName}</p>
          </div>
        )}
      </div>
      <div className="tooltip__section">
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-file" />
          <p className="tooltip__event-code">{title}</p>
        </div>
      </div>
      <div className="tooltip__section">
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-calendar-alt" />
          <p className="tooltip__date">
            {DateTimeService.toDateInputString(startDate)} - {DateTimeService.toDateInputString(endDate)}
          </p>
        </div>
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-clock" />
          <p className="tooltip__event-duration">{desiredEventDuration} w/d</p>
        </div>
      </div>
      {(requestLocation?.location || deliveryMethodName) && (
        <div className="tooltip__section">
          {requestLocation?.location && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-map-marked-alt" />
              <p className="tooltip__event-location-origin">
                {requestLocation?.location} ({requestLocation?.countryName})
              </p>
            </div>
          )}
          {deliveryMethodName && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-chalkboard-teacher" />
              <p className="tooltip__delivery-method">{deliveryMethodName}</p>
            </div>
          )}
          {studentsNumber > 0 && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-user-friends" />
              <p className="tooltip__delivery-method">{studentsNumber}</p>
            </div>
          )}
        </div>
      )}

      <div className="tooltip__section">
        {roleName && (
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-id-card-alt" />
            <p className="tooltip__expertise">{roleName}</p>
          </div>
        )}

        {trainingLevelName && (
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-graduation-cap" />
            <p className="tooltip__participants">{trainingLevelName}</p>
          </div>
        )}
        {customizationDataId && (
          <div className="tooltip__section-item">
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-pencil-ruler" />
              <p className="tooltip__instructor-customer">{t('Customization')}</p>
            </div>
          </div>
        )}
      </div>
      {isMachineRelated && mLength > 0 && <div className="tooltip__section machines">{machines.map(requestMachinesMapper)}</div>}
      {nmrFunctionalAreaName && (
        <div className="tooltip__section">
          <div className="tooltip__section-item">
            <p className="tooltip__instructor-customer">{nmrFunctionalAreaName}</p>
          </div>
        </div>
      )}
      {nmrTrainingNameName && (
        <div className="tooltip__section">
          <div className="tooltip__section-item">
            <p className="tooltip__instructor-customer">{nmrTrainingNameName}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(React.memo(SchedulerEventTooltip));
