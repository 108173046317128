import * as React from 'react';
import InvisibleFilters, { InvisibleFiltersValue } from './filters/invisible-filters';
import AlwaysVisibleFilters, { AlwaysVisibleFiltersValue } from './filters/always-visible-filters';
import SchedulerButtonComponent from './button-component/scheduler-buttons-component';
import ViewPreset from './viewpreset-component/ViewPreset';
import RowHeight from './rowheight-component/RowHeight';
import { SchedulerEventFilterService } from './filters/scheduler-event-filter-service';
import './scheduler-header.component.less';
import { PublicHolidayConf } from '../scheduler-poc-config/config-tabs/public-holidays-tab';
import { CreateWorkingDaysDto } from 'stores/working-days/working-days-store';
import { container } from 'inversify.config';
import { Button } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface FilterValues {
  alwaysVisibleFilterValues: AlwaysVisibleFiltersValue;
  invisibleFilterValues: InvisibleFiltersValue;
}

interface SchedulerHeaderProps extends WithTranslation {
  filterValues: FilterValues;
  filtersDisabled?: boolean;
  showInstructorFilters?: boolean;
  showInstructorFiltersToPlanners?: boolean;
  toggleHeight?: () => void;
  onFilterMeHandler?: () => boolean;
  onFilterValueChange?: (filterValues: FilterValues, triggeredByInstructorRowFilter?: boolean) => void;
  onViewPresetChange?: (value: any) => void;
  onCreatedEvent?: (isSucess: boolean) => void;
  onCreatedRequest?: (isSucess: boolean) => void;
  onRowHeightChange?: (value: any) => void;
  onReloadWorkingDaysPublicHolidays: (data?: PublicHolidayConf | CreateWorkingDaysDto) => void;
}

const SchedulerHeaderComponent: React.FC<SchedulerHeaderProps> = ({ onFilterValueChange, t, filterValues, ...props }) => {
  const schedulerEventFilterService: SchedulerEventFilterService = container.get(SchedulerEventFilterService);

  const [showAdditionalFilters, setShowAdditionalFilters] = React.useState<boolean>(() => true);

  const handleFilterValueChange = (propertyName: keyof FilterValues, value: any, triggeredByInstructorRowFilter?: boolean) =>
    onFilterValueChange({ ...filterValues, [propertyName]: value }, triggeredByInstructorRowFilter);

  const handleRowHeightChange = (value: any) => props.onRowHeightChange(value);

  const handleViewPresetChange = (value: any) => props.onViewPresetChange(value);

  const handleOnHideFilters = () => {
    handleFilterValueChange('invisibleFilterValues', schedulerEventFilterService.getDefaultAdditionalFilters());
    setShowAdditionalFilters(prev => !prev);
  };

  const handleOnFilterByUpdateFlag = (value: boolean) => {
    const alwaysVisibleFiltersValue = { ...filterValues.alwaysVisibleFilterValues };
    alwaysVisibleFiltersValue.filterByActiveUpdateFlag = value;
    handleFilterValueChange('alwaysVisibleFilterValues', alwaysVisibleFiltersValue);
  };

  const reloadWorkingDaysPublicHolidaysHandler = (data: PublicHolidayConf | CreateWorkingDaysDto = null) =>
    props.onReloadWorkingDaysPublicHolidays(data);

  const handleAlertFilter = (value: boolean) => {
    const alwaysVisibleFiltersValue = { ...filterValues.alwaysVisibleFilterValues };
    alwaysVisibleFiltersValue.handleAlertFilter = value;

    handleFilterValueChange('alwaysVisibleFilterValues', alwaysVisibleFiltersValue);
  };

  const handleFilterInvisibleFilterChange = (value: InvisibleFiltersValue) => handleFilterValueChange('invisibleFilterValues', value);

  const handleFilterVisibleFilterChange = (value: AlwaysVisibleFiltersValue, triggeredByInstructorRowFilter?: boolean) =>
    handleFilterValueChange('alwaysVisibleFilterValues', value, triggeredByInstructorRowFilter);

  const handleRoleInstructorChange = (
    roleInstructor: 'Internal Trainer' | 'Power Instructor' | 'Instructor' | '',
    triggeredByInstructorRowFilter: boolean
  ) =>
    handleFilterVisibleFilterChange(
      {
        ...filterValues.alwaysVisibleFilterValues,
        roleInstructor: filterValues.alwaysVisibleFilterValues.roleInstructor === roleInstructor ? '' : roleInstructor,
        pillarManagedBy: ''
      },
      triggeredByInstructorRowFilter
    );

  const handlePillarManagedByChange = (pillarManagedBy: 'PlannerMTC' | 'PlannerTFT' | '', triggeredByInstructorRowFilter: boolean) =>
    handleFilterVisibleFilterChange(
      {
        ...filterValues.alwaysVisibleFilterValues,
        pillarManagedBy: filterValues.alwaysVisibleFilterValues.pillarManagedBy === pillarManagedBy ? '' : pillarManagedBy,
        roleInstructor: ''
      },
      triggeredByInstructorRowFilter
    );

  return (
    <div className="sch-instructors__header">
      <div className="sch-instructors__header-above">
        <InvisibleFilters
          disabled={props.filtersDisabled}
          value={filterValues.invisibleFilterValues}
          showInstructorsFilters={props.showInstructorFilters}
          visible={showAdditionalFilters}
          onChange={handleFilterInvisibleFilterChange}
        />
      </div>
      <div className="sch-instructors__header-below">
        <div className="sch-instructors__header-left">
          <div className="row">
            <ViewPreset onViewPresetChange={handleViewPresetChange} />
            <RowHeight onChange={handleRowHeightChange} />
          </div>
          {props.showInstructorFilters && (
            <div className="row top-margin">
              {props.showInstructorFiltersToPlanners && (
                <Button
                  onClick={() => handlePillarManagedByChange('PlannerTFT', true)}
                  type="button"
                  className={`sch-button${filterValues.alwaysVisibleFilterValues.pillarManagedBy === 'PlannerTFT' ? ' active' : ''}`}
                  content={t('TFT Instructors')}
                />
              )}

              {props.showInstructorFiltersToPlanners && (
                <Button
                  onClick={() => handlePillarManagedByChange('PlannerMTC', true)}
                  type="button"
                  className={`sch-button${filterValues.alwaysVisibleFilterValues.pillarManagedBy === 'PlannerMTC' ? ' active' : ''}`}
                  content={t('MTC Instructors')}
                />
              )}

              {!props.showInstructorFiltersToPlanners && (
                <Button
                  onClick={() => handleRoleInstructorChange('Instructor', true)}
                  type="button"
                  className={`sch-button${filterValues.alwaysVisibleFilterValues.roleInstructor === 'Instructor' ? ' active' : ''}`}
                  content={t('Instructor')}
                />
              )}

              <Button
                onClick={() => handleRoleInstructorChange('Internal Trainer', true)}
                type="button"
                className={`sch-button${filterValues.alwaysVisibleFilterValues.roleInstructor === 'Internal Trainer' ? ' active' : ''}`}
                content={t('Internal Trainer')}
              />
            </div>
          )}
        </div>
        <div className="sch-instructors__header-right">
          <div className="sch-instructors__header-right-filters">
            <AlwaysVisibleFilters
              disabled={props.filtersDisabled}
              showInstructorsFilters={props.showInstructorFilters}
              value={filterValues.alwaysVisibleFilterValues}
              onChange={handleFilterVisibleFilterChange}
            />
          </div>
          <SchedulerButtonComponent
            handleOnHideFilters={handleOnHideFilters}
            handleOnFilterByUpdateFlag={handleOnFilterByUpdateFlag}
            handleOnCreatedEvent={props.onCreatedEvent}
            handleOnCreatedRequest={props.onCreatedRequest}
            handleAlertFilter={handleAlertFilter}
            onReloadWDPH={reloadWorkingDaysPublicHolidaysHandler}
          />
        </div>
      </div>
    </div>
  );
};

SchedulerHeaderComponent.defaultProps = {
  toggleHeight: () => {},
  onFilterMeHandler: () => false,
  onFilterValueChange: () => {},
  onViewPresetChange: () => {},
  onCreatedEvent: () => {},
  onCreatedRequest: () => {},
  onRowHeightChange: () => {},
  filtersDisabled: false
};

export default withTranslation()(React.memo(SchedulerHeaderComponent));
