import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface PersonnelAreaDto extends BaseDto {
  id: string;
  name: string;
  locationId: string;
  locationName: string;
}

export interface CreatePersonnelAreaDto {
  name: string;
  locationId: string;
}

export interface ChangePersonnelAreaDto {
  id: string;
  name: string;
  locationId: string;
}

export class CreatePersonnelAreaValidator extends AbstractValidator<CreatePersonnelAreaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Personnel Area name is required'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));
  }
}

export class ChangePersonnelAreaValidator extends AbstractValidator<ChangePersonnelAreaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Personnel Area name is required'));
  }
}

@repository('@@PERSONNELAREAS', 'personnelAreas.summary')
export class PersonnelAreasStore extends DataStore<PersonnelAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-personnelArea';
  retrievePath = 'get-personnelAreas';
  updatePath = 'update-personnelArea';
  deletePath = 'delete-personnelArea';

  protected validate(item: PersonnelAreaDto) {
    return new ChangePersonnelAreaValidator().validate(item);
  }

  constructor() {
    super('PERSONNELAREA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PERSONNELAREAS', 'personnelAreas.new')
export class NewPersonnelAreaStore extends FormStore<CreatePersonnelAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-personnelArea';
  retrievePath = 'get-personnelAreas';
  updatePath = 'update-personnelArea';

  protected validate(item: CreatePersonnelAreaDto) {
    return new CreatePersonnelAreaValidator().validate(item);
  }

  constructor() {
    super('NEW_PERSONNELAREA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PERSONNELAREAS', 'personnelAreas.change')
export class ChangePersonnelAreaStore extends FormStore<ChangePersonnelAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-personnelArea';
  retrievePath = 'get-personnelAreas';
  updatePath = 'update-personnelArea';

  protected validate(item: ChangePersonnelAreaDto) {
    return new ChangePersonnelAreaValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PERSONNELAREA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
