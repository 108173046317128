import React, { FC, memo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon, Button } from 'semantic-ui-react';
import { DateTimePeriod } from 'stores/events/events-store';
import PeriodInput from 'widgets/form/period-input';
import './pause-periods-list.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons';

interface EventPausePeriodsProps extends WithTranslation {
  onEditMode: boolean;
  pausePeriods?: DateTimePeriod[];
  onChange?: (period: DateTimePeriod[]) => void;
  hideIcon?: boolean;
  btnWithItems?: boolean;
  className?: string;
  startDate?: string;
  endDate?: string;
}
const EventPausePeriodList: FC<EventPausePeriodsProps> = props => {
  const { onEditMode, onChange, t, pausePeriods, hideIcon, className, btnWithItems, startDate, endDate } = props;
  const handleOnRemovePausePeriod = (index: number) => {
    onChange(pausePeriods.filter((_, i) => i !== index));
  };

  const handlePeriodChange = (period: DateTimePeriod, index: number) => {
    const periods = [...pausePeriods];
    periods[index] = period;
    onChange(periods);
  };

  const handleOnAddPausePeriod = (): void => {
    const emptyPeriod: DateTimePeriod = { from: null, to: null };
    const periods = [...pausePeriods, emptyPeriod];
    onChange(periods);
  };

  return (
    <div className={className || 'form__header__row'}>
      {!hideIcon && <FontAwesomeIcon className="form__header__row-icon" icon={faPauseCircle} />}
      {!btnWithItems && (
        <div className="pause-period__btn">
          <Button disabled={!onEditMode} onClick={handleOnAddPausePeriod}>
            {t('Add Pause Period')}
          </Button>
        </div>
      )}
      <div className="pause-period__items">
        {btnWithItems && (
          <div className="pause-period__btn">
            <Button disabled={!onEditMode} onClick={handleOnAddPausePeriod}>
              {t('Add Pause Period')}
            </Button>
          </div>
        )}
        {(pausePeriods || []).map((period, index) => (
          <div className="pause-period__dates-row" key={`${period.from}-${index}-${period.to}`}>
            <PeriodInput
              id={`pause-period-${period.from}-${index}-${period.to}`}
              readOnly={!onEditMode}
              value={period}
              onChange={value => handlePeriodChange(value, index)}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
            />

            {onEditMode && pausePeriods.length > 0 && (
              <Icon
                className="clickable-icon remove-icon"
                color="red"
                size="large"
                name="remove"
                onClick={inst => handleOnRemovePausePeriod(index)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
EventPausePeriodList.defaultProps = { hideIcon: false, btnWithItems: false };

export default withTranslation()(memo(EventPausePeriodList));
