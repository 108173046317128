import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { LocationDto, DropDownLocationsStore } from '../../../stores/configuration/locations/locations-store';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { IdentityService } from 'services/identity-service';
import { Dropdown } from 'semantic-ui-react';
import { resolve } from 'inversify-react';
import { ItemReference } from 'stores/dataStore';

interface LocationSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  onChangeLocation?: (value: ItemReference) => void;
  locations?: DropDownLocationsStore;
  filterByRole?: boolean;
  direction?: 'left' | 'right';
}

@connect(['locations', DropDownLocationsStore])
class LocationSelector extends React.Component<LocationSelectorProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    let filter: any = [`active eq true`];
    let locationsByActiveRole: string[];
    const activeRole = this.identityService.activeRole;
    if (this.props.filterByRole && activeRole !== 'Admin') {
      const currentUserInfo = this.identityService.getUserInfo();
      locationsByActiveRole = currentUserInfo.locationsByRoles[activeRole] as string[];
      filter = [{ Id: { in: { type: 'guid', value: locationsByActiveRole } } }];
    }
    this.props.locations
      .getAllAsync(
        {
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }],
          filter
        },
        locationsByActiveRole
      )
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.location }))
        });
      });
  }

  handleOnChange(id: string) {
    if (this.props.onChange) {
      this.props.onChange(id);
      return;
    }

    if (this.props.onChangeLocation) {
      if (!id) {
        this.props.onChangeLocation(null);
        return;
      }

      let option = this.state.options.find(x => x.value === id);
      this.props.onChangeLocation({
        id: option.value,
        title: option.text
      });
    }
  }

  public render() {
    const { options, isLoading } = this.state;
    const { value, placeholder, direction, readOnly, className, clearable } = this.props;
    const selectedValue = value || null;

    return (
      <Dropdown
        direction={direction}
        selection
        loading={isLoading}
        search
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => this.handleOnChange(value as string)}
        selectOnBlur={false}
        disabled={readOnly}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(LocationSelector);
