import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Component } from 'react';
import { resolve } from 'inversify-react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { isUserEventOwner, isCurrentUserEventInstructor } from 'utils/event-utils';
import { IdentityService, Roles } from 'services/identity-service';
import { ItemModel } from 'stores/dataStore';
import { EventFormStore } from 'stores/events/event-form-store';
import {
  EventFeedbackTabStore,
  NewFeedbackFormUserStore,
  EventFeedbackTemplatesDto,
  SimpleFeedbackFormAnswerDto,
  CreateFeedbackFormDto,
  Respondent
} from 'stores/events/feedback-forms-store';
import FeedbackTable from './feedback-table-form';
import './feedback-tab.less';
import ChooseUserForm from 'widgets/form/choose-user-form';
import { UserDto } from 'stores/users/users-store';
import { ColumnsNeeds } from 'widgets/collections/util-table';
import { openInNewWindow } from 'utils/useful-functions';

interface FeedbackTabProps extends WithTranslation {
  eventFormStore?: EventFormStore;
  feedbacksStore?: EventFeedbackTabStore;
  newFeedbackStore?: NewFeedbackFormUserStore;
}
export interface FeedbackTabState {
  showConfirmFeedbackModal: boolean;
  showConfirmDeleteUserFeedback: boolean;
  showUsersModal: boolean;
  feedbackTemplateIdToGenerate: string;
  selectedUsers: UserDto[];
  isTraineesFeedback: boolean;
  feedbackFormAnswerClickedToDeleteId: string;
}
const cols: ColumnsNeeds<UserDto>[] = [
  { column: 'lastName', tooltipRenderer: false, title: 'Last Name' },
  { column: 'firstName', tooltipRenderer: false, title: 'First Name' },
  { column: 'employeeId', tooltipRenderer: false, title: 'SAP ID' },
  { column: 'sfPosition', tooltipRenderer: false, title: 'SF Position' },
  { column: 'positionCode', tooltipRenderer: false, title: 'Position Code' },
  { column: 'location', tooltipRenderer: false, title: 'Location' }
];

@connect(['eventFormStore', EventFormStore], ['feedbacksStore', EventFeedbackTabStore], ['newFeedbackStore', NewFeedbackFormUserStore])
class FeedbackTab extends Component<FeedbackTabProps, FeedbackTabState> {
  state: FeedbackTabState = {
    showConfirmFeedbackModal: false,
    showConfirmDeleteUserFeedback: false,
    showUsersModal: false,
    feedbackTemplateIdToGenerate: null,
    selectedUsers: [],
    isTraineesFeedback: false,
    feedbackFormAnswerClickedToDeleteId: null
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isCurrentUserEventOwnerOrInstructorOrAdmin = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      isUserEventOwner(event) ||
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventInstructor(event)) ||
      this.identityService.isInRole(Roles.Admin)
    );
  };
  private isCurrentUserPlannerOrPoC = (): boolean => {
    return (
      this.identityService.isInRole(Roles.Planner) ||
      this.identityService.isInRole(Roles.PlannerMTC) ||
      this.identityService.isInRole(Roles.PoC)
    );
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    try {
      this.props.feedbacksStore.getTemplatesByEventId(this.props.eventFormStore.state.item.id);
    } catch (error) {
      console.error({ error });
    }
  };

  private isEventClosed = (): boolean => {
    return this.props.eventFormStore.state.item.status.toString() === 'Closed';
  };

  private isCurrentUserEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  private getCurrentUserId = (): string => {
    const { userId } = this.identityService.getUserInfo();
    return userId;
  };

  private closeConfirmModal = () => {
    this.setState({ showConfirmFeedbackModal: false });
  };

  private closeConfirmDeleteModal = () => {
    this.setState({ showConfirmDeleteUserFeedback: false, feedbackFormAnswerClickedToDeleteId: null });
  };

  private onGenerateFeedback = async () => {
    this.setState({ showConfirmFeedbackModal: false, showUsersModal: false, showConfirmDeleteUserFeedback: false });
    const { eventFormStore, feedbacksStore, newFeedbackStore } = this.props;
    const { selectedUsers, feedbackTemplateIdToGenerate } = this.state;
    const { id: eventId, hasStudents, students } = eventFormStore.state.item;
    const storedData = feedbacksStore.state.items.find(({ item }) => item.id === feedbackTemplateIdToGenerate);

    const isTrainees = storedData.item.respondents === 'Trainees';
    let isValid = (isTrainees && (!hasStudents || (students || []).length > 0)) || !isTrainees;
    if (!isValid) {
      eventFormStore.changeMessages([{ body: 'Number of assigned student should be greater than 0', level: 'Error' }]);
      return;
    }

    if (!storedData.item) return;
    const newItem: CreateFeedbackFormDto = {
      eventId,
      usersId: (selectedUsers || []).map(({ id }) => id),
      feedbackTemplateId: storedData.item.id
    };

    newFeedbackStore.createNew(newItem);
    try {
      const newForms = await newFeedbackStore.createForms();
      if (newForms && newForms.isSuccess) {
        feedbacksStore.state.items = [];
        this.load();
      }
    } catch (error) {
      console.error({ error });
    }

    this.setState({ showConfirmFeedbackModal: false, showUsersModal: false, showConfirmDeleteUserFeedback: false });
  };

  private handleOnRemoveFeedBack = async (feedbackFormAnswerClicked: SimpleFeedbackFormAnswerDto) => {
    this.setState({ showConfirmDeleteUserFeedback: true, feedbackFormAnswerClickedToDeleteId: feedbackFormAnswerClicked.id });
  };

  private handleConfirmationRemoveFeedBack = async () => {
    await this.props.feedbacksStore.deleteAsync(this.state.feedbackFormAnswerClickedToDeleteId);
    this.setState({ showConfirmDeleteUserFeedback: false, feedbackFormAnswerClickedToDeleteId: null });
    this.props.feedbacksStore.state.items = [];
    this.load();
  };

  private onOpenEmployeeFeedBackForm = (form: SimpleFeedbackFormAnswerDto) => {
    openInNewWindow(`./assessments/feedbacks/form/${form.id}`);
  };

  private toFindFeedBacksToEmployee = (list: ItemModel<EventFeedbackTemplatesDto>[]) => {
    const employeeId = this.getCurrentUserId();
    return (list || []).filter(({ item }) => (item.feedbackFormAnswers || []).filter(({ userId }) => userId === employeeId).length > 0);
  };

  private handleAddUsers = (selectedUsers: UserDto[]) => {
    this.setState({ selectedUsers, showConfirmFeedbackModal: true });
  };

  private toggleUsersModal = () => {
    this.setState(({ showUsersModal }) => ({ showUsersModal: !showUsersModal }));
  };

  private handleGenerateForms = (respondent: string, templateId: string) => {
    this.setState({ feedbackTemplateIdToGenerate: templateId });
    if (Respondent.Trainees === Respondent[respondent]) this.setState({ isTraineesFeedback: true, showConfirmFeedbackModal: true });
    else if (Respondent.ManualAssignment === Respondent[respondent]) {
      this.toggleUsersModal();
      this.setState({ isTraineesFeedback: false });
    }
  };

  private canSeeUsersMessage(): boolean {
    if (this.state.selectedUsers && this.state.selectedUsers.length > 0 && !this.state.isTraineesFeedback) return true;
    return false;
  }

  public render() {
    const { t, feedbacksStore, newFeedbackStore, eventFormStore } = this.props;
    const { items } = feedbacksStore.state;
    const { showConfirmFeedbackModal, showUsersModal, showConfirmDeleteUserFeedback } = this.state;
    const currentUserId = this.getCurrentUserId();
    const isUserEmployee = this.isCurrentUserEmployee();
    const isEventOwnerOrInstructorOrAdmin = this.isCurrentUserEventOwnerOrInstructorOrAdmin();
    const isEventClosed = this.isEventClosed();
    const isPocOrPlanner = this.isCurrentUserPlannerOrPoC();

    if ((newFeedbackStore.state.result?.messages || []).length > 0) {
      eventFormStore.changeMessages(newFeedbackStore.state.result.messages);
      newFeedbackStore.clearMessages();
    }

    return (
      <>
        <Dimmer
          active={feedbacksStore.state.isBusy || newFeedbackStore.state.isBusy}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}
        >
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <div className="form__feedback-container">
          {(items || []).map(({ item }) => {
            const { title, feedbackFormAnswers, id, respondents } = item;
            const feedbackTypeClass =
              (feedbackFormAnswers || []).length > 0 ? 'form__feedback-item flex-column' : 'form__feedback-item flex-start-center';
            return (
              <>
                {(isEventOwnerOrInstructorOrAdmin || isPocOrPlanner) && feedbackFormAnswers && feedbackFormAnswers.length > 0 && (
                  <div key={id} className={feedbackTypeClass}>
                    <p className="feedback-tab__item-title">{title}</p>
                    <FeedbackTable
                      templateId={id}
                      forms={feedbackFormAnswers && feedbackFormAnswers}
                      handleOnRemoveFeedBack={this.handleOnRemoveFeedBack}
                      onOpenEmployeeFeedBackForm={this.onOpenEmployeeFeedBackForm}
                      isEventClosed={isEventClosed}
                      canRemoveFeedback={isEventOwnerOrInstructorOrAdmin}
                    />
                  </div>
                )}

                {isEventOwnerOrInstructorOrAdmin && item && !isEventClosed && (!feedbackFormAnswers || feedbackFormAnswers.length <= 0) && (
                  <div key={id} className={feedbackTypeClass}>
                    <p className="feedback-tab__item-title">{title}</p>
                    <Button onClick={() => this.handleGenerateForms(respondents, id)}>{t('Generate Feedback')}</Button>
                  </div>
                )}

                {isUserEmployee &&
                  !isEventOwnerOrInstructorOrAdmin &&
                  (feedbackFormAnswers || [])
                    .filter(answer => answer.userId === currentUserId)
                    .map(answer => (
                      <div key={id} className="form__feedback-item flex-start-center">
                        <p className="feedback-tab__item-title">{title}</p>
                        <Button
                          onClick={() => this.onOpenEmployeeFeedBackForm(answer)}
                          className={answer.answered ? 'feedback-generation__answered-btn' : ''}
                          disabled={answer.answered}
                        >
                          {answer.answered ? t('Answered') : t('Answer Feedback')}
                        </Button>
                      </div>
                    ))}
              </>
            );
          })}
          {showConfirmFeedbackModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              centered
              open={showConfirmFeedbackModal}
              size="mini"
              className="have-warnings-popup feedback__confirm-modal"
              closeOnEscape={true}
              onClose={this.closeConfirmModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>
                  {t(
                    `This Feedback will be generated for ${
                      this.canSeeUsersMessage() ? 'the users selected' : 'all the Trainees'
                    }. Do you want to generate it?`
                  )}
                </p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="feedback-modal__cancel-button" content={t('Cancel')} onClick={this.closeConfirmModal} />
                <Button
                  className="have-warnings-popup__btn feedback-modal__accept-button"
                  content={t('Accept')}
                  onClick={this.onGenerateFeedback}
                />
              </div>
            </Modal>
          )}
          {showUsersModal && (
            <ChooseUserForm
              columns={cols}
              onAddUsers={this.handleAddUsers}
              onCloseUsersModal={this.toggleUsersModal}
              showEmployeeIdFilter
              showPositionCodeFilter
              showRoleFilter
              showLocationFilter
            />
          )}
          {showConfirmDeleteUserFeedback && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              centered
              open={showConfirmDeleteUserFeedback}
              size="mini"
              className="have-warnings-popup feedback__confirm-modal"
              closeOnEscape={true}
              onClose={this.closeConfirmDeleteModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>{t(`This User Feedback will be deleted. Are you sure you want to continue?`)}</p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="feedback-modal__cancel-button" content={t('Cancel')} onClick={this.closeConfirmDeleteModal} />
                <Button
                  className="have-warnings-popup__btn feedback-modal__accept-button"
                  content={t('Accept')}
                  onClick={this.handleConfirmationRemoveFeedBack}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(FeedbackTab);
