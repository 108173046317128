import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface DeliveryMethodSfMapDto extends BaseDto {
  id: string;
  deliveryTypeId: string;
  deliveryTypeName: string;
  mappingToSF: string;
}

export interface CreateDeliveryMethodSfMapDto {
  deliveryTypeId: string;
  mappingToSF: string;
}

export interface ChangeDeliveryMethodSfMapDto {
  id: string;
  deliveryTypeId: string;
  deliveryTypeName: string;
  mappingToSF: string;
}

export class CreateDeliveryMethodSfMapValidator extends AbstractValidator<CreateDeliveryMethodSfMapDto> {
  constructor() {
    super();

    this.validateIfString(o => o.deliveryTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('DeliveryTypeId is required'));

    this.validateIfString(o => o.mappingToSF)
      .isNotEmpty()
      .withFailureMessage(i18n.t('MappinToSF is required'));
  }
}

export class ChangeDeliveryMethodSfMapValidator extends AbstractValidator<ChangeDeliveryMethodSfMapDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('DeliveryMethodSfMap Id is required'));

    this.validateIfString(o => o.deliveryTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('DeliveryTypeId is required'));

    this.validateIfString(o => o.mappingToSF)
      .isNotEmpty()
      .withFailureMessage(i18n.t('MappinToSF is required'));
  }
}

@repository('@@DELIVERYMETHODSFMAP', 'deliverymethodsfmap.summary')
export class DeliveryMethodSfMapsStore extends DataStore<DeliveryMethodSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-delivery-method';
  retrievePath = 'get-delivery-methods';
  updatePath = 'update-delivery-method';
  deletePath = 'delete-delivery-method';

  protected validate(item: DeliveryMethodSfMapDto) {
    return new ChangeDeliveryMethodSfMapValidator().validate(item);
  }

  constructor() {
    super('CLUSTER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@DELIVERYMETHODSFMAP', 'deliverymethodsfmap.new')
export class NewDeliveryMethodSfMapStore extends FormStore<CreateDeliveryMethodSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-delivery-method';
  retrievePath = 'get-delivery-method';
  updatePath = 'update-delivery-method';

  protected validate(item: CreateDeliveryMethodSfMapDto) {
    return new CreateDeliveryMethodSfMapValidator().validate(item);
  }

  constructor() {
    super('NEW_DELIVERYMETHODSFMAP', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@DELIVERYMETHODSFMAP', 'deliverymethodsfmap.change')
export class ChangeDeliveryMethodSfMapStore extends FormStore<ChangeDeliveryMethodSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-delivery-method';
  retrievePath = 'get-delivery-method';
  updatePath = 'update-delivery-method';

  protected validate(item: ChangeDeliveryMethodSfMapDto) {
    return new ChangeDeliveryMethodSfMapValidator().validate(item);
  }

  constructor() {
    super('CHANGE_DELIVERYMETHODSFMAP', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
