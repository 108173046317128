import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';
import { InstructorVisaDto, ChangeInstructorVisaStore } from 'stores/skills/instructor-visa-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';
import VisaEditor, { VisaItemReference } from 'widgets/bussiness/visa-editor';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { DateInput } from 'widgets/form/dateInput';
import { DateTimeService } from 'services/datetime-service';

interface ChangeInstructorVisaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeInstructorVisa?: ChangeInstructorVisaStore;
  currentInstructorVisa: InstructorVisaDto;
}

interface ChangeInstructorVisaViewState {
  instructorId: string;
  ttcLocationId: string;
  travelingLocation: ItemReference;
  visa: VisaItemReference;
  startDate: string;
  expireDate: string;
  userClickedSaved: boolean;
  treatyRequired: boolean;
  noneVisa: boolean;
  isInstructorRestriction: boolean;
}

@connect(['changeInstructorVisa', ChangeInstructorVisaStore])
class ChangeInstructorVisaView extends React.Component<ChangeInstructorVisaViewProps, ChangeInstructorVisaViewState> {
  private get changeInstructorVisaStore() {
    return this.props.changeInstructorVisa;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: ChangeInstructorVisaViewProps) {
    super(props);

    this.state = {
      instructorId: this.props.currentInstructorVisa.instructorId,
      ttcLocationId: this.props.currentInstructorVisa.ttcLocationId,
      travelingLocation: {
        id: this.props.currentInstructorVisa.travelingLocationId,
        title: this.props.currentInstructorVisa.travelingLocationName
      },
      visa: {
        id: this.props.currentInstructorVisa.visaId,
        title: this.props.currentInstructorVisa.visaName,
        visaTreatyRequired: this.props.currentInstructorVisa.visaTreatyRequired
      },
      startDate: this.props.currentInstructorVisa.startDate,
      expireDate: this.props.currentInstructorVisa.expireDate,
      userClickedSaved: false,
      treatyRequired: this.props.currentInstructorVisa.visaTreatyRequired,
      noneVisa: this.props.currentInstructorVisa.visaName === 'None',
      isInstructorRestriction: this.props.currentInstructorVisa.isInstructorRestriction
    };

    this.changeInstructorVisaStore.state.result = null;

    this.changeInstructorVisaStore.change({
      id: this.props.currentInstructorVisa.id,
      instructorId: this.props.currentInstructorVisa.instructorId,
      ttcLocationId: this.props.currentInstructorVisa.ttcLocationId,
      travelingLocationId: this.props.currentInstructorVisa.travelingLocationId,
      visaId: this.props.currentInstructorVisa.visaId,
      startDate: this.props.currentInstructorVisa.startDate,
      expireDate: this.props.currentInstructorVisa.expireDate,
      isInstructorRestriction: this.props.currentInstructorVisa.isInstructorRestriction,
      visaTreatyRequired: this.props.currentInstructorVisa.visaTreatyRequired
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeInstructorVisaViewProps) {
    if (this.changeInstructorVisaStore.state.result && this.changeInstructorVisaStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeInstructorVisaStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeInstructorVisaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeInstructorVisaStore.change({ ...this.changeInstructorVisaStore.state.item, ...change });
  }

  private getInstructorName = () => {
    const user = this.identityService.getCurrentUserDto();
    this.changeInstructorVisaStore.state.item.instructorId = user.id;
    return `${user.firstName} ${user.lastName}`.trim();
  };

  private getInstructorLocation = () => {
    const user = this.identityService.getCurrentUserDto();
    const instructorLocation = (user.roles || []).find(item => item.role.name === 'Instructor');
    this.changeInstructorVisaStore.state.item.ttcLocationId = instructorLocation.location.id;
    return instructorLocation.location.location;
  };

  private handleOnTravelingLocationChange = (travelingLocation: ItemReference) => {
    this.changeInstructorVisaStore.state.item.travelingLocationId = travelingLocation ? travelingLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnVisaChange = (visa: VisaItemReference) => {
    let treatyRequired: boolean = false;
    let noneVisa: boolean = false;
    let travelingLocation: ItemReference = null;
    let startDate: string = null;
    let expireDate: string = null;

    if (visa) {
      treatyRequired = visa.visaTreatyRequired;
      noneVisa = visa.title === 'None';
      this.changeInstructorVisaStore.state.item.visaId = visa.id;
      this.changeInstructorVisaStore.state.item.visaName = visa.title;
      this.changeInstructorVisaStore.state.item.visaTreatyRequired = treatyRequired;

      if (treatyRequired) {
        this.changeInstructorVisaStore.state.item.travelingLocationId = '';
        travelingLocation = this.state.travelingLocation;
        startDate = this.state.startDate;
        expireDate = this.state.expireDate;
      }

      if (noneVisa) {
        this.changeInstructorVisaStore.state.item.startDate = '';
        this.changeInstructorVisaStore.state.item.expireDate = '';
      }
    }

    this.setState({
      userClickedSaved: false,
      treatyRequired: treatyRequired,
      noneVisa: noneVisa,
      travelingLocation: travelingLocation,
      startDate: startDate,
      expireDate: expireDate
    });
  };

  public render() {
    const { t } = this.props as any;
    const { treatyRequired, noneVisa } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeInstructorVisaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Instructor Visa')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeInstructorVisaStore.state.result && !this.changeInstructorVisaStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeInstructorVisaStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeInstructorVisaStore.state.item && (
              <Form>
                <Form.Group required widths="3">
                  <Form.Field required>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Instructor')}</label>
                      <Input disabled value={this.getInstructorName()}></Input>
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changeInstructorVisaStore.state.item.visaId && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid
                      className={`${this.changeInstructorVisaStore.state.item.visaId == null ||
                        isNullOrWhiteSpaces(this.changeInstructorVisaStore.state.item.visaId)} field`}
                      label={t('Visa')}
                    >
                      <VisaEditor
                        onChange={this.handleOnVisaChange}
                        value={this.state.visa}
                        placeholder={t('Visa')}
                        loadDataOnOpen={true}
                      />
                    </Form.Input>
                  </Form.Field>

                  <Form.Field required error={!this.changeInstructorVisaStore.state.item.ttcLocationId && this.state.userClickedSaved}>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('TTC Location')}</label>
                      <Input disabled value={this.getInstructorLocation()}></Input>
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group required widths="3">
                  {!treatyRequired && (
                    <Form.Field
                      required
                      error={!this.changeInstructorVisaStore.state.item.travelingLocationId && this.state.userClickedSaved}
                    >
                      <Form.Input
                        required
                        fluid
                        className={`${this.changeInstructorVisaStore.state.item.travelingLocationId == null ||
                          isNullOrWhiteSpaces(this.changeInstructorVisaStore.state.item.travelingLocationId)} field`}
                        label={t('Traveling Location')}
                      >
                        <LocationEditor nullable value={this.state.travelingLocation} onChange={this.handleOnTravelingLocationChange} />
                      </Form.Input>
                    </Form.Field>
                  )}

                  {!noneVisa && (
                    <Form.Field required error={!this.changeInstructorVisaStore.state.item.startDate && this.state.userClickedSaved}>
                      <div
                        className={`required ${this.changeInstructorVisaStore.state.item.startDate == null ||
                          isNullOrWhiteSpaces(this.changeInstructorVisaStore.state.item.startDate)} field`}
                      >
                        <label>{t('Start Date')}</label>
                        <DateInput
                          iconPosition="right"
                          className="date-picker__left"
                          value={
                            this.changeInstructorVisaStore.state.item.startDate == null
                              ? ''
                              : DateTimeService.toString(this.changeInstructorVisaStore.state.item.startDate)
                          }
                          onChange={(_, value) => this.handleValue('startDate', value)}
                          minDate={
                            this.changeInstructorVisaStore.state.item.startDate == null
                              ? DateTimeService.toString(DateTimeService.now())
                              : DateTimeService.toString(this.changeInstructorVisaStore.state.item.startDate)
                          }
                        />
                      </div>
                    </Form.Field>
                  )}

                  {!noneVisa && (
                    <Form.Field required error={!this.changeInstructorVisaStore.state.item.expireDate && this.state.userClickedSaved}>
                      <div
                        className={`required ${this.changeInstructorVisaStore.state.item.expireDate == null ||
                          isNullOrWhiteSpaces(this.changeInstructorVisaStore.state.item.expireDate)} field`}
                      >
                        <label>{t('Expire Date')}</label>
                        <DateInput
                          iconPosition="right"
                          className="date-picker__right"
                          value={
                            this.changeInstructorVisaStore.state.item.expireDate == null
                              ? ''
                              : DateTimeService.toString(this.changeInstructorVisaStore.state.item.expireDate)
                          }
                          onChange={(_, value) => this.handleValue('expireDate', value)}
                          initialValue={
                            this.changeInstructorVisaStore.state.item.startDate
                              ? DateTimeService.toString(this.changeInstructorVisaStore.state.item.startDate)
                              : null
                          }
                          minDate={
                            this.changeInstructorVisaStore.state.item.startDate
                              ? DateTimeService.toString(this.changeInstructorVisaStore.state.item.startDate)
                              : DateTimeService.toString(DateTimeService.now())
                          }
                        />
                      </div>
                    </Form.Field>
                  )}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeInstructorVisaView);
