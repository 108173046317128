import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AxiosResponse } from 'axios';
import { container } from 'inversify.config';
import { AsyncAction, ReduxRepository, repository } from 'redux-scaffolding-ts';
import HttpService from 'services/http-service';
import { IdentityService } from 'services/identity-service';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { ItemResult } from 'stores/types';
import { constants } from 'services/configuration-service';
import { DateTimeService } from 'services/datetime-service';
import { stringify } from 'query-string';

export interface RequestChatMessageDto {
  id: string;
  requestId: string;
  timestamp: string;
  fromUserId: string;
  fromUsername: string;
  fromFirstName: string;
  fromLastName: string;
  requestStatus: string;
  message: string;
}

export interface CreateRequestChatMessageDto {
  requestId: string;
  timestamp: string;
  message: string;
}

export interface RequestChatState {
  requestId: string;
  messages: RequestChatMessageDto[];
  isBusy: boolean;
  result: any;
}

@repository('@@REQUESTS', 'requests.chat')
export class RequestChatStore extends ReduxRepository<RequestChatState> {
  public DATA_LOADED = 'REQUEST_CHAT_DATA_LOADED';
  public NEW_MESSAGE_RECEIVED = 'REQUEST_CHAT_NEW_MESSAGE_RECEIVED';
  public MESSAGE_SENT = 'REQUEST_CHAT_MESSAGE_SENT';
  public HUB_ERROR = 'REQUEST_CHAT_HUB_ERROR';
  public CLEAR_MESSAGES = 'REQUEST_CHAT_CLEAR_MESSAGES';
  public RESET = 'REQUEST_CHAT_RESET';

  // baseUrl = 'events/v1/request-chat';
  baseUrl = `${constants.apiBaseUrl}/events/v1/request-chat`;
  addMessagePath = 'send-message';
  retrieveMessagesPath = 'get-messages';

  private signalRConnection: HubConnection;

  constructor() {
    super({
      requestId: null,
      messages: [],
      isBusy: false,
      result: null
    });

    this.loadChat.bind(this);
    this.reset.bind(this);
    this.addMessage.bind(this);

    this.addReducer(this.DATA_LOADED, this.onChatLoaded, 'AsyncAction');
    this.addReducer(this.CLEAR_MESSAGES, this.onClearMessages, 'Simple');
    this.addReducer(this.HUB_ERROR, this.onHubError, 'Simple');
    this.addReducer(this.NEW_MESSAGE_RECEIVED, this.onNewMessageReceived, 'Simple');
    this.addReducer(this.MESSAGE_SENT, this.onMessageSent, 'AsyncAction');
    this.addReducer(this.RESET, this.onReset, 'Simple');
  }

  protected onHubError = (error: any): RequestChatState => {
    return { ...this.state, result: error };
  };

  public reset() {
    if (this.signalRConnection != null) {
      this.signalRConnection.stop().catch(err => {
        console.error('HubError', 'manual stop', err);
      });
    }
    this.dispatch(this.RESET);
  }

  protected onReset = () => {
    return {
      requestId: null,
      messages: [],
      isBusy: false,
      result: null
    };
  };

  public async loadChat(requestId: string, connectToSignalR: boolean = true, filters: any[] = []) {
    const finalFilter = [...(filters || []), { requestId: { eq: { type: 'guid', value: requestId } } }];
    const query = {
      searchQuery: '',
      orderBy: [{ direction: 'Ascending', field: 'timestamp', useProfile: false }],
      skip: 0,
      take: 1000000,
      filter: finalFilter
    } as Query;

    const httpService = container.get<HttpService>(HttpService);
    const identityService = container.get<IdentityService>(IdentityService);

    await this.dispatchAsync(
      this.DATA_LOADED,
      httpService.get<QueryResult<RequestChatMessageDto>>(
        `${this.baseUrl}/${requestId}/${this.retrieveMessagesPath}?${DataStore.buildUrl(query)}`
      ),
      requestId,
      connectToSignalR,
      identityService.activeRole
    );
  }

  public clearChatMessages() {
    this.dispatch(this.CLEAR_MESSAGES);
  }

  protected onChatLoaded = (): AsyncAction<AxiosResponse<QueryResult<RequestChatMessageDto>>, RequestChatState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result, requestId, connectToSignalR, activeRole) => {
        if (this.signalRConnection != null) {
          this.signalRConnection.stop().catch(err => {
            console.error('HubError', 'stop', err);
          });
        }

        if (connectToSignalR) {
          const params = { webrole: activeRole };
          this.signalRConnection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}/${requestId}?${stringify(params)}`, {
              accessTokenFactory: () => container.get<IdentityService>(IdentityService).accessToken
            })
            .build();

          this.signalRConnection.start().catch(err => {
            console.error('HubError', 'start', err);
            this.dispatch(this.HUB_ERROR, err);
          });

          this.signalRConnection.onclose(err => {
            if (err != null) {
              console.error('HubError', 'onclose', err);
              this.signalRConnection.start();
            }
          });

          this.signalRConnection.on('newMessage', this.newMessageReceived);
        }

        return {
          ...this.state,
          isBusy: false,
          messages: result.data.items,
          requestId,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  protected newMessageReceived = (message: RequestChatMessageDto) => {
    const { requestId } = this.state;
    if (message == null || message.requestId !== requestId) return;

    this.dispatch(this.NEW_MESSAGE_RECEIVED, message);
  };

  protected onNewMessageReceived = (message: RequestChatMessageDto): RequestChatState => {
    const { requestId } = this.state;
    const messages = (this.state.messages || []).filter(m => m.requestId === requestId && m.id !== message.id);
    messages.push(message);
    messages.sort(this.compareByDateTime);
    return {
      ...this.state,
      messages
    };
  };

  protected onClearMessages = () => {
    const messages = [];
    return {
      ...this.state,
      messages
    };
  };

  public async addMessage(dto: CreateRequestChatMessageDto) {
    const requestId = dto.requestId;
    const httpService = container.get<HttpService>(HttpService);
    const result = await this.dispatchAsync(
      this.MESSAGE_SENT,
      httpService.post<CreateRequestChatMessageDto, ItemResult<RequestChatMessageDto>>(
        `${this.baseUrl}/${requestId}/${this.addMessagePath}`,
        dto
      )
    );
    return result.data;
  }

  protected onMessageSent = (): AsyncAction<AxiosResponse<ItemResult<RequestChatMessageDto>>, RequestChatState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: result => {
        const message = result.data.item;
        const messages = (this.state.messages || []).filter(m => m.id !== message.id);
        messages.push(message);
        messages.sort(this.compareByDateTime);

        return {
          ...this.state,
          messages,
          isBusy: false,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  private compareByDateTime = (x: RequestChatMessageDto, y: RequestChatMessageDto): number => {
    const d1 = DateTimeService.toMoment(x.timestamp);
    const d2 = DateTimeService.toMoment(y.timestamp);
    if (d1.isSame(d2)) {
      return 0;
    }
    return d1.isBefore(d2) ? -1 : 1;
  };
}
