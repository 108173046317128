import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { SsaQuestionBanksStore, SsaQuestionBankDto } from 'stores/assessments/questionBank/ssa-questionBank-store';

interface ChooseSSAQuestionsViewProps extends WithTranslation {
  onAddSSAQuestions: (questions: SsaQuestionBankDto[]) => void;
  onCloseModal?: () => void;
  alreadySelectedQuestions: string[];
  ssaQuestionsStore?: SsaQuestionBanksStore;
  ssaSkillId?: string;
  ssaSkillName?: string;
  category?: string;
  professionId?: string;
}

interface ChooseSSAQuestionsFilters {
  questionTitleOrId: string;
}

interface ChooseSSAQuestionsViewState {
  query: Query;
  selectedQuestions: SsaQuestionBankDto[];
  filters: ChooseSSAQuestionsFilters;
}

@connect(['ssaQuestionsStore', SsaQuestionBanksStore])
class ChooseSSAQuestionsView extends React.Component<ChooseSSAQuestionsViewProps, ChooseSSAQuestionsViewState> {
  constructor(props: ChooseSSAQuestionsViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      selectedQuestions: null,
      filters: {
        questionTitleOrId: undefined
      }
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  @autobind
  private onAddSSAQuestions() {
    this.props.onAddSSAQuestions(this.state.selectedQuestions);

    this.props.onCloseModal();
  }

  @autobind
  private onCancel() {
    this.props.onCloseModal();
  }

  @autobind
  private load() {
    this.props.ssaQuestionsStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  }

  private handleFilterByQuestionTitleOrId(value: string) {
    let filters = { ...this.state.filters };
    filters.questionTitleOrId = value;
    this.refreshTable(filters);
  }

  private selectedSSAQuestions(items: unknown[]): void {
    this.setState({ selectedQuestions: items as any });
  }

  private refreshTable(filters: ChooseSSAQuestionsFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChooseSSAQuestionsFilters) {
    const oDataFilters = [];
    const { category, alreadySelectedQuestions, ssaSkillId, professionId } = this.props;

    if (alreadySelectedQuestions && alreadySelectedQuestions.length > 0)
      oDataFilters.push(`not(Id in (${alreadySelectedQuestions.join(',')}))`);

    if (!isNullOrWhiteSpaces(ssaSkillId)) {
      oDataFilters.push({
        or: [{ ssaSkillId: { eq: { type: 'guid', value: ssaSkillId } } }, { ssaSkillId: null }]
      });
    }
    if (!isNullOrWhiteSpaces(category)) oDataFilters.push({ testCategory: { name: { eq: category } } });

    if (!isNullOrWhiteSpaces(professionId)) {
      oDataFilters.push({ Professions: { any: { id: { eq: { value: professionId, type: 'guid' } } } } });
    }

    if (!isNullOrWhiteSpaces(filters.questionTitleOrId)) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QS');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QS')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    return oDataFilters;
  }

  public render() {
    const { t } = this.props as any;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SsaQuestionBankDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.text}</div>,
          selectableHeader: true
        }
      ],
      data: this.props.ssaQuestionsStore.state
    } as TableModel<SsaQuestionBankDto>;

    return (
      <Modal className="template-search__modal" open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={(event, data) => this.handleFilterByQuestionTitleOrId(data.value)}
            />
            <span className="keep-right skill-name">
              {t('Skill')}: {this.props.ssaSkillName}
            </span>
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={questions => this.selectedSSAQuestions(questions)}
              onPageChange={this.handlePageChange}
              //onFilterChange={this.handleFilterChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddSSAQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseSSAQuestionsView);
