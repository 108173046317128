import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { DropdownProps, SelectionInput } from 'widgets/form/selectionInput';
import { ItemReference, QueryResult } from 'stores/dataStore';
import { TestCategoryStore, TestCategoryDto } from 'stores/assessments/testCategory-store';
import React, { Component } from 'react';

export interface CategoryItemReference extends ItemReference {
  id: string;
  name?: string;
}

interface TestCategorySelectorProps extends DropdownProps, WithTranslation {
  value: CategoryItemReference;
  onChange?: (value: CategoryItemReference) => void;
  testCategories?: TestCategoryStore;
  clearable?: boolean;
  readOnly?: boolean;
  isTnaCategory: boolean;
  category?: 'TNA' | 'SSA' | 'IWS';
}

interface TestCategoryState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  value: CategoryItemReference | ItemReference;
}

@connect(['testCategories', TestCategoryStore])
class TestCategorySelector extends Component<TestCategorySelectorProps, TestCategoryState> {
  private get testCategoryStore() {
    return this.props.testCategories;
  }

  constructor(props: Readonly<TestCategorySelectorProps>) {
    super(props);

    this.state = {
      query: this.getTestCategories(),
      value: this.props.value
    };
  }

  componentDidUpdate({ value: newValue }: Readonly<TestCategorySelectorProps>) {
    const { value } = this.props;
    let newChanges: any = {};
    if (value?.id !== newValue?.id) newChanges.value = value;
    Object.keys(newChanges).length > 0 && this.setState(newChanges);
  }

  private getTestCategories = () => {
    let filter;
    if (this.props.testCategories && this.props.isTnaCategory) filter = [{ AllowedForTNA: this.props.isTnaCategory }];
    else filter = [{ AllowedForSSA: !this.props.isTnaCategory }];
    if (this.props.category) {
      let allowed = {};
      const prefix = 'AllowedFor';
      const val = prefix + this.props.category;
      allowed[val] = true;
      filter = [allowed];
    }
    const method = async (search: string) => {
      const result = await this.testCategoryStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<TestCategoryDto>('name'), useProfile: false }],
        filter
      });

      return Object.assign({}, result, {
        items: result.items.map(c => ({ id: c.id, title: c.name }))
      }) as QueryResult<CategoryItemReference>;
    };
    return method;
  };

  public render() {
    const query = this.state.query;
    const selectedValue = this.state.value;
    return (
      <SelectionInput
        content={item => <>{item.title}</>}
        searchable
        clearable={this.props.clearable}
        className={this.props.className}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        readOnly={this.props.readOnly}
        value={selectedValue}
        onChange={value => this.props.onChange(value as any)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(TestCategorySelector);
