import { setupRepositories as setupSkillsRepositories } from './skills/assessments-skills-store.config';
import { setupRepositories as setupQuestionsBankRepositories } from './questionBank/assessments-questionBank-store.config';
import { setupRepositories as setupTnaTemplatesRepositories } from './templates/assessments-templates-store.config';
import { setupRepositories as setupTheoreticalTemplates } from './wizard/theoretical-config';
import { TestCategoryStore, ChangeTestCategoryStore, NewTestCategoryStore } from './testCategory-store';
import {
  TnaFormListStore,
  NewTnaFormStore,
  TnaFormChangeAnswerStore,
  ChangeActiveTnaFormTnaStore,
  ChangeTnaFormWizardStore
} from './forms/tna-forms-store';
import { SsaFormWizardStore, ChangeSsaFormWizardStore } from './wizard/ssa-form-wizard-store';
import { NewSsaFormStore, SsaFormStore, SsaFormListStore } from './forms/ssa-forms-store';

import { SsaActivityTypeStore } from './ssa-activity-type-store';
import { FeedBackFormViewModelStore } from './forms/feedback-form.store';
import { TheoreticalTestFormStore } from './forms/theoretical-form-test-store';
import { TnaTheoreticalTestListStore, NewTnaTheoreticalTestStore } from './forms/tna-theoretical-tests-store';

import { PracticalFormWizardStore, PracticalFormListStore } from './forms/practical-form-store';
import {
  ChangeActiveIwsFormIwsStore,
  ChangeIwsFormWizardStore,
  IwsFormChangeAnswerStore,
  IwsFormListStore,
  NewIwsFormStore
} from './forms/iws-forms-store';

import { TranslateTnaQuestionTranslateFormStore } from './forms/tna-translate-question-form-store';
import { TranslateIwsQuestionTranslateFormStore } from './forms/iws-translate-question-form-store';

export function setupRepositories(storeBuilder) {
  setupSkillsRepositories(storeBuilder);
  setupQuestionsBankRepositories(storeBuilder);
  setupTheoreticalTemplates(storeBuilder);
  setupTnaTemplatesRepositories(storeBuilder);

  storeBuilder.addRepository(new TestCategoryStore());
  storeBuilder.addRepository(new ChangeTestCategoryStore());
  storeBuilder.addRepository(new NewTestCategoryStore());

  storeBuilder.addRepository(new TnaFormListStore());
  storeBuilder.addRepository(new NewTnaFormStore());
  storeBuilder.addRepository(new ChangeTnaFormWizardStore());
  storeBuilder.addRepository(new TnaFormChangeAnswerStore());
  storeBuilder.addRepository(new ChangeActiveTnaFormTnaStore());

  storeBuilder.addRepository(new SsaFormWizardStore());
  storeBuilder.addRepository(new ChangeSsaFormWizardStore());

  storeBuilder.addRepository(new SsaFormStore());
  storeBuilder.addRepository(new NewSsaFormStore());
  storeBuilder.addRepository(new SsaFormListStore());

  storeBuilder.addRepository(new SsaActivityTypeStore());
  storeBuilder.addRepository(new FeedBackFormViewModelStore());

  storeBuilder.addRepository(new TnaTheoreticalTestListStore());
  storeBuilder.addRepository(new NewTnaTheoreticalTestStore());

  storeBuilder.addRepository(new PracticalFormWizardStore());
  storeBuilder.addRepository(new PracticalFormListStore());
  storeBuilder.addRepository(new TheoreticalTestFormStore());

  storeBuilder.addRepository(new IwsFormListStore());
  storeBuilder.addRepository(new NewIwsFormStore());
  storeBuilder.addRepository(new ChangeIwsFormWizardStore());
  storeBuilder.addRepository(new IwsFormChangeAnswerStore());
  storeBuilder.addRepository(new ChangeActiveIwsFormIwsStore());

  storeBuilder.addRepository(new TranslateTnaQuestionTranslateFormStore());
  storeBuilder.addRepository(new TranslateIwsQuestionTranslateFormStore());
}
