import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { ItemReference } from 'stores/dataStore';
import { NewInstructorExpertiseStore, CreateInstructorExpertiseDto } from 'stores/skills/instructor-expertise-store';
import UserEditor from 'widgets/bussiness/user-by-role-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import ProfesionEditor from 'widgets/bussiness/profession-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import MachineMOdelEditor from 'widgets/bussiness/machine-model-editor';
import FunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import ClusterEditor from 'widgets/bussiness/cluster-editor';
import FunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import TrainingNameEditor from 'widgets/bussiness/training-name-editor';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import { nameof } from 'utils/object';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';

interface newInstructorExpertiseViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newInstructorExpertise?: NewInstructorExpertiseStore;
  isMachineRelated?: boolean;
}

interface newInstructorExpertiseViewState {
  instructor: ItemReference;
  eventType: ItemReference;
  role: ItemReference;
  level: ItemReference;
  cluster: ItemReference;
  isMachineRelated: boolean;
  equipmentType: ItemReference;
  machineModel: ItemReference;
  functionalSubArea: ItemReference;
  oem: ItemReference;
  nmrcluster: ItemReference;
  nmrfunctionalarea: ItemReference;
  nrmTrainingName: ItemReference;
}

@connect(['newInstructorExpertise', NewInstructorExpertiseStore])
class newInstructorExpertiseView extends React.Component<newInstructorExpertiseViewProps, newInstructorExpertiseViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get NewInstructorExpertiseStore() {
    return this.props.newInstructorExpertise;
  }

  constructor(props: newInstructorExpertiseViewProps) {
    super(props);

    this.NewInstructorExpertiseStore.createNew({
      eventTypeId: null,
      nmrFunctionalSubAreaId: null,
      instructorId: null,
      roleId: null,
      trainingLevelId: null,
      machineModelId: null,
      equipmentTypeId: null,
      isMachineRelated: this.props.isMachineRelated ? true : false,
      machineRelatedClusterId: null,
      nmrClusterId: null,
      nmrTrainingNameId: null,
      nmrFunctionalAreaId: null,
      oemId: null,
      plcTypes: []
    });

    this.state = {
      instructor: null,
      eventType: null,
      role: null,
      level: null,
      isMachineRelated: this.props.isMachineRelated ? true : false,
      cluster: null,
      equipmentType: null,
      machineModel: null,
      functionalSubArea: null,
      oem: null,
      nmrcluster: null,
      nmrfunctionalarea: null,
      nrmTrainingName: null
    };
  }

  @autobind
  private onCreateNewItem() {
    this.NewInstructorExpertiseStore.submit().then(x => {
      if (this.NewInstructorExpertiseStore.state.result && this.NewInstructorExpertiseStore.state.result.isSuccess) {
        this.props.onClose(true);
      }
    });
  }

  @autobind
  private onCancelNewItem() {
    this.NewInstructorExpertiseStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.NewInstructorExpertiseStore.change({ ...this.NewInstructorExpertiseStore.state.item, ...change });
  }

  private isMachineRelated(mode: boolean) {
    this.NewInstructorExpertiseStore.state.item.isMachineRelated = mode;
    this.setState({
      isMachineRelated: mode
    });
  }

  public render() {
    const { t } = this.props;
    const { instructor, eventType, role, level, isMachineRelated, cluster, equipmentType } = this.state;
    const { machineModel, functionalSubArea, oem, nmrcluster, nmrfunctionalarea, nrmTrainingName } = this.state;
    const { result, item, isBusy } = this.NewInstructorExpertiseStore.state;

    const user = this.identityService.getUserInfo();
    const isPowerInstructor = IdentityService.isPowerInstructor(user);
    const pillar = this.identityService.getCurrentUserDto()?.pillar;
    const pillarIdFilter = isPowerInstructor && pillar ? pillar.id : null;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New Instructor Expertise')}</Modal.Header>
        <Modal.Content style={{ padding: 40 }} image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths={2}>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Instructor')}</label>
                      <UserEditor
                        nullable
                        role={'Instructor'}
                        value={instructor}
                        pillarIdFilter={pillarIdFilter}
                        onChange={c => {
                          item.instructorId = c ? c.id : null;
                          this.setState({ instructor: c });
                        }}
                        activeOnly={true}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Event Type')}</label>
                      <EventTypeEditor
                        nullable
                        value={eventType}
                        onChange={c => {
                          item.eventTypeId = c ? c.id : null;
                          this.setState({ eventType: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={2}>
                  <Form.Field required>
                    <div className={`required field`}>
                      {/* <div className={`field`}> */}
                      <label>{t('Role')}</label>
                      <ProfesionEditor
                        nullable
                        value={role}
                        onChange={c => {
                          item.roleId = c ? c.id : null;
                          this.setState({ role: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                  {isMachineRelated && (
                    <Form.Field required>
                      <div className={`required field`}>
                        {/* <div className={` field`}> */}
                        <label>{t('Training Level')}</label>
                        <TrainingLevelEditor
                          nullable
                          value={level}
                          onChange={c => {
                            item.trainingLevelId = c ? c.id : null;
                            this.setState({ level: c });
                          }}
                        />
                      </div>
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group width={2}></Form.Group>
                {isMachineRelated && (
                  <>
                    <Form.Group widths={4}>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Cluster')}</label>
                          {/* <div className={`field inline`}> */}
                          <MRClusterEditor
                            nullable
                            clearable
                            value={cluster}
                            onChange={c => {
                              item.machineRelatedClusterId = c ? c.id : null;
                              this.setState({ cluster: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Equipment Type')}</label>
                          {/* <div className={`field inline`}> */}
                          <EquipmentTypeEditor
                            nullable
                            clearable
                            clusterId={cluster ? cluster.id : undefined}
                            value={equipmentType}
                            onChange={c => {
                              item.equipmentTypeId = c ? c.id : null;
                              this.setState({ equipmentType: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('OEM')}</label>
                          {/* <div className={`field inline`}> */}
                          <OemEditor
                            nullable
                            clearable
                            value={oem}
                            onChange={c => {
                              item.oemId = c ? c.id : null;
                              this.setState({ oem: c });
                            }}
                            equipmentId={equipmentType != null ? equipmentType.id : undefined}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Machine Model')}</label>
                          {/* <div className={`field inline`}> */}
                          <MachineMOdelEditor
                            nullable
                            clearable
                            value={machineModel}
                            equipmentId={equipmentType ? equipmentType.id : undefined}
                            oemId={oem ? oem.id : undefined}
                            onChange={c => {
                              item.machineModelId = c ? c.id : null;
                              this.setState({ machineModel: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths={4}>
                      <Form.Field>
                        <div className={`field`}>
                          <label>{t('PLC Types')}</label>
                          <MultiPlcTypeEditor
                            className="new-instructor-height-plc"
                            placeholder=" "
                            value={item.plcTypes}
                            oemId={item.oemId}
                            equipmentId={item.equipmentTypeId}
                            onChange={c => this.handleValue(nameof<CreateInstructorExpertiseDto>('plcTypes'), c)}
                            multiple
                          />
                        </div>
                      </Form.Field>
                    </Form.Group>
                  </>
                )}

                {!isMachineRelated && (
                  <>
                    <Form.Group widths={4}>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Cluster')}</label>
                          {/* <div className={`field inline`}> */}
                          <ClusterEditor
                            nullable
                            value={nmrcluster}
                            onChange={c => {
                              item.nmrClusterId = c ? c.id : null;
                              this.setState({ nmrcluster: c, nmrfunctionalarea: null });
                              item.nmrFunctionalAreaId = null;
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Functional Area')}</label>
                          {/* <div className={`field inline`}> */}
                          <FunctionalAreaEditor
                            nullable
                            clusterId={nmrcluster ? nmrcluster.id : null}
                            value={nmrfunctionalarea}
                            onChange={c => {
                              item.nmrFunctionalAreaId = c ? c.id : null;
                              this.setState({ nmrfunctionalarea: c, nrmTrainingName: null });
                              item.nmrTrainingNameId = null;
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Training Name')}</label>
                          {/* <div className={`field inline`}> */}
                          <TrainingNameEditor
                            nullable
                            funcionalAreaId={nmrfunctionalarea ? nmrfunctionalarea.id : null}
                            value={nrmTrainingName}
                            onChange={c => {
                              item.nmrTrainingNameId = c ? c.id : null;
                              this.setState({ nrmTrainingName: c, functionalSubArea: null });
                              item.nmrFunctionalSubAreaId = null;
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={`required field inline`}>
                          <label>{t('Functional Subarea')}</label>
                          {/* <div className={`field inline`}> */}
                          <FunctionalSubAreaEditor
                            nullable
                            trainingNameId={nrmTrainingName ? nrmTrainingName.id : null}
                            value={functionalSubArea}
                            onChange={c => {
                              item.nmrFunctionalSubAreaId = c ? c.id : null;
                              this.setState({ functionalSubArea: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                    </Form.Group>
                  </>
                )}
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(newInstructorExpertiseView);
