import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Divider, Checkbox, Button, TextArea, Accordion } from 'semantic-ui-react';
import './tna-edit-form-footer-styles.less';
import { ChangeTnaFormAnswerDto, InternalTrainerRecommendationDto, TnaFormAnswersDto } from 'stores/assessments/forms/tna-forms-store';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import { TNAFormContext } from './tna-edit-form';
import { useToggle } from 'utils/react-custom-hooks';

const TNAFormFooterComponent: React.FC<WithTranslation> = ({ t }) => {
  const { active, toggle } = useToggle(true);

  const {
    onChange,
    onSave,
    submittable,
    onSend,
    onSendStatusBack,
    getAssessorType,
    getAStatus,
    getAnwersStatus,
    isAdminOrPoC,
    isPoC,
    isDisabledButton,
    isAdmin,
    isOnlyAdmin,
    bypassEmployeeEvaluation
  } = React.useContext(TNAFormContext);

  const { type } = getAssessorType();

  const { answerStatus } = getAStatus() || {};

  const status = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => summary.item.status);

  const changeanswerItem = useSelector<ApplicationState>(
    ({ tnaformlist: { changeanswer } }) => changeanswer.item
  ) as ChangeTnaFormAnswerDto;

  const answerItem = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => summary.item.answers) as TnaFormAnswersDto;

  const considerForFactoryInteralTrainer = isAdminOrPoC
    ? answerItem.considerForFactoryInteralTrainer
    : changeanswerItem.considerForFactoryInteralTrainer;

  const considerForGSCInteralTrainer = isAdminOrPoC
    ? answerItem.considerForGSCInteralTrainer
    : changeanswerItem.considerForGSCInteralTrainer;

  const lmRecommendation = isAdminOrPoC
    ? answerItem.recommendationOfManagerForInternalTrainer
    : changeanswerItem.recommendationOfManagerForInternalTrainer;

  const feRecommendation = isAdminOrPoC
    ? answerItem.recommendationOfExpertForInternalTrainer
    : changeanswerItem.recommendationOfExpertForInternalTrainer;

  const isDone = status === 'Done';
  const inAssessorsStatus = status === 'Assessors';

  const nonAssessor = type === 'nonAssessor';

  const canSeeCheckBox = nonAssessor || (isAdminOrPoC && isDone);

  const isExpert = type === 'functionalExpert';

  const isManager = type === 'lineManager';

  const assessorPending = ['Pending', 'PendingRecommendation'].includes(answerStatus);

  const employeeEditable = !isAdminOrPoC && nonAssessor && !isDone;

  const assessorEditable = !isAdminOrPoC && !isDone && assessorPending;

  // const adminEditable = isOnlyAdmin && isDone && (answerItem.machineModelAnswers || []).length > 0;

  const expertEditable = assessorEditable && isExpert;

  const managerEditable = assessorEditable && isManager;

  const feChangeHandler = (property: keyof InternalTrainerRecommendationDto, value: any) => {
    const changes: InternalTrainerRecommendationDto = { ...feRecommendation, [property]: value };
    onChange('recommendationOfExpertForInternalTrainer', changes);
  };

  const lmChangeHandler = (property: keyof InternalTrainerRecommendationDto, value: any) => {
    const changes: InternalTrainerRecommendationDto = { ...lmRecommendation, [property]: value };
    onChange('recommendationOfManagerForInternalTrainer', changes);
  };

  return (
    <footer className="form__all-wrapper__footer tna-footer">
      <Divider />
      <Accordion className="tna-footer-accordion">
        <Accordion.Title active={active} onClick={toggle} className="tna-footer-accordion__title" />
        <Accordion.Content active={active}>
          {canSeeCheckBox ? <p>{t('Do you want to be consider as Internal Trainer?')}</p> : <p></p>}

          <div className="flex-between">
            <div className="tna-edit-form__footer-considerations">
              <div className="tna-edit-form__footer__check">
                <Checkbox
                  disabled={!employeeEditable}
                  checked={considerForFactoryInteralTrainer}
                  onChange={(_, { checked }) => onChange('considerForFactoryInteralTrainer', checked)}
                />
                <div className="table__tooltip">
                  <p className="shortText">{t('Please, consider me for Factory Internal Trainer role')}</p>
                  <div className="table__tooltip-top">
                    {t(
                      'Factory Internal Trainer is a factory employee who provides technical training and mentoring to colleagues on the shopfloor'
                    )}
                  </div>
                </div>
              </div>
              <div className="tna-edit-form__footer__check">
                <Checkbox
                  disabled={!employeeEditable}
                  checked={considerForGSCInteralTrainer}
                  onChange={(_, { checked }) => onChange('considerForGSCInteralTrainer', checked)}
                />

                <div className="table__tooltip">
                  <p className="shortText">{t('Please, consider me for GSC Internal Trainer role')}</p>
                  <div className="table__tooltip-top">
                    {t(`GSC Internal Trainer is a factory employee who provides technical training or technical support (to own factory as
                      well as to other JTI GSC factories)`)}
                  </div>
                </div>
              </div>
              <div />

              {(isExpert || isDone || isAdminOrPoC) && (
                <>
                  <div className="tna-edit-form__footer__check">
                    <Checkbox
                      disabled={!expertEditable}
                      checked={feRecommendation?.forFactoryInteralTrainer}
                      onChange={(_, { checked }) => feChangeHandler('forFactoryInteralTrainer', checked)}
                    />
                    <p className="shortText">{t('Recommendation of Functional expert for Factory Internal Trainer role.')}</p>
                  </div>
                  <div className="tna-edit-form__footer__check">
                    <Checkbox
                      disabled={!expertEditable}
                      checked={feRecommendation?.forGSCInteralTrainer}
                      onChange={(_, { checked }) => feChangeHandler('forGSCInteralTrainer', checked)}
                    />
                    <p className="shortText">{t('Recommendation of Functional expert for GSC Internal Trainer role.')}</p>
                  </div>
                  <div className="tna-edit-form__footer__text-area">
                    <p>{t('Please comment if "NO"')}</p>
                    <TextArea
                      disabled={!expertEditable}
                      value={feRecommendation?.comment}
                      onChange={(_, { value }) => feChangeHandler('comment', value)}
                    />
                  </div>
                </>
              )}

              {(isManager || isDone || isAdminOrPoC) && (
                <>
                  <div className="tna-edit-form__footer__check">
                    <Checkbox
                      disabled={!managerEditable}
                      checked={lmRecommendation?.forFactoryInteralTrainer}
                      onChange={(_, { checked }) => lmChangeHandler('forFactoryInteralTrainer', checked)}
                    />
                    <p className="shortText">{t('Approval of Line manager for Factory Internal Trainer role.')}</p>
                  </div>
                  <div className="tna-edit-form__footer__check">
                    <Checkbox
                      disabled={!managerEditable}
                      checked={lmRecommendation?.forGSCInteralTrainer}
                      onChange={(_, { checked }) => lmChangeHandler('forGSCInteralTrainer', checked)}
                    />
                    <p className="shortText">{t('Approval of Line manager for GSC Internal Trainer role.')}</p>
                  </div>
                  <div className="tna-edit-form__footer__text-area">
                    <p>{t('Please comment if "NO"')}</p>
                    <TextArea
                      disabled={!managerEditable}
                      value={lmRecommendation?.comment}
                      onChange={(_, { value }) => lmChangeHandler('comment', value)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      <div className="tna-footer-action-buttons" style={{ marginTop: '5px' }}>
        {isAdmin && (
          <div className="status-buttons">
            {!bypassEmployeeEvaluation && (status === 'Assessors' || isDone) && (
              <Button className="inverted-color-btn" onClick={() => onSendStatusBack('Employee')} positive>
                {t('Set Status Employee')}
              </Button>
            )}
            {(isDone || inAssessorsStatus) && (getAnwersStatus() || []).any(x => x.answerStatus !== 'Pending') && (
              <Button className="inverted-color-btn" onClick={() => onSendStatusBack('Assessors')} positive>
                {t('Set Status Assessors')}
              </Button>
            )}
          </div>
        )}
        {(assessorEditable || employeeEditable || (isOnlyAdmin && !isManager)) /*adminEditable*/ && (
          <div className="save-buttons">
            {/*!isAdminOrPoC*/ ((isOnlyAdmin && !isManager) || !isPoC) && (
              <Button disabled={isDisabledButton} className="white-save-btn" onClick={onSave} positive>
                {t('Save')}
              </Button>
            )}
            {!isAdminOrPoC && submittable() && (
              <Button disabled={isDisabledButton} className="inverted-color-btn" onClick={onSend} positive>
                {t('Send TNA')}
              </Button>
            )}
          </div>
        )}
      </div>
    </footer>
  );
};
export default withTranslation()(React.memo(TNAFormFooterComponent));
