import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import LanguageEditor from 'widgets/bussiness/language-editor';
import { PracticalFormView } from 'stores/assessments/forms/practical-form-store';

interface HeaderProps extends WithTranslation, PracticalFormView {
  onChange: (property: keyof PracticalFormView, value: any) => void;
}

const PracticalFormHeader: FC<HeaderProps> = props => {
  const { t, onChange, user, eventTitle, role } = props;
  const { currentLanguage, trainingLevel } = props;
  return (
    <div className="edit-form__header__data-language flex-between">
      <div className="edit-form__header__general-info">
        <div className="edit-form__header__general-info__element">
          <h5>{t('User')}</h5>
          <p>{user}</p>
        </div>
        <div className="edit-form__header__general-info__element">
          <h5>{t('Role')}</h5>
          <p>{role}</p>
        </div>

        <div className="edit-form__header__general-info__element">
          <h5>{t('Training Level')}</h5>
          <p>{trainingLevel}</p>
        </div>
        <div className="edit-form__header__general-info__element">
          <h5>{t('Event')}</h5>
          <p>{eventTitle}</p>
        </div>
      </div>

      <LanguageEditor
        className="planit-user-dropdown-custom"
        placeholder={t('Select Language')}
        clearable
        nullable
        onlyForTests
        value={currentLanguage}
        onChange={value => onChange('currentLanguage', value?.id ?? null)}
      />
    </div>
  );
};

export default withTranslation()(PracticalFormHeader);
