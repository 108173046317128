import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { BaseCommandResult, CommandResult } from 'stores/types';
import { EventStatus } from './events-store';
import { RelatedToType } from 'stores/assessments/templates/theoretical-templates-store';
import { SimpleUserDto } from 'stores/users/users-store';

export interface EventTheoreticalTemplateDto {
  id: string;
  title: string;
  generatedState: EventStatus;
  hoursDuration: string;
  theoreticalFormAnswers: SimpleTheoreticalFormAnswerDto[];
}

export interface SimpleTheoreticalFormAnswerDto {
  id: string;
  answered: boolean;
  userId: string;
  user: SimpleUserDto;
  averageTime?: string;
  totalTime?: string;
  result?: number;
  totalQuestions?: number;
  questionsAnswered?: number;
  startedAt?: string;
}

export interface TheoreticalFormDto {
  id: string;
  eventId: string;
  eventTitle: string;
  theoreticalTemplateId: string;
  theoreticalTemplateName: string;
  userAnswers: SimpleTheoreticalFormAnswerDto[];
}

export interface ChangeTheoreticalFormDto {
  id: string;
  eventId: string;
  theoreticalTemplateId: string;
  usersId: string[];
}

export interface CreateTheoreticalFormDto {
  templateId: string;
  typeRelated: RelatedToType;
  tnaDetails?: CreateTnaTheoreticalFormDetailsDto;
  eventDetails: CreateTheoreticalFormEventDetailsDto;
}

export interface CreateTnaTheoreticalFormDetailsDto {
  title: string;
  profileId: string;
  deadline: string;
  users: string[];
}

export interface CreateTheoreticalFormEventDetailsDto {
  eventId: string;
}

export class CreateTheoreticalFormValidator extends AbstractValidator<CreateTheoreticalFormDto> {
  constructor() {
    super();
    this.validateIfString(o => o.eventDetails.eventId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Id is required'));

    this.validateIfString(o => o.templateId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Theoretical Template Id is required'));
  }
}

export const toCreateTheoreticalItemDto = ({ id }: EventTheoreticalTemplateDto, eventId: string): CreateTheoreticalFormDto => ({
  templateId: id,
  typeRelated: 'Event',
  eventDetails: { eventId: eventId }
});

@repository('@@FORMTHEORETICALLIST', 'formtheoreticallist.summary')
export class FormTheoreticalListStore extends DataStore<EventTheoreticalTemplateDto> {
  RETRIEVE_ONE_THEORETICAL_FORM = 'RETRIEVE_ONE_THEORETICAL_FORM';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-form';
  retrievePath = 'get-theoretical-forms';
  updatePath = '';
  deletePath = 'delete-theoretical-form';
  retrieveOnePath = 'get-theoretical-form';
  retriveTemplatesByEventPath = 'get-theoretical-templates';
  resendPath = 'resend-theoretical-form';

  protected validate(item: EventTheoreticalTemplateDto): ValidationResult {
    const createTheoreticalItemDto = toCreateTheoreticalItemDto(this.state.item, this.state.item.id);
    return new CreateTheoreticalFormValidator().validate(createTheoreticalItemDto);
  }

  public async getTemplatesByEventId(id: string, eventStatus, complete: boolean = true): Promise<any> {
    const httpService = container.get(HttpService);
    const complParam = complete ? '' : `&complete=${complete}`;
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<EventTheoreticalTemplateDto>>(
        `${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}?eventStatus=${eventStatus}${complParam}`
      )
    );
    return result;
  }

  public async getFormById(id: string): Promise<TheoreticalFormDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_THEORETICAL_FORM,
      httpService.get<TheoreticalFormDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async resendTheoreticalFormById(id: string): Promise<any> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_THEORETICAL_FORM,
      httpService.put<{}, BaseCommandResult>(`${this.baseUrl}/${this.resendPath}/${id}`, {})
    );
    return result.data;
  }

  constructor() {
    super('FORMTHEORETICALLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMTHEORETICALLIST', 'formtheoreticallist.event-tab-summary')
export class EventTheoreticalTabStore extends DataStore<EventTheoreticalTemplateDto> {
  RETRIEVE_ONE_THEORETICAL_FORM = 'RETRIEVE_ONE_THEORETICAL_FORM';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-form';
  retrievePath = 'get-theoretical-forms';
  updatePath = '';
  deletePath = 'delete-theoretical-form';
  retrieveOnePath = 'get-theoretical-form';
  retriveTemplatesByEventPath = 'get-theoretical-templates';
  resendPath = 'resend-theoretical-form';
  lastParamQuery: string = null;

  protected validate(item: EventTheoreticalTemplateDto): ValidationResult {
    const createTheoreticalItemDto = toCreateTheoreticalItemDto(this.state.item, this.state.item.id);
    return new CreateTheoreticalFormValidator().validate(createTheoreticalItemDto);
  }

  public async getTemplatesByEventId(id: string, eventStatus, complete: boolean = true): Promise<any> {
    let paramsString = `${id}_${eventStatus}_${complete}`;

    if (this.state.items.length <= 0 || this.lastParamQuery !== paramsString) {
      const httpService = container.get(HttpService);
      const complParam = complete ? '' : `&complete=${complete}`;
      const result = await this.dispatchAsync(
        this.ENTITY_LIST_UPDATE,
        httpService.get<QueryResult<EventTheoreticalTemplateDto>>(
          `${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}?eventStatus=${eventStatus}${complParam}`
        )
      );
      this.lastParamQuery = paramsString;

      return result;
    }
  }

  public async resendTheoreticalFormById(id: string): Promise<any> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_THEORETICAL_FORM,
      httpService.put<{}, BaseCommandResult>(`${this.baseUrl}/${this.resendPath}/${id}`, {})
    );
    return result.data;
  }

  constructor() {
    super('EVENTTHEORETICALTAB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMTHEORETICALLIST', 'formtheoreticallist.new')
export class NewTheoreticalFormUserStore extends FormStore<CreateTheoreticalFormDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-theoretical-form';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateTheoreticalFormDto) {
    return new CreateTheoreticalFormValidator().validate(item);
  }

  public createForms = async () => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateTheoreticalFormDto, CommandResult<CreateTheoreticalFormDto>>(
        `${this.baseUrl}/${this.createPath}`,
        this.state.item
      )
    );
    return result.data;
  };

  constructor() {
    super('NEW_FORMTHEORETICALLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
