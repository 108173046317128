import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from 'stores/dataStore';
import {
  NMFunctionalAreaDto,
  NMFunctionalAreasStore
} from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { DropdownProps, SelectionInput, SelectionItem } from 'widgets/form/selectionInput';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface NMFunctionalAreaEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  functionalAreas?: NMFunctionalAreasStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  clearOnReload?: boolean;
  clusterId?: string;
  className?: string;
  direction?: 'left' | 'right';
  loadDataOnOpen?: boolean;
}

interface NMFunctionalAreaEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['functionalAreas', NMFunctionalAreasStore])
class NMFunctionalAreaEditor extends React.Component<NMFunctionalAreaEditorProps, NMFunctionalAreaEditorState> {
  private get functionalAreasStore() {
    return this.props.functionalAreas;
  }

  constructor(props: NMFunctionalAreaEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getNMFunctionalAreaMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  componentDidUpdate({ value: newValue, clusterId: newCluster }: NMFunctionalAreaEditorProps) {
    const { value, clusterId } = this.props;
    let newChanges: any = {};
    if (newCluster !== clusterId) newChanges.query = this.getNMFunctionalAreaMethod();
    if (value?.id !== newValue?.id) {
      newChanges.value = value;
      newChanges.options = getOptionsFromValue(newChanges.query, value);
    }
    Object.keys(newChanges).length > 0 && this.setState(newChanges);
  }

  @autobind
  private getNMFunctionalAreaMethod() {
    const method = async (search: string) => {
      const filters = isNullOrWhiteSpaces(search)
        ? []
        : [`startswith(tolower(${nameof<NMFunctionalAreaDto>('name')}), '${search.toLowerCase()}')`];
      if (this.props.clusterId) {
        filters.push(`${nameof<NMFunctionalAreaDto>('clusterId')} eq ${this.props.clusterId}`);
      }
      const result = await this.functionalAreasStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<NMFunctionalAreaDto>('name'), useProfile: false }],
        filter: filters
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getNMFunctionalAreaMethod() });
    }
  };

  public render() {
    const val = this.state.value;
    const options = this.state.options;
    const query = this.state.query;
    const { className, clearable, nullable, direction, minWidth, clearOnReload, placeholder, readonly, onChange } = this.props;
    return (
      <SelectionInput
        direction={direction}
        content={item => <div>{item.title}</div>}
        searchable
        minWidth={minWidth}
        nullable={nullable}
        placeholder={placeholder}
        query={query}
        value={val}
        onChange={value => onChange(value as any)}
        readOnly={readonly}
        clearable={clearable}
        clearOnReload={clearOnReload}
        className={className}
        onOpenDropDown={this.onOpenDropDown}
        options={this.state.query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NMFunctionalAreaEditor);
