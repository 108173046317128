import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NMFunctionalSubareaDto,
  ChangeNMFunctionalSubareaDto,
  ChangeNMFunctionalSubareaStore
} from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { ItemReference } from 'stores/dataStore';
import { formStoreHandler, isNullOrWhiteSpaces } from 'utils/useful-functions';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Categories } from 'stores/requests/requests-store';

interface ChangeNMFunctionalSubareaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeNMFunctionalSubarea?: ChangeNMFunctionalSubareaStore;
  currentDto: NMFunctionalSubareaDto;
}

interface ChangeNMFunctionalSubareaViewState {
  trainingName: ItemReference;
  functionalArea: ItemReference;
  cluster: ItemReference;
  category: Categories;
}

@connect(['changeNMFunctionalSubarea', ChangeNMFunctionalSubareaStore])
class ChangeNMFunctionalSubareaView extends Component<ChangeNMFunctionalSubareaViewProps, ChangeNMFunctionalSubareaViewState> {
  constructor(props: ChangeNMFunctionalSubareaViewProps) {
    super(props);

    const { currentDto, changeNMFunctionalSubarea } = this.props;
    const { functionalAreaName, trainingNameName, functionalAreaId, trainingNameId, clusterName, ...rest } = currentDto;
    const { clusterId, category, name, id } = rest;
    changeNMFunctionalSubarea.state.result = null;

    changeNMFunctionalSubarea.change({ id, name, trainingNameId });
    const trainingName = { id: trainingNameId, title: trainingNameName };
    const functionalArea = { id: functionalAreaId, title: functionalAreaName };
    const cluster = { id: clusterId, title: clusterName };
    this.state = { category: category === 'Unknown' ? Categories.Technical : Categories[category], trainingName, functionalArea, cluster };
  }

  private onChangeItem = async () => {
    const result = await this.props.changeNMFunctionalSubarea.update();
    result?.isSuccess && this.props.onClose(true);
  };

  private onCancelChangeItem = () => {
    this.props.changeNMFunctionalSubarea.clear();
    this.props.onClose(false);
  };

  private onChangeCluster = (cluster: ItemReference) =>
    this.setState({ cluster, functionalArea: null, trainingName: null }, () =>
      this.props.changeNMFunctionalSubarea.change({ trainingNameId: null })
    );

  private onChangeFunctionalArea = (functionalArea: ItemReference) =>
    this.setState({ functionalArea, trainingName: null }, () => this.props.changeNMFunctionalSubarea.change({ trainingNameId: null }));

  categoryChangeHandler = (category: Categories) => this.setState({ category, cluster: null, functionalArea: null, trainingName: null });

  public render() {
    const { t, changeNMFunctionalSubarea } = this.props;
    const { category, cluster, functionalArea, trainingName } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.props.changeNMFunctionalSubarea.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Functional Subarea')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {changeNMFunctionalSubarea.state.result && !changeNMFunctionalSubarea.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={changeNMFunctionalSubarea.state.result.messages.map(o => o.body)}
              />
            )}
            {changeNMFunctionalSubarea.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!changeNMFunctionalSubarea.state.item.name}
                    label={t('Functional Subarea')}
                    placeholder={t('Name')}
                    value={changeNMFunctionalSubarea.state.item.name}
                    onChange={(_, { value }) => formStoreHandler<ChangeNMFunctionalSubareaDto>(changeNMFunctionalSubarea, 'name', value)}
                  />
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        category={category}
                        placeholder={t('Cluster')}
                        nullable
                        value={cluster}
                        onChange={this.onChangeCluster}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Functional Area')}</label>
                      <NMFunctionalAreaEditor
                        placeholder={t('Functional Area')}
                        nullable
                        readonly={!cluster}
                        clusterId={cluster ? cluster.id : undefined}
                        value={functionalArea}
                        onChange={this.onChangeFunctionalArea}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div
                      className={`required ${
                        changeNMFunctionalSubarea.state.item.trainingNameId == null ||
                        isNullOrWhiteSpaces(changeNMFunctionalSubarea.state.item.trainingNameId)
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Training Name')}</label>
                      <NMTrainingNameEditor
                        nullable
                        value={trainingName}
                        readOnly={!functionalArea}
                        funcionalAreaId={functionalArea?.id}
                        onChange={c => formStoreHandler<ChangeNMFunctionalSubareaDto>(changeNMFunctionalSubarea, 'trainingNameId', c?.id)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <div className="custom-actions">
          <Form.Field>
            <label>{t('Category')}</label>
            <CategoryFormEditor
              placeholder={t('Filter by Category')}
              className="form__header__dropdown-style"
              value={category}
              onChange={this.categoryChangeHandler}
            />
          </Form.Field>
          <Modal.Actions>
            <Button basic onClick={this.onCancelChangeItem}>
              {t('Cancel')}
            </Button>
            <Button onClick={this.onChangeItem} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeNMFunctionalSubareaView);
