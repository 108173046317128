import React, { Component, SyntheticEvent } from 'react';
import { DateInput as SemanticDateInput, DateInputProps } from '@planit/semantic-ui-calendar-react';
import { Icon } from 'semantic-ui-react';
import './dateInput.less';
import { DateTimeService } from 'services/datetime-service';
import 'moment/locale/en-gb';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface CustomDateInputProps extends DateInputProps {
  value: string;
  initialValue?: string;
  localeDate?: boolean;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  clearable?: boolean;
  forceOpen?: boolean;
  id?: string;
  required?: boolean;
  minDate?: string;
  maxDate?: string;
  iconPosition?: 'left' | 'right';
  icon?: SemanticICONS | false;
  onlySelectableFromCalendar?: boolean;
  propagateOnInvalid?: boolean;
}

interface CustomDateInputState {
  value: string;
}

export class DateInput extends Component<CustomDateInputProps, CustomDateInputState> {
  dateElement: SemanticDateInput;
  state: CustomDateInputState = { value: this.props.value };

  shouldComponentUpdate(nextProps: CustomDateInputProps, nextState: CustomDateInputState) {
    const should =
      this.props.value !== nextProps.value ||
      this.state.value !== nextState.value ||
      this.props.forceOpen !== nextProps.forceOpen ||
      this.props.readOnly !== nextProps.readOnly ||
      this.props.minDate !== nextProps.minDate ||
      this.props.maxDate !== nextProps.maxDate ||
      this.props.initialValue !== nextProps.initialValue ||
      this.state.value !== nextState.value;
    return should;
  }

  componentDidUpdate(prevProps: CustomDateInputProps) {
    const { forceOpen, value, id } = this.props;
    if (forceOpen !== prevProps.forceOpen && forceOpen)
      if (!!this.dateElement && this.dateElement.openPopup) {
        if (id) {
          const el = document.getElementById(id);
          el?.focus && el.focus();
          el?.click && el.click();
          this.dateElement.openPopup();
        }
      } else this.dateElement.closePopup();
    const changes: CustomDateInputState = { value: this.state.value };
    if (changes.value === value) return;
    if (value == null) changes.value = '';
    else if (value !== changes.value) changes.value = value;
    this.setState({ value: changes.value });
  }

  handleValue = (e: SyntheticEvent<HTMLElement, Event>, { value }: any) => {
    const { onChange, onlySelectableFromCalendar } = this.props;
    let date = value.replace(DateTimeService.simpleDateInputRegex, '');
    if (onlySelectableFromCalendar || DateTimeService.isCoherent(value) || !value) {
      date = DateTimeService.toString(value, DateTimeService.dateInputFormat);
      this.dateElement.closePopup();
      if (onChange) onChange(e, date);
    }
    if (this.props.propagateOnInvalid) if (onChange) onChange(e, date);
    this.setState({ value: date }, () => this.dateElement.closePopup());
  };

  getProperValue = (value: string) => {
    const { minDate, onlySelectableFromCalendar, initialValue } = this.props;
    const isCoherent = DateTimeService.isCoherent(value);
    if (value) {
      const isGEInitialValue = initialValue && DateTimeService.toMoment(value).isSameOrAfter(initialValue, 'D');
      const isGreaterThanMin = minDate && DateTimeService.isGreaterThan(value, minDate);
      if (onlySelectableFromCalendar === false && !isCoherent) return value;
      if (minDate && !isGreaterThanMin) return DateTimeService.toDateInputString(minDate);
      if ((!minDate && !initialValue) || isGreaterThanMin || isGEInitialValue) return DateTimeService.toDateInputString(value);
    }
    return '';
  };

  public render() {
    const { minDate, icon, initialValue, maxDate, iconPosition, placeholder } = this.props;
    const { className, clearable, readOnly, required, id, enable, disable } = this.props;
    let val = this.getProperValue(this.state.value);

    const minDateProps = minDate && DateTimeService.toDateInputString(minDate);
    const maxDateProps = maxDate && DateTimeService.toDateInputString(maxDate);
    const iconPos = iconPosition || 'left';
    const initValue = initialValue ? DateTimeService.toDateInputString(initialValue) : '';
    const defaultIcon = icon !== false ? icon : 'calendar';

    return (
      <SemanticDateInput
        enable={enable}
        disable={disable}
        ref={input => (this.dateElement = input)}
        minDate={minDateProps}
        maxDate={maxDateProps}
        placeholder={placeholder}
        className={className}
        animation={null}
        popupPosition="bottom left"
        name="date"
        closeOnMouseLeave={false}
        closable={true}
        clearIcon={<Icon name="remove" color="red" />}
        clearable={clearable}
        duration={200}
        hideMobileKeyboard
        iconPosition={iconPos}
        icon={defaultIcon}
        preserveViewMode={false}
        autoComplete="off"
        readOnly={readOnly}
        dateFormat={DateTimeService.dateInputFormat}
        value={val}
        popupNodeClass={'jti-popupNode'}
        id={id}
        onChange={this.handleValue}
        required={required}
        localization={'en-gb'}
        initialDate={initValue}
      />
    );
  }
}
