import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { Categories } from 'stores/requests/requests-store';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface NMClusterDto extends BaseDto {
  id: string;
  name: string;
  category: Category;
}

export type Category = 'Unknown' | 'Technical' | 'Functional';

export interface CreateNMClusterDto {
  name: string;
  category: Category;
}

export interface ChangeNMClusterDto {
  id: string;
  name: string;
  category: Category;
}

export class CreateNMClusterValidator extends AbstractValidator<CreateNMClusterDto> {
  constructor() {
    super();

    this.validateIf(o => Categories[o.category])
      .isIn([Categories.Functional, Categories.Technical])
      .withFailureMessage(i18n.t(`Category is required`));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster name is required'));
  }
}

export class ChangeNMClusterValidator extends AbstractValidator<ChangeNMClusterDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster Id is required'));

    this.validateIf(o => Categories[o.category])
      .isIn([Categories.Functional, Categories.Technical])
      .withFailureMessage(i18n.t(`Category is required`));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster name is required'));
  }
}

@repository('@@NMCLUSTERS', 'nmClusters.summary')
export class NMClustersStore extends DataStore<NMClusterDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCluster';
  retrievePath = 'get-nmClusters';
  updatePath = 'update-nmCluster';
  deletePath = 'delete-nmCluster';

  protected validate(item: NMClusterDto) {
    return new ChangeNMClusterValidator().validate(item);
  }

  constructor() {
    super('NMCLUSTER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMCLUSTERS', 'nmClusters.dropdown')
export class DropDownNMClustersStore extends DataStore<NMClusterDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-nmClusters';
  updatePath = '';
  deletePath = '';

  protected validate(item: NMClusterDto) {
    return new ChangeNMClusterValidator().validate(item);
  }

  constructor() {
    super('NMCLUSTER_MODELDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, nmClusterIds?: string[], search?: string): Promise<QueryResult<NMClusterDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let nmClustersDto = this.state.items.map(({ item }) => item);

      if ((nmClusterIds || []).length > 0) {
        nmClustersDto = nmClustersDto.filter(x => nmClusterIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        nmClustersDto = nmClustersDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: nmClustersDto, count: nmClustersDto.length } as QueryResult<NMClusterDto>;
      return new Promise<QueryResult<NMClusterDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<NMClusterDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<NMClusterDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@NMCLUSTERS', 'nmClusters.new')
export class NewNMClusterStore extends FormStore<CreateNMClusterDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCluster';
  retrievePath = 'get-nmClusters';
  updatePath = 'update-nmCluster';

  protected validate(item: CreateNMClusterDto) {
    return new CreateNMClusterValidator().validate(item);
  }

  constructor() {
    super('NEW_NMCLUSTER', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMCLUSTERS', 'nmClusters.change')
export class ChangeNMClusterStore extends FormStore<ChangeNMClusterDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmCluster';
  retrievePath = 'get-nmClusters';
  updatePath = 'update-nmCluster';

  protected validate(item: ChangeNMClusterDto) {
    return new ChangeNMClusterValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMCLUSTER', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
