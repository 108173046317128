import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Checkbox, Message, Table, Icon, Grid } from 'semantic-ui-react';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

import { PatternDto, PatternsStore, ChangePatternStore } from '../../../../../stores/configuration/machinery/patterns-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import NewPatternView from './new-patern';
import ChangePatternView from './edit-patern';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

export interface PatternsListProps extends WithTranslation, RouteComponentProps {
  patterns: PatternsStore;
  readOnly: boolean;
  changePatternStore?: ChangePatternStore;
}

export interface PatternsListState {
  query: Query;
  newPatternShown: boolean;
  changePatternShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['patterns', PatternsStore], ['changePatternStore', ChangePatternStore])
class PatternsListPage extends React.Component<PatternsListProps, PatternsListState> {
  handleOnEnterKeydown = item => {
    const { readOnly } = this.props;
    !readOnly && this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<PatternDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newPatternShown: false,
      changePatternShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.patterns.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: PatternDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.patterns.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: PatternDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.patterns.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newPatternShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newPatternShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changePatternShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changePatternShown: false });
    if (isSuccess) this.load();
  }

  handleToggleAvailability = async (data: PatternDto) => {
    if (!data) return;
    const { id, name, patternRows, active } = data;
    this.props.changePatternStore.change({ id, name, patternRows, active: !active });
    await this.props.changePatternStore.update();
    this.load();
  };

  onDismiss = () => {
    const { patterns, changePatternStore } = this.props;
    patterns.clearMessages();
    changePatternStore.clearMessages();
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, readOnly, patterns, changePatternStore } = this.props;
    const { activeFilters, newPatternShown, changePatternShown, selectedItem, someFilterOpened } = this.state;

    let errMsgs = (patterns.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changePatternStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Pattern'),
          tooltipRenderer: true,
          renderer: ({ name }) => <span>{name}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Pattern')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<PatternDto>('name'), `contains(${nameof<PatternDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<PatternDto>('name'))}
              active={activeFilters.includes(nameof<PatternDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PatternDto>('name'),
            useProfile: false,
            active: 'Ascending'
          }
        },
        { title: t('Cluster'), tooltipRenderer: true, renderer: data => <span></span> },
        { title: t('Equipment Type'), tooltipRenderer: true, renderer: data => <span></span> },
        { title: t('OEM'), tooltipRenderer: true, renderer: data => <span></span> },
        { title: t('Machine Model'), tooltipRenderer: true, renderer: data => <span></span> },
        {
          title: t('Active'),
          renderer: data => <Checkbox toggle checked={data.active} onChange={() => this.handleToggleAvailability(data)} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Active/Inactive')}
              trueLabel={t('Active')}
              falseLabel={t('Inactive')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<PatternDto>('active'), { active: value })}
              onClear={() => onClear(nameof<PatternDto>('active'))}
              active={activeFilters.includes(nameof<PatternDto>('active'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        }
      ],
      data: patterns.state,
      showExpander: (item: PatternDto) => (item?.patternRows || []).length !== 0,
      expandedRenderer: (item: PatternDto) => (
        <>
          <Table.Row className={'custom-table-expanded-row'}>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              {item.patternRows.map((row, index) => (
                <TableTooltipCell
                  key={row?.machineModelId + row?.machineRelatedClusterName + index}
                  textToShow={row?.machineRelatedClusterName || '-'}
                />
              ))}
            </Table.Cell>
            <Table.Cell>
              {item.patternRows.map((row, index) => (
                <TableTooltipCell key={row?.machineModelId + row?.equipmentTypeName + index} textToShow={row?.equipmentTypeName || '-'} />
              ))}
            </Table.Cell>
            <Table.Cell>
              {item.patternRows.map((row, index) => (
                <TableTooltipCell key={row?.machineModelId + row?.oemName + index} textToShow={row?.oemName || '-'} />
              ))}
            </Table.Cell>
            <Table.Cell>
              {item.patternRows.map((row, index) => (
                <TableTooltipCell key={row?.machineModelId + row?.machineModelName + index} textToShow={row?.machineModelName || '-'} />
              ))}
            </Table.Cell>
          </Table.Row>
          <br />
        </>
      )
    } as TableModel<PatternDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {readOnly && <h2>{t('Patterns')}</h2>}

          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!newPatternShown && !changePatternShown && !readOnly}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              customClassName="pattern-list__special-table"
              model={tableModel}
              extraActions={
                !readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" /> &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              isRowDisableLayout={(item: PatternDto) => !item.active}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!readOnly}
              allowOnlyOneRowExpanded={true}
              canExpandRows={true}
              createNewButtonTitle={t('Add Pattern')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(newPatternShown && <NewPatternView onClose={this.onNewItemClosed} {...this.props} />) ||
          (changePatternShown && <ChangePatternView onClose={this.onEditItemClosed} currentPattern={selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(PatternsListPage);
