import React, { FC, useState } from 'react';
import i18n from 'i18n';
import { List, Icon, Checkbox } from 'semantic-ui-react';

interface NotificationSettingPopupItemProps {
  title: string;
  onChange?: (notificationTypes: string[]) => void;
  active?: boolean;
  notificationTypes?: string[];
}

export const NotificationSettingPopupItem: FC<NotificationSettingPopupItemProps> = (props: NotificationSettingPopupItemProps) => {
  const [active, setActive] = useState(!!props.active);
  const [notificationTypes, setNotificationTypes] = useState(props.notificationTypes || []);

  const onIconClick = (e, { name }) => {
    const n = name === 'bell' ? 'ToDo' : 'Email';
    let nextNotificationTypes = [];
    if (notificationTypes.includes(n)) {
      nextNotificationTypes = notificationTypes.filter(x => x !== n);
    } else {
      nextNotificationTypes = [...notificationTypes, n];
    }
    let nextActive = nextNotificationTypes.length !== 0;
    setActive(nextActive);
    setNotificationTypes(nextNotificationTypes);
    if (props.onChange != null) props.onChange(nextNotificationTypes);
  };
  const onCheckBoxClick = (e, { checked }) => {
    setActive(checked);
    const next = checked ? ['ToDo', 'Email'] : [];
    setNotificationTypes(next);
    if (props.onChange != null) props.onChange(next);
  };

  return (
    <List.Item className="popup-item">
      <List.Content floated="right">
        <Icon onClick={onIconClick} name="bell" className={`popup-icon ${notificationTypes.includes('ToDo') ? 'active' : ''}`} />
        <Icon onClick={onIconClick} name="mail" className={`popup-icon ${notificationTypes.includes('Email') ? 'active' : ''}`} />
      </List.Content>
      <List.Content>
        {' '}
        <Checkbox checked={active} onChange={onCheckBoxClick} className="popup-chkbx" label={i18n.t(props.title)} />{' '}
      </List.Content>
    </List.Item>
  );
};
