import { BaseDto } from '../../types';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces, isNullOrEmpty } from 'utils/useful-functions';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { LanguageDto } from 'stores/configuration/locations/languages-store';

export interface FeedbackQuestionBankDto extends BaseDto {
  id: string;
  friendlyId: string;
  isMandatory: boolean;
  answerType: string;
  question: FeedbackQuestionDto;
  questionTranslations: FeedbackQuestionDto[];
}

export interface CreateFeedbackQuestionBankDto {
  isMandatory: boolean;
  answerType: string;
  question: CreateFeedbackQuestionDto;
  questionTranslations: CreateFeedbackQuestionDto[];
}

export interface ChangeFeedbackQuestionBankDto {
  id: string;
  isMandatory: boolean;
  answerType: string;
  question: ChangeFeedbackQuestionDto;
  questionTranslations: ChangeFeedbackQuestionDto[];
}

export interface FeedbackQuestionDto {
  text: string;
  subtitle: string;
  languageId: string;
  language: LanguageDto;
}

export interface CreateFeedbackQuestionDto {
  text: string;
  subtitle: string;
  languageId: string;
}

export interface ChangeFeedbackQuestionDto {
  text: string;
  subtitle: string;
  languageId: string;
}

export class FeedbackQuestionDtoValidator extends ExtendedAbstractValidator<ChangeFeedbackQuestionDto | CreateFeedbackQuestionDto> {
  constructor(
    isTraslation: boolean,
    questionLangId?: string,
    translations?: ChangeFeedbackQuestionDto[] | CreateFeedbackQuestionDto[],
    idx?: number,
    onErrors?: (...failures: ValidationFailure[]) => void
  ) {
    super(onErrors);
    let preffix = '';
    if (isTraslation) preffix = i18n.t(`Translation at position ${idx + 1}`);
    else preffix = i18n.t(`Question`);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.text)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Question text is required')}`);

    this.validateIfString(x => x.languageId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Valid language is required')}`);

    this.validateIf(x => x)
      .fulfills(x => x.languageId !== questionLangId && translations.slice(0, idx).every(t => t == null || t.languageId !== x.languageId))
      .when(x => isTraslation && x != null && !isNullOrWhiteSpaces(x.languageId) && (translations || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Language repeated')}`);
  }
}

export class CreateFeedbackQuestionBankValidator extends ExtendedAbstractValidator<CreateFeedbackQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.answerType)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Answer Type is required'));

    this.validateIfString(o => o.answerType)
      .isIn(['YesOrNo', 'Text', 'Rating'])
      .when(x => x != null && !isNullOrEmpty(x.answerType))
      .withFailureMessage(i18n.t('Answer Type invalid'));

    this.validateIf(o => o.isMandatory)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Must Select if the question is mandatory'));

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Feedback Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new FeedbackQuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new FeedbackQuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors)
            .extendValidate(t)
            .isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

export class ChangeFeedbackQuestionBankValidator extends ExtendedAbstractValidator<ChangeFeedbackQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Feedback Question Bank Id is required'));

    this.validateIfString(o => o.answerType)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Answer Type is required'));

    this.validateIfString(o => o.answerType)
      .isIn(['YesOrNo', 'Text', 'Rating'])
      .when(x => x != null && !isNullOrEmpty(x.answerType))
      .withFailureMessage(i18n.t('Answer Type invalid'));

    this.validateIf(o => o.isMandatory)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Must Select if the question is mandatory'));

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new FeedbackQuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new FeedbackQuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors)
            .extendValidate(t)
            .isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

@repository('@@FEEDBACKQUESTIONBANKS', 'feedbackquestionbanks.summary')
export class FeedbackQuestionBanksStore extends DataStore<FeedbackQuestionBankDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-question';
  retrievePath = 'get-feedback-questions';
  updatePath = 'update-feedback-question';
  deletePath = 'delete-feedback-question';
  retrieveOnePath = 'get-feedback-question';
  retrieveCountPath = 'get-used-feedback-question-count';

  protected validate(item: FeedbackQuestionBankDto) {
    return new ValidationResult();
  }

  constructor() {
    super('FEEDBACKQUESTIONBANK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getQuestionBankById(id: string): Promise<FeedbackQuestionBankDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<FeedbackQuestionBankDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedFeedbackQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@FEEDBACKQUESTIONBANKS', 'feedbackquestionbanks.new')
export class NewFeedbackQuestionBankStore extends FormStore<CreateFeedbackQuestionBankDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-question';
  retrievePath = 'get-feedback-questions';
  updatePath = 'update-feedback-question';

  protected validate(item: CreateFeedbackQuestionBankDto) {
    return new CreateFeedbackQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_FEEDBACKQUESTIONBANKS', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@FEEDBACKQUESTIONBANKS', 'feedbackquestionbanks.change')
export class ChangeFeedbackQuestionBankStore extends FormStore<ChangeFeedbackQuestionBankDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-question';
  retrievePath = 'get-feedback-questions';
  updatePath = 'update-feedback-question';

  protected validate(item: ChangeFeedbackQuestionBankDto) {
    return new ChangeFeedbackQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('CHANGE_FEEDBACKQUESTIONBANKS', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
