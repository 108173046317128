import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Button, Dimmer, Loader, Message } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import './add-expertise-popup.less';
import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import { InstructorExpertiseRequestFormStore } from 'stores/skills/instructor-expertise-request-form-store';
import { UserDto } from 'stores/users/users-store';

type UserDtoOrStringArray = UserDto | string[];

export interface AddExpertiseProps extends WithTranslation {
  open: boolean;
  instructorExpertiseRequestFormStore?: InstructorExpertiseRequestFormStore;
  machines: MachineListItemViewModel[];
  instructorIds: UserDtoOrStringArray;
  eventTypeId: string;
  originalEventTypeId: string;
  roleId: string;
  trainingLevelId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

interface AddExpertisePopupState {
  loading: boolean;
  errMsgs: string[];
}

@connect(['instructorExpertiseRequestFormStore', InstructorExpertiseRequestFormStore])
class AddExpertisePopup extends React.Component<AddExpertiseProps, AddExpertisePopupState> {
  static defaultProps = { onCancel: () => {}, onSuccess: () => {}, onError: () => {}, cancelRequest: false };
  state: AddExpertisePopupState = { loading: false, errMsgs: [] };

  private onCancel = () => this.props.onCancel();

  private onCreateInstructorExpertiseRequest = () => {
    const errMsgs = [];
    const {
      onSuccess,
      onError,
      instructorExpertiseRequestFormStore,
      machines,
      instructorIds,
      eventTypeId,
      originalEventTypeId,
      roleId,
      trainingLevelId
    } = this.props;

    this.setState({ loading: true });
    const instructors = this.handleInstructores(instructorIds);
    instructorExpertiseRequestFormStore
      .createInstructorExpertiseRequest(instructors, machines, eventTypeId, roleId, trainingLevelId, originalEventTypeId)
      .then(response => {
        if (response?.isSuccess) onSuccess();
      })
      .catch(error => {
        console.error(error);
        errMsgs.push(
          error.response && error.response.data && error.response.data.messages
            ? (error.response.data.messages || []).firstOrDefault()
            : error.message
        );
        this.setState({ errMsgs });
        onError();
      });

    this.setState({ loading: false });
  };

  private handleInstructores(input: UserDtoOrStringArray) {
    if (Array.isArray(input)) {
      return input;
    } else {
      return [input.id];
    }
  }

  public render() {
    const { t, open, instructorIds } = this.props;
    const { loading, errMsgs } = this.state;

    const errors = errMsgs.length ? (
      <Message
        className="error-message__style"
        error
        icon="exclamation circle"
        onDismiss={() => {}}
        header={t('An error ocurred')}
        list={this.props.instructorExpertiseRequestFormStore.state.result.messages.map(o => o.body)}
      />
    ) : null;

    return (
      <Modal open={open} size="tiny" className="add-expertise-popup">
        <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <>
          <Modal.Content className="add-expertise-popup__content">
            {errors}
            <p>
              {t(
                `Would you like to add defined expertise for ${
                  !Array.isArray(instructorIds) ? `instructor ${instructorIds.firstName} ${instructorIds.lastName}` : 'instructors'
                } to the master data?`
              )}
            </p>
          </Modal.Content>

          <div className="add-expertise-popup__buttons-container">
            <div className="add-expertise-popup__buttons-container__right-container">
              <Button className="add-expertise-popup__btn add-expertise-popup__back-btn" content={t('No')} onClick={this.onCancel} />
            </div>
            <div className="add-expertise-popup__buttons-container__left-container">
              <Button className="add-expertise-popup__btn" content={t('Yes')} onClick={this.onCreateInstructorExpertiseRequest} />
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default withTranslation()(AddExpertisePopup);
