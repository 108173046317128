import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { BaseDto } from 'stores/types';
import { LocationDto } from 'stores/configuration/locations/locations-store';

export interface MachineForLocationDto extends BaseDto {
  id: string; //Guid
  locationId: string; //Guid
  locationName: string;
  location: LocationDto;
  machineModelId: string; //Guid
  machineModelName: string;
  clusterName: string;
  equipmentName: string;
  oemName: string;
}

export interface CreateMachineForLocationDto {
  locationId: string; //Guid
  machineModelId: string; //Guid
}

export interface ChangeMachineForLocationDto {
  id: string; //Guid
  locationId: string; //Guid
  machineModelId: string; //Guid
}

export class CreateMachineForLocationValidator extends AbstractValidator<CreateMachineForLocationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Machine Model Id is required'));
  }
}

export class ChangeMachineForLocationValidator extends AbstractValidator<ChangeMachineForLocationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location Id is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Machine Model Id is required'));
  }
}

@repository('@@MACHINESFORLOCATIONS', 'machinesforlocations.summary')
export class MachinesForLocationsStore extends DataStore<MachineForLocationDto> {
  baseUrl = 'machines/v1';
  createPath = 'create-machine-for-location';
  retrievePath = 'get-machines-for-locations';
  updatePath = 'update-machine-for-location';
  deletePath = 'delete-machine-for-location';

  protected validate(item: MachineForLocationDto) {
    return new ChangeMachineForLocationValidator().validate(item);
  }

  constructor() {
    super('MACHINESFORLOCATIONS', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@MACHINESFORLOCATIONS', 'machinesforlocations.new')
export class NewMachineForLocationStore extends FormStore<CreateMachineForLocationDto> {
  baseUrl = 'machines/v1';
  createPath = 'create-machine-for-location';
  retrievePath = 'get-machines-for-locations';
  updatePath = 'update-machine-for-location';

  protected validate(item: CreateMachineForLocationDto) {
    return new CreateMachineForLocationValidator().validate(item);
  }

  constructor() {
    super('NEW_MACHINEFORLOCATION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
