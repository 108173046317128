import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NMFunctionalAreaDto,
  ChangeNMFunctionalAreaDto,
  ChangeNMFunctionalAreaStore
} from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { ItemReference } from 'stores/dataStore';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { formStoreHandler, isNullOrWhiteSpaces } from 'utils/useful-functions';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Categories } from 'stores/requests/requests-store';

interface ChangeNMFunctionalAreaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeNMFunctionalArea?: ChangeNMFunctionalAreaStore;
  currentDto: NMFunctionalAreaDto;
}

interface ChangeNMFunctionalAreaViewState {
  cluster: ItemReference;
  category: Categories;
}

@connect(['changeNMFunctionalArea', ChangeNMFunctionalAreaStore])
class ChangeNMFunctionalAreaView extends Component<ChangeNMFunctionalAreaViewProps, ChangeNMFunctionalAreaViewState> {
  private get changeNMFunctionalAreaStore() {
    return this.props.changeNMFunctionalArea;
  }

  constructor(props: ChangeNMFunctionalAreaViewProps) {
    super(props);

    const { changeNMFunctionalArea, currentDto } = this.props;
    const { id, name, clusterId, clusterName, category } = currentDto;

    changeNMFunctionalArea.state.result = null;

    changeNMFunctionalArea.change({ id, name, clusterId });

    this.state = {
      cluster: { id: clusterId, title: clusterName },
      category: category === 'Unknown' ? Categories.Technical : Categories[category]
    };
  }

  private onChangeItem = async () => {
    const result = await this.changeNMFunctionalAreaStore.update();
    result?.isSuccess && this.props.onClose(true);
  };

  private onCancelChangeItem = () => {
    this.changeNMFunctionalAreaStore.clear();
    this.props.onClose(false);
  };

  categoryChangeHandler = (category: Categories) => this.setState({ category, cluster: null });

  public render() {
    const { t, changeNMFunctionalArea } = this.props;
    const { category, cluster } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={changeNMFunctionalArea.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Functional Area')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {changeNMFunctionalArea.state.result && !changeNMFunctionalArea.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={changeNMFunctionalArea.state.result.messages.map(o => o.body)}
              />
            )}
            {changeNMFunctionalArea.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!changeNMFunctionalArea.state.item.name}
                    label={t('Functional Area')}
                    placeholder={t('Name')}
                    value={changeNMFunctionalArea.state.item.name}
                    onChange={(_, { value }) => formStoreHandler<ChangeNMFunctionalAreaDto>(changeNMFunctionalArea, 'name', value)}
                  />
                  <Form.Field required>
                    <div
                      className={`required ${
                        changeNMFunctionalArea.state.item.clusterId == null ||
                        isNullOrWhiteSpaces(changeNMFunctionalArea.state.item.clusterId)
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        nullable
                        category={category}
                        value={cluster}
                        onChange={c => formStoreHandler<ChangeNMFunctionalAreaDto>(changeNMFunctionalArea, 'clusterId', c?.id)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <div className="custom-actions">
          <Form.Field>
            <label>{t('Category')}</label>
            <CategoryFormEditor
              placeholder={t('Filter by Category')}
              className="form__header__dropdown-style"
              value={category}
              onChange={this.categoryChangeHandler}
            />
          </Form.Field>
          <Modal.Actions>
            <Button basic onClick={this.onCancelChangeItem}>
              {t('Cancel')}
            </Button>
            <Button onClick={this.onChangeItem} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeNMFunctionalAreaView);
