import * as React from 'react';
import { Tab } from 'semantic-ui-react';
import { container } from 'inversify.config';

import SchedulerInstructorsPage from './scheduler-instructor-page/scheduler-instructor-page';
import SchedulerLocationsPage from './scheduler-location-page/scheduler-location-page';
import './scheduler-page.less';
import { IdentityService, Roles } from 'services/identity-service';
import queryString from 'query-string';
import i18n from 'i18n';
import { RouteComponentProps } from 'react-router';

function isAllowedToSeeInstructorsScheduler(identityService: IdentityService): boolean {
  return (
    identityService.isInRole(Roles.Admin) ||
    identityService.isInRole(Roles.Instructor) ||
    identityService.isInRole(Roles.PoC) ||
    identityService.isInRole(Roles.Planner) ||
    identityService.isInRole(Roles.PlannerMTC) ||
    identityService.isInRole(Roles.Reporting)
  );
}

function isAllowedToSeeLocationsScheduler(identityService: IdentityService): boolean {
  return (
    identityService.isInRole(Roles.Admin) ||
    identityService.isInRole(Roles.PoC) ||
    identityService.isInRole(Roles.Planner) ||
    identityService.isInRole(Roles.PlannerMTC) ||
    identityService.isInRole(Roles.Reporting) ||
    identityService.isInRole(Roles.FactoryLead) ||
    identityService.isInRole(Roles.GlobalEngineering) ||
    identityService.isInRole(Roles.GlobalManufacturing) ||
    identityService.isInRole(Roles.RegionalManufacturingVP)
  );
}

const menu = { secondary: true, pointing: true };

const useSchedulerConfig = ({ location, match, history }: RouteComponentProps) => {
  const identityService = React.useMemo(() => container.get<IdentityService>(IdentityService), []);

  const panes: any[] = [
    ...(isAllowedToSeeInstructorsScheduler(identityService)
      ? [
          {
            menuItem: i18n.t('Instructors'),
            render: () => <Tab.Pane attached={false}>{<SchedulerInstructorsPage />}</Tab.Pane>
          }
        ]
      : []),
    ...(isAllowedToSeeLocationsScheduler(identityService)
      ? [
          {
            menuItem: i18n.t('Locations'),
            render: () => (
              <Tab.Pane attached={false}>
                <SchedulerLocationsPage />
              </Tab.Pane>
            )
          }
        ]
      : [])
  ];

  const [activeIndex, setActiveIndex] = React.useState<number>(() => getActiveIndex());
  const [path, setPath] = React.useState<string>(() => getPath());

  function getPath() {
    return location.pathname;
  }

  function getActiveIndex() {
    let values = queryString.parse(location.search);
    if (values == null || values.tab == null) values = match.params;

    if (values == null || values.tab == null) {
      if (identityService.isInRole(Roles.PoC)) {
        return 1;
      }
      return 0;
    }
    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    let l = panes.length;
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
    return 0;
  }

  function onTabChange(_, { path, activeIndex }) {
    history.replace(`${path}?tab=${activeIndex}`);
    setPath(path);
    setActiveIndex(activeIndex);
  }

  return { onTabChange, activeIndex, panes, path };
};

const SchedulerPage: React.FC<RouteComponentProps> = ({ location, match, history }) => {
  const { activeIndex, panes, onTabChange, path } = useSchedulerConfig({ location, match, history });
  return <Tab activeIndex={activeIndex} onTabChange={onTabChange} className="main-tab" menu={menu} panes={panes} path={path} />;
};

export default SchedulerPage;
