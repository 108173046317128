import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Divider, Button, Accordion } from 'semantic-ui-react';
import './iws-edit-form-footer-styles.less';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import { AssessorType, IWSFormContext } from './iws-edit-form';
import { useToggle } from 'utils/react-custom-hooks';
import { ChangeIwsFormAnswerDto, IwsFormItemDto, IwsFormStatus } from 'stores/assessments/forms/iws-forms-store';

interface IWSFormFooterProps extends WithTranslation {
  seletedTemplateId: string;
}

const IWSFormFooterComponent: React.FC<IWSFormFooterProps> = ({ t, seletedTemplateId }) => {
  const {
    onSave,
    submittable,
    onSend,
    onSendStatusBack,
    getAssessorType,
    getAStatus,
    onChange,
    isAdminOrPoC,
    isEmployee,
    isAssessor,
    isAdmin
  } = React.useContext(IWSFormContext);
  const assessorType = getAssessorType();
  const { answerStatus } = getAStatus() || {};
  const status = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => summary.item.status);
  const isDone = status === 'Done';
  const nonAssessor = assessorType.type === 'nonAssessor';
  const assessorPending = ['Pending', 'PendingRecommendation'].includes(answerStatus);
  const isEmployeeStatus = status === 'FirstAssesment' || status === 'SecondAssesment';
  const assessorReadOnlyMode = isAssessor && isEmployeeStatus;
  const employeeEditable = !isAdminOrPoC && nonAssessor && !isDone && !(isEmployee && status === 'Qualifier');
  const assessorEditable = !isAdminOrPoC && !isDone && assessorPending && !assessorReadOnlyMode;

  const { active, toggle } = useToggle(employeeEditable || assessorEditable);

  const changeanswerItem = useSelector<ApplicationState>(
    ({ iwsformlist: { changeanswer } }) => changeanswer.item
  ) as ChangeIwsFormAnswerDto;

  const formItem = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => summary?.item as IwsFormItemDto) as IwsFormItemDto;

  const getCurrentUserEdit = (assessorType: AssessorType, iwsFormStatus: IwsFormStatus): string => {
    let currentUserEdit = null;
    if (assessorType.type === 'qualifier') {
      currentUserEdit = 'qualifierComment';
    } else {
      if (iwsFormStatus.toString() === 'FirstAssesment') currentUserEdit = 'employeeFirstAssessmentComment';
      else currentUserEdit = 'employeeSecondAssessmentComment';
    }

    return currentUserEdit;
  };

  const getCurrentTitleLabelComment = (assessorType: AssessorType, iwsFormStatus: IwsFormStatus): string => {
    let label = null;
    if (assessorType.type === 'qualifier') {
      label = 'Qualifier Comments';
    } else {
      if (iwsFormStatus.toString() === 'FirstAssesment') label = '1st Employee Comments';
      else label = '2nd Employee Comments';
    }

    return label;
  };

  const currentUserEdit = getCurrentUserEdit(assessorType, formItem.status);

  var templateItems = changeanswerItem?.templateSectionAnswers;
  if (assessorType?.type === 'qualifier') {
    let templateIds = assessorType?.qualifierDto?.templates.map(x => x.templateId);
    templateItems = templateItems.filter(x => templateIds.any(id => id === x.templateId));
  }

  var tempSectionAnswerSelected =
    seletedTemplateId == null
      ? (templateItems || [])[0]
      : changeanswerItem?.templateSectionAnswers?.firstOrDefault(x => x.templateId === seletedTemplateId);

  const onTemplateCommentChanged = (value: string) => {
    changeanswerItem?.templateSectionAnswers.forEach(temp => {
      temp[currentUserEdit] = value;
    });

    onChange('templateSectionAnswers', changeanswerItem.templateSectionAnswers);
  };

  return (
    <footer className="form__all-wrapper__footer tna-footer">
      <Divider />
      <Accordion className="tna-footer-accordion">
        <Accordion.Title active={active} onClick={toggle} className="tna-footer-accordion__title" />
        <Accordion.Content active={active}>
          {(assessorEditable || employeeEditable) && (
            <div className="tna-edit-form__footer__text-area">
              <p>{t(getCurrentTitleLabelComment(assessorType, formItem.status))}</p>
              <textarea
                value={tempSectionAnswerSelected[currentUserEdit] ? tempSectionAnswerSelected[currentUserEdit] : ''}
                onChange={({ target: { value } }) => onTemplateCommentChanged(value)}
                className="template-section__comments"
              ></textarea>
            </div>
          )}
          {isAdmin && (
            <div className="tna-edit-form__footer__btns flex-end">
              {(status === 'Qualifier' || isDone) && (
                <Button className="inverted-color-btn" onClick={() => onSendStatusBack('assesment')} positive>
                  {t('Set Status Assesment')}
                </Button>
              )}
              {isDone && (
                <Button className="inverted-color-btn" onClick={() => onSendStatusBack('qualifier')} positive>
                  {t('Set Status Qualifier')}
                </Button>
              )}
            </div>
          )}
          {(assessorEditable || employeeEditable) && (
            <div className="tna-edit-form__footer__btns flex-end">
              {!isAdminOrPoC && (
                <Button className="white-save-btn" onClick={onSave} positive>
                  {t('Save')}
                </Button>
              )}
              {!isAdminOrPoC && submittable() && (
                <Button className="inverted-color-btn" onClick={onSend} positive>
                  {t('Send IWS SuC')}
                </Button>
              )}
            </div>
          )}
        </Accordion.Content>
      </Accordion>
    </footer>
  );
};
export default withTranslation()(React.memo(IWSFormFooterComponent));
