import React, { Component } from 'react';
import { RequestFormStore } from 'stores/requests/request-form-store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import SupportDetailsComponent from '../../shared-wizard-form/request-support-details.component';
import NewSupportDetailsComponent from '../../shared-wizard-form/request-new-support-details.component';

import { SupportDetails } from 'stores/requests/requests-store';

export interface SupportDetailsContainerProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
}

@connect(['requestFormStore', RequestFormStore])
class SupportDetailsContainer extends Component<SupportDetailsContainerProps> {
  private handleOnSupportDetailsChange = (change: Partial<SupportDetails>) => {
    if (!this.props.requestFormStore.state.item) return;
    const { supportDetails } = this.props.requestFormStore.state.item;
    this.props.requestFormStore.change({ supportDetails: { ...supportDetails, ...change } });
  };

  render() {
    const { readOnly } = this.props;
    const { supportDetails, startDate, endDate, requestingLocation } = this.props.requestFormStore.state.item;

    return (
      <div>
        {!supportDetails.isNewSupportPositionModel && (
          <SupportDetailsComponent
            startDate={startDate}
            endDate={endDate}
            supportDetails={supportDetails}
            onChange={this.handleOnSupportDetailsChange}
            isForm
            readOnly={readOnly}
          />
        )}
        {supportDetails.isNewSupportPositionModel && (
          <NewSupportDetailsComponent
            startDate={startDate}
            endDate={endDate}
            supportDetails={supportDetails}
            onChange={this.handleOnSupportDetailsChange}
            isForm
            readOnly={readOnly}
            requestLocationId={requestingLocation.id}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(SupportDetailsContainer);
