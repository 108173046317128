import i18n from 'i18n';
import React, { FC, useState } from 'react';
import { Checkbox, List } from 'semantic-ui-react';

interface RoleSelectorPopupItemProps {
  title: string;
  onChange?: (active: boolean) => void;
  active?: boolean;
}

export const RoleSelectorPopupItem: FC<RoleSelectorPopupItemProps> = (props: RoleSelectorPopupItemProps) => {
  const [active, setActive] = useState(!!props.active);

  const onCheckBoxClick = (e, { checked }) => {
    setActive(checked);
    if (props.onChange != null) props.onChange(checked);
  };

  return (
    <List.Item className="popup-item">
      <List.Content>
        {' '}
        <Checkbox checked={active} onChange={onCheckBoxClick} className="popup-chkbx" label={i18n.t(props.title)} />{' '}
      </List.Content>
    </List.Item>
  );
};
