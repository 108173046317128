import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DropDownEventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { nameof } from 'utils/object';
import { ProfessionDto, DropDownProfessionsStore } from 'stores/configuration/profiles/profession-roles-store';
import { DropDownMachineModelsStore, MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import {
  DropDownNMFunctionalSubareasStore,
  NMFunctionalSubareaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { DropDownTrainingLevelsStore, TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { EquipmentTypeDto, DropDownEquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import { OrderDefinition, Query } from 'stores/dataStore';

export interface InstructorRowFilterValues {
  eventTypes: string[];
  machineModels: string[];
  functionalSubareas: string[];
  traininglevels: string[];
  professions: string[];
  equipmentTypes: string[];
}

export interface InstructorRowFilterProps {
  className?: string;
  placeholder?: string;
  eventTypesStore?: DropDownEventTypesStore;
  machineModelsStore?: DropDownMachineModelsStore;
  functionalSubareasStore?: DropDownNMFunctionalSubareasStore;
  traininglevelsStore?: DropDownTrainingLevelsStore;
  professionsStore?: DropDownProfessionsStore;
  equipmentTypeStore?: DropDownEquipmentTypesStore;
  onChange?: (value: InstructorRowFilterValues) => void;
}

export interface InstructorRowFilterOptions<T = InstructorRowFilterValues> {
  id: string;
  text: string;
  type: keyof T;
  value: any;
}

const commonQuery: Query = { searchQuery: '', skip: 0, take: 100000, orderBy: [], filter: [] };

interface InstructorRowFilterState {
  open: boolean;
  loading: boolean;
  options: { [id: string]: InstructorRowFilterOptions };
}

type NewState<T> = Partial<T> | ((state: T) => T);

const getDefaultValues = (): InstructorRowFilterState => ({ open: false, loading: false, options: {} });

type StateReducer = (state: InstructorRowFilterState, newState: NewState<InstructorRowFilterState>) => InstructorRowFilterState;

const reducer = (state: InstructorRowFilterState, newState: NewState<InstructorRowFilterState>): InstructorRowFilterState =>
  typeof newState === 'function' ? newState(state) : { ...state, ...newState };

const InstructorRowFilter: React.FC<InstructorRowFilterProps> = props => {
  const [state, setState] = React.useReducer<StateReducer, InstructorRowFilterState>(reducer, getDefaultValues(), getDefaultValues);
  const handleOnSearchChange = (_, searchQuery) => setState({ open: !!searchQuery });

  const handleOnOptionChange = (value: any) => {
    let instructorRowFilterValues = buildInstructorRowFilterValues(value);
    setState({ open: false });
    props.onChange && props.onChange(instructorRowFilterValues);
  };

  const buildInstructorRowFilterValues = (value: any): InstructorRowFilterValues => {
    const instructorRowFilterValues: InstructorRowFilterValues = {
      eventTypes: [],
      functionalSubareas: [],
      machineModels: [],
      professions: [],
      traininglevels: [],
      equipmentTypes: []
    };

    (value || []).forEach(key => {
      let option = state.options[key];
      instructorRowFilterValues[option.type].push(option.value);
    });

    return instructorRowFilterValues;
  };

  const toDropdownOptions = () => Object.keys(state.options || {}).map(key => ({ text: state.options[key].text, value: key }));

  const { eventTypesStore, machineModelsStore, traininglevelsStore, professionsStore, functionalSubareasStore, equipmentTypeStore } = props;

  const getAllEventTypes = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<EventTypeDto>('name'), useProfile: false }];
    return eventTypesStore.getAllAsync({ ...commonQuery, orderBy, filter: { active: { eq: true } } });
  }, [eventTypesStore]);

  const getAllMachineModels = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<MachineModelDto>('name'), useProfile: false }];
    return machineModelsStore.getAllAsync({ ...commonQuery, orderBy, filter: { active: { eq: true } } });
  }, [machineModelsStore]);

  const getAllTrainingLevels = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<TrainingLevelDto>('name'), useProfile: false }];
    return traininglevelsStore.getAllAsync({ ...commonQuery, orderBy });
  }, [traininglevelsStore]);

  const getAllProfessions = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<ProfessionDto>('profession'), useProfile: false }];
    return professionsStore.getAllAsync({ ...commonQuery, orderBy });
  }, [professionsStore]);

  const getAllFunctionalSubareas = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<NMFunctionalSubareaDto>('name'), useProfile: false }];
    return functionalSubareasStore.getAllAsync({ ...commonQuery, orderBy });
  }, [functionalSubareasStore]);
  const getAllEquipmentTypes = React.useCallback(() => {
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<EquipmentTypeDto>('name'), useProfile: false }];
    return equipmentTypeStore.getAllAsync({ ...commonQuery, orderBy });
  }, [equipmentTypeStore]);

  // React.useEffect(() => {
  //   const eventTypesReq = getAllEventTypes();
  //   const machineModelsReq = getAllMachineModels();
  //   const functionalSubareasReq = getAllFunctionalSubareas();
  //   const professionsReq = getAllProfessions();
  //   const trainingLevelsReq = getAllTrainingLevels();
  //   const equipmentTypesReq = getAllEquipmentTypes();

  //   Promise.all([eventTypesReq, machineModelsReq, functionalSubareasReq, professionsReq, trainingLevelsReq, equipmentTypesReq]).then(
  //     ([eventTypesRes, machineModelsRes, functionalSubareasRes, professionsRes, trainingLevelsRes, equipmentTypesRes]) => {
  //       const options = {};

  //       mapEventTypes(eventTypesRes.items).forEach(option => (options[buildKey(option)] = option));
  //       mapMachineModels(machineModelsRes.items).forEach(option => (options[buildKey(option)] = option));
  //       mapFunctionalSubareas(functionalSubareasRes.items).forEach(option => (options[buildKey(option)] = option));
  //       mapProfessions(professionsRes.items).forEach(option => (options[buildKey(option)] = option));
  //       mapTrainingLevels(trainingLevelsRes.items).forEach(option => (options[buildKey(option)] = option));
  //       mapEquipmentTypes(equipmentTypesRes.items).forEach(option => (options[buildKey(option)] = option));

  //       setState({ options, loading: false });
  //     }
  //   );
  // }, [getAllEventTypes, getAllMachineModels, getAllFunctionalSubareas, getAllProfessions, getAllTrainingLevels, getAllEquipmentTypes]);

  const mapMachineModels = (machineModels: MachineModelDto[] = []): InstructorRowFilterOptions<InstructorRowFilterValues>[] =>
    machineModels.map(({ id, name }) => ({ value: id, text: name, id: id, type: 'machineModels' }));

  const mapEventTypes = (eventTypes: EventTypeDto[]): InstructorRowFilterOptions[] =>
    eventTypes.map(({ id, name }) => ({ value: id, text: name, id: id, type: 'eventTypes' }));

  const mapFunctionalSubareas = (functionalSubareas: NMFunctionalSubareaDto[]): InstructorRowFilterOptions[] =>
    functionalSubareas.map(({ id, name }) => ({ value: id, text: name, id: id, type: 'functionalSubareas' }));

  const mapProfessions = (professions: ProfessionDto[]): InstructorRowFilterOptions[] =>
    professions.map(({ id, profession }) => ({ value: id, text: profession, id: id, type: 'professions' }));

  const mapTrainingLevels = (trainingLevels: TrainingLevelDto[]): InstructorRowFilterOptions[] =>
    trainingLevels.map(({ id, name }) => ({ value: id, text: name, id: id, type: 'traininglevels' }));

  const mapEquipmentTypes = (equipmentTypes: EquipmentTypeDto[]): InstructorRowFilterOptions[] =>
    equipmentTypes.map(({ id, name }) => ({ value: id, text: name, id: id, type: 'equipmentTypes' }));

  const buildKey = (option: InstructorRowFilterOptions): string => `${option.id}-${option.type as any}`;

  const { placeholder, className } = props;

  const dropdownOptions = toDropdownOptions();

  const onOpenDropDown = () => {
    if (dropdownOptions.length === 0 && !state.loading) {
      setState({ loading: true });
      // React.useEffect(() => {
      const eventTypesReq = getAllEventTypes();
      const machineModelsReq = getAllMachineModels();
      const functionalSubareasReq = getAllFunctionalSubareas();
      const professionsReq = getAllProfessions();
      const trainingLevelsReq = getAllTrainingLevels();
      const equipmentTypesReq = getAllEquipmentTypes();

      Promise.all([eventTypesReq, machineModelsReq, functionalSubareasReq, professionsReq, trainingLevelsReq, equipmentTypesReq]).then(
        ([eventTypesRes, machineModelsRes, functionalSubareasRes, professionsRes, trainingLevelsRes, equipmentTypesRes]) => {
          const options = {};

          mapEventTypes(eventTypesRes.items).forEach(option => (options[buildKey(option)] = option));
          mapMachineModels(machineModelsRes.items).forEach(option => (options[buildKey(option)] = option));
          mapFunctionalSubareas(functionalSubareasRes.items).forEach(option => (options[buildKey(option)] = option));
          mapProfessions(professionsRes.items).forEach(option => (options[buildKey(option)] = option));
          mapTrainingLevels(trainingLevelsRes.items).forEach(option => (options[buildKey(option)] = option));
          mapEquipmentTypes(equipmentTypesRes.items).forEach(option => (options[buildKey(option)] = option));

          setState({ options, loading: false });
        }
      );
      //}, [getAllEventTypes, getAllMachineModels, getAllFunctionalSubareas, getAllProfessions, getAllTrainingLevels, getAllEquipmentTypes]);
    }
  };

  return (
    <Dropdown
      placeholder={placeholder}
      className={className}
      loading={state.loading}
      multiple
      search
      selection
      icon="search"
      open={state.open}
      onSearchChange={(event, { searchQuery }) => handleOnSearchChange(event, searchQuery)}
      onChange={(_, { value }) => handleOnOptionChange(value)}
      options={dropdownOptions}
      onOpen={onOpenDropDown}
    />
  );
};

export default React.memo(InstructorRowFilter);
