import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EventWizardData } from 'stores/events/creation-wizard-store';
import NonMachineRelatedComponent, {
  NonMachineRelatedComponentViewModel
} from 'site/pages/shared/events-and-requests/non-machine-related/non-machine-related';

interface NonMachineRelatedSubjectStepProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, any][]) => void;
}

interface NonMachineRelatedSubjectStepState {
  wizardData: EventWizardData;
  clusterActive: boolean;
  functionalAreaActive: boolean;
  trainingNameActive: boolean;
}

class NonMachineRelatedSubjectStep extends Component<NonMachineRelatedSubjectStepProps, NonMachineRelatedSubjectStepState> {
  constructor(props) {
    super(props);

    this.state = {
      wizardData: props.wizardData,
      clusterActive: this.isClusterActive(),
      functionalAreaActive: this.isFunctionalAreaActive(),
      trainingNameActive: this.isTrainingNameActive()
    };
  }

  private isClusterActive(): boolean {
    return this.props.wizardData.nmrClusterId != null;
  }

  private isFunctionalAreaActive(): boolean {
    return this.props.wizardData.nMRFunctionalAreaId != null;
  }

  private isTrainingNameActive(): boolean {
    return this.props.wizardData.nMRTrainingNameId != null;
  }

  UNSAFE_componentWillReceiveProps(nextProps: NonMachineRelatedSubjectStepProps) {
    if (nextProps && nextProps !== this.props) {
      this.setState({
        wizardData: nextProps.wizardData
      });
    }
  }

  private handleOnNonMachineRelatedChange = ({
    cluster,
    functionalArea,
    functionalSubArea,
    trainingName
  }: NonMachineRelatedComponentViewModel) => {
    const changes: [keyof EventWizardData, any][] = [
      ['nmrCluster', cluster],
      ['nmrFunctionalArea', functionalArea],
      ['nmrFunctionalSubArea', functionalSubArea],
      ['nmrTrainingName', trainingName],
      ['nmrClusterId', cluster?.id],
      ['nMRFunctionalAreaId', functionalArea?.id],
      ['nMRFunctionalSubAreaId', functionalSubArea?.id],
      ['nMRTrainingNameId', trainingName?.id]
    ];

    this.props.onValueChanged(...changes);
  };

  render() {
    const { t } = this.props as any;
    const { nmrCluster, nmrFunctionalArea, nmrFunctionalSubArea, nmrTrainingName, category } = this.props.wizardData;

    const nonMachineRelated: NonMachineRelatedComponentViewModel = {
      cluster: nmrCluster,
      functionalArea: nmrFunctionalArea,
      functionalSubArea: nmrFunctionalSubArea,
      trainingName: nmrTrainingName
    };

    return (
      <>
        <h3 className="machine-related__title">{t('NON-MACHINE RELATED')}</h3>
        <NonMachineRelatedComponent
          category={category}
          className="wizard__no-machine-related"
          value={nonMachineRelated}
          readOnly={false}
          onChange={this.handleOnNonMachineRelatedChange}
        />
      </>
    );
  }
}

export default withTranslation()(NonMachineRelatedSubjectStep);
