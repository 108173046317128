import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { OemDto, OemsStore } from '../../stores/configuration/machinery/oems-store';
import { SelectionInput, SelectionItem } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { customEqualCompare, isNullOrWhiteSpaces } from '../../utils/useful-functions';
import { PatternsStore, PatternDto } from 'stores/configuration/machinery/patterns-store';

interface PatternEditorProps extends WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  patterns?: OemsStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  className?: string;
  showOnlyActive?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  loadDataOnOpen?: boolean;
}

interface PatternEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
  isAlreadyQuerying: boolean;
}

@connect(['patterns', PatternsStore])
class PatternEditor extends React.Component<PatternEditorProps, PatternEditorState> {
  private get patternsStore() {
    return this.props.patterns;
  }

  constructor(props: PatternEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getPatternMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value),
      isAlreadyQuerying: false
    };
  }

  componentDidUpdate(prevProps) {
    const { value, reloadOnChange } = this.props;
    if (!customEqualCompare(prevProps.value, value) && reloadOnChange) {
      this.setState({ value: value, options: getOptionsFromValue(this.state?.query, value) });
    }
  }
  @autobind
  private getPatternMethod() {
    const method = async (search: string) => {
      if (this.state.isAlreadyQuerying) return;
      this.setState({ isAlreadyQuerying: true });
      const filter = isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<PatternDto>('name')}), '${search.toLowerCase()}')`];
      if (this.props.showOnlyActive) filter.push('active eq true');

      const result = await this.patternsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<OemDto>('name'), useProfile: false }],
        filter: filter,
        select: 'id, name'
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    this.setState({ isAlreadyQuerying: false });
    return method;
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getPatternMethod() });
    }
  };
  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        readOnly={this.props.readOnly}
        content={item => <div>{item.title}</div>}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        className={this.props.className}
        clearable={this.props.clearable}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(PatternEditor);
