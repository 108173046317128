import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Button } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import './change-status-popup.less';

export interface ChangeStatusPopupProps extends WithTranslation {
  open: boolean;
  onCancelClicked?: () => void;
  onAcceptClicked?: () => void;
  eventFormStore?: EventFormStore;
}

class ChangeStatusPopup extends React.Component<ChangeStatusPopupProps> {
  private hasEventValidations = (): boolean => {
    const event = this.props.eventFormStore?.state.item;
    const eventStatus = event?.status.toString();

    let rolesRequiredToValidateStatus = null;

    switch (eventStatus) {
      case 'Planned':
        rolesRequiredToValidateStatus = event?.eventType.planned.rolesRequiredToValidateStatus;
        break;
      case 'Completed':
        rolesRequiredToValidateStatus = event?.eventType.completed.rolesRequiredToValidateStatus;
        break;
      case 'InProgress':
        rolesRequiredToValidateStatus = event?.eventType.inProgress.rolesRequiredToValidateStatus;
        break;
      case 'Draft':
        rolesRequiredToValidateStatus = event?.eventType.draft.rolesRequiredToValidateStatus;
        break;
      case 'Closed':
      default:
        rolesRequiredToValidateStatus = null;
    }

    return rolesRequiredToValidateStatus && (rolesRequiredToValidateStatus || []).length > 0;
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal
        style={{ transform: 'translateY(35vh)' }}
        open={this.props.open}
        size="tiny"
        className="change-status-popup"
        closeOnEscape={true}
        onClose={this.props.onCancelClicked}
      >
        <Modal.Content className="change-status-popup__content">
          <p>
            {this.hasEventValidations()
              ? t('You are going to skip the validations and change the event state.')
              : t('You are going to change the event state.')}
          </p>
          <p>{t('Are you sure you want to continue?')}</p>
        </Modal.Content>
        <div className="change-status-popup__buttons-container">
          <Button className="change-status-popup__btn" content={t('No')} onClick={this.props.onCancelClicked} />
          <Button
            className="change-status-popup__btn change-status-popup__pending-btn"
            content={t('Yes')}
            onClick={this.props.onAcceptClicked}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeStatusPopup);
