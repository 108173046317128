import i18n from 'i18n';
import * as React from 'react';
import { Button, Checkbox, Divider, Form, Header, Icon, Popup, Segment } from 'semantic-ui-react';

interface ICheckboxFilterProps {
  triggerTitle: string;
  filterTitle: string;
  trueLabel: string;
  falseLabel: string;
  onFilter: (value: boolean) => void;
  onClear: () => void;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  position?: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center';
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[];
  active?: boolean;
  onActivate?: (visible: boolean) => void;
}

interface ICheckboxFilterState {
  visible: boolean;
  filtered: boolean;
  value: boolean;
}

export class CheckboxFilter extends React.Component<ICheckboxFilterProps, ICheckboxFilterState> {
  constructor(props: ICheckboxFilterProps) {
    super(props);
    this.state = {
      visible: false,
      filtered: this.props.active,
      value: false
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.active !== this.state.filtered) {
      if (next.active) {
        this.setState({ filtered: true });
      } else {
        this.setState({ filtered: false, visible: false, value: false });
        this.props.onActivate && this.props.onActivate(false);
      }
    }
  }

  private onChange = (e, { checked }) => {
    this.setState({ value: !!checked });
  };

  private onFilter = e => {
    e.preventDefault();
    if (this.props.onFilter) {
      this.props.onFilter(this.state.value);
    }
    this.setState({ filtered: true, visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClear = () => {
    if (this.props.onClear) {
      this.props.onClear();
    }
    this.setState({ visible: false, filtered: false, value: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onClose = () => {
    this.setState({ visible: false });
    this.props.onActivate && this.props.onActivate(false);
  };

  private onOpen = e => {
    e.preventDefault();
    this.setState({ visible: true });
    this.props.onActivate && this.props.onActivate(true);
  };

  render() {
    const { filterTitle, triggerTitle, size, position, on, trueLabel, falseLabel } = this.props;
    const { visible, filtered, value } = this.state;
    return (
      <Popup
        open={visible}
        trigger={
          <a
            href="!#"
            onClick={this.onOpen}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
          >
            {triggerTitle}&nbsp;
            <Icon name="filter" size="small" disabled={!filtered} />
          </a>
        }
        content={
          <Segment size="mini" basic>
            <Header as="h4">{filterTitle}</Header>
            <Divider />
            <Form size="tiny" className="checkbox-filter">
              <Form.Group>
                <Form.Field width={12}>
                  <Checkbox label={value ? trueLabel : falseLabel} toggle size="tiny" onChange={this.onChange} checked={value} />
                </Form.Field>
                <Form.Field width={4} className="filter-button-field">
                  <Button size="small" icon="filter" compact onClick={this.onFilter} />
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider />
            <Button size="tiny" compact onClick={this.onClear}>
              <Icon name="times" /> {i18n.t('Clear')}
            </Button>
          </Segment>
        }
        on={on || 'click'}
        position={position || 'bottom left'}
        size={size || 'mini'}
        onClose={this.onClose}
      />
    );
  }
}
