import { RequestStatus, ExtendedStatusValidation } from 'stores/requests/requests-store';
import { IdentityService, IUserInfo } from 'services/identity-service';
import { replaceAll } from './useful-functions';

export function userCanValidateGeaOrGmaRequest(
  requestType: 'GEA' | 'GMA',
  validationStatus: ExtendedStatusValidation,
  validatorRoles: string[],
  status: RequestStatus,
  userInfo: IUserInfo
): boolean {
  if (userInfo == null) return false;
  if (status.toString() !== 'Pending') return false;
  if (!['GMA', 'GEA'].includes(requestType)) return false;
  if (IdentityService.isAdmin(userInfo)) return true;
  if (IdentityService.isReporting(userInfo)) return false;

  if ((validatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''))) {
    if (validationStatus == null) return false;

    if (requestType === 'GMA') {
      return (
        (IdentityService.isPlanner(userInfo) && (validationStatus.validatedByPlanners || []).length === 0) ||
        (IdentityService.isGlobalManufacturing(userInfo) &&
          (validationStatus.validatedByPlanners || []).length !== 0 &&
          (validationStatus.validatedByGlobalManufacturers || []).length === 0) ||
        (IdentityService.isRegionalManufacturingVP(userInfo) &&
          (validationStatus.validatedByPlanners || []).length !== 0 &&
          (validationStatus.validatedByGlobalManufacturers || []).length !== 0 &&
          (validationStatus.validatedByRegionalManufacturersVP || []).length === 0)
      );
    } else if (requestType === 'GEA') {
      return (
        (IdentityService.isPlanner(userInfo) && (validationStatus.validatedByPlanners || []).length === 0) ||
        (IdentityService.isGlobalEngineering(userInfo) &&
          (validationStatus.validatedByPlanners || []).length !== 0 &&
          (validationStatus.validatedByGlobalEngineers || []).length === 0)
      );
    }
  }
  return false;
}

export function userCanRejectGeaOrGmaRequest(
  requestType: 'GEA' | 'GMA',
  validationStatus: ExtendedStatusValidation,
  validatorRoles: string[],
  status: RequestStatus,
  userInfo: IUserInfo
): boolean {
  if (userInfo == null) return false;
  if (status.toString() !== 'Pending') return false;
  if (!['GMA', 'GEA'].includes(requestType)) return false;
  if (IdentityService.isAdmin(userInfo)) return true;
  if (IdentityService.isReporting(userInfo)) return false;
  if (IdentityService.isPlanner(userInfo)) return false;
  return userCanValidateGeaOrGmaRequest(requestType, validationStatus, validatorRoles, status, userInfo);
}

export function userCanEditGeaOrGmaRequest(
  requestType: 'GEA' | 'GMA',
  validationStatus: ExtendedStatusValidation,
  creatorRoles: string[],
  status: RequestStatus,
  userInfo: IUserInfo
): boolean {
  if (userInfo == null) return false;
  if (!['Draft', 'Pending'].includes(status == null ? '' : status.toString())) return false;
  if (!['GMA', 'GEA'].includes(requestType)) return false;
  if (IdentityService.isAdmin(userInfo)) return true;
  if (IdentityService.isReporting(userInfo)) return false;

  return (
    (creatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', '')) &&
    [
      ...(validationStatus.validatedByPlanners || []),
      ...(validationStatus.validatedByGlobalEngineers || []),
      ...(validationStatus.validatedByGlobalManufacturers || []),
      ...(validationStatus.validatedByRegionalManufacturersVP || [])
    ].length === 0
  );
}
