import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { PlcTypeDto, PlcTypesStore } from '../../stores/configuration/machinery/plc-types-store';
import { nameof } from '../../utils/object';
import { Dropdown } from 'semantic-ui-react';
import { customEqualCompare } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';

interface MultiPlcTypeEditorProps extends WithTranslation {
  value: string | string[];
  onChange: (value: any) => void;
  placeholder?: string;
  plcTypes?: PlcTypesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readOnly?: boolean;
  className?: string;
  equipmentId?: string;
  oemId?: string;
  multiple?: boolean;
  plcTypeOptions?: ItemReference[];
}

interface MultiPlcTypeEditorState {
  plcTypeOptions: { text: string; value: string }[];
  isLoading: boolean;
  dataLoaded: boolean;
  selectedOemId: string;
  selectedEquipmentId: string;
  isAlreadyQuerying: boolean;
}

@connect(['plcTypes', PlcTypesStore])
class MultiPlcTypeEditor extends React.Component<MultiPlcTypeEditorProps, MultiPlcTypeEditorState> {
  private get plcTypeStore() {
    return this.props.plcTypes;
  }

  state: MultiPlcTypeEditorState = {
    plcTypeOptions: (this.props.plcTypeOptions || []).map(x => ({ text: x.title, value: x.id })),
    isLoading: false,
    dataLoaded: false,
    selectedOemId: null,
    selectedEquipmentId: null,
    isAlreadyQuerying: false
  };

  componentDidMount() {
    //this.loadPlcTypes();
  }

  componentDidUpdate({ equipmentId, oemId, plcTypeOptions }: MultiPlcTypeEditorProps) {
    if (!customEqualCompare(equipmentId, this.props.equipmentId) || !customEqualCompare(oemId, this.props.oemId)) this.loadPlcTypes();

    if (
      (this.props.plcTypeOptions || plcTypeOptions) &&
      (plcTypeOptions?.length !== this.props.plcTypeOptions?.length ||
        JSON.stringify(plcTypeOptions) !== JSON.stringify(this.props.plcTypeOptions))
    ) {
      this.setState({ plcTypeOptions: (this.props.plcTypeOptions || []).map(x => ({ text: x.title, value: x.id })) });
    }
  }

  private async loadPlcTypes() {
    if (this.state.isAlreadyQuerying) return;
    this.setState({ isAlreadyQuerying: true, isLoading: true });
    const filters = [];
    const { equipmentId, oemId } = this.props;

    if (equipmentId === this.state.selectedEquipmentId && oemId === this.state.selectedOemId) {
      this.setState({ isAlreadyQuerying: false });
      return;
    }

    if (equipmentId) {
      filters.push(`${nameof<PlcTypeDto>('equipmentTypeId')} eq ${equipmentId}`);
      this.setState({ selectedEquipmentId: equipmentId });
    } else {
      this.setState({ selectedEquipmentId: undefined });
    }

    if (oemId) {
      filters.push(`${nameof<PlcTypeDto>('oemId')} eq ${oemId}`);
      this.setState({ selectedOemId: oemId });
    } else {
      this.setState({ selectedOemId: undefined });
    }
    return await this.plcTypeStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        filter: filters,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<PlcTypeDto>('name'),
            useProfile: false
          }
        ]
      })
      .then(plcTypes => {
        const options = [];
        plcTypes.items.forEach(plcType => {
          options.push({ text: plcType.name, value: plcType.id });
        });
        this.setState({ plcTypeOptions: options, isLoading: false, dataLoaded: true, isAlreadyQuerying: false });
      });
  }

  onOpenDropDown = () => {
    if (!this.state.dataLoaded) {
      this.loadPlcTypes();
    }
  };

  public render() {
    const { t, className, multiple, value, onChange, placeholder, readOnly } = this.props;
    const { plcTypeOptions, isLoading } = this.state;
    const classname = className ? `${className}` : 'planit-user-dropdown planit__multi-plc__dropdown';

    return (
      <Dropdown
        search
        selection
        closeOnChange
        closeOnEscape
        selectOnBlur={false}
        clearable
        multiple={multiple}
        options={plcTypeOptions}
        className={classname}
        value={multiple ? value || [] : value}
        onChange={(_, { value }) => onChange(value)}
        disabled={readOnly}
        placeholder={placeholder || t('Select PLC Type')}
        onOpen={this.onOpenDropDown}
        loading={isLoading}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiPlcTypeEditor);
