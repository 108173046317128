import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import './profile-definition.less';
import { TableView, TableModel } from 'widgets/collections/table';
import { BaseDto } from 'stores/types';
import './profile-definition.less';
import ProfileDetailComponent from './profile-detail.component';
import NewProfileDefinition from './new-profile-definition';

import { ProfileItemStore, ProfileItemDto } from 'stores/profile/profile-store';
import { connect } from 'redux-scaffolding-ts';
import { Query } from 'stores/dataStore';
import { Message } from 'semantic-ui-react';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface ProfileDefinitionProps extends WithTranslation, RouteComponentProps {
  onClose: (isSuccess: boolean) => void;
  profileItemStore: ProfileItemStore;
  open: boolean;
  onCancelClicked?: () => void;
  onAcceptClicked?: () => void;
}

export interface ProfileDefinitionState {
  showNewModal: boolean;
  showEditModal: boolean;
  query: Query;
  selectedRow: ProfileItemDto;
  open: boolean;
}

export interface PruebaDto extends BaseDto {
  id: string; //Guid
  profileId: string;
  role: string;
}

@connect(['profileItemStore', ProfileItemStore])
class ProfileDefinitionPage extends React.Component<ProfileDefinitionProps, ProfileDefinitionState> {
  constructor(props: ProfileDefinitionProps) {
    super(props);
    this.state = {
      showNewModal: false,
      showEditModal: false,
      selectedRow: null,
      open: false,
      query: {
        searchQuery: '',
        filter: [],
        orderBy: [{ direction: 'Descending', field: 'friendlyId', useProfile: false }],
        skip: 0,
        take: 100000
      }
    };
  }
  componentDidMount = () => {
    this.load();
  };

  load = async () => {
    const { profileItemStore } = this.props;
    const { query, selectedRow } = this.state;
    await profileItemStore.getAllAsync(query);
    this.setState({ selectedRow: (profileItemStore.state.items || []).find(({ item }) => item?.id === selectedRow?.id)?.item || null });
  };

  private onToggleNewProfileModal = () => {
    this.setState(prevState => ({ showNewModal: !prevState.showNewModal }));
  };

  onSelectedRow = (selectedRow: ProfileItemDto) => {
    this.setState({ selectedRow });
  };

  onNewProfileHandler = () => {
    this.setState({ showNewModal: true });
  };

  onItemEdited = (itemId: string) => {
    this.props.profileItemStore.getAllAsync(this.state.query).then(item => {
      const editedItem = item.items.find(x => x.id === itemId);
      editedItem && this.setState({ selectedRow: editedItem });
    });
  };

  onItemCloned = (itemId: string) => {
    this.props.profileItemStore.getAllAsync(this.state.query).then(item => {
      const clonedItem = item.items.find(x => x.id === itemId);
      clonedItem && this.setState({ selectedRow: clonedItem });
    });
  };

  render() {
    const { t, profileItemStore } = this.props;
    const tableModel = {
      columns: [
        {
          title: t('Profile ID'),
          tooltipRenderer: false,
          renderer: data => data.friendlyId,
          selectableHeader: true
        },
        {
          title: t('Role'),
          tooltipRenderer: true,
          renderer: data => data.professionName,
          selectableHeader: true
        },
        {
          title: t('Assessment'),
          tooltipRenderer: true,
          renderer: data => data.assessment,
          selectableHeader: true
        }
      ],
      data: profileItemStore.state
    } as TableModel<ProfileItemDto>;

    return (
      <>
        <h3 className="profile-def__title">{t('Profile Definition')}</h3>
        <LineSeparator />
        {profileItemStore.state.result && !profileItemStore.state.result.isSuccess && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            list={profileItemStore.state.result.messages.map(m => m.body)}
          />
        )}
        <div className="profile-definition__wrapper flex-start">
          <>
            <div className="flex-between">
              <TableView
                selectable
                maxSelection={1}
                model={tableModel}
                onRefresh={this.load}
                onNewItem={this.onNewProfileHandler}
                canCreateNew={true}
                createNewButtonTitle={t('Create New Profile')}
                onSelectionChange={(selectedRows: ProfileItemDto[]) => this.onSelectedRow(selectedRows[0])}
                // selectedRow={this.state.selectedRow}
                onHideCheckbox
                isRowDisableLayout={(item: ProfileItemDto) => !item.isActive}
              />

              {this.state.selectedRow ? (
                <ProfileDetailComponent
                  onRefresh={this.load}
                  onItemEdited={this.onItemEdited}
                  onItemCloned={this.onItemCloned}
                  selectedRow={this.state.selectedRow}
                />
              ) : (
                <span className="empty-profile-definition-detail">{t('Please, select a profile definition')}</span>
              )}
            </div>
          </>

          {this.state.showNewModal && <NewProfileDefinition onRefresh={this.load} onClose={this.onToggleNewProfileModal} />}
        </div>
      </>
    );
  }
}

export default withTranslation()(ProfileDefinitionPage);
