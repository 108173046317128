import { injectable } from 'inversify';
import { Log, OidcClient, User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { constants } from './configuration-service';
import { isNullOrWhiteSpaces, firstToLowerCase } from 'utils/useful-functions';
import { UserDto } from 'stores/users/users-store';

export interface IUserInfo {
  userId: string;
  userName: string;
  email: string;
  roles: string[];
  locationsByRoles: { [key: string]: string[] | string };
  activeRole: string;
  firstName: string;
  lastName: string;
  acronym: string;
  isAssessor: boolean;
  isGlobalPoc: boolean;
  isPowerInstructor: boolean;
}

export enum Roles {
  Admin = 0,
  Planner = 1,
  PoC = 2,
  Instructor = 3,
  Employee = 4,
  Reporting = 5,
  FactoryLead = 6,
  GlobalEngineering = 7,
  GlobalManufacturing = 8,
  RegionalManufacturingVP = 9,
  PlannerMTC = 10
}

@injectable()
export class IdentityService {
  public userManager: UserManager;
  public oidcClient: OidcClient;
  public accessToken: string;
  public idToken: string;
  public userId: string;
  public userName: string;
  public email: string;
  public activeRole: string;
  private userInfo: IUserInfo;
  private userDto: UserDto;

  public static roles = [
    'Admin',
    'Planner',
    'PoC',
    'Instructor',
    'Employee',
    'Reporting',
    'Factory Lead',
    'Global Engineering',
    'Global Manufacturing',
    'Regional Manufacturing VP',
    'Planner MTC'
  ];

  public static rolesOrder = [
    'Admin',
    'Planner',
    'Planner MTC',
    'PoC',
    'Regional Manufacturing VP',
    'Global Manufacturing',
    'Global Engineering',
    'Factory Lead',
    'Instructor',
    'Reporting',
    'Employee'
  ];

  public static rolesSorter = (r1: string, r2: string) => {
    const idx1 = IdentityService.rolesOrder.findIndex(x => x === r1);
    const idx2 = IdentityService.rolesOrder.findIndex(x => x === r2);
    if (idx1 === -1) return 1;
    if (idx2 === -1) return -1;
    if (idx1 === idx2) return 0;
    return idx1 < idx2 ? -1 : 1;
  };

  public static rolesSorter2 = (r1: Roles, r2: Roles) => {
    const idx1 = IdentityService.rolesOrder.findIndex(x => x === IdentityService.roles[r1]);
    const idx2 = IdentityService.rolesOrder.findIndex(x => x === IdentityService.roles[r2]);
    if (idx1 === -1) return 1;
    if (idx2 === -1) return -1;
    if (idx1 === idx2) return 0;
    return idx1 < idx2 ? -1 : 1;
  };

  constructor() {
    this.isLoggedIn.bind(this);
    this.loadUser.bind(this);

    const settings = {
      authority: constants.authority,
      client_id: constants.clientId,
      redirect_uri: String(new URL('/signin-oidc', constants.clientRoot)),
      login: String(new URL('/login', constants.clientRoot)),
      automaticSilentRenew: true,
      silent_redirect_uri: String(new URL('/silentrefresh.html', constants.clientRoot)),
      post_logout_redirect_uri: String(new URL('/logout/callback', constants.clientRoot)),
      response_type: 'code',
      scope: constants.clientScope,
      audience: String(new URL(constants.clientRoot)), //is there a way to specific the audience when making the jwt
      responseType: 'code',
      userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    const oidcClientSettings = {
      authority: constants.authority,
      client_id: constants.clientId,
      response_type: 'code',
      scope: constants.clientScope,
      redirect_uri: String(new URL('/signin-oidc', constants.clientRoot)),
      post_logout_redirect_uri: String(new URL('/logout/callback', constants.clientRoot))
    };

    this.userManager = new UserManager(settings);
    this.oidcClient = new OidcClient(oidcClientSettings);

    Log.setLogger(console);
    Log.setLevel(Log.NONE);

    this.userManager.events.addUserLoaded(user => {
      //Log.level !== Log.NONE && Log.logger.info('events.addUserLoaded');
      console.info('events.addUserLoaded');
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    this.userManager.events.addSilentRenewError((e: any) => {
      //Log.level !== Log.NONE && Log.logger.info('silent renew error', e.message, e.error);
      console.info('silent renew error', e.message, e.error);
      // Login required is not a real error - we will just redirect the user to login when the API returns 401
      if (e.error && e.error !== 'login_required') {
        this.userManager.signinRedirect();
      }
    });

    this.userManager.events.addUserSignedOut(() => {
      //Log.level !== Log.NONE && Log.logger.info('The user has logged out. Redirecting to the sign in page.');
      console.info('The user has logged out. Redirecting to the sign in page.');
      this.userManager.signinRedirect();
    });

    this.userManager.events.addAccessTokenExpired(() => {
      //Log.level !== Log.NONE && Log.logger.info('token expired');
      console.info('token expired');
      this.signinSilent();
    });

    window['logger'] = Log;
  }

  public setupTest = () => {
    this.loadUser = () => {
      this.accessToken = 'access token';
      this.idToken = 'id token';
      this.userId = 'user Id';
      this.userName = 'user name';
      this.email = 'test@email.com';
      this.activeRole = 'Admin';
      this.userInfo = {
        activeRole: this.activeRole,
        email: this.email,
        userId: this.userId,
        userName: this.userName,
        roles: ['Admin'],
        locationsByRoles: {},
        firstName: 'user',
        lastName: 'name',
        acronym: 'UN',
        isAssessor: false,
        isGlobalPoc: false
      } as IUserInfo;
      return Promise.resolve();
    };
  };

  public signinRedirectCallback = () => {
    this.userManager
      .signinRedirectCallback()
      .then(() => {
        '';
      })
      .catch(e => {
        window.location.replace('/');
      });
  };

  public static isAdminOrPlanner = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'Planner', 'Planner MTC'].includes(userInfo.activeRole);
  };

  public static isAdminOrPoc = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC'].includes(userInfo.activeRole);
  };

  public static isInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Instructor'].includes(userInfo.activeRole);
  };

  public static isAdminOrPocOrWorker = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC', 'Employee'].includes(userInfo.activeRole);
  };

  public static isAdminOrPocOrWorkerOrPowerInstructor = (userInfo: IUserInfo): boolean => {
    return IdentityService.isAdminOrPocOrWorker(userInfo) || IdentityService.isPowerInstructor(userInfo);
  };

  public static isWorker = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Employee'].includes(userInfo.activeRole);
  };

  public static isAdmin = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return 'Admin' === userInfo.activeRole;
  };

  public static isAdminPocPlannerOrInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC', 'Planner', 'Planner MTC', 'Instructor'].includes(userInfo.activeRole);
  };

  public static isPoc = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['PoC'].includes(userInfo.activeRole);
  };

  public static isAdminOrInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'Instructor'].includes(userInfo.activeRole);
  };

  public static isAdminOrPocWorkerOrInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC', 'Employee', 'Instructor'].includes(userInfo.activeRole);
  };

  public static isAdminPocOrPlanner = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC', 'Planner', 'Planner MTC'].includes(userInfo.activeRole);
  };

  public static isAdminPocOrInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Admin', 'PoC', 'Instructor'].includes(userInfo.activeRole);
  };

  public static isPlannerTFT = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Planner'].includes(userInfo.activeRole);
  };

  public static isPlannerMTC = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Planner MTC'].includes(userInfo.activeRole);
  };

  public static isPlanner = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Planner', 'Planner MTC'].includes(userInfo.activeRole);
  };

  public static isReportingOrFactoryLeadOrGlobalEngineeringOrManufacturingOrVP = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Reporting', 'Factory Lead', 'Global Engineering', 'Global Manufacturing', 'Regional Manufacturing VP'].includes(
      userInfo.activeRole
    );
  };

  public static isWorkerPoCOrInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['PoC', 'Employee', 'Instructor'].includes(userInfo.activeRole);
  };

  public static isPowerInstructor = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return userInfo.isPowerInstructor && IdentityService.isInstructor(userInfo);
  };

  public static isAdminOrPowerInstructor = (userInfo: IUserInfo): boolean => {
    return IdentityService.isAdmin(userInfo) || IdentityService.isPowerInstructor(userInfo);
  };
  public static isAdminOrPocOrPowerInstructor = (userInfo: IUserInfo): boolean => {
    return IdentityService.isAdmin(userInfo) || IdentityService.isPoc(userInfo) || IdentityService.isPowerInstructor(userInfo);
  };

  public static isReporting = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Reporting'].includes(userInfo.activeRole);
  };

  public static isGlobalEngineering = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Global Engineering'].includes(userInfo.activeRole);
  };

  public static isGlobalManufacturing = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Global Manufacturing'].includes(userInfo.activeRole);
  };

  public static isRegionalManufacturingVP = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Regional Manufacturing VP'].includes(userInfo.activeRole);
  };

  public static isRoleWithLocations = (userInfo: IUserInfo): boolean => {
    if (userInfo == null) return false;
    return ['Reporting', 'PoC', 'Factory Lead'].includes(userInfo.activeRole);
  };

  public hasPocMultipleLocations = (): boolean => {
    const currentUser = this.getUserInfo();
    if (currentUser == null) return false;
    const t = currentUser.locationsByRoles['PoC'];
    if (t) {
      if (Array.isArray(t) && t.length > 1) return true;
    }
    return false;
  };

  public hasRoleMultipleLocations = (userInfo: IUserInfo): boolean => {
    const t = userInfo.locationsByRoles[userInfo.activeRole];
    if (t) {
      if (Array.isArray(t) && t.length > 1) return true;
    }
    return false;
  };

  public getCurrentRoleLocations() {
    if (this.isInRole(Roles.PoC)) return this.userInfo.locationsByRoles['PoC'] as string[];
    if (this.isInRole(Roles.FactoryLead)) return this.userInfo.locationsByRoles['Factory Lead'] as string[];
    if (this.isInRole(Roles.Reporting)) return this.userInfo.locationsByRoles['Reporting'] as string[];
    return null;
  }

  public getUser = async (): Promise<User | null> => {
    //Log.level !== Log.NONE && Log.logger.info('getUser');
    console.info('getUser');
    const user = await this.userManager.getUser();
    if (!user) {
      return await this.userManager.signinRedirectCallback();
    }
    return user;
  };

  public signinRedirect = (redirectUri?: string) => {
    //Log.level !== Log.NONE && Log.logger.info('signinRedirect');
    console.info('signinRedirect');
    this.clearUser();
    if (isNullOrWhiteSpaces(redirectUri)) localStorage.setItem('redirectUri', window.location.pathname);
    else localStorage.setItem('redirectUri', redirectUri);

    this.userManager.signinRedirect({});
  };

  private navigateToScreen = () => {
    // const redirectUri = !!localStorage.getItem("redirectUri")
    //     ? localStorage.getItem("redirectUri")
    //     : "en/";
    // const language = "/" + redirectUri.split("/")[1];

    // window.location.replace(language + "/");

    const redirectUri = !!localStorage.getItem('redirectUri') ? localStorage.getItem('redirectUri') : '/';

    window.location.replace(redirectUri);
  };

  public isAuthenticated = (): boolean => {
    return !!this.accessToken;
  };

  public async isLoggedIn(): Promise<boolean> {
    return (await this.userManager.getUser()) != null;
  }

  private clearUser = () => {
    //Log.level !== Log.NONE && Log.logger.info('clearUser');
    console.info('clearUser');
    this.accessToken = '';
    this.idToken = '';
    this.userId = '';
    this.userName = '';
    this.userInfo = null;
    this.email = '';
    this.activeRole = '';
  };

  public async loadUser(usr: User = null): Promise<void> {
    const user = usr || (await this.userManager.getUser());
    if (user != null) {
      this.accessToken = user.access_token;
      this.idToken = user.id_token;
      this.userId = user.profile.sub;
      this.userName = user.profile.name;
      this.email = user.profile.email;

      let roles: string[] = user.profile.role != null ? (Array.isArray(user.profile.role) ? user.profile.role : [user.profile.role]) : [];
      roles = roles.filter(x => IdentityService.roles.includes(x)).sort(IdentityService.rolesSorter);

      const pocLocations: string[] =
        user.profile.poC != null ? (Array.isArray(user.profile.poC) ? user.profile.poC : [user.profile.poC]) : [];

      const reportingLocations: string[] =
        user.profile.reporting != null ? (Array.isArray(user.profile.reporting) ? user.profile.reporting : [user.profile.reporting]) : [];

      let factoryLeadProfile = user.profile[firstToLowerCase(IdentityService.roles[Roles.FactoryLead])];
      const factoryLeadLocations: string[] =
        factoryLeadProfile != null ? (Array.isArray(factoryLeadProfile) ? factoryLeadProfile : [factoryLeadProfile]) : [];

      let globalManufacturingProfile = user.profile[firstToLowerCase(IdentityService.roles[Roles.GlobalManufacturing])];
      const globalManufacturingRegions: string[] =
        globalManufacturingProfile != null
          ? Array.isArray(globalManufacturingProfile)
            ? globalManufacturingProfile
            : [globalManufacturingProfile]
          : [];

      let regionalManufacturingVPProfile = user.profile[firstToLowerCase(IdentityService.roles[Roles.RegionalManufacturingVP])];
      const regionalManufacturingVPRegions: string[] =
        regionalManufacturingVPProfile != null
          ? Array.isArray(regionalManufacturingVPProfile)
            ? regionalManufacturingVPProfile
            : [regionalManufacturingVPProfile]
          : [];

      const employeeLocation: string = (user.profile.employee || '') as string;
      const instructorLocation: string = (user.profile.instructor || '') as string;
      const locationsByRoles: { [key: string]: string[] | string } = {};

      const isAssessor: boolean = user.profile.is_assessor ? (user.profile.is_assessor as string).toLowerCase() === 'true' : false;
      const isGlobalPoc: boolean = user.profile.is_global_poc ? (user.profile.is_global_poc as string).toLowerCase() === 'true' : false;

      const isPowerInstructor: boolean = user.profile.is_power_instructor
        ? (user.profile.is_power_instructor as string)?.toLowerCase() === 'true'
        : false;

      //locationsByRoles[IdentityService.roles[Roles.Employee]] = employeeLocation;

      if (roles.includes(IdentityService.roles[Roles.Employee])) locationsByRoles[IdentityService.roles[Roles.Employee]] = employeeLocation;

      if (roles.includes(IdentityService.roles[Roles.PoC])) locationsByRoles[IdentityService.roles[Roles.PoC]] = pocLocations;

      if (roles.includes(IdentityService.roles[Roles.Instructor]))
        locationsByRoles[IdentityService.roles[Roles.Instructor]] = instructorLocation;

      if (roles.includes(IdentityService.roles[Roles.FactoryLead]))
        locationsByRoles[IdentityService.roles[Roles.FactoryLead]] = factoryLeadLocations;

      if (roles.includes(IdentityService.roles[Roles.Reporting]))
        locationsByRoles[IdentityService.roles[Roles.Reporting]] = reportingLocations;

      if (roles.includes(IdentityService.roles[Roles.GlobalManufacturing]))
        locationsByRoles[IdentityService.roles[Roles.GlobalManufacturing]] = globalManufacturingRegions;

      if (roles.includes(IdentityService.roles[Roles.RegionalManufacturingVP]))
        locationsByRoles[IdentityService.roles[Roles.RegionalManufacturingVP]] = regionalManufacturingVPRegions;

      const firstName = user.profile['given_name'] || '';
      const lastName = user.profile['family_name'] || '';
      let acronym = [firstName[0], lastName[0], firstName[1], lastName[1]]
        .join('')
        .slice(0, 2)
        .toUpperCase();

      const activeRole = localStorage.getItem('activeRole') || roles.firstOrDefault();
      this.activeRole = roles.includes(activeRole) ? activeRole : roles.firstOrDefault();
      localStorage.setItem('activeRole', this.activeRole);

      this.userInfo = {
        email: this.email,
        userId: this.userId,
        userName: this.userName,
        activeRole: this.activeRole,
        roles,
        locationsByRoles,
        firstName,
        lastName,
        acronym,
        isAssessor,
        isGlobalPoc,
        isPowerInstructor
      } as IUserInfo;
    } else {
      this.clearUser();
    }
  }

  public signinSilent = async (nonSilent: boolean = false) => {
    //Log.level !== Log.NONE && Log.logger.info('signinSilent');
    console.info('signinSilent');
    try {
      const user = await this.userManager.signinSilent();
      //Log.level !== Log.NONE && Log.logger.info('signed in', user);
      console.info('signed in', user);
      await this.loadUser(user);
      if (nonSilent) this.userManager.signinRedirect();
    } catch (err) {
      //Log.level !== Log.NONE && Log.logger.info('signinSilent', err);
      console.info('signinSilent', err);
      this.clearUser();
      this.userManager.signinRedirect();
    }
  };

  public signinSilentCallback = () => {
    this.userManager.signinSilentCallback().then(_ => this.loadUser());
  };

  public createSigninRequest = () => {
    //Log.level !== Log.NONE && Log.logger.info('createSigninRequest');
    console.info('createSigninRequest');
    //return this.userManager.createSigninRequest();
    return this.oidcClient.createSigninRequest({});
  };

  public logout = () => {
    let token = this.idToken;
    this.userManager.signoutRedirect({ id_token_hint: token });
    localStorage.clear();
  };

  public signoutRedirectCallback = () => {
    this.userManager.signoutRedirectCallback().then(() => {
      this.clearUser();
      this.userManager.removeUser();
      this.userManager.clearStaleState();
      localStorage.clear();
      this.signinRedirect(constants.clientRoot);
    });
  };

  public getUserInfo = (): IUserInfo => {
    return this.userInfo ? Object.assign({}, this.userInfo) : null;
  };

  public isInRole = (role: Roles): boolean => {
    return this.isAuthenticated && this.userInfo != null && this.userInfo.activeRole === IdentityService.roles[role];
  };

  public isInAnyRole = (roles: Roles[]): boolean => {
    return this.isAuthenticated && this.userInfo != null && roles.some(x => this.userInfo.activeRole === IdentityService.roles[x]);
  };

  public hasRole = (role: Roles): boolean => {
    return this.isAuthenticated && this.userInfo != null && this.userInfo.roles.includes(IdentityService.roles[role]);
  };

  public changeActiveRole = (role: Roles) => {
    if (this.userInfo.roles.includes(IdentityService.roles[role])) {
      this.activeRole = this.userInfo.activeRole = IdentityService.roles[role];
      localStorage.setItem('activeRole', IdentityService.roles[role]);
    }
  };

  public setCurrentUserDto = (dto: UserDto) => {
    this.userDto = dto;
  };
  public getCurrentUserDto = () => {
    return this.userDto;
  };
}
