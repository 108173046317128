import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import '../assets/less/not-found.less';
import { Header, Container } from 'semantic-ui-react';

interface UnavailableProps extends WithTranslation {
  textToShow: string;
}

const NoAvailableTest: FC<UnavailableProps> = ({ t, textToShow }) => {
  return (
    <Container style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Header icon> {t(textToShow)}</Header>
      <span>
        {t('Click')} <a href="/">{t('here')}</a> {t('to return to home')}
      </span>
    </Container>
  );
};

export default withTranslation()(NoAvailableTest);
