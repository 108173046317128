import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, Divider, Tab } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormStore, AssessorAnswerStatusDto, SsaFormStatus } from 'stores/assessments/forms/ssa-forms-store';
import SSAFormTabComponent from './ssa-form-tab-component';

interface SSAFormMainComponentProps extends WithTranslation {
  ssaFormStore?: SsaFormStore;
  isCurrentUserAssessor: boolean;
  isInRoleAdminOrPoC: boolean;
  assessorAnswerStatus: AssessorAnswerStatusDto;
  currentUser: string;
  formStatus: SsaFormStatus;
  onExportExcel?: () => void;
}

interface SSAFormMainComponentState {
  instructionsAccordionOpen: boolean;
}

@connect(['ssaFormStore', SsaFormStore])
class SSAFormMainComponent extends Component<SSAFormMainComponentProps, SSAFormMainComponentState> {
  state: SSAFormMainComponentState = { instructionsAccordionOpen: true };

  mapToSsaObject = () => {
    const { assessorAnswerStatus, currentUser, isInRoleAdminOrPoC, formStatus, isCurrentUserAssessor, onExportExcel } = this.props;
    const { item } = this.ssaFormStore.state;
    const panes = [];
    let catSections: { name: string; skillIds: string[] }[] = [];
    item.categorySections.forEach(({ name, skillSections }) => {
      const skillIds: string[] = [];
      let catSection = { name, skillIds };
      catSections.push(catSection);
      if (!isInRoleAdminOrPoC) {
        skillSections.forEach(({ skillId }) => {
          const user = item.functionalExperts.find(({ userId }) => currentUser === userId);
          if (user != null) {
            const skill = user.skills.find(({ id }) => id === skillId);
            skill && skillIds.push(skill.id);
          } else {
            const ids = skillSections.map(({ skillId }) => skillId);
            skillIds.push(...ids);
          }
        });
      } else {
        const ids = skillSections.map(({ skillId }) => skillId);
        skillIds.push(...ids);
      }
    });

    (catSections || [])
      .filter(({ skillIds }) => skillIds.length > 0)
      .forEach(({ name, skillIds }, index) => {
        panes.push({
          menuItem: name,
          render: () => (
            <>
              <SSAFormTabComponent
                isCurrentUserAssessor={isCurrentUserAssessor}
                formStatus={formStatus}
                isInRoleAdminOrPoC={isInRoleAdminOrPoC}
                assessorAnswerStatus={assessorAnswerStatus}
                skillsIds={skillIds}
                key={index}
                category={name}
                onExportExcel={onExportExcel}
              />
            </>
          )
        });
      });
    return panes;
  };

  private get ssaFormStore() {
    return this.props.ssaFormStore;
  }

  private hasAssessorAnswerStatus = (): boolean => {
    const { assessorAnswerStatus, isCurrentUserAssessor, isInRoleAdminOrPoC } = this.props;
    if (isCurrentUserAssessor && !isInRoleAdminOrPoC && assessorAnswerStatus && assessorAnswerStatus.comment) {
      return true;
    }
  };

  toggleAccordion = () => this.setState(({ instructionsAccordionOpen }) => ({ instructionsAccordionOpen: !instructionsAccordionOpen }));

  render() {
    const { instructionsAccordionOpen } = this.state;
    const { item } = this.ssaFormStore.state;
    const { formStatus, assessorAnswerStatus } = this.props;
    const panes = this.mapToSsaObject();
    return (
      <div>
        {item.ssaAssessment.ssaTemplate.header && (
          <>
            <div className="header-instructions-accordion-spacer ">
              <Accordion className="tna-header-accordion">
                <Accordion.Title
                  style={{ backgroundColor: '#ccf0ee' }}
                  active={instructionsAccordionOpen}
                  onClick={this.toggleAccordion}
                  className="tna-header-accordion__title"
                />
                <Accordion.Content active={instructionsAccordionOpen}>
                  <div className="tna-edit-form__header__evaluation-criteria">
                    <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>{item.ssaAssessment.ssaTemplate.header}</pre>
                  </div>
                </Accordion.Content>
              </Accordion>
            </div>
            <Divider />
          </>
        )}

        {this.hasAssessorAnswerStatus && formStatus === 'Returned' && assessorAnswerStatus && (
          <div className="ssa-form__returned__assessor-comments">{assessorAnswerStatus.comment}</div>
        )}
        <Tab menu={{ secondary: true, pointing: true }} className="tna-form-main-tabs" panes={panes} />
      </div>
    );
  }
}
export default withTranslation()(SSAFormMainComponent);
