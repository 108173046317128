import { BaseDto, CommandResult } from '../../types';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult, Query } from '../../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AbstractValidator, ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import i18n from 'i18n';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';
import { IwsTemplateDto } from '../templates/iws-templates-store';
import { AssessorAnswerStatus, ChangeActiveFlagTnaFormDto, FunctionalExpertSkillDto, SimpleUserDto } from './tna-forms-store';

export interface IwsFormListDto extends BaseDto {
  id: string;
  title: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  profiles: IdNameDto[];
  profileIds: string[];
  profileNames: string[];
  createAt: string;
  deadline1: string;
  deadline2: string;
  deadline3: string;
  status: string;
  outOfDate1: boolean;
  outOfDate2: boolean;
  outOfDate3: boolean;
  isActive: boolean;
  isStarted: boolean;
  extendedStatus: string[];
}

export interface IwsFormItemDto {
  id: string;
  friendlyId: string;
  assessorAnswerStatus: AssessorAnswerStatus[];
  userId: string;
  user: SimpleUserDto;
  iwsAssessmentId: string;
  iwsAssessment: IwsAssessmentDto;
  qualifiers: QualifierDto[];
  status: IwsFormStatus;
  deadline1: string;
  deadline2: string;
  deadline3: string;
  outOfDate1: boolean;
  outOfDate2: boolean;
  outOfDate3: boolean;
  answers?: IwsFormAnswersDto;
  currentLanguage?: string;
  isActive: boolean;
}

export interface IdNameDto {
  id: string;
  name: string;
}

export interface QualifierDto {
  userId: string;
  user: SimpleUserDto;
  templates: QualifierTemplateDto[];
}
export interface QualifierTemplateDto {
  templateId: string;
  template: IwsTemplateDto;
  skills: FunctionalExpertSkillDto[];
}

export interface IwsFormAnswersDto {
  templateSectionAnswers: TemplateSectionAnswersDto[];
}

export interface TemplateSectionAnswersDto {
  templateId: string;
  templateName: string;
  iwsSkillAnswers: IwsSkillAnswerDto[];
  employeeFirstAssessmentComment: string;
  employeeSecondAssessmentComment: string;
  qualifierComment: string;
}
export interface IwsSkillAnswerDto {
  skillId: string;
  skillName: string;
  average: Average;
  questionAnswers: IwsQuestionAnswerDto[];
  qualifierRecomendation: string;
  templateId?: string;
}

export interface ChangeIwsFormAnswerDto {
  id: string; // IWS Form Id
  templateSectionAnswers: ChangeTemplateSectionAnswersDto[];
}

export interface ChangeTemplateSectionAnswersDto {
  templateId: string;
  iwsSkillAnswers: ChangeIwsSkillAnswerDto[];
  employeeFirstAssessmentComment: string;
  employeeSecondAssessmentComment: string;
  qualifierComment: string;
}

export interface ChangeIwsSkillAnswerDto {
  skillId: string;
  questionAnswers: ChangeQuestionAnswerDto[];
  qualifierRecomendation: string;
  templateId?: string;
}

export interface ChangeSkillAnswersDto {
  skillId: string;
  questionAnswers: ChangeQuestionAnswerDto[];
  qualifierRecomendation?: string;
}

export interface Average {
  firstEmployeeAverage: number;
  secondEmployeeAverage: number;
  qualifierAverage: number;
}

export interface IwsQuestionAnswerDto {
  questionId: string;
  questionText: string;
  average: Average;
  question: QuestionDto;
  questionTranslations: QuestionDto[];
  employeeFirstAssessmentAnswer: UserAnswerDto;
  employeeSecondAssessmentAnswer: UserAnswerDto;
  qualifierAnswer: UserAnswerDto;
}

export interface QuestionDto {
  text: string;
  languageId: string;
}

export interface ChangeQuestionAnswerDto {
  questionId: string;
  answer: number;
  comment: string;
}

export interface UserAnswerDto {
  answer: number;
  comment: string;
}
export interface IwsAssessmentDto {
  id: string;
  title: string;
  iwsTemplateIds: string[];
  iwsTemplates: IwsTemplateDto[];
  //functionalExpert?: any;
}

export enum IwsFormStatus {
  Unknown = 0,
  FirstAssesment = 10,
  SecondAssesment = 20,
  Qualifier = 30,
  Done = 40
}
export type IwsFormStatusKeys = keyof typeof IwsFormStatus;

export enum IwsFormStatusDescp {
  FirstAssesment = '1st Assessment',
  SecondAssesment = '2nd Assessment',
  Qualifier = 'Qualifier',
  OutOfDate1stAssessment = 'Out of date 1st',
  OutOfDate2ndAssessment = 'Out of date 2nd',
  OutOfDateQualifier = 'Out of date qualifier',
  Done = 'Done'
}
export type IwsFormStatusDescpKeys = keyof typeof IwsFormStatusDescp;

//CHANGE IWS FORM WIZARD

export interface ChangeIwsFormDto {
  id: string;
  employee: ChangeEmployeeIwsFormDto;
  deadline1: string;
  deadline2: string;
  deadline3: string;
}

export interface ChangeEmployeeIwsFormDto {
  userId: string;
  qualifiers: ChangeQualifierDto[];
}

export interface ChangeQualifierDto {
  userId: string;
  templateId: string;
  skills: string[];
}

export interface SendIwsFormBackToInstructorsDto {
  formId: string;
  assesorIds: string[];
  formStatus: IwsFormStatus;
}

export class IwsFormListValidator extends AbstractValidator<IwsFormListDto> {}

export class IwsFormChangeAnswerValidator extends ExtendedAbstractValidator<ChangeIwsFormAnswerDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfString(x => x.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('IwsForm Id is required'));
  }
}
export class IwsFormSendAnswerValidator extends ExtendedAbstractValidator<ChangeIwsFormAnswerDto> {
  constructor(status: string, qualifierDto: QualifierDto, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.templateSectionAnswers || []).all(
          temp =>
            temp != null && new TemplateSectionAnswerViewModelValidator(status, qualifierDto, this.addErrors).extendValidate(temp).isValid()
        )
      )
      .withFailureMessage(
        i18n.t('Please check the comments in the footer. You must add a comment for each template before sending the IWS SuC.')
      );
  }
}

class TemplateSectionAnswerViewModelValidator extends ExtendedAbstractValidator<ChangeTemplateSectionAnswersDto> {
  constructor(status: string, qualifierDto: QualifierDto, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(x => x.employeeFirstAssessmentComment)
      .isNotEmpty()
      .when(x => status === 'FirstAssesment');

    this.validateIfString(x => x.employeeSecondAssessmentComment)
      .isNotEmpty()
      .when(x => status === 'SecondAssesment');

    this.validateIfString(x => x.qualifierComment)
      .isNotEmpty()
      .when(x => status === 'Qualifier' && qualifierDto?.templates.any(q => q.templateId === x.templateId));
  }
}

@repository('@@IWSFORMLISTS', 'iwsformlist.changeanswer')
export class IwsFormChangeAnswerStore extends FormStore<ChangeIwsFormAnswerDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-iws-form-answers';
  updatePath = 'v1/save-iws-form-answers';
  sendPath = 'v1/send-iws-form-answers';
  deletePath = '';
  RETRIEVE_ONE_ANSWERS = 'RETRIEVE_ONE_ANSWERS';
  public EXCEL_EXPORTED = 'EXCEL_EXPORTED';
  exportToExcelPath = 'v1/export-iws-form';
  changeStatusPath = 'v1/send-iws-form-status-back';

  protected validate(item: ChangeIwsFormAnswerDto) {
    return new IwsFormChangeAnswerValidator().validate(item as any);
  }

  protected validateOnSend(item: ChangeIwsFormAnswerDto, status: string, qualifierDto: QualifierDto) {
    return new IwsFormSendAnswerValidator(status, qualifierDto).validate(item as any);
  }

  constructor() {
    super('CHANGEANSWERIWSFORMLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });

    this.addReducer(
      this.RETRIEVE_ONE_ANSWERS,
      () => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => ({
            ...this.state,
            isBusy: false,
            count: value.data.count
          }),
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );
  }

  public async SendIwsForm(status: string, qualifierDto: QualifierDto) {
    const sendValidation = this.validateOnSend(this.state.item, status, qualifierDto);

    if (sendValidation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, sendValidation);
    } else {
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<ChangeIwsFormAnswerDto, CommandResult<ChangeIwsFormAnswerDto>>(`${this.baseUrl}/${this.sendPath}`, this.state.item)
      );
      return result.data;
    }
  }

  public async getIwsFormAnswersByFormId(id: string): Promise<IwsFormAnswersDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_ANSWERS,
      httpService.get<IwsFormAnswersDto>(`${this.baseUrl}/${this.retrievePath}/${id}`)
    );
    return result.data;
  }

  public async exportToExcel(id: string) {
    const httpService = container.get(HttpService);
    return await this.dispatchAsync(
      this.EXCEL_EXPORTED,
      httpService.download(`${this.baseUrl}/${this.exportToExcelPath}/${id}`, `IWS-Suc-${id}`, null, this.state.filters)
    );
  }

  public async sendBackStatus(statusSelected: string, formId: string, assesors: string[]): Promise<CommandResult<IwsFormItemDto>> {
    let body = {
      formId: formId,
      assesorIds: assesors,
      formStatus: statusSelected === 'qualifier' ? IwsFormStatus.Qualifier : IwsFormStatus.SecondAssesment
    } as SendIwsFormBackToInstructorsDto;
    let result = null;
    const httpService = container.get(HttpService);
    result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<SendIwsFormBackToInstructorsDto, CommandResult<IwsFormItemDto>>(`${this.baseUrl}/${this.changeStatusPath}`, body)
    );
    if (result) return result.data;
    return null;
  }
}

@repository('@@IWSFORMLISTS', 'iwsformlist.summary')
export class IwsFormListStore extends DataStore<IwsFormListDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-iws-forms';
  retrieveAssessorsPath = 'v1/get-assessor-iws-forms';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'v1/get-iws-form';
  RETRIEVE_ONE_IWS_FORM = 'RETRIEVE_ONE_IWS_FORM';
  CHANGE_ANSWER_VALUES = 'CHANGE_ANSWER_VALUES';
  CHANGE_CURRENT_LANGUAGE = 'CHANGE_CURRENT_LANGUAGE';

  protected validate(item: IwsFormListDto) {
    return new IwsFormListValidator().validate(item as any);
  }

  constructor() {
    super('IWSFORMLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {},
      item: {}
    });

    this.addReducer(
      this.RETRIEVE_ONE_IWS_FORM,
      () => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => {
            return {
              ...this.state,
              isBusy: false,
              count: value.data.count,
              rawData: value.data,
              item: value.data
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.CHANGE_ANSWER_VALUES,
      (answers: Partial<IwsFormAnswersDto>) => {
        return {
          ...this.state,
          item: { ...this.state.item, answers: { ...this.state.item.answers, ...answers } }
        };
      },
      'Simple'
    );

    this.addReducer(
      this.CHANGE_CURRENT_LANGUAGE,
      (currentLanguage: string) => {
        return {
          ...this.state,
          item: { ...this.state.item, currentLanguage }
        };
      },
      'Simple'
    );
  }

  public changeAnswers = (answers: Partial<IwsFormAnswersDto>) => this.dispatch(this.CHANGE_ANSWER_VALUES, answers);

  public changeCurrentLanguage = (language: string) => {
    this.dispatch(this.CHANGE_CURRENT_LANGUAGE, language);
  };

  public async getFormById(id: string): Promise<IwsFormItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_IWS_FORM,
      httpService.get<IwsFormItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getAssessorIwsFormList(query: Query, data?: any): Promise<QueryResult<IwsFormListDto>> {
    const httpService = container.get(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<IwsFormListDto>>(`${this.baseUrl}/${this.retrieveAssessorsPath}?${path}`, data)
    );
    return result.data;
  }
}

export interface CreateIwsFormDto {
  title: string;
  templateIds: string[];
  employees: CreateEmployeeIwsFormDto[];
  deadline1: string;
  deadline2: string;
  deadline3: string;
  skillCount: number;
  templatesWithLocalQuestions: CreateTemplateWithLocalQuestions[];
  profilesIds: string[];
}

export interface CreateTemplateWithLocalQuestions {
  templateId: string;
  skillsWithLocalQuestions: CreteSkillWithLocalQuestions[];
}

export interface CreteSkillWithLocalQuestions {
  skillId: string;
  localQuestions: string[];
}
export interface CreateEmployeeIwsFormDto {
  userId: string;
  qualifiers: CreateQualifierDto[];
}

export interface CreateQualifierDto {
  userId: string;
  templateId: string;
  skills: string[];
}

export class CreateQualifierDtoValidator extends ExtendedAbstractValidator<CreateQualifierDto> {
  constructor(idx: number, idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1} Qualifier ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfString(x => x.templateId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Templates info are required')}`);

    this.validateIfEachString(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.skills != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}

export class CreateEmployeeIwsFormDtoValidator extends ExtendedAbstractValidator<CreateEmployeeIwsFormDto> {
  constructor(idx: number, templateIds: string[], onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Employee info is wrong')}`);
    this.validateIf(x => x.qualifiers)
      .fulfills(x => (x || []).all((i, j) => new CreateQualifierDtoValidator(idx, j, this.addErrors).extendValidate(i).isValid()))
      .when(x => x != null && (x.qualifiers || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Qualifiers info is wrong')}`);
  }
}
export class CreateIwsFormValidator extends ExtendedAbstractValidator<CreateIwsFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('IWS Title is required'));

    this.validateIfString(x => x.deadline1)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('1st Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline1)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline1))
      .withFailureMessage(i18n.t('1st Assessment Deadline date is wrong'));

    this.validateIfString(x => x.deadline1)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline1) && moment(x.deadline1).isValid())
      .withFailureMessage(i18n.t('1st Assessment Deadline date cant be in the past'));

    this.validateIfString(x => x.deadline2)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('2nd Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline2)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline2))
      .withFailureMessage(i18n.t('2nd Assessment Deadline date is wrong'));

    this.validateIfString(x => x.deadline2)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline2) && moment(x.deadline2).isValid())
      .withFailureMessage(i18n.t('2nd Assessment Deadline date cant be in the past'));

    this.validateIfString(x => x.deadline3)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('3rd Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline3)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline3))
      .withFailureMessage(i18n.t('3rd Assessment Deadline date is wrong'));

    this.validateIfString(x => x.deadline3)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline3) && moment(x.deadline3).isValid())
      .withFailureMessage(i18n.t('3rd Assessment Deadline date cant be in the past'));

    this.validateIfEachString(x => x.templateIds)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('IWS Template is required'));

    this.validateIfIterable(x => x.employees)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)

      .withFailureMessage(i18n.t('Employees and Qualifiers info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        (x.employees || []).all((i, idx) =>
          new CreateEmployeeIwsFormDtoValidator(idx, x.templateIds, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => x != null && (x.employees || []).length !== 0 && x.skillCount > 0)
      .withFailureMessage(i18n.t('Employees and Qualifiers info is wrong'));
  }
}

export class ChangeFunctionalExpertDtoValidator extends ExtendedAbstractValidator<ChangeQualifierDto> {
  constructor(idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee Qualifier ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfString(x => x.templateId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Templates info are required')}`);

    this.validateIfEachString(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.skills != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}

export class ChangeEmployeeIwsFormDtoValidator extends ExtendedAbstractValidator<ChangeEmployeeIwsFormDto> {
  constructor(skillCount: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Employee info is wrong')}`);
  }
}

export class ChangeIwsFormWizardValidator extends ExtendedAbstractValidator<ChangeIwsFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.deadline1)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('1st Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline1)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline1))
      .withFailureMessage(i18n.t('1st Assessment Deadline date is wrong'));

    this.validateIfString(x => x.deadline2)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('2nd Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline2)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline2))
      .withFailureMessage(i18n.t('2nd Assessment Deadline date is wrong'));

    this.validateIfString(x => x.deadline3)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('3rd Assessment Deadline date is required'));

    this.validateIfString(x => x.deadline3)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline3))
      .withFailureMessage(i18n.t('3rd Assessment Deadline date is wrong'));

    this.validateIf(x => x.employee)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Employees and Assesors info is required'));
  }
}

@repository('@@IWSFORMLISTS', 'iwsformlist.new')
export class NewIwsFormStore extends FormStore<CreateIwsFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-forms';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateIwsFormDto) {
    return new CreateIwsFormValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_IWSTEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@IWSFORMLISTS', 'iwsformlist.change')
export class ChangeIwsFormWizardStore extends FormStore<ChangeIwsFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-forms';
  retrievePath = '';
  updatePath = 'update-iws-form';

  protected validate(item: ChangeIwsFormDto) {
    return new ChangeIwsFormWizardValidator().extendValidate(item);
  }

  constructor() {
    super('EDIT_IWSFORM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@IWSFORMLISTS', 'iwsformlist.change')
export class ChangeActiveIwsFormIwsStore extends FormStore<ChangeActiveFlagTnaFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-iws-template';
  retrievePath = 'get-iws-templates';
  retrievePathByProfile = 'get-iws-templates-by-profiles';
  updatePath = 'update-active-flag-iws-form';

  protected validate(_: ChangeActiveFlagTnaFormDto) {
    return new ValidationResult();
  }
  constructor() {
    super('CHANGE_ACTIVE_IWSFORM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
