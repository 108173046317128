import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { DropDownPriorityStore, PriorityDto } from '../../stores/configuration/events-n-requests/priorities-store';
import { SelectionInput, SelectionItem } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';

interface PriorityEditorProps extends WithTranslation {
  value: ItemReference;
  onChange?: (value: ItemReference) => void;
  className?: string;
  placeholder?: string;
  priorities?: DropDownPriorityStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
  direction?: 'left' | 'right';
  loadDataOnOpen?: boolean;
}

interface PriorityEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['priorities', DropDownPriorityStore])
class PriorityEditor extends React.Component<PriorityEditorProps, PriorityEditorState> {
  private get prioritiesStore() {
    return this.props.priorities;
  }

  constructor(props: PriorityEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getPriorityMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.value !== this.props.value && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        options: getOptionsFromValue(this.state?.query, next.value)
        // query: this.getPriorityMethod()
      });
    }
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getPriorityMethod() });
    }
  };
  @autobind
  private getPriorityMethod() {
    const method = async (search: string) => {
      const result = await this.prioritiesStore.getAllAsync(
        {
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<PriorityDto>('name'), useProfile: false }],
          filter: isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<PriorityDto>('name')}), '${search.toLowerCase()}')`]
        },
        null,
        search
      );
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;
    return (
      <SelectionInput
        direction={this.props.direction}
        content={item => <div>{item.title}</div>}
        searchable
        className={this.props.className}
        readOnly={this.props.readOnly}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(PriorityEditor);
