import React from 'react';
import * as autobind from 'autobind';
import { connect } from 'redux-scaffolding-ts';
import { ChangePatternStore, ChangePatternDto } from '../../../../../stores/configuration/machinery/patterns-store';
import PatternRowEditItem from './pattern-row-item-edit';
import { ChangePatternRowDto } from 'stores/configuration/machinery/pattern-rows-store';

interface PatternRowsListProps {
  changePattern?: ChangePatternStore;
  onChange?: (dto: ChangePatternDto) => void;
}

interface PatternRowsListState {}

@connect(['changePattern', ChangePatternStore])
class PatternRowsListEdit extends React.Component<PatternRowsListProps, PatternRowsListState> {
  private get changePatternStore() {
    return this.props.changePattern;
  }

  @autobind
  private onDelete(index: number, row: ChangePatternRowDto) {
    const item = this.changePatternStore.state.item;
    item.patternRows = this.changePatternStore.state.item.patternRows.filter((p, i) => {
      return i !== index;
    });
    this.changePatternStore.change(item);
    if (this.props.onChange) this.props.onChange(item);
  }

  private itemChange = (nextPattern, idx) => {
    const item = this.changePatternStore.state.item;
    item.patternRows[idx] = nextPattern;
    this.changePatternStore.change(item);
    if (this.props.onChange) this.props.onChange(item);
  };

  render() {
    if (this.changePatternStore.state.item.patternRows && this.changePatternStore.state.item.patternRows.length > 0) {
      let tagsHtml = this.changePatternStore.state.item.patternRows.map((item, index) => {
        return (
          <div className="four wide column">
            <PatternRowEditItem index={index} patternRow={item} onDelete={this.onDelete} onChange={this.itemChange} />
          </div>
        );
      });

      return <div>{tagsHtml}</div>;
    } else {
      return <div></div>;
    }
  }
}

export default PatternRowsListEdit;
