import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import IndividualTabPaneSearch, { IndividualTabPaneSearchFilters } from './individual-tab-pane-search';
import IndividualTabPaneList from './individual-tab-pane-list';
import { Query, QueryParameters } from 'stores/dataStore';
import { SsaDevelopmentPlanStore } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import NewDevelopmentPlansModal from './modals/new-development-plans.modal';
import '../../../shared/individual-tab-pane.less';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface IndividualTabPaneProps extends WithTranslation {
  ssaDevelopmentPlanStore?: SsaDevelopmentPlanStore;
}

export interface IndividualTabPaneState {
  query: Query;
  canSearch: boolean;
  isListVisible: boolean;
}

@connect(['ssaDevelopmentPlanStore', SsaDevelopmentPlanStore])
class IndividualTabPane extends Component<IndividualTabPaneProps, IndividualTabPaneState> {
  state: IndividualTabPaneState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10,
      parameters: {}
    },
    canSearch: false,
    isListVisible: false
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private handleOnSearch = (tabFilters: IndividualTabPaneSearchFilters) => {
    const queryParameters: QueryParameters = {};
    const oDataFilters = [];

    if (!!tabFilters.employee) queryParameters['Employee'] = tabFilters.employee;

    if (tabFilters.profile && tabFilters.profile.id)
      oDataFilters.push({ ProfileItemId: { eq: { type: 'guid', value: tabFilters.profile.id } } });

    if (tabFilters.activity && tabFilters.activity.id)
      oDataFilters.push({ SsaActivityId: { eq: { type: 'guid', value: tabFilters.activity.id } } });

    if (tabFilters.location && tabFilters.location.id) queryParameters['LocationId'] = tabFilters.location.id;

    if (!!tabFilters.year) oDataFilters.push({ DeadlineYear: { eq: tabFilters.year } });

    this.setState(
      { query: Object.assign(this.state.query, { filter: oDataFilters, parameters: queryParameters }), isListVisible: true },
      this.load
    );
  };

  private handleOnRefresh = () => {
    this.load();
  };

  private handleOnPageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private load = () => {
    this.props.ssaDevelopmentPlanStore.getAllAsync(this.state.query);
  };

  private handleFilterChange = (filters: IndividualTabPaneSearchFilters) => {
    const canSearch = !!(
      !isNullOrEmpty(filters.employee) ||
      (filters.profile && filters.profile.id) ||
      (filters.activity && filters.activity.id) ||
      (filters.location && filters.location.id) ||
      !!filters.year
    );

    const isListVisible = !canSearch ? false : this.state.isListVisible;
    this.setState({ canSearch: canSearch, isListVisible: isListVisible });
  };

  render() {
    const canCreateDevelopmentPlans = this.identityService.isInAnyRole([Roles.Admin, Roles.PoC, Roles.FactoryLead]);
    const { canSearch, isListVisible } = this.state;

    return (
      <div>
        <div className="ssa-invidual-tab-pane flex-start">
          <IndividualTabPaneSearch canSearch={canSearch} onFilterChange={this.handleFilterChange} onSearch={this.handleOnSearch} />
          {canCreateDevelopmentPlans && <NewDevelopmentPlansModal />}
        </div>
        {isListVisible && <IndividualTabPaneList onRefresh={this.handleOnRefresh} onPageChanged={this.handleOnPageChange} />}
      </div>
    );
  }
}

export default withTranslation()(IndividualTabPane);
