import React, { FC, memo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, InputOnChangeData } from 'semantic-ui-react';
import { RejectReasonDto } from 'stores/configuration/events-n-requests/rejection-reasons-store';
import { ModalMode } from './rejection-reasons';

interface RejectReasonManagerProps extends WithTranslation {
  onClose: () => void;
  onClearErrors: () => void;
  onSubmit: () => void;
  onChange: (name: string) => void;
  errMsgs: string[];
  isBusy: boolean;
  mode: ModalMode;
  editItem: RejectReasonDto;
}

const RejectReasonManager: FC<RejectReasonManagerProps> = props => {
  const handleValue = (_, { value }: InputOnChangeData) => {
    props.onChange(value);
  };

  const { t, isBusy, errMsgs, editItem, mode, onClearErrors: onClear } = props;

  return (
    <Modal size="small" open closeOnEscape={true} onClose={props.onClose} closeOnDimmerClick={false}>
      <Dimmer active={isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
        <Loader indeterminate>{t('')}</Loader>
      </Dimmer>
      <Modal.Header className="modal__header">{t(`${mode === 'edit' ? 'Edit' : 'New'} Reject Reason`)}</Modal.Header>
      <Modal.Content image>
        <Container>
          {(errMsgs || []).length ? (
            <Message
              onDismiss={onClear}
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={errMsgs}
            />
          ) : null}
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                fluid
                label={t('Reject Reason')}
                placeholder={t('Name')}
                value={editItem?.name || ''}
                onChange={handleValue}
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={props.onClose}>
          {t('Cancel')}
        </Button>
        <Button onClick={props.onSubmit} positive>
          {t(`${mode === 'edit' ? 'Save' : 'Create'}`)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(memo(RejectReasonManager));
