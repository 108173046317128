import React, { PureComponent } from 'react';

import { Modal, Button, Form, Container, Dropdown, Dimmer, Loader, Icon, Input, TextArea } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import RolesSelector from 'widgets/bussiness/selectors/roles-selector';
import { ItemReference } from 'stores/dataStore';
import SkillsSelector from 'widgets/bussiness/selectors/skills-selector';
import { ProfileSkillDto, Assessment } from 'stores/profile/profile-store';
import { isNullOrUndefined, isNumber } from 'util';
import { isNullOrWhiteSpaces, isNullOrEmpty, getFormattedPhase } from 'utils/useful-functions';
import NumberFormat from 'react-number-format';

type AllowedStrings = 'Clone' | 'Edit' | 'New';

interface ProfileModalProps extends WithTranslation {
  onClose: () => void;
  onSubmit: () => void;
  values: ProfileValues;
  isOnEditMode?: boolean;
  mode: AllowedStrings;
  onChange: (values: ProfileValues) => void;
  onNewRow: () => void;
  onRemoveRow: (index: number) => void;
  saveTry: boolean;
  isBusy: boolean;
}

export interface ProfileValues {
  id?: string;
  professionId: string;
  assessment: any;
  skill?: ItemReference;
  description: string;
  main?: number;
  substitute?: number;
  skills: ProfileSkillDto[];
  isActive: boolean;
  phase0?: number;
  phase1?: number;
  phase2?: number;
  phase3?: number;
  phase4?: number;
}

class ProfileDefinitionModal extends PureComponent<ProfileModalProps> {
  onRemoveRowHandler = (index: number) => {
    this.props.onRemoveRow(index);
  };

  handleNewProfileRow = () => {
    this.props.onNewRow();
  };

  handleProfileValueChange = (property: keyof ProfileValues, propertyValue: any): void => {
    const newFilterValues = { ...this.props.values };
    newFilterValues[property as string] = propertyValue;

    if (this.isOnCloneMode() && property === 'assessment') {
      newFilterValues.skills = [];
    }

    this.props.onChange && this.props.onChange(newFilterValues);
  };

  handleSkillValueChange = (property: keyof ProfileValues, propertyValue: any, index: number) => {
    const newFilterValues = { ...this.props.values };

    if (property === 'main') {
      newFilterValues.skills[index].main = propertyValue;
    }
    if (property === 'substitute') {
      newFilterValues.skills[index].substitute = propertyValue;
    }
    if (property === 'phase0') {
      newFilterValues.skills[index].phase0 = propertyValue;
    }
    if (property === 'phase1') {
      newFilterValues.skills[index].phase1 = propertyValue;
    }
    if (property === 'phase2') {
      newFilterValues.skills[index].phase2 = propertyValue;
    }
    if (property === 'phase3') {
      newFilterValues.skills[index].phase3 = propertyValue;
    }
    if (property === 'phase4') {
      newFilterValues.skills[index].phase4 = propertyValue;
    }

    this.props.onChange && this.props.onChange(newFilterValues);
  };

  onSubmit = () => {
    this.props.onSubmit();
  };

  isOnEditMode = (): boolean => {
    const { mode } = this.props;
    return !isNullOrWhiteSpaces(mode) && !isNullOrUndefined(mode) && mode === 'Edit';
  };

  isOnCloneMode = (): boolean => {
    const { mode } = this.props;
    return !isNullOrWhiteSpaces(mode) && !isNullOrUndefined(mode) && mode === 'Clone';
  };

  isOnNewMode = (): boolean => {
    return !this.isOnEditMode() && !this.isOnCloneMode();
  };

  isAbleToSave = (): boolean => {
    const { professionId, assessment } = this.props.values;
    return !isNullOrWhiteSpaces(professionId) && !isNullOrUndefined(assessment);
  };

  isAbleToAddSkill = (): boolean => {
    const { skill, main, substitute } = this.props.values;
    return !isNullOrUndefined(skill) && !isNullOrEmpty(main?.toString()) && !isNullOrEmpty(substitute?.toString()) && main >= substitute;
  };

  isAbleToAddIWSSkill = (): boolean => {
    const { skill, phase0 } = this.props.values;
    return !isNullOrUndefined(skill) && !isNullOrEmpty(phase0?.toString());
  };

  formatScores = (val: string): string => {
    if (val && val !== typeof undefined) {
      const availableScores = /^[0-4]$/;
      if (val.match(availableScores)) return val;
      return val.length > 1 ? val.substring(0, 1) : '';
    }
    return '';
  };

  render() {
    const { t, mode } = this.props;
    const options = [
      { key: 1, text: t('TNA'), value: 10 },
      { key: 2, text: t('SSA'), value: 20 },
      { key: 3, text: t('IWS'), value: 30 }
    ];
    let isAssessmentSelected, assessment, mainLabel, substituteLabel;
    let phase0, phase1, phase2, phase3, phase4;
    if (this.props.values.assessment === Assessment.TNA || this.props.values.assessment === 'TNA') {
      isAssessmentSelected = true;
      assessment = 10;
      mainLabel = t('Main');
      substituteLabel = t('Substitute');
    } else if (this.props.values.assessment === Assessment.SSA || this.props.values.assessment === 'SSA') {
      isAssessmentSelected = true;
      assessment = 20;
      mainLabel = t('Main');
      substituteLabel = t('Substitute');
    } else if (this.props.values.assessment === Assessment.IWS || this.props.values.assessment === 'IWS') {
      isAssessmentSelected = true;
      assessment = 30;
      phase0 = t('Phase 0');
      phase1 = t('Phase 1');
      phase2 = t('Phase 2');
      phase3 = t('Phase 3');
      phase4 = t('Phase 4');
    } else {
      isAssessmentSelected = false;
    }

    return (
      <Modal className="tna-skill__modal" closeOnEscape={true} onClose={this.props.onClose} open closeOnDimmerClick={false}>
        <Dimmer active={this.props.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-skill__modal-header">{t(`${mode} Profile`)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.props.children}
            <Form className="profile-def__all-wrapper">
              <Form.Group className="profile-definition__inputs-container flex-center">
                <Form.Field
                  error={this.props.saveTry && isNullOrUndefined(this.props.values.professionId)}
                  disabled={this.isOnEditMode()}
                  required
                  className="profile-def__label-input"
                >
                  <label>{t('Role')}</label>
                  <RolesSelector
                    className="planit-user-dropdown-custom"
                    placeholder="Select Role"
                    value={this.props.values.professionId}
                    onChange={value => this.handleProfileValueChange('professionId', value)}
                    showDeleted={this.isOnEditMode()}
                  />
                </Form.Field>

                <Form.Field
                  disabled={(this.isOnEditMode() || this.isOnNewMode()) && this.props.values.skills.length > 0}
                  error={this.props.saveTry && isNullOrUndefined(this.props.values.assessment)}
                  required
                  className="profile-def__label-input"
                >
                  <label>{t('Assessment')}</label>
                  <Dropdown
                    selection
                    multiple={false}
                    options={options}
                    value={assessment}
                    className="planit-user-dropdown-custom"
                    placeholder={t('Select Assessment')}
                    onChange={(_, { value }) => this.handleProfileValueChange('assessment', value)}
                  />
                </Form.Field>
              </Form.Group>

              <div className="profile-def__textarea-label__wrapper flex-center">
                <label>{t('Description')}</label>
                <TextArea
                  className="profile-def__textarea"
                  value={this.props.values.description || ''}
                  onChange={(_, { value }) => this.handleProfileValueChange('description', value)}
                />
              </div>

              <Form.Group className="profile-def__add-skill-row flex-center">
                <Form.Field disabled={!isAssessmentSelected} className="profile-def__label-input">
                  <label>{t('Skill')}</label>
                  <SkillsSelector
                    nullable
                    clearable
                    onChange={data => this.handleProfileValueChange('skill', data)}
                    value={this.props.values.skill?.id || null}
                    assessment={assessment}
                    className="planit-user-dropdown-custom"
                    placeholder={t('Select Skill')}
                  />
                </Form.Field>

                {assessment !== Assessment.IWS && (
                  <Form.Field error={this.props.values.substitute > this.props.values.main} required className="profile-def__label-input">
                    <Input
                      error={this.props.values.substitute > this.props.values.main}
                      disabled={!this.props.values.skill}
                      className="profile-def__numeric-input"
                    >
                      <NumberFormat
                        value={this.props.values.main}
                        onChange={({ target }) => this.handleProfileValueChange('main', target.value)}
                        format={this.formatScores}
                        placeholder={mainLabel}
                      />
                    </Input>

                    <Input
                      error={this.props.values.substitute > this.props.values.main}
                      disabled={!this.props.values.skill}
                      className="profile-def__numeric-input"
                    >
                      <NumberFormat
                        value={this.props.values.substitute}
                        onChange={({ target }) => this.handleProfileValueChange('substitute', target.value)}
                        format={this.formatScores}
                        placeholder={substituteLabel}
                      />
                    </Input>

                    <Button disabled={!this.isAbleToAddSkill()} onClick={this.handleNewProfileRow} className="add-row-btn" type="button">
                      {t('Add Skill')}
                    </Button>
                  </Form.Field>
                )}

                {assessment === Assessment.IWS && (
                  <Form.Field required className="profile-def__label-input">
                    <Input
                      error={!isNumber(this.props.values.phase0)}
                      disabled={!this.props.values.skill}
                      className="profile-def__numeric-phase-input"
                    >
                      <NumberFormat
                        value={this.props.values.phase0}
                        onChange={({ target }) => this.handleProfileValueChange('phase0', target.value)}
                        format={this.formatScores}
                        placeholder={phase0}
                      />
                    </Input>

                    <Input disabled={!this.props.values.skill} className="profile-def__numeric-phase-input">
                      <NumberFormat
                        value={this.props.values.phase1}
                        onChange={({ target }) => this.handleProfileValueChange('phase1', target.value)}
                        format={this.formatScores}
                        placeholder={phase1}
                      />
                    </Input>
                    <Input disabled={!this.props.values.skill} className="profile-def__numeric-phase-input">
                      <NumberFormat
                        value={this.props.values.phase2}
                        onChange={({ target }) => this.handleProfileValueChange('phase2', target.value)}
                        format={this.formatScores}
                        placeholder={phase2}
                      />
                    </Input>

                    <Input disabled={!this.props.values.skill} className="profile-def__numeric-phase-input">
                      <NumberFormat
                        value={this.props.values.phase3}
                        onChange={({ target }) => this.handleProfileValueChange('phase3', target.value)}
                        format={this.formatScores}
                        placeholder={phase3}
                      />
                    </Input>

                    <Input disabled={!this.props.values.skill} className="profile-def__numeric-phase-input">
                      <NumberFormat
                        value={this.props.values.phase4}
                        onChange={({ target }) => this.handleProfileValueChange('phase4', target.value)}
                        format={this.formatScores}
                        placeholder={phase4}
                      />
                    </Input>

                    <Button disabled={!this.isAbleToAddIWSSkill()} onClick={this.handleNewProfileRow} className="add-row-btn" type="button">
                      {t('Add Skill')}
                    </Button>
                  </Form.Field>
                )}
              </Form.Group>
              <div
                className="profile-definition__skills-group scroll"
                style={{ display: this.props.values.skills.length > 0 ? 'block' : 'none' }}
              >
                {this.props.values.skills.length > 0 &&
                  this.props.values.skills.map(
                    ({ skillName, skillId, main, substitute, phase0, phase1, phase2, phase3, phase4 }, index) => (
                      <React.Fragment key={mode + skillId + index}>
                        {mode !== 'Edit' && (
                          <Form.Group key={skillId + index} className="new-profile__new-row flex-center">
                            {assessment !== Assessment.IWS && (
                              <div className="flex-center">
                                <span>{skillName}</span>
                                <span>{main}</span>
                                <span>{substitute}</span>
                              </div>
                            )}

                            {assessment === Assessment.IWS && (
                              <div className="iws-flex-center">
                                <span>{skillName}</span>
                                <span>{phase0}</span>
                                <span>{getFormattedPhase(phase1)}</span>
                                <span>{getFormattedPhase(phase2)}</span>
                                <span>{getFormattedPhase(phase3)}</span>
                                <span>{getFormattedPhase(phase4)}</span>
                              </div>
                            )}

                            <Icon
                              className="clickable-icon remove-icon"
                              color="red"
                              name="remove"
                              size="large"
                              onClick={() => this.onRemoveRowHandler(index)}
                            />
                          </Form.Group>
                        )}
                        {mode === 'Edit' && (
                          <Form.Group key={skillId + index} className="new-profile__new-row flex-center">
                            {assessment !== Assessment.IWS && (
                              <>
                                <div className="flex-center">
                                  <span>{skillName}</span>
                                  <Form.Field
                                    error={this.props.values.substitute > this.props.values.main}
                                    required
                                    className="profile-def__label-input"
                                  >
                                    <Input
                                      error={this.props.values.substitute > this.props.values.main}
                                      className="edit-profile-def__numeric-edit-input"
                                    >
                                      <NumberFormat
                                        value={main}
                                        onChange={({ target }) => this.handleSkillValueChange('main', target.value, index)}
                                        format={this.formatScores}
                                        placeholder={mainLabel}
                                      />
                                    </Input>

                                    <Input
                                      error={this.props.values.substitute > this.props.values.main}
                                      className="edit-profile-def__numeric-edit-input"
                                    >
                                      <NumberFormat
                                        value={substitute}
                                        onChange={({ target }) => this.handleSkillValueChange('substitute', target.value, index)}
                                        format={this.formatScores}
                                        placeholder={substituteLabel}
                                      />
                                    </Input>
                                  </Form.Field>
                                </div>
                              </>
                            )}

                            {assessment === Assessment.IWS && (
                              <div className="flex-center">
                                <span>{skillName}</span>
                                <Input error={!isNumber(phase0)} className="edit-profile-def__numeric-edit-input">
                                  <NumberFormat
                                    value={phase0}
                                    onChange={({ target }) => this.handleSkillValueChange('phase0', target.value, index)}
                                    format={this.formatScores}
                                    placeholder={'Phase 0'}
                                  />
                                </Input>

                                <Input className="edit-profile-def__numeric-edit-input">
                                  <NumberFormat
                                    value={phase1 >= 0 ? phase1 : null}
                                    onChange={({ target }) => this.handleSkillValueChange('phase1', target.value, index)}
                                    format={this.formatScores}
                                    placeholder={'Phase 1'}
                                  />
                                </Input>

                                <Input className="edit-profile-def__numeric-edit-input">
                                  <NumberFormat
                                    value={phase2 >= 0 ? phase2 : null}
                                    onChange={({ target }) => this.handleSkillValueChange('phase2', target.value, index)}
                                    format={this.formatScores}
                                    placeholder={'Phase 2'}
                                  />
                                </Input>

                                <Input className="edit-profile-def__numeric-edit-input">
                                  <NumberFormat
                                    value={phase3 >= 0 ? phase3 : null}
                                    onChange={({ target }) => this.handleSkillValueChange('phase3', target.value, index)}
                                    format={this.formatScores}
                                    placeholder={'Phase 3'}
                                  />
                                </Input>

                                <Input className="edit-profile-def__numeric-edit-input">
                                  <NumberFormat
                                    value={phase4 >= 0 ? phase4 : null}
                                    onChange={({ target }) => this.handleSkillValueChange('phase4', target.value, index)}
                                    format={this.formatScores}
                                    placeholder={'Phase 4'}
                                  />
                                </Input>
                              </div>
                            )}

                            <Icon
                              className="clickable-icon remove-icon"
                              color="red"
                              name="remove"
                              size="large"
                              onClick={() => this.onRemoveRowHandler(index)}
                            />
                          </Form.Group>
                        )}
                      </React.Fragment>
                    )
                  )}
              </div>
            </Form>
          </Container>
        </Modal.Content>

        <Modal.Actions className="new-tna-skill__btns basic">
          <Button onClick={this.props.onClose}>{t('Cancel')}</Button>
          <Button disabled={!this.isAbleToSave()} onClick={this.onSubmit} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ProfileDefinitionModal);
