import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import IwsStepsComponent from '../../../wizards/iws-wizard-step';
import { IwsSkillTemplateSectionDto } from 'stores/assessments/templates/iws-templates-store';
import IwsSecondStepSkillSectionComponent from './iws-wizard-second-step-skill-component';
import './iws-wizard-second-step.less';

export interface IwsSecondStepProps extends WithTranslation {
  isStepActive?: boolean;
  onChangeProperty: (property: string, value: any) => void;
  categoriesSection: IwsSkillTemplateSectionDto;
  profession: string;
}

class IwsSecondStepContent extends React.Component<IwsSecondStepProps> {
  state = {};

  changePropertyHandler = (skillSection: IwsSkillTemplateSectionDto) => {
    this.props.onChangeProperty('skillSection', skillSection);
  };

  onChange = (property: keyof IwsSkillTemplateSectionDto, value: Partial<IwsSkillTemplateSectionDto>) => {
    const { categoriesSection } = this.props;
    categoriesSection[property as string] = value;

    this.changePropertyHandler(categoriesSection);
  };

  moveSkillHandler = (dir: 'up' | 'down', skillIdx: number, categoryIdx: number) => {};

  render() {
    const { t, isStepActive, categoriesSection, profession } = this.props;

    return isStepActive ? (
      <div className="iws-wizard__step iws-wizard__step-second">
        <IwsStepsComponent isStepActive stepNumber={'2'} stepTitle={t('SELECT QUESTIONS')} />
        <div className="step-two__category-skills-questions__wrapper">
          <IwsSecondStepSkillSectionComponent
            profession={profession}
            key={categoriesSection.skillId}
            category={'categorySection.name'}
            skillSection={categoriesSection}
            onChange={(name, val) => this.onChange(name, val)}
            move={dir => this.moveSkillHandler(dir, 0, 0)}
            canGoUp={false}
            canGoDown={false}
          />
        </div>
      </div>
    ) : (
      <div className="iws-wizard__step iws-wizard__step-second">
        <IwsStepsComponent isStepActive={isStepActive} stepNumber={'2'} stepTitle={t('SELECT QUESTIONS')} />
      </div>
    );
  }
}

export default withTranslation()(IwsSecondStepContent);
