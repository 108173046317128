import { injectable } from 'inversify';
import { InstructorDto } from 'stores/instructors/instructors-store';
import { AlwaysVisibleFiltersValue } from './always-visible-filters';
import { InvisibleFiltersValue } from './invisible-filters';
@injectable()
export class SchedulerResourceFilterService {
  public fullfillsAllFilters = (
    resource,
    filters: AlwaysVisibleFiltersValue,
    additionalFilters: InvisibleFiltersValue,
    locationsFilter: boolean = false
  ) => {
    resource.showStar = false;
    const { instructorLocations, instructors, requestLocations, roleInstructor, eventLocations, pillarManagedBy } = filters;
    const { pillars } = additionalFilters;
    const instructor: InstructorDto = resource?.instructor;
    const rawEvents = resource.getEvents();
    const events = rawEvents.map(({ data }) => data?.event);
    const requests = (events.map(event => event?.requests) || []).reduce((acc, curr) => [...acc, ...(curr || [])], []);

    if (
      (requestLocations || []).length &&
      !requestLocations.some(({ id }) => (requests || []).some(({ requestLocation }) => requestLocation?.id === id))
    )
      return false;

    if (
      (eventLocations || []).length &&
      !eventLocations.some(({ id }) => (events || []).some(({ eventDetails }) => eventDetails?.locationId === id))
    )
      return false;

    if (locationsFilter) return true;

    if ((pillars || []).length && !pillars.some(({ id }) => instructor?.pillarId === id)) return false;

    if ((instructors || []).length && !instructors.some(({ id }) => id === resource?.id)) return false;

    if (
      roleInstructor &&
      !filters.filterByMe &&
      ((roleInstructor === 'Instructor' && !['Power Instructor', 'Instructor'].includes(instructor?.instructorRole?.name)) ||
        (roleInstructor === 'Internal Trainer' && instructor?.instructorRole?.name !== roleInstructor))
    )
      return false;

    const ienabled = instructor?.enabled;
    const instructorEvents = events.map(x => x.instructors.map(y => y.instructorId)).any(x => x.includes(resource?.instructor.id));

    if (roleInstructor && !(ienabled || (!ienabled && instructorEvents))) return false;

    if (pillarManagedBy && !filters.filterByMe && instructor?.pillar?.managedBy !== pillarManagedBy) return false;

    if (
      (instructorLocations || []).length &&
      !instructorLocations.some(
        ({ id }) => (resource?.instructor?.roles || []).find(({ role }) => role.name === 'Instructor')?.location?.id === id
      )
    )
      return false;

    return true;
  };
}
