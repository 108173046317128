import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import i18n from 'i18n';
import SupportCostsListPage from './support-costs/support-costs-list';

export interface SupportCostsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface SupportCostsConfigurationHomeState {
  activeTab: 'supportCosts';
}

class SupportCostsConfigurationHomePage extends React.Component<SupportCostsConfigurationHomeProps, SupportCostsConfigurationHomeState> {
  constructor(props: SupportCostsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'supportCosts'
    };
  }

  private panes = [
    {
      menuItem: i18n.t('Support Costs'),
      render: () => (
        <Tab.Pane attached={false}>
          <SupportCostsListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Support Costs')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(SupportCostsConfigurationHomePage);
