import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../utils/object';
import { Dropdown, DropdownProps, StrictDropdownProps } from 'semantic-ui-react';
import { OrderDefinition } from 'stores/dataStore';
import { RejectedByDto, RejectedByStore } from 'stores/configuration/events-n-requests/rejected-by-store';

interface RejectedByComponentProps extends WithTranslation, StrictDropdownProps {
  value: string;
  onChange: (value: any) => void;
  placeholder?: string;
  clearable?: boolean;
  rejectedByStore?: RejectedByStore;
  readOnly?: boolean;
  className?: string;
}

interface RejectedByComponentState {
  rejectedBy: { text: string; value: string }[];
}

@connect(['rejectedByStore', RejectedByStore])
class RejectedByComponent extends Component<RejectedByComponentProps, RejectedByComponentState> {
  private get rejectedByStore() {
    return this.props.rejectedByStore;
  }

  state: RejectedByComponentState = { rejectedBy: [] };

  componentDidMount() {
    this.loadRejectedByStore();
  }

  private loadRejectedByStore = async () => {
    const filter = [];

    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<RejectedByDto>('name'), useProfile: false }];

    return await this.rejectedByStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }).then(rejectedByStore => {
      const options = [];
      rejectedByStore.items.forEach(rejectReason => options.push({ text: rejectReason.name, value: rejectReason.id }));
      this.setState({ rejectedBy: options });
    });
  };

  private handleOnChange = (_, { value }: DropdownProps) => {
    this.props.onChange(value as string);
  };

  public render() {
    const { rejectedBy } = this.state;
    const { t, readOnly, error, className, loading, value, placeholder, rejectedByStore } = this.props;
    return (
      <Dropdown
        search
        selection
        closeOnChange
        loading={loading || rejectedByStore.state.isBusy}
        closeOnEscape
        selectOnBlur={false}
        clearable
        options={rejectedBy}
        error={error}
        className={className}
        value={value ?? ''}
        onChange={this.handleOnChange}
        disabled={readOnly}
        placeholder={placeholder || t('Select Rejected By')}
      />
    );
  }
}

export default withTranslation()(RejectedByComponent);
