import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface ProfessionDto extends BaseDto {
  id: string;
  profession: string;
}

export interface CreateProfessionDto {
  profession: string;
}

export interface ChangeProfessionDto {
  id: string;
  profession: string;
}

export class CreateProfessionValidator extends AbstractValidator<CreateProfessionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.profession)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Profession Name is required'));
  }
}

export class ChangeProfessionValidator extends AbstractValidator<ChangeProfessionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Profession Id is required'));

    this.validateIfString(o => o.profession)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Profession Name is required'));
  }
}

@repository('@@PROFESSIONS', 'professions.summary')
export class ProfessionsStore extends DataStore<ProfessionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-profession';
  retrievePath = 'get-professions';
  updatePath = 'update-profession';
  deletePath = 'delete-profession';

  protected validate(item: ProfessionDto) {
    return new ChangeProfessionValidator().validate(item);
  }

  constructor() {
    super('PROFESSION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PROFESSIONS', 'professions.dropdown')
export class DropDownProfessionsStore extends DataStore<ProfessionDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-professions';
  updatePath = '';
  deletePath = '';

  protected validate(item: ProfessionDto) {
    return new ChangeProfessionValidator().validate(item);
  }

  constructor() {
    super('PROFESSIONDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PROFESSIONS', 'professions.new')
export class NewProfessionStore extends FormStore<CreateProfessionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-profession';
  retrievePath = 'get-professions';
  updatePath = 'update-profession';

  protected validate(item: CreateProfessionDto) {
    return new CreateProfessionValidator().validate(item);
  }

  constructor() {
    super('NEW_PROFESSION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PROFESSIONS', 'professions.change')
export class ChangeProfessionStore extends FormStore<ChangeProfessionDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-profession';
  retrievePath = 'get-professions';
  updatePath = 'update-profession';

  protected validate(item: ChangeProfessionDto) {
    return new ChangeProfessionValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PROFESSION', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
