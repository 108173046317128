import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { NmrPracticalTestQuestionItemDto, NMRPracticalQuestionBanksStore } from 'stores/assessments/questionBank/practical-test-store';
import TrainingLevelEditor from 'widgets/bussiness/selectors/training-level-selector';
import React, { Component } from 'react';

interface ChooseNmrPracticalQuestionsViewProps extends WithTranslation {
  nmrPracticalQuestionBanksStore?: NMRPracticalQuestionBanksStore;
  alreadySelectedQuestions: string[];
  professionId?: string;
  clusterId?: string;
  functionalAreaId?: string;
  functionalSubAreaId: string;
  trainingNameId?: string;
  onAddPracticalQuestions: (questions: NmrPracticalTestQuestionItemDto[]) => void;
  onCloseModal?: () => void;
}

export interface ChoosePracticalQuestionFilters {
  questionTitleOrId: string;
  trainingLevelId: string;
}

interface ChooseNmrPracticalQuestionsViewState {
  query: Query;
  selectedQuestions: NmrPracticalTestQuestionItemDto[];
  filters: ChoosePracticalQuestionFilters;
}

@connect(['nmrPracticalQuestionBanksStore', NMRPracticalQuestionBanksStore])
class ChooseNmrPracticalQuestionsView extends Component<ChooseNmrPracticalQuestionsViewProps, ChooseNmrPracticalQuestionsViewState> {
  constructor(props: ChooseNmrPracticalQuestionsViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      selectedQuestions: null,
      filters: {
        questionTitleOrId: '',
        trainingLevelId: null
      }
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  private onAddQuestions = () => {
    this.props.onAddPracticalQuestions(this.state.selectedQuestions);
    this.props.onCloseModal();
  };

  private onCancel = () => {
    this.props.onCloseModal();
  };

  private load = async () => {
    await this.props.nmrPracticalQuestionBanksStore.getAllAsync(this.state.query);
  };
  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleFilterChange = (filters, oDatafilters) => {
    let query = { ...this.state.query, filter: oDatafilters, skip: 0 };
    this.setState({ filters, query }, this.load);
  };

  private handleOnFiltersChange = (property: keyof ChoosePracticalQuestionFilters, value: any) => {
    const filters = { ...this.state.filters };
    filters[property as string] = value;
    this.refreshTable(filters);
  };

  private selectedQuestions(items: unknown[]): void {
    this.setState({ selectedQuestions: items as any });
  }

  private refreshTable(filters: ChoosePracticalQuestionFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChoosePracticalQuestionFilters) {
    const oDataFilters = [];

    if (this.props.alreadySelectedQuestions && this.props.alreadySelectedQuestions.length > 0)
      oDataFilters.push(`not(Id in (${this.props.alreadySelectedQuestions.join(',')}))`);

    if (!isNullOrWhiteSpaces(this.props.professionId)) {
      oDataFilters.push({ Professions: { any: { id: { eq: { value: this.props.professionId, type: 'guid' } } } } });
    }

    if (!isNullOrWhiteSpaces(this.props.clusterId)) {
      oDataFilters.push({ NMRClusterId: { eq: { type: 'guid', value: this.props.clusterId } } });
    }

    if (!isNullOrWhiteSpaces(this.props.functionalAreaId)) {
      oDataFilters.push({ FunctionalAreaId: { eq: { type: 'guid', value: this.props.functionalAreaId } } });
    }

    if (!isNullOrWhiteSpaces(this.props.functionalSubAreaId)) {
      oDataFilters.push({ FunctionalSubareaId: { eq: { type: 'guid', value: this.props.functionalSubAreaId } } });
    }

    if (!isNullOrWhiteSpaces(this.props.trainingNameId)) {
      oDataFilters.push({ TrainingNameId: { eq: { type: 'guid', value: this.props.trainingNameId } } });
    }

    if (!!filters.trainingLevelId) {
      oDataFilters.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.trainingLevelId } } });
    }

    if (!isNullOrWhiteSpaces(filters.questionTitleOrId)) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QNMRP');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QNMRP')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    return oDataFilters;
  }

  public render() {
    const { t } = this.props;
    const { filters } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<NmrPracticalTestQuestionItemDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.text}</div>,
          selectableHeader: true
        },
        {
          title: t('Training Level'),
          tooltipRenderer: false,
          renderer: data => (
            <span className={data.trainingLevel ? 'question-bank__cell__tag' : 'text__center'}>
              {data.trainingLevel ? data.trainingLevel.name : '-'}
            </span>
          ),
          selectableHeader: true
        }
      ],
      data: this.props.nmrPracticalQuestionBanksStore.state
    } as TableModel<NmrPracticalTestQuestionItemDto>;

    return (
      <Modal className="template-search__modal" open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={(event, data) => this.handleOnFiltersChange('questionTitleOrId', data.value)}
            />

            <TrainingLevelEditor
              clearable
              nullable
              className="planit-user-dropdown-custom"
              value={filters.trainingLevelId}
              onChange={value => this.handleOnFiltersChange('trainingLevelId', value)}
              placeholder={t('Training Level')}
              searchable
            />
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={questions => this.selectedQuestions(questions)}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseNmrPracticalQuestionsView);
