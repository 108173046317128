import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { NewNMClusterStore, CreateNMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { Categories } from 'stores/requests/requests-store';
import { nameof } from 'utils/object';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';

interface NewNMClusterViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newNMCluster?: NewNMClusterStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewNMClusterViewState {
  userClickedSave: boolean;
}

@connect(['newNMCluster', NewNMClusterStore])
class NewNMClusterView extends React.Component<NewNMClusterViewProps, NewNMClusterViewState> {
  private get newNMClusterStore() {
    return this.props.newNMCluster;
  }

  constructor(props: NewNMClusterViewProps) {
    super(props);
    this.state = {
      userClickedSave: false
    };
    this.newNMClusterStore.createNew({
      name: '',
      category: 'Unknown'
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewNMClusterViewProps) {
    if (this.newNMClusterStore.state.result && this.newNMClusterStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSave: true });
    this.newNMClusterStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newNMClusterStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newNMClusterStore.change({ ...this.newNMClusterStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newNMClusterStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Cluster')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newNMClusterStore.state.result && !this.newNMClusterStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newNMClusterStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newNMClusterStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <div
                      className={`required ${
                        this.newNMClusterStore.state.item.category == null ||
                        (![Categories.Functional, Categories.Technical].includes(Categories[this.newNMClusterStore.state.item.category]) &&
                          this.state.userClickedSave)
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Category')}</label>
                      <CategoryFormEditor
                        className="form__header__dropdown-style"
                        value={this.newNMClusterStore.state.item.category}
                        onChange={value => this.handleValue(nameof<CreateNMClusterDto>('category'), value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Input
                    required
                    fluid
                    error={!this.newNMClusterStore.state.item.name && this.state.userClickedSave}
                    label={t('Cluster')}
                    placeholder={t('Name')}
                    value={this.newNMClusterStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateNMClusterDto>('name'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewNMClusterView);
