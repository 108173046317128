import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { LocationDto, ChangeLocationStore, ChangeLocationDto } from '../../../../../stores/configuration/locations/locations-store';
import { nameof } from '../../../../../utils/object';
import { isNullOrWhiteSpaces } from '../../../../../utils/useful-functions';
import CountryEditor from 'widgets/bussiness/country-editor';
import RegionEditor from 'widgets/bussiness/region-editor';

interface ChangeLocationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeLocation?: ChangeLocationStore;
  currentLocation: LocationDto;
}

interface ChangeLocationViewState {}

@connect(['changeLocation', ChangeLocationStore])
class ChangeLocationView extends React.Component<ChangeLocationViewProps, ChangeLocationViewState> {
  private get changeLocationStore() {
    return this.props.changeLocation;
  }

  constructor(props: ChangeLocationViewProps) {
    super(props);

    this.changeLocationStore.state.result = null;

    this.changeLocationStore.change({
      id: this.props.currentLocation.id,
      code: this.props.currentLocation.code,
      location: this.props.currentLocation.location,
      countryId: this.props.currentLocation.countryId,
      active: this.props.currentLocation.active,
      regionId: this.props.currentLocation.regionId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeLocationViewProps) {
    if (this.changeLocationStore.state.result && this.changeLocationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeLocationStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeLocationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeLocationStore.change({ ...this.changeLocationStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeLocationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Location')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeLocationStore.state.result && !this.changeLocationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeLocationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeLocationStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeLocationStore.state.item.code}
                    label={t('Code')}
                    placeholder={t('Code')}
                    value={this.changeLocationStore.state.item.code}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeLocationDto>('code'), value)}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.changeLocationStore.state.item.location}
                    label={t('Location')}
                    placeholder={t('Location')}
                    value={this.changeLocationStore.state.item.location}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeLocationDto>('location'), value)}
                  />
                  <Form.Checkbox
                    style={{ top: 25 }}
                    toggle
                    checked={this.changeLocationStore.state.item.active}
                    label={t('Active')}
                    placeholder={t('Active')}
                    onChange={(e, { checked }) => this.handleValue(nameof<ChangeLocationDto>('active'), checked)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <div
                    className={`${this.changeLocationStore.state.item.countryId == null ||
                      isNullOrWhiteSpaces(this.changeLocationStore.state.item.countryId)} field`}
                  >
                    <label>{t('Country')}</label>
                    <CountryEditor
                      nullable
                      placeholder={t('Country')}
                      value={
                        isNullOrWhiteSpaces(this.changeLocationStore.state.item.countryId)
                          ? null
                          : { id: this.changeLocationStore.state.item.countryId, title: '' }
                      }
                      onChange={value => this.handleValue(nameof<ChangeLocationDto>('countryId'), value.id)}
                    />
                  </div>
                  <div className={`field`}>
                    <label>{t('Region')}</label>
                    <RegionEditor
                      placeholder={t('Region')}
                      nullable
                      value={
                        isNullOrWhiteSpaces(this.changeLocationStore.state.item.regionId)
                          ? null
                          : { id: this.changeLocationStore.state.item.regionId, title: '' }
                      }
                      onChange={value => {
                        this.handleValue(nameof<ChangeLocationDto>('regionId'), value == null ? null : value.id);
                      }}
                      clearable={true}
                    />
                  </div>
                  <div className={`field`}></div>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeLocationView);
