import React from 'react';
import { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { ChangeRequestStore, RequestStatus, RequestDto, ExtendedStatusValidation } from '../../../../../stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { replaceAll } from 'utils/useful-functions';
import { userCanValidateGeaOrGmaRequest } from 'utils/extended-requests-utils';
import { Message } from 'stores/types';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

interface RequestAcceptButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestValidatorRoles: string[];
  changeRequestStore?: ChangeRequestStore;
  isGea: boolean;
  isGma: boolean;
  extendedStatusValidation?: ExtendedStatusValidation;
  readOnly: boolean;

  onSuccess?: (request: RequestDto) => void;
  onError?: (extraError: Message[]) => void;
  onBeginAccept: () => void;
}

@connect(['changeRequestStore', ChangeRequestStore])
class RequestAcceptButton extends Component<RequestAcceptButtonProps> {
  state = {
    showConfirm: false
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private onAcceptClick = () => {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma } = this.props;

    if ((isGea || isGma) && IdentityService.isPlanner(userInfo)) {
      this.setState({ showConfirm: true });
    } else {
      this.handleOnClick();
    }
  };

  private handleOnClick = async () => {
    this.setState({ showConfirm: false });
    if (this.props.onBeginAccept) this.props.onBeginAccept();

    try {
      let request = await this.props.changeRequestStore.changeStatus(this.props.requestId, RequestStatus.InProgress);
      ToastComponent({ text: 'Request accepted', type: 'success-toast' });
      this.props.onSuccess && this.props.onSuccess(request);
    } catch (error) {
      this.props.onError && this.props.onError([{ body: 'An error ocurred', level: 'Error' }]);
      this.props.changeRequestStore.state.result.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'error-toast' }));
    }
  };

  render() {
    const { t, readOnly } = this.props as any;
    const { showConfirm } = this.state;
    return (
      this.isVisible() && (
        <>
          <Button className="form__actions__button form__next-status-accept-btn" disabled={!readOnly} onClick={this.onAcceptClick} positive>
            {t('Accept')}
          </Button>

          <Modal
            style={{ transform: 'translateY(35vh)' }}
            open={showConfirm}
            size="mini"
            className="tna-confirmation-modal"
            closeOnEscape={true}
            onClose={() => this.setState({ showConfirm: false })}
          >
            <Modal.Content className="tna-confirmation-modal__content">
              <p>
                {t('Did you check the Support Details section before approving?')}
                <br />
                {t('You can cancel this and review it or continue with the approval')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="tiny"
                className="tna-confirmation-modal__btn cancel"
                content={t('Cancel Approval')}
                onClick={() => this.setState({ showConfirm: false })}
              />
              <Button size="tiny" className="tna-confirmation-modal__btn" content={t('Continue Approval')} onClick={this.handleOnClick} />
            </Modal.Actions>
          </Modal>
        </>
      )
    );
  }

  isVisible(): boolean {
    return this.isVisibleInCurrentStatus() && this.currentUserHasPermission();
  }

  isVisibleInCurrentStatus(): boolean {
    return this.props.requestStatus.toString() === 'Pending';
  }

  currentUserHasPermission(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma, extendedStatusValidation, requestValidatorRoles, requestStatus } = this.props;

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    if ((requestValidatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''))) {
      if (isGma) return userCanValidateGeaOrGmaRequest('GMA', extendedStatusValidation, requestValidatorRoles, requestStatus, userInfo);

      if (isGea) return userCanValidateGeaOrGmaRequest('GEA', extendedStatusValidation, requestValidatorRoles, requestStatus, userInfo);

      return true;
    }
    return false;
  }
}

export default withTranslation()(RequestAcceptButton);
