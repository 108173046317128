import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, TextArea, Input } from 'semantic-ui-react';
import { EventWizardData } from 'stores/events/creation-wizard-store';

interface CommentsStepProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, string][]) => void;
}

interface CommentsStepState {
  wizardData: EventWizardData;
  titleFocused: boolean;
}

class CommentsStep extends Component<CommentsStepProps, CommentsStepState> {
  constructor(props) {
    super(props);

    this.state = {
      wizardData: props.wizardData,
      titleFocused: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: CommentsStepProps) {
    if (nextProps && nextProps !== this.props) {
      this.setState({
        wizardData: nextProps.wizardData
      });
    }
  }

  private handleValue = (property: string, value: any) => {
    const { wizardData } = this.state;
    wizardData[property] = value;
    this.setState(
      {
        wizardData
      },
      () => this.props.onValueChanged([property, value])
    );
  };

  private onManuallyChangeTitle = (val: string) => {
    const current = this.state.wizardData.eventTitle;
    if (current !== val) {
      const modifiedWizardData = this.state.wizardData;
      modifiedWizardData.eventTitle = val;
      this.setState(
        {
          wizardData: modifiedWizardData
        },
        () => this.props.onValueChanged(['title', val])
      );
    }
  };

  render() {
    const { t } = this.props as any;
    const { wizardData } = this.state;

    return (
      <Form className="request-wizard__step6-comments__container">
        <div className="flex-start-center">
          <label>{t('Event Title')}</label>
          <Input
            className="request-title__input"
            fluid
            value={wizardData.eventTitle}
            onChange={(_, { value }) => this.onManuallyChangeTitle(value)}
            onFocus={() => this.setState({ titleFocused: true })}
            onBlur={() => this.setState({ titleFocused: false })}
          />
        </div>

        <div className="flex-start-center">
          <label>{t('Comments')}</label>
          <TextArea
            className="custom-editor request__textarea"
            style={{ minHeight: '30vh', maxHeight: '30vh', resize: 'none' }}
            value={wizardData.comments}
            onChange={(_, { value }) => this.handleValue('comments', value)}
          />
        </div>
      </Form>
    );
  }
}

export default withTranslation()(CommentsStep);
