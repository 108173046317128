import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Checkbox, Container, Dimmer, Form, Icon, Loader, Message, Modal, Popup } from 'semantic-ui-react';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { PlannerAssistantDto, PlannerAssistantStore } from 'stores/configuration/planner-assistant/planner-assistant-store';
import { nameof } from 'utils/object';
import { buildEventViewModel, EventFormStore, PlannerAssistantOrigen } from 'stores/events/event-form-store';
import { EventDto, EventStatus } from 'stores/events/events-store';
import PlannerAssistantEventsSuggestion from './event-suggestion';
import './event-suggestion-style.less';
import LineSeparator from 'widgets/bussiness/line-separator';
import SchedulerViewModal from 'widgets/scheduler/scheduler-view-modal';

interface PlannerAssistantProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  selectedEventRows?: EventDto[];
  plannerAssistantStore?: PlannerAssistantStore;
  eventFormStore?: EventFormStore;
  origen: PlannerAssistantOrigen;
}

interface PlannerAssistantState {
  errorMessagesVisible: boolean;
  suggestedEvents: EventDto[];
  suggestionsReceived: boolean;
  visibleSuggestions: string[];
  showCalendarView: boolean;
}

@connect(['plannerAssistantStore', PlannerAssistantStore], ['eventFormStore', EventFormStore])
class PlannerAssistant extends React.Component<PlannerAssistantProps, PlannerAssistantState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: PlannerAssistantProps) {
    super(props);
    this.plannerAssistantStore.createNew({
      id: '',
      isFlexibleDate: false,
      durationCoefficient: 1,
      priorityLevel: false,
      simultaneousEvents: false,
      consecutiveEvents: false,
      selectedEvents: this.props.selectedEventRows
    });

    this.state = {
      errorMessagesVisible: false,
      suggestedEvents: [],
      suggestionsReceived: false,
      visibleSuggestions: [],
      showCalendarView: false
    };
  }

  private get plannerAssistantStore() {
    return this.props.plannerAssistantStore;
  }

  private get eventFormStore() {
    return this.props.eventFormStore;
  }

  private handleShowCalendarViewModal = () => {
    this.setState({ showCalendarView: true });
  };

  private handleHideCalendarViewModal = () => {
    this.setState({ showCalendarView: false });
  };

  private getStoreErrorMessages = () => {
    const { isBusy, result } = this.plannerAssistantStore.state;
    const { errorMessagesVisible } = this.state;
    const { t } = this.props;

    if (isBusy || result == null) return null;

    let header = '';

    if (!result.isSuccess) {
      header = t('An error ocurred');
      return (
        <Message
          className="no-white-error"
          hidden={!errorMessagesVisible}
          icon={!result.isSuccess && 'exclamation circle'}
          onDismiss={this.onHideErrorMessages}
          header={header}
          list={result.messages.map(o => o.body)}
          error
        />
      );
    }

    return null;
  };

  private getEventStoreErrorMessages = () => {
    const { isBusy, result } = this.eventFormStore.state;
    const messages = (result && result.messages) || [];
    const { t } = this.props;

    if (isBusy || result == null) return null;

    if (messages && messages.length > 0) {
      return (
        <Message
          className="error-message__style"
          icon="exclamation circle"
          error
          header={t('An error ocurred')}
          list={messages.map(o => o.body).filter(x => x !== '')}
          onDismiss={this.eventFormStore.clearMessages}
        />
      );
    }

    return null;
  };

  private getSelectionErrorMessages = () => {
    const { t, selectedEventRows } = this.props;
    if ((selectedEventRows || []).length > 0) return null;

    let header = '';
    let content = '';

    if ((selectedEventRows || []).length <= 0) {
      header = t('An error ocurred');
      content = t('Any item was selected for Planner Assistant');

      return <Message className="no-white-error" icon={'exclamation circle'} header={header} content={content} error />;
    }
    return null;
  };

  private isDisabledRunButton = (): boolean => {
    const { isBusy } = this.plannerAssistantStore.state;
    const { selectedEventRows } = this.props;
    const allEventStatusDraft = selectedEventRows.all(x => x.status.toString() === EventStatus[EventStatus.Draft]);

    return isBusy || !allEventStatusDraft || (selectedEventRows || []).length <= 0;
  };

  @autobind
  private onCancelPlannerAssistant() {
    this.props.onClose(false);
  }

  @autobind
  private onRunPlannerAssistant() {
    this.setState({ suggestionsReceived: false, suggestedEvents: [] });
    this.plannerAssistantStore
      .RunPlannerAssistant()
      .then(r => {
        if (r == null || !r.isSuccess) console.error('Error running planner assistant.');
        if (r != null && r.isSuccess) {
          this.setState({ errorMessagesVisible: !r.isSuccess, suggestionsReceived: r.isSuccess, suggestedEvents: r.items });
        }
      })
      .catch(_ => {
        this.setState({ errorMessagesVisible: true });
      });
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;

    if (property === 'priorityLevel') {
      change[property] = this.plannerAssistantStore.state.item.priorityLevel === value ? '' : value;
    }

    this.plannerAssistantStore.change({ ...this.plannerAssistantStore.state.item, ...change });
  }

  private onHideErrorMessages = () => {
    this.setState({ errorMessagesVisible: false });
  };

  private saveEvent = async (event: EventDto) => {
    event.fromSuggestion = true;
    await this.eventFormStore.PlannerAssistantUpdateSuggestion(event);

    if (this.eventFormStore.state.result == null || !this.eventFormStore.state.result.isSuccess) {
      const content = (this.eventFormStore.state.result.messages || []).map(x => x.body).join('. ');
      console.error(content);
    }

    if (this.eventFormStore.state.result != null && this.eventFormStore.state.result.isSuccess) {
      this.setState({ errorMessagesVisible: !this.eventFormStore.state.result.isSuccess });
    }
  };

  acceptSuggestionFromInstructorTabPaneHandler(event: EventDto) {
    const instructors = [...this.props.eventFormStore.state.item.instructors];

    if (this.props.onClose) {
      const eventViewModel = buildEventViewModel(event);

      instructors.map(instructor => {
        eventViewModel.instructors = [...eventViewModel.instructors, instructor];
        return null;
      });

      this.eventFormStore.change({ ...eventViewModel });

      this.props.onClose(false);
    }
  }

  async acceptSuggestionFromEventsHomePageHandler(event: EventDto) {
    const suggestions = this.state.visibleSuggestions;
    const eventId = event.id;

    await this.saveEvent(event);

    if (this.eventFormStore.state.result != null && this.eventFormStore.state.result.isSuccess) {
      if (!suggestions.any(x => x === eventId)) {
        suggestions.push(eventId);
      }

      this.setState({ visibleSuggestions: suggestions });

      const suggestedEvents = this.state.suggestedEvents;
      const suggestedEventIds = Array.from(new Set(suggestedEvents.map(e => e.id)));

      const allElementsPresent = suggestedEventIds.every(element => suggestions.includes(element));
      if (allElementsPresent && this.props.onClose) {
        this.props.onClose(false);
      }
    }
  }

  async onAcceptSuggestionClick(event: EventDto) {
    const { origen } = this.props;
    switch (origen) {
      case 'instructorTabPane':
        this.acceptSuggestionFromInstructorTabPaneHandler(event);
        break;
      default:
      case 'eventsHomePage':
        await this.acceptSuggestionFromEventsHomePageHandler(event);
        break;
    }
  }

  isVisibleSuggestionBox = (eventId: string): boolean => {
    const visible = !this.state.visibleSuggestions.any(x => x === eventId);
    return visible;
  };

  public render() {
    const { t } = this.props;
    const { item } = this.plannerAssistantStore.state;
    const { suggestedEvents, suggestionsReceived, showCalendarView } = this.state;

    return (
      <>
        <Modal open closeOnEscape={true} onClose={this.onCancelPlannerAssistant} closeOnDimmerClick={false}>
          <Dimmer
            active={this.plannerAssistantStore.state.isBusy || this.eventFormStore.state.isBusy}
            inverted
            style={{ position: 'fixed' }}
          >
            <Loader indeterminate inverted></Loader>
          </Dimmer>

          {this.getEventStoreErrorMessages()}
          {this.getStoreErrorMessages()}
          {this.getSelectionErrorMessages()}

          <Modal.Header>
            <Button
              className="form__modal__left-container form__modal__close-menu-left-container form__header-top-container form__modal__close-btn"
              style={{
                background: 'rgba(0, 0, 0, 0)',
                fontSize: '20px',
                width: 'auto',
                textAlign: 'end',
                height: 'fit-content !important',
                padding: '0px',
                marginLeft: '0px',
                marginTop: '0px'
              }}
              icon
              onClick={() => {
                if (this.props.onClose) this.props.onClose(false);
              }}
            >
              <Icon name="times" />
            </Button>
            {t('Planner Assistant')}
          </Modal.Header>
          <Modal.Content style={{ padding: 40 }} image>
            <Container>
              {item && (
                <Form>
                  <Form.Group widths="2">
                    <Form.Field>
                      <div className={`field`}>
                        <Form.Checkbox
                          label={t('Events Priorization')}
                          style={{ top: 25 }}
                          toggle
                          checked={item && item.priorityLevel}
                          placeholder={t('Flexible Date')}
                          onChange={(e, { checked }) => {
                            this.handleValue(nameof<PlannerAssistantDto>('priorityLevel'), checked);
                          }}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field`}>
                        <label>{t('Events Duration Coefficient')}</label>
                        <Form.Input
                          min="0.01"
                          step="0.01"
                          max="1"
                          data-number-to-fixed="2"
                          data-number-stepfactor="100"
                          type="number"
                          fluid
                          placeholder={t('1')}
                          value={item.durationCoefficient}
                          onChange={(e, { value }) => this.handleValue(nameof<PlannerAssistantDto>('durationCoefficient'), value)}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="2"></Form.Group>
                  <Form.Group widths="2">
                    <Form.Field>
                      <div className={`field`}>
                        <Popup
                          on="hover"
                          position="bottom center"
                          inverted
                          size="large"
                          className="field-group-description-popup"
                          trigger={
                            <Checkbox
                              label={t('Simultaneous Events')}
                              checked={item.simultaneousEvents}
                              onChange={(_, { checked }) => {
                                this.handleValue(nameof<PlannerAssistantDto>('simultaneousEvents'), checked);
                                if (checked) this.handleValue(nameof<PlannerAssistantDto>('consecutiveEvents'), false);
                              }}
                            />
                          }
                        >
                          <div>
                            {t('Planner Assistant consider planning these requests simultaneously')} <br />
                          </div>
                        </Popup>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field`}>
                        <Popup
                          on="hover"
                          position="bottom center"
                          inverted
                          size="large"
                          className="field-group-description-popup"
                          trigger={
                            <Checkbox
                              label={t('Consecutive Events')}
                              checked={item.consecutiveEvents}
                              onChange={(_, { checked }) => {
                                this.handleValue(nameof<PlannerAssistantDto>('consecutiveEvents'), checked);
                                if (checked) this.handleValue(nameof<PlannerAssistantDto>('simultaneousEvents'), false);
                              }}
                            />
                          }
                        >
                          <div>
                            {t('Planner Assistant consider planning these requests consecutive')} <br />
                          </div>
                        </Popup>
                      </div>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="2">
                    <Form.Field>
                      <div className={`field`}>
                        <Form.Checkbox
                          label={t('Flexible Date')}
                          style={{ top: 25 }}
                          toggle
                          checked={item && item.isFlexibleDate}
                          placeholder={t('Flexible Date')}
                          onChange={(e, { checked }) => {
                            this.handleValue(nameof<PlannerAssistantDto>('isFlexibleDate'), checked);
                          }}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                </Form>
              )}
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCancelPlannerAssistant} basic>
              {t('Cancel')}
            </Button>
            <Button disabled={this.isDisabledRunButton()} onClick={this.onRunPlannerAssistant} positive>
              {t('Run')}
            </Button>
          </Modal.Actions>
          {suggestionsReceived && (
            <div className="instructor-event-suggestion">
              <div
                className="header-instructor-event-suggestion"
                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}
              >
                <h3>{t('Suggested Events Instructor')}</h3>
                {(suggestedEvents || []).length > 0 && (
                  <div className="section" style={{ position: 'absolute', right: '0' }}>
                    <div className="section__btns" style={{ width: 'fit-content', margin: '0 auto', padding: '0px 25px' }}>
                      <Button onClick={this.handleShowCalendarViewModal} secondary inverted>
                        {t('Calendar View')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <LineSeparator />
              {(suggestedEvents || []).length > 0 && (
                <div className="instructor-event-suggestion-items">
                  {suggestedEvents.map((event, index) => (
                    <PlannerAssistantEventsSuggestion
                      key={event.id + '-' + index}
                      event={event}
                      onAcceptSuggestionClick={value => this.onAcceptSuggestionClick(value)}
                      hide={this.isVisibleSuggestionBox(event.id)}
                    />
                  ))}
                </div>
              )}
              {(suggestedEvents || []).length <= 0 && (
                <div className="instructor-event-suggestion-items">
                  <Message
                    className={'error-message__style'}
                    icon={'exclamation circle'}
                    content={t('Any instructor meets the requirements to be suggested.')}
                  />
                </div>
              )}
            </div>
          )}
        </Modal>

        {showCalendarView && (
          <SchedulerViewModal onClose={this.handleHideCalendarViewModal} events={suggestedEvents} open={showCalendarView} />
        )}
      </>
    );
  }
}

export default withTranslation()(PlannerAssistant);
