import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Icon } from 'semantic-ui-react';

import { CreatePatternDto, NewPatternStore, CreatePatternValidator } from '../../../../../stores/configuration/machinery/patterns-store';
import { nameof } from '../../../../../utils/object';
import PatternRowsList from '../pattern-rows/pattern.rows-list';
import './patterns.less';
import i18n from 'i18n';

interface NewPatternViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newPattern?: NewPatternStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewPatternViewState {
  errorMessages: any[];
}

@connect(['newPattern', NewPatternStore])
class NewPatternView extends React.Component<NewPatternViewProps, NewPatternViewState> {
  private get newPatternStore() {
    return this.props.newPattern;
  }

  constructor(props: NewPatternViewProps) {
    super(props);

    this.state = {
      errorMessages: []
    };

    this.newPatternStore.createNew({
      name: '',
      patternRows: [],
      active: true
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPatternViewProps) {
    if (this.newPatternStore.state.result && this.newPatternStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    const validator = new CreatePatternValidator();
    if (validator != null) {
      const result = validator.extendValidate(this.newPatternStore.state.item);
      const errorMessages = result.isValid() ? [] : result.getFailureMessages().map(x => ({ level: 'Error', body: x }));
      this.setState({ errorMessages });
    }

    this.newPatternStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPatternStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newPatternStore.change({ ...this.newPatternStore.state.item, ...change });
  }

  @autobind
  private addRow() {
    const row = {
      patternId: null,
      machineRelatedClusterId: null,
      equipmentTypeId: null,
      oemId: null,
      machineModelId: null,
      isOnlyRequest: true,
      order: 0
    };

    const item = this.newPatternStore.state.item;
    row.order = item.patternRows.length;
    item.patternRows = [...item.patternRows, row];

    this.newPatternStore.change(item);
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal className="create-new-pattern__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPatternStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Create New Pattern')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.state.errorMessages.length > 0 && (
              <Message
                className="error-message__style"
                error
                header={t('An error ocurred')}
                list={this.state.errorMessages.map(o => o.body).filter(x => x !== '')}
              />
            )}
            {this.newPatternStore.state.result && this.state.errorMessages.length === 0 && !this.newPatternStore.state.result.isSuccess && (
              <Message
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPatternStore.state.result.messages.map(o => o.body)}
              />
            )}

            {this.newPatternStore.state.item && (
              <Form loading={this.newPatternStore.state.isBusy}>
                <Form.Group className="new-pattern-modal__name-btn-wrapper">
                  <Form.Input
                    width={6}
                    required
                    fluid
                    label={t('Pattern')}
                    placeholder={t('Name')}
                    value={this.newPatternStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreatePatternDto>('name'), value)}
                  />

                  <Button className="custom-table-add-row-btn-inverted" icon size="mini" type="button" onClick={this.addRow}>
                    {i18n.t('Add Row ')}
                    <Icon circular size="tiny" name="add" />
                  </Button>
                </Form.Group>

                <PatternRowsList newPattern={this.props.newPattern} />

                <Form.Checkbox
                  toggle
                  label={t('Active')}
                  checked={this.newPatternStore.state.item.active}
                  placeholder={t('Active')}
                  onChange={(e, { checked }) => this.handleValue(nameof<CreatePatternDto>('active'), checked)}
                />
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPatternView);
