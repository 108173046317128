import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
// import { IdentityService } from 'services/identity-service';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
// import { resolve } from 'inversify-react';
// import { ItemReference } from 'stores/dataStore';
import { TnaSkillsStore, TnaSkillDto } from 'stores/assessments/skills/tna-skills-store';
import { SsaSkillsStore, SsaSkillDto } from 'stores/assessments/skills/ssa-skills-store';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import { Assessment } from 'stores/profile/profile-store';
import { IwsSkillDto, IwsSkillsStore } from 'stores/assessments/skills/iws-skills-store';

interface SkillsSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: any) => void;
  tnaSkillsStore?: TnaSkillsStore;
  ssaSkillsStore?: SsaSkillsStore;
  iwsSkillsStore?: IwsSkillsStore;
  assessment: any;
}

@connect(['tnaSkillsStore', TnaSkillsStore], ['ssaSkillsStore', SsaSkillsStore], ['iwsSkillsStore', IwsSkillsStore])
class SkillsSelector extends React.Component<SkillsSelectorProps> {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { tnaSkillsStore, ssaSkillsStore, iwsSkillsStore, assessment } = this.props;
    const filter = [];
    this.setState({ isLoading: false });
    const tnaOrder: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<TnaSkillDto>('name'), useProfile: false }];
    const ssaOrder: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<SsaSkillDto>('name'), useProfile: false }];
    const iwsOrder: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<IwsSkillDto>('name'), useProfile: false }];
    let result: Promise<QueryResult<SsaSkillDto | TnaSkillDto | IwsSkillDto>>;

    if (assessment === Assessment.TNA) {
      result = tnaSkillsStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy: tnaOrder, filter });
    } else if (assessment === Assessment.SSA) {
      result = ssaSkillsStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy: ssaOrder, filter });
    } else if (assessment === Assessment.IWS) {
      result = iwsSkillsStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy: iwsOrder, filter });
    }

    if (result) {
      result?.then(response => {
        const options = (response?.items || []).map(item => {
          const { id, name } = item;
          // if (assessment === Assessment.TNA) return { value: id, text: name, isMachineRelated: (item as TnaSkillDto).isMachineRelated };
          return { value: id, text: name };
        });
        this.setState({ isLoading: false, options });
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.assessment !== this.props.assessment) this.load();
  }

  onChangeHandler = ({ options, value }: DropdownProps) => {
    const selectedOption = options
      .filter(opt => opt.value === value)
      .map(v => {
        const returnObj: any = { title: v.text, id: v.value };
        if (v.isMachineRelated != null) returnObj.isMachineRelated = v.isMachineRelated;
        return returnObj;
      });
    this.props.onChange(selectedOption[0]);
  };

  public render() {
    const selectedValue = this.props.value ? this.props.value : null;

    return (
      <Dropdown
        selection
        loading={this.state.isLoading}
        search={true}
        clearable={this.props.clearable}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={selectedValue}
        options={this.state.options}
        onChange={(event, data) => this.onChangeHandler(data)}
        selectOnBlur={false}
        disabled={this.props.readOnly}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(SkillsSelector);
