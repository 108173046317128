import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Divider, Button } from 'semantic-ui-react';
import { SsaFormStatus } from 'stores/assessments/forms/ssa-forms-store';

interface SSAFormFooterComponentProps extends WithTranslation {
  isCurrentUserAssessor: boolean;
  isCurrentUserPocOrAdmin: boolean;
  isModifiableStatusByAssessor: boolean;
  formStatus: SsaFormStatus;
  pocAdminCanReturned: boolean;
  onSubmit: () => void;
  onSaveSsa: () => void;
  isAbleToSubmit: boolean;
  onReturned: () => void;
  showConfirmButton: boolean;
}

class SSAFormFooterComponent extends Component<SSAFormFooterComponentProps> {
  isSendBtnVisible = (): boolean => {
    if (!this.props.isCurrentUserPocOrAdmin) return true;
    else return false;
  };

  handleOnClickSendButton = () => {
    this.props.onSubmit();
  };

  private getSsaFormEditMode = (): boolean => {
    const { formStatus } = this.props;
    if (formStatus.toString() === 'Done') {
      return true;
    } else if (formStatus.toString() === 'Review') {
      return true;
    }
  };

  render() {
    const { t, isModifiableStatusByAssessor, pocAdminCanReturned, onReturned, isAbleToSubmit, onSaveSsa, onSubmit } = this.props;
    return (
      <>
        <Divider />

        <div className="flex-end ssa-form-buttons-container">
          {isModifiableStatusByAssessor && (
            <div className="tna-edit-form__footer__btns flex-end">
              <Button disabled={this.getSsaFormEditMode()} className="white-save-btn" onClick={onSaveSsa} positive>
                {t('Save')}
              </Button>

              {isAbleToSubmit && (
                <Button disabled={this.getSsaFormEditMode()} className="inverted-color-btn" onClick={onSubmit} positive>
                  {t('Send SSA')}
                </Button>
              )}
            </div>
          )}

          {pocAdminCanReturned && (
            <div className="tna-edit-form__footer__btns flex-end">
              <>
                <Button className="white-save-btn ssa-form-return-btn" onClick={onReturned} positive>
                  {t('Return to Assessors')}
                </Button>
                {this.props.showConfirmButton && (
                  <Button onClick={onSubmit} className="inverted-color-btn ssa-form-return-btn" positive>
                    {t('Confirm and Close')}
                  </Button>
                )}
              </>
            </div>
          )}
        </div>

        <br />
      </>
    );
  }
}
export default withTranslation()(SSAFormFooterComponent);
