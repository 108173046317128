import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { ClusterDto, DropDownClustersStore } from 'stores/configuration/machinery/clusters-store';

interface MrClusterEditorProps extends WithTranslation {
  value: ClusterDto[];
  onChange?: (value: ClusterDto[]) => void;
  mrClusters?: DropDownClustersStore;
  placeholder?: string;
  clearable?: boolean;
  disabled?: boolean;
  className?: string;
  mrClustersReceived?: string[];
}

interface MrClusterEditorState {
  options: MultipleSelectionDropdownOptions<ClusterDto>[];
}

@connect(['mrClusters', DropDownClustersStore])
class MultipleMrClusterEditor extends React.Component<MrClusterEditorProps, MrClusterEditorState> {
  private get mrClusterStore() {
    return this.props.mrClusters;
  }

  state: MrClusterEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions = (mrClusters: QueryResult<ClusterDto>): MultipleSelectionDropdownOptions<ClusterDto>[] =>
    (mrClusters?.items || []).map(region => ({ id: region.id, value: region, text: region.name }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      let filter;
      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<ClusterDto>('name'), useProfile: false }];

      if (this.props.mrClustersReceived && this.props.mrClustersReceived.length > 0)
        filter = [{ id: { in: { type: 'guid', value: this.props.mrClustersReceived } } }];

      this.mrClusterStore
        .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }, this.props.mrClustersReceived)
        .then(mrClusters => this.setState({ options: this.mapToOptions(mrClusters) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        disabled={this.props.disabled}
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleMrClusterEditor);
