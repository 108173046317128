import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dimmer, Loader, Button, Message, Modal } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import FirstStep from './steps/feedbacks-wizard-first-step';
import '../feedbacks-templates.styles.less';
import { FeedbackTemplateItemDto, FeedbacksTemplatesStore } from 'stores/assessments/templates/feedbacks-templates-store';
import { RouteComponentProps } from 'react-router';
import LineSeparator from 'widgets/bussiness/line-separator';
import SecondStep from './steps/feedbacks-wizard-second-step';
import ThirdStep from './steps/feedbacks-wizard-third-step';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import './feedbacks-templates-wizard.styles.less';

export interface FeedbacksTemplateWizardProps extends WithTranslation, RouteComponentProps {
  feedbacksTemplateStore?: FeedbacksTemplatesStore;
}
type ModalMode = 'cancel' | 'accept';
export interface FeedbacksTemplateWizardState {
  showConfirmationModal: boolean;
  confirmationModalMode: ModalMode;
  loading: boolean;
}

@connect(['feedbacksTemplateStore', FeedbacksTemplatesStore])
class FeedbacksTemplateWizard extends Component<FeedbacksTemplateWizardProps, FeedbacksTemplateWizardState> {
  state: FeedbacksTemplateWizardState = {
    confirmationModalMode: null,
    showConfirmationModal: false,
    loading: false
  };

  private get plainNewItem(): FeedbackTemplateItemDto {
    return {
      id: '',
      eventTypes: [],
      feedbackQuestions: [],
      friendlyId: '',
      header: '',
      isActive: true,
      respondent: null,
      title: ''
    };
  }

  private get templates() {
    return this.props.feedbacksTemplateStore;
  }
  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state || match.params['id']) return true;
    else return false;
  };

  componentDidMount() {
    this.templates.clear();
    const { params } = this.props.match;
    const id = params['id'];
    if (id != null) this.loadTemplate(id);
    else this.createNewItemStore(this.plainNewItem);
  }

  loadTemplate = async (id: string) => {
    const { history } = this.props;
    try {
      this.setState({ loading: true });
      const templData = await this.templates.getTemplateById(id);
      this.setState({ loading: false });
      this.createNewItemStore(templData);
    } catch (error) {
      history.replace('/not-found');
    }
  };

  createNewItemStore = (item: FeedbackTemplateItemDto) => {
    this.templates.change({ ...item });
  };

  goBack = () => {
    const { history } = this.props;
    this.templates.clear();
    history.goBack();
  };

  submit = async () => {
    this.cancelConfirmationModal();
    if (this.isOnEditMode()) {
      const { item } = this.props.feedbacksTemplateStore.state;
      if (!item.isActive) {
        item.isActive = true;
        this.props.feedbacksTemplateStore.change({ ...item });
      }
      await this.templates.changeTemplate().then(res => res.isSuccess && this.goBack());
    } else await this.templates.newTemplate().then(res => res.isSuccess && this.goBack());
  };

  confirmationModalHandler = (mode: ModalMode) => {
    this.setState({ showConfirmationModal: true, confirmationModalMode: mode });
  };

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, confirmationModalMode: null });
  };

  getUniqueValues = (value: any, index: number, self: any[]) => {
    return self.indexOf(value) === index;
  };

  changeHandler = (change: Partial<FeedbackTemplateItemDto>) => {
    this.props.feedbacksTemplateStore.change({ ...change });
  };

  stepTwoActiveHandler = (): boolean => {
    const { item } = this.props.feedbacksTemplateStore.state;
    if (!item) return false;
    return !isNullOrWhiteSpaces(item.title) && !isNullOrWhiteSpaces(item.respondent);
  };
  stepThreeActiveHandler = (): boolean => {
    const { item } = this.props.feedbacksTemplateStore.state;
    if (!item) return false;
    return !isNullOrWhiteSpaces(item.title) && !isNullOrWhiteSpaces(item.respondent) && (item.eventTypes || []).length > 0;
  };
  isAbleToSubmit = (): boolean => {
    const { item } = this.props.feedbacksTemplateStore.state;
    if (!item) return false;
    return !isNullOrWhiteSpaces(item.title) && (item.eventTypes || []).length > 0 && (item.feedbackQuestions || []).length > 0;
  };

  render() {
    const { t, feedbacksTemplateStore } = this.props;
    const { item, isBusy, result } = feedbacksTemplateStore.state;
    const { confirmationModalMode, loading, showConfirmationModal } = this.state;
    const mode = this.isOnEditMode();

    return (
      <>
        <h3 className="template-wizard-title">{t(`Feedback Template ${mode ? 'Edition' : 'Creation'}`)}</h3>
        <LineSeparator />
        {result && !result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={result.messages.map(m => m.body)} />
        )}
        <div className="feedbacks-wizard__steps-container">
          <Dimmer page active={isBusy || loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          {item && (
            <>
              <FirstStep active header={item.header} title={item.title} respondent={item.respondent} onChange={this.changeHandler} />
              <SecondStep
                active={this.stepTwoActiveHandler()}
                onChange={this.changeHandler}
                respondent={item.respondent}
                selectedOptions={(item.eventTypes || []).map(({ originalId }) => originalId)}
                clearable
              />
              <ThirdStep active={this.stepThreeActiveHandler()} questions={item.feedbackQuestions} onChange={this.changeHandler} />
            </>
          )}
        </div>

        <div className="feedbacks-templates-wizard__btns">
          <Button onClick={() => this.confirmationModalHandler('cancel')} secondary inverted>
            {t('Cancel')}
          </Button>
          <Button className="submit-btn" disabled={!this.isAbleToSubmit()} onClick={() => this.confirmationModalHandler('accept')}>
            {t(`${mode ? 'Save' : 'Create'} Template`)}
          </Button>
        </div>
        {showConfirmationModal && (
          <Modal
            open={showConfirmationModal}
            size="mini"
            className="feedbacks-confirmation-modal"
            closeOnEscape={true}
            onClose={this.cancelConfirmationModal}
          >
            <Modal.Content className="feedbacks-confirmation-modal__content">
              {confirmationModalMode === 'accept' ? (
                item.isActive ? (
                  <p>
                    {t(
                      `${
                        mode
                          ? 'Are you sure do you want to apply this changes in this feedback template?'
                          : 'Do you want to create the feedback Template'
                      }`
                    )}
                  </p>
                ) : (
                  <>
                    <p>{t("Saving the template will change it's status to active.")}</p> <p>{t('Are you sure you want to continue?')}</p>
                  </>
                )
              ) : (
                <>
                  <p>{t('Are you sure you want to CANCEL?')}</p>
                  <p>{t('You will lose all the changes made in this feedback template.')}</p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                size="small"
                className="feedbacks-confirmation-modal__btn cancel"
                content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                onClick={this.cancelConfirmationModal}
              />
              <Button
                size="small"
                className="feedbacks-confirmation-modal__btn"
                content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                onClick={confirmationModalMode === 'accept' ? this.submit : this.goBack}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(FeedbacksTemplateWizard);
