import { withTranslation, WithTranslation } from 'react-i18next';
import { Component } from 'react';
import React from 'react';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMFunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import { ItemReference } from 'stores/dataStore';
import './non-machine-related.less';
import { Categories } from 'stores/requests/requests-store';

export interface NonMachineRelatedComponentViewModel {
  cluster: ItemReference;
  functionalArea: ItemReference;
  trainingName: ItemReference;
  functionalSubArea: ItemReference;
}

interface NonMachineRelatedComponentProps extends WithTranslation {
  readOnly?: boolean;
  value: NonMachineRelatedComponentViewModel;
  onChange?: (value: NonMachineRelatedComponentViewModel) => void;
  className?: string;
  category: Categories;
}

class NonMachineRelatedComponent extends Component<NonMachineRelatedComponentProps> {
  private handleOnClusterChange = (newCluster: ItemReference) => {
    let newValue = { ...this.props.value };
    newValue.cluster = newCluster;
    newValue.trainingName = null;
    newValue.functionalArea = null;
    newValue.functionalSubArea = null;

    this.props.onChange && this.props.onChange(newValue);
  };

  private handleOnFunctionalAreaChange = (functionalArea: ItemReference) => {
    let newValue = { ...this.props.value };
    newValue.functionalArea = functionalArea;
    newValue.trainingName = null;
    newValue.functionalSubArea = null;

    this.props.onChange && this.props.onChange(newValue);
  };

  private handleOnTrainingNameChange = (trainingLName: ItemReference) => {
    let newValue = { ...this.props.value };
    newValue.trainingName = trainingLName;
    newValue.functionalSubArea = null;

    this.props.onChange && this.props.onChange(newValue);
  };

  private handleOnFunctionalSubAreaChange = (functionalSubarea: ItemReference) => {
    let newValue = { ...this.props.value };
    newValue.functionalSubArea = functionalSubarea;

    this.props.onChange && this.props.onChange(newValue);
  };

  public render() {
    const { t, readOnly, className, value, category } = this.props;
    const { cluster, functionalArea, functionalSubArea, trainingName } = value;

    return (
      <div className={className || 'no-machine-related-wrapper'}>
        <div className="flex-center">
          <div className="no-machine-related-wrapper__element">
            <label className={readOnly ? '' : 'label-required'}>{t('Cluster')}</label>
            <NMClusterEditor
              nullable
              clearable
              category={category}
              readonly={readOnly}
              value={cluster}
              onChange={this.handleOnClusterChange}
              loadDataOnOpen
            />
          </div>

          <div className="no-machine-related-wrapper__element">
            <label className={readOnly ? '' : 'label-required'}>{t('Functional Area')}</label>
            <NMFunctionalAreaEditor
              nullable
              clearable
              clearOnReload
              readonly={readOnly || !cluster}
              clusterId={cluster?.id || null}
              value={functionalArea}
              onChange={this.handleOnFunctionalAreaChange}
              loadDataOnOpen
            />
          </div>
        </div>

        <div className="flex-center">
          <div className="no-machine-related-wrapper__element">
            <label>{t('Training Name')}</label>
            <NMTrainingNameEditor
              nullable
              clearable
              clearOnReload
              readOnly={readOnly || !functionalArea}
              funcionalAreaId={functionalArea?.id || null}
              value={trainingName}
              onChange={this.handleOnTrainingNameChange}
              loadDataOnOpen
            />
          </div>

          <div className="no-machine-related-wrapper__element">
            <label>{t('Functional Subarea')}</label>
            <NMFunctionalSubAreaEditor
              nullable
              clearable
              clearOnReload
              readonly={readOnly || !trainingName}
              trainingNameId={trainingName?.id || null}
              value={functionalSubArea}
              onChange={this.handleOnFunctionalSubAreaChange}
              loadDataOnOpen
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NonMachineRelatedComponent);
