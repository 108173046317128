import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { SupportCostDto, ChangeSupportCostDto, ChangeSupportCostStore } from 'stores/configuration/support-costs/support-costs-store';
import { nameof } from 'utils/object';

interface ChangeSupportCostViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeSupportCost?: ChangeSupportCostStore;
  currentSC: SupportCostDto;
}

interface ChangeSupportCostViewState {
  userClickedSaved: boolean;
}

@connect(['changeSupportCost', ChangeSupportCostStore])
class ChangeSupportCostView extends React.Component<ChangeSupportCostViewProps, ChangeSupportCostViewState> {
  private get changeSupportCostStore() {
    return this.props.changeSupportCost;
  }

  constructor(props: ChangeSupportCostViewProps) {
    super(props);

    this.state = {
      userClickedSaved: false
    };

    this.changeSupportCostStore.state.result = null;

    this.changeSupportCostStore.change({
      id: this.props.currentSC.id,
      cost: this.props.currentSC.cost
    });
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps: ChangeSupportCostViewProps) {
    if (this.changeSupportCostStore.state.result && this.changeSupportCostStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeSupportCostStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeSupportCostStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeSupportCostStore.change({ ...this.changeSupportCostStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeSupportCostStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t(`Edit ${this.props.currentSC.name}`)}</Modal.Header>
        <Modal.Content image>
          <Container>
            <Dimmer active={this.changeSupportCostStore.state.isBusy} inverted>
              <Loader size="large" indeterminate inverted></Loader>
            </Dimmer>
            {this.changeSupportCostStore.state.result && !this.changeSupportCostStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeSupportCostStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeSupportCostStore.state.item && (
              <Form>
                <Form.Group widths="equal" className="support-cost-form-group">
                  <Form.Input
                    required
                    min="0"
                    step="0.01"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    type="number"
                    fluid
                    error={!this.changeSupportCostStore.state.item.cost && this.state.userClickedSaved}
                    label={t('Cost')}
                    placeholder={t('Cost')}
                    value={this.changeSupportCostStore.state.item.cost ?? '0.00'}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeSupportCostDto>('cost'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeSupportCostView);
