import React, { FunctionComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface TNAFormScoreComponentProps extends WithTranslation {
  scoreName: string;
  scoreNumber: string;
  active: boolean;
  isToggleable: boolean;
  className?: string;
  onToggleGrade?: (scoreNumber: string, active: boolean) => void;
}

const TNAFormScoreComponent: FunctionComponent<TNAFormScoreComponentProps> = ({
  scoreName,
  scoreNumber,
  className,
  active,
  isToggleable,
  onToggleGrade,
  t
}) => {
  const defaultClassName = 'main-tab__skill-section__score-items';

  return (
    <div className={className ? `${className} ${defaultClassName}` : defaultClassName}>
      <div
        onClick={() => isToggleable && onToggleGrade(scoreNumber, !active)}
        className={`main-tab__skill-section__score${active ? ' active' : ''}`}
      >
        <p className="skill-section__score__number">{scoreNumber !== '5' && scoreNumber !== '-1' ? scoreNumber : 'n/a'}</p>
        <p>{scoreName}</p>
      </div>
    </div>
  );
};
export default withTranslation()(TNAFormScoreComponent);
