import React from 'react';

import { container } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FullPageLoader } from '../full-page-loader';

export const LogoutCallback = () => {
  container.get(IdentityService).signoutRedirectCallback();
  return <FullPageLoader text={'Logout redirect'} />;
};
