import { container } from 'inversify.config';
import { ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { LocationDto } from 'stores/configuration/locations/locations-store';

import i18n from '../../i18n';
import { DataStore, Query, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { RoleDto } from '../roles/roles-store';
import { BaseDto, CommandResult, Message } from '../types';
import HttpService from 'services/http-service';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import { RegionDto } from 'stores/configuration/locations/regions-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { PillarDto } from 'stores/configuration/profiles/pillars-store';
import { InstructorDto } from 'stores/instructors/instructors-store';
import { DateTimeService } from 'services/datetime-service';

export interface RoleInLocationDto extends BaseDto {
  role: RoleDto;
  location: LocationDto;
  region: RegionDto;
}

export interface CreateRoleInLocationDto {
  roleName: string;
  locationId: string;
  regionId: string;
}

export interface UserPhotoDto {
  hasPhoto: boolean;
  photo: string;
  photoType: number;
}

export interface UserDto extends BaseDto {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  roles: RoleInLocationDto[];
  locationId: string;
  location: LocationDto;
  lineManager: string;
  portrait: string;
  enabled: boolean;
  employeeId: string;
  positionCodeId: string;
  positionCode: PositionCodeDto;
  origin: string;
  region: string;
  personnelAreaId: string;
  personnelArea: string;
  sfPosition: string;
  updateFlag: boolean;
  hireDate: string;
  leavingDate: string;
  acronym: string;
  languages: LanguageDto[];
  trainingLanguages: LanguageDto[];
  hasProfileAssigned: boolean;
  pillarId: string | null;
  pillar: PillarDto;
  instructorRoleId: string;
  instructorRole: InstructorDto;
  isAssessor: boolean;
  deletedOn: string | null;
  deleted: boolean;
  rowVersion: string;
  isPowerInstructor: boolean;
  friendlyId: string;
  userPhoto: UserPhotoDto;
  isGlobalPoc: boolean;
}

export interface SimpleUserDto {
  id: string;
  userName: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  sfPosition: string;
  location: LocationDto;
}

export interface CreateUserDto {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  enabled: boolean;
  roles: CreateRoleInLocationDto[];
  employeeId: string;
  positionCodeId: string;
  positionCode: string;
  origin: string;
  region: string;
  personnelAreaId: string;
  personnelArea: string;
  sfPosition: string;
  password: string;
  portrait: string;
  locationId: string;
  location: string;
  lineManager: string;
  hireDate: string;
  acronym: string;
  languages: LanguageDto[];
  trainingLanguages: LanguageDto[];
  isGlobalPoc: boolean;
}

export interface ChangeUserDto {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  roles: CreateRoleInLocationDto[];
  portrait: string;
  locationId: string;
  location: string;
  lineManager: string;
  enabled: boolean;
  employeeId: string;
  positionCodeId: string;
  positionCode: string;
  origin: string;
  region: string;
  personnelAreaId: string;
  personnelArea: string;
  sfPosition: string;
  updateFlag: boolean;
  hireDate: string;
  leavingDate: string;
  acronym: string;
  languages: LanguageDto[];
  trainingLanguages: LanguageDto[];
  hasProfileAssigned: boolean;
  instructorRoleId: string;
  isGlobalPoc: boolean;
}

export class CreateUserValidator extends ExtendedAbstractValidator<CreateUserDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.firstName)
      .isNotEmpty()
      .withFailureMessage(i18n.t('First name is required'));

    this.validateIfString(o => o.lastName)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('Last name is required'));

    this.validateIfString(o => o.email)
      .isNotEmpty()
      .isEmail()
      .withFailureMessage(i18n.t('A valid user email is required'));

    this.validateIfString(o => o.userName)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('User name is required'));

    this.validateIfString(o => o.password)
      .isNotEmpty()
      .hasMinLength(8)
      .withFailureMessage(i18n.t('Password needs to have at least 8 characters'));

    this.validateIf(o => o.password)
      .isNotNull()
      .fulfills(password => {
        return (
          password.includes('1') ||
          password.includes('2') ||
          password.includes('3') ||
          password.includes('4') ||
          password.includes('5') ||
          password.includes('6') ||
          password.includes('7') ||
          password.includes('8') ||
          password.includes('9')
        );
      })
      .withFailureMessage(i18n.t('Password needs at least one number'));

    this.validateIf(o => o.password)
      .isNotNull()
      .fulfills(password => {
        return (
          password.includes('(') ||
          password.includes(')') ||
          password.includes('=') ||
          password.includes('?') ||
          password.includes('¿') ||
          password.includes('$') ||
          password.includes('%') ||
          password.includes('&') ||
          password.includes('/') ||
          password.includes('@') ||
          password.includes('#') ||
          password.includes('_') ||
          password.includes('!') ||
          password.includes('"') ||
          password.includes('¡')
        );
      })
      .withFailureMessage(i18n.t('Password needs at least one special character from this list ( ) = ? ¿ $ % & / @ # _ ! " or ¡'));

    this.validateIf(o => o.password)
      .isNotNull()
      .fulfills(password => {
        return (
          password.includes('A') ||
          password.includes('B') ||
          password.includes('C') ||
          password.includes('D') ||
          password.includes('E') ||
          password.includes('F') ||
          password.includes('G') ||
          password.includes('H') ||
          password.includes('I') ||
          password.includes('J') ||
          password.includes('K') ||
          password.includes('L') ||
          password.includes('M') ||
          password.includes('N') ||
          password.includes('O') ||
          password.includes('P') ||
          password.includes('Q') ||
          password.includes('R') ||
          password.includes('S') ||
          password.includes('T') ||
          password.includes('U') ||
          password.includes('V') ||
          password.includes('W') ||
          password.includes('X') ||
          password.includes('Y') ||
          password.includes('Z')
        );
      })
      .withFailureMessage(i18n.t('Password needs at least one Upper character'));

    this.validateIfString(o => o.lineManager)
      .isNotEmpty()
      .hasMinLength(1)
      .withFailureMessage(i18n.t('Line manager is required'));

    this.validateIfString(o => o.locationId)
      // .isUuid('4')
      .isNotEmpty()
      .withFailureMessage(i18n.t('A location is required'));

    this.validateIf(o => o.roles)
      .isNotEmpty()
      .withFailureMessage(i18n.t('At least one role is required'));

    this.validateIf(x => x)
      .fulfills(x => (x.roles || []).all((p, idx) => new RoleValidator(p.roleName, idx, this.addErrors).extendValidate(p).isValid()))
      .withFailureMessage(i18n.t(`At least one role is wrong`));
  }
}

export class ChangeUserValidator extends ExtendedAbstractValidator<ChangeUserDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.firstName)
      .isNotEmpty()
      .withFailureMessage(i18n.t('First name is required'));

    this.validateIfString(o => o.lastName)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('Last name is required'));

    this.validateIfString(o => o.email)
      .isNotEmpty()
      .isEmail()
      .withFailureMessage(i18n.t('A valid user email is required'));

    this.validateIfString(o => o.userName)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('User name is required'));

    this.validateIfString(o => o.lineManager)
      .isNotEmpty()
      .hasMinLength(1)
      .withFailureMessage(i18n.t('Line manager is required'));

    this.validateIfString(o => o.locationId)
      .isUuid('4')
      .isNotEmpty()
      .withFailureMessage(i18n.t('A location is required'));

    this.validateIf(o => o.roles)
      .isNotEmpty()
      .withFailureMessage(i18n.t('At least one role is required'));

    this.validateIf(x => x)
      .fulfills(x => (x.roles || []).all((p, idx) => new RoleValidator(p.roleName, idx, this.addErrors).extendValidate(p).isValid()))
      .withFailureMessage(i18n.t(`At least one role is wrong`));

    this.validateIf(o => o.hireDate)
      .fulfills(hireDate => {
        return !hireDate || (DateTimeService.isValid(hireDate) && DateTimeService.isCoherent(hireDate));
      })
      .withFailureMessage(i18n.t(`Hire Date is not consistent`));

    this.validateIf(o => o.leavingDate)
      .fulfills(leavingDate => {
        return !!leavingDate && DateTimeService.isValid(leavingDate) && DateTimeService.isCoherent(leavingDate);
      })
      .when(x => !x.enabled)
      .withFailureMessage(i18n.t(`Leaving Date is not consistent`));
  }
}

var rolesWithLocations = ['Employee', 'Reporting', 'Instructor', 'PoC', 'Factory Lead'];
var rolesWithRegion = ['Global Manufacturing', 'Regional Manufacturing VP'];
var normalRoles = ['Global Engineering', 'Planner', 'Admin', 'Planner MTC'];

export class RoleValidator extends ExtendedAbstractValidator<CreateRoleInLocationDto> {
  constructor(name: string, idx?: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(o => o.locationId)
      .isDefined()
      .isNotEmpty()
      .isNotNull()
      .when(o => this.isRoleWithoutLocationRequired(name))
      .withFailureMessage(i18n.t(`At least one location is required for role ${name}`));

    this.validateIf(o => o.regionId)
      .isDefined()
      .isNotEmpty()
      .isNotNull()
      .when(o => this.isRoleWithRegionRequired(name))
      .withFailureMessage(i18n.t(`At least one region is required for role ${name}`));
  }

  private isRoleWithLocationRequired(roleName: string) {
    return normalRoles.any(x => x === roleName);
  }

  private isRoleWithoutLocationRequired(roleName: string) {
    return rolesWithLocations.any(x => x === roleName);
  }

  private isRoleWithRegionRequired(roleName: string) {
    return rolesWithRegion.any(x => x === roleName);
  }
}

@repository('@@USERS', 'users.summary')
export class UserStore extends DataStore<UserDto> {
  baseUrl = 'identity/api';
  createPath = 'v1/create-user';
  retrievePath = 'v2/get-users';
  updatePath = 'v1/update-user';
  deletePath = 'v1/delete-user';
  retrieveOnePath = 'v1/get-user';
  retrieveByRolesPath = 'v1/get-users-with-role';
  retrieveByLocationAndRolePath = 'v1/get-users-by-location-and-role';
  retrieveByRoleAndLocationsAndOdataPath = 'v1/get-users-by-locations-and-role';
  retrieveByEmployeeAndLocationsAndOdataPath = 'v1/get-users-by-employee-locations-and-role';

  protected validate(item: UserDto) {
    return new ChangeUserValidator().validate(item as any);
  }

  constructor() {
    super('USER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getEmployeeLocationsOData(query: Query, locationIds?: string[], role?: string, data?: any): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const parts = [];
    if ((locationIds || []).length !== 0) parts.push(`locationIds=${locationIds.join('&locationIds=')}`);

    if (!isNullOrWhiteSpaces(role)) parts.push(`role=${role}`);

    parts.push(path);

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<UserDto>>(`${this.baseUrl}/${this.retrieveByEmployeeAndLocationsAndOdataPath}?${parts.join('&')}`, data)
    );
    return result.data;
  }

  public async getUsersByRoleAndLocationsAndOData(
    query: Query,
    locationIds?: string[],
    role?: string,
    data?: any
  ): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const parts = [];
    if ((locationIds || []).length !== 0) parts.push(`locationIds=${locationIds.join('&locationIds=')}`);

    if (!isNullOrWhiteSpaces(role)) parts.push(`role=${role}`);

    parts.push(path);

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<UserDto>>(`${this.baseUrl}/${this.retrieveByRoleAndLocationsAndOdataPath}?${parts.join('&')}`, data)
    );
    return result.data;
  }

  public async getAllUsersWithRoleAsync(query: Query, role: string, data?: any, invertedSearch?: boolean): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<UserDto>>(
        `${this.baseUrl}/${this.retrievePath}?role=${role}&invertRoleSearch=${!!invertedSearch}&${path}`,
        data
      )
    );
    return result.data;
  }

  public async getAllUsersWithRoleValidationAsync(
    query: Query,
    role: string,
    rolesRequiredToValidateStatus: string[],
    data?: any,
    invertedSearch?: boolean
  ): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    if (rolesRequiredToValidateStatus.includes(role.replace(/\s/g, ''))) {
      const result = await this.dispatchAsync(
        this.ENTITY_LIST_UPDATE,
        httpService.get<QueryResult<UserDto>>(
          `${this.baseUrl}/${this.retrievePath}?role=${role}&invertRoleSearch=${!!invertedSearch}&${path}`,
          data
        )
      );
      return result.data;
    } else {
      let eventsResult = { items: [], count: 0 } as QueryResult<UserDto>;
      return new Promise<QueryResult<UserDto>>(resolve => resolve(eventsResult));
    }
  }

  public async getUsersbyLocationAndRoleAsync(
    locationId: string,
    role: string,
    enabled?: boolean,
    query?: Query
  ): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);

    let queryPath = '';
    let suffix = '';
    if (enabled != null) suffix = `&enabled=${enabled}`;
    if (query) queryPath = `&${DataStore.getRequestParts(query).path}`;

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<UserDto>>(
        `${this.baseUrl}/${this.retrieveByLocationAndRolePath}?locationId=${locationId}&role=${role}${suffix}${queryPath}`
      )
    );

    return result.data;
  }

  public async getUserById(id: string): Promise<UserDto> {
    const httpService = container.get(HttpService);

    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<UserDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

//
@repository('@@USERS', 'users.dropdown')
export class DropDownUserStore extends DataStore<UserDto> {
  baseUrl = 'identity/api';
  createPath = '';
  retrievePath = 'v2/get-users';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = '';
  retrieveByRolesPath = 'v1/get-users-with-role';

  protected validate(item: UserDto) {
    return new ChangeUserValidator().validate(item as any);
  }

  constructor() {
    super('USERDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllUsersWithRoleAsync(query: Query, role: string, data?: any, invertedSearch?: boolean): Promise<QueryResult<UserDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<UserDto>>(
        `${this.baseUrl}/${this.retrievePath}?role=${role}&invertRoleSearch=${!!invertedSearch}&${path}`,
        data
      )
    );
    return result.data;
  }
}

@repository('@@USERS', 'user.new')
export class NewUserStore extends FormStore<CreateUserDto> {
  baseUrl = 'identity/api/v1';
  createPath = 'create-user';
  retrievePath = 'get-users';
  updatePath = 'update-user';
  deletePath = 'delete-user';

  protected validate(item: CreateUserDto) {
    return new CreateUserValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_USER', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@USERS', 'user.change')
export class ChangeUserStore extends FormStore<ChangeUserDto> {
  baseUrl = 'identity/api/v1';
  createPath = 'create-user';
  retrievePath = 'get-user';
  updatePath = 'update-user';
  deletePath = 'delete-user';

  protected validate(item: ChangeUserDto) {
    return new ChangeUserValidator().extendValidate(item);
  }

  constructor() {
    super('CHANGE_USER', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }

  public async updateUserWithFlags(ignoreUpdateFlag: boolean = true, overrideSsffProps: boolean = true): Promise<CommandResult<UserDto>> {
    const validationResult = this.validate(this.state.item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages:
          validationResult == null
            ? [{ propertyName: '', body: 'Something went wrong', level: 'Error' } as Message]
            : validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    } else {
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<any, CommandResult<UserDto>>(
          `${this.baseUrl}/${this.updatePath}?ignoreUpdateFlag=${ignoreUpdateFlag}&overrideSsffProps=${overrideSsffProps}`,
          this.state.item
        )
      );
      if (result) return result.data;
      return null;
    }
  }
}
