import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Icon, Message, Checkbox, Grid } from 'semantic-ui-react';

import {
  MachineModelDto,
  MachineModelsStore,
  ChangeMachineModelStore
} from '../../../../../stores/configuration/machinery/machine-models-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import ChangeMachineModelView from './edit-machine-model';
import NewMachineModelView from './new-machine-model';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

export interface MachineModelsListProps extends WithTranslation, RouteComponentProps {
  machineModels: MachineModelsStore;
  changeMachineModelStore?: ChangeMachineModelStore;
  readOnly: boolean;
}

export interface MachineModelsListState {
  query: Query;
  newMachineModelShown: boolean;
  changeMachineModelShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['machineModels', MachineModelsStore], ['changeMachineModelStore', ChangeMachineModelStore])
class MachineModelsListPage extends React.Component<MachineModelsListProps, MachineModelsListState> {
  handleOnEnterKeydown = item => {
    const { readOnly } = this.props;
    !readOnly && this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<MachineModelDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newMachineModelShown: false,
      changeMachineModelShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.machineModels.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: MachineModelDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.machineModels.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: MachineModelDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.machineModels.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newMachineModelShown: true });
  }

  @autobind
  private onEditItem() {
    this.setState({ changeMachineModelShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeMachineModelShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newMachineModelShown: false });
    if (isSuccess) this.load();
  }

  private uploadComplete(data: any) {
    this.load();
  }

  handleToggleAvailability = async (item: MachineModelDto) => {
    if (!item) return;
    const { id, name, clusterId, equipmentTypeId, oemId, description, active } = item;
    this.props.changeMachineModelStore.change({ id, name, clusterId, equipmentTypeId, oemId, description, active: !active });
    await this.props.changeMachineModelStore.update();
    this.load();
  };

  onDismiss = () => {
    const { machineModels, changeMachineModelStore } = this.props;
    machineModels.clearMessages();
    changeMachineModelStore.clearMessages();
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, readOnly, machineModels, changeMachineModelStore } = this.props;
    const { activeFilters, newMachineModelShown, changeMachineModelShown, selectedItem, someFilterOpened } = this.state;

    let errMsgs = (machineModels.state.result?.messages || [])?.map(o => {
      return o.body === 'Machine Model already existswith the same name but different EQ Type-OEM relation.'
        ? 'Machine Model already exists with the same name and same EQ Type-OEM relation.'
        : o.body;
    });
    errMsgs = [...errMsgs, ...(changeMachineModelStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          renderer: ({ clusterName }) => <>{clusterName}</>,
          tooltipRenderer: true,
          //editor: (data, onChange) => <MRClusterEditor nullable value={{id:data.clusterId, title:data.clusterName}} onChange={(c) => { data.clusterId = c.id; data.clusterName = c.title; onChange(); }}/>,
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<MachineModelDto>('clusterName'),
                  `contains(tolower(${nameof<MachineModelDto>('clusterName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<MachineModelDto>('clusterName'))}
              active={activeFilters.includes(nameof<MachineModelDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<MachineModelDto>('clusterName'), useProfile: false }
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: true,
          renderer: data => <>{data.equipmentTypeName}</>,
          //editor: (data, onChange) => <EquipmentTypeEditor nullable value={{id:data.equipmentTypeId, title:data.equipmentTypeName}} onChange={(c) => { data.equipmentTypeId = c.id; data.equipmentTypeName = c.title; onChange(); }}/>,
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<MachineModelDto>('equipmentTypeName'),
                  `contains(tolower(${nameof<MachineModelDto>('equipmentTypeName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<MachineModelDto>('equipmentTypeName'))}
              active={activeFilters.includes(nameof<MachineModelDto>('equipmentTypeName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<MachineModelDto>('equipmentTypeName'), useProfile: false }
        },
        {
          title: t('OEM'),
          tooltipRenderer: true,
          renderer: data => <>{data.oemName}</>,
          //editor: (data, onChange) => <OemEditor nullable value={{id:data.oemId, title:data.oemName}} onChange={(c) => { data.oemId = c.id; data.oemName = c.title; onChange(); }}/>,
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by OEM')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<MachineModelDto>('oemName'),
                  `contains(tolower(${nameof<MachineModelDto>('oemName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<MachineModelDto>('oemName'))}
              active={activeFilters.includes(nameof<MachineModelDto>('oemName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<MachineModelDto>('oemName'), useProfile: false }
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: true,
          renderer: data => <>{data.name}</>,
          //editor: (data, onChange) => <Input value={data.name} fluid onChange={(e, { value }) => { data.name = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Machine Model')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MachineModelDto>('name'), `contains(tolower(${nameof<MachineModelDto>('name')}), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MachineModelDto>('name'))}
              active={activeFilters.includes(nameof<MachineModelDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<MachineModelDto>('name'), useProfile: false, active: 'Ascending' }
        },
        {
          title: t('Description'),
          tooltipRenderer: true,
          renderer: data => <>{data.description}</>,
          //editor: (data, onChange) => <Input value={data.name} fluid onChange={(e, { value }) => { data.name = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Description')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<MachineModelDto>('description'),
                  `contains(tolower(${nameof<MachineModelDto>('description')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<MachineModelDto>('description'))}
              active={activeFilters.includes(nameof<MachineModelDto>('description'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<MachineModelDto>('description'), useProfile: false }
        },
        {
          title: t('Active'),
          renderer: data => <Checkbox toggle checked={data.active} onChange={() => this.handleToggleAvailability(data)} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Active/Inactive')}
              trueLabel={t('Active')}
              falseLabel={t('Inactive')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<MachineModelDto>('active'), { active: value })}
              onClear={() => onClear(nameof<MachineModelDto>('active'))}
              active={activeFilters.includes(nameof<MachineModelDto>('active'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        }
      ],
      data: machineModels.state
    } as TableModel<MachineModelDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {readOnly && <h2>{t('Machine Models')}</h2>}
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            {/* <div className="configuration__machine-related__machine-model-table"> */}
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!changeMachineModelShown && !newMachineModelShown && !readOnly}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraActions={
                !readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              isRowDisableLayout={(item: MachineModelDto) => !item.active}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!readOnly}
              createNewButtonTitle={t('Add Machine Model')}
              bulkInsertUrl={'machines/v1/machine-model-bulkinsert'}
              bulkTemplateName={'machinemodels.xlsx'}
              bulkTemplateUrl={'machines/v1/machine-model-template'}
              exportUrl={'machines/v1/machine-model-export'}
              uploadComplete={data => this.uploadComplete(data)}
            ></TableView>
            {/* </div> */}
          </Grid.Row>
        </Grid>
        {(newMachineModelShown && <NewMachineModelView onClose={this.onNewItemClosed} {...this.props} />) ||
          (changeMachineModelShown && <ChangeMachineModelView onClose={this.onEditItemClosed} currentMM={selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(MachineModelsListPage);
