import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NewRestrictionWarningStore,
  CreateRestrictionWarningDto
} from 'stores/configuration/planner-assistant/restrictions-warnings-store';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import LocationEditor from 'widgets/bussiness/location-editor';
import { ItemReference } from 'stores/dataStore';
import VisaEditor, { VisaItemReference } from 'widgets/bussiness/visa-editor';
import ContractEditor from 'widgets/bussiness/contract-editor';
import FlightCostEditor from 'widgets/bussiness/flight-cost-editor';

interface NewRestrictionWarningViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newRestrictionWarning?: NewRestrictionWarningStore;
}

interface NewRestrictionWarningViewState {
  TTCLocation: ItemReference;
  TravelingLocation: ItemReference;
  Visa: VisaItemReference;
  VisaApplicationDuration: number;
  IsRestriction: boolean;
  Comments: string;
  Contract: ItemReference;
  FlightCost: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newRestrictionWarning', NewRestrictionWarningStore])
class NewRestrictionWarningView extends React.Component<NewRestrictionWarningViewProps, NewRestrictionWarningViewState> {
  private get newRestrictionWarningStore() {
    return this.props.newRestrictionWarning;
  }

  constructor(props: NewRestrictionWarningViewProps) {
    super(props);
    this.newRestrictionWarningStore.createNew({
      TTCLocationId: null,
      TravelingLocationId: null,
      VisaId: null,
      VisaApplicationDuration: null,
      IsRestriction: false,
      Comments: null,
      ContractId: null,
      FlightCostId: null
    });

    this.state = {
      TTCLocation: null,
      TravelingLocation: null,
      Visa: null,
      VisaApplicationDuration: null,
      IsRestriction: false,
      Comments: null,
      Contract: null,
      FlightCost: null,
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewRestrictionWarningViewProps) {
    if (this.newRestrictionWarningStore.state.result && this.newRestrictionWarningStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newRestrictionWarningStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newRestrictionWarningStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newRestrictionWarningStore.change({ ...this.newRestrictionWarningStore.state.item, ...change });
  }

  private handleOnTTCLocationChange = (ttcLocation: ItemReference) => {
    this.newRestrictionWarningStore.state.item.TTCLocationId = ttcLocation ? ttcLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnTravelingLocationChange = travelingLocation => {
    this.newRestrictionWarningStore.state.item.TravelingLocationId = travelingLocation ? travelingLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnVisaChange = (visa: VisaItemReference) => {
    this.newRestrictionWarningStore.state.item.VisaId = visa ? visa.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnContractChange = (contract: ItemReference) => {
    this.newRestrictionWarningStore.state.item.ContractId = contract ? contract.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnFlightCostChange = (flightCost: ItemReference) => {
    this.newRestrictionWarningStore.state.item.FlightCostId = flightCost ? flightCost.id : null;
    this.setState({ userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newRestrictionWarningStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Restriction')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newRestrictionWarningStore.state.result && !this.newRestrictionWarningStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newRestrictionWarningStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newRestrictionWarningStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.TTCLocationId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.TTCLocationId == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.TTCLocationId)} field`}
                    >
                      <label>{t('TTC Location')}</label>
                      <LocationEditor
                        value={this.state.TTCLocation}
                        placeholder={t('TTC Location')}
                        onChange={this.handleOnTTCLocationChange}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field
                    required
                    error={!this.newRestrictionWarningStore.state.item.TravelingLocationId && this.state.userClickedSaved}
                  >
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.TravelingLocationId == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.TravelingLocationId)} field`}
                    >
                      <label>{t('Traveling Location')}</label>
                      <LocationEditor
                        value={this.state.TravelingLocation}
                        placeholder={t('Traveling Location')}
                        onChange={this.handleOnTravelingLocationChange}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.VisaId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.VisaId == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.VisaId)} field`}
                    >
                      <label>{t('Visa Requirement')}</label>
                      <VisaEditor
                        onChange={this.handleOnVisaChange}
                        nullable
                        clearable={true}
                        value={this.state.Visa}
                        placeholder={t('Visa')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                        readOnly={false}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field
                    required
                    error={!this.newRestrictionWarningStore.state.item.VisaApplicationDuration && this.state.userClickedSaved}
                  >
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.VisaApplicationDuration == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.VisaApplicationDuration.toString())} field`}
                    >
                      <Form.Input
                        required
                        type="number"
                        min="0"
                        fluid
                        error={!this.newRestrictionWarningStore.state.item.VisaApplicationDuration && this.state.userClickedSaved}
                        label={t('Visa application. Months duration')}
                        placeholder={t('Visa application. Months duration')}
                        value={this.newRestrictionWarningStore.state.item.VisaApplicationDuration}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateRestrictionWarningDto>('VisaApplicationDuration'), value)}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.Comments && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.Comments == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.Comments)} field`}
                    >
                      <Form.Input
                        required
                        fluid={!this.newRestrictionWarningStore.state.item.Comments}
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        value={this.newRestrictionWarningStore.state.item.Comments}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateRestrictionWarningDto>('Comments'), value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.IsRestriction && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.IsRestriction == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.IsRestriction.toString())} field`}
                    >
                      <Form.Checkbox
                        style={{ top: 25 }}
                        toggle
                        checked={this.newRestrictionWarningStore.state.item.IsRestriction}
                        label={t('Restriction')}
                        placeholder={t('Restriction')}
                        onChange={(e, { checked }) => {
                          this.handleValue(nameof<CreateRestrictionWarningDto>('IsRestriction'), checked);
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.ContractId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.ContractId == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.ContractId)} field`}
                    >
                      <label>{t('Contract')}</label>
                      <ContractEditor
                        onChange={this.handleOnContractChange}
                        nullable
                        clearable={true}
                        value={this.state.Contract}
                        placeholder={t('Contract')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                        readOnly={false}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newRestrictionWarningStore.state.item.FlightCostId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newRestrictionWarningStore.state.item.FlightCostId == null ||
                        isNullOrWhiteSpaces(this.newRestrictionWarningStore.state.item.FlightCostId)} field`}
                    >
                      <label>{t('Flight Cost')}</label>
                      <FlightCostEditor
                        onChange={this.handleOnFlightCostChange}
                        nullable
                        clearable={true}
                        value={this.state.FlightCost}
                        placeholder={t('Flight Cost')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewRestrictionWarningView);
