import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message } from 'semantic-ui-react';
import { ImportLogDto, ImportLogStore } from '../../../../stores/users/importLogs/importLogs-Store';
import { ItemState, OrderDefinition, Query } from '../../../../stores/dataStore';
import { CommandResult } from '../../../../stores/types';
import { nameof } from '../../../../utils/object';
import { TableModel, TableView } from '../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../widgets/collections/table-filters/textbox-filter';
import { DatesFilter } from 'widgets/collections/table-filters/dates-filter';
import { DateTimeService } from 'services/datetime-service';

export interface ImportLogsListListProps extends WithTranslation, RouteComponentProps {
  importlogs: ImportLogStore;
}
export interface ImportLogsListState {
  query: Query;
  newImportLogsShown: boolean;
  activeFilters: string[];
}
@connect(['importlogs', ImportLogStore])
class ImportLogsListPage extends React.Component<ImportLogsListListProps, ImportLogsListState> {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [
          {
            direction: 'Descending',
            field: nameof<ImportLogDto>('timestamp'),
            useProfile: false
          }
        ],
        skip: 0,
        take: 10
      },
      newImportLogsShown: false,
      activeFilters: []
    };
  }
  componentDidMount() {
    this.load();
  }
  @autobind
  private load() {
    this.props.importlogs.getAllAsync(this.state.query);
  }
  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }
  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }
  @autobind
  private async onSaveRow(item: ImportLogDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.importlogs.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }
  @autobind
  private async onDelete(item: ImportLogDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.importlogs.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private onNewItem() {
    this.setState({ newImportLogsShown: true });
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);
    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  public render() {
    const { t } = this.props as any;
    const { activeFilters } = this.state;
    const tableModel = {
      columns: [
        {
          title: t('Execution Timestamp'),
          tooltipRenderer: true,
          renderer: data => <>{DateTimeService.toDateTimeCleanExtended(DateTimeService.toString(data.timestamp))}</>,
          editor: (data, onChange) => (
            <Input
              value={data.timestamp}
              fluid
              onChange={(e, { value }) => {
                data.timestamp = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DatesFilter
              filterTitle={t('Filter by Timestamp')}
              triggerTitle={title}
              onFilter={(start, end) => {
                onFilter('timestamp', {
                  timestamp: {
                    ge: { value: start, type: 'raw' },
                    le: { value: end, type: 'raw' }
                  }
                });
              }}
              onClear={() => onClear(nameof<ImportLogDto>('timestamp'))}
              active={activeFilters.includes(nameof<ImportLogDto>('timestamp'))}
            />
          ),
          sortDefinition: {
            field: nameof<ImportLogDto>('timestamp'),
            useProfile: false,
            active: 'Descending'
          }
        },

        {
          title: t('Action'),
          tooltipRenderer: true,
          renderer: data => data.action,
          editor: (data, onChange) => (
            <Input
              value={data.action}
              fluid
              onChange={(e, { value }) => {
                data.action = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by action')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<ImportLogDto>('action'), `contains(${nameof<ImportLogDto>('action')}, '${value}')`)}
              onClear={() => onClear(nameof<ImportLogDto>('action'))}
              active={activeFilters.includes(nameof<ImportLogDto>('action'))}
            />
          ),
          sortDefinition: {
            field: nameof<ImportLogDto>('action'),
            useProfile: false
          }
        },

        {
          title: t('Users Updated'),
          tooltipRenderer: true,
          renderer: data => <>{data.userUpdatedCount === 0 || data.userUpdatedCount > 0 ? data.userUpdatedCount + ' Users Updated' : ''}</>,
          editor: (data, onChange) => (
            <Input
              value={data.userUpdatedCount}
              fluid
              onChange={(e, { value }) => {
                data.userUpdatedCount = parseInt(value);
                onChange();
              }}
            />
          ),
          selectableHeader: true
        },

        {
          title: t('Personnel Area Found'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.newPersonnelAreaCount === 0 || data.newPersonnelAreaCount > 0
                ? data.newPersonnelAreaCount + ' new Personnel Area Found'
                : ''}
            </>
          ),
          editor: (data, onChange) => (
            <Input
              value={data.newPersonnelAreaCount}
              fluid
              onChange={(e, { value }) => {
                data.newPersonnelAreaCount = parseInt(value);
                onChange();
              }}
            />
          ),
          selectableHeader: true
        },

        {
          title: t('Users on Hold'),
          tooltipRenderer: true,
          renderer: data => <>{data.userOnHoldCount === 0 || data.userOnHoldCount > 0 ? data.userOnHoldCount + ' Users on Hold' : ''}</>,
          editor: (data, onChange) => (
            <Input
              value={data.userOnHoldCount}
              fluid
              onChange={(e, { value }) => {
                data.userOnHoldCount = parseInt(value);
                onChange();
              }}
            />
          ),
          selectableHeader: true
        }
      ],
      data: this.props.importlogs.state
    } as TableModel<ImportLogDto>;
    return (
      <>
        {this.props.importlogs.state.result && !this.props.importlogs.state.result.isSuccess && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={this.props.importlogs.state.result.messages.map(o => o.body)}
          />
        )}
        <TableView
          model={tableModel}
          onOrderByChanged={this.handleOrderBy}
          onNewItem={this.onNewItem}
          onRefresh={this.load}
          canEdit={false}
          canDelete={false}
          onDeleteRow={this.onDelete}
          onSaveRow={this.onSaveRow}
          onPageChange={this.handlePageChange}
          onFilterChange={this.handleFilterChange}
          canCreateNew={false}
        ></TableView>
      </>
    );
  }
}
export default withTranslation()(ImportLogsListPage);
