import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import { CreateRegionDto, NewRegionStore } from 'stores/configuration/locations/regions-store';
import { nameof } from 'utils/object';

interface NewRegionViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newRegion?: NewRegionStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewRegionViewState {}

@connect(['newRegion', NewRegionStore])
class NewRegionView extends React.Component<NewRegionViewProps, NewRegionViewState> {
  private get newRegionStore() {
    return this.props.newRegion;
  }

  constructor(props: NewRegionViewProps) {
    super(props);
    this.newRegionStore.createNew({
      name: ''
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewRegionViewProps) {
    const { result } = this.newRegionStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.newRegionStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newRegionStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const { item } = this.newRegionStore.state;
    const change = {};
    change[property] = value;
    this.newRegionStore.change({ ...item, ...change });
  }

  public render() {
    const { t } = this.props;
    const { result, item } = this.newRegionStore.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newRegionStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New region')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group>
                  <Form.Field width={6} required>
                    <label>{t('Name')}</label>
                    <Input
                      placeholder={t('Name')}
                      value={item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateRegionDto>('name'), value)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewRegionView);
