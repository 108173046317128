import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import { Input, TextArea } from 'semantic-ui-react';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import './tna-wizard-first-step.less';
import { TnaTemplateData } from '../tna-templates-wizard-component';

export interface FirstStepProps extends WithTranslation {
  data: TnaTemplateData;
  onChangeProperty: (property: string, value: any) => void;
  isEditMode: boolean;
}

class FirstStepContent extends React.Component<FirstStepProps> {
  shouldComponentUpdate(nextProps, nextState) {
    const { profile, templateTitle, templateHeader } = this.props.data;
    if (
      profile !== nextProps.profile ||
      templateTitle !== nextProps.templateTitle ||
      templateHeader !== nextProps.templateHeader ||
      this.props.isEditMode !== nextProps.isEditMode
    )
      return true;
    else return false;
  }

  render() {
    const { t, data, isEditMode } = this.props;
    return (
      <div className="wizard__step wizard__step-one">
        <StepsComponent active number={'1'} title={'IDENTIFY THE NEW TEMPLATE'}></StepsComponent>
        <div className="step-one__content flex-start-center wizard__step-margin-left">
          <label>{t('Who is this template for?')}</label>
          <div className="input profile-editor">
            <ProfileEditor
              readonly={isEditMode}
              assessment={'TNA'}
              value={data && data.profile && data.profile.id}
              nullable
              className="custom-editor"
              clearable
              onChange={profile => this.props.onChangeProperty('profile', profile)}
              placeholder={t('Profile')}
              notLoadDataOnOpen
            />
          </div>
        </div>

        <div className="step-one__content flex-start wizard__step-margin-left">
          <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
          <Input
            value={data && data.templateTitle ? data.templateTitle : ''}
            onChange={(_, inputField) => this.props.onChangeProperty('templateTitle', inputField.value)}
          />
          <label>{t('Header (Optional)')}</label>
          <TextArea
            value={!data ? '' : data.templateHeader ? data.templateHeader : ''}
            onChange={(_, { value }) => this.props.onChangeProperty('templateHeader', value)}
            className="tna-wizard__textarea"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(FirstStepContent);
