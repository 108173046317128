import React, { useState, FC, useContext, memo } from 'react';
import {
  IwsFormItemDto,
  IwsFormAnswersDto,
  TemplateSectionAnswersDto,
  IwsSkillAnswerDto,
  IwsFormListStore
} from 'stores/assessments/forms/iws-forms-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tab, Modal, Button, Accordion, Divider } from 'semantic-ui-react';
import IwsFormTabComponent from './iws-form-tab.component';
import './iws-edit-form-main-styles.less';
import { IWSFormContext } from './iws-edit-form';
import { ApplicationState } from 'stores/reducers';
import { useSelector } from 'react-redux';

interface IWSFormProps extends WithTranslation {
  handleTemplateChange: (templateId: string) => void;
  onExportExcel?: () => void;
  iwsFormListStore?: IwsFormListStore;
}

const IWSFormComponent: FC<IWSFormProps> = ({ t, handleTemplateChange, onExportExcel, iwsFormListStore }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(true);
  const [showScores, setShowScores] = useState<boolean>(false);
  const [instructionsAccordionOpen, setInstructionsAccordionOpen] = useState<boolean>(true);
  const status = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).status) as string;
  const { getAssessorType, getAStatus, submittable, ...contextValues } = useContext(IWSFormContext);
  const { isAdminOrPoC } = contextValues;
  const assessorType = getAssessorType();
  const assessorAnswerStatus = getAStatus();
  const answers = useSelector<ApplicationState>(
    ({ iwsformlist: { summary } }) => (summary?.item as IwsFormItemDto)?.answers
  ) as IwsFormAnswersDto;
  const language = useSelector<ApplicationState>(
    ({ iwsformlist: { summary } }) => (summary?.item as IwsFormItemDto)?.currentLanguage
  ) as string;

  const formItem = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => summary?.item as IwsFormItemDto) as IwsFormItemDto;

  var templateItems = formItem?.iwsAssessment?.iwsTemplates;
  if (assessorType?.type === 'qualifier') {
    let templateIds = assessorType?.qualifierDto?.templates.map(x => x.templateId);
    templateItems = templateItems.filter(x => templateIds.any(id => id === x.id));
  }

  let acitveTempId = templateItems[activeIndex]?.id;
  const [activeHeader, setActiveHeader] = useState<string>(templateItems.firstOrDefault(x => x.id === acitveTempId)?.header);

  const mapToGeneralObj = ({ templateSectionAnswers }: IwsFormAnswersDto, currentLanguage: string) => {
    const { type } = assessorType;
    const panes = [];
    var iwsSkillAnswersAux: IwsSkillAnswerDto[] = [];

    var generalTemplate: TemplateSectionAnswersDto = {
      templateId: null,
      templateName: 'General',
      employeeFirstAssessmentComment: templateSectionAnswers.firstOrDefault()?.employeeFirstAssessmentComment,
      employeeSecondAssessmentComment: templateSectionAnswers.firstOrDefault()?.employeeSecondAssessmentComment,
      qualifierComment: templateSectionAnswers.firstOrDefault()?.qualifierComment,
      iwsSkillAnswers: []
    };

    (templateSectionAnswers || []).forEach(tempItem => {
      if (!tempItem.iwsSkillAnswers.isEmpty()) tempItem.iwsSkillAnswers.forEach(x => (x.templateId = tempItem.templateId));

      iwsSkillAnswersAux.push(...tempItem.iwsSkillAnswers);
    });

    generalTemplate.iwsSkillAnswers = iwsSkillAnswersAux;

    if (
      assessorType.type === 'qualifier' &&
      !isAdminOrPoC &&
      !formItem.qualifiers.any(
        x => x.userId === assessorType.userId && x.templates.any(temp => templateSectionAnswers.any(a => a.templateId === temp.templateId))
      )
    )
      return;

    (isAdminOrPoC ||
      type !== 'qualifier' ||
      (type === 'qualifier' &&
        assessorType.qualifierDto.templates.any(temp => templateSectionAnswers.any(a => a.templateId === temp.templateId)))) &&
      panes.push({
        menuItem: t(generalTemplate.templateName),
        render: () => (
          <>
            <IwsFormTabComponent
              {...generalTemplate}
              templateSectionAnswers={generalTemplate}
              scoresVisibility={showScores}
              onExportExcel={onExportExcel}
              iwsFormListStore={iwsFormListStore}
            />
          </>
        )
      });

    return panes;
  };

  const handleTabChange = (_, { activeIndex }) => {
    let acitveTempId = templateItems[activeIndex]?.id;
    setActiveIndex(activeIndex);
    setActiveHeader(templateItems.firstOrDefault(x => x.id === acitveTempId)?.header);
    handleTemplateChange(acitveTempId);
  };

  const acceptConfirmationModal = () => {
    setShowConfirmationModal(false);
    setShowScores(true);
  };

  const { type } = getAssessorType();
  const panes = mapToGeneralObj(answers, language);
  const pending = assessorAnswerStatus?.answerStatus === 'PendingRecommendation';
  const isQualifier = type === 'qualifier';
  const showModal = submittable() && status !== 'Done' && pending && isQualifier && showConfirmationModal;
  const toggleAccordion = () => {
    setInstructionsAccordionOpen(!instructionsAccordionOpen);
  };

  return (
    <>
      <div>
        {activeHeader && (
          <>
            <div className="header-instructions-accordion-spacer ">
              <Accordion className="tna-header-accordion">
                <Accordion.Title active={instructionsAccordionOpen} onClick={toggleAccordion} className="tna-header-accordion__title" />
                <Accordion.Content active={instructionsAccordionOpen}>
                  <div className="tna-edit-form__header__evaluation-criteria">
                    <pre style={{ fontFamily: 'inherit' }}>{activeHeader}</pre>
                  </div>
                </Accordion.Content>
              </Accordion>
            </div>
            <Divider />
          </>
        )}
        {panes?.length > 0 && (
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
            className="tna-form-main-tabs"
            panes={panes}
          />
        )}
      </div>

      {showModal && (
        <Modal
          /*closeOnEscape={false}*/ closeOnEscape={true}
          onClose={acceptConfirmationModal}
          open={showConfirmationModal}
          size="mini"
          className="tna-confirmation-modal"
        >
          <Modal.Content className="tna-confirmation-modal__content">
            {<p>{t('You have completed the evaluation successfully. Please, now add your recommendations for this Employee.')}</p>}
          </Modal.Content>
          <Modal.Actions>
            <Button size="tiny" className="tna-confirmation-modal__btn" content={t('Accept')} onClick={acceptConfirmationModal} />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default withTranslation()(memo(IWSFormComponent));
