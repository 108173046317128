import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';
import { ProfessionsStore, ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface RolesSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string, data: ItemReference) => void;
  professionsStore?: ProfessionsStore;
  withProfileActive?: boolean;
  withProfileAssessment?: 'SSA' | 'TNA';
  showDeleted?: boolean;
}

@connect(['professionsStore', ProfessionsStore])
class RolesSelector extends React.Component<RolesSelectorProps> {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    const parameters = {};
    if (this.props.withProfileActive != null) {
      parameters['WithProfileActive'] = !!this.props.withProfileActive ? 'true' : 'false';
    }
    if (this.props.withProfileAssessment != null) {
      parameters['WithProfileAssessment'] = this.props.withProfileAssessment;
    }
    if (this.props.showDeleted != null) {
      parameters['ShowDeleted'] = !!this.props.showDeleted ? 'true' : 'false';
    }

    this.props.professionsStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<ProfessionDto>('profession'), useProfile: false }],
        filter: [],
        parameters
      })
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.profession }))
        });
      });
  }

  private getItemReference = (value): ItemReference => {
    if (isNullOrWhiteSpaces(value)) return null;
    const v = this.state.options.firstOrDefault(x => x.value === value);
    if (v != null) return { id: v.value, title: v.text };
    return null;
  };

  public render() {
    const selectedValue = this.props.value ? this.props.value : null;

    return (
      <Dropdown
        selection
        loading={this.state.isLoading}
        search={true}
        clearable={this.props.clearable}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={selectedValue}
        options={this.state.options}
        onChange={(event, data) => this.props.onChange(data.value as string, this.getItemReference(data.value))}
        selectOnBlur={false}
        disabled={this.props.readOnly}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(RolesSelector);
