import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, QueryResult, Query } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';

export interface SupportCostDto extends BaseDto {
  id: string;
  name: string;
  year: number;
  cost: number;
  active: boolean;
}

export interface CreateSupportCostDto {
  name: string;
  year: number;
  cost: number;
}

export interface ChangeSupportCostDto {
  id: string;
  cost: number;
}

export interface ChangeLogSupportCostDto extends SupportCostDto {
  createdOn: string;
}

export interface SupportPositionCostDto {
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
}

export class CreateSupportCostValidator extends AbstractValidator<CreateSupportCostDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));

    this.validateIfString(o => o.name)
      .hasMaxLength(250)
      .withFailureMessage(i18n.t('Name can not be larger than 250 characters'));

    this.validateIfNumber(o => o.year)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('Year is required'));

    this.validateIfNumber(o => o.year)
      .isGreaterThan(1900)
      .withFailureMessage(i18n.t('Year can not be less than 1900'));

    this.validateIfString(o => o.year.toString())
      .hasMaxLength(4)
      .withFailureMessage(i18n.t('Invalid year format'));

    this.validateIfNumber(o => o.cost)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('Cost is required'));

    this.validateIfNumber(o => o.cost)
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('Cost can not be less than Zero'));
  }
}

export class ChangeSupportCostValidator extends AbstractValidator<ChangeSupportCostDto> {
  constructor() {
    super();

    this.validateIfNumber(o => o.cost)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('Cost is required'));

    this.validateIfNumber(o => o.cost)
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('Cost can not be less than Zero'));
  }
}

@repository('@@SUPPORTCOSTS', 'supportCosts.summary')
export class SupportCostsStore extends DataStore<SupportCostDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-supportCost';
  retrievePath = 'get-supportCosts';
  updatePath = 'update-supportCost';
  deletePath = 'delete-supportCost';
  getSupportPositionCostPath = 'get-supportPositionCost';

  protected validate(item: SupportCostDto) {
    return new ChangeSupportCostValidator().validate(item);
  }

  constructor() {
    super('SUPPORTCOST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getSupportPositionCost(
    locationId: string,
    startDate: string,
    endDate: string,
    requestedHc: string
  ): Promise<SupportPositionCostDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.getSupportPositionCostPath,
      httpService.get<SupportPositionCostDto>(
        `${this.baseUrl}/${this.getSupportPositionCostPath}/${locationId}/${startDate}/${endDate}/${requestedHc}`
      )
    );
    return result.data;
  }
}

@repository('@@SUPPORTCOSTS', 'supportCosts.new')
export class NewSupportCostStore extends FormStore<CreateSupportCostDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-supportCost';
  retrievePath = 'get-supportCosts';
  updatePath = 'update-supportCost';

  protected validate(item: CreateSupportCostDto) {
    return new CreateSupportCostValidator().validate(item);
  }

  constructor() {
    super('NEW_SUPPORTCOST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@SUPPORTCOSTS', 'supportCosts.change')
export class ChangeSupportCostStore extends FormStore<ChangeSupportCostDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-supportCost';
  retrievePath = 'get-supportCosts';
  updatePath = 'update-supportCost';

  protected validate(item: ChangeSupportCostDto) {
    return new ChangeSupportCostValidator().validate(item);
  }

  constructor() {
    super('CHANGE_SUPPORTCOST', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@SUPPORTCOSTS', 'supportCosts.changelog')
export class ChangeLogSupportCostStore extends DataStore<ChangeLogSupportCostDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-supportCost';
  retrievePath = 'get-supportCosts';
  updatePath = 'update-supportCost';
  deletePath = 'delete-supportCost';

  protected validate(item: ChangeLogSupportCostDto) {
    return new ChangeSupportCostValidator().validate(item);
  }

  constructor() {
    super('CHANGELOG_SUPPORTCOST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllHistoryAsync(query: Query, data?: any): Promise<QueryResult<ChangeLogSupportCostDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<ChangeLogSupportCostDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }
}
