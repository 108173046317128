import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18n';
import { RouteComponentProps } from 'react-router-dom';
import { Divider, Tab } from 'semantic-ui-react';
import { UserDto } from 'stores/users/users-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { InstructorExpertiseDto } from 'stores/skills/instructor-expertise-store';
import InstructorExpertisesMrTable from './instructor-expertises-mr-table';
import InstructorExpertisesNoMrTable from './instructor-expertises-no-mr-table';
import LineSeparator from 'widgets/bussiness/line-separator';
import InstructorVisasTable from './instructor-visas-table';

export interface InstructorExpertisesProps extends WithTranslation, RouteComponentProps {}

export interface InstructorExpertisesState {
  currentUser: UserDto;
  expertisesList: InstructorExpertiseDto[];
  instructorLoggedOrFounded: boolean;
  activeIndex: number;
  instructorId: string;
}

class InstructorExpertisesComponent extends React.Component<InstructorExpertisesProps, InstructorExpertisesState> {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      expertisesList: null,
      instructorLoggedOrFounded: true,
      activeIndex: 0,
      instructorId: (this.identityService.isInRole(Roles.Instructor) && this.identityService.getUserInfo().userId) || null
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    const { instructorId } = this.state;
    this.setState({ instructorId: instructorId });
  }

  private handleTabChange = (_, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  private getPanes = () => {
    let panes = [];

    panes.push({
      menuItem: i18n.t('Machine Related'),
      render: () => (
        <Tab.Pane key={`instructor-expertise-card_mr`} attached={false}>
          <InstructorExpertisesMrTable instructorId={this.state.instructorId} />
        </Tab.Pane>
      )
    });

    panes.push({
      menuItem: i18n.t('Non-Machine Related'),
      render: () => (
        <Tab.Pane key={`instructor-expertise-card_nmr`} attached={false}>
          <InstructorExpertisesNoMrTable instructorId={this.state.instructorId} />
        </Tab.Pane>
      )
    });

    // panes.push({
    //   menuItem: i18n.t('My Visas'),
    //   render: () => (
    //     <Tab.Pane key={`instructor-expertise-card_nmr`} attached={false}>
    //       <InstructorVisasTable instructorId={this.state.instructorId} />
    //     </Tab.Pane>
    //   )
    // });

    return panes;
  };

  public render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const panes = this.getPanes();
    return (
      <>
        <h2 className="instructor-card__expertise-title">MY EXPERTISE</h2>
        <Tab
          menu={{ secondary: true, pointing: true }}
          className="instructor-expertise-card__tab"
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
        <Divider />
        <h2 className="instructor-card__expertise-title">{t('MY VISAS')}</h2>
        <LineSeparator />
        <InstructorVisasTable instructorId={this.state.instructorId} />
      </>
    );
  }
}
export default withTranslation()(InstructorExpertisesComponent);
