import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import LocationSelector from 'widgets/bussiness/selectors/location-selector';
import { Grid, Button, Input, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';
import { Query, QueryResult } from 'stores/dataStore';
import { connect } from 'redux-scaffolding-ts';
import { LocationsStore, LocationDto } from 'stores/configuration/locations/locations-store';
import PeriodInput from 'widgets/form/period-input';
import { DateTimePeriod } from 'stores/events/events-store';
import {
  PublicHolidayConfigurationStore,
  ChangePublicHolidayConfigurationStore,
  NewPublicHolidayConfigurationStore,
  DateTimeOffset,
  CreatePublicHolidayConfigurationDto,
  PublicHolidayDto
} from 'stores/public-holidays/public-holidays-store';
import { DateTimeService } from 'services/datetime-service';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface PublicHolidayConf {
  id: string;
  locationId: string;
  year: number;
  periods: DateTimePeriod[];
}

export interface SchedulerPublicHolidaysProps extends WithTranslation {
  onClose?: (data?: PublicHolidayConf) => void;
  locations?: LocationsStore;
  location?: LocationDto;
  publicHolidaysConfigurationStore?: PublicHolidayConfigurationStore;
  changePublicHolidaysConfigurationStore?: ChangePublicHolidayConfigurationStore;
  newPublicHolidaysConfigurationStore?: NewPublicHolidayConfigurationStore;
}

export interface SchedulerPublicHolidaysState {
  publicHolidayConfiguration: PublicHolidayConf;
  loading: boolean;
}
const year = DateTimeService.now().year();

@connect(
  ['publicHolidaysConfigurationStore', PublicHolidayConfigurationStore],
  ['changePublicHolidaysConfigurationStore', ChangePublicHolidayConfigurationStore],
  ['newPublicHolidaysConfigurationStore', NewPublicHolidayConfigurationStore]
)
class SchedulerPublicHolidaysComponent extends Component<SchedulerPublicHolidaysProps, SchedulerPublicHolidaysState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      publicHolidayConfiguration: { ...this.defaultPublicHolidaysConfiguration }
    };
  }

  get defaultPublicHolidaysConfiguration() {
    return { id: null, periods: [], year, locationId: null };
  }

  componentDidMount() {
    if (this.props?.location) this.handleOneLocation(this.props?.location);
  }

  handleOneLocation = (location: LocationDto) => {
    if (!location) return;

    const filter: any = [
      { LocationId: { eq: { type: 'guid', value: location.id } } }
      // { Year: { eq: this.state.publicHolidayConfiguration.year } }
    ];
    const query: Query = { searchQuery: '', skip: 0, take: 1, filter };

    this.setState(
      ({ publicHolidayConfiguration }) => ({
        publicHolidayConfiguration: { ...publicHolidayConfiguration, locationId: location?.id }
      }),
      async () => this.manageResponse(await this.getAllAsync(query))
    );
  };

  getAllAsync = async (query: Query): Promise<QueryResult<PublicHolidayDto>> => {
    this.setState({ loading: true });
    try {
      return await this.props.publicHolidaysConfigurationStore.getAllAsync(query);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  manageResponse = (response: QueryResult<PublicHolidayDto>) => {
    if (!response) return;
    const previousConfiguration = (response?.items || []).length > 0;
    const periods = previousConfiguration ? this.mapToDateTimePeriod(response.items[0].publicHolidayPeriods) : [];
    const id = previousConfiguration ? response.items[0].id : null;
    this.setState(({ publicHolidayConfiguration }) => ({ publicHolidayConfiguration: { ...publicHolidayConfiguration, periods, id } }));
  };

  handleLocationChange = async (location: string) => {
    if (!location) {
      this.setState({ publicHolidayConfiguration: this.defaultPublicHolidaysConfiguration });
      return;
    }
    let publicHolidayConfiguration = { ...this.state.publicHolidayConfiguration };
    publicHolidayConfiguration.locationId = location;

    const filter: any = [
      { LocationId: { eq: { type: 'guid', value: publicHolidayConfiguration.locationId } } }
      // { Year: { eq: publicHolidayConfiguration.year } }
    ];
    const query: Query = { searchQuery: '', skip: 0, take: 1, filter };
    this.setState({ publicHolidayConfiguration });

    const response = await this.getAllAsync(query);
    this.manageResponse(response);
  };

  mapToDateTimePeriod(items: DateTimeOffset[]): DateTimePeriod[] {
    return items.map(({ startDate, endDate }) => ({ from: startDate, to: endDate }));
  }

  handlePeriodInputChange = (index: number, dateTimePeriod: DateTimePeriod) => {
    const change: DateTimePeriod = { ...dateTimePeriod };
    if (change?.from && change?.to) {
      const from = DateTimeService.toMoment(change.from);
      if (from.isAfter(change.to)) change.to = change.from;
    }
    this.setState(({ publicHolidayConfiguration: { periods, ...rest } }) => ({
      publicHolidayConfiguration: { ...rest, periods: (periods || []).map((period, i) => (i === index ? change : period)) }
    }));
  };

  addDatesRange = () =>
    this.setState(({ publicHolidayConfiguration: { periods, ...conf } }) => ({
      publicHolidayConfiguration: { ...conf, periods: [...periods, { from: '', to: '' }] }
    }));

  removeDateRange = (index: number) =>
    this.setState(({ publicHolidayConfiguration: { periods, ...conf } }) => ({
      publicHolidayConfiguration: { ...conf, periods: periods.filter((_, i) => i !== index) }
    }));

  handleOnSaveClicked = async () => {
    const { newPublicHolidaysConfigurationStore, changePublicHolidaysConfigurationStore, onClose, t } = this.props;
    const { publicHolidayConfiguration } = this.state;
    const { id, locationId, periods, year } = publicHolidayConfiguration;
    if (!id) {
      newPublicHolidaysConfigurationStore.createNew(this.mapToCreatePublicationHolidayDto(publicHolidayConfiguration));
      await newPublicHolidaysConfigurationStore.submit();
      if (newPublicHolidaysConfigurationStore.state.result?.isSuccess) {
        ToastComponent({ text: t('Public holiday saved'), type: 'success-toast' });
        onClose && onClose(publicHolidayConfiguration);
      }
    } else {
      changePublicHolidaysConfigurationStore.change({ id, locationId, publicHolidayPeriods: this.toDateTimeOffset(periods), year });
      await changePublicHolidaysConfigurationStore.update();
      if (changePublicHolidaysConfigurationStore.state.result?.isSuccess) {
        ToastComponent({ text: t('Public holiday saved'), type: 'success-toast' });
        onClose && onClose(publicHolidayConfiguration);
      }
    }
  };

  private mapToCreatePublicationHolidayDto = ({ locationId, periods, year }: PublicHolidayConf): CreatePublicHolidayConfigurationDto => ({
    locationId,
    publicHolidayPeriods: this.toDateTimeOffset(periods),
    year
  });

  toDateTimeOffset = (dateTimePeriods: DateTimePeriod[]) => dateTimePeriods.map(({ from, to }) => ({ startDate: from, endDate: to }));

  onDismiss = () => {
    this.props.publicHolidaysConfigurationStore.clearMessages();
    this.props.newPublicHolidaysConfigurationStore.clearMessages();
    this.props.changePublicHolidaysConfigurationStore.clearMessages();
  };

  handleClose = () => {
    this.onDismiss();
    this.props.onClose();
  };

  render() {
    const { t, publicHolidaysConfigurationStore, location } = this.props;
    const { newPublicHolidaysConfigurationStore, changePublicHolidaysConfigurationStore } = this.props;
    const { isBusy, result } = publicHolidaysConfigurationStore.state;
    const { isBusy: newIsBusy, result: newResult } = newPublicHolidaysConfigurationStore.state;
    const { isBusy: changeIsBusy, result: changeResult } = changePublicHolidaysConfigurationStore.state;
    const { locationId, periods } = this.state.publicHolidayConfiguration;
    const messages = [
      ...(result?.messages || []).map(x => x.body),
      ...(newResult?.messages || []).map(x => x.body),
      ...(changeResult?.messages || []).map(x => x.body)
    ];
    const msgClass = 'error-message__style';
    const msgIcon = 'exclamation circle';

    return (
      <div className="poc-config-modal__container">
        <Dimmer page active={newIsBusy || changeIsBusy || isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {messages.length > 0 && (
          <Message onDismiss={this.onDismiss} className={msgClass} icon={msgIcon} error header={t('An error ocurred')} list={messages} />
        )}

        <Grid.Row>
          {location?.location ? (
            <h2>
              <b>{location?.location}</b>
            </h2>
          ) : (
            <LocationSelector
              className="filter-form__item-input"
              nullable
              filterByRole
              clearable
              searchable
              placeholder={t('Select Location')}
              value={locationId}
              onChange={this.handleLocationChange}
            />
          )}
        </Grid.Row>
        <div className="poc-config-modal__scrollable-content">
          {(locationId || location?.location) && (
            <>
              <Grid.Row>
                <Grid.Column>{t('Start Date')}</Grid.Column>
                <Grid.Column>{t('End Date')}</Grid.Column>
                <Grid.Column>{t('Days')}</Grid.Column>
              </Grid.Row>
              {(periods || []).map((period, index) => {
                const inputValue =
                  period?.from && period?.to
                    ? DateTimeService.toMoment(period?.to).diff(DateTimeService.toMoment(period?.from), 'days') + 1
                    : 0;

                return (
                  <Grid.Row className="poc-config-modal__ranges-container" key={period?.from + index}>
                    <PeriodInput
                      id={'public-holidays' + period?.from + index + period?.to}
                      value={period}
                      min={period?.from}
                      onChange={p => this.handlePeriodInputChange(index, p)}
                    />
                    <Grid.Column>
                      <Input disabled value={inputValue} />
                    </Grid.Column>
                    <Grid.Column verticalAlign="bottom">
                      <Icon className="remove-row__times" size="big" color="red" name="times" onClick={() => this.removeDateRange(index)} />
                    </Grid.Column>
                  </Grid.Row>
                );
              })}
            </>
          )}
        </div>
        <Grid.Row className="poc-config-modal__action-buttons">
          <div className="left">
            <Button
              disabled={!locationId && !!!location?.location}
              className="inverted-color-btn add"
              content={t('Add Dates')}
              onClick={this.addDatesRange}
            />
          </div>
          <div className="right">
            <Button basic className="cancel" content={t('Cancel')} onClick={this.handleClose} />
            <Button positive disabled={!locationId} className="save" content={t('Save')} onClick={this.handleOnSaveClicked} />
          </div>
        </Grid.Row>
      </div>
    );
  }
}

export default withTranslation()(SchedulerPublicHolidaysComponent);
