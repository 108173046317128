import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { EventWizardData } from 'stores/events/creation-wizard-store';
import { nameof } from 'utils/object';
import './machine-related-subject-step.less';

import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';

interface NewEventViewProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, any][]) => void;
}

interface NewEventViewState {
  canEdit?: boolean;
}

class MachineRelatedSubjectStep extends Component<NewEventViewProps, NewEventViewState> {
  constructor(props) {
    super(props);

    this.state = {
      canEdit: !this.props.wizardData.usePattern || (this.props.wizardData.usePattern && this.props.wizardData.hasModifiedPattern)
    };
  }

  private handleOnPatternMachinesChange = (newPatternMachines: PatternMachinesViewModel) => {
    let changes = [];
    changes.push([nameof<EventWizardData>('pattern'), newPatternMachines.pattern]);
    changes.push([nameof<EventWizardData>('requestMachines'), newPatternMachines.machines]);
    this.props.onValueChanged(...changes);
  };

  render() {
    const { t } = this.props;

    const { pattern, requestMachines } = this.props.wizardData;

    const patternMachines: PatternMachinesViewModel = {
      pattern: pattern,
      machines: requestMachines
    };

    return (
      <Form className="wizard__step2__machine-related">
        <h3 className="machine-related__title"> {t('MACHINE RELATED')}</h3>
        <Form.Group className="wizard__step2__machine-related__content">
          <div className="form__event-details-tab__mr__pattern form__event-details-tab__element wizard__mr-table mr-table-component__common-styles">
            <PatternMachines showRequired readOnly={false} value={patternMachines} onChange={this.handleOnPatternMachinesChange} />
          </div>
        </Form.Group>
      </Form>
    );
  }
}

export default withTranslation()(MachineRelatedSubjectStep);
