import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { OrderDefinition, QueryResult } from 'stores/dataStore';
import { NMClusterDto, NMClustersStore } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { DropdownProps, SelectionInput } from 'widgets/form/selectionInput';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { Categories } from 'stores/requests/requests-store';

interface NMClusterEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  clusters?: NMClustersStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  className?: string;
  category?: Categories;
  direction?: 'left' | 'right';
  loadDataOnOpen?: boolean;
}

interface NMClusterEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['clusters', NMClustersStore])
class NMClusterEditor extends Component<NMClusterEditorProps, NMClusterEditorState> {
  constructor(props: NMClusterEditorProps) {
    super(props);
    this.state = { value: this.props.value, query: this.props.loadDataOnOpen ? null : this.getData() };
  }

  componentDidUpdate({ value: prevValue, category: prevCat }: NMClusterEditorProps) {
    const { value, reloadOnChange, category } = this.props;
    let newChanges: any = {};

    if (value?.id !== prevValue?.id || category !== prevCat) {
      if (reloadOnChange || category) newChanges.query = this.getData();
      newChanges.value = value;
    }
    Object.keys(newChanges).length > 0 && this.setState(newChanges);
  }

  private getData = () => {
    const method = async (search: string) => {
      const { clusters, category } = this.props;
      const filter = isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<NMClusterDto>('name')}), '${search.toLowerCase()}')`];

      if (category)
        filter.push(
          `${nameof<NMClusterDto>('category')} eq '${
            category === ('Functional' as any) || category === ('Technical' as any) ? category : Categories[category]
          }'`
        );

      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<NMClusterDto>('name'), useProfile: false }];

      const result = await clusters.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter });

      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  };

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getData() });
    }
  };

  public render() {
    const { minWidth, nullable, placeholder, readonly, clearable, className, onChange, direction } = this.props;
    const { value, query } = this.state;
    const props = { minWidth, nullable, placeholder, readonly, clearable, className, query, value };

    return (
      <SelectionInput
        {...props}
        direction={direction}
        content={item => <div>{item.title}</div>}
        searchable
        readOnly={readonly}
        onChange={value => onChange(value as any)}
        onOpenDropDown={this.onOpenDropDown}
        options={this.state.query != null ? null : []}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NMClusterEditor);
