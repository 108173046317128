import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { PatternsStore } from 'stores/configuration/machinery/patterns-store';
import { RequestWizardStore } from 'stores/requests/create-request-wizard-store';
import { ItemReference } from 'stores/dataStore';
import { CreateRequestMachinesDto } from 'stores/requests/requests-store';
import './request-step2-subject.less';
import { PatternRowDto } from 'stores/configuration/machinery/pattern-rows-store';
import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';

interface NewRequestViewProps extends WithTranslation {
  requestWizardStore?: RequestWizardStore;
  selectedPattern?: PatternsStore;
}

interface NewRequestViewState {
  canEdit?: boolean;
  patterns: PatternRowDto[];
}

@connect(['selectedPattern', PatternsStore], ['requestWizardStore', RequestWizardStore])
class StepSubjectMachineRelated extends Component<NewRequestViewProps, NewRequestViewState> {
  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  private get selectedPattern() {
    return this.props.selectedPattern;
  }

  constructor(props) {
    super(props);

    this.state = {
      patterns: [],
      canEdit:
        !this.requestWizardStore.state.item.withPattern ||
        (this.requestWizardStore.state.item.withPattern && this.requestWizardStore.state.item.hasModifiedPattern)
    };

    if (!this.requestWizardStore.state.item.withPattern && this.requestWizardStore.state.item.machineModels.length === 0)
      this.addEmptyRow();
    else if (this.requestWizardStore.state.item.withPattern) this.getMachinesRelated(this.requestWizardStore.state.item.pattern);
  }

  private addEmptyRow = () => {
    const machine: CreateRequestMachinesDto = {
      machineRelatedClusterId: null,
      machineRelatedClusterName: '',
      equipmentTypeId: null,
      equipmentTypeName: '',
      oemId: null,
      oemName: '',
      machineModelId: null,
      machineModelName: '',
      plcTypeRequestMachines: [],
      machineUnitRequestMachines: []
    };

    const tempItems = [];
    tempItems.push(machine);

    this.requestWizardStore.change({ machineModels: tempItems });
  };

  private getMachinesRelated = async (patternItemReference: ItemReference) => {
    if (this.requestWizardStore.state.item.pattern === patternItemReference && this.requestWizardStore.state.item.machineModels.length > 0)
      return;

    const pattern = await this.selectedPattern.getById(patternItemReference.id);
    this.setState({ patterns: pattern.patternRows });
    const machineModels = pattern.patternRows.map(pr => {
      const machine: CreateRequestMachinesDto = {
        machineRelatedClusterId: pr.machineRelatedClusterId,
        machineRelatedClusterName: pr.machineRelatedClusterName,
        equipmentTypeId: pr.equipmentTypeId,
        equipmentTypeName: pr.equipmentTypeName,
        oemId: pr.oemId || null,
        oemName: pr.oemId || '',
        machineModelId: pr.machineModelId || null,
        machineModelName: pr.machineModelId || '',
        plcTypeRequestMachines: [],
        machineUnitRequestMachines: []
      };
      return machine;
    });

    this.requestWizardStore.change({ machineModels, pattern: patternItemReference, hasModifiedPattern: false });
  };

  private handleOnPatternMachinesChange = ({ pattern, machines }: PatternMachinesViewModel) => {
    this.props.requestWizardStore.change({
      pattern,
      machines,
      machineModels: (machines || []).map(({ cluster, equipmentType, machineModel, machineUnits, oem, plcTypes }) => ({
        machineRelatedClusterId: cluster?.id,
        machineRelatedClusterName: cluster?.title,
        equipmentTypeId: equipmentType?.id,
        equipmentTypeName: equipmentType?.title,
        oemId: oem?.id,
        oemName: oem?.title,
        machineModelId: machineModel?.id,
        machineModelName: machineModel?.title,
        plcTypeRequestMachines: plcTypes,
        machineUnitRequestMachines: machineUnits
      }))
    });
  };

  handleOnDeleteRow = (): void => {
    this.setState({ canEdit: true });
    this.requestWizardStore.change({ hasModifiedPattern: true });
  };

  render() {
    const { t, requestWizardStore } = this.props;
    const { pattern, machines } = requestWizardStore.state.item;
    const patternMachines: PatternMachinesViewModel = { pattern, machines };

    return (
      <Form className="wizard__step2__machine-related" loading={requestWizardStore.state.isBusy}>
        <h3 className="machine-related__title">{t('MACHINE RELATED')}</h3>
        <Form.Group className="wizard__step2__machine-related__content">
          <div className="form__event-details-tab__mr__pattern form__event-details-tab__element wizard__mr-table mr-table-component__common-styles">
            <PatternMachines showRequired readOnly={false} value={patternMachines} onChange={this.handleOnPatternMachinesChange} />
          </div>
        </Form.Group>
      </Form>
    );
  }
}

export default withTranslation()(StepSubjectMachineRelated);
