import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import GeneralDataStep, { GeneralDataViewModel } from '../../../tna/forms/wizard/steps/general-data-step';
import { Button, Message, Modal } from 'semantic-ui-react';
import EmployeesAndAssessorsStep, { EmployeesAssessorsViewModel } from './steps/employees-and-assessors-step';
import { nameof, getProperties, clone } from 'utils/object';
import { TnaTemplateDto } from 'stores/assessments/templates/tna-templates-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { RouteComponentProps } from 'react-router';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LineSeparator from 'widgets/bussiness/line-separator';
import { tnaWizardMode } from './create-tna-form-wizard';
import { TnaFormListDto, TnaFormItemDto, TnaAssessmentType } from 'stores/assessments/forms/tna-forms-store';
import { DateTimeService } from 'services/datetime-service';
import SelectMachineryStep from './steps/select-machinery-step';
import {
  MachineModelSelectionViewModel,
  MultipleMachineModelSelectionViewModel,
  TnaTemplateStepViewModel
} from './steps/select-machinery-step-types';
import { ProfileItemDto, ProfileItemStore } from 'stores/profile/profile-store';
import { connect } from 'redux-scaffolding-ts';

export interface TnaFormWizardViewModel {
  generalStepViewModel: GeneralDataViewModel;
  tnaTemplateStepViewModel: TnaTemplateStepViewModel;
  employeesAssessorViewModel: EmployeesAssessorsViewModel;
}

export interface TnaFormWizardProps extends WithTranslation, RouteComponentProps {
  messages: any[];
  onSubmit: (viewModel: TnaFormWizardViewModel) => void;
  isValidViewModel: (viewModel: TnaFormWizardViewModel) => boolean;
  tnaWizardMode: tnaWizardMode;
  dataFormEditMode: TnaFormListDto;
  itemToEdit: TnaFormItemDto;
  profileStore?: ProfileItemStore;
}

export interface TnaFormWizardState {
  loading: boolean;
  value: TnaFormWizardViewModel;
  nonMachineRelatedSkills: { [skillId: string]: string };
  step: number;
  showCancelConfirm: boolean;
  profileSelected: ProfileItemDto;
  isGlobalPoc: boolean;
}

@connect(['profileStore', ProfileItemStore])
class TnaFormWizard extends Component<TnaFormWizardProps, TnaFormWizardState> {
  constructor(props: TnaFormWizardProps) {
    super(props);
    this.state = {
      loading: false,
      value: this.initTnaFormsWizardViewModel(),
      nonMachineRelatedSkills: {},
      step: 1,
      showCancelConfirm: false,
      profileSelected: null,
      isGlobalPoc: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    const value = this.initTnaFormsWizardViewModel();
    const { itemToEdit } = this.props;

    let nonMachineRelatedSkills = this.state.nonMachineRelatedSkills;
    if (this.props.tnaWizardMode === 'Edit') {
      nonMachineRelatedSkills = {};
      itemToEdit.nonMachineFunctionalExperts.forEach(nmrFe =>
        nmrFe.skills.forEach(skill => (nonMachineRelatedSkills[skill.id] = skill.name))
      );
    }

    this.setState({
      value,
      nonMachineRelatedSkills,
      isGlobalPoc
    });
  }

  private getProfile = async profileId => {
    const { profileStore } = this.props;
    if (!profileId) return Promise.reject();
    return await profileStore.getProfileById(profileId).then(e => this.setState({ profileSelected: e }));
  };

  private initTnaFormsWizardViewModel = (): TnaFormWizardViewModel => {
    const item = this.props.dataFormEditMode;
    const tnaFormToEdit = this.props.itemToEdit;

    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const pocLocations = isPoc ? (userInfo.locationsByRoles['PoC'] as string[]) || [] : [];
    let location = null;
    if (isPoc && pocLocations.length === 1) {
      location = { id: pocLocations[0], title: null };
    }

    const isOnEditMode = this.props.tnaWizardMode === 'Edit';
    if (isOnEditMode) {
      const assesment = tnaFormToEdit?.tnaAssessment;
      const skillsAssessor = {};

      this.getProfile(item.profileId);

      tnaFormToEdit.nonMachineFunctionalExperts.forEach(nmrFe => nmrFe.skills.forEach(skill => (skillsAssessor[skill.id] = nmrFe.userId)));
      const skillCount = tnaFormToEdit.nonMachineFunctionalExperts.reduce((prev, next) => prev + next.skills.length, 0);
      return {
        generalStepViewModel: {
          deadline: tnaFormToEdit.deadline,
          location: { id: tnaFormToEdit.user.location.id, title: tnaFormToEdit.user.location.location },
          profileId: item.profileId,
          title: tnaFormToEdit.tnaAssessment.title,
          machineModelsSelection: []
        },
        tnaTemplateStepViewModel: {
          template: assesment?.tnaTemplate,
          machineModelsSelection: this.generateMachineModelSelection(tnaFormToEdit),
          multipleMachineModelsSelection: [],
          skillCount: skillCount,
          type: assesment.type
        },
        employeesAssessorViewModel: {
          assessors:
            assesment.type === 'SameMachineHierarchy'
              ? !tnaFormToEdit.machineFunctionalExpertId
                ? [...tnaFormToEdit.nonMachineFunctionalExperts.map(assessor => assessor.userId), tnaFormToEdit.lineManagerId]
                : [
                    ...tnaFormToEdit.nonMachineFunctionalExperts.map(assessor => assessor.userId),
                    tnaFormToEdit.machineFunctionalExpertId,
                    tnaFormToEdit.lineManagerId
                  ]
              : [
                  ...tnaFormToEdit.nonMachineFunctionalExperts.map(assessor => assessor.userId),
                  ...tnaFormToEdit.machineFunctionalExperts.map(x => x.userId),
                  tnaFormToEdit.lineManagerId
                ],

          employees: [tnaFormToEdit.userId],
          ignoredNmrSkills: [],
          employeesAssessors: {
            [tnaFormToEdit.userId]: {
              lineManagerId: tnaFormToEdit.lineManagerId,
              bypassEmployeeEvaluation: tnaFormToEdit.bypassEmployeeEvaluation,
              bypassLineManagerEvaluation: tnaFormToEdit.bypassLineManagerEvaluation,
              machineFunctionalExpertId: tnaFormToEdit.machineFunctionalExpertId,
              skillsAssessor,
              machineFunctionalExperts: tnaFormToEdit.machineFunctionalExperts
                .map(x => x.machineModels.map(m => ({ userId: x.userId, machineId: m.id })))
                .reduce((a, b) => a.concat(b), [] as { userId: string; machineId: string }[])
                .reduce((o, i) => {
                  o[i.machineId] = i.userId;
                  return o;
                }, {})
            }
          }
        }
      };
    } else {
      return {
        generalStepViewModel: {
          deadline: '',
          location,
          profileId: '',
          title: '',
          machineModelsSelection: []
        },
        tnaTemplateStepViewModel: {
          template: null,
          machineModelsSelection: [],
          multipleMachineModelsSelection: [],
          skillCount: 0,
          type: 'Unknown'
        },
        employeesAssessorViewModel: {
          assessors: [],
          employees: [],
          employeesAssessors: {},
          ignoredNmrSkills: []
        }
      };
    }
  };

  public generateMachineModelSelection(tnaFormToEdit: TnaFormItemDto): MachineModelSelectionViewModel[] {
    if (tnaFormToEdit.tnaAssessment.type === 'SameMachineHierarchy') {
      const template = tnaFormToEdit.tnaAssessment.tnaTemplate;
      return (template.machineModels || []).map(machineModel => ({
        hasMachineUnitSelected: tnaFormToEdit.machineModelsSelection.some(x => x.machineModelId === machineModel.id),
        machineModel: machineModel,
        machineUnitSelection: (template.machineRelatedSections || []).map(machineRelatedSection => ({
          machineUnit: machineRelatedSection.machineUnitItemDto,
          isSelected: this.verifyMachineUnitIsSelected(machineModel, machineRelatedSection.machineUnitId)
        }))
      }));
    } else {
      return tnaFormToEdit.machineModelsSelection.map(m => ({
        hasMachineUnitSelected: true,
        machineModel: m.machineModel,
        machineUnitSelection: []
      }));
    }
  }

  private verifyMachineUnitIsSelected(machineModel, machineUnitId) {
    const { itemToEdit } = this.props;
    if (!itemToEdit) return false;

    const machinesFromEditMode = itemToEdit.machineModelsSelection;

    const machineSelected = machinesFromEditMode.find(
      mrs => mrs.machineModelId === machineModel.id && mrs.selectedMachineUnits.includes(machineUnitId)
    );

    if (machineSelected?.machineModelId) {
      return true;
    } else return false;
  }

  handleOnSubmit = () => {
    this.props.onSubmit && this.props.onSubmit(this.state.value);
  };

  private checkStep1 = () => {
    const { value } = this.state;
    if (value.generalStepViewModel == null) return false;
    const { location, profileId, deadline } = value.generalStepViewModel;
    return (
      !isNullOrWhiteSpaces(profileId) &&
      !isNullOrWhiteSpaces(deadline) &&
      DateTimeService.isValid(deadline) &&
      location != null &&
      !isNullOrWhiteSpaces(location.id)
    );
  };

  private checkStep2 = () => {
    const { value } = this.state;
    const isOnEditMode = this.props.tnaWizardMode === 'Edit';
    if (value.tnaTemplateStepViewModel == null) return false;
    const { template, machineModelsSelection, type, multipleMachineModelsSelection } = value.tnaTemplateStepViewModel;
    if (!isOnEditMode) {
      if (type === 'Unknown') return false;
      if (type === 'SameMachineHierarchy')
        return (
          template != null &&
          !isNullOrWhiteSpaces(template.id) &&
          (!this.isMachineRelatedProfile() ||
            ((machineModelsSelection || []).length !== 0 && (machineModelsSelection || []).any(x => x.hasMachineUnitSelected)))
        );

      return (
        template != null &&
        !isNullOrWhiteSpaces(template.id) &&
        (multipleMachineModelsSelection || []).length !== 0 &&
        (multipleMachineModelsSelection || []).all(x => x != null && x.checked && x.machineModel != null && x.tnaTemplateItem != null)
      );
    } else return true;
  };

  private updateStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      this.setState({ step: 1 });
      return;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      this.setState({ step: 2 });
      return;
    }
    this.setState({ step: 3 });
  };

  private handleChangeProperty(property: keyof TnaFormWizardViewModel, propertyValue: any, profile?: ProfileItemDto) {
    let value = clone<TnaFormWizardViewModel>(this.state.value);

    if (
      this.props.tnaWizardMode === 'New' &&
      property === nameof<TnaFormWizardViewModel>('generalStepViewModel') &&
      this.state.value.generalStepViewModel.profileId !== propertyValue.profileId
    ) {
      value.tnaTemplateStepViewModel = {
        template: null,
        machineModelsSelection: [],
        skillCount: 0,
        type: 'Unknown',
        multipleMachineModelsSelection: []
      };
      this.setState({ profileSelected: profile });
    }

    value[property] = propertyValue;

    const step = this.state.step;
    if (step === 1 && !this.checkStep1() && value.generalStepViewModel.machineModelsSelection.length !== 0) {
      value.generalStepViewModel.machineModelsSelection = [];
    }

    this.setState({ value }, this.updateStep);
  }

  private handleSecondStepChanged = (tnaTemplateStepViewModel: TnaTemplateStepViewModel): void => {
    if (tnaTemplateStepViewModel.type === 'SameMachineHierarchy') this.handleOnTemplateChange(tnaTemplateStepViewModel);
    else if (tnaTemplateStepViewModel.type === 'MultipleMachineHierarchy') this.handleOnMultipleMachinesChange(tnaTemplateStepViewModel);
  };

  private handleOnTemplateChange = (tnaTemplateStepViewModel: TnaTemplateStepViewModel): void => {
    const value = clone<TnaFormWizardViewModel>(this.state.value);
    value.tnaTemplateStepViewModel = tnaTemplateStepViewModel;
    const nonMachineRelatedSkills = this.getNonMachineRelatedSkillsFromTemplate(tnaTemplateStepViewModel.template);
    const nmrSkillsSectionsLength = (tnaTemplateStepViewModel?.template?.nonMachineRelatedSkillSections || []).length;

    value.tnaTemplateStepViewModel.skillCount = nmrSkillsSectionsLength > 0 ? getProperties(nonMachineRelatedSkills).length : 1;
    value.generalStepViewModel.machineModelsSelection = (tnaTemplateStepViewModel.machineModelsSelection || []).filter(
      x => x.hasMachineUnitSelected
    );

    this.setState({ value, nonMachineRelatedSkills: nonMachineRelatedSkills }, this.updateStep);
  };

  private handleOnMultipleMachinesChange = (tnaTemplateStepViewModel: TnaTemplateStepViewModel): void => {
    const value = clone<TnaFormWizardViewModel>(this.state.value);
    value.tnaTemplateStepViewModel = tnaTemplateStepViewModel;
    const nonMachineRelatedSkills = this.getNonMachineRelatedSkillsFromSelection(tnaTemplateStepViewModel.multipleMachineModelsSelection);

    value.tnaTemplateStepViewModel.skillCount = tnaTemplateStepViewModel?.skillCount ?? 1;
    value.generalStepViewModel.machineModelsSelection = tnaTemplateStepViewModel.machineModelsSelection;

    if (this.props.tnaWizardMode === 'Edit') {
      this.setState({ value }, this.updateStep);
    } else {
      this.setState({ value, nonMachineRelatedSkills: nonMachineRelatedSkills }, this.updateStep);
    }
  };

  private getNonMachineRelatedSkillsFromSelection = (selection: MultipleMachineModelSelectionViewModel[]) => {
    if ((selection || []).length === 0) return null;
    const skills: { [skillId: string]: string } = {};
    selection.forEach(s => {
      if ((s.tnaTemplateItem?.nonMachineRelatedSkillSections || []).length !== 0) {
        s.tnaTemplateItem.nonMachineRelatedSkillSections.forEach(sk => (skills[sk.skillId] = sk.skillName));
      }
    });
    return skills;
  };

  private getNonMachineRelatedSkillsFromTemplate = (tnaTemplate: TnaTemplateDto) => {
    if (!tnaTemplate) return null;

    const skills: { [skillId: string]: string } = {};

    (tnaTemplate.nonMachineRelatedSkillSections || []).forEach(x => (skills[x.skillId] = x.skillName));

    return skills;
  };

  private onCancel = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/tna?tab=3' });
  };

  private cantSubmit = () => {
    return this.props.isValidViewModel && !this.props.isValidViewModel(this.state.value);
  };

  private onTryCancel = () => {
    this.setState({ showCancelConfirm: true });
  };
  private onCancelCancel = () => {
    this.setState({ showCancelConfirm: false });
  };

  private onTnaTypeChanged = (type: TnaAssessmentType) => {
    const value = this.state.value;
    value.tnaTemplateStepViewModel = {
      template: null,
      machineModelsSelection: [],
      skillCount: 0,
      type,
      multipleMachineModelsSelection: []
    };
    this.setState({
      value,
      step: 2
    });
  };

  private isMachineRelatedProfile = (): boolean => {
    const { profileSelected } = this.state;
    return profileSelected && (profileSelected?.skills || []).any(x => x.isMachineRelated);
  };

  render() {
    const { t, tnaWizardMode, itemToEdit, messages } = this.props;
    const { step, showCancelConfirm, value, isGlobalPoc } = this.state;
    const { generalStepViewModel, employeesAssessorViewModel, tnaTemplateStepViewModel } = value;
    const isOnEditMode = tnaWizardMode === 'Edit';
    const machineModelIds = (tnaTemplateStepViewModel?.machineModelsSelection || [])
      .filter(x => x.hasMachineUnitSelected)
      .map(({ machineModel: { id } }) => id);

    return (
      <>
        <h3 className="template-wizard-title">{t(`Training Need Analysis`)}</h3>
        <LineSeparator />
        {messages?.length > 0 && (
          <Message className="error-message__style" icon="exclamation circle" error list={this.props.messages.map(m => m.body)} />
        )}

        <div className="wizard__steps-container tna-form-wizzard">
          <GeneralDataStep
            isStepActive={step >= 1}
            value={clone(generalStepViewModel)}
            onChange={(value: any, profile: ProfileItemDto) => this.handleChangeProperty('generalStepViewModel', value, profile)}
            isOnEditMode={isOnEditMode}
            isGlobalPoc={isGlobalPoc}
          />
          <SelectMachineryStep
            isMachineRelatedProfile={this.isMachineRelatedProfile()}
            dataFormEditMode={this.props.dataFormEditMode}
            itemToEdit={itemToEdit && itemToEdit}
            isStepActive={step >= 2}
            profileId={generalStepViewModel.profileId}
            value={clone(tnaTemplateStepViewModel)}
            onChange={this.handleSecondStepChanged}
            isOnEditMode={isOnEditMode}
            onTnaTypeChanged={this.onTnaTypeChanged}
          />

          <EmployeesAndAssessorsStep
            isMachineRelatedProfile={this.isMachineRelatedProfile()}
            value={employeesAssessorViewModel}
            itemToEdit={itemToEdit && itemToEdit}
            isOnEditMode={isOnEditMode}
            dataFormEditMode={this.props.dataFormEditMode}
            isStepActive={step >= 3}
            nonMachineRelatedSkills={this.state.nonMachineRelatedSkills}
            locationId={generalStepViewModel.location ? generalStepViewModel.location.id : null}
            profileId={generalStepViewModel.profileId}
            machineModelIds={machineModelIds}
            onChange={(value: any) => this.handleChangeProperty('employeesAssessorViewModel', value)}
            formType={tnaTemplateStepViewModel.type}
            machineModelsDict={tnaTemplateStepViewModel.machineModelsSelection.reduce((prev, next) => {
              if (next.hasMachineUnitSelected) return { ...prev, [next.machineModel.id]: next.machineModel.name };
              return prev;
            }, {})}
            isGlobalPoc={isGlobalPoc}
          />
        </div>
        <div className="tna-templates-wizard__btns">
          <Button secondary inverted onClick={this.onTryCancel}>
            {t('Cancel')}
          </Button>
          <Button primary positive onClick={this.handleOnSubmit} disabled={this.cantSubmit()}>
            {!isOnEditMode ? t('Generate TNA') : t('Save Changes')}
          </Button>
        </div>
        {showCancelConfirm && (
          <Modal closeOnEscape={true} onClose={this.onCancelCancel} open={showCancelConfirm} size="mini" className="tna-confirmation-modal">
            <Modal.Content className="tna-confirmation-modal__content">
              <p>
                {t('Are you sure you want to CANCEL?')}
                <br />
                {t('You will lose all the changes made in this TNA.')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button size="tiny" className="tna-confirmation-modal__btn cancel" content={t('Back')} onClick={this.onCancelCancel} />
              <Button size="tiny" className="tna-confirmation-modal__btn" content={t('Cancel Changes')} onClick={this.onCancel} />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(TnaFormWizard);
