import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal, Header, Input, List, Label, Icon } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import 'site/pages/assessments/tna/templates/wizard/steps/tna-wizard-second-step.less';
import { ProfileItemStore } from 'stores/profile/profile-store';
import clone from 'clone';
import { IwsTemplateData } from 'site/pages/assessments/iws/templates/wizard/iws-templates-wizard-component';
import { IwsSkillTemplateSectionDto, IwsTemplateDto, IwsTemplatesStore } from 'stores/assessments/templates/iws-templates-store';
import ChooseIWSQuestionsView from 'widgets/form/choose-iws-questions-form';
import { IwsQuestionBankDto } from 'stores/assessments/questionBank/iws-questionBank-store';

interface CustomizeIwsTemplatesViewProps extends WithTranslation {
  onSaveCustomizeTemplate?: (templateData: IwsTemplateData) => void;
  onCloseTemplateModal?: () => void;
  templateDto: IwsTemplateDto;
  templateStore?: IwsTemplatesStore;
  profileStore?: ProfileItemStore;
}

interface CustomizeIwsTemplatesViewState {
  templateData: IwsTemplateData;
  isOpenSelectIwsQuestionsModal: boolean;
  alreadySelectedQuestions: string[];
  skillToSelectQuestions: IwsSkillTemplateSectionDto;
  categoryNameToSelectQuestions: string;
}

@connect(['templateStore', IwsTemplatesStore], ['profileStore', ProfileItemStore])
class CustomizeIwsTemplatesView extends React.Component<CustomizeIwsTemplatesViewProps, CustomizeIwsTemplatesViewState> {
  constructor(props: CustomizeIwsTemplatesViewProps) {
    super(props);

    this.state = {
      templateData: {
        profile: null,
        title: null,
        header: null,
        skillSection: null
      },
      isOpenSelectIwsQuestionsModal: false,
      alreadySelectedQuestions: null,
      skillToSelectQuestions: null,
      categoryNameToSelectQuestions: null
    };
  }

  loading = false;

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { profileStore, templateDto } = this.props;
    try {
      this.loading = true;
      let profile = profileStore.state.items.find(obj => templateDto.profileItemIds.includes(obj.item.id)).item;
      profile = profile ? profile : await profileStore.getProfileById(templateDto.profileItemId);

      if (profile && templateDto) this.loading = false;

      const templateData = this.mapToTemplateData(templateDto);

      this.setState({ templateData: { ...templateData, profile } });
    } catch (error) {
      console.error({ error });
    }
  };

  mapToTemplateData = (rawData: IwsTemplateDto): IwsTemplateData => {
    const { skillSection, header, title, id } = rawData;
    return { ...this.state.templateData, id: id, header: header, title: title, skillSection };
  };

  @autobind
  private handleOnCancel = () => {
    this.props.onCloseTemplateModal();
  };

  @autobind
  private onSaveCustomizeTemplate = () => {
    const { templateData } = this.state;
    if (templateData == null) return;

    this.props.onSaveCustomizeTemplate && this.props.onSaveCustomizeTemplate(clone<IwsTemplateData>(templateData));
    this.props.onCloseTemplateModal();
  };

  @autobind
  private onSelectIwsQuestions(alreadySelectedQuestions: string[], skill: IwsSkillTemplateSectionDto) {
    this.setState({
      isOpenSelectIwsQuestionsModal: true,
      alreadySelectedQuestions,
      skillToSelectQuestions: skill
    });
  }

  @autobind
  private addQuestionsHandler(questions: IwsQuestionBankDto[]) {
    if (questions != null && (questions || []).length > 0) {
      const { templateData } = this.state;

      var addedQuestions = questions.map(({ id, friendlyId, question, type }) => ({
        id,
        friendlyId,
        question: question.text,
        source: type,
        isAddedInCustom: true
      }));

      templateData.skillSection.questions.push(...addedQuestions);
      this.setState({ templateData: templateData });
    }
  }

  @autobind
  private removeQuestionHandler(skillId: string, questionId: string) {
    if (skillId != null && questionId != null) {
      const { templateData } = this.state;

      var nonRemovedQuestions = templateData.skillSection.questions.filter(question => question.id !== questionId);
      templateData.skillSection.questions = nonRemovedQuestions;
      this.setState({ templateData: templateData });
    }
  }

  public render() {
    const { t, templateDto } = this.props as any;
    const { templateData, isOpenSelectIwsQuestionsModal } = this.state;
    const { skillSection } = templateData;

    return (
      <Modal
        size="large"
        className="template-search__modal"
        open
        closeOnEscape={true}
        onClose={this.handleOnCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Customize Template')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="wizard__steps-container">
            <div className="wizard__step wizard__step-one">
              <StepsComponent active number={'1'} title={'IDENTIFICATION OF THE TEMPLATE'}></StepsComponent>
              <div className="step-one__content flex-start wizard__step-margin-left">
                <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
                <Input value={templateDto && templateDto.title ? templateDto.title : ''} disabled={true} />
              </div>
            </div>

            <div className="wizard__step wizard__step-three">
              <StepsComponent active number={'2'} title={t('SELECT QUESTIONS')} />
              <div className="step-three__skills-questions-wrapper">
                {skillSection && (
                  <List key={skillSection.skillId} verticalAlign="middle">
                    <List.Item className="edit__select-profiles__wrapper__row">
                      <Label className="skill-label" style={{ width: '100%', height: '38px' }}>
                        <p>{skillSection.skillName}</p>
                        <div className="wizard__sort-icons-wrapper">
                          <Button
                            onClick={() =>
                              this.onSelectIwsQuestions(
                                skillSection.questions && skillSection.questions.length > 0 && skillSection.questions.map(({ id }) => id),
                                skillSection
                              )
                            }
                            className="tna-template-wizard__add-questions-btn"
                            type="button"
                          >
                            {t('Add Questions')}
                          </Button>
                        </div>
                      </Label>
                      {(skillSection.questions || []).map((question, i) => (
                        <div key={question.friendlyId + i} className="tna-wizard__assign-question">
                          <div className="tna-wizard__assign-question__items">
                            <p className="wizard__customize-question-item">{question.friendlyId}</p>
                            <p className="wizard__customize-question-item">{question.source == null ? 'Global' : question.source}</p>
                            <p className="wizard__customize-question-item">{question.question}</p>
                          </div>
                          {question.isAddedInCustom && (
                            <Icon
                              onClick={() => this.removeQuestionHandler(skillSection.skillId, question.id)}
                              className="assigned-profile-row-machine__remove"
                              name="remove"
                              size="large"
                              color="red"
                            />
                          )}
                        </div>
                      ))}
                    </List.Item>
                  </List>
                )}
                {isOpenSelectIwsQuestionsModal && (
                  <ChooseIWSQuestionsView
                    onCloseModal={() => this.setState({ isOpenSelectIwsQuestionsModal: false })}
                    iwsSkillId={this.state.skillToSelectQuestions.skillId}
                    iwsSkillName={this.state.skillToSelectQuestions.skillName}
                    onAddIWSQuestions={this.addQuestionsHandler}
                    alreadySelectedQuestions={this.state.alreadySelectedQuestions}
                    fromCustomize={true}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.handleOnCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onSaveCustomizeTemplate} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(CustomizeIwsTemplatesView);
