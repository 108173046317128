import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { Category } from './clusters-store';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface NMFunctionalAreaDto extends BaseDto {
  id: string;
  name: string;
  categoryId: string;
  categoryName: string;
  clusterId: string;
  clusterName: string;
  category: Category;
}

export interface CreateNMFunctionalAreaDto {
  name: string;
  categoryId: string;
  clusterId: string;
}

export interface ChangeNMFunctionalAreaDto {
  id: string;
  name: string;
  categoryId: string;
  clusterId: string;
}

export class CreateNMFunctionalAreaValidator extends AbstractValidator<CreateNMFunctionalAreaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('FunctionalArea name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category Cluster Id is required'));
  }
}

export class ChangeNMFunctionalAreaValidator extends AbstractValidator<ChangeNMFunctionalAreaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('FunctionalArea Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('FunctionalArea name is required'));
  }
}

@repository('@@NMFUNCTIONALAREAS', 'nmFunctionalAreas.summary')
export class NMFunctionalAreasStore extends DataStore<NMFunctionalAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalArea';
  retrievePath = 'get-nmFunctionalAreas';
  updatePath = 'update-nmFunctionalArea';
  deletePath = 'delete-nmFunctionalArea';

  protected validate(item: NMFunctionalAreaDto) {
    return new ChangeNMFunctionalAreaValidator().validate(item);
  }

  constructor() {
    super('NMFUNCTIONALAREA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMFUNCTIONALAREAS', 'nmFunctionalAreas.dropdown')
export class DropDownNMFunctionalAreasStore extends DataStore<NMFunctionalAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-nmFunctionalAreas';
  updatePath = '';
  deletePath = '';

  protected validate(item: NMFunctionalAreaDto) {
    return new ChangeNMFunctionalAreaValidator().validate(item);
  }

  constructor() {
    super('NMFUNCTIONALAREADROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, nmFunctionalAreaIds?: string[], search?: string): Promise<QueryResult<NMFunctionalAreaDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let nmFunctionalAreasDto = this.state.items.map(({ item }) => item);

      if ((nmFunctionalAreaIds || []).length > 0) {
        nmFunctionalAreasDto = nmFunctionalAreasDto.filter(x => nmFunctionalAreaIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        nmFunctionalAreasDto = nmFunctionalAreasDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: nmFunctionalAreasDto, count: nmFunctionalAreasDto.length } as QueryResult<NMFunctionalAreaDto>;
      return new Promise<QueryResult<NMFunctionalAreaDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<NMFunctionalAreaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<NMFunctionalAreaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@NMFUNCTIONALAREAS', 'nmFunctionalAreas.new')
export class NewNMFunctionalAreaStore extends FormStore<CreateNMFunctionalAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalArea';
  retrievePath = 'get-nmFunctionalAreas';
  updatePath = 'update-nmFunctionalArea';

  protected validate(item: CreateNMFunctionalAreaDto) {
    return new CreateNMFunctionalAreaValidator().validate(item);
  }

  constructor() {
    super('NEW_NMFUNCTIONALAREA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMFUNCTIONALAREAS', 'nmFunctionalAreas.change')
export class ChangeNMFunctionalAreaStore extends FormStore<ChangeNMFunctionalAreaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalArea';
  retrievePath = 'get-nmFunctionalAreas';
  updatePath = 'update-nmFunctionalArea';

  protected validate(item: ChangeNMFunctionalAreaDto) {
    return new ChangeNMFunctionalAreaValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMFUNCTIONALAREA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
