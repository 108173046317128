import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { FormStore } from '../../formStore';
import { EventDto } from 'stores/events/events-store';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { CommandResult } from 'stores/types';

export interface PlannerAssistantDto {
  id: string;
  isFlexibleDate: boolean;
  durationCoefficient: number;
  priorityLevel: boolean;
  simultaneousEvents: boolean;
  consecutiveEvents: boolean;
  selectedEvents: EventDto[];
}

export class PlannerAssistantValidator extends AbstractValidator<PlannerAssistantDto> {
  //   constructor() {
  //     super();
  //   }
}

@repository('@@PLANNERASSISTANT', 'plannerAssistant.summary')
export class PlannerAssistantStore extends FormStore<PlannerAssistantDto> {
  //baseUrl = 'http://localhost:7074/api/v1';
  baseUrl = 'skills/v1';
  createPath = 'create-planner-assistant';
  retrievePath = '';
  updatePath = '';
  deletePath = '';

  protected validate(item: PlannerAssistantDto) {
    return new PlannerAssistantValidator().validate(item);
  }

  constructor() {
    super('NEW_PLANNERASSISTANT', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  public async RunPlannerAssistant() {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<PlannerAssistantDto, CommandResult<EventDto>>(`${this.baseUrl}/${this.createPath}`, this.state.item)
    );
    return result.data;
  }
}
