import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import NewSupportDetailsComponent from './shared-wizard-form/request-new-support-details.component';
import { CreateNewSupportPositionDto } from 'stores/requests/requests-store';
import { RequestWizardStore } from 'stores/requests/create-request-wizard-store';
import { DateTimeService } from 'services/datetime-service';

export interface SupportDetailsContainerProps extends WithTranslation {
  readOnly: boolean;
  requestWizardStore?: RequestWizardStore;
}

@connect(['requestWizardStore', RequestWizardStore])
class SupportDetailsContainer extends Component<SupportDetailsContainerProps> {
  componentDidMount() {
    this.load();
  }

  private load = () => {
    const { requestPreApprovedDuringASPCheck, supportDetails } = this.props.requestWizardStore.state.item;
    const requestPreApprovedDuringASP = requestPreApprovedDuringASPCheck;
    this.props.requestWizardStore.change({ supportDetails: { ...supportDetails, requestPreApprovedDuringASP } });
  };

  private handleOnSupportDetailsChange = change => {
    const { supportDetails } = this.props.requestWizardStore.state.item;

    if (change.newsupportPositions)
      change.newsupportPositions = change.newsupportPositions.map(newsupportPosition => {
        return { ...newsupportPosition } as CreateNewSupportPositionDto[];
      });

    this.props.requestWizardStore.change({ supportDetails: { ...supportDetails, ...change }, manualStudents: false });
  };

  render() {
    const { readOnly } = this.props;
    const { supportDetails, startDate, endDate, requestingLocationId } = this.props.requestWizardStore.state.item;

    return (
      <NewSupportDetailsComponent
        startDate={DateTimeService.toString(startDate)}
        endDate={DateTimeService.toString(endDate)}
        supportDetails={supportDetails}
        onChange={this.handleOnSupportDetailsChange}
        isForm={false}
        readOnly={readOnly}
        requestLocationId={requestingLocationId}
      />
    );
  }
}

export default withTranslation()(SupportDetailsContainer);
