import React, { Fragment, FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
// import { PracticalFormItemDto } from 'stores/events/practical-form-store';
import './practical-tab.less';
import { PracticalFormItemDto } from 'stores/assessments/forms/practical-form-store';

interface PracticalTableProps extends WithTranslation {
  readOnly?: boolean;
  practicalFormAnswers: PracticalFormItemDto[];
  templateId: string;
  onOpenEmployeePracticalForm: (form: PracticalFormItemDto) => void;
  showDeleteConfirmationModal: (form: PracticalFormItemDto) => void;
  userCanDeleteAndResend?: boolean;
}

const PracticalTable: FC<PracticalTableProps> = ({
  t,
  practicalFormAnswers,
  onOpenEmployeePracticalForm,
  showDeleteConfirmationModal,
  userCanDeleteAndResend
}) => {
  if ((practicalFormAnswers || []).length <= 0) return null;
  return (
    <div className="form__practical-table form__practical__admins-view">
      <p className="feedback-table__name-column">{t('User Name')}</p>
      <p className="feedback-table__name-column">{t('Last Name')}</p>
      <p className="feedback-table__name-column">{t('First Name')}</p>
      <p className="feedback-table__name-column">{t('Location')}</p>
      <p className="feedback-table__name-column">{t('Total Actual Duration')}</p>
      <p className="feedback-table__name-column">{t('Total Target Level')}</p>
      <p className="feedback-table__name-column">{t('Result')}</p>
      <p className="feedback-table__name-column text__transparent">X</p>
      {userCanDeleteAndResend && <p className="feedback-table__name-column text__transparent">X</p>}

      {(practicalFormAnswers || []).map(form => {
        if (!form) return null;

        const { user, id, userId, answerDetails } = form;

        if (answerDetails) {
          const { answered, numberOfFailedQuestions, numberOfPassedQuestions, totalActualDuration, totalTargetDuration } = answerDetails;
          const iconClassName = answered
            ? 'clickable-icon feedback__comments-icon__answered'
            : 'clickable-icon feedback-table__comments-icon';
          return (
            <Fragment key={`theoretical-table__${id}-${userId}`}>
              <p className="feedback-table__cell">{user.userName}</p>
              <p className="feedback-table__cell">{user.lastName}</p>
              <p className="feedback-table__cell">{user.firstName}</p>
              <p className="feedback-table__cell">{user.location ? user.location.location : '-'}</p>
              <p className="feedback-table__cell">{totalActualDuration ? totalActualDuration : '-'}</p>
              <p className="feedback-table__cell">{totalTargetDuration ? totalTargetDuration : '-'}</p>
              <p className="feedback-table__cell">
                {answered ? `${numberOfPassedQuestions} Passed /${numberOfFailedQuestions} Failed` : '-'}
              </p>

              <p className="feedback-table__cell">
                <Icon onClick={() => onOpenEmployeePracticalForm(form)} className={iconClassName} name="comments" size="large" />
              </p>
              {userCanDeleteAndResend && (
                <p className="feedback-table__cell">
                  <Icon
                    onClick={() => showDeleteConfirmationModal(form)}
                    className="clickable-icon"
                    color="red"
                    name="remove"
                    size="large"
                  />
                </p>
              )}
            </Fragment>
          );
        } else return null;
      })}
    </div>
  );
};

export default withTranslation()(PracticalTable);
