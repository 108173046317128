import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { MachineModelDto } from './machine-models-store';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface OemDto extends BaseDto {
  id: string;
  name: string;
  MachineModels: IEnumerable<MachineModelDto>;
  relatedClusterIds: string[];
  relatedEqIds: string[];
}

export interface CreateOemDto {
  name: string;
}

export interface ChangeOemDto {
  id: string;
  name: string;
}

export class CreateOemValidator extends AbstractValidator<CreateOemDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM name is required'));
  }
}

export class ChangeOemValidator extends AbstractValidator<ChangeOemDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('OEM Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM name is required'));
  }
}

@repository('@@OEMS', 'oems.summary')
export class OemsStore extends DataStore<OemDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-oem';
  retrievePath = 'get-oems';
  updatePath = 'update-oem';
  deletePath = 'delete-oem';

  protected validate(item: OemDto) {
    return new ChangeOemValidator().validate(item);
  }

  constructor() {
    super('OEM', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@OEMS', 'oems.dropdown')
export class DropdownOemsStore extends DataStore<OemDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = 'get-oems';
  updatePath = '';
  deletePath = '';

  protected validate(item: OemDto) {
    return new ChangeOemValidator().validate(item);
  }

  constructor() {
    super('OEMDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, oemIds?: string[], search?: string, equipmentId?: string): Promise<QueryResult<OemDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    if ((this.state.items || []).length > 0) {
      let oemsDto = this.state.items.map(({ item }) => item);

      if ((oemIds || []).length > 0) {
        oemsDto = oemsDto.filter(x => oemIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(equipmentId)) {
        oemsDto = oemsDto.filter(x => (x.relatedEqIds || []).any(eqId => eqId === equipmentId));
      }

      if (!isNullOrWhiteSpaces(search)) {
        oemsDto = oemsDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: oemsDto, count: oemsDto.length } as QueryResult<OemDto>;
      return new Promise<QueryResult<OemDto>>(resolve => resolve(itemResult));
    } else {
      if (query.filter && query.filter instanceof Array && query.filter.length > 0) {
        const result = await httpService.get<QueryResult<OemDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);
        return result.data;
      } else {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<OemDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      }
    }
  }
}

@repository('@@OEMS', 'oems.new')
export class NewOemStore extends FormStore<CreateOemDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-oem';
  retrievePath = 'get-oems';
  updatePath = 'update-oem';

  protected validate(item: CreateOemDto) {
    return new CreateOemValidator().validate(item);
  }

  constructor() {
    super('NEW_OEM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@OEMS', 'oems.change')
export class ChangeOemStore extends FormStore<ChangeOemDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-oem';
  retrievePath = 'get-oems';
  updatePath = 'update-oem';

  protected validate(item: ChangeOemDto) {
    return new ChangeOemValidator().validate(item);
  }

  constructor() {
    super('CHANGE_OEM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
