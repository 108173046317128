import { DateTimePeriod } from 'stores/events/events-store';
import { injectable } from 'inversify';
import { DateTimeService } from 'services/datetime-service';

export interface DatePeriod {
  from: string;
  to: string;
}

@injectable()
export class SchedulerPeriodService {
  toSchedulerPeriod(period: DateTimePeriod): DatePeriod {
    return { from: DateTimeService.toString(period.from, null, null, true), to: DateTimeService.toString(period.to, null, null, true) };
  }

  areEquals(source: DatePeriod, target: DatePeriod): boolean {
    return (
      DateTimeService.toMoment(source.to).isSame(DateTimeService.toMoment(target.to), 'day') &&
      DateTimeService.toMoment(source.from).isSame(DateTimeService.toMoment(target.from), 'day')
    );
  }
}
