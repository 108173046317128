import React, { Component } from 'react';
import SsaFormWizard from './ssa-form-wizard';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { resolve } from 'inversify-react';
import {
  SsaFormWizardStore,
  ChangeSsaFormWizardStore,
  ChangeSsaFormDto,
  SsaFormWizardViewModel,
  ChangeEmployeeSsaFormDto
} from 'stores/assessments/wizard/ssa-form-wizard-store';
import { IdentityService } from 'services/identity-service';
import { RouteComponentProps } from 'react-router';
import { SsaFormListStore, SsaFormListDto, SsaFormItemDto } from 'stores/assessments/forms/ssa-forms-store';

import { UserProfilesStore, UserProfilesDto } from 'stores/profile/user-profile-store';
import { getKeyValuePairs } from 'utils/object';
import { IGroup } from 'utils/linq';
import { Dimmer, Loader } from 'semantic-ui-react';

export interface SsaFormEditionWizardProps extends WithTranslation, RouteComponentProps {
  ssaFormWizardStore?: SsaFormWizardStore;
  ssaFormsStore: SsaFormListStore;
  userProfilesStore?: UserProfilesStore;
  changeSsaFormWizardStore?: ChangeSsaFormWizardStore;
}

export interface SsaFormEditionWizardState {
  formToEdit: SsaFormItemDto;
  loading: boolean;
  userFormInfo: UserProfilesDto;
  assessorsInfo: any;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
@connect(['ssaFormsStore', SsaFormListStore])
@connect(['userProfilesStore', UserProfilesStore])
@connect(['changeSsaFormWizardStore', ChangeSsaFormWizardStore])
class SsaFormEditionWizard extends Component<SsaFormEditionWizardProps, SsaFormEditionWizardState> {
  @resolve(IdentityService)
  identityService: IdentityService;

  constructor(props: Readonly<SsaFormEditionWizardProps>) {
    super(props);
    this.state = {
      formToEdit: null,
      loading: false,
      userFormInfo: null,
      assessorsInfo: []
    };
    this.load();
  }

  load = async () => {
    this.setState({ loading: true });

    const itemId = this.props.match.params['id'];

    try {
      const response: SsaFormItemDto = await this.props.ssaFormsStore.getFormById(itemId);
      this.setState({ formToEdit: response });

      if (this.isOnEditMode()) {
        await this.loadUserProfileDto();
      }
    } catch (error) {
      console.log('error to load ssa form edit');
      this.setState({ loading: false });
    }
  };

  private loadUserProfileDto = async () => {
    const employeeIdEditMode = this.state.formToEdit.userId;
    try {
      const res = await this.props.userProfilesStore.getUserProfileById(employeeIdEditMode);
      if (res) {
        this.setState({ userFormInfo: res }, () => this.toGetAssessorEditListInfo());
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    let location = null;
    this.props.ssaFormWizardStore.change({
      location: location
    });
  }

  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state !== undefined || match.params['id'] !== undefined) {
      return true;
    } else return false;
  };

  changeValueStore = () => {
    const { formToEdit, userFormInfo, assessorsInfo } = this.state;
    const item: SsaFormListDto = this.props.history.location.state;
    const assessorsDict = {};

    assessorsInfo.forEach(assessor => (assessorsDict[assessor.id] = assessor));

    const skillsAssessors = {};
    formToEdit.functionalExperts.forEach(x => x.skills.forEach(s => (skillsAssessors[s.id] = x.userId)));

    this.props.ssaFormWizardStore.changeEditMode({
      profileId: item?.profileId,
      title: formToEdit?.ssaAssessment?.title,
      location: { id: formToEdit.user?.location?.id, title: formToEdit?.user?.location.location },
      deadline: formToEdit.deadline,
      template: formToEdit.ssaAssessment.ssaTemplate,
      assessors: assessorsDict,
      employees: { [userFormInfo?.userId]: userFormInfo },
      employeesAssessors: { [userFormInfo?.userId]: { skillsAssessor: skillsAssessors } }
    });
  };

  handleOnSubmit = () => {
    const { history } = this.props;

    if (this.isOnEditMode()) {
      this.setState({ loading: true });
      this.props.changeSsaFormWizardStore.change(this.mapToChangeDtoEditMode());

      this.props.changeSsaFormWizardStore
        .submit(true)
        .then(r => {
          this.setState({ loading: false });
          if (r != null && r.isSuccess) {
            history.push({ pathname: '/assessments/ssa?tab=3' });
          } else {
            document.getElementById('root').scrollTop = 0;
          }
        })
        .catch(_ => {
          document.getElementById('root').scrollTop = 0;
          this.setState({ loading: false });
        });
    }
  };

  private mapToChangeEmployeeDto = (viewModel: SsaFormWizardViewModel): ChangeEmployeeSsaFormDto => {
    const { formToEdit } = this.state;
    const { userId } = formToEdit;

    const kvp = getKeyValuePairs<string>(viewModel.employeesAssessors[userId].skillsAssessor);
    const functionalExperts = kvp
      .groupBy(x => x.value)
      .toArray<IGroup<string, { key: string; value: string }>>()
      .map(x => ({ userId: x.key, skills: x.items.map(y => y.key) }));

    return {
      userId: userId,
      functionalExperts
    };
  };

  mapToChangeDtoEditMode = (): ChangeSsaFormDto => {
    const viewModel = this.props.ssaFormWizardStore.state.item;
    const { formToEdit } = this.state;

    const assessorDtos: ChangeEmployeeSsaFormDto = this.mapToChangeEmployeeDto(viewModel);

    return {
      id: formToEdit.id,
      employee: assessorDtos,
      deadline: viewModel.deadline
    };
  };

  private scrollToTop() {
    document.getElementById('root').scrollTop = 0;
  }

  private toGetAssessorEditListInfo = () => {
    const assessors = (this.state.formToEdit.functionalExperts || []).map(({ userId, user, ...rest }) => {
      return {
        id: userId,
        firstName: user.firstName,
        lastName: user.lastName,
        userName: '',
        roles: [],
        locationId: user.locationId,
        employeeId: user.employeeId,
        isAssessor: true,
        hasProfileAssigned: true,
        sfPosition: user.sfPosition,
        positionCode: user.positionCodeItem,
        ...rest
      };
    });
    this.setState({ assessorsInfo: assessors }, () => this.changeValueStore());
  };

  render() {
    const isOnEditMode = this.isOnEditMode();
    const { formToEdit, userFormInfo, loading, assessorsInfo } = this.state;
    const { changeSsaFormWizardStore, userProfilesStore, t } = this.props;

    if (isOnEditMode && formToEdit && userFormInfo) {
      return (
        <>
          <Dimmer
            active={loading || changeSsaFormWizardStore.state.isBusy || userProfilesStore.state.isBusy}
            style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}
          >
            <Loader indeterminate>{changeSsaFormWizardStore.state.isBusy ? t('Saving SSA form') : t('Loading SSA form')}</Loader>
          </Dimmer>
          <SsaFormWizard
            assessorsInfo={assessorsInfo}
            userFormInfo={userFormInfo}
            formToEdit={formToEdit}
            isOnEditMode={isOnEditMode}
            {...this.props}
            onSubmit={this.handleOnSubmit}
            loading={loading}
          />
        </>
      );
    } else
      return (
        <>
          <Dimmer active={true} style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}>
            <Loader indeterminate>{t('Loading SSA form')}</Loader>
          </Dimmer>
        </>
      );
  }
}

export default withTranslation()(SsaFormEditionWizard);
