import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import {
  NMFunctionalSubareaDto,
  NMFunctionalSubareasStore
} from '../../stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { DropdownProps, SelectionInput, SelectionItem } from '../../widgets/form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';

interface NMFunctionalSubAreaEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  functionalSubAreas?: NMFunctionalSubareasStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  trainingNameId?: string;
  className?: string;
  clearOnReload?: boolean;
  loadDataOnOpen?: boolean;
}

interface NMFunctionalSubAreaEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['functionalSubAreas', NMFunctionalSubareasStore])
class NMFunctionalSubAreaEditor extends React.Component<NMFunctionalSubAreaEditorProps, NMFunctionalSubAreaEditorState> {
  private get functionalSubAreasStore() {
    return this.props.functionalSubAreas;
  }

  constructor(props: NMFunctionalSubAreaEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getNMFunctionalSubAreaMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  componentDidUpdate({ value: prevValue, trainingNameId: prevTraining }: NMFunctionalSubAreaEditorProps) {
    const { value, trainingNameId, reloadOnChange } = this.props;
    let newChanges: any = {};
    if (prevTraining !== trainingNameId) newChanges.query = this.getNMFunctionalSubAreaMethod();
    if (value?.id !== prevValue?.id) {
      if (reloadOnChange) newChanges.query = this.getNMFunctionalSubAreaMethod();
      newChanges.value = value;
      newChanges.options = getOptionsFromValue(newChanges.query, value);
    }
    Object.keys(newChanges).length > 0 && this.setState(newChanges);
  }

  @autobind
  private getNMFunctionalSubAreaMethod() {
    const method = async (search: string) => {
      const filters = isNullOrWhiteSpaces(search)
        ? []
        : [`startswith(tolower(${nameof<NMFunctionalSubareaDto>('name')}), '${search.toLowerCase()}')`];
      if (this.props.trainingNameId) {
        filters.push(`${nameof<NMFunctionalSubareaDto>('trainingNameId')} eq ${this.props.trainingNameId}`);
      }
      const result = await this.functionalSubAreasStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<NMFunctionalSubareaDto>('name'), useProfile: false }],
        filter: filters
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }
  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getNMFunctionalSubAreaMethod() });
    }
  };

  public render() {
    const val = this.state.value;
    const options = this.state.options;
    const query = this.state.query;
    return (
      <SelectionInput
        className={this.props.className}
        content={item => <div>{item.title}</div>}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        readOnly={this.props.readonly}
        clearOnReload={this.props.clearOnReload}
        onOpenDropDown={this.onOpenDropDown}
        options={this.state.query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NMFunctionalSubAreaEditor);
