import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from 'stores/dataStore';
import { CountryDto, CountriesStore } from 'stores/configuration/locations/countries-store';
import { SelectionInput } from 'widgets/form/selectionInput';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface CountryEditorProps extends WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  countries?: CountriesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readonly?: boolean;
  clearable?: boolean;
}

interface CountryEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['countries', CountriesStore])
class CountryEditor extends React.Component<CountryEditorProps, CountryEditorState> {
  private get countriesStore() {
    return this.props.countries;
  }

  constructor(props: CountryEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.getCountryMethod()
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next !== this.props && next.value !== this.props.value && this.props.reloadOnChange) {
      this.setState({
        value: next.value,
        query: this.getCountryMethod()
      });
    }
  }

  @autobind
  private getCountryMethod() {
    const method = async (search: string) => {
      const result = await this.countriesStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<CountryDto>('name'), useProfile: false }],
        filter: isNullOrWhiteSpaces(search) ? [] : [`startswith(tolower(${nameof<CountryDto>('name')}), '${search.toLowerCase()}')`]
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        readOnly={this.props.readonly}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(CountryEditor);
