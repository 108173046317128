import {
  ChangePublicHolidayConfigurationStore,
  NewPublicHolidayConfigurationStore,
  PublicHolidayConfigurationStore,
  ChangeCountryPublicHolidayConfigurationStore,
  NewCountryPublicHolidayConfigurationStore,
  CountryPublicHolidayConfigurationStore
} from './public-holidays-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new PublicHolidayConfigurationStore());
  storeBuilder.addRepository(new ChangePublicHolidayConfigurationStore());
  storeBuilder.addRepository(new NewPublicHolidayConfigurationStore());

  storeBuilder.addRepository(new CountryPublicHolidayConfigurationStore());
  storeBuilder.addRepository(new ChangeCountryPublicHolidayConfigurationStore());
  storeBuilder.addRepository(new NewCountryPublicHolidayConfigurationStore());
}
