import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Message } from 'semantic-ui-react';
import { MachineriesStore, MachineriesDto } from 'stores/configuration/machinery/machineries-store';
import { nameof } from 'utils/object';
import { OrderDefinition, Query } from 'stores/dataStore';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';

export interface MachineriesListProps extends WithTranslation {
  machineriesStore?: MachineriesStore;
}

@connect(['machineriesStore', MachineriesStore])
class MachineriesListPage extends Component<MachineriesListProps> {
  activeFilters = [];

  query: Query = { searchQuery: '', orderBy: [], skip: 0, take: 10 };

  componentDidMount() {
    this.load();
  }

  private load = () => this.props.machineriesStore.getAllAsync(this.query);

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.query = { ...this.query, orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }] };
    this.load();
  };

  private handlePageChange = (skip: number, take: number) => {
    this.query = { ...this.query, skip, take };
    this.load();
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    this.query = { ...this.query, filter: filters.map(({ filter }) => filter), skip: 0 };
    this.activeFilters = filters.map(({ id }) => id);
    this.load();
  };

  public render() {
    const { t, machineriesStore } = this.props;

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          renderer: ({ clusterName }) => clusterName,
          tooltipRenderer: true,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MachineriesDto>('clusterName'), `contains(tolower(EquipmentType/Cluster/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MachineriesDto>('clusterName'))}
              active={this.activeFilters.includes(nameof<MachineriesDto>('clusterName'))}
            />
          ),
          sortDefinition: { field: 'EquipmentType/Cluster/Name', useProfile: false }
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: true,
          renderer: ({ equipmentTypeName }) => equipmentTypeName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MachineriesDto>('equipmentTypeName'), `contains(tolower(EquipmentType/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MachineriesDto>('equipmentTypeName'))}
              active={this.activeFilters.includes(nameof<MachineriesDto>('equipmentTypeName'))}
            />
          ),
          sortDefinition: { field: 'EquipmentType/Name', useProfile: false }
        },
        {
          title: t('OEM'),
          tooltipRenderer: true,
          renderer: data => data.oemName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by OEM')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<MachineriesDto>('oemName'), `contains(tolower(Oem/Name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<MachineriesDto>('oemName'))}
              active={this.activeFilters.includes(nameof<MachineriesDto>('oemName'))}
            />
          ),
          sortDefinition: { field: 'Oem/Name', useProfile: false }
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: true,
          renderer: ({ name }) => name,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Machine Model')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<MachineriesDto>('name'), `contains(tolower(${nameof<MachineriesDto>('name')}), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<MachineriesDto>('name'))}
              active={this.activeFilters.includes(nameof<MachineriesDto>('name'))}
            />
          ),
          sortDefinition: { field: 'Name', useProfile: false }
        },
        {
          title: t('Machine Unit'),
          tooltipRenderer: true,
          renderer: ({ machineUnits }) => machineUnits
        }
      ],
      data: machineriesStore.state
    } as TableModel<MachineriesDto>;
    return (
      <>
        {machineriesStore.state.result &&
          !machineriesStore.state.result.isSuccess &&
          (machineriesStore.state.result.messages || []).length > 0 && (
            <Message
              className={'error-message__style'}
              icon={'exclamation circle'}
              onDismiss={machineriesStore.clearMessages}
              error
              header={t('An error ocurred')}
              list={machineriesStore.state.result.messages.map(o => o.body)}
            />
          )}
        <TableView
          model={tableModel}
          onOrderByChanged={this.handleOrderBy}
          onRefresh={this.load}
          onPageChange={this.handlePageChange}
          onFilterChange={this.handleFilterChange}
          exportUrl={'machines/v1/machineries-export'}
          bulkTemplateName={'machineries.xlsx'}
        />
      </>
    );
  }
}

export default withTranslation()(MachineriesListPage);
