import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Loader, Dimmer } from 'semantic-ui-react';
import { NewSupportCostStore, CreateSupportCostDto } from 'stores/configuration/support-costs/support-costs-store';
import { nameof } from 'utils/object';

interface NewSupportCostViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newSupportCost?: NewSupportCostStore;
}

interface NewSupportCostViewState {
  userClickedSaved: boolean;
}

@connect(['newSupportCost', NewSupportCostStore])
class NewSupportCostView extends React.Component<NewSupportCostViewProps, NewSupportCostViewState> {
  private get newSupportCostStore() {
    return this.props.newSupportCost;
  }

  constructor(props: NewSupportCostViewProps) {
    super(props);
    this.newSupportCostStore.createNew({
      name: '',
      year: null,
      cost: null
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewSupportCostViewProps) {
    if (this.newSupportCostStore.state.result && this.newSupportCostStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newSupportCostStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newSupportCostStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newSupportCostStore.change({ ...this.newSupportCostStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newSupportCostStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Support Cost')}</Modal.Header>
        <Modal.Content image>
          <Container>
            <Dimmer active={this.newSupportCostStore.state.isBusy} inverted>
              <Loader size="large" indeterminate inverted></Loader>
            </Dimmer>
            {this.newSupportCostStore.state.result && !this.newSupportCostStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newSupportCostStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newSupportCostStore.state.item && (
              <Form>
                <Form.Group widths="equal" className="support-cost-form-group">
                  <Form.Input
                    required
                    fluid
                    maxLength={250}
                    error={!this.newSupportCostStore.state.item.name && this.state.userClickedSaved}
                    label={t('Name')}
                    placeholder={t('Name')}
                    value={this.newSupportCostStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateSupportCostDto>('name'), value)}
                  />
                  <Form.Input
                    required
                    type="number"
                    min="1900"
                    fluid
                    error={!this.newSupportCostStore.state.item.year && this.state.userClickedSaved}
                    label={t('Year')}
                    placeholder={t('Year')}
                    value={this.newSupportCostStore.state.item.year}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateSupportCostDto>('year'), value)}
                  />
                  <Form.Input
                    required
                    min="0"
                    step="0.01"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    type="number"
                    fluid
                    error={!this.newSupportCostStore.state.item.cost && this.state.userClickedSaved}
                    label={t('Cost')}
                    placeholder={t('Cost')}
                    value={this.newSupportCostStore.state.item.cost ?? '0.00'}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateSupportCostDto>('cost'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(NewSupportCostView);
