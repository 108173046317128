import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface TrainingLevelDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateTrainingLevelDto {
  name: string;
}

export interface ChangeTrainingLevelDto {
  id: string;
  name: string;
}

export class CreateTrainingLevelValidator extends AbstractValidator<CreateTrainingLevelDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Training Level Name is required'));
  }
}

export class ChangeTrainingLevelValidator extends AbstractValidator<ChangeTrainingLevelDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Training Level Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Training Level Name is required'));
  }
}

@repository('@@TRAININGLEVELS', 'traininglevels.summary')
export class TrainingLevelsStore extends DataStore<TrainingLevelDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-traininglevel';
  retrievePath = 'get-traininglevels';
  updatePath = 'update-traininglevel';
  deletePath = 'delete-traininglevel';

  protected validate(item: TrainingLevelDto) {
    return new ChangeTrainingLevelValidator().validate(item);
  }

  constructor() {
    super('TRAININGLEVEL', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@TRAININGLEVELS', 'traininglevels.dropdown')
export class DropDownTrainingLevelsStore extends DataStore<TrainingLevelDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-traininglevels';
  updatePath = '';
  deletePath = '';

  protected validate(item: TrainingLevelDto) {
    return new ChangeTrainingLevelValidator().validate(item);
  }

  constructor() {
    super('TRAININGLEVELDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@TRAININGLEVELS', 'traininglevels.new')
export class NewTrainingLevelStore extends FormStore<CreateTrainingLevelDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-traininglevel';
  retrievePath = 'get-traininglevels';
  updatePath = 'update-traininglevel';

  protected validate(item: CreateTrainingLevelDto) {
    return new CreateTrainingLevelValidator().validate(item);
  }

  constructor() {
    super('NEW_TRAININGLEVEL', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TRAININGLEVELS', 'traininglevels.change')
export class ChangeTrainingLevelStore extends FormStore<ChangeTrainingLevelDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-traininglevel';
  retrievePath = 'get-traininglevels';
  updatePath = 'update-traininglevel';

  protected validate(item: ChangeTrainingLevelDto) {
    return new ChangeTrainingLevelValidator().validate(item);
  }

  constructor() {
    super('CHANGE_TRAININGLEVEL', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
