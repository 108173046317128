import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import { CreatePlcTypeDto, NewPlcTypeStore } from '../../../../../stores/configuration/machinery/plc-types-store';
import { ItemReference } from '../../../../../stores/dataStore';
import { nameof } from '../../../../../utils/object';
import EquipmentTypeEditor from '../../../../../widgets/bussiness/equipment-type-editor';
import OemEditor from '../../../../../widgets/bussiness/oem-editor';
import ClusterEditor from 'widgets/bussiness/mrcluster-editor';

interface NewPlcTypeViewProps extends WithTranslation {
  onClose: () => void;
  newPlcType?: NewPlcTypeStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewPlcTypeViewState {
  cluster: ItemReference;
  oem: ItemReference;
  equipmentType: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newPlcType', NewPlcTypeStore])
class NewPlcTypeView extends React.Component<NewPlcTypeViewProps, NewPlcTypeViewState> {
  private get newPlcTypeStore() {
    return this.props.newPlcType;
  }

  constructor(props: NewPlcTypeViewProps) {
    super(props);

    this.state = {
      cluster: null,
      equipmentType: null,
      oem: null,
      userClickedSaved: false
    };

    this.newPlcTypeStore.createNew({
      name: '',
      equipmentTypeId: '',
      oemId: ''
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewPlcTypeViewProps) {
    if (this.newPlcTypeStore.state.result && this.newPlcTypeStore.state.result.isSuccess) {
      nextProps.onClose();
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newPlcTypeStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newPlcTypeStore.clear();
    this.props.onClose();
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newPlcTypeStore.change({ ...this.newPlcTypeStore.state.item, ...change });
  }

  private onChangeCluster = (cluster: ItemReference) => {
    this.newPlcTypeStore.change({ ...this.newPlcTypeStore.state.item, ...{ equipmentTypeId: null } });
    this.setState({ cluster, equipmentType: null, userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newPlcTypeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New PLC Type')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newPlcTypeStore.state.result && !this.newPlcTypeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newPlcTypeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newPlcTypeStore.state.item && (
              <Form>
                <Form.Field widths="equal" error={!this.newPlcTypeStore.state.item.name && this.state.userClickedSaved}>
                  <Form.Input
                    required
                    fluid
                    label={t('PLC Type')}
                    placeholder={t('Name')}
                    value={this.newPlcTypeStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreatePlcTypeDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Group widths={'equal'}>
                  <Form.Field required error={!(this.state.cluster && this.state.cluster.id) && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <ClusterEditor placeholder={t('Cluster')} nullable value={this.state.cluster} onChange={this.onChangeCluster} />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newPlcTypeStore.state.item.equipmentTypeId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        readOnly={!(this.state.cluster && this.state.cluster.id)}
                        clusterId={this.state.cluster && this.state.cluster.id ? this.state.cluster.id : undefined}
                        placeholder={t('Equipment Type')}
                        nullable
                        value={this.state.equipmentType}
                        onChange={c => {
                          this.newPlcTypeStore.state.item.equipmentTypeId = c == null ? null : c.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newPlcTypeStore.state.item.oemId && this.state.userClickedSaved}>
                    <div className={`required field`}>
                      <label>{t('OEM')}</label>
                      <OemEditor
                        placeholder={t('OEM')}
                        nullable
                        value={this.state.oem}
                        onChange={c => {
                          this.newPlcTypeStore.state.item.oemId = c == null ? null : c.id;
                          this.setState({ userClickedSaved: false });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewPlcTypeView);
