import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export enum EventStatus {
  Draft = 10,
  Planned = 20,
  InProgress = 30,
  Completed = 40,
  Closed = 50
}
export interface EventTypeSfMapDto extends BaseDto {
  id: string;
  eventTypeId: string;
  eventTypeName: string;
  itemType: string;
  status: EventStatus;
  mappingToSF: string;
}

export interface CreateEventTypeSfMapDto {
  eventTypeId: string;
  itemType: string;
  status: EventStatus;
  mappingToSF: string;
}

export interface ChangeEventTypeSfMapDto {
  id: string;
  eventTypeId: string;
  eventTypeName: string;
  itemType: string;
  status: EventStatus;
  mappingToSF: string;
}

export class CreateEventTypeSfMapValidator extends AbstractValidator<CreateEventTypeSfMapDto> {
  constructor() {
    super();

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('EventTypeId is required'));

    this.validateIfString(o => o.itemType)
      .isNotEmpty()
      .withFailureMessage(i18n.t('ItemType is required'));

    this.validateIfString(o => o.mappingToSF)
      .isNotEmpty()
      .withFailureMessage(i18n.t('MappinToSF is required'));
  }
}

export class ChangeEventTypeSfMapValidator extends AbstractValidator<ChangeEventTypeSfMapDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('EventTypeSfMap Id is required'));

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('EventTypeId is required'));

    this.validateIfString(o => o.itemType)
      .isNotEmpty()
      .withFailureMessage(i18n.t('ItemType is required'));

    this.validateIfString(o => o.mappingToSF)
      .isNotEmpty()
      .withFailureMessage(i18n.t('MappinToSF is required'));
  }
}

@repository('@@EVENTTYPESFMAP', 'eventtypesfmap.summary')
export class EventTypeSfMapsStore extends DataStore<EventTypeSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-eventtype-map';
  retrievePath = 'get-eventtypes-map';
  updatePath = 'update-eventtype-map';
  deletePath = 'delete-eventtype-map';

  protected validate(item: EventTypeSfMapDto) {
    return new ChangeEventTypeSfMapValidator().validate(item);
  }

  constructor() {
    super('EVENTTYPESSFMAP', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTTYPESFMAP', 'eventtypesfmap.new')
export class NewEventTypeSfMapStore extends FormStore<CreateEventTypeSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-eventtype-map';
  retrievePath = 'get-eventtypes-map';
  updatePath = 'update-eventtype-map';

  protected validate(item: CreateEventTypeSfMapDto) {
    return new CreateEventTypeSfMapValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENTTYPESFMAP', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EVENTTYPESFMAP', 'eventtypesfmap.change')
export class ChangeEventTypeSfMapStore extends FormStore<ChangeEventTypeSfMapDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-eventtype-map';
  retrievePath = 'get-eventtypes-map';
  updatePath = 'update-eventtype-map';

  protected validate(item: ChangeEventTypeSfMapDto) {
    return new ChangeEventTypeSfMapValidator().validate(item);
  }

  constructor() {
    super('CHANGE_EVENTTYPESFMAP', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
