import React, { Component } from 'react';
import { FeedBackFormViewModelStore } from 'stores/assessments/forms/feedback-form.store';
import { WithTranslation, withTranslation } from 'react-i18next';
import Question from './questions/question.component';
import { connect } from 'redux-scaffolding-ts';
import './feedback-form.less';

export interface FeedbackFormMainProps extends WithTranslation {
  feedBackFormViewModelStore?: FeedBackFormViewModelStore;
}

@connect(['feedBackFormViewModelStore', FeedBackFormViewModelStore])
class FeedbackFormMain extends Component<FeedbackFormMainProps> {
  private handleAnswerChange = (index: number, value: string): void => {
    const questions = [...this.props.feedBackFormViewModelStore.state.item.questionAnswers];
    questions[index].value = value;

    this.props.feedBackFormViewModelStore.change({
      questionAnswers: questions
    });
  };

  render() {
    const { feedBackFormViewModelStore: store } = this.props;
    const { feedbackTemplateHeader, questionAnswers, readOnly, selectedLanguage } = store.state.item;

    return (
      <>
        <div>
          <div className="feedback-form__header__evaluation-criteria">
            {feedbackTemplateHeader ? (
              <pre style={{ fontFamily: 'inherit', whiteSpace: 'break-spaces' }}>{feedbackTemplateHeader}</pre>
            ) : (
              <p></p>
            )}
          </div>

          {(questionAnswers || []).map((answer, index) => (
            <Question
              key={answer.questionId}
              readOnly={readOnly}
              index={index}
              questionAnswer={answer}
              displayLanguage={selectedLanguage?.id}
              onAnswerChange={value => this.handleAnswerChange(index, value)}
            />
          ))}
        </div>
      </>
    );
  }
}

export default withTranslation()(FeedbackFormMain);
