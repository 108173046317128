import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Header } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from 'utils/object';
import { extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { NmrTheoreticalQuestionItemDto, NMRTheoreticalQuestionBanksStore } from 'stores/assessments/questionBank/theoretical-test-store';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import TrainingLevelEditor from 'widgets/bussiness/selectors/training-level-selector';
import React, { Component } from 'react';

interface ChooseNmrTheoreticalQuestionsViewProps extends WithTranslation {
  nmrTheoreticalQuestionBanksStore?: NMRTheoreticalQuestionBanksStore;
  alreadySelectedQuestions: string[];
  professionId?: string;
  clusterId?: string;
  functionalAreaId?: string;
  functionalSubAreaId: string;
  trainingNameId?: string;
  onAddTheoreticalQuestions: (questions: NmrTheoreticalQuestionItemDto[]) => void;
  onCloseModal?: () => void;
}

export interface ChooseTheoreticalQuestionFilters {
  questionTitleOrId: string;
  category: ItemReference;
  trainingLevelId: string;
}

interface ChooseNmrTheoreticalQuestionsViewState {
  query: Query;
  selectedQuestions: NmrTheoreticalQuestionItemDto[];
  filters: ChooseTheoreticalQuestionFilters;
}

@connect(['nmrTheoreticalQuestionBanksStore', NMRTheoreticalQuestionBanksStore])
class ChooseNmrTheoreticalQuestionsView extends Component<ChooseNmrTheoreticalQuestionsViewProps, ChooseNmrTheoreticalQuestionsViewState> {
  state: ChooseNmrTheoreticalQuestionsViewState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    selectedQuestions: null,
    filters: {
      questionTitleOrId: '',
      category: null,
      trainingLevelId: null
    }
  };

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  private onAddQuestions = () => {
    this.props.onAddTheoreticalQuestions(this.state.selectedQuestions);
    this.props.onCloseModal();
  };

  private onCancel = () => {
    this.props.onCloseModal();
  };

  private load = async () => {
    await this.props.nmrTheoreticalQuestionBanksStore.getAllAsync(this.state.query);
  };
  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private handleFilterChange = (filters, filter) => {
    this.setState(({ query }) => ({ filters, query: { ...query, filter, skip: 0 } }), this.load);
  };

  private handleOnFiltersChange = (property: keyof ChooseTheoreticalQuestionFilters, value: any) => {
    const filters = { ...this.state.filters };
    filters[property as string] = value;
    this.refreshTable(filters);
  };

  private selectedTnaQuestions = (selectedQuestions: NmrTheoreticalQuestionItemDto[]): void => {
    this.setState({ selectedQuestions });
  };

  private refreshTable = (filters: ChooseTheoreticalQuestionFilters) => {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  };

  private buildODataFilter = (filters?: ChooseTheoreticalQuestionFilters) => {
    const { alreadySelectedQuestions, professionId, clusterId, functionalAreaId, functionalSubAreaId, trainingNameId } = this.props;
    const oDataFilters = [];

    if ((alreadySelectedQuestions || []).length > 0) oDataFilters.push(`not(Id in (${alreadySelectedQuestions.join(',')}))`);

    if (professionId) oDataFilters.push({ Professions: { any: { id: { eq: { value: professionId, type: 'guid' } } } } });

    if (clusterId) oDataFilters.push({ NMRClusterId: { eq: { type: 'guid', value: clusterId } } });

    if (functionalAreaId) oDataFilters.push({ FunctionalAreaId: { eq: { type: 'guid', value: functionalAreaId } } });

    if (functionalSubAreaId) oDataFilters.push({ FunctionalSubareaId: { eq: { type: 'guid', value: functionalSubAreaId } } });

    if (trainingNameId) oDataFilters.push({ TrainingNameId: { eq: { type: 'guid', value: trainingNameId } } });

    if (!!filters.category) oDataFilters.push({ TestCategoryId: { eq: { type: 'guid', value: filters.category.id } } });

    if (!!filters.trainingLevelId) oDataFilters.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.trainingLevelId } } });

    if (filters.questionTitleOrId) {
      const input = filters.questionTitleOrId;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QNMRTh');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QNMRTh')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    return oDataFilters;
  };

  public render() {
    const { t, nmrTheoreticalQuestionBanksStore } = this.props;
    const { filters } = this.state;
    const qbCellTag = 'question-bank__cell__tag';

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data?.friendlyId || '',
          selectableHeader: true,
          sortDefinition: { field: nameof<NmrTheoreticalQuestionItemDto>('friendlyId'), useProfile: false }
        },
        { title: t('Questions'), tooltipRenderer: false, renderer: data => <div>{data?.question?.text || ''}</div>, electableHeader: true },
        {
          title: t('Category'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.testCategory ? qbCellTag : 'text__center'}>{data?.testCategory?.name || '-'}</span>,
          selectableHeader: true
        },
        {
          title: t('Training Level'),
          tooltipRenderer: false,
          renderer: data => <span className={data?.trainingLevel ? qbCellTag : 'text__center'}>{data?.trainingLevel?.name || '-'}</span>,
          selectableHeader: true
        }
      ],
      data: nmrTheoreticalQuestionBanksStore.state
    } as TableModel<NmrTheoreticalQuestionItemDto>;

    return (
      <Modal size={'large'} className="template-search__modal" open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Icon className="modal-back-icon clickable-icon" size="large" color="grey" name="arrow left" onClick={() => this.onCancel()} />
          <Header as="h2" className="modal-header-title">
            {t('Select Questions')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={(event, { value }) => this.handleOnFiltersChange('questionTitleOrId', value)}
            />

            <TestCategorySelector
              clearable
              nullable
              isTnaCategory={true}
              className="planit-user-dropdown-custom"
              placeholder={t('Select Category')}
              value={filters.category}
              onChange={category => this.handleOnFiltersChange('category', category)}
            />

            <TrainingLevelEditor
              clearable
              nullable
              className="planit-user-dropdown-custom"
              value={filters.trainingLevelId}
              onChange={value => this.handleOnFiltersChange('trainingLevelId', value)}
              placeholder={t('Training Level')}
              searchable
            />
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={this.selectedTnaQuestions}
              onPageChange={this.handlePageChange}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" onClick={this.onAddQuestions} positive>
            {t('Select Questions')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseNmrTheoreticalQuestionsView);
