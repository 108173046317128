import { Container, interfaces } from 'inversify';
import { IdentityService } from './services/identity-service';
import getDecorators from 'inversify-inject-decorators';
import HttpService from './services/http-service';
import { SchedulerEventFilterService } from 'site/pages/landing-pages/shared-scheduler-components/scheduler-header/filters/scheduler-event-filter-service';
import { SchedulerResourceFilterService } from 'site/pages/landing-pages/shared-scheduler-components/scheduler-header/filters/scheduler-resource-filter-service';
import { SchedulerPeriodService } from 'site/pages/landing-pages/shared-scheduler-components/events/scheduler-period-service';
import { InstructorRowFilterService } from 'site/pages/landing-pages/scheduler-instructor-page/instructor-row-filter/instructor-row-filter-service';
import { DateTimeService } from 'services/datetime-service';
import EventService from 'stores/events/event-service';

// Initialize DI/IoC container
const container = new Container();

function initialize(app, test = false) {
  if (!container.isBound(IdentityService)) {
    if (test) {
      container
        .bind(IdentityService)
        .toSelf()
        .inSingletonScope()
        .onActivation((context, instance) => {
          instance.setupTest();
          return instance;
        });
    } else {
      container
        .bind(IdentityService)
        .toSelf()
        .inSingletonScope();
    }
  }

  if (!container.isBound(HttpService)) {
    // Initialize services if container is not configured before
    container
      .bind(HttpService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(SchedulerEventFilterService)) {
    container
      .bind(SchedulerEventFilterService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(SchedulerResourceFilterService)) {
    container
      .bind(SchedulerResourceFilterService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(SchedulerPeriodService)) {
    container
      .bind(SchedulerPeriodService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(DateTimeService)) {
    container
      .bind(DateTimeService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(InstructorRowFilterService)) {
    container
      .bind(InstructorRowFilterService)
      .toSelf()
      .inSingletonScope();
  }

  if (!container.isBound(EventService)) {
    container
      .bind(EventService)
      .toSelf()
      .inSingletonScope();
  }
}

function setup() {
  // TODO: Add dependencies
}

interface IBabelPropertyDescriptor extends PropertyDescriptor {
  initializer(): any;
}

const injectProperty = <T>(serviceIdentifier: interfaces.ServiceIdentifier<T>) => {
  const original = getDecorators(container).lazyInject(serviceIdentifier);
  return function(this: any, proto: any, key: string, descriptor?: IBabelPropertyDescriptor): void {
    // make it work as usual
    original.call(this, proto, key);
    // return link to proto, so own value wont be 'undefined' after component's creation
    descriptor.initializer = function() {
      return proto[key];
    };
  } as any;
};

export { container, setup, initialize, injectProperty as resolve };
