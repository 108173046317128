import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Grid, Input, Checkbox, Button, Popup, Menu, Dimmer, Loader } from 'semantic-ui-react';
import {
  RestrictionWarningDto,
  RestrictionWarningsStore,
  DownloadRestrictionWarningStore,
  UploadRestrictionWarningStore
} from 'stores/configuration/planner-assistant/restrictions-warnings-store';
import { OrderDefinition, Query } from 'stores/dataStore';
import { TableModel, TableView } from 'widgets/collections/table';
import NewRestrictionWarningView from './new-restriction-warning';
import ChangeRestrictionWarningView from './edit-restriction-warning';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { nameof } from 'utils/object';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFileExcel, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FileInfo } from 'stores/types';

export interface RestrictionWarningListProps extends WithTranslation, RouteComponentProps {
  restrictionWarnings: RestrictionWarningsStore;
  downloadRestrictionWarning?: DownloadRestrictionWarningStore;
  uploadRestrictionWarning?: UploadRestrictionWarningStore;
}

export interface RestrictionWarningListState {
  query: Query;
  newRestrictionWarningShown: boolean;
  changeRestrictionWarningShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  showDownloadMsg: boolean;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  uploadLoadingMessage: string;
}

@connect(
  ['restrictionWarnings', RestrictionWarningsStore],
  ['downloadRestrictionWarning', DownloadRestrictionWarningStore],
  ['uploadRestrictionWarning', UploadRestrictionWarningStore]
)
class RestrictionWarningListPage extends React.Component<RestrictionWarningListProps, RestrictionWarningListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }], skip: 0, take: 10 },
      newRestrictionWarningShown: false,
      changeRestrictionWarningShown: false,
      activeFilters: [],
      selectedItem: null,
      showDownloadMsg: false,
      uploadMenuOpen: false,
      showUploadMsg: false,
      uploadLoadingMessage: ''
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.restrictionWarnings.getAllAsync(this.state.query);
  }

  @autobind
  private onNewItem() {
    this.setState({ newRestrictionWarningShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newRestrictionWarningShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeRestrictionWarningShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeRestrictionWarningShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'modifiedOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private initExportVisaRestrictionsProcess = () => {
    this.setState({ showDownloadMsg: true });
    this.props.downloadRestrictionWarning.initBackgroundDownload();
  };

  private getBackgroundUploadMessage = () => {
    const { isBusy, result } = this.props.uploadRestrictionWarning.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    let error = false;
    if (result.error || (result.response && !result.response.isSuccessful)) {
      header = t('Something went wrong with the file');
      content = (result.error || {}).message;
      error = true;
    } else if (result.error == null && result.response && result.response.isSuccessful) {
      header = t('File uploaded successfully');
      content = t('You will receive an email when the process ends');
      error = false;
    } else return null;

    return (
      <Message
        className={error && 'error-message__style'}
        icon={error && 'exclamation circle'}
        onDismiss={this.handleUploadMsgDismiss}
        header={header}
        content={content}
        error={error}
        success={!error}
      />
    );
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadRestrictionWarning.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = (result.messages || []).map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private handleUploadMsgDismiss = () => {
    this.setState({ showUploadMsg: false });
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.uploadRestrictionWarning
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.uploadRestrictionWarning
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('TTC Location'),
          renderer: data => <span>{data.ttcLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.ttcLocationName}
              fluid
              onChange={(e, { value }) => {
                data.ttcLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by TTC Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('ttcLocationName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('ttcLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('ttcLocationName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('ttcLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('ttcLocationName'), useProfile: false }
        },
        {
          title: t('Traveling Location'),
          renderer: data => <span>{data.travelingLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.travelingLocationName}
              fluid
              onChange={(e, { value }) => {
                data.travelingLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Traveling Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('travelingLocationName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('travelingLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('travelingLocationName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('travelingLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('travelingLocationName'), useProfile: false }
        },
        {
          title: t('Visa Requirement'),
          renderer: data => <span>{data.visaName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.visaName}
              fluid
              onChange={(e, { value }) => {
                data.visaName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('visaName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('visaName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('visaName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('visaName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('visaName'), useProfile: false }
        },
        {
          title: t('Visa application. Months duration'),
          tooltipRenderer: false,
          renderer: data => <span>{data.visaApplicationDuration}</span>
        },
        {
          title: t('Restriction'),
          tooltipRenderer: false,
          renderer: data => <span>{!!data.isRestriction ? t('Yes') : t('No')}</span>,
          editor: (data, onChange) => (
            <Checkbox
              toggle
              checked={data.isRestriction}
              onChange={(e, { checked }) => {
                data.isRestriction = !!checked;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Restriction')}
              trueLabel={t('Yes')}
              falseLabel={t('No')}
              triggerTitle={title}
              onFilter={(value: boolean) => onFilter(nameof<RestrictionWarningDto>('isRestriction'), { isRestriction: value })}
              onClear={() => onClear(nameof<RestrictionWarningDto>('isRestriction'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('isRestriction'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('isRestriction'), useProfile: false }
        },
        {
          title: t('Comments'),
          tooltipRenderer: false,
          renderer: data => <span>{data.comments}</span>
        },
        {
          title: t('Contract'),
          renderer: data => <span>{data.contractName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.contractName}
              fluid
              onChange={(e, { value }) => {
                data.contractName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Contract')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('contractName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('contractName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('contractName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('contractName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('contractName'), useProfile: false }
        },
        {
          title: t('Flight Cost'),
          renderer: data => <span>{data.flightCostName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.flightCostName}
              fluid
              onChange={(e, { value }) => {
                data.flightCostName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Flight Cost')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('flightCostName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('flightCostName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('flightCostName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('flightCostName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('flightCostName'), useProfile: false }
        }
      ],
      data: this.props.restrictionWarnings.state
    } as TableModel<RestrictionWarningDto>;

    return (
      <>
        <Grid className="event-types-list-grid">
          {this.state.showUploadMsg && this.getBackgroundUploadMessage()}
          {this.props.downloadRestrictionWarning.state.isBusy && (
            <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
              <Loader indeterminate>{t('Initializing download background process')}</Loader>
            </Dimmer>
          )}
          {this.state.showDownloadMsg && this.getBackgroundDownloadMessage()}
          {this.props.restrictionWarnings.state.result && !this.props.restrictionWarnings.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.restrictionWarnings.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              selectable={false}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil alternate" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, this.onEditItem);
                  }
                }
              ]}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              canCreateNew={true}
              onNewItem={this.onNewItem}
              createNewButtonTitle={t('New Restriction')}
              onFilterChange={this.handleFilterChange}
              onOrderByChanged={this.handleOrderBy}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Popup
                      on="click"
                      position="bottom center"
                      open={this.state.uploadMenuOpen}
                      onOpen={() => this.setState({ uploadMenuOpen: true })}
                      onClose={() => this.setState({ uploadMenuOpen: false })}
                      trigger={
                        <Button
                          icon
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={() => this.setState({ uploadMenuOpen: true })}
                          data-tooltip={t('Import')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                        </Button>
                      }
                    >
                      <Menu vertical secondary>
                        <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                          <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                          <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                          &nbsp;{t('Upload File')}
                        </Menu.Item>

                        <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                          <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                          &nbsp;{t('Download Template')}
                        </Menu.Item>
                      </Menu>
                    </Popup>
                  )
                },
                {
                  className: 'menu-item-download',
                  content: (
                    <Button
                      icon
                      size="medium"
                      onClick={this.initExportVisaRestrictionsProcess}
                      className="custom-table-upload-btn black-btn"
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )
                }
              ]}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newRestrictionWarningShown && <NewRestrictionWarningView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeRestrictionWarningShown && (
            <ChangeRestrictionWarningView onClose={this.onEditItemClosed} currentRestrictionWarning={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(RestrictionWarningListPage);
