import React from 'react';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { ChangeRequestStore } from '../../../../../../stores/requests/requests-store';
import './request-cancel-popup.less';
import RejectModal, { RejectProperties } from 'site/pages/shared/events-and-requests/reject-modal';

export interface RequestCancelPopupProps extends WithTranslation {
  requestId: string;
  open: boolean;
  changeRequestStore?: ChangeRequestStore;

  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

interface CancelRequestState extends RejectProperties {
  loading: boolean;
}

const title = 'Are you sure you want to CANCEL the request?';
const subtitle = 'This action cannot be undone, and the request will be completely deleted.';
@connect(['changeRequestStore', ChangeRequestStore])
class RequestCancelPopup extends Component<RequestCancelPopupProps, CancelRequestState> {
  static defaultProps = { onCancel: () => {}, onSuccess: () => {}, onError: () => {} };
  state: CancelRequestState = { rejectReason: '', rejectionReasonId: '', rejectedById: '', loading: false };

  onSubmit = async () => {
    const { rejectReason, rejectionReasonId, rejectedById } = this.state;
    const { requestId, changeRequestStore, onError, onSuccess } = this.props;

    this.setState({ loading: true });
    try {
      const response = await changeRequestStore.deleteById(requestId, rejectReason, rejectionReasonId, rejectedById);
      if (response?.isSuccess) {
        onSuccess();
        changeRequestStore.clear();
      }
    } catch (error) {
      onError();
    }
    this.setState({ loading: false });
  };

  onCancel = () => this.props.onCancel();

  changeHandler = (property: keyof RejectProperties, value: any) => this.setState({ [property]: value } as any);

  render() {
    const { loading, rejectReason, rejectionReasonId, rejectedById } = this.state;
    const { open, changeRequestStore } = this.props;
    const errMsgs = (changeRequestStore.state.result?.messages || []).map(x => x.body);
    return (
      <RejectModal
        title={title}
        subtitle={subtitle}
        submitBtnText={'Yes'}
        cancelBtnText={'No'}
        loading={loading || changeRequestStore.state.isBusy}
        open={open}
        onClose={this.onCancel}
        onChange={this.changeHandler}
        onDismiss={changeRequestStore.clearMessages}
        onSubmit={this.onSubmit}
        errMsgs={errMsgs}
        rejectReason={rejectReason}
        rejectionReasonId={rejectionReasonId}
        rejectedById={rejectedById}
      />
    );
  }
}

export default withTranslation()(RequestCancelPopup);
