import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { connect } from 'redux-scaffolding-ts';
import { PracticalFormWizardStore, PracticalFormView, PracticalFormAnswerItemDto } from 'stores/assessments/forms/practical-form-store';
import { Message, Dimmer, Loader, Divider, Button } from 'semantic-ui-react';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import Header from '../components/practical-form-header';
import Main from '../components/practical-form-main';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import './practical-form-style.less';
import { goToPage } from 'utils/useful-functions';

interface PracticalFormTestProps extends WithTranslation, RouteComponentProps {
  practicalFormStore?: PracticalFormWizardStore;
}

interface PracticalFormTestState {}

@connect(['practicalFormStore', PracticalFormWizardStore])
class PracticalFormTest extends Component<PracticalFormTestProps, PracticalFormTestState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state = { isTestStudent: false, loading: false };

  getFormAnswers = async (id: string): Promise<PracticalFormAnswerItemDto> => {
    this.setState({ loading: true });
    try {
      const formAnswerDto = await this.props.practicalFormStore.getFormAnswerById(id);
      this.props.practicalFormStore.createNew(this.props.practicalFormStore.state.item);
      this.setState({ loading: false });
      return formAnswerDto;
    } catch (error) {
      this.setState({ loading: false });
      goToPage('/NotFound');
    }
  };

  submitForm = async () => {
    this.setState({ loading: true });
    const { practicalFormStore: practicalFormWizard } = this.props;
    try {
      const result = await practicalFormWizard.submitAnswer();
      return result;
    } catch (error) {
      console.error({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  load = () => {
    const id = this.props.match.params['id'].toString();
    this.getFormAnswers(id);
  };

  componentDidMount() {
    this.load();
  }

  isTestStudent = (id: string) => this.identityService.getUserInfo().userId === id;
  isEmployee = () => this.identityService.isInRole(Roles.Employee);

  submitTest = async () => {
    const result = await this.submitForm();
    if (!result?.isSuccess) return result;
    ToastComponent({ text: this.props.t('Form successfully submitted!'), type: 'success-toast' });
    setTimeout(() => window.close(), 3000);
  };

  changeHandler = (property: keyof PracticalFormView, value: any) => {
    const { practicalFormStore: practicalFormWizard } = this.props;
    practicalFormWizard.change({ [property as string]: value });
  };

  render() {
    const { t, practicalFormStore } = this.props;
    const { item, result, isBusy } = practicalFormStore.state;

    const msgs = (result?.messages || []).map(m => m.body);
    return (
      <>
        <Dimmer active={isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <h2 className="edit-form__title">{item?.templateTitle}</h2>

        {!result?.isSuccess && (result?.messages || []).length > 0 && (
          <Message
            onDismiss={practicalFormStore.clearMessages}
            className="error-message__style"
            icon="exclamation circle"
            error
            list={msgs}
          />
        )}
        {item && (
          <div className="practical-form-test">
            <div className="form__all-wrapper">
              <header className="form__all-wrapper__header">
                <Header {...item} onChange={this.changeHandler} />
              </header>
              <main className="form__main-wrapper scroll">
                {item.header && <pre className="form-header">{item.header || ''}</pre>}
                <Main {...item} onChange={this.changeHandler} />
              </main>
              <Divider />
              <footer style={{ display: 'flex' }} className="form__all-wrapper__footer">
                <div className="spacer" />
                <Button disabled={item.readonly} className="practical-form__save-btn" content={t('Save')} onClick={this.submitTest} />
              </footer>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(PracticalFormTest);
