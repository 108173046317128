import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import { nameof } from '../../../../../utils/object';
import { VisaDto, ChangeVisaStore, ChangeVisaDto } from 'stores/configuration/planner-assistant/visas-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface ChangeVisaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeVisa?: ChangeVisaStore;
  currentVisa: VisaDto;
}

interface ChangeVisaViewState {}

@connect(['changeVisa', ChangeVisaStore])
class ChangeVisaView extends React.Component<ChangeVisaViewProps, ChangeVisaViewState> {
  private get changeVisaStore() {
    return this.props.changeVisa;
  }

  constructor(props: ChangeVisaViewProps) {
    super(props);

    this.changeVisaStore.state.result = null;

    this.changeVisaStore.change({
      id: this.props.currentVisa.id,
      name: this.props.currentVisa.name,
      internationalTreatyRequired: this.props.currentVisa.internationalTreatyRequired
    });

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeVisaViewProps) {
    if (this.changeVisaStore.state.result && this.changeVisaStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeVisaStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeVisaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeVisaStore.change({ ...this.changeVisaStore.state.item, ...change });
  }

  private onVisaChange = (e, { value }) => {
    this.handleValue(nameof<ChangeVisaDto>('name'), value);
  };

  public render() {
    const { t } = this.props as any;
    const { result, item } = this.changeVisaStore.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeVisaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Visa')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field error={isNullOrWhiteSpaces(item.name)} required>
                    <label>{t('Visa')}</label>
                    <Input
                      placeholder={t('Visa')}
                      value={item.name}
                      onChange={(e, { value }) => this.handleValue(nameof<ChangeVisaDto>('name'), value)}
                    />
                  </Form.Field>

                  <Form.Checkbox
                    style={{ top: 25 }}
                    toggle
                    checked={this.changeVisaStore.state.item.internationalTreatyRequired}
                    label={t('International Treaty Required')}
                    placeholder={t('International Treaty')}
                    onChange={(e, { checked }) => {
                      this.handleValue(nameof<ChangeVisaDto>('internationalTreatyRequired'), checked);
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeVisaView);
