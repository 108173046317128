import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { CreateCustomizationDataDto, NewCustomizationDataStore } from 'stores/configuration/events-n-requests/customization-data-store';
import { nameof } from 'utils/object';

interface NewCustomizationDataViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newCustomizationData?: NewCustomizationDataStore;
}

interface NewCustomizationDataViewState {
  userClickedSaved: boolean;
}

@connect(['newCustomizationData', NewCustomizationDataStore])
class NewCustomizationDataView extends React.Component<NewCustomizationDataViewProps, NewCustomizationDataViewState> {
  private get newCustomizationDataStore() {
    return this.props.newCustomizationData;
  }

  constructor(props: NewCustomizationDataViewProps) {
    super(props);
    this.newCustomizationDataStore.createNew({
      name: ''
    });
    this.state = {
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewCustomizationDataViewProps) {
    if (this.newCustomizationDataStore.state.result && this.newCustomizationDataStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newCustomizationDataStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newCustomizationDataStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newCustomizationDataStore.change({ ...this.newCustomizationDataStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newCustomizationDataStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Customization Data')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newCustomizationDataStore.state.result && !this.newCustomizationDataStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newCustomizationDataStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newCustomizationDataStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.newCustomizationDataStore.state.item.name && this.state.userClickedSaved}
                    label={t('Customization Data')}
                    placeholder={t('Name')}
                    value={this.newCustomizationDataStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateCustomizationDataDto>('name'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewCustomizationDataView);
