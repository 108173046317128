import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeTypeHoursCodeDto,
  ChangeTypeHoursCodeStore,
  TypeHoursCodeDto
} from '../../../../../stores/configuration/events-n-requests/type-hour-code-store';
import { nameof } from '../../../../../utils/object';

interface ChangeTypeHoursCodeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeTypeHoursCode?: ChangeTypeHoursCodeStore;
  currentTypeHoursCode: TypeHoursCodeDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeTypeHoursCodeViewState {}

@connect(['changeTypeHoursCode', ChangeTypeHoursCodeStore])
class ChangeTypeHoursCodeView extends React.Component<ChangeTypeHoursCodeViewProps, ChangeTypeHoursCodeViewState> {
  private get changeTypeHoursCodeStore() {
    return this.props.changeTypeHoursCode;
  }

  constructor(props: ChangeTypeHoursCodeViewProps) {
    super(props);

    this.changeTypeHoursCodeStore.state.result = null;

    this.changeTypeHoursCodeStore.change({
      id: this.props.currentTypeHoursCode.id,
      code: this.props.currentTypeHoursCode.code
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeTypeHoursCodeViewProps) {
    if (this.changeTypeHoursCodeStore.state.result && this.changeTypeHoursCodeStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeTypeHoursCodeStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeTypeHoursCodeStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeTypeHoursCodeStore.change({ ...this.changeTypeHoursCodeStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeTypeHoursCodeStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Delivery Type')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeTypeHoursCodeStore.state.result && !this.changeTypeHoursCodeStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeTypeHoursCodeStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeTypeHoursCodeStore.state.item && (
              <Form loading={this.changeTypeHoursCodeStore.state.isBusy}>
                <Form.Field>
                  <Form.Input
                    required
                    fluid
                    label={t('Delivery Type')}
                    placeholder={t('Name')}
                    maxLength={50}
                    value={this.changeTypeHoursCodeStore.state.item.code}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeTypeHoursCodeDto>('code'), value)}
                  />
                </Form.Field>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeTypeHoursCodeView);
