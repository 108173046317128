import { setupRepositories as setupConfigurationRepositories } from './stores/configuration/configuration-stores.config';
import { setupRepositories as setupUsersRepositories } from './stores/users/users-store.config';
import { setupRepositories as setupInstructorsRepositories } from './stores/instructors/instructors-store.config';
import { setupRepositories as setupUserImportsRepositories } from './stores/user-import/user-import-store.config';
import { setupRepositories as setupImportLogsRepositories } from './stores/users/importLogs/importLogs-Store.config';
import { setupRepositories as setupRolesRepositories } from './stores/roles/roles-store.config';
import { setupRepositories as setupInstructorRolesRepositories } from './stores/instructor-roles/instructor-roles-store.config';
import { setupRepositories as setupMachinesPerLocationRepositories } from './stores/locationsmanagement/machines-per-location.config';
import { setupRepositories as setupRequestsRepositories } from './stores/requests/requests-stores.config';
import { setupRepositories as setupEventsRepositories } from './stores/events/event-stores.config';
import { setupRepositories as setupSkillsRepositories } from './stores/skills/skills.config';
import { setupRepositories as setupAssessmentsRepositories } from './stores/assessments/assessments-store-config';
import { setupRepositories as setupPublicationHolidaysRepositories } from './stores/public-holidays/public-holidays-store.config';
import { setupRepositories as setupWorkingDaysRepositories } from './stores/working-days/working-days-store.config';
import { setupRepositories as setupSFMAPRepositories } from './stores/configuration/sf-mappings/configuration-sf-mapping-stores.config';
import { setupRepositories as setupProfilesRepositories } from './stores/profile/profile-store.config';
import { setupRepositories as setupUserProfilesRepositories } from './stores/profile/user-profile-store.config';
import { setupRepositories as setupEvaluationsRepositories } from './stores/evaluations/evaluations-store.config';
import { setupRepositories as setupEmployeeTrackLogRepositories } from './stores/employee-track-log/employee-track-log.config';
import { setupRepositories as setupDevelopmentPlanRepositories } from './stores/development-plan/development-plan.config';
import { setupRepositories as setupAdminReportsRepositories } from './stores/admin-reports/admin-reports-config';

export function setupRepositories(storeBuilder) {
  setupConfigurationRepositories(storeBuilder);
  setupUsersRepositories(storeBuilder);
  setupInstructorsRepositories(storeBuilder);
  setupUserImportsRepositories(storeBuilder);
  setupRolesRepositories(storeBuilder);
  setupInstructorRolesRepositories(storeBuilder);
  setupMachinesPerLocationRepositories(storeBuilder);
  setupRequestsRepositories(storeBuilder);
  setupEventsRepositories(storeBuilder);
  setupSkillsRepositories(storeBuilder);
  setupImportLogsRepositories(storeBuilder);
  setupPublicationHolidaysRepositories(storeBuilder);
  setupWorkingDaysRepositories(storeBuilder);
  setupSFMAPRepositories(storeBuilder);
  setupProfilesRepositories(storeBuilder);
  setupAssessmentsRepositories(storeBuilder);
  setupUserProfilesRepositories(storeBuilder);
  setupEvaluationsRepositories(storeBuilder);
  setupEmployeeTrackLogRepositories(storeBuilder);
  setupDevelopmentPlanRepositories(storeBuilder);
  setupAdminReportsRepositories(storeBuilder);
}
