import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface LanguageDto extends BaseDto {
  id: string;
  languageId: string;
  language: string;
  languageCode: string;
  hasTnaAvailable: boolean;
}

export interface CreateLanguageDto {
  language: string;
  languageCode: string;
  hasTnaAvailable: boolean;
}

export interface ChangeLanguageDto {
  id: string;
  languageId: string;
  language: string;
  languageCode: string;
  hasTnaAvailable: boolean;
}

export class CreateLanguageValidator extends AbstractValidator<CreateLanguageDto> {
  constructor() {
    super();

    this.validateIfString(o => o.language)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Language is required'));

    this.validateIfString(o => o.languageCode)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('Language Code is required'));
  }
}

export class ChangeLanguageValidator extends AbstractValidator<ChangeLanguageDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Language Id is required'));

    this.validateIfString(o => o.language)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Language is required'));

    this.validateIfString(o => o.languageCode)
      .isNotEmpty()
      .hasMinLength(2)
      .withFailureMessage(i18n.t('Language Code is required'));
  }
}

@repository('@@LANGUAGES', 'languages.summary')
export class LanguagesStore extends DataStore<LanguageDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-language';
  retrievePath = 'get-languages';
  updatePath = 'update-language';
  deletePath = 'delete-language';
  defaulLanguage = 'get-default-language';

  protected validate(item: LanguageDto) {
    return new ChangeLanguageValidator().validate(item);
  }

  constructor() {
    super('LANGUAGE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getDefaultLanguage(): Promise<LanguageDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(this.defaulLanguage, httpService.get<LanguageDto>(`${this.baseUrl}/${this.defaulLanguage}`));
    return result.data;
  }
}

@repository('@@LANGUAGES', 'languages.dropdown')
export class DropdownLanguagesStore extends DataStore<LanguageDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-languages';
  updatePath = '';
  deletePath = '';

  protected validate(item: LanguageDto) {
    return new ChangeLanguageValidator().validate(item);
  }

  constructor() {
    super('LANGUAGEDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, languagesIds?: string[], search?: string): Promise<QueryResult<LanguageDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let languagesDto = this.state.items.map(({ item }) => item);

      if ((languagesIds || []).length > 0) {
        languagesDto = languagesDto.filter(x => languagesIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        languagesDto = languagesDto.filter(x => x.language.startsWith(search));
      }

      let locationsResult = { items: languagesDto, count: languagesDto.length } as QueryResult<LanguageDto>;
      return new Promise<QueryResult<LanguageDto>>(resolve => resolve(locationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<LanguageDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<LanguageDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@LANGUAGES', 'languages.new')
export class NewLanguageStore extends FormStore<CreateLanguageDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-language';
  retrievePath = 'get-languages';
  updatePath = 'update-language';

  protected validate(item: CreateLanguageDto) {
    return new CreateLanguageValidator().validate(item);
  }

  constructor() {
    super('NEW_LANGUAGE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@LANGUAGES', 'languages.change')
export class ChangeLanguageStore extends FormStore<ChangeLanguageDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-language';
  retrievePath = 'get-languages';
  updatePath = 'update-language';

  protected validate(item: ChangeLanguageDto) {
    return new ChangeLanguageValidator().validate(item);
  }

  constructor() {
    super('CHANGE_LANGUAGE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
