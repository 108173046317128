import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from 'stores/dataStore';
import { MachineModelDto, MachineModelsStore } from 'stores/configuration/machinery/machine-models-store';
import { nameof } from 'utils/object';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface MultipleMachineModelEditorProps extends WithTranslation {
  value: MachineModelDto[];
  onChange?: (value: MachineModelDto[]) => void;
  machineModels?: MachineModelsStore;
  placeholder?: string;
  clearable?: boolean;
  disabled?: boolean;
  className?: string;
  equipmentId?: string;
  clusterId?: string;
  oemId?: string;
  reloadOnChange?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultipleMachineModelEditorState {
  options: MultipleSelectionDropdownOptions<MachineModelDto>[];
}

@connect(['machineModels', MachineModelsStore])
class MultipleMachineModelEditor extends React.Component<MultipleMachineModelEditorProps, MultipleMachineModelEditorState> {
  private get machineModelsStore() {
    return this.props.machineModels;
  }

  state: MultipleMachineModelEditorState = {
    options: []
  };

  componentDidMount() {
    this.getMachineModelMethod();
  }

  getMachineModelMethod = () => {
    const filters = ['active eq true'];

    if (this.props.clusterId) filters.push(`${nameof<MachineModelDto>('clusterId')} eq ${this.props.clusterId}`);
    if (this.props.equipmentId) filters.push(`${nameof<MachineModelDto>('equipmentTypeId')} eq ${this.props.equipmentId}`);
    if (this.props.oemId) {
      filters.push(`${nameof<MachineModelDto>('oemId')} eq ${this.props.oemId}`);
    }

    this.machineModelsStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<MachineModelDto>('name'), useProfile: false }],
        filter: filters
      })
      .then(machineModels => this.setState({ options: this.mapToOptions(machineModels) }));
  };

  mapToOptions = (machineModels: QueryResult<MachineModelDto>): MultipleSelectionDropdownOptions<MachineModelDto>[] =>
    (machineModels?.items || []).map(machine => ({ id: machine.id, value: machine, text: machine.name }));

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleMachineModelEditor);
