import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from '../../stores/dataStore';
import { EquipmentTypeDto, EquipmentTypesStore } from '../../stores/configuration/machinery/equipment-types-store';
import { DropdownProps, SelectionInput, SelectionItem } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces, customEqualCompare } from '../../utils/useful-functions';

export interface EquipmentTypeItemReference extends ItemReference {
  cluster: ItemReference;
}

interface EquipmentTypeEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference | string;
  onChange?: (value: ItemReference | EquipmentTypeItemReference) => void;
  placeholder?: string;
  equipmentTypes?: EquipmentTypesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  clearOnReload?: boolean;
  clearable?: boolean;
  clusterId?: string;
  readOnly?: boolean;
  className?: string;
  loadDataOnOpen?: boolean;
  oemId?: string;
  relatedEquipmentTypeIds?: string[];
}

interface EquipmentTypeEditorState {
  value: EquipmentTypeItemReference | ItemReference | string;
  searchQuery: string;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
  selectedClusterId?: string;
  selectedOemId?: string;
  currentQuery?: QueryResult<EquipmentTypeDto>;
  isAlreadyQuerying: boolean;
}

@connect(['equipmentTypes', EquipmentTypesStore])
class EquipmentTypeEditor extends React.Component<EquipmentTypeEditorProps, EquipmentTypeEditorState> {
  private get equipmentTypesStore() {
    return this.props.equipmentTypes;
  }

  constructor(props: Readonly<EquipmentTypeEditorProps>) {
    super(props);
    this.state = {
      value: this.props.value,
      searchQuery: null,
      query: this.props.loadDataOnOpen ? null : this.getEquipmentTypeMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value),
      selectedClusterId: null,
      currentQuery: null,
      isAlreadyQuerying: false,
      selectedOemId: null
    };
  }

  componentDidUpdate(prevProps: Readonly<EquipmentTypeEditorProps>) {
    const { value, reloadOnChange, clusterId, relatedEquipmentTypeIds } = this.props;
    if (reloadOnChange && !customEqualCompare(prevProps.value, value)) {
      this.setState({
        value,
        searchQuery: null,
        options: getOptionsFromValue(this.state?.query, value)
      });
    }

    if (!customEqualCompare(prevProps.clusterId, clusterId)) {
      this.setState({
        value,
        searchQuery: null,
        query: this.getEquipmentTypeMethod(),
        options: getOptionsFromValue(this.state?.query, value)
      });
    }

    if (!customEqualCompare(prevProps.relatedEquipmentTypeIds, relatedEquipmentTypeIds)) {
      this.setState({
        value,
        searchQuery: null,
        query: this.getEquipmentTypeMethod(),
        options: getOptionsFromValue(this.state?.query, value)
      });
    }
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getEquipmentTypeMethod() });
    }
  };
  @autobind
  private getEquipmentTypeMethod() {
    const method = async (search: string) => {
      if (this.state.isAlreadyQuerying) return;
      this.setState({ isAlreadyQuerying: true });
      const filters = isNullOrWhiteSpaces(search)
        ? []
        : [`contains(tolower(${nameof<EquipmentTypeDto>('name')}), '${search.toLowerCase()}')`];

      if (this.props.clusterId && this.state?.currentQuery?.items && this.props.clusterId === this.state.selectedClusterId) {
        this.setState({ isAlreadyQuerying: false });
        return Object.assign({}, this.state.currentQuery, {
          items: this.state.currentQuery.items.map(c => ({ id: c.id, title: c.name, cluster: { id: c.clusterId, title: c.clusterName } }))
        }) as QueryResult<EquipmentTypeItemReference>;
      }

      if (this.props.clusterId) {
        filters.push(`${nameof<EquipmentTypeDto>('clusterId')} eq ${this.props.clusterId}`);
        this.setState({ selectedClusterId: this.props.clusterId });
      } else {
        this.setState({ selectedClusterId: undefined });
      }

      if (this.props.oemId && (this.props.relatedEquipmentTypeIds || []).length > 0) {
        filters.push(`${nameof<EquipmentTypeDto>('id')} in (${(this.props.relatedEquipmentTypeIds || []).join(', ')})`);
        this.setState({ selectedOemId: this.props.oemId });
      } else {
        this.setState({ selectedOemId: undefined });
      }

      const result = await this.equipmentTypesStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<EquipmentTypeDto>('name'), useProfile: false }],
        filter: filters
      });

      this.setState({ currentQuery: this.props.clusterId ? result : undefined, isAlreadyQuerying: false });

      return Object.assign({}, result, {
        items: result.items.map(c => ({ id: c.id, title: c.name, cluster: { id: c.clusterId, title: c.clusterName } }))
      }) as QueryResult<EquipmentTypeItemReference>;
    };
    return method;
  }

  public render() {
    const val = this.state.value;
    const query = this.state.query;
    const options = this.state.options;

    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        searchable
        className={this.props.className}
        readOnly={this.props.readOnly}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        searchQuery={this.state.searchQuery}
        onQueryChange={q => this.setState({ searchQuery: q })}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        clearOnReload={this.props.clearOnReload}
        onOpenDropDown={this.onOpenDropDown}
        options={query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EquipmentTypeEditor);
