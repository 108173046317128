import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { ProfessionsStore, ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { nameof } from '../../utils/object';
import { Dropdown } from 'semantic-ui-react';

interface MultiProfessionEditorProps extends WithTranslation {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  professions?: ProfessionsStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  filteredProfessions?: string[];
  className?: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultiProfessionEditorState {
  value: string[];
  availableProfessions: { [id: string]: ProfessionDto };
  professionsOptions: { text: string; value: string }[];
}

@connect(['professions', ProfessionsStore])
class MultiProfessionsEditor extends React.Component<MultiProfessionEditorProps, MultiProfessionEditorState> {
  private get professionsStore() {
    return this.props.professions;
  }

  constructor(props: MultiProfessionEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      availableProfessions: {},
      professionsOptions: []
    };

    this.initLocations();
  }

  private async initLocations() {
    return await this.professionsStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<ProfessionDto>('profession'), useProfile: false }],
        filter: []
      })
      .then(profession => {
        const dict = {};
        const options = [];

        profession.items.forEach(p => {
          dict[p.id] = p;
          options.push({ text: p.profession, value: p.id });
        });

        this.setState({
          availableProfessions: dict,
          professionsOptions: options
        });
      });
  }

  public render() {
    const { t, className, placeholder } = this.props;
    const locationOptions = this.state.professionsOptions;

    return (
      <Dropdown
        search
        inline
        selection
        closeOnChange
        closeOnEscape
        clearable
        multiple
        options={locationOptions}
        error={!this.state.value}
        className={className ? className : 'planit-users-inputs planit-user-dropdown planit-user-selector'}
        value={this.state.value ? this.state.value : null}
        onChange={(e, data) => this.selectedLocation(data)}
        placeholder={placeholder || t('Select Role(s)')}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }

  selectedLocation(data: any) {
    this.setState({
      value: data.value
    });

    this.props.onChange(data.value);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiProfessionsEditor);
