import { BaseDto, CommandResult } from '../../types';
import { repository, AsyncAction } from 'redux-scaffolding-ts';
import { DataStore, QueryResult, Query } from '../../dataStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import i18n from 'i18n';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';

import { SsaTemplateDto } from '../templates/ssa-templates-store';
import { FunctionalExpertDto, SimpleUserDto } from './tna-forms-store';
import { AxiosResponse } from 'axios';
import { SsaQuestionBankDownloaderState } from '../questionBank/ssa-questionBank-upload-store';

export interface SsaFormUserViewModel {
  id: string;
  name: string;
}

export interface SsaFormViewModel {
  titleForm: string; //GUID
  formId: string;
  answerFormId: string;
  user: SsaFormUserViewModel;
  profile: string;
  functionalExperts: FunctionalExpertDto[];
  status: SsaFormStatus;
  categorySections: SkillSectionCategoryAnswersDto[];
  currentLanguage: string;
  assessorAnswerStatus: AssessorAnswerStatusDto[];
  ssaAssessment: SsaAssessmentItemDto;
}

export interface ReturnFormDto {
  formId: string;
  assesors: AssessorReturnDto[];
}
export interface AssessorReturnDto {
  assessorId: string;
  checked: boolean;
  comment: string;
}

export interface CategorySectionViewModel {
  name: string;
}

export interface SsaFormListDto extends BaseDto {
  id: string; //GUID
  friendlyId: string;
  title: string;
  userId: string; //GUID
  user: SimpleUserDto;
  profileId: string; //GUID
  profileName: string;
  createAt: string;
  deadline: string;
  status: SsaFormStatus;
  outOfDate: boolean;
  isStarted: boolean;
}

export type SsaFormStatus = 'Assessors' | 'Review' | 'Returned' | 'Done';

export interface SsaAssessmentItemDto {
  id: string;
  title: string;
  ssaTemplateId: string;
  ssaTemplate: SsaTemplateDto;
}

export interface SsaFormItemDto {
  id: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  ssaAssessmentId: string;
  ssaAssessment: SsaAssessmentItemDto;
  functionalExperts: FunctionalExpertDto[];
  assessorAnswerStatus: AssessorAnswerStatusDto[];
  status: SsaFormStatus;
  deadline: string;
  outOfDate: boolean;
}
export type AnswerStatus = 'Unknown' | 'Pending' | 'Returned' | 'Done';

export interface AssessorAnswerStatusDto {
  userId: string;
  answerStatus: AnswerStatus;
  comment: string;
}

export interface CreateSsaFormDto {
  title: string;
  ssaTemplateId: string;
  employees: CreateEmployeeSsaFormDto[];
  deadline: string;
}
export interface CreateEmployeeSsaFormDto {
  userId: string;
  functionalExperts: CreateFunctionalExpertDto[];
}
export interface ChangeSsaFormAnswerDto {
  id: string;
  formId: string;
  skillSectionCategoriesAnswers: ChangeSkillSectionCategoryAnswersDto[];
}

export interface ChangeSkillSectionCategoryAnswersDto {
  name: string;
  skillSections: ChangeSsaSkillAnswersDto[];
}

export interface ChangeSsaSkillAnswersDto {
  skillId: string; //GUID
  answer: number;
  strengths: string;
  areasForImprovement: string;
  recommendations: string;
  checkpoints: ChangeSsaCheckpointAnswersDto[];
}
export interface ChangeSsaCheckpointAnswersDto {
  questionAnswers: ChangeSsaQuestionAnswersDto[];
}
export interface ChangeSsaQuestionAnswersDto {
  questionId: string; //GUID
  questionsTranslations: Question[];
}
export interface Question {
  text: string;
  languageId: string; //GUID
}
export interface SsaFormAnswersDto {
  id: string; //GUID
  skillSectionCategoriesAnswers: SkillSectionCategoryAnswersDto[];
}

export interface SkillSectionCategoryAnswersDto {
  name: string;
  skillSections: SsaSkillAnswersDto[];
}

export interface SsaSkillAnswersDto {
  skillId: string; //GUID
  skillName: string;
  urlLink: string;
  answer: number;
  strengths: string;
  areasForImprovement: string;
  recommendations: string;
  checkpoints: SsaCheckpointAnswersDto[];
  skillDescription: string;
}

export interface SsaCheckpointAnswersDto {
  description: string;
  questionAnswers: SsaQuestionAnswersDto[];
}

export interface SsaQuestionAnswersDto {
  questionId: string;
  question: QuestionDto;
  questionTranslations: QuestionDto[];
}

export interface QuestionDto {
  text: string;
  languageId: string;
}

export class SsaFormListValidator extends AbstractValidator<SsaFormListDto> {}

@repository('@@SSAFORMS', 'ssa-forms.summary')
export class SsaFormListStore extends DataStore<SsaFormListDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-ssa-forms';
  retrieveAssessorsPath = 'v1/get-assessor-ssa-forms';
  updatePath = '';
  deletePath = 'v1/hard-delete-ssa-form';
  downloadTemplateUrl = 'v1/get-ssa-form-answer-excel';
  retrieveOnePath = 'v1/get-ssa-form';
  RETRIEVE_ONE_SSA_FORM = 'RETRIEVE_ONE_SSA_FORM';
  CHANGE_ANSWER_VALUES = 'CHANGE_ANSWER_VALUES';
  CHANGE_CURRENT_LANGUAGE = 'CHANGE_CURRENT_LANGUAGE';
  TEMPLATE_DOWNLOADED = 'TEMPLATE_DOWNLOADED';

  protected validate(item: SsaFormListDto) {
    return new SsaFormListValidator().validate(item as any);
  }

  constructor() {
    super('SSAFORMS', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {},
      item: {}
    });
  }

  public async getFormById(id: string): Promise<SsaFormItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_SSA_FORM,
      httpService.get<SsaFormItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getAssessorssaFormList(query: Query, data?: any): Promise<QueryResult<SsaFormListDto>> {
    const httpService = container.get(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<SsaFormListDto>>(`${this.baseUrl}/${this.retrieveAssessorsPath}?${path}`, data)
    );
    return result.data;
  }
  public async downloadTemplate({ id, friendlyId }: SsaFormListDto) {
    const url = `${this.baseUrl}/${this.downloadTemplateUrl}?FormId=${id}`;
    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, `SSA_Form_${friendlyId}.xlsx`));
  }

  private onTemplateDownloaded = (): AsyncAction<AxiosResponse<any>, SsaQuestionBankDownloaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };
}

export function toSsaFormViewModel(form: SsaFormItemDto, answers: SsaFormAnswersDto): SsaFormViewModel {
  return {
    formId: form.id,
    answerFormId: answers.id,
    titleForm: form.ssaAssessment.title,
    user: {
      id: form.userId,
      name: `${form.user.firstName} ${form.user.lastName}`
    },
    functionalExperts: form.functionalExperts,
    profile: form.ssaAssessment && form.ssaAssessment.ssaTemplate && form.ssaAssessment.ssaTemplate.profileName,
    status: form.status,
    categorySections: answers.skillSectionCategoriesAnswers || [],
    currentLanguage: null,
    assessorAnswerStatus: form.assessorAnswerStatus,
    ssaAssessment: form.ssaAssessment
  };
}

export interface CreateSsaFormDto {
  title: string;
  ssaTemplateId: string;
  machineModelsSelection: CreateMachineModelFormSelectionDto[];
  employees: CreateEmployeeSsaFormDto[];
  deadline: string;
  skillCount: number;
}

export interface CreateMachineModelFormSelectionDto {
  machineModelId: string;
  selectedMachineUnits: string[];
}

export interface CreateFunctionalExpertDto {
  userId: string;
  skills: string[];
}

export class CreateFunctionalExpertDtoValidator extends ExtendedAbstractValidator<CreateFunctionalExpertDto> {
  constructor(idx: number, idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1} Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.userId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfIterable(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Skills info are required')}`);

    this.validateIfEachString(x => x.skills)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.skills != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}

export class CreateEmployeeSsaFormDtoValidator extends ExtendedAbstractValidator<CreateEmployeeSsaFormDto> {
  constructor(idx: number, skillCount: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    // const preffix = i18n.t(`Employee at position ${idx + 1}`);
    this.validateIf(x => x);
  }
}

export class CreateMachineModelFormSelectionDtoValidator extends ExtendedAbstractValidator<CreateMachineModelFormSelectionDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Machine model selection at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.machineModelId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Machine model info is wrong')}`);

    this.validateIfIterable(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit is required')}`);

    this.validateIfEachString(x => x.selectedMachineUnits)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null && x.selectedMachineUnits != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one machine unit info is wrong')}`);
  }
}

export class CreateSsaFormValidator extends ExtendedAbstractValidator<CreateSsaFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('ssa Title is required'));

    this.validateIfString(x => x.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline) && moment(x.deadline).isValid())
      .withFailureMessage(i18n.t('Deadline date cant be in the past'));

    this.validateIfString(x => x.ssaTemplateId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('ssa Template is required'));

    this.validateIfNumber(x => x.skillCount)
      .isDefined()
      .isNotNull()
      .isGreaterThan(0)
      .when(x => x != null && !isNullOrWhiteSpaces(x.ssaTemplateId))
      .withFailureMessage(i18n.t('Template has no skills'));

    this.validateIfIterable(x => x.machineModelsSelection)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Machine model selection info is required'));

    this.validateIf(x => x.machineModelsSelection)
      .fulfills(x =>
        (x || []).all((i, idx) => new CreateMachineModelFormSelectionDtoValidator(idx, this.addErrors).extendValidate(i).isValid())
      )
      .when(x => x != null && (x.machineModelsSelection || []).length !== 0)
      .withFailureMessage(i18n.t('Machine model selection info is required'));

    this.validateIfIterable(x => x.employees)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Employees and Assesors info is required'));

    this.validateIf(x => x)
      .fulfills(x =>
        (x.employees || []).all((i, idx) =>
          new CreateEmployeeSsaFormDtoValidator(idx, x.skillCount, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => x != null && (x.employees || []).length !== 0 && x.skillCount > 0)
      .withFailureMessage(i18n.t('Employees and Assesors info is wrong'));
  }
}

@repository('@@SSAFORMLISTS', 'ssa-forms.new')
export class NewSsaFormStore extends FormStore<CreateSsaFormDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-ssa-forms';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateSsaFormDto) {
    return new CreateSsaFormValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_ssaTEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

export class SsaFormViewModelValidator extends ExtendedAbstractValidator<SsaFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x.categorySections)
      .isNotEmpty()
      // .fulfills(x => (x || []).all((i, idx) => new SsaFormCategoriesSectionValidator(i.name, this.addErrors).extendValidate(i).isValid()))
      .withFailureMessage('CATEGORY SECTIONS');
  }
}

@repository('@@SSAFORMLISTS', 'ssa-forms.form-wizard')
export class SsaFormStore extends FormStore<SsaFormViewModel> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-ssa-form-answers';
  updatePath = 'v1/save-ssa-form-answers';
  sendPath = 'v1/send-ssa-form-answers';
  returnPath = 'v1/return-ssa-form'; //PUT
  deletePath = '';
  RETRIEVE_ONE_ANSWERS = 'RETRIEVE_ONE_ANSWERS';
  CHANGE_CURRENT_LANGUAGE = 'CHANGE_CURRENT_LANGUAGE';
  CHANGE_ANSWER_VALUES = 'CHANGE_ANSWER_VALUES';
  public EXCEL_EXPORTED = 'EXCEL_EXPORTED';
  exportToExcelPath = 'v1/export-ssa-form';

  protected validate(item: SsaFormViewModel) {
    return new SsaFormViewModelValidator().extendValidate(item as any);
  }

  constructor() {
    super('CHANGEANSWERTNAFORMLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });

    this.addReducer(
      this.RETRIEVE_ONE_ANSWERS,
      () => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: (value: any) => {
            return {
              ...this.state,
              isBusy: false,
              count: value.data.count
            };
          },
          onError: (error: any) => ({
            ...this.state,
            isBusy: false,
            result:
              error && error.response && error.response.data && error.response.data.messages
                ? error.response.data
                : {
                    isSuccess: false,
                    items: [],
                    count: 0,
                    messages: [{ body: error.message || error, level: 'Error' }]
                  }
          })
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.CHANGE_ANSWER_VALUES,
      (categorySections: SsaFormAnswersDto) => {
        return {
          ...this.state,
          item: { ...this.state.item, answers: { ...this.state.item.categorySections, ...categorySections } }
        };
      },
      'Simple'
    );

    this.addReducer(
      this.CHANGE_CURRENT_LANGUAGE,
      (currentLanguage: string) => {
        return {
          ...this.state,
          item: { ...this.state.item, currentLanguage }
        };
      },
      'Simple'
    );
  }

  public changeCurrentLanguage = (language: string) => {
    this.dispatch(this.CHANGE_CURRENT_LANGUAGE, language);
  };

  public async updateSsaForm() {
    const httpService = container.get(HttpService);
    const { item } = this.state;
    const validation = this.validate(item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const changeSsaFormAnswerDto = this.toChangeSsaFormAnswerDto();
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<ChangeSsaFormAnswerDto, CommandResult<ChangeSsaFormAnswerDto>>(
        `${this.baseUrl}/${this.updatePath}`,
        changeSsaFormAnswerDto
      )
    );
    return result.data;
  }

  public async sendSsaForm() {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const changeSsaFormAnswerDto = this.toChangeSsaFormAnswerDto();
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<ChangeSsaFormAnswerDto, CommandResult<ChangeSsaFormAnswerDto>>(
        `${this.baseUrl}/${this.sendPath}`,
        changeSsaFormAnswerDto
      )
    );
    return result.data;
  }

  public async returnCase(item: ReturnFormDto) {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.returnPath,
      httpService.put<ReturnFormDto, CommandResult<ReturnFormDto>>(`${this.baseUrl}/${this.returnPath}`, item)
    );
    return result.data;
  }

  toChangeSsaFormAnswerDto = (): ChangeSsaFormAnswerDto => {
    const { answerFormId: id, formId, categorySections } = this.state.item;
    return { id, formId, skillSectionCategoriesAnswers: (categorySections || []).map(this.toChangeCategoriesDto) };
  };

  toChangeCategoriesDto = ({ name, skillSections }: SkillSectionCategoryAnswersDto): ChangeSkillSectionCategoryAnswersDto => ({
    name,
    skillSections: (skillSections || []).map(this.toChangeSkillsSectionsDto)
  });

  toChangeSkillsSectionsDto = ({
    answer,
    checkpoints,
    skillName: _0,
    urlLink: _1,
    ...rest
  }: SsaSkillAnswersDto): ChangeSsaSkillAnswersDto => ({
    ...rest,
    answer: answer === 5 ? -1 : answer,
    checkpoints: (checkpoints || []).map(this.toChangeCheckpointsDto)
  });

  toChangeCheckpointsDto = ({ questionAnswers }: SsaCheckpointAnswersDto): ChangeSsaCheckpointAnswersDto => ({
    questionAnswers: (questionAnswers || []).map(this.toChangeQuestionSectionDto)
  });

  toChangeQuestionSectionDto = ({ questionId, questionTranslations }: SsaQuestionAnswersDto): ChangeSsaQuestionAnswersDto => ({
    questionId,
    questionsTranslations: questionTranslations.map(this.toChangeQuestionDto)
  });

  toChangeQuestionDto = (item: QuestionDto): Question => ({ ...item });

  public async initFromSsaFormAnswer(ssaFormId: string): Promise<SsaFormViewModel> {
    const httpService = container.get(HttpService);
    const answers = await this.dispatchAsync(
      this.RETRIEVE_ONE_ANSWERS,
      httpService.get<SsaFormAnswersDto>(`${this.baseUrl}/${this.retrievePath}/${ssaFormId}`)
    );

    const assessment = await this.dispatchAsync(
      this.RETRIEVE_ONE_ANSWERS,
      httpService.get<SsaFormItemDto>(`${this.baseUrl}/v1/get-ssa-form/${ssaFormId}`)
    );

    const viewModel = toSsaFormViewModel(assessment.data, answers.data);
    this.change(viewModel);

    return viewModel;
  }

  public async exportToExcel(id: string) {
    const httpService = container.get(HttpService);
    return await this.dispatchAsync(
      this.EXCEL_EXPORTED,
      httpService.download(`${this.baseUrl}/${this.exportToExcelPath}/${id}`, `SSA-${id}`, null, this.state.filters)
    );
  }
}
