import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from '../../stores/dataStore';
import { UserStore, UserDto } from '../../stores/users/users-store';
import { SelectionInput } from '../form/selectionInput';
import { ItemReference } from '../../stores/dataStore';
import { InstructorStore } from 'stores/instructors/instructors-store';
import { PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';

export interface InstructorReference extends ItemReference {
  location: ItemReference;
}
interface InstructorEditorProps extends WithTranslation {
  value: ItemReference;
  role: string;
  onChange: (value: InstructorReference) => void;
  className?: string;
  placeholder?: string;
  users?: UserStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  readOnly?: boolean;
  location?: ItemReference;
  clearable?: boolean;
  instructor?: InstructorStore;
  activeOnly?: boolean;
  onlyInstructorsPlannerTFT?: boolean;
  onlyInstructorsPlannerMTC?: boolean;
}

interface InstructorEditorState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
}

@connect(['users', UserStore], ['instructor', InstructorStore])
class InstructorEditor extends React.Component<InstructorEditorProps, InstructorEditorState> {
  private get usersStore() {
    return this.props.users;
  }

  private get instructorStore() {
    return this.props.instructor;
  }

  constructor(props: InstructorEditorProps) {
    super(props);
    let query;
    if (props.location && props.location.id) {
      query = this.getUserMethod();
    } else {
      query = this.getAllInstructorsMethod();
    }
    this.state = {
      query: query
    };
  }
  componentDidUpdate(prevProps: InstructorEditorProps) {
    if (this.props.location !== prevProps.location) {
      this.props.location && this.props.location.id
        ? this.setState({
            query: this.getUserMethod(prevProps)
          })
        : this.setState({
            query: this.getAllInstructorsMethod()
          });
    }
  }
  @autobind
  private getUserMethod(prevProps?: InstructorEditorProps) {
    const method = async (search: string) => {
      const result = await this.usersStore.getUsersbyLocationAndRoleAsync(
        this.props.location ? this.props.location.id : '',
        this.props.role,
        this.props.activeOnly,
        {
          searchQuery: '',
          orderBy: [{ direction: 'Ascending', field: 'name', useProfile: false }],
          skip: 0,
          take: 100000
        }
      );
      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.id,
          title: c.firstName + ' ' + c.lastName,
          location: this.getLocationInstructor(c),
          pillar: c.pillar
        }))
      }) as QueryResult<ItemReference>;
    };
    return method;
  }

  private getAllInstructorsMethod() {
    const method = async (search: string) => {
      const filter = [];
      if (this.props.activeOnly != null) filter.push({ Enabled: !!this.props.activeOnly });
      const result = await this.instructorStore.getAllAsync({
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: 'name', useProfile: false }],
        skip: 0,
        take: 100000,
        filter
      });

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.userId,
          title: c.name + ' ' + c.surname,
          location: { title: c.location ? c.location.location : '', id: c.locationId },
          pillar: c.pillar
        }))
      }) as QueryResult<ItemReference>;
    };
    return method;
  }

  private getLocationInstructor(user: UserDto) {
    let result = { title: '', id: '' };

    if (user && user.roles) {
      let role = user.roles.filter(rol => rol.role.name === 'Instructor').firstOrDefault();

      if (role && role.location) {
        result = { title: role.location.location, id: role.location.id };
      }
    }

    return result;
  }

  public render() {
    const query = this.state.query;
    let isDisabled: (item: any) => boolean = undefined;

    if (!!this.props.onlyInstructorsPlannerTFT || !!this.props.onlyInstructorsPlannerMTC) {
      if (this.props.onlyInstructorsPlannerTFT) {
        isDisabled = item => !(item.pillar && (item.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !item.pillar.managedBy));
      }

      if (this.props.onlyInstructorsPlannerMTC) {
        isDisabled = item => !(item.pillar && (item.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !item.pillar.managedBy));
      }
    }

    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        searchable
        className={this.props.className}
        readOnly={this.props.readOnly}
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={this.props.value}
        onChange={value => this.props.onChange(value as any)}
        clearable={this.props.clearable}
        isDisabled={isDisabled}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(InstructorEditor);
