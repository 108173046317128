import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { getOptionsFromValue, QueryResult } from 'stores/dataStore';
import { NMTrainingNameDto, NMTrainingNamesStore } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { DropdownProps, SelectionInput, SelectionItem } from 'widgets/form/selectionInput';
import { nameof } from 'utils/object';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface NMTrainingNameEditorProps extends DropdownProps, WithTranslation {
  value: ItemReference;
  onChange: (value: ItemReference) => void;
  placeholder?: string;
  trainingNames?: NMTrainingNamesStore;
  nullable?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  className?: string;
  readOnly?: boolean;
  clearable?: boolean;
  funcionalAreaId?: string;
  clearOnReload?: boolean;
  loadDataOnOpen?: boolean;
}

interface NMTrainingNameEditorState {
  value: ItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  options: SelectionItem[];
}

@connect(['trainingNames', NMTrainingNamesStore])
class NMTrainingNameEditor extends React.Component<NMTrainingNameEditorProps, NMTrainingNameEditorState> {
  private get trainingNamesStore() {
    return this.props.trainingNames;
  }

  constructor(props: NMTrainingNameEditorProps) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.props.loadDataOnOpen ? null : this.getNMTrainingNameMethod(),
      options: getOptionsFromValue(this.state?.query, this.props.value)
    };
  }

  componentDidUpdate({ value: newValue, funcionalAreaId: prevFuncional }: NMTrainingNameEditorProps) {
    const { value, funcionalAreaId, reloadOnChange } = this.props;
    let newChanges: any = {};
    if (prevFuncional !== funcionalAreaId) newChanges.query = this.getNMTrainingNameMethod();
    if (value?.id !== newValue?.id) {
      if (reloadOnChange) newChanges.query = this.getNMTrainingNameMethod();
      newChanges.value = value;
      newChanges.options = getOptionsFromValue(newChanges.query, value);
    }
    Object.keys(newChanges).length > 0 && this.setState(newChanges);
  }

  @autobind
  private getNMTrainingNameMethod() {
    const method = async (search: string) => {
      const filters = isNullOrWhiteSpaces(search)
        ? []
        : [`startswith(tolower(${nameof<NMTrainingNameDto>('name')}), '${search.toLowerCase()}')`];
      if (this.props.funcionalAreaId) {
        filters.push(`${nameof<NMTrainingNameDto>('functionalAreaId')} eq ${this.props.funcionalAreaId}`);
      }
      const result = await this.trainingNamesStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<NMTrainingNameDto>('name'), useProfile: false }],
        filter: filters
      });
      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  onOpenDropDown = () => {
    if (this.props.loadDataOnOpen && !this.state.query) {
      this.setState({ query: this.getNMTrainingNameMethod() });
    }
  };

  public render() {
    const val = this.state.value;
    const options = this.state.options;
    const query = this.state.query;

    return (
      <SelectionInput
        content={item => <div>{item.title}</div>}
        searchable
        minWidth={this.props.minWidth}
        nullable={this.props.nullable}
        placeholder={this.props.placeholder}
        query={query}
        value={val}
        onChange={value => this.props.onChange(value as any)}
        className={!this.props.className ? undefined : this.props.className}
        readOnly={this.props.readOnly}
        clearable={this.props.clearable}
        clearOnReload={this.props.clearOnReload}
        onOpenDropDown={this.onOpenDropDown}
        options={this.state.query != null ? null : options}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NMTrainingNameEditor);
