import React, { FC, Fragment, memo, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  IwsSkillAnswerDto,
  TemplateSectionAnswersDto,
  IwsFormItemDto,
  QualifierDto,
  QualifierTemplateDto
} from 'stores/assessments/forms/iws-forms-store';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import { IWSFormContext } from './iws-edit-form';

interface IwsFormTableScoreProps extends WithTranslation {
  scoreTitle?: string;
  section: TemplateSectionAnswersDto;
  onRecommendationChange: (value: string, id: string, templateId?: string) => void;
}

const IwsFormTableScore: FC<IwsFormTableScoreProps> = ({ t, section, scoreTitle, onRecommendationChange }) => {
  const {
    getAssessorType,
    answerHandler,
    getAStatus,
    recommendationHandler,
    submittable,
    copyAnswersFromMachinesWithSameTemplate,
    ...contextValues
  } = useContext(IWSFormContext);

  const { isAdminOrPoC, isAssessor, isEmployee, userId } = contextValues;
  const status = useSelector<ApplicationState>(({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).status) as string;
  const iwsFormQualifiers = useSelector<ApplicationState>(
    ({ iwsformlist: { summary } }) => (summary.item as IwsFormItemDto).qualifiers || []
  ) as QualifierDto[];
  const isEmployeeStatus = status === 'FirstAssesment' || status === 'SecondAssesment';
  const assessorReadOnlyMode = isAssessor && isEmployeeStatus;
  const assessorAnswerStatus = getAStatus();

  const getTableSection = () => {
    let iwsSkillAnswers: IwsSkillAnswerDto[] = [];

    if (
      !(
        status === 'Done' ||
        isAdminOrPoC ||
        assessorReadOnlyMode ||
        isEmployee /* && status !== 'FirstAssesment'*/ ||
        (!isAdminOrPoC && isAssessor && assessorAnswerStatus?.answerStatus === 'Done')
      )
    ) {
      const iwsFormQualifiersTemplates = ((iwsFormQualifiers.filter(x => x.userId === userId).selectMany(x => x.templates) ||
        []) as QualifierTemplateDto[]).map(a => a.templateId);
      iwsSkillAnswers = section.iwsSkillAnswers?.filter(x => iwsFormQualifiersTemplates.includes(x.templateId));
    } else {
      iwsSkillAnswers = section.iwsSkillAnswers;
    }

    return ((iwsSkillAnswers || []) as IwsSkillAnswerDto[]).map(
      ({ average, skillId, skillName, qualifierRecomendation, templateId, ...s }) => (
        <Fragment key={templateId + skillId}>
          <p>{t(skillName)}</p>
          <p>{average?.firstEmployeeAverage != null ? Number(average.firstEmployeeAverage).toFixed(1) : t('n/a')}</p>
          <p>{average?.secondEmployeeAverage != null ? Number(average.secondEmployeeAverage).toFixed(1) : t('n/a')}</p>
          <p>{average?.qualifierAverage != null ? Number(average.qualifierAverage).toFixed(1) : t('n/a')}</p>
          {!(s as any).isTotal && (
            <input
              value={qualifierRecomendation || ''}
              onChange={({ target: { value } }) => {
                onRecommendationChange(value, skillId, templateId);
              }}
            />
          )}
        </Fragment>
      )
    );
  };

  const className = 'machine-related__score-by-skills-table';
  return (
    section &&
    section.iwsSkillAnswers &&
    section.iwsSkillAnswers.length > 0 && (
      <div className={className}>
        <h2>{t(scoreTitle)}</h2>
        <p>{t('1st Employee Self-Evaluation')}</p>
        <p>{t('2nd Employee Self-Evaluation')}</p>
        <p>{t('Qualifier Evaluation')}</p>
        <p>{t('Recommendations from Qualifier')}</p>
        {getTableSection()}
        <h3> </h3>
        <p className="template_comment">{t(section.employeeFirstAssessmentComment)}</p>
        <p className="template_comment">{t(section.employeeSecondAssessmentComment)}</p>
        <p className="template_comment">{t(section.qualifierComment)}</p>
        <h3> </h3>
      </div>
    )
  );
};

export default withTranslation()(memo(IwsFormTableScore));
