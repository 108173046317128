import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { LanguageDto, ChangeLanguageDto, ChangeLanguageStore } from 'stores/configuration/locations/languages-store';
import { nameof } from 'utils/object';

interface ChangeLanguageViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeLanguage?: ChangeLanguageStore;
  currentDto: LanguageDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeLanguageViewState {
  editorValue: any;
}

@connect(['changeLanguage', ChangeLanguageStore])
class ChangeLanguageView extends React.Component<ChangeLanguageViewProps, ChangeLanguageViewState> {
  private get changeLanguageStore() {
    return this.props.changeLanguage;
  }

  constructor(props: ChangeLanguageViewProps) {
    super(props);

    this.changeLanguageStore.state.result = null;

    this.changeLanguageStore.change({
      id: this.props.currentDto.id,
      language: this.props.currentDto.language,
      languageCode: this.props.currentDto.languageCode,
      hasTnaAvailable: this.props.currentDto.hasTnaAvailable
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeLanguageViewProps) {
    if (this.changeLanguageStore.state.result && this.changeLanguageStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeLanguageStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeLanguageStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeLanguageStore.change({ ...this.changeLanguageStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeLanguageStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Language')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeLanguageStore.state.result && !this.changeLanguageStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeLanguageStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeLanguageStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeLanguageStore.state.item.language}
                    label={t('Language')}
                    placeholder={t('Name')}
                    value={this.changeLanguageStore.state.item.language}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeLanguageDto>('language'), value)}
                  />
                  <Form.Input
                    required
                    fluid
                    error={!this.changeLanguageStore.state.item.languageCode}
                    label={t('Code')}
                    placeholder={t('Code')}
                    value={this.changeLanguageStore.state.item.languageCode}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeLanguageDto>('languageCode'), value)}
                  />
                  <Form.Checkbox
                    style={{ top: 25 }}
                    toggle
                    checked={this.changeLanguageStore.state.item.hasTnaAvailable}
                    label={t('Available for tests')}
                    placeholder={t('Available for tests')}
                    onChange={(e, { checked }) => {
                      this.handleValue(nameof<ChangeLanguageDto>('hasTnaAvailable'), checked);
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeLanguageView);
