import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { Category } from './clusters-store';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface NMTrainingNameDto extends BaseDto {
  id: string;
  name: string;
  functionalAreaId: string;
  functionalAreaName: string;
  clusterId: string;
  clusterName: string;
  category: Category;
}

export interface CreateNMTrainingNameDto {
  name: string;
  functionalAreaId: string;
}

export interface ChangeNMTrainingNameDto {
  id: string;
  name: string;
  functionalAreaId: string;
}

export class CreateNMTrainingNameValidator extends AbstractValidator<CreateNMTrainingNameDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Training Name name is required'));

    this.validateIfString(o => o.functionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Functional Area is required'));
  }
}

export class ChangeNMTrainingNameValidator extends AbstractValidator<ChangeNMTrainingNameDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Training Name is required'));

    this.validateIfString(o => o.functionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Functional Area is required'));
  }
}

@repository('@@NMTRAININGNAMES', 'nmTrainingNames.summary')
export class NMTrainingNamesStore extends DataStore<NMTrainingNameDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmTrainingName';
  retrievePath = 'get-nmTrainingNames';
  updatePath = 'update-nmTrainingName';
  deletePath = 'delete-nmTrainingName';

  protected validate(item: NMTrainingNameDto) {
    return new ChangeNMTrainingNameValidator().validate(item);
  }

  constructor() {
    super('NMTRAININGNAME', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMTRAININGNAMES', 'nmTrainingNames.dropdown')
export class DropDownNMTrainingNamesStore extends DataStore<NMTrainingNameDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-nmTrainingNames';
  updatePath = '';
  deletePath = '';

  protected validate(item: NMTrainingNameDto) {
    return new ChangeNMTrainingNameValidator().validate(item);
  }

  constructor() {
    super('NMTRAININGNAMEDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, nmTrainingNameIds?: string[], search?: string): Promise<QueryResult<NMTrainingNameDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let nmTrainingNamesDto = this.state.items.map(({ item }) => item);

      if ((nmTrainingNameIds || []).length > 0) {
        nmTrainingNamesDto = nmTrainingNamesDto.filter(x => nmTrainingNameIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        nmTrainingNamesDto = nmTrainingNamesDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: nmTrainingNamesDto, count: nmTrainingNamesDto.length } as QueryResult<NMTrainingNameDto>;
      return new Promise<QueryResult<NMTrainingNameDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<NMTrainingNameDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<NMTrainingNameDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@NMTRAININGNAMES', 'nmTrainingNames.new')
export class NewNMTrainingNameStore extends FormStore<CreateNMTrainingNameDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmTrainingName';
  retrievePath = 'get-nmTrainingNames';
  updatePath = 'update-nmTrainingName';

  protected validate(item: CreateNMTrainingNameDto) {
    return new CreateNMTrainingNameValidator().validate(item);
  }

  constructor() {
    super('NEW_NMTRAININGNAME', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMTRAININGNAMES', 'nmTrainingNames.change')
export class ChangeNMTrainingNameStore extends FormStore<ChangeNMTrainingNameDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmTrainingName';
  retrievePath = 'get-nmTrainingNames';
  updatePath = 'update-nmTrainingName';

  protected validate(item: ChangeNMTrainingNameDto) {
    return new ChangeNMTrainingNameValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMTRAININGNAME', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
