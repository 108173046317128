import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';

import {
  ChangeMrHoursDurationDto,
  ChangeMrHoursDurationStore,
  MrHoursDurationDto
} from 'stores/configuration/events-n-requests/mr-hours-durations-store';
import { nameof } from '../../../../../utils/object';

interface ChangeMrHoursDurationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeMrHoursDuration?: ChangeMrHoursDurationStore;
  currentMU: MrHoursDurationDto;
}

interface ChangeMrHoursDurationViewState {
  userClickedSaved: boolean;
}

@connect(['changeMrHoursDuration', ChangeMrHoursDurationStore])
class ChangeMrHoursDurationView extends React.Component<ChangeMrHoursDurationViewProps, ChangeMrHoursDurationViewState> {
  private get changeMrHoursDurationStore() {
    return this.props.changeMrHoursDuration;
  }

  constructor(props: ChangeMrHoursDurationViewProps) {
    super(props);

    this.state = {
      userClickedSaved: false
    };

    this.changeMrHoursDurationStore.state.result = null;

    this.changeMrHoursDurationStore.change({
      id: this.props.currentMU.id,
      hoursDuration: this.props.currentMU.hoursDuration
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeMrHoursDurationViewProps) {
    if (this.changeMrHoursDurationStore.state.result && this.changeMrHoursDurationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    } else {
      document.getElementById('hours-input').focus();
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeMrHoursDurationStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeMrHoursDurationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeMrHoursDurationStore.change({ ...this.changeMrHoursDurationStore.state.item, ...change });
  }

  public render() {
    const { t, currentMU } = this.props;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeMrHoursDurationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New MR Hours Duration')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeMrHoursDurationStore.state.result && !this.changeMrHoursDurationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeMrHoursDurationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeMrHoursDurationStore.state.item && (
              <Form>
                <Form.Group widths={3}>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Event Type')}</label>
                      <Input disabled value={currentMU.eventTypeName}></Input>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Cluster')}</label>
                      <Input disabled value={currentMU.clusterName}></Input>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Equipment Type')}</label>
                      <Input disabled value={currentMU.equipmentTypeName}></Input>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('OEM')}</label>
                      <Input disabled value={currentMU.oemName}></Input>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Machine Model')}</label>
                      <Input disabled value={currentMU.machineModelName}></Input>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Machine Unit')}</label>
                      <Input disabled value={currentMU.machineUnitName || '[ENTIRE MACHINE]'}></Input>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Role')}</label>
                      <Input disabled value={currentMU.professionName || '[ANY]'}></Input>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Training Level')}</label>
                      <Input disabled value={currentMU.trainingLevelName || '[ANY]'}></Input>
                    </div>
                  </Form.Field>
                  <Form.Input
                    type="number"
                    focus={true}
                    required
                    fluid
                    id="hours-input"
                    error={this.changeMrHoursDurationStore.state.item.hoursDuration <= 0 && this.state.userClickedSaved}
                    label={t('Hours Duration')}
                    placeholder={t('Hours Duration')}
                    value={this.changeMrHoursDurationStore.state.item.hoursDuration}
                    onChange={(e, { value }) =>
                      this.handleValue(nameof<ChangeMrHoursDurationDto>('hoursDuration'), Math.abs(parseInt(value) || 0))
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeMrHoursDurationView);
