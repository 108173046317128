import React, { FunctionComponent } from 'react';
import { MoneyInput, MoneyInputProps } from 'widgets/form/moneyInput';
import { Icon } from 'semantic-ui-react';
export interface InputCostProps extends MoneyInputProps {
  className?: string;
  disabledLayout?: boolean;
  currencyIcon?: 'dollar sign' | 'dollar' | 'euro' | 'yen' | 'yen sign' | 'pound sign' | 'pound' | 'euro sign';
  hideIcon?: boolean;
}

const InputCostComponent: FunctionComponent<InputCostProps> = ({
  value,
  onChange,
  currencyChar,
  allowNegative,
  className,
  currencyIcon,
  disabledLayout,
  disabled,
  hideIcon,
  decimals,
  ...rest
}) => {
  let defaultClass = 'cost-component';
  if (className) defaultClass += ` ${className}`;
  if (disabledLayout) defaultClass += ' disabled';
  return (
    <div className={defaultClass}>
      <MoneyInput
        allowNegative={false}
        currencyChar={null}
        style={{ border: 'none' }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        decimals={decimals}
        {...rest}
      />
      {!hideIcon && <Icon name={currencyIcon || 'dollar sign'} />}
    </div>
  );
};

export default InputCostComponent;
