import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore } from 'stores/dataStore';

export interface FlightCostDto extends BaseDto {
  id: string;
  name: string;
}

export class FlightCostValidator extends AbstractValidator<FlightCostDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

@repository('@@FLIGHTCOSTS', 'flightcosts.summary')
export class FlightCostStore extends DataStore<FlightCostDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-flight-costs';
  updatePath = '';
  deletePath = '';

  protected validate(item: FlightCostDto) {
    return new FlightCostValidator().validate(item);
  }

  constructor() {
    super('FLIGHTCOST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
