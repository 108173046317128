import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NMTrainingNameDto,
  ChangeNMTrainingNameDto,
  ChangeNMTrainingNameStore
} from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import { formStoreHandler, isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Categories } from 'stores/requests/requests-store';

interface ChangeNMTrainingNameViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeNMTrainingName?: ChangeNMTrainingNameStore;
  currentDto: NMTrainingNameDto;
}

interface ChangeNMTrainingNameViewState {
  functionalArea: ItemReference;
  cluster: ItemReference;
  category: Categories;
}

@connect(['changeNMTrainingName', ChangeNMTrainingNameStore])
class ChangeNMTrainingNameView extends Component<ChangeNMTrainingNameViewProps, ChangeNMTrainingNameViewState> {
  constructor(props: ChangeNMTrainingNameViewProps) {
    super(props);
    const { changeNMTrainingName, currentDto } = this.props;
    const { category, name, id, clusterId, clusterName, functionalAreaId, functionalAreaName } = currentDto;
    changeNMTrainingName.state.result = null;

    changeNMTrainingName.change({ id, name, functionalAreaId });

    this.state = {
      category: category === 'Unknown' ? Categories.Technical : Categories[category],
      functionalArea: { id: functionalAreaId, title: functionalAreaName },
      cluster: { id: clusterId, title: clusterName }
    };
  }

  private onChangeItem = async () => {
    const result = await this.props.changeNMTrainingName.update();
    result?.isSuccess && this.props.onClose(true);
  };

  private onCancelChangeItem = () => {
    this.props.changeNMTrainingName.clear();
    this.props.onClose(false);
  };

  private onChangeCluster = (cluster: ItemReference) => {
    this.props.changeNMTrainingName.change({ functionalAreaId: null });
    this.setState({ cluster, functionalArea: null });
  };

  categoryChangeHandler = (category: Categories) => this.setState({ category });
  public render() {
    const { category, functionalArea, cluster } = this.state;
    const { t, changeNMTrainingName } = this.props;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={changeNMTrainingName.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Training Name')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {changeNMTrainingName.state.result && !changeNMTrainingName.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={changeNMTrainingName.state.result.messages.map(o => o.body)}
              />
            )}
            {changeNMTrainingName.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!changeNMTrainingName.state.item.name}
                    label={t('Training Name')}
                    placeholder={t('Name')}
                    value={changeNMTrainingName.state.item.name}
                    onChange={(_, { value }) => formStoreHandler<ChangeNMTrainingNameDto>(changeNMTrainingName, 'name', value)}
                  />
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        placeholder={t('Cluster')}
                        nullable
                        category={category}
                        value={cluster}
                        onChange={this.onChangeCluster}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div
                      className={`required ${
                        this.props.changeNMTrainingName.state.item.functionalAreaId == null ||
                        isNullOrWhiteSpaces(changeNMTrainingName.state.item.functionalAreaId)
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Functional Area')}</label>
                      <NMFunctionalAreaEditor
                        nullable
                        readonly={!cluster}
                        clusterId={cluster?.id}
                        value={functionalArea}
                        onChange={c => formStoreHandler<ChangeNMTrainingNameDto>(changeNMTrainingName, 'functionalAreaId', c?.id)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <div className="custom-actions">
          <Form.Field>
            <label>{t('Category')}</label>
            <CategoryFormEditor
              placeholder={t('Filter by Category')}
              className="form__header__dropdown-style"
              value={category}
              onChange={this.categoryChangeHandler}
            />
          </Form.Field>
          <Modal.Actions>
            <Button basic onClick={this.onCancelChangeItem}>
              {t('Cancel')}
            </Button>
            <Button onClick={this.onChangeItem} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeNMTrainingNameView);
