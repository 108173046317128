import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Icon, Checkbox, Input } from 'semantic-ui-react';
import './request-support-details.style.less';
import CostsComponent from 'widgets/form/costs.component';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import PeriodInput from 'widgets/form/period-input';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateTimePeriod } from 'stores/events/events-store';
import './request-support-details.style.less';
import { SupportDetails } from 'stores/requests/requests-store';
import { DurationInput } from 'widgets/form/durationInput';

interface RequestSupportDetailsProps extends WithTranslation {
  isForm?: boolean;
  readOnly: boolean;
  onChange: (values) => void;
  supportDetails: SupportDetails;
  startDate: string;
  endDate: string;
}

const defaultSupportPosition = {
  locationId: null,
  location: null,
  estimatedCost: 0
};
class RequestSupportDetails extends Component<RequestSupportDetailsProps> {
  getRawSupportPosition = () => {
    const { startDate, endDate } = this.props;
    return {
      comment: '',
      duration: '',
      endDate: endDate,
      startDate: startDate,
      locationA: { estimatedCost: 0, location: null, locationId: null },
      locationB: { estimatedCost: 0, location: null, locationId: null },
      locationC: { estimatedCost: 0, location: null, locationId: null },
      locationD: { estimatedCost: 0, location: null, locationId: null },
      positionCode: null,
      positionCodeName: null,
      requestedHC: 0
    };
  };

  handleNewSupportPosition = () => {
    const supportDetails = this.props.supportDetails;
    let newSupportPosition;
    if ((supportDetails.supportPositions || []).length <= 0) {
      newSupportPosition = { ...this.getRawSupportPosition() };
    } else {
      const defaultPosition = supportDetails.supportPositions[0];
      newSupportPosition = {
        ...this.getRawSupportPosition(),
        locationA: { ...defaultPosition.locationA, estimatedCost: 0 },
        locationB: { ...defaultPosition.locationB, estimatedCost: 0 },
        locationC: { ...defaultPosition.locationC, estimatedCost: 0 },
        locationD: { ...defaultPosition.locationD, estimatedCost: 0 }
      };
    }
    this.props.onChange({ supportPositions: [...supportDetails.supportPositions, newSupportPosition] });
  };

  removeSupportPosition = (index: number) => {
    const supportPositions = this.props.supportDetails.supportPositions.filter((_, i) => i !== index);
    this.props.onChange({ supportPositions });
  };

  positionCodeEditorChange = (property: string, value: any, index: number) => {
    const supportPositions = this.props.supportDetails.supportPositions.map((supportPos, idx) => {
      if (idx === index) {
        supportPos = { ...supportPos, [property]: value ? value.id : value };
      }
      return supportPos;
    });
    this.props.onChange({ supportPositions });
  };

  locationsEditorChange = (location: string, value: any) => {
    const supportDetails = this.props.supportDetails;
    const supportPositions = supportDetails.supportPositions.map(supportPos => {
      if (supportPos[location])
        supportPos = {
          ...supportPos,
          [location]: {
            ...supportPos[location],
            locationId: value ? value.id : value,
            estimatedCost: !value ? 0 : supportPos[location].estimatedCost
          }
        };
      else supportPos[location] = { locationId: value ? value.id : value, location: null, estimatedCost: 0 };
      return supportPos;
    });
    const changes = { supportPositions };
    const changeTotal = 'totalCost' + this.capitalize(location);
    let total = 0;
    if (value != null) {
      total = supportPositions.reduce((prevVal, acc) => prevVal + acc[location].estimatedCost, 0);
      changes[changeTotal] = total;
    }
    this.props.onChange({ supportPositions });
    this.props.onChange({ [changeTotal]: total });
  };

  supplyingHandler = (location: string, value: number, supportPosIdx: number) => {
    const supportDetails = this.props.supportDetails;
    const supportPositions = supportDetails.supportPositions.map((supportPos, i) => {
      if (supportPosIdx === i) supportPos = { ...supportPos, [location]: { ...supportPos[location], estimatedCost: value } };
      return supportPos;
    });
    const total = supportPositions.reduce((prevVal, acc) => prevVal + acc[location].estimatedCost, 0);
    const changeTotal = 'totalCost' + this.capitalize(location);
    this.props.onChange({ supportPositions });
    this.props.onChange({ [changeTotal]: total });
  };

  capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  inputChangeHandler = (property: string, idx: number, value: string) => {
    const supportPositions = this.props.supportDetails.supportPositions.map((supportPos, i) => {
      if (i === idx) {
        supportPos = { ...supportPos, [property]: value };
      }
      return supportPos;
    });
    this.props.onChange({ supportPositions });
  };

  datesChangeHandler = ({ from, to }: DateTimePeriod, idx: number) => {
    const { startDate, endDate } = this.props.supportDetails.supportPositions[idx];
    let supportPositions;
    if (startDate && from !== startDate) {
      supportPositions = this.props.supportDetails.supportPositions.map((supportPos, i) => {
        if (i === idx) supportPos = { ...supportPos, startDate: from };
        return supportPos;
      });
    } else if (endDate && to !== endDate) {
      supportPositions = this.props.supportDetails.supportPositions.map((supportPos, i) => {
        if (i === idx) supportPos = { ...supportPos, endDate: to };
        return supportPos;
      });
    } else return;
    this.props.onChange({ supportPositions });
  };

  render() {
    const { t, readOnly } = this.props;
    const { supportDetails } = this.props;
    const { isForm } = this.props;
    const {
      totalCostLocationA,
      totalCostLocationB,
      totalCostLocationC,
      totalCostLocationD,
      requestPreApprovedDuringASP,
      supportPositions
    } = supportDetails;

    const divHiddenStyle = {
      display: 'none'
    };

    return (
      <div className={`support-details-container${isForm ? ' isform' : ''}`}>
        <div className="support-details__pre-approved" style={isForm ? {} : divHiddenStyle}>
          <Checkbox
            disabled={readOnly}
            label={'Request pre-approved during ASP'}
            checked={requestPreApprovedDuringASP}
            onChange={(_, { checked }) => this.props.onChange({ requestPreApprovedDuringASP: checked })}
          />
        </div>
        <div className="support-details__main">
          <div className="support-details__add-position">
            {isForm && <Icon size="large" name="cogs" />}
            <Button disabled={readOnly} onClick={this.handleNewSupportPosition} icon className="support-details__add-btn">
              {t('Add Support Position')}
              <Icon name="user plus" />
            </Button>
            <div className="costs">
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Cost')}
                subLabel={t('Location A')}
                value={totalCostLocationA}
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Cost')}
                subLabel={t('Location B')}
                value={totalCostLocationB}
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Cost')}
                subLabel={t('Location C')}
                value={totalCostLocationC}
              />
              <CostsComponent
                onChange={null}
                disabledLayout
                disabled
                label={t('Total Cost')}
                subLabel={t('Location D')}
                value={totalCostLocationD}
              />
            </div>
          </div>
          {(supportPositions || []).length > 0 &&
            supportPositions.map(
              ({ positionCode, comment, requestedHC, duration, locationA, locationB, locationC, locationD, startDate, endDate }, i) => {
                locationA = locationA || { ...defaultSupportPosition };
                locationB = locationB || { ...defaultSupportPosition };
                locationC = locationC || { ...defaultSupportPosition };
                locationD = locationD || { ...defaultSupportPosition };
                return (
                  <div key={i} className="support-details__item">
                    <div className="support-details__item-collection">
                      <Input
                        disabled={readOnly}
                        className="support-details__comment-input"
                        value={comment}
                        placeholder={t('Free text from Request')}
                        onChange={(_, { value }) => this.inputChangeHandler('comment', i, value)}
                      />
                      <div className="support-details__item-main">
                        <div className="supppor-details__item-main-segment">
                          <label className="support-details__segment-label">{t('Position')}</label>
                          <PositionCodeEditor
                            readonly={readOnly}
                            placeholder={t('Position')}
                            value={positionCode}
                            showDescription
                            onChange={posCode => this.positionCodeEditorChange('positionCode', posCode, i)}
                          />
                        </div>
                        <div className="supppor-details__item-main-segment">
                          <label className="support-details__segment-label">Requested HC</label>
                          <Input
                            disabled={readOnly}
                            type="number"
                            value={requestedHC}
                            onChange={(_, { value }) => this.inputChangeHandler('requestedHC', i, value)}
                          />
                        </div>
                        <div className="supppor-details__item-main-segment">
                          <label className="support-details__segment-label">{t('Dates')}</label>
                          <PeriodInput
                            id={'request-suppport-details-' + i}
                            readOnly={readOnly}
                            onChange={period => this.datesChangeHandler(period, i)}
                            icon={false}
                            value={{ from: startDate, to: endDate }}
                          />
                        </div>
                        <div className="supppor-details__item-main-segment">
                          <label className="support-details__segment-label">{t('Duration')}</label>
                          <Input disabled={readOnly} type="number">
                            <DurationInput onChange={value => this.inputChangeHandler('duration', i, value)} value={`${duration}`} />
                          </Input>
                        </div>
                      </div>
                      <div className="support-details__location-detail-item">
                        <label className="support-details__location-detail-label">
                          <span>{t('Supplying')}</span>
                          <span>{t('Location A')}</span>
                        </label>

                        <LocationEditor
                          placeholder={t('Location')}
                          nullable
                          clearable
                          readOnly={i !== 0 || readOnly}
                          value={locationA?.locationId ? { id: locationA.locationId, title: '' } : null}
                          onChange={location => this.locationsEditorChange('locationA', location)}
                          showCountry={true}
                        />
                        <CostsComponent
                          disabled={!locationA.locationId || readOnly}
                          label={t('Estimated')}
                          subLabel={t('Cost')}
                          value={locationA.estimatedCost}
                          onChange={supply => this.supplyingHandler('locationA', supply, i)}
                        />
                      </div>
                      <div className="support-details__location-detail-item">
                        <label className="support-details__location-detail-label">
                          <span>{t('Supplying')}</span>
                          <span>{t('Location B')}</span>
                        </label>

                        <LocationEditor
                          placeholder={t('Location')}
                          nullable
                          clearable
                          readOnly={i !== 0 || readOnly}
                          value={locationB?.locationId ? { id: locationB.locationId, title: '' } : null}
                          onChange={location => this.locationsEditorChange('locationB', location)}
                          showCountry={true}
                        />

                        <CostsComponent
                          disabled={!locationB.locationId || readOnly}
                          label={t('Estimated')}
                          subLabel={t('Cost')}
                          value={locationB?.estimatedCost}
                          onChange={supply => this.supplyingHandler('locationB', supply, i)}
                        />
                      </div>
                      <div className="support-details__location-detail-item">
                        <label className="support-details__location-detail-label">
                          <span>Supplying</span>
                          <span>Location C</span>
                        </label>

                        <LocationEditor
                          placeholder={t('Location')}
                          nullable
                          clearable
                          readOnly={i !== 0 || readOnly}
                          value={locationC.locationId ? { id: locationC.locationId, title: '' } : null}
                          onChange={location => this.locationsEditorChange('locationC', location)}
                          showCountry={true}
                        />

                        <CostsComponent
                          disabled={!locationC.locationId || readOnly}
                          label={t('Estimated')}
                          subLabel={t('Cost')}
                          value={locationC.estimatedCost}
                          onChange={supply => this.supplyingHandler('locationC', supply, i)}
                        />
                      </div>
                      <div className="support-details__location-detail-item">
                        <label className="support-details__location-detail-label">
                          <span>{t('Supplying ')}</span>
                          <span>{t('Location D')}</span>
                        </label>

                        <LocationEditor
                          placeholder={t('Location')}
                          nullable
                          clearable
                          readOnly={i !== 0 || readOnly}
                          value={locationD.locationId ? { id: locationD.locationId, title: '' } : null}
                          onChange={location => this.locationsEditorChange('locationD', location)}
                          showCountry={true}
                        />

                        <CostsComponent
                          disabled={!locationD.locationId || readOnly}
                          label={t('Estimated')}
                          subLabel={t('Cost')}
                          value={locationD.estimatedCost}
                          onChange={supply => this.supplyingHandler('locationD', supply, i)}
                        />
                      </div>
                    </div>
                    {!readOnly && <Icon color="red" name="remove" onClick={() => this.removeSupportPosition(i)} />}
                  </div>
                );
              }
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(RequestSupportDetails);
