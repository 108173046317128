import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { CommandResult } from 'stores/types';
import { SimpleUserDto } from 'stores/users/users-store';

export interface EventFeedbackTemplatesDto {
  id: string;
  title: string;
  respondents: 'Trainees' | 'ManualAssignment';
  feedbackFormAnswers: SimpleFeedbackFormAnswerDto[];
}

export enum Respondent {
  Trainees = 10,
  ManualAssignment = 20
}

export interface SimpleFeedbackFormAnswerDto {
  id: string;
  userId: string;
  user: SimpleUserDto;
  answered: boolean;
}

export interface ChangeFeedbackFormDto {
  id: string;
  eventId: string;
  feedbackTemplateId: string;
  usersId: string[];
}

export interface CreateFeedbackFormDto {
  eventId: string;
  feedbackTemplateId: string;
  usersId: string[];
}

export interface FeedbackFormDto {
  id: string;
  eventId: string;
  eventTitle: string;
  feedbackTemplateId: string;
  feedbackTemplateName: string;
  userAnswers: SimpleFeedbackFormAnswerDto[];
}

export class CreateFeedbackFormValidator extends AbstractValidator<CreateFeedbackFormDto> {
  constructor() {
    super();
    this.validateIfString(o => o.eventId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.feedbackTemplateId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Feedback Template Id is required'));

    // this.validateIf(o => o)
    //   .fulfills(o => o.usersId.length > 0)
    //   .withFailureMessage(i18n.t('Students Assigned to feedback must be greater than 0'));
  }
}

export const toCreateFeedbackItemDto = (
  { id, feedbackFormAnswers }: EventFeedbackTemplatesDto,
  eventId: string
): CreateFeedbackFormDto => ({
  eventId,
  feedbackTemplateId: id,
  usersId: (feedbackFormAnswers || []).map(respondent => respondent.id)
});

export function toReturnRespondants(feedbackTemplateForm: EventFeedbackTemplatesDto): Respondent[] {
  let respondents = [];
  feedbackTemplateForm.feedbackFormAnswers.forEach(userAnswer => {
    respondents.push({
      id: userAnswer.user.id,
      firstName: userAnswer.user.firstName,
      userName: userAnswer.user.userName,
      lastName: userAnswer.user.lastName,
      sfPosition: userAnswer.user.sfPosition,
      location: userAnswer.user.location
    });
  });
  return respondents;
}

@repository('@@FORMFEEDBACKSLIST', 'formfeedbacklist.summary')
export class FormFeedbackListStore extends DataStore<EventFeedbackTemplatesDto> {
  RETRIEVE_ONE_FEEDBACK_FORM = 'RETRIEVE_ONE_FEEDBACK_FORM';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-forms';
  retrievePath = 'get-feedback-forms';
  updatePath = '';
  deletePath = 'hard-delete-feedback-form-answer';
  hardDeletePath = 'hard-delete-feedback-form-answer';
  retrieveOnePath = 'get-feedback-form';
  retriveTemplatesByEventPath = 'get-feedback-templates';

  protected validate(item: EventFeedbackTemplatesDto): ValidationResult {
    const createFeedbackItemDto = toCreateFeedbackItemDto(this.state.item, this.state.item.id);
    return new CreateFeedbackFormValidator().validate(createFeedbackItemDto);
  }

  public async getTemplatesByEventId(id: string): Promise<any> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<EventFeedbackTemplatesDto>>(`${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}`)
    );
    return result;
  }

  public async getFormById(id: string): Promise<FeedbackFormDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_FEEDBACK_FORM,
      httpService.get<FeedbackFormDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('FORMFEEDBACKSLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMFEEDBACKSLIST', 'formfeedbacklist.event-tab-summary')
export class EventFeedbackTabStore extends DataStore<EventFeedbackTemplatesDto> {
  RETRIEVE_ONE_FEEDBACK_FORM = 'RETRIEVE_ONE_FEEDBACK_FORM';
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-forms';
  retrievePath = 'get-feedback-forms';
  updatePath = '';
  deletePath = 'hard-delete-feedback-form-answer';
  hardDeletePath = 'hard-delete-feedback-form-answer';
  retrieveOnePath = 'get-feedback-form';
  retriveTemplatesByEventPath = 'get-feedback-templates';
  lastParamQuery: string = null;

  protected validate(item: EventFeedbackTemplatesDto): ValidationResult {
    const createFeedbackItemDto = toCreateFeedbackItemDto(this.state.item, this.state.item.id);
    return new CreateFeedbackFormValidator().validate(createFeedbackItemDto);
  }

  public async getTemplatesByEventId(id: string): Promise<any> {
    if (this.state.items.length <= 0 || this.lastParamQuery !== id) {
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.ENTITY_LIST_UPDATE,
        httpService.get<QueryResult<EventFeedbackTemplatesDto>>(`${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}`)
      );
      this.lastParamQuery = id;

      return result;
    }
  }

  constructor() {
    super('EVENTFEEDBACKTAB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMFEEDBACKSLIST', 'formfeedbacklist.new')
export class NewFeedbackFormUserStore extends FormStore<CreateFeedbackFormDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-feedback-forms';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateFeedbackFormDto) {
    return new CreateFeedbackFormValidator().validate(item);
  }

  public createForms = async () => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateFeedbackFormDto, CommandResult<CreateFeedbackFormDto>>(`${this.baseUrl}/${this.createPath}`, this.state.item)
    );
    return result.data;
  };

  constructor() {
    super('NEW_FORMFEEDBACKSLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
