import { RequestDto } from 'stores/requests/requests-store';
import { isGMA, isGEA, getValidators } from './event-type-utils';

export function isGMARequest(request: RequestDto): boolean {
  return request != null && isGMA(request.eventType);
}

export function isGEARequest(request: RequestDto): boolean {
  return request != null && isGEA(request.eventType);
}
export function getRequestValidators(request: RequestDto): string[] {
  if (request == null) return [];
  return getValidators(request.eventType);
}
