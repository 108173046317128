import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { ValidationResult } from 'fluent-ts-validator';
import { MachineModelDto } from './machine-models-store';
export interface MachineriesDto extends MachineModelDto {
  machineUnits: string;
}

@repository('@@MACHINERIES', 'machineries.summary')
export class MachineriesStore extends DataStore<MachineriesDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = 'get-machineries';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = '';

  protected validate(_: MachineriesDto) {
    return new ValidationResult();
  }

  constructor() {
    super('MACHINERIES', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
