import { BaseDto } from '../../types';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { TnaSkillDto } from '../skills/tna-skills-store';
import { TestCategoryDto } from '../testCategory-store';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { OemDto } from 'stores/configuration/machinery/oems-store';

export interface TnaQuestionBankDto extends BaseDto {
  id: string; //Guid
  friendlyId: string;
  question: QuestionDto;
  tnaSkillId: string;
  tnaSkill: TnaSkillDto;
  testCategoryId: string;
  testCategory: TestCategoryDto;
  professions: ProfessionDto[];
  isMachineRelated: boolean;
  equipmentTypeId: string;
  equipmentType: EquipmentTypeDto;
  oemId: string;
  oem: OemDto;
  questionTranslations: QuestionDto[];
  locationId: string;
  type: string;
}

export interface CreateTnaQuestionBankDto {
  question: CreateQuestionDto;
  tnaSkillId: string;
  clusterId: string;
  oemId: string;
  testCategoryId: string;
  professions: string[];
  isMachineRelated: boolean;
  equipmentTypeId: string;
  questionTranslations: CreateQuestionDto[];
  locationRequired: boolean;
  locationId: string;
}

export interface ChangeTnaQuestionBankDto {
  id: string; //Guid
  friendlyId: string;
  question: ChangeQuestionDto;
  tnaSkillId: string;
  clusterId: string;
  testCategoryId: string;
  professions: string[];
  isMachineRelated: boolean;
  equipmentTypeId: string;
  oemId: string;
  questionTranslations: ChangeQuestionDto[];
  locationRequired: boolean;
  locationId: string;
}

export interface QuestionDto {
  text: string;
  languageId: string;
  language: LanguageDto;
}

export interface CreateQuestionDto {
  text: string;
  languageId: string;
}

export interface ChangeQuestionDto {
  text: string;
  languageId: string;
}

export class QuestionDtoValidator extends ExtendedAbstractValidator<ChangeQuestionDto | CreateQuestionDto> {
  constructor(
    isTraslation: boolean,
    questionLangId?: string,
    translations?: ChangeQuestionDto[] | CreateQuestionDto[],
    idx?: number,
    onErrors?: (...failures: ValidationFailure[]) => void
  ) {
    super(onErrors);
    let preffix = '';
    if (isTraslation) preffix = i18n.t(`Translation at position ${idx + 1}`);
    else preffix = i18n.t(`Question`);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.text)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(`${isTraslation ? preffix + ': ' : ''}${i18n.t('Question is required')}`);

    this.validateIfString(x => x.languageId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Valid language is required')}`);

    this.validateIf(x => x)
      .fulfills(x => x.languageId !== questionLangId && translations.slice(0, idx).every(t => t == null || t.languageId !== x.languageId))
      .when(x => isTraslation && x != null && !isNullOrWhiteSpaces(x.languageId) && (translations || []).length !== 0)
      .withFailureMessage(`${preffix}: ${i18n.t('Language repeated')}`);
  }
}

export class CreateTnaQuestionBankValidator extends ExtendedAbstractValidator<CreateTnaQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.testCategoryId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIf(o => o.professions)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role(s) required'));

    this.validateIf(o => o.isMachineRelated)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Related is required'));

    this.validateIfString(o => o.clusterId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster is required when is machine related'))
      .when(o => o.isMachineRelated);

    this.validateIfString(o => o.equipmentTypeId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Equipment type is required when is machine related'))
      .when(({ clusterId, isMachineRelated }) => isMachineRelated || clusterId != null);

    this.validateIfString(o => o.oemId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('OEM Id is required when is machine related'))
      .when(({ equipmentTypeId, isMachineRelated }) => isMachineRelated || equipmentTypeId != null);

    this.validateIfString(o => o.locationId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'))
      .when(o => o.locationRequired);

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new QuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new QuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors).extendValidate(t).isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

export class ChangeTnaQuestionBankValidator extends ExtendedAbstractValidator<ChangeTnaQuestionBankDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Question Bank Id is required'));

    this.validateIfString(o => o.testCategoryId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIf(o => o.professions)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role(s) required'));

    this.validateIf(o => o.isMachineRelated)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Related is required'));

    this.validateIfString(o => o.clusterId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster is required when is machine related'))
      .when(o => o.isMachineRelated);

    this.validateIfString(o => o.equipmentTypeId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Equipment type is required when is machine related'))
      .when(({ clusterId, isMachineRelated }) => isMachineRelated || clusterId != null);

    this.validateIfString(o => o.oemId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('OEM Id is required when is machine related'))
      .when(({ equipmentTypeId, isMachineRelated }) => isMachineRelated || equipmentTypeId != null);

    this.validateIfString(o => o.locationId)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Location is required'))
      .when(o => o.locationRequired);

    this.validateIf(o => o.question)
      .isDefined()
      .isNotNull()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Question is required'));

    this.validateIf(x => x.question)
      .fulfills(x => new QuestionDtoValidator(false, null, null, null, this.addErrors).extendValidate(x).isValid())
      .when(x => x != null && x.question != null)
      .withFailureMessage(i18n.t('Question is wrong'));

    this.validateIf(x => x)
      .fulfills(x =>
        x.questionTranslations.all((t, i) =>
          new QuestionDtoValidator(true, x.question.languageId, x.questionTranslations, i, this.addErrors).extendValidate(t).isValid()
        )
      )
      .when(
        x => x != null && x.question != null && !isNullOrWhiteSpaces(x.question.languageId) && (x.questionTranslations || []).length !== 0
      )
      .withFailureMessage(i18n.t('At least one translation is wrong'));
  }
}

@repository('@@TNAQUESTIONBANKS', 'tnaquestionbanks.summary')
export class TnaQuestionBanksStore extends DataStore<TnaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-question';
  retrievePath = 'get-tna-questions';
  updatePath = 'update-tna-question';
  deletePath = 'delete-tna-question';
  retrieveOnePath = 'get-tna-question';
  retrieveCountPath = 'get-used-tna-question-count';

  protected validate(item: TnaQuestionBankDto) {
    return new ValidationResult();
  }

  constructor() {
    super('TNAQUESTIONBANK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getQuestionBankById(id: string): Promise<TnaQuestionBankDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TnaQuestionBankDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedTnaQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@TNAQUESTIONBANKS', 'tnaquestionbanks.new')
export class NewTnaQuestionBankStore extends FormStore<CreateTnaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-question';
  retrievePath = 'get-tna-question';
  updatePath = 'update-tna-question';

  protected validate(item: CreateTnaQuestionBankDto) {
    return new CreateTnaQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('NEW_TNAQUESTIONBANKS', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNAQUESTIONBANKS', 'tnaquestionbanks.change')
export class ChangeTnaQuestionBankStore extends FormStore<ChangeTnaQuestionBankDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-question';
  retrievePath = 'get-tna-question';
  updatePath = 'update-tna-question';

  protected validate(item: ChangeTnaQuestionBankDto) {
    return new ChangeTnaQuestionBankValidator().extendValidate(item);
  }

  constructor() {
    super('CHANGE_TNAQUESTIONBANKS', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
