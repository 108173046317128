import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './profile-definition-modal.less';
import { ChangeProfileItemStore, ProfileItemDto, CreateProfileItemDto, ProfileSkillDto } from 'stores/profile/profile-store';
import ProfileDefinitionModal, { ProfileValues } from './profile-definition-modal.component';
import { Message } from 'semantic-ui-react';

interface CloneProfileDefProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  currentProfile: ProfileItemDto;
  changeProfileStore: ChangeProfileItemStore;
  onFinish?: () => void;
  onRefresh?: () => void;
}

interface CloneProfileDefinitionState {
  profileValues: ProfileValues;
  userClickedSave: boolean;
}

class CloneProfileDefinition extends Component<CloneProfileDefProps, CloneProfileDefinitionState> {
  state: CloneProfileDefinitionState = {
    userClickedSave: false,
    profileValues: this.mapProfileItemToDetailedSkills()
  };

  private mapProfileItemToDetailedSkills(): ProfileValues {
    const initialValues = { ...this.props.currentProfile };
    delete initialValues.friendlyId;
    delete initialValues.professionName;
    this.props.changeProfileStore.createNew({ ...initialValues });
    return {
      ...initialValues,
      assessment: initialValues.assessment,
      main: null,
      substitute: null,
      skill: null,
      phase0: null,
      phase1: null,
      phase2: null,
      phase3: null,
      phase4: null
    };
  }
  private getDefaultProfileRowsValues() {
    return {
      skill: null,
      main: null,
      substitute: null,
      phase0: null,
      phase1: null,
      phase2: null,
      phase3: null,
      phase4: null
    };
  }

  sanitizedFinalObj = (frontValues: ProfileValues): CreateProfileItemDto => {
    delete frontValues.skill;
    delete frontValues.main;
    delete frontValues.substitute;
    delete frontValues.phase0;
    delete frontValues.phase1;
    delete frontValues.phase2;
    delete frontValues.phase3;
    delete frontValues.phase4;
    return frontValues;
  };

  private onCancelChangeItem = () => {
    this.props.changeProfileStore.clear();
    this.props.onClose(false);
  };

  addNewRowHandler = () => {
    const profileValuesCopy = { ...this.state.profileValues };
    const newRow: ProfileSkillDto = {
      main: profileValuesCopy.main,
      substitute: profileValuesCopy.substitute,
      assessment: profileValuesCopy.assessment,
      skillId: profileValuesCopy.skill.id,
      skillName: profileValuesCopy.skill.title,
      phase0: profileValuesCopy.phase0,
      phase1: profileValuesCopy.phase1,
      phase2: profileValuesCopy.phase2,
      phase3: profileValuesCopy.phase3,
      phase4: profileValuesCopy.phase4
    };
    if ((profileValuesCopy.skill as any).isMachineRelated != null)
      newRow.isMachineRelated = (profileValuesCopy.skill as any).isMachineRelated;
    this.setState(({ profileValues }) => ({
      profileValues: {
        ...profileValues,
        ...this.getDefaultProfileRowsValues(),
        skills: [...profileValues.skills, newRow]
      }
    }));
    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(profileValuesCopy), skills: [...profileValuesCopy.skills, newRow] });
  };

  onProfileValuesChange = (profileValues: ProfileValues) => {
    this.setState({ profileValues, userClickedSave: false });
    const newState = { ...profileValues };
    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(newState) });
  };

  removeSkillRow = (index: number) => {
    const prevValues = { ...this.state.profileValues };
    const skills = prevValues.skills.filter((_, i) => index !== i);

    this.setState(_ => ({
      userClickedSave: false,
      profileValues: { ...prevValues, skills }
    }));

    this.props.changeProfileStore.change({ ...this.sanitizedFinalObj(prevValues), skills });
  };

  onSubmit = () => {
    const { changeProfileStore } = this.props;
    this.setState({ userClickedSave: true });
    changeProfileStore.submit().then(_ => {
      if (changeProfileStore.state.result && changeProfileStore.state.result?.isSuccess) {
        this.onCancelChangeItem();
        this.props.onRefresh && this.props.onRefresh();
      }
    });
  };

  public render() {
    return (
      <>
        <ProfileDefinitionModal
          mode="Clone"
          onSubmit={this.onSubmit} //{this.onToggleConfirmationBox}
          onNewRow={this.addNewRowHandler}
          onRemoveRow={this.removeSkillRow}
          saveTry={this.state.userClickedSave}
          values={this.state.profileValues}
          onChange={this.onProfileValuesChange}
          onClose={this.onCancelChangeItem}
          isBusy={this.props.changeProfileStore.state.isBusy}
        >
          {this.props.changeProfileStore.state.result && !this.props.changeProfileStore.state.result.isSuccess && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              list={this.props.changeProfileStore.state.result.messages.map(m => m.body)}
            />
          )}
        </ProfileDefinitionModal>
      </>
    );
  }
}

export default withTranslation()(CloneProfileDefinition);
