import React, { FC, useState, useEffect, useReducer } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Dimmer, Container, Loader, Message, Form, TextArea, Button, Accordion, Input } from 'semantic-ui-react';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import NMRClusterEditor from 'widgets/bussiness/cluster-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OEM from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import TrainingNameEditor from 'widgets/bussiness/training-name-editor';
import { ItemReference } from 'stores/dataStore';
import PracticalQuestionTranslation from './practical-question-translation';
import MultiMachineUnitEditor from 'widgets/bussiness/multi-machine-unit-editor';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import FunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import FunctionalSubareaEditor from 'widgets/bussiness/functional-subarea-editor';
import '../practical-test.style.less';
import { plainQuestion, canBeSaved, getCascadeChanges } from './util';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import {
  MrPracticalTestQuestionItemDto,
  NmrPracticalTestQuestionItemDto,
  PracticalQuestionDto
} from 'stores/assessments/questionBank/practical-test-store';

export type ModalMode = 'New' | 'Edit' | 'Clone';
interface QuestionManagerProps extends WithTranslation {
  data?: MrPracticalTestQuestionItemDto | NmrPracticalTestQuestionItemDto;
  type: 'MR' | 'NMR';
  mode: ModalMode;
  loading: boolean;
  errMsg?: { body: string; level: string }[];
  onClose: () => void;
  onSubmit: (item: MrPracticalTestQuestionItemDto | NmrPracticalTestQuestionItemDto) => void;
  onDismiss: () => void;
}

interface QuestionManagerState {
  item: MrPracticalTestQuestionItemDto | NmrPracticalTestQuestionItemDto;
}

const PracticalQuestionManager: FC<QuestionManagerProps> = props => {
  const { type, mode, loading, errMsg, data, onDismiss, onSubmit, t, onClose } = props;
  const [savePressed, setSavePressed] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [state, setState]: [QuestionManagerState, (newState: Partial<QuestionManagerState>) => void] = useReducer(
    (state: QuestionManagerState, newState: Partial<QuestionManagerState>) => ({ ...state, ...newState }),
    { item: data }
  );
  const { item } = state;
  const { professions, trainingLevelId, targetLevel } = item;
  const { question, questionTranslations } = item;

  const { clusterId, equipmentTypeId, oemId, machineModelId, machineUnitId, plcTypeId } = item as MrPracticalTestQuestionItemDto;
  const { nmrClusterId, functionalAreaId, trainingNameId, functionalSubareaId } = item as NmrPracticalTestQuestionItemDto;

  useEffect(() => {
    if (!item) return setCanSave(false);
    setCanSave(canBeSaved(item));
  }, [item]);

  const changeHandler = (property: keyof MrPracticalTestQuestionItemDto | keyof NmrPracticalTestQuestionItemDto, value: any) => {
    const cascadeChanges = getCascadeChanges(property, value);
    setSavePressed(false);
    const newChange = { [property as string]: value, ...cascadeChanges };
    setState({ item: { ...state.item, ...newChange } });
  };

  const questionHandler = (property: keyof PracticalQuestionDto, value: any) => {
    if (!state.item) return;
    const newQuestionItem: PracticalQuestionDto = { ...state.item.question, [property as string]: value };
    changeHandler('question', newQuestionItem);
  };

  const translationHandler = (property: keyof PracticalQuestionDto, value: any, i: number) => {
    if (!state.item) return;
    const translationChange = (questionTranslations || []).map((item, idx) =>
      idx === i ? { ...item, [property as string]: value } : item
    );
    changeHandler('questionTranslations', translationChange);
  };

  const languageHandler = (item: ItemReference, i: number) => {
    if (item == null) translationHandler('language', item, i);
    else {
      const languageItem = questionTranslations[i];
      translationHandler('language', { ...languageItem.language, id: item.id, languageId: item.id, language: item.title }, i);
    }
  };

  const onSave = () => {
    setSavePressed(true);
    onSubmit(item);
  };

  const handleNewTranslation = () => {
    changeHandler('questionTranslations', [{ ...plainQuestion, language: {} as LanguageDto, active: true }, ...questionTranslations]);
  };

  const handleRemoveTranslation = (i: number) => {
    const newQs = (questionTranslations || []).filter((_, idx) => i !== idx);
    changeHandler('questionTranslations', newQs);
  };

  const functionalAreaValue = functionalAreaId ? { id: functionalAreaId, title: '' } : null;
  const functionalSubareaValue = functionalSubareaId ? { id: functionalSubareaId, title: '' } : null;
  const trainingLevelIdValue = trainingLevelId ? { id: trainingLevelId, title: '' } : null;
  const nMRClusterValue = nmrClusterId ? { id: nmrClusterId, title: '' } : null;
  const trainingNameIdValue = trainingNameId ? { id: trainingNameId, title: '' } : null;
  return (
    <Modal open /*closeOnEscape*/ closeOnEscape={true} closeOnDocumentClick onClose={onClose}>
      <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
        <Loader indeterminate>{t('Loading')}</Loader>
      </Dimmer>
      <Modal.Header>{t(`${mode} ${type} Practical Question`)}</Modal.Header>
      <Modal.Content>
        <Container className="practical-question-manager-container">
          {(errMsg || []).length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              onDismiss={onDismiss}
              header={t('An error ocurred')}
              list={errMsg.map(err => err.body ?? err)}
            />
          )}
          <Form>
            <Form.Group>
              <Form.Field width={8} inline required error={savePressed && (professions || []).length <= 0}>
                <label>{t('Roles')}</label>
                <MultiProfessionEditor
                  placeholder={t('Roles')}
                  value={(professions || []).map(({ id }) => id)}
                  onChange={roles => changeHandler('professions', (roles || []).length > 0 ? roles.map(id => ({ id })) : null)}
                />
              </Form.Field>

              <Form.Field width={8} inline required error={savePressed && !trainingLevelId}>
                <label>{t('Training Level')}</label>
                <TrainingLevelEditor
                  value={trainingLevelIdValue}
                  onChange={trainingLevel => changeHandler('trainingLevelId', trainingLevel && trainingLevel.id ? trainingLevel.id : null)}
                  placeholder={t('Training Level')}
                  searchable
                  clearable
                  nullable
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} inline required error={savePressed && !targetLevel}>
                <label>{t('Target Level')}</label>
                <Input
                  className="practical-minutes-input"
                  label="minutes"
                  labelPosition="right"
                  value={targetLevel}
                  onChange={(_, { value }) => changeHandler('targetLevel', value)}
                />
              </Form.Field>

              <Form.Field width={8} inline></Form.Field>
            </Form.Group>

            <Form.Group>
              {type === 'MR' ? (
                <Form.Field width={8} inline required error={savePressed && !clusterId}>
                  <label>{t('Cluster')}</label>
                  <MRClusterEditor
                    nullable
                    clearable
                    placeholder={t('Cluster')}
                    value={clusterId}
                    onChange={cluster => changeHandler('clusterId', cluster?.id || null)}
                  />
                </Form.Field>
              ) : (
                <Form.Field width={8} inline required error={savePressed && !nMRClusterValue}>
                  <label>{t('Cluster')}</label>
                  <NMRClusterEditor
                    nullable
                    placeholder={t('Cluster')}
                    reloadOnChange
                    clearable
                    value={nMRClusterValue}
                    onChange={cluster => changeHandler('nmrClusterId', cluster?.id || null)}
                  />
                </Form.Field>
              )}

              {type === 'MR' ? (
                <Form.Field width={8} inline required error={savePressed && !equipmentTypeId}>
                  <label>{t('Equipment Type')}</label>
                  <EquipmentTypeEditor
                    nullable
                    clearable
                    readOnly={!clusterId}
                    clusterId={clusterId}
                    value={equipmentTypeId}
                    onChange={value => changeHandler('equipmentTypeId', value?.id || null)}
                    placeholder={t('Equipment Type')}
                  />
                </Form.Field>
              ) : (
                <Form.Field width={8} inline required error={savePressed && !functionalAreaId}>
                  <label>{t('Functional Area')}</label>
                  <FunctionalAreaEditor
                    clusterId={nmrClusterId}
                    value={functionalAreaValue}
                    clearable
                    clearOnReload
                    nullable
                    placeholder={t('Functional Area')}
                    readonly={!nmrClusterId}
                    onChange={item => changeHandler('functionalAreaId', item?.id ?? null)}
                  />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} inline>
                {type === 'MR' ? (
                  <>
                    <label>{t('OEM')}</label>
                    <OEM
                      value={oemId}
                      nullable
                      clearable
                      equipmentId={equipmentTypeId}
                      onChange={oem => changeHandler('oemId', oem?.id || null)}
                      placeholder={t('OEM')}
                      readonly={!equipmentTypeId}
                    />
                  </>
                ) : (
                  <>
                    <label>{t('Training Name')}</label>
                    <TrainingNameEditor
                      funcionalAreaId={functionalAreaId}
                      value={trainingNameIdValue}
                      nullable
                      clearOnReload
                      clearable
                      readOnly={!functionalAreaId}
                      onChange={item => changeHandler('trainingNameId', item?.id || null)}
                      placeholder={t('Training Name')}
                    />
                  </>
                )}
              </Form.Field>

              <Form.Field width={8} inline>
                {type === 'MR' ? (
                  <>
                    <label>{t('Machine Model')}</label>
                    <MachineModelEditor
                      equipmentId={equipmentTypeId}
                      oemId={oemId}
                      clusterId={clusterId}
                      nullable
                      clearable
                      value={machineModelId}
                      readonly={!equipmentTypeId || !oemId || !clusterId}
                      onChange={machineModel => changeHandler('machineModelId', machineModel?.id || null)}
                      placeholder={t('Machine Model')}
                    />
                  </>
                ) : (
                  <>
                    <label>{t('Functional Subarea')}</label>
                    <FunctionalSubareaEditor
                      trainingNameId={trainingNameId}
                      readonly={!trainingNameId}
                      nullable
                      clearable
                      reloadOnChange
                      clearOnReload
                      value={functionalSubareaValue}
                      placeholder={t('Functional Subarea')}
                      onChange={item => changeHandler('functionalSubareaId', item?.id ?? null)}
                    />
                  </>
                )}
              </Form.Field>
            </Form.Group>
            {type === 'MR' && (
              <Form.Group>
                <Form.Field width={8} inline>
                  <label>{t('Machine Unit')}</label>
                  <MultiMachineUnitEditor
                    equipmentId={equipmentTypeId}
                    oemId={oemId}
                    readOnly={!equipmentTypeId || !oemId}
                    value={machineUnitId || ''}
                    onChange={val => changeHandler('machineUnitId', val)}
                  />
                </Form.Field>

                <Form.Field width={8} inline>
                  <label>{t('PLC Type')}</label>
                  <MultiPlcTypeEditor
                    multiple={false}
                    equipmentId={equipmentTypeId}
                    oemId={oemId}
                    readOnly={!equipmentTypeId || !oemId}
                    value={plcTypeId || ''}
                    onChange={val => changeHandler('plcTypeId', val)}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Field required inline width={12} error={savePressed && !question.text}>
                <label>{t('Question')}</label>
                <TextArea value={question ? question.text : ''} onChange={(_, { value }) => questionHandler('text', value)} />
              </Form.Field>
              <Form.Field required inline width={4}>
                <Button className="add-translation-btn" type="button" onClick={handleNewTranslation}>
                  {t('Add Translation')}
                </Button>
              </Form.Field>
            </Form.Group>

            {(questionTranslations || []).length > 0 && (
              <Accordion>
                {questionTranslations.map((item, index) => (
                  <PracticalQuestionTranslation
                    key={index}
                    {...item}
                    index={index}
                    onChange={(prop, value) => translationHandler(prop, value, index)}
                    onRemove={() => handleRemoveTranslation(index)}
                    onLanguageChange={item => languageHandler(item, index)}
                  />
                ))}
              </Accordion>
            )}
          </Form>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          {t('Cancel')}
        </Button>
        <Button disabled={!canSave} onClick={onSave} positive>
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default withTranslation()(PracticalQuestionManager);
