import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDto } from 'stores/users/users-store';
import { Grid, Button, Dropdown, Icon, Checkbox } from 'semantic-ui-react';
import ChooseEmployeesView from 'widgets/form/choose-employees-form';
import ChooseAssessorsView from 'widgets/form/choose-assessors-form';
import { getProperties, getKeyValuePairs, nameof } from 'utils/object';

import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { SsaEmployeeAssessors, SsaFormWizardStore } from 'stores/assessments/wizard/ssa-form-wizard-store';
import './employees-and-assessors-step.less';
import { connect } from 'redux-scaffolding-ts';
import { SsaTemplateDto } from 'stores/assessments/templates/ssa-templates-store';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import { SsaFormItemDto } from 'stores/assessments/forms/ssa-forms-store';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import { ColumnsNeeds } from 'widgets/collections/util-table';
import { FunctionalExpertDto } from 'stores/assessments/forms/tna-forms-store';

export interface EmployeesAndAssessorsStepProps extends WithTranslation {
  active: boolean;
  ssaFormWizardStore?: SsaFormWizardStore;
  isEditMode?: boolean;
  formToEdit?: SsaFormItemDto;
  userFormInfo?: UserProfilesDto;
  assessorsInfo?: FunctionalExpertDto[];
  isGlobalPoc?: boolean;
}
export interface EmployeesAndAssessorsStepState {
  showEmployeeModal: boolean;
  showAssessorModal: boolean;
  selectedAssessor: string;
}

@connect(['ssaFormWizardStore', SsaFormWizardStore])
class EmployeesAndAssessorsStep extends React.Component<EmployeesAndAssessorsStepProps, EmployeesAndAssessorsStepState> {
  constructor(props: Readonly<EmployeesAndAssessorsStepProps>) {
    super(props);

    this.state = {
      showEmployeeModal: false,
      showAssessorModal: false,
      selectedAssessor: undefined
    };

    if (props.isEditMode) {
      const { assessorsInfo } = this.props;
      this.onEmployeesSelected([this.props.userFormInfo]);
      assessorsInfo && this.onAssessorsFromEditMode(assessorsInfo);
    }
  }

  private onAssessorsFromEditMode = (newAssessors: any[]) => {
    const { assessors } = this.props.ssaFormWizardStore.state.item;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }
    this.props.ssaFormWizardStore.change({
      assessors: { ...assessors }
    });
  };

  private showEmployeeModal = () => {
    this.setState({ showEmployeeModal: true });
  };

  private onCloseEmployeeModal = () => {
    this.setState({ showEmployeeModal: false });
  };

  private onEmployeesSelected = (newEmployees: UserProfilesDto[]) => {
    const { employeesAssessors: matrix, employees, template } = this.props.ssaFormWizardStore.state.item;
    const skills = this.getSkillsFromTemplate(template);

    const skillsProps = getProperties(skills).map(kvp => kvp.key);

    for (let i = 0; i < newEmployees.length; i++) {
      const employeeId = newEmployees[i].userId;
      if (!this.props.isEditMode) {
        const skillDict = {};
        for (let j = 0; j < skillsProps.length; j++) {
          const sId = skillsProps[j];
          skillDict[sId] = null;
        }

        matrix[employeeId] = {
          skillsAssessor: skillDict
        } as SsaEmployeeAssessors;
        employees[employeeId] = newEmployees[i];
      }
    }

    this.props.ssaFormWizardStore.change({
      employees: { ...employees },
      employeesAssessors: { ...matrix }
    });

    !this.props.isEditMode && this.setState({ showEmployeeModal: false });
  };

  protected removeEmployee = (id: string) => {
    const { employees, employeesAssessors: matrix, assessors } = this.props.ssaFormWizardStore.state.item;
    const { selectedAssessor } = this.state;
    delete matrix[id];
    delete employees[id];

    let nextAssessors = assessors;
    let nextSelectedAssessor = selectedAssessor;
    if (getProperties(employees).length === 0) {
      nextAssessors = {};
      nextSelectedAssessor = null;
    }

    this.props.ssaFormWizardStore.change({
      employees: { ...employees },
      employeesAssessors: { ...matrix },
      assessors: nextAssessors
    });

    this.setState({ selectedAssessor: nextSelectedAssessor });
  };

  private showAssessorModal = () => {
    this.setState({ showAssessorModal: true });
  };

  private onCloseAssessorModal = () => {
    this.setState({ showAssessorModal: false });
  };

  private onAssessorsSelected = (newAssessors: UserDto[]) => {
    const { assessors } = this.props.ssaFormWizardStore.state.item;
    for (let i = 0; i < newAssessors.length; i++) {
      const a = newAssessors[i];
      assessors[a.id] = a;
    }

    this.props.ssaFormWizardStore.change({ assessors });
    this.setState({ showAssessorModal: false });
  };

  private onAssessorSelectorChanged = (_, { value }) => {
    let selectedAssessor = value;
    if (isNullOrWhiteSpaces(value)) selectedAssessor = null;
    this.setState({ selectedAssessor });
  };

  private removeAssessor = (id: string) => {
    if (this.isUsed(id)) return;
    const { assessors, employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    let { selectedAssessor } = this.state;

    const matrixRows = getProperties(matrix);

    for (let i = 0; i < matrixRows.length; i++) {
      const row = matrixRows[i].value as SsaEmployeeAssessors;
      const prop = getProperties(row.skillsAssessor);
      for (let j = 0; j < prop.length; j++) {
        const { key, value } = prop[j];
        if (value === id) {
          row.skillsAssessor[key] = null;
        }
      }
    }
    delete assessors[id];
    if (selectedAssessor === id) selectedAssessor = null;

    this.props.ssaFormWizardStore.change({
      assessors: { ...assessors },
      employeesAssessors: { ...matrix }
    });

    this.setState({ selectedAssessor });
  };

  private getEmployeesCell = (id: string, prop: string, key: string) => {
    const { employeesAssessors: matrix, assessors } = this.props.ssaFormWizardStore.state.item;
    const { selectedAssessor } = this.state;

    const row = matrix[id];
    if (row == null) return null;

    let objVal = row[prop];
    if (prop === nameof<SsaEmployeeAssessors>('skillsAssessor')) {
      objVal = objVal[key];
    }

    if (objVal == null) {
      if (selectedAssessor == null) return null;
      return <Checkbox checked={false} onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)} />;
    } else if (objVal === selectedAssessor) {
      return <Checkbox checked={true} onChange={(_, { checked }) => this.setSelectedAssesorToEmployee(checked, id, prop, key)} />;
    } else {
      const a = assessors[objVal];
      if (a == null) return '';
      return <TableTooltipCell textToShow={`${a?.firstName} ${a?.lastName}`} />;
    }
  };

  private getEmployeesHeader = (header: string, prop: string, key: string) => {
    const { employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    const { selectedAssessor } = this.state;

    if (selectedAssessor == null) return header;

    const rows = getKeyValuePairs<SsaEmployeeAssessors>(matrix);
    let thereAreEmpty = false;
    let thereAreSelected = false;
    let avoidCount = 0;
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i].value;
      let val = row[prop];
      if (prop === nameof<SsaEmployeeAssessors>('skillsAssessor')) val = val[key];

      if (val == null) {
        thereAreEmpty = true;
        break;
      } else if (val === selectedAssessor) {
        thereAreSelected = true;
      } else {
        avoidCount++;
      }
    }

    if (avoidCount === rows.length)
      return (
        <>
          {header} <Checkbox checked={false} disabled />
        </>
      );

    if (!thereAreSelected && !thereAreEmpty) return header;

    const chk = !thereAreEmpty && thereAreSelected;
    return (
      <>
        {header} <Checkbox checked={chk} onChange={(_, { checked }) => this.setSelectedAssesorToAll(checked, prop, key)} />
      </>
    );
  };

  private setSelectedAssesorToAll = (setValue: boolean, prop: string, key: string) => {
    const { employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    const { selectedAssessor } = this.state;
    if (selectedAssessor == null) return;
    const rows = getKeyValuePairs<SsaEmployeeAssessors>(matrix);
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].value;
      if (prop === nameof<SsaEmployeeAssessors>('skillsAssessor')) {
        if (setValue && row[prop][key] == null) row[prop][key] = selectedAssessor;
        if (!setValue && row[prop][key] === selectedAssessor) row[prop][key] = null;
      } else {
        if (setValue && row[prop] == null) row[prop] = selectedAssessor;
        if (!setValue && row[prop] === selectedAssessor) row[prop] = null;
      }
    }

    this.props.ssaFormWizardStore.change({ employeesAssessors: { ...matrix } });
  };

  private setSelectedAssesorToEmployee = (setValue: boolean, id: string, prop: string, key: string) => {
    const { employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    const { selectedAssessor } = this.state;
    if (matrix[id] == null) return;

    if (prop === nameof<SsaEmployeeAssessors>('skillsAssessor')) {
      matrix[id][prop][key] = setValue ? selectedAssessor : null;
    } else {
      matrix[id][prop] = setValue ? selectedAssessor : null;
    }

    this.props.ssaFormWizardStore.change({ employeesAssessors: { ...matrix } });
  };

  private isFunctionalExpertOf = (assesorId: string, employeeId: string) => {
    if (isNullOrWhiteSpaces(assesorId) || isNullOrWhiteSpaces(employeeId)) return false;
    const { employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    if (matrix == null) return false;
    const row = matrix[employeeId];
    if (row == null) return false;
    return getKeyValuePairs<string>(row.skillsAssessor || {})
      .map(({ value }) => value)
      .includes(assesorId);
  };

  private isFunctionalExpert = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    const { employeesAssessors: matrix } = this.props.ssaFormWizardStore.state.item;
    if (matrix == null) return false;
    return getProperties(matrix).any(({ key: employeeId }) => this.isFunctionalExpertOf(assesorId, employeeId));
  };

  private isUsed = (assesorId: string) => {
    if (isNullOrWhiteSpaces(assesorId)) return false;
    return this.isFunctionalExpert(assesorId);
  };

  private getSkillsFromTemplate = (ssaTemplate: SsaTemplateDto) => {
    if (!ssaTemplate) return null;

    const skills: { [skillId: string]: string } = {};
    ssaTemplate.skillSectionCategories.forEach(section => section.skillSections.forEach(s => (skills[s.skillId] = s.skillName)));

    return skills;
  };

  private clearAllAssessors = () => {
    const { item } = this.props.ssaFormWizardStore.state;

    const employeesAssessors: { [employeeId: string]: SsaEmployeeAssessors } = {};
    Object.keys(item.employeesAssessors || {}).forEach(assessorId => {
      employeesAssessors[assessorId] = item.employeesAssessors[assessorId];
      Object.keys(item.employeesAssessors[assessorId].skillsAssessor || {}).forEach(skillId => {
        employeesAssessors[assessorId].skillsAssessor[skillId] = null;
      });
    });
    this.props.ssaFormWizardStore.change({ employeesAssessors });

    this.setState({ selectedAssessor: null });
  };

  render() {
    const { t, active, isEditMode, isGlobalPoc } = this.props;
    const { location, template, employees, assessors, profileId } = this.props.ssaFormWizardStore.state.item;
    const { showEmployeeModal, selectedAssessor, showAssessorModal } = this.state;

    let assessorsList: UserDto[] = [];
    let employeesList: UserProfilesDto[] = [];
    let skillsList: { key: string; value: string }[] = [];

    if (active) {
      assessorsList = getKeyValuePairs<UserDto>(assessors).map(x => x.value);
      employeesList = getKeyValuePairs<UserProfilesDto>(employees).map(x => x.value);
      skillsList = getKeyValuePairs<string>(this.getSkillsFromTemplate(template));
    }

    return (
      <div className="wizard__step wizard__step-three ssa-form-general-data">
        <StepsComponent active={active} number={'3'} title={t('SELECT EMPLOYEES & ASSIGN ASSESSORS')}>
          <div className="new-ssa-form-wizard_step3__btn-dropdown flex-start" id="">
            {!isEditMode && (
              <Button className="new-ssa-form-wizard_step3-row_btn" onClick={this.showEmployeeModal}>
                {t('Add Employees')}
              </Button>
            )}
            {showEmployeeModal && (
              <ChooseEmployeesView
                showPositionCodeFilter
                showEmployeeIdFilter
                locationId={location?.id || null}
                profileId={profileId || null}
                alreadySelectedUserIds={employeesList.map(x => x.userId)}
                onAddEmployees={this.onEmployeesSelected}
                showColumns={employeesModalColumnDef}
                onCloseUsersModal={this.onCloseEmployeeModal}
                isGlobalPoc={isGlobalPoc}
              />
            )}

            {employeesList.length !== 0 && assessorsList.length !== 0 && (
              <div className="flex-start-center">
                <Dropdown
                  id="new-ssa-form-wizard_step3-row1_assessor-select"
                  fluid
                  selection
                  options={assessorsList.map(a => ({ value: a.id, text: `${a?.firstName} ${a?.lastName}` }))}
                  value={selectedAssessor}
                  closeOnBlur={true}
                  closeOnEscape={true}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  placeholder={t('Assign Assessor')}
                  onChange={this.onAssessorSelectorChanged}
                  clearable
                />

                <Button
                  disabled={assessorsList.length !== 0 ? false : true}
                  className="new-ssa-form-wizard_step3-row_btn clear-all__btn"
                  onClick={this.clearAllAssessors}
                >
                  {t('Clear All')}
                </Button>
              </div>
            )}
          </div>

          {employeesList.length !== 0 && (
            <div className="new-ssa-form-wizard_step3-matrix-container tooltip-visible scroll">
              <Grid
                id="new-ssa-form-wizard_step3-matrix-table"
                className="new-ssa-form-wizard_step3-table new-ssa-form-wizard_step3-table__assessor"
              >
                <Grid.Row className="new-ssa-form-wizard_step3-table-row new-ssa-form-wizard_step3-table-header">
                  <Grid.Column className="new-ssa-form-wizard_step3-table-cell">{t('Last Name')}</Grid.Column>
                  <Grid.Column className="new-ssa-form-wizard_step3-table-cell">{t('First Name')}</Grid.Column>
                  <Grid.Column className="new-ssa-form-wizard_step3-table-cell">{t('SF Position')}</Grid.Column>
                  <Grid.Column className="new-ssa-form-wizard_step3-table-cell">{t('Position Code')}</Grid.Column>
                  {assessorsList.length !== 0 &&
                    skillsList.map(kvp => (
                      <Grid.Column key={kvp.key} className="new-ssa-form-wizard_step3-table-cell">
                        {this.getEmployeesHeader(kvp.value, nameof<SsaEmployeeAssessors>('skillsAssessor'), kvp.key)}
                      </Grid.Column>
                    ))}
                  <Grid.Column
                    width={1}
                    className="new-ssa-form-wizard_step3-table-cell new-ssa-form-wizard_step3-table-remove-cell"
                  ></Grid.Column>
                </Grid.Row>

                {employeesList.map(e => (
                  <Grid.Row key={`matrix_${e.userId}`} className="new-ssa-form-wizard_step3-table-row">
                    <Grid.Column className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(e.lastName || '').toUpperCase()} />
                    </Grid.Column>

                    <Grid.Column className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(e.firstName || '').toUpperCase()} />
                    </Grid.Column>

                    <Grid.Column className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(e.sfPosition || '').toUpperCase()} />
                    </Grid.Column>
                    <Grid.Column className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(e.positionCodeName || '').toUpperCase()} />
                    </Grid.Column>

                    {assessorsList.length !== 0 &&
                      skillsList.map(kvp => (
                        <Grid.Column key={kvp.key} className="new-ssa-form-wizard_step3-table-cell">
                          {this.getEmployeesCell(e.userId, nameof<SsaEmployeeAssessors>('skillsAssessor'), kvp.key)}
                        </Grid.Column>
                      ))}

                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-ssa-form-wizard_step3-table-cell  new-ssa-form-wizard_step3-table-remove-cell"
                    >
                      {!isEditMode && (
                        <Icon
                          size="large"
                          name="close"
                          color="red"
                          className="clickable-icon"
                          onClick={() => this.removeEmployee(e.userId)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </div>
          )}
          {employeesList.length !== 0 && (
            <>
              <Button className="new-ssa-form-wizard_step3-row_btn add-assessor__btn" onClick={this.showAssessorModal}>
                {t('Add Assessors')}
              </Button>
              {showAssessorModal && (
                <ChooseAssessorsView
                  showEmployeeIdFilter
                  showPositionCode
                  showLocation
                  alreadySelectedUserIds={[...assessorsList.map(a => a.id), ...employeesList.map(e => e.userId)]}
                  onAddAssessors={this.onAssessorsSelected}
                  onCloseUsersModal={this.onCloseAssessorModal}
                />
              )}
            </>
          )}
          {employeesList.length !== 0 && assessorsList.length !== 0 && (
            <Grid id="new-ssa-form-wizard_step3-assessors-table" className="new-ssa-form-wizard_step3-table">
              <Grid.Row columns={4} className="new-ssa-form-wizard_step3-table-row new-ssa-form-wizard_step3-table-header">
                <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                  {t('Last Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                  {t('First Name')}
                </Grid.Column>
                <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                  {t('SF Position')}
                </Grid.Column>
                <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                  {t('Position Code')}
                </Grid.Column>
                <Grid.Column
                  width={1}
                  className="new-ssa-form-wizard_step3-table-cell new-ssa-form-wizard_step3-table-remove-cell"
                ></Grid.Column>
              </Grid.Row>
              {assessorsList.map(a => (
                <Fragment key={a.id}>
                  <Grid.Row
                    columns={4}
                    key={`assessors_${a.id}`}
                    className={
                      isEditMode ? 'new-ssa-form-wizard_step3-table-row table-row__not-bottom-white' : 'new-ssa-form-wizard_step3-table-row'
                    }
                  >
                    <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(a.lastName || '').toUpperCase()} />
                    </Grid.Column>
                    <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(a.firstName || '').toUpperCase()} />
                    </Grid.Column>
                    <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={(a.sfPosition || '').toUpperCase()} />
                    </Grid.Column>
                    <Grid.Column width={5} className="new-ssa-form-wizard_step3-table-cell">
                      <TableTooltipCell textToShow={((a.positionCode && a.positionCode.code) || '').toUpperCase()} />
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      width={1}
                      className="new-ssa-form-wizard_step3-table-cell  new-ssa-form-wizard_step3-table-remove-cell"
                    >
                      {!this.isUsed(a.id) && (
                        <Icon size="large" name="close" color="red" className="clickable-icon" onClick={() => this.removeAssessor(a.id)} />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Fragment>
              ))}
            </Grid>
          )}
        </StepsComponent>
      </div>
    );
  }
}

const employeesModalColumnDef: ColumnsNeeds<UserProfilesDto>[] = [
  { column: 'lastName', title: 'Last Name', tooltipRenderer: false },
  { column: 'firstName', title: 'First Name', tooltipRenderer: false },
  { column: 'employeeId', title: 'SAP ID', tooltipRenderer: false },
  { column: 'sfPosition', title: 'SF Position', tooltipRenderer: false },
  { column: 'positionCodeName', title: 'Position Code', tooltipRenderer: false }
];

export default withTranslation()(EmployeesAndAssessorsStep);
