import React, { FC, memo } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EventDto } from 'stores/events/events-store';
import SchedulerAllPlannerAssistantSuggestionPage from 'site/pages/landing-pages/scheduler-instructor-page/scheduler-all-planner-assistant-suggestion-page';

interface SchedulerViewModalProps extends WithTranslation {
  events: EventDto[];
  open: boolean;
  onClose: () => void;
}

const SchedulerViewModal: FC<SchedulerViewModalProps> = props => {
  const { open, events, onClose, t } = props;
  return (
    <Modal open={open} size="large" className="have-warnings-popup" closeOnEscape={true} onClose={onClose}>
      <Modal.Content className="have-warnings-popup__content">
        <SchedulerAllPlannerAssistantSuggestionPage suggestedEvents={events} />
      </Modal.Content>
      <div className="have-warnings-popup__buttons-container">
        <Button className="have-warnings-popup__btn" content={t('Close')} onClick={onClose} />
      </div>
    </Modal>
  );
};

export default withTranslation()(memo(SchedulerViewModal));
