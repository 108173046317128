import * as React from 'react';
import { CSSProperties } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export interface MoneyInputProps {
  value: number;
  onChange: (value: number) => void;
  placeholder?: string;
  currencyChar?: string;
  style?: CSSProperties;
  allowNegative?: boolean;
  disabled?: boolean;
  className?: string;
  decimals?: number;
}

interface MoneyInputState {
  value: number;
  decimalSeparator: string;
  thousandSeparator: string;
}

export class MoneyInput extends React.Component<MoneyInputProps, MoneyInputState> {
  decimalSeparator = '.';
  thousandSeparator = ',';
  decimalPlaces = this.props.decimals || 4;
  constructor(props: MoneyInputProps) {
    super(props);
    // tslint:disable-next-line:variable-name

    this.state = {
      value: this.props.value,
      decimalSeparator: this.decimalSeparator,
      thousandSeparator: this.thousandSeparator
    };
  }

  private handleNumberFormatValue = (values: NumberFormatValues) => {
    if (this.props.onChange) {
      this.props.onChange(values.floatValue);
    }
  };

  public render() {
    return (
      <NumberFormat
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        allowNegative={!!this.props.allowNegative}
        decimalScale={this.decimalPlaces}
        suffix={this.props.currencyChar ? ` ${this.props.currencyChar}` : ''}
        style={this.props.style}
        value={this.props.value}
        thousandSeparator={this.thousandSeparator}
        decimalSeparator={this.decimalSeparator}
        onValueChange={this.handleNumberFormatValue}
        className={this.props.className}
        inputMode="numeric"
      />
    );
  }
}
