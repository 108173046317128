import React, { FC, memo, useContext, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import TNAFormSectionComponent from './tna-form-section.component';
import {
  SkillAnswersDto,
  MachineUnitAnswersDto,
  Average,
  SkillAverages,
  TnaFormItemDto,
  MachineModelAnswersDto,
  TnaFormListStore
} from 'stores/assessments/forms/tna-forms-store';
import { TNAFormContext } from './tna-edit-form';
import TnaFormTableScore from './tna-form-table-score';
import { TnaTheoreticalTestItemDto } from 'stores/assessments/forms/tna-theoretical-tests-store';
import { Button } from 'semantic-ui-react';
import { openInNewWindow } from 'utils/useful-functions';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';
import CopyAnswersEditor from 'widgets/bussiness/copy-answers-editor';
import { ItemReference } from 'stores/dataStore';
import LanguageEditor from 'widgets/bussiness/language-editor';

interface TNAFormTabProps extends WithTranslation {
  machineRelated?: boolean;
  machineUnitAnswers?: MachineUnitAnswersDto[];
  nonMachineRelatedSection?: SkillAnswersDto[];
  scoresVisibility: boolean;
  machineModelId?: string;
  totalSkillAverage?: Average;
  machineModel?: string;
  skillAverages?: SkillAverages[];
  theoreticals?: TnaTheoreticalTestItemDto[];
  tnaTemplateId?: string;
  otherMachinesModelAnswers?: MachineModelAnswersDto[];
  onExportExcel?: () => void;
  overallRecommendation?: string;
  overallRecommendationId?: string;
  tnaFormListStore?: TnaFormListStore;
}

const TNAFormTabComponent: FC<TNAFormTabProps> = ({ t, onExportExcel, tnaFormListStore, ...props }) => {
  const {
    getAssessorType,
    answerHandler,
    getAStatus,
    recommendationHandler,
    overallRecommendationHandler,
    submittable,
    copyAnswersFromMachinesWithSameTemplate,
    ...contextValues
  } = useContext(TNAFormContext);

  const { isAdminOrPoC, isAssessor } = contextValues;

  const { overallRecommendation, overallRecommendationId } = props;
  const [machineModelSeleted, setMachineModelSeleted] = useState({});

  const status = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => (summary.item as TnaFormItemDto).status) as string;
  const id = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => (summary.item as TnaFormItemDto).id) as string;

  const assessorAnswerStatus = getAStatus();

  const getMRAverages = (isMachineUnit: boolean = true) => {
    const returnItem: SkillAnswersDto[] = [];

    const { machineUnitAnswers, skillAverages, totalSkillAverage } = props;

    (machineUnitAnswers || []).length > 0 &&
      machineUnitAnswers.forEach(({ average, functionalExpertRecomendation, machineUnitName, machineUnitId, skillAnswers }) => {
        if (isMachineUnit)
          returnItem.push({
            average,
            functionalExpertRecomendation,
            skillId: machineUnitId,
            skillName: machineUnitName,
            questionAnswers: []
          });
        else
          (skillAnswers || []).forEach(({ skillId, skillName, functionalExpertRecomendation: funcExpRec, questionAnswers }) => {
            const ave = skillAverages[skillId];
            const currentSkill = returnItem.find(x => x.skillId === skillId);
            if (ave && !currentSkill) {
              returnItem.push({ average: ave, skillId, skillName, functionalExpertRecomendation: funcExpRec, questionAnswers });
            }
          });
      });
    if (!isMachineUnit) {
      returnItem.push({
        average: totalSkillAverage,
        functionalExpertRecomendation: '',
        questionAnswers: [],
        skillId: 'totalSkillsScore',
        skillName: 'TOTAL SCORE',
        isTotal: true
      } as SkillAnswersDto);
    }
    return returnItem;
  };

  const openTheoretical = ({ id: theoId }: TnaTheoreticalTestItemDto) => {
    openInNewWindow(`./assessments/theoretical-test/${theoId}?FromTna=${id}`);
  };

  const { machineRelated, machineUnitAnswers, nonMachineRelatedSection, machineModelId, tnaTemplateId } = props;
  const { scoresVisibility, theoreticals } = props;
  const mrSkillAverages: SkillAnswersDto[] = getMRAverages(false);
  const mrMachineUnitAverages: SkillAnswersDto[] = getMRAverages();

  if (machineRelated)
    return (
      <>
        {(status.toString() === 'Done' ||
          isAdminOrPoC ||
          (!isAdminOrPoC && isAssessor && assessorAnswerStatus?.answerStatus === 'Done') ||
          (!isAdminOrPoC && isAssessor && scoresVisibility)) && (
          <>
            <div className="machine-related__score-by-skills-table__container">
              <TnaFormTableScore
                isSkill
                overallRecommendationId={overallRecommendationId}
                overallRecommendation={overallRecommendation}
                machineModelId={machineModelId}
                section={mrSkillAverages}
                scoreTitle={'SCORE BY SKILLS'}
                onRecommendationChange={(value: string, id: string, isMachineUnit: boolean) =>
                  !isAdminOrPoC && status.toString() !== 'Done' && recommendationHandler(value, id, isMachineUnit, machineModelId)
                }
                onOverallRecommendationChange={(value: ItemReference /*, machinemodelId: string*/) =>
                  //                  !isAdminOrPoC && status.toString() !== 'Done' && recommendationHandler(value, id, isMachineUnit, machineModelId)
                  overallRecommendationHandler(value, machineModelId)
                }
              />
              <br />
              <TnaFormTableScore
                isSkill={false}
                section={mrMachineUnitAverages}
                scoreTitle={'SCORE BY MACHINE UNIT'}
                onRecommendationChange={(value: string, id: string, isMachineUnit: boolean) =>
                  !isAdminOrPoC && status.toString() !== 'Done' && recommendationHandler(value, id, isMachineUnit, machineModelId)
                }
              />
            </div>
            <Button className="tna-form-btn" onClick={onExportExcel}>
              {t(`Export to Excel`)}
            </Button>
          </>
        )}
        <div id="tnaLanguageSelector" className="ssa-form-language-selector">
          <LanguageEditor
            className="planit-user-dropdown-custom"
            placeholder={t('Select Language')}
            clearable
            nullable
            value={tnaFormListStore.state.item.currentLanguage || null}
            onChange={val => tnaFormListStore.changeCurrentLanguage(val?.id || null)}
            onlyForTests
          />
        </div>
        <div className="machine-related-section">
          {(theoreticals || []).map((theoretical, i) => (
            <Button key={theoretical.id + i} className="tna-form-theoretical-btn" onClick={() => openTheoretical(theoretical)}>
              {t(`Open TNA Theoretical Test ${theoretical?.tnaDetails?.title || ''}`)}
            </Button>
          ))}
        </div>

        {!isAdminOrPoC && (
          <div className="copy-answer-editor">
            <label>{t('Copy answers from other machine model: ')}</label>
            <CopyAnswersEditor
              className="planit-user-dropdown-custom"
              nullable
              value={machineModelSeleted[machineModelId]}
              placeholder={t('Machine Model')}
              otherMachinesModelAnswers={props.otherMachinesModelAnswers}
              onChange={value => {
                setMachineModelSeleted(state => ({ ...state, [machineModelId]: value?.id }));
                copyAnswersFromMachinesWithSameTemplate(value?.id, machineModelId);
              }}
              reloadOnChange={true}
            />
          </div>
        )}

        {(machineUnitAnswers || []).map(({ machineUnitName, machineUnitId, skillAnswers }) => (
          <div key={machineUnitId} className="machine-related-section">
            <h2>{machineUnitName || ''}</h2>
            <TNAFormSectionComponent
              skillSections={skillAnswers}
              machineModelId={machineModelId}
              tnaTemplateId={tnaTemplateId}
              otherMachinesModelAnswers={props.otherMachinesModelAnswers}
              onQuestionAnswered={(answer, questionId, skillId, comment) =>
                (assessorAnswerStatus?.answerStatus === 'Pending' || assessorAnswerStatus?.answerStatus === 'Unknown') &&
                answerHandler(answer, questionId, skillId, machineUnitId, machineModelId, comment)
              }
            />
          </div>
        ))}
      </>
    );
  else
    return (
      nonMachineRelatedSection && (
        <>
          {(status === 'Done' ||
            isAdminOrPoC ||
            (!isAdminOrPoC && isAssessor && assessorAnswerStatus?.answerStatus === 'Done') ||
            (!isAdminOrPoC && isAssessor && scoresVisibility)) && (
            <>
              <div className="machine-related__score-by-skills-table__container">
                <TnaFormTableScore
                  isSkill
                  scoreTitle={'SCORE BY SKILLS'}
                  section={nonMachineRelatedSection}
                  onRecommendationChange={(value: string, id: string, isMachineUnit: boolean) =>
                    !isAdminOrPoC && status !== 'Done' && recommendationHandler(value, id, isMachineUnit)
                  }
                />
              </div>
              <Button className="tna-form-btn" onClick={onExportExcel}>
                {t(`Export to Excel`)}
              </Button>
            </>
          )}
          <div id="tnaLanguageSelector" className="ssa-form-language-selector">
            <LanguageEditor
              className="planit-user-dropdown-custom"
              placeholder={t('Select Language')}
              clearable
              nullable
              value={tnaFormListStore.state.item.currentLanguage || null}
              onChange={val => tnaFormListStore.changeCurrentLanguage(val?.id || null)}
              onlyForTests
            />
          </div>
          <TNAFormSectionComponent
            machineModelId={machineModelId}
            skillSections={nonMachineRelatedSection}
            onQuestionAnswered={(answer, questionId, skillId, comment) =>
              (assessorAnswerStatus?.answerStatus === 'Pending' || assessorAnswerStatus?.answerStatus === 'Unknown') &&
              answerHandler(answer, questionId, skillId, null, null, comment)
            }
          />
        </>
      )
    );
};

TNAFormTabComponent.defaultProps = { machineRelated: false };
export default withTranslation()(memo(TNAFormTabComponent));
