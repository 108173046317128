import {
  OverallRecommendationsStore,
  NewOverallRecommendationStore,
  ChangeOverallRecommendationStore
  //ChangeLogOverallRecommendationStore
} from './overall-recommendations-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new OverallRecommendationsStore());
  storeBuilder.addRepository(new NewOverallRecommendationStore());
  storeBuilder.addRepository(new ChangeOverallRecommendationStore());
  //storeBuilder.addRepository(new ChangeLogOverallRecommendationStore());
}
