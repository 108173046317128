import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Dropdown } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { TableModel, TableView } from 'widgets/collections/table';
import NewIEView from './new-instructor-expertise-request';
import ViewIEView from './view-instructor-expertise-request';
import { EventTypesStore } from 'stores/configuration/events-workflow/event-types-store';
import { DropDownEquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import { DropdownOemsStore } from 'stores/configuration/machinery/oems-store';
import { DropDownMachineModelsStore } from 'stores/configuration/machinery/machine-models-store';
import { DropDownTrainingLevelsStore } from 'stores/configuration/events-n-requests/training-levels-store';
import { DropDownNMFunctionalAreasStore } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { DropDownNMFunctionalSubareasStore } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { DropDownNMTrainingNamesStore } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { DropDownProfessionsStore } from '../../../../stores/configuration/profiles/profession-roles-store';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMFunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { nameof, getProperties } from 'utils/object';
import UserEditor from 'widgets/bussiness/user-by-role-editor';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import './instructor-expertise-requests.less';
import {
  InstructorExpertiseRequestsStore,
  InstructorExpertiseRequestDto,
  ExpertiseRequestStatus
} from 'stores/skills/instructor-expertise-request-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface InstructorExpertiseRequestsListProps extends WithTranslation, RouteComponentProps {
  expertiseRequestsStore: InstructorExpertiseRequestsStore;
  eventTypesStore: EventTypesStore;
  equipmentTypesStore: DropDownEquipmentTypesStore;
  oemsStore: DropdownOemsStore;
  machineModelsStore: DropDownMachineModelsStore;
  trainingLevelsStore: DropDownTrainingLevelsStore;
  nmrFunctionalAreasStore: DropDownNMFunctionalAreasStore;
  nmrFunctionalSubAreasStore: DropDownNMFunctionalSubareasStore;
  trainingNamesStore: DropDownNMTrainingNamesStore;
  professionsStore: DropDownProfessionsStore;
}

export interface InstructorExpertiseRequestsListState {
  query: Query;
  newModalShown: boolean;
  viewModalShown: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  eventType: ItemReference;
  active: boolean;
  cluster: ItemReference;
  NMRcluster: ItemReference;
  role: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  functionalArea: ItemReference;
  functionalSubArea: ItemReference;
  trainingLevel: ItemReference;
  instructor: ItemReference;
  isMachineRelated: boolean;
  status: ExpertiseRequestStatus;
  trainingName: ItemReference;
}

@connect(
  ['expertiseRequestsStore', InstructorExpertiseRequestsStore],
  ['eventTypes', EventTypesStore],
  ['equipmentTypes', DropDownEquipmentTypesStore],
  ['oems', DropdownOemsStore],
  ['machineModels', DropDownMachineModelsStore],
  ['trainingLevels', DropDownTrainingLevelsStore],
  ['nmrFunctionalAreas', DropDownNMFunctionalAreasStore],
  ['nmrFunctionalSubAreas', DropDownNMFunctionalSubareasStore],
  ['trainingNames', DropDownNMTrainingNamesStore],
  ['professions', DropDownProfessionsStore]
)
class InstructorExpertiseRequestsListPage extends React.Component<
  InstructorExpertiseRequestsListProps,
  InstructorExpertiseRequestsListState
> {
  timer: any = null;
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
        skip: 0,
        take: 10,
        parameters: { hideDeprecated: 'true' }
      },
      newModalShown: false,
      viewModalShown: false,
      selectedItem: null,
      active: true,
      activeFilters: [],
      eventType: null,
      cluster: null,
      role: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      functionalArea: null,
      functionalSubArea: null,
      trainingLevel: null,
      instructor: null,
      isMachineRelated: false,
      status: null,
      trainingName: null,
      NMRcluster: null
    };
  }

  private isInstructor = () => {
    const userinfo = this.identityService.getUserInfo();
    return !this.isPowerInstructor() && IdentityService.isInstructor(userinfo);
  };

  private isPowerInstructor = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPowerInstructor(userinfo);
  };

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    const activeFilters = this.state.activeFilters;
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );

    let orderBy = [...this.state.query.orderBy];
    const query = Object.assign(this.state.query, { filter, orderBy });
    this.setState({ query }, () => {
      this.props.expertiseRequestsStore.getAllAsync(this.state.query);
    });
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onDelete(item: InstructorExpertiseRequestDto, state: ItemState): Promise<CommandResult<any>> {
    if (!this.isInstructor()) return;
    if (state !== 'New') {
      await this.props.expertiseRequestsStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.filter(x => x.id !== 'instructor').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    if (!this.isInstructor()) return;
    this.setState({ newModalShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newModalShown: false });

    if (isSuccess) this.load();
  }

  private onNMRClusterEditorChange(value: ItemReference) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRClusterId'];
    } else {
      activeFilters['NMRClusterId'] = {
        NMRClusterId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }
    this.setState(
      {
        activeFilters: activeFilters,
        NMRcluster: value
      },
      this.load
    );
  }

  onChangeFunctionalAreaFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['nmrFunctionalAreaId'];
    } else {
      activeFilters['nmrFunctionalAreaId'] = {
        nmrFunctionalAreaId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        functionalArea: value
      },
      this.load
    );
  }

  @autobind
  private onChangeTrainingName(value: ItemReference) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRTrainingNameId'];
    } else {
      activeFilters['NMRTrainingNameId'] = {
        NMRTrainingNameId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        trainingName: value
      },
      this.load
    );
  }

  onChangeFunctionalSubAreaFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRFunctionalSubAreaId'];
    } else {
      activeFilters['NMRFunctionalSubAreaId'] = {
        NMRFunctionalSubAreaId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        functionalSubArea: value
      },
      this.load
    );
  }

  private onClusterEditorChange(value: ItemReference) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['MachineRelatedClusterId'];
    } else {
      activeFilters['MachineRelatedClusterId'] = {
        MachineRelatedClusterId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }
    this.setState(
      {
        activeFilters: activeFilters,
        cluster: value
      },
      this.load
    );
  }

  onEquipmentTypeFilterChange(value: ItemReference, fieldName) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters[fieldName];
    } else {
      activeFilters[fieldName] = {
        equipmentTypeId: { eq: { type: 'guid', value: value.id } }
      };
    }
    this.setState(
      {
        activeFilters: activeFilters,
        equipmentType: value
      },
      this.load
    );
  }

  onOemFilterChange(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['oem'];
    } else {
      activeFilters['oem'] = {
        oemId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        oem: value
      },
      this.load
    );
  }

  onChangeMachineModelFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['machineModelId'];
    } else {
      activeFilters['machineModelId'] = {
        machineModelId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        machineModel: value
      },
      this.load
    );
  }

  @autobind
  private onInstructorNameFilterChanged = (value: ItemReference) => {
    this.timer = setTimeout(() => {
      const activeFilters = this.state.activeFilters;
      if (!value) {
        delete activeFilters['instructors'];
      } else {
        activeFilters['instructors'] = {
          InstructorId: {
            eq: { type: 'guid', value: value.id }
          }
        };
      }
      this.setState(
        {
          activeFilters: activeFilters,
          instructor: value
        },
        this.load
      );
    }, 1000);
  };

  private handleFilterStatus = (_, { value }) => {
    const status = value;
    const activeFilters = this.state.activeFilters;
    if (!status) {
      delete activeFilters['status'];
    } else {
      activeFilters['status'] = { Status: value };
    }
    this.setState(
      {
        activeFilters: activeFilters,
        status
      },
      this.load
    );
  };

  @autobind
  private onEventTypeFilterChanged(value: ItemReference, fieldName) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters[fieldName];
    } else {
      activeFilters[fieldName] = {
        originalEventTypeId: { eq: { type: 'guid', value: value.id } }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        eventType: value
      },
      this.load
    );
  }

  onProfessionChange(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['profession'];
    } else {
      activeFilters['profession'] = {
        roleId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        role: value
      },
      this.load
    );
  }

  onChangeTrainingLevelFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['trainingLevelId'];
    } else {
      activeFilters['trainingLevelId'] = {
        trainingLevelId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        trainingLevel: value
      },
      this.load
    );
  }

  private onChangeMachineRelated() {
    if (this.state.isMachineRelated === true) {
      this.clearMrFilters();
      this.setState({ isMachineRelated: false }, this.load);
    } else {
      this.clearNmrFilters();
      this.setState({ isMachineRelated: true }, this.load);
    }
  }
  @autobind
  private clearMrFilters() {
    const activeFilters = this.state.activeFilters;
    delete activeFilters['MachineRelatedClusterId'];
    delete activeFilters['equipmentTypeId'];
    delete activeFilters['oemId'];
    delete activeFilters['machineModelId'];
    this.setState({
      activeFilters: activeFilters,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null
    });
  }
  @autobind
  private clearNmrFilters() {
    const activeFilters = this.state.activeFilters;
    delete activeFilters['NMRClusterId'];
    delete activeFilters['nmrFunctionalAreaId'];
    delete activeFilters['NMRTrainingNameId'];
    delete activeFilters['NMRFunctionalSubAreaId'];
    this.setState({
      activeFilters: activeFilters,
      functionalArea: null,
      functionalSubArea: null,
      trainingName: null,
      NMRcluster: null
    });
  }
  @autobind
  private clearAllFilters() {
    this.clearMrFilters();
    this.clearNmrFilters();
    this.setState(
      {
        activeFilters: [],
        instructor: null,
        eventType: null,
        role: null,
        trainingLevel: null,
        status: null
      },
      this.load
    );
  }

  public render() {
    const { t } = this.props as any;
    const anyFilter = this.state.activeFilters && Object.keys(this.state.activeFilters).length > 0;
    const tableModel = {
      columns: [
        {
          title: t('Instructor'),
          tooltipRenderer: true,
          renderer: data => data.instructor,
          selectableHeader: false
        },
        {
          title: t('Event Type'),
          tooltipRenderer: true,
          renderer: data => data.eventTypeName,
          selectableHeader: false
        },
        {
          title: t('Role'),
          tooltipRenderer: true,
          renderer: data => data.roleName,
          selectableHeader: false
        },
        {
          title: t('Level'),
          tooltipRenderer: true,
          renderer: data => data.trainingLevelName,
          selectableHeader: false
        },
        {
          title: t('Cluster Machine Related'),
          tooltipRenderer: true,
          renderer: data => (data.isMachineRelated ? data.machineRelatedClusterName : ''),
          selectableHeader: false
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: true,
          renderer: data => data.equipmentTypeName,
          selectableHeader: false
        },
        {
          title: t('OEM'),
          tooltipRenderer: true,
          renderer: data => data.oemName,
          selectableHeader: false
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: true,
          renderer: data => data.machineModelName,
          selectableHeader: false
        },
        {
          title: t('Cluster Non-Machine'),
          tooltipRenderer: true,
          renderer: data => <>{data.nmrClusterName}</>,
          selectableHeader: false
        },
        {
          title: t('Functional Area'),
          tooltipRenderer: true,
          renderer: data => <>{data.nmrFunctionalAreaName}</>,
          selectableHeader: false
        },
        {
          title: t('Training Name'),
          tooltipRenderer: true,
          renderer: data => data.nmrTrainingName,
          selectableHeader: false
        },
        {
          title: t('Functional Subarea'),
          tooltipRenderer: true,
          renderer: data => data.nmrFunctionalSubAreaName,
          selectableHeader: false
        },
        {
          title: t('Status'),
          tooltipRenderer: true,
          renderer: data => <span className={`expertise-request-tag__${data.status}`}>{data.status}</span>,
          selectableHeader: false
        }
      ],
      data: this.props.expertiseRequestsStore.state
    } as TableModel<InstructorExpertiseRequestDto>;
    return (
      <>
        {this.props.expertiseRequestsStore.state.result && !this.props.expertiseRequestsStore.state.result.isSuccess && (
          <div className={`event-types-list-error-row ${!this.isPowerInstructor() ? 'fix-margin-top' : ''}`}>
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={this.props.expertiseRequestsStore.state.result.messages.map(o => o.body)}
            />
          </div>
        )}

        <div className="filters-wrapper instructor-expertise__filters-wrapper table__filters-share-main-actions">
          <Dropdown
            className="instructors-expertise-list__filter-dropdown filter"
            selection
            options={[
              { text: 'Pending', value: 'Pending' },
              { text: 'Rejected', value: 'Rejected' }
            ]}
            value={this.state.status}
            closeOnBlur={true}
            closeOnEscape={true}
            selectOnBlur={false}
            selectOnNavigation={false}
            placeholder={t('Request status')}
            onChange={this.handleFilterStatus}
            clearable
          />

          {this.isPowerInstructor() && (
            <UserEditor
              className="instructors-expertise-list__filter-dropdown filter"
              clearable={true}
              nullable
              role={'Instructor'}
              value={this.state.instructor}
              placeholder={t('Instructor')}
              onChange={c => this.onInstructorNameFilterChanged(c)}
            ></UserEditor>
          )}

          <EventTypeEditor
            className="instructors-expertise-list__filter-dropdown filter"
            clearable={true}
            nullable
            value={this.state.eventType}
            onChange={value => this.onEventTypeFilterChanged(value, 'eventTypeId')}
            placeholder={t('Event Type')}
            useOriginalEventTypeIdAsValue
            reloadOnChange
          />

          <ProfessionEditor
            className="instructors-expertise-list__filter-dropdown filter"
            clearable={true}
            nullable
            value={this.state.role}
            onChange={c => this.onProfessionChange(c)}
            placeholder={t('Roles')}
            reloadOnChange
          />

          <TrainingLevelEditor
            className="instructors-expertise-list__filter-dropdown filter"
            nullable
            clearable={true}
            value={this.state.trainingLevel}
            onChange={c => {
              this.onChangeTrainingLevelFilter(c);
            }}
            placeholder={t('Training Level')}
          />

          <div className="flex-center-center">
            <label>{t('Machine related')}</label>
            <Button.Group className="filter-button-group green" style={{ width: 108, height: 38, marginTop: 8, marginRight: 16 }}>
              <Button
                className={this.state.isMachineRelated ? 'active gray-border' : 'gray-border'}
                onClick={() => this.onChangeMachineRelated()}
              >
                {t('Yes')}
              </Button>
              <Button
                className={this.state.isMachineRelated === false ? 'active gray-border filter' : 'gray-border filter'}
                onClick={() => this.onChangeMachineRelated()}
              >
                {t('No')}
              </Button>
            </Button.Group>
          </div>

          {this.state.isMachineRelated && (
            <>
              <MRClusterEditor
                className="instructors-expertise-list__filter-dropdown filter"
                clearable={true}
                nullable
                value={this.state.cluster}
                onChange={c => {
                  this.onClusterEditorChange(c);
                }}
                placeholder={t('Cluster')}
              />

              <EquipmentTypeEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                clusterId={this.state.cluster ? this.state.cluster.id : ''}
                value={this.state.equipmentType}
                onChange={c => this.onEquipmentTypeFilterChange(c, nameof<InstructorExpertiseRequestDto>('equipmentTypeId'))}
                placeholder={t('Equipment Type')}
              />

              <OemEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                value={this.state.oem}
                onChange={c => this.onOemFilterChange(c)}
                equipmentId={this.state.equipmentType != null ? this.state.equipmentType.id : undefined}
                placeholder={t('OEM')}
              />

              <MachineModelEditor
                className="instructors-expertise-list__filter-dropdown filter"
                cascade="true"
                nullable
                clearable={true}
                oemId={this.state.oem ? this.state.oem.id : ''}
                equipmentId={this.state.equipmentType ? this.state.equipmentType.id : ''}
                value={this.state.machineModel}
                onChange={c => this.onChangeMachineModelFilter(c)}
                placeholder={t('Machine Model')}
              />
            </>
          )}
          {!this.state.isMachineRelated && (
            <>
              <NMClusterEditor
                className="instructors-expertise-list__filter-dropdown filter"
                clearable={true}
                nullable
                reloadOnChange
                value={this.state.NMRcluster}
                onChange={c => {
                  this.onNMRClusterEditorChange(c);
                }}
                placeholder={t('NMR Cluster')}
              />

              <NMFunctionalAreaEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                value={this.state.functionalArea}
                clusterId={this.state.NMRcluster ? this.state.NMRcluster.id : null}
                onChange={c => {
                  this.onChangeFunctionalAreaFilter(c);
                }}
                placeholder={t('Functional Area')}
              />

              <NMTrainingNameEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                funcionalAreaId={this.state.functionalArea ? this.state.functionalArea.id : null}
                value={this.state.trainingName}
                placeholder={t('Training Name')}
                onChange={c => {
                  this.onChangeTrainingName(c);
                }}
              />

              <NMFunctionalSubAreaEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                trainingNameId={this.state.trainingName ? this.state.trainingName.id : null}
                value={this.state.functionalSubArea}
                onChange={c => {
                  this.onChangeFunctionalSubAreaFilter(c);
                }}
                placeholder={t('Functional Subarea')}
              />
            </>
          )}

          {anyFilter && (
            <>
              <Button
                className="event-type-filter-icon-btn event-type-hide-filters-btn instructor-expertise-filter-icon-btn"
                onClick={this.clearAllFilters}
                icon
              >
                <Icon.Group>
                  <Icon name="filter" />
                  <Icon size="small" className="hide-filter" name="remove" />
                </Icon.Group>
              </Button>
            </>
          )}
        </div>

        <div className="instructor-expertise-table-wrapper">
          <TableView
            model={tableModel}
            onNewItem={this.onNewItem}
            canCreateNew={this.isInstructor()}
            createNewButtonTitle={t('Create Expertise Request')}
            onOrderByChanged={this.handleOrderBy}
            onRefresh={this.load}
            canEdit={false}
            canDelete={this.isInstructor()}
            onDeleteRow={this.onDelete}
            onPageChange={this.handlePageChange}
            onFilterChange={this.handleFilterChange}
            extraActions={[
              {
                content: (
                  <>
                    <Icon name="eye" />
                    &nbsp;{t('View')}
                  </>
                ),
                onClick: item => {
                  this.setState({ selectedItem: item }, () => this.onViewItem());
                }
              }
            ]}
          />
        </div>
        {this.state.newModalShown && <NewIEView onClose={this.onNewItemClosed} {...this.props} />}

        {this.state.viewModalShown && <ViewIEView onClose={this.onViewItemClosed} expertiseRequest={this.state.selectedItem} />}
      </>
    );
  }

  @autobind
  onViewItem(): void {
    this.setState({ viewModalShown: true });
  }

  @autobind
  private onViewItemClosed(isSuccess: boolean) {
    this.setState({ viewModalShown: false });
    if (isSuccess) this.load();
  }
}

export default withTranslation()(InstructorExpertiseRequestsListPage);
