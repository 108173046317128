import React, { FunctionComponent, useReducer } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal, Header, Button, Grid } from 'semantic-ui-react';
import ReturnItem from './return-item';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';
import { Reducer } from 'redux';
import { ReturnObj } from '../ssa-edit-form';

export interface ReturnModalProps extends WithTranslation {
  assessorsToReturn: ReturnObj[];
  onCancel?: () => void;
  onChange: (data: ReturnObj[]) => void;
}
export interface ReturnModalState {
  assessorsToReturn: ReturnObj[];
}

const ReturnModal: FunctionComponent<ReturnModalProps> = ({ t, onCancel, onChange, assessorsToReturn }) => {
  const [{ assessorsToReturn: data }, setState] = useReducer<Reducer<ReturnModalState, any>>(
    (state, newState) => ({ ...state, ...newState }),
    { assessorsToReturn }
  );

  const btnEnable = (): boolean => data.find(({ checked }) => checked) != null;

  const handleChange = (change: any, i: number) => {
    setState({
      assessorsToReturn: data.map((item, idx) => (idx === i ? { ...item, ...change } : item))
    });
  };

  return (
    <div>
      <Modal className="ssa-form-returned-modal" open closeOnEscape={true} onClose={onCancel && onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="modal-header">
          <Header as="h3">
            <span>{t('You are going to Return this SSA form. Who do you want to edit it?')}</span>
          </Header>
          <HeaderSubHeader style={{ marginTop: 0 }} as="h5">
            <span>{t('Those who are not selected for editing will receive the form in read-only.')}</span>
          </HeaderSubHeader>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <Grid divided="vertically">
            {(data || []).map((item, i) => (
              <ReturnItem {...item} onChange={change => handleChange(change, i)} key={i} />
            ))}
          </Grid>
        </Modal.Content>

        <Modal.Actions className="returned-modal__actions">
          <Button className="basic" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button disabled={!btnEnable()} className="add-participants-button" onClick={() => onChange(data)} positive>
            {t('Return SSA')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default withTranslation()(ReturnModal);
