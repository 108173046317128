import { repository, AsyncAction, ReduxRepository } from 'redux-scaffolding-ts';
import { DataStore, ItemCommandResult, DataModel } from 'stores/dataStore';
import { BaseDto, ItemResult } from 'stores/types';
import { IdentityService } from 'services/identity-service';
import { ValidationResult } from 'fluent-ts-validator';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { constants } from 'services/configuration-service';
import { stringify } from 'query-string';

export interface ToDoListItemDto extends BaseDto {
  id: string;
  ownerUserId: string;
  task: string;
  type: TodoType;
  done: boolean;
  relatedItemId?: string;
  createdOn: string;
  modifiedOn: string;
}

export type TodoType =
  | 'Event'
  | 'Request'
  | 'Admin_SuccessFactor'
  | 'Admin_UserImport'
  | 'TnaForm'
  | 'FeedBackForm'
  | 'TheoreticalForm'
  | 'SsaForm'
  | 'IwsForm';

@repository('@@TODOS', 'todos.summary')
export class UserTodoStore extends DataStore<ToDoListItemDto> {
  CLEAR_ALL_TODOS = 'CLEAR_ALL_TODOS';

  baseUrl = 'notifications/v1';
  createPath = null;
  retrievePath = 'get-web-todos';
  updatePath = 'update-todo';
  deletePath = null;
  clearAllPath = 'complete-all-todos';

  protected validate(item: ToDoListItemDto) {
    return new ValidationResult();
  }

  constructor() {
    super('TODO', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });

    this.addReducer(this.CLEAR_ALL_TODOS, this.onClearAllTodos, 'AsyncAction');
  }

  public async clearAllAsync() {
    const httpService = container.get<HttpService>(HttpService);

    const result = await this.dispatchAsync(
      this.CLEAR_ALL_TODOS,
      httpService.post<void, ItemCommandResult>(`${this.baseUrl}/${this.clearAllPath}`, null)
    );

    return result.data;
  }

  protected onClearAllTodos = (): AsyncAction<AxiosResponse<ItemCommandResult>, DataModel<ToDoListItemDto>> => {
    return {
      onStart: () => {
        return { ...this.state, isBusy: true };
      },
      onSuccess: result => {
        let items = this.state.items;
        if (result.data.isSuccess) {
          items.forEach(i => (i.item.done = true));
        }
        return {
          ...this.state,
          isBusy: false,
          items,
          result: {
            isSuccess: result.data.isSuccess,
            items: [],
            messages: result.data.messages
          }
        };
      },
      onError: (error, args) => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? error.response.data
            : {
                isSuccess: false,
                items: [],
                messages: [{ body: error.message || error, level: 'Error' }]
              }
      })
    };
  };
}

export interface ChangeToDoListItemDto {
  Id: string;
  Done: boolean;
}

export interface UndoneToDoCountStoreState {
  unread: number;
  isBusy: boolean;
  result: any;
}

export interface ToDoItemType {
  Event: 10;
  Request: 20;
  Admin_SuccessFactor: 30;
  Admin_UserImport: 31;
  TnaForm: 40;
  SsaForm: 70;
  IwsForm: 80;
}

@repository('@@TODOS', 'todos.unreadcount')
export class UndoneToDoCountStore extends ReduxRepository<UndoneToDoCountStoreState> {
  public DATA_LOADED = 'DATA_LOADED';
  public UNDONE_TODO_COUNT_RECEIVED = 'UNDONE_TODO_COUNT_RECEIVED';
  public HUB_ERROR = 'HUB_ERROR';

  baseUrl = `${constants.apiBaseUrl}/notifications/v1`;
  retrieveCountPath = 'get-todos-not-completed-count';
  negotiatePath = 'user-todos';

  private signalRConnection: HubConnection;

  constructor() {
    super({
      unread: 0,
      isBusy: false,
      result: null
    });

    this.init.bind(this);

    this.addReducer(this.DATA_LOADED, this.onInit, 'AsyncAction');
    this.addReducer(this.HUB_ERROR, this.onHubError, 'Simple');
    this.addReducer(this.UNDONE_TODO_COUNT_RECEIVED, this.onNewMessageReceived, 'Simple');
  }

  protected onHubError = (error: any): UndoneToDoCountStoreState => {
    return { ...this.state, result: error };
  };

  public async init() {
    const httpService = container.get<HttpService>(HttpService);
    const identityService = container.get<IdentityService>(IdentityService);
    await this.dispatchAsync(
      this.DATA_LOADED,
      httpService.get<ItemResult<number>>(`${this.baseUrl}/${this.retrieveCountPath}`),
      identityService.activeRole
    );
  }

  protected onInit = (): AsyncAction<AxiosResponse<ItemResult<number>>, UndoneToDoCountStoreState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true }),
      onSuccess: (result, activeRole) => {
        if (this.signalRConnection != null) {
          this.signalRConnection.stop().catch(err => {
            console.error('HubError', 'stop', err);
          });
        }
        const params = { webrole: activeRole };
        this.signalRConnection = new HubConnectionBuilder()
          .withUrl(`${this.baseUrl}/${this.negotiatePath}?${stringify(params)}`, {
            accessTokenFactory: () => container.get<IdentityService>(IdentityService).accessToken
          })
          .build();

        this.signalRConnection.start().catch(err => {
          console.error('HubError', 'start', err);
          this.dispatch(this.HUB_ERROR, err);
        });

        this.signalRConnection.onclose(err => {
          if (err != null) {
            console.error('HubError', 'onclose', err);
            this.signalRConnection.start();
          }
        });

        this.signalRConnection.on('toDoItemsNotDone', this.newMessageReceived);

        return {
          ...this.state,
          unread: result.data.item,
          isBusy: false,
          result: undefined
        };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  protected newMessageReceived = (count: number) => {
    this.dispatch(this.UNDONE_TODO_COUNT_RECEIVED, count);
  };

  protected onNewMessageReceived = (count: number): UndoneToDoCountStoreState => {
    return {
      ...this.state,
      unread: count
    };
  };
}
