import React, { useRef, useEffect } from 'react';
import { WidgetHelper } from '@planit/bryntum-scheduler';
import './rowheight-style.less';

const RowHeight = ({ onChange }) => {
  // refs and t function
  const elRef = useRef(),
    rowHeightRef: any = useRef();

  const rowHeightSlider = (rowHeightRef.current =
    rowHeightRef.current ||
    WidgetHelper.createWidget({
      type: 'slider',
      min: 64,
      max: 150,
      showValue: false,
      cls: 'rowheight-slider',
      dataUrl: null,
      onInput: ({ value }) => onChange(value),
      value: 64
    }));

  // runs on mounting, renders the rowHeightSlider
  useEffect(() => {
    rowHeightSlider.appendTo = elRef.current;
    rowHeightSlider.render();
  }, [rowHeightSlider]);

  return (
    <b>
      Row Height
      <div ref={elRef}></div>
    </b>
  );
}; // eo function rowHeightSlider

export default RowHeight;
