import React, { Component } from 'react';
import { Dropdown /*, Button*/, FormGroup } from 'semantic-ui-react';
import ScoreList from './score-list';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserProfileDto, SkillDto, UserIwsAssessmentScoreDto, IwsScoreItemDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './score-summary';
import { openInNewWindow, randomNumber } from 'utils/useful-functions';
import IwsRadarChartComponent from '../iws-radar-chart-component';
import * as H from 'history';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { nameof } from 'utils/object';

export interface IwsUserProfileListingDetailsProps extends WithTranslation {
  userProfile: UserProfileDto;
  selectFirstByDefault?: boolean;
  history?: H.History;
}

export interface IwsUserProfileListingDetailsState {
  scoreSummary: ScoreSummary;
  selectedTemplateProfileId: string;
  phasesOptionSeleted: string;
}

class IwsUserProfileListingDetails extends Component<IwsUserProfileListingDetailsProps, IwsUserProfileListingDetailsState> {
  state = { scoreSummary: null, selectedTemplateProfileId: null, phasesOptionSeleted: 'phase0' };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isThisUserAdminOrPoc = (): boolean => this.identityService.isInRole(Roles.Admin) || this.identityService.isInRole(Roles.PoC);

  private isThisUserAnEmployee = (): boolean => this.identityService.isInRole(Roles.Employee);

  componentDidMount() {
    this.onUserProfileLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile !== prevProps.userProfile) this.onUserProfileLoaded();
  }

  onUserProfileLoaded() {
    if (this.props.selectFirstByDefault && this.props.userProfile.assessment.toString() === 'IWS') {
      const templateId = (this.props.userProfile?.userTemplateProfiles || []).firstOrDefault()?.templateId;
      this.onTemplateOrPhaseSelected(templateId, this.state.phasesOptionSeleted);
      return;
    }
    this.setState({ scoreSummary: this.initScoreSummary() });
  }

  initScoreSummary = (): ScoreSummary => {
    if (this.props.userProfile.assessment.toString() === 'IWS') {
      return this.toScoreSummary(this.state.phasesOptionSeleted);
    }
    return {} as ScoreSummary;
  };

  onTemplateOrPhaseSelected = (templateProfileId: any, phase: any): void => {
    //TODO filter skills by templateId
    let scoreSummary = null;
    scoreSummary = this.toScoreSummary(templateProfileId, phase);
    this.setState({ selectedTemplateProfileId: templateProfileId, scoreSummary: scoreSummary, phasesOptionSeleted: phase });
  };

  toScoreSummary = (phase: any, templateProfileId?: any): ScoreSummary => ({
    skillScores: this.toSkillScores(templateProfileId, phase),
    totalScore: 0
  });

  toSkillScores(phase: any, templateProfileId?: any): SkillScore[] {
    var skills: SkillScore[] = [];
    let userSkills = this.props.userProfile.skills;

    let skillScores: { [key: string]: IwsScoreItemDto };
    if (templateProfileId != null) {
      (this.props.userProfile.userTemplateProfiles || []).forEach(x => {
        if (x.iwsCurrentScores) skillScores = { ...skillScores, ...x.iwsCurrentScores.scores };
        else skillScores = this.setEmptySkills(userSkills).scores;
      });
    } else {
      skillScores = this.setEmptySkills(userSkills).scores;
    }

    userSkills.forEach(skill => {
      let item = {
        id: skill.skillId,
        name: skill.skillName,
        score: skillScores[skill.skillId] ? skillScores[skill.skillId].qualifierScore?.score : 0,
        scores: {},
        target: skill[phase],
        isUpdated: skillScores[skill.skillId]?.qualifierScore?.isUpdated ?? false
      };
      const firstAssessmentName = nameof<IwsScoreItemDto>('employeeFirstAssessmentScore');
      const secondAssessmentName = nameof<IwsScoreItemDto>('employeeSecondAssessmentScore');
      const qualifier = nameof<IwsScoreItemDto>('qualifierScore');
      item.scores[firstAssessmentName] = {
        score: skillScores[skill.skillId] ? skillScores[skill.skillId].employeeFirstAssessmentScore?.score : 0,
        isCompleted: skillScores[skill.skillId] ? skillScores[skill.skillId].employeeFirstAssessmentScore?.isCompleted : false
      };
      item.scores[secondAssessmentName] = {
        score: skillScores[skill.skillId] ? skillScores[skill.skillId].employeeSecondAssessmentScore?.score : 0,
        isCompleted: skillScores[skill.skillId] ? skillScores[skill.skillId].employeeSecondAssessmentScore?.isCompleted : false
      };
      item.scores[qualifier] = {
        score: skillScores[skill.skillId] ? skillScores[skill.skillId].qualifierScore?.score : 0,
        isCompleted: skillScores[skill.skillId] ? skillScores[skill.skillId].qualifierScore?.isCompleted : false
      };
      skills.push(item);
    });
    return skills;
  }

  setEmptySkills = (skills: SkillDto[]): UserIwsAssessmentScoreDto => {
    let userScores = {} as UserIwsAssessmentScoreDto;
    let skillScores: any = {};
    for (let i = 0; i < skills.length; i++) {
      let key = skills[i].skillId;
      skillScores[key] = {} as IwsScoreItemDto;
      skillScores[key].skillId = skills[i].skillId;

      skillScores[key].employeeFirstAssessmentScore = { skillId: key, score: 0 };
      skillScores[key].employeeSecondAssessmentScore = { skillId: key, score: 0 };
      skillScores[key].qualifierScore = { skillId: key, score: 0 };
    }
    userScores.scores = skillScores;
    return userScores;
  };

  onReviewLastIwsFormClicked = (id: string) => id && openInNewWindow(`./assessments/iws/iws-edit-form/${id}`);

  onGoToIwsDevPlan = () => {
    const { history } = this.props;
    if (this.isThisUserAdminOrPoc()) history?.replace && history.replace('/assessments/development-plan/iws');
    else if (this.isThisUserAnEmployee()) history?.replace && history.replace('/assessments/development-plan?tab=0');
  };

  render() {
    const { selectedTemplateProfileId, scoreSummary, phasesOptionSeleted } = this.state;
    const { t, userProfile } = this.props;
    const tableHeaders = ['1st Assessment', '2nd Assessment', '3rd Assessment', 'Target'];
    const phasesOptions = [
      { key: 1, text: t('Phase 0'), value: 'phase0' },
      { key: 2, text: t('Phase 1'), value: 'phase1' },
      { key: 3, text: t('Phase 2'), value: 'phase2' },
      { key: 4, text: t('Phase 3'), value: 'phase3' },
      { key: 5, text: t('Phase 4'), value: 'phase4' }
    ];

    return (
      <>
        <div className="employee__-scores-radar">
          <div className=" flex-column iws-table-container">
            {userProfile.assessment.toString() === 'IWS' && (
              <FormGroup>
                <Dropdown
                  search
                  inline
                  selection
                  closeOnChange
                  closeOnEscape
                  options={phasesOptions}
                  className="planit-users-inputs planit-user-dropdown planit-user-selector phases-dropdown"
                  placeholder={t('Select phase')}
                  onChange={(_, { value }) => this.onTemplateOrPhaseSelected(selectedTemplateProfileId, value)}
                  selectOnBlur={false}
                  value={phasesOptionSeleted}
                  closeOnBlur
                />
              </FormGroup>
            )}
            {scoreSummary && (
              <ScoreList
                key={userProfile.id + '-' + randomNumber(0, 100)}
                scoreSummary={scoreSummary}
                type={'table'}
                tableColumnHeaders={tableHeaders}
                source={'iws'}
              />
            )}
          </div>
          {scoreSummary && <IwsRadarChartComponent skills={userProfile.skills} scoreSummary={scoreSummary} />}
        </div>
      </>
    );
  }
}

export default withTranslation()(IwsUserProfileListingDetails);
