import { ItemReference } from 'stores/dataStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { repository } from 'redux-scaffolding-ts';
import { FormStore } from 'stores/formStore';
import { LocationItemReference } from 'widgets/bussiness/location-editor';
import { SsaTemplateDto, SsaSkillSectionQuestionDto } from '../templates/ssa-templates-store';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import { UserDto } from 'stores/users/users-store';
import { ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import moment from 'moment';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DateTimeService } from 'services/datetime-service';
import { getKeyValuePairs } from 'utils/object';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { ItemResult } from 'stores/types';

export class CreateFunctionalExpertDto {
  userId: string;
  skills: string[];
}

export class CreateEmployeeSsaFormDto {
  userId: string;
  functionalExperts: CreateFunctionalExpertDto[];
}
export class CreateSsaFormDto {
  title: string;
  ssaTemplateId: string;
  employees: CreateEmployeeSsaFormDto[];
  deadline: string;
}

export interface SsaEmployeeAssessors {
  skillsAssessor: { [skillId: string]: string };
}

export interface SsaFormWizardViewModel {
  title: string;
  location: ItemReference;
  profileId: string;
  deadline: string;
  template: SsaTemplateDto;
  employees: { [employeeId: string]: UserProfilesDto };
  assessors: { [employeeId: string]: UserDto };
  employeesAssessors: { [employeeId: string]: SsaEmployeeAssessors };
}

export interface ChangeSsaFormDto {
  id: string;
  employee: ChangeEmployeeSsaFormDto;
  deadline: string;
}

export interface ChangeEmployeeSsaFormDto {
  userId: string;
  functionalExperts: ChangeFunctionalExpertDto[];
}

export interface ChangeFunctionalExpertDto {
  userId: string;
  skills: string[];
}

export class CreateFunctionalExpertDtoValidator extends ExtendedAbstractValidator<{ key: string; value: string }> {
  constructor(idx: number, idxAs: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1} Functional Expert ${idxAs + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.key)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Info is wrong')}`);

    this.validateIfString(x => x.value)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('At least one skill info is wrong')}`);
  }
}

export class EmployeeAssessorValidator extends ExtendedAbstractValidator<{ key: string; value: SsaEmployeeAssessors }> {
  constructor(idx: number, skillCount: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Employee at position ${idx + 1}`);
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(`${preffix}: ${i18n.t('No data provided')}`);

    this.validateIfString(x => x.key)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(`${preffix}: ${i18n.t('Employee info is wrong')}`);

    this.validateIfIterable(x => getKeyValuePairs(x.value.skillsAssessor))
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => !!x && !!x.value)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is required')}`);

    this.validateIf(x => getKeyValuePairs<string>(x.value.skillsAssessor))
      .fulfills(x => x.length > 0)
      .fulfills(x => x.all((i, j) => new CreateFunctionalExpertDtoValidator(idx, j, this.addErrors).extendValidate(i).isValid()))
      .when(x => !!x && !!x.value && !!x.value.skillsAssessor)
      .withFailureMessage(`${preffix}: ${i18n.t('Functional Experts info is wrong')}`);
  }
}

export class SsaFormWizardViewModelValidator extends ExtendedAbstractValidator<SsaFormWizardViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.title)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('TNA Title is required'));

    this.validateIfString(x => x.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isSameOrAfter(DateTimeService.today()))
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline) && moment(x.deadline).isValid())
      .withFailureMessage(i18n.t('Deadline date cant be in the past'));

    this.validateIfString(x => x.template.id)
      .isDefined()
      .isNotNull()
      .isUuid('4')
      .when(x => !!x && !!x.template)
      .withFailureMessage(i18n.t('TNA Template is required'));

    this.validateIfNumber(x => this.getSkills(x.template).length)
      .isDefined()
      .isNotNull()
      .isGreaterThan(0)
      .when(x => !!x && !!x.template && !!x.template.skillSectionCategories)
      .withFailureMessage(i18n.t('Template has no skills'));

    this.validateIfIterable(x => getKeyValuePairs(x.employeesAssessors))
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => !!x && !!x.employeesAssessors)
      .withFailureMessage(i18n.t('Employees and Assesors info is required'));

    this.validateIf(x => x)
      .fulfills(x => getKeyValuePairs<SsaEmployeeAssessors>(x.employeesAssessors).length > 0)
      .fulfills(x =>
        getKeyValuePairs<SsaEmployeeAssessors>(x.employeesAssessors).all((i, idx) =>
          new EmployeeAssessorValidator(idx, this.getSkills(x.template).length, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(x => !!x && x.template && x.template.skillSectionCategories && this.getSkills(x.template).length > 0 && !!x.employeesAssessors)
      .withFailureMessage(i18n.t('Employees and Assesors info is wrong'));
  }

  private getSkills = (template: SsaTemplateDto): SsaSkillSectionQuestionDto[] => {
    return template.skillSectionCategories.selectMany(x => x.skillSections).toArray<SsaSkillSectionQuestionDto>();
  };
}

export class ChangeSsaFormWizardValidator extends ExtendedAbstractValidator<ChangeSsaFormDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No data provided'));

    this.validateIfString(x => x.deadline)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => x != null)
      .withFailureMessage(i18n.t('Deadline date is required'));

    this.validateIfString(x => x.deadline)
      .fulfills(x => moment(x).isValid())
      .when(x => x != null && !isNullOrWhiteSpaces(x.deadline))
      .withFailureMessage(i18n.t('Deadline date is wrong'));

    this.validateIf(x => x.employee)
      .isDefined()
      .isNotNull()
      .when(x => !!x && !!x.employee && !!x.employee.userId)
      .withFailureMessage(i18n.t('Assesors info is required'));

    this.validateIf(x => x.employee?.functionalExperts)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .when(x => !!x && !!x.employee.functionalExperts)
      .withFailureMessage(i18n.t('Assesors info is required'));

    this.validateIf(x => x)

      .fulfills(x => x.employee.functionalExperts.length > 0)
      .when(x => !!x && !!x.employee.functionalExperts && !!x.employee.functionalExperts)
      .withFailureMessage(`${i18n.t('Functional Experts info is wrong')}`);
  }
}

@repository('@@SSAFORMS', 'ssa-forms.wizard')
export class SsaFormWizardStore extends FormStore<SsaFormWizardViewModel> {
  baseUrl = 'skills';
  createPath = 'v1/new-ssa-forms';
  retrievePath = '';
  updatePath = 'v1/update-ssa-form';
  deletePath = '';
  retrieveOnePath = '';

  constructor() {
    super('SSAFORMWIZARD', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: SsaFormWizardViewModel) {
    return new SsaFormWizardViewModelValidator().validate(item as any);
  }

  private generateTitle(newLocation: LocationItemReference, newTemplate: SsaTemplateDto) {
    let title = '';
    let location = newLocation ? newLocation : this.state.item?.location;
    let template = newTemplate ? newTemplate : this.state.item?.template;

    if (location) title += `${location.title} `;
    if (template) title += `${template.title} `;

    return title.trimEnd();
  }

  public change(partialItem?: Partial<SsaFormWizardViewModel>) {
    let newPartialItem = partialItem;

    if (newPartialItem.template || newPartialItem.location)
      newPartialItem.title = this.generateTitle(newPartialItem.location, newPartialItem.template);

    if (newPartialItem.profileId) newPartialItem.template = null;
    if (newPartialItem.profileId || newPartialItem.location) {
      newPartialItem.employees = {};
      newPartialItem.assessors = {};
      newPartialItem.employeesAssessors = {};
    }

    super.change(newPartialItem);
  }

  public changeEditMode(partialItem?: Partial<SsaFormWizardViewModel>) {
    let newPartialItem = partialItem;

    super.change(newPartialItem);
  }

  public async createSsaForm() {
    const { item } = this.state;
    const createSsaFormItemDto = this.toCreateSsaFormDto(this.state.item);
    const eventFormViewModelValidation = new SsaFormWizardViewModelValidator().extendValidate(item);

    if (eventFormViewModelValidation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, eventFormViewModelValidation);
      return { isSuccess: false };
    }

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateSsaFormDto, ItemResult<any>>(`${this.baseUrl}/${this.createPath}`, createSsaFormItemDto)
    );
    return result.data;
  }

  private toCreateSsaFormDto(viewModel: SsaFormWizardViewModel): CreateSsaFormDto {
    return {
      title: viewModel.title,
      ssaTemplateId: viewModel.template && viewModel.template.id,
      deadline: viewModel.deadline,
      employees: this.toCreateEmployeeSsaFormDto(viewModel)
    };
  }

  private toCreateEmployeeSsaFormDto(viewModel: SsaFormWizardViewModel): CreateEmployeeSsaFormDto[] {
    let employees = getKeyValuePairs<SsaEmployeeAssessors>(viewModel.employeesAssessors);

    return employees.map(e => ({
      userId: e.key,
      functionalExperts: this.toCreateFunctionalExpertDto(e.value)
    }));
  }

  private toCreateFunctionalExpertDto(v: SsaEmployeeAssessors): CreateFunctionalExpertDto[] {
    let assessors = getKeyValuePairs<string>(v.skillsAssessor);
    const assessorsSkills: { [assessorId: string]: string[] } = {};

    assessors.forEach(({ key, value }) => {
      let assessorSkills = assessorsSkills[value] || [];
      assessorSkills.push(key);
      assessorsSkills[value] = assessorSkills;
    });

    const createFunctionExpertsDto: CreateFunctionalExpertDto[] = [];
    getKeyValuePairs<string[]>(assessorsSkills).forEach(({ key, value }) => {
      createFunctionExpertsDto.push({
        userId: key,
        skills: value
      });
    });

    return createFunctionExpertsDto;
  }
}

@repository('@@SSAFORMS', 'ssa-forms.change')
export class ChangeSsaFormWizardStore extends FormStore<ChangeSsaFormDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-ssa-forms';
  retrievePath = '';
  updatePath = 'v1/update-ssa-form';
  deletePath = '';
  retrieveOnePath = '';

  constructor() {
    super('EDIT_TNAFORM', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: ChangeSsaFormDto) {
    return new ChangeSsaFormWizardValidator().extendValidate(item);
  }

  public async editSsaForm() {
    const { item } = this.state;

    new SsaFormWizardViewModelValidator();
    const validatedDto = new ChangeSsaFormWizardValidator().extendValidate(item);

    if (validatedDto.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validatedDto);
      return { isSuccess: false };
    }

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<ChangeSsaFormDto, ItemResult<any>>(`${this.baseUrl}/${this.createPath}`, item)
    );
    return result.data;
  }
}
