import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { MachineUnitDto, MachineUnitsStore } from '../../stores/configuration/machinery/machine-units-store';
import { nameof } from '../../utils/object';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import { customEqualCompare } from 'utils/useful-functions';
import { ItemReference, OrderDefinition } from 'stores/dataStore';

interface MultiMachineUnitEditorProps extends WithTranslation, StrictDropdownProps {
  value: string | string[];
  onChange: (value: any) => void;
  placeholder?: string;
  machineUnits?: MachineUnitsStore;
  multiple?: boolean;
  readOnly?: boolean;
  className?: string;
  equipmentId?: string;
  oemId?: string;
  machineUnitOptions?: ItemReference[];
  machineUnitDtos?: MachineUnitDto[];
  loadDataOnOpen?: boolean;
}

interface MultiMachineUnitEditorState {
  machineUnitOptions: { text: string; value: string }[];
  isLoading: boolean;
  dataLoaded: boolean;
  selectedOemId: string;
  selectedEquipmentId: string;
  isAlreadyQuerying: boolean;
}

@connect(['machineUnits', MachineUnitsStore])
class MultiMachineUnitEditor extends Component<MultiMachineUnitEditorProps, MultiMachineUnitEditorState> {
  private get machineUnitStore() {
    return this.props.machineUnits;
  }

  state: MultiMachineUnitEditorState = {
    machineUnitOptions: this.props.machineUnitOptions
      ? this.props.machineUnitOptions.map(x => ({ text: x.title, value: x.id }))
      : this.getMachineUnitOptionsFromprops(),
    isLoading: false,
    dataLoaded: false,
    selectedOemId: null,
    selectedEquipmentId: null,
    isAlreadyQuerying: false
  };

  componentDidMount() {
    if (!this.props.machineUnitDtos && !this.props.loadDataOnOpen && !this.props.machineUnitOptions) this.loadMachineUnits();
  }

  componentDidUpdate({ equipmentId, oemId, machineUnitOptions }: MultiMachineUnitEditorProps) {
    if (!customEqualCompare(equipmentId, this.props.equipmentId) || !customEqualCompare(oemId, this.props.oemId)) this.loadMachineUnits();

    if (
      (this.props.machineUnitOptions || machineUnitOptions) &&
      (machineUnitOptions?.length !== this.props.machineUnitOptions?.length ||
        JSON.stringify(machineUnitOptions) !== JSON.stringify(this.props.machineUnitOptions))
    ) {
      this.setState({
        machineUnitOptions: this.props.machineUnitOptions
          ? this.props.machineUnitOptions.map(x => ({ text: x.title, value: x.id }))
          : this.getMachineUnitOptionsFromprops()
      });
    }
  }

  getMachineUnitOptionsFromprops() {
    const { equipmentId, oemId, machineUnitDtos } = this.props;
    if (machineUnitDtos && machineUnitDtos.length > 0) {
      const options = [];

      let machineUnitsFiltered = machineUnitDtos.filter(
        x => (!equipmentId || x.equipmentTypeId === equipmentId) && (!oemId || x.oemId === oemId)
      );
      machineUnitsFiltered.forEach(machineUnit => options.push({ text: machineUnit.name, value: machineUnit.id }));

      return options;
    } else {
      return [];
    }
  }

  private loadMachineUnits = async () => {
    if (this.state.isAlreadyQuerying) return;
    this.setState({ isAlreadyQuerying: true, isLoading: true });
    const filter = [];
    const { equipmentId, oemId } = this.props;

    if (equipmentId === this.state.selectedEquipmentId && oemId === this.state.selectedOemId) {
      this.setState({ isAlreadyQuerying: false });
      return;
    }

    if (equipmentId) {
      filter.push(`${nameof<MachineUnitDto>('equipmentTypeId')} eq ${this.props.equipmentId}`);
      this.setState({ selectedEquipmentId: equipmentId });
    } else {
      this.setState({ selectedEquipmentId: undefined });
    }

    if (oemId) {
      filter.push(`${nameof<MachineUnitDto>('oemId')} eq ${this.props.oemId}`);
      this.setState({ selectedOemId: oemId });
    } else {
      this.setState({ selectedOemId: undefined });
    }
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<MachineUnitDto>('name'), useProfile: false }];
    return await this.machineUnitStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }).then(machineUnits => {
      const options = [];
      machineUnits.items.forEach(machineUnit => options.push({ text: machineUnit.name, value: machineUnit.id }));
      this.setState({ machineUnitOptions: options, isLoading: false, dataLoaded: true, isAlreadyQuerying: false });
    });
  };

  onOpenDropDown = () => {
    if (!this.state.dataLoaded) {
      this.loadMachineUnits();
    }
  };

  public render() {
    const { machineUnitOptions, isLoading } = this.state;
    const { t, readOnly, multiple, error, className, value, onChange, placeholder } = this.props;
    const classname = className ? `${className}` : 'planit-user-dropdown planit__multi-plc__dropdown';
    return (
      <Dropdown
        search
        selection
        closeOnChange
        closeOnEscape
        selectOnBlur={false}
        clearable
        multiple={multiple}
        options={machineUnitOptions}
        error={error}
        className={classname}
        value={multiple ? value || [] : value}
        onChange={(_, { value }) => onChange(value)}
        disabled={readOnly}
        placeholder={placeholder || t('Select Machine Unit')}
        onOpen={this.onOpenDropDown}
        loading={isLoading}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiMachineUnitEditor);
