import React, { Component } from 'react';
import 'site/pages/assessments/tna/templates/wizard/steps/tna-wizard-first-step.less';
import './general-data-step.less';
import { Input, TextArea } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import BooleanEditor from 'widgets/bussiness/boolean-editor';
import { PracticalTemplateWizardViewModel } from '../../practical-template-wizard-utils';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import ProfessionEditor from 'widgets/bussiness/profession-editor';

export interface PracticalTemplateWizardGeneralDataStepProps extends WithTranslation {
  active: boolean;
  viewModel: PracticalTemplateWizardViewModel;
  onChange: (viewModel: PracticalTemplateWizardViewModel) => void;
  mode: 'Create' | 'Edit';
}

class PracticalTemplateWizardGeneralDataStep extends Component<PracticalTemplateWizardGeneralDataStepProps> {
  private handleOnChange = (property: keyof PracticalTemplateWizardViewModel, value: any) => {
    const { viewModel, onChange } = this.props;
    viewModel[property as string] = value;
    onChange(viewModel);
  };

  render() {
    const { t, active, viewModel, mode } = this.props;
    const { title, header, profession, machineRelated, eventTypes } = viewModel;

    return (
      <div className="wizard__step wizard__step-one">
        <StepsComponent active={active} number={'1'} title={t('IDENTIFY YOUR NEW TEMPLATE')}></StepsComponent>
        <div className="step-one__content flex-start">
          <label style={{ transform: 'translateY(50%)' }}>{t('Title')}</label>
          <Input
            id="practical-template-wizard-title"
            value={title || ''}
            onChange={(_, inputField) => this.handleOnChange('title', inputField.value)}
          />
          <label>{t('Header (Optional)')}</label>
          <TextArea
            value={header || ''}
            onChange={(_, { value }) => this.handleOnChange('header', value)}
            className="tna-wizard__textarea"
          />
        </div>
        <br />
        <div className="step-one__content flex-start practical-template-wizard-adjust">
          <label>{t('Role')}</label>

          <ProfessionEditor
            clearable
            placeholder={t('Roles')}
            value={profession}
            onChange={data => this.handleOnChange('profession', data)}
          />

          <label>{t('Machine Related')}</label>
          <BooleanEditor
            clearable
            value={machineRelated}
            onChange={mr => this.handleOnChange('machineRelated', mr)}
            readOnly={mode === 'Edit'}
            className={mode === 'Edit' ? 'normal-look-readonly-select' : undefined}
          />
        </div>
        <br />
        <div className="step-one__content flex-start" id="practical-template-event-type-selector">
          <label>{t('Event Types')}</label>
          <MultiEventTypeEditor
            id="practical-template-list-event-types-filter"
            value={eventTypes.map(x => x.id)}
            onChange={(_, items) => this.handleOnChange('eventTypes', items)}
            useOriginalEventTypeIdAsValue
            placeholder=" "
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PracticalTemplateWizardGeneralDataStep);
