import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Dropdown, Modal, Button, Grid } from 'semantic-ui-react';
import { SsaSkillDto, SsaSkillsStore } from 'stores/assessments/skills/ssa-skills-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof, clone, getProperties } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import NewSsaSkillView from './new-ssa-skill';
import EditSsaSkillView from './edit-ssa-skill';
import { TestCategoryStore, TestCategoryDto } from 'stores/assessments/testCategory-store';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface SkillListProps extends WithTranslation, RouteComponentProps {
  skills: SsaSkillsStore;
  testCategoryStore?: TestCategoryStore;
}

export interface skillsListState {
  query: Query;
  newSkillShown: boolean;
  changeSkillShown: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  isclone: boolean;
  cloneData: SsaSkillDto;
  showDeleteConfirm: boolean;
  deleteModalText: string;
  someFilterOpened: boolean;
}

@connect(['skills', SsaSkillsStore], ['testCategoryStore', TestCategoryStore])
class SkillsListPage extends React.Component<SkillListProps, skillsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newSkillShown: false,
      changeSkillShown: false,
      activeFilters: [],
      selectedItem: null,
      cloneData: null,
      isclone: false,
      showDeleteConfirm: false,
      deleteModalText: '',
      someFilterOpened: false
    };
  }

  private get skills() {
    return this.props.skills;
  }

  loadTestCategories = () => {
    this.props.testCategoryStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<TestCategoryDto>('name'), useProfile: false }],
      filter: [{ AllowedForSSA: true }]
    });
  };

  componentDidMount() {
    this.loadTestCategories();
    this.load();
  }

  @autobind
  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };

    this.setState({ query }, () => this.props.skills.getAllAsync(this.state.query));
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isInPowerInstructorRole(): boolean {
    return IdentityService.isPowerInstructor(this.identityService.getUserInfo());
  }

  @autobind
  private async onSaveRow(item: SsaSkillDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.skills.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async showDeleteModal() {
    if (this.state.selectedItem.state !== 'New') {
      const count = await this.skills.getUsedSkillCountById(this.state.selectedItem.id);
      this.setState({
        deleteModalText:
          count > 0
            ? `This skill is being used in some profiles. This action will disable the templates related to those profiles. The skill is used in ` +
              count +
              ' question' +
              (count === 1 ? '' : 's') +
              ' and this relation will be deleted.\r\n Are you sure you want to delete it?'
            : `Are you sure you want to delete it?`
      });
      this.setState({ showDeleteConfirm: true });
    }
  }

  @autobind
  private async onDelete(item: SsaSkillDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.skills.deleteAsync(item.id, state);
      this.hideConfirmModal();
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private hideConfirmModal() {
    this.setState({ showDeleteConfirm: false, selectedItem: null });
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newSkillShown: true });
  }

  @autobind
  private onCloneItem() {
    this.setState({ newSkillShown: true, isclone: true, cloneData: clone(this.state.selectedItem) });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newSkillShown: false, isclone: false, cloneData: null });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeSkillShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeSkillShown: false });
    if (isSuccess) this.load();
  }

  private onFilterSkillOrFriendlyId = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['IdName'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(name), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'SS');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('SS')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      activeFilters['IdName'] = `(${parts.join(' or ')})`;
    }

    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onSkillDescription = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['Description'];
    } else {
      activeFilters['Description'] = { description: { contains: value } };
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onCategoryChanged = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };
    if (isNullOrWhiteSpaces(value)) delete activeFilters['categories'];
    else activeFilters['categories'] = { TestCategoryId: { type: 'guid', value } };

    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t } = this.props as any;
    const { someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Skill ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          editor: (data, onChange) => (
            <Input
              value={data.id}
              fluid
              onChange={(e, { value }) => {
                data.id = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SsaSkillDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Skill'),
          tooltipRenderer: true,
          renderer: data => data.name,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SsaSkillDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Description'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.description} />
            </>
          )
        },
        {
          title: t('Category'),
          tooltipRenderer: false,
          renderer: data => <span className="question-bank__cell__tag">{t(data.testCategory.name)}</span>,
          sortDefinition: {
            field: 'testCategory/name',
            useProfile: false
          }
        }
      ],
      data: this.skills.state
    } as TableModel<SsaSkillDto>;

    const testCategoryOptions = this.props.testCategoryStore.state.items.map(({ item }) => ({
      key: item.id,
      text: item.name,
      value: item.id
    }));

    return (
      <div className="ssa__skills__wrapper">
        <Grid className="event-types-list-grid">
          {this.skills.state.result && !this.skills.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.skills.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <div className="ssa__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Skill or ID')}
              onChange={this.onFilterSkillOrFriendlyId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Description')}
              onChange={this.onSkillDescription}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <Dropdown
              search
              inline
              selection
              closeOnChange
              closeOnEscape
              clearable={true}
              options={testCategoryOptions}
              className="planit-users-inputs planit-user-dropdown planit-user-selector"
              placeholder={t('Category')}
              onChange={this.onCategoryChanged}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.showDeleteConfirm && !this.state.newSkillShown && !this.state.changeSkillShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      &nbsp;{t('Clone')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onCloneItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon color="red" name="trash alternate" />
                      {<span className="text__red text__bold">{t('Delete')}</span>}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.showDeleteModal());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              areActionsDisabled={item => this.isInPowerInstructorRole()}
              canCreateNew={!this.isInPowerInstructorRole()}
              createNewButtonTitle={t('New SSA Skill')}
            ></TableView>
          </Grid.Row>
        </Grid>

        <Modal
          open={this.state.showDeleteConfirm}
          size="mini"
          className="have-warnings-popup"
          closeOnEscape={true}
          onClose={() => this.hideConfirmModal()}
        >
          <Modal.Content className="have-warnings-popup__content">
            <p>{this.state.deleteModalText}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={() => this.hideConfirmModal()} />
            <Button
              className="have-warnings-popup__btn have-warnings-popup__pending-btn"
              content={t('Yes')}
              onClick={() => this.onDelete(this.state.selectedItem, this.state.selectedItem.state)}
            />
          </div>
        </Modal>
        {this.state.newSkillShown && (
          <NewSsaSkillView isClone={this.state.isclone} cloneData={this.state.cloneData} onClose={this.onNewItemClosed} {...this.props} />
        )}
        {this.state.changeSkillShown && <EditSsaSkillView onClose={this.onEditItemClosed} currentSsaSkill={this.state.selectedItem} />}
      </div>
    );
  }
}

export default withTranslation()(SkillsListPage);
