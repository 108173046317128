import React from 'react';
import { Route } from 'react-router-dom';

import { resolve } from 'inversify.config';
import { IdentityService, Roles, IUserInfo } from 'services/identity-service';
import { FullPageLoader } from '../full-page-loader';
import AuthErrorView from './auth-error-view';
import { UnauthorizedView } from './unauthorized-view';

interface PrivateComponentProps {
  Component: any;
  props: any;
  userInRoleOnly?: Roles;
  allowedRoles?: Roles[];
  allowUser?: (userInfo: IUserInfo) => boolean;
}

interface PrivateComponentState {
  checkingIdentity: boolean;
  loggedIn: boolean;
}

class PrivateComponent extends React.Component<PrivateComponentProps, PrivateComponentState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: PrivateComponentProps) {
    super(props);
    this.state = {
      checkingIdentity: true,
      loggedIn: false
    };
  }

  private validateRoleConditions = (): boolean => {
    const { userInRoleOnly, allowedRoles, allowUser } = this.props;
    if (userInRoleOnly != null) {
      return this.identityService.getUserInfo().activeRole === IdentityService.roles[userInRoleOnly];
    }
    if (allowUser != null) {
      return allowUser(this.identityService.getUserInfo());
    }
    if (allowedRoles != null) {
      return this.identityService.isInAnyRole(allowedRoles);
    }
    return true;
  };

  componentDidMount() {
    this.setState({ checkingIdentity: true }, () => {
      this.identityService.isLoggedIn().then(loggedIn => {
        this.setState({
          checkingIdentity: false,
          loggedIn
        });
      });
    });
  }

  render() {
    const { Component, props } = this.props;
    const { loggedIn, checkingIdentity } = this.state;
    if (Component == null) {
      return <AuthErrorView />;
    } else if (checkingIdentity) {
      return <FullPageLoader text={'Loading user'} />;
    } else if (!loggedIn) {
      this.identityService.signinRedirect();
      return <FullPageLoader text={'Signing in'} />;
    } else if (this.validateRoleConditions()) {
      return <Component {...props} />;
    } else {
      return <UnauthorizedView />; // Improve this!!
    }
  }
}

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = Component => props => (
    <PrivateComponent
      Component={Component}
      props={props}
      userInRoleOnly={rest.userInRoleOnly}
      allowedRoles={rest.allowedRoles}
      allowUser={rest.allowUser}
    />
  );

  return <Route {...rest} render={renderFn(component)} />;
};
