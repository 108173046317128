import React, { Component } from 'react';
import './profile-definition.less';
import { Icon, Popup, Menu, Modal, Button } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ProfileItemDto, ChangeProfileItemStore, Assessment } from 'stores/profile/profile-store';
import { connect } from 'redux-scaffolding-ts';
import EditProfileDefinition from './edit-profile-definition';
import CloneProfileDefinition from './clone-profile-definition';
import '../../../../../src/widgets/collections/tooltip-style.less';

interface ProfileDetailProps extends WithTranslation {
  selectedRow: ProfileItemDto;
  changeProfileStore?: ChangeProfileItemStore;
  onRefresh?: () => void;
  onItemEdited?: (id: string) => void;
  onItemCloned?: (id: string) => void;
}

interface ProfileDetailState {
  showEditModal: boolean;
  showCloneModal: boolean;
  onConfirmToggleDisAble: boolean;
  togglePopup: boolean;
  activeTooltip: boolean;
  // lastRowSelected: ProfileItemDto;
}
@connect(['changeProfileStore', ChangeProfileItemStore])
class ProfileDetailComponent extends Component<ProfileDetailProps, ProfileDetailState> {
  state = {
    showEditModal: false,
    showCloneModal: false,
    togglePopup: false,
    onConfirmToggleDisAble: false,
    activeTooltip: false
    // lastRowSelected: null
  };

  private onToggleEditProfileModal = () => {
    if (this.state.showEditModal) this.props.onItemEdited(this.props.selectedRow.id);
    this.setState(prevState => ({ togglePopup: false, showEditModal: !prevState.showEditModal }));
  };

  private onToggleCloneProfileModal = () => {
    if (this.state.showCloneModal) this.props.onItemCloned(this.props.selectedRow.id);
    this.setState(prevState => ({ togglePopup: false, showCloneModal: !prevState.showCloneModal }));
  };

  componentDidMount() {
    const cellText = this.refs.shortText;
    if (this.isEllipsisActive(cellText)) {
      this.setState({ activeTooltip: true });
    }
  }

  private isEllipsisActive(element) {
    if (element) {
      return element.offsetWidth < element.scrollWidth;
    }
  }

  mapRowsAndDetails = () => {
    const spaceAmount = [0, 1, 2, 3, 4];
    const activeTooltip = this.refs.shortText ? this.isEllipsisActive(this.refs.shortText) : false;
    // if (!this.state.lastRowSelected || this.state.lastRowSelected !== this.props.selectedRow)
    // this.setState({ lastRowSelected: this.props.selectedRow, togglePopup: false });

    if (this.props.selectedRow?.assessment === Assessment[Assessment.IWS].toString()) {
      return this.getTableIWSProfileDefinitionDetails(spaceAmount, activeTooltip);
    } else {
      return this.getTableProfileDefinitionDetails(spaceAmount, activeTooltip);
    }
  };

  getTableProfileDefinitionDetails = (spaceAmount: number[], isActiveTooltip: boolean) => {
    return (
      this.props.selectedRow &&
      this.props.selectedRow.skills.map(({ skillName, substitute, main, skillId, assessment }) => {
        let substituteTag = 'S';
        let mainTag = 'M';
        if (assessment === Assessment[Assessment.IWS].toString()) {
          substituteTag = 'Ta';
          mainTag = 'Tr';
        }
        return (
          <tr className="profile-detail__table-body-rows" key={skillId}>
            <td className="profile-skill">
              <span className="table__tooltip">
                <div className="table__short-text" ref="shortText">
                  {skillName}
                </div>

                {isActiveTooltip ? (
                  <div className="table__tooltip-top">
                    {skillName}
                    <i></i>
                  </div>
                ) : null}
              </span>
            </td>
            {spaceAmount.map((_, i) => {
              const substituteItem = i === substitute && <span className="item substitute">{substituteTag}</span>;
              const mainItem = i === main && <span className="item main">{mainTag}</span>;
              if (i > main) return null;

              return (
                <td key={skillId + i + main} className="profile-level">
                  {substituteItem}
                  {mainItem}
                </td>
              );
            })}
          </tr>
        );
      })
    );
  };

  getTableIWSProfileDefinitionDetails = (spaceAmount: number[], isActiveTooltip: boolean) => {
    return (
      this.props.selectedRow &&
      this.props.selectedRow.skills.map(({ skillName, phase0, phase1, phase2, phase3, phase4, skillId }) => {
        let phase0Tag = 'P0';
        let phase1Tag = 'P1';
        let phase2Tag = 'P2';
        let phase3Tag = 'P3';
        let phase4Tag = 'P4';

        return (
          <tr className="profile-detail__table-body-rows" key={skillId}>
            <td className="profile-skill">
              <span className="table__tooltip">
                <div className="table__short-text" ref="shortText">
                  {skillName}
                </div>

                {isActiveTooltip ? (
                  <div className="table__tooltip-top">
                    {skillName}
                    <i></i>
                  </div>
                ) : null}
              </span>
            </td>
            {spaceAmount.map((_, i) => {
              const phase0Item = i === phase0 && <span className="item main">{phase0Tag}</span>;
              const phase1Item = i === phase1 && <span className="item substitute">{phase1Tag}</span>;
              const phase2Item = i === phase2 && <span className="item substitute">{phase2Tag}</span>;
              const phase3Item = i === phase3 && <span className="item substitute">{phase3Tag}</span>;
              const phase4Item = i === phase4 && <span className="item substitute">{phase4Tag}</span>;
              if (i > phase0 && i > phase1 && i > phase2 && i > phase3 && i > phase4) return null;

              return (
                <td key={skillId + i + phase0} className="profile-level">
                  {phase0Item}
                  {phase1Item}
                  {phase2Item}
                  {phase3Item}
                  {phase4Item}
                </td>
              );
            })}
          </tr>
        );
      })
    );
  };

  onToggleConfirmationBox = () => {
    this.props.changeProfileStore.clear();
    this.setState(prevState => ({ togglePopup: false, onConfirmToggleDisAble: !prevState.onConfirmToggleDisAble }));
  };
  onUpdateEnableDisable = () => {
    const assessment = this.props.selectedRow.assessment;
    const skills = this.props.selectedRow.skills.map(skill => ({ ...skill, assessment }));
    this.props.changeProfileStore.change({ ...this.props.selectedRow, assessment, skills, isActive: !this.props.selectedRow.isActive });
    this.props.changeProfileStore.update().then(_ => {
      this.isOperationResultSucess() && this.onToggleConfirmationBox();
      this.props.onRefresh && this.props.onRefresh();
    });
  };
  isOperationResultSucess = () => {
    return this.props.changeProfileStore.state.result && this.props.changeProfileStore.state.result.isSuccess;
  };

  togglePopupHandler = () => {
    this.setState(({ togglePopup }) => ({ togglePopup: !togglePopup }));
  };

  closePopUpHandler = () => {
    this.setState({ togglePopup: false });
  };

  render = () => {
    const { t, selectedRow } = this.props;

    return (
      <>
        <div className="profile-detail-wrapper">
          <div className="profile-detail__header">
            <p>{selectedRow.description} </p>
            <Popup
              open={this.state.togglePopup}
              on="click"
              onClose={this.closePopUpHandler}
              closeOnDocumentClick
              closeOnTriggerClick
              closeOnEscape
              position="bottom right"
              trigger={<Icon onClick={this.togglePopupHandler} style={{ cursor: 'pointer' }} name="ellipsis vertical" />}
              content={
                <Menu vertical secondary>
                  <Menu.Item key="edit" position="left" onClick={this.onToggleEditProfileModal}>
                    <Icon name="edit" />
                    {t('Edit')}
                  </Menu.Item>
                  <Menu.Item key="toggle" position="left" onClick={this.onToggleConfirmationBox}>
                    {selectedRow.isActive ? (
                      <>
                        <Icon color="red" name={'toggle on'} />
                        {t('Disable')}
                      </>
                    ) : (
                      <>
                        <Icon name={'toggle off'} />
                        {t('Activate')}
                      </>
                    )}
                  </Menu.Item>
                  <Menu.Item key="Clone" position="left" onClick={this.onToggleCloneProfileModal}>
                    <Icon name="clone" />
                    {t('Clone')}
                  </Menu.Item>
                </Menu>
              }
            />
          </div>

          {selectedRow.skills.length > 0 && (
            <table className="profile-detail__table">
              <thead>
                <tr className="profile-detail__table-header">
                  <th></th>
                  <th>0</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                </tr>
              </thead>
              <tbody>{this.mapRowsAndDetails()}</tbody>
            </table>
          )}
        </div>
        {this.state.showEditModal && selectedRow && (
          <EditProfileDefinition
            changeProfileStore={this.props.changeProfileStore}
            currentProfile={selectedRow}
            onClose={this.onToggleEditProfileModal}
            onFinish={this.isOperationResultSucess() && this.props.onRefresh}
          />
        )}
        {this.state.showCloneModal && selectedRow && (
          <CloneProfileDefinition
            changeProfileStore={this.props.changeProfileStore}
            currentProfile={selectedRow}
            onClose={this.onToggleCloneProfileModal}
            onFinish={this.isOperationResultSucess() && this.props.onRefresh}
          />
        )}
        {this.state.onConfirmToggleDisAble && (
          <Modal
            open={this.state.onConfirmToggleDisAble}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.onToggleConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to ${this.props.selectedRow.isActive ? 'deactivate' : 'active'} this profile.`)}</p>
              {this.props.selectedRow.isActive && <p>{t('Templates associated with this profile will be disabled')}</p>}
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.onToggleConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onUpdateEnableDisable}
              />
            </div>
          </Modal>
        )}
      </>
    );
  };
}
export default withTranslation()(ProfileDetailComponent);
