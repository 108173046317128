import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ItemReference } from 'stores/dataStore';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { Dimmer, Loader, Message, Button, Modal } from 'semantic-ui-react';
import { NewTnaTheoreticalTestStore, CreateTnaTheoreticalFormDto } from 'stores/assessments/forms/tna-theoretical-tests-store';
import LineSeparator from 'widgets/bussiness/line-separator';
import { connect } from 'redux-scaffolding-ts';
import { isNullOrWhiteSpaces, customEqualCompare } from 'utils/useful-functions';
import { TheoreticalTemplateItemDto } from 'stores/assessments/templates/theoretical-templates-store';
import GeneralDataStep from './steps/general-data-step';
import TheoreticalTemplateDataStep from './steps/theoretical-template-data-step';
import EmployeeDataStep from './steps/employee-data-step';
import { UserProfilesDto } from 'stores/profile/user-profile-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { DateTimeService } from 'services/datetime-service';

export interface NewTnaTheoreticalTestViewNodel {
  title: string;
  location: ItemReference;
  profile: ProfileItemDto;
  deadline: string;
  template: TheoreticalTemplateItemDto;
  employees: UserProfilesDto[];
}

export interface NewTnaTheoreticalTestProps extends WithTranslation, RouteComponentProps {
  newTnaTheoreticalTestStore?: NewTnaTheoreticalTestStore;
}

export interface NewTnaTheoreticalTestState {
  loading: boolean;
  activeStep: number;
  viewModel: NewTnaTheoreticalTestViewNodel;
  showCancelConfirm: boolean;
}

@connect(['newTnaTheoreticalTestStore', NewTnaTheoreticalTestStore])
class NewTnaTheoreticalTest extends Component<NewTnaTheoreticalTestProps, NewTnaTheoreticalTestState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props) {
    super(props);

    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const location = pocLocations.length === 1 ? { id: pocLocations[0], title: null } : null;

    this.state = {
      loading: false,
      activeStep: 1,
      viewModel: {
        title: '',
        location,
        profile: null,
        deadline: null,
        template: null,
        employees: []
      },
      showCancelConfirm: false
    };
  }

  private onTryCancel = () => {
    this.setState({ showCancelConfirm: true });
  };
  private onCancelCancel = () => {
    this.setState({ showCancelConfirm: false });
  };

  private mapToDto = (viewModel: NewTnaTheoreticalTestViewNodel): CreateTnaTheoreticalFormDto => {
    return {
      templateId: viewModel.template?.id,
      typeRelated: 'TNA',
      tnaDetails: {
        deadline: viewModel.deadline,
        profileId: viewModel.profile?.id,
        title: viewModel.title,
        users: viewModel.employees.map(x => x.userId)
      }
    };
  };

  private onSubmit = () => {
    if (!this.canSubmit()) return;
    this.setState({ loading: true });

    const { newTnaTheoreticalTestStore } = this.props;
    const dto = this.mapToDto(this.state.viewModel);

    newTnaTheoreticalTestStore.createNew(dto);
    newTnaTheoreticalTestStore
      .submit()
      .then(r => {
        this.setState({ loading: false });
        if (r != null && r.isSuccess) {
          this.backToList();
        } else {
          document.getElementById('root').scrollTop = 0;
        }
      })
      .catch(_ => {
        this.setState({ loading: false });
        document.getElementById('root').scrollTop = 0;
      });
  };

  private backToList = () => {
    const { history } = this.props;

    if (IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo()))
      history.push({ pathname: '/assessments/theoretical-test?tab=3' });
    else history.push({ pathname: '/assessments/theoretical-test' });
  };

  private checkStep1 = () => {
    const { viewModel } = this.state;
    return (
      !isNullOrWhiteSpaces(viewModel?.title) &&
      !isNullOrWhiteSpaces(viewModel?.location?.id) &&
      !isNullOrWhiteSpaces(viewModel?.profile?.id) &&
      !isNullOrWhiteSpaces(viewModel?.deadline) &&
      DateTimeService.isValid(viewModel?.deadline)
    );
  };

  private checkStep2 = () => {
    const { viewModel } = this.state;
    return viewModel?.template != null;
  };

  private checkStep3 = () => {
    const { viewModel } = this.state;
    return (viewModel?.employees || []).length > 0;
  };

  private updateStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      this.setState({ activeStep: 1 });
      return;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      this.setState({ activeStep: 2 });
      return;
    }
    this.setState({ activeStep: 3 });
  };

  private canSubmit = () => {
    return this.checkStep1() && this.checkStep2() && this.checkStep3();
  };

  private generateTitle(newLocation?: any, newProfile?: any, newTemplate?: any) {
    let title = '';
    const { viewModel: CurrentViewmodel } = this.state;
    let location = newLocation ? newLocation : CurrentViewmodel.location;
    let profile = newProfile ? newProfile : CurrentViewmodel.profile;
    let template = newTemplate ? newTemplate : CurrentViewmodel.template;

    if (location) title += `${location.title} `;
    if (profile) title += `${profile.name} `;
    if (template) title += `${template.title} `;

    return title.trimEnd();
  }

  private onStepChange = (nextViewModel: NewTnaTheoreticalTestViewNodel) => {
    const { viewModel: currentViewmodel } = this.state;
    const profileChanged = !customEqualCompare(currentViewmodel.profile?.id, nextViewModel.profile?.id);
    const locationChanged = !customEqualCompare(currentViewmodel.location?.id, nextViewModel.location?.id);
    const templateChanged = !customEqualCompare(currentViewmodel.template?.id, nextViewModel.template?.id);

    if (locationChanged) {
      nextViewModel.title = this.generateTitle(nextViewModel.location, nextViewModel.profile);
      nextViewModel.template = null;
    }

    if (profileChanged) {
      nextViewModel.title = this.generateTitle(nextViewModel.location, nextViewModel.profile);
      nextViewModel.template = null;
    }

    if (templateChanged) {
      nextViewModel.title = this.generateTitle(nextViewModel.location, nextViewModel.profile, nextViewModel.template);
    }

    if (profileChanged || locationChanged || templateChanged) nextViewModel.employees = [];
    this.setState({ viewModel: nextViewModel }, this.updateStep);
  };

  render() {
    const { t, newTnaTheoreticalTestStore } = this.props;
    const { state: storeState } = newTnaTheoreticalTestStore;
    const { loading, activeStep, viewModel, showCancelConfirm } = this.state;
    return (
      <>
        <h3>{t('TNA Theoretical Form')}</h3>
        <LineSeparator />
        <Dimmer active={loading || storeState.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}>
          <Loader indeterminate></Loader>
        </Dimmer>
        {storeState.result && !storeState.result.isSuccess && (storeState.result.messages || []).length > 0 && (
          <Message
            onDismiss={newTnaTheoreticalTestStore.clearMessages}
            className="error-message__style"
            icon="exclamation circle"
            error
            list={storeState.result.messages.map(m => m.body)}
          />
        )}
        <div className="wizard__steps-container tna-form-wizzard">
          <GeneralDataStep stepActive={activeStep >= 1} viewModel={{ ...viewModel }} onChange={this.onStepChange} />
          <TheoreticalTemplateDataStep stepActive={activeStep >= 2} viewModel={{ ...viewModel }} onChange={this.onStepChange} />
          <EmployeeDataStep stepActive={activeStep >= 3} viewModel={{ ...viewModel }} onChange={this.onStepChange} />
        </div>
        <div className="tna-templates-wizard__btns">
          <Button secondary inverted onClick={this.onTryCancel}>
            {t('Cancel')}
          </Button>
          <Button primary positive onClick={this.onSubmit} disabled={!this.canSubmit()}>
            {t('Generate Test')}
          </Button>
        </div>
        {showCancelConfirm && (
          <Modal open={showCancelConfirm} size="mini" className="tna-confirmation-modal" closeOnEscape={true} onClose={this.onCancelCancel}>
            <Modal.Content className="tna-confirmation-modal__content">
              <p>
                {t('Are you sure you want to CANCEL?')}
                <br />
                {t('You will lose all the changes made in this Test.')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button size="tiny" className="tna-confirmation-modal__btn cancel" content={t('Back')} onClick={this.onCancelCancel} />
              <Button size="tiny" className="tna-confirmation-modal__btn" content={t('Cancel Changes')} onClick={this.backToList} />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(NewTnaTheoreticalTest);
