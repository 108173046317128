import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface ClusterDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateClusterDto {
  name: string;
}

export interface ChangeClusterDto {
  id: string;
  name: string;
}

export class CreateClusterValidator extends AbstractValidator<CreateClusterDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster name is required'));
  }
}

export class ChangeClusterValidator extends AbstractValidator<ChangeClusterDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Cluster name is required'));
  }
}

@repository('@@CLUSTERS', 'clusters.summary')
export class ClustersStore extends DataStore<ClusterDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-related-cluster';
  retrievePath = 'get-mrc';
  updatePath = 'update-machine-related-cluster';
  deletePath = 'delete-machine-related-cluster';

  protected validate(item: ClusterDto) {
    return new ChangeClusterValidator().validate(item);
  }

  constructor() {
    super('CLUSTER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@CLUSTERS', 'clusters.dropdown')
export class DropDownClustersStore extends DataStore<ClusterDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = 'get-mrc';
  updatePath = '';
  deletePath = '';

  protected validate(item: ClusterDto) {
    return new ChangeClusterValidator().validate(item);
  }

  constructor() {
    super('CLUSTERDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, mrclustersIds?: string[], search?: string): Promise<QueryResult<ClusterDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let mrclustersDto = this.state.items.map(({ item }) => item);

      if ((mrclustersIds || []).length > 0) {
        mrclustersDto = mrclustersDto.filter(x => mrclustersIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        mrclustersDto = mrclustersDto.filter(x => x.name.startsWith(search));
      }

      let locationsResult = { items: mrclustersDto, count: mrclustersDto.length } as QueryResult<ClusterDto>;
      return new Promise<QueryResult<ClusterDto>>(resolve => resolve(locationsResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<ClusterDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<ClusterDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }

  public async getAllClustersAsync(query: Query, data?: any): Promise<QueryResult<ClusterDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,

      httpService.get<QueryResult<ClusterDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }
}

@repository('@@CLUSTERS', 'clusters.new')
export class NewClusterStore extends FormStore<CreateClusterDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-related-cluster';
  retrievePath = 'get-mrc';
  updatePath = 'update-machine-related-cluster';

  protected validate(item: CreateClusterDto) {
    return new CreateClusterValidator().validate(item);
  }

  constructor() {
    super('NEW_CLUSTER', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@CLUSTERS', 'clusters.change')
export class ChangeClusterStore extends FormStore<ChangeClusterDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-related-cluster';
  retrievePath = 'get-mrc';
  updatePath = 'update-machine-related-cluster';

  protected validate(item: ChangeClusterDto) {
    return new ChangeClusterValidator().validate(item);
  }

  constructor() {
    super('CHANGE_CLUSTER', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
