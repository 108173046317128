import {
  MrPracticalTestQuestionItemDto,
  NmrPracticalTestQuestionItemDto,
  PracticalQuestionDto
} from 'stores/assessments/questionBank/practical-test-store';

export const canBeSaved = (item: MrPracticalTestQuestionItemDto | NmrPracticalTestQuestionItemDto): boolean => {
  const { professions, questionTranslations, trainingLevelId, question } = item;

  let isValid = true;
  if ((professions || []).length <= 0) isValid = false;
  if (trainingLevelId == null) isValid = false;
  if (question?.language?.id == null || !question?.text) isValid = false;

  if ((questionTranslations || []).length > 0)
    isValid = questionTranslations.some(({ text, language }) => text !== '' && language.id != null);

  if ('equipmentTypeId' in item) {
    // MR RELATED
    const { clusterId, equipmentTypeId } = item;
    if (clusterId == null) isValid = false;
    if (equipmentTypeId == null) isValid = false;
  } else if ('functionalArea' in item) {
    // NMR RELATED
    const { nmrClusterId, functionalAreaId } = item;
    if (nmrClusterId == null) isValid = false;
    if (functionalAreaId == null) isValid = false;
  }
  return isValid;
};

export const plainQuestion: PracticalQuestionDto = {
  text: '',
  language: null
};

export const plainObj = {
  id: null,
  friendlyId: null,
  question: { ...plainQuestion },
  questionTranslations: [],
  professions: [],
  targetLevel: null,
  targetLevelFormatted: null,
  trainingLevelId: null,
  trainingLevel: null
};

export const plainMR = {
  clusterId: null,
  cluster: null,
  equipmentTypeId: null,
  equipmentType: null,
  oemId: null,
  oem: null,
  machineModelId: null,
  machineModel: null,
  machineUnitId: null,
  machineUnit: null,
  plcTypeId: null,
  plcType: null
};

export const plainNMR = {
  nmrClusterId: null,
  nmrCluster: null,
  functionalAreaId: null,
  functionalArea: null,
  trainingNameId: null,
  trainingName: null,
  functionalSubareaId: null,
  functionalSubArea: null
};

const allMrCascadeItems = {
  clusterId: null,
  equipmentTypeId: null,
  oemId: null,
  machineModelId: null,
  machineUnitId: null,
  plcTypeId: null
};

export const getCascadeChanges = (
  property: keyof MrPracticalTestQuestionItemDto | keyof NmrPracticalTestQuestionItemDto,
  value: any
): Partial<MrPracticalTestQuestionItemDto | NmrPracticalTestQuestionItemDto> => {
  if (value != null) return {};
  let changes: any = {};
  switch (property) {
    case 'clusterId':
      const { clusterId: _, ...rest } = allMrCascadeItems;
      changes = { ...changes, ...rest };
      break;
    case 'equipmentTypeId':
      const { clusterId: _1, equipmentTypeId: _2, ...rest1 } = allMrCascadeItems;
      changes = { ...changes, ...rest1 };
      break;
    case 'oemId':
      const { clusterId: _3, equipmentTypeId: _4, oemId: _5, ...rest2 } = allMrCascadeItems;
      changes = { ...changes, ...rest2 };
      break;
    case 'nmrClusterId':
      changes.functionalAreaId = null;
      changes.trainingNameId = null;
      changes.functionalSubareaId = null;
      break;
    case 'functionalAreaId':
      changes.trainingNameId = null;
      changes.functionalSubareaId = null;
      break;
    case 'trainingNameId':
      changes.functionalSubareaId = null;
      break;
    default:
      break;
  }
  return changes;
};
