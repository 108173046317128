import { AbstractValidator } from 'fluent-ts-validator';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import i18n from 'i18n';
import { AzureStorageUploadResult, CommandResult, ItemResult } from 'stores/types';
import { AxiosResponse } from 'axios';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

const isNullOrUndefined = x => x === null || x === undefined;

interface TnaTemplateDownloaderState {
  isBusy: boolean;
  result: ItemResult<any>;
}
interface TnaTemplateLoaderState {
  isBusy: boolean;
  result: AzureStorageUploadResult;
}

export interface TnaTemplateDto {
  id: string; //Guid
  friendlyId: string;
  profileItemId: string;
  profileName: string;
  title: string;
  header: string;
  isActive: boolean;
  machineModelIds: string[];
  machineModels: MachineModelDto[];
  nonMachineRelatedSkillSectionIds: string[];
  nonMachineRelatedSkillSections: TnaSkillTemplateSectionDto[];
  machineRelatedSectionIds: string[];
  machineRelatedSections: TnaMachineUnitTemplateSectionDto[];
  [key: string]: any;
}

export interface TnaTemplateFilters {
  templateName: string;
  templateId: string;
  profileName: string;
  profileItemId: string;
  machineModelName: string;
  machineModelId: string;
  oemId: string;
  equipmentTypeName: string;
  equipmentTypeId: string;
  clusterName: string;
  clusterId: string;
}

export interface CreateTnaTemplateDto {
  profileItemId: string;
  title: string;
  header: string;
  isActive: boolean;
  machineModels: string[]; //  GUID
  nonMachineRelatedSkillSections: CreateTnaSkillTemplateSectionDto[];
  machineRelatedSections: CreateTnaMachineUnitTemplateSectionDto[];
}

export interface ChangeTnaTemplateDto {
  id: string;
  title: string;
  header: string;
  isActive: boolean;
  machineModels: string[]; // GUID
  nonMachineRelatedSkillSections: CreateTnaSkillTemplateSectionDto[];
  machineRelatedSections: CreateTnaMachineUnitTemplateSectionDto[];
}

export interface CreateTnaMachineUnitTemplateSectionDto {
  machineUnitId: string;
  skillSections: CreateTnaSkillTemplateSectionDto[];
}

export interface TnaMachineUnitTemplateSectionDto {
  machineUnitId: string;
  machineUnitItemDto: MachineModelDto;
  skillSections: TnaSkillTemplateSectionDto[];
}

export interface TnaSkillTemplateSectionDto {
  skillId: string;
  skillName: string;
  // questionIds: string[];
  questions: TnaSkillSectionQuestionDto[];
}

export interface CreateTnaSkillTemplateSectionDto {
  skillId: string;
  questions: string[];
}

export interface TnaSkillSectionQuestionDto {
  id: string;
  friendlyId: string;
  question: string;
  source?: string;
  isAddedInCustom?: boolean;
}

export class CreateTnaTemplateValidator extends AbstractValidator<CreateTnaTemplateDto> {
  constructor() {
    super();
    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIfString(o => o.profileItemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Profile is required'));

    this.validateIf(o => o.machineModels)
      .isNotEmpty()
      .when(x => (x.machineModels || []).length > 0)
      .withFailureMessage(i18n.t('There must be at least one Machine Model assigned'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.machineModels)
      .isNotEmpty()
      .fulfills(o => {
        const duplicatedSkills = o.find((machineUnitId, index) => o.indexOf(machineUnitId) !== index);
        return isNullOrUndefined(duplicatedSkills);
      })
      .when(x => (x.machineModels || []).length > 0)
      .withFailureMessage(i18n.t('There should not be duplicated machine models in Step 2'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(mrSections => mrSections.all(sections => sections.machineUnitId != null && sections.machineUnitId !== ''))
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('Machine Unit is required in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(p => {
        const machineUnitIds = p.map(({ machineUnitId }) => machineUnitId);
        const duplicatedSkills = machineUnitIds.find((machineUnitId, index) => machineUnitIds.indexOf(machineUnitId) !== index);
        return isNullOrUndefined(duplicatedSkills);
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There are duplicated machine units in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(p => {
        const skillSections = p.map(x => x.skillSections);
        let duplicatedQuestions = null;
        skillSections.forEach(sections => {
          const questions = (sections || []).map(s => s.questions);
          duplicatedQuestions = questions.find((question, index) => questions.indexOf(question) !== index);
        });
        return isNullOrUndefined(duplicatedQuestions);
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There are duplicated questions in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(sections => {
        const skillWithoutQuestion = sections.find(
          ({ skillSections }) => skillSections && skillSections.find(({ questions }) => questions && questions.length <= 0)
        );
        return skillWithoutQuestion == null;
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There should be at least one question per machine related skill'));
  }
}

export class ChangeTnaTemplateValidator extends AbstractValidator<ChangeTnaTemplateDto> {
  constructor() {
    super();
    this.validateIfString(o => o.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template id is required'));
    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIf(o => o.machineModels)
      .isNotEmpty()
      .when(x => (x.machineModels || []).length > 0)
      .withFailureMessage(i18n.t('There must be at least one Machine Model assigned'));

    this.validateIf(o => o.isActive)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Template status is required'));

    this.validateIf(o => o.machineModels)
      .isNotEmpty()
      .fulfills(o => {
        const duplicatedSkills = o.find((machineUnitId, index) => o.indexOf(machineUnitId) !== index);
        return isNullOrUndefined(duplicatedSkills);
      })
      .when(x => (x.machineModels || []).length > 0)
      .withFailureMessage(i18n.t('There should not be duplicated machine models in Step 2'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(mrSections => mrSections.all(sections => sections.machineUnitId != null && sections.machineUnitId !== ''))
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('Machine Unit is required in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(p => {
        const machineUnitIds = p.map(({ machineUnitId }) => machineUnitId);
        const duplicatedSkills = machineUnitIds.find((machineUnitId, index) => machineUnitIds.indexOf(machineUnitId) !== index);
        return isNullOrUndefined(duplicatedSkills);
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There are duplicated machine units in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(p => {
        const skillSections = p.map(x => x.skillSections);
        let duplicatedQuestions = null;
        skillSections.forEach(sections => {
          const questions = (sections || []).map(s => s.questions);
          duplicatedQuestions = questions.find((question, index) => questions.indexOf(question) !== index);
        });
        return isNullOrUndefined(duplicatedQuestions);
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There are duplicated questions in Step 3 machine related section'));

    this.validateIf(o => o.machineRelatedSections)
      .isNotEmpty()
      .fulfills(sections => {
        const skillWithoutQuestion = sections.find(
          ({ skillSections }) => skillSections && skillSections.find(({ questions }) => questions && questions.length <= 0)
        );
        return skillWithoutQuestion == null;
      })
      .when(x => (x.machineRelatedSections || []).length > 0)
      .withFailureMessage(i18n.t('There should be at least one question per machine related skill'));
  }
}
export class EmptyValidator extends AbstractValidator<any> {}

export interface MachineModelTnaTemplateDto {
  machineModelId: string;
  hasTnaTemplate: boolean;
  tnaTemplateId: string;
  tnaTemplateItem: TnaTemplateDto;
}

@repository('@@TNATEMPLATES', 'tna-templates.summary')
export class TnaTemplatesStore extends DataStore<TnaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-template';
  retrievePath = 'get-tna-templates';
  updatePath = 'update-tna-template';
  deletePath = '';
  retrieveOnePath = 'get-tna-template';
  getTnaTemplateForMachineModelsPath = 'tna-template-for-machine-models';

  protected validate(item: TnaTemplateDto) {
    return new EmptyValidator().validate(true);
  }

  constructor() {
    super('TNATEMPLATE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getTemplateById(id: string): Promise<TnaTemplateDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TnaTemplateDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getTnaTemplateForMachineModels(profileId: string, machineModelIds: string[]): Promise<MachineModelTnaTemplateDto[]> {
    const httpService = container.get(HttpService);
    const qs = `profileId=${profileId}&mModelId=${machineModelIds.join('&mModelId=')}`;
    const result = await this.dispatchAsync(
      this.getTnaTemplateForMachineModelsPath,
      httpService.get<CommandResult<MachineModelTnaTemplateDto>>(`${this.baseUrl}/${this.getTnaTemplateForMachineModelsPath}?${qs}`)
    );
    return result.data?.items || [];
  }
}

@repository('@@TNATEMPLATES', 'tna-templates.new')
export class NewTnaTemplateStore extends FormStore<CreateTnaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-template';
  retrievePath = 'get-tna-templates';
  updatePath = 'update-tna-template';

  protected validate(item: CreateTnaTemplateDto) {
    return new CreateTnaTemplateValidator().validate(item);
  }

  constructor() {
    super('NEW_TNATEMPLATE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNATEMPLATES', 'tna-templates.change')
export class ChangeTnaTemplateStore extends FormStore<ChangeTnaTemplateDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-template';
  retrievePath = 'get-tna-templates';
  updatePath = 'update-tna-template';

  protected validate(item: ChangeTnaTemplateDto) {
    return new ChangeTnaTemplateValidator().validate(item);
  }

  constructor() {
    super('CHANGE_TNATEMPLATE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNATEMPLATES', 'tna-templates.download')
export class DownloadTnaTemplatesStore extends ReduxRepository<TnaTemplateDownloaderState> {
  baseUrl = 'skills/v1';
  initBackgroundDownloadUrl = 'download-tna-template-in-background';

  public BACKGROUND_DOWNLOAD_INITIALIZED = 'BACKGROUND_DOWNLOAD_INITIALIZED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.onBackgroundDownloadInitilized.bind(this);

    this.addReducer(this.BACKGROUND_DOWNLOAD_INITIALIZED, this.onBackgroundDownloadInitilized, 'AsyncAction');
  }

  public async initBackgroundDownload(id) {
    const url = `${this.baseUrl}/${this.initBackgroundDownloadUrl}/${id}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.BACKGROUND_DOWNLOAD_INITIALIZED, httpService.post(url, null));
  }

  private onBackgroundDownloadInitilized = (): AsyncAction<AxiosResponse<any>, TnaTemplateLoaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: result => {
        ToastComponent({ text: i18n.t('The email has been sent'), type: 'success-toast' });
        return { ...this.state, isBusy: false, result: result.data };
      },
      onError: error => {
        ToastComponent({ text: i18n.t('File export failed'), type: 'error-toast' });
        return {
          ...this.state,
          isBusy: false,
          result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
        };
      }
    };
  };
}
