import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface PriorityDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreatePriorityDto {
  name: string;
}

export interface ChangePriorityDto {
  id: string;
  name: string;
}

export class CreatePriorityValidator extends AbstractValidator<CreatePriorityDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Priority name is required'));
  }
}

export class ChangePriorityValidator extends AbstractValidator<ChangePriorityDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Priority Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Priority name is required'));
  }
}

@repository('@@PRIORITIES', 'priorities.summary')
export class PriorityStore extends DataStore<PriorityDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-priority';
  retrievePath = 'get-priorities';
  updatePath = 'update-priority';
  deletePath = 'delete-priority';

  protected validate(item: PriorityDto) {
    return new ChangePriorityValidator().validate(item);
  }

  constructor() {
    super('PRIORITY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PRIORITIES', 'priorities.dropdown')
export class DropDownPriorityStore extends DataStore<PriorityDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-priorities';
  updatePath = '';
  deletePath = '';

  protected validate(item: PriorityDto) {
    return new ChangePriorityValidator().validate(item);
  }

  constructor() {
    super('PRIORITYDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, priorityIds?: string[], search?: string): Promise<QueryResult<PriorityDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let prioritysDto = this.state.items.map(({ item }) => item);

      if ((priorityIds || []).length > 0) {
        prioritysDto = prioritysDto.filter(x => priorityIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        prioritysDto = prioritysDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: prioritysDto, count: prioritysDto.length } as QueryResult<PriorityDto>;
      return new Promise<QueryResult<PriorityDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<PriorityDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<PriorityDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@PRIORITIES', 'priorities.new')
export class NewPriorityStore extends FormStore<CreatePriorityDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-priority';
  retrievePath = 'get-priorities';
  updatePath = 'update-priority';

  protected validate(item: CreatePriorityDto) {
    return new CreatePriorityValidator().validate(item);
  }

  constructor() {
    super('NEW_PRIORITY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PRIORITIES', 'priorities.change')
export class ChangePriorityStore extends FormStore<ChangePriorityDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-priority';
  retrievePath = 'get-priorities';
  updatePath = 'update-priority';

  protected validate(item: ChangePriorityDto) {
    return new ChangePriorityValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PRIORITY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
