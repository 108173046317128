import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TnaFormItemDto, TnaFormListDto } from 'stores/assessments/forms/tna-forms-store';
import { TnaTemplateDto, TnaTemplatesStore } from 'stores/assessments/templates/tna-templates-store';
import { MachineModelSelectionViewModel, TnaTemplateStepViewModel } from '../select-machinery-step-types';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import MrclusterEditor from 'widgets/bussiness/mrcluster-editor';
import { connect } from 'redux-scaffolding-ts';
import { ItemReference } from 'stores/dataStore';
import './styles.less';
import { Button, Icon, Popup } from 'semantic-ui-react';
import CustomizeTnaTemplatesView from 'widgets/form/customize-tna-template-form';
import { TnaTemplateData } from 'site/pages/assessments/tna/templates/wizard/tna-templates-wizard-component';

export interface MultipleMachineModelsStepProps extends WithTranslation {
  isStepActive: boolean;
  value: TnaTemplateStepViewModel;
  profileId: string;
  tnaTemplatesStore?: TnaTemplatesStore;
  onChange?: (value: TnaTemplateStepViewModel) => void;
  isOnEditMode: boolean;

  dataFormEditMode: TnaFormListDto;
  itemToEdit: TnaFormItemDto;
}

type SelectionType = {
  cluster: ItemReference;
  eqType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  checked: boolean;
  tnaTemplateItem: TnaTemplateDto;
  templateData?: TnaTemplateData;
};

export interface MultipleMachineModelsStepState {
  selectedCluster: ItemReference;
  selectedEqType: ItemReference;
  selectedOem: ItemReference;
  selectedMachineModel: ItemReference;
  selection: SelectionType[];
  checkingTemplates: boolean;
  showCustomizeTemplateModal: boolean;
  seletionToCutomize: SelectionType;
}

@connect(['tnaTemplatesStore', TnaTemplatesStore])
class MultipleMachineModelsStep extends Component<MultipleMachineModelsStepProps, MultipleMachineModelsStepState> {
  constructor(props: MultipleMachineModelsStepProps) {
    super(props);

    let selection: SelectionType[] = [];

    if (props.isOnEditMode && this.props.dataFormEditMode) {
      selection = this.props.dataFormEditMode.machineModels.map(mm => ({
        cluster: { id: mm.clusterId, title: mm.clusterName },
        eqType: { id: mm.equipmentTypeId, title: mm.equipmentTypeName },
        oem: { id: mm.oemId, title: mm.oemName },
        machineModel: { id: mm.id, title: mm.name },
        checked: true,
        tnaTemplateItem: this.props.itemToEdit ? this.props.itemToEdit.tnaAssessment.tnaTemplate : ({} as TnaTemplateDto)
      }));
    }

    this.state = {
      selectedCluster: null,
      selectedEqType: null,
      selectedMachineModel: null,
      selectedOem: null,
      selection,
      checkingTemplates: false,
      showCustomizeTemplateModal: false,
      seletionToCutomize: null
    };
  }

  UNSAFE_componentWillReceiveProps(next: MultipleMachineModelsStepProps) {
    if (
      next &&
      next !== this.props &&
      (next.isOnEditMode !== this.props.isOnEditMode || next.dataFormEditMode !== this.props.dataFormEditMode)
    ) {
      if (next.isOnEditMode) {
        const selection = next.dataFormEditMode.machineModels.map(mm => ({
          cluster: { id: mm.clusterId, title: mm.clusterName },
          eqType: { id: mm.equipmentTypeId, title: mm.equipmentTypeName },
          oem: { id: mm.oemId, title: mm.oemName },
          machineModel: { id: mm.id, title: mm.name },
          checked: true,
          tnaTemplateItem: {} as TnaTemplateDto
        }));
        this.setState({ selection });
      }
    }
  }

  private onAdd = () => {
    if (this.state.checkingTemplates) return;
    const { selectedCluster, selectedEqType, selectedOem, selectedMachineModel } = this.state;
    if (selectedCluster?.id == null || selectedEqType?.id == null || selectedOem?.id == null || selectedMachineModel?.id == null) return;
    if (this.state.selection.any(x => x.machineModel.id === selectedMachineModel.id)) return;
    const item = {
      cluster: selectedCluster,
      eqType: selectedEqType,
      oem: selectedOem,
      machineModel: selectedMachineModel,
      tnaTemplateItem: null,
      checked: false
    };
    this.setState(
      {
        selection: [...this.state.selection, item],
        selectedMachineModel: null
      },
      () => {
        this.onChange();
      }
    );
  };

  private onRemove = (idx: number) => {
    if (this.state.checkingTemplates) return;
    this.setState(
      {
        selection: this.state.selection.filter((_, i) => i !== idx)
      },
      () => {
        this.onChange();
      }
    );
  };

  private onCustomizeMachineModel = (selection: SelectionType) => {
    this.setState({ showCustomizeTemplateModal: true, seletionToCutomize: selection });
  };

  private checkTemplates = async () => {
    if (this.state.checkingTemplates) return;
    this.setState({ checkingTemplates: true });
    try {
      const selection = this.state.selection;
      const result = await this.props.tnaTemplatesStore.getTnaTemplateForMachineModels(
        this.props.profileId,
        selection.map(x => x.machineModel.id)
      );
      result.forEach(r => {
        const s = selection.find(x => x.machineModel.id === r.machineModelId);
        s.checked = true;
        s.tnaTemplateItem = r.tnaTemplateItem;
      });
      this.setState({ selection }, () => {
        this.onChange();
      });
    } finally {
      this.setState({ checkingTemplates: false });
    }
  };

  private onChange = () => {
    const selection = this.state.selection || [];
    if (this.props.onChange) {
      this.props.onChange({
        machineModelsSelection: selection.reduce((prev, next) => {
          if (next.checked && next.tnaTemplateItem)
            return [
              ...prev,
              {
                hasMachineUnitSelected: true,
                machineUnitSelection: [],
                machineModel: { id: next.machineModel.id, name: next.machineModel.title }
              } as MachineModelSelectionViewModel
            ];
          return prev;
        }, []),
        multipleMachineModelsSelection: selection.map(s => ({
          checked: s.checked,
          machineModel: s.machineModel,
          tnaTemplateItem: s.tnaTemplateItem,
          templateData: s.templateData
        })),
        skillCount: this.props.isOnEditMode
          ? this.props.value.skillCount
          : new Set(
              selection.reduce(
                (prev, curr) => [...prev, ...(curr?.tnaTemplateItem?.nonMachineRelatedSkillSections?.map(x => x.skillId) ?? [])],
                []
              )
            ).size,
        template: selection[0]?.tnaTemplateItem,
        type: 'MultipleMachineHierarchy'
      });
    }
  };

  onSaveCustomizeTemplate = (templateData: TnaTemplateData) => {
    const { selection } = this.state;
    const machineSelected = (selection || []).find(x => x.machineModel.id === this.state.seletionToCutomize.machineModel.id);
    machineSelected.templateData = templateData;

    this.setState({ selection }, () => {
      this.onChange();
    });
  };

  render() {
    const { selectedCluster, selectedEqType, selectedOem, selectedMachineModel, selection, checkingTemplates } = this.state;
    const { t } = this.props;

    return (
      <div className="step2__select-tna-wrapper">
        <div id="multiple-machine-model-selector-first-row">
          <div className="flex-column editor-38px">
            <label>{t('Cluster')}</label>
            <MrclusterEditor
              className="custom-editor"
              clearable
              nullable
              value={selectedCluster}
              onChange={mrCluster =>
                this.setState({ selectedCluster: mrCluster, selectedEqType: null, selectedOem: null, selectedMachineModel: null })
              }
              placeholder={t('Cluster')}
              readOnly={checkingTemplates}
            />
          </div>
          <div className="flex-column editor-38px">
            <label>{t('Equipment Type')}</label>
            <EquipmentTypeEditor
              nullable
              clearable
              clusterId={selectedCluster && selectedCluster.id}
              value={selectedEqType}
              onChange={equipmentType => this.setState({ selectedEqType: equipmentType, selectedOem: null, selectedMachineModel: null })}
              placeholder={t('Equipment Type')}
              clearOnReload
              readOnly={checkingTemplates || !selectedCluster?.id}
            />
          </div>

          <div className="flex-column editor-38px">
            <label>{t('OEM')}</label>
            <OemEditor
              nullable
              clearable
              value={selectedOem}
              onChange={oem => this.setState({ selectedOem: oem, selectedMachineModel: null })}
              equipmentId={selectedEqType && selectedEqType.id}
              placeholder={t('OEM')}
              readonly={checkingTemplates || !selectedEqType?.id}
            />
          </div>

          <div className="flex-column editor-38px">
            <label>{t('Machine Model')}</label>
            <MachineModelEditor
              cascade="true"
              nullable
              clearable
              oemId={selectedOem && selectedOem.id}
              equipmentId={selectedEqType && selectedEqType.id}
              value={selectedMachineModel}
              onChange={machineModel => this.setState({ selectedMachineModel: machineModel })}
              placeholder={t('Machine Model')}
              reloadOnChange={false}
              readonly={checkingTemplates || !selectedOem?.id}
            />
          </div>
          <div className="flex-column">
            <label className="hidden-label">.</label>
            <Button
              className="search-tna-template__btn"
              onClick={this.onAdd}
              disabled={
                checkingTemplates ||
                selectedCluster?.id == null ||
                selectedEqType?.id == null ||
                selectedOem?.id == null ||
                selectedMachineModel?.id == null ||
                selection.any(x => x.machineModel.id === selectedMachineModel?.id)
              }
            >
              {t('Add Machine Model')}
            </Button>
          </div>
          <div className="flex-column">
            <label className="hidden-label">.</label>
            <Button
              className="search-tna-template__btn"
              loading={checkingTemplates}
              onClick={this.checkTemplates}
              disabled={(selection || []).length === 0}
            >
              {t('Check Templates')}
            </Button>
          </div>
        </div>
        {(selection || []).length !== 0 && <p>{t('Selected Machine Models:')}</p>}
        {selection.map((x, i) => (
          <div className="machine-selection-item" key={x.machineModel.id}>
            <h4>
              <Icon
                size="large"
                name={'close'}
                color={checkingTemplates ? 'grey' : 'red'}
                className={checkingTemplates ? undefined : 'clickable-icon'}
                onClick={() => this.onRemove(i)}
              />
              &nbsp;
              <span style={{ color: 'gray' }}>
                {x.cluster.title} | {x.eqType.title} | {x.oem.title} |
              </span>
              &nbsp; <span>{x.machineModel.title}</span>
              {(checkingTemplates || x.checked) && (
                <>
                  &nbsp;
                  <Popup
                    wide
                    position="top center"
                    content={
                      x.tnaTemplateItem == null ? (
                        <span>{t('No template found')}</span>
                      ) : (
                        <span>
                          {t('Template found')}:{' '}
                          <strong>
                            {x.tnaTemplateItem.friendlyId} - {x.tnaTemplateItem.title}
                          </strong>
                        </span>
                      )
                    }
                    trigger={
                      <Icon
                        loading={checkingTemplates}
                        name={checkingTemplates ? 'spinner' : x.tnaTemplateItem == null ? 'warning circle' : 'check circle'}
                        color={checkingTemplates ? undefined : x.tnaTemplateItem == null ? 'red' : 'green'}
                        className="clickable-icon"
                      />
                    }
                  />
                  {!this.props.isOnEditMode && x.tnaTemplateItem && (
                    <div className="flex-column">
                      <Button className="search-tna-template__btn" onClick={() => this.onCustomizeMachineModel(x)}>
                        {t('Customize')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </h4>
          </div>
        ))}
        {this.state.showCustomizeTemplateModal && (
          <CustomizeTnaTemplatesView
            machineModel={this.state.seletionToCutomize.machineModel}
            templateDto={this.state.seletionToCutomize.tnaTemplateItem}
            onSaveCustomizeTemplate={this.onSaveCustomizeTemplate}
            onCloseTemplateModal={() => this.setState({ showCustomizeTemplateModal: false })}
            templateData={this.state.seletionToCutomize?.templateData}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(MultipleMachineModelsStep);
