import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { InstructorVisaDto, InstructorVisaStore } from 'stores/skills/instructor-visa-store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView } from 'widgets/collections/table';
import NewInstructorVisaView from './new-instructor-visa';
import ChangeInstructorVisaView from './edit-instructor-visa';
import moment from 'moment';
import { Icon, Input } from 'semantic-ui-react';
import { OrderDefinition, Query } from 'stores/dataStore';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { nameof } from 'utils/object';

export interface InstructorVisasProps extends WithTranslation, RouteComponentProps {
  instructorVisaStore?: InstructorVisaStore;
  instructorId?: string;
}

export interface InstructorVisasState {
  newInstructorVisaShown: boolean;
  changeInstructorVisaShown: boolean;
  selectedItem: any;
  activeFilters: string[];
  query: Query;
}

@connect(['instructorVisaStore', InstructorVisaStore])
class InstructorVisasTable extends React.Component<InstructorVisasProps, InstructorVisasState> {
  constructor(props) {
    super(props);
    this.state = {
      newInstructorVisaShown: false,
      changeInstructorVisaShown: false,
      selectedItem: null,
      activeFilters: [],
      query: {
        searchQuery: '',
        skip: 0,
        take: 1000000,
        orderBy: [],
        filter: []
      }
    };
  }

  @autobind
  private onNewItem() {
    this.setState({ newInstructorVisaShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newInstructorVisaShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeInstructorVisaShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeInstructorVisaShown: false });
    if (isSuccess) this.load();
  }

  componentDidMount() {
    this.load();
  }

  private getDateWithOutTimeZone = (date: Date) => {
    const myFormat = 'YYYY-MM-DD';
    return moment(date, 'YYYYMMDDTHHmmss')
      .format(myFormat)
      .split('-');
  };

  private load = () => {
    this.props.instructorVisaStore.getAllAsync(this.state.query);
  };

  private getMRTableModel = () => {
    const { t } = this.props as any;
    const { activeFilters } = this.state;

    const tableModel = {
      columns: [
        { title: t('Instructor Name'), renderer: data => data.instructor },
        {
          title: t('TTC Location'),
          renderer: data => data.ttcLocationName,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.ttcLocationName}
              fluid
              onChange={(e, { value }) => {
                data.ttcLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by TTC Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<InstructorVisaDto>('ttcLocationName'),
                  `contains(tolower(${nameof<InstructorVisaDto>('ttcLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<InstructorVisaDto>('ttcLocationName'))}
              active={activeFilters.includes(nameof<InstructorVisaDto>('ttcLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<InstructorVisaDto>('ttcLocationName'), useProfile: false }
        },
        {
          title: t('Traveling Location'),
          renderer: data => data.travelingLocationName,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.travelingLocationName}
              fluid
              onChange={(e, { value }) => {
                data.travelingLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Traveling Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<InstructorVisaDto>('travelingLocationName'),
                  `contains(tolower(${nameof<InstructorVisaDto>('travelingLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<InstructorVisaDto>('travelingLocationName'))}
              active={activeFilters.includes(nameof<InstructorVisaDto>('travelingLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<InstructorVisaDto>('travelingLocationName'), useProfile: false }
        },
        {
          title: t('Visa Requirement'),
          renderer: data => data.visaName,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.visaName}
              fluid
              onChange={(e, { value }) => {
                data.visaName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<InstructorVisaDto>('visaName'),
                  `contains(tolower(${nameof<InstructorVisaDto>('visaName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<InstructorVisaDto>('visaName'))}
              active={activeFilters.includes(nameof<InstructorVisaDto>('visaName'))}
            />
          ),
          sortDefinition: { field: nameof<InstructorVisaDto>('visaName'), useProfile: false }
        },
        {
          title: t('Start Date'),
          renderer: data => {
            if (data.startDate) {
              const date = this.getDateWithOutTimeZone(new Date(data.startDate));
              const printableDate = `${date[2]}/${date[1]}/${date[0]}`.trim();
              return <span>{printableDate}</span>;
            } else return <span />;
          },
          selectableHeader: true,
          sortDefinition: { field: nameof<InstructorVisaDto>('startDate') }
        },
        {
          title: t('Expire Date'),
          renderer: data => {
            if (data.expireDate) {
              const date = this.getDateWithOutTimeZone(new Date(data.expireDate));
              const printableDate = `${date[2]}/${date[1]}/${date[0]}`.trim();
              return <span>{printableDate}</span>;
            } else return <span />;
          },
          selectableHeader: true,
          sortDefinition: { field: nameof<InstructorVisaDto>('expireDate') }
        }
      ],
      data: this.props.instructorVisaStore.state
    } as TableModel<InstructorVisaDto>;
    return tableModel;
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'createdOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handleIsValidVisa = (item: InstructorVisaDto) => {
    if (item.visaName === 'None') return true;
    const currentDate: Date = new Date();
    const dateFromISO: Date = new Date(item.startDate);
    const dateToISO: Date = new Date(item.expireDate);
    return dateFromISO <= currentDate && dateToISO > currentDate;
  };

  public render() {
    const { t } = this.props as any;

    const nmrTableModel = this.getMRTableModel();
    return (
      <>
        <TableView
          selectable={false}
          maxSelection={1}
          onRefresh={this.load}
          onHideCheckbox={true}
          selectionType={'allRow'}
          model={nmrTableModel}
          extraActions={[
            {
              content: (
                <>
                  <Icon name="pencil alternate" />
                  &nbsp;{t('Edit')}
                </>
              ),
              onClick: item => {
                this.setState({ selectedItem: item }, this.onEditItem);
              }
            }
          ]}
          canCreateNew={true}
          onNewItem={this.onNewItem}
          createNewButtonTitle={t('New Instructor Visa')}
          canEdit={false}
          canDelete={false}
          onFilterChange={this.handleFilterChange}
          onOrderByChanged={this.handleOrderBy}
          isRowDisableLayout={(item: InstructorVisaDto) => !this.handleIsValidVisa(item)}
        />

        {(this.state.newInstructorVisaShown && <NewInstructorVisaView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeInstructorVisaShown && (
            <ChangeInstructorVisaView onClose={this.onEditItemClosed} currentInstructorVisa={this.state.selectedItem} />
          ))}
      </>
    );
  }
}
export default withTranslation()(InstructorVisasTable);
