import React, { Component } from 'react';
import StepsComponent from 'site/pages/shared/tna-and-ssa/wizard/wizard-step';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NewTnaTheoreticalTestViewNodel } from '../new-tna-theoretical-test';
import { Button } from 'semantic-ui-react';
import ChooseTheoreticalTemplatesView from 'widgets/form/choose-theoretical-template-form';
import { TheoreticalTemplateItemDto } from 'stores/assessments/templates/theoretical-templates-store';
import './tna-theoretical-tests.less';

export interface TheoreticalTemplateDataStepProps extends WithTranslation {
  stepActive: boolean;
  viewModel: NewTnaTheoreticalTestViewNodel;
  onChange: (vm: NewTnaTheoreticalTestViewNodel) => void;
}

export interface TheoreticalTemplateDataStepState {
  showModal: boolean;
}

class TheoreticalTemplateDataStep extends Component<TheoreticalTemplateDataStepProps, TheoreticalTemplateDataStepState> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  private getButton = () => {
    const { t, viewModel } = this.props;
    if (viewModel.template != null) {
      return <Button className="step2__select-tna__btn" circular icon="pencil" onClick={this.showModal} />;
    } else {
      return (
        <Button className="search-tna-template__btn" onClick={this.showModal}>
          {t('Select Theoretical Template')}
        </Button>
      );
    }
  };

  private onAddTemplate = (template: TheoreticalTemplateItemDto) => {
    const { viewModel } = this.props;
    viewModel.template = template;
    this.props.onChange(viewModel);
  };

  private showModal = () => {
    this.setState({ showModal: true });
  };

  private hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { t, viewModel: value, stepActive } = this.props;
    const { showModal } = this.state;

    return (
      <div className="wizard__step wizard__step-one tna-form-general-data">
        <StepsComponent active={stepActive} number={'2'} title={t('SELECT TNA THEORETICAL TEST TEMPLATE')}>
          <div id="new-tna-theoretical-test__template-selection">
            <div id="new-tna-theoretical-test__template-selection__column1">{this.getButton()}</div>
            <div id="new-tna-theoretical-test__template-selection__column2">{value.template?.friendlyId}</div>
            <div id="new-tna-theoretical-test__template-selection__column3">{value.template?.title}</div>
          </div>
        </StepsComponent>
        {showModal && (
          <ChooseTheoreticalTemplatesView
            alreadySelectedTemplateId={value.template?.id}
            profileId={value.profile?.id}
            typeRelated="TNA"
            onAddTemplate={this.onAddTemplate}
            onCloseTemplateModal={this.hideModal}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(TheoreticalTemplateDataStep);
