import React from 'react';
import './tooltip-style.less';

interface TableTooltipCellProps {
  textToShow: any;
  optionalRenderer?: any;
  newClassFromComponent?: string;
}

interface TableTooltipCellState {
  activeTooltip: boolean;
}

class TableTooltipCell extends React.Component<TableTooltipCellProps, TableTooltipCellState> {
  constructor(props) {
    super(props);
    this.state = {
      activeTooltip: false
    };
  }

  componentDidMount() {
    const cellText = this.refs.shortText;
    if (this.isEllipsisActive(cellText)) {
      this.setState({ activeTooltip: true });
    }
  }

  private isEllipsisActive(element) {
    return element.offsetWidth < element.scrollWidth;
  }

  render() {
    const activeTooltip = this.refs.shortText ? this.isEllipsisActive(this.refs.shortText) : false;

    return (
      <span className="table__tooltip">
        <div
          className={this.props.newClassFromComponent ? `table__short-text ${this.props.newClassFromComponent}` : 'table__short-text'}
          ref="shortText"
        >
          {this.props.optionalRenderer ? this.props.optionalRenderer : this.props.textToShow}
        </div>

        {activeTooltip ? (
          <div className="table__tooltip-top">
            {this.props.textToShow}
            <i></i>
          </div>
        ) : null}
      </span>
    );
  }
}

export default TableTooltipCell;
