import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChooseTnaTemplateModalButton from './choose-tna-template-button';
import './tna-template-step.less';
import { TnaTemplateDto, TnaTemplatesStore } from 'stores/assessments/templates/tna-templates-store';
import { Button, Checkbox } from 'semantic-ui-react';
import { connect } from 'redux-scaffolding-ts';
import { randomInteger } from 'utils/useful-functions';
import { TnaFormListDto, TnaFormItemDto } from 'stores/assessments/forms/tna-forms-store';
import { MachineModelSelectionViewModel, TnaTemplateStepViewModel } from '../select-machinery-step-types';
import CustomizeTnaTemplatesView from 'widgets/form/customize-tna-template-form';
import { TnaTemplateData } from 'site/pages/assessments/tna/templates/wizard/tna-templates-wizard-component';
export interface TnaTemplateStepProps extends WithTranslation {
  isStepActive: boolean;
  value: TnaTemplateStepViewModel;
  profileId: string;
  tnaTemplatesStore?: TnaTemplatesStore;
  onChange?: (value: TnaTemplateStepViewModel) => void;
  isOnEditMode: boolean;

  dataFormEditMode: TnaFormListDto;
  itemToEdit: TnaFormItemDto;
}

export interface TnaTemplateStepState {
  showCustomizeTemplateModal: boolean;
}

@connect(['tnaTemplatesStore', TnaTemplatesStore])
class TnaTemplateStep extends Component<TnaTemplateStepProps, TnaTemplateStepState> {
  state = {
    showCustomizeTemplateModal: false
  };
  handleOnAddTemplate = (templateListItem: TnaTemplateDto) => {
    this.props.tnaTemplatesStore.getTemplateById(templateListItem.id).then(template => {
      let value = { ...this.props.value };
      value.template = template;
      value.machineModelsSelection = this.generateMachineModelSelection(template);
      value.templateData = null;

      this.props.onChange && this.props.onChange(value);
    });
  };

  onSaveCustomizeTemplate = (templateData: TnaTemplateData) => {
    let value = { ...this.props.value };
    value.templateData = templateData;

    this.props.onChange && this.props.onChange(value);
  };

  handleOnCheckMachineUnit = (checked: boolean, machineModelIndex: number, machineUnitIndex: number) => {
    let value = { ...this.props.value };

    const machineModelSelection = value.machineModelsSelection[machineModelIndex];

    const machineUnitSelection = value.machineModelsSelection[machineModelIndex].machineUnitSelection[machineUnitIndex];

    machineUnitSelection.isSelected = checked;

    machineModelSelection.hasMachineUnitSelected = machineModelSelection.machineUnitSelection.any(x => x.isSelected);

    this.props.onChange && this.props.onChange(value);
  };

  public generateMachineModelSelection(template: TnaTemplateDto): MachineModelSelectionViewModel[] {
    const { isOnEditMode } = this.props;
    return (template.machineModels || []).map(machineModel => ({
      hasMachineUnitSelected: isOnEditMode ? true : false,
      machineModel: machineModel,
      machineUnitSelection: (template.machineRelatedSections || []).map(machineRelatedSection => ({
        machineUnit: machineRelatedSection.machineUnitItemDto,
        isSelected: false
      }))
    }));
  }

  render() {
    const { value, isOnEditMode } = this.props;
    const { t } = this.props;

    return (
      <div className="step2__select-tna-wrapper">
        <div className="step2__select-tna-id-name">
          {!isOnEditMode && (
            <ChooseTnaTemplateModalButton
              isEditMode={isOnEditMode}
              size={!this.props.value.template ? 'Normal' : 'Mini'}
              onAddTemplate={this.handleOnAddTemplate}
              profileId={this.props.profileId}
              selectedTemplateId={this.props.value.template ? this.props.value.template.id : undefined}
            />
          )}
          {this.props.value.template && (
            <>
              <p>{value?.template?.friendlyId}</p>
              <p>{value?.template?.title}</p>
              {!isOnEditMode && (
                <div className="flex-column">
                  <Button className="search-tna-template__btn" onClick={() => this.setState({ showCustomizeTemplateModal: true })}>
                    {t('Customize')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {this.props.value.template && (
          <div className="step2__select-tna-wrapper__content">
            <ul>
              {value.machineModelsSelection &&
                value.machineModelsSelection.map((machineModelSelection, machineModelIndex) => {
                  return (
                    <li
                      key={`${machineModelSelection.machineModel.id}-${randomInteger(100, 1000)}`}
                      className="step2__select-tna-wrapper__content__element"
                    >
                      <h4
                        className={machineModelSelection.hasMachineUnitSelected ? '' : 'machine-model__deactivated'}
                      >{`${machineModelSelection.machineModel.equipmentTypeName} | ${machineModelSelection.machineModel.name}`}</h4>

                      <ul>
                        {machineModelSelection.machineUnitSelection.map((machineUnitSelection, machineUnitIndex) => {
                          return (
                            <li
                              key={`${machineUnitSelection.machineUnit.id}-${randomInteger(100, 1000)}`}
                              className="step2__select-tna-content__machines flex-start"
                            >
                              <Checkbox
                                checked={machineUnitSelection.isSelected}
                                onClick={(_, { checked }) => {
                                  this.handleOnCheckMachineUnit(checked, machineModelIndex, machineUnitIndex);
                                }}
                              />
                              <label>{machineUnitSelection.machineUnit.name}</label>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        {this.state.showCustomizeTemplateModal && (
          <CustomizeTnaTemplatesView
            templateDto={this.props.value.template}
            onSaveCustomizeTemplate={this.onSaveCustomizeTemplate}
            onCloseTemplateModal={() => this.setState({ showCustomizeTemplateModal: false })}
            templateData={this.props.value.templateData}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(TnaTemplateStep);
