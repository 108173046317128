import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import 'assets/less/evaluation-search.less';
import { Dropdown, Form, Button, Checkbox, Message, Dimmer, Loader } from 'semantic-ui-react';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import MultiplePositionCodeEditor from 'widgets/bussiness/multiple-selector/multiple-position-code-editor';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import { Assessment, EvaluationsSearchFilters, EvaluationsDynamicTableStore } from 'stores/evaluations/evaluations-store';
import { connect } from 'redux-scaffolding-ts';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import { ProfileItemDto } from 'stores/profile/profile-store';

export interface EvaluationsSearchProps extends WithTranslation {
  evaluationsStore?: EvaluationsDynamicTableStore;
  onSearchCompleted: (filters?: EvaluationsSearchFilters) => void;
  prevSearch: EvaluationsSearchFilters;
}

export interface EvaluationsSearchState {
  filters: EvaluationsSearchFilters;
  currentLocations: LocationDto[];
  currentPositionCodes: PositionCodeDto[];
  isSSASelected: boolean;
  isIwsSelected: boolean;
  loading: boolean;
}

const mapToPositionCodeDto = (id: string): PositionCodeDto => ({
  code: null,
  createdOn: null,
  description: null,
  id,
  keywords: null,
  modifiedByUser: null,
  modifiedByUserId: null,
  modifiedOn: null,
  name: null
});
const mapToLocationDto = (id: string): LocationDto => ({
  active: null,
  code: null,
  countryId: null,
  countryName: null,
  createdOn: null,
  id,
  isoCode: null,
  location: null,
  modifiedByUser: null,
  modifiedByUserId: null,
  modifiedOn: null,
  region: null,
  regionId: null
});

@connect(['evaluationsStore', EvaluationsDynamicTableStore])
class EvaluationsSearch extends React.Component<EvaluationsSearchProps, EvaluationsSearchState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: EvaluationsSearchProps) {
    super(props);
    this.state = {
      filters: props.prevSearch || {
        assessment: undefined,
        profileId: undefined,
        profile: undefined,
        locationsId: [],
        level: undefined,
        machineModelId: undefined,
        machineModelName: undefined,
        oemId: undefined,
        equipmentTypeId: undefined,
        clusterId: undefined,
        showDisabled: undefined,
        positionCodesId: undefined
      },
      currentLocations: (props.prevSearch?.locationsId || []).map(mapToLocationDto),
      currentPositionCodes: (props.prevSearch?.positionCodesId || []).map(mapToPositionCodeDto),
      isSSASelected: this.props.prevSearch?.assessment === 'SSA' || false,
      isIwsSelected: this.props.prevSearch?.assessment === 'IWS' || false,
      loading: false
    };
  }

  private onHandleChangeFilter = (property: keyof EvaluationsSearchFilters, value: string | boolean) => {
    let filters = { ...this.state.filters };
    let isSSASelected = this.state.isSSASelected;
    let isIwsSelected = this.state.isIwsSelected;
    filters[property as string] = value;

    if (property === 'assessment') {
      isSSASelected = value === Assessment[Assessment.SSA].toString();
      isIwsSelected = value === Assessment[Assessment.IWS].toString();

      if (isSSASelected) {
        filters.level = undefined;
        filters.oemId = undefined;
        filters.equipmentTypeId = undefined;
        filters.clusterId = undefined;
        filters.machineModelId = undefined;
        filters.machineModelName = undefined;
      } else if (isIwsSelected) {
        filters.level = undefined;
        filters.oemId = undefined;
        filters.equipmentTypeId = undefined;
        filters.clusterId = undefined;
        filters.machineModelId = undefined;
        filters.machineModelName = undefined;
        filters.positionCodesId = undefined;
      } else filters.positionCodesId = undefined;

      if (isSSASelected !== this.state.isSSASelected || isIwsSelected !== this.state.isIwsSelected) {
        filters.profileId = undefined;
        filters.profile = undefined;
      }
    }

    this.setState({ filters, isSSASelected, isIwsSelected });
  };

  private onMachineModelFilterChange = (item: ItemReference) => {
    const filters = { ...this.state.filters };
    filters.machineModelId = item?.id || null;
    filters.machineModelName = item?.title || '';
    this.setState({ filters });
  };

  private onProfileChange = (item: ProfileItemDto) => {
    const filters = { ...this.state.filters };
    filters.profileId = item.id;
    filters.profile = item;
    this.setState({ filters });
  };

  private handleLocationsValueChange = (locations: LocationDto[]) => {
    const filters = { ...this.state.filters };
    const currentLocations = locations;
    filters.locationsId = locations.map(x => x.id);

    this.setState({ filters, currentLocations });
  };

  private handlePositionCodesValueChange = (positionCodes: PositionCodeDto[]) => {
    const filters = { ...this.state.filters };
    const currentPositionCodes = positionCodes;
    filters.positionCodesId = positionCodes.map(x => x.id);

    this.setState({ filters, currentPositionCodes });
  };

  private onSearchEmployees = () => {
    const { filters } = this.state;
    const store = this.props.evaluationsStore;

    this.setState({ loading: true });

    this.props.evaluationsStore
      .getCurrentScores(filters)
      .then(_ => {
        this.setState({ loading: false });
        if (store.state.result?.isSuccess && this.props.onSearchCompleted) this.props.onSearchCompleted(filters);
      })
      .catch(_ => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;
    const { loading, filters, isSSASelected, isIwsSelected } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const assessmentOptions = [
      { key: 1, text: t('TNA'), value: 'TNA' },
      { key: 2, text: t('SSA'), value: 'SSA' },
      { key: 3, text: t('IWS'), value: 'IWS' }
    ];

    const levelOptions = [
      { key: 1, text: t('Main'), value: 'Main' },
      { key: 2, text: t('Substitute'), value: 'Substitute' }
    ];
    return (
      <>
        {loading && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading')}</Loader>
          </Dimmer>
        )}

        <div className="evaluation-search-container">
          <h4 className="evaluation-search-title">{t('DEFINE YOUR SEARCH')}</h4>
          {this.props.evaluationsStore.state.result && !this.props.evaluationsStore.state.result.isSuccess && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={this.props.evaluationsStore.state.result.messages.map(o => o.body)}
            />
          )}

          {
            <Form>
              <Form.Group className="evaluation-search__filters">
                <Form.Field>
                  <label>{t('Assessment')}</label>
                  <Dropdown
                    className="custom-editor"
                    selection
                    multiple={false}
                    options={assessmentOptions}
                    value={filters.assessment}
                    onChange={(_event, data) => this.onHandleChangeFilter('assessment', data.value as string)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t('Profile')}</label>
                  <ProfileEditor
                    value={filters.profileId}
                    nullable
                    className="custom-editor"
                    onChange={this.onProfileChange}
                    assessment={filters.assessment}
                    readonly={isNullOrWhiteSpaces(filters.assessment)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t('Location')}</label>
                  <MultipleLocationEditor
                    className="custom-editor"
                    clearable
                    locationsReceived={
                      IdentityService.isPoc(currentUserInfo)
                        ? currentUserInfo.locationsByRoles[currentUserInfo.activeRole].toArray<string>()
                        : []
                    }
                    value={this.state.currentLocations}
                    onChange={this.handleLocationsValueChange}
                  />
                </Form.Field>
                {isSSASelected && (
                  <Form.Field>
                    <label>{t('Position Code')}</label>
                    <MultiplePositionCodeEditor
                      className="custom-editor"
                      clearable
                      value={this.state.currentPositionCodes}
                      onChange={this.handlePositionCodesValueChange}
                    />
                  </Form.Field>
                )}

                {!isSSASelected && !isIwsSelected && (
                  <Form.Field>
                    <label>{t('Level')}</label>
                    <Dropdown
                      className="custom-editor"
                      selection
                      clearable
                      multiple={false}
                      options={levelOptions}
                      value={filters.level}
                      onChange={(_event, data) => this.onHandleChangeFilter('level', data.value as string)}
                      disabled={isNullOrWhiteSpaces(filters.assessment) || isSSASelected || isIwsSelected}
                    />
                  </Form.Field>
                )}
                {!isSSASelected && !isIwsSelected && (
                  <Form.Field>
                    <label>{t('Cluster')}</label>
                    <MRClusterEditor
                      className="custom-editor"
                      nullable
                      value={filters.clusterId}
                      onChange={data => this.onHandleChangeFilter('clusterId', data ? data.id : null)}
                      readOnly={isNullOrWhiteSpaces(filters.assessment) || isSSASelected || isIwsSelected}
                    />
                  </Form.Field>
                )}
                {!isSSASelected && !isIwsSelected && (
                  <Form.Field>
                    <label>{t('Equipment Type')}</label>
                    <EquipmentTypeEditor
                      className="custom-editor"
                      nullable
                      clusterId={filters.clusterId ? filters.clusterId : undefined}
                      value={filters.equipmentTypeId}
                      onChange={data => this.onHandleChangeFilter('equipmentTypeId', data == null ? null : data.id)}
                      readOnly={isNullOrWhiteSpaces(filters.assessment) || isSSASelected || isIwsSelected}
                    />
                  </Form.Field>
                )}
                {!isSSASelected && !isIwsSelected && (
                  <Form.Field>
                    <label>{t('OEM')}</label>
                    <OemEditor
                      className="custom-editor"
                      nullable
                      equipmentId={filters.equipmentTypeId ? filters.equipmentTypeId : undefined}
                      value={filters.oemId}
                      onChange={data => this.onHandleChangeFilter('oemId', data == null ? null : data.id)}
                      readonly={isNullOrWhiteSpaces(filters.assessment) || isSSASelected || isIwsSelected}
                    />
                  </Form.Field>
                )}
                {!isSSASelected && !isIwsSelected && (
                  <Form.Field>
                    <label>{t('Machine Model')}</label>
                    <MachineModelEditor
                      className="custom-editor"
                      nullable
                      value={filters.machineModelId}
                      oemId={filters.oemId ? filters.oemId : undefined}
                      equipmentId={filters.equipmentTypeId ? filters.equipmentTypeId : undefined}
                      onChange={this.onMachineModelFilterChange}
                      readonly={isNullOrWhiteSpaces(filters.assessment) || isSSASelected || isIwsSelected}
                    />
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group className="evaluation-search__checkbox-btn">
                <Form.Field>
                  <Checkbox
                    label="INCLUDE EMPLOYEES DISABLED"
                    onClick={(_e, { checked }) => this.onHandleChangeFilter('showDisabled', checked)}
                  />
                </Form.Field>
                <Form.Field>
                  <Button className="inverted-color-btn" onClick={this.onSearchEmployees} icon>
                    {t('Search Employees')}&nbsp;
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          }
        </div>
        <div id="evaluation-search-footer">
          <p>{t('DEFINE A SEARCH TO SEE EMPLOYEE RESULTS')}</p>
        </div>
      </>
    );
  }
}

export default withTranslation()(EvaluationsSearch);
