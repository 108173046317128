import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface PillarDto extends BaseDto {
  id: string;
  name: string;
  managedBy?: PlanitPlannerTypes;
}

export enum PlanitPlannerTypes {
  plannerTFT = 'PlannerTFT',
  plannerMTC = 'PlannerMTC'
}

export interface CreatePillarDto {
  name: string;
  managedBy?: number;
}

export interface ChangePillarDto {
  id: string;
  name: string;
  managedBy?: number;
}

export class PillarValidator extends AbstractValidator<PillarDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pillar Name is required'));
  }
}

export class CreatePillarValidator extends AbstractValidator<CreatePillarDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pillar Name is required'));
  }
}

export class ChangePillarValidator extends AbstractValidator<ChangePillarDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Pillar Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pillar Name is required'));
  }
}

@repository('@@PILLARS', 'pillars.summary')
export class PillarsStore extends DataStore<PillarDto> {
  baseUrl = 'identity/api/v1';
  createPath = 'create-pillar';
  retrievePath = 'get-pillars';
  updatePath = 'update-pillar';
  deletePath = 'delete-pillar';

  protected validate(item: PillarDto) {
    return new PillarValidator().validate(item);
  }

  constructor() {
    super('PILLAR', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PILLARS', 'pillars.dropdown')
export class DropDownPillarsStore extends DataStore<PillarDto> {
  baseUrl = 'identity/api/v1';
  createPath = '';
  retrievePath = 'get-pillars';
  updatePath = '';
  deletePath = '';

  protected validate(item: PillarDto) {
    return new PillarValidator().validate(item);
  }

  constructor() {
    super('PILLARDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, pillarIds?: string[], search?: string): Promise<QueryResult<PillarDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let pillarsDto = this.state.items.map(({ item }) => item);

      if ((pillarIds || []).length > 0) {
        pillarsDto = pillarsDto.filter(x => pillarIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        pillarsDto = pillarsDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: pillarsDto, count: pillarsDto.length } as QueryResult<PillarDto>;
      return new Promise<QueryResult<PillarDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<PillarDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<PillarDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@PILLARS', 'pillars.new')
export class NewPillarStore extends FormStore<CreatePillarDto> {
  baseUrl = 'identity/api/v1';
  createPath = 'create-pillar';
  retrievePath = 'get-pillars';
  updatePath = 'update-pillar';

  protected validate(item: CreatePillarDto) {
    return new CreatePillarValidator().validate(item);
  }

  constructor() {
    super('NEW_PILLAR', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PILLARS', 'pillars.change')
export class ChangePillarStore extends FormStore<ChangePillarDto> {
  baseUrl = 'identity/api/v1';
  createPath = 'create-pillar';
  retrievePath = 'get-pillars';
  updatePath = 'update-pillar';

  protected validate(item: ChangePillarDto) {
    return new ChangePillarValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PILLAR', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
