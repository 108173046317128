import * as React from 'react';
import i18n from 'i18n';
import { Container, Header, Icon } from 'semantic-ui-react';

interface WhiteScreenViewState {
  hasError: boolean;
}

export default class WhiteScreenView extends React.Component<{}, WhiteScreenViewState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.clear();
    console.error('============= SEND FROM HERE 👇🏻👇🏻👇🏻 ===============');
    console.error(error);
    console.error(errorInfo);
    console.error('============= SEND TO HERE 👆🏻👆🏻👆🏻 ===============');
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="planit-general-error-container">
          <Container>
            <Header icon>
              <Icon name="frown outline" />
              {i18n.t('Oops! Something went really wrong')}
            </Header>
            <p>
              {i18n.t(
                'Please clean your browser cache and try again. If problem persists please press F12 and send all logs in the Console tab when you report this incident.'
              )}
            </p>
            <small>
              {i18n.t('Click')} <a href="/">{i18n.t('here')}</a> {i18n.t('to return to home')}
            </small>
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}
