import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { IdentityService } from 'services/identity-service';
import { Dropdown } from 'semantic-ui-react';
import { resolve } from 'inversify-react';
import { ItemReference } from 'stores/dataStore';
import { DropDownCountriesStore, CountryDto } from 'stores/configuration/locations/countries-store';

interface CountrySelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  onChangeCountry?: (value: ItemReference) => void;
  countries?: DropDownCountriesStore;
  filterByRole?: boolean;
  direction?: 'left' | 'right';
}

@connect(['countries', DropDownCountriesStore])
class CountrySelector extends React.Component<CountrySelectorProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    let filter: any = [];
    let countriesByActiveRole: string[];

    const activeRole = this.identityService.activeRole;

    if (this.props.filterByRole && activeRole !== 'Admin') {
      const currentUserInfo = this.identityService.getUserInfo();
      countriesByActiveRole = currentUserInfo.locationsByRoles[activeRole] as string[];
      filter = [{ Id: { in: { type: 'guid', value: countriesByActiveRole } } }];
    }

    this.props.countries
      .getAllAsync(
        {
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<CountryDto>('name'), useProfile: false }],
          filter
        },
        countriesByActiveRole
      )
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.name }))
        });
      });
  }

  handleOnChange(id: string) {
    if (this.props.onChange) {
      this.props.onChange(id);
      return;
    }

    if (this.props.onChangeCountry) {
      if (!id) {
        this.props.onChangeCountry(null);
        return;
      }

      let option = this.state.options.find(x => x.value === id);
      this.props.onChangeCountry({
        id: option.value,
        title: option.text
      });
    }
  }

  public render() {
    const { options, isLoading } = this.state;
    const { value, placeholder, direction, readOnly, className, clearable } = this.props;
    const selectedValue = value || null;

    return (
      <Dropdown
        direction={direction}
        selection
        loading={isLoading}
        search
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => this.handleOnChange(value as string)}
        selectOnBlur={false}
        disabled={readOnly}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(CountrySelector);
