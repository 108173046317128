import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from '../../stores/dataStore';
import { DropdownProps, SelectionInput } from '../form/selectionInput';
import { nameof } from '../../utils/object';
import { ItemReference } from '../../stores/dataStore';
import { isNullOrWhiteSpaces, customEqualCompare } from '../../utils/useful-functions';
import { TnaSkillsStore, TnaSkillDto } from 'stores/assessments/skills/tna-skills-store';
import { SsaSkillsStore, SsaSkillDto } from 'stores/assessments/skills/ssa-skills-store';
import { IwsSkillsStore, IwsSkillDto } from 'stores/assessments/skills/iws-skills-store';

export enum SkillType {
  TNA = 10,
  SSA = 20,
  IWS = 30
}

export interface SkillItemReference extends ItemReference {
  testCategoryId?: string;
}

interface SkillsEditorProps extends DropdownProps, WithTranslation {
  value: SkillItemReference;
  onChange?: (value: SkillItemReference) => void;
  placeholder?: string;
  tnaSkill?: TnaSkillsStore;
  ssaSkill?: SsaSkillsStore;
  iwsSkill?: IwsSkillsStore;
  nullable?: boolean;
  readOnly?: boolean;
  minWidth?: number;
  reloadOnChange?: boolean;
  className?: string;
  clearable?: boolean;
  categoryId?: string;
  skillType: SkillType;
}

interface SkillsEditorState {
  value: SkillItemReference;
  query?: (searchQuery: string) => Promise<QueryResult<SkillItemReference>>;
}

@connect(['tnaSkill', TnaSkillsStore], ['ssaSkill', SsaSkillsStore], ['iwsSkill', IwsSkillsStore])
class SkillsEditor extends React.Component<SkillsEditorProps, SkillsEditorState> {
  private get tnaSkillsStore() {
    return this.props.tnaSkill;
  }

  private get ssaSkillsStore() {
    return this.props.ssaSkill;
  }

  private get iwsSkillsStore() {
    return this.props.iwsSkill;
  }

  constructor(props: Readonly<SkillsEditorProps>) {
    super(props);
    this.state = {
      value: this.props.value,
      query: this.getSkills()
    };
  }

  componentDidUpdate(prevProps: Readonly<SkillsEditorProps>) {
    const { value, reloadOnChange, categoryId, skillType } = this.props;

    if (!customEqualCompare(prevProps.value, value)) {
      if (reloadOnChange)
        this.setState({
          value,
          query: this.getSkills()
        });
      else this.setState({ value });
    }

    if (!customEqualCompare(prevProps.categoryId, categoryId) || !customEqualCompare(prevProps.skillType, skillType)) {
      this.setState({
        value,
        query: this.getSkills()
      });
    }
  }

  private getSkills = () => {
    const method = async (search: string) => {
      let result = await this.getSkillsByType(search);

      return Object.assign({}, result, {
        items: result.items.map(c => ({
          id: c.id,
          title: c.name,
          isMachineRelated: !!c['isMachineRelated'],
          testCategoryId: c.testCategoryId
        }))
      }) as QueryResult<SkillItemReference>;
    };
    return method;
  };

  private getSsaSkills = async (search: string) => {
    let filters = this.getCommonFilters(search);
    return await this.ssaSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<SsaSkillDto>('name'), useProfile: false }],
      filter: filters
    });
  };

  private getTnaSkills = async (search: string) => {
    let filters = this.getCommonFilters(search);
    return await this.tnaSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<TnaSkillDto>('name'), useProfile: false }],
      filter: filters
    });
  };

  private getIwsSkills = async (search: string) => {
    let filters = this.getCommonFilters(search);
    return await this.iwsSkillsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: nameof<IwsSkillDto>('name'), useProfile: false }],
      filter: filters
    });
  };
  private getCommonFilters = (search: string) => {
    const { categoryId } = this.props;
    let filters = [];

    if (!isNullOrWhiteSpaces(search)) filters.push({ StartsWith: { Name: search.toLowerCase() } });
    if (categoryId) filters.push({ TestCategoryId: { eq: { type: 'guid', value: categoryId } } });

    return filters;
  };
  private async getSkillsByType(search: string): Promise<QueryResult<any>> {
    let result: QueryResult<any>;
    if (this.props.skillType === SkillType.TNA) {
      result = await this.getTnaSkills(search);
    }
    if (this.props.skillType === SkillType.SSA) {
      result = await this.getSsaSkills(search);
    }
    if (this.props.skillType === SkillType.IWS) {
      result = await this.getIwsSkills(search);
    }
    return result;
  }

  render() {
    const { minWidth, nullable, placeholder, className, clearable, readOnly, onChange } = this.props;
    const { value, query } = this.state;

    return (
      <SelectionInput
        content={item => <>{item.title}</>}
        searchable
        readOnly={readOnly}
        minWidth={minWidth}
        nullable={nullable}
        placeholder={placeholder}
        query={query}
        value={value}
        onChange={value => onChange && onChange(value as any)}
        className={className}
        clearable={clearable}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(SkillsEditor);
