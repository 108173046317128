import React from 'react';
import { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { ChangeRequestStore, RequestStatus } from '../../../../../../stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import RequestCancelPopup from './request-cancel-popup';
import { replaceAll } from 'utils/useful-functions';

interface RequestCancelButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestCreatorRoles: string[];
  changeRequestStore?: ChangeRequestStore;
  readOnly: boolean;
  isGea: boolean;
  isGma: boolean;

  onSuccess?: () => void;
  onError?: () => void;
}

interface RequestCancelButtonState {
  showRequestCancelPopup: boolean;
}

@connect(['changeRequestStore', ChangeRequestStore])
class RequestCancelButton extends Component<RequestCancelButtonProps, RequestCancelButtonState> {
  static defaultProps = { onSuccess: () => {}, onError: () => {} };
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: RequestCancelButtonState = { showRequestCancelPopup: false };

  handleOnCancel = () => this.setState({ showRequestCancelPopup: false });

  handleOnClick = () => this.setState({ showRequestCancelPopup: true });

  isVisible = (): boolean => this.isVisibleInCurrentStatus() && this.currentUserHasPermission();

  isVisibleInCurrentStatus = (): boolean =>
    this.props.requestStatus.toString() === 'Draft' || this.props.requestStatus.toString() === 'Pending';

  currentUserHasPermission = (): boolean => {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma, requestCreatorRoles } = this.props;

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;
    if (IdentityService.isPlanner(userInfo) && (isGea || isGma)) return false;

    return (requestCreatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''));
  };

  render() {
    const { t, readOnly, onError, onSuccess, requestId } = this.props;
    const { showRequestCancelPopup } = this.state;
    return (
      this.isVisible() && (
        <>
          <Button
            className="form__actions__button form__cancel-btn request-form__cancel-btn"
            disabled={!readOnly}
            onClick={this.handleOnClick}
          >
            {t('Cancel Request')}
          </Button>
          {showRequestCancelPopup && (
            <RequestCancelPopup
              requestId={requestId}
              open={showRequestCancelPopup}
              onCancel={this.handleOnCancel}
              onSuccess={onSuccess}
              onError={onError}
            />
          )}
        </>
      )
    );
  }
}

export default withTranslation()(RequestCancelButton);
