import React from 'react';

import { container } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FullPageLoader } from '../full-page-loader';

export const SilentRenew = () => {
  container.get(IdentityService).signinSilentCallback();
  return <FullPageLoader text={'Silent Renew'} />;
};
