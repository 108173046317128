import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { DropDownPriorityStore, PriorityDto } from '../../../stores/configuration/events-n-requests/priorities-store';
import { nameof } from '../../../utils/object';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface PriorityEditorProps extends WithTranslation {
  value: PriorityDto[];
  onChange?: (value: PriorityDto[]) => void;
  priorities?: DropDownPriorityStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  prioritiesReceived?: string[];
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface PriorityEditorState {
  options: MultipleSelectionDropdownOptions<PriorityDto>[];
}

@connect(['priorities', DropDownPriorityStore])
class MultiplePriorityEditor extends React.Component<PriorityEditorProps, PriorityEditorState> {
  private get priorityStore() {
    return this.props.priorities;
  }

  state: PriorityEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions = (priorities: QueryResult<PriorityDto>): MultipleSelectionDropdownOptions<PriorityDto>[] =>
    (priorities?.items || []).map(region => ({ id: region.id, value: region, text: region.name }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      let filter;
      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<PriorityDto>('name'), useProfile: false }];

      if (this.props.prioritiesReceived && this.props.prioritiesReceived.length > 0)
        filter = [{ id: { in: { type: 'guid', value: this.props.prioritiesReceived } } }];

      this.priorityStore
        .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }, this.props.prioritiesReceived)
        .then(priorities => this.setState({ options: this.mapToOptions(priorities) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiplePriorityEditor);
