import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Loader, Dimmer } from 'semantic-ui-react';
import { NewUsefulLinkStore, CreateUsefulLinkDto } from 'stores/configuration/useful-links/useful-links-store';
import { nameof } from 'utils/object';
import RichTextEditor from 'react-rte';

interface NewUsefulLinkViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newUsefulLink?: NewUsefulLinkStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewUsefulLinkViewState {
  editorValue: any;
  userClickedSaved: boolean;
}

@connect(['newUsefulLink', NewUsefulLinkStore])
class NewUsefulLinkView extends React.Component<NewUsefulLinkViewProps, NewUsefulLinkViewState> {
  private get newUsefulLinkStore() {
    return this.props.newUsefulLink;
  }

  constructor(props: NewUsefulLinkViewProps) {
    super(props);
    this.newUsefulLinkStore.createNew({
      name: '',
      link: '',
      role: '',
      active: true
    });
    this.state = {
      editorValue: RichTextEditor.createEmptyValue(),
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewUsefulLinkViewProps) {
    if (this.newUsefulLinkStore.state.result && this.newUsefulLinkStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  handleEditorChanges = editorValue => {
    this.setState({ editorValue });
    this.handleValue(nameof<CreateUsefulLinkDto>('link'), editorValue.toString('html'));
  };

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newUsefulLinkStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newUsefulLinkStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newUsefulLinkStore.change({ ...this.newUsefulLinkStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const roleOptions = [
      { key: 'e', text: t('Employee'), value: 'Employee' },
      { key: 'p', text: t('PoC'), value: 'PoC' },
      { key: 'i', text: t('Instructor'), value: 'Instructor' }
    ];
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newUsefulLinkStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Useful Link')}</Modal.Header>
        <Modal.Content image>
          <Container>
            <Dimmer active={this.newUsefulLinkStore.state.isBusy} inverted>
              <Loader size="large" indeterminate inverted></Loader>
            </Dimmer>
            {this.newUsefulLinkStore.state.result && !this.newUsefulLinkStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newUsefulLinkStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newUsefulLinkStore.state.item && (
              <Form>
                <Form.Group widths="equal" className="useful-link-form-group">
                  <Form.Input
                    required
                    fluid
                    error={!this.newUsefulLinkStore.state.item.name && this.state.userClickedSaved}
                    label={t('Link')}
                    placeholder={t('Name')}
                    value={this.newUsefulLinkStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateUsefulLinkDto>('name'), value)}
                  />
                  <Form.Select
                    required
                    fluid
                    error={!this.newUsefulLinkStore.state.item.role && this.state.userClickedSaved}
                    label={t('Role')}
                    placeholder={t('Role')}
                    options={roleOptions}
                    value={this.newUsefulLinkStore.state.item.role}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateUsefulLinkDto>('role'), value)}
                  />
                  <Form.Checkbox
                    className="custom-check-box custom-position"
                    toggle
                    checked={this.newUsefulLinkStore.state.item.active}
                    label={t('Active')}
                    placeholder={t('Active')}
                    onChange={(e, { checked }) => this.handleValue(nameof<CreateUsefulLinkDto>('active'), checked)}
                  />
                </Form.Group>
              </Form>
            )}
            <br />
            <RichTextEditor className="custom-rte rte-30vh" value={this.state.editorValue} onChange={this.handleEditorChanges} />
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewUsefulLinkView);
