import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface EventTypeGroupDto extends BaseDto {
  id: string;
  name: string;
}

export interface CreateEventTypeGroupDto {
  name: string;
}

export interface ChangeEventTypeGroupDto {
  id: string;
  name: string;
}

export class CreateEventTypeGroupValidator extends AbstractValidator<CreateEventTypeGroupDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Type Group Name is required'));
  }
}

export class ChangeEventTypeGroupValidator extends AbstractValidator<ChangeEventTypeGroupDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Group Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Eent Type Group Name is required'));
  }
}

@repository('@@EVENTTYPEGROUPS', 'eventTypeGroup.summary')
export class EventTypeGroupsStore extends DataStore<EventTypeGroupDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroup';
  retrievePath = 'get-eventGroups';
  updatePath = 'update-eventGroup';
  deletePath = 'delete-eventGroup';

  protected validate(item: EventTypeGroupDto) {
    return new ChangeEventTypeGroupValidator().validate(item);
  }

  constructor() {
    super('EVENTTYPEGROUP', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTTYPEGROUPS', 'eventTypeGroup.dropdown')
export class DropDownEventTypeGroupsStore extends DataStore<EventTypeGroupDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-eventGroups';
  updatePath = '';
  deletePath = '';

  protected validate(item: EventTypeGroupDto) {
    return new ChangeEventTypeGroupValidator().validate(item);
  }

  constructor() {
    super('EVENTTYPEGROUPDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTTYPEGROUPS', 'eventTypeGroup.new')
export class NewEventTypeGroupStore extends FormStore<CreateEventTypeGroupDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroup';
  retrievePath = 'get-eventGroup';
  updatePath = 'update-eventGroup';

  protected validate(item: CreateEventTypeGroupDto) {
    return new CreateEventTypeGroupValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENTTYPEGROUP', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EVENTTYPEGROUPS', 'eventTypeGroup.change')
export class ChangeEventTypeGroupStore extends FormStore<ChangeEventTypeGroupDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-eventGroup';
  retrievePath = 'get-eventTypeGroup';
  updatePath = 'update-eventGroup';

  protected validate(item: ChangeEventTypeGroupDto) {
    return new ChangeEventTypeGroupValidator().validate(item);
  }

  constructor() {
    super('CHANGE_EVENTTYPEGROUP', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
