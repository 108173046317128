import * as autobind from 'autobind';
import check from 'assets/img/check-solid-white.png';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Message, Modal, Loader, Dimmer } from 'semantic-ui-react';
import { RequestStatus } from '../../../stores/requests/requests-store';
import Stepper from 'react-stepper-horizontal';
import StepBasicComponent from './wizard/step1-basic';
import StepSubjectMachineRelated from './wizard/step2-subject-machine-related';
import StepEventsDetails from './wizard/step3-event-details';
import StepDates from './wizard/step4-dates';
import StepInstructors from './wizard/step5-instructors';
import StepComments from './wizard/step6-comments';
import { RequestWizardStore } from 'stores/requests/create-request-wizard-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { isNullOrEmpty, isNullOrWhiteSpaces, randomInteger } from 'utils/useful-functions';
import NonMachineRelatedComponent, {
  NonMachineRelatedComponentViewModel
} from 'site/pages/shared/events-and-requests/non-machine-related/non-machine-related';
import { EventTypeCategory, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import SupportDetailsContainer from './wizard-support-details-container';
import StudentsStep from './wizard/students-step-container';
import { ToastComponent } from '../landing-pages/util/toast-component';
import EventService, { EventDurationRequestDto } from 'stores/events/event-service';

interface NewRequestViewProps extends WithTranslation {
  onClose: (isSucess: boolean) => void;
  requestWizardStore?: RequestWizardStore;
}

interface NewRequestViewState {
  steps: any[];
  errorMessages: any[];
  isBusy: boolean;
  currentStepEnum: Step;
  currentComponent: StepComponent;
  durationCalculationWarning: boolean;
}

enum Step {
  INITIAL = -1,
  BASIC = 0,
  SUBJECT = 1,
  EVENTDETAILS = 2,
  DATES = 3,
  SUPPORTDETAILS = 4,
  STUDENTS = 5,
  INSTRUCTOR = 6,
  COMMENTS = 7,
  END = 8
}

enum StepComponent {
  StepBasic,
  StepMachineRelated,
  StepNonMachineRelated,
  StepEventDetails,
  StepDate,
  StepSupportDetails,
  StepStudents,
  StepInstructor,
  StepComment
}

enum Action {
  GO_BACK = 0,
  GO_NEXT = 1
}

@connect(['requestWizardStore', RequestWizardStore])
class NewRequestView extends React.Component<NewRequestViewProps, NewRequestViewState> {
  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  @resolve(EventService)
  eventsService: EventService;

  constructor(props: NewRequestViewProps) {
    super(props);
    const { t } = this.props as any;

    this.state = {
      isBusy: false,
      errorMessages: [],
      currentStepEnum: Step.BASIC,
      currentComponent: StepComponent.StepBasic,
      durationCalculationWarning: false,
      steps: [
        {
          title: t('Basic'),
          visible: true,
          internalStep: Step.BASIC,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Subject'),
          visible: false,
          internalStep: Step.SUBJECT,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Event Details'),
          visible: false,
          internalStep: Step.EVENTDETAILS,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Dates'),
          visible: true,
          internalStep: Step.DATES,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Support Details'),
          visible: false,
          internalStep: Step.SUPPORTDETAILS,
          onClick: e => {
            e.preventDefault();
          }
        },

        {
          title: t('Students'),
          visible: false,
          internalStep: Step.STUDENTS,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Instructor'),
          visible: false,
          internalStep: Step.INSTRUCTOR,
          onClick: e => {
            e.preventDefault();
          }
        },
        {
          title: t('Comments'),
          visible: true,
          internalStep: Step.COMMENTS,
          onClick: e => {
            e.preventDefault();
          }
        }
      ]
    };

    this.requestWizardStore.createNew({
      requestId: Math.random().toString(),
      status: RequestStatus.Draft,
      customization: null,
      deliveryMethod: null,
      isMachineRelated: null,
      withPattern: false,
      hasModifiedPattern: false,
      language: null,
      eventType: null,
      eventLocation: null,
      requestLocation: null,
      priority: null,
      role: null,
      isTechnical: null,
      eventsColor: null,
      hasSupportDetails: false,
      isRequestDetails: false,
      isParticipantYes: false,
      studentsMandatory: true,
      isEventDetails: false,
      isInstructorYes: false,
      requestReason: null,
      requestReasonComments: null,
      requestReasonEventTypes: [],
      category: null,
      manualTitle: false,
      manualStudents: false,
      machineModels: [],
      nmrCluster: null,
      nmrFunctionalArea: null,
      nmrFunctionalSubArea: null,
      nmrTrainingName: null,
      pattern: null,
      title: '',
      trainingLevel: null,
      studentsNumber: null,
      students: [],
      endDate: null,
      startDate: null,
      desiredEventDuration: null,
      instructor: null,
      instructorLocation: null,
      comments: '',
      requestOwnerId: this.identityService.userId,
      requestingLocationId: null,
      plcNames: [],
      machineRelated: false,
      machines: [],
      supportDetails: {
        requestPreApprovedDuringASP: false,
        totalCostLocationA: 0,
        totalCostLocationB: 0,
        totalCostLocationC: 0,
        totalCostLocationD: 0,
        supportPositions: [],
        newSupportPositions: [],
        isNewSupportPositionModel: true,
        totalRequestedManDays: 0,
        totalRequestedManMonths: 0,
        totalRequestedWorkingManHours: 0,
        totalTheoreticalCost: 0
      },
      calculatedEventDuration: 0,
      plannedDuration: '0',
      userEventDuration: 0,
      requestPreApprovedDuringASPCheck: false
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewRequestViewProps) {
    if (this.requestWizardStore.state.result && this.requestWizardStore.state.result.isSuccess) {
      nextProps.onClose(this.requestWizardStore.state.result.isSuccess);
    }
  }

  @autobind
  private onCreateNewItem() {
    const currentUser = this.identityService.getUserInfo();

    this.requestWizardStore.change({
      requestId: Math.random().toString(),
      requestOwnerId: currentUser.userId
    });

    if (!this.requestWizardStore.state.item.title) {
      this.requestWizardStore.change({
        title: `Request_${randomInteger(100, 1000000)}`
      });
    }

    this.requestWizardStore
      .submitWizzard()
      .then(res => this.showToast(res))
      .catch(err => {
        console.error(err);
        ToastComponent({ text: this.props.t('Request save failed'), type: 'warning-toast' });
      });
  }

  private showToast = res => {
    const isDraft = this.props.requestWizardStore.state.item.status === RequestStatus.Draft;

    if (res.isSuccess && isDraft) {
      ToastComponent({ text: this.props.t('Save Request Draft successfully!'), type: 'success-toast' });
    } else if (res.isSuccess) {
      ToastComponent({ text: this.props.t('Send Request successfully!'), type: 'success-toast' });
    } else {
      this.props.requestWizardStore.state.result.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'warning-toast' }));
    }
  };

  @autobind
  private onCreateNewItemDraft() {
    const { item } = this.requestWizardStore.state;
    this.requestWizardStore.change({
      status: RequestStatus.Draft,
      machineModels: item.machines.map(({ cluster, equipmentType, machineModel, machineUnits, oem, plcTypes }) => ({
        machineRelatedClusterId: cluster && cluster.id,
        machineRelatedClusterName: cluster && cluster.title,
        equipmentTypeId: equipmentType && equipmentType.id,
        equipmentTypeName: equipmentType && equipmentType.title,
        oemId: oem && oem.id,
        oemName: oem && oem.title,
        machineModelId: machineModel && machineModel.id,
        machineModelName: machineModel && machineModel.title,
        plcTypeRequestMachines: plcTypes,
        machineUnitRequestMachines: machineUnits
      }))
    });

    this.onCreateNewItem();
  }

  @autobind
  private nextOrPending() {
    const isLastStep = this.state.currentStepEnum === Step.COMMENTS;
    if (isLastStep) {
      this.onCreateNewItemPending();
    } else {
      this.handleNext(Action.GO_NEXT);
    }
  }

  @autobind
  private onCreateNewItemPending() {
    const { item } = this.requestWizardStore.state;
    this.requestWizardStore.change({
      status: RequestStatus.Pending,
      machineModels: item.machines.map(({ cluster, equipmentType, machineModel, machineUnits, oem, plcTypes }) => ({
        machineRelatedClusterId: cluster && cluster.id,
        machineRelatedClusterName: cluster && cluster.title,
        equipmentTypeId: equipmentType && equipmentType.id,
        equipmentTypeName: equipmentType && equipmentType.title,
        oemId: oem && oem.id,
        oemName: oem && oem.title,
        machineModelId: machineModel && machineModel.id,
        machineModelName: machineModel && machineModel.title,
        plcTypeRequestMachines: plcTypes,
        machineUnitRequestMachines: machineUnits
      }))
    });

    this.onCreateNewItem();
  }

  @autobind
  private onCancelNewItem() {
    this.requestWizardStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleNext(action) {
    if (action === Action.GO_NEXT) {
      const validationResult = this.validateCurrentStep();
      const isStepInvalid = validationResult && validationResult.isInvalid();

      if (isStepInvalid) {
        this.requestWizardStore.Validate(validationResult);
      } else {
        this.requestWizardStore.state.result = null;
        const [nextStep, component] = this.getNext(this.state.currentStepEnum, this.props.requestWizardStore.state.item);
        if ((nextStep as any) === StepComponent.StepEventDetails)
          this.recalculateEventDuration().catch(_ => {
            this.setState({ isBusy: false });
          });
        this.setState({ currentStepEnum: nextStep, currentComponent: component });
      }
    }
    //action GO BACK
    else if (this.state.currentStepEnum !== Step.BASIC) {
      const [backStep, component] = this.getBack(this.state.currentStepEnum, this.props.requestWizardStore.state.item);

      this.setState({ currentStepEnum: backStep, currentComponent: component });
    }
  }

  private validateCurrentStep = () => {
    const { currentStepEnum, currentComponent } = this.state;
    const { requestWizardStore } = this.props;
    const { item } = requestWizardStore.state;

    let validationResult = null;

    if (currentStepEnum === Step.BASIC) {
      validationResult = requestWizardStore.validateBasicStep(item);
    } else if (currentStepEnum === Step.SUBJECT && currentComponent === StepComponent.StepMachineRelated && item.isRequestDetails) {
      validationResult = requestWizardStore.validateSubjectStep(item);
    } else if (currentStepEnum === Step.SUBJECT && currentComponent === StepComponent.StepNonMachineRelated && item.isRequestDetails) {
      validationResult = requestWizardStore.validateNMRSubjectStep(item);
    } else if (currentStepEnum === Step.EVENTDETAILS) {
      validationResult = requestWizardStore.validateEventDetailsStep(item);
    } else if (currentStepEnum === Step.DATES) {
      validationResult = requestWizardStore.validateDatesStep(item);
    } else if (currentStepEnum === Step.SUPPORTDETAILS) {
      validationResult = requestWizardStore.validateSupportDetailsStep(item);
    } else if (currentStepEnum === Step.STUDENTS) {
      validationResult = requestWizardStore.validateStudentsStep(item);
    } else if (currentStepEnum === Step.INSTRUCTOR) {
      validationResult = requestWizardStore.validateInstructorStep(item);
    } else if (currentStepEnum === Step.COMMENTS) {
      validationResult = requestWizardStore.validateCommentsStep(item);
    }
    return validationResult;
  };

  getBack = (prev: Step, event: any): [Step, StepComponent] => {
    let st = prev;

    if (st === Step.COMMENTS) {
      if (
        event.isInstructorYes &&
        EventTypeCategory[((event.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
      )
        return [Step.INSTRUCTOR, StepComponent.StepInstructor];
      st = Step.INSTRUCTOR;
    }

    if (st === Step.INSTRUCTOR) {
      if (
        event.isParticipantYes &&
        EventTypeCategory[((event.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
      )
        return [Step.STUDENTS, StepComponent.StepStudents];
      st = Step.STUDENTS;
    }

    if (st === Step.STUDENTS) {
      if (event.hasSupportDetails) return [Step.SUPPORTDETAILS, StepComponent.StepSupportDetails];
      st = Step.SUPPORTDETAILS;
    }

    if (st === Step.SUPPORTDETAILS) {
      return [Step.DATES, StepComponent.StepDate];
    }

    if (st === Step.DATES) {
      if (event.isEventDetails) return [Step.EVENTDETAILS, StepComponent.StepEventDetails];
      st = Step.EVENTDETAILS;
    }

    if (st === Step.EVENTDETAILS) {
      if (
        event &&
        event.eventType &&
        (EventTypeCategory[(event.eventType as EventTypeDto).eventTypeCategory] === EventTypeCategory.Vacations ||
          event.eventType.eventTypeCategory === 'Extended')
      ) {
        return [Step.BASIC, StepComponent.StepBasic];
      }
      if (event.isMachineRelated) {
        return [Step.SUBJECT, StepComponent.StepMachineRelated];
      } else {
        return [Step.SUBJECT, StepComponent.StepNonMachineRelated];
      }
    }

    if (st === Step.SUBJECT) {
      return [Step.BASIC, StepComponent.StepBasic];
    }

    if (st === Step.INITIAL) return [Step.INITIAL, StepComponent.StepBasic];

    if (st === Step.BASIC) {
      return [Step.BASIC, StepComponent.StepBasic];
    }
  };

  getNext = (prev: Step, event: any): [Step, StepComponent] => {
    if (prev === Step.INITIAL) return [Step.BASIC, StepComponent.StepBasic];

    if (prev === Step.BASIC) {
      if (
        event &&
        event.eventType &&
        (EventTypeCategory[(event.eventType as EventTypeDto).eventTypeCategory] === EventTypeCategory.Vacations ||
          EventTypeCategory[((event.eventType as any) as EventTypeDto).eventTypeCategory] === EventTypeCategory.Extended)
      ) {
        return [Step.DATES, StepComponent.StepDate];
      }

      if (event.isMachineRelated) return [Step.SUBJECT, StepComponent.StepMachineRelated];
      return [Step.SUBJECT, StepComponent.StepNonMachineRelated];
    }

    if (prev === Step.SUBJECT) {
      if (event.isEventDetails) return [Step.EVENTDETAILS, StepComponent.StepEventDetails];
      return [Step.DATES, StepComponent.StepDate];
    }

    if (prev === Step.EVENTDETAILS) return [Step.DATES, StepComponent.StepDate];

    if (prev === Step.DATES) {
      if (event.hasSupportDetails) return [Step.SUPPORTDETAILS, StepComponent.StepSupportDetails];

      if (event.isParticipantYes) return [Step.STUDENTS, StepComponent.StepStudents];
      if (event.isInstructorYes) return [Step.INSTRUCTOR, StepComponent.StepInstructor];
      return [Step.COMMENTS, StepComponent.StepComment];
    }
    if (prev === Step.SUPPORTDETAILS) {
      if (
        event.isParticipantYes &&
        EventTypeCategory[((event.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
      )
        return [Step.STUDENTS, StepComponent.StepStudents];
      if (
        event.isInstructorYes &&
        EventTypeCategory[((event.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
      )
        return [Step.INSTRUCTOR, StepComponent.StepInstructor];
      return [Step.COMMENTS, StepComponent.StepComment];
    }

    if (prev === Step.STUDENTS) {
      if (event.isInstructorYes) return [Step.INSTRUCTOR, StepComponent.StepInstructor];
      return [Step.COMMENTS, StepComponent.StepComment];
    }

    if (prev === Step.INSTRUCTOR) return [Step.COMMENTS, StepComponent.StepComment];

    if (prev === Step.COMMENTS) return [Step.END, StepComponent.StepComment];

    return [Step.END, StepComponent.StepComment];
  };

  createNonMachineRelatedComponent = (): React.ReactNode => {
    const { t, requestWizardStore } = this.props;
    const { nmrCluster, nmrFunctionalArea, nmrFunctionalSubArea, nmrTrainingName, category } = requestWizardStore.state.item;

    const nMR: NonMachineRelatedComponentViewModel = {
      cluster: nmrCluster,
      functionalArea: nmrFunctionalArea,
      functionalSubArea: nmrFunctionalSubArea,
      trainingName: nmrTrainingName
    };
    return (
      <>
        <h3 className="machine-related__title">{t('NON-MACHINE RELATED')}</h3>
        <NonMachineRelatedComponent
          category={category}
          className="wizard__no-machine-related"
          value={nMR}
          readOnly={false}
          onChange={this.handleNMRChange}
        />
      </>
    );
  };

  loading: boolean = false;

  @autobind
  private getStepContent() {
    const { currentComponent: nextComponent, durationCalculationWarning } = this.state;

    if (nextComponent === StepComponent.StepBasic)
      return (
        <StepBasicComponent
          onSelectEventTypeBegin={() => this.setState({ isBusy: true })}
          onSelectEventTypeEnd={() => this.setState({ isBusy: false })}
          {...this.props}
        />
      );
    else if (nextComponent === StepComponent.StepMachineRelated) return <StepSubjectMachineRelated {...this.props} />;
    else if (nextComponent === StepComponent.StepNonMachineRelated) return this.createNonMachineRelatedComponent();
    else if (nextComponent === StepComponent.StepDate) return <StepDates {...this.props} durationWarning={durationCalculationWarning} />;
    else if (nextComponent === StepComponent.StepEventDetails) return <StepEventsDetails {...this.props} />;
    else if (nextComponent === StepComponent.StepSupportDetails) return <SupportDetailsContainer {...this.props} readOnly={false} />;
    else if (nextComponent === StepComponent.StepStudents) return <StudentsStep {...this.props} />;
    else if (nextComponent === StepComponent.StepInstructor) return <StepInstructors {...this.props} />;
    else if (nextComponent === StepComponent.StepComment) return <StepComments {...this.props} />;

    return nextComponent;
  }

  getDurationRequestDto = (): EventDurationRequestDto => {
    const { isMachineRelated, machineModels, nmrCluster, nmrFunctionalArea, ...rest } = this.props.requestWizardStore.state.item;
    const { nmrTrainingName, eventType, role, trainingLevel } = rest;
    const rows = (machineModels || []).map(({ machineModelId, oemId, ...x }) => ({
      clusterId: x?.machineRelatedClusterId,
      equipmentTypeId: x?.equipmentTypeId,
      machineModelId,
      machineUnitsId: x.machineUnitRequestMachines,
      oemId
    }));
    return {
      isMachineRelated,
      mrRequest: isMachineRelated
        ? { originalEventTypeId: eventType?.originalEventTypeId, professionId: role?.id, trainingLevelId: trainingLevel?.id, rows }
        : null,
      nmrRequest: !isMachineRelated
        ? { nmrClusterId: nmrCluster?.id, nmrFunctionalAreaId: nmrFunctionalArea?.id, nmrTrainingNameId: nmrTrainingName?.id }
        : null
    };
  };

  private isValidRequest = (dto: EventDurationRequestDto) => {
    if (dto == null) return false;
    if (dto.isMachineRelated) {
      if (
        dto.mrRequest == null ||
        isNullOrWhiteSpaces(dto.mrRequest.originalEventTypeId) ||
        isNullOrWhiteSpaces(dto.mrRequest.professionId) ||
        isNullOrWhiteSpaces(dto.mrRequest.trainingLevelId) ||
        (dto.mrRequest.rows || []).length === 0
      )
        return false;
      return dto.mrRequest.rows.all(r => !isNullOrWhiteSpaces(r.clusterId) && !isNullOrWhiteSpaces(r.equipmentTypeId));
    } else return !isNullOrWhiteSpaces(dto.nmrRequest?.nmrClusterId) && !isNullOrWhiteSpaces(dto.nmrRequest?.nmrFunctionalAreaId);
  };

  private recalculateEventDuration = () => {
    if (this.loading) return;
    const { requestWizardStore } = this.props;
    const dto = this.getDurationRequestDto();
    this.setState({ isBusy: true });
    const isValid = this.isValidRequest(dto);
    if (!isValid) return Promise.reject();
    return new Promise<void>(resolveCall => {
      this.eventsService
        .calculateEventDuration(dto)
        .then(({ days }) => {
          const w = { ...requestWizardStore.state.item };
          w.calculatedEventDuration = days;
          if (isNullOrEmpty(w.plannedDuration) || w.plannedDuration === '0 w/d' || w.plannedDuration === '0') {
            w.plannedDuration = `${days}`;
            w.userEventDuration = days;
          }
          requestWizardStore.change({ calculatedEventDuration: days, plannedDuration: `${days}`, userEventDuration: days });
          this.setState({ isBusy: false, durationCalculationWarning: false });
          resolveCall();
        })
        .catch(_ => {
          const w = { ...requestWizardStore.state.item };
          w.calculatedEventDuration = 0;
          if (isNullOrEmpty(w.plannedDuration)) {
            w.plannedDuration = '0';
            w.userEventDuration = 0;
          }
          console.log({ _ });
          this.setState({ isBusy: false, durationCalculationWarning: true });
          resolveCall();
        });
    });
  };

  private handleNMRChange = (value: NonMachineRelatedComponentViewModel) => {
    this.props.requestWizardStore.change({
      nmrCluster: value.cluster,
      nmrFunctionalArea: value.functionalArea,
      nmrFunctionalSubArea: value.functionalSubArea,
      nmrTrainingName: value.trainingName
    });
  };

  private getVisibleSteps = () => {
    const { steps, currentStepEnum } = this.state;
    const { item } = this.props.requestWizardStore.state;
    const visibles = [steps[0]]; //basic

    if (
      item &&
      item.eventType &&
      EventTypeCategory[((item.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Vacations &&
      EventTypeCategory[((item.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
    )
      visibles.push(steps[1]);

    if (item && item.isEventDetails) visibles.push(steps[2]);

    visibles.push(steps[3]);

    if (item && item.hasSupportDetails) visibles.push(steps[4]);

    if (
      item &&
      item.isParticipantYes &&
      EventTypeCategory[((item.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
    )
      visibles.push(steps[5]);

    if (
      item &&
      item.isInstructorYes &&
      EventTypeCategory[((item.eventType as any) as EventTypeDto).eventTypeCategory] !== EventTypeCategory.Extended
    )
      visibles.push(steps[6]);

    visibles.push(steps[7]); //comments

    visibles.forEach(v => {
      if (v.internalStep < currentStepEnum) {
        v.icon = check;
      } else {
        v.icon = undefined;
      }
    });

    return visibles;
  };

  public render() {
    const { t } = this.props;
    let steps = this.getVisibleSteps();

    return (
      <Modal className="requests-events-wizard___modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.props.requestWizardStore.state.isBusy || this.state.isBusy} inverted>
          <Loader indeterminate inverted></Loader>
        </Dimmer>

        <Modal.Header className="stepper-container">
          <h2>{t('REQUEST CREATION')}</h2>
          <Stepper
            steps={steps}
            activeStep={steps.findIndex(x => x.internalStep === this.state.currentStepEnum)}
            activeColor="#00B2A9"
            completeColor="#00B2A9"
            defaultColor="#B0B0B0"
            activeTitleColor="white"
            completeTitleColor="white"
            defaultTitleColor="white"
            circleFontColor="white"
            defaultBarColor="#B0B0B0"
            completeBarColor="#00B2A9"
            lineMarginOffset={10}
            size={24}
            circleFontSize={14}
            titleFontSize={14}
            circleTop={15}
          />
        </Modal.Header>
        <Modal.Content image>
          <Container>
            {this.requestWizardStore.state.result && !this.requestWizardStore.state.result.isSuccess && (
              <Message
                icon="exclamation circle"
                className="error-message__style"
                error
                header={t('An error ocurred')}
                list={this.requestWizardStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.requestWizardStore.state.item && this.getStepContent()}
          </Container>
        </Modal.Content>
        <Modal.Actions className="wizard__action-buttons-wrapper">
          <Button
            className={this.state.currentStepEnum === Step.BASIC ? 'hidden' : 'form__actions__button wizard__go-back-btn'}
            icon="arrow left"
            content={t('Back')}
            labelPosition="left"
            onClick={side => this.handleNext(Action.GO_BACK)}
          />

          <div className="form__align-right-btns">
            <Button className="form__actions__button basic" onClick={this.onCancelNewItem} basic>
              {t('Cancel')}
            </Button>

            {this.state.currentStepEnum === Step.COMMENTS && (
              <Button
                icon="save"
                labelPosition="right"
                className="form__actions__button wizard__save-draft-btn"
                onClick={this.onCreateNewItemDraft}
                content={t('Save Draft')}
              />
            )}

            <Button className="form__actions__button" onClick={this.nextOrPending} positive>
              {this.state.currentStepEnum === Step.COMMENTS ? t('Send') : t('Next')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewRequestView);
