import { EventsStore, ChangeEventsStore, EventsSchedulerStore, ChangeUnblockButtonsStore, InstructorTabEventsStore } from './events-store';
import { NewEventsStore, NewMergeEventsStore } from './creation-wizard-store';
import { EventChatStore } from './events-chat-store';
import { EventRequestChangesStore, NewEventRequestChangeStore } from './event-request-changes-store';
import { UnreadNotificationCountStore, UserNotificationsStore } from './event-notifications-store';
import { UserTodoStore, UndoneToDoCountStore } from './event-todo-store';
import {
  EventCheckListRoleUserStore,
  ChangeEventCheckListRoleUserStore,
  NewEventCheckListRoleUserStore
} from './event-checkList-roleuser-store';
import { EventFormStore } from './event-form-store';
import { FormFeedbackListStore, NewFeedbackFormUserStore, EventFeedbackTabStore } from './feedback-forms-store';
import { FormTheoreticalListStore, NewTheoreticalFormUserStore, EventTheoreticalTabStore } from './theoretical-form-store';
import { FormPracticalListStore, NewPracticalFormUserStore, EventPracticalTabStore } from './practical-form-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new EventsStore());
  storeBuilder.addRepository(new ChangeEventsStore());
  storeBuilder.addRepository(new ChangeUnblockButtonsStore());
  storeBuilder.addRepository(new NewEventsStore());
  storeBuilder.addRepository(new NewMergeEventsStore());
  storeBuilder.addRepository(new EventChatStore());
  storeBuilder.addRepository(new EventRequestChangesStore());
  storeBuilder.addRepository(new NewEventRequestChangeStore());
  storeBuilder.addRepository(new UnreadNotificationCountStore());
  storeBuilder.addRepository(new UserNotificationsStore());
  storeBuilder.addRepository(new UserTodoStore());
  storeBuilder.addRepository(new UndoneToDoCountStore());
  storeBuilder.addRepository(new NewEventCheckListRoleUserStore());
  storeBuilder.addRepository(new ChangeEventCheckListRoleUserStore());
  storeBuilder.addRepository(new EventCheckListRoleUserStore());
  storeBuilder.addRepository(new EventsSchedulerStore());
  storeBuilder.addRepository(new EventFormStore());
  storeBuilder.addRepository(new FormFeedbackListStore());
  storeBuilder.addRepository(new NewFeedbackFormUserStore());
  storeBuilder.addRepository(new EventFeedbackTabStore());
  storeBuilder.addRepository(new FormTheoreticalListStore());
  storeBuilder.addRepository(new NewTheoreticalFormUserStore());
  storeBuilder.addRepository(new EventTheoreticalTabStore());
  storeBuilder.addRepository(new FormPracticalListStore());
  storeBuilder.addRepository(new NewPracticalFormUserStore());
  storeBuilder.addRepository(new EventPracticalTabStore());
  storeBuilder.addRepository(new InstructorTabEventsStore());
}
