import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import i18n from 'i18n';
import OverallRecommendationsListPage from './overall-recommendations/overall-recommendations-list';

export interface OverallRecommendationsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface OverallRecommendationsConfigurationHomeState {
  activeTab: 'overallRecommendations';
}

class OverallRecommendationsConfigurationHomePage extends React.Component<
  OverallRecommendationsConfigurationHomeProps,
  OverallRecommendationsConfigurationHomeState
> {
  constructor(props: OverallRecommendationsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'overallRecommendations'
    };
  }

  private panes = [
    {
      menuItem: i18n.t('Overall Recommendations'),
      render: () => (
        <Tab.Pane attached={false}>
          <OverallRecommendationsListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Overall Recommendations')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(OverallRecommendationsConfigurationHomePage);
