import React, { FC, Fragment, useState } from 'react';
import { PracticalFormView, PracticalQuestionView } from 'stores/assessments/forms/practical-form-store';
import { Accordion, AccordionTitleProps } from 'semantic-ui-react';
import PracticalQuestion from './practical-form-question';

interface PracticalFormMainProps extends PracticalFormView {
  onChange: (property: keyof PracticalFormView, value: any) => void;
}

const PracticalFormMain: FC<PracticalFormMainProps> = props => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { questionAnswers, currentLanguage, readonly, onChange } = props;
  const handleToggleSkill = (_: any, { index }: AccordionTitleProps) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(+newIndex);
  };

  const changeHandler = (values: any, i: number) => {
    let obj = values as PracticalQuestionView;
    const newQuestions = (questionAnswers || []).map((item, idx) => {
      let merged = item;
      if (idx === i) {
        merged = { ...item, ...obj };
      }
      return merged;
    });
    onChange('questionAnswers', newQuestions);
  };

  const getCurrentQuestion = (idx: number): string => {
    let currentLangQ = questionAnswers[idx]?.questionTranslations?.find(({ language }) => language?.id === currentLanguage)?.text;
    if (currentLangQ == null) currentLangQ = questionAnswers[idx]?.question?.text;
    return currentLangQ;
  };

  return (
    <Accordion className="accordion-wrapper">
      {(questionAnswers || []).map((item, i) => {
        return (
          <Fragment key={item?.questionId + i}>
            <Accordion.Title active={i === activeIndex} index={i} onClick={handleToggleSkill} className="form__accordion-title">
              <p>{getCurrentQuestion(i)}</p>
            </Accordion.Title>
            <Accordion.Content active={i === activeIndex} className="flex-column">
              <PracticalQuestion readonly={readonly} {...item} onChange={obj => changeHandler(obj, i)} />
            </Accordion.Content>
          </Fragment>
        );
      })}
    </Accordion>
  );
};
export default PracticalFormMain;
