import React, { FC, memo, useEffect, useLayoutEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import RichTextEditor from 'react-rte';
import { Button, Container, Modal } from 'semantic-ui-react';
import { UsefulLinkDto } from 'stores/configuration/useful-links/useful-links-store';

interface ChangeUsefulLinkViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  currentDto: UsefulLinkDto;
}

const ChangeUsefulLinkView: FC<ChangeUsefulLinkViewProps> = ({ currentDto, t, onClose }) => {
  const [editorValue, setEditorValue] = useState(() => RichTextEditor.createEmptyValue());

  useLayoutEffect(() => {
    const container = document.getElementById('view-custom-rte');
    const anchorTags = container.getElementsByTagName('a');
    for (let item of anchorTags) {
      item.setAttribute('target', '_blank');
    }
  });

  useEffect(() => {
    const editorValue = RichTextEditor.createValueFromString(currentDto.link, 'html');
    setEditorValue(editorValue);
  }, [currentDto.link]);

  const onCancelViewItem = () => onClose(false);

  return (
    <Modal open closeOnEscape={true} onClose={onCancelViewItem} closeOnDimmerClick={false}>
      <Modal.Header>{t('View Useful Link')}</Modal.Header>
      <Modal.Content image>
        <Container id="view-custom-rte">
          <RichTextEditor className="custom-rte rte-30vh" readOnly={true} value={editorValue} />
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancelViewItem}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(memo(ChangeUsefulLinkView));
