import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { Category, NMClusterDto, NMClustersStore } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { Categories } from 'stores/requests/requests-store';

interface NmrClusterEditorProps extends WithTranslation {
  value: NMClusterDto[];
  onChange?: (value: NMClusterDto[]) => void;
  nmrClusters?: NMClustersStore;
  placeholder?: string;
  clearable?: boolean;
  disabled?: boolean;
  className?: string;
  nmrClustersReceived?: string[];
  category?: Categories | Category;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface NmrClusterEditorState {
  options: MultipleSelectionDropdownOptions<NMClusterDto>[];
}

@connect(['nmrClusters', NMClustersStore])
class MultipleNmrClusterEditor extends React.Component<NmrClusterEditorProps, NmrClusterEditorState> {
  state: NmrClusterEditorState = { options: [] };

  componentDidMount() {}

  getOptions = () => {
    const { nmrClusters, nmrClustersReceived, category } = this.props;
    let filter = [];
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<NMClusterDto>('name'), useProfile: false }];
    if (category)
      filter.push(
        `${nameof<NMClusterDto>('category')} eq '${
          category === ('Functional' as any) || category === ('Technical' as any) ? category : Categories[category]
        }'`
      );
    if ((nmrClustersReceived || []).length > 0) filter.push({ id: { in: { type: 'guid', value: nmrClustersReceived } } });

    nmrClusters
      .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter })
      .then(nmrClusters => this.setState({ options: this.mapToOptions(nmrClusters) }));
  };

  componentDidUpdate(prevProps: NmrClusterEditorProps) {
    const { category, nmrClustersReceived } = this.props;
    if (category !== prevProps.category || (nmrClustersReceived || []).length !== (prevProps.nmrClustersReceived || []).length)
      this.getOptions();
  }

  mapToOptions = (nmrClusters: QueryResult<NMClusterDto>): MultipleSelectionDropdownOptions<NMClusterDto>[] =>
    (nmrClusters?.items || []).map(region => ({ id: region.id, value: region, text: region.name }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.getOptions();
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleNmrClusterEditor);
