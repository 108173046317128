import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import GeneralDataStep, { GeneralDataViewModel } from '../../../iws/forms/wizard/steps/general-data-step';
import { Button, Message, Modal } from 'semantic-ui-react';
import EmployeesAndAssessorsStep, { EmployeesAssessorsViewModel } from './steps/employees-and-assessors-step';
import { nameof, clone, ArrayToObjectStringDict, getProperties, getKeyValuePairs } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { RouteComponentProps } from 'react-router';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LineSeparator from 'widgets/bussiness/line-separator';
import { iwsWizardMode } from './create-iws-form-wizard';
import { IwsFormListDto, IwsFormItemDto } from 'stores/assessments/forms/iws-forms-store';
import { DateTimeService } from 'services/datetime-service';
import IwsTemplateStep, { IwsTemplateStepViewModel, IwsTemplateViewModel } from './steps/iws-template-step/iws-template-step';
import { IwsTemplateDto } from 'stores/assessments/templates/iws-templates-store';

export interface IwsFormWizardViewModel {
  generalStepViewModel: GeneralDataViewModel;
  iwsTemplateStepViewModel: IwsTemplateStepViewModel;
  employeesAssessorViewModel: EmployeesAssessorsViewModel;
  orderedTemplates: IwsTemplateDto[];
}

export interface IwsFormWizardProps extends WithTranslation, RouteComponentProps {
  messages: any[];
  onSubmit: (viewModel: IwsFormWizardViewModel) => void;
  isValidViewModel: (viewModel: IwsFormWizardViewModel) => boolean;
  iwsWizardMode: iwsWizardMode;
  dataFormEditMode: IwsFormListDto;
  itemToEdit: IwsFormItemDto;
}
export interface ProfTemplateDict {
  profileName: string;
  templatesDict: { [tempId: string]: string };
}
export interface IwsFormWizardState {
  loading: boolean;
  value: IwsFormWizardViewModel;
  profilesTemplDict: { [profileId: string]: ProfTemplateDict };
  step: number;
  showCancelConfirm: boolean;
  showNewEmployeeProfilesModal: boolean;
  duplicatedSkills: boolean;
  isGlobalPoc: boolean;
}

class IwsFormWizard extends Component<IwsFormWizardProps, IwsFormWizardState> {
  constructor(props: IwsFormWizardProps) {
    super(props);
    this.state = {
      loading: false,
      value: this.initIwsFormsWizardViewModel(),
      profilesTemplDict: {},
      step: 1,
      showCancelConfirm: false,
      showNewEmployeeProfilesModal: false,
      duplicatedSkills: false,
      isGlobalPoc: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    const value = this.initIwsFormsWizardViewModel();
    const { itemToEdit } = this.props;

    if (this.props.iwsWizardMode === 'Edit') {
      this.generateDictionaries(itemToEdit);
    }
    this.setState({
      value,
      isGlobalPoc
    });
  }

  private initIwsFormsWizardViewModel = (): IwsFormWizardViewModel => {
    const item = this.props.dataFormEditMode;

    let iwsFormToEdit = this.props.itemToEdit;

    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const pocLocations = isPoc ? (userInfo.locationsByRoles['PoC'] as string[]) || [] : [];
    let location = null;
    if (isPoc && pocLocations.length === 1) {
      location = { id: pocLocations[0], title: null };
    }

    const isOnEditMode = this.props.iwsWizardMode === 'Edit';
    if (isOnEditMode) {
      const profDict = iwsFormToEdit.iwsAssessment.iwsTemplates.flatMap(x => ({ id: x.profileItemId, name: x.profileName }));
      const profiles = profDict.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {});
      return {
        generalStepViewModel: {
          deadline1: iwsFormToEdit.deadline1,
          deadline2: iwsFormToEdit.deadline2,
          deadline3: iwsFormToEdit.deadline3,
          location: { id: iwsFormToEdit.user.location.id, title: iwsFormToEdit.user.location.location },
          profileIds: item.profiles.map(x => x.id),
          title: iwsFormToEdit.iwsAssessment.title,
          profilesDict: profiles
        },
        iwsTemplateStepViewModel: this.generateTemplatesSelection(iwsFormToEdit),
        employeesAssessorViewModel: {
          assessors: iwsFormToEdit.qualifiers.map(x => x.userId),
          employees: [iwsFormToEdit.userId],
          employeesAssessors: {
            [iwsFormToEdit.userId]: {
              skillsAssessor: this.generateTemplatesAssessors(iwsFormToEdit),
              userProfileIds: [],
              newProfiles: [],
              displayName: ''
            }
          }
        },
        orderedTemplates: []
      };
    } else {
      return {
        generalStepViewModel: {
          deadline1: '',
          deadline2: '',
          deadline3: '',
          location,
          profileIds: [],
          title: '',
          profilesDict: {}
        },
        iwsTemplateStepViewModel: {},
        employeesAssessorViewModel: {
          assessors: [],
          employees: [],
          employeesAssessors: {}
        },
        orderedTemplates: []
      };
    }
  };

  public generateDictionaries(iwsFormToEdit: IwsFormItemDto): void {
    const profiles = iwsFormToEdit.iwsAssessment.iwsTemplates.flatMap(x => ({ id: x.profileItemId, name: x.profileName }));
    let profTempls = {};
    profiles.forEach(prof => {
      let profTempl = iwsFormToEdit.iwsAssessment.iwsTemplates
        .filter(x => x.profileItemId === prof.id)
        .map(x => ({ id: x.id, name: x.title }));
      const temps = ArrayToObjectStringDict(profTempl);
      profTempls[prof.id] = { profileName: prof.name, templatesDict: temps };
    });
    this.setState({ profilesTemplDict: profTempls });
  }

  public generateTemplatesAssessors(iwsFormToEdit: IwsFormItemDto): any {
    const skillsAssessor = {};
    iwsFormToEdit.qualifiers.forEach(qualifier => {
      qualifier.templates.forEach(template => {
        skillsAssessor[template.templateId] = qualifier.userId;
      });
    });
    return skillsAssessor;
  }

  public generateTemplatesSelection(iwsFormToEdit: IwsFormItemDto): any {
    const allProfiles = iwsFormToEdit.iwsAssessment.iwsTemplates.flatMap(x => x.profileItemIds);
    const profiles = allProfiles.filter((n, i) => allProfiles.indexOf(n) === i);
    let viewModel = {};
    profiles.forEach(prof => {
      let profTempl = iwsFormToEdit.iwsAssessment.iwsTemplates.filter(x => x.profileItemIds.includes(prof));
      let model = {
        name: '',
        templates: profTempl,
        skillCount: 0
      } as IwsTemplateViewModel;
      viewModel[prof] = model;
    });

    return viewModel;
  }

  handleOnSubmit = () => {
    this.state.showNewEmployeeProfilesModal && this.setState({ showNewEmployeeProfilesModal: false });
    this.props.onSubmit && this.props.onSubmit(this.state.value);
  };

  private checkStep1 = () => {
    const { value } = this.state;
    if (value.generalStepViewModel == null) return false;
    const { location, profileIds, deadline1, deadline2, deadline3 } = value.generalStepViewModel;
    return (
      profileIds &&
      profileIds.length > 0 &&
      !isNullOrWhiteSpaces(deadline1) &&
      DateTimeService.isValid(deadline1) &&
      !isNullOrWhiteSpaces(deadline2) &&
      DateTimeService.isValid(deadline2) &&
      !isNullOrWhiteSpaces(deadline3) &&
      DateTimeService.isValid(deadline3) &&
      location != null &&
      !isNullOrWhiteSpaces(location.id)
    );
  };

  private checkStep2 = () => {
    // const { value, duplicatedSkills } = this.state;
    const { value } = this.state;
    const isOnEditMode = this.props.iwsWizardMode === 'Edit';
    if (value.iwsTemplateStepViewModel == null) return false;
    if (!isOnEditMode) {
      let profileList = getKeyValuePairs<IwsTemplateViewModel>(value.iwsTemplateStepViewModel).map(x => x.value);

      return value.iwsTemplateStepViewModel != null && profileList.every(x => x.templates != null && x.templates.length > 0);
      // return (
      //   !duplicatedSkills && value.iwsTemplateStepViewModel != null && profileList.every(x => x.templates != null && x.templates.length > 0)
      // );
    } else return true;
  };

  private updateStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      this.setState({ step: 1 });
      return;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      this.setState({ step: 2 });
      return;
    }
    this.setState({ step: 3 });
  };

  private handleChangeProperty(property: keyof IwsFormWizardViewModel, propertyValue: any) {
    let value = clone<IwsFormWizardViewModel>(this.state.value);

    if (
      this.props.iwsWizardMode === 'New' &&
      property === nameof<IwsFormWizardViewModel>('generalStepViewModel') &&
      propertyValue.profileIds &&
      this.state.value.generalStepViewModel.profileIds !== propertyValue.profileIds
    ) {
      let iwsTemplateModel = {};
      (propertyValue.profileIds as Array<string>).map(
        x =>
          (iwsTemplateModel[x] = {
            name: propertyValue.profilesDict[x] || '???',
            templates: value.iwsTemplateStepViewModel[x]?.templates || [],
            skillCount: 0
          })
      );
      value.iwsTemplateStepViewModel = iwsTemplateModel;
    }
    value[property] = propertyValue;

    let profDict = this.updateProfilesTemplatesDict(value);
    this.setState({ value, profilesTemplDict: profDict }, this.updateStep);
  }

  //private handleSecondStepChanged = (iwsTemplateStepViewModel: IwsTemplateStepViewModel, duplicatedSkills?: boolean): void => {
  private handleSecondStepChanged = (iwsTemplateStepViewModel: IwsTemplateStepViewModel): void => {
    const value = clone<IwsFormWizardViewModel>(this.state.value);
    value.iwsTemplateStepViewModel = iwsTemplateStepViewModel;

    let profDict = this.updateProfilesTemplatesDict(value);
    this.setState({ value, profilesTemplDict: profDict }, this.updateStep);

    const templates = (Object.values(value.iwsTemplateStepViewModel)
      .selectMany(x => x.templates)
      .toArray() as IwsTemplateDto[]).map(x => x.id);

    const orderedTemplates = value.orderedTemplates;
    value.orderedTemplates = (orderedTemplates || []).filter(x => templates.includes(x.id));

    this.setState({ value }, this.updateStep);
    //this.setState({ value, profilesTemplDict: profDict, duplicatedSkills }, this.updateStep);
  };

  private handleOrderedTemplateChanged = (templates: IwsTemplateDto[]): void => {
    const value = clone<IwsFormWizardViewModel>(this.state.value);
    value.orderedTemplates = templates;
    this.setState({ value }, this.updateStep);
  };

  private updateProfilesTemplatesDict = (value: IwsFormWizardViewModel): any => {
    const profIds = value.generalStepViewModel.profileIds || [];
    let profDict = {};
    for (const [key, vale] of Object.entries(value.iwsTemplateStepViewModel || {})) {
      if (profIds.includes(key)) {
        const profTempl = vale?.templates.map(x => ({ id: x.id, name: x.title })) || [];
        const name = value.iwsTemplateStepViewModel[key]?.name;
        const temps = ArrayToObjectStringDict(profTempl);
        profDict[key] = { profileName: name, templatesDict: temps };
      }
    }
    return profDict;
  };
  private onCancel = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/iws?tab=3' });
  };

  private cantSubmit = () => {
    return this.props.isValidViewModel && !this.props.isValidViewModel(this.state.value);
  };

  private onTryCancel = () => {
    this.setState({ showCancelConfirm: true });
  };
  private onCancelCancel = () => {
    this.setState({ showCancelConfirm: false });
  };

  private onTrySubmit = () => {
    this.setState({ showNewEmployeeProfilesModal: true });
  };
  private onCancelNewEmployeeProfilesModal = () => {
    this.setState({ showNewEmployeeProfilesModal: false });
  };

  private showEmployeeNewProfiles = (employeeId: string, employeeName: string, profileIds: string[]) => {
    const { profilesTemplDict } = this.state;
    let profiles = profileIds.map(x => profilesTemplDict[x]?.profileName).join(', ');

    return { employee: employeeName, profiles };
  };

  render() {
    const { t, iwsWizardMode, itemToEdit, messages } = this.props;
    const { step, showCancelConfirm, value, profilesTemplDict, showNewEmployeeProfilesModal, isGlobalPoc } = this.state;
    const { generalStepViewModel, iwsTemplateStepViewModel, employeesAssessorViewModel } = value;
    const isOnEditMode = iwsWizardMode === 'Edit';
    let anyEmployeeWithNewProfiles = false;
    let employeeWithNewProfiles: { id: string; name: string; value: any }[] = [];
    if (!isOnEditMode) {
      const employeeNewProfilesProps = getProperties(employeesAssessorViewModel.employeesAssessors);
      anyEmployeeWithNewProfiles = employeeNewProfilesProps.some(x => (x.value.newProfiles || []).length > 0);
      employeeWithNewProfiles = anyEmployeeWithNewProfiles
        ? employeeNewProfilesProps.map(x => ({ id: x.key, name: x.value.displayName, value: x.value.newProfiles || [] }))
        : [];

      if (showNewEmployeeProfilesModal && messages?.length > 0) {
        this.onCancelNewEmployeeProfilesModal();
      }
    }
    return (
      <>
        <h3 className="template-wizard-title">{t(`Step Up Card`)}</h3>
        <LineSeparator />
        {messages?.length > 0 && (
          <Message className="error-message__style" icon="exclamation circle" error list={this.props.messages.map(m => m.body)} />
        )}

        <div className="wizard__steps-container iws-form-wizzard">
          <GeneralDataStep
            isStepActive={step >= 1}
            value={clone(generalStepViewModel)}
            onChange={(value: any) => this.handleChangeProperty('generalStepViewModel', value)}
            isOnEditMode={isOnEditMode}
            isGlobalPoc={isGlobalPoc}
          />
          <IwsTemplateStep
            isStepActive={step >= 2}
            value={clone(iwsTemplateStepViewModel)}
            profileIds={generalStepViewModel.profileIds}
            //onChange={(value: any, duplicatedSkills?: boolean) => this.handleSecondStepChanged(value, duplicatedSkills)}
            onChange={(value: any) => this.handleSecondStepChanged(value)}
            onChangeOrderedTemplate={(value: any) => this.handleOrderedTemplateChanged(value)}
            isOnEditMode={isOnEditMode}
          />

          <EmployeesAndAssessorsStep
            value={employeesAssessorViewModel}
            itemToEdit={itemToEdit && itemToEdit}
            isOnEditMode={isOnEditMode}
            dataFormEditMode={this.props.dataFormEditMode}
            isStepActive={step >= 3}
            locationId={generalStepViewModel.location ? generalStepViewModel.location.id : null}
            profileIds={generalStepViewModel.profileIds}
            profilesTemplates={profilesTemplDict}
            onChange={(value: any) => this.handleChangeProperty('employeesAssessorViewModel', value)}
            isGlobalPoc={isGlobalPoc}
          />
        </div>
        <div className="iws-templates-wizard__btns">
          <Button secondary inverted onClick={this.onTryCancel}>
            {t('Cancel')}
          </Button>
          <Button
            primary
            positive
            onClick={anyEmployeeWithNewProfiles ? this.onTrySubmit : this.handleOnSubmit}
            disabled={this.cantSubmit()}
          >
            {!isOnEditMode ? t('Generate IWS SuC') : t('Save Changes')}
          </Button>
        </div>
        {showNewEmployeeProfilesModal && anyEmployeeWithNewProfiles && (
          <Modal
            open={showNewEmployeeProfilesModal}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.onCancelNewEmployeeProfilesModal}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t('The following employees will be assigned to profiles:')}</p>
              {anyEmployeeWithNewProfiles && employeeWithNewProfiles.length > 0 && (
                <div>
                  <ul>
                    {employeeWithNewProfiles.map(empl => {
                      let info = this.showEmployeeNewProfiles(empl.id, empl.name, empl.value);
                      return (
                        info.profiles.length > 0 && (
                          <li key={empl.id}>
                            {' '}
                            <span className="uppercase">
                              {' '}
                              <span className=""> {info.employee}</span> {' -> '} <span> {info.profiles}</span>{' '}
                            </span>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              )}
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.onCancelNewEmployeeProfilesModal} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.handleOnSubmit}
              />
            </div>
          </Modal>
        )}
        {showCancelConfirm && (
          <Modal open={showCancelConfirm} size="mini" className="iws-confirmation-modal" closeOnEscape={true} onClose={this.onCancelCancel}>
            <Modal.Content className="iws-confirmation-modal__content">
              <p>
                {t('Are you sure you want to CANCEL?')}
                <br />
                {t('You will lose all the changes made in this IWS SuC.')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button size="tiny" className="iws-confirmation-modal__btn cancel" content={t('Back')} onClick={this.onCancelCancel} />
              <Button size="tiny" className="iws-confirmation-modal__btn" content={t('Cancel Changes')} onClick={this.onCancel} />
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(IwsFormWizard);
