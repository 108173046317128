import { TnaQuestionBanksStore, ChangeTnaQuestionBankStore, NewTnaQuestionBankStore } from './tna-questionBank-store';
import { SsaQuestionBanksStore, ChangeSsaQuestionBankStore, NewSsaQuestionBankStore } from './ssa-questionBank-store';
import { FeedbackQuestionBanksStore, ChangeFeedbackQuestionBankStore, NewFeedbackQuestionBankStore } from './feedback-questionBank-store';
import { UploadTnaQuestionBankStore, DownloadTnaQuestionBankStore } from './tna-questionBank-upload-store';
import {
  MRTheoreticalQuestionBanksStore,
  NMRTheoreticalQuestionBanksStore,
  MRTheoreticalTestQBWizardStore,
  NMRTheoreticalQBWizardStore
} from './theoretical-test-store';
import { UploadMRTheoreticalQBStore, DownloadMRTheoreticalQBStore } from './mr-theoretical-test-load-store';
import { UploadNMRTheoreticalQBStore, DownloadNMRTheoreticalQBStore } from './nmr-theoretical-test-load-store';
import { UploadSsaQuestionBankStore, DownloadSsaQuestionBankStore } from './ssa-questionBank-upload-store';
import { UploadFeedbackQuestionBankStore, DownloadFeedbackQuestionBankStore } from './feedback-questionBank-upload-store';
import {
  MRPracticalQuestionBanksStore,
  NMRPracticalQuestionBanksStore,
  MRPracticalTestQBWizardStore,
  NMRPracticalTestQBWizardStore
} from './practical-test-store';
import { DownloadNmrPracticalQBStore, UploadNmrPracticalQBStore } from './nmr-practical-test-load-store';
import { DownloadMrPracticalQBStore, UploadMrPracticalQBStore } from './mr-practical-test-load-store';
import { IwsQuestionBanksStore, ChangeIwsQuestionBankStore, NewIwsQuestionBankStore } from './iws-questionBank-store';
import { UploadIwsQuestionBankStore, DownloadIwsQuestionBankStore } from './iws-questionBank-upload-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new TnaQuestionBanksStore());
  storeBuilder.addRepository(new ChangeTnaQuestionBankStore());
  storeBuilder.addRepository(new NewTnaQuestionBankStore());

  storeBuilder.addRepository(new SsaQuestionBanksStore());
  storeBuilder.addRepository(new ChangeSsaQuestionBankStore());
  storeBuilder.addRepository(new NewSsaQuestionBankStore());
  storeBuilder.addRepository(new UploadTnaQuestionBankStore());
  storeBuilder.addRepository(new DownloadTnaQuestionBankStore());
  storeBuilder.addRepository(new UploadSsaQuestionBankStore());
  storeBuilder.addRepository(new DownloadSsaQuestionBankStore());

  storeBuilder.addRepository(new FeedbackQuestionBanksStore());
  storeBuilder.addRepository(new ChangeFeedbackQuestionBankStore());
  storeBuilder.addRepository(new NewFeedbackQuestionBankStore());
  storeBuilder.addRepository(new UploadFeedbackQuestionBankStore());
  storeBuilder.addRepository(new DownloadFeedbackQuestionBankStore());

  storeBuilder.addRepository(new MRTheoreticalQuestionBanksStore());
  storeBuilder.addRepository(new MRTheoreticalTestQBWizardStore());
  storeBuilder.addRepository(new UploadMRTheoreticalQBStore());
  storeBuilder.addRepository(new DownloadMRTheoreticalQBStore());

  storeBuilder.addRepository(new NMRTheoreticalQuestionBanksStore());
  storeBuilder.addRepository(new NMRTheoreticalQBWizardStore());
  storeBuilder.addRepository(new UploadNMRTheoreticalQBStore());
  storeBuilder.addRepository(new DownloadNMRTheoreticalQBStore());
  storeBuilder.addRepository(new UploadFeedbackQuestionBankStore());
  storeBuilder.addRepository(new DownloadFeedbackQuestionBankStore());

  storeBuilder.addRepository(new MRPracticalQuestionBanksStore());
  storeBuilder.addRepository(new NMRPracticalQuestionBanksStore());

  storeBuilder.addRepository(new MRPracticalTestQBWizardStore());
  storeBuilder.addRepository(new NMRPracticalTestQBWizardStore());
  storeBuilder.addRepository(new UploadMrPracticalQBStore());
  storeBuilder.addRepository(new UploadNmrPracticalQBStore());
  storeBuilder.addRepository(new DownloadMrPracticalQBStore());
  storeBuilder.addRepository(new DownloadNmrPracticalQBStore());

  storeBuilder.addRepository(new IwsQuestionBanksStore());
  storeBuilder.addRepository(new ChangeIwsQuestionBankStore());
  storeBuilder.addRepository(new NewIwsQuestionBankStore());
  storeBuilder.addRepository(new UploadIwsQuestionBankStore());
  storeBuilder.addRepository(new DownloadIwsQuestionBankStore());
}
