import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  NewIwsFormStore,
  CreateEmployeeIwsFormDto,
  CreateQualifierDto,
  CreateIwsFormDto,
  CreateIwsFormValidator,
  ChangeIwsFormWizardValidator,
  ChangeIwsFormWizardStore,
  ChangeIwsFormDto,
  IwsFormListDto,
  ChangeEmployeeIwsFormDto,
  IwsFormListStore,
  IwsFormItemDto,
  ChangeQualifierDto,
  CreateTemplateWithLocalQuestions,
  CreteSkillWithLocalQuestions
} from 'stores/assessments/forms/iws-forms-store';
import { connect } from 'redux-scaffolding-ts';
import IwsFormWizard, { IwsFormWizardViewModel } from './iws-form-wizard';
import { RouteComponentProps } from 'react-router';
import { EmployeesAssessorsViewModel, EmployeeAssessors } from './steps/employees-and-assessors-step';
import { Dimmer, Loader } from 'semantic-ui-react';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { getKeyValuePairs, clone } from 'utils/object';
import { UserProfilesStore } from 'stores/profile/user-profile-store';
import { IGroup } from 'utils/linq';
import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import { Message } from 'stores/types';
import { IwsTemplateStepViewModel, IwsTemplateViewModel } from './steps/iws-template-step/iws-template-step';
import { IwsTemplateDto } from 'stores/assessments/templates/iws-templates-store';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';

export interface CreateIwsFormWizardProps extends WithTranslation, RouteComponentProps {
  newIwsFormStore?: NewIwsFormStore;
  changeIwsFormWizardStore?: ChangeIwsFormWizardStore;
  iwsFormListStore: IwsFormListStore;
  userProfilesStore: UserProfilesStore;
}
export interface CreateIwsFormWizardState {
  loading: boolean;
  mode: iwsWizardMode;
  itemToEdit: IwsFormItemDto;
}

export type iwsWizardMode = 'New' | 'Edit';

@connect(
  ['newIwsFormStore', NewIwsFormStore],
  ['changeIwsFormWizardStore', ChangeIwsFormWizardStore],
  ['iwsFormListStore', IwsFormListStore],
  ['userProfilesStore', UserProfilesStore]
)
class CreateIwsFormWizard extends Component<CreateIwsFormWizardProps, CreateIwsFormWizardState> {
  constructor(props: CreateIwsFormWizardProps) {
    super(props);
    const editMode = this.isOnEditMode();
    this.state = {
      loading: false,
      mode: editMode ? 'Edit' : 'New',
      itemToEdit: null
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    if (this.state.mode === 'Edit') this.load();
  }

  private load = async () => {
    const { history, iwsFormListStore } = this.props;
    const dataFormEditMode = this.props.history.location.state as IwsFormListDto;
    const formIdClickedFromIwsFormList = dataFormEditMode?.id;
    try {
      this.setState({ loading: true });
      const iwsFormData = await iwsFormListStore.getFormById(formIdClickedFromIwsFormList);
      this.setState({ loading: false, itemToEdit: iwsFormData });
    } catch (error) {
      history.replace('/not-found');
    }
  };

  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state !== undefined || match.params['id'] !== undefined) {
      return true;
    } else return false;
  };

  private mapToDto = (x: IwsFormWizardViewModel, inEditMode: boolean): CreateIwsFormDto => {
    const iwsFormWizardViewModel = clone<IwsFormWizardViewModel>(x);
    return {
      title: iwsFormWizardViewModel.generalStepViewModel.title,
      deadline1: iwsFormWizardViewModel.generalStepViewModel.deadline1,
      deadline2: iwsFormWizardViewModel.generalStepViewModel.deadline2,
      deadline3: iwsFormWizardViewModel.generalStepViewModel.deadline3,
      templateIds: iwsFormWizardViewModel.orderedTemplates.map(x => x.id),
      employees: this.mapToCreateEmployeeIwsFormDto(
        iwsFormWizardViewModel.employeesAssessorViewModel,
        iwsFormWizardViewModel.iwsTemplateStepViewModel
      ),
      skillCount: 0,
      templatesWithLocalQuestions: this.getTemplateWithLocalQuestions(iwsFormWizardViewModel.iwsTemplateStepViewModel),
      profilesIds: iwsFormWizardViewModel.generalStepViewModel.profileIds
    };
  };

  private isValidViewModel = (iwsFormWizardViewModel: IwsFormWizardViewModel) => {
    const { iwsTemplateStepViewModel, employeesAssessorViewModel: { employeesAssessors } = {} } = iwsFormWizardViewModel;

    const isEditMode = this.state.mode === 'Edit';
    let validation: ValidationResult;
    if (isEditMode) {
      validation = new ChangeIwsFormWizardValidator().extendValidate(this.mapToChangeDtoEditMode(iwsFormWizardViewModel, false));
    } else {
      validation = new CreateIwsFormValidator().extendValidate(this.mapToDto(iwsFormWizardViewModel, isEditMode));
    }
    const failures: ValidationFailure[] = [];
    const requiredAssessorsLength = Object.keys(iwsTemplateStepViewModel || {}).length;

    if (requiredAssessorsLength > 0)
      Object.keys(employeesAssessors || {}).forEach((empId, i) => {
        const currentEmployee = employeesAssessors[empId];
        const assignedAssessorToEmployee = Object.keys(currentEmployee?.skillsAssessor || {}).every(
          skill => currentEmployee.skillsAssessor[skill] != null
        );
        if (!assignedAssessorToEmployee)
          failures.push({
            attemptedValue: null,
            code: null,
            message: this.props.t(`Employee at position ${i + 1}: Qualifier info is required `),
            propertyName: 'Qualifier',
            severity: null,
            target: null
          });
      });

    failures.length > 0 && validation.addFailures(failures);

    return validation.isValid();
  };

  private handleOnSubmit = (iwsFormWizardViewModel: IwsFormWizardViewModel) => {
    const { history } = this.props;
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);

    this.setState({ loading: true });

    if (this.state.mode === 'New') {
      this.props.newIwsFormStore.createNew(this.mapToDto(iwsFormWizardViewModel, false));
      this.props.newIwsFormStore
        .submit()
        .then(r => {
          this.setState({ loading: false });
          if (r != null && r.isSuccess) {
            let tabForm = isPoc ? 1 : 3;
            history.push({ pathname: '/assessments/iws?tab=' + tabForm });
          } else {
            document.getElementById('root').scrollTop = 0;
          }
        })
        .catch(_ => {
          document.getElementById('root').scrollTop = 0;
          this.setState({ loading: false });
        });
    } else if (this.state.mode === 'Edit') {
      this.props.changeIwsFormWizardStore.change(this.mapToChangeDtoEditMode(iwsFormWizardViewModel, true));
      this.props.changeIwsFormWizardStore
        .submit(true)
        .then(r => {
          this.setState({ loading: false });
          if (r != null && r.isSuccess) {
            let tabForm = isPoc ? 1 : 3;
            history.push({ pathname: '/assessments/iws?tab=' + tabForm });
          } else {
            document.getElementById('root').scrollTop = 0;
          }
        })
        .catch(_ => {
          document.getElementById('root').scrollTop = 0;
          this.setState({ loading: false });
        });
    }
  };

  //EDIT MODE MAPS
  private mapToChangeDtoEditMode = (iwsFormWizardViewModel: IwsFormWizardViewModel, onSubmit: boolean): ChangeIwsFormDto => {
    const { itemToEdit } = this.state;

    const employee = this.mapToChangeEmployeeDto(
      iwsFormWizardViewModel.employeesAssessorViewModel,
      iwsFormWizardViewModel.iwsTemplateStepViewModel
    );

    return {
      id: itemToEdit.id,
      employee: employee,
      deadline1: iwsFormWizardViewModel.generalStepViewModel.deadline1,
      deadline2: iwsFormWizardViewModel.generalStepViewModel.deadline2,
      deadline3: iwsFormWizardViewModel.generalStepViewModel.deadline3
    };
  };

  private mapToChangeEmployeeDto = (
    viewModel: EmployeesAssessorsViewModel,
    templatesView: IwsTemplateStepViewModel
  ): ChangeEmployeeIwsFormDto => {
    const { userId } = this.state.itemToEdit;
    let templates = this.templatesDtoFromViewModelDict(templatesView);

    const kvp = getKeyValuePairs<string>(viewModel.employeesAssessors[userId].skillsAssessor);
    let qualifiers: ChangeQualifierDto[] = [];
    kvp
      .groupBy(x => x.value)
      .toArray<IGroup<string, { key: string; value: string }>>()
      .forEach(assessor => {
        assessor.items.forEach(templ => {
          const tempSkills = [templates.find(t => t.id === templ.key).skillSection.skillId];
          qualifiers.push({ userId: assessor.key, templateId: templ.key, skills: tempSkills });
        });
      });

    return {
      userId: userId,
      qualifiers
    };
  };

  //CREATE MODE MAPS
  private templatesDtoFromViewModelDict = (view: IwsTemplateStepViewModel): IwsTemplateDto[] => {
    let entries = Object.entries(view || {});
    let profileList = entries.map(x => x[1] as IwsTemplateViewModel);
    let templates = profileList.flatMap(x => x.templates);
    return templates;
  };

  private getTemplateIdsFromDict = (view: IwsTemplateStepViewModel): string[] => {
    let templates = this.templatesDtoFromViewModelDict(view);
    return [...templates.map(x => x.id)];
  };

  private getTemplateWithLocalQuestions = (view: IwsTemplateStepViewModel): CreateTemplateWithLocalQuestions[] => {
    let templates = this.templatesDtoFromViewModelDict(view);
    const templatesWithLocalQuestions: CreateTemplateWithLocalQuestions[] = [];

    templates.forEach(temp => {
      let skillsWithLocalQuestions: CreteSkillWithLocalQuestions[] = [];
      let skill = temp.skillSection;

      let localQuestionIds = skill.questions.filter(q => q.isAddedInCustom).map(x => x.id);
      if ((localQuestionIds || []).length > 0) {
        skillsWithLocalQuestions.push({ skillId: skill.skillId, localQuestions: localQuestionIds });
      }

      if ((skillsWithLocalQuestions || []).length > 0) {
        templatesWithLocalQuestions.push({
          templateId: temp.id,
          skillsWithLocalQuestions: skillsWithLocalQuestions
        });
      }
    });
    return templatesWithLocalQuestions;
  };

  private mapToCreateEmployeeIwsFormDto = (
    employeesAssessorViewModel: EmployeesAssessorsViewModel,
    templatesView: IwsTemplateStepViewModel
  ): CreateEmployeeIwsFormDto[] => {
    const employeesAssessors: CreateEmployeeIwsFormDto[] = [];

    const kvps = getKeyValuePairs<EmployeeAssessors>(employeesAssessorViewModel.employeesAssessors).filter(
      ({ key, value }) => !isNullOrWhiteSpaces(key) && value != null
    );
    let templates = this.templatesDtoFromViewModelDict(templatesView);
    kvps.forEach(({ key, value }) => {
      employeesAssessors.push({
        userId: key,
        qualifiers: this.mapToCreateQualifierDto(value.skillsAssessor, templates)
      });
    });

    return employeesAssessors;
  };

  private mapToCreateQualifierDto = (profiles: { [skillId: string]: string }, templates: IwsTemplateDto[]): CreateQualifierDto[] => {
    const assessorsSkills: { [assessorId: string]: string[] } = {};
    const kvps = getKeyValuePairs<string>(profiles).filter(({ key, value }) => !isNullOrWhiteSpaces(key) && !isNullOrWhiteSpaces(value));

    kvps.forEach(({ key, value }) => {
      let assessorSkills = assessorsSkills[value] || [];
      assessorSkills.push(key);
      assessorsSkills[value] = assessorSkills;
    });

    const createQualifiersDto: CreateQualifierDto[] = [];
    getKeyValuePairs<string[]>(assessorsSkills).forEach(({ key, value }) => {
      value.forEach(tId => {
        const tempSkill = [templates.find(t => t.id === tId)?.skillSection.skillId];

        createQualifiersDto.push({
          userId: key,
          skills: tempSkill,
          templateId: tId
        });
      });
    });
    return createQualifiersDto;
  };

  toReturnMessages = () => {
    let message: Message[];
    if (this.state.mode === 'Edit') {
      message =
        this.props.changeIwsFormWizardStore.state.result && !this.props.changeIwsFormWizardStore.state.result.isSuccess
          ? (this.props.changeIwsFormWizardStore.state.result.messages || []).filter(x => !isNullOrWhiteSpaces(x.body))
          : [];
    } else if (this.state.mode === 'New') {
      message =
        this.props.newIwsFormStore.state.result && !this.props.newIwsFormStore.state.result.isSuccess
          ? (this.props.newIwsFormStore.state.result.messages || []).filter(x => !isNullOrWhiteSpaces(x.body))
          : [];
    }
    return message;
  };

  render() {
    const { loading, mode, itemToEdit } = this.state;
    const dataFormEditMode = this.props.history.location.state as IwsFormListDto;
    const messages = this.toReturnMessages();
    const { iwsFormListStore, changeIwsFormWizardStore, newIwsFormStore, userProfilesStore } = this.props;

    if (!this.isOnEditMode() || (this.isOnEditMode() && this.state.itemToEdit)) {
      return (
        <>
          <IwsFormWizard
            dataFormEditMode={dataFormEditMode}
            itemToEdit={itemToEdit}
            iwsWizardMode={mode}
            onSubmit={this.handleOnSubmit}
            messages={messages}
            isValidViewModel={this.isValidViewModel}
            {...this.props}
          />

          <Dimmer
            active={
              newIwsFormStore.state.isBusy ||
              changeIwsFormWizardStore.state.isBusy ||
              iwsFormListStore.state.isBusy ||
              userProfilesStore.state.isBusy ||
              loading
            }
            style={{ background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 999 }}
          >
            <Loader indeterminate>{changeIwsFormWizardStore.state.isBusy ? 'Saving IWS SuC' : 'Loading IWS SuC'}</Loader>
          </Dimmer>
        </>
      );
    } else return <></>;
  }
}

export default withTranslation()(CreateIwsFormWizard);
