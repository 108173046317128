import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Query } from 'stores/dataStore';
import { ChangeLogSupportCostStore, SupportCostDto, ChangeLogSupportCostDto } from 'stores/configuration/support-costs/support-costs-store';
import { Modal, Dimmer, Loader, Container, Message, Button } from 'semantic-ui-react';
import { TableModel, TableView } from 'widgets/collections/table';
import { DateTimeService } from 'services/datetime-service';

interface SupportCostChangeLogViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changelogSupportCost?: ChangeLogSupportCostStore;
  currentSC: SupportCostDto;
}

interface SupportCostChangeLogViewState {
  query: Query;
}

@connect(['changelogSupportCost', ChangeLogSupportCostStore])
class SupportCostChangeLogView extends React.Component<SupportCostChangeLogViewProps, SupportCostChangeLogViewState> {
  constructor(props: SupportCostChangeLogViewProps) {
    super(props);

    this.ChangeLogSupportCostStore.state.result = null;

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }],
        filter: null,
        skip: 0,
        take: 10
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const query = { ...this.state.query };
    const q = { ...query };
    q.filter = [...((query.filter as any[]) || []), { year: this.props.currentSC.year }];
    this.getHistory(q);
  };

  private getHistory = async (query: Query) => {
    return await this.ChangeLogSupportCostStore.getAllHistoryAsync(query);
  };

  private get ChangeLogSupportCostStore() {
    return this.props.changelogSupportCost;
  }

  @autobind
  private onCancelViewItem() {
    this.props.onClose(false);
  }

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  };

  public render() {
    const { t } = this.props as any;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          renderer: data => data.name
        },
        {
          title: t('Year'),
          renderer: data => data.year
        },
        {
          title: t('Cost'),
          renderer: data => data.cost
        },
        {
          title: t('Created on'),
          renderer: data => <span>{DateTimeService.toNotificationString(data.createdOn)}</span>
        }
      ],
      data: this.props.changelogSupportCost.state
    } as TableModel<ChangeLogSupportCostDto>;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelViewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.ChangeLogSupportCostStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t(`${this.props.currentSC.name} Change Log History`)}</Modal.Header>
        <Modal.Content image>
          <Container className="modal__wrapper">
            {this.ChangeLogSupportCostStore.state.result && !this.ChangeLogSupportCostStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.ChangeLogSupportCostStore.state.result.messages.map(o => o.body)}
              />
            )}
            <div className="">
              <TableView
                hiddeMenu
                isRowDisableLayout={(item: ChangeLogSupportCostDto) => !item.active}
                model={tableModel}
                canEdit={false}
                canDelete={false}
                onPageChange={this.handlePageChange}
              />
            </div>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelViewItem} basic>
            {t('Close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(SupportCostChangeLogView);
