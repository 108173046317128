import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropdownProps } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';
import { EventUserDto } from 'stores/events/events-store';
import { PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';

interface InstructorsSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  locationId?: string;
  onChange?: (value: EventUserDto) => void;
  options: EventUserDto[];
  onlyInstructorsPlannerTFT?: boolean;
  onlyInstructorsPlannerMTC?: boolean;
}

class EventInstructorsSelector extends React.Component<InstructorsSelectorProps> {
  state = {
    options: [],
    optionsMap: {}
  };

  componentDidMount() {
    this.refreshOptions();
  }

  componentDidUpdate(prevProps: InstructorsSelectorProps) {
    if (this.props.options !== prevProps.options || this.props.locationId !== prevProps.locationId) {
      this.refreshOptions();
    }
  }

  private refreshOptions() {
    let currentOptions = this.props.options || [];
    let optionsFiltered = !this.props.locationId ? currentOptions : currentOptions.filter(x => x.location?.id === this.props.locationId);

    let options = optionsFiltered.map(x => {
      let isDisabled: (item: EventUserDto) => boolean = undefined;

      if (!!this.props.onlyInstructorsPlannerTFT || !!this.props.onlyInstructorsPlannerMTC) {
        if (this.props.onlyInstructorsPlannerTFT) {
          isDisabled = item => !(item.pillar && (item.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !item.pillar.managedBy));
        }

        if (this.props.onlyInstructorsPlannerMTC) {
          isDisabled = item => !(item.pillar && (item.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !item.pillar.managedBy));
        }
      }

      return {
        key: x.id,
        text: x.firstName + ' ' + x.lastName,
        object: x,
        value: x.id,
        disabled: isDisabled && isDisabled(x)
      };
    });
    let optionsMap = this.buildOptionsMap(optionsFiltered);
    this.setState({ optionsMap, options });
  }

  private buildOptionsMap(options) {
    let mappedOptions = {};
    options.forEach(x => {
      mappedOptions[x.id] = x;
    });
    return mappedOptions;
  }

  public render() {
    return (
      <Dropdown
        selection
        disabled={this.props.readOnly}
        search={true}
        clearable={this.props.clearable}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.state.options}
        onChange={(event, { value }) => this.props.onChange(this.state.optionsMap[value as string])}
        selectOnBlur={false}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EventInstructorsSelector);
