import React, { FC, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TheoreticalFormView } from 'stores/assessments/forms/theoretical-form-test-store';
import { Icon } from 'semantic-ui-react';
import { MediaContent } from 'stores/assessments/questionBank/theoretical-test-store';

interface FormSummaryProps extends WithTranslation, TheoreticalFormView {}

const mediaContentTypeToIcon = (mediaContentType: MediaContent) => {
  if (mediaContentType === 'Video') return <Icon size="large" name="video" />;
  if (mediaContentType === 'Image') return <Icon size="large" name="image" />;
  return null;
};

const TheoreticalFormSummary: FC<FormSummaryProps> = props => {
  const { t, questionAnswers } = props;
  if (questionAnswers.length <= 0) return null;

  return (
    <>
      <div className="theoretical-summary__table">
        <p className="theoretical-summary__table-title">{t('Question')} </p>
        <p className="theoretical-summary__table-title">{t('Question ID')} </p>
        <p className="theoretical-summary__table-title">{t('Description')} </p>
        <p className="theoretical-summary__table-title">{t('Content')} </p>
        <p className="theoretical-summary__table-title">{t('Answer Type')} </p>
        <p className="theoretical-summary__table-title">{t('Question Time')} </p>
        <p className="theoretical-summary__table-title">{t('Option Selected')} </p>
        <p className="theoretical-summary__table-title">{t('Result')} </p>
        {questionAnswers.map((item, i) => {
          const { friendlyId, question, mediaContentType, answerType, unavailableMedia, value, time, correctAnswer, answered } = item;
          const typeOfAnswer = answerType === 'YesOrNo' ? 'Yes/No' : 'Rating';
          let filteredOption = unavailableMedia ? 'No video' : value;
          return (
            <Fragment key={'' + friendlyId + i}>
              <div className="border-bottom-gray">
                <p>{i + 1}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{friendlyId}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{question?.text}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{mediaContentTypeToIcon(mediaContentType)}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{typeOfAnswer}</p>
              </div>
              <div className="border-bottom-gray">
                <p>{time}</p>
              </div>
              <div className="border-bottom-gray">
                <p style={{ color: unavailableMedia ? '#B0B0B0' : 'inherit' }}>{filteredOption || '-'}</p>
              </div>
              <div className="border-bottom-gray">
                {unavailableMedia || !answered ? (
                  <p style={{ color: '#B0B0B0' }}>{t('Not Answered')}</p>
                ) : correctAnswer ? (
                  <p>{t('Correct')}</p>
                ) : (
                  <p style={{ color: '#E03C31' }}>{t('Wrong')}</p>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
export default withTranslation()(TheoreticalFormSummary);
