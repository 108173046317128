import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';

export interface InstructorRoleDto extends BaseDto {
  id: string;
  name: string;
}

export interface ChangeInstructorRoleDto {
  id: string;
  name: string;
}

export class ChangeInstructorRoleValidator extends AbstractValidator<ChangeInstructorRoleDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('InstructorRole Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('InstructorRole name is required'));
  }
}

@repository('@@INSTRUCTOR-ROLES', 'instructor-roles.summary')
export class InstructorRoleStore extends DataStore<InstructorRoleDto> {
  baseUrl = 'identity/api/v1';
  createPath = '';
  retrievePath = 'get-instructor-roles';
  updatePath = '';
  deletePath = '';

  protected validate(item: InstructorRoleDto) {
    return new ChangeInstructorRoleValidator().validate(item);
  }

  constructor() {
    super('INSTRUCTOR_ROLE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
