import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import { hasAdministrationRights } from 'utils/event-utils';
import CancelEventPopup from '../popups/cancel-event-popup/cancel-event-popup';

export interface CancelEventButtonProps extends WithTranslation {
  onEventCancelled?: () => void;
  eventFormStore?: EventFormStore;
  readOnly: boolean;
}

export interface CancelEventButtonState {
  showCancelEventModal: boolean;
}

@connect(['eventFormStore', EventFormStore])
class CancelEventButton extends Component<CancelEventButtonProps, CancelEventButtonState> {
  static defaultProps = { onEventCancelled: () => {} };
  state: CancelEventButtonState = { showCancelEventModal: false };

  private handleOnEventCancelled = () => {
    this.hideCancelModal();
    this.props.onEventCancelled();
  };

  private handleOnCancelEventClick = () => this.setState({ showCancelEventModal: true });

  private hideCancelModal = () => this.setState({ showCancelEventModal: false });

  private canBeCancelled = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return event.status.toString() !== 'Completed' && event.status.toString() !== 'Closed';
  };

  private isCancelEventButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return hasAdministrationRights(event) && this.canBeCancelled();
  };

  render() {
    const { t, readOnly } = this.props;
    const { showCancelEventModal } = this.state;
    const isVisible = this.isCancelEventButtonVisible();

    if (!isVisible) return null;

    return (
      <>
        <Button
          className="form__actions__button form__cancel-btn"
          disabled={!readOnly}
          content={t('Cancel Event')}
          onClick={this.handleOnCancelEventClick}
        />
        {showCancelEventModal && (
          <CancelEventPopup open={showCancelEventModal} onSuccess={this.handleOnEventCancelled} onCancel={this.hideCancelModal} />
        )}
      </>
    );
  }
}

export default withTranslation()(CancelEventButton);
