import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { isNullOrEmpty } from 'utils/useful-functions';

interface BooleanEditorProps extends WithTranslation {
  value: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  placeholder?: string;
  clearable?: boolean;
  readOnly?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface BooleanEditorState {
  options: DropdownItemProps[];
}

class BooleanEditor extends React.Component<BooleanEditorProps, BooleanEditorState> {
  constructor(props: BooleanEditorProps) {
    super(props);
    this.state = {
      options: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    };
  }

  public render() {
    return (
      <Dropdown
        className={!this.props.className ? 'user-dropdown' : this.props.className}
        selection
        selectOnBlur={false}
        placeholder={this.props.placeholder}
        clearable={this.props.clearable}
        disabled={this.props.readOnly}
        options={this.state.options}
        value={this.props.value}
        onChange={(e, { value }) => this.handleOnSelection(value)}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }

  private handleOnSelection(value) {
    if (isNullOrEmpty(value)) return this.props.onChange(null);

    this.props.onChange && this.props.onChange(value);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(BooleanEditor);
