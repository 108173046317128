import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
export interface MachineUnitDto extends BaseDto {
  id: string;
  name: string;
  clusterId: string;
  clusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
}

export interface CreateMachineUnitDto {
  name: string;
  equipmentTypeId: string;
  oemId: string;
}

export interface ChangeMachineUnitDto {
  id: string;
  name: string;
  equipmentTypeId: string;
  oemId: string;
}

export class CreateMachineUnitValidator extends AbstractValidator<CreateMachineUnitDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Unit name is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));
  }
}

export class ChangeMachineUnitValidator extends AbstractValidator<ChangeMachineUnitDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Machine Unit Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Machine Unit name is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('OEM is required'));
  }
}

@repository('@@MACHINE-UNITS', 'machineUnits.summary')
export class MachineUnitsStore extends DataStore<MachineUnitDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-unit';
  retrievePath = 'get-machine-units';
  updatePath = 'update-machine-unit';
  deletePath = 'delete-machine-unit';
  retrieveOnePath = 'v1-get-machine-unit';

  protected validate(item: MachineUnitDto) {
    return new ChangeMachineUnitValidator().validate(item);
  }

  constructor() {
    super('MACHINE_UNIT', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getMachineUnitById(id: string): Promise<MachineUnitDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<MachineUnitDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  // private  = () => {};
}

@repository('@@MACHINE-UNITS', 'machineUnits.new')
export class NewMachineUnitStore extends FormStore<CreateMachineUnitDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-unit';
  retrievePath = 'get-machine-units';
  updatePath = 'update-machine-unit';

  protected validate(item: CreateMachineUnitDto) {
    return new CreateMachineUnitValidator().validate(item);
  }

  constructor() {
    super('NEW_MACHINE_UNIT', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@MACHINE-UNITS', 'machineUnits.change')
export class ChangeMachineUnitStore extends FormStore<ChangeMachineUnitDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-machine-unit';
  retrievePath = 'get-machine-units';
  updatePath = 'update-machine-unit';

  protected validate(item: ChangeMachineUnitDto) {
    return new ChangeMachineUnitValidator().validate(item);
  }

  constructor() {
    super('CHANGE_MACHINE_UNIT', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
