import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { AdminReportCountersStore, LoginsAuditItemDataDto } from 'stores/admin-reports/admin-reports-counters-store';
import CounterBox from './components/counter-box';
import { Message } from 'semantic-ui-react';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

export interface AdminReportCountersProps extends RouteComponentProps, WithTranslation {
  adminReportCounterStore?: AdminReportCountersStore;
}

export interface AdminReportCountersState {
  selectedData: LoginsAuditItemDataDto[];
  graphType: 'WEEK' | 'MONTH' | 'YEAR';
}

@connect(['adminReportCounterStore', AdminReportCountersStore])
class AdminReportCounters extends React.Component<AdminReportCountersProps, AdminReportCountersState> {
  state = {
    selectedData: null,
    graphType: null
  };

  componentDidMount() {
    this.props.adminReportCounterStore.loadCounters();
  }

  private onDraftEventsClick = () => {
    this.props.history.push('/events');
  };
  private onPlannedEventsClick = () => {
    this.props.history.push('/events');
  };
  private onInProgressEventsClick = () => {
    this.props.history.push('/events');
  };
  private onCompletedEventsClick = () => {
    this.props.history.push('/events');
  };
  private onOpenRequestsClick = () => {
    this.props.history.push('/requests');
  };
  private onOpenTheoreticalsClick = () => {
    this.props.history.push('/assessments/theoretical-test?tab=3');
  };
  private onOpenFeedbacksClick = () => {
    this.props.history.push('/assessments/feedbacks');
  };
  private onOpenPracticalsClick = () => {
    this.props.history.push('/assessments/practical-test?tab=3');
  };
  private onLastWeekTotalLoggedUsersClick = () => {
    const { adminReportCounterStore } = this.props;
    this.setState({
      selectedData: this.state.graphType === 'WEEK' ? null : adminReportCounterStore.state.counters.lastWeekLoggedUsersCount,
      graphType: this.state.graphType === 'WEEK' ? null : 'WEEK'
    });
  };
  private onLastMonthTotalLoggedUsersClick = () => {
    const { adminReportCounterStore } = this.props;
    this.setState({
      selectedData: this.state.graphType === 'MONTH' ? null : adminReportCounterStore.state.counters.lastMonthLoggedUsersCount,
      graphType: this.state.graphType === 'MONTH' ? null : 'MONTH'
    });
  };
  private onLastYearTotalLoggedUsersClick = () => {
    const { adminReportCounterStore } = this.props;
    this.setState({
      selectedData: this.state.graphType === 'YEAR' ? null : adminReportCounterStore.state.counters.lastYearLoggedUsersCount,
      graphType: this.state.graphType === 'YEAR' ? null : 'YEAR'
    });
  };
  private onOpenTnasClick = () => {
    this.props.history.push('/assessments/tna?tab=3');
  };
  private onOpenSsasClick = () => {
    this.props.history.push('/assessments/ssa?tab=3');
  };

  render() {
    const {
      t,
      adminReportCounterStore: {
        state: { counters, result, isBusy }
      }
    } = this.props;
    const { selectedData, graphType } = this.state;
    return (
      <>
        {result && !result.isSuccess && (result.messages || []).length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            list={result.messages.map(o => o.body)}
            onDismiss={this.props.adminReportCounterStore.clearMessages}
          />
        )}
        <div className="admin-report__counters-container">
          <div className="admin-report__counters-container__row">
            <CounterBox
              color="#00B2A9"
              count={counters.lastWeekTotalLoggedUsersCount}
              title={t('LOGGED USERS LAST WEEK')}
              loading={isBusy}
              clickable={true}
              onClick={this.onLastWeekTotalLoggedUsersClick}
              selected={graphType === 'WEEK'}
            />
            <CounterBox
              color="#00B2A9"
              count={counters.lastMonthTotalLoggedUsersCount}
              title={t('LOGGED USERS LAST MONTH')}
              loading={isBusy}
              clickable={true}
              onClick={this.onLastMonthTotalLoggedUsersClick}
              selected={graphType === 'MONTH'}
            />
            <CounterBox
              color="#00B2A9"
              count={counters.lastYearTotalLoggedUsersCount}
              title={t('LOGGED USERS LAST YEAR')}
              loading={isBusy}
              clickable={true}
              onClick={this.onLastYearTotalLoggedUsersClick}
              selected={graphType === 'YEAR'}
            />
          </div>
          <div className="admin-report__counters-container__row">
            <CounterBox
              color="#EAAA00"
              count={counters.openTnas}
              title={t('OPEN TNA')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenTnasClick}
            />
            <CounterBox
              color="#EAAA00"
              count={counters.openSsas}
              title={t('OPEN SSA')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenSsasClick}
            />
            <CounterBox
              color="#EAAA00"
              count={counters.openTheoreticalsCount}
              title={t('OPEN THEORETICAL')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenTheoreticalsClick}
            />
            <CounterBox
              color="#EAAA00"
              count={counters.openPracticalsCount}
              title={t('OPEN PRACTICAL')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenPracticalsClick}
            />
            <CounterBox
              color="#EAAA00"
              count={counters.openFeedbacksCount}
              title={t('OPEN FEEDBACK')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenFeedbacksClick}
            />
          </div>
          <div className="admin-report__counters-container__row">
            <CounterBox
              color="#E782A9"
              count={counters.draftEventsCount}
              title={t('DRAFT EVENTS')}
              loading={isBusy}
              clickable={true}
              onClick={this.onDraftEventsClick}
            />
            <CounterBox
              color="#E782A9"
              count={counters.plannedEventsCount}
              title={t('PLANNED EVENTS')}
              loading={isBusy}
              clickable={true}
              onClick={this.onPlannedEventsClick}
            />
            <CounterBox
              color="#E782A9"
              count={counters.inProgressEventsCount}
              title={t('IN PROGRESS EVENTS')}
              loading={isBusy}
              clickable={true}
              onClick={this.onInProgressEventsClick}
            />
            <CounterBox
              color="#E782A9"
              count={counters.completedEventsCount}
              title={t('COMPLETED EVENTS')}
              loading={isBusy}
              clickable={true}
              onClick={this.onCompletedEventsClick}
            />
            <CounterBox
              color="#E782A9"
              count={counters.openRequestsCount}
              title={t('OPEN REQUESTS')}
              loading={isBusy}
              clickable={true}
              onClick={this.onOpenRequestsClick}
            />
          </div>
        </div>
        {selectedData != null && (
          <div className="admin-report__counters__graph-container">
            <h2 className="admin-report__counters__graph-title">{t(`UNIQUE USERS LAST ${graphType}`)}</h2>
            <LineChart
              width={graphType === 'MONTH' ? 1000 : 571.5}
              height={311.5}
              data={selectedData}
              margin={{
                top: 8.5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <XAxis
                dataKey="title"
                padding={{ left: 10, right: 10 }}
                interval={0}
                angle={graphType === 'MONTH' ? -30 : undefined}
                dy={graphType === 'MONTH' ? 5 : undefined}
                dx={graphType === 'MONTH' ? -5 : undefined}
                tick={graphType === 'MONTH' ? { fontSize: 12 } : undefined}
              />
              <YAxis padding={{ top: 10 }} />
              <Tooltip />
              <Line type="monotone" dataKey="count" stroke="#00B2A9" />
            </LineChart>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(AdminReportCounters);
