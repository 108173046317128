import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { RouteComponentProps } from 'react-router';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormStore, SsaFormViewModel, AssessorAnswerStatusDto, ReturnFormDto } from 'stores/assessments/forms/ssa-forms-store';
import SsaFormHeaderComponent from './ssa-form-header-component';
import ReturnModal from './returned-case/return-modal';
import SSAFormMainComponent from './ssa-form-main-component';
import SsaFormFooterComponent from './ssa-form-footer-component';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import './ssa-edit-form-general.less';

interface ChangeSsaFormViewProps extends WithTranslation, RouteComponentProps {
  onClose: (isSuccess: boolean) => void;
  onChangeLanguage?: (language: string) => void;
  ssaFormStore?: SsaFormStore;
}

interface ChangeSsaFormState {
  showReturnModal: boolean;
  isSaved: boolean;
  isSended: boolean;
  statusFormDone: boolean;
  assessorAnswerStatus: AssessorAnswerStatusDto;
  returnObj: ReturnObj[];
  showConfirmButton: boolean;
  loading: boolean;
}

export interface ReturnObj {
  assessorId: string;
  name: string;
  skills: string[];
  checked: boolean;
  comment: string;
}

@connect(['ssaFormStore', SsaFormStore])
class ChangeSsaFormView extends React.Component<ChangeSsaFormViewProps, ChangeSsaFormState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: ChangeSsaFormState = {
    showReturnModal: false,
    isSaved: false,
    isSended: false,
    statusFormDone: false,
    assessorAnswerStatus: { userId: '', answerStatus: 'Unknown', comment: '' },
    returnObj: [],
    showConfirmButton: true,
    loading: false
  };

  componentDidMount() {
    this.load();
  }

  buildReturnObj = ({ functionalExperts }: SsaFormViewModel): ReturnObj[] =>
    (functionalExperts || []).map(({ skills, userId: assessorId, user }) => ({
      assessorId,
      name: user.firstName + ' ' + user.lastName,
      comment: '',
      skills: skills.map(({ name }) => name),
      checked: false
    }));

  load = async () => {
    document.querySelector('.planit-main-container').classList.add('employee-background');
    const id = this.props.match.params['id'].toString();
    const item = await this.props.ssaFormStore.initFromSsaFormAnswer(id);
    if (!item) return;
    const change: any = {};
    const assessorAnswerStatus = this.getAssesssorAnswerStatus();
    change.assessorAnswerStatus = assessorAnswerStatus;
    if (this.isInRoleAdminOrPoC()) change.returnObj = this.buildReturnObj(item);
    this.setState({ ...change });
  };

  componentWillUnmount() {
    document.querySelector('.planit-main-container').classList.remove('employee-background');
  }

  private get ssaFormStore() {
    return this.props.ssaFormStore;
  }

  private isAssessor = (): boolean => {
    if (!this.ssaFormStore.state.item) return;
    const { userId: user } = this.identityService.getUserInfo();
    const { functionalExperts } = this.ssaFormStore.state.item;
    const isFunctionalExpert = (functionalExperts || []).find(({ userId }) => user === userId);
    if (isFunctionalExpert) return true;
  };

  private isInRoleAdminOrPoC = (): boolean => {
    return IdentityService.isAdminPocPlannerOrInstructor(this.identityService.getUserInfo());
  };

  onModifiableStatusByAssessor = (): boolean => {
    const item: SsaFormViewModel = this.ssaFormStore.state.item;
    if (!item) return;
    const assessor = this.isAssessor();
    const isInRoleAdminOrPoC = this.isInRoleAdminOrPoC();
    if (assessor && !isInRoleAdminOrPoC && (item.status === 'Assessors' || item.status === 'Returned')) {
      return true;
    } else return;
  };

  isAbleToSubmitHandler = () => {
    const { item } = this.props.ssaFormStore.state;
    if (!item) return;
    const { functionalExperts, categorySections } = item;
    if (this.isInRoleAdminOrPoC()) return true;
    const { userId: currentUser } = this.identityService.getUserInfo();
    const funcExpert = functionalExperts.find(({ userId }) => userId === currentUser);
    if (!funcExpert) return;
    const skillIds = funcExpert.skills.map(({ id }) => id);
    return categorySections.every(({ skillSections }) =>
      skillSections.filter(({ skillId }) => skillIds.find(id => id === skillId)).every(({ answer }) => answer != null)
    );
  };

  private getAssesssorAnswerStatus = () => {
    const { item } = this.props.ssaFormStore.state;
    if (!item) return;
    const { assessorAnswerStatus } = item;
    const { userId: currentUser } = this.identityService.getUserInfo();
    return assessorAnswerStatus.find(({ userId }) => userId === currentUser);
  };

  onSaveSsaFormAnswer = async () => {
    const { t } = this.props;
    await this.props.ssaFormStore.updateSsaForm().then(({ isSuccess }) => {
      if (isSuccess) {
        ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
      }
    });
  };

  onSendSsaFormAnswer = async () => {
    const { ssaFormStore } = this.props;
    await ssaFormStore.sendSsaForm();
    ssaFormStore.state.result && ssaFormStore.state.result.isSuccess && window.close();
  };

  toggleReturnModal = () => {
    this.setState(({ showReturnModal }) => ({ showReturnModal: !showReturnModal }));
  };

  returnObjChangeHandler = (items: ReturnObj[]) => {
    const { ssaFormStore, t } = this.props;
    this.setState({ loading: true });
    ssaFormStore
      .returnCase({
        assesors: items.map(({ name: _0, skills: _1, ...rest }) => ({ ...rest })),
        formId: ssaFormStore.state.item.formId
      } as ReturnFormDto)
      .then(({ isSuccess }) => {
        if (isSuccess) {
          this.setState({ showConfirmButton: false });
          ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
          this.setState({ loading: false });
          window.close();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
    this.toggleReturnModal();
  };

  onExportExcel = () => {
    const { ssaFormStore } = this.props;
    const { state } = ssaFormStore;

    this.setState({ loading: true });

    state &&
      state.item &&
      this.props.ssaFormStore
        .exportToExcel(state.item.formId)

        .then(res => {
          ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
          this.setState({ loading: false });
        })
        .catch(error => {
          console.error(error);
          ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
          this.setState({ loading: false });
        });
  };

  handleScroll = e => {
    const myDiv = document.getElementById('ssaLanguageSelector');
    const scroll = e.target.scrollTop;
    if (scroll >= 300) {
      myDiv.style.position = 'absolute';
      myDiv.style.top = '0px';
      myDiv.style.right = '0px';
      myDiv.style.transform = `translateY(60px)`;
    } else {
      myDiv.removeAttribute('style');
    }
  };

  public render() {
    const { t, ssaFormStore } = this.props;
    const { state } = ssaFormStore;
    const { assessorAnswerStatus, showReturnModal, returnObj, showConfirmButton, loading } = this.state;
    const isInRoleAdminOrPoC = this.isInRoleAdminOrPoC();
    const isCurrentUserAssessor = this.isAssessor();
    const isModifiableStatusByAssessor = this.onModifiableStatusByAssessor();
    const pocAdminCanReturned = isInRoleAdminOrPoC && state.item && state.item.status === 'Review';

    return (
      <>
        <div className="ssa-edit-form__all-wrapper">
          <h2 className="edit-form__title">{(state.item?.ssaAssessment?.title || '').toUpperCase()}</h2>
          {state.result && !state.result.isSuccess && (
            <Message className="error-message__style" icon="exclamation circle" error list={state.result.messages.map(m => m.body)} />
          )}
          <Dimmer active={state.isBusy || loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading')}</Loader>
          </Dimmer>

          {state.item?.user && (
            <Form reply>
              <div onScroll={this.handleScroll} className="form__all-wrapper">
                <header className="form__all-wrapper__header">
                  <SsaFormHeaderComponent isInRoleAdminOrPoC={isInRoleAdminOrPoC} />
                </header>

                <main className="form__all-wrapper__main-content scroll">
                  <SSAFormMainComponent
                    formStatus={state.item.status}
                    currentUser={this.identityService.userId}
                    isInRoleAdminOrPoC={isInRoleAdminOrPoC}
                    isCurrentUserAssessor={isCurrentUserAssessor}
                    assessorAnswerStatus={assessorAnswerStatus}
                    onExportExcel={this.onExportExcel}
                  />
                </main>

                <footer className="form__all-wrapper__footer">
                  <SsaFormFooterComponent
                    pocAdminCanReturned={pocAdminCanReturned}
                    isCurrentUserAssessor={isCurrentUserAssessor}
                    isCurrentUserPocOrAdmin={isInRoleAdminOrPoC}
                    isModifiableStatusByAssessor={
                      assessorAnswerStatus && assessorAnswerStatus.answerStatus !== 'Done' && isModifiableStatusByAssessor
                    }
                    formStatus={state.item.status}
                    isAbleToSubmit={this.isAbleToSubmitHandler()}
                    onReturned={this.toggleReturnModal}
                    onSaveSsa={this.onSaveSsaFormAnswer}
                    onSubmit={this.onSendSsaFormAnswer}
                    showConfirmButton={showConfirmButton}
                  />
                </footer>
              </div>
            </Form>
          )}
        </div>
        {showReturnModal && (
          <ReturnModal onChange={this.returnObjChangeHandler} assessorsToReturn={returnObj} onCancel={this.toggleReturnModal} />
        )}
      </>
    );
  }
}

export default withTranslation()(ChangeSsaFormView);
