import React, { FC, memo } from 'react';
import RejectionReasonEditor from 'widgets/bussiness/rejection-reason-editor';
import RejectedByEditor from 'widgets/bussiness/rejected-by-editor';
import { Button, Dimmer, Form, Loader, Message, Modal } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface RejectProperties {
  rejectReason: string;
  rejectionReasonId: string;
  rejectedById: string;
}
interface RejectModalProps extends WithTranslation {
  title?: string;
  subtitle?: string;
  open: boolean;
  loading: boolean;
  rejectReason: string;
  rejectedById: string;
  rejectionReasonId: string;
  errMsgs: string[];
  onDismiss: () => void;
  onSubmit: () => void;
  onClose: () => void;
  onChange: (property: keyof RejectProperties, value: any) => void;
  submitBtnText?: string;
  cancelBtnText?: string;
}
const msgProps = { className: 'error-message__style', error: true, icon: 'exclamation circle', list: [], onDismiss: () => {} };

const RejectModal: FC<RejectModalProps> = props => {
  const { open, loading, errMsgs, onDismiss, onChange, onSubmit, onClose, t, ...rest } = props;
  const { title, subtitle, submitBtnText, cancelBtnText, rejectionReasonId, rejectReason, rejectedById } = rest;
  const rejectReasonHandler = (value: string) => onChange('rejectionReasonId', value);

  const rejectedByHandler = (value: string) => onChange('rejectedById', value);

  const handleValue = (_, { value }) => onChange('rejectReason', value);

  const errors = (errMsgs || []).length ? (
    <Message {...msgProps} onDismiss={onDismiss} header={t('An error ocurred')} list={errMsgs} />
  ) : null;

  return (
    <Modal open={open} size="tiny" className="reject-request-popup" centered={true} closeOnEscape={true} onClose={onClose}>
      <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
        <Loader indeterminate>{t('')}</Loader>
      </Dimmer>
      <Modal.Content className="reject-request-popup__content">
        {errors}
        <div className="reject-request-popup__description">
          <p>{t(title || 'You are going to Reject this Request.')}</p>
          <p>{t(subtitle || 'Please, write your reasons.')}</p>
        </div>
        <div className="reject-request-popup__selectors">
          <RejectionReasonEditor value={rejectionReasonId} onChange={rejectReasonHandler} />
          <RejectedByEditor value={rejectedById} onChange={rejectedByHandler} />
        </div>
        <Form loading={loading}>
          <Form.TextArea
            placeholder={t('Reason')}
            required
            className="reject-request-popup__reason-description"
            value={rejectReason}
            onChange={handleValue}
          />
        </Form>
      </Modal.Content>
      <div className="reject-request-popup__buttons-container">
        <Button
          className="reject-request-popup__btn reject-request-popup__cancel-btn"
          content={t(cancelBtnText || 'Cancel')}
          onClick={onClose}
        />
        <Button className="form__cancel-btn" content={t(submitBtnText || 'Reject')} onClick={onSubmit} />
      </div>
    </Modal>
  );
};

export default withTranslation()(memo(RejectModal));
