import React, { FC, useState } from 'react';
import Step from 'widgets/form/templates-step-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FeedbackQuestionDto, FeedbackTemplateItemDto } from 'stores/assessments/templates/feedbacks-templates-store';
import QuestionsComponent from '../../../../ssa/templates/wizard/steps/ssa-wizard-second-step-question-component';
import { Button } from 'semantic-ui-react';
import { FeedbackQuestionBankDto } from 'stores/assessments/questionBank/feedback-questionBank-store';
import ChooseFeedbackQuestionsView from 'widgets/form/choose-feedback-questions-form';

export interface SecondStepProps extends WithTranslation {
  active: boolean;
  questions: FeedbackQuestionDto[];
  onChange: (change: Partial<FeedbackTemplateItemDto>) => void;
}

const SecondStep: FC<SecondStepProps> = ({ active, questions, t, onChange }) => {
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);

  const addQuestionsHandler = (modalQuestions: FeedbackQuestionBankDto[]) => {
    const newQs: FeedbackQuestionDto[] = (modalQuestions || []).map(({ id, friendlyId, question }) => ({
      friendlyId,
      questionId: id,
      text: question.text
    }));
    onChange({ feedbackQuestions: [...questions, ...newQs] });
  };

  const handleMoveUpQuestion = (questionIdx: number) => {
    const feedbackQuestions = [...questions];
    const temporalVariable = { ...feedbackQuestions[questionIdx - 1] };
    feedbackQuestions[questionIdx - 1] = { ...feedbackQuestions[questionIdx] };
    feedbackQuestions[questionIdx] = { ...temporalVariable };
    onChange({ feedbackQuestions });
  };

  const handleMoveDownQuestion = (questionIdx: number) => {
    const feedbackQuestions = [...questions];
    const temporalVariable = { ...feedbackQuestions[questionIdx + 1] };
    feedbackQuestions[questionIdx + 1] = { ...feedbackQuestions[questionIdx] };
    feedbackQuestions[questionIdx] = { ...temporalVariable };
    onChange({ feedbackQuestions });
  };

  const moveQuestionHandler = (dir: 'up' | 'down', idx: number) => {
    if (questions.length <= 1) return;
    if (dir === 'up' && idx > 0) handleMoveUpQuestion(idx);
    else if (dir === 'down' && idx < questions.length - 1) handleMoveDownQuestion(idx);
  };

  const removeQuestionHandler = (idx: number) => {
    onChange({ feedbackQuestions: questions.filter((_, i) => i !== idx) });
  };
  const hideQuestionsModal = () => {
    setShowQuestionsModal(false);
  };
  const showModalHandler = () => {
    setShowQuestionsModal(true);
  };

  return (
    <>
      <Step
        active={active}
        number="3"
        title={t('SELECT QUESTIONS')}
        render={
          <div className="questions-container">
            <Button onClick={showModalHandler} content={t('Add Questions')} />
            {(questions || []).map(({ friendlyId, text: question, questionId: id }, i) => (
              <QuestionsComponent
                key={i}
                move={dir => moveQuestionHandler(dir, i)}
                canGoUp={i > 0}
                canGoDown={i + 1 < questions.length}
                onRemoveQuestion={() => removeQuestionHandler(i)}
                question={{ friendlyId, question, id }}
              />
            ))}
          </div>
        }
      />
      {showQuestionsModal && (
        <ChooseFeedbackQuestionsView
          onCloseModal={hideQuestionsModal}
          onAddFeedbackQuestions={addQuestionsHandler}
          alreadySelectedQuestions={questions.map(({ questionId }) => questionId)}
        />
      )}
    </>
  );
};

export default withTranslation()(SecondStep);
