import { TnaSkillsStore, ChangeTnaSkillStore, NewTnaSkillStore } from './tna-skills-store';
import { SsaSkillsStore, ChangeSsaSkillStore, NewSsaSkillStore } from './ssa-skills-store';
import { ChangeIwsSkillStore, IwsSkillsStore, NewIwsSkillStore } from './iws-skills-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new TnaSkillsStore());
  storeBuilder.addRepository(new ChangeTnaSkillStore());
  storeBuilder.addRepository(new NewTnaSkillStore());
  storeBuilder.addRepository(new SsaSkillsStore());
  storeBuilder.addRepository(new ChangeSsaSkillStore());
  storeBuilder.addRepository(new NewSsaSkillStore());
  storeBuilder.addRepository(new IwsSkillsStore());
  storeBuilder.addRepository(new ChangeIwsSkillStore());
  storeBuilder.addRepository(new NewIwsSkillStore());
}
