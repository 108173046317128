import { History } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { storeBuilder } from 'redux-scaffolding-ts';
import thunk from 'redux-thunk';

import { setupRepositories } from './repositories.config';
import { ApplicationState } from './stores/reducers';

export default function configureStore(history: History, initialState?: ApplicationState) {
  // If devTools is installed, connect to it
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          latency: 0
        })
      : compose;

  // define middlewares
  const middlewares = [thunk, routerMiddleware(history)];

  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middlewares))(createStore);

  // Add repositories
  setupRepositories(storeBuilder);

  // Combine all reducers and instantiate the app-wide store instance
  const store = storeBuilder.getStore(initialState, buildRootReducer(), createStoreWithMiddleware) as Store<ApplicationState>;

  return store;
}

function buildRootReducer() {
  return combineReducers<ApplicationState>({
    router: routerReducer,
    countries: (state = {}) => state,
    languages: (state = {}) => state,
    eventRequestChanges: (state = {}) => state,
    'delivery-types': (state = {}) => state,
    traininglevels: (state = {}) => state,
    locations: (state = {}) => state,
    personnelAreas: (state = {}) => state,
    nmClusters: (state = {}) => state,
    nmCategories: (state = {}) => state,
    nmFunctionalAreas: (state = {}) => state,
    nmTrainingNames: (state = {}) => state,
    nmFunctionalSubareas: (state = {}) => state,
    usefulLinks: (state = {}) => state,
    supportCosts: (state = {}) => state,
    users: (state = {}) => state,
    user: (state = {}) => state,
    instructorExpertises: (state = {}) => state,
    instructorExpertise: (state = {}) => state,
    clusters: (state = {}) => state,
    equipmentTypes: (state = {}) => state,
    machineModels: (state = {}) => state,
    machineUnits: (state = {}) => state,
    oems: (state = {}) => state,
    patterns: (state = {}) => state,
    plcTypes: (state = {}) => state,
    perdiems: (state = {}) => state,
    roles: (state = {}) => state,
    priorities: (state = {}) => state,
    machinesforlocations: (state = {}) => state,
    professions: (state = {}) => state,
    requests: (state = {}) => state,
    checklists: (state = {}) => state,
    'customization-data': (state = {}) => state,
    'request-reasons': (state = {}) => state,
    'instructor-roles': (state = {}) => state,
    'event-types': (state = {}) => state,
    'position-codes': (state = {}) => state,
    events: (state = {}) => state,
    importlogs: (state = {}) => state,
    positionCodes: (state = {}) => state,
    userimport: (state = {}) => state,
    matches: (state = {}) => state,
    notifications: (state = {}) => state,
    todos: (state = {}) => state,
    eventchecklistroleuser: (state = {}) => state,
    eventchecklistsroleuser: (state = {}) => state,
    publicholidayconfiguration: (state = {}) => state,
    workingdays: (state = {}) => state,
    dropdownlocations: (state = {}) => state,
    instructors: (state = {}) => state,
    deliverymethodsfmap: (state = {}) => state,
    eventtypesfmap: (state = {}) => state,
    dropdownroles: (state = {}) => state,
    'tna-templates': (state = {}) => state,
    tnaskills: (state = {}) => state,
    ssaskills: (state = {}) => state,
    tnaquestionbanks: (state = {}) => state,
    ssaquestionbanks: (state = {}) => state,
    testcategory: (state = {}) => state,
    pillars: (state = {}) => state,
    profiles: (state = {}) => state,
    'user-profiles-store': (state = {}) => state,
    evaluations: (state = {}) => state,
    employeeTrackLog: (state = {}) => state,
    tnaformlist: (state = {}) => state,
    regions: (state = {}) => state,
    'ssa-templates': (state = {}) => state,
    'ssa-forms': (state = {}) => state,
    ssaActivityType: (state = {}) => state,
    'feedbacks-templates': (state = {}) => state,
    feedbackquestionbanks: (state = {}) => state,
    'tna-development-plan': (state = {}) => state,
    'ssa-development-plan': (state = {}) => state,
    formfeedbacklist: (state = {}) => state,
    mrTheoreticalTestQB: (state = {}) => state,
    nmrTheoreticalTestQB: (state = {}) => state,
    'feedback-forms': (state = {}) => state,
    'theoretical-templates': (state = {}) => state,
    tnaTheoreticalTests: (state = {}) => state,
    theoreticalTestsForm: (state = {}) => state,
    adminReports: (state = {}) => state,
    mrPracticalTestQuestionBank: (state = {}) => state,
    nmrPracticalTestQuestionBank: (state = {}) => state,
    formtheoreticallist: (state = {}) => state,
    nmrPracticalTestQB: (state = {}) => state,
    mrPracticalTestQB: (state = {}) => state,
    'practical-form-test': (state = {}) => state,
    'practical-templates': (state = {}) => state,
    instructorExpertiseRequests: (state = {}) => state,
    mrHoursDuration: (state = {}) => state,
    nmrHoursDuration: (state = {}) => state,
    formpracticallist: (state = {}) => state,
    machineries: (state = {}) => state,
    'reject-reasons': (state = {}) => state,
    'rejected-by': (state = {}) => state,
    iwsskills: (state = {}) => state,
    iwsquestionbanks: (state = {}) => state,
    'iws-templates': (state = {}) => state,
    iwsformlist: (state = {}) => state,
    tnaquestiontranslateforms: (state = {}) => state,
    iwsquestiontranslateforms: (state = {}) => state
  } as any);
}
