import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Header, Icon } from 'semantic-ui-react';

export class AuthErrorView extends React.Component<any, any> {
  render() {
    const { t } = this.props as any;
    return (
      <div className="planit-general-error-container">
        <Container>
          <Header icon>
            <Icon name="frown outline" />
            {t('Authorization Error')}
          </Header>
          <p>{t('Something went wrong checking your permissions. Reload the page to try again')}</p>
        </Container>
      </div>
    );
  }
}

// frown outline Wire up the React component to the Redux store
export default withTranslation()(AuthErrorView);
