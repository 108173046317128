import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface MrHoursDurationDto extends BaseDto {
  id: string;
  clusterId: string;
  clusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  machineModelId: string;
  machineModelName: string;
  machineUnitId: string;
  machineUnitName: string;
  professionId: string;
  professionName: string;
  trainingLevelId: string;
  trainingLevelName: string;
  hoursDuration: number;
  originalEventTypeId: string;
  currentEventTypeId: string;
  eventTypeName: string;
}

export interface CreateMrHoursDurationDto {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  machineUnitId: string;
  professionId: string;
  trainingLevelId: string;
  hoursDuration: number;
  originalEventTypeId: string;
  isFamilyCreationRequest: boolean;
}

export interface ChangeMrHoursDurationDto {
  id: string;
  hoursDuration: number;
}

export class CreateMrHoursDurationValidator extends AbstractValidator<CreateMrHoursDurationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.originalEventTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(o => o.professionId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Role is invalid'));

    this.validateIfString(o => o.trainingLevelId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Training Level is invalid'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Machine Model is required'));

    this.validateIfString(o => o.machineUnitId)
      .isUuid('4')
      .whenNotEmpty()
      .withFailureMessage(i18n.t('Machine Unit is invalid'));

    this.validateIfNumber(o => o.hoursDuration)
      .isGreaterThan(0)
      .when(x => !x.isFamilyCreationRequest)
      .withFailureMessage(i18n.t('The Hours Duration must be greater than 0'));
  }
}

export class ChangeMrHoursDurationValidator extends AbstractValidator<ChangeMrHoursDurationDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('NmrHoursDuration Id is required'));

    this.validateIfNumber(o => o.hoursDuration)
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('The Hours Duration must be greater than 0'));
  }
}

@repository('@@MRHOURS_DURATIONS', 'mrHoursDuration.summary')
export class MrHoursDurationsStore extends DataStore<MrHoursDurationDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-mr-hour';
  retrievePath = 'get-mr-hours';
  updatePath = 'update-mr-hour';
  deletePath = 'delete-mr-hour';

  protected validate(item: MrHoursDurationDto) {
    return new ChangeMrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('MRHOURSDURATION', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@MRHOURS_DURATIONS', 'mrHoursDuration.new')
export class NewMrHoursDurationStore extends FormStore<CreateMrHoursDurationDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-mr-hour';
  retrievePath = 'get-mr-hours';
  updatePath = 'update-mr-hour';

  protected validate(item: CreateMrHoursDurationDto) {
    return new CreateMrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('NEW_MRHOURSDURATION', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@MRHOURS_DURATIONS', 'mrHoursDuration.change')
export class ChangeMrHoursDurationStore extends FormStore<ChangeMrHoursDurationDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-mr-hour';
  retrievePath = 'get-mr-hours';
  updatePath = 'update-mr-hour';

  protected validate(item: ChangeMrHoursDurationDto) {
    return new ChangeMrHoursDurationValidator().validate(item);
  }

  constructor() {
    super('CHANGE_MRHOURSDURATION', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
