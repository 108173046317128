import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface FormRowProps extends WithTranslation {
  icon?: IconProp;
  label?: string;
  className?: string;
}

const FormRow: FC<FormRowProps> = ({ label, icon, className, children }) => {
  let classname = `form__header__row`;
  if (className) classname += ` ${className}`;

  return (
    <div className={classname}>
      {icon && <FontAwesomeIcon className="form__header__row-icon" icon={icon} />}
      {label && <p>{label}</p>}
      {children}
    </div>
  );
};

export default withTranslation()(FormRow);
