import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { LocationDto, DropDownLocationsStore } from '../../../stores/configuration/locations/locations-store';
import { nameof } from '../../../utils/object';
import { QueryResult, OrderDefinition } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';

interface LocationEditorProps extends WithTranslation {
  value: LocationDto[];
  onChange?: (value: LocationDto[]) => void;
  locations?: DropDownLocationsStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  locationsReceived?: string[];
  showCountry?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface LocationEditorState {
  options: MultipleSelectionDropdownOptions<LocationDto>[];
}

@connect(['locations', DropDownLocationsStore])
class MultipleLocationEditor extends React.Component<LocationEditorProps, LocationEditorState> {
  private get locationStore() {
    return this.props.locations;
  }

  state: LocationEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions = (locations: QueryResult<LocationDto>): MultipleSelectionDropdownOptions<LocationDto>[] =>
    (locations?.items || []).map(location => ({
      id: location.id,
      value: location,
      text:
        !this.props.showCountry || !location || (location.code !== 'XXXX' && location.code !== 'XXXXX')
          ? location.location + ' - ' + location.countryName
          : location.location
    }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      let filter;
      const filterActive = `active eq true`;
      const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }];

      if (this.props.locationsReceived && this.props.locationsReceived.length > 0)
        filter = [{ id: { in: { type: 'guid', value: this.props.locationsReceived } } }, filterActive];
      else filter = [filterActive];

      this.locationStore
        .getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter }, this.props.locationsReceived)
        .then(locations => this.setState({ options: this.mapToOptions(locations) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={this.props.className}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleLocationEditor);
