import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Input, Grid, Label, Icon } from 'semantic-ui-react';
import { DateWarningType, EventWizardData } from 'stores/events/creation-wizard-store';
import { DateInput } from 'widgets/form/dateInput';
import { DurationInput } from 'widgets/form/durationInput';
import EventService, { DatesCalculationRequestDto, EventDurationRequestDto } from 'stores/events/event-service';
import { isNullOrWhiteSpaces, trim, replaceAll, isNullOrEmpty } from 'utils/useful-functions';
import { DateTimeService } from 'services/datetime-service';
import { resolve } from 'inversify.config';
import { Message as BackendMessage } from 'stores/types';

interface DatesStepProps extends WithTranslation {
  wizardData: EventWizardData;
  dateFormat?: string;
  onValueChanged: (...changes: [string, string][]) => void;
  durationWarning: boolean;
}

interface DatesStepState {
  wizardData: EventWizardData;
  warnings: DateWarningType[];
  recalculating: boolean;
}

class DatesStep extends Component<DatesStepProps, DatesStepState> {
  @resolve(EventService)
  eventsService: EventService;

  constructor(props: DatesStepProps) {
    super(props);
    const { t } = props;
    const warnings = [];
    if (props.durationWarning) warnings.push({ type: 'DURATION', text: t('There is no default calculated duration for this event') });

    this.state = { wizardData: props.wizardData, warnings, recalculating: false };
  }

  private getDurationRequestDto = (): EventDurationRequestDto => {
    const data = this.state.wizardData;
    return {
      isMachineRelated: data.isMachineRelated,
      mrRequest: data.isMachineRelated
        ? {
            originalEventTypeId: data.eventType?.originalEventTypeId,
            professionId: data.profession?.id,
            rows: (data.requestMachines || []).map(rm => ({
              clusterId: rm.cluster?.id,
              equipmentTypeId: rm.equipmentType?.id,
              machineModelId: rm.machineModel?.id,
              machineUnitsId: rm.machineUnits,
              oemId: rm.oem?.id
            })),
            trainingLevelId: data.trainingLevel?.id
          }
        : null,
      nmrRequest: !data.isMachineRelated
        ? {
            nmrClusterId: data.nmrCluster?.id ?? data.nmrClusterId,
            nmrFunctionalAreaId: data.nmrFunctionalArea?.id ?? data.nMRFunctionalAreaId,
            nmrTrainingNameId: data.nmrTrainingName?.id ?? data.nMRTrainingNameId
          }
        : null
    };
  };

  private recalculateEventDuration = () => {
    const { t } = this.props;
    const dto = this.getDurationRequestDto();

    this.setState({ recalculating: true });
    this.eventsService
      .calculateEventDuration(dto)
      .then(v => {
        let changes: [string, any][] = [['calculatedEventDuration', v]];

        const w = this.state.wizardData;
        if (isNullOrEmpty(w.plannedDuration) || w.plannedDuration === '0 w/d' || w.plannedDuration === '0') {
          changes = [...changes, ['plannedDuration', `${v}`], ['userEventDuration', v]];
        }

        this.handleValues(...changes);
        this.setState({
          recalculating: false,
          warnings: this.state.warnings.filter(x => x.type !== 'DURATION')
        });
      })
      .catch(_ => {
        this.handleValue('calculatedEventDuration', 0);
        this.setState({
          recalculating: false,
          warnings: [
            {
              type: 'DURATION',
              text: t('There is no default calculated duration for this event')
            },
            ...this.state.warnings.filter(x => x.type !== 'DURATION')
          ]
        });
      });
  };

  private getDatesCalculationRequestDto = (recalculate: 'StartDate' | 'EndDate' | 'Duration'): DatesCalculationRequestDto => {
    const data = this.state.wizardData;
    return {
      startDate: recalculate === 'StartDate' ? null : data.dateFrom,
      endDate: recalculate === 'EndDate' ? null : data.dateTo,
      durationDays: recalculate === 'Duration' ? null : data.userEventDuration,
      locationId: data.location?.id,
      pausePeriods: data.pausePeriods || []
    };
  };

  private performCalculation = (recalculate: 'StartDate' | 'EndDate' | 'Duration') => {
    const dto = this.getDatesCalculationRequestDto(recalculate);
    this.setState({ recalculating: true });
    const warnStateCalculation = this.state.warnings.filter(x => x.type !== 'CALCULATION');
    this.eventsService
      .calculateEventDates(dto)
      .then(({ item, isSuccess, messages }) => {
        const warnMsgs = (messages || []).map(x => ({ text: x.body, type: 'CALCULATION' }));
        const stateChanges: any = { recalculating: false };
        if (isSuccess && item != null) {
          const changes = {
            dateFrom: item.startDate,
            dateTo: item.endDate,
            plannedDuration: `${item.durationDays}`,
            userEventDuration: item.durationDays,
            shouldRecalculate: false
          };
          this.setState(
            ({ wizardData }) => ({ wizardData: { ...wizardData, ...changes } }),
            () => this.props.onValueChanged(...Object.keys(changes).map(x => [x as string, changes[x]] as [string, string]))
          );
        }

        stateChanges.warnings = [...warnStateCalculation, ...warnMsgs];

        this.setState({ ...stateChanges });
      })
      .catch(e => {
        const warnMsgs = (e?.response?.data?.messages || []).map((x: BackendMessage) => ({ text: x.body, type: 'CALCULATION' }));
        this.setState({ recalculating: false, warnings: [...warnStateCalculation, ...warnMsgs] });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps: DatesStepProps) {
    if (
      nextProps &&
      nextProps !== this.props &&
      (nextProps.wizardData.calculatedEventDuration !== this.props.wizardData.calculatedEventDuration ||
        nextProps.durationWarning !== this.props.durationWarning)
    ) {
      let warnings = this.state.warnings;
      if (nextProps.durationWarning !== this.props.durationWarning) {
        if (!!nextProps.durationWarning) {
          warnings = warnings.filter(x => x.type !== 'DURATION');
        } else {
          const { t } = nextProps;

          warnings = [
            {
              type: 'DURATION',
              text: t('There is no default calculated duration for this event')
            },
            ...warnings.filter(x => x.type !== 'DURATION')
          ];
        }
      }

      this.setState({ wizardData: nextProps.wizardData, warnings });
    }
  }

  private handleValues = (...changes: [string, any][]) => {
    const { wizardData } = this.state;
    changes.forEach(([property, value]) => {
      wizardData[property] = value;
    });
    this.setState({ wizardData }, () => this.props.onValueChanged(...changes));
  };

  private handleValue = (property: keyof EventWizardData, value: any) => {
    const wizardData = { ...this.state.wizardData };
    const { dateFrom, dateTo } = wizardData;
    wizardData[property as string] = value;
    const changes = [];

    if (property === 'dateFrom' || property === 'dateTo') {
      const current = value && DateTimeService.toMoment(value);
      const start = dateFrom && DateTimeService.toMoment(dateFrom);
      const end = dateTo && DateTimeService.toMoment(dateTo);
      changes.push([property, value]);
      if (property === 'dateFrom') {
        if (current && end && current.isAfter(end, 'd')) {
          wizardData.dateTo = current.toISOString();
          changes.push(['dateTo', DateTimeService.toString(current)]);
        }
      } else {
        if (current && start && start.isAfter(current, 'd')) {
          wizardData.dateTo = start.toISOString();
          changes.push(['dateTo', DateTimeService.toString(start)]);
        }
      }
    } else if (property === 'plannedDuration') {
      const val = parseInt(trim(replaceAll(value || '', 'w/d', '')) || '0');
      if (Number.isNaN(val)) {
        wizardData.plannedDuration = '0';
        wizardData.userEventDuration = 0;
        changes.push([property, '0']);
        changes.push(['userEventDuration', 0]);
      } else {
        changes.push([property, value]);
        changes.push(['userEventDuration', val]);
        wizardData.userEventDuration = val;
      }
    } else changes.push([property, value]);
    this.setState({ wizardData }, () => this.props.onValueChanged(...changes));
  };

  render() {
    const { t } = this.props as any;
    const { wizardData, warnings, recalculating } = this.state;

    const hasDateFrom = !isNullOrWhiteSpaces(wizardData.dateFrom);
    const hasDateTo = !isNullOrWhiteSpaces(wizardData.dateTo);
    const hasDates = hasDateFrom && hasDateTo;
    const hasPlannedDuration = !isNullOrWhiteSpaces(wizardData.plannedDuration);
    return (
      <>
        <Form loading={recalculating} className="wizard__form__margin wizard__step-dates-wrapper">
          <Grid columns={4}>
            <Grid.Row className="padding-bottom-20px">
              <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                <div className={`required field inline`}>
                  <label>{t('Calculated Duration')}</label>
                </div>
              </Grid.Column>
              <Grid.Column className="normal-looking-disabled-input relative-recalc-command-container" width={4}>
                <Input
                  readOnly
                  className="wizard__numeric-input"
                  id="events__step3__event-duration__input"
                  value={`${wizardData?.calculatedEventDuration || ' - '} w/d`}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="second-row">
              <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                <div className={`required field inline`}>
                  <label>{t('Events Dates')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <DateInput
                  iconPosition="right"
                  className="date-picker__left"
                  id="date-step__date-input__left"
                  required
                  value={wizardData.dateFrom}
                  onChange={(_, data) => this.handleValue('dateFrom', data)}
                />

                <DateInput
                  iconPosition="right"
                  className="date-picker__right"
                  id="date-step__date-input__right"
                  required
                  value={wizardData.dateTo}
                  onChange={(_, data) => this.handleValue('dateTo', data)}
                  minDate={wizardData.dateFrom || null}
                  forceOpen={!!wizardData?.dateFrom}
                  initialValue={wizardData.dateFrom}
                />
              </Grid.Column>
              <Grid.Column textAlign="right" verticalAlign="middle" width={2}>
                <div className={`required field inline`}>
                  <label>{t('Event Duration')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Input className="form__planned-duration__numeric__wd" type="number">
                  <DurationInput onChange={value => this.handleValue('plannedDuration', value)} value={`${wizardData.plannedDuration}`} />
                </Input>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="recalc-commands-row">
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={4}>
                <div className="recalc-dates-container">
                  {hasDateTo && hasPlannedDuration && (
                    <span className="recalc-command" onClick={() => this.performCalculation('StartDate')}>
                      {t('Recalculate start date')}
                    </span>
                  )}
                </div>
                <div className="recalc-dates-container">
                  {hasDateFrom && hasPlannedDuration && (
                    <span className="recalc-command" onClick={() => this.performCalculation('EndDate')}>
                      {t('Recalculate end date')}
                    </span>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={4}>
                {hasDates && (
                  <span className="recalc-command" onClick={() => this.performCalculation('Duration')}>
                    {t('Recalculate duration')}
                  </span>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        {(warnings || []).length !== 0 && (
          <Label className="modal__action-warning wizard__action-warning">
            <Icon name="exclamation triangle" />
            <div>
              {(warnings || []).map(({ text }, index) => (
                <Fragment key={'date-warning-' + index}>{text != null && <p className="modal__action-warning__text"> {text} </p>}</Fragment>
              ))}
            </div>
          </Label>
        )}
      </>
    );
  }
}

export default withTranslation()(DatesStep);
