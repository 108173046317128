import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TnaUserProfileListingDetails from './user-profile-listing-details/tna-user-profile-listing-details';

import './employee-page.less';
import './background-employee.less';

import { Button } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

import { UserProfileDto } from 'stores/profile/user-profile-store';
import SsaUserProfileListingDetails from './user-profile-listing-details/ssa-user-profile-listing-details';
import IwsUserProfileListingDetails from './user-profile-listing-details/iws-user-profile-listing-details';

export interface ProfileListingTabContentProps extends WithTranslation, RouteComponentProps {
  userProfiles: UserProfileDto[];
  selectFirstAvailableByDefault?: boolean;
}

export interface ProfileListingTabContentState {
  selectedUserProfile: UserProfileDto;
}

class ProfileListingTabContent extends Component<ProfileListingTabContentProps, ProfileListingTabContentState> {
  state = {
    selectedUserProfile: null
  };

  componentDidMount() {
    if (this.props.selectFirstAvailableByDefault) this.selectFirstAvailableProfile();
  }

  private selectFirstAvailableProfile() {
    const selectedUserProfile = this.props.userProfiles.firstOrDefault();
    if (selectedUserProfile == null) return;
    this.setState({ selectedUserProfile: selectedUserProfile });
  }

  private handleClickProfileBtn = (userProfileId: string) => {
    const selectedUserProfile = this.props.userProfiles.firstOrDefault(profile => profile.id === userProfileId);
    if (selectedUserProfile == null) return;
    this.setState({ selectedUserProfile: selectedUserProfile });
  };

  public render() {
    const { selectedUserProfile } = this.state;
    return (
      <>
        <div className="profile-machinery__list flex-start">
          <div className="profile-machinery__profile-list flex-column">
            {this.props.userProfiles.map((profile, index) => (
              <Button
                key={profile.id + '_' + index}
                onClick={() => this.handleClickProfileBtn(profile.id)}
                className={
                  this.state.selectedUserProfile && this.state.selectedUserProfile.id === profile.id
                    ? 'custom-table-add-btn'
                    : profile.profileIsActive
                    ? 'custom-table-add-btn button__inactive'
                    : 'custom-table-add-btn button__disabled'
                }
              >
                {profile.profileName}
              </Button>
            ))}
          </div>
          {selectedUserProfile && selectedUserProfile.assessment.toString() === 'TNA' && (
            <TnaUserProfileListingDetails
              {...this.props}
              selectFirstByDefault={this.props.selectFirstAvailableByDefault}
              userProfile={this.state.selectedUserProfile}
            />
          )}

          {selectedUserProfile && selectedUserProfile.assessment.toString() === 'SSA' && (
            <SsaUserProfileListingDetails
              {...this.props}
              selectFirstByDefault={this.props.selectFirstAvailableByDefault}
              userProfile={this.state.selectedUserProfile}
            />
          )}
          {selectedUserProfile && selectedUserProfile.assessment.toString() === 'IWS' && (
            <IwsUserProfileListingDetails
              {...this.props}
              selectFirstByDefault={this.props.selectFirstAvailableByDefault}
              userProfile={this.state.selectedUserProfile}
            />
          )}
        </div>
      </>
    );
  }
}
export default withTranslation()(ProfileListingTabContent);
