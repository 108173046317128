import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export enum CheckListRoles {
  Planner = 10,
  PoC = 20,
  Instructor = 30,
  Employee = 40,
  PlannerMTC = 50
}

export interface CheckListDto extends BaseDto {
  id: string;
  description: string;
  eventTypeId: string;
  eventTypeName: string;
  rolesThatCreateCheckLists: string[];
}

export interface CreateCheckListDto {
  description: string;
  eventTypeId: string;
  rolesThatCreateCheckLists: string[];
}

export interface ChangeCheckListDto {
  id: string;
  description: string;
  eventTypeId: string;
  rolesThatCreateCheckLists: string[];
}

export class CreateCheckListValidator extends AbstractValidator<CreateCheckListDto> {
  private roles = ['Planner', 'PoC', 'Instructor', 'Employee', 'PlannerMTC'];

  constructor() {
    super();

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Id is required'));

    this.validateIf(o => o.rolesThatCreateCheckLists)
      .isNotEmpty()
      .withFailureMessage(i18n.t('CheckList creator role is required'));

    this.validateIfEach(o => o.rolesThatCreateCheckLists)
      .isIn(this.roles)
      .whenDefined()
      .whenNotNull()
      .withFailureMessage(i18n.t('CheckList creator role is invalid'));
  }
}

export class ChangeCheckListValidator extends AbstractValidator<ChangeCheckListDto> {
  private roles = ['Planner', 'PoC', 'Instructor', 'Employee', 'PlannerMTC'];

  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Id is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIf(o => o.rolesThatCreateCheckLists)
      .isNotEmpty()
      .withFailureMessage(i18n.t('CheckList creator role is required'));

    this.validateIfEach(o => o.rolesThatCreateCheckLists)
      .isIn(this.roles)
      .whenDefined()
      .whenNotNull()
      .withFailureMessage(i18n.t('CheckList creator role is invalid'));
  }
}

@repository('@@CHECKLISTS', 'checklists.summary')
export class CheckListStore extends DataStore<CheckListDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'create-checklist';
  retrievePath = 'get-checklists';
  updatePath = 'update-checklist';
  deletePath = 'delete-checklist';

  protected validate(item: CheckListDto) {
    return new ChangeCheckListValidator().validate(item);
  }

  constructor() {
    super('CHECKLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@CHECKLISTS', 'checklists.new')
export class NewCheckListStore extends FormStore<CreateCheckListDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'create-checklist';
  retrievePath = 'get-checklist';
  updatePath = 'update-checklist';

  protected validate(item: CreateCheckListDto) {
    return new CreateCheckListValidator().validate(item);
  }

  constructor() {
    super('NEW_CHECKLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@CHECKLISTS', 'checklists.change')
export class ChangeCheckListStore extends FormStore<ChangeCheckListDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'create-checklist';
  retrievePath = 'get-checklist';
  updatePath = 'update-checklist';

  protected validate(item: ChangeCheckListDto) {
    return new ChangeCheckListValidator().validate(item);
  }

  constructor() {
    super('CHANGE_CHECKLIST', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
