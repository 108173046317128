import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid } from 'semantic-ui-react';
import { UserDto, UserStore } from 'stores/users/users-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import InstructorSummary from './instructor-summary/instructor-summary-component';
import { EventsStore, EventDto } from 'stores/events/events-store';
import EventsEmployee from 'widgets/bussiness/events-employee';
import { Query } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { goToPage } from 'utils/useful-functions';
import { InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import InstructorExpertisesComponent from './instructor-summary/instructor-expertise/instructor-expertises-component';
import './instructor-page.less';

export interface EmployeeViewProps extends WithTranslation, RouteComponentProps {
  usersStore?: UserStore;
  eventsStore?: EventsStore;
  instructorExpertiseStore?: InstructorExpertiseStore;
}

export interface EmployeeViewState {
  currentUser: UserDto;
  eventsList: EventDto[];
  instructorLoggedOrFounded: boolean;
}

@connect(['usersStore', UserStore], ['eventsStore', EventsStore], ['instructorExpertiseStore', InstructorExpertiseStore])
class InstructorViewPage extends React.Component<EmployeeViewProps, EmployeeViewState> {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      eventsList: null,
      instructorLoggedOrFounded: true
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    const instructorId = this.identityService.isInRole(Roles.Instructor) && this.identityService.getUserInfo().userId;

    if (this.getInstructor(instructorId)) {
      this.getEventsByEmployeeId(instructorId).then(eventsListTemp =>
        this.setState({ eventsList: eventsListTemp, instructorLoggedOrFounded: true })
      );
    } else {
      goToPage('./NotFound');
    }
  }

  private getInstructor = async (id: string): Promise<boolean> => {
    try {
      const instructorData = await this.props.usersStore.getUserById(id);

      this.setState({ currentUser: instructorData, instructorLoggedOrFounded: true });
    } catch {
      this.setState({ instructorLoggedOrFounded: false });
      return false;
    }
    return true;
  };

  private getEventsByEmployeeId = async (id: string): Promise<EventDto[]> => {
    let queryEvents: Query = {
      searchQuery: '',
      orderBy: [
        {
          direction: 'Ascending',
          field: nameof<EventDto>('startDate'),
          useProfile: false
        }
      ],
      skip: 0,
      take: 100000
    };
    const eventsInstructor = await this.props.eventsStore.getAllAsyncByEmployeeId(queryEvents);

    return eventsInstructor.items;
  };

  public render() {
    return (
      this.state.instructorLoggedOrFounded && (
        <Grid className="padding-margin-right-none employee-page__container" columns={3}>
          <Grid.Row>
            <Grid.Column width={6} textAlign="left" verticalAlign="middle">
              <InstructorSummary user={this.state.currentUser}></InstructorSummary>
            </Grid.Column>

            <Grid.Column className="min-height-box" width={10} textAlign="center" verticalAlign="middle">
              <EventsEmployee eventsList={this.state.eventsList} />
            </Grid.Column>
          </Grid.Row>

          <InstructorExpertisesComponent />
        </Grid>
      )
    );
  }
}
export default withTranslation()(InstructorViewPage);
