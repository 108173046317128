import React from 'react';
import { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { ChangeRequestStore, RequestStatus, RequestDto } from '../../../../../stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { replaceAll } from 'utils/useful-functions';

interface SendRequestButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestCreatorRoles: string[];
  changeRequestStore?: ChangeRequestStore;
  readOnly: boolean;

  onSuccess?: (request: RequestDto) => void;
  onError?: () => void;
  onBeginReOpen?: () => void;
}

@connect(['changeRequestStore', ChangeRequestStore])
class SendRequestButton extends Component<SendRequestButtonProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnClick() {
    if (this.props.onBeginReOpen) this.props.onBeginReOpen();
    this.props.changeRequestStore
      .changeStatus(this.props.requestId, RequestStatus.Draft)
      .then(request => this.props.onSuccess && this.props.onSuccess(request))
      .catch(() => this.props.onError && this.props.onError());
  }

  render() {
    const { t, readOnly } = this.props as any;
    return (
      this.isVisible() && (
        <Button
          className="form__actions__button form__next-status-accept-btn"
          disabled={!readOnly}
          onClick={() => this.handleOnClick()}
          positive
        >
          {t('Reopen')}
        </Button>
      )
    );
  }

  isVisible() {
    return this.isVisibleInCurrentStatus() && this.currentUserHasPermission();
  }

  isVisibleInCurrentStatus(): boolean {
    return this.props.requestStatus.toString() === 'Rejected';
  }

  currentUserHasPermission(): boolean {
    const userInfo = this.identityService.getUserInfo();

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    return (this.props.requestCreatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''));
  }
}

export default withTranslation()(SendRequestButton);
