import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import i18n from '../../i18n';
import { DataStore, Query, QueryResult /*, ItemModel, DataModel*/ } from '../dataStore';
import { FormStore } from '../formStore';

import { BaseDto } from '../types';
import { UserDto } from 'stores/users/users-store';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';

export interface InstructorExpertiseDto extends BaseDto {
  id: string;
  instructorId: string;
  instructor: string;
  eventTypeId: string;
  eventTypeName: string;
  trainingLevelId: string;
  trainingLevelName: string;
  user: UserDto;
  locationId: string;
  locationName: string;
  countryName: string;
  countryId: string;

  isMachineRelated: boolean;

  machineModelId: string;
  machineModelName: string;
  machineRelatedClusterId: string;
  machineRelatedClusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  plcTypes: string[];
  plcTypeNames: string[];

  nmrClusterId: string;
  nmrClusterName: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalAreaName: string;
  nmrFunctionalSubAreaId: string;
  nmrFunctionalSubAreaName: string;
  nmrTrainingNameId: string;
  nmrTrainingName: string;

  roleId: string;
  roleName: string;
}

export interface CreateInstructorExpertiseDto {
  instructorId: string;
  eventTypeId: string;
  trainingLevelId: string;
  roleId: string;

  isMachineRelated: boolean;

  machineModelId: string;
  machineRelatedClusterId: string;
  equipmentTypeId: string;
  oemId: string;
  plcTypes: string[];

  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
}

export interface ChangeInstructorExpertiseDto extends CreateInstructorExpertiseDto {
  id: string;
}

export class CreateUserValidator extends AbstractValidator<CreateInstructorExpertiseDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(o => o.roleId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfString(o => o.trainingLevelId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIfString(o => o.machineRelatedClusterId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Machine Model is required'));

    this.validateIfString(o => o.nmrClusterId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.nmrFunctionalAreaId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(o => o.nmrTrainingNameId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Training Name is required'));

    this.validateIfString(o => o.nmrFunctionalSubAreaId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Functional SubArea is required'));
  }
}

export class ChangeUserValidator extends AbstractValidator<ChangeInstructorExpertiseDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(o => o.roleId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfString(o => o.trainingLevelId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIfString(o => o.machineRelatedClusterId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.equipmentTypeId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Equipment Type is required'));

    this.validateIfString(o => o.oemId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('OEM is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .when(x => x.isMachineRelated)
      .withFailureMessage(i18n.t('Machine Model is required'));

    this.validateIfString(o => o.nmrClusterId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.nmrFunctionalAreaId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(o => o.nmrTrainingNameId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Training Name is required'));

    this.validateIfString(o => o.nmrFunctionalSubAreaId)
      .isNotEmpty()
      .when(x => !x.isMachineRelated)
      .withFailureMessage(i18n.t('Functional SubArea is required'));
  }
}

// interface InstructorsExpertisesModel<T> extends DataModel<T> {
//   instructors: ItemModel<T>[];
//   instructorsCount: number;
//   instructorsIsBusy: boolean;
// }

@repository('@@INSTRUCTOREXPERTISE', 'instructorExpertise.summary')
export class InstructorExpertiseStore extends DataStore<InstructorExpertiseDto> {
  //baseUrl = 'http://localhost:7074/api';
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise';
  retrievePath = 'v2/get-instructor-expertises';
  retrieveGhostsPath = 'v1/get-ghost-instructor-expertises';
  updatePath = 'v1/update-instructor-expertise';
  deletePath = 'v1/delete-instructor-expertise';
  retrieveOnePath = 'v1/get-instructor-expertise';

  protected validate(item: InstructorExpertiseDto) {
    return new ChangeUserValidator().validate(item as any);
  }

  constructor() {
    super('INSTRUCTOREXPERTISE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(
    query: Query,
    removeDuplicates: boolean = false,
    instructorFilter: string = null,
    instructorLocationFilter: string = null,
    data?: any
  ): Promise<QueryResult<InstructorExpertiseDto>> {
    let httpService = container.get<HttpService>(HttpService);
    let url = `${this.baseUrl}/${this.retrievePath}?removeDuplicates=${removeDuplicates}`;

    if (instructorFilter) url += `&instructor=${instructorFilter}`;
    if (instructorLocationFilter) url += `&locationInstructorId=${instructorLocationFilter}`;

    url += `&${DataStore.buildUrl(query)}`;

    const result = await this.dispatchAsync(this.ENTITY_LIST_UPDATE, httpService.get<QueryResult<InstructorExpertiseDto>>(url, data));
    return result.data;
  }

  public async getAllWithGhostsAsync(
    query: Query,
    removeDuplicates: boolean = false,
    instructorFilter: string = null,
    instructorLocationFilter: string = null,
    data?: any
  ): Promise<QueryResult<InstructorExpertiseDto>> {
    let httpService = container.get<HttpService>(HttpService);
    let url = `${this.baseUrl}/${this.retrieveGhostsPath}?removeDuplicates=${removeDuplicates}`;

    if (instructorFilter) url += `&instructor=${instructorFilter}`;
    if (instructorLocationFilter) url += `&locationInstructorId=${instructorLocationFilter}`;

    url += `&${DataStore.buildUrl(query)}`;

    const result = await this.dispatchAsync(this.ENTITY_LIST_UPDATE, httpService.get<QueryResult<InstructorExpertiseDto>>(url, data));
    return result.data;
  }
}

@repository('@@INSTRUCTOREXPERTISE', 'instructorExpertise.new')
export class NewInstructorExpertiseStore extends FormStore<CreateInstructorExpertiseDto> {
  //baseUrl = 'http://localhost:7074/api';
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise';
  retrievePath = 'v2/get-instructor-expertises';
  updatePath = 'v1/update-instructor-expertise';
  deletePath = 'v1/delete-instructor-expertise';

  protected validate(item: CreateInstructorExpertiseDto) {
    return new CreateUserValidator().validate(item);
  }

  constructor() {
    super('NEW_INSTRUCTOREXPERTISE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@INSTRUCTOREXPERTISE', 'instructorExpertise.change')
export class ChangeInstructorExpertiseStore extends FormStore<ChangeInstructorExpertiseDto> {
  //baseUrl = 'http://localhost:7074/api';
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise';
  retrievePath = 'v2/get-instructor-expertises';
  updatePath = 'v1/update-instructor-expertise';
  deletePath = 'v1/delete-instructor-expertise';

  protected validate(item: ChangeInstructorExpertiseDto) {
    return new ChangeUserValidator().validate(item);
  }

  constructor() {
    super('CHANGE_INSTRUCTOREXPERTISE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@INSTRUCTOREXPERTISE', 'instructorExpertise.instructor-tab-summary')
export class InstructorTabExpertiseStore extends DataStore<InstructorExpertiseDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v2/get-instructor-expertises';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = '';
  lastUrlQuery: string = null;

  protected validate(item: InstructorExpertiseDto) {
    return new ChangeUserValidator().validate(item as any);
  }

  constructor() {
    super('INSTRUCTOREXPERTISE__INSTRUCTOR_TAB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(
    query: Query,
    removeDuplicates: boolean = false,
    instructorFilter: string = null,
    instructorLocationFilter: string = null,
    data?: any
  ): Promise<QueryResult<InstructorExpertiseDto>> {
    let httpService = container.get<HttpService>(HttpService);
    let url = `${this.baseUrl}/${this.retrievePath}?removeDuplicates=${removeDuplicates}`;

    if (instructorFilter) url += `&instructor=${instructorFilter}`;
    if (instructorLocationFilter) url += `&locationInstructorId=${instructorLocationFilter}`;

    url += `&${DataStore.buildUrl(query)}`;

    if (this.lastUrlQuery === url) {
      let eventsResult = { items: this.state.items.map(({ item }) => item), count: this.state.items.length } as QueryResult<
        InstructorExpertiseDto
      >;
      return new Promise<QueryResult<InstructorExpertiseDto>>(resolve => resolve(eventsResult));
    } else {
      const result = await this.dispatchAsync(this.ENTITY_LIST_UPDATE, httpService.get<QueryResult<InstructorExpertiseDto>>(url, data));
      this.lastUrlQuery = url;
      return result.data;
    }
  }
}
