import React, { FunctionComponent } from 'react';
import InputCostComponent, { InputCostProps } from './input-cost.component';

export interface CostsProps extends InputCostProps {
  label: string;
  subLabel?: string;
  value: number;
  onChange: (change: number) => void;
}

const CostsComponent: FunctionComponent<CostsProps> = ({ label, value, subLabel, onChange, ...rest }) => {
  return (
    <div className="costs-container">
      <div className="cost-label">
        <span>{label}</span>
        {subLabel && <span>{subLabel}</span>}
      </div>
      <InputCostComponent value={value} {...rest} onChange={onChange} />
    </div>
  );
};
export default CostsComponent;
