import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { RelatedToType } from 'stores/assessments/templates/theoretical-templates-store';

interface TheoreticalRelatedToEditorProps extends WithTranslation {
  value?: RelatedToType;
  onChange?: (value: RelatedToType) => void;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  clearable?: boolean;
  nullable?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface TheoreticalRelatedToEditorState {
  options: DropdownItemProps[];
}

class TheoreticalRelatedToEditor extends React.Component<TheoreticalRelatedToEditorProps, TheoreticalRelatedToEditorState> {
  constructor(props: TheoreticalRelatedToEditorProps) {
    super(props);
    this.state = {
      options: this.getAllRelatedToTypes()
    };
  }

  private handleOnSelection = value => {
    this.props.onChange(value);
  };

  private getAllRelatedToTypes() {
    return [
      { text: 'Event', value: 'Event' },
      { text: 'TNA', value: 'TNA' }
    ];
  }
  public render() {
    return (
      <Dropdown
        className={!this.props.className ? 'user-dropdown' : this.props.className}
        selection
        selectOnBlur={false}
        placeholder={this.props.placeholder}
        clearable={this.props.clearable}
        disabled={this.props.readOnly}
        options={this.state.options}
        value={this.props.value}
        onChange={(e, { value }) => this.handleOnSelection(value)}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(TheoreticalRelatedToEditor);
