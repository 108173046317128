import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IwsScoreItemDto, SkillDto } from 'stores/profile/user-profile-store';
import { ScoreSummary, SkillScore } from './user-profile-listing-details/score-summary';
import './employee-page.less';
import './background-employee.less';
import RadarChartComponent, { ComparisonViewModel } from './radar-chart-component';
import { nameof } from 'utils/object';

export interface IwsRadarChartComponentProps extends WithTranslation {
  skills: SkillDto[];
  scoreSummary: ScoreSummary;
}
class IwsRadarChartComponent extends Component<IwsRadarChartComponentProps> {
  static readonly iwsColors = {
    iws1st: '#ff9999',
    iws2nd: '#ffbb99',
    iws3rd: '#7cd6eb'
  };

  generateData(skills: SkillDto[], skillScores: SkillScore[]) {
    let maxPoint = -1;

    const points = skills.map(skill => {
      const skillScore = skillScores.firstOrDefault(score => score.id === skill.skillId);

      const firstAssessmentName = nameof<IwsScoreItemDto>('employeeFirstAssessmentScore');
      const secondAssessmentName = nameof<IwsScoreItemDto>('employeeSecondAssessmentScore');
      const qualifierName = nameof<IwsScoreItemDto>('qualifierScore');

      const p = {
        subject: skill.skillName,
        actual: skillScore ? skillScore.score : 0, //equal to scores[qualifier]
        iws1st: skillScore ? (skillScore.scores[firstAssessmentName].isCompleted ? skillScore.scores[firstAssessmentName].score : 0) : 0,
        iws2nd: skillScore ? (skillScore.scores[secondAssessmentName].isCompleted ? skillScore.scores[secondAssessmentName].score : 0) : 0,
        iws3rd: skillScore ? (skillScore.scores[qualifierName].isCompleted ? skillScore.scores[qualifierName].score : 0) : 0,
        substitute: skillScore ? skillScore.target : 0,
        main: skill.main,
        fullMark: 4
      };
      maxPoint = Math.max(...[maxPoint, p.actual, p.iws1st, p.iws2nd, p.substitute, p.main]);
      return p;
    });
    if (maxPoint <= 0) maxPoint = 4;
    points.forEach(p => (p.fullMark = maxPoint));

    return { points, maxPoint };
  }

  public render() {
    const { t } = this.props;
    const { points: data, maxPoint } = this.generateData(this.props.skills, this.props.scoreSummary.skillScores);

    const comparisonViewModels: ComparisonViewModel[] = [
      {
        name: t('Target'),
        dataKey: 'substitute',
        stroke: '#B777D7',
        fill: '#8884d8',
        fillBorder: 4
      }
    ];
    return (
      <RadarChartComponent
        data={data}
        comparisonViewModels={comparisonViewModels}
        maxPoint={maxPoint}
        extraKeysPrefix="iws"
        extraKeysColors={IwsRadarChartComponent.iwsColors}
        disableActual={true}
      />
    );
  }
}

export default withTranslation()(IwsRadarChartComponent);
