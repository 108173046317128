import { Icon, Popup } from 'semantic-ui-react';
import React from 'react';
import NewRequestView from '../../../../requests/request-wizard';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface CreateRequestButtonProps extends WithTranslation {
  className?: string;
  onCreateRequest?: (isSucess: boolean) => void;
}

class CreateRequestButton extends React.Component<CreateRequestButtonProps> {
  state = {
    showCreationWizard: false
  };

  handleOnButtonClick = () => {
    this.setState({
      showCreationWizard: true
    });
  };

  handleOnRequestWizardClose = (isSucess: boolean) => {
    this.setState({
      showCreationWizard: false
    });
    this.props.onCreateRequest && this.props.onCreateRequest(isSucess);
  };

  render() {
    const t = this.props.t;
    return (
      <>
        <Popup
          content={t('New Request')}
          trigger={
            <button onClick={this.handleOnButtonClick} className={this.props.className}>
              <Icon.Group>
                <Icon name="file alternate" />
                <Icon size="small" circular name="plus circle" />
              </Icon.Group>
            </button>
          }
        />
        {this.state.showCreationWizard && <NewRequestView onClose={this.handleOnRequestWizardClose} />}
      </>
    );
  }
}

export default withTranslation()(CreateRequestButton);
