import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { SimpleUserDto } from 'stores/assessments/forms/tna-forms-store';
import { EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { FormStore } from 'stores/formStore';
import { ValidationFailure } from 'fluent-ts-validator';
import i18n from 'i18n';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { DevelopmentPlanStatus } from './development-plan-status.enum';

export interface TnaDevelopmentPlanGroupingDto {
  locationId: string;
  locationName: string;
  profileName: string;
  eventTypeId: string;
  eventTypeName: string;
  trainingLevelId: string;
  trainingLevelName: string;
  machineModelId: string;
  machineModel: MachineModelDto;
  deadlineYear: number;
  plannedCount: number;
  inProgressCount: number;
  completedCount: number;
  outOfDateCount: number;
}

export interface TnaDevelopmentPlanDto {
  id: string;
  friendlyId: string;
  userId: string;
  user: SimpleUserDto;
  profileItemId: string;
  profileName: string;
  eventTypeId: string;
  eventType: EventTypeDto;
  trainingLevelId: string;
  trainingLevelName: string;
  machineModelId: string;
  machineModel: MachineModelDto;
  deadlineYear: number;
  status: DevelopmentPlanStatus;
  outOfDate: boolean;
}

export interface MachineModelFilters {
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
}

export interface UserFilters {
  employee: string;
  locationId: string;
}

@repository('@@TNADEVELOPMENTPLAN', 'tna-development-plan.summary')
export class TnaDevelopmentPlanStore extends DataStore<TnaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-tna-development-plans';
  updatePath = '';
  deletePath = 'v1/delete-tna-development-plan';

  protected validate(item: TnaDevelopmentPlanDto) {
    return undefined;
  }

  constructor() {
    super('TNADEVELOPMENTPLAN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {}
    });
  }
}

@repository('@@TNADEVELOPMENTPLAN', 'tna-development-plan.grouped-summary')
export class TnaDevelopmentPlanGroupingStore extends DataStore<TnaDevelopmentPlanGroupingDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-tna-development-plans-grouped';
  updatePath = '';
  deletePath = '';

  protected validate(item: TnaDevelopmentPlanGroupingDto) {
    return undefined;
  }

  constructor() {
    super('TNADEVELOPMENTPLANGROUPING', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {}
    });
  }
}

export interface CreateTnaDevelopmentPlanDto {
  profileItemId: string;
  usersId: string[];
  eventTypeId: string;
  machineModelId: string;
  trainingLevelId: string;
  deadlineYear: number;
}

class CreateTnaDevelopmentPlanValidator extends ExtendedAbstractValidator<CreateTnaDevelopmentPlanDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.eventTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Activity is required'));

    this.validateIfString(o => o.machineModelId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Machine Model is required'));

    this.validateIfString(o => o.profileItemId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Profile is required'));

    this.validateIfString(o => o.trainingLevelId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIfIterable(o => o.usersId)
      .isDefined()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Users are required'));

    this.validateIfEachString(o => o.usersId)
      .isDefined()
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t("There's a least a bad UserId"));

    this.validateIfNumber(x => x.deadlineYear)
      .isDefined()
      .isNotNull()
      .isGreaterThan(0)
      .withFailureMessage(i18n.t('Year is required'));
  }
}

@repository('@@TNADEVELOPMENTPLAN', 'tna-development-plan.new')
export class NewTnaDevelopmentPlanStore extends FormStore<CreateTnaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = 'v1/create-tna-development-plan';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateTnaDevelopmentPlanDto) {
    return new CreateTnaDevelopmentPlanValidator().validate(item);
  }

  constructor() {
    super('NEW_PROFILE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

export interface UpdateTnaDevelopmentPlanDto {
  id: string;
  nextStatus: DevelopmentPlanStatus;
}

class UpdateTnaDevelopmentPlanValiador extends ExtendedAbstractValidator<UpdateTnaDevelopmentPlanDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TNA DEvelopment Id is required'));

    this.validateIf(o => o.nextStatus)
      .isNotEmpty()
      .isDefined()
      .withFailureMessage(i18n.t('Status is required'));
  }
}

@repository('@@TNADEVELOPMENTPLAN', 'tna-development-plan.change')
export class ChangeTnaDevelopmentPlanStore extends FormStore<UpdateTnaDevelopmentPlanDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = '';
  updatePath = 'v1/update-tna-development-plan';

  protected validate(item: UpdateTnaDevelopmentPlanDto) {
    return new UpdateTnaDevelopmentPlanValiador().validate(item);
  }

  constructor() {
    super('CHANGE_PROFILE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
