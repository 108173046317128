import { DeliveryMethodSfMapsStore, NewDeliveryMethodSfMapStore, ChangeDeliveryMethodSfMapStore } from './delivery-method-sf-map-store';
import { EventTypeSfMapsStore, NewEventTypeSfMapStore, ChangeEventTypeSfMapStore } from './event-type-sf-map-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new DeliveryMethodSfMapsStore());
  storeBuilder.addRepository(new NewDeliveryMethodSfMapStore());
  storeBuilder.addRepository(new ChangeDeliveryMethodSfMapStore());

  storeBuilder.addRepository(new EventTypeSfMapsStore());
  storeBuilder.addRepository(new NewEventTypeSfMapStore());
  storeBuilder.addRepository(new ChangeEventTypeSfMapStore());
}
