import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { nameof } from 'utils/object';
import { connect } from 'redux-scaffolding-ts';

import { NMClustersStore, NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import {
  NMFunctionalAreasStore,
  NMFunctionalAreaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMTrainingNamesStore, NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import {
  NMFunctionalSubareasStore,
  NMFunctionalSubareaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';

export interface NMrTagsFiltersValues {
  nmClusters: string[];
  functionalArea: string[];
  trainingName: string[];
  functionalSubarea: string[];
}

export interface NMRTagsFiltersProps extends WithTranslation {
  className?: string;
  placeholder?: string;
  nmClustersStore?: NMClustersStore;
  functionalAreasStore?: NMFunctionalAreasStore;
  trainingNamesStore?: NMTrainingNamesStore;
  functionalSubAreasStore?: NMFunctionalSubareasStore;
  onChange?: (value: NMrTagsFiltersValues) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

export interface NMRTagsFiltersOptions {
  id: string;
  text: string;
  type: string;
  value: any;
}

interface MRMachineryTagsFiltersState {
  open: boolean;
  isLoading: boolean;
  options: { [id: string]: NMRTagsFiltersOptions };
}
@connect(
  ['nmClustersStore', NMClustersStore],
  ['functionalAreasStore', NMFunctionalAreasStore],
  ['trainingNamesStore', NMTrainingNamesStore],
  ['functionalSubAreasStore', NMFunctionalSubareasStore]
)
class MultiSelectorNMRFilters extends Component<NMRTagsFiltersProps, MRMachineryTagsFiltersState> {
  state: MRMachineryTagsFiltersState = {
    open: false,
    isLoading: true,
    options: {}
  };

  private handleOnSearchChange(event, searchQuery) {
    this.setState({ open: !!searchQuery });
  }

  private handleOnOptionChange(value: any) {
    let values = this.nmrTagsFiltersValues(value);
    this.setState({ open: false });
    this.props.onChange && this.props.onChange(values);
  }

  private nmrTagsFiltersValues(value: any): NMrTagsFiltersValues {
    let NMrTagsFiltersValues: NMrTagsFiltersValues = {
      nmClusters: [],
      functionalArea: [],
      trainingName: [],
      functionalSubarea: []
    };

    (value || []).forEach(key => {
      let option = this.state.options[key];
      NMrTagsFiltersValues[option.type].push(option.value?.id ?? option.value);
    });

    return NMrTagsFiltersValues;
  }

  private toDropdownOptions() {
    return Object.keys(this.state.options).map(key => {
      let option = this.state.options[key];
      return {
        text: option.text,
        value: key
      };
    });
  }

  componentDidMount() {
    const nmClusters = this.getAllNMrClusters();
    const functionalArea = this.getAllfunctionalAreas();
    const trainingName = this.getAllTrainingName();
    const functionalSubArea = this.getAllFunctionalSubarea();

    let requests: any = [nmClusters, functionalArea, trainingName, functionalSubArea];

    Promise.all([...requests]).then(([nmClustersResponse, functionalArea, trainingNameResponse, functionalSubareaResponse]) => {
      let allOptions = {};

      this.mapNmClusters(nmClustersResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapFunctionalArea(functionalArea.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.maptrainingName(trainingNameResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));
      this.mapFunctionalSubarea(functionalSubareaResponse.items).forEach(option => (allOptions[this.buildKey(option)] = option));

      this.setState({
        isLoading: false,
        options: allOptions
      });
    });
  }

  private mapNmClusters(clusters: NMClusterDto[]): NMRTagsFiltersOptions[] {
    return clusters.map(cluster => ({
      value: cluster.id,
      text: cluster.name,
      id: cluster.id,
      type: nameof<NMrTagsFiltersValues>('nmClusters')
    }));
  }

  private mapFunctionalArea(FunctionalAreas: NMFunctionalAreaDto[]): NMRTagsFiltersOptions[] {
    return FunctionalAreas.map(functionalArea => ({
      value: functionalArea.id,
      text: functionalArea.name,
      id: functionalArea.id,
      type: nameof<NMrTagsFiltersValues>('functionalArea')
    }));
  }

  private maptrainingName(trainingNames: NMTrainingNameDto[]): NMRTagsFiltersOptions[] {
    return trainingNames.map(trainingName => ({
      value: trainingName.id,
      text: trainingName.name,
      id: trainingName.id,
      type: nameof<NMrTagsFiltersValues>('trainingName')
    }));
  }

  private mapFunctionalSubarea(functionalSubareas: NMFunctionalSubareaDto[]): NMRTagsFiltersOptions[] {
    return functionalSubareas.map(functionalSubarea => ({
      value: functionalSubarea.id,
      text: functionalSubarea.name,
      id: functionalSubarea.id,
      type: nameof<NMrTagsFiltersValues>('functionalSubarea')
    }));
  }

  private buildKey(option: NMRTagsFiltersOptions): string {
    return `${option.id}-${option.type}`;
  }

  private getAllNMrClusters = () => {
    return this.props.nmClustersStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<NMClusterDto>('name'), useProfile: false }],
      filter: []
    });
  };

  private getAllfunctionalAreas() {
    return this.props.functionalAreasStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<NMFunctionalAreaDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllTrainingName() {
    return this.props.trainingNamesStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<NMTrainingNameDto>('name'), useProfile: false }],
      filter: []
    });
  }

  private getAllFunctionalSubarea = () => {
    return this.props.functionalSubAreasStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Descending', field: nameof<NMFunctionalSubareaDto>('name'), useProfile: false }],
      filter: []
    });
  };

  render() {
    const { placeholder, className } = this.props;

    const dropdownOptions = this.toDropdownOptions();

    return (
      <Dropdown
        placeholder={placeholder}
        className={className}
        loading={this.state.isLoading}
        multiple
        search
        selection
        icon="search"
        open={this.state.open}
        onSearchChange={(event, { searchQuery }) => this.handleOnSearchChange(event, searchQuery)}
        onChange={(event, { value }) => this.handleOnOptionChange(value)}
        options={dropdownOptions}
        selectOnBlur={false}
        closeOnBlur
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

export default withTranslation()(MultiSelectorNMRFilters);
