import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Component } from 'react';
import { resolve } from 'inversify-react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { isUserEventOwner, isCurrentUserEventInstructor, isCurrentUserEventLeadInstructor } from 'utils/event-utils';
import { IdentityService, Roles } from 'services/identity-service';
import { EventFormStore } from 'stores/events/event-form-store';
import {
  EventTheoreticalTabStore,
  EventTheoreticalTemplateDto,
  NewTheoreticalFormUserStore,
  CreateTheoreticalFormDto,
  SimpleTheoreticalFormAnswerDto
} from 'stores/events/theoretical-form-store';
import TheoreticalTable from './theoretical-table-form';
import { openInNewWindow } from 'utils/useful-functions';
import TheoreticalEmployeeTable from './theoretical-table-form-employee-view';
import './theoretical-tab.less';

interface TheoreticalTabProps extends WithTranslation {
  eventFormStore?: EventFormStore;
  theoreticalStore?: EventTheoreticalTabStore;
  newtheoreticalStore?: NewTheoreticalFormUserStore;
}
export interface TheoreticalTabState {
  showConfirmTheoreticalModal: boolean;
  theoreticalFormIdToGenerate: string;
  theoreticalFormTitleToGenerate: string;
  templateSelected: EventTheoreticalTemplateDto;
  loading: boolean;
  showDeleteConfirmModal: boolean;
  showResendConfirmModal: boolean;
  selectedTheoretical: SimpleTheoreticalFormAnswerDto;
}

@connect(
  ['eventFormStore', EventFormStore],
  ['theoreticalStore', EventTheoreticalTabStore],
  ['newtheoreticalStore', NewTheoreticalFormUserStore]
)
class TheoreticalTab extends Component<TheoreticalTabProps, TheoreticalTabState> {
  state: TheoreticalTabState = {
    showConfirmTheoreticalModal: false,
    theoreticalFormIdToGenerate: null,
    theoreticalFormTitleToGenerate: null,
    templateSelected: null,
    loading: false,
    showDeleteConfirmModal: false,
    showResendConfirmModal: false,
    selectedTheoretical: null
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isCurrentUserEventOwnerOrInstructorOrAdminOrPoc = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      isUserEventOwner(event) ||
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventInstructor(event)) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.PoC)
    );
  };

  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const { eventFormStore, theoreticalStore } = this.props;
    const { id, status } = eventFormStore.state.item;
    try {
      //theoreticalStore.state.items = [];
      await theoreticalStore.getTemplatesByEventId(id, '' + status);
    } catch (error) {
      console.error({ error });
    }
  };

  private isEventClosed = (): boolean => {
    return this.props.eventFormStore.state.item.status.toString() === 'Closed';
  };

  private isCurrentUserEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  private userCanDelete = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return (
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventLeadInstructor(event)) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.Planner) ||
      this.identityService.isInRole(Roles.PlannerMTC)
    );
  };

  private getCurrentUserId = (): string => {
    const { userId } = this.identityService.getUserInfo();
    return userId;
  };

  private closeConfirmModal = () => {
    this.setState({ showConfirmTheoreticalModal: false });
  };

  private onGenerateTheoreticalForm = async () => {
    this.setState({ showConfirmTheoreticalModal: false, loading: true });
    const { eventFormStore, theoreticalStore, newtheoreticalStore } = this.props;
    const eventId = eventFormStore.state.item.id;
    const storedData = theoreticalStore.state.items.find(({ item }) => item.id === this.state.theoreticalFormIdToGenerate);

    if (!storedData.item) return;
    const newItem: CreateTheoreticalFormDto = {
      templateId: storedData.item.id,
      typeRelated: 'Event',
      eventDetails: { eventId: eventId }
    };

    newtheoreticalStore.createNew(newItem);
    try {
      const newForms = await this.props.newtheoreticalStore.createForms();
      if (newForms.isSuccess) {
        this.props.theoreticalStore.state.items = [];
        this.load();
      }

      this.setState({ loading: false });
    } catch (error) {
      console.error({ error });
      this.setState({ loading: false });
    }
  };

  private onOpenEmployeeTheoreticalForm = (form: SimpleTheoreticalFormAnswerDto) => {
    openInNewWindow(`./assessments/theoretical-test/${form.id}`);
    this.setState({ templateSelected: null });
  };

  private showDeleteConfirmationModal = (form: SimpleTheoreticalFormAnswerDto) => {
    this.setState({ selectedTheoretical: form, showDeleteConfirmModal: true });
  };

  private showResendConfirmationModal = (form: SimpleTheoreticalFormAnswerDto) => {
    this.setState({ selectedTheoretical: form, showResendConfirmModal: true });
  };

  private onDelete = async (id: string) => {
    this.hideConfirmModal();
    const deleteResponse = await this.props.theoreticalStore.deleteAsync(id);

    if (deleteResponse.isSuccess) {
      this.props.theoreticalStore.state.items = [];
      this.load();
    }
  };

  private onResend = async (id: string) => {
    this.hideConfirmResendModal();
    const resendResponse = await this.props.theoreticalStore.resendTheoreticalFormById(id);

    if (resendResponse.isSuccess) {
      this.props.theoreticalStore.state.items = [];
      this.load();
    }
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false, selectedTheoretical: null });
  };

  private hideConfirmResendModal = () => {
    this.setState({ showResendConfirmModal: false, selectedTheoretical: null });
  };

  private handleGenerateForms = (templateId: string, title: string) => {
    this.setState({ theoreticalFormIdToGenerate: templateId, showConfirmTheoreticalModal: true, theoreticalFormTitleToGenerate: title });
  };

  private isFormCompleted = generatedState => generatedState === 'Completed';

  public render() {
    const { t, theoreticalStore, newtheoreticalStore } = this.props;
    const { items } = theoreticalStore.state;
    const {
      theoreticalFormTitleToGenerate,
      showConfirmTheoreticalModal,
      loading,
      showDeleteConfirmModal,
      showResendConfirmModal,
      selectedTheoretical
    } = this.state;
    const currentUserId = this.getCurrentUserId();
    const isUserEmployee = this.isCurrentUserEmployee();
    const isEventOwnerOrInstructorOrAdminOrPoc = this.isCurrentUserEventOwnerOrInstructorOrAdminOrPoc();
    const isEventClosed = this.isEventClosed();

    return (
      <>
        <Dimmer
          active={theoreticalStore.state.isBusy || newtheoreticalStore.state.isBusy || loading}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}
        >
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <div className="form__theoretical-container">
          {(items || [])

            .sort((x, y) => {
              const v1 = this.isFormCompleted(x.item.generatedState);
              const v2 = this.isFormCompleted(y.item.generatedState);

              const v3 = x.item.theoreticalFormAnswers.length <= 0;
              const v4 = y.item.theoreticalFormAnswers.length <= 0;

              if (v3 && v4) return 0;
              if (v3 && !v4) return -1;
              if (!v3 && v4) return 1;
              if (v1 === v2) return 0;
              return v1 ? 1 : -1;
            })

            .map(({ item }) => {
              const { title, theoreticalFormAnswers, id, generatedState } = item;
              const theoreticalTypeClass =
                (theoreticalFormAnswers || []).length > 0
                  ? 'form__theoretical-item flex-column'
                  : 'form__theoretical-item flex-start-center';
              return (
                <>
                  {isEventOwnerOrInstructorOrAdminOrPoc && (theoreticalFormAnswers || []).length > 0 && (
                    <div key={id} className={theoreticalTypeClass}>
                      <div className="flex-start-center">
                        <p className="theoretical-tab__item-title">{title}</p>

                        {generatedState ? (
                          <p className="theoretical-tab__item__status-flag">{`Generated: ${generatedState.toString().toUpperCase()}`}</p>
                        ) : null}
                      </div>

                      <TheoreticalTable
                        templateId={id}
                        theoreticalFormAnswers={theoreticalFormAnswers || []}
                        onOpenEmployeeTheoreticalForm={this.onOpenEmployeeTheoreticalForm}
                        isEventClosed={isEventClosed}
                        showDeleteConfirmationModal={this.showDeleteConfirmationModal}
                        showResendConfirmationModal={this.showResendConfirmationModal}
                        userCanDeleteAndResend={this.userCanDelete()}
                      />
                    </div>
                  )}

                  {isEventOwnerOrInstructorOrAdminOrPoc &&
                    item &&
                    !isEventClosed &&
                    (!theoreticalFormAnswers || theoreticalFormAnswers.length <= 0) && (
                      <div key={id} className={theoreticalTypeClass}>
                        <p className="theoretical-tab__item-title">{title}</p>
                        <Button onClick={() => this.handleGenerateForms(id, title)}>{t('Generate Test')}</Button>
                      </div>
                    )}

                  {isUserEmployee &&
                    !isEventOwnerOrInstructorOrAdminOrPoc &&
                    (theoreticalFormAnswers || [])
                      .filter(ans => !ans.answered || ans.totalTime === '')
                      .map(answer => (
                        <div key={id} className="form__theoretical-item flex-start-center">
                          <p className="theoretical-tab__item-title">{title}</p>
                          <Button
                            disabled={!!answer.startedAt}
                            onClick={() => this.onOpenEmployeeTheoreticalForm(answer)}
                            className="theoretical-generation__answered-btn"
                          >
                            {t('Answer Test')}
                          </Button>
                        </div>
                      ))}

                  {isUserEmployee &&
                    !isEventOwnerOrInstructorOrAdminOrPoc &&
                    (theoreticalFormAnswers || []).filter(ans => ans.answered || (!ans.answered && ans.totalTime !== '')).length > 0 && (
                      <>
                        <div className="flex-start-center">
                          <p className="theoretical-tab__item-title text__bold">{title}</p>
                          {generatedState ? (
                            <p className="theoretical-tab__item__status-flag">{`Generated: ${generatedState.toString().toUpperCase()}`}</p>
                          ) : null}
                        </div>

                        <TheoreticalEmployeeTable
                          currentUserId={currentUserId}
                          theoreticalFormAnswers={(theoreticalFormAnswers || []).filter(
                            ({ answered, totalTime }) => answered || (!answered && totalTime !== '')
                          )}
                        />
                      </>
                    )}
                </>
              );
            })}

          {showConfirmTheoreticalModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              centered
              open={showConfirmTheoreticalModal}
              size="mini"
              className="have-warnings-popup theoretical__confirm-modal"
              closeOnEscape={true}
              onClose={this.closeConfirmModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>
                  {t(
                    `
                    You are going to generate Theoretical test "${theoreticalFormTitleToGenerate}". Are you sure you want to generate it?
                    `
                  )}
                </p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="theoretical-modal__cancel-button" content={t('Cancel')} onClick={this.closeConfirmModal} />
                <Button
                  className="have-warnings-popup__btn theoretical-modal__accept-button"
                  content={t('Accept')}
                  onClick={this.onGenerateTheoreticalForm}
                />
              </div>
            </Modal>
          )}

          {showDeleteConfirmModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              open={showDeleteConfirmModal}
              size="mini"
              centered
              className="have-warnings-popup theoretical__confirm-modal"
              closeOnEscape={true}
              onClose={this.hideConfirmModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>{t(`This theoretical test will be deleted. Are you sure that you want to delete this item?`)}</p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="theoretical-modal__cancel-button" content={t('No')} onClick={this.hideConfirmModal} />
                <Button
                  className="have-warnings-popup__btn theoretical-modal__accept-button"
                  content={t('Yes')}
                  onClick={() => this.onDelete(selectedTheoretical?.id)}
                />
              </div>
            </Modal>
          )}

          {showResendConfirmModal && (
            <Modal
              style={{ transform: 'translateY(35vh)' }}
              open={showResendConfirmModal}
              size="mini"
              centered
              className="have-warnings-popup theoretical__confirm-modal"
              closeOnEscape={true}
              onClose={this.hideConfirmResendModal}
            >
              <Modal.Content className="have-warnings-popup__content">
                <p>
                  {t(
                    `This theoretical test will be re-send, the test results will be deleted, and the student should start and do it again. Are you sure that you want to re-send this item?`
                  )}
                </p>
              </Modal.Content>
              <div className="have-warnings-popup__buttons-container">
                <Button className="theoretical-modal__cancel-button" content={t('No')} onClick={this.hideConfirmResendModal} />
                <Button
                  className="have-warnings-popup__btn theoretical-modal__accept-button"
                  content={t('Yes')}
                  onClick={() => this.onResend(selectedTheoretical?.id)}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(TheoreticalTab);
