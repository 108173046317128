import React from 'react';

import { Grid, Loader } from 'semantic-ui-react';
import i18n from '../i18n';

export const FullPageLoader: React.FC<{ text: string }> = ({ text }) => (
  <Grid verticalAlign="middle" centered style={{ height: '100vh', margin: 0 }}>
    <Grid.Row>
      <Grid.Column>
        <Loader active size="huge">
          {i18n.t(text || 'Loading')}
        </Loader>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
