import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon, Label, Input, Message } from 'semantic-ui-react';
import { DateTimeService } from 'services/datetime-service';
import EventFormHeaderEventDates from './event-form-header-event-dates';
import LocationEditor from 'widgets/bussiness/location-editor';
import PriorityEditor from 'widgets/bussiness/priority-editor';
import { EventFormStore, EventFormViewModel, EventDetailsViewModel } from 'stores/events/event-form-store';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import { faStar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'redux-scaffolding-ts';
import PausePeriodList from '../../pause-period-list';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import { DurationInput } from 'widgets/form/durationInput';
import EventService, { DatesCalculationRequestDto, EventDurationRequestDto } from 'stores/events/event-service';
import { replaceAll, trim, isNullOrWhiteSpaces } from 'utils/useful-functions';
import { Message as BackendMessage } from 'stores/types';
import { Moment } from 'moment';
import { EventFormMode } from '../event-form';

export interface EventFormHeaderProps extends WithTranslation {
  readOnly: boolean;
  eventFormStore?: EventFormStore;
  recalculating: (boolean: boolean) => void;
  mode: EventFormMode;
}
export interface EventFormState {
  startDate: Moment;
  endDate: Moment;
  plannedDuration: string;
  warnings: { type: 'DURATION' | 'CALCULATION'; text: string }[];
}

@connect(['eventFormStore', EventFormStore])
class EventFormHeader extends Component<EventFormHeaderProps, EventFormState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  @resolve(EventService)
  eventsService: EventService;

  constructor(props: EventFormHeaderProps) {
    super(props);
    const { t, eventFormStore: store, recalculating } = props;
    const event = store.state.item;

    const warnings = [];
    if (event.calculatedEventDurationHours === 0) {
      warnings.push({
        type: 'DURATION',
        text: t('There is no default calculated duration for this event')
      });
    }
    const startDate = DateTimeService.toMoment(event?.startDate);
    const endDate = DateTimeService.toMoment(event?.endDate);
    this.state = { warnings, startDate, endDate, plannedDuration: event?.plannedDuration };
    recalculating(false);
  }

  componentDidMount() {
    const { eventFormStore, mode, readOnly } = this.props;
    const { calculatedEventDurationHours: cEDH, eventDetails } = eventFormStore.state.item;
    if (!cEDH && (!!eventDetails || mode === 'Merge' || mode === 'Preview')) {
      this.getEventDurationFromSystem();
      if (!(readOnly && mode === 'ViewDetails')) this.performCalculation('Duration', false);
    }
  }

  componentDidUpdate({ readOnly: prevRO }: EventFormHeaderProps) {
    const { eventFormStore, readOnly } = this.props;
    const { shouldRecalculate, calculatedEventDurationHours, eventDetails } = eventFormStore.state.item;
    if (shouldRecalculate) {
      this.getEventDurationFromSystem();
      eventFormStore.change({ shouldRecalculate: false });
    }
    if (prevRO !== readOnly && !readOnly && calculatedEventDurationHours === 0 && !!eventDetails) {
      this.performCalculation('Duration', false);
      this.getEventDurationFromSystem();
    }
  }

  private getDurationRequestDto = (): EventDurationRequestDto => {
    const { eventFormStore: store } = this.props;
    const data = store.state.item;
    return {
      isMachineRelated: data.eventDetails?.machineRelated,
      mrRequest: data.eventDetails?.machineRelated
        ? {
            originalEventTypeId: data.eventType?.originalEventTypeId,
            professionId: data.eventDetails?.profession?.id,
            rows: (data.eventDetails?.machines || []).map(rm => ({
              clusterId: rm.cluster?.id,
              equipmentTypeId: rm.equipmentType?.id,
              machineModelId: rm.machineModel?.id,
              machineUnitsId: rm.machineUnits,
              oemId: rm.oem?.id
            })),
            trainingLevelId: data.trainingDetails?.trainingLevel?.id
          }
        : null,
      nmrRequest: !data.eventDetails?.machineRelated
        ? {
            nmrClusterId: data.eventDetails?.nmrCluster?.id,
            nmrFunctionalAreaId: data.eventDetails?.nmrFunctionalArea?.id,
            nmrTrainingNameId: data.eventDetails?.nmrTrainingName?.id
          }
        : null
    };
  };

  private getDatesCalculationRequestDto = (recalculate: 'StartDate' | 'EndDate' | 'Duration'): DatesCalculationRequestDto => {
    const { eventFormStore: store } = this.props;
    const data = store.state.item;
    return {
      startDate: recalculate === 'StartDate' ? null : data.startDate,
      endDate: recalculate === 'EndDate' ? null : data.endDate,
      durationDays: recalculate === 'Duration' ? null : parseInt(trim(replaceAll(data?.plannedDuration || '', 'w/d', '')) || '0'),
      locationId: data.eventDetails?.location?.id,
      pausePeriods: data.pausePeriods || []
    };
  };

  private getEventDurationFromSystem = () => {
    const dto = this.getDurationRequestDto();
    const { recalculating, eventFormStore, t } = this.props;
    recalculating(true);
    this.eventsService
      .calculateEventDuration(dto)
      .then(resp => {
        try {
          const v = resp;
          const calculatedEventDurationHours = v.hours;
          const changes: Partial<EventFormViewModel> = { calculatedEventDurationHours };
          if (isNullOrWhiteSpaces(eventFormStore.state.item?.plannedDuration)) changes.plannedDuration = `${v}`;
          this.setState(
            ({ warnings }) => ({ warnings: [...warnings.filter(x => x.type !== 'DURATION')] }),
            () => eventFormStore.change({ ...changes })
          );
        } catch (error) {
          const type = 'DURATION';
          const text = t('There is no default calculated duration for this event');
          this.setState({ warnings: [{ type, text }, ...this.state.warnings.filter(x => x.type !== 'DURATION')] }, () =>
            eventFormStore.change({ calculatedEventDurationHours: 0 })
          );
        }
        recalculating(false);
      })
      .catch(err => {
        recalculating(false);
      });
  };

  private performCalculation = (recalculate: 'StartDate' | 'EndDate' | 'Duration', setValues = true) => {
    const dto = this.getDatesCalculationRequestDto(recalculate);
    const { recalculating, eventFormStore } = this.props;
    recalculating(true);
    this.eventsService
      .calculateEventDates(dto)
      .then(v => {
        const stateChanges: any = {};
        const data: EventFormViewModel = { ...eventFormStore.state.item };
        if (v?.isSuccess && v?.item != null && setValues) {
          data.shouldRecalculate = false;
          data.startDate = v.item.startDate;
          data.endDate = v.item.endDate;
          data.plannedDuration = '' + v.item.durationDays;

          stateChanges.startDate = DateTimeService.toMoment(v.item.startDate);
          stateChanges.endDate = DateTimeService.toMoment(v.item.endDate);
          stateChanges.plannedDuration = '' + v.item.durationDays;
        }
        stateChanges.warnings = [
          ...this.state.warnings.filter(x => x.type !== 'CALCULATION'),
          ...(v?.messages || []).map(x => ({ text: x.body, type: 'CALCULATION' } as { type: 'DURATION' | 'CALCULATION'; text: string }))
        ];
        this.setState({ ...stateChanges }, () => {
          this.props.eventFormStore.change(data);
          recalculating(false);
        });
      })
      .catch(e => {
        this.setState(
          ({ warnings }) => ({
            warnings: [
              ...warnings.filter(x => x.type !== 'CALCULATION'),
              ...(e?.response?.data?.messages || []).map((x: BackendMessage) => ({ text: x.body, type: 'CALCULATION' }))
            ]
          }),
          () => recalculating(false)
        );
      });
  };

  private handleOnChange = (property: keyof EventFormViewModel, value: any) => {
    const { plannedDuration } = this.state;
    const { eventFormStore } = this.props;
    let changes: Partial<EventFormViewModel> = { fromScheduler: false };
    changes[property as string] = value;
    if (DateTimeService.isCoherent(value) && (property === 'startDate' || property === 'endDate')) {
      const dateMoment = DateTimeService.toMoment(value);
      const current = this.state[property];
      if (property === 'startDate' && dateMoment.isAfter(this.state.endDate)) changes.endDate = value;
      if (dateMoment && current.diff(dateMoment, 'days') !== 0) changes.shouldRecalculate = true;
      else changes.shouldRecalculate = false;
    }
    if (property === 'plannedDuration') {
      if (plannedDuration !== value) changes.shouldRecalculate = true;
      else changes.shouldRecalculate = false;
    }

    eventFormStore.change(changes);
  };

  private handleOnEventDetailsChange = (property: keyof EventDetailsViewModel, value: any) => {
    let eventDetails = { ...this.props.eventFormStore.state.item?.eventDetails };
    eventDetails[property as string] = value;

    this.props.eventFormStore.change({ eventDetails });
  };

  render() {
    const { t, eventFormStore } = this.props;
    const { item, result } = eventFormStore.state;
    const parentRequest = item.parentRequest;
    const readOnly =
      this.props.readOnly ||
      (this.identityService.activeRole === 'Instructor' &&
        (item.status.toString() === 'InProgress' || item.status.toString() === 'Completed'));

    const messages = (result && result.messages) || [];
    const { warnings } = this.state;

    var roleLocations: string[];
    const currentUser = this.identityService.getUserInfo();
    const isRoleWithLocations = IdentityService.isRoleWithLocations(currentUser);

    if (isRoleWithLocations) {
      roleLocations = currentUser.locationsByRoles[currentUser.activeRole] as string[];
    }

    const canRecalculatePlannedDuration = !readOnly && !isNullOrWhiteSpaces(item.endDate) && !isNullOrWhiteSpaces(item.startDate);

    return (
      <div className="form__modal__header-content">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body).filter(x => x !== '')}
            onDismiss={eventFormStore.clearMessages}
          />
        )}
        <div className="form__header__data recalc-container">
          <div className="form__header__first-row__event-type flex-start">
            <Label
              className="first-row__event-type__circle"
              circular
              size="mini"
              style={{ backgroundColor: item.eventType.eventsColor }}
              key={item.eventType.eventsColor}
            ></Label>
            <p className="">{t(item.eventType.name)}</p>
          </div>
          <div className="form__header__row form__header__second-row__dates">
            <Icon className="form__header__row-icon" name="calendar" />
            <div className="flex-wrap">
              {parentRequest?.startDate && parentRequest?.endDate && item.status.toString() === 'Draft' && (
                <div className="form__header__second-row__requested-date-slot">
                  <div className="generic-date-picker__wrapper">
                    <p className="form__header__text-disable">{t('Requested Date Slot')}</p>
                    <Input
                      className="date-picker__left"
                      readOnly
                      disabled
                      value={DateTimeService.toDateInputString(parentRequest.startDate)}
                    />
                    <Input
                      className="date-picker__right"
                      readOnly
                      disabled
                      value={DateTimeService.toDateInputString(parentRequest.endDate)}
                    />
                  </div>
                </div>
              )}
              <EventFormHeaderEventDates
                readOnly={readOnly}
                startDate={item.startDate}
                endDate={item.endDate}
                onStartDateChange={startDate => this.handleOnChange('startDate', startDate)}
                onEndDateChange={endDate => this.handleOnChange('endDate', endDate)}
                onRecalculate={this.performCalculation}
                hasDuration={!isNullOrWhiteSpaces(item.plannedDuration)}
              />
            </div>
          </div>
          <div
            className={`form__header__row form__header__third-row__durations ${
              !readOnly && (warnings || []).length !== 0 ? 'fix-margin' : ''
            }`}
          >
            <Icon className="form__header__row-icon" name="clock" />
            <div className="flex-wrap">
              {parentRequest && item.status.toString() === 'Draft' && (
                <div className="form__header__third-row__durations__element">
                  <p className="form__header__text-disable">{t('Request Duration')}</p>
                  <Input
                    label={{ basic: true, content: 'w/d' }}
                    className="form__duration-field-numeric form__duration-field-numeric__wd"
                    disabled
                    value={`${parentRequest.desiredEventDuration}`}
                    labelPosition="right"
                  />
                </div>
              )}
              <div className="form__header__third-row__durations__element recalc-container">
                <p>{t('Event Duration')}</p>
                <Input className="form__planned-duration__numeric__wd" type="number">
                  <DurationInput
                    onChange={value => this.handleOnChange('plannedDuration', value)}
                    value={`${item.plannedDuration}`}
                    disabled={readOnly}
                  />
                </Input>
                {canRecalculatePlannedDuration && (
                  <span id="recalc-event-duration" className="recalc-command" onClick={() => this.performCalculation('Duration')}>
                    {t('Recalculate')}
                  </span>
                )}
              </div>
              <div className="form__header__third-row__durations__element recalc-container" style={{ marginLeft: 20 }}>
                <p>{t('Calculated Duration')}</p>
                <Input
                  label={{ basic: true, content: 'w/d' }}
                  className="form__duration-field-numeric form__duration-field-numeric__wd"
                  disabled
                  value={`${item?.calculatedEventDurationHours ? Math.round(item.calculatedEventDurationHours / 8) : 0}`}
                  labelPosition="right"
                />
              </div>
            </div>
          </div>
          {!readOnly && (warnings || []).length !== 0 && (
            <div className="event-duration-warnings-container">
              <Label className="modal__action-warning wizard__action-warning">
                <Icon name="exclamation triangle" />
                <div>
                  {(warnings || []).map(
                    ({ text }, i) =>
                      text != null && (
                        <p key={text + i} className="modal__action-warning__text">
                          {text}
                        </p>
                      )
                  )}
                </div>
              </Label>
            </div>
          )}
          {item.hasRequestDetails && (item.eventDetails?.location || !readOnly) && (
            <FormRow label={t('Event Location')} icon={faMapMarkerAlt}>
              <LocationEditor
                readOnly={readOnly}
                className="form__header__dropdown-style"
                value={item.eventDetails?.location}
                locationsReceived={roleLocations}
                onChange={location => this.handleOnEventDetailsChange('location', location)}
                showCountry={true}
                loadDataOnOpen
              />
            </FormRow>
          )}
          {item.hasRequestDetails && (item.eventDetails?.priority || !readOnly) && (
            <FormRow label={t('Priority')} icon={faStar}>
              <PriorityEditor
                readOnly={readOnly}
                className="form__header__dropdown-style"
                nullable
                value={item.eventDetails?.priority}
                onChange={priority => this.handleOnEventDetailsChange('priority', priority)}
                loadDataOnOpen
              />
            </FormRow>
          )}
          <PausePeriodList
            onEditMode={!this.props.readOnly}
            onChange={periods => this.handleOnChange('pausePeriods', periods)}
            pausePeriods={item.pausePeriods || []}
            startDate={item.startDate}
            endDate={item.endDate}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(EventFormHeader);
