import React, { FC, memo } from 'react';
import { EventDto } from 'stores/events/events-store';
import { withTranslation, WithTranslation } from 'react-i18next';
import SchedulerEventStatusIcon from './scheduler-event-status-icon';
import { decamelCase } from 'utils/event-utils';
import { DateTimeService } from 'services/datetime-service';

export interface SchedulerEventTooltipProps extends WithTranslation {
  event: EventDto;
}

const SchedulerEventTooltip: FC<SchedulerEventTooltipProps> = ({ event, t }) => {
  if (!event) return;
  const { eventDetails, eventTrainingDetails, eventType, friendlyId, status, title, ...rest } = event;
  const { startDate, endDate, plannedDuration, instructors } = rest;
  const requestedLocation = (event as any)?.requestLocation || event.requests[0]?.requestLocation;
  const conditionalSection = eventDetails?.profession || eventTrainingDetails?.assignedStudents || eventTrainingDetails?.customizationData;
  return (
    <div className="scheduler-tooltip__wrapper">
      <div className="tooltip__header">
        <p className="tooltip__id">{friendlyId}</p>
        <div className="tooltip__header-event-type">
          <style>
            {`.scheduler-tooltip__wrapper .b-fa-circle:before {
               color: ${eventType.eventsColor};
              }
            `}
          </style>
          <i className="b-fa b-fa-circle"></i>
          <p className="first-row__content">{eventType.name}</p>
        </div>
        <p className="tooltip__status">
          <span style={{ marginRight: '0.5rem' }}>{decamelCase(status.toString())}</span>
          <SchedulerEventStatusIcon status={status} />
        </p>
      </div>
      <div className="tooltip__section">
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-file"></i>
          <p className="tooltip__event-code">{title}</p>
        </div>
        {eventDetails?.priority?.name && (
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-star"></i>
            <p className="tooltip__event-code">{eventDetails?.priority?.name}</p>
          </div>
        )}
      </div>
      <div className="tooltip__section">
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-calendar-alt"></i>
          <p className="tooltip__date">
            {DateTimeService.toDateInputString(startDate)} - {DateTimeService.toDateInputString(endDate)}
          </p>
        </div>
        <div className="tooltip__section-item">
          <i className="b-fa b-fa-clock"></i>
          <p className="tooltip__event-duration">{plannedDuration} w/d</p>
        </div>
        {eventTrainingDetails?.deliveryMethod && (
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-graduation-cap"></i>
            <p className="tooltip__delivery-method">{eventTrainingDetails.deliveryMethod.name}</p>
          </div>
        )}
      </div>
      {(eventDetails || requestedLocation) && (
        <div className="tooltip__section">
          {eventDetails?.location && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-map-marker-alt"></i>
              <p className="tooltip__event-location-origin">
                {eventDetails?.location?.location} ({eventDetails?.location?.countryName})
              </p>
            </div>
          )}
          {requestedLocation && (
            <div style={{ marginRight: 8, marginLeft: 8 }} className="tooltip__section-item">
              <i className="b-fa b-fa-map-marked-alt"></i>
              <p className="tooltip__event-location-finish">
                {requestedLocation.location} ({requestedLocation.countryName})
              </p>
            </div>
          )}
        </div>
      )}

      {conditionalSection && (
        <div className="tooltip__section">
          {eventDetails?.profession && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-id-card-alt"></i>
              <p className="tooltip__expertise">{eventDetails.profession.profession}</p>
            </div>
          )}

          {eventTrainingDetails?.numStudentsAssigned > 0 && (
            <div className="tooltip__section-item">
              <i className="b-fa b-fa-users"></i>
              <p className="tooltip__participants">
                {`${eventTrainingDetails.numStudentsAssigned}/${eventTrainingDetails.numStudentsRequested}`} {t('Participants assigned')}
              </p>
            </div>
          )}

          {eventTrainingDetails?.customizationData && (
            <div className="tooltip__section">
              <div className="tooltip__section-item">
                <i className="b-fa b-fa-pencil-ruler"></i>
                <p className="tooltip__instructor-customer">{t('Customization')}</p>
              </div>
            </div>
          )}
        </div>
      )}

      {(instructors || []).map(({ instructor: { firstName, lastName }, period, instructorId, locationId, role }) => (
        <div key={instructorId + locationId} className="tooltip__section">
          <div className="tooltip__section-item">
            <i className="b-fa b-fa-user"></i>
            <p className="tooltip__instructor-info__name">
              {lastName}, {firstName} - {role.toString()} {t('Instructor')} - {DateTimeService.toDateInputString(period.from)}
              {' - '}
              {DateTimeService.toDateInputString(period.to)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default withTranslation()(memo(SchedulerEventTooltip));
