import React, { Component, ReactNode } from 'react';
import { Checkbox, Dimmer, Input, Loader } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
// import eventTypeEditor from 'widgets/bussiness/event-type-editor';

export type MultipleSelectionDropdownOptions<T = string> = {
  id: string;
  value: T;
  text: string;
};

export interface CustomMultiSelectorProps<Type = string> extends WithTranslation {
  placeholder?: string;
  searchable?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  className?: string;
  direction?: 'left' | 'right';
  value: Type[];
  options: MultipleSelectionDropdownOptions<Type>[];
  optionRenderer?: (option: MultipleSelectionDropdownOptions<Type>) => ReactNode;
  onChange?: (value: Type[]) => void;
  onOpenDropDown?: () => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

export type CustomMultiSelectorState = {
  expanded: boolean;
  selectionOrder: string[];
  selected: { [id: string]: boolean };
  search: string;
};

class MultipleSelectionDropdown<Type = string> extends Component<CustomMultiSelectorProps<Type>, CustomMultiSelectorState> {
  static defaultProps: Partial<CustomMultiSelectorProps> = { direction: 'left' };
  constructor(props: CustomMultiSelectorProps<Type>) {
    super(props);

    this.state = {
      expanded: false,
      selected: this.initSelectionMap(props.options),
      selectionOrder: [],
      search: ''
    };
  }

  dropdown: HTMLDivElement;

  initSelectionMap = (options: MultipleSelectionDropdownOptions<Type>[]): { [id: string]: boolean } =>
    (options || []).reduce((prev, { id }) => {
      prev[id] = false;
      return prev;
    }, {});

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = ({ target }) => {
    if (this.dropdown && !this.dropdown.contains(target)) this.setState({ expanded: false });
  };

  getSelectionOptionsCount = () => Object.keys(this.state.selected || {}).filter(x => this.state.selected[x]).length;

  handleOnOptionClick = (_, option: MultipleSelectionDropdownOptions<Type>): void => {
    let { selectionOrder, selected } = this.state;
    let newValue = this.props.value;

    if (selected[option.id]) {
      newValue = newValue.filter(x => option.value !== x);
      selectionOrder = selectionOrder.filter(x => x !== option.id);
    } else {
      newValue = [...newValue, option.value];
      selectionOrder = [...selectionOrder, option.id];
    }

    selected[option.id] = !selected[option.id];

    this.setState({ selectionOrder, selected });

    this.props.onChange && this.props.onChange(newValue);
  };

  componentDidUpdate({ options: opts, value: prevVal }: CustomMultiSelectorProps<Type>) {
    const { options, value } = this.props;
    const { selected, selectionOrder } = this.state;
    const changes = { selected, selectionOrder };
    if (options !== opts || prevVal !== value) {
      (options || []).forEach(x => {
        const match: any = (value || []).find((y: any) => y?.id === x?.id || y === x?.id);
        if (match) {
          changes.selected[x.id] = true;
          changes.selectionOrder = [((value || []) as any)[0].id ?? ((value || []) as any)[0]];
        } else changes.selected[x.id] = false;
      });
      this.setState({ ...changes });
    }
  }

  handleOnClearAllClick = (): void => {
    const selected = { ...this.state.selected };
    this.props.options.forEach(x => (selected[x.id] = false));

    this.setState({ selected, search: '', selectionOrder: [] });

    this.props.onChange && this.props.onChange([]);
  };

  renderTextToShow = (): ReactNode => {
    const selectedOptionsNumber = this.getSelectionOptionsCount();

    if (selectedOptionsNumber === 0)
      return (
        <div className="default text" role="alert" aria-live="polite" aria-atomic="true">
          {this.props.placeholder}
        </div>
      );

    const item = (this.state.selectionOrder || [])[0] || ((this.state.selected || []) as any)[0]?.id;

    const firstOptionSelected = this.props.options.find(x => x.id === item);

    if (selectedOptionsNumber === 1) return this.renderOption(firstOptionSelected);

    return (
      <div className="text planit-multi-dropdown__selected-option">
        {this.renderOption(firstOptionSelected)}
        {selectedOptionsNumber > 1 && <span className="planit-multi-dropdown__selected-option__number">(+{selectedOptionsNumber})</span>}
      </div>
    );
  };

  renderOption = (option: MultipleSelectionDropdownOptions<Type>): ReactNode => {
    if (!this.props.optionRenderer) return <span className="text">{option?.text}</span>;

    return this.props.optionRenderer(option);
  };

  renderDropdownIcon = (): ReactNode => {
    if (!this.props.clearable || (this.props.value || []).length === 0) return <i aria-hidden="true" className="dropdown icon"></i>;

    return <i aria-hidden="true" className="dropdown icon clear" onClick={() => this.handleOnClearAllClick()}></i>;
  };

  handleCloseWhenClickingInside = evt => {
    // Open when closed
    if (!this.state.expanded) {
      this.setState({ expanded: true });
      this.props.onOpenDropDown && this.props.onOpenDropDown();
    } else {
      const elementClassList = evt.target.classList;
      // Click on text of dropdown
      if (elementClassList.contains('text')) {
        const isOptionOfDropdown = evt.target.parentElement.classList.contains('item');
        if (!isOptionOfDropdown) this.setState({ expanded: false });
      }
      // Click on dropdown or any related site
      if (elementClassList.contains('dropdown')) this.setState({ expanded: false });

      // Click on icon (and not the clear icon)
      if (elementClassList.contains('icon') && !elementClassList.contains('clear')) this.setState({ expanded: false });
    }
  };

  render() {
    const { searchable, t, className, disabled, direction } = this.props;
    const { search, selected, expanded } = this.state;

    let listBoxClassName = 'ui multiple selection dropdown planit-multi-dropdown';

    if (expanded) listBoxClassName += ' visible';

    if (disabled) listBoxClassName += ` disabled`;

    if (className) listBoxClassName += ` ${className}`;

    let menuClassName = !expanded ? 'menu transition' : 'menu visible transition';
    if (direction) menuClassName = `${direction} ${menuClassName}`;
    const options = this.props.options || [];
    const visibleOptions = search
      ? options.filter(({ text }) => text.match(new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')))
      : options;

    return (
      <div
        ref={node => (this.dropdown = node)}
        role="listbox"
        aria-expanded="false"
        aria-multiselectable="true"
        className={listBoxClassName}
        tabIndex={0}
        onClick={this.handleCloseWhenClickingInside}
      >
        {this.renderTextToShow()}
        {this.renderDropdownIcon()}
        <div className={menuClassName}>
          {searchable && (
            <Input
              className="employee-search-filter planner-instructor__input-search"
              icon="search"
              placeholder={t('Search')}
              value={search}
              onChange={(_, { value }) => this.setState({ search: value })}
              onFocus={e => this.props.onFocus && this.props.onFocus(e)}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              onBlur={e => this.props.onBlur && this.props.onBlur(e)}
            />
          )}
          <div className="planit-multi-dropdown__options">
            {visibleOptions.length === 0 && (
              <Dimmer active inverted>
                <Loader inverted size="small"></Loader>
              </Dimmer>
            )}
            {visibleOptions.map((option: MultipleSelectionDropdownOptions<Type>) => (
              <div
                key={option.id}
                style={{ pointerEvents: 'all' }}
                role="option"
                aria-checked={selected[option.id]}
                aria-selected={selected[option.id]}
                className="item"
                onClick={event => this.handleOnOptionClick(event, option)}
              >
                <Checkbox checked={selected[option.id]} />
                {this.renderOption(option)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MultipleSelectionDropdown);
