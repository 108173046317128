import * as React from 'react';
import { DateTimePeriod, EventStatus } from 'stores/events/events-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Icon } from 'semantic-ui-react';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import MultipleUsersEditor from 'widgets/bussiness/multiple-selector/multiple-user-editor';
import MultipleEventStatusEditor from 'widgets/bussiness/multiple-selector/multiple-event-status-editor';
import PeriodInput from 'widgets/form/period-input';
import { UserDto } from 'stores/users/users-store';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import './always-visible-filters.less';
import { IdentityService, Roles } from 'services/identity-service';
import { DateTimeService } from 'services/datetime-service';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';
import { container } from 'inversify.config';
import MultipleRegionEditor from 'widgets/bussiness/multiple-selector/multiple-region-editor';
import { RegionDto } from 'stores/configuration/locations/regions-store';
import MultiplePriorityEditor from 'widgets/bussiness/multiple-selector/multiple-priority-editor';
import { PriorityDto } from 'stores/configuration/events-n-requests/priorities-store';
export interface AlwaysVisibleFiltersValue {
  period: DateTimePeriod;
  instructors: UserDto[];
  instructorLocations: LocationDto[];
  requestLocations: LocationDto[];
  requestsRegions: RegionDto[];
  eventLocations: LocationDto[];
  status: EventStatus[];
  eventType: string[];
  eventTitle: string;
  filterByActiveUpdateFlag: boolean;
  handleAlertFilter: boolean;
  filterByMe: boolean;
  filterByMyPillar: boolean;
  priorities: PriorityDto[];
  roleInstructor: 'Internal Trainer' | 'Power Instructor' | 'Instructor' | '';
  pillarManagedBy: 'PlannerMTC' | 'PlannerTFT' | '';
}

export interface AlwaysVisibleFiltersProps extends WithTranslation {
  value: AlwaysVisibleFiltersValue;
  showInstructorsFilters?: boolean;
  onChange?: (filterValues: AlwaysVisibleFiltersValue) => void;
  disabled?: boolean;
  onFilterMe?: (t: boolean, other: string) => void;
}

const AlwaysVisibleFilters: React.FC<AlwaysVisibleFiltersProps> = props => {
  const identityService: IdentityService = container.get(IdentityService);

  const handlePeriodInputChange = ({ from, to }: DateTimePeriod) => {
    if (!to || !from) return;
    const { period } = props.value;
    const fromMin = DateTimeService.toMoment(to).subtract(1, 'years');
    const toMax = DateTimeService.toMoment(from).add(1, 'years');
    if (period.from !== from && DateTimeService.toMoment(to).isAfter(toMax)) to = DateTimeService.toString(toMax);
    else if (period.to !== to && DateTimeService.toMoment(from).isBefore(fromMin)) from = DateTimeService.toString(fromMin);

    handleFilterChange('period', { from, to });
  };

  const handleFilterChange = (property: keyof AlwaysVisibleFiltersValue, propertyValue: any): void => {
    const newFilterValues: AlwaysVisibleFiltersValue = { ...props.value };
    if (property === 'filterByMe' && propertyValue && newFilterValues.filterByMyPillar) newFilterValues.filterByMyPillar = false; //toggle
    if (property === 'filterByMyPillar' && propertyValue && newFilterValues.filterByMe) newFilterValues.filterByMe = false; //toggle
    newFilterValues[property as string] = propertyValue;
    props.onChange && props.onChange(newFilterValues);
  };

  const { t, showInstructorsFilters, value } = props;
  const isPowerInstructor = IdentityService.isPowerInstructor(identityService.getUserInfo());
  return (
    <>
      {identityService.isInRole(Roles.Instructor) && (
        <>
          {isPowerInstructor && (
            <button
              onClick={() => handleFilterChange('filterByMyPillar', !value.filterByMyPillar)}
              className={`sch-my-pillar${value.filterByMyPillar ? ' active' : ''}`}
            >
              {t('My Pillar')}
            </button>
          )}
          <button
            onClick={() => handleFilterChange('filterByMe', !value.filterByMe)}
            className={`sch-me-filter${value.filterByMe ? ' active' : ''}`}
          >
            <Icon.Group>
              <Icon name="star" />
            </Icon.Group>
            <span>{t('Me')}</span>
          </button>
        </>
      )}
      <div className="scheduler-header__period-input">
        <PeriodInput id={'scheduler-header'} minMaxDate value={value.period} onChange={handlePeriodInputChange} />
      </div>

      {showInstructorsFilters && (
        <>
          <MultipleUsersEditor
            clearable
            placeholder={t('Instructor')}
            role="Instructor"
            value={value.instructors}
            onChange={value => handleFilterChange('instructors', value)}
          />

          <MultipleLocationEditor
            clearable
            placeholder={t("Instructor's location")}
            value={value.instructorLocations}
            onChange={value => handleFilterChange('instructorLocations', value)}
          />
        </>
      )}

      <MultipleLocationEditor
        clearable
        placeholder={t('Request Location')}
        value={value.requestLocations}
        onChange={value => handleFilterChange('requestLocations', value)}
      />

      {showInstructorsFilters && (
        <MultipleRegionEditor
          clearable
          placeholder={t('Request Location Region')}
          value={value.requestsRegions}
          onChange={value => handleFilterChange('requestsRegions', value)}
        />
      )}

      <MultipleLocationEditor
        clearable
        placeholder={t('Event Location')}
        value={value.eventLocations}
        onChange={value => handleFilterChange('eventLocations', value)}
      />

      <MultipleEventStatusEditor
        clearable
        placeholder={t('Status')}
        value={value.status}
        onChange={value => handleFilterChange('status', value)}
        requestedFromScheduler
      />

      <MultiEventTypeEditor
        placeholder={t('Event Type')}
        schedulerRender
        useOriginalEventTypeIdAsValue
        value={value.eventType}
        onChange={value => handleFilterChange('eventType', value)}
      />

      <MultiplePriorityEditor
        placeholder={t('Priority')}
        clearable
        value={value.priorities}
        onChange={value => handleFilterChange('priorities', value)}
      />

      <Input
        className="employee-search-filter input-search"
        icon="search"
        placeholder={t('Event Title')}
        onChange={(_, { value }) => handleFilterChange('eventTitle', value)}
      />
    </>
  );
};

AlwaysVisibleFilters.defaultProps = {
  showInstructorsFilters: false,
  onFilterMe: () => {},
  onChange: () => {},
  disabled: false
};

export default withTranslation()(React.memo(AlwaysVisibleFilters));
