import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Accordion, Icon, Form, TextArea } from 'semantic-ui-react';
import { TheoreticalQuestionDto, AnswerType } from 'stores/assessments/questionBank/theoretical-test-store';
import LanguageEditor from 'widgets/bussiness/language-editor';
import { ItemReference } from 'stores/dataStore';
import TheoreticalQuestionOption from './theoretical-question-option';

interface QuestionTranslationProps extends WithTranslation, TheoreticalQuestionDto {
  index: number;
  answerType: AnswerType;
  active?: boolean;
  onRemove: () => void;
  onChange: (property: keyof TheoreticalQuestionDto, value: any) => void;
  onLanguageChange: (item: ItemReference) => void;
}

const TheoreticalQuestionTranslation: FC<QuestionTranslationProps> = ({
  t,
  onChange,
  onRemove,
  onLanguageChange,
  index,
  language,
  text,
  answerType,
  optionA,
  optionB,
  optionC,
  optionD,
  optionE,
  active
}) => {
  const [activeIndex, setActiveIndex] = useState(active);

  const handleClickAcordion = () => {
    setActiveIndex(!activeIndex);
  };

  const basicContent = (
    <Form.Group>
      <Form.Field required inline width={12}>
        <label>{t('Question')}</label>
        <TextArea value={text} onChange={(_, { value }) => onChange('text', value)} />
      </Form.Field>
      <Form.Field required inline width={4}>
        <LanguageEditor onlyForTests value={language?.id ?? null} onChange={onLanguageChange} />
      </Form.Field>
    </Form.Group>
  );

  let translationContent = basicContent;
  if (answerType === 'Rating') {
    translationContent = (
      <>
        {basicContent}
        <Form.Field className="theoretical-options-box" required>
          <label>{t('Options')}</label>
          <div className="options-group">
            <TheoreticalQuestionOption value={optionA} title={'Option A'} onChange={(_, { value }) => onChange('optionA', value)} />
            <TheoreticalQuestionOption value={optionB} title={'Option B'} onChange={(_, { value }) => onChange('optionB', value)} />
            <TheoreticalQuestionOption value={optionC} title={'Option C'} onChange={(_, { value }) => onChange('optionC', value)} />
            <TheoreticalQuestionOption value={optionD} title={'Option D'} onChange={(_, { value }) => onChange('optionD', value)} />
            <TheoreticalQuestionOption value={optionE} title={'Option E'} onChange={(_, { value }) => onChange('optionE', value)} />
          </div>
        </Form.Field>
      </>
    );
  }
  const icon = `angle ${activeIndex ? 'up' : 'down'}` as any;

  return (
    <div className="translate-accordion">
      <div className="accordion-wrapper">
        <Accordion.Title className="accordion__title" index={index} active={activeIndex} onClick={handleClickAcordion}>
          {(language?.id && language?.language) ?? '| Language Name |'}
          <Icon name={icon} />
        </Accordion.Title>
        <Accordion.Content className="accordion__content" key={`ssa-form-accordion${index}`} active={activeIndex}>
          {translationContent}
        </Accordion.Content>
      </div>
      <Icon name="remove" onClick={onRemove} />
    </div>
  );
};
export default withTranslation()(TheoreticalQuestionTranslation);
