import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Message, List, Checkbox, Form, Dropdown, Button, Label, Icon, Dimmer, Loader } from 'semantic-ui-react';

import { Query } from 'stores/dataStore';
import { nameof } from 'utils/object';
import './employees-with-profile/assigment-employees-profile.less';
import { ItemReference } from 'stores/dataStore';

import {
  UserProfilesDto,
  UserMachineProfileItemDto,
  NewProfileItemAssigmentStore,
  ChangeUserProfileItemStore,
  UserProfileItemDto,
  CreateUserProfilesDto,
  ChangeUserProfilesDto
} from 'stores/profile/user-profile-store';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { AssignmentMode } from './profile-assigment-home';
import { ProfileItemStore, ProfileItemDto, Assessment } from 'stores/profile/profile-store';
import { isNullOrUndefined } from 'util';

export interface UserProfilesAssigmentObj {
  oem: ItemReference;
  equipmentType: ItemReference;
  machineModel: ItemReference;
  machineRelatedCluster: ItemReference;
  level: { id: number; title: string };
}

export interface UserProfileAssignment {
  profile: ProfileItemDto;
  zip: boolean;
  machineRow?: UserProfilesAssigmentObj;
  machines?: UserProfilesAssigmentObj[];
  checked: boolean;
}

interface UserProfilesAssigmentProps extends WithTranslation {
  profilesStore?: ProfileItemStore;
  newProfileStore?: NewProfileItemAssigmentStore;
  changeProfileStore?: ChangeUserProfileItemStore;
  onCancel: () => void;
  data: UserProfilesDto[];
  mode: AssignmentMode;
}
export interface UserProfileAssignmentState {
  query: Query;
  profiles: UserProfileAssignment[];
  expandEditProfileRow?: boolean;
}

@connect(
  ['profilesStore', ProfileItemStore],
  ['newProfileStore', NewProfileItemAssigmentStore],
  ['changeProfileStore', ChangeUserProfileItemStore]
)
class AssigmentEmployeesWithProfileEditMode extends Component<UserProfilesAssigmentProps, UserProfileAssignmentState> {
  state: UserProfileAssignmentState = {
    query: { searchQuery: '', orderBy: [], skip: 0, take: 1000, filter: this.props.mode === 'edit' ? [] : [{ IsActive: true }] },
    expandEditProfileRow: false,
    profiles: []
  };

  private get modeStore() {
    return this.props.mode === 'edit' ? this.props.changeProfileStore : this.props.newProfileStore;
  }

  private get profiles() {
    return this.props.profilesStore;
  }

  componentDidMount() {
    this.load();
  }

  mapMachinesToProfiles = (machines: UserMachineProfileItemDto[]): UserProfilesAssigmentObj[] =>
    machines.map(machine => ({
      oem: { id: machine.oemId, title: machine.oemName },
      equipmentType: { id: machine.equipmentTypeId, title: machine.equipmentTypeName },
      machineModel: { id: machine.machineModelId, title: machine.machineModelName },
      machineRelatedCluster: { id: machine.clusterId, title: machine.clusterName },
      level: { id: (machine.level as any) === 'Main' ? 10 : 20, title: machine.level.toString() }
    }));

  getDefaultMachineRow = (machineRow?: UserProfilesAssigmentObj) => ({
    equipmentType: machineRow ? machineRow.equipmentType : null,
    oem: machineRow ? machineRow.oem : null,
    level: null,
    machineRelatedCluster: machineRow ? machineRow.machineRelatedCluster : null,
    machineModel: null
  });

  load = () => {
    this.profiles.getAllAsync(this.state.query).then(response => {
      const { items } = response;
      if (this.props.mode === 'edit') this.mapToEditMode(items);
      else if (this.props.mode === 'new' || this.props.mode === 'multiAssign') this.mapToNewMode(items);
    });
  };

  getDefaultProfiles = (item: ProfileItemDto, checked: boolean = false): UserProfileAssignment => {
    if (item.assessment === Assessment[Assessment.TNA]) {
      return {
        profile: item,
        zip: false,
        machineRow: this.getDefaultMachineRow(),
        machines: [],
        checked: false
      };
    } else return { profile: item, zip: false, checked };
  };

  mapToInitialState = items => {
    let profiles: UserProfileAssignment[] = [];
    if (items.length > 0) {
      items.forEach(obj => {
        profiles.push(this.getDefaultProfiles(obj));
      });
    }
    this.setState({ profiles });
  };

  mapToNewMode = (items: ProfileItemDto[]) => {
    this.mapToInitialState(items);
    const userIds = this.props.data && this.props.data.map((obj: any) => obj.id);
    const profiles: UserProfileItemDto[] = [];
    (this.modeStore as any).createNew({ userIds, profiles });
  };

  mapToEditMode = (items: ProfileItemDto[]) => {
    let profiles: UserProfileAssignment[] = [];
    const rawProfiles = [...this.props.data[0].profiles];
    const notMatch = items.filter(o => !rawProfiles.find(p => p.profileId === o.id));
    rawProfiles.forEach(p => {
      const m = [...p.userMachineProfiles];
      const matchProfile = items.find(obj => obj.id === p.profileId);

      if (matchProfile) {
        let machines = [];
        if (Assessment[matchProfile.assessment] === Assessment.TNA) {
          m.forEach(machine => {
            machines.push({
              oem: { id: machine.oemId, title: machine.oemName },
              equipmentType: { id: machine.equipmentTypeId, title: machine.equipmentTypeName },
              machineModel: { id: machine.machineModelId, title: machine.machineModelName },
              machineRelatedCluster: { id: machine.clusterId, title: machine.clusterName },
              level: { id: (machine.level as any) === 'Main' ? 10 : 20, title: machine.level.toString() }
            } as UserProfilesAssigmentObj);
          });
          profiles.push({
            profile: matchProfile,
            zip: false,
            machineRow: this.getDefaultMachineRow(),
            checked: true,
            machines
          });
        } else {
          profiles.push(this.getDefaultProfiles(matchProfile, true));
        }
      }
    });
    notMatch.forEach(p => profiles.push(this.getDefaultProfiles(p)));
    this.setState({ profiles }, () => {
      const userId = this.props.data && this.props.data.map(obj => obj.userId)[0];
      let profs: UserProfileItemDto[] = [];
      this.state.profiles.forEach(item => {
        let { profile, machines, checked } = item;
        if (!checked) return;
        const result: UserProfileItemDto = item && {
          profileId: profile.id,
          machines: !isNullOrUndefined(machines)
            ? machines.map(machine => ({ machineModelId: machine.machineModel.id, level: machine.level.title as any }))
            : []
        };
        profs.push(result);
      });
      this.props.changeProfileStore.createNew({ userId, profiles: profs });
    });
  };

  onEditCheckboxItem = (profileId: string) => {
    const profiles = this.state.profiles.map(item => {
      if (profileId === item.profile.id) {
        item.zip = !item.zip;
      }
      return item;
    });
    this.setState({ profiles });
  };

  handleChanges(property: string, value: any, profileId: string): void {
    const profiles = this.state.profiles.map(p => {
      if (p.profile.id === profileId) {
        if (property === 'level') {
          p.machineRow[property] = value === 10 ? { id: 10, title: 'Main' } : value === 20 ? { id: 20, title: 'Substitute' } : null;
        } else p.machineRow[property] = value;
      }
      return p;
    });
    this.setState({ profiles });
  }

  private handleNewMachineProfileRow = profileId => {
    let newMachine = null;
    const profiles = this.state.profiles.map(item => {
      if (item.profile.id === profileId) {
        newMachine = item.machineRow;
        item.machines.push(item.machineRow);
        item.machineRow = this.getDefaultMachineRow(item.machineRow);
      }
      return item;
    });
    this.setState({ profiles }, () => {
      const newChangeCreation: CreateUserProfilesDto | ChangeUserProfilesDto = { ...this.modeStore.state.item };
      const { profiles: profs }: { profiles: UserProfileItemDto[] } = newChangeCreation;

      const matchingProfile = profiles.find(x => x.profile.id === profileId && x.checked);
      if (matchingProfile) {
        this.modeStore.change({
          ...newChangeCreation,
          profiles: profs.map(p => {
            const machines = [...p.machines];
            machines.push({ machineModelId: newMachine.machineModel.id, level: newMachine.level.title });
            if (p.profileId === profileId) return { ...p, machines };
            else return p;
          })
        });
      }
    });
  };

  onRemoveRow = (profileId: string, i: number) => {
    let newMachine = null;
    const profiles = this.state.profiles.map(item => {
      if (profileId === item.profile.id) {
        newMachine = item.machines[i];
        item.machines.splice(i, 1);
      }
      return item;
    });
    this.setState({ profiles }, () => {
      const newChangeCreation: CreateUserProfilesDto | ChangeUserProfilesDto = { ...this.modeStore.state.item };
      let { profiles: profs }: { profiles: UserProfileItemDto[] } = newChangeCreation;
      const matchingProfile = profs.find(x => x.profileId === profileId);

      if (matchingProfile) {
        this.modeStore.change({
          ...newChangeCreation,
          profiles: profs.map(p => {
            const machines = p.machines.filter(m => m.machineModelId !== newMachine.machineModel.id);
            if (p.profileId === profileId) {
              const returObj = { ...p, machines };
              return returObj;
            }
            return p;
          })
        });
      }
    });
  };

  onSubmit = () => {
    let result: Promise<any> = null;
    const sanitazedProfiles = this.modeStore.state.item.profiles.map(({ machines, profileId }) => ({ machines, profileId }));
    this.modeStore.change({ ...this.modeStore.state.item, profiles: sanitazedProfiles });
    if (this.props.mode === 'edit') {
      result = this.modeStore.update();
    } else result = this.modeStore.submit();
    result &&
      result.then(_ => {
        if (this.modeStore.state.result.isSuccess) {
          this.onCancel();
        }
      });
  };

  onCheckChange = (e, data, profileId: string) => {
    const { checked }: { checked: boolean } = data;
    const profiles = this.state.profiles.map(item => {
      if (item.profile.id === profileId) {
        item.checked = checked;
        if (checked && item.machineRow) item.zip = true;
        if (!checked && item.machineRow) item.zip = false;
      }
      return item;
    });
    this.setState({ profiles }, () => {
      const newChangeCreation = { ...this.modeStore.state.item };
      if (checked) {
        const filteredProfiles = profiles.find(item => item.profile.id === profileId);
        const newProfileAssignment: UserProfileItemDto = !isNullOrUndefined(filteredProfiles)
          ? {
              profileId,
              machines:
                filteredProfiles.machines && filteredProfiles.machines.length > 0
                  ? filteredProfiles.machines.map(m => ({ machineModelId: m.machineModel.id, level: m.level.id }))
                  : []
            }
          : { profileId, machines: [] };
        const newProfile = newChangeCreation.profiles || [];
        newProfile.push(newProfileAssignment);
        this.modeStore.change({ ...newChangeCreation, profiles: newProfile });
      } else {
        this.modeStore.change({ ...newChangeCreation, profiles: newChangeCreation.profiles.filter(p => p.profileId !== profileId) });
      }
    });
  };

  onCancel = () => {
    this.modeStore.clear();
    this.props.onCancel();
  };

  isAbleToSave = (): boolean => {
    const model = { ...this.modeStore.state.item };
    return model.profiles && model.profiles.length > 0 && !isNullOrUndefined(model.profiles.find(p => p.profileId));
  };

  canAddMachine = (profile: string, machines: UserProfilesAssigmentObj[], machineRow: UserProfilesAssigmentObj): boolean => {
    const checkProfile = this.state.profiles.find(p => p.profile.id === profile);
    if (!checkProfile) return true;
    return (
      machineRow.equipmentType == null ||
      machineRow.machineRelatedCluster == null ||
      machineRow.level == null ||
      machineRow.oem == null ||
      machineRow.machineModel == null ||
      (machines.length > 0 && machineRow.machineModel != null && machines.any(m => m.machineModel.id === machineRow.machineModel.id))
    );
  };

  isMachineRelatedProfile = (profile: ProfileItemDto): boolean => {
    return profile && (profile?.skills || []).any(x => x.isMachineRelated);
  };

  public render() {
    const { t, profilesStore, data } = this.props;
    const { profiles } = this.state;
    const options = [
      { key: 1, text: t('Main'), value: 10 },
      { key: 2, text: t('Substitute'), value: 20 }
    ];

    return (
      <div className="assigment-employees-profiles-edit__wrapper">
        <Dimmer
          active={this.profiles.state.isBusy || this.modeStore.state.isBusy}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}
        >
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {profilesStore.state.result && !profilesStore.state.result.isSuccess && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={profilesStore.state.result.messages.map(o => o.body)}
          />
        )}
        {data.length > 0 &&
          data.map(item => {
            return (
              <p>
                {item.firstName} {item.lastName}
              </p>
            );
          })}
        {this.modeStore.state.result && !this.modeStore.state.result.isSuccess && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            list={this.modeStore.state.result.messages.map(msg => msg.body)}
          />
        )}

        <div className="edit__select-profiles__wrapper">
          <p>{t('SELECT PROFILES')}</p>
          <List verticalAlign="middle">
            {profiles.length > 0 &&
              profiles.map(({ zip, profile, machineRow, machines, checked }, index) => (
                <div key={profile && profile.id + index}>
                  <List.Item className="edit__select-profiles__wrapper__row">
                    <Checkbox
                      disabled={!profile.isActive}
                      onChange={(e, d) => this.onCheckChange(e, d, profile.id)}
                      defaultChecked={checked}
                    />
                    <Label
                      className={`profile-label${!isNullOrUndefined(machineRow) ? ' expandable' : ''}${
                        !profile.isActive ? ' inactive' : ''
                      }`}
                      onClick={() => this.onEditCheckboxItem(profile.id)}
                      style={{ width: '100%' }}
                      content={profile && t(profile.name)}
                    />
                  </List.Item>

                  {zip && !isNullOrUndefined(machineRow) && this.isMachineRelatedProfile(profile) && (
                    <>
                      <div className={profile.isActive ? 'edit__select-profiles__edit-wrapper' : 'edit__select-profiles__display-none'}>
                        <Form.Group>
                          <Form.Field width={4}>
                            <div className={`required field edit__select-profiles__label-input`}>
                              <label>{t('Cluster')}</label>

                              <MRClusterEditor
                                clearable
                                nullable
                                value={machineRow.machineRelatedCluster && machineRow.machineRelatedCluster.id}
                                onChange={c => {
                                  this.handleChanges(nameof<UserProfilesAssigmentObj>('machineRelatedCluster'), c, profile.id);
                                }}
                              />
                            </div>
                          </Form.Field>

                          <Form.Field width={6}>
                            <div className={`required field edit__select-profiles__label-input`}>
                              <label>{t('Equipment Type')}</label>
                              <EquipmentTypeEditor
                                readOnly={isNullOrUndefined(machineRow.machineRelatedCluster)}
                                clearable
                                nullable
                                clusterId={machineRow.machineRelatedCluster ? machineRow.machineRelatedCluster.id : undefined}
                                value={machineRow.equipmentType ? machineRow.equipmentType.id : null}
                                onChange={c => this.handleChanges(nameof<UserProfilesAssigmentObj>('equipmentType'), c, profile.id)}
                              />
                            </div>
                          </Form.Field>

                          <Form.Field width={4}>
                            <div className={`required field edit__select-profiles__label-input`}>
                              <label>{t('OEM')}</label>
                              <OemEditor
                                readonly={isNullOrUndefined(machineRow.equipmentType)}
                                clearable
                                nullable
                                equipmentId={machineRow.equipmentType ? machineRow.equipmentType.id : undefined}
                                value={machineRow.oem && machineRow.oem.id}
                                onChange={c => this.handleChanges(nameof<UserProfilesAssigmentObj>('oem'), c, profile.id)}
                              />
                            </div>
                          </Form.Field>

                          <Form.Field error width={6}>
                            <div className={`required field edit__select-profiles__label-input`}>
                              <label>{t('Machine Model')}</label>
                              <MachineModelEditor
                                error={
                                  machines &&
                                  machines.length > 0 &&
                                  !isNullOrUndefined(
                                    machines.find(m => machineRow.machineModel != null && m.machineModel.id === machineRow.machineModel.id)
                                  )
                                }
                                readonly={isNullOrUndefined(machineRow.oem) || isNullOrUndefined(machineRow.equipmentType)}
                                clearable
                                nullable
                                value={machineRow.machineModel ? machineRow.machineModel.id : undefined}
                                oemId={machineRow.oem ? machineRow.oem.id : undefined}
                                equipmentId={machineRow.equipmentType ? machineRow.equipmentType.id : undefined}
                                onChange={c => this.handleChanges(nameof<UserProfilesAssigmentObj>('machineModel'), c, profile.id)}
                              />
                            </div>
                          </Form.Field>

                          <Form.Field width={4}>
                            <div className={`required field edit__select-profiles__label-input`}>
                              <label>{t('Level')}</label>

                              <Dropdown
                                disabled={isNullOrUndefined(machineRow.machineModel)}
                                clearable
                                selection
                                multiple={false}
                                options={options}
                                value={machineRow.level ? machineRow.level.id : null}
                                placeholder={t('Select Level')}
                                onChange={(_, { value }) =>
                                  this.handleChanges(nameof<UserProfilesAssigmentObj>('level'), value, profile.id)
                                }
                              />
                            </div>
                          </Form.Field>

                          <Button
                            disabled={this.canAddMachine(profile.id, machines, machineRow)}
                            onClick={() => this.handleNewMachineProfileRow(profile.id)}
                            className="add-row-btn"
                            type="button"
                          >
                            {t('Add Machine')}
                          </Button>
                        </Form.Group>
                      </div>

                      {machines &&
                        machines.length > 0 &&
                        machines.map((assignedMachine, idx) => (
                          <div key={profile.id + idx} className="flex-center assigned-profile-row-machine__info">
                            <div className="assigned-profile-row-machine__items">
                              <p className="machine-cell">{t(assignedMachine.machineRelatedCluster.title)}</p>
                              <p className="machine-cell">{t(assignedMachine.equipmentType.title)}</p>
                              <p className="machine-cell">{t(assignedMachine.oem.title)}</p>
                              <p className="machine-cell">{t(assignedMachine.machineModel.title)}</p>
                              <p className="machine-cell">{t(assignedMachine.level.title)}</p>
                            </div>
                            {profile.isActive && (
                              <Icon
                                className="assigned-profile-row-machine__remove"
                                name="remove"
                                size="large"
                                color="red"
                                onClick={() => this.onRemoveRow(profile.id, idx)}
                              />
                            )}
                          </div>
                        ))}
                    </>
                  )}
                </div>
              ))}
          </List>
          <div className="profile_assignment__btns">
            <Button secondary inverted onClick={this.onCancel}>
              {t('Cancel')}
            </Button>
            <Button disabled={!this.isAbleToSave()} primary onClick={this.onSubmit} positive>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AssigmentEmployeesWithProfileEditMode);
