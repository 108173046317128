import { UserDto } from 'stores/users/users-store';
import { DateTimeService } from 'services/datetime-service';
import { openInNewWindow } from './useful-functions';
import { Roles } from 'services/identity-service';

export function CalculateSeniority(hireDate) {
  if (!hireDate) return 0;

  const hiredDate = DateTimeService.toString(hireDate);
  const now = DateTimeService.now();

  return now.year() - DateTimeService.toMoment(hiredDate).year();
}

export function GetLocationName(user: UserDto): string {
  if (user == null) return null;
  let location: string = user.location == null ? null : user.location.location;

  const emplRole = (user.roles || []).firstOrDefault(r => r != null && r.role != null && r.role.name === 'Employee');
  if (emplRole != null && emplRole.location != null) {
    location = emplRole.location.location;
  }

  return location;
}

export function GetLocationNameAndCountry(user: UserDto) {
  if (user == null) return null;
  let location: any = user.location == null ? null : user.location;

  const emplRole = (user.roles || []).firstOrDefault(r => r != null && r.role != null && r.role.name === 'Employee');
  if (emplRole != null && emplRole.location != null) {
    location = emplRole.location;
  }

  return location?.location + ' - ' + location?.countryName;
}

export function openEmployeePage(userId: string): void {
  openInNewWindow(`./employee-page/${userId}`);
}

export function GetRoleName(role: string): string {
  if (role === Roles[Roles.Planner]) return 'Planner TFT';
  else if (role === Roles[Roles.PlannerMTC]) return 'Planner MTC';
  else return role;
}
