import { BaseDto, CommandResult } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';

export interface EventRequestChangesDto extends BaseDto {
  id: string;
  eventId: string;
  eventTitle: string;
  reasonId: string;
  reasonName: string;
  comments: string;
  timestamp: string;
  fromUserId: string;
  fromUsername: string;
}

export interface CreateEventRequestChangesDto {
  reasonId: string;
  comments: string;
}

export class CreateEventRequestChangesValidator extends AbstractValidator<CreateEventRequestChangesDto> {
  constructor() {
    super();

    this.validateIfString(o => o.comments)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments are required'));

    this.validateIfString(o => o.reasonId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Reason Id is required'));
  }
}

@repository('@@EVENTREQUESTCHANGES', 'eventRequestChanges.summary')
export class EventRequestChangesStore extends DataStore<EventRequestChangesDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = 'get-eventRequestChange';
  updatePath = '';
  deletePath = '';

  protected validate(item: EventRequestChangesDto) {
    return new CreateEventRequestChangesValidator().validate(item);
  }

  constructor() {
    super('EVENTREQUESTCHANGE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTREQUESTCHANGES', 'eventRequestChanges.new')
export class NewEventRequestChangeStore extends FormStore<CreateEventRequestChangesDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'event/{eventId}/new-request-change';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreateEventRequestChangesDto) {
    return new CreateEventRequestChangesValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENTREQUESTCHANGE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  public async createEventRequestChange(eventId: string): Promise<CommandResult<CreateEventRequestChangesDto>> {
    const httpService = container.get(HttpService);
    const createPathForEvent = this.createPath.replace('{eventId}', eventId);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreateEventRequestChangesDto, CommandResult<EventRequestChangesDto>>(
        `${this.baseUrl}/${createPathForEvent}`,
        this.state.item
      )
    );
    return result.data;
  }
}
