import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Message, Dimmer, Loader, Modal, Button, Container } from 'semantic-ui-react';
import {
  TnaFormListStore,
  TnaFormItemDto,
  TnaFormChangeAnswerStore,
  TnaFormAnswersDto,
  ChangeSkillAnswersDto,
  ChangeTnaFormAnswerDto,
  ChangeMachineUnitAnswersDto,
  ChangeQuestionAnswerDto,
  MachineUnitAnswersDto,
  SkillAnswersDto,
  QuestionAnswerDto,
  FunctionalExpertDto,
  TnaFormStatus,
  MachineModelAnswersDto,
  ChangeMachineModelAnswersDto,
  AssessorAnswerStatus,
  InternalTrainerRecommendationDto
} from 'stores/assessments/forms/tna-forms-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { RouteComponentProps } from 'react-router';
import { connect } from 'redux-scaffolding-ts';
import TNAFormComponent from './tna-form.component';
import TnaFormFooterComponent from './tna-form-footer-component';
import TnaFormHeaderComponent from './tna-form-header-component';
import { goToPage, isNullOrEmpty } from 'utils/useful-functions';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import './tna-edit-form-general.less';
import { TnaTheoreticalTestListStore, TnaTheoreticalTestItemDto } from 'stores/assessments/forms/tna-theoretical-tests-store';
import { QueryResult, Query, ItemReference } from 'stores/dataStore';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import NotAvailable from 'site/not-available-component';

interface ChangeTnaFormViewProps extends WithTranslation, RouteComponentProps {
  onClose: (isSuccess: boolean) => void;
  onChangeLanguage?: (language: string) => void;
  changeTnaAnswerForm?: TnaFormChangeAnswerStore;
  tnaFormListStore?: TnaFormListStore;
  tnaTheoreticalStore?: TnaTheoreticalTestListStore;
}

interface ChangeTnaFormState {
  theoreticals: TheoreticalRel[];
  isDisabledButton: boolean;
  loading: boolean;
  showStatusBackModal: boolean;
  showStatusBackModalText: string;
  statusSelected: string;
  showQualifiersSelector: boolean;
  qualifiersSelectedToBackStatus: string[];
}

export interface AssessorType {
  userId: string;
  type: 'lineManager' | 'functionalExpert' | 'nonAssessor';
  mrSkills?: boolean;
  nmrFunctionalExpertsSkills?: FunctionalExpertDto;
}

const query: Query = { searchQuery: '', orderBy: [], skip: 0, filter: [{ TypeRelated: 'TNA' }], take: 100000, parameters: {} };

export interface TheoreticalRel {
  id: string;
  theoreticalTests: TnaTheoreticalTestItemDto[];
}

export type BasicRemainingModel = {
  name: string;
  remaining: string;
  id: string;
};

export type MachineUnitRemainingModel = {
  machineUnit: string;
  totalMU: string;
  skills: BasicRemainingModel[];
  id: string;
};
export type MachineRemainingModel = {
  totalMM: string;
  machineModel: string;
  machineUnits: MachineUnitRemainingModel[];
  id: string;
};
export interface RemainingQuestionsModel {
  general: { skills: BasicRemainingModel[] };
  totalNMR: string;
  machines: MachineRemainingModel[];
  totalMR: string;
}

const nonAssessorStatus: AssessorAnswerStatus = { answerStatus: 'Unknown', userId: '' };
export const TNAFormContext = React.createContext({
  submittable: (): boolean => false,
  modStatus: (): boolean => false,
  answerHandler: (...args) => {},
  onSave: () => {},
  onChange: (property: keyof ChangeTnaFormAnswerDto, value: any) => {},
  onSend: () => {},
  onSendStatusBack: (status: string) => {},
  getAssessorType: (): AssessorType => null,
  getAStatus: (): AssessorAnswerStatus => nonAssessorStatus,
  getAnwersStatus: (): AssessorAnswerStatus[] => [],
  recommendationHandler: (...args) => {},
  overallRecommendationHandler: (...args) => {},
  copyAnswersFromMachinesWithSameTemplate: (machineModelIdSource: string, machineModelIdDest: string) => {},
  isAdminOrPoC: false,
  isPoC: false,
  isAssessor: false,
  isEmployee: false,
  isDisabledButton: false,
  isAdmin: false,
  isOnlyAdmin: false,
  bypassEmployeeEvaluation: false
});

@connect(
  ['tnaFormListStore', TnaFormListStore],
  ['changeTnaAnswerForm', TnaFormChangeAnswerStore],
  ['tnaTheoreticalStore', TnaTheoreticalTestListStore]
)
class ChangeTnaFormView extends React.Component<ChangeTnaFormViewProps, ChangeTnaFormState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: ChangeTnaFormState = {
    theoreticals: [],
    isDisabledButton: false,
    loading: false,
    showStatusBackModal: false,
    showStatusBackModalText: '',
    statusSelected: '',
    showQualifiersSelector: false,
    qualifiersSelectedToBackStatus: []
  };

  private isDisabledButton = (): boolean => {
    return this.state.isDisabledButton;
  };

  private isAssessor = (): boolean => {
    const { userId } = this.identityService.getUserInfo();
    const { lineManagerId, machineFunctionalExpertId, nonMachineFunctionalExperts, machineFunctionalExperts } = this.tnaFormStore.state
      .item as TnaFormItemDto;
    const isNMRFunctionalExpert = (nonMachineFunctionalExperts || []).any(({ userId: nmrUserId }) => userId === nmrUserId);
    const isMRFunctionalExpert =
      machineFunctionalExpertId === userId || (machineFunctionalExperts || []).any(({ userId: mrUserId }) => userId === mrUserId);
    return isNMRFunctionalExpert || isMRFunctionalExpert || lineManagerId === userId;
  };

  private getAssessorType = (): AssessorType => {
    const { userId } = this.identityService.getUserInfo();
    const { lineManagerId, machineFunctionalExpertId, nonMachineFunctionalExperts, machineFunctionalExperts } = this.tnaFormStore.state
      .item as TnaFormItemDto;

    if (userId === lineManagerId) return { userId, type: 'lineManager' };

    if (userId === machineFunctionalExpertId || (machineFunctionalExperts || []).any(({ userId: mrUserId }) => userId === mrUserId)) {
      const mrFEAndNmrFE = nonMachineFunctionalExperts.find(({ userId: id }) => id === userId);

      if (mrFEAndNmrFE != null) return { userId, type: 'functionalExpert', mrSkills: true, nmrFunctionalExpertsSkills: mrFEAndNmrFE };

      return { userId, type: 'functionalExpert', mrSkills: true };
    }

    const nmrFE = (nonMachineFunctionalExperts || []).find(({ userId: id }) => id === userId);

    if (nmrFE) return { userId, type: 'functionalExpert', mrSkills: false, nmrFunctionalExpertsSkills: nmrFE };

    return { userId, type: 'nonAssessor' };
  };

  private isInRoleEmployee = (): boolean => {
    const { userId } = this.identityService.getUserInfo();
    const { userId: tnaUser } = this.tnaFormStore.state.item as TnaFormItemDto;
    return tnaUser === userId && this.identityService.isInRole(Roles.Employee);
  };

  private isInRoleAdmin = (): boolean => IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo());

  private isOnlyAdmin = (): boolean => IdentityService.isAdmin(this.identityService.getUserInfo());

  private bypassEmployeeEvaluation = (): boolean => {
    const { tnaFormListStore } = this.props;
    const { state } = tnaFormListStore;
    return state.item && state.item?.bypassEmployeeEvaluation;
  };

  private isInRoleAdminOrPoC = (): boolean => this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC);
  private isInRolePoC = (): boolean => this.identityService.isInRole(Roles.PoC);

  private get tnaFormStore() {
    return this.props.tnaFormListStore;
  }

  private get changeTnaFormAnswerStore() {
    return this.props.changeTnaAnswerForm;
  }

  private onChange = (property: keyof ChangeTnaFormAnswerDto, value: any) => {
    const change: Partial<ChangeTnaFormAnswerDto> = {};
    change[property as string] = value;
    this.changeTnaFormAnswerStore.change(change);
  };

  copyAnswersFromMachinesWithSameTemplate = (machineModelIdSource: string, machineModelIdDest: string) => {
    const assessorType = this.getAssessorType();
    const { type } = assessorType;
    let currentUserEdit = 'employeeAnswer';
    if (type === 'functionalExpert') currentUserEdit = 'functionalExpertAnswer';
    if (type === 'lineManager') currentUserEdit = 'lineManagerAnswer';

    const { answers } = this.tnaFormStore.state.item as TnaFormItemDto;

    let machineModelAnswersSource = answers.machineModelAnswers.find(x => x.machineModelId === machineModelIdSource);
    let machineModelAnswersDest = answers.machineModelAnswers.find(x => x.machineModelId === machineModelIdDest);

    if (
      machineModelAnswersSource &&
      machineModelAnswersDest &&
      machineModelAnswersSource.tnaTemplateId === machineModelAnswersDest.tnaTemplateId
    ) {
      (machineModelAnswersSource.machineUnitAnswers || []).forEach(({ skillAnswers, machineUnitId }) => {
        (skillAnswers || []).forEach(({ questionAnswers, skillId }) => {
          (questionAnswers || []).forEach(answerSource => {
            let answerDest = (
              ((machineModelAnswersDest.machineUnitAnswers || []).find(mu => mu.machineUnitId === machineUnitId)?.skillAnswers || []).find(
                sa => sa.skillId === skillId
              )?.questionAnswers || []
            ).find(qa => qa.questionId === answerSource.questionId);

            if (answerDest && !isNullOrEmpty(answerSource[currentUserEdit]?.answer)) {
              answerDest[currentUserEdit] = { ...answerDest[currentUserEdit], answer: answerSource[currentUserEdit].answer };
              this.onAnswerChangeStore(
                answerSource[currentUserEdit].answer,
                answerDest.questionId,
                skillId,
                machineUnitId,
                machineModelIdDest,
                false
              );
            }
          });
        });
      });
    }
  };

  private onSave = async () => {
    const { t } = this.props;
    try {
      this.setState({ isDisabledButton: true });
      const result = await this.changeTnaFormAnswerStore.update();
      if (result?.isSuccess) {
        ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
        this.setState({ isDisabledButton: false });
      }
    } catch (e) {
      console.dir(e);
      console.error(e, 'There was a problem saving TNA Form Answers');
      this.setState({ isDisabledButton: false });
    }
  };

  updateFunctionalExpertStatus = () => {
    const { userId } = this.identityService.getUserInfo();
    const item = { ...this.tnaFormStore.state.item } as TnaFormItemDto;
    const itemChange = item.assessorAnswerStatus.find(assessorStatus => assessorStatus.userId === userId);
    if (itemChange && itemChange.answerStatus === 'Pending') this.load();
    else window.close();
  };

  private onSend = async () => {
    const { t } = this.props;
    const isFunctionalExpert = this.getAssessorType().type === 'functionalExpert';
    try {
      this.setState({ isDisabledButton: true });
      const sendTnaResult = await this.changeTnaFormAnswerStore.SendTnaForm();
      if (sendTnaResult?.isSuccess) {
        ToastComponent({ type: 'success-toast', text: t('Form successfully submitted!') });
        if (!isFunctionalExpert) window.close();
        else this.updateFunctionalExpertStatus();

        this.setState({ isDisabledButton: false });
      }
    } catch {
      console.error('There was a problem submitting TNA Form Answers');
      this.setState({ isDisabledButton: false });
    }
  };

  private getTnaFormAnswers = async (id: string) => {
    try {
      const currentData = await this.changeTnaFormAnswerStore.getTnaFormAnswersByFormId(id);
      return currentData;
    } catch (error) {
      goToPage('./NotFound');
      console.error(error);
    }
  };

  private getTnaForm = async (id: string) => {
    try {
      const tnaForm = await this.tnaFormStore.getFormById(id);
      return tnaForm;
    } catch (error) {
      goToPage('./NotFound');
      console.error(error);
    }
  };

  mapToAnswers = (questionItem: QuestionAnswerDto): ChangeQuestionAnswerDto => {
    const assessorType = this.getAssessorType();
    const { type } = assessorType;
    let currentUserEdit = 'employeeAnswer';
    if (type === 'functionalExpert') currentUserEdit = 'functionalExpertAnswer';
    if (type === 'lineManager') currentUserEdit = 'lineManagerAnswer';
    const item = questionItem[currentUserEdit];
    return { answer: item?.answer ?? null, comment: item?.comment || '', questionId: questionItem?.questionId };
  };

  mapToSkillAnswers = (skillItem: SkillAnswersDto): ChangeSkillAnswersDto => {
    const isLineManager = this.getAssessorType().type === 'lineManager';
    const itemReturn = {
      skillId: skillItem?.skillId,
      functionalExpertRecomendation: skillItem?.functionalExpertRecomendation || '',
      questionAnswers: (skillItem?.questionAnswers || []).map(this.mapToAnswers)
    };
    if (isLineManager) delete itemReturn.functionalExpertRecomendation;

    return itemReturn;
  };

  mapToMachineUnitAnswer = (machineUnitAnswerItem: MachineUnitAnswersDto): ChangeMachineUnitAnswersDto => {
    const isLineManager = this.getAssessorType().type === 'lineManager';
    const itemReturn = {
      machineUnitId: machineUnitAnswerItem.machineUnitId,
      functionalExpertRecomendation: machineUnitAnswerItem.functionalExpertRecomendation || '',
      skillAnswers: (machineUnitAnswerItem.skillAnswers || []).map(this.mapToSkillAnswers)
    };
    if (isLineManager) delete itemReturn.functionalExpertRecomendation;
    return itemReturn;
  };

  mapMRSections = (mrSections: MachineModelAnswersDto[]): ChangeMachineModelAnswersDto[] =>
    (mrSections || []).map(({ machineModelId, machineUnitAnswers, overallRecommendationId, overallRecommendation }) => ({
      machineModelId,
      machineUnitAnswers: machineUnitAnswers.map(this.mapToMachineUnitAnswer),
      overallRecommendationId,
      overallRecommendation
    }));

  mapNMRSections = (nmrSections: SkillAnswersDto[]): ChangeSkillAnswersDto[] => (nmrSections || []).map(this.mapToSkillAnswers);

  mapToNewRawItem = (tnaFormId: string, answers: TnaFormAnswersDto) => {
    const status = this.tnaFormStore.state.item.status as TnaFormStatus;
    const { nonRelatedMachineSkillAnswers: nmr, machineModelAnswers: mr, ...rest } = answers;
    const { recommendationOfExpertForInternalTrainer, recommendationOfManagerForInternalTrainer, ...considerations } = rest;
    const { considerForFactoryInteralTrainer, considerForGSCInteralTrainer } = considerations;
    const assessorType = this.getAssessorType();
    const isEmployee = this.isInRoleEmployee();
    const { type, mrSkills, nmrFunctionalExpertsSkills } = assessorType;
    if (!this.isOnlyAdmin && type === 'nonAssessor' && !isEmployee && status !== 'Done') return;
    let machineModelAnswers = null;
    let nonMachineRelatedSkillAnswers = null;

    if (mrSkills) {
      if (nmrFunctionalExpertsSkills) {
        nonMachineRelatedSkillAnswers =
          (nmr || []).length > 0 && nmr.filter(({ skillId }) => nmrFunctionalExpertsSkills.skills.find(({ id }) => id === skillId));
        nonMachineRelatedSkillAnswers = nonMachineRelatedSkillAnswers ? this.mapNMRSections(nmr) : [];
      }
      machineModelAnswers = this.mapMRSections(mr);
    }
    if (nmrFunctionalExpertsSkills) {
      nonMachineRelatedSkillAnswers =
        (nmr || []).length > 0 && nmr.filter(({ skillId }) => nmrFunctionalExpertsSkills.skills.find(({ id }) => id === skillId));
      nonMachineRelatedSkillAnswers = nonMachineRelatedSkillAnswers ? this.mapNMRSections(nmr) : [];
    }
    if (isEmployee || type === 'lineManager') {
      nonMachineRelatedSkillAnswers = (nmr || []).length > 0 ? this.mapNMRSections(nmr) : [];
      machineModelAnswers = this.mapMRSections(mr);
    }

    if (this.isOnlyAdmin && type === 'nonAssessor' && !machineModelAnswers) {
      machineModelAnswers = this.mapMRSections(mr);
    }

    if (this.isOnlyAdmin && type === 'functionalExpert' && !machineModelAnswers) {
      machineModelAnswers = this.mapMRSections(mr);
    }

    const dfR: InternalTrainerRecommendationDto = { comment: '', forFactoryInteralTrainer: false, forGSCInteralTrainer: false };

    const newItem: ChangeTnaFormAnswerDto = {
      ...rest,
      considerForFactoryInteralTrainer: considerForFactoryInteralTrainer || false,
      considerForGSCInteralTrainer: considerForGSCInteralTrainer || false,
      recommendationOfExpertForInternalTrainer: recommendationOfExpertForInternalTrainer || dfR,
      recommendationOfManagerForInternalTrainer: recommendationOfManagerForInternalTrainer || dfR,
      id: tnaFormId,
      machineModelAnswers,
      nonMachineRelatedSkillAnswers
    };
    return newItem;
  };

  componentDidMount() {
    document.querySelector('.planit-main-container').classList.add('employee-background');
    this.load();
  }

  getTheoreticalTests = async (id: string): Promise<QueryResult<TnaTheoreticalTestItemDto>> => {
    try {
      return await this.props.tnaTheoreticalStore.getTheoreticalsByFormId(query, id);
    } catch (error) {
      console.error({ error });
    }
  };

  getTheoreticalFormRel = (tna: TnaFormItemDto, theoreticals: TnaTheoreticalTestItemDto[], machineIds: string[]): TheoreticalRel[] => {
    const theoreticalTest: TheoreticalRel[] = [];
    machineIds = (machineIds || []).reduce((prev, curr) => (prev.includes(curr) ? prev : [...prev, curr]), []); //remove duplicates
    (machineIds || []).forEach(id => {
      let machineModel: MachineModelDto = undefined;
      if (tna?.tnaAssessment?.type === 'SameMachineHierarchy')
        machineModel = tna?.tnaAssessment?.tnaTemplate?.machineModels?.find(({ id: mmId }) => mmId === id);
      else if (tna?.tnaAssessment?.type === 'MultipleMachineHierarchy')
        machineModel = tna?.machineModelsSelection?.find(x => x.machineModelId === id)?.machineModel;

      if (!machineModel) return;
      const theoreticalTests = (theoreticals || []).filter(
        ({ tnaDetails: { clusterId, equipmentTypeId, oemId, machineModelId } }) =>
          clusterId === machineModel?.clusterId &&
          equipmentTypeId === machineModel?.equipmentTypeId &&
          (!!oemId ? machineModel?.oemId === oemId : true) &&
          (!!machineModelId ? machineModelId === id : true)
      );
      theoreticalTest.push({ id, theoreticalTests });
    });
    return theoreticalTest;
  };

  load = async () => {
    const id = '' + this.props.match.params['id'];
    const tna = await this.getTnaForm(id);
    let answers = await this.getTnaFormAnswers(id);
    if (!this.isInRoleEmployee()) {
      this.getTheoreticalTests(id).then(ttResponse => {
        const machineModels = (answers.machineModelAnswers || []).map(({ machineModelId }) => machineModelId);
        const theoreticals = (ttResponse?.items || []).length > 0 && this.getTheoreticalFormRel(tna, ttResponse.items, machineModels);
        (ttResponse?.items || []).forEach(({ finishedAt }) => finishedAt && this.setState({ theoreticals }));
      });
    }

    this.tnaFormStore.changeAnswers(answers);
    const item = this.mapToNewRawItem(id, answers);
    this.changeTnaFormAnswerStore.change({ ...item });
  };

  answerHandler = (
    change: number | string,
    questionId: string,
    skillId: string,
    machineUnitId: string = null,
    machineModelId: string = null,
    comment: boolean = false
  ) => {
    const answers: TnaFormAnswersDto = this.tnaFormStore.state.item.answers;
    let currentUserEdit = null;
    const currentAssessorType = this.getAssessorType();
    if (this.isInRoleEmployee()) currentUserEdit = 'employeeAnswer';
    if (currentAssessorType.type === 'lineManager') currentUserEdit = 'lineManagerAnswer';
    if (currentAssessorType.type === 'functionalExpert') currentUserEdit = 'functionalExpertAnswer';

    if (machineUnitId && machineModelId) {
      const currentMachineModel = answers.machineModelAnswers.find(mModelSection => mModelSection.machineModelId === machineModelId);
      const currentMachineUnit = currentMachineModel.machineUnitAnswers.find(mUnitSection => mUnitSection.machineUnitId === machineUnitId);
      const currentMachineUnitSkill = currentMachineUnit.skillAnswers.find(skills => skills.skillId === skillId);
      const currentQuestion = currentMachineUnitSkill.questionAnswers.find(question => question.questionId === questionId);

      if (!comment) currentQuestion[currentUserEdit] = { ...currentQuestion[currentUserEdit], answer: change };
      else currentQuestion[currentUserEdit] = { ...currentQuestion[currentUserEdit], comment: change };

      this.tnaFormStore.changeAnswers(answers);
    } else {
      const nonMachineSection = answers.nonRelatedMachineSkillAnswers.find(nmrSection => nmrSection.skillId === skillId);
      const nonMachineQuestions = nonMachineSection.questionAnswers.find(question => question.questionId === questionId);

      if (!comment) nonMachineQuestions[currentUserEdit] = { ...nonMachineQuestions[currentUserEdit], answer: change };
      else nonMachineQuestions[currentUserEdit] = { ...nonMachineQuestions[currentUserEdit], comment: change };
      this.tnaFormStore.changeAnswers(answers);
    }
    this.onAnswerChangeStore(change, questionId, skillId, machineUnitId, machineModelId, comment);
  };

  onAnswerChangeStore = (
    change: number | string,
    questionId: string,
    skillId: string,
    machineUnitId?: string,
    machineModelId?: string,
    comment?: boolean
  ) => {
    const newChange = this.changeTnaFormAnswerStore.state.item;
    const { machineModelAnswers, nonMachineRelatedSkillAnswers } = newChange;
    let currentEdit = null;
    if (!comment) {
      currentEdit = 'answer';
      change = change === 5 ? -1 : change;
    } else currentEdit = 'comment';
    if (machineUnitId && machineModelId) {
      // MACHINE RELATED ANSWERS
      const machineModelSection = machineModelAnswers.find(mMAnswer => mMAnswer.machineModelId === machineModelId);

      const machineModelUnit = machineModelSection.machineUnitAnswers.find(mMSection => mMSection.machineUnitId === machineUnitId);

      const machineSkillQuestion = machineModelUnit.skillAnswers.find(skill => skill.skillId === skillId);

      const changeQuestion = machineSkillQuestion.questionAnswers.find(question => question.questionId === questionId);

      changeQuestion[currentEdit] = change;

      this.onChange('machineModelAnswers', machineModelAnswers);
    } else {
      // NON-MACHINE RELATED ANSWERS
      const nonMachineSection = nonMachineRelatedSkillAnswers.find(nmrSection => nmrSection.skillId === skillId);
      const nonMachineQuestions = nonMachineSection.questionAnswers.find(question => question.questionId === questionId);

      nonMachineQuestions[currentEdit] = change;

      this.onChange('nonMachineRelatedSkillAnswers', nonMachineRelatedSkillAnswers);
    }
  };

  recommendationHandler = (value: string, id: string, isMachineUnit: boolean, machineModel: string = null) => {
    const { answers }: { answers: TnaFormAnswersDto } = this.tnaFormStore.state.item;
    this.onStoredFERecommendationChangeHandler(value, id, isMachineUnit, machineModel);
    let { nonRelatedMachineSkillAnswers, machineModelAnswers } = answers;
    if (machineModel) {
      // MACHINE RELATED FUNCTIONAL EXPERT RECOMMENDATION CHANGE
      machineModelAnswers = machineModelAnswers.map(machineModelAnswer => {
        if (machineModelAnswer.machineModelId === machineModel) {
          if (isMachineUnit)
            machineModelAnswer.machineUnitAnswers.find(({ machineUnitId }) => machineUnitId === id).functionalExpertRecomendation = value;
          else
            machineModelAnswer.machineUnitAnswers = machineModelAnswer.machineUnitAnswers.map(machineUnitAnswers => {
              machineUnitAnswers.skillAnswers.find(x => x.skillId === id).functionalExpertRecomendation = value;
              return machineUnitAnswers;
            });
        }
        return machineModelAnswer;
      });
    } else {
      // NON-MACHINE RELATED FUNCTIONAL EXPERT RECOMMENDATION CHANGE
      nonRelatedMachineSkillAnswers = nonRelatedMachineSkillAnswers.map(nonRelatedMachineSkillAnswer => {
        if (nonRelatedMachineSkillAnswer.skillId === id) nonRelatedMachineSkillAnswer.functionalExpertRecomendation = value;
        return nonRelatedMachineSkillAnswer;
      });
    }

    this.tnaFormStore.changeAnswers({ ...answers, nonRelatedMachineSkillAnswers, machineModelAnswers });
  };

  overallRecommendationHandler = (value: ItemReference, machineModel: string) => {
    const { answers }: { answers: TnaFormAnswersDto } = this.tnaFormStore.state.item;
    this.onStoredFEOverallRecommendationChangeHandler(value, machineModel);
    let { machineModelAnswers } = answers;

    if (machineModel) {
      machineModelAnswers = machineModelAnswers.map(machineModelAnswer => {
        if (machineModelAnswer.machineModelId === machineModel) {
          machineModelAnswer.overallRecommendationId = value ? value.id : null;
          machineModelAnswer.overallRecommendation = value ? value.title : null;
        }
        return machineModelAnswer;
      });
    }

    this.tnaFormStore.changeAnswers({ ...answers, machineModelAnswers });
  };

  onStoredFEOverallRecommendationChangeHandler = (value: ItemReference, machineModel: string) => {
    const { item } = this.changeTnaFormAnswerStore.state;
    let { machineModelAnswers: storeMRAnswers } = item;
    if (machineModel) {
      storeMRAnswers = storeMRAnswers.map(storeMRAnswer => {
        if (storeMRAnswer.machineModelId === machineModel) {
          storeMRAnswer.overallRecommendationId = value ? value.id : null;
          storeMRAnswer.overallRecommendation = value ? value.title : null;
        }
        return storeMRAnswer;
      });
    }

    this.changeTnaFormAnswerStore.change({ ...item, machineModelAnswers: storeMRAnswers });
  };

  onStoredFERecommendationChangeHandler = (value: string, id: string, isMachineUnit: boolean, machineModel: string = null) => {
    const { item } = this.changeTnaFormAnswerStore.state;
    let { machineModelAnswers: storeMRAnswers, nonMachineRelatedSkillAnswers } = item;
    if (machineModel) {
      storeMRAnswers = storeMRAnswers.map(storeMRAnswer => {
        if (storeMRAnswer.machineModelId === machineModel) {
          if (isMachineUnit)
            storeMRAnswer.machineUnitAnswers.find(({ machineUnitId }) => machineUnitId === id).functionalExpertRecomendation = value;
          else
            storeMRAnswer.machineUnitAnswers = storeMRAnswer.machineUnitAnswers.map(machineUnitAnswer => {
              machineUnitAnswer.skillAnswers.find(({ skillId }) => skillId === id).functionalExpertRecomendation = value;
              return machineUnitAnswer;
            });
        }
        return storeMRAnswer;
      });
    } else {
      nonMachineRelatedSkillAnswers = nonMachineRelatedSkillAnswers.map(nonMachineRelatedSkillAnswer => {
        if (nonMachineRelatedSkillAnswer.skillId === id) nonMachineRelatedSkillAnswer.functionalExpertRecomendation = value;
        return nonMachineRelatedSkillAnswer;
      });
    }
    this.changeTnaFormAnswerStore.change({ ...item, machineModelAnswers: storeMRAnswers, nonMachineRelatedSkillAnswers });
  };

  componentWillUnmount() {
    document.querySelector('.planit-main-container').classList.remove('employee-background');
  }

  submittable = () => {
    if (!this.changeTnaFormAnswerStore.state.item) return;

    const assessorType = this.getAssessorType();
    const { mrSkills, nmrFunctionalExpertsSkills } = assessorType;
    const { machineModelAnswers, nonMachineRelatedSkillAnswers } = this.changeTnaFormAnswerStore.state.item;
    const nmrIsValid =
      nonMachineRelatedSkillAnswers &&
      nonMachineRelatedSkillAnswers.all(({ questionAnswers }) => questionAnswers.all(({ answer }) => answer != null));

    const form = this.tnaFormStore.state.item as TnaFormItemDto;
    let mrIsValid = false;

    if (form.tnaAssessment.type === 'MultipleMachineHierarchy' && assessorType.type === 'functionalExpert' && assessorType.mrSkills) {
      const neededMachines = form.machineFunctionalExperts.find(x => x.userId === assessorType.userId)?.machineModels?.map(x => x.id);
      mrIsValid =
        neededMachines &&
        machineModelAnswers &&
        machineModelAnswers
          .filter(x => neededMachines.includes(x.machineModelId))
          .all(({ machineUnitAnswers }) =>
            machineUnitAnswers.all(({ skillAnswers }) =>
              skillAnswers.all(({ questionAnswers }) => questionAnswers.all(({ answer }) => answer != null))
            )
          );
    } else {
      mrIsValid =
        machineModelAnswers &&
        machineModelAnswers.all(({ machineUnitAnswers }) =>
          machineUnitAnswers.all(({ skillAnswers }) =>
            skillAnswers.all(({ questionAnswers }) => questionAnswers.all(({ answer }) => answer != null))
          )
        );
    }

    if (mrSkills) {
      if (nmrFunctionalExpertsSkills) {
        const nmrSkills =
          (nonMachineRelatedSkillAnswers || []).length > 0 &&
          nonMachineRelatedSkillAnswers.filter(storedMRAnswers =>
            nmrFunctionalExpertsSkills.skills.find(skill => skill.id === storedMRAnswers.skillId)
          );
        const nmrSkillsValid = nmrSkills && nmrSkills.all(skills => skills.questionAnswers.all(a => a.answer != null));

        return mrIsValid && nmrSkillsValid;
      }
      return mrIsValid;
    } else if (!mrSkills && nmrFunctionalExpertsSkills) {
      const nmrSkills =
        (nonMachineRelatedSkillAnswers || []).length > 0 &&
        nonMachineRelatedSkillAnswers.filter(storedMRAnswers =>
          nmrFunctionalExpertsSkills.skills.find(skill => skill.id === storedMRAnswers.skillId)
        );
      const nmrSkillsValid =
        nmrSkills &&
        nmrSkills.all(skills => {
          return skills.questionAnswers.all(a => a.answer != null);
        });

      return nmrSkillsValid;
    }

    return nmrIsValid && mrIsValid;
  };

  modStatus = (): boolean => {
    const item: TnaFormItemDto = this.tnaFormStore.state.item;
    if (!item) return;
    const employee = this.isInRoleEmployee();
    const assessor = this.isAssessor();
    if (item.status !== 'Done') {
      if (employee && item.status === 'Employee') return true;
      if (assessor && item.status === 'Assessors') return true;
    } else return;
  };

  getRemainingItems = (assessorInfo: AssessorType, isAdminOrPoC: boolean = false) => {
    if (!assessorInfo || isAdminOrPoC) return;
    const { type, mrSkills, nmrFunctionalExpertsSkills: nmrFE } = assessorInfo;
    if (!this.props.tnaFormListStore.state.item.answers) return;
    const form: TnaFormItemDto = this.props.tnaFormListStore.state.item as TnaFormItemDto;
    const answers: TnaFormAnswersDto = form.answers;
    const model: RemainingQuestionsModel = { general: { skills: [] }, machines: null, totalMR: '', totalNMR: '' };
    let totalNMR = 0;
    let countNMR = 0;
    const roleUserTarget =
      type === 'lineManager' ? 'lineManagerAnswer' : type === 'functionalExpert' ? 'functionalExpertAnswer' : 'employeeAnswer';
    (answers.nonRelatedMachineSkillAnswers || []).forEach(({ skillName, questionAnswers, skillId }) => {
      const nmrTarget = (nmrFE?.skills || []).find(x => x.id === skillId) == null;
      if (nmrFE && nmrTarget) return;
      const total = (questionAnswers || []).length;
      const count = (questionAnswers || []).filter(x => x[roleUserTarget]?.answer != null)?.length;
      totalNMR += total;
      countNMR += count;
      model.general.skills.push({ name: skillName, id: skillId, remaining: `${count}/${total}` });
    });
    if (type === 'functionalExpert' && mrSkills && !nmrFE) model.general.skills = [];
    model.totalNMR = `${countNMR}/${totalNMR}`;

    if (mrSkills || type === 'lineManager' || type === 'nonAssessor') {
      let totalMR = 0;
      let countMR = 0;
      model.machines = [];
      (answers.machineModelAnswers || []).forEach(({ machineModel, machineUnitAnswers, machineModelId }) => {
        if (
          assessorInfo.type === 'functionalExpert' &&
          mrSkills &&
          form.tnaAssessment.type === 'MultipleMachineHierarchy' &&
          !form.machineFunctionalExperts.any(x => x.userId === assessorInfo.userId && x.machineModels.any(m => m.id === machineModelId))
        )
          return;
        const machineUnits: MachineUnitRemainingModel[] = [];
        let totalMModel = 0;
        let countMModel = 0;
        (machineUnitAnswers || []).forEach(({ machineUnitName, skillAnswers, machineUnitId }) => {
          let totalMUnit = 0;
          let countMUnit = 0;
          const skills = (skillAnswers || []).map(({ questionAnswers, skillName, skillId }) => {
            const total = (questionAnswers || []).length;
            const count = (questionAnswers || []).filter(x => x[roleUserTarget]?.answer != null)?.length;
            totalMUnit += total;
            countMUnit += count;
            return { name: skillName, id: skillId, remaining: `${count}/${total}` };
          });
          totalMModel += totalMUnit;
          countMModel += countMUnit;

          machineUnits.push({ machineUnit: machineUnitName, id: machineUnitId, skills, totalMU: `${countMUnit}/${totalMUnit}` });
        });
        totalMR += totalMModel;
        countMR += countMModel;
        model.machines.push({ machineModel, id: machineModelId, machineUnits, totalMM: `${countMModel}/${totalMModel}` });
      });
      model.totalMR = `${countMR}/${totalMR}`;
    }

    return model;
  };

  getAStatus = (): AssessorAnswerStatus =>
    (this.props.tnaFormListStore.state.item?.assessorAnswerStatus || []).find((x: any) => x.userId === this.identityService.userId) ||
    nonAssessorStatus;

  getAnwersStatus = (): AssessorAnswerStatus[] => this.props.tnaFormListStore.state.item?.assessorAnswerStatus || [];

  onDismiss = () => {
    this.props.changeTnaAnswerForm.clearMessages();
    this.props.tnaFormListStore.clearMessages();
  };

  onExportExcel = () => {
    const id = '' + this.props.match.params['id'];
    this.setState({ loading: true });

    this.changeTnaFormAnswerStore
      .exportToExcel(id)

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
        this.setState({ loading: false });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
        this.setState({ loading: false });
      });
  };

  private onSendStatusBack = async (status: string) => {
    if (status === 'Employee') {
      this.setState({
        showStatusBackModalText: 'Are you sure to send this form back to Employee Status?',
        showStatusBackModal: true,
        statusSelected: status
      });
    } else if (status === 'Assessors') {
      this.setState({
        showStatusBackModalText: 'Are you sure to send this form back to Assesors Status?',
        showStatusBackModal: true,
        statusSelected: status
      });
    }
  };

  private hideConfirmModal = () => {
    this.setState({ showStatusBackModal: false, showStatusBackModalText: '', statusSelected: '' });
  };

  private hideQualifiersModal = () => {
    this.setState({ showQualifiersSelector: false });
  };

  changeTnaFormStatus = async (sendQualifier: boolean = false) => {
    this.setState({ loading: true });
    try {
      const { t } = this.props;
      const form = this.tnaFormStore?.state?.item as TnaFormItemDto;
      if (form) {
        if (this.state.statusSelected === 'Employee') {
          const qualifiers = form?.assessorAnswerStatus?.map(x => x.userId) || [];
          const result = await this.props.changeTnaAnswerForm.sendBackStatus(this.state.statusSelected, form.id, qualifiers);
          if (result) {
            this.hideConfirmModal();
            ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
            window.location.reload();
          }
        } else if (this.state.statusSelected === 'Assessors') {
          if (sendQualifier) {
            const result = await this.props.changeTnaAnswerForm.sendBackStatus(
              this.state.statusSelected,
              form.id,
              this.state.qualifiersSelectedToBackStatus
            );
            if (result) {
              this.hideQualifiersModal();
              this.hideConfirmModal();
              ToastComponent({ type: 'success-toast', text: t('Form successfully saved!') });
              window.location.reload();
            }
          } else {
            this.setState({ showQualifiersSelector: true, qualifiersSelectedToBackStatus: [] });
          }
        }
      }
    } catch (e) {
      console.dir(e);
      console.error(e, 'There was a problem changing status');
    }
    this.setState({ loading: false });
  };

  handleAssesorToSendBackToQualifier(qualifier: AssessorAnswerStatus, checked: boolean) {
    let qualifiers = this.state.qualifiersSelectedToBackStatus;
    if (checked) {
      qualifiers.push(qualifier.userId);
    } else {
      qualifiers = qualifiers.filter(x => x !== qualifier.userId);
    }
    this.setState({ qualifiersSelectedToBackStatus: qualifiers });
  }

  getAssesorName(userId: string): string {
    const form = this.tnaFormStore?.state?.item as TnaFormItemDto;
    if (form) {
      if (form.lineManagerId === userId) {
        return form.lineManager.firstName + ' ' + form.lineManager.lastName + ' - Line Manager';
      } else if (form.machineFunctionalExpertId === userId) {
        return form.machineFunctionalExpert.firstName + ' ' + form.machineFunctionalExpert.lastName + ' - Machine Functional Expert';
      } else if (form.machineFunctionalExperts && form.machineFunctionalExperts.length !== 0) {
        let funcexpert = form.machineFunctionalExperts.filter(x => x.userId === userId);
        if (funcexpert && funcexpert.length > 0)
          return funcexpert[0].user.firstName + ' ' + funcexpert[0].user.lastName + ' - Machine Functional Expert';
      } else if (form.nonMachineFunctionalExperts && form.nonMachineFunctionalExperts.length !== 0) {
        let nonFuncexpert = form.nonMachineFunctionalExperts.filter(x => x.userId === userId);
        if (nonFuncexpert && nonFuncexpert.length > 0)
          return nonFuncexpert[0].user.firstName + ' ' + nonFuncexpert[0].user.lastName + ' - Non Machine Functional Expert';
      }
    }
    return '';
  }

  getQualifiersToReset() {
    const { tnaFormListStore } = this.props;
    const { state } = tnaFormListStore;

    let qualifiers: AssessorAnswerStatus[] = [];

    if (state.item?.assessorAnswerStatus && state.item?.assessorAnswerStatus.length !== 0) {
      qualifiers = state.item?.assessorAnswerStatus;
    }

    if ((qualifiers || []).length !== 0 && state.item?.bypassLineManagerEvaluation) {
      const lineManagerId = state.item?.lineManagerId;
      qualifiers = qualifiers.filter(e => e.userId !== lineManagerId);
    }

    return qualifiers.filter(e => e.answerStatus === 'PendingRecommendation' || e.answerStatus === 'Done');
  }

  handleScroll = e => {
    const myDiv = document.getElementById('tnaLanguageSelector');
    const scroll = e.target.scrollTop;
    if (scroll >= 300) {
      myDiv.style.position = 'absolute';
      myDiv.style.top = '0px';
      myDiv.style.right = '0px';
      myDiv.style.transform = `translateY(60px)`;
    } else {
      myDiv.removeAttribute('style');
    }
  };

  public render() {
    const {
      submittable,
      modStatus,
      onSave,
      onChange,
      onSend,
      onSendStatusBack,
      getAssessorType,
      getAStatus,
      getAnwersStatus,
      answerHandler,
      recommendationHandler,
      overallRecommendationHandler,
      copyAnswersFromMachinesWithSameTemplate
    } = this;
    const { theoreticals, loading, showStatusBackModal, showQualifiersSelector } = this.state;
    const { t, tnaFormListStore } = this.props;
    const isAdminOrPoC = this.isInRoleAdminOrPoC();
    const isPoC = this.isInRolePoC();
    const isOnlyAdmin = this.isOnlyAdmin();
    const isAssessor = this.isAssessor();
    const isEmployee = this.isInRoleEmployee();
    const isAdmin = this.isInRoleAdmin();
    const isDisabledButton = this.isDisabledButton();
    const { isBusy: fetchingAnswers, item: changeStoreItem, result } = this.changeTnaFormAnswerStore.state;
    const { state } = tnaFormListStore;
    const assessorType = state.item?.answers && this.getAssessorType();
    const remainingQuestions = !isAdminOrPoC && this.getRemainingItems(assessorType);
    const bypassEmployeeEvaluation = this.bypassEmployeeEvaluation();
    let ctxValues: any = {
      submittable,
      modStatus,
      onSave,
      onChange,
      onSend,
      onSendStatusBack,
      getAssessorType,
      getAStatus,
      getAnwersStatus,
      answerHandler,
      copyAnswersFromMachinesWithSameTemplate
    };
    ctxValues = {
      ...ctxValues,
      recommendationHandler,
      isAdminOrPoC,
      isPoC,
      isAdmin,
      isAssessor,
      isEmployee,
      isDisabledButton,
      overallRecommendationHandler,
      isOnlyAdmin,
      bypassEmployeeEvaluation
    };

    if (!isAdminOrPoC && !isEmployee && !isAssessor && !state.isBusy)
      return <NotAvailable textToShow={'This TNA is no longer available'} />;

    return (
      <>
        <h2 className="edit-form__title">{(state.item.tnaAssessment?.title || '').toUpperCase()}</h2>
        {state.result && !state.result.isSuccess && (
          <Message className="error-message__style" icon="exclamation circle" error list={state.result.messages.map(m => m.body)} />
        )}
        <Dimmer active={loading || state.isBusy || fetchingAnswers} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        {result && !result.isSuccess && (result?.messages || []).length > 0 && (
          <Message onDismiss={this.onDismiss} error list={result.messages.map(m => m.body)} />
        )}
        {state.item.user && (
          <Form reply>
            <div onScroll={this.handleScroll} className="form__all-wrapper tna-form">
              <TNAFormContext.Provider value={ctxValues}>
                <TnaFormHeaderComponent
                  remainingQuestions={remainingQuestions}
                  isNotEmployee={!isEmployee}
                  assessorType={this.getAssessorType()}
                  assessorAnswerStatus={this.getAStatus()}
                  isAdminOrPoC={isAdminOrPoC}
                  onAnswerChange={(answer, questionId, skillId, machineUnitId, machineModelId, comment) =>
                    this.onAnswerChangeStore(answer, questionId, skillId, machineUnitId, machineModelId, comment)
                  }
                />

                <main className="form__all-wrapper__main-content scroll">
                  {state.item?.answers && (
                    <TNAFormComponent theoreticals={theoreticals} onExportExcel={this.onExportExcel} tnaFormListStore={tnaFormListStore} />
                  )}
                </main>

                {changeStoreItem && <TnaFormFooterComponent />}
              </TNAFormContext.Provider>
            </div>
          </Form>
        )}
        <div>
          <Modal
            open={showStatusBackModal}
            size="mini"
            className="have-warnings-popup"
            closeOnEscape={true}
            onClose={this.hideConfirmModal}
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{this.state.showStatusBackModalText}</p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
              <Button
                className="have-warnings-popup__btn have-warnings-popup__pending-btn"
                content={t('Yes')}
                onClick={() => this.changeTnaFormStatus()}
              />
            </div>
          </Modal>
        </div>
        <div>
          <Modal open={showQualifiersSelector} closeOnEscape={true} onClose={this.hideQualifiersModal} closeOnDimmerClick={false}>
            <Dimmer active={this.state.loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <Loader indeterminate>{t('')}</Loader>
            </Dimmer>
            <Modal.Header className="modal__header">{t('Select qualifiers to reset qualifications')}</Modal.Header>
            <Modal.Content image>
              <Container>
                <Form>
                  {this.getQualifiersToReset().map((qualifier: AssessorAnswerStatus, i) => (
                    <Form.Group key={i} widths="equal">
                      <Form.Checkbox
                        style={{ top: 25 }}
                        label={this.getAssesorName(qualifier.userId)}
                        placeholder={''}
                        onChange={(e, { checked }) => {
                          this.handleAssesorToSendBackToQualifier(qualifier, checked);
                        }}
                      />
                    </Form.Group>
                  ))}
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.hideQualifiersModal} basic>
                {t('Cancel')}
              </Button>
              <Button
                disabled={this.state?.qualifiersSelectedToBackStatus?.length === 0}
                onClick={() => this.changeTnaFormStatus(true)}
                positive
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </>
    );
  }
}

export default withTranslation()(ChangeTnaFormView);
