import { WithTranslation, withTranslation } from 'react-i18next';
import { EventChatStore } from 'stores/events/events-chat-store';
import { IdentityService, IUserInfo } from 'services/identity-service';
import { connect } from 'redux-scaffolding-ts';
import { Component, Fragment } from 'react';
import { resolve } from 'inversify.config';
import { Form, Button, Comment, Dimmer, Segment, Loader } from 'semantic-ui-react';
import React from 'react';

import { DateTimeService } from 'services/datetime-service';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

interface EventsChatViewProps extends WithTranslation {
  onClose?: () => void;
  chat?: EventChatStore;
  readonly?: boolean;
  initialId: string;
  initialChannel: string;
  forceReload?: boolean;
}

export interface EventsChatState {
  eventId: string;
  messageToSend: string;
  currentUserInfo: IUserInfo;
  channel: string;
  loading: boolean;
  sending: boolean;
}

@connect(['chat', EventChatStore])
class EventsChat extends Component<EventsChatViewProps, EventsChatState> {
  constructor(props) {
    super(props);
    this.state = {
      channel: this.props.initialChannel,
      eventId: this.props.initialId,
      messageToSend: '',
      currentUserInfo: this.identityService.getUserInfo(),
      loading: true,
      sending: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  async componentDidUpdate(nextProps: EventsChatViewProps) {
    if (this.props.forceReload !== nextProps.forceReload) {
      setTimeout(async () => {
        this.setState({ loading: true });
        this.props.chat.reset();
        await this.props.chat.loadChat(this.state.eventId, this.state.channel, !this.props.readonly);
        this.setState({ loading: false });
      }, 500);
    }
  }
  async componentDidMount() {
    this.props.chat.reset();
    await this.props.chat.loadChat(this.state.eventId, this.state.channel, !this.props.readonly);
    this.setState({ loading: false });
  }

  sendMessage = () => {
    const { eventId, channel } = this.state;
    const { t } = this.props;
    if (this.state.sending || isNullOrWhiteSpaces(this.state.messageToSend)) return;

    const messageDto = {
      eventId,
      channel,
      timestamp: DateTimeService.toString(DateTimeService.now()),
      message: this.state.messageToSend
    };
    this.setState({ messageToSend: t('Sending...'), sending: true });

    this.props.chat.addMessage(messageDto).then(response => {
      this.setState({ messageToSend: '', sending: false });
      let eventChatMessagesList = document.getElementById('chatEventScroll');
      eventChatMessagesList.scrollIntoView({ behavior: 'smooth' });
      eventChatMessagesList.scrollTop = eventChatMessagesList.scrollHeight;
    });
  };

  keypress = e => {
    if (e.key === 'Enter') {
      this.sendMessage();
    }
  };

  public render() {
    const { t, chat, readonly } = this.props;
    const guidEmpty = '00000000-0000-0000-0000-000000000000';
    return (
      <>
        <Dimmer.Dimmable as={Segment} dimmed>
          <Dimmer active={this.state.loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading...')}</Loader>
          </Dimmer>
          <div id="chatEventScroll" className="chat-div scroll">
            <Comment.Group>
              <li>
                {(chat.state.messages || []).map(m => (
                  <Fragment key={m?.id}>
                    {m.fromUsername === this.state.currentUserInfo.userName &&
                      m.channel === this.state.channel &&
                      m.fromUserId !== guidEmpty && (
                        <ul className="comment-own-color">
                          <Comment>
                            <Comment.Content key={m.id}>
                              <Comment.Author></Comment.Author>
                              <Comment.Text>{m.message}</Comment.Text>
                              <Comment.Metadata className="comment-metadata">
                                {m.fromUsername}, {m.fromLastName} {m.fromFirstName}, {DateTimeService.toChatString(m.timestamp)}
                              </Comment.Metadata>
                            </Comment.Content>
                          </Comment>
                        </ul>
                      )}

                    {m.fromUsername !== this.state.currentUserInfo.userName &&
                      m.channel === this.state.channel &&
                      m.fromUserId !== guidEmpty && (
                        <ul className="comment-basic-color">
                          <Comment>
                            <Comment.Content key={m.id}>
                              <Comment.Author></Comment.Author>
                              <Comment.Text className="other-author">{m.message}</Comment.Text>
                              <Comment.Metadata className="comment-metadata">
                                {m.fromUsername}, {m.fromLastName} {m.fromFirstName}, {DateTimeService.toChatString(m.timestamp)}
                              </Comment.Metadata>
                            </Comment.Content>
                          </Comment>
                        </ul>
                      )}

                    {m.fromUserId === guidEmpty && m.channel === this.state.channel && (
                      <ul className="comment-system-color">
                        <Comment>
                          <Comment.Content key={m.id}>
                            <Comment.Author></Comment.Author>
                            <Comment.Text>{m.message}</Comment.Text>
                            <Comment.Metadata className="comment-metadata">
                              {m.fromUsername}, {DateTimeService.toChatString(m.timestamp)}
                            </Comment.Metadata>
                          </Comment.Content>
                        </Comment>
                      </ul>
                    )}
                  </Fragment>
                ))}
              </li>
            </Comment.Group>
          </div>
        </Dimmer.Dimmable>
        <Fragment>
          <Form className="events-chat__write-section" reply>
            <Form.Field>
              <div className="chat-input-div">
                {!readonly && (
                  <textarea
                    onKeyPress={this.keypress}
                    value={this.state.messageToSend}
                    wrap="hard"
                    rows={4}
                    className="chat__textarea-message"
                    onChange={value => this.setState({ messageToSend: value.target.value })}
                  />
                )}
              </div>
              <div className="chat-button-div">
                {!readonly && (
                  <Button active={!this.state.sending} className="chat-button inverted-color-btn" icon="send" onClick={this.sendMessage} />
                )}
              </div>
            </Form.Field>
          </Form>
        </Fragment>
      </>
    );
  }
}

export default withTranslation()(EventsChat);
