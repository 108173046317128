import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { SsaFormListStore, SsaFormListDto } from 'stores/assessments/forms/ssa-forms-store';
import { nameof, getProperties } from 'utils/object';
import { resolve } from 'inversify.config';
import { IdentityService, Roles } from 'services/identity-service';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Query, ItemReference, OrderDefinition, ItemState } from 'stores/dataStore';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { TableView, TableModel, TableColumn } from 'widgets/collections/table';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import { Message, Form, Dropdown, Button, Checkbox, Icon, Modal, Grid } from 'semantic-ui-react';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import LocationEditor from 'widgets/bussiness/location-editor';
import { DateTimeService } from 'services/datetime-service';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber, openInNewWindow } from 'utils/useful-functions';
import { CommandResult } from 'stores/types';
import { RouteComponentProps } from 'react-router';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface SsaFormsListProps extends WithTranslation, RouteComponentProps {
  ssaFormsStore?: SsaFormListStore;
  isUserAssessor?: boolean;
}

interface SsaFormsListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  location: ItemReference;
  creationDateFrom: string;
  creationDateTo: string;
  deadlineFrom: string;
  deadlineTo: string;
  profile: ProfileItemDto;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  ssaStatus: string;
  isInRoleEmployee: boolean;
  isInRoleAdminOrPoC: boolean;
  isInRoleAdmin: boolean;
  isInRolePoC: boolean;
  isInRoleAssessor: boolean;
  isInRoleAdminOrPocOrAssesor: boolean;
  employee: string;
  showDisabled: boolean;
  showConfirmationModal: boolean;
  parameters: any;
  someFilterOpened: boolean;
}

@connect(['ssaFormsStore', SsaFormListStore])
class SsaFormsList extends Component<SsaFormsListProps, SsaFormsListState> {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: [],
      selectedItem: null,
      showConfirmationModal: false,
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<SsaFormListDto>('createdOn'), useProfile: false }],
        skip: 0,
        take: 10,
        parameters: this.initializeParameters()
      },

      location: null,
      creationDateFrom: null,
      creationDateTo: null,
      deadlineFrom: null,
      deadlineTo: null,
      profile: null,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      isInRoleEmployee: this.isInRoleEmployee(),
      isInRoleAdminOrPoC: this.isInRoleAdminOrPoC(),
      isInRoleAdmin: this.isInRoleAdmin(),
      isInRolePoC: this.isInRolePoC(),
      ssaStatus: null,
      employee: '',
      showDisabled: false,
      parameters: this.initializeParameters(),
      isInRoleAssessor: this.props.isUserAssessor,
      isInRoleAdminOrPocOrAssesor: this.isInRoleAdminPocOrAssesor(),
      someFilterOpened: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private initializeParameters = (): any => {
    return { IsActive: 'true' };
  };

  private isInRoleEmployee(): boolean {
    return this.identityService.isInRole(Roles.Employee);
  }

  private isInRoleAdmin(): boolean {
    return IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo());
  }

  private isInRolePoC(): boolean {
    return this.identityService.isInRole(Roles.PoC);
  }

  private isGlobalPoc(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    return isGlobalPoc;
  }

  private isInRoleAdminOrPoC(): boolean {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC);
  }

  private isInRoleAdminPocOrAssesor(): boolean {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC) || this.props.isUserAssessor;
  }

  get ssaFormStore() {
    return this.props.ssaFormsStore;
  }

  private onOpenEmployee = (user: SsaFormListDto) => {
    openInNewWindow(`./employee-page/${user.userId}/${user.id}`);
  };

  private canSeeOpenSsa = (ssa: SsaFormListDto): boolean => {
    const isAssessor = this.state.isInRoleAssessor;
    return (
      this.state.isInRoleAdminOrPoC ||
      (isAssessor && ssa.status.toString() === 'Assessors') ||
      (isAssessor && ssa.status.toString() === 'Returned') ||
      (isAssessor && ssa.status.toString() === 'Review') ||
      (isAssessor && ssa.status.toString() === 'Done')
    );
  };

  private canEditSsaForm = (ssa: SsaFormListDto): boolean => {
    return this.state.isInRoleAdminOrPoC && ['Assessors', 'Review', 'Returned'].includes(ssa.status.toString());
  };

  private canDeleteItem = (ssa: SsaFormListDto): boolean => {
    if (!this.state.isInRoleAdminOrPoC) return false;
    if (ssa.status === 'Done') return false;
    return true;
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    if (!this.state.isInRoleAssessor || this.state.isInRoleAdmin)
      this.setState({ query }, () => this.ssaFormStore.getAllAsync(this.state.query));
    else {
      this.setState({ query }, () => this.ssaFormStore.getAssessorssaFormList(this.state.query));
    }
  };

  componentDidMount() {
    this.load();
  }

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  onSaveRow = async (item: SsaFormListDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.ssaFormsStore.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  onDelete = async (): Promise<CommandResult<any>> => {
    const { selectedItem } = this.state;
    if (selectedItem.state !== 'New') {
      await this.props.ssaFormsStore.deleteAsync(selectedItem.id, selectedItem.state);
      this.setState({ selectedItem: null, showConfirmationModal: false });
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  private onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/ssa/form/create' });
  };

  private onEditItem = (ssa: SsaFormListDto) => {
    openInNewWindow(`./assessments/ssa/ssa-edit-form/${ssa.id}`);
  };

  private getColumnsModel = (): TableColumn<SsaFormListDto>[] => {
    const { t } = this.props;

    let columns: TableColumn<SsaFormListDto>[] = [
      {
        title: t('SSA ID'),
        tooltipRenderer: true,
        renderer: data => data.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<SsaFormListDto>('friendlyId'),
          useProfile: false
        }
      }
    ];

    columns.push({ title: t('SSA Title'), tooltipRenderer: true, renderer: data => data?.title, selectableHeader: true });

    columns.push(
      {
        title: t('Employee'),
        tooltipRenderer: false,
        renderer: data => `${data?.user?.lastName || ''}, ${data?.user?.firstName || ''}`,
        selectableHeader: true
      },
      { title: t('SAP ID'), tooltipRenderer: false, renderer: data => `${data?.user?.employeeId}`, selectableHeader: true },
      { title: t('SF Position'), tooltipRenderer: true, renderer: data => data?.user?.sfPosition || '', selectableHeader: true },
      {
        title: t('Position Code'),
        tooltipRenderer: true,
        renderer: data => data?.user?.positionCodeItem?.code || '',
        selectableHeader: true
      },
      { title: t('Location'), tooltipRenderer: true, renderer: data => data?.user?.location?.location || '', selectableHeader: true },
      {
        title: t('Profile'),
        tooltipRenderer: false,
        renderer: data => {
          return <span className="assessment-forms-grey-tag">{data?.profileName}</span>;
        }
      }
    );

    columns.push(
      {
        title: t('Creation Date'),
        tooltipRenderer: false,
        renderer: data => {
          return <span>{DateTimeService.toDateInputString(data.createAt)}</span>;
        },
        selectableHeader: true,
        sortDefinition: {
          field: nameof<SsaFormListDto>('createdOn'),
          useProfile: false,
          active: 'Descending'
        }
      },
      {
        title: t('Deadline'),
        tooltipRenderer: false,
        renderer: data => {
          return <span>{DateTimeService.toDateInputString(data.deadline)}</span>;
        },
        selectableHeader: true,
        sortDefinition: {
          field: nameof<SsaFormListDto>('deadline'),
          useProfile: false
        }
      },
      {
        title: t('Status'),
        tooltipRenderer: false,
        renderer: ({ status, outOfDate }) => {
          if (outOfDate && status !== 'Done')
            return <span className="assessment-forms__table__tag assessment-forms-out-of-date-tag">{t('Out of Date')}</span>;
          else
            switch (status) {
              case 'Assessors':
                return <span className="assessment-forms__table__tag assessment-forms-assessors-tag">{status}</span>;
              case 'Review':
                return <span className="assessment-forms__table__tag assessment-forms-review-tag">{status}</span>;
              case 'Returned':
                return <span className="assessment-forms__table__tag assessment-forms-returned-tag">{status}</span>;
              case 'Done':
                return <span className="assessment-forms__table__tag assessment-ssa-forms-done-tag">{status}</span>;
              default:
                return null;
            }
        },
        selectableHeader: true
      }
    );

    return columns;
  };

  private handleFilterSsa = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(SsaAssessment/Title), '${input.toLowerCase()}')`];
      const friendlyId = extractFriendlyIdNumber(input, 'SSA');

      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('SSA')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }
      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const parameters = { ...this.state.query.parameters } || {};

    if (isNullOrWhiteSpaces(employee)) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, employee }), this.load);
  };

  private handleFilterProfile = (profile: ProfileItemDto) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!profile) delete activeFilters['ProfileId'];
    else activeFilters['ProfileId'] = { SsaAssessment: { SsaTemplate: { ProfileItemId: { eq: { type: 'guid', value: profile.id } } } } };

    this.setState({ activeFilters: activeFilters, profile }, this.load);
  };

  private handleFilterLocation = (location: ItemReference) => {
    let parameters = this.state.parameters;

    if (location) parameters['LocationId'] = location.id;
    else parameters = this.initializeParameters();

    const query = Object.assign(this.state.query, { skip: 0, parameters });

    this.setState(
      {
        query: query,
        location: location,
        parameters: parameters
      },
      this.load
    );
  };

  private handleFilterSsaStatus = (_, { value: ssaStatus }) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!ssaStatus) delete activeFilters['ssaStatus'];
    else if (ssaStatus !== 50) {
      const ssaString = this.returnSsaStatus(ssaStatus);
      activeFilters['ssaStatus'] = { Status: ssaString, OutOfDate: false };
    } else {
      activeFilters['ssaStatus'] = {
        not: {
          and: [{ Status: 'Done' }, { OutOfDate: true }]
        }
      };
    }

    this.setState({ activeFilters: activeFilters, ssaStatus }, this.load);
  };

  private returnSsaStatus = (status: number): string => {
    if (status === 10) return 'Assessors';
    if (status === 20) return 'Review';
    if (status === 30) return 'Returned';
    if (status === 40) return 'Done';
    if (status === 50) return 'Out of Date';
    return '';
  };

  private handleFilterCreateAt = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const changeFilters = { ...activeFilters };
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete changeFilters['creationDate'];
    } else {
      changeFilters['creationDate'] = {
        createdOn: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters: changeFilters, creationDateFrom: from, creationDateTo: to }, this.load);
  };

  private handleFilterDeadline = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const changeFilters = { ...activeFilters };
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete changeFilters['deadline'];
    } else {
      changeFilters['deadline'] = {
        Deadline: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters: changeFilters, deadlineFrom: from, deadlineTo: to }, this.load);
  };

  private handleInactiveUsers = () => {
    const { showDisabled, query, activeFilters } = this.state;
    const parameters = query.parameters || {};
    if (showDisabled) parameters['IsActive'] = `${showDisabled}`;
    else delete parameters['IsActive'];

    this.setState(
      ({ query, showDisabled }) => ({ query: { ...query, skip: 0, parameters }, activeFilters, showDisabled: !showDisabled }),
      this.load
    );
  };

  exportToExcel = async (item: SsaFormListDto) => {
    try {
      await this.props.ssaFormsStore.downloadTemplate(item);
      ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
    } catch (error) {
      console.error(error);
      ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
    }
  };

  showConfirmationModal = (selectedItem: SsaFormListDto) => {
    this.setState({ showConfirmationModal: true, selectedItem });
  };

  hideConfirmModal = () => {
    this.setState({ showConfirmationModal: false, selectedItem: null });
  };

  private onEditForm = (selectedItem: SsaFormListDto) => {
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/ssa/form/edit-wizard/' + selectedItem.id, state: selectedItem });
  };

  private getExtraActions = () => {
    const { t } = this.props;
    const { isInRoleAdminOrPoC, isInRoleAssessor } = this.state;

    let buttonMenuOptions = [];
    if (isInRoleAdminOrPoC || isInRoleAssessor) {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open SSA Form')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as SsaFormListDto));
          },
          isVisible: item => this.canSeeOpenSsa(item as SsaFormListDto)
        },
        {
          content: (
            <>
              <Icon name="eye" />
              <span className="text__bold">{t('Open Employee Card')}</span>
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onOpenEmployee(item as SsaFormListDto));
          }
        },
        {
          content: (
            <>
              <>
                <Icon name="pencil" />
                {<span className="text__bold">{t('Edit SSA form')}</span>}
              </>
            </>
          ),
          isVisible: item => this.canEditSsaForm(item as SsaFormListDto),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditForm(item as SsaFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon name="download" />
              {<span className="text__bold">{t('Export to Excel')}</span>}
            </>
          ),
          isVisible: () => true,
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.exportToExcel(item as SsaFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon color="red" name="trash alternate" />
              {<span className="text__red text__bold">{t('Delete')}</span>}
            </>
          ),
          onClick: (item: SsaFormListDto) => this.showConfirmationModal(item),
          isVisible: (item: SsaFormListDto) => this.canDeleteItem(item)
        }
      ];
    } else {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open SSA Form')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as SsaFormListDto));
          },
          isVisible: item => this.canSeeOpenSsa(item as SsaFormListDto)
        }
      ];
    }

    return buttonMenuOptions;
  };

  private canCreate = () => {
    const { isInRoleAdminOrPocOrAssesor, isInRoleAdmin, isInRolePoC, isInRoleAssessor } = this.state;
    return isInRoleAdminOrPocOrAssesor && (isInRoleAdmin || (isInRolePoC && !isInRoleAssessor));
  };

  private ssaFormStatusOptions = [
    { key: 1, text: 'Assessors', value: 10 },
    { key: 2, text: 'Review', value: 20 },
    { key: 3, text: 'Returned', value: 30 },
    { key: 4, text: 'Done', value: 40 },
    { key: 5, text: 'Out of Date', value: 50 }
  ];

  handleOnEnterKeydown = item => {
    if (this.canSeeOpenSsa(item as SsaFormListDto)) this.setState({ selectedItem: item }, () => this.onEditItem(item as SsaFormListDto));
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { employee, profile, location, ssaStatus, creationDateFrom, creationDateTo, someFilterOpened } = this.state;
    const { deadlineFrom, deadlineTo, query, isInRoleAdminOrPoC, isInRoleAssessor, showConfirmationModal } = this.state;
    const { t, ssaFormsStore } = this.props;
    const { result } = ssaFormsStore.state;
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !this.isGlobalPoc()
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: ssaFormsStore.state
    } as TableModel<SsaFormListDto>;

    return (
      <>
        <div className="question-bank__wrapper">
          <Grid className="event-types-list-grid">
            {result && !result.isSuccess && (result?.messages || []).length > 0 && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={result.messages.map(o => o.body)}
                />
              </Grid.Row>
            )}

            <div id="assessment-forms-list-filters__admin-or-poc-container">
              <div
                id="assessment-forms-list-filters__admin-or-poc-container__lef-container"
                className="specific-margin-bottom-equal-filters"
              >
                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search in Title or ID')}
                    onChange={this.handleFilterSsa}
                    key={'searchSsa'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Employee')}
                    onChange={(_, { value }) => this.handleFilterEmployee(value)}
                    value={employee || ''}
                    key={'searchEmployee'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ProfileEditor
                    placeholder={t('Profile')}
                    nullable={true}
                    assessment="SSA"
                    value={profile?.id || ''}
                    onChange={this.handleFilterProfile}
                    clearable={true}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <LocationEditor
                    loadDataOnOpen={true}
                    placeholder={t('Location')}
                    nullable
                    value={location}
                    onChange={this.handleFilterLocation}
                    clearable={true}
                    locationsReceived={pocLocations}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <Dropdown
                    className="custom-editor width-14em"
                    fluid
                    selection
                    options={this.ssaFormStatusOptions}
                    value={ssaStatus || null}
                    closeOnBlur={true}
                    closeOnEscape={true}
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    placeholder={t('SSA Status')}
                    onChange={this.handleFilterSsaStatus}
                    clearable
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={creationDateFrom}
                  toValue={creationDateTo}
                  onChange={this.handleFilterCreateAt}
                  placeholder={t('Creation Date')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadlineFrom}
                  toValue={deadlineTo}
                  onChange={this.handleFilterDeadline}
                  placeholder={t('Deadline')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </div>
              <div id="assessment-forms-list-filters__admin-or-poc-container__right-container">
                {this.canCreate() && (
                  <Button className="inverted-color-btn" onClick={this.onNewItem}>
                    {t('Create New SSA')}
                  </Button>
                )}
                {isInRoleAdminOrPoC && (
                  <Checkbox
                    className={`${(query.orderBy || []).length !== 0 ? 'orderby' : ''}`}
                    label={t('Include Inactive Users')}
                    onClick={(_e, { checked }) => {
                      this.handleInactiveUsers();
                    }}
                  />
                )}
              </div>
            </div>
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={!showConfirmationModal}
                maxSelection={1}
                onHideCheckbox={true}
                selectionType={'allRow'}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                preventEnterKeyDownEvent={someFilterOpened}
                //showActionsConfirmModal={true}
                /////////////////For build table keyboard navegation/////////////////

                isRowDisableLayout={item => !(item as any).user.enabled}
                model={tableModel}
                extraActions={this.getExtraActions()}
                onOrderByChanged={this.handleOrderBy}
                canCreateNew={false}
                onRefresh={this.load}
                canEdit={false}
                canDelete={false}
                onSaveRow={this.onSaveRow}
                onPageChange={this.handlePageChange}
                createNewButtonTitle={t('Create New SSA')}
                areActionsDisabled={item => !this.canSeeOpenSsa(item as SsaFormListDto) && !(isInRoleAdminOrPoC || isInRoleAssessor)}
              />
            </Grid.Row>
          </Grid>
        </div>

        <Modal
          centered
          open={showConfirmationModal}
          size="mini"
          className="have-warnings-popup"
          onClose={this.hideConfirmModal}
          closeOnEscape={true}
        >
          <Modal.Content className="have-warnings-popup__content">
            <p>{t('Are you sure that you want to delete the form?')}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
            <Button className="have-warnings-popup__btn have-warnings-popup__pending-btn" content={t('Yes')} onClick={this.onDelete} />
          </div>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(SsaFormsList);
