import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Accordion, Icon, Form, TextArea } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
import { ItemReference } from 'stores/dataStore';
import { PracticalQuestionDto } from 'stores/assessments/questionBank/practical-test-store';

interface QuestionTranslationProps extends WithTranslation, PracticalQuestionDto {
  index: number;
  active?: boolean;
  onRemove: () => void;
  onChange: (property: keyof PracticalQuestionDto, value: any) => void;
  onLanguageChange: (item: ItemReference) => void;
}

const TheoreticalQuestionTranslation: FC<QuestionTranslationProps> = props => {
  const { t, onChange, onRemove, onLanguageChange, index, language, text, active } = props;
  const [activeIndex, setActiveIndex] = useState(active);

  const handleClickAcordion = () => {
    setActiveIndex(!activeIndex);
  };

  const icon = `angle ${activeIndex ? 'up' : 'down'}` as any;

  return (
    <div className="translate-accordion">
      <div className="accordion-wrapper">
        <Accordion.Title className="accordion__title" index={index} active={activeIndex} onClick={handleClickAcordion}>
          {(language?.id && language?.language) ?? '| Language Name |'}
          <Icon name={icon} />
        </Accordion.Title>
        <Accordion.Content className="accordion__content" key={`ssa-form-accordion${index}`} active={activeIndex}>
          <Form.Group>
            <Form.Field required inline width={12}>
              <label>{t('Question')}</label>
              <TextArea value={text} onChange={(_, { value }) => onChange('text', value)} />
            </Form.Field>
            <Form.Field required inline width={4}>
              <LanguageEditor value={language?.id ?? null} onlyForTests onChange={onLanguageChange} />
            </Form.Field>
          </Form.Group>
        </Accordion.Content>
      </div>
      <Icon name="remove" onClick={onRemove} />
    </div>
  );
};
export default withTranslation()(TheoreticalQuestionTranslation);
