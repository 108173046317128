import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  RestrictionWarningDto,
  ChangeRestrictionWarningStore,
  ChangeRestrictionWarningDto
} from 'stores/configuration/planner-assistant/restrictions-warnings-store';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';
import VisaEditor, { VisaItemReference } from 'widgets/bussiness/visa-editor';
import ContractEditor from 'widgets/bussiness/contract-editor';
import FlightCostEditor from 'widgets/bussiness/flight-cost-editor';

interface ChangeRestrictionWarningViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeRestrictionWarning?: ChangeRestrictionWarningStore;
  currentRestrictionWarning: RestrictionWarningDto;
}

interface ChangeRestrictionWarningViewState {
  TTCLocation: ItemReference;
  TravelingLocation: ItemReference;
  Visa: VisaItemReference;
  VisaApplicationDuration: number;
  IsRestriction: boolean;
  Comments: string;
  Contract: ItemReference;
  FlightCost: ItemReference;
  userClickedSaved: boolean;
}

@connect(['changeRestrictionWarning', ChangeRestrictionWarningStore])
class ChangeRestrictionWarningView extends React.Component<ChangeRestrictionWarningViewProps, ChangeRestrictionWarningViewState> {
  private get changeRestrictionWarningStore() {
    return this.props.changeRestrictionWarning;
  }

  constructor(props: ChangeRestrictionWarningViewProps) {
    super(props);

    this.state = {
      TTCLocation: {
        id: this.props.currentRestrictionWarning.ttcLocationId,
        title: this.props.currentRestrictionWarning.ttcLocationName
      },
      TravelingLocation: {
        id: this.props.currentRestrictionWarning.travelingLocationId,
        title: this.props.currentRestrictionWarning.travelingLocationName
      },
      Visa: {
        id: this.props.currentRestrictionWarning.visaId,
        title: this.props.currentRestrictionWarning.visaName,
        visaTreatyRequired: false
      },
      VisaApplicationDuration: this.props.currentRestrictionWarning.visaApplicationDuration,
      IsRestriction: this.props.currentRestrictionWarning.isRestriction,
      Comments: this.props.currentRestrictionWarning.comments,
      Contract: {
        id: this.props.currentRestrictionWarning.contractId,
        title: this.props.currentRestrictionWarning.contractName
      },
      FlightCost: {
        id: this.props.currentRestrictionWarning.flightCostId,
        title: this.props.currentRestrictionWarning.flightCostName
      },
      userClickedSaved: false
    };

    this.changeRestrictionWarningStore.state.result = null;

    this.changeRestrictionWarningStore.change({
      Id: this.props.currentRestrictionWarning.id,
      TTCLocationId: this.props.currentRestrictionWarning.ttcLocationId,
      TravelingLocationId: this.props.currentRestrictionWarning.travelingLocationId,
      VisaId: this.props.currentRestrictionWarning.visaId,
      VisaApplicationDuration: this.props.currentRestrictionWarning.visaApplicationDuration,
      IsRestriction: this.props.currentRestrictionWarning.isRestriction,
      Comments: this.props.currentRestrictionWarning.comments,
      ContractId: this.props.currentRestrictionWarning.contractId,
      FlightCostId: this.props.currentRestrictionWarning.flightCostId
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeRestrictionWarningViewProps) {
    if (this.changeRestrictionWarningStore.state.result && this.changeRestrictionWarningStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeRestrictionWarningStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeRestrictionWarningStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.changeRestrictionWarningStore.change({ ...this.changeRestrictionWarningStore.state.item, ...change });
  }

  private handleOnTTCLocationChange = (ttcLocation: ItemReference) => {
    this.changeRestrictionWarningStore.state.item.TTCLocationId = ttcLocation ? ttcLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnTravelingLocationChange = (travelingLocation: ItemReference) => {
    this.changeRestrictionWarningStore.state.item.TravelingLocationId = travelingLocation ? travelingLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnVisaChange = (visa: VisaItemReference) => {
    this.changeRestrictionWarningStore.state.item.VisaId = visa ? visa.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnContractChange = (contract: ItemReference) => {
    this.changeRestrictionWarningStore.state.item.ContractId = contract ? contract.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnFlightCostChange = (flightCost: ItemReference) => {
    this.changeRestrictionWarningStore.state.item.FlightCostId = flightCost ? flightCost.id : null;
    this.setState({ userClickedSaved: false });
  };

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeRestrictionWarningStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Restriction')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeRestrictionWarningStore.state.result && !this.changeRestrictionWarningStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeRestrictionWarningStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeRestrictionWarningStore.state.item && (
              <Form>
                <Form.Group required widths="equal">
                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.TTCLocationId && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid
                      className={`${this.changeRestrictionWarningStore.state.item.TTCLocationId == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.TTCLocationId)} field`}
                      label={t('TTC Location')}
                    >
                      <LocationEditor nullable value={this.state.TTCLocation} onChange={this.handleOnTTCLocationChange} />
                    </Form.Input>
                  </Form.Field>

                  <Form.Field
                    required
                    error={!this.changeRestrictionWarningStore.state.item.TravelingLocationId && this.state.userClickedSaved}
                  >
                    <Form.Input
                      required
                      fluid
                      className={`${this.changeRestrictionWarningStore.state.item.TravelingLocationId == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.TravelingLocationId)} field`}
                      label={t('Traveling Location')}
                    >
                      <LocationEditor nullable value={this.state.TravelingLocation} onChange={this.handleOnTravelingLocationChange} />
                    </Form.Input>
                  </Form.Field>

                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.VisaId && this.state.userClickedSaved}>
                    <Form.Input
                      required
                      fluid
                      className={`${this.changeRestrictionWarningStore.state.item.VisaId == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.VisaId)} field`}
                      label={t('Visa Requirement')}
                    >
                      <VisaEditor
                        onChange={this.handleOnVisaChange}
                        nullable
                        clearable={true}
                        value={this.state.Visa}
                        placeholder={t('Visa')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                        readOnly={false}
                      />
                    </Form.Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field
                    required
                    error={!this.changeRestrictionWarningStore.state.item.VisaApplicationDuration && this.state.userClickedSaved}
                  >
                    <div
                      className={`required ${this.changeRestrictionWarningStore.state.item.VisaApplicationDuration == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.VisaApplicationDuration.toString())} field`}
                    >
                      <Form.Input
                        required
                        type="number"
                        min="0"
                        fluid
                        error={!this.changeRestrictionWarningStore.state.item.VisaApplicationDuration && this.state.userClickedSaved}
                        label={t('Visa application. Months duration')}
                        placeholder={t('Visa application. Months duration')}
                        value={this.changeRestrictionWarningStore.state.item.VisaApplicationDuration}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeRestrictionWarningDto>('VisaApplicationDuration'), value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.Comments && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.changeRestrictionWarningStore.state.item.Comments == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.Comments)} field`}
                    >
                      <Form.Input
                        required
                        fluid={!this.changeRestrictionWarningStore.state.item.Comments}
                        label={t('Comments')}
                        placeholder={t('Comments')}
                        value={this.changeRestrictionWarningStore.state.item.Comments}
                        onChange={(e, { value }) => this.handleValue(nameof<ChangeRestrictionWarningDto>('Comments'), value)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.IsRestriction && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.changeRestrictionWarningStore.state.item.IsRestriction == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.IsRestriction.toString())} field`}
                    >
                      <Form.Checkbox
                        style={{ top: 25 }}
                        toggle
                        checked={this.changeRestrictionWarningStore.state.item.IsRestriction}
                        label={t('Restriction')}
                        placeholder={t('Restriction')}
                        onChange={(e, { checked }) => {
                          this.handleValue(nameof<ChangeRestrictionWarningDto>('IsRestriction'), checked);
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.ContractId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.changeRestrictionWarningStore.state.item.ContractId == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.ContractId)} field`}
                    >
                      <label>{t('Contract')}</label>
                      <ContractEditor
                        onChange={this.handleOnContractChange}
                        nullable
                        clearable={true}
                        value={this.state.Contract}
                        placeholder={t('Contract')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                        readOnly={false}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.changeRestrictionWarningStore.state.item.FlightCostId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.changeRestrictionWarningStore.state.item.FlightCostId == null ||
                        isNullOrWhiteSpaces(this.changeRestrictionWarningStore.state.item.FlightCostId)} field`}
                    >
                      <label>{t('Flight Cost')}</label>
                      <FlightCostEditor
                        onChange={this.handleOnFlightCostChange}
                        nullable
                        clearable={true}
                        value={this.state.FlightCost}
                        placeholder={t('Flight Cost')}
                        reloadOnChange={true}
                        loadDataOnOpen={true}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeRestrictionWarningView);
