import React, { FC, Fragment, memo, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import GradeComponent from './tna-form-score-component';
import TNAFormScores from './tna-form-scores.component';
import {
  SkillAnswersDto,
  QuestionDto,
  UserAnswerDto,
  TnaFormItemDto,
  MachineModelAnswersDto
} from 'stores/assessments/forms/tna-forms-store';
import { scoreNames } from './score-names-constants';
import { TNAFormContext } from './tna-edit-form';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'stores/reducers';

interface TNAFormSectionComponentProps extends WithTranslation {
  skillSections: SkillAnswersDto[];
  machineModelId: string;
  onQuestionAnswered: (answer: number | string, questionId: string, skillId: string, comment: boolean) => void;
  tnaTemplateId?: string;
  otherMachinesModelAnswers?: MachineModelAnswersDto[];
}

const TNAFormSectionComponent: FC<TNAFormSectionComponentProps> = ({ t, ...props }) => {
  const { getAssessorType, isAdminOrPoC, isAssessor, isEmployee } = useContext(TNAFormContext);

  const status = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => (summary.item as TnaFormItemDto).status) as string;
  const currentLanguage = useSelector<ApplicationState>(
    ({ tnaformlist: { summary } }) => (summary?.item as TnaFormItemDto)?.currentLanguage
  ) as string;

  const assessorType = getAssessorType();
  const { skillSections, machineModelId } = props;

  const getQuestionCurrLanguage = (questionTranslations: QuestionDto[], question: QuestionDto) => {
    const neededLanguage = (questionTranslations || []).find(x => x.languageId === currentLanguage);
    return neededLanguage ? neededLanguage.text : question.text;
  };

  const getActiveGrade = (employeeAnswer: UserAnswerDto, functionalExpertAnswer: UserAnswerDto, lineManagerAnswer: UserAnswerDto) => {
    if (isEmployee && employeeAnswer != null) return employeeAnswer.answer;
    if (assessorType.type === 'lineManager' && lineManagerAnswer != null) return lineManagerAnswer.answer;
    if (assessorType.type === 'functionalExpert' && functionalExpertAnswer != null) return functionalExpertAnswer.answer;
    return null;
  };

  const itsDone = status.toString() === 'Done';
  const isLineManager = assessorType.type === 'lineManager';

  return (
    <>
      {(skillSections || []).length > 0 &&
        skillSections.map(({ skillId, skillName, questionAnswers }) => (
          <div key={machineModelId + skillId} className="tna-form__main-tab__skill-section">
            <h3>{skillName}</h3>
            {(questionAnswers || []).length > 0 &&
              questionAnswers.map(
                ({ question, questionId, employeeAnswer, functionalExpertAnswer, lineManagerAnswer, questionTranslations }, index) => {
                  employeeAnswer = employeeAnswer?.answer === -1 ? { ...employeeAnswer, answer: 5 } : employeeAnswer;
                  functionalExpertAnswer =
                    functionalExpertAnswer?.answer === -1 ? { ...functionalExpertAnswer, answer: 5 } : functionalExpertAnswer;
                  lineManagerAnswer = lineManagerAnswer?.answer === -1 ? { ...lineManagerAnswer, answer: 5 } : lineManagerAnswer;
                  return (
                    <Fragment key={machineModelId + questionId}>
                      <p className="skill-section__question">{`Question ${index + 1}. ${getQuestionCurrLanguage(
                        questionTranslations,
                        question
                      )}`}</p>

                      {!isAdminOrPoC ? (
                        <>
                          <div className="skill-section__scores-list">
                            <TNAFormScores
                              assessment="tna"
                              activeGrade={getActiveGrade(employeeAnswer, functionalExpertAnswer, lineManagerAnswer)}
                              onGradeChange={answer => !itsDone && props.onQuestionAnswered(answer, questionId, skillId, false)}
                            />

                            {isAssessor && employeeAnswer?.answer != null && (
                              <div className="flex-column assesor__result-score-container">
                                <p className="employee-self-text">{t('Employee Self-Evaluation')}</p>
                                <GradeComponent
                                  active
                                  isToggleable={false}
                                  className={'scores-list__assesor__result-score'}
                                  scoreName={t(scoreNames[employeeAnswer.answer])}
                                  scoreNumber={t(employeeAnswer.answer.toString())}
                                />
                              </div>
                            )}
                          </div>
                          {isAssessor && (
                            <div className="skill-section__assesor-comments-area">
                              <div className="flex-column">
                                <p>{t("Assessor's comments")}</p>
                                <textarea
                                  value={isLineManager ? lineManagerAnswer?.comment || '' : functionalExpertAnswer?.comment || ''}
                                  readOnly={itsDone}
                                  onChange={({ target: { value } }) => props.onQuestionAnswered(value, questionId, skillId, true)}
                                  className="skill-section__comments"
                                ></textarea>
                              </div>
                              <div className="flex-column">
                                <p className="text__grey ">{t("Employee's comments")}</p>
                                <textarea
                                  value={employeeAnswer ? employeeAnswer.comment : ''}
                                  readOnly
                                  className="skill-section__comments"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          {isEmployee && (
                            <div>
                              <p>{t('Comments')}</p>
                              <textarea
                                readOnly={itsDone}
                                value={employeeAnswer ? employeeAnswer.comment : ''}
                                onChange={({ target: { value } }) => props.onQuestionAnswered(value, questionId, skillId, true)}
                                className="skill-section__comments"
                              ></textarea>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="skill-section__poc-admin__scores-list">
                            {employeeAnswer && employeeAnswer.answer != null && (
                              <div className="flex-column">
                                <p>{t('Employee Self-Evaluation')}</p>
                                <GradeComponent
                                  active
                                  isToggleable={false}
                                  scoreName={t(scoreNames[employeeAnswer.answer])}
                                  scoreNumber={t(employeeAnswer.answer.toString())}
                                />
                              </div>
                            )}
                            {lineManagerAnswer && lineManagerAnswer.answer != null && (
                              <div className="flex-column">
                                <p>{t('Line Manager Evaluation')}</p>
                                <GradeComponent
                                  active
                                  isToggleable={false}
                                  scoreName={t(scoreNames[lineManagerAnswer.answer])}
                                  scoreNumber={t(lineManagerAnswer.answer.toString())}
                                />
                              </div>
                            )}
                            {functionalExpertAnswer && functionalExpertAnswer.answer != null && (
                              <div className="flex-column">
                                <p>{t('Functional Expert Evaluation')}</p>
                                <GradeComponent
                                  active
                                  isToggleable={false}
                                  scoreName={t(scoreNames[functionalExpertAnswer.answer])}
                                  scoreNumber={t(functionalExpertAnswer.answer.toString())}
                                />
                              </div>
                            )}
                          </div>

                          <div className="skill-section__poc-admin__comments-area">
                            <div className="flex-column">
                              <p>{t("Assessor's comment")}</p>
                              <textarea
                                readOnly
                                className="skill-section__comments"
                                value={functionalExpertAnswer?.comment || ''}
                              ></textarea>
                            </div>
                            <div className="flex-column">
                              <p>{t("Employee's comments")}</p>
                              <textarea readOnly className="skill-section__comments" value={employeeAnswer?.comment || ''}></textarea>
                            </div>
                            <div className="flex-column">
                              <p>{t("Line Manager's comments")}</p>
                              <textarea readOnly className="skill-section__comments" value={lineManagerAnswer?.comment || ''}></textarea>
                            </div>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                }
              )}
          </div>
        ))}
    </>
  );
};
export default withTranslation()(memo(TNAFormSectionComponent));
