import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, TextArea } from 'semantic-ui-react';

export interface CommentsTabPaneProps extends WithTranslation {
  comment: string;
  readOnly: boolean;
  onChange?: (value: string) => void;
}

class CommentsTabPane extends Component<CommentsTabPaneProps> {
  render() {
    const { readOnly } = this.props;

    return (
      <Form>
        <Form.Field>
          <TextArea
            rows="14"
            readOnly={readOnly}
            transparent="true"
            className="form__comment-textarea scroll-not-visible"
            value={this.props.comment}
            onChange={(e, { value }) => this.props.onChange(value as string)}
          ></TextArea>
        </Form.Field>
      </Form>
    );
  }
}

export default withTranslation()(CommentsTabPane);
