import { EventDto } from 'stores/events/events-store';
import React, { FC, memo } from 'react';
import SchedulerEventStatusIcon from './scheduler-event-status-icon';

export interface SchedulerEventProps {
  event: EventDto;
  pausePeriods?: any;
}

const SchedulerEvent: FC<SchedulerEventProps> = ({ event, pausePeriods }) => {
  const { eventUpdatedFlag, eventDetails, eventTrainingDetails, eventWarnings, status, title, requests, travelDays } = event;
  const hasBeenModified = () => eventUpdatedFlag;

  const hasWarning = () => (eventWarnings[0] !== undefined && eventWarnings[0].description !== '' ? true : false);

  const hasStudents = () => eventTrainingDetails?.assignedStudents;

  const hasCustomizationData = () => eventTrainingDetails?.customizationData;

  const pausePeriod = (pausePeriods || []).map(({ width, left }, i) => {
    return <div key={i + width + left} className="nested-pause-period" style={{ width, left }}></div>;
  });
  const e: any = event;
  const requestedLocation = e?.requestLocation || requests[0]?.requestLocation;
  const departure = e.travelStart?.from || travelDays?.from ? <i className="travel-icon b-fa b-fa-plane-departure" /> : null;
  const arrival = e.travelEnd?.to || travelDays?.to ? <i className="travel-icon b-fa b-fa-plane-arrival" /> : null;

  return (
    <>
      {departure}
      {pausePeriod}
      <div className="event-template__wrapper">
        <div className="event-template__state">
          <div className="event-template__state-section">
            <SchedulerEventStatusIcon status={status} />
          </div>
          <div className="event-template__info">
            <div className="event-template__info-title">
              <b>{title}</b>
            </div>
            <div className="event-template__info-items">
              {eventDetails?.location && (
                <div className="event-template__info-first-location">
                  <i className="b-fa b-fa-map-marker-alt"></i>
                  {eventDetails.location.location}
                </div>
              )}
              {requestedLocation && (
                <div className="event-template__info-second-location">
                  <i className="b-fa b-fa-map-marked-alt"></i>
                  {requestedLocation.location}
                </div>
              )}
              {hasCustomizationData() && (
                <div className="event-template__info-conditional-custom">
                  <i className="b-fa b-fa-pencil-ruler"></i>
                </div>
              )}
              {hasStudents() && (
                <div className="event-template__info-conditional-instructors">
                  <i className="b-fa b-fa-users"></i>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="event-template__conditionals">
          {hasWarning() && <i className="b-fa b-fa-exclamation-triangle"></i>}
          {hasBeenModified() && <i className="b-fa b-fa-circle"></i>}
        </div>
      </div>
      {arrival}
    </>
  );
};

export default memo(SchedulerEvent);
