import React, { Component } from 'react';
import StepsComponent from '../../../../../shared/tna-and-ssa/wizard/wizard-step';
import { withTranslation, WithTranslation } from 'react-i18next';
import './tna-template-step/tna-template-step.less';
import { TnaTemplatesStore } from 'stores/assessments/templates/tna-templates-store';
import { TnaFormListDto, TnaFormItemDto, TnaAssessmentType } from 'stores/assessments/forms/tna-forms-store';
import { Grid, Radio } from 'semantic-ui-react';
import TnaTemplateStep from './tna-template-step/tna-template-step';
import MultipleMachineModelsStep from './multiple-machine-models-step/multiple-machine-models-step';
import { TnaTemplateStepViewModel } from './select-machinery-step-types';

export interface SelectMachineryStepProps extends WithTranslation {
  isMachineRelatedProfile: boolean;
  isStepActive: boolean;
  value: TnaTemplateStepViewModel;
  profileId: string;
  tnaTemplatesStore?: TnaTemplatesStore;
  onChange?: (value: TnaTemplateStepViewModel) => void;
  isOnEditMode: boolean;

  dataFormEditMode: TnaFormListDto;
  itemToEdit: TnaFormItemDto;

  onTnaTypeChanged: (type: TnaAssessmentType) => void;
}

export interface SelectMachineryStepState {
  isStepActive: boolean;
  type: TnaAssessmentType;
}

class SelectMachineryStep extends Component<SelectMachineryStepProps, SelectMachineryStepState> {
  constructor(props: SelectMachineryStepProps) {
    super(props);
    this.state = {
      isStepActive: !!this.props.isStepActive,
      type: this.props.value.type
    };
  }

  UNSAFE_componentWillReceiveProps(next: SelectMachineryStepProps) {
    if (next != null && next !== this.props) {
      let change = false;
      let isStepActive = this.state.isStepActive;
      let type = this.state.type;

      if (next.isStepActive !== this.props.isStepActive) {
        isStepActive = !!next.isStepActive;
        change = true;
      }
      if (next.value.type !== this.props.value.type) {
        type = next.value.type;
        change = true;
      }
      if (change) {
        this.setState({
          isStepActive,
          type
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isMachineRelatedProfile } = this.props;

    if (this.props.profileId !== prevProps.profileId) {
      if (!isMachineRelatedProfile) this.onTnaTypeChanged('SameMachineHierarchy');
    }
  }

  private onTnaTypeChanged = (type: TnaAssessmentType) => {
    this.setState({ type }, () => this.props.onTnaTypeChanged(type));
  };

  render() {
    const { t, isOnEditMode, isMachineRelatedProfile } = this.props;
    const { isStepActive } = this.state;

    let title = isMachineRelatedProfile ? 'SELECT MACHINE MODELS' : 'SELECT TNA TEMPLATE';

    return (
      <div className="wizard__step wizard__step-two tna-form-tna-template">
        <StepsComponent active={isStepActive} number={'2'} title={title}>
          {isMachineRelatedProfile && !isOnEditMode && (
            <Grid style={{ marginBottom: 40 }}>
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column computer={4} largeScreen={3} mobile={8} tablet={5} widescreen={2}>
                  <Radio
                    label={t('Select Template')}
                    name="radioGroup"
                    value="SameMachineHierarchy"
                    checked={this.state.type === 'SameMachineHierarchy'}
                    onChange={() => this.onTnaTypeChanged('SameMachineHierarchy')}
                  />
                </Grid.Column>
                <Grid.Column computer={4} largeScreen={3} mobile={8} tablet={5} widescreen={2}>
                  <Radio
                    label={t('Select Machine Models')}
                    name="radioGroup"
                    value="MultipleMachineHierarchy"
                    checked={this.state.type === 'MultipleMachineHierarchy'}
                    onChange={() => this.onTnaTypeChanged('MultipleMachineHierarchy')}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          {(!isMachineRelatedProfile || this.state.type === 'SameMachineHierarchy') && <TnaTemplateStep {...this.props} />}
          {this.state.type === 'MultipleMachineHierarchy' && <MultipleMachineModelsStep {...this.props} />}
        </StepsComponent>
      </div>
    );
  }
}

export default withTranslation()(SelectMachineryStep);
