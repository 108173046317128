import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';

export interface RequestReasonDto extends BaseDto {
  id: string;
  name: string;
  eventTypes: RequestReasonEventTypeDto[];
}

export interface CreateRequestReasonDto {
  name: string;
  eventTypes: string[];
}

export interface RequestReasonEventTypeDto {
  originalEventTypeId: string;
  name: string;
}

export interface ChangeRequestReasonDto {
  id: string;
  name: string;
  eventTypes: string[];
}

export class RequestReasonValidator extends AbstractValidator<RequestReasonDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request Reason name is required'));
  }
}
export class CreateRequestReasonValidator extends AbstractValidator<CreateRequestReasonDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request Reason name is required'));
  }
}

export class ChangeRequestReasonValidator extends AbstractValidator<ChangeRequestReasonDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Request Reason Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Request Reason name is required'));
  }
}

@repository('@@REQUEST-REASONS', 'request-reasons.summary')
export class RequestReasonStore extends DataStore<RequestReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-request-reason';
  retrievePath = 'get-request-reasons';
  updatePath = 'update-request-reason';
  deletePath = 'delete-request-reason';

  protected validate(item: RequestReasonDto) {
    return new RequestReasonValidator().validate(item);
  }

  constructor() {
    super('REQUEST_REASON', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@REQUEST-REASONS', 'request-reasons.new')
export class NewRequestReasonStore extends FormStore<CreateRequestReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-request-reason';
  retrievePath = 'get-request-reasons';
  updatePath = 'update-request-reason';

  protected validate(item: CreateRequestReasonDto) {
    return new CreateRequestReasonValidator().validate(item);
  }

  constructor() {
    super('NEW_REQUEST_REASON', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@REQUEST-REASONS', 'request-reasons.change')
export class ChangeRequestReasonStore extends FormStore<ChangeRequestReasonDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-request-reason';
  retrievePath = 'get-request-reasons';
  updatePath = 'update-request-reason';

  protected validate(item: ChangeRequestReasonDto) {
    return new ChangeRequestReasonValidator().validate(item);
  }

  constructor() {
    super('CHANGE_REQUEST_REASON', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
