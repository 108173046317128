import React, { Component, ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SsaSkillAnswersDto, SsaFormStatus, AssessorAnswerStatusDto } from 'stores/assessments/forms/ssa-forms-store';
import { Button, Accordion, AccordionTitleProps, TextArea } from 'semantic-ui-react';
import SsaFormCheckpointComponent from './ssa-form-checkpoint-component';
import { guidIsNullOrEmpty } from 'utils/useful-functions';
import ScoreComponent from '../../tna/forms/tna-form-scores.component';

interface SSAFormSkillComponentProps extends WithTranslation {
  skillIndex: number;
  active: number;
  onSkillToggle: (i: number) => void;
  onChange: (skill: string, change: any) => void;
  skillSection: SsaSkillAnswersDto;
  currentLanguage: string;
  assessorAnswerStatus: AssessorAnswerStatusDto;
  formStatus: SsaFormStatus;
  isInRoleAdminOrPoC: boolean;
  isCurrentUserAssessor: boolean;
}

interface SSAFormMainState {
  activeIndex: number;
  showScores: boolean;
}

class SSAFormSkillComponent extends Component<SSAFormSkillComponentProps, SSAFormMainState> {
  state = { activeIndex: 0, showScores: false };

  handleToggleSkill = (_: any, { index }: AccordionTitleProps) => {
    const { active } = this.props;
    const newIndex = active === index ? -1 : index;
    this.props.onSkillToggle(+newIndex);
  };

  handleToggleCheckpoint = newIndex => {
    this.setState({ activeIndex: newIndex });
  };

  handleGradeChange = (answer: number) => {
    this.props.onChange(this.props.skillSection.skillId, { answer });
  };

  handleCommentChange = ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(this.props.skillSection.skillId, { [currentTarget.name]: currentTarget.value });
  };

  private calculateOffsets = () => {
    const checkpoints = this.props.skillSection.checkpoints;
    const offsets = [1];
    for (let i = 1; i < checkpoints.length; i++) {
      offsets.push(offsets[i - 1] + checkpoints[i - 1].questionAnswers.length);
    }
    return offsets;
  };

  private canEditSsaForm = (): boolean => {
    const { formStatus, isInRoleAdminOrPoC, isCurrentUserAssessor, assessorAnswerStatus } = this.props;
    if (isInRoleAdminOrPoC) return false;
    else if (isCurrentUserAssessor && (formStatus === 'Review' || formStatus === 'Done')) return false;
    else if (isCurrentUserAssessor && formStatus === 'Assessors' && assessorAnswerStatus.answerStatus !== 'Done') return true;
    else if (
      isCurrentUserAssessor &&
      formStatus === 'Returned' &&
      (assessorAnswerStatus.answerStatus === 'Returned' ||
        assessorAnswerStatus.answerStatus === 'Unknown' ||
        assessorAnswerStatus.answerStatus === 'Pending')
    )
      return true;
    else return false;
  };

  private canSeeOpenExtraMaterialAndCheckPoints = (): boolean => {
    const { isInRoleAdminOrPoC, isCurrentUserAssessor } = this.props;
    if (isInRoleAdminOrPoC || isCurrentUserAssessor) return true;
    else return false;
  };

  render() {
    const { t, skillIndex, active, skillSection, currentLanguage } = this.props;
    const { skillName, skillDescription, urlLink, answer, areasForImprovement, recommendations, strengths, checkpoints } = skillSection;
    const questionIndexOffset = this.calculateOffsets();
    const canEdit = this.canEditSsaForm();
    const canSeeExtraAndCheckPoints = this.canSeeOpenExtraMaterialAndCheckPoints();

    return (
      <>
        <Accordion.Title
          active={skillIndex === active}
          index={skillIndex}
          onClick={this.handleToggleSkill}
          key={`ssa-form-skillSection`}
          className="ssa-form__skill-section"
        >
          <p>{skillName.toUpperCase()}</p>
        </Accordion.Title>
        <Accordion.Content active={skillIndex === active} className="flex-column">
          <div className="flex-between ssa-form-skill__description-container">
            {skillDescription && <p>{skillDescription}</p>}

            {urlLink && canSeeExtraAndCheckPoints && (
              <Button href={urlLink} target="_blank" className="open-btn" disabled={guidIsNullOrEmpty(urlLink)}>
                {t('Open Extra Material')}
              </Button>
            )}
          </div>
          <div className="ssa-form__scores-wrappper">
            <ScoreComponent
              isToggleable={canEdit}
              assessment="ssa"
              activeGrade={answer === -1 ? 5 : answer}
              className="ssa-form-grades"
              onGradeChange={this.handleGradeChange}
            />
          </div>

          <div className="skill-section__comments-area">
            <div className="flex-column">
              <p>{t("Employee's Strengths")}</p>
              <TextArea
                readOnly={!canEdit}
                name="strengths"
                className="skill-section__comments"
                value={strengths ? strengths : ''}
                onChange={this.handleCommentChange}
              />
            </div>
            <div className="flex-column">
              <p>{t("Employee's Areas for Improvement")}</p>
              <TextArea
                readOnly={!canEdit}
                name="areasForImprovement"
                className="skill-section__comments"
                value={areasForImprovement ? areasForImprovement : ''}
                onChange={this.handleCommentChange}
              />
            </div>
            <div className="flex-column">
              <p>{t('Recommendations')}</p>
              <TextArea
                readOnly={!canEdit}
                name="recommendations"
                className="skill-section__comments"
                value={recommendations ? recommendations : ''}
                onChange={this.handleCommentChange}
              />
            </div>
          </div>

          {checkpoints && checkpoints.length > 0 && canSeeExtraAndCheckPoints && (
            <div className="ssa-form__checkpoint-container">
              <h4>CHECKPOINTS</h4>

              {(checkpoints || []).map((check, idx) => (
                <Accordion key={check.description + idx}>
                  <SsaFormCheckpointComponent
                    currentLanguage={currentLanguage}
                    handleToggleCheckpoint={this.handleToggleCheckpoint}
                    activeIndex={this.state.activeIndex}
                    checkpoint={check}
                    index={idx}
                    questionIndexOffset={questionIndexOffset[idx]}
                  />
                </Accordion>
              ))}
            </div>
          )}
        </Accordion.Content>
      </>
    );
  }
}

export default withTranslation()(SSAFormSkillComponent);
