import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { NewMachineForLocationStore } from 'stores/locationsmanagement/machines-per-location-store';
import LocationEditor from 'widgets/bussiness/location-editor';
import { ItemReference } from 'stores/dataStore';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import './locations-management.less';

interface NewMachineForLocationViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newMachineForLocation?: NewMachineForLocationStore;
}

interface NewMachineForLocationViewState {
  location: ItemReference;
  locationIdentifier: string;
  oem: string;
  equipmentType: string;
  machineModel: string;
  machineRelatedCluster: string;
}

@connect(['newMachineForLocation', NewMachineForLocationStore])
class NewMachineForLocationView extends React.Component<NewMachineForLocationViewProps, NewMachineForLocationViewState> {
  private get newMachineForLocationStore() {
    return this.props.newMachineForLocation;
  }

  constructor(props: NewMachineForLocationViewProps) {
    super(props);
    this.newMachineForLocationStore.createNew({
      locationId: '',
      machineModelId: ''
    });
    this.state = {
      location: null,
      locationIdentifier: '',
      oem: null,
      equipmentType: null,
      machineModel: null,
      machineRelatedCluster: null
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  UNSAFE_componentWillReceiveProps(nextProps: NewMachineForLocationViewProps) {
    if (this.newMachineForLocationStore.state.result && this.newMachineForLocationStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.newMachineForLocationStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newMachineForLocationStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.newMachineForLocationStore.change({ ...this.newMachineForLocationStore.state.item, ...change });
  }

  private onChangeCluster = id => {
    this.setState({ machineRelatedCluster: id, equipmentType: undefined, oem: undefined, machineModel: undefined });
  };

  private onChangeEqType = id => {
    this.setState({ equipmentType: id, oem: undefined, machineModel: undefined });
  };

  private onChangeOem = id => {
    this.setState({ oem: id, machineModel: undefined });
  };

  private onChangeMModel = machineModel => {
    this.newMachineForLocationStore.state.item.machineModelId = machineModel == null ? null : machineModel.id;
    this.setState({ machineModel: machineModel.id });
  };

  public render() {
    const { t } = this.props as any;
    const currentUserInfo = this.identityService.getUserInfo();
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    const areYouAdmin = IdentityService.isAdmin(currentUserInfo);
    const areYouPoc = IdentityService.isPoc(currentUserInfo);

    return (
      <Modal
        className="new-machines-per-location__modal-container"
        open
        closeOnEscape={true}
        onClose={this.onCancelNewItem}
        closeOnDimmerClick={false}
      >
        <Dimmer active={this.newMachineForLocationStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New Machine For Location')}</Modal.Header>
        <Modal.Content image>
          <Container className="new-machine__modal">
            {this.newMachineForLocationStore.state.result && !this.newMachineForLocationStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newMachineForLocationStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newMachineForLocationStore.state.item && (
              <Form loading={this.newMachineForLocationStore.state.isBusy}>
                <Form.Field className="planit-users-fields" inline required width={4}>
                  {areYouAdmin && (
                    <>
                      <label>{t('Location')}</label>
                      <br />
                      <LocationEditor
                        nullable
                        clearable
                        placeholder={this.props.t('Select location')}
                        value={this.state.location}
                        onChange={location => (this.newMachineForLocationStore.state.item.locationId = location.id)}
                      />
                    </>
                  )}
                  {areYouPoc && (
                    <>
                      <label>{t('Location')}</label>
                      <br />
                      <LocationEditorWithRoleLocations
                        className={'new-machines-per-location__location-field'}
                        nullable
                        placeholder={this.props.t('Select location')}
                        value={this.state.locationIdentifier}
                        locationsReceived={pocLocations}
                        onChange={location => (this.newMachineForLocationStore.state.item.locationId = location ? location.id : null)}
                      />
                    </>
                  )}
                </Form.Field>
                <Form.Group>
                  <Form.Field required width={4}>
                    <div className={`required field`}>
                      <label>{t('Cluster')}</label>
                      <MRClusterEditor
                        nullable
                        value={this.state.machineRelatedCluster}
                        onChange={c => {
                          this.onChangeCluster(c ? c.id : null);
                        }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required width={6} disabled={this.state.machineRelatedCluster == null}>
                    <div className={`required field`}>
                      <label>{t('Equipment Type')}</label>
                      <EquipmentTypeEditor
                        nullable
                        clusterId={this.state.machineRelatedCluster ? this.state.machineRelatedCluster : undefined}
                        value={this.state.equipmentType}
                        onChange={c => this.onChangeEqType(c == null ? null : c.id)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field width={4} disabled={this.state.equipmentType == null}>
                    <div className={`required field`}>
                      <label>{t('OEM')}</label>
                      <OemEditor
                        nullable
                        value={this.state.oem}
                        equipmentId={this.state.equipmentType ? this.state.equipmentType : undefined}
                        onChange={c => this.onChangeOem(c == null ? null : c.id)}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field width={4} disabled={this.state.oem == null}>
                    <div className={`required field`}>
                      <label>{t('Machine Model')}</label>
                      <MachineModelEditor
                        nullable
                        value={this.state.machineModel}
                        oemId={this.state.oem ? this.state.oem : undefined}
                        equipmentId={this.state.equipmentType ? this.state.equipmentType : undefined}
                        onChange={c => this.onChangeMModel(c)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewMachineForLocationView);
