import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Component } from 'react';
import React from 'react';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import { connect } from 'redux-scaffolding-ts';
import './request-details-tab-pane.less';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';

import { faIdCardAlt, faThLarge, faCogs } from '@fortawesome/free-solid-svg-icons';
import { EventFormStore, EventDetailsViewModel } from 'stores/events/event-form-store';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';
import NonMachineRelatedComponent, {
  NonMachineRelatedComponentViewModel
} from 'site/pages/shared/events-and-requests/non-machine-related/non-machine-related';
import { EventFormMode } from '../../event-form';
import { EventTypeDto, EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';
import { RequestDto } from 'stores/requests/requests-store';
import { guidIsNullOrEmpty } from 'utils/useful-functions';

interface RequestDetailsTabPaneProps extends WithTranslation {
  loading: boolean;
  readOnly: boolean;
  eventFormStore?: EventFormStore;
  mode: EventFormMode;
}

@connect(['eventFormStore', EventFormStore])
class RequestDetailsTabPane extends Component<RequestDetailsTabPaneProps> {
  private handleOnEventDetailsChange(property: keyof EventDetailsViewModel, value: any): void {
    const eventDetails = { ...this.props.eventFormStore.state.item?.eventDetails };
    eventDetails[property as string] = value;
    if (property === 'machineRelated' || property === 'category') {
      eventDetails.nmrCluster = null;
      eventDetails.nmrFunctionalArea = null;
      eventDetails.nmrTrainingName = null;
      eventDetails.nmrFunctionalSubArea = null;
    }
    if (property === 'machineRelated' && value) eventDetails.category = 'Technical' as any;
    if (property === 'category' && value === 'Functional') eventDetails.machineRelated = false;

    this.props.eventFormStore.change({ eventDetails });
  }

  private handleOnPatternMachinesChange = (newPatternMachines: PatternMachinesViewModel) => {
    if (this.props.eventFormStore.state?.item?.eventDetails) {
      let eventDetails = { ...this.props.eventFormStore.state.item.eventDetails };
      eventDetails.pattern = newPatternMachines.pattern;
      eventDetails.machines = newPatternMachines.machines;
      this.props.eventFormStore.change({ eventDetails });
    }
  };

  private handleNMRChange = (value: NonMachineRelatedComponentViewModel) => {
    let eventDetails = { ...this.props.eventFormStore.state.item?.eventDetails };

    eventDetails.nmrCluster = value.cluster;
    eventDetails.nmrFunctionalArea = value.functionalArea;
    eventDetails.nmrFunctionalSubArea = value.functionalSubArea;
    eventDetails.nmrTrainingName = value.trainingName;

    this.props.eventFormStore.change({ eventDetails });
  };

  private isEventFromExtededWorkFlowRequest = (eventType: EventTypeDto, requests: RequestDto[]): boolean => {
    let isExtended = eventType && EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended;
    isExtended =
      isExtended &&
      requests &&
      requests.all(r => r.eventType && EventTypeCategory[r.eventType.eventTypeCategory] === EventTypeCategory.Extended);
    return isExtended;
  };

  render() {
    const { t, readOnly, loading, eventFormStore } = this.props;
    const { pattern, machines, profession, category, machineRelated, ...rest } = eventFormStore.state.item.eventDetails;

    const { eventType, requests } = eventFormStore.state.item;
    const { nmrCluster, nmrFunctionalArea, nmrFunctionalSubArea, nmrTrainingName } = rest;
    const patternMachines: PatternMachinesViewModel = { pattern, machines };

    const nMR: NonMachineRelatedComponentViewModel = {
      cluster: nmrCluster,
      functionalArea: nmrFunctionalArea,
      functionalSubArea: nmrFunctionalSubArea,
      trainingName: nmrTrainingName
    };

    return (
      <>
        {loading && (
          <div style={{ height: 200 }}>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}

        <Form className="form__event-details__container">
          <FormRow label={t('Role')} icon={faIdCardAlt}>
            <ProfessionEditor
              readOnly={readOnly}
              className="form__header__dropdown-style"
              nullable
              value={guidIsNullOrEmpty(profession?.id) ? null : profession}
              onChange={value => this.handleOnEventDetailsChange('profession', value)}
            />
          </FormRow>

          {eventType && requests && !this.isEventFromExtededWorkFlowRequest(eventType, requests) && (
            <>
              <FormRow label={t('Category')} icon={faThLarge}>
                <CategoryFormEditor
                  readOnly={readOnly}
                  className="form__header__dropdown-style"
                  value={category}
                  onChange={value => this.handleOnEventDetailsChange('category', value)}
                />
              </FormRow>

              <FormRow label={t('Machine Related')} icon={faCogs}>
                <Form.Field className="planit-requestform-fields" inline>
                  <Button.Group>
                    <Button
                      disabled={readOnly}
                      attached="left"
                      className={machineRelated ? 'events-form__btn__positive' : 'events-form__btn__negative'}
                      onClick={() => this.handleOnEventDetailsChange('machineRelated', true)}
                    >
                      {t('Machine Related')}
                    </Button>
                    <Button
                      disabled={readOnly}
                      attached="right"
                      className={machineRelated ? 'events-form__btn__negative' : 'events-form__btn__positive'}
                      onClick={() => this.handleOnEventDetailsChange('machineRelated', false)}
                    >
                      {t('Non-Machine Related')}
                    </Button>
                  </Button.Group>
                </Form.Field>
              </FormRow>
            </>
          )}

          <div className="form__event-details-tab__mr__pattern form__event-details-tab__element mr-table-component__common-styles">
            {machineRelated && (
              <PatternMachines
                readOnly={readOnly}
                value={patternMachines}
                onChange={this.handleOnPatternMachinesChange}
                loadPatternsOnOpen={true}
              />
            )}
          </div>

          {!machineRelated && (
            <NonMachineRelatedComponent category={category} readOnly={readOnly} value={nMR} onChange={this.handleNMRChange} />
          )}
        </Form>
      </>
    );
  }
}

export default withTranslation()(RequestDetailsTabPane);
