import * as React from 'react';
import { EventStatus } from 'stores/events/events-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faSpinner, faFile, faDoorClosed, faCircle } from '@fortawesome/free-solid-svg-icons';

export interface SchedulerEventStatusIconProps {
  status: EventStatus;
}

const SchedulerEventStatusIcon: React.FC<SchedulerEventStatusIconProps> = ({ status }) => {
  const s = status.toString();

  if (s === 'Draft') return <FontAwesomeIcon icon={faEraser} />;

  if (s === 'Planned') return <FontAwesomeIcon icon={faCircle} />;

  if (s === 'InProgress') return <FontAwesomeIcon icon={faSpinner} />;

  if (s === 'Completed') return <FontAwesomeIcon icon={faFile} />;

  if (s === 'Closed') return <FontAwesomeIcon icon={faDoorClosed} />;

  return <></>;
};

export default React.memo(SchedulerEventStatusIcon);
