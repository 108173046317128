import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal, Dimmer, Loader, Message } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LineSeparator from 'widgets/bussiness/line-separator';
import { connect } from 'redux-scaffolding-ts';
import {
  TheoreticalTemplateWizardStore,
  TheoreticalTemplateWizardViewModelValidator,
  mapToTemplateDataModel,
  TheoreticalTemplateWizardViewModel,
  toChangeTheoreticalTemplateFormDto
} from 'stores/assessments/wizard/theoretical-template-wizard-store';
import GeneralDataStep from './steps/general-data-step/general-data-step';
import MachineryStep from './steps/machinery-step/machinery-step';
import QuestionsStep from './steps/questions-step/questions-step';
import { isNullOrEmpty } from 'utils/useful-functions';
import { isNullOrUndefined } from 'util';
import { TheoreticalTemplatesStore, ChangeTheoreticalTemplatesStore } from 'stores/assessments/templates/theoretical-templates-store';
import './theoretical-template-wizard.less';

export interface TheoreticalFormWizardProps extends WithTranslation, RouteComponentProps {
  theoreticalTemplateWizardStore?: TheoreticalTemplateWizardStore;
  theoreticalTemplatesStore?: TheoreticalTemplatesStore;
  changeStore?: ChangeTheoreticalTemplatesStore;
}

type ModalMode = 'cancel' | 'accept';
export interface TheoreticalFormWizardState {
  step: number;
  mode?: 'Create' | 'Edit';
  loading: boolean;
  showConfirmationModal: boolean;
  confirmationModalMode: ModalMode;
}

@connect(
  ['theoreticalTemplateWizardStore', TheoreticalTemplateWizardStore],
  ['theoreticalTemplatesStore', TheoreticalTemplatesStore],
  ['changeStore', ChangeTheoreticalTemplatesStore]
)
class TheoreticalTemplateWizard extends Component<TheoreticalFormWizardProps, TheoreticalFormWizardState> {
  constructor(props: Readonly<TheoreticalFormWizardProps>) {
    super(props);

    this.state = {
      step: 1,
      mode: null,
      loading: false,
      confirmationModalMode: null,
      showConfirmationModal: false
    };
    this.props.theoreticalTemplateWizardStore.createNew({ ...this.plainNewItem });
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private get plainNewItem(): TheoreticalTemplateWizardViewModel {
    return {
      id: '',
      title: '',
      header: '',
      professionId: '',
      profileId: '',
      machineRelated: null,
      relatedTo: null,
      status: null,
      duration: null,
      trainingLevel: null,
      mrCluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      nmrCluster: null,
      functionalArea: null,
      trainingName: null,
      functionalSubarea: null,
      questionsMr: [],
      questionsNMr: [],
      isActive: true
    };
  }

  initWizardOnCreateMode() {
    this.props.theoreticalTemplateWizardStore.createNew(this.plainNewItem);
    this.setState({ mode: 'Create' });
  }

  initWizardOnEditmode = async (id: string) => {
    this.setState({ loading: true });
    const template = await this.loadTemplate(id);
    this.props.theoreticalTemplateWizardStore.createNew(template);
    const currentStep = this.getCurrentStep();
    this.setState({ loading: false, mode: 'Edit', step: currentStep });
  };

  loadTemplateById = async id => {
    const { theoreticalTemplatesStore, history } = this.props;
    try {
      return await theoreticalTemplatesStore.getTemplateById(id);
    } catch (error) {
      console.error('load template edit mode error:', error);
      history.replace('/not-found');
    }
  };

  loadTemplate = async (id: string) => {
    const templateDataDto = await this.loadTemplateById(id);
    const templateDataViewModel = mapToTemplateDataModel(templateDataDto);
    return templateDataViewModel;
  };

  componentDidMount() {
    const { params } = this.props.match;
    const id = params['id'];

    if (id != null) this.initWizardOnEditmode(id);
    else this.initWizardOnCreateMode();
  }

  isOnEditMode = (): boolean => {
    const { history, match } = this.props;
    if (history.location.state || match.params['id']) return true;
    else return false;
  };

  private checkStep1 = (): boolean => {
    const {
      title,
      professionId,
      profileId,
      machineRelated,
      relatedTo,
      duration,
      status
    } = this.props.theoreticalTemplateWizardStore.state.item;
    const isStatusRequired = relatedTo && relatedTo.toString() === 'Event';
    const statusValueFilled = isStatusRequired ? !isNullOrUndefined(status) : true;

    return (
      !isNullOrEmpty(title) &&
      (!isNullOrEmpty(professionId) || !isNullOrEmpty(profileId)) &&
      !isNullOrUndefined(machineRelated) &&
      !isNullOrUndefined(relatedTo) &&
      !isNullOrUndefined(duration) &&
      statusValueFilled
    );
  };

  private checkStep2 = () => {
    const {
      machineRelated,
      trainingLevel,
      mrCluster,
      equipmentType,
      nmrCluster,
      functionalArea,
      relatedTo
    } = this.props.theoreticalTemplateWizardStore.state.item;
    const isTrainingLevelRequired = relatedTo && relatedTo.toString() === 'Event';
    const trainingLevelFilled = isTrainingLevelRequired ? trainingLevel && !isNullOrEmpty(trainingLevel.id) : true;
    const commonValidators = this.checkStep1();

    if (machineRelated) {
      return (
        trainingLevelFilled &&
        mrCluster &&
        !isNullOrEmpty(mrCluster.id) &&
        equipmentType &&
        !isNullOrEmpty(equipmentType.id) &&
        commonValidators
      );
    } else {
      return (
        trainingLevelFilled &&
        nmrCluster &&
        !isNullOrEmpty(nmrCluster.id) &&
        functionalArea &&
        !isNullOrEmpty(functionalArea.id) &&
        commonValidators
      );
    }
  };

  private updateStep = () => {
    const step = this.getCurrentStep();
    this.setState({ step: step });
  };

  private getCurrentStep = () => {
    const step1Ok = this.checkStep1();
    if (!step1Ok) {
      return 1;
    }
    const step2Ok = this.checkStep2();
    if (!step2Ok) {
      return 2;
    }
    return 3;
  };

  private onCancelChangesClicked = () => {
    const { history } = this.props;
    history.goBack();
  };

  private canSubmit = () => {
    const validationResult = new TheoreticalTemplateWizardViewModelValidator().extendValidate(
      this.props.theoreticalTemplateWizardStore.state.item
    );
    return validationResult.isValid();
  };

  private onCancelClicked = () => {
    this.setState({ showConfirmationModal: true, confirmationModalMode: 'cancel' });
  };

  private confirmationModalHandler = (mode: ModalMode) => {
    this.setState({ showConfirmationModal: true, confirmationModalMode: mode });
  };

  private cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false, confirmationModalMode: null });
  };

  private handleOnSubmit = async () => {
    const edit: boolean = this.state.mode === 'Edit' ? true : false;
    const { theoreticalTemplateWizardStore, history, changeStore } = this.props;
    const { item } = theoreticalTemplateWizardStore.state;
    let result;
    try {
      this.setState({ loading: true, showConfirmationModal: false });
      if (edit) {
        const newItem = toChangeTheoreticalTemplateFormDto(item);
        changeStore.createNew(newItem);
        result = await changeStore.update();
      } else result = await theoreticalTemplateWizardStore.createTheoreticalTemplate();
      if (result?.isSuccess) {
        history.goBack();
      } else this.scrollToTop();
    } catch (error) {
      this.scrollToTop();
      console.log({ error });
    }
    this.setState({ loading: false });
    changeStore.clear();
  };

  private scrollToTop() {
    document.getElementById('root').scrollTop = 0;
  }

  toReturnTitleContent = () => {
    if (this.state.mode === 'Create') {
      return 'Theoretical Template Creation';
    } else {
      return 'Theoretical Template Edition';
    }
  };

  render() {
    const { t, theoreticalTemplateWizardStore } = this.props;
    if (!this.props.theoreticalTemplateWizardStore.state.item) return;
    const { isBusy } = theoreticalTemplateWizardStore.state;

    const { step, mode, loading, showConfirmationModal, confirmationModalMode } = this.state;
    const canSubmit = this.canSubmit();
    const requestSent = isBusy || loading;
    const messages =
      (this.props.theoreticalTemplateWizardStore.state.result && this.props.theoreticalTemplateWizardStore.state.result.messages) || [];
    return (
      <>
        <Dimmer page active={isBusy || loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading...')}</Loader>
        </Dimmer>
        <h3 className="template-wizard-title">{t(this.toReturnTitleContent())}</h3>
        {mode && (
          <>
            <LineSeparator />
            {messages && messages.length > 0 && (
              <Message className="error-message__style" icon="exclamation circle" error list={messages.map(m => m.body)} />
            )}

            <div className="feedbacks-wizard__steps-container theoretical-form-wizard-container">
              <GeneralDataStep active={step >= 1} onStepChanged={this.updateStep} mode={mode} />
              <MachineryStep active={step >= 2} onStepChanged={this.updateStep} mode={mode} />
              <QuestionsStep active={step >= 3} mode={mode} />

              <div className="theoretical-templates-wizard__btns">
                <Button secondary inverted onClick={this.onCancelClicked}>
                  {t('Cancel')}
                </Button>
                <Button
                  primary
                  positive
                  onClick={() => (mode === 'Edit' ? this.confirmationModalHandler('accept') : this.handleOnSubmit())}
                  disabled={!canSubmit}
                >
                  {t(`${mode === 'Create' ? 'Create' : 'Save'} Template`)}
                </Button>
              </div>
            </div>
            {showConfirmationModal && (
              <Modal
                open={showConfirmationModal}
                size="mini"
                className="change-status-popup"
                closeOnEscape={true}
                onClose={this.cancelConfirmationModal}
              >
                <Modal.Content className="change-status-popup__content">
                  {confirmationModalMode === 'accept' ? (
                    <p>
                      {t(
                        `${
                          mode === 'Create'
                            ? 'Do you want to create the theoretical Template'
                            : 'Are you sure do you want to apply this changes in this theoretical Template?'
                        }`
                      )}
                    </p>
                  ) : (
                    <>
                      <p>{t('Are you sure you want to CANCEL?')}</p>
                      <p>{t('You will lose all the changes made in this template.')}</p>
                    </>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    size="tiny"
                    className="change-status-popup__btn"
                    content={t(`${confirmationModalMode === 'accept' ? 'Cancel' : 'Back'}`)}
                    onClick={this.cancelConfirmationModal}
                    disabled={requestSent}
                  />
                  <Button
                    size="tiny"
                    className="change-status-popup__btn"
                    content={t(`${confirmationModalMode === 'accept' ? 'Accept' : 'Cancel Changes'}`)}
                    onClick={confirmationModalMode === 'accept' ? this.handleOnSubmit : this.onCancelChangesClicked}
                    disabled={requestSent}
                  />
                </Modal.Actions>
              </Modal>
            )}
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(TheoreticalTemplateWizard);
