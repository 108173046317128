import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import {
  Button,
  Container,
  Form,
  Message,
  Modal,
  DropdownItemProps,
  Flag,
  FlagNameValues,
  Dropdown,
  Input,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { nameof } from '../../../../../utils/object';
import { CountryDto, ChangeCountryStore, ChangeCountryDto } from 'stores/configuration/locations/countries-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { countryInfos } from 'utils/country-infos';
import VisaSelector from 'widgets/bussiness/selectors/visa-selector';

interface ChangeCountryViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeCountry?: ChangeCountryStore;
  currentCountry: CountryDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeCountryViewState {
  countryOptions: DropdownItemProps[];
}

@connect(['changeCountry', ChangeCountryStore])
class ChangeCountryView extends React.Component<ChangeCountryViewProps, ChangeCountryViewState> {
  private get changeCountryStore() {
    return this.props.changeCountry;
  }

  constructor(props: ChangeCountryViewProps) {
    super(props);

    this.changeCountryStore.state.result = null;

    this.changeCountryStore.change({
      id: this.props.currentCountry.id,
      name: this.props.currentCountry.name,
      isoCode: this.props.currentCountry.isoCode,
      description: this.props.currentCountry.description,
      visaId: this.props.currentCountry.visaId
    });

    this.state = {
      countryOptions: countryInfos.map(c => ({
        key: c.name,
        text: c.name,
        content: (
          <>
            <Flag name={c.code as FlagNameValues} />
            &nbsp; {c.name}
          </>
        ),
        value: c.name
      }))
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeCountryViewProps) {
    if (this.changeCountryStore.state.result && this.changeCountryStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeCountryStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeCountryStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeCountryStore.change({ ...this.changeCountryStore.state.item, ...change });
  }

  private onCountryChange = (e, { value }) => {
    this.handleValue(nameof<ChangeCountryDto>('name'), value);
    const info = countryInfos.firstOrDefault(x => x.name === value);
    if (info) {
      this.handleValue(nameof<ChangeCountryDto>('isoCode'), info.code);
    }
  };

  private onCountryAddition = (e, { value }) => {
    this.setState({
      countryOptions: [...this.state.countryOptions, { key: value, value, text: value }]
    });
  };

  public render() {
    const { t } = this.props as any;
    const { result, item } = this.changeCountryStore.state;
    const { countryOptions } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeCountryStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Country')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field error={isNullOrWhiteSpaces(item.name)} required>
                    <label>{t('Country')}</label>
                    <Dropdown
                      search
                      selection
                      allowAdditions
                      options={countryOptions}
                      placeholder={t('Name')}
                      value={item.name}
                      onChange={this.onCountryChange}
                      onAddItem={this.onCountryAddition}
                      additionLabel={t('Add ')}
                    />
                  </Form.Field>
                  <Form.Field required error={isNullOrWhiteSpaces(item.isoCode)}>
                    <label>{t('Code')}</label>
                    <Input
                      placeholder={t('Code')}
                      value={item.isoCode}
                      onChange={(e, { value }) => this.handleValue(nameof<ChangeCountryDto>('isoCode'), value)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>{t('Visa')}</label>
                    <VisaSelector
                      className="filter-form__item-input"
                      nullable
                      filterByRole
                      clearable
                      searchable
                      placeholder={t('Select Visa')}
                      value={item.visaId}
                      onChange={value => {
                        this.handleValue(nameof<ChangeCountryDto>('visaId'), value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeCountryView);
