import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import AdminReportcounters from './admin-report-counters';
import AdminReportAuditLogsList from './admin-report-audit-logs-list';
import './admin-reports-landing-page.less';

export interface AdminReportsLandingPageProps extends RouteComponentProps, WithTranslation {}

const AdminReportsLandingPage: FC<AdminReportsLandingPageProps> = props => {
  return (
    <>
      <h3>{''}</h3>
      <AdminReportcounters {...props} />
      <AdminReportAuditLogsList {...props} />
    </>
  );
};

export default withTranslation()(AdminReportsLandingPage);
