import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Modal, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { ProfileItemDto } from 'stores/profile/profile-store';
import '../../tna/templates/tna-ssa-templates.less';
import {
  SsaTemplatesStore,
  SsaTemplateDto,
  NewSsaTemplateStore,
  ChangeSsaTemplateStore,
  ChangeSsaTemplateDto,
  CreateSsaTemplateDto,
  CreateSsaSkillTemplateSectionDto,
  SsaSkillTemplateSectionDto,
  SsaSkillTemplateSectionCategoryDto,
  CreateSsaSkillTemplateSectionCategoryDto,
  SsaCheckpointDto,
  CreateSsaCheckpointDto
} from 'stores/assessments/templates/ssa-templates-store';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface SsaTemplateListProps extends WithTranslation, RouteComponentProps {
  ssaTemplateStore: SsaTemplatesStore;
  newSsaTemplateStore: NewSsaTemplateStore;
  changeSsaTemplateStore: ChangeSsaTemplateStore;
}

interface SsaTemplateFilters {
  isActive: boolean;
  profileItemId: string;
  templateNameOrID: string;
}

export interface SsaTemplateListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: SsaTemplateDto;
  isclone: boolean;
  cloneData: SsaTemplateDto;
  showInactiveTemplates: boolean;
  uploadLoadingMessage: string;
  filters: SsaTemplateFilters;
  onConfirmToggleDisable: boolean;
  cloneModalToShown: boolean;
  showOnlyInactiveRows?: boolean;
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['ssaTemplateStore', SsaTemplatesStore],
  ['newSsaTemplateStore', NewSsaTemplateStore],
  ['changeSsaTemplateStore', ChangeSsaTemplateStore]
)
class SsaTemplateList extends React.Component<SsaTemplateListProps, SsaTemplateListState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      cloneData: null,
      isclone: false,
      cloneModalToShown: false,
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
        filter: [{ isActive: true }],
        skip: 0,
        take: 10
      },
      activeFilters: [{ isActive: true }],
      showInactiveTemplates: false,
      uploadLoadingMessage: '',
      onConfirmToggleDisable: false,
      showOnlyInactiveRows: false,
      filters: {
        isActive: true,
        templateNameOrID: null,
        profileItemId: null
      },
      loading: false,
      someFilterOpened: false
    };
  }

  private get newSsaTemplateStore() {
    return this.props.newSsaTemplateStore;
  }

  private get changeSsaTemplateStore() {
    return this.props.changeSsaTemplateStore;
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.ssaTemplateStore.getAllAsync(this.state.query));
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: SsaTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.ssaTemplateStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: SsaTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.ssaTemplateStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private onNewTemplate() {
    const { history } = this.props;
    history.push({ pathname: '/assessments/ssa/template/' });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem(selectedItem: SsaTemplateDto) {
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/ssa/template/' + selectedItem.id, state: selectedItem });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    if (isSuccess) this.load();
  }

  private uploadComplete = (data: any) => {
    this.load();
  };

  private onFilterTemplateOrId = (_, { value }) => {
    const filters = { ...this.state.filters };
    if (!value) {
      delete filters['templateNameOrID'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TS');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TS')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      filters['templateNameOrID'] = `(${parts.join(' or ')})`;
    }

    this.setState({ filters }, () => this.refreshTable(filters));
  };

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters;

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  private handleFilterByProfile(profile: ProfileItemDto) {
    let filters = { ...this.state.filters };

    filters.profileItemId = profile ? profile.id : null;
    this.refreshTable(filters);
  }

  private refreshTable(filters: SsaTemplateFilters) {
    const tableFilter = this.buildODataFilter(filters);

    this.setState({ filters });
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters: SsaTemplateFilters) {
    let oDataFilters = [];
    const { isActive, templateNameOrID, profileItemId } = filters;
    if (isActive) {
      oDataFilters.push({ isActive: true });
    }

    if (!isNullOrWhiteSpaces(templateNameOrID)) {
      oDataFilters.push(templateNameOrID);
    }

    if (profileItemId) {
      oDataFilters.push({ ProfileItemId: { eq: { type: 'guid', value: profileItemId } } });
    }

    return oDataFilters;
  }

  onHandleChangeFilter = (property: string, value: string | boolean) => {
    let filters = { ...this.state.filters };
    if (value == null) {
      delete filters[property];
    } else {
      filters[property] = value;
    }

    this.setState({
      filters
    });
  };

  onToggleAvailabilityItem = () => {
    this.setState({ onConfirmToggleDisable: false, loading: true });
    const item: SsaTemplateDto = { ...this.state.selectedItem };
    const newItem: ChangeSsaTemplateDto = {
      id: item.id,
      title: item.title,
      header: item.header,
      isActive: !item.isActive,
      skillTemplateSectionCategories: this.toSsaskillTemplateSectionCategories(item.skillSectionCategories)
    };

    this.changeSsaTemplateStore.change(newItem);
    this.changeSsaTemplateStore.update().then(response => {
      if (response && response.isSuccess) {
        this.setState({ selectedItem: null, loading: false }, this.load);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  toSsaskillTemplateSectionCategories(categories: SsaSkillTemplateSectionCategoryDto[]): CreateSsaSkillTemplateSectionCategoryDto[] {
    return (categories || []).map(category => ({
      name: category.name,
      skillSections: this.toCreateSsaSkillTemplateSections(category.skillSections)
    }));
  }

  toCreateSsaSkillTemplateSections(skillSections: SsaSkillTemplateSectionDto[]): CreateSsaSkillTemplateSectionDto[] {
    return skillSections.map(({ skillId, checkpoints, linkUrl: urlLink }) => ({
      skillId,
      urlLink,
      checkpoints: this.toCreateCheckpointsSections(checkpoints)
    }));
  }

  toCreateCheckpointsSections(checkpoints: SsaCheckpointDto[]): CreateSsaCheckpointDto[] {
    return checkpoints.map(checkpoint => ({
      description: checkpoint.description,
      questions: checkpoint.questions.map(({ id }) => id)
    }));
  }

  isOperationResultSucess = () => {
    return this.props.changeSsaTemplateStore.state.result && this.props.changeSsaTemplateStore.state.result.isSuccess;
  };

  isOperationCloneSucess = () => {
    return this.props.newSsaTemplateStore.state.result && this.props.newSsaTemplateStore.state.result.isSuccess;
  };

  closeConfirmationBox = () => {
    this.setState({ ...this.state, selectedItem: null, onConfirmToggleDisable: false, cloneModalToShown: false });
  };

  onCloneItem = () => {
    const item: SsaTemplateDto = { ...this.state.selectedItem };
    const newItem: CreateSsaTemplateDto = {
      profileItemId: item.profileItemId,
      title: item.title,
      header: item.header,
      isActive: item.isActive,
      skillTemplateSectionCategories: this.toSsaskillTemplateSectionCategories(item.skillSectionCategories)
    };

    this.newSsaTemplateStore.createNew({ ...newItem });
    this.setState({ cloneModalToShown: false, loading: true });

    this.newSsaTemplateStore.submit().then(res => {
      this.setState({ loading: false });
      if (res == null || !res.isSuccess) console.log('Error cloning SSA Template');
      this.load();
    });
  };
  private onShowInactiveTemplates = () => {
    this.setState({ loading: true });
    let filters = { ...this.state.filters };

    this.setState({ showInactiveTemplates: !this.state.showInactiveTemplates }, () => {
      if (this.state.showInactiveTemplates) delete filters['isActive'];
      else filters['isActive'] = true;
      this.setState({ loading: false });
      this.refreshTable(filters);
    });
  };

  private getExtraMenu = () => {
    const { t } = this.props as any;
    return [
      {
        index: 0,
        className: '',
        content: (
          <Button onClick={this.onShowInactiveTemplates} className="table__custom-white-outline-button">
            {this.state.showInactiveTemplates ? t('Hide Inactive templates') : t('Show Inactive templates')}
          </Button>
        )
      }
    ];
  };
  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [];
    buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="pencil" />
            {<span className="text__bold">{t('Edit')}</span>}
          </>
        ),
        onClick: (item: SsaTemplateDto) => {
          this.setState({ selectedItem: item }, () => this.onEditItem(item));
        }
      },
      {
        content: (
          <>
            <Icon name="clone" />
            {<span className="text__bold">{t('Clone')}</span>}
          </>
        ),
        onClick: (item: SsaTemplateDto) => {
          this.setState({ selectedItem: item, cloneModalToShown: true });
        }
      },
      {
        content: (
          <>
            <Icon name="toggle off" color="red" />
            <span className="text__bold text__red">{t('Deactivate')}</span>
          </>
        ),
        isVisible: (item: SsaTemplateDto) => item.isActive,
        onClick: (item: SsaTemplateDto) => {
          this.setState({ selectedItem: item, onConfirmToggleDisable: true });
        }
      },
      {
        content: (
          <>
            <Icon name="toggle on" />
            <span className="text__bold">{t('Activate')}</span>
          </>
        ),
        isVisible: (item: SsaTemplateDto) => !item.isActive,
        onClick: (item: SsaTemplateDto) => {
          this.setState({ selectedItem: item, onConfirmToggleDisable: true });
        }
      }
    ];

    return buttonMenuOptions;
  };

  handleOnRowDoubleClick = (item: SsaTemplateDto) => {
    this.handleOnEnterKeydown(item);
  };

  handleOnEnterKeydown = (item: SsaTemplateDto) => {
    this.setState({ selectedItem: item }, () => this.onEditItem(item));
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, ssaTemplateStore, changeSsaTemplateStore } = this.props;
    const { someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SsaTemplateDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Template'),
          tooltipRenderer: false,
          renderer: data => <div>{data.title}</div>,
          selectableHeader: true
        },
        {
          title: t('Profile'),
          tooltipRenderer: false,
          renderer: data => (
            <span style={{ backgroundColor: '#EEEEEE', fontWeight: 'normal' }} className="question-bank__cell__tag">
              {data.profileName ? data.profileName : ''}
            </span>
          )
        }
      ],
      data: ssaTemplateStore.state
    } as TableModel<SsaTemplateDto>;

    const messages = [];
    if (ssaTemplateStore.state.result && !ssaTemplateStore.state.result.isSuccess)
      ssaTemplateStore.state.result.messages.forEach(({ body }) => messages.push(body));
    if (changeSsaTemplateStore.state.result && !changeSsaTemplateStore.state.result.isSuccess)
      changeSsaTemplateStore.state.result.messages.forEach(({ body }) => messages.push(body));
    return (
      <div className="tna-ssa-template__wrapper ssa-template__wrapper">
        <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>

        <Grid className="event-types-list-grid">
          {messages && messages.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message className="error-message__style" icon="exclamation circle" error header={t('An error ocurred')} list={messages} />
            </Grid.Row>
          )}
          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Template or ID')}
              onChange={this.onFilterTemplateOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <ProfileEditor
              assessment="SSA"
              value={null}
              nullable
              className="custom-editor"
              clearable
              onChange={profile => this.handleFilterByProfile(profile)}
              placeholder={t('Profile')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.onConfirmToggleDisable && !this.state.cloneModalToShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              isRowDisableLayout={item => !(item as any).isActive}
              onRefresh={this.load}
              extraMenu={this.getExtraMenu()}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewTemplate}
              canCreateNew={true}
              canEdit={false}
              canDelete={true}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('New SSA Template')}
            ></TableView>
          </Grid.Row>
        </Grid>

        {this.state.onConfirmToggleDisable && (
          <Modal
            open={this.state.onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${this.state.selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}

        {this.state.cloneModalToShown && (
          <Modal
            open={this.state.cloneModalToShown}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to clone this template with id ${this.state.selectedItem.friendlyId}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button className="change-status-popup__btn change-status-popup__pending-btn" content={t('Yes')} onClick={this.onCloneItem} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(SsaTemplateList);
