import React, { FunctionComponent, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

export interface SsaTemplateWizardStepProps extends WithTranslation {
  number: string | number;
  title: string;
  active: boolean;
  render?: ReactNode | any;
  className?: string;
}

const StepsComponent: FunctionComponent<SsaTemplateWizardStepProps> = ({ active, t, render, number, title, className }) => {
  let classname = 'template-step';
  if (!active) classname += ' inactive';
  if (className) classname += className;
  return (
    <>
      <div className={classname}>
        <Label basic className="step__label" circular>
          {number}
        </Label>
        <p className="step__title">{t(title)}</p>
      </div>
      {active && render}
    </>
  );
};

export default withTranslation()(StepsComponent);
