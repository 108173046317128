import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import {
  ChangeInstructorExpertiseRequestStore,
  InstructorExpertiseRequestDto,
  ExpertiseRequestStatus
} from 'stores/skills/instructor-expertise-request-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

interface ViewInstructorExpertiseRequestProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeExpertiseRequestStore?: ChangeInstructorExpertiseRequestStore;
  expertiseRequest: InstructorExpertiseRequestDto;
}

interface ViewInstructorExpertiseRequestState {}

@connect(['changeExpertiseRequestStore', ChangeInstructorExpertiseRequestStore])
class ViewInstructorExpertiseRequestView extends React.Component<ViewInstructorExpertiseRequestProps, ViewInstructorExpertiseRequestState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private canEdit = () => {
    const { expertiseRequest } = this.props;
    const userInfo = this.identityService.getUserInfo();

    return (
      expertiseRequest.instructorId !== userInfo.userId &&
      IdentityService.isPowerInstructor(userInfo) &&
      expertiseRequest.status === ExpertiseRequestStatus.Pending
    );
  };

  private onApprove = () => {
    if (!this.canEdit()) return;
    const { changeExpertiseRequestStore, expertiseRequest } = this.props;

    changeExpertiseRequestStore.change({
      id: expertiseRequest.id,
      nextStatus: ExpertiseRequestStatus.Approved
    });

    changeExpertiseRequestStore.update().then(x => {
      if (x != null && x.isSuccess) this.props.onClose(true);
    });
  };
  private onReject = () => {
    if (!this.canEdit()) return;
    const { changeExpertiseRequestStore, expertiseRequest } = this.props;

    changeExpertiseRequestStore.change({
      id: expertiseRequest.id,
      nextStatus: ExpertiseRequestStatus.Rejected
    });

    changeExpertiseRequestStore.update().then(x => {
      if (x != null && x.isSuccess) this.props.onClose(true);
    });
  };

  private onCancel = () => {
    this.props.changeExpertiseRequestStore.clear();
    this.props.changeExpertiseRequestStore.clearMessages();
    this.props.onClose(false);
  };

  public render() {
    const { t, expertiseRequest } = this.props;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Dimmer active={this.props.changeExpertiseRequestStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Instructor Expertise Request')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.props.changeExpertiseRequestStore.state.result && !this.props.changeExpertiseRequestStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.changeExpertiseRequestStore.state.result.messages.map(o => o.body)}
              />
            )}

            <Form>
              <Form.Group widths={2}>
                <Form.Field>
                  <div className={`field normal-looking-disabled-input`}>
                    <label>{t('Instructor')}</label>
                    <Input disabled value={expertiseRequest.instructor}></Input>
                  </div>
                </Form.Field>
                <Form.Field>
                  <div className={`field normal-looking-disabled-input`}>
                    <label>{t('Event Type')}</label>
                    <Input disabled value={expertiseRequest.eventTypeName}></Input>
                  </div>
                </Form.Field>
              </Form.Group>

              <Form.Group widths={2}>
                <Form.Field>
                  <div className={`field normal-looking-disabled-input`}>
                    <label>{t('Role')}</label>
                    <Input disabled value={expertiseRequest.roleName}></Input>
                  </div>
                </Form.Field>
                <Form.Field>
                  <div className={`field normal-looking-disabled-input`}>
                    <label>{t('Training Level')}</label>
                    <Input disabled value={expertiseRequest.trainingLevelName}></Input>
                  </div>
                </Form.Field>
              </Form.Group>

              <Form.Group widths={2}>
                <Form.Field>
                  <label>{t('Is machine related')}</label>
                  <Button.Group className="filter-button-group green" style={{ width: 120 }}>
                    <Button className={!!expertiseRequest.isMachineRelated ? `active gray-border` : `gray-border`} disabled>
                      {t('Yes')}
                    </Button>
                    <Button className={!expertiseRequest.isMachineRelated ? `active gray-border` : `gray-border`} disabled>
                      {t('No')}
                    </Button>
                  </Button.Group>
                </Form.Field>
                <Form.Field>
                  <label>{t('Status')}</label>
                  <span className={`view-expertise-modal-tag expertise-request-tag__${expertiseRequest.status}`}>
                    {expertiseRequest.status}
                  </span>
                </Form.Field>
              </Form.Group>
              {!!expertiseRequest.isMachineRelated && (
                <>
                  <Form.Group widths={5}>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Cluster')}</label>
                        <Input disabled value={expertiseRequest.machineRelatedClusterName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Equipment Type')}</label>
                        <Input disabled value={expertiseRequest.equipmentTypeName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('OEM')}</label>
                        <Input disabled value={expertiseRequest.oemName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Machine Model')}</label>
                        <Input disabled value={expertiseRequest.machineModelName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>{t('PLC Types')}</label>
                      <div className={`field inline normal-looking-disabled-input`}>
                        <MultiPlcTypeEditor
                          className="new-instructor-height-plc fix-icon"
                          placeholder=" "
                          value={expertiseRequest.plcTypes}
                          oemId={expertiseRequest.oemId}
                          multiple
                          equipmentId={expertiseRequest.equipmentTypeId}
                          onChange={c => {}}
                          readOnly={true}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                </>
              )}

              {!expertiseRequest.isMachineRelated && (
                <>
                  <Form.Group widths={4}>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Cluster')}</label>
                        <Input disabled value={expertiseRequest.nmrClusterName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Functional Area')}</label>
                        <Input disabled value={expertiseRequest.nmrFunctionalAreaName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Training Name')}</label>
                        <Input disabled value={expertiseRequest.nmrTrainingName}></Input>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Functional Subarea')}</label>
                        <Input disabled value={expertiseRequest.nmrFunctionalSubAreaName}></Input>
                      </div>
                    </Form.Field>
                  </Form.Group>
                </>
              )}
            </Form>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancel} basic>
            {t('Back')}
          </Button>
          {this.canEdit() && (
            <Button onClick={this.onApprove} positive>
              {t('Approve')}
            </Button>
          )}
          {this.canEdit() && (
            <Button onClick={this.onReject} className="request-form__reject-button">
              {t('Reject')}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ViewInstructorExpertiseRequestView);
