import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, TextArea, Dimmer, Loader } from 'semantic-ui-react';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';

interface FormCommentsTabComponentProps extends WithTranslation {
  loading: boolean;
  readOnly: boolean;
  eventFormStore?: EventFormStore;
}

@connect(['eventFormStore', EventFormStore])
class CommentTabPane extends Component<FormCommentsTabComponentProps> {
  private handleOnCommentChange(newComment: string) {
    this.props.eventFormStore.change({ comment: newComment });
  }

  render() {
    const { readOnly } = this.props;
    const { comment } = this.props.eventFormStore.state.item;

    return (
      <>
        {this.props.loading && (
          <div style={{ height: 200 }}>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}
        <Form>
          <Form.Field>
            <TextArea
              rows="14"
              readOnly={readOnly}
              transparent="true"
              className="form__comment-textarea scroll-not-visible"
              value={comment}
              onChange={(e, { value }) => this.handleOnCommentChange(value as string)}
            ></TextArea>
          </Form.Field>
        </Form>
      </>
    );
  }
}
export default withTranslation()(CommentTabPane);
