import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { UserStore, UserDto } from 'stores/users/users-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';

interface MultipleUsersEditorProps extends WithTranslation {
  value: UserDto[];
  role: string;
  clearable?: boolean;
  onChange?: (value: UserDto[]) => void;
  users?: UserStore;
  placeholder?: string;
  className?: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultipleUsersEditorState {
  options: MultipleSelectionDropdownOptions<UserDto>[];
}

@connect(['users', UserStore])
class MultipleUsersEditor extends React.Component<MultipleUsersEditorProps, MultipleUsersEditorState> {
  private get userStore() {
    return this.props.users;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  state: MultipleUsersEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(users: QueryResult<UserDto>): MultipleSelectionDropdownOptions<UserDto>[] {
    const usersFitlered = this.identityService.isInRole(Roles.Instructor)
      ? users.items.filter(x => x.id !== this.identityService.userId)
      : users.items;

    return usersFitlered
      .map(user => ({
        id: user.id,
        value: user,
        text: `${user.lastName}, ${user.firstName}`
      }))
      .sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
        return 0;
      });
  }

  renderOption(option: MultipleSelectionDropdownOptions<UserDto>) {
    // const { t } = this.props;

    // if (option.id === this.identityService.userId && this.identityService.activeRole === 'Instructor') {
    //   return (
    //     <div className="text multi-user-dropdown__me">
    //       <FontAwesomeIcon icon={faStar} color="#00B2A9" />
    //       <span>{t('Me')}</span>
    //     </div>
    //   );
    // }

    return <div className="text">{option?.text}</div>;
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.userStore
        .getAllUsersWithRoleAsync(
          {
            searchQuery: '',
            skip: 0,
            take: 100000,
            orderBy: [
              { direction: 'Ascending', field: 'surname', useProfile: false },
              { direction: 'Ascending', field: 'name', useProfile: false }
            ],
            filter: [`enabled eq true`]
          },
          this.props.role
        )
        .then(users => this.setState({ options: this.mapToOptions(users) }));
    }
  };

  public render() {
    const { className } = this.props;
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        optionRenderer={(option: MultipleSelectionDropdownOptions<UserDto>) => this.renderOption(option)}
        className={className ? className : ''}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleUsersEditor);
