import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';

export interface ImportLogDto extends BaseDto {
  id: string;
  action: string;
  userUpdatedCount: number;
  newPersonnelAreaCount: number;
  userOnHoldCount: number;
  timestamp: string;
}

export interface ChangeImportLogDto {
  id: string;
  action: string;
}

export class ChangeImportLogsValidator extends AbstractValidator<ChangeImportLogDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Log Id is required'));

    this.validateIfString(o => o.action)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Action name is required'));
  }
}

@repository('@@IMPORTLOGS', 'importlogs.summary')
export class ImportLogStore extends DataStore<ImportLogDto> {
  baseUrl = 'success-factors/v1';
  createPath = 'create-importLog';
  retrievePath = 'get-importLogs';
  updatePath = '';
  deletePath = 'delete-importLog';

  protected validate(item: ImportLogDto) {
    return new ChangeImportLogsValidator().validate(item);
  }

  constructor() {
    super('IMPORTLOG', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
