import { ChangeEventInstructorDto, ChangeEventSupportDetailsDto } from 'stores/events/events-store';
import {
  EventTypeDto,
  EventTypeCategory,
  EventFieldGroups,
  EventUserRoles
} from '../stores/configuration/events-workflow/event-types-store';
import { isNullOrWhiteSpaces } from './useful-functions';

export function hasRequestDetails(eventType: EventTypeDto): boolean {
  return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.indexOf('RequestDetails') >= 0;
}

export function hasEventDetails(eventType: EventTypeDto): boolean {
  return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.indexOf('EventDetails') >= 0;
}

export function hasSupportPositions(supportDetails: ChangeEventSupportDetailsDto): boolean {
  return supportDetails && ((supportDetails.newSupportPositions || []).length > 0 || (supportDetails.supportPositions || []).length > 0);
}

export function hasEventPositions(supportDetails: ChangeEventSupportDetailsDto): boolean {
  const eventNewModelPositions = (supportDetails.newSupportPositions || []).selectMany(x => x.eventNewModelPositions).toArray();
  const eventPositions = (supportDetails.supportPositions || []).selectMany(x => x.eventPositions).toArray();
  var result = hasSupportPositions(supportDetails) && ((eventNewModelPositions || []).length > 0 || (eventPositions || []).length > 0);
  return result;
}

export function instructorsAnyMergedSupportPositionsIncludesSupportPositionId(
  instructors: ChangeEventInstructorDto[],
  supportDetails: ChangeEventSupportDetailsDto
): boolean {
  const newSupportPositionsWithEventNewModelPositions = (supportDetails.newSupportPositions || [])
    .filter(x => (x.eventNewModelPositions || []).length <= 0)
    .map(x => x.id);

  const supportPositionsWithEventPositions = (supportDetails.supportPositions || [])
    .filter(x => (x.eventPositions || []).length <= 0)
    .map(x => x.id);

  const mergedSupportPositions: string[] = [...newSupportPositionsWithEventNewModelPositions, ...supportPositionsWithEventPositions];

  return instructors.any(x => mergedSupportPositions.includes(x.supportPositionId));
}

export function hasInstructors(eventType: EventTypeDto): boolean {
  var result = eventType && eventType.instructor === 'Yes';
  return result;
}

export function hasStudents(eventType: EventTypeDto): boolean {
  return eventType && eventType.participants;
}

// export function hasSupportDetails(eventType: EventTypeDto): boolean {
//   return eventType && eventType.requestFieldGroups && eventType.requestFieldGroups.includes(EventFieldGroups.SupportDetails.toString());
// }

export function hasSupportDetails(eventType: EventTypeDto): boolean {
  const result =
    eventType &&
    eventType.requestFieldGroups &&
    eventType.requestFieldGroups.includes(String(EventFieldGroups[EventFieldGroups.SupportDetails]));
  return result;
}

export function getEventOwners(eventType: EventTypeDto): string[] {
  if (eventType == null) return [];
  let owners: string[] = [];

  if (eventType.roleThatOwnEvents != null) owners.push(eventType.roleThatOwnEvents);
  if ((eventType.rolesThatOwnEventsExtended || []).length !== 0) owners = [...owners, ...(eventType.rolesThatOwnEventsExtended || [])];

  return [...new Set(owners)];
}

export const isExtendedWorkflow = (eventTypeCategory: string): boolean => {
  return !isNullOrWhiteSpaces(eventTypeCategory) && EventTypeCategory[eventTypeCategory] === EventTypeCategory.Extended;
};

export function isGEA(eventType: EventTypeDto): boolean {
  return (
    eventType != null &&
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    eventType.requestFieldGroups.any(x => EventFieldGroups[x] === EventFieldGroups.SupportDetails) &&
    (eventType.rolesThatCreateRequests || []).length !== 0 &&
    eventType.rolesThatCreateRequests.any(x => EventUserRoles[x] === EventUserRoles.FactoryLead) &&
    eventType.rolesThatCreateRequests.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    (eventType.rolesThatValidateRequestsExtended || []).length >= 2 &&
    eventType.rolesThatValidateRequestsExtended.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.GlobalEngineering)
  );
}

export function isGMA(eventType: EventTypeDto): boolean {
  return (
    eventType != null &&
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    eventType.requestFieldGroups.any(x => EventFieldGroups[x] === EventFieldGroups.SupportDetails) &&
    (eventType.rolesThatCreateRequests || []).length !== 0 &&
    eventType.rolesThatCreateRequests.any(x => EventUserRoles[x] === EventUserRoles.FactoryLead) &&
    eventType.rolesThatCreateRequests.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    (eventType.rolesThatValidateRequestsExtended || []).length >= 3 &&
    eventType.rolesThatValidateRequestsExtended.any(
      x => EventUserRoles[x] === EventUserRoles.Planner || EventUserRoles[x] === EventUserRoles.PlannerMTC
    ) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.GlobalManufacturing) &&
    eventType.rolesThatValidateRequestsExtended.any(x => EventUserRoles[x] === EventUserRoles.RegionalManufacturingVP)
  );
}

export function getValidators(eventType: EventTypeDto): string[] {
  if (eventType == null) return [];
  let validators: string[] = [];
  if (EventTypeCategory[eventType.eventTypeCategory] !== EventTypeCategory.Extended && eventType.roleThatValidateRequests != null)
    validators.push(eventType.roleThatValidateRequests);
  if (
    EventTypeCategory[eventType.eventTypeCategory] === EventTypeCategory.Extended &&
    (eventType.rolesThatValidateRequestsExtended || []).length !== 0
  )
    validators = [...validators, ...eventType.rolesThatValidateRequestsExtended];
  return validators;
}
