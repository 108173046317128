import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { DropDownDeliveryTypesStore, DeliveryTypeDto } from 'stores/configuration/events-n-requests/delivery-types-store';

interface MultipleDeliveryMethodsEditorProps extends WithTranslation {
  value: DeliveryTypeDto[];
  onChange?: (value: DeliveryTypeDto[]) => void;
  deliveryMethods?: DropDownDeliveryTypesStore;
  placeholder?: string;
  clearable?: boolean;
}

interface MultipleDeliveryMethodsEditorState {
  options: MultipleSelectionDropdownOptions<DeliveryTypeDto>[];
}

@connect(['deliveryMethods', DropDownDeliveryTypesStore])
class MultipleDeliveryMethodsEditor extends React.Component<MultipleDeliveryMethodsEditorProps, MultipleDeliveryMethodsEditorState> {
  private get deliveryMethodsStore() {
    return this.props.deliveryMethods;
  }

  state: MultipleDeliveryMethodsEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(deliveryMethods: QueryResult<DeliveryTypeDto>): MultipleSelectionDropdownOptions<DeliveryTypeDto>[] {
    return deliveryMethods.items.map(deliveryMethod => ({
      id: deliveryMethod.id,
      value: deliveryMethod,
      text: deliveryMethod.name
    }));
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.deliveryMethodsStore
        .getAllAsync({
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<DeliveryTypeDto>('name'), useProfile: false }],
          filter: []
        })
        .then(deliveryMethods => this.setState({ options: this.mapToOptions(deliveryMethods) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        onOpenDropDown={this.onOpenDropDown}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleDeliveryMethodsEditor);
