import { AzureStorageUploadResult, BaseDto, FileInfo, ItemResult, SaSTokenInfoDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { AsyncAction, ReduxRepository, repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { InstructorVisaDto } from 'stores/skills/instructor-visa-store';
import { RestrictionWarningDto } from './restrictions-warnings-store';
import { DateTimeService } from 'services/datetime-service';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import * as AzureStorageNpm from 'azure-storage';

export interface InstructorVisaRestrictionDto extends BaseDto {
  instructorVisa: InstructorVisaDto;
  restriction: RestrictionWarningDto;
}

export interface InstructorVisaRestrictionDownloaderState {
  isBusy: boolean;
  result: ItemResult<any>;
}

export interface InstructorVisaRestrictionUploaderState {
  isBusy: boolean;
  result: AzureStorageUploadResult;
}

export interface CreateInstructorVisaRestrictionDto {}

export interface ChangeInstructorVisaRestrictionDto extends InstructorVisaRestrictionDto {}

export class InstructorVisaRestrictionValidator extends AbstractValidator<InstructorVisaRestrictionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorVisa.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.instructorVisa.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.instructorVisa.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.instructorVisa.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    // this.validateIf(o => o.instructorVisa.startDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIf(o => o.instructorVisa.expireDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.startDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.expireDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isSame(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isBefore(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.restriction.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.restriction.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.restriction.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.restriction.comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.restriction.contractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.restriction.flightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o => o.restriction.ttcLocationId && o.instructorVisa.ttcLocationId && o.restriction.ttcLocationId === o.instructorVisa.ttcLocationId
      )
      .withFailureMessage(i18n.t('TTC locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o =>
          o.restriction.travelingLocationId &&
          o.instructorVisa.travelingLocationId &&
          o.restriction.travelingLocationId === o.instructorVisa.travelingLocationId
      )
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.restriction.visaId && o.instructorVisa.visaId && o.restriction.visaId === o.instructorVisa.visaId)
      .withFailureMessage(i18n.t('Visa Restrictions for Visa and Restrictions are differents'));
  }
}

export class ChangeInstructorVisaRestrictionValidator extends AbstractValidator<ChangeInstructorVisaRestrictionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorVisa.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.instructorVisa.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.instructorVisa.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.instructorVisa.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    // this.validateIf(o => o.instructorVisa.startDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIf(o => o.instructorVisa.expireDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.startDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.expireDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isSame(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isBefore(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Restriction Id is required'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.restriction.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.restriction.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.restriction.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.restriction.comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.restriction.contractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.restriction.flightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o => o.restriction.ttcLocationId && o.instructorVisa.ttcLocationId && o.restriction.ttcLocationId === o.instructorVisa.ttcLocationId
      )
      .withFailureMessage(i18n.t('TTC locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o =>
          o.restriction.travelingLocationId &&
          o.instructorVisa.travelingLocationId &&
          o.restriction.travelingLocationId === o.instructorVisa.travelingLocationId
      )
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.restriction.visaId && o.instructorVisa.visaId && o.restriction.visaId === o.instructorVisa.visaId)
      .withFailureMessage(i18n.t('Visa Restrictions for Visa and Restrictions are differents'));
  }
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.summary')
export class InstructorVisaRestrictionStore extends DataStore<InstructorVisaRestrictionDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-instructor-visa-restrictions';
  updatePath = 'v1/update-instructor-visa-restriction';
  deletePath = '';

  protected validate(item: InstructorVisaRestrictionDto) {
    return new InstructorVisaRestrictionValidator().validate(item);
  }

  constructor() {
    super('MASTERDATA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.change')
export class ChangeInstructorVisaRestrictionStore extends FormStore<ChangeInstructorVisaRestrictionDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-instructor-visa-restrictions';
  updatePath = 'v1/update-instructor-visa-restriction';

  protected validate(item: ChangeInstructorVisaRestrictionDto) {
    return new ChangeInstructorVisaRestrictionValidator().validate(item);
  }

  constructor() {
    super('CHANGE_MASTERDATA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.download')
export class DownloadInstructorVisaRestrictionStore extends ReduxRepository<InstructorVisaRestrictionDownloaderState> {
  baseUrl = 'skills';
  initBackgroundDownloadUrl = 'v1/download-instructor-visa-restriction-in-background';

  public BACKGROUND_DOWNLOAD_INITIALIZED = 'BACKGROUND_DOWNLOAD_INITIALIZED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.onBackgroundDownloadInitilized.bind(this);

    this.addReducer(this.BACKGROUND_DOWNLOAD_INITIALIZED, this.onBackgroundDownloadInitilized, 'AsyncAction');
  }

  public async initBackgroundDownload() {
    const url = `${this.baseUrl}/${this.initBackgroundDownloadUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.BACKGROUND_DOWNLOAD_INITIALIZED, httpService.post(url, null));
  }

  private onBackgroundDownloadInitilized = (): AsyncAction<AxiosResponse<any>, InstructorVisaRestrictionDownloaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: result => {
        ToastComponent({ text: i18n.t('The email has been sent'), type: 'success-toast' });
        return { ...this.state, isBusy: false, result: result.data };
      },
      onError: error => {
        ToastComponent({ text: i18n.t('File export failed'), type: 'error-toast' });
        return {
          ...this.state,
          isBusy: false,
          result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
        };
      }
    };
  };
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.upload')
export class UploadInstructorVisaRestrictionStore extends ReduxRepository<InstructorVisaRestrictionUploaderState> {
  baseUrl = 'skills';
  getSaSTokenUrl = 'v1/get-instructor-visa-restriction-blob-sas-url';
  downloadTemplateUrl = 'v1/get-instructor-visa-restriction-import-template';

  public SAS_TOKEN_OBTAINED = 'SAS_TOKEN_OBTAINED';
  public FILE_UPLOADED = 'FILE_UPLOADED';
  public TEMPLATE_DOWNLOADED = 'TEMPLATE_DOWNLOADED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.uploadFile.bind(this);
    this.onSaSTokenObtained.bind(this);
    this.onFileUploaded.bind(this);
    this.onTemplateDownloaded.bind(this);

    this.addReducer(this.SAS_TOKEN_OBTAINED, this.onSaSTokenObtained, 'AsyncAction');
    this.addReducer(this.FILE_UPLOADED, this.onFileUploaded, 'Simple');
    this.addReducer(this.TEMPLATE_DOWNLOADED, this.onTemplateDownloaded, 'AsyncAction');
  }

  public async uploadFile(fileInfo: FileInfo) {
    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getSaSTokenUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.SAS_TOKEN_OBTAINED, httpService.get<ItemResult<SaSTokenInfoDto>>(url, params), fileInfo);
  }

  private onSaSTokenObtained = (): AsyncAction<AxiosResponse<ItemResult<SaSTokenInfoDto>>, InstructorVisaRestrictionUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: (result: AxiosResponse<ItemResult<SaSTokenInfoDto>>, fileInfo: FileInfo) => {
        let data = result.data.item;
        const blobService = AzureStorage.Blob.createBlobServiceWithSas(data.host, data.saSToken).withFilter(
          new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
        );
        const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;

        blobService.createBlockBlobFromBrowserFile(
          data.container,
          data.filePath,
          fileInfo.content,
          {
            blockSize: customBlockSize,
            metadata: {
              originalName: encodeURIComponent(fileInfo.fileName),
              userId: fileInfo.userId,
              userName: fileInfo.userName,
              timestamp: DateTimeService.toString(DateTimeService.now())
            }
          },
          (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
            const res = { error, response };
            this.dispatch(this.FILE_UPLOADED, res);
          }
        );
        return { ...this.state };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };

  protected onFileUploaded = (result: AzureStorageUploadResult): InstructorVisaRestrictionUploaderState => {
    return { ...this.state, isBusy: false, result };
  };

  public async downloadTemplate() {
    const url = `${this.baseUrl}/${this.downloadTemplateUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, 'Master Data.xlsx'));
  }

  private onTemplateDownloaded = (): AsyncAction<AxiosResponse<any>, InstructorVisaRestrictionUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };
}
