import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { Category } from './clusters-store';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface NMFunctionalSubareaDto extends BaseDto {
  id: string;
  name: string;
  trainingNameId: string;
  trainingNameName: string;
  functionalAreaId: string;
  functionalAreaName: string;
  clusterId: string;
  clusterName: string;
  category: Category;
}

export interface CreateNMFunctionalSubareaDto {
  name: string;
  trainingNameId: string;
}

export interface ChangeNMFunctionalSubareaDto {
  id: string;
  name: string;
  trainingNameId: string;
}

export class CreateNMFunctionalSubareaValidator extends AbstractValidator<CreateNMFunctionalSubareaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Functional Subarea name is required'));

    this.validateIfString(o => o.trainingNameId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Training Name is required'));
  }
}

export class ChangeNMFunctionalSubareaValidator extends AbstractValidator<ChangeNMFunctionalSubareaDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Functional Subarea Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Functional Subarea name is required'));

    this.validateIfString(o => o.trainingNameId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Training Name is required'));
  }
}

@repository('@@NMFUNCTIONALSUBAREAS', 'nmFunctionalSubareas.summary')
export class NMFunctionalSubareasStore extends DataStore<NMFunctionalSubareaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalSubarea';
  retrievePath = 'get-nmFunctionalSubareas';
  updatePath = 'update-nmFunctionalSubarea';
  deletePath = 'delete-nmFunctionalSubarea';

  protected validate(item: NMFunctionalSubareaDto) {
    return new ChangeNMFunctionalSubareaValidator().validate(item);
  }

  constructor() {
    super('NMFUNCTIONALSUBAREA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@NMFUNCTIONALSUBAREAS', 'nmFunctionalSubareas.dropdown')
export class DropDownNMFunctionalSubareasStore extends DataStore<NMFunctionalSubareaDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-nmFunctionalSubareas';
  updatePath = '';
  deletePath = '';

  protected validate(item: NMFunctionalSubareaDto) {
    return new ChangeNMFunctionalSubareaValidator().validate(item);
  }

  constructor() {
    super('NMFUNCTIONALSUBAREADROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, nmFunctionalSubareaId?: string[], search?: string): Promise<QueryResult<NMFunctionalSubareaDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let nmFunctionalSubareasDto = this.state.items.map(({ item }) => item);

      if ((nmFunctionalSubareaId || []).length > 0) {
        nmFunctionalSubareasDto = nmFunctionalSubareasDto.filter(x => nmFunctionalSubareaId.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        nmFunctionalSubareasDto = nmFunctionalSubareasDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: nmFunctionalSubareasDto, count: nmFunctionalSubareasDto.length } as QueryResult<NMFunctionalSubareaDto>;
      return new Promise<QueryResult<NMFunctionalSubareaDto>>(resolve => resolve(itemResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<NMFunctionalSubareaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<NMFunctionalSubareaDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}

@repository('@@NMFUNCTIONALSUBAREAS', 'nmFunctionalSubareas.new')
export class NewNMFunctionalSubareaStore extends FormStore<CreateNMFunctionalSubareaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalSubarea';
  retrievePath = 'get-nmFunctionalSubareas';
  updatePath = 'update-nmFunctionalSubarea';

  protected validate(item: CreateNMFunctionalSubareaDto) {
    return new CreateNMFunctionalSubareaValidator().validate(item);
  }

  constructor() {
    super('NEW_NMFUNCTIONALSUBAREA', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@NMFUNCTIONALSUBAREAS', 'nmFunctionalSubareas.change')
export class ChangeNMFunctionalSubareaStore extends FormStore<ChangeNMFunctionalSubareaDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-nmFunctionalSubarea';
  retrievePath = 'get-nmFunctionalSubareas';
  updatePath = 'update-nmFunctionalSubarea';

  protected validate(item: ChangeNMFunctionalSubareaDto) {
    return new ChangeNMFunctionalSubareaValidator().validate(item);
  }

  constructor() {
    super('CHANGE_NMFUNCTIONALSUBAREA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
