import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  NewNMFunctionalAreaStore,
  CreateNMFunctionalAreaDto
} from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { isNullOrWhiteSpaces, formStoreHandler } from 'utils/useful-functions';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { ItemReference } from 'stores/dataStore';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Categories } from 'stores/requests/requests-store';

interface NewNMFunctionalAreaViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newNMFunctionalArea?: NewNMFunctionalAreaStore;
}

interface NewNMFunctionalAreaViewState {
  cluster: ItemReference;
  userClickedSave: boolean;
  category: Categories;
}

@connect(['newNMFunctionalArea', NewNMFunctionalAreaStore])
class NewNMFunctionalAreaView extends Component<NewNMFunctionalAreaViewProps, NewNMFunctionalAreaViewState> {
  constructor(props: NewNMFunctionalAreaViewProps) {
    super(props);
    this.props.newNMFunctionalArea.createNew({ name: '', categoryId: '', clusterId: '' });
    this.state = { category: Categories.Technical, cluster: null, userClickedSave: false };
  }

  private onCreateNewItem = async () => {
    this.setState({ userClickedSave: true });
    const result = await this.props.newNMFunctionalArea.submit();
    result?.isSuccess && this.props.onClose(true);
  };

  private onCancelNewItem = () => {
    this.props.newNMFunctionalArea.clear();
    this.props.onClose(false);
  };

  categoryChangeHandler = (category: Categories) => this.setState({ category, cluster: null });

  public render() {
    const { category, cluster, userClickedSave } = this.state;
    const { t, newNMFunctionalArea } = this.props;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.props.newNMFunctionalArea.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Functional Area')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {newNMFunctionalArea.state.result && !newNMFunctionalArea.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={newNMFunctionalArea.state.result.messages.map(o => o.body)}
              />
            )}
            {newNMFunctionalArea.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!newNMFunctionalArea.state.item.name && userClickedSave}
                    label={t('Functional Area')}
                    placeholder={t('Name')}
                    value={newNMFunctionalArea.state.item.name}
                    onChange={(_, { value }) => formStoreHandler<CreateNMFunctionalAreaDto>(newNMFunctionalArea, 'name', value)}
                  />
                  <Form.Field required>
                    <div
                      className={`required ${
                        (newNMFunctionalArea.state.item.clusterId == null ||
                          isNullOrWhiteSpaces(newNMFunctionalArea.state.item.clusterId)) &&
                        userClickedSave
                          ? 'error'
                          : ''
                      } field`}
                    >
                      <label>{t('Cluster')}</label>
                      <NMClusterEditor
                        placeholder={t('Cluster')}
                        nullable
                        category={category}
                        value={cluster}
                        onChange={c => formStoreHandler<CreateNMFunctionalAreaDto>(newNMFunctionalArea, 'clusterId', c?.id)}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <div className="custom-actions">
          <Form.Field>
            <label>{t('Category')}</label>
            <CategoryFormEditor
              placeholder={t('Filter by Category')}
              className="form__header__dropdown-style"
              value={category}
              onChange={this.categoryChangeHandler}
            />
          </Form.Field>
          <Modal.Actions>
            <Button onClick={this.onCancelNewItem} basic>
              {t('Cancel')}
            </Button>
            <Button onClick={this.onCreateNewItem} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(NewNMFunctionalAreaView);
