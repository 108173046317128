import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import { ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { CommandResult, CommandModel, Message } from './types';
import { clone } from 'utils/object';
import { ValidationResult } from 'fluent-ts-validator';

export interface FormModel<T> extends CommandModel<T> {
  status: 'New' | 'Unchanged' | 'Modified';
  item: T;
  [key: string]: any;
}

export abstract class FormStore<T> extends ReduxRepository<FormModel<T>> {
  public ENTITY_LOAD;
  public ENTITY_CREATED;
  public ENTITY_SAVE;
  public ENTITY_CHANGED;
  public ENTITY_VALIDATED;
  public RESET;
  public CLEAR_MESSAGES;
  public CHANGE_MESSAGES;

  private _initialState: FormModel<T>;

  protected abstract get baseUrl(): string;
  protected abstract get createPath(): string;
  protected abstract get retrievePath(): string;
  protected abstract get updatePath(): string;

  protected abstract validate(item: T): ValidationResult;

  constructor(entityName: string, initialState: FormModel<T>) {
    super(initialState);
    this._initialState = clone(initialState);
    this.ENTITY_LOAD = `${entityName}_LOAD`;
    this.ENTITY_CREATED = `${entityName}_CREATED`;
    this.ENTITY_SAVE = `${entityName}_SAVE`;
    this.ENTITY_CHANGED = `${entityName}_CHANGED`;
    this.ENTITY_VALIDATED = `${entityName}_VALIDATED`;
    this.RESET = `${entityName}_COMPLETE_CLEAR`;
    this.CLEAR_MESSAGES = `${entityName}_CLEAR_MESSAGES`;
    this.CHANGE_MESSAGES = `${entityName}_CHANGE_MESSAGES`;

    this.addReducer(
      this.ENTITY_CREATED,
      (item: T): FormModel<T> => ({
        isBusy: false,
        item: item || ({} as T),
        status: 'New',
        result: undefined
      }),
      'Simple'
    );

    this.addReducer(this.RESET, this.onReset, 'Simple');

    this.addReducer(
      this.ENTITY_LOAD,
      (): AsyncAction<AxiosResponse<T>, FormModel<T>> => {
        return {
          onStart: args => ({ ...this.state, isBusy: true }),
          onSuccess: (value, args) => {
            return {
              ...this.state,
              isBusy: false,
              item: value.data,
              status: 'Unchanged',
              result: undefined
            };
          },
          onError: (error, args) =>
            ({
              ...this.state,
              isBusy: false,
              result:
                error && error.response && error.response.data && error.response.data.messages
                  ? error.response.data
                  : {
                      isSuccess: false,
                      items: [],
                      messages: [{ body: error.message || error, level: 'Error' }]
                    }
            } as FormModel<T>)
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.ENTITY_SAVE,
      (): AsyncAction<AxiosResponse<CommandResult<T>>, FormModel<T>> => {
        return {
          onStart: () => ({ ...this.state, isBusy: true }),
          onSuccess: value => {
            return {
              ...this.state,
              isBusy: false,
              status: 'Unchanged',
              result: value.data
            };
          },
          onError: error =>
            ({
              ...this.state,
              isBusy: false,
              result:
                error && error.response && error.response.data && error.response.data.messages
                  ? error.response.data
                  : {
                      isSuccess: false,
                      items: [],
                      messages: [{ body: error.message || error, level: 'Error' }]
                    }
            } as FormModel<T>)
        };
      },
      'AsyncAction'
    );

    this.addReducer(
      this.ENTITY_CHANGED,
      (item: Partial<T>): FormModel<T> => ({
        ...this.state,
        status: this.state.status === 'Unchanged' ? 'Modified' : this.state.status,
        // item: Object.assign((this.state || ({} as any)).item || {}, item || {}),
        item: { ...(this.state.item || ({} as any)), ...(item || {}) }
      }),
      'Simple'
    );

    this.addReducer(
      this.CLEAR_MESSAGES,
      (): FormModel<T> => ({
        ...this.state,
        result: this.state.result == null ? this.state.result : { ...this.state.result, messages: [] }
      }),
      'Simple'
    );

    this.addReducer(
      this.CHANGE_MESSAGES,
      (newMsgs: Message[], replace: boolean = false): FormModel<T> => {
        const { result } = this.state;
        let messages = [...newMsgs];
        if (result) {
          if (!replace) messages = [...result.messages, ...newMsgs];
          return { ...this.state, result: { ...result, messages } };
        } else return { ...this.state, result: { isSuccess: false, items: [], messages } };
      },
      'Simple'
    );

    this.addReducer(
      this.ENTITY_VALIDATED,
      (result: ValidationResult): FormModel<T> => ({
        ...this.state,
        result: {
          isSuccess: false,
          items: [],
          messages:
            result == null
              ? [{ propertyName: '', body: 'Something went wrong', level: 'Error' } as Message]
              : result.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
        }
      }),
      'Simple'
    );
  }

  public changeMessages = (messages: Message[], replace: boolean = false) => {
    this.dispatch(this.CHANGE_MESSAGES, messages, replace);
  };

  public async getById(id: string): Promise<T> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(this.ENTITY_LOAD, httpService.get<T>(`${this.baseUrl}/${this.retrievePath}/${id}`));
    return result.data;
  }

  public clearMessages = () => {
    this.dispatch(this.CLEAR_MESSAGES);
  };

  public clear() {
    this.dispatch(this.ENTITY_CHANGED, clone(this._initialState.item));
  }

  public reset() {
    this.dispatch(this.RESET);
  }

  public createNew(item?: T) {
    this.dispatch(this.ENTITY_CREATED, item);
  }

  public change(partialItem?: Partial<T>) {
    this.dispatch(this.ENTITY_CHANGED, partialItem);
  }

  public async Validate(validationResult: ValidationResult) {
    this.dispatch(this.ENTITY_VALIDATED, validationResult);
  }

  protected onReset = () => {
    return {
      isBusy: false,
      item: null,
      result: null,
      status: 'Modified'
    };
  };

  public async submit(update: boolean = false): Promise<CommandResult<T>> {
    const validationResult = this.validate(this.state.item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages:
          validationResult == null
            ? [{ propertyName: '', body: 'Something went wrong', level: 'Error' } as Message]
            : validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    } else {
      const httpService = container.get(HttpService);
      let result = null;
      if (update) {
        result = await this.dispatchAsync(
          this.ENTITY_SAVE,
          httpService.put<T, CommandResult<T>>(`${this.baseUrl}/${this.updatePath}`, this.state.item)
        );
      } else {
        result = await this.dispatchAsync(
          this.ENTITY_SAVE,
          httpService.post<T, CommandResult<T>>(`${this.baseUrl}/${this.createPath}`, this.state.item)
        );
      }
      if (result) return result.data;
      return null;
    }
  }

  public async update(): Promise<CommandResult<T>> {
    const validationResult = this.validate(this.state.item);
    if (validationResult.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return {
        isSuccess: false,
        messages:
          validationResult == null
            ? [{ propertyName: '', body: 'Something went wrong', level: 'Error' } as Message]
            : validationResult.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    } else {
      const httpService = container.get(HttpService);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<T, CommandResult<T>>(`${this.baseUrl}/${this.updatePath}`, this.state.item)
      );
      if (result) return result.data;
      return null;
    }
  }

  // protected patch(actionName: string, partial: Partial<T>) {
  //     const httpService = container.get(HttpService);
  //     return this.dispatchAsync(actionName, httpService.patch(`${this.baseUrl}`, partial), partial);
  // }

  // protected onPatch(): AsyncAction<AxiosResponse<CommandResult<T>>, FormModel<T>> {
  //     return {
  //         onStart: (args) => ({ ...this.state, isBusy: true }),
  //         onSuccess: (result, partial: Partial<T>) => {
  //             return {
  //                 ...this.state,
  //                 item: Object.assign((this.state || {} as any).item || {}, partial),
  //                 isBusy: false,
  //                 status: 'Unchanged',
  //                 result: result.data
  //             };
  //         },
  //         onError: (error, args) => ({
  //             ...this.state,
  //             isBusy: false,
  //             result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : {
  //                 isSuccess: false,
  //                 items: [],
  //                 messages: [{ body: error.message || error, level: 'Error' }]
  //             }
  //         } as FormModel<T>)
  //     };
  // }
}
