import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Grid } from 'semantic-ui-react';
import { ProfessionDto, ProfessionsStore } from '../../../../../../src/stores/configuration/profiles/profession-roles-store';
import { ItemState, OrderDefinition, Query } from '../../../../../../src/stores/dataStore';
import { CommandResult } from '../../../../../../src/stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import ChangeProfessionView from './edit-profession';

import NewProfessionView from './new-profession';

export interface ProfessionRolesListProps extends WithTranslation, RouteComponentProps {
  professions: ProfessionsStore;
}

export interface ProfessionRolesListState {
  query: Query;
  newProfessionShown: boolean;
  changeProfessionShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['professions', ProfessionsStore])
class ProfessionRolesListPage extends React.Component<ProfessionRolesListProps, ProfessionRolesListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newProfessionShown: false,
      changeProfessionShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.professions.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: ProfessionDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.professions.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: ProfessionDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.professions.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newProfessionShown: true });
  }

  @autobind
  private onNewItemClosed() {
    this.setState({ newProfessionShown: false });
    this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeProfessionShown: true });
  }

  @autobind
  private onEditItemClosed() {
    this.setState({ changeProfessionShown: false });
    this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Role'),
          tooltipRenderer: false,
          renderer: data => <span>{data.profession}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.profession}
              fluid
              onChange={(e, { value }) => {
                data.profession = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Role')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<ProfessionDto>('profession'), `contains(${nameof<ProfessionDto>('profession')}, '${value}')`)
              }
              onClear={() => onClear(nameof<ProfessionDto>('profession'))}
              active={activeFilters.includes(nameof<ProfessionDto>('profession'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<ProfessionDto>('profession'),
            useProfile: false
          }
        }
      ],
      data: this.props.professions.state
    } as TableModel<ProfessionDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.professions.state.result && !this.props.professions.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.professions.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newProfessionShown && !this.state.changeProfessionShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Role')}
              deleteConfirmationMessage={t(
                'This action may affect some Assessment Question Banks and Templates related to this professional role. Are you sure that you want to delete the item?'
              )}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newProfessionShown && <NewProfessionView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeProfessionShown && (
            <ChangeProfessionView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(ProfessionRolesListPage);
