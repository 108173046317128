import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Popup, Menu, Dimmer, Loader, Grid } from 'semantic-ui-react';

import { ItemState, OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { CommandResult, FileInfo } from 'stores/types';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';

import { TableModel, TableView } from 'widgets/collections/table';

import NewIEView from './new-instructor-expertise';
import ChangeIEView from './edit-instructor-expertise';
import { EventTypesStore } from 'stores/configuration/events-workflow/event-types-store';

import { DropDownEquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import { DropdownOemsStore } from 'stores/configuration/machinery/oems-store';
import { DropDownMachineModelsStore } from 'stores/configuration/machinery/machine-models-store';
import { DropDownTrainingLevelsStore } from 'stores/configuration/events-n-requests/training-levels-store';
import { DropDownNMFunctionalAreasStore } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { DropDownNMFunctionalSubareasStore } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { DropDownNMTrainingNamesStore } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { DropDownProfessionsStore } from '../../../../../src/stores/configuration/profiles/profession-roles-store';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import NMFunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import NMFunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import NMClusterEditor from 'widgets/bussiness/cluster-editor';
import { nameof, getProperties } from 'utils/object';
import UserEditor from 'widgets/bussiness/user-by-role-editor';
import NMTrainingNameEditor from 'widgets/bussiness/training-name-editor';
import './instructors-expertise.less';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFileExcel, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { DownloadNMRInstructorExpertiseStore, UploadNMRInstructorExpertiseStore } from 'stores/skills/instructor-expertise-upload-store';

export interface NMRInstructorExpertisesListProps extends WithTranslation, RouteComponentProps {
  instructorExpertisesStore: InstructorExpertiseStore;
  eventTypes: EventTypesStore;
  equipmentTypes: DropDownEquipmentTypesStore;
  oems: DropdownOemsStore;
  machineModels: DropDownMachineModelsStore;
  trainingLevels: DropDownTrainingLevelsStore;
  nmrFunctionalAreas: DropDownNMFunctionalAreasStore;
  nmrFunctionalSubAreas: DropDownNMFunctionalSubareasStore;
  trainingNames: DropDownNMTrainingNamesStore;
  professions: DropDownProfessionsStore;
  uploadNMRInstructorExpertiseStore: UploadNMRInstructorExpertiseStore;
  downloadNMRInstructorExpertiseStore: DownloadNMRInstructorExpertiseStore;
}

export interface NMRInstructorExpertisesListState {
  query: Query;
  newIEShown: boolean;
  nechangeIEShown: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  eventType: ItemReference;
  active: boolean;
  cluster: ItemReference;
  NMRcluster: ItemReference;
  role: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  functionalArea: ItemReference;
  functionalSubArea: ItemReference;
  trainingLevel: ItemReference;
  instructor: ItemReference;
  isMachineRelated: boolean;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  showDownloadMsg: boolean;
  uploadLoadingMessage: string;

  trainingName: ItemReference;
  someFilterOpened: boolean;
}

@connect(
  ['instructorExpertisesStore', InstructorExpertiseStore],
  ['uploadNMRInstructorExpertiseStore', UploadNMRInstructorExpertiseStore],
  ['downloadNMRInstructorExpertiseStore', DownloadNMRInstructorExpertiseStore],
  ['eventTypes', EventTypesStore],
  ['equipmentTypes', DropDownEquipmentTypesStore],
  ['oems', DropdownOemsStore],
  ['machineModels', DropDownMachineModelsStore],
  ['trainingLevels', DropDownTrainingLevelsStore],
  ['nmrFunctionalAreas', DropDownNMFunctionalAreasStore],
  ['nmrFunctionalSubAreas', DropDownNMFunctionalSubareasStore],
  ['trainingNames', DropDownNMTrainingNamesStore],
  ['professions', DropDownProfessionsStore]
)
class NMRInstructorExpertisesListPage extends React.Component<NMRInstructorExpertisesListProps, NMRInstructorExpertisesListState> {
  timer: any = null;
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [],
        skip: 0,
        take: 10,
        parameters: { hideDeprecated: 'true', orderbyModifiedOnDesc: 'true' }
      },
      newIEShown: false,
      nechangeIEShown: false,
      selectedItem: null,
      active: true,
      activeFilters: [],
      eventType: null,
      cluster: null,
      role: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      functionalArea: null,
      functionalSubArea: null,
      trainingLevel: null,
      instructor: null,
      isMachineRelated: false,
      uploadMenuOpen: false,
      showUploadMsg: false,
      showDownloadMsg: false,
      uploadLoadingMessage: '',
      trainingName: null,
      NMRcluster: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  @autobind
  private load() {
    const activeFilters = this.state.activeFilters;
    activeFilters['IsMachineRelated'] = { IsMachineRelated: { eq: false } };
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );

    let orderBy = [...this.state.query.orderBy];
    const query = Object.assign(this.state.query, { filter, orderBy });
    this.setState({ query }, () => {
      this.props.instructorExpertisesStore.getAllAsync(this.state.query);
    });
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    let instructorOrder = null;
    if (!!orderBy && orderBy.length > 0) {
      if (orderBy.some(x => x.field === 'instructor')) {
        instructorOrder = orderBy.find(x => x.field === 'instructor').direction;
      }
    }

    //OrderDefinition
    this.setState(
      {
        query: Object.assign(this.state.query, {
          parameters: { ...this.state.query.parameters, instructorOrder: instructorOrder }
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: InstructorExpertiseDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.instructorExpertisesStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: InstructorExpertiseDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.instructorExpertisesStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.filter(x => x.id !== 'instructor').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const parameters = { hideDeprecated: 'true', orderbyModifiedOnDesc: 'true' };
    if (filter.length !== activeFilters.length) {
      const value = (filters.firstOrDefault(x => x.id === 'instructor') || { filter: '' }).filter;
      parameters['instructor'] = value;
    }

    const query = Object.assign(this.state.query, { filter, skip: 0, parameters });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newIEShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newIEShown: false });

    if (isSuccess) this.load();
  }

  private onNMRClusterEditorChange(value: ItemReference) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRClusterId'];
    } else {
      activeFilters['NMRClusterId'] = {
        NMRClusterId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }
    this.setState(
      {
        activeFilters: activeFilters,
        NMRcluster: value
      },
      this.load
    );
  }

  onChangeFunctionalAreaFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['nmrFunctionalAreaId'];
    } else {
      activeFilters['nmrFunctionalAreaId'] = {
        nmrFunctionalAreaId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        functionalArea: value
      },
      this.load
    );
  }

  @autobind
  private onChangeTrainingName(value: ItemReference) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRTrainingNameId'];
    } else {
      activeFilters['NMRTrainingNameId'] = {
        NMRTrainingNameId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        trainingName: value
      },
      this.load
    );
  }

  onChangeFunctionalSubAreaFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['NMRFunctionalSubAreaId'];
    } else {
      activeFilters['NMRFunctionalSubAreaId'] = {
        NMRFunctionalSubAreaId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        functionalSubArea: value
      },
      this.load
    );
  }

  @autobind
  private onInstructorNameFilterChanged = (value: ItemReference) => {
    this.timer = setTimeout(() => {
      const activeFilters = this.state.activeFilters;
      if (!value) {
        delete activeFilters['instructors'];
      } else {
        activeFilters['instructors'] = {
          InstructorId: {
            eq: { type: 'guid', value: value.id }
          }
        };
      }
      this.setState(
        {
          activeFilters: activeFilters,
          instructor: value
        },
        this.load
      );
    }, 1000);
  };

  @autobind
  private onEventTypeFilterChanged(value: ItemReference, fieldName) {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters[fieldName];
    } else {
      activeFilters[fieldName] = {
        eventTypeId: { eq: { type: 'guid', value: value.id } }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        eventType: value
      },
      this.load
    );
  }

  onProfessionChange(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['profession'];
    } else {
      activeFilters['profession'] = {
        roleId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        role: value
      },
      this.load
    );
  }

  onChangeTrainingLevelFilter(value: ItemReference): void {
    const activeFilters = this.state.activeFilters;
    if (!value) {
      delete activeFilters['trainingLevelId'];
    } else {
      activeFilters['trainingLevelId'] = {
        trainingLevelId: {
          eq: { type: 'guid', value: value.id }
        }
      };
    }

    this.setState(
      {
        activeFilters: activeFilters,
        trainingLevel: value
      },
      this.load
    );
  }

  @autobind
  private clearMrFilters() {
    const activeFilters = this.state.activeFilters;
    delete activeFilters['MachineRelatedClusterId'];
    delete activeFilters['equipmentTypeId'];
    delete activeFilters['oemId'];
    delete activeFilters['machineModelId'];

    this.setState({
      activeFilters: activeFilters,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null
    });
  }

  @autobind
  private clearNmrFilters() {
    const activeFilters = this.state.activeFilters;
    delete activeFilters['NMRClusterId'];
    delete activeFilters['nmrFunctionalAreaId'];
    delete activeFilters['NMRTrainingNameId'];
    delete activeFilters['NMRFunctionalSubAreaId'];
    this.setState({
      activeFilters: activeFilters,
      functionalArea: null,
      functionalSubArea: null,
      trainingName: null,
      NMRcluster: null
    });
  }

  @autobind
  private clearAllFilters() {
    this.clearMrFilters();
    this.clearNmrFilters();
    this.setState(
      {
        activeFilters: [],
        instructor: null,
        eventType: null,
        role: null,
        trainingLevel: null
      },
      this.load
    );
  }

  private getBackgroundUploadMessage = () => {
    const { isBusy, result } = this.props.uploadNMRInstructorExpertiseStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    let error = false;
    if (result.error || (result.response && !result.response.isSuccessful)) {
      header = t('Something went wrong with the file');
      content = (result.error || {}).message;
      error = true;
    } else if (result.error == null && result.response && result.response.isSuccessful) {
      header = t('File uploaded successfully');
      content = t('You will receive an email when the process ends');
      error = false;
    } else return null;

    return (
      <Message
        className={error && 'error-message__style'}
        icon={error && 'exclamation circle'}
        onDismiss={this.handleUploadMsgDismiss}
        header={header}
        content={content}
        error={error}
        success={!error}
      />
    );
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadNMRInstructorExpertiseStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = (result.messages || []).map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private handleUploadMsgDismiss = () => {
    this.setState({ showUploadMsg: false });
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  private initExportQuestionBankProcess = () => {
    this.setState({ showDownloadMsg: true });
    this.props.downloadNMRInstructorExpertiseStore.initBackgroundDownload();
  };

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.uploadNMRInstructorExpertiseStore
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.uploadNMRInstructorExpertiseStore
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  @autobind
  private handleOnEnterKeydown(item: any) {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  }

  public render() {
    const { t } = this.props as any;
    const { someFilterOpened } = this.state;
    const anyFilter = this.state.activeFilters && Object.keys(this.state.activeFilters).length > 1;
    var pillarId: string = null;
    if (IdentityService.isPowerInstructor(this.identityService.getUserInfo())) {
      pillarId = this.identityService.getCurrentUserDto()?.pillar?.id;
    }

    const tableModel = {
      columns: [
        {
          title: t('Instructor'),
          tooltipRenderer: true,
          renderer: data => data.instructor,
          selectableHeader: false,
          sortDefinition: {
            field: nameof<InstructorExpertiseDto>('instructor'),
            useProfile: false
          }
        },
        {
          title: t('Event Type'),
          tooltipRenderer: true,
          renderer: data => data.eventTypeName,
          selectableHeader: false
        },
        {
          title: t('Role'),
          tooltipRenderer: true,
          renderer: data => data.roleName,
          selectableHeader: false
        },
        {
          title: t('Cluster'),
          tooltipRenderer: true,
          renderer: data => <>{data.nmrClusterName}</>,
          selectableHeader: false
        },
        {
          title: t('Functional Area'),
          tooltipRenderer: true,
          renderer: data => <>{data.nmrFunctionalAreaName}</>,
          selectableHeader: false
        },
        {
          title: t('Training Name'),
          tooltipRenderer: true,
          renderer: data => data.nmrTrainingName,
          selectableHeader: false
        },
        {
          title: t('Functional Subarea'),
          tooltipRenderer: true,
          renderer: data => data.nmrFunctionalSubAreaName,
          selectableHeader: false
        }
      ],
      data: this.props.instructorExpertisesStore.state
    } as TableModel<InstructorExpertiseDto>;
    return (
      <>
        {this.props.uploadNMRInstructorExpertiseStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{this.state.uploadLoadingMessage}</Loader>
          </Dimmer>
        )}
        {this.state.showUploadMsg && this.getBackgroundUploadMessage()}

        {this.props.downloadNMRInstructorExpertiseStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing download background process')}</Loader>
          </Dimmer>
        )}
        {this.state.showDownloadMsg && this.getBackgroundDownloadMessage()}
        {this.props.instructorExpertisesStore.state.result && !this.props.instructorExpertisesStore.state.result.isSuccess && (
          <Message
            error
            className="error-message__style"
            icon="exclamation circle"
            header={t('An error ocurred')}
            list={this.props.instructorExpertisesStore.state.result.messages.map(o => o.body)}
          />
        )}

        <div className="filters-wrapper instructor-expertise__filters-wrapper table__filters-share-main-actions">
          <UserEditor
            className="instructors-expertise-list__filter-dropdown filter"
            clearable={true}
            nullable
            role={'Instructor'}
            value={this.state.instructor}
            placeholder={t('Instructor')}
            onChange={c => this.onInstructorNameFilterChanged(c)}
            //onBlur={this.onBlurHandler}
            onBlur={this.onBlurHandler}
            onFocus={this.onFocusHandler}
          ></UserEditor>

          <EventTypeEditor
            reloadOnChange
            className="instructors-expertise-list__filter-dropdown filter"
            clearable={true}
            nullable
            value={this.state.eventType}
            onChange={value => this.onEventTypeFilterChanged(value, 'eventTypeId')}
            placeholder={t('Event Type')}
            onBlur={this.onBlurHandler}
            onFocus={this.onFocusHandler}
          />

          <ProfessionEditor
            reloadOnChange
            className="instructors-expertise-list__filter-dropdown filter"
            clearable={true}
            nullable
            value={this.state.role}
            onChange={c => this.onProfessionChange(c)}
            placeholder={t('Roles')}
            onBlur={this.onBlurHandler}
            onFocus={this.onFocusHandler}
          />

          {!this.state.isMachineRelated && (
            <>
              <NMClusterEditor
                className="instructors-expertise-list__filter-dropdown filter"
                clearable={true}
                nullable
                value={this.state.NMRcluster}
                onChange={c => {
                  this.onNMRClusterEditorChange(c);
                }}
                placeholder={t('Cluster')}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <NMFunctionalAreaEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                value={this.state.functionalArea}
                clusterId={this.state.NMRcluster ? this.state.NMRcluster.id : null}
                onChange={c => {
                  this.onChangeFunctionalAreaFilter(c);
                }}
                placeholder={t('Functional Area')}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <NMTrainingNameEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                funcionalAreaId={this.state.functionalArea ? this.state.functionalArea.id : null}
                value={this.state.trainingName}
                placeholder={t('Training Name')}
                onChange={c => {
                  this.onChangeTrainingName(c);
                }}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />

              <NMFunctionalSubAreaEditor
                className="instructors-expertise-list__filter-dropdown filter"
                nullable
                clearable={true}
                trainingNameId={this.state.trainingName ? this.state.trainingName.id : null}
                value={this.state.functionalSubArea}
                onChange={c => {
                  this.onChangeFunctionalSubAreaFilter(c);
                }}
                placeholder={t('Functional Subarea')}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </>
          )}
          {anyFilter && (
            <>
              <Button
                className="event-type-filter-icon-btn event-type-hide-filters-btn instructor-expertise-filter-icon-btn"
                onClick={this.clearAllFilters}
                icon
              >
                <Icon.Group>
                  <Icon name="filter" />
                  <Icon size="small" className="hide-filter" name="remove" />
                </Icon.Group>
              </Button>
            </>
          )}
        </div>
        <div className="instructor-expertise-wrapper">
          <div className="instructor-expertise-table-wrapper">
            <Grid className="event-types-list-grid">
              <Grid.Row className="event-types-list-items-row request-list__table-view">
                <TableView
                  /////////////////For build table keyboard navegation/////////////////
                  selectable={!this.state.newIEShown && !this.state.nechangeIEShown}
                  maxSelection={1}
                  onHideCheckbox={true}
                  selectionType={'allRow'}
                  onEnterKeydown={this.handleOnEnterKeydown}
                  onRowDoubleClick={this.handleOnRowDoubleClick}
                  preventEnterKeyDownEvent={someFilterOpened}
                  /////////////////For build table keyboard navegation/////////////////
                  model={tableModel}
                  onNewItem={this.onNewItem}
                  canCreateNew={!this.identityService.isInRole(Roles.Planner) && !this.identityService.isInRole(Roles.PlannerMTC)}
                  createNewButtonTitle={t('Add Instructor Expertise')}
                  onOrderByChanged={this.handleOrderBy}
                  onRefresh={this.load}
                  canEdit={false}
                  canDelete={true}
                  onDeleteRow={this.onDelete}
                  areActionsDisabled={(item: InstructorExpertiseDto) => pillarId != null && item.user.pillarId !== pillarId}
                  onSaveRow={this.onSaveRow}
                  onPageChange={this.handlePageChange}
                  onFilterChange={this.handleFilterChange}
                  extraMenu={[
                    {
                      className: 'menu-item-download',
                      content: (
                        <Popup
                          on="click"
                          position="bottom center"
                          open={this.state.uploadMenuOpen}
                          onOpen={() => this.setState({ uploadMenuOpen: true })}
                          onClose={() => this.setState({ uploadMenuOpen: false })}
                          trigger={
                            <Button
                              icon
                              size="mini"
                              className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                              onClick={() => this.setState({ uploadMenuOpen: true })}
                              data-tooltip={t('Import')}
                            >
                              <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                            </Button>
                          }
                        >
                          <Menu vertical secondary>
                            <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                              <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                              <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                              &nbsp;{t('Upload File')}
                            </Menu.Item>

                            <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                              <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                              &nbsp;{t('Download Template')}
                            </Menu.Item>
                          </Menu>
                        </Popup>
                      )
                    },
                    {
                      className: 'menu-item-download',
                      content: (
                        <Button
                          icon
                          size="medium"
                          onClick={this.initExportQuestionBankProcess}
                          className="custom-table-upload-btn black-btn"
                          data-tooltip={t('Export')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                        </Button>
                      )
                    }
                  ]}
                  extraActions={[
                    {
                      content: (
                        <>
                          <Icon name="edit" />
                          &nbsp;{t('Edit')}
                        </>
                      ),
                      onClick: item => {
                        this.setState({ selectedItem: item }, () => this.onEditItem());
                      }
                    }
                  ]}
                />
              </Grid.Row>
            </Grid>
          </div>
        </div>
        {(this.state.newIEShown && <NewIEView onClose={this.onNewItemClosed} isMachineRelated={false} {...this.props} />) ||
          (this.state.nechangeIEShown && (
            <ChangeIEView onClose={this.onEditItemClosed} currentInstructorExpertise={this.state.selectedItem} />
          ))}
      </>
    );
  }

  @autobind
  onEditItem(): void {
    this.setState({ nechangeIEShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ nechangeIEShown: false });
    if (isSuccess) this.load();
  }
}

export default withTranslation()(NMRInstructorExpertisesListPage);
