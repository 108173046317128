import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { DropDownPillarsStore, PillarDto } from 'stores/configuration/profiles/pillars-store';

interface MultiplePillarsEditorProps extends WithTranslation {
  value: PillarDto[];
  onChange?: (value: PillarDto[]) => void;
  pillars?: DropDownPillarsStore;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}

interface MultiplePillarsEditorState {
  options: MultipleSelectionDropdownOptions<PillarDto>[];
}

@connect(['pillars', DropDownPillarsStore])
class MultiplePillarsEditor extends React.Component<MultiplePillarsEditorProps, MultiplePillarsEditorState> {
  private get pillarStore() {
    return this.props.pillars;
  }

  state: MultiplePillarsEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(pillars: QueryResult<PillarDto>): MultipleSelectionDropdownOptions<PillarDto>[] {
    return pillars.items.map(pillar => ({
      id: pillar.id,
      value: pillar,
      text: pillar.name
    }));
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.pillarStore
        .getAllAsync({
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<PillarDto>('name'), useProfile: false }],
          filter: []
        })
        .then(pillars => this.setState({ options: this.mapToOptions(pillars) }));
    }
  };

  public render() {
    const { className } = this.props;
    return (
      <MultipleSelectionDropdown
        searchable
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        className={className ? className : ''}
        onOpenDropDown={this.onOpenDropDown}
        onBlur={e => this.props.onBlur && this.props.onBlur(e)}
        onFocus={e => this.props.onFocus && this.props.onFocus(e)}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultiplePillarsEditor);
