import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SsaCheckpointDto, SsaSkillSectionQuestionDto } from 'stores/assessments/templates/ssa-templates-store';
import { Input, Button } from 'semantic-ui-react';
import SsaQuestionComponent from './ssa-wizard-second-step-question-component';
import ChooseSSAQuestionsView from 'widgets/form/choose-ssa-questions-form';
import { SsaQuestionBankDto } from 'stores/assessments/questionBank/ssa-questionBank-store';

export interface SsaCheckpointComponentProps extends WithTranslation {
  checkpoint: SsaCheckpointDto;
  onRemoveCheckpoint: () => void;
  onDescriptionChange: (value: string) => void;
  skill: { id: string; name: string };
  category: string;
  profession: string;
  alreadySelectedQuestions: string[];
  onQuestionsChange: (value: SsaSkillSectionQuestionDto[]) => void;
}

const SsaCheckpointComponent: FunctionComponent<SsaCheckpointComponentProps> = ({
  t,
  onRemoveCheckpoint,
  onDescriptionChange,
  onQuestionsChange,
  checkpoint,
  skill,
  category,
  profession,
  alreadySelectedQuestions
}) => {
  const [showQuestions, setShowQuestions] = useState(false);
  const showQuestionsModal = () => {
    setShowQuestions(true);
  };
  const hideQuestionsModal = () => {
    setShowQuestions(false);
  };
  const addQuestionsHandler = (questions: SsaQuestionBankDto[]) => {
    const newQuestions: SsaSkillSectionQuestionDto[] = questions.map(({ id, friendlyId, question }) => ({
      id,
      friendlyId,
      question: question.text
    }));
    onQuestionsChange([...checkpoint.questions, ...newQuestions]);
  };

  const handleMoveUpQuestion = (questionIdx: number) => {
    const questions = [...checkpoint.questions];
    const temporalVariable = { ...questions[questionIdx - 1] };
    questions[questionIdx - 1] = { ...questions[questionIdx] };
    questions[questionIdx] = { ...temporalVariable };
    onQuestionsChange(questions);
  };

  const handleMoveDownQuestion = (questionIdx: number) => {
    const questions = [...checkpoint.questions];
    const temporalVariable = { ...questions[questionIdx + 1] };
    questions[questionIdx + 1] = { ...questions[questionIdx] };
    questions[questionIdx] = { ...temporalVariable };
    onQuestionsChange(questions);
  };

  const moveQuestionHandler = (dir: 'up' | 'down', idx: number) => {
    const { questions } = checkpoint;
    if (questions.length <= 1) return;
    if (dir === 'up' && idx > 0) handleMoveUpQuestion(idx);
    else if (dir === 'down' && idx < questions.length - 1) handleMoveDownQuestion(idx);
  };

  const removeQuestionHandler = (idx: number) => {
    onQuestionsChange(checkpoint.questions.filter((_, i) => i !== idx));
  };

  return (
    <>
      <div className="ssa-wizard__checkpoint-container">
        <Input
          className="ssa-wizard__checkpoint-input"
          style={{
            width: checkpoint.description.length > 18 ? checkpoint.description.length * (checkpoint.description.length / 5) : 'inherit'
          }}
          onChange={(_, { value }) => onDescriptionChange(value)}
          value={checkpoint.description}
        />
        <Button onClick={onRemoveCheckpoint} className="ssa-template-wizard__remove-btn" type="button">
          {t('Remove')}
        </Button>
        <div className="ssa-wizard__add-question-btn">
          <Button onClick={showQuestionsModal} className="ssa-wizard__add-questions-btn" type="button">
            {t('Add Questions')}
          </Button>
        </div>
        {(checkpoint.questions || []).map((question, i) => (
          <SsaQuestionComponent
            key={i}
            move={dir => moveQuestionHandler(dir, i)}
            canGoUp={i > 0}
            canGoDown={i + 1 < checkpoint.questions.length}
            question={question}
            onRemoveQuestion={() => removeQuestionHandler(i)}
          />
        ))}
      </div>
      {showQuestions && (
        <ChooseSSAQuestionsView
          onCloseModal={hideQuestionsModal}
          ssaSkillId={skill.id}
          ssaSkillName={skill.name}
          professionId={profession}
          category={category}
          onAddSSAQuestions={addQuestionsHandler}
          alreadySelectedQuestions={alreadySelectedQuestions}
        />
      )}
    </>
  );
};

export default withTranslation()(SsaCheckpointComponent);
