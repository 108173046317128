declare global {
  interface IEnumerable<TSource> {
    any(predicate?: (x: TSource, indx: number) => boolean): boolean;
    all(predicate?: (x: TSource, indx: number) => boolean): boolean;
    sum(mapper: (x: TSource) => number): number;
    first(predicate?: (x: TSource) => boolean): TSource;
    firstOrDefault(predicate?: (x: TSource) => boolean): TSource | null;
    orderBy<TKey>(predicate: (x: TSource) => TKey): IEnumerable<TSource>;
    orderByDescending<TKey>(predicate: (x: TSource) => TKey): IEnumerable<TSource>;
    groupBy<TKey>(
      keySelector: (x: TSource) => TKey,
      groupSorter?: (g1: IGroup<TKey, TSource>, g2: IGroup<TKey, TSource>) => number
    ): IEnumerable<IGroup<TKey, TSource>>;
    toArray<TSource>(): TSource[];
    distinct<TKey>(keySelector: (x: TSource) => TKey): IEnumerable<TSource>;
    selectMany<TDestination>(select: (x: TSource) => IEnumerable<TDestination>): IEnumerable<TDestination>;
    isEmpty(): boolean;
  }
  interface Array<T> extends IEnumerable<T> {}
  interface Uint8Array extends IEnumerable<number> {}
  interface Uint8ClampedArray extends IEnumerable<number> {}
  interface Uint16Array extends IEnumerable<number> {}
  interface Uint32Array extends IEnumerable<number> {}
  interface Int8Array extends IEnumerable<number> {}
  interface Int16Array extends IEnumerable<number> {}
  interface Int32Array extends IEnumerable<number> {}
  interface Float32Array extends IEnumerable<number> {}
  interface Float64Array extends IEnumerable<number> {}
  interface Map<K, V> extends IEnumerable<[K, V]> {}
  interface Set<T> extends IEnumerable<T> {}
  interface String extends IEnumerable<string> {}
}

function isEmpty(this: any): boolean {
  if (this == null) return true;
  const self = this.toArray();
  return self.length === 0;
}

function any<TSource>(this: any, predicate?: (x: TSource, indx: number) => boolean): boolean {
  return this.filter((o: any, i: number) => predicate(o, i)).length > 0;
}

function all<TSource>(this: any, predicate: (x: TSource, indx: number) => boolean): boolean {
  return this.filter((o: any, i: number) => !predicate(o, i)).length === 0;
}

function selectMany<TSource, TDestination>(this: any, select: (x: TSource) => IEnumerable<TDestination>): IEnumerable<TDestination> {
  let result: TDestination[] = [];
  let self = this;
  if (this instanceof Set || this instanceof Map) {
    self = this.toArray();
  }

  if (self != null && self.length !== 0) {
    for (let i = 0; i < self.length; i++) result = result.concat(select(self[i]).toArray());
  }
  return result;
}

function sum<TSource>(this: any, mapper: (x: TSource) => number): number {
  let total = 0;
  let self = this;
  if (this instanceof Set || this instanceof Map) {
    self = this.toArray();
  }
  let idx = 0;
  while (idx < self.length) {
    total += mapper(self[idx]);
    idx++;
  }
  return total;
}

function firstOrDefault<TSource>(predicate?: (x: TSource) => boolean): TSource | null {
  let self = this;
  if (this instanceof Set || this instanceof Map) {
    self = this.toArray();
  }
  if (predicate) {
    let idx = 0;
    while (idx < self.length && !predicate(self[idx])) {
      idx++;
    }
    return idx < self.length ? self[idx] : null;
  }
  return self.length > 0 ? self[0] : null;
}

function first<TSource>(predicate?: (x: TSource) => boolean): TSource {
  const item = this.firstOrDefault(predicate);
  if (!item) {
    throw new Error('Item not found in collection');
  }
  return item;
}

function orderBy<TSource, TKey>(predicate: (x: TSource) => TKey): IEnumerable<TSource> {
  let self = this;
  if (this instanceof Set || this instanceof Map || this instanceof String || typeof this === 'string') {
    self = this.toArray();
  }

  return self.sort((n1, n2) => {
    if (predicate(n1) > predicate(n2)) {
      return 1;
    }
    if (predicate(n1) < predicate(n2)) {
      return -1;
    }
    return 0;
  });
}

function orderByDescending<TSource, TKey>(predicate: (x: TSource) => TKey): IEnumerable<TSource> {
  let self = this;
  if (this instanceof Set || this instanceof Map || this instanceof String || typeof this === 'string') {
    self = this.toArray();
  }

  return self.sort((n1, n2) => {
    if (predicate(n1) < predicate(n2)) {
      return 1;
    }
    if (predicate(n1) > predicate(n2)) {
      return -1;
    }
    return 0;
  });
}

export interface IGroup<TKey, TSource> {
  key: TKey;
  items: TSource[];
}

function groupBy<TSource, TKey>(
  keySelector: (x: TSource) => TKey,
  groupSorter?: (g1: IGroup<TKey, TSource>, g2: IGroup<TKey, TSource>) => number
): IEnumerable<IGroup<TKey, TSource>> {
  let self = this;
  if (this instanceof Set || this instanceof Map || this instanceof String || typeof this === 'string') {
    self = this.toArray();
  }

  const g = self.reduce((groups, item: TSource) => {
    const key = keySelector(item);
    groups[key] = groups[key] || ({ key, items: [] } as IGroup<TKey, TSource>);
    groups[key].items.push(item);
    return groups;
  }, {});
  const result = [] as IGroup<TKey, TSource>[];
  for (const key in g) {
    if (g.hasOwnProperty(key)) {
      result.push(g[key]);
    }
  }

  if (groupSorter) {
    return result.sort(groupSorter);
  }

  return result;
}

function toArray<TSource>(): TSource[] {
  if (this == null) return null;
  if (this instanceof String || typeof this === 'string') {
    return this.split('');
  }
  if (this instanceof Set || this instanceof Map) {
    const arr = [];
    this.forEach(e => {
      arr.push(e);
    });
    return arr;
  }
  return [...this];
}

function distinct<TSource, TKey>(keySelector: (x: TSource) => TKey): IEnumerable<TSource> {
  const set = new Set<TKey>();
  const result: TSource[] = [];

  this.forEach((e: TSource) => {
    const key = keySelector(e);
    if (!set.has(key)) {
      set.add(key);
      result.push(e);
    }
  });

  return result;
}

function bindLinqFunctions(enumerable) {
  enumerable.prototype['toArray'] = toArray;
  enumerable.prototype['firstOrDefault'] = firstOrDefault;
  enumerable.prototype['first'] = first;
  enumerable.prototype['orderBy'] = orderBy;
  enumerable.prototype['orderByDescending'] = orderByDescending;
  enumerable.prototype['groupBy'] = groupBy;
  enumerable.prototype['distinct'] = distinct;
  enumerable.prototype['any'] = any;
  enumerable.prototype['all'] = all;
  enumerable.prototype['sum'] = sum;
  enumerable.prototype['selectMany'] = selectMany;
  enumerable.prototype['isEmpty'] = isEmpty;
}

bindLinqFunctions(String);
bindLinqFunctions(Array);
bindLinqFunctions(Map);
bindLinqFunctions(Set);
bindLinqFunctions(Int8Array);
bindLinqFunctions(Int16Array);
bindLinqFunctions(Int32Array);
bindLinqFunctions(Uint8Array);
bindLinqFunctions(Uint8ClampedArray);
bindLinqFunctions(Uint16Array);
bindLinqFunctions(Uint32Array);
bindLinqFunctions(Float32Array);
bindLinqFunctions(Float64Array);

export {};
