import React, { FC } from 'react';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface QuestionOptionProps extends WithTranslation {
  className?: string;
  title: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

const TheoreticalQuestionOption: FC<QuestionOptionProps> = ({ t, className, title, value, onChange }) => {
  let classname = 'theoretical-question-option';
  if (className) classname += ` ${className}`;
  return (
    <div className={classname}>
      <label>{t(title)}</label>
      <Input value={value || ''} onChange={onChange} />
    </div>
  );
};
export default withTranslation()(TheoreticalQuestionOption);
