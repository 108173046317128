import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { OrderDefinition, QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import {
  NMFunctionalAreaDto,
  NMFunctionalAreasStore
} from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';

interface MultipleFunctionalAreaEditorProps extends WithTranslation {
  value: NMFunctionalAreaDto[];
  disabled?: boolean;
  onChange?: (value: NMFunctionalAreaDto[]) => void;
  functionalAreaStore?: NMFunctionalAreasStore;
  placeholder?: string;
  clearable?: boolean;
  clusterId?: string;
}

interface MultipleFunctionalAreaEditorState {
  options: MultipleSelectionDropdownOptions<NMFunctionalAreaDto>[];
}

@connect(['functionalAreaStore', NMFunctionalAreasStore])
class MultipleFunctionalAreaEditor extends React.Component<MultipleFunctionalAreaEditorProps, MultipleFunctionalAreaEditorState> {
  state: MultipleFunctionalAreaEditorState = { options: [] };

  componentDidMount() {}

  componentDidUpdate(prevProps: MultipleFunctionalAreaEditorProps) {
    const { clusterId } = this.props;
    if (clusterId !== prevProps.clusterId) this.getOptions();
  }

  getOptions = async () => {
    const { clusterId, functionalAreaStore } = this.props;
    const filter = [];
    if (clusterId) filter.push(`${nameof<NMFunctionalAreaDto>('clusterId')} eq ${clusterId}`);
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<NMFunctionalAreaDto>('name'), useProfile: false }];

    const fa = await functionalAreaStore.getAllAsync({ searchQuery: '', skip: 0, take: 100000, orderBy, filter });
    this.setState({ options: this.mapToOptions(fa) });
  };

  mapToOptions = (functionalArea: QueryResult<NMFunctionalAreaDto>): MultipleSelectionDropdownOptions<NMFunctionalAreaDto>[] =>
    functionalArea.items.map(trainingLevel => ({ id: trainingLevel.id, value: trainingLevel, text: trainingLevel.name }));

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.getOptions();
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable={this.props.clearable}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        onOpenDropDown={this.onOpenDropDown}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleFunctionalAreaEditor);
