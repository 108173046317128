import React, { Component, Fragment } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Grid, Icon, Input, Label } from 'semantic-ui-react';
import { RequestWizardStore, RequestWizardData } from 'stores/requests/create-request-wizard-store';
import { DateInput } from 'widgets/form/dateInput';
import { DateTimeService } from 'services/datetime-service';
import './step4-dates.less';
import { DurationInput } from 'widgets/form/durationInput';
import { DateWarningType } from 'stores/events/creation-wizard-store';

interface NewRequestViewProps extends WithTranslation {
  requestWizardStore?: RequestWizardStore;
  durationWarning: boolean;
}
interface NewRequestViewState {
  openDatePopup: boolean;
}

@connect(['requestWizardStore', RequestWizardStore])
class StepDates extends Component<NewRequestViewProps, NewRequestViewState> {
  private get requestWizardStore() {
    return this.props.requestWizardStore;
  }

  constructor(props) {
    super(props);
    this.state = { openDatePopup: false };
  }

  private handleValue = (property: string, value: any) => {
    const { startDate, endDate } = this.props.requestWizardStore.state.item;
    const change: Partial<RequestWizardData> = {};
    change[property] = value;
    if (property === 'startDate' || property === 'endDate') {
      const current = DateTimeService.isCoherent(value) ? DateTimeService.toMoment(value) : null;
      const end = DateTimeService.isCoherent(endDate) ? DateTimeService.toMoment(endDate) : null;
      const start = DateTimeService.isCoherent(startDate) ? DateTimeService.toMoment(startDate) : null;
      if (property === 'startDate') {
        if (current && end && current.isAfter(end, 'days')) change.endDate = current ? (current as any) : null;
        this.setState(({ openDatePopup }) => ({ openDatePopup: !openDatePopup }));
      } else {
        if (start && current && start.isAfter(current, 'days')) change.endDate = start ? (start as any) : null;
      }
    }
    this.requestWizardStore.change(change);
  };

  render() {
    const { t, requestWizardStore, durationWarning } = this.props;
    const warnings: DateWarningType[] = [
      ...(durationWarning
        ? ([{ type: 'DURATION', text: this.props.t('There is no default calculated duration for this event') }] as DateWarningType[])
        : [])
    ];
    const { isBusy, item } = requestWizardStore.state;
    const { startDate, endDate, desiredEventDuration } = item;
    return (
      <>
        <Form className="wizard__form__margin wizard__step-dates-wrapper" loading={isBusy}>
          <Grid columns={4}>
            <Grid.Row className="padding-bottom-20px">
              <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                <div className={`required field inline`}>
                  <label>{t('Calculated Duration')}</label>
                </div>
              </Grid.Column>
              <Grid.Column className="normal-looking-disabled-input relative-recalc-command-container" width={4}>
                <Input
                  readOnly
                  className="wizard__numeric-input"
                  id="events__step3__event-duration__input"
                  value={`${item?.calculatedEventDuration || ' - '} w/d`}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="second-row">
              <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                <div className={`required field inline`}>
                  <label>{t('Desired Date Slot')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="period-input">
                  <DateInput
                    iconPosition="right"
                    className="date-picker__left"
                    value={startDate == null ? '' : DateTimeService.toString(startDate)}
                    onChange={(_, value) => this.handleValue('startDate', value)}
                  />
                  <DateInput
                    id={'request-step-4-endDate'}
                    iconPosition="right"
                    className="date-picker__right"
                    forceOpen={this.state.openDatePopup}
                    value={endDate == null ? '' : DateTimeService.toString(endDate)}
                    onChange={(_, value) => this.handleValue('endDate', value)}
                    initialValue={startDate ? DateTimeService.toString(startDate) : null}
                    minDate={startDate ? DateTimeService.toString(startDate) : null}
                  />
                </div>
              </Grid.Column>
              <Grid.Column textAlign="right" verticalAlign="middle" width={2}>
                <div className={`required field inline`}>
                  <label>{t('Desired Event Duration')}</label>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Input className="wizard__numeric-input" type="number" required>
                  <DurationInput onChange={value => this.handleValue('desiredEventDuration', value)} value={desiredEventDuration} />
                </Input>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {(warnings || []).length !== 0 && (
          <Label className="modal__action-warning wizard__action-warning">
            <Icon name="exclamation triangle" />
            <div>
              {(warnings || []).map(({ text }, index) => (
                <Fragment key={'date-warning-' + index}>{text != null && <p className="modal__action-warning__text"> {text} </p>}</Fragment>
              ))}
            </div>
          </Label>
        )}
      </>
    );
  }
}

export default withTranslation()(StepDates);
