import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { RouteComponentProps } from 'react-router';
import {
  MRTheoreticalQuestionBanksStore as MRQuestionBankStore,
  TheoreticalQuestionMRItemDto,
  MRTheoreticalTestQBWizardStore
} from 'stores/assessments/questionBank/theoretical-test-store';
import { Query, ItemState, OrderDefinition, ItemReference } from 'stores/dataStore';
import { nameof, getProperties, clone } from 'utils/object';
import { CommandResult, FileInfo, ImageInfo } from 'stores/types';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { TableModel, TableView } from 'widgets/collections/table';
import { Message, Dropdown, Icon, Button, Modal, Popup, Menu, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import withDelayedInput from 'widgets/editors/delay-input';
import ChipInfo from 'widgets/bussiness/chip-info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileExcel, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import multiLanguageEditor from 'widgets/bussiness/multiple-selector/multi-language-editor';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import { UploadMRTheoreticalQBStore, DownloadMRTheoreticalQBStore } from 'stores/assessments/questionBank/mr-theoretical-test-load-store';
import QuestionManager, { ModalMode } from '../shared/theoretical-question-manager';
import { plainObj, plainMR } from '../shared/util';
import { DropdownLanguagesStore, LanguageDto } from 'stores/configuration/locations/languages-store';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import '../theoretical-lists.less';

const DelayedMultiLanguageEditor = withDelayedInput(multiLanguageEditor, 1500, true);

interface MRTheoreticalQBListProps extends WithTranslation, RouteComponentProps {
  mrQuestionBankStore?: MRQuestionBankStore;
  uploadStore?: UploadMRTheoreticalQBStore;
  downloadStore?: DownloadMRTheoreticalQBStore;
  wizardStore?: MRTheoreticalTestQBWizardStore;
  languagesStore?: DropdownLanguagesStore;
}

interface MRTheoreticalQBListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: TheoreticalQuestionMRItemDto;
  languagesIdentifiers: string[];
  languageIdentifier: string;
  showDeleteConfirm: boolean;
  questionManager: boolean;
  modalLoading: boolean;
  modalMode: ModalMode;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  showDownloadMsg: boolean;
  uploadLoadingMessage: '';
  defaultLanguage: LanguageDto;
  deleteModalText: string;
  someFilterOpened: boolean;
}

@connect(
  ['mrQuestionBankStore', MRQuestionBankStore],
  ['uploadStore', UploadMRTheoreticalQBStore],
  ['wizardStore', MRTheoreticalTestQBWizardStore],
  ['downloadStore', DownloadMRTheoreticalQBStore],
  ['languagesStore', DropdownLanguagesStore]
)
class MRTheoreticalQBList extends Component<MRTheoreticalQBListProps, MRTheoreticalQBListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: MRTheoreticalQBListState = {
    query: {
      searchQuery: '',
      orderBy: [
        { direction: 'Descending', field: nameof<TheoreticalQuestionMRItemDto>('friendlyId'), useProfile: false },
        { direction: 'Descending', field: 'modifiedOn', useProfile: false }
      ],
      skip: 0,
      take: 10
    },
    activeFilters: [],
    selectedItem: null,
    languagesIdentifiers: [],
    languageIdentifier: '',
    showDeleteConfirm: false,
    questionManager: false,
    modalLoading: false,
    modalMode: null,
    uploadMenuOpen: false,
    showUploadMsg: false,
    showDownloadMsg: false,
    uploadLoadingMessage: '',
    defaultLanguage: null,
    deleteModalText: '',
    someFilterOpened: false
  };

  handleOnEnterKeydown = item => {
    this.onEditItem(item);
  };

  handleOnRowDoubleClick = item => {
    this.onEditItem(item);
  };

  componentDidMount() {
    this.load();
    this.loadDefaulLanguage();
  }

  private loadDefaulLanguage() {
    let languageQuery: Query = {
      searchQuery: '',
      orderBy: [],
      skip: 0,
      take: 1000,
      filter: [{ or: [{ LanguageCode: 'EN-en' }, { Language: 'English' }] }]
    };
    this.props.languagesStore.getAllAsync(languageQuery).then(e => {
      this.setState({ defaultLanguage: e.items[0] });
    });
  }

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.mrQuestionBankStore.getAllAsync(this.state.query));
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      ({ query }) => ({
        query: { ...query, orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }] }
      }),
      this.load
    );
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private onSaveRow = async (item: any, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') {
      await this.props.mrQuestionBankStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async () => {
    if (!this.state.selectedItem) return;
    await this.props.mrQuestionBankStore.deleteAsync(this.state.selectedItem.id);
    this.hideConfirmModal();
  };

  private showDeleteModal = async (selectedItem: TheoreticalQuestionMRItemDto) => {
    const count = await this.props.mrQuestionBankStore.getUsedMRTheoreticalQuestionCountById(selectedItem.id);
    const deleteModalText =
      count > 0
        ? `This question is used in ${count} template${count === 1 ? '' : 's'} and the template${
            count === 1 ? '' : 's'
          } will become inactive.\r\n Are you sure that you want to delete the question?`
        : 'Are you sure that you want to delete the question?';

    this.setState({ selectedItem, deleteModalText, showDeleteConfirm: true });
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirm: false, selectedItem: null });
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    this.setState(({ query }) => ({ query: { ...query, filter, skip: 0 }, activeFilters }), this.load);
  };

  private onNewItem = () => {
    this.props.wizardStore.change({ ...this.state.selectedItem });
    this.setState({ questionManager: true, modalMode: 'New' });
  };

  private onCloneItem = (selectedItem: TheoreticalQuestionMRItemDto) => {
    this.props.wizardStore.change({ ...selectedItem });
    this.setState({ selectedItem, modalMode: 'Clone', questionManager: true });
  };

  private onEditItem = (selectedItem: TheoreticalQuestionMRItemDto) => {
    this.props.wizardStore.change({ ...selectedItem });

    this.setState({ selectedItem, modalMode: 'Edit', questionManager: true });
  };

  private onFilterQuestionOrId = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['IdDescription'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QMRTh');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QMRTh')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }

    this.setState({ activeFilters }, this.load);
  };

  private handleAnswerTypeFilter = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value) delete activeFilters['AnswerTypes'];
    else activeFilters['AnswerTypes'] = { AnswerType: { eq: value } };

    this.setState({ activeFilters }, this.load);
  };

  private multiChangeLanguagesFilters = (languagesIds: string[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (languagesIds != null && languagesIds.length > 0) {
      activeFilters['languages'] = {
        or: [
          { QuestionTranslations: { any: { LanguageId: { in: { type: 'guid', value: languagesIds } } } } },
          { Question: { LanguageId: { in: { type: 'guid', value: languagesIds } } } }
        ]
      };
    } else delete activeFilters['languages'];
    this.setState({ activeFilters }, () => this.load());
  };

  private rolesFilterHandlerHandler = (roles: string[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (roles != null && roles.length > 0)
      activeFilters['roles'] = { professions: { any: { id: { in: { type: 'guid', value: roles } } } } };
    else delete activeFilters['roles'];
    this.setState({ activeFilters }, () => this.load());
  };

  private onClusterEditorChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value?.id) {
      delete activeFilters.cluster;
      delete activeFilters.equipmentType;
      delete activeFilters.oem;
      delete activeFilters.machineModel;
    } else activeFilters.cluster = { clusterId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private onEquipmentTypeChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value?.id) {
      delete activeFilters.equipmentType;
      delete activeFilters.oem;
      delete activeFilters.machineModel;
    } else activeFilters.equipmentType = { equipmentTypeId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private onOemChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value?.id) {
      delete activeFilters.oem;
      delete activeFilters.machineModel;
    } else activeFilters.oem = { oemId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private onMachineModelChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value?.id) delete activeFilters.machineModel;
    else activeFilters.machineModel = { machineModelId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private onTrainingLevelFilterChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value?.id) delete activeFilters.trainingLevel;
    else activeFilters.trainingLevel = { trainingLevelId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private getAnswerTypeTitle = (answerType: string) => {
    if (answerType === 'YesOrNo') return 'Yes/No';
    return answerType;
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.uploadStore
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  uploadMediaHandler = async (content: File): Promise<ImageInfo> => {
    const { userId, userName } = this.identityService.getUserInfo();
    const { uploadStore, wizardStore } = this.props;
    if (!content) return;
    const fileInfo: FileInfo = { fileName: content.name, fileSize: content.size, content, userId, userName };
    let imageInfo: ImageInfo = null;
    try {
      const result = await uploadStore.uploadEventDocument(fileInfo);
      if (result.isSuccess) {
        imageInfo = {
          mimeType: null,
          path: result.filePath,
          size: null,
          title: content.name,
          url: result.filePath
        };
        wizardStore.change({ imageInfo });
      }
      (document.getElementById('add-theoretical-image') as HTMLInputElement).value = '';
    } catch (error) {
      console.error({ error });
      (document.getElementById('add-theoretical-image') as HTMLInputElement).value = '';
    }
    return imageInfo;
  };

  downloadMedia = async () => {
    const { uploadStore, wizardStore } = this.props;
    this.setState({ modalLoading: true });
    if (wizardStore.state.item?.imageInfo?.path) await uploadStore.downloadMedia(wizardStore.state.item.imageInfo);
    this.setState({ modalLoading: false });
  };

  private handleUploadMsgDismiss = () => {
    this.setState({ showUploadMsg: false });
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  private getBackgroundUploadMessage = () => {
    const { isBusy, result } = this.props.uploadStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    let error = false;
    if (result.error || (result.response && !result.response.isSuccessful)) {
      header = t('Something went wrong with the file');
      content = (result.error || {}).message;
      error = true;
    } else if (result.error == null && result.response && result.response.isSuccessful) {
      header = t('File uploaded successfully');
      content = t('You will receive an email when the process ends');
      error = false;
    } else return null;

    return (
      <Message
        className={error && 'error-message__style'}
        icon={error && 'exclamation circle'}
        onDismiss={this.handleUploadMsgDismiss}
        header={header}
        content={content}
        error={error}
        success={!error}
      />
    );
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = (result.messages || []).map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };
  private initExportQuestionBankProcess = () => {
    this.setState({ showDownloadMsg: true });
    this.props.downloadStore.initBackgroundDownload();
  };

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.uploadStore
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  private toggleQuestionManager = () => {
    this.props.wizardStore.clearMessages();
    this.props.uploadStore.clearMsg();
    this.setState(({ questionManager }) => ({ selectedItem: null, questionManager: !questionManager }));
  };

  handleModalSubmit = async (newItem: TheoreticalQuestionMRItemDto) => {
    this.setState({ modalLoading: true });
    if (!newItem) return;
    const { wizardStore } = this.props;
    wizardStore.createNew(clone(newItem));
    const finalize: any = { selectedItem: null, questionManager: false, modalLoading: false, modalMode: null };
    const { modalMode } = this.state;
    try {
      if (modalMode === 'New' || modalMode === 'Clone') await wizardStore.submitQuestion();
      else if (modalMode === 'Edit') await wizardStore.submitQuestion(true);
      wizardStore.clear();
      this.load();
    } catch (error) {
      console.error({ error });
      delete finalize.questionManager;
      delete finalize.selectedItem;
      delete finalize.modalMode;
    }

    this.setState(finalize);
  };

  onDissmissModal = () => {
    const { wizardStore, uploadStore } = this.props;
    uploadStore.clearMsg();
    wizardStore.clearMessages();
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { activeFilters, deleteModalText, selectedItem, showDeleteConfirm, showDownloadMsg, someFilterOpened } = this.state;
    const { questionManager, modalMode, modalLoading, showUploadMsg } = this.state;
    const { t, mrQuestionBankStore, wizardStore, uploadStore, downloadStore } = this.props;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: ({ friendlyId }) => friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<any>('friendlyId'),
            useProfile: false,
            active: 'Descending'
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: ({ question }) => question && <div>{question?.text}</div>,
          selectableHeader: true
        },
        {
          title: t('Roles'),
          tooltipRenderer: false,
          renderer: ({ professions }) => {
            const allProfessions = (professions || []).map(
              profItem =>
                profItem && (
                  <span key={profItem?.id} className="question-bank__cell__tag">
                    {profItem?.profession}
                  </span>
                )
            );
            return <div className={professions && professions.length > 1 ? 'table-cell__more-width' : ''}>{allProfessions}</div>;
          }
        },
        {
          title: t('Category'),
          tooltipRenderer: false,
          renderer: ({ testCategory }) => testCategory && <span className={'question-bank__cell__tag'}>{testCategory?.name}</span>
        },
        {
          title: t('Machinery'),
          tooltipRenderer: false,
          renderer: ({ cluster, equipmentType, oem, machineUnit, machineModel, plcType }) => {
            const info = [];
            cluster && info.push(cluster?.name);
            equipmentType && info.push(equipmentType?.name);
            oem && info.push(oem?.name);
            machineModel && info.push(machineModel?.name);
            machineUnit && info.push(machineUnit?.name);
            plcType && info.push(plcType?.name);
            return info.length > 0 && <ChipInfo info={info} />;
          }
        },
        {
          title: t('Content'),
          tooltipRenderer: false,
          renderer: ({ mediaContentType }) => {
            if (mediaContentType === 'Video') return <Icon size="big" name="video" />;
            if (mediaContentType === 'Image') return <Icon size="big" name="image" />;
            return null;
          }
        },
        {
          title: t('Answer Type'),
          tooltipRenderer: false,
          renderer: ({ answerType }) => <div>{this.getAnswerTypeTitle(answerType)}</div>
        },
        {
          title: t('Training Level'),
          tooltipRenderer: false,
          renderer: ({ trainingLevel }) => trainingLevel?.name
        },
        {
          title: t('Language'),
          tooltipRenderer: false,
          renderer: ({ questionTranslations, question }) => {
            const languagesList = [question.language];
            questionTranslations.forEach(questionTranslation => languagesList.push(questionTranslation.language));
            const allLanguages = (languagesList || []).map(
              (langItem, i) =>
                langItem && (
                  <span key={langItem?.id + i} className={'question-bank__cell__tag'}>
                    {langItem?.languageCode}
                  </span>
                )
            );
            return (
              <div className={allLanguages && allLanguages.length > 1 ? 'table-cell__more-width question-bank__language__div' : ''}>
                {allLanguages}
              </div>
            );
          }
        }
      ],
      data: mrQuestionBankStore.state
    } as TableModel<TheoreticalQuestionMRItemDto>;

    const mgs = [];
    const errMsg = [];
    if (mrQuestionBankStore.state.result && (mrQuestionBankStore.state.result.messages || []).length > 0)
      mgs.push(...mrQuestionBankStore.state.result.messages.map(({ body }) => body));

    if (wizardStore.state.result && (wizardStore.state.result.messages || []).length > 0) {
      mgs.push(...wizardStore.state.result.messages.map(({ body }) => body));
      errMsg.push(...wizardStore.state.result.messages.map(({ body }) => body));
    }
    if ((uploadStore as any).state.result?.messages?.length > 0)
      errMsg.push(...(uploadStore as any).state.result.messages.map(({ body }) => body));

    return (
      <div id="mr-theoretical-question-bank-wrapper" className="question-bank__wrapper">
        {uploadStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{this.state.uploadLoadingMessage}</Loader>
          </Dimmer>
        )}
        {showUploadMsg && this.getBackgroundUploadMessage()}

        {downloadStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing download background process')}</Loader>
          </Dimmer>
        )}
        {showDownloadMsg && this.getBackgroundDownloadMessage()}

        <Grid className="event-types-list-grid">
          {mgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                hidden={!mgs}
                error
                header={t('An error ocurred')}
                list={mgs}
                onDismiss={() => {
                  mrQuestionBankStore.clearMessages();
                  wizardStore.clearMessages();
                }}
              />
            </Grid.Row>
          )}
          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              id="mr-theoretical-questions-bank-list__filters__search"
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={this.onFilterQuestionOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <MultiProfessionEditor
              placeholder={t('Roles')}
              value={[]}
              onChange={this.rolesFilterHandlerHandler}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <Dropdown
              id="mr-theoretical-questions-bank-list__filters__answerType"
              className="planit-users-inputs planit-user-dropdown planit-user-selector"
              search
              inline
              selection
              closeOnChange
              closeOnEscape
              clearable={true}
              closeOnBlur={true}
              selectOnBlur={false}
              selectOnNavigation={false}
              placeholder={t('Answer Types')}
              options={[
                { text: 'Yes/No', value: 'YesOrNo' },
                { text: 'Rating', value: 'Rating' }
              ]}
              onChange={this.handleAnswerTypeFilter}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <DelayedMultiLanguageEditor
              id="mr-theoretical-questions-bank-list__filters__langs"
              value={this.state.languagesIdentifiers}
              onChange={this.multiChangeLanguagesFilters}
              placeholder={t('Languages')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MRClusterEditor
              clearable={true}
              nullable={true}
              value={activeFilters.cluster?.clusterId?.eq?.value}
              onChange={this.onClusterEditorChange}
              placeholder={t('Cluster')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <EquipmentTypeEditor
              nullable={true}
              clearable={true}
              clusterId={activeFilters.cluster?.clusterId?.eq?.value || null}
              value={activeFilters.equipmentType?.equipmentTypeId?.eq?.value}
              onChange={this.onEquipmentTypeChange}
              placeholder={t('Equipment Type')}
              readOnly={!activeFilters.cluster}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <OemEditor
              nullable={true}
              clearable={true}
              value={activeFilters.oem?.oemId?.eq?.value}
              onChange={this.onOemChange}
              placeholder={t('OEM')}
              readonly={!activeFilters.cluster || !activeFilters.equipmentType}
              equipmentId={activeFilters.equipmentType?.equipmentTypeId?.eq?.value || null}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MachineModelEditor
              cascade="true"
              nullable={true}
              clearable={true}
              oemId={activeFilters.oem?.oemId?.eq?.value || null}
              equipmentId={activeFilters.equipmentType?.equipmentTypeId?.eq?.value || null}
              value={activeFilters.machineModel?.machineModelId?.eq?.value}
              onChange={this.onMachineModelChange}
              placeholder={t('Machine Model')}
              readonly={!activeFilters.cluster || !activeFilters.oem || !activeFilters.equipmentType}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <TrainingLevelEditor
              value={null}
              onChange={this.onTrainingLevelFilterChange}
              placeholder={t('Training Level')}
              searchable
              clearable
              nullable
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!showDeleteConfirm && !questionManager}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Popup
                      on="click"
                      position="bottom center"
                      open={this.state.uploadMenuOpen}
                      onOpen={() => this.setState({ uploadMenuOpen: true })}
                      onClose={() => this.setState({ uploadMenuOpen: false })}
                      trigger={
                        <Button
                          icon
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={() => this.setState({ uploadMenuOpen: true })}
                          data-tooltip={t('Import')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                        </Button>
                      }
                    >
                      <Menu vertical secondary>
                        <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                          <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                          <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                          &nbsp;{t('Upload File')}
                        </Menu.Item>

                        <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                          <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                          &nbsp;{t('Download Template')}
                        </Menu.Item>
                      </Menu>
                    </Popup>
                  )
                },
                {
                  className: 'menu-item-download',
                  content: (
                    <Button
                      icon
                      size="medium"
                      onClick={this.initExportQuestionBankProcess}
                      className="custom-table-upload-btn black-btn"
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit')}</span>}
                    </>
                  ),
                  onClick: this.onEditItem
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      <span className="text__bold">{t('Clone')}</span>
                    </>
                  ),
                  onClick: this.onCloneItem
                },
                {
                  content: (
                    <>
                      <Icon color="red" name="trash alternate" />
                      {<span className="text__red text__bold">{t('Delete')}</span>}
                    </>
                  ),
                  onClick: this.showDeleteModal
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              canCreateNew={true}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              createNewButtonTitle={t('New MR Theoretical Question')}
            ></TableView>
          </Grid.Row>
        </Grid>
        <Modal open={showDeleteConfirm} size="mini" className="have-warnings-popup" closeOnEscape={true} onClose={this.hideConfirmModal}>
          <Modal.Content className="have-warnings-popup__content">
            <p>{t(deleteModalText)}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
            <Button className="have-warnings-popup__btn have-warnings-popup__pending-btn" content={t('Yes')} onClick={this.onDelete} />
          </div>
        </Modal>
        {questionManager && modalMode && (
          <QuestionManager
            onDownloadHandler={this.downloadMedia}
            uploadFile={this.uploadMediaHandler}
            errMsg={errMsg}
            onDismiss={this.onDissmissModal}
            loading={modalLoading}
            type="MR"
            mode={modalMode}
            onClose={this.toggleQuestionManager}
            data={selectedItem ?? { ...plainObj, question: { ...plainObj.question, language: this.state.defaultLanguage }, ...plainMR }}
            onSubmit={this.handleModalSubmit}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(MRTheoreticalQBList);
