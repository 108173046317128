import { BaseDto } from 'stores/types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from 'i18n';
import { DataStore } from 'stores/dataStore';

export interface ContractDto extends BaseDto {
  id: string;
  name: string;
}

export class ContractValidator extends AbstractValidator<ContractDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Name is required'));
  }
}

@repository('@@CONTRACTS', 'contracts.summary')
export class ContractStore extends DataStore<ContractDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-contracts';
  updatePath = '';
  deletePath = '';

  protected validate(item: ContractDto) {
    return new ContractValidator().validate(item);
  }

  constructor() {
    super('CONTRACT', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
