import * as React from 'react';
import * as autobind from 'autobind';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { RoleDto, RolesStore } from '../../stores/roles/roles-store';
import { nameof } from '../../utils/object';
import { Dropdown, Checkbox, Form, Loader, Dimmer } from 'semantic-ui-react';
import { LocationsStore, LocationDto } from 'stores/configuration/locations/locations-store';
import { ChangeUserDto, CreateRoleInLocationDto } from 'stores/users/users-store';
import '../../assets/less/roles-editor.less';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { RegionDto, RegionsStore } from 'stores/configuration/locations/regions-store';
import { arrayValuesCompare } from 'utils/useful-functions';
import { GetRoleName } from 'utils/userinfo-functions';

export interface RoleLocationsOrRegionsSelection {
  selected: boolean;
  role: RoleDto;
  getLocationsOrRegions(): any[];
  setLocationsOrRegions(locationsOrRegions: any[]);
}

export type UserActionMode = 'New' | 'Edit';

class RoleWithoutLocationSelection implements RoleLocationsOrRegionsSelection {
  selected: boolean;
  role: RoleDto;

  getLocationsOrRegions(): LocationDto[] {
    return [];
  }

  setLocationsOrRegions(locationsOrRegions: LocationDto[]) {}
}

class RoleWithSingleLocationsSelection implements RoleLocationsOrRegionsSelection {
  selected: boolean;
  role: RoleDto;
  location: LocationDto;

  getLocationsOrRegions(): LocationDto[] {
    return this.location ? [this.location] : [];
  }

  setLocationsOrRegions(locationsOrRegions: LocationDto[]) {
    if (!locationsOrRegions || locationsOrRegions.length <= 0) this.location = null;
    else this.location = locationsOrRegions[0];
  }
}

class RoleWithMultipleLocationsSelection implements RoleLocationsOrRegionsSelection {
  selected: boolean;
  role: RoleDto;
  locations: LocationDto[];

  getLocationsOrRegions(): LocationDto[] {
    return this.locations;
  }

  setLocationsOrRegions(locationsOrRegions: LocationDto[]) {
    this.locations = locationsOrRegions;
  }
}

class RoleWithMultipleRegionSelection implements RoleLocationsOrRegionsSelection {
  selected: boolean;
  role: RoleDto;
  regions: RegionDto[];

  getLocationsOrRegions(): RegionDto[] {
    return this.regions;
  }

  setLocationsOrRegions(locationsOrRegions: RegionDto[]) {
    this.regions = locationsOrRegions;
  }
}

interface RoleEditorProps extends WithTranslation {
  isSubmited?: boolean;
  value: CreateRoleInLocationDto[];
  onChange?: (value: RoleLocationsOrRegionsSelection[]) => void;
  roleStore?: RolesStore;
  locationStore?: LocationsStore;
  regionStore?: RegionsStore;
  isActiveFilter?: boolean;
  hideLabel?: boolean;
  userItem?: ChangeUserDto;
  userAction?: UserActionMode;
  onChangeGlobalPoc?: (value: boolean) => void;
}

interface RoleEditorState {
  value: CreateRoleInLocationDto[];
  selections: RoleLocationsOrRegionsSelection[];
  availableLocations: { [id: string]: LocationDto };
  locationOptions: { text: string; value: string }[];
  regionOptions: { text: string; value: string }[];
  availableRoles: { [id: string]: RoleDto };
  roleOptions: { text: string; value: string }[];
  availableRegions: { [id: string]: RegionDto };
  loading: boolean;
  isGlobalPoc: boolean;
}

@connect(['roleStore', RolesStore])
@connect(['locationStore', LocationsStore])
@connect(['regionStore', RegionsStore])
class RoleEditor extends React.Component<RoleEditorProps, RoleEditorState> {
  private get roleOrder(): string[] {
    return [
      'Employee',
      'Reporting',
      'Instructor',
      'Global Manufacturing',
      'PoC',
      'Regional Manufacturing VP',
      'Factory Lead',
      'Global Engineering',
      'Planner',
      'Admin',
      'Planner MTC'
    ];
  }

  private get singleLocationSelectionRoles(): string[] {
    return ['Employee', 'Instructor'];
  }

  private get multipleLocationSelectionRoles(): string[] {
    return ['PoC', 'Reporting', 'Factory Lead'];
  }

  private get multipleRegionSelectionRoles(): string[] {
    return ['Global Manufacturing', 'Regional Manufacturing VP'];
  }

  private get availableLocationSelectionRoles() {
    return this.singleLocationSelectionRoles.concat(this.multipleLocationSelectionRoles);
  }

  private get roleStore() {
    return this.props.roleStore;
  }

  private get locationStore() {
    return this.props.locationStore;
  }

  private get regionStore() {
    return this.props.regionStore;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: RoleEditorProps) {
    super(props);

    this.state = {
      value: this.props.value,
      selections: [],
      availableRoles: {},
      roleOptions: [],
      availableLocations: {},
      locationOptions: [],
      regionOptions: [],
      availableRegions: {},
      loading: true,
      isGlobalPoc: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const initRoles = this.initRoles();
    const initLocations = this.initLocations();
    const initRegions = this.initRegions();

    if (this.props.userItem) {
      this.setState({ isGlobalPoc: this.props.userItem.isGlobalPoc });
    }

    Promise.all([initRoles, initLocations, initRegions]).then(() => {
      if (!this.state.value) {
        this.setState({ loading: false });
        return;
      }

      let selections = [];
      this.state.roleOptions.forEach(x => {
        let role = this.state.availableRoles[x.value];
        selections[this.getRoleIndexOrder(x.text)] = this.createRoleSelection(role);
      });

      this.state.value.forEach(roleInLocation => {
        let selection = selections.firstOrDefault(x => x.role.name === roleInLocation.roleName);
        if (selection) {
          selection.selected = true;

          let locationDto = this.state.availableLocations[roleInLocation.locationId];
          let regionDto = this.state.availableRegions[roleInLocation.regionId];

          if (this.hasMultipleLocationSelection(selection.role.name)) {
            let selectionLocations = selection.getLocationsOrRegions();
            selectionLocations.push(locationDto);

            selection.setLocationsOrRegions(selectionLocations);
            return;
          }

          if (this.hasMultipleRegionSelection(selection.role.name)) {
            let selectionRegions = selection.getLocationsOrRegions();
            selectionRegions.push(regionDto);

            selection.setLocationsOrRegions(selectionRegions);
            return;
          }

          if (this.hasSingleLocationSelection(selection.role.name)) selection.setLocationsOrRegions([locationDto]);
        }
      });

      this.setState({
        selections: selections,
        loading: false
      });
    });
  }

  shouldComponentUpdate(nextProp, nextState) {
    if (
      this.props.isSubmited !== nextProp.isSubmited ||
      this.props.isActiveFilter !== nextProp.isActiveFilter ||
      this.props.hideLabel !== nextProp.hideLabel ||
      this.props.value.length !== nextProp.value.length ||
      !arrayValuesCompare(this.props.value, nextProp.value) ||
      this.state.selections.length !== nextState.selections.length ||
      !arrayValuesCompare(this.state.selections, nextState.selections) ||
      this.state.isGlobalPoc !== nextState.isGlobalPoc
    ) {
      return true;
    } else return false;
  }

  private async initLocations() {
    const filter = this.props.isActiveFilter ? ['active eq true'] : [];
    return await this.locationStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        filter: filter,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<LocationDto>('location'),
            useProfile: false
          }
        ]
      })
      .then(locations => {
        const dict = {};
        const options = [];

        if (locations.items.any(location => location.location === 'Global' || location.code === 'GLB')) {
          const location = locations.items.firstOrDefault(location => location.location === 'Global' && location.code === 'GLB');
          dict[location.id] = location;
          options.push({ text: location.location, value: location.id });
        }

        locations.items.forEach(location => {
          if (location.location !== 'Global' && location.code !== 'GLB') {
            dict[location.id] = location;
            options.push({ text: location.location, value: location.id });
          }
        });

        this.setState({
          availableLocations: dict,
          locationOptions: options
        });
      });
  }

  private async initRegions() {
    return await this.regionStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        filter: [],
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<RegionDto>('name'),
            useProfile: false
          }
        ]
      })
      .then(regions => {
        const dict = {};
        const options = [];

        regions.items.forEach(region => {
          dict[region.id] = region;
          options.push({ text: region.name, value: region.id });
        });

        this.setState({
          availableRegions: dict,
          regionOptions: options
        });
      });
  }

  private async initRoles() {
    return await this.roleStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<RoleDto>('name'),
            useProfile: false
          }
        ]
      })
      .then(roles => {
        let dict = {};
        let options = [];

        roles.items.forEach(role => {
          dict[role.id] = role;
          options.push({ text: role.name, value: role.id });
        });

        this.setState({
          availableRoles: dict,
          roleOptions: options
        });
      });
  }

  createRoleSelection(role: RoleDto): RoleLocationsOrRegionsSelection {
    let roleSelection;

    if (this.hasMultipleRegionSelection(role.name)) {
      roleSelection = new RoleWithMultipleRegionSelection();
      roleSelection.role = role;
      roleSelection.selected = false;
      roleSelection.regions = [];
    } else if (!this.hasLocationSelection(role.name)) {
      roleSelection = new RoleWithoutLocationSelection();
      roleSelection.role = role;
      roleSelection.selected = false;
    } else if (this.hasSingleLocationSelection(role.name)) {
      roleSelection = new RoleWithSingleLocationsSelection();
      roleSelection.role = role;
      roleSelection.selected = false;
      roleSelection.location = null;
    } else {
      roleSelection = new RoleWithMultipleLocationsSelection();
      roleSelection.role = role;
      roleSelection.selected = false;
      roleSelection.locations = [];
    }

    return roleSelection as RoleLocationsOrRegionsSelection;
  }

  public render() {
    const { t } = this.props as any;

    if (this.state.loading)
      return (
        <Dimmer active={this.state.loading} inverted style={{ position: 'fixed' }}>
          <Loader indeterminate inverted></Loader>
        </Dimmer>
      );

    const locationOptions = this.state.locationOptions;
    const regionOptions = this.state.regionOptions;
    let table: RoleLocationsOrRegionsSelection[][] = [];

    if (!this.state.selections) return <></>;

    this.state.selections.forEach((element, index) => {
      let i = Math.floor(index / 2);
      let j = index % 2;

      if (j === 0) table[i] = [];

      table[i].push(element);
    });

    return (
      <Form.Field
        required
        error={
          this.props.isSubmited &&
          (this.state.selections.any(x => x.selected && !this.isValidRoleInLocationSelect(x)) ||
            this.state.selections.all(x => !x.selected))
        }
      >
        {!this.props.hideLabel && <label style={{ marginLeft: '0px' }}>{t('Roles')}</label>}
        {table.map((row, rowIndex) => {
          return (
            <Form.Group widths={2} key={rowIndex}>
              {row.map((e, columnIndex) => this.renderRoleInLocations(e, rowIndex * 2 + columnIndex, locationOptions, regionOptions))}
            </Form.Group>
          );
        })}
      </Form.Field>
    );
  }

  renderRoleInLocations(roleInLocation: RoleLocationsOrRegionsSelection, index: number, locationOptions, regionOptions) {
    const { t, userAction } = this.props as any;
    const { isGlobalPoc } = this.state;

    let isRoleWithRegion = this.hasMultipleRegionSelection(roleInLocation.role.name);
    let placeholder = isRoleWithRegion ? t('Select Region') : t('Select Location');
    let options = isRoleWithRegion ? regionOptions : locationOptions;
    let onChange = isRoleWithRegion ? this.selectedRegion : this.selectedLocation;
    const locationComponent = this.generateLocationComponentForRole(roleInLocation, options, index, placeholder, onChange);

    const cNameParts = ['role-editor__wrapper'];
    if (index % 2 === 0) cNameParts.push('role-editor__wrapper__column-1');
    else cNameParts.push('role-editor__wrapper__column-2');

    if (locationComponent == null) cNameParts.push('role-editor__wrapper__without-selector');
    else cNameParts.push('role-editor__wrapper__with-selector');

    return (
      <div className={cNameParts.join(' ')} key={roleInLocation.role.id}>
        <div className="role-editor__elements">
          {(userAction === 'New' || (userAction === 'Edit' && this.props.userItem)) && roleInLocation.role.name === 'PoC' && (
            <div className="new-user-modal" style={{ marginBottom: '5px' }}>
              <Checkbox
                label={t('Global PoC')}
                checked={isGlobalPoc}
                disabled={!roleInLocation.selected}
                className="role-editor__elements__checkbox"
                onChange={(_, { checked }) => this.handleGlobalPoc(!!checked)}
              />
            </div>
          )}
          <Checkbox
            className="role-editor__elements__checkbox"
            checked={roleInLocation.selected}
            label={GetRoleName(roleInLocation.role.name)}
            onChange={e => this.selectedRole(index)}
            disabled={!this.isAllowedToEditRole(roleInLocation.role.name)}
          />
        </div>
        {locationComponent}
      </div>
    );
  }

  isAllowedToEditRole(role: string) {
    if (this.identityService.isInRole(Roles.Admin)) return true;

    if (
      this.identityService.isInRole(Roles.Employee) ||
      this.identityService.isInRole(Roles.Instructor) ||
      this.identityService.isInRole(Roles.Planner) ||
      this.identityService.isInRole(Roles.PlannerMTC)
    )
      return false;

    if (this.identityService.isInRole(Roles.PoC)) return role === 'Employee' || role === 'Instructor' || role === 'PoC';

    return role === 'Employee' || role === 'Instructor' || role === 'Planner' || role === 'Planner MTC';
  }

  isValidRoleInLocationSelect(roleInLocation: RoleLocationsOrRegionsSelection): boolean {
    if (!this.hasLocationSelection(roleInLocation.role.name) && !this.hasMultipleRegionSelection(roleInLocation.role.name)) return true;
    return roleInLocation.getLocationsOrRegions().length > 0;
  }

  generateLocationComponentForRole(
    roleSelection: RoleLocationsOrRegionsSelection,
    options: any,
    index: number,
    placeholder: any,
    onChangeSelection
  ) {
    if (roleSelection instanceof RoleWithoutLocationSelection) return null;

    const locationOptionsForRole = [].concat(options);

    if (roleSelection.role.name !== 'Reporting') {
      if ((locationOptionsForRole || []).any(x => x.text === 'Global')) {
        const globalIndex = (locationOptionsForRole || []).findIndex(x => x.text === 'Global');
        if (globalIndex >= 0) {
          (locationOptionsForRole || []).splice(globalIndex, 1);
        }
      }
    }

    return (
      <>
        <div className="role-editor__elements__dropdown">
          <Dropdown
            search
            inline
            selection
            clearable
            options={locationOptionsForRole}
            multiple={this.hasMultipleSelection(roleSelection.role.name)}
            className="planit-user-dropdown planit-users-roles-in-location-selector"
            value={this.getLocationValue(roleSelection)}
            onChange={(e, data) => onChangeSelection(index, data)}
            placeholder={placeholder}
            disabled={
              !roleSelection.selected ||
              !this.isAllowedToEditRole(roleSelection.role.name) ||
              (roleSelection.role.name === 'PoC' && this.state.isGlobalPoc)
            }
          />
        </div>
      </>
    );
  }

  private getLocationValue(roleInLocation: RoleLocationsOrRegionsSelection) {
    const locationsOrRegions = roleInLocation.getLocationsOrRegions() || [];
    if (this.hasMultipleSelection(roleInLocation.role.name)) return locationsOrRegions.filter(f => f?.id).map(x => x.id);

    if (locationsOrRegions.length > 0 && locationsOrRegions[0]) return locationsOrRegions[0].id;

    return null;
  }

  @autobind
  selectedLocation(index: number, data: any): void {
    let newRoleLocationInSelection = this.state.selections;

    let locations = data.multiple
      ? data.value.map(x => this.state.availableLocations[x])
      : data.value === ''
      ? null
      : [this.state.availableLocations[data.value]];

    newRoleLocationInSelection[index].setLocationsOrRegions(locations);

    this.setState({
      selections: newRoleLocationInSelection
    });

    this.props.onChange(newRoleLocationInSelection);
  }

  @autobind
  selectedRegion(index: number, data: any): void {
    let rolesInSelection = this.state.selections;

    let regions = data.multiple
      ? data.value.map(x => this.state.availableRegions[x])
      : data.value === ''
      ? null
      : [this.state.availableRegions[data.value]];

    rolesInSelection[index].setLocationsOrRegions(regions);

    this.setState({
      selections: rolesInSelection
    });

    this.props.onChange(rolesInSelection);
  }

  selectedRole(index: number): void {
    let newRoleLocationInSelection = this.state.selections;
    newRoleLocationInSelection[index].selected = !this.state.selections[index].selected;

    if (newRoleLocationInSelection[index].role.name === 'PoC' && !newRoleLocationInSelection[index].selected) {
      let selectionLocations = newRoleLocationInSelection[index].getLocationsOrRegions();
      selectionLocations = selectionLocations.filter(x => x.location !== 'Global');
      newRoleLocationInSelection[index].setLocationsOrRegions(selectionLocations);

      this.setState({
        isGlobalPoc: false
      });
    }

    this.setState({
      selections: newRoleLocationInSelection
    });

    this.props.onChange(newRoleLocationInSelection);
  }

  handleGlobalPoc(value: boolean): void {
    const { isGlobalPoc } = this.state;
    const dict = this.state.availableLocations;

    const mySelections = [];
    this.state.roleOptions.forEach(x => {
      mySelections[this.getRoleIndexOrder(x.text)] = this.state.selections[this.getRoleIndexOrder(x.text)];
    });

    const locationDto = Object.values(dict).firstOrDefault(x => x.location === 'Global');
    const selection = mySelections.firstOrDefault(x => x.role.name === 'PoC');

    if (locationDto && selection && this.hasMultipleLocationSelection(selection.role.name)) {
      let selectionLocations = []; //selection.getLocationsOrRegions(); //=> remove only global loc or all loc (as it is now)
      if (value) {
        if (!!!selectionLocations.any(x => x.location === 'Global')) selectionLocations.push(locationDto);
      } else {
        selectionLocations = selectionLocations.filter(x => x.location !== 'Global');
      }
      selection.setLocationsOrRegions(selectionLocations);
    }

    this.props.onChangeGlobalPoc(value);

    this.setState({
      selections: mySelections,
      isGlobalPoc: !!!isGlobalPoc
    });

    this.props.onChange(mySelections);
  }

  hasLocationSelection(roleName: string) {
    return this.availableLocationSelectionRoles.any(x => x === roleName);
  }

  hasSingleLocationSelection(roleName: string) {
    return this.singleLocationSelectionRoles.any(x => x === roleName);
  }

  hasMultipleLocationSelection(roleName: string) {
    return this.multipleLocationSelectionRoles.any(x => x === roleName);
  }

  getRoleIndexOrder(roleName: string): number {
    return this.roleOrder.indexOf(roleName);
  }

  hasMultipleRegionSelection(roleName: string) {
    return this.multipleRegionSelectionRoles.any(x => x === roleName);
  }

  hasMultipleSelection(roleName: string) {
    return this.hasMultipleLocationSelection(roleName) || this.hasMultipleRegionSelection(roleName);
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(RoleEditor);
