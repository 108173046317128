import React, { PureComponent } from 'react';
import { Form, Button } from 'semantic-ui-react';
import PatternEditor from 'widgets/bussiness/pattern-editor';
import { ItemReference } from 'stores/dataStore';
import { PatternRowDto } from 'stores/configuration/machinery/pattern-rows-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HierarchyDto, HierarchyStore, IdNameDto, PatternsStore } from 'stores/configuration/machinery/patterns-store';
import MachineListItem, { MachineListItemViewModel } from 'site/pages/shared/events-and-requests/machine-list-item';
import { connect } from 'redux-scaffolding-ts';
import './pattern-machines.less';

export interface PatternMachinesViewModel {
  pattern: ItemReference;
  machines: MachineListItemViewModel[];
}

export interface PatternMachinesProps extends WithTranslation {
  readOnly: boolean;
  showRequired?: boolean;
  patternsStore?: PatternsStore;
  hierarchyStore?: HierarchyStore;
  value: PatternMachinesViewModel;
  onChange?: (value: PatternMachinesViewModel) => void;
  className?: string;
  loadPatternsOnOpen?: boolean;
}

export interface PatternMachinesState {
  hasPattern: boolean;
  patternRows: PatternRowDto[];
}

@connect(['patternsStore', PatternsStore], ['hierarchyStore', HierarchyStore])
class PatternMachines extends PureComponent<PatternMachinesProps, PatternMachinesState> {
  static defaultProps = { onChange: () => {} };
  state: PatternMachinesState = {
    patternRows: [],
    hasPattern: false
  };

  componentDidMount() {
    if (!this.props.value.machines || this.props.value.machines.length === 0) {
      this.props.onChange({
        pattern: null,
        machines: [{ cluster: null, equipmentType: null, oem: null, machineModel: null, machineUnits: [], plcTypes: [] }]
      });
    } else {
      const machinesProp = this.props.value.machines;
      let model: HierarchyDto = {
        clusterIds: machinesProp.flatMap(x => x.cluster?.id).filter(x => x),
        equipmentTypeIds: machinesProp.flatMap(x => x.equipmentType?.id).filter(x => x),
        oemIds: machinesProp.flatMap(x => x.oem?.id).filter(x => x),
        machineModelIds: machinesProp.flatMap(x => x.machineModel?.id).filter(x => x),
        machineUnitIds: machinesProp.flatMap(x => x.machineUnits).filter(x => x),
        plcTypeIds: machinesProp.flatMap(x => x.plcTypes).filter(x => x)
      };
      this.props.hierarchyStore.get(model).then(resp => {
        if (resp.isSuccess) {
          let machines: MachineListItemViewModel[] = [];
          const idNames = resp.item;
          machines = machinesProp.map(pr => ({
            cluster: pr.cluster ? this.toItemReference(idNames.clusters.find(x => x.id === pr.cluster.id)) : null,
            equipmentType: pr.equipmentType ? this.toItemReference(idNames.equipmentTypes.find(x => x.id === pr.equipmentType.id)) : null,
            oem: pr.oem ? this.toItemReference(idNames.oems.find(x => x.id === pr.oem.id)) : null,
            machineModel: pr.machineModel ? this.toItemReference(idNames.machineModels.find(x => x.id === pr.machineModel.id)) : null,
            machineUnits: pr.machineUnits,
            plcTypes: pr.plcTypes,
            machineUnitItems:
              pr.machineUnits && pr.machineUnits.length > 0
                ? idNames.machineUnits.filter(x => pr.machineUnits.includes(x.id)).map(x => this.toItemReference(x))
                : [],
            plcTypeItems:
              pr.plcTypes && pr.plcTypes.length > 0
                ? idNames.plcTypes.filter(x => pr.plcTypes.includes(x.id)).map(x => this.toItemReference(x))
                : []
          }));

          const patternViewModel: PatternMachinesViewModel = { ...this.props.value, machines };
          this.setState({}, () => this.props.onChange(patternViewModel));
        }
      });
    }

    if (this.props.value.pattern && this.props.value.pattern.id) {
      this.setState({ hasPattern: true });
      this.loadPattern(this.props.value.pattern.id).then(pattern => {
        this.setState({ patternRows: pattern?.patternRows });
      });
    }
  }

  private toItemReference = (item: IdNameDto) => {
    if (!item) return null;
    const model: ItemReference = {
      id: item.id,
      title: item.name
    };
    return model;
  };
  private handleOnHasPatternChange = (hasPattern: boolean) => {
    let newValue = { ...this.props.value };
    newValue.pattern = null;
    newValue.machines = !hasPattern
      ? [{ cluster: null, equipmentType: null, oem: null, machineModel: null, machineUnits: [], plcTypes: [] }]
      : [];

    this.setState({ hasPattern: hasPattern });
    this.props.onChange(newValue);
  };

  private handleOnPatternChange = (pattern: ItemReference) => {
    if (!pattern) {
      let value = { ...this.props.value };
      value.pattern = null;
      value.machines = [];

      this.props.onChange(value);
      return;
    }

    this.loadPattern(pattern.id).then(({ patternRows }) => {
      const machines: MachineListItemViewModel[] = this.mapPatternRowsToViewModel(patternRows || []);

      const value: PatternMachinesViewModel = { ...this.props.value, pattern, machines };

      this.setState({ patternRows }, () => this.props.onChange(value));
    });
  };

  private loadPattern = (patternId: string) => this.props.patternsStore.getById(patternId);

  private handleOnMachineChange = (index: number, newMachine: MachineListItemViewModel) => {
    let value = { ...this.props.value };
    value.machines[index] = newMachine;

    this.props.onChange(value);
  };

  private handleOnDeleteRow = (index: number) => {
    const { value, onChange } = this.props;
    onChange({ ...value, machines: value.machines.filter((_, i) => i !== index) });
  };
  private mapPatternRowsToViewModel = (patternRows: PatternRowDto[]) => {
    const machines: MachineListItemViewModel[] = (patternRows || []).map(pr => ({
      cluster: pr.machineRelatedClusterId ? { id: pr.machineRelatedClusterId, title: pr.machineRelatedClusterName } : null,
      equipmentType: pr.equipmentTypeId ? { id: pr.equipmentTypeId, title: pr.equipmentTypeName } : null,
      oem: pr.oemId ? { id: pr.oemId, title: pr.oemName } : null,
      machineModel: pr.machineModelId ? { id: pr.machineModelId, title: pr.machineModelName } : null,
      machineUnits: [],
      plcTypes: []
    }));
    return machines;
  };
  render() {
    const { t, readOnly, patternsStore, loadPatternsOnOpen, showRequired } = this.props;
    const { pattern, machines } = this.props.value;
    const { hasPattern, patternRows } = this.state;
    const requiredFieldsClassName = showRequired ? 'field required' : 'field';
    const { isBusy } = patternsStore.state;
    return (
      <>
        <Form.Field className="request-details-tab__mr__pattern-row-btns">
          <p>{t('Pattern')}</p>

          <Button.Group className="mr__pattern-row-btns-wrapper">
            <Button
              attached="left"
              disabled={readOnly}
              className={hasPattern ? 'events-form__btn__positive' : 'events-form__btn__negative'}
              onClick={() => this.handleOnHasPatternChange(true)}
            >
              {t('Yes')}
            </Button>
            <Button
              attached="right"
              disabled={readOnly}
              className={hasPattern ? 'events-form__btn__negative' : 'events-form__btn__positive'}
              onClick={() => this.handleOnHasPatternChange(false)}
            >
              {t('No')}
            </Button>
          </Button.Group>

          {hasPattern && (
            <PatternEditor
              nullable
              clearable
              showOnlyActive
              readOnly={readOnly}
              className="event-details__pattern-dropdown"
              value={pattern}
              onChange={this.handleOnPatternChange}
              loadDataOnOpen={loadPatternsOnOpen}
            />
          )}
        </Form.Field>

        {machines && (
          <div className="event-details-tab__mr-table scroll">
            <div className={requiredFieldsClassName}>
              <label className="mr-table__column-title">{t('Cluster')}</label>
            </div>
            <div className={requiredFieldsClassName}>
              <label className="mr-table__column-title">{t('Equipment Type')}</label>
            </div>
            <div className={showRequired && !hasPattern ? 'field required' : 'field'}>
              <label className="mr-table__column-title">{t('OEM')}</label>
            </div>
            <div className={showRequired && !hasPattern ? 'field required' : 'field'}>
              <label className="mr-table__column-title">{t('Machine Model')}</label>
            </div>
            <div className="field">
              <label className="mr-table__column-title">{t('Machine Unit')}</label>
            </div>
            <div className="field">
              <label className="mr-table__column-title">{t('PLC Type')}</label>
            </div>
            <div className="field">
              <label className="mr-table__column-title text__transparent">X</label>
            </div>
            {machines.map((machine, index) => (
              <MachineListItem
                key={index}
                pattern={hasPattern && (patternRows || []).length > index ? patternRows[index] : null}
                machine={machine}
                onEditMode={!readOnly && !isBusy}
                canDelete={!readOnly && hasPattern}
                onChange={value => this.handleOnMachineChange(index, value)}
                onDeleteClose={() => this.handleOnDeleteRow(index)}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(PatternMachines);
