import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Button, Dimmer, Loader, Form, Message } from 'semantic-ui-react';
import './cancel-event-popup.less';
import { connect } from 'redux-scaffolding-ts';
import { ChangeRequestStore, RequestDto, RequestStatus } from 'stores/requests/requests-store';
import { EventFormStore } from 'stores/events/event-form-store';
import RejectionReasonEditor from 'widgets/bussiness/rejection-reason-editor';
import RejectedByEditor from 'widgets/bussiness/rejected-by-editor';
import { RejectProperties } from 'site/pages/shared/events-and-requests/reject-modal';

export interface EventFormStoreProps extends WithTranslation {
  open: boolean;
  eventFormStore?: EventFormStore;
  changeRequestStore?: ChangeRequestStore;
  cancelRequest?: boolean;
  request?: RequestDto;
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

interface CancelEventPopupState extends RejectProperties {
  loading: boolean;
  errMsgs: string[];
}

@connect(['eventFormStore', EventFormStore], ['changeRequestStore', ChangeRequestStore])
class CancelEventPopup extends React.Component<EventFormStoreProps, CancelEventPopupState> {
  static defaultProps = { onCancel: () => {}, onSuccess: () => {}, onError: () => {}, cancelRequest: false };
  state: CancelEventPopupState = { rejectReason: '', rejectedById: '', rejectionReasonId: '', loading: false, errMsgs: [] };

  private cancelEvent = async (requestStatus: RequestStatus, cancelRequest: boolean = this.props.cancelRequest) => {
    const { rejectReason, rejectedById, rejectionReasonId } = this.state;
    const errMsgs = [];
    const { onSuccess, onError, eventFormStore, changeRequestStore, request } = this.props;
    const { item } = eventFormStore.state;

    if (!rejectReason && !item.isDirectEvent) errMsgs.push('Invalid Reject reason text');
    if (!rejectionReasonId && !item.isDirectEvent) errMsgs.push('Is required to select Reject Reason');
    if (!rejectedById && !item.isDirectEvent) errMsgs.push('Is required to select Rejected By');

    if (errMsgs.length) {
      this.setState({ errMsgs });
      return;
    }
    this.setState({ loading: true });
    try {
      let response: any;
      if (cancelRequest) {
        response = await changeRequestStore.changeStatus(request?.id, requestStatus, rejectReason, rejectedById, rejectionReasonId);
      } else {
        response = await eventFormStore.cancelEvent(requestStatus, rejectReason, rejectedById, rejectionReasonId);
      }
      if (response?.isSuccess || response?.id) onSuccess();
    } catch (error) {
      console.error(error);
      onError();
    }
    this.setState({ loading: false });
  };

  private onRejectRequest = () => this.cancelEvent(RequestStatus.Rejected);

  private rejectionReasonIdHandler = rejectionReasonId => this.setState({ rejectionReasonId });

  private rejectReasonHandler = (_, { value }) => this.setState({ rejectReason: value });

  private onPendingRequest = () => this.cancelEvent(RequestStatus.Pending);

  private rejectedByHandler = rejectedById => this.setState({ rejectedById });

  private onDismiss = () => this.setState({ errMsgs: [] });

  private onCancel = () => this.props.onCancel();

  public render() {
    const { t, open, eventFormStore } = this.props;
    const { loading, rejectionReasonId, rejectedById, rejectReason, errMsgs } = this.state;
    const { item } = eventFormStore.state;
    const subtitle = item.hasRequests
      ? 'What do you want to do with the ASSOCIATED REQUESTS?'
      : 'This action is not reversible, are you sure do you want to cancel it?';
    const rejectBtn = item.hasRequests ? 'Reject Request' : 'Cancel Event';
    const errors = errMsgs.length ? (
      <Message
        className="error-message__style"
        error
        icon="exclamation circle"
        onDismiss={this.onDismiss}
        header={t('An error ocurred')}
        list={errMsgs}
      />
    ) : null;

    return (
      <Modal open={open} size="tiny" className="cancel-event-popup">
        <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <>
          <Modal.Content className="cancel-event-popup__content">
            {errors}
            <p>{t('You are going to CANCEL the event.')}</p>

            {/* {!item.isDirectEvent && ( */}
            <>
              <div className="reject-request-popup__selectors">
                <RejectionReasonEditor value={rejectionReasonId} onChange={this.rejectionReasonIdHandler} />
                <RejectedByEditor value={rejectedById} onChange={this.rejectedByHandler} />
              </div>
              <Form loading={loading}>
                <Form.TextArea
                  placeholder={t('Reason')}
                  required
                  className="reject-request-popup__reason-description"
                  value={rejectReason}
                  onChange={this.rejectReasonHandler}
                />
              </Form>
            </>
            {/* )} */}

            <p style={{ marginTop: 16 }}>{t(subtitle)}</p>
          </Modal.Content>
          <div className="cancel-event-popup__buttons-container">
            <div className="cancel-event-popup__buttons-container__right-container">
              <Button className="cancel-event-popup__btn cancel-event-popup__back-btn" content={t('Back')} onClick={this.onCancel} />
            </div>
            <div className="cancel-event-popup__buttons-container__left-container">
              <Button className="cancel-event-popup__btn" content={t(rejectBtn)} onClick={this.onRejectRequest} />
              {item.hasRequests && (
                <Button
                  className="cancel-event-popup__btn cancel-event-popup__pending-btn"
                  content={t('Return to Pending')}
                  onClick={this.onPendingRequest}
                />
              )}
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default withTranslation()(CancelEventPopup);
