import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { EventTypeDto, DropDownEventTypesStore } from '../../../stores/configuration/events-workflow/event-types-store';
import { nameof } from '../../../utils/object';
import { DropdownProps, SelectionItem } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';

interface EventTypeSelectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: SelectionItem) => void;
  eventTypes?: DropDownEventTypesStore;
  direction?: 'left' | 'right';
  optionsFromRequests?: SelectionItem[];
}

@connect(['eventTypes', DropDownEventTypesStore])
class EventTypesSelector extends React.Component<EventTypeSelectorProps> {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    this.props.eventTypes
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }],
        filter: [`active eq true`]
      })
      .then(response => {
        let asOptions = response.items.map(item => ({ value: item.id, text: item.name } as SelectionItem));
        const { optionsFromRequests } = this.props;
        let extendedOptions = [...asOptions];
        if (this.props.optionsFromRequests && this.props.optionsFromRequests.length > 0) {
          let notAsOption = this.getArrayDifferencesByText(optionsFromRequests, asOptions);
          extendedOptions = [];
          extendedOptions = [...asOptions, ...notAsOption].sort((a, b) => a.text.localeCompare(b.text));
        }
        this.setState({
          isLoading: false,
          options: extendedOptions
        });
      });
  }

  private getArrayDifferencesByText(source: SelectionItem[], toDifferenciate: SelectionItem[]) {
    return source.filter(object1 => {
      return !toDifferenciate.some(object2 => {
        return object1.text === object2.text;
      });
    });
  }
  private onSelectValue(value: boolean | number | string | (boolean | number | string)[]) {
    if (this.props.onChange) {
      const { options } = this.state;
      let selected = options.find(x => x.value === value);
      this.props.onChange(selected);
    }
  }
  public render() {
    const { isLoading, options } = this.state;
    const { value, searchable, placeholder, direction, clearable, className } = this.props;
    const selectedValue = value || null;
    return (
      <Dropdown
        direction={direction}
        selection
        loading={isLoading}
        search={searchable}
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => {
          this.onSelectValue(value);
        }}
        selectOnBlur={false}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(EventTypesSelector);
