import React, { FunctionComponent } from 'react';
import GradeComponent from './tna-form-score-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { iwsScoreNames, scoreNames, gradesNames } from './score-names-constants';

interface TNAScoresProps extends WithTranslation {
  activeGrade: number;
  onGradeChange: (int: number) => void;
  assessment?: 'tna' | 'ssa';
  className?: string;
  isToggleable?: boolean;
}

interface ScoreObj {
  active: boolean;
  name: string;
}

const TNAFormScores: FunctionComponent<TNAScoresProps> = ({ activeGrade, assessment, isToggleable, className, onGradeChange, t }) => {
  const mapScoreActive = (): ScoreObj[] => {
    return (!assessment || assessment === 'tna' ? (assessment === 'tna' ? scoreNames : iwsScoreNames) : gradesNames).map((score, idx) => {
      let active = false;
      if (idx === activeGrade) active = true;
      if (activeGrade === -1) active = true;
      return { active, name: score };
    });
  };

  const scores = mapScoreActive();

  return (
    <>
      {scores.map(({ name, active }, i) => (
        <GradeComponent
          key={`name-${i}`}
          onToggleGrade={(number, status) => onGradeChange(status ? +number : null)}
          active={active}
          isToggleable={isToggleable === false ? false : true}
          scoreName={t(name)}
          className={className}
          scoreNumber={'' + i}
        />
      ))}
    </>
  );
};
export default withTranslation()(TNAFormScores);
