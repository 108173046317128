import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import { EventFieldGroups, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { EventWizardData } from 'stores/events/creation-wizard-store';
import { Categories } from 'stores/requests/requests-store';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import LocationEditor from 'widgets/bussiness/location-editor';
import PriorityEditor from 'widgets/bussiness/priority-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';
import { LocationsStore } from 'stores/configuration/locations/locations-store';
import { connect } from 'redux-scaffolding-ts';

interface BasicStepProps extends WithTranslation {
  wizardData: EventWizardData;
  onValueChanged: (...changes: [string, any][]) => void;
  selectLocation: LocationsStore;
}

interface BasicStepState {
  wizardData: EventWizardData;
  locationsRecived: string[];
  selectedLocation: string;
}

@connect(['selectLocation', LocationsStore])
class BasicStep extends Component<BasicStepProps, BasicStepState> {
  constructor(props) {
    super(props);

    this.state = {
      wizardData: props.wizardData,
      locationsRecived: [],
      selectedLocation: ''
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  UNSAFE_componentWillReceiveProps(nextProps: BasicStepProps) {
    if (nextProps && nextProps !== this.props) {
      this.setState({
        wizardData: nextProps.wizardData
      });
    }
  }

  componentDidMount() {
    const currentUserInfo = this.identityService.getUserInfo();

    if (IdentityService.isRoleWithLocations(currentUserInfo)) {
      const rolesLocations = currentUserInfo.locationsByRoles[currentUserInfo.activeRole] as string[];

      if (this.identityService.hasRoleMultipleLocations(currentUserInfo)) {
        this.setState({ locationsRecived: rolesLocations });
      } else {
        this.props.selectLocation.getUserById(rolesLocations[0]).then(res => {
          const { wizardData } = this.state;
          wizardData.location = { id: res.id, title: res.location };
          this.setState({ selectedLocation: res.location });
        });
      }
    }

    if (IdentityService.isRoleWithLocations(currentUserInfo)) {
      const rolesLocations = currentUserInfo.locationsByRoles[currentUserInfo.activeRole] as string[];

      if (this.identityService.hasRoleMultipleLocations(currentUserInfo)) {
        this.setState({ locationsRecived: rolesLocations });
      } else {
        this.props.selectLocation.getUserById(rolesLocations[0]).then(res => {
          const { wizardData } = this.state;
          wizardData.location = { id: res.id, title: res.location };
          this.setState({ selectedLocation: res.location });
        });
      }
    }
  }

  private onEventTypeChanged = (eventType: EventTypeDto) => {
    const wizardChanges: Partial<EventWizardData> = {};

    wizardChanges.eventType = eventType;
    wizardChanges.eventTitle = '';
    let changes: [keyof EventWizardData, any][] = [
      ['eventType', eventType],
      ['eventTitle', '']
    ];
    const hasReqDetails = (eventType?.requestFieldGroups || '').includes(EventFieldGroups[EventFieldGroups.RequestDetails]);

    this.setState(
      ({ wizardData }) => {
        if (!hasReqDetails) {
          wizardChanges.location = null;
          wizardChanges.priority = null;
          wizardChanges.category = null;
          wizardChanges.profession = null;
          wizardChanges.isMachineRelated = null;
          changes.push(['location', null], ['priority', null], ['category', null], ['profession', null], ['isMachineRelated', null]);
        }
        return { wizardData: { ...wizardData, ...wizardChanges } };
      },
      () => this.props.onValueChanged(...changes)
    );
  };

  private handleValue = (property: keyof EventWizardData, value: any) => {
    const wizardChanges: Partial<EventWizardData> = { [property]: value, eventTitle: '' };
    let changes: [keyof EventWizardData, any][] = [
      [property, value],
      ['eventTitle', '']
    ];

    if (property === 'isMachineRelated' || property === 'category')
      changes.push(
        ['nmrCluster', null],
        ['nmrFunctionalArea', null],
        ['nmrTrainingName', null],
        ['nmrFunctionalSubArea', null],
        ['nmrClusterId', ''],
        ['nMRFunctionalAreaId', ''],
        ['nMRFunctionalSubAreaId', ''],
        ['nMRTrainingNameId', '']
      );

    if (property === 'isMachineRelated' && value) {
      wizardChanges.category = Categories.Technical;
      changes = [...changes, ['category', Categories.Technical]];
    }
    if (property === 'category' && value === Categories.Functional) {
      wizardChanges.isMachineRelated = false;
      changes = [...changes, ['isMachineRelated', false]];
    }
    this.setState(
      ({ wizardData }) => ({ wizardData: { ...wizardData, ...wizardChanges } }),
      () => this.props.onValueChanged(...changes)
    );
  };

  private showReservedRequired() {
    if (
      this.state.wizardData?.eventType?.requestFieldGroups?.includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
      this.state.wizardData?.eventType?.name.toLocaleLowerCase().includes('reserved') &&
      this.state.wizardData?.isMachineRelated == null &&
      this.state.wizardData?.category == null
    ) {
      return false;
    }

    return true;
  }

  render() {
    const { t } = this.props as any;
    const { wizardData, locationsRecived } = this.state;
    const currentUser = this.identityService.getUserInfo();
    const isRoleWithLocations = IdentityService.isRoleWithLocations(currentUser);
    const showMultipleLocations = !IdentityService.isRoleWithLocations(currentUser);
    const reservedRequired = this.showReservedRequired();

    return (
      <Form className="wizard__basic-step__container">
        <Grid columns={4}>
          <Grid.Row>
            <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
              <div className={`required field inline`}>
                <label>{t('Event Type')}</label>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <EventTypeEditor
                nullable
                value={wizardData.eventType as any}
                onChange={c => this.onEventTypeChanged(c?.id ? (c as EventTypeDto) : null)}
                directOnly
                filterDirectEventCreator={true}
              />
            </Grid.Column>
          </Grid.Row>
          {(wizardData?.eventType?.requestFieldGroups || '').includes(EventFieldGroups[EventFieldGroups.RequestDetails]) && (
            <>
              <Grid.Row>
                <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                  <div className={`required field inline`}>
                    <label>{t('Event Location')}</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  {showMultipleLocations && (
                    <LocationEditor
                      nullable
                      value={wizardData.location}
                      onChange={c => this.handleValue('location', c)}
                      showCountry={true}
                    />
                  )}

                  {isRoleWithLocations && this.identityService.hasRoleMultipleLocations(currentUser) && (
                    <LocationEditorWithRoleLocations
                      className="min-width-100pc"
                      value={wizardData.location ? wizardData.location.id : null}
                      locationsReceived={locationsRecived}
                      onChange={location =>
                        this.handleValue('location', location?.id ? { id: location.id, title: location?.location } : null)
                      }
                      showCountry={true}
                    />
                  )}
                  {isRoleWithLocations && !this.identityService.hasRoleMultipleLocations(currentUser) && (
                    <Input className="min-width-100pc" readonly={true} value={this.state.selectedLocation} />
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" verticalAlign="middle" width={2}>
                  <div className={`field inline`}>
                    <label>{t('Priority')}</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  <PriorityEditor nullable clearable={true} value={wizardData.priority} onChange={c => this.handleValue('priority', c)} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                  <div className={`${reservedRequired ? 'required ' : ''}field inline`}>
                    <label>{t('Category')}</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <div className={`${reservedRequired ? 'required ' : ''}field inline`}>
                    <Button.Group className="filter-button-group green" style={{ width: 195 }}>
                      <Button
                        className={wizardData.category === Categories.Technical ? 'active gray-border' : 'gray-border'}
                        onClick={() => this.handleValue('category', Categories.Technical)}
                      >
                        {t('Technical')}
                      </Button>
                      <Button
                        className={wizardData.category === Categories.Functional ? 'active gray-border' : 'gray-border'}
                        onClick={() => this.handleValue('category', Categories.Functional)}
                      >
                        {t('Functional')}
                      </Button>
                    </Button.Group>
                  </div>
                </Grid.Column>

                <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                  <div className={`${wizardData?.eventType?.name.toLocaleLowerCase().includes('reserved') ? '' : 'required '}field inline`}>
                    <label>{t('Role')}</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  <ProfessionEditor nullable value={wizardData.profession} onChange={c => this.handleValue('profession', c)} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                  <div className={`${reservedRequired ? 'required ' : ''}field inline`}>
                    <label>{t('Machine related')}</label>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className={`${reservedRequired ? 'required ' : ''}field inline`}>
                    <Button.Group className="filter-button-group green" style={{ width: 195 }}>
                      <Button
                        className={wizardData.isMachineRelated ? 'active gray-border' : 'gray-border'}
                        onClick={() => this.handleValue('isMachineRelated', true)}
                      >
                        {t('Yes')}
                      </Button>
                      <Button
                        className={wizardData.isMachineRelated === false ? 'active gray-border' : 'gray-border'}
                        onClick={() => this.handleValue('isMachineRelated', false)}
                      >
                        {t('No')}
                      </Button>
                    </Button.Group>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Form>
    );
  }
}

export default withTranslation()(BasicStep);
