import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { decamelCase } from 'utils/event-utils';
import { DevelopmentPlanStatus } from 'stores/development-plan/development-plan-status.enum';

interface DevelopmentPlanStatusEditorProps extends WithTranslation {
  value: DevelopmentPlanStatus;
  onChange?: (value: DevelopmentPlanStatus) => void;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
}

interface DevelopmentPlanStatusEditorState {
  options: DropdownItemProps[];
}

class DevelopmentPlanStatusEditor extends React.Component<DevelopmentPlanStatusEditorProps, DevelopmentPlanStatusEditorState> {
  constructor(props: DevelopmentPlanStatusEditorProps) {
    super(props);
    this.state = {
      options: this.getAllDevelopmentPlanStatus()
    };
  }

  public render() {
    return (
      <Dropdown
        className={!this.props.className ? 'user-dropdown' : this.props.className}
        selection
        disabled={this.props.readOnly}
        options={this.state.options}
        value={DevelopmentPlanStatus[this.props.value]}
        onChange={(e, { value }) => this.handleOnSelection(value)}
      />
    );
  }

  private handleOnSelection(value) {
    let developmentPlanStatusStr: string = value;
    let developmentPlanStatus: DevelopmentPlanStatus = DevelopmentPlanStatus[developmentPlanStatusStr];
    this.props.onChange(developmentPlanStatus);
  }

  private getAllDevelopmentPlanStatus() {
    let map: { text: string; value: string }[] = [];

    for (let n in DevelopmentPlanStatus) {
      if (typeof DevelopmentPlanStatus[n] === 'number') {
        map.push({ text: decamelCase(n), value: DevelopmentPlanStatus[n] });
      }
    }

    return map;
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(DevelopmentPlanStatusEditor);
