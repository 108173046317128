import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import SsaSkillsListPage from './skills/skills-list';
import SsaQuestionBankList from './question-bank/ssa-question-bank-list';
import queryString from 'query-string';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import SsaTemplateList from './templates/ssa-templates-list';
import SsaFormsList from './forms/ssa-forms-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface SsaSkillsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface SsaSkillsConfigurationHomeState {
  activeIndex: number;
}

class SsaSkillsConfigurationHomePage extends React.Component<SsaSkillsConfigurationHomeProps, SsaSkillsConfigurationHomeState> {
  routes: RouteComponentProps = {
    match: this.props.match,
    history: this.props.history,
    location: this.props.location,
    staticContext: this.props.staticContext
  };

  constructor(props: SsaSkillsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndex()
    };
  }

  private getActiveIndex = () => {
    let values = queryString.parse(this.props.location.search);
    if (values == null || values.tab == null) values = this.props.match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    let l = this.panes.length;
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < l) return n;
    return 0;
  };

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isInRoleAdmin(): boolean {
    return this.identityService.isInRole(Roles.Admin);
  }

  private isInPowerInstructorRole(): boolean {
    return IdentityService.isPowerInstructor(this.identityService.getUserInfo());
  }

  private isInRolePoCorEmployee(): boolean {
    return this.identityService.isInRole(Roles.PoC) || this.identityService.isInRole(Roles.Employee);
  }

  private panes = [
    {
      menuItem: i18n.t('Question Bank'),
      render: () => (
        <Tab.Pane attached={false}>
          <SsaQuestionBankList />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Skills'),
      render: () => (
        <Tab.Pane attached={false}>
          <SsaSkillsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Templates'),
      render: () => (
        <Tab.Pane attached={false}>
          <SsaTemplateList {...this.routes} />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Forms'),
      render: () => (
        <Tab.Pane attached={false}>
          <SsaFormsList {...this.routes} />
        </Tab.Pane>
      )
    }
  ];

  private onTabChange = (_, { activeIndex }) => {
    this.props.history.replace(`/assessments/ssa?tab=${activeIndex}`);
    this.setState({ activeIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    let p = [...this.panes];
    return (
      <>
        <h3 id="tna-forms-list-filters__title">{t('Shopfloor Skills Assessments')}</h3>
        {(this.isInRoleAdmin() || this.isInPowerInstructorRole()) && (
          <Tab
            className="main-tab"
            menu={{ secondary: true, pointing: true }}
            panes={p}
            activeIndex={activeIndex}
            onTabChange={this.onTabChange}
          />
        )}
        {this.isInRolePoCorEmployee() && (
          <>
            <LineSeparator />
            <SsaFormsList {...this.routes} isUserAssessor={false} />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(SsaSkillsConfigurationHomePage);
