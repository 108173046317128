import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';

interface MediaCmpProps extends WithTranslation {
  className?: string;
  file: string;
  onClear: () => void;
  onDownload?: () => void;
}

const TheoreticalMedia: FC<MediaCmpProps> = ({ t, className, file, onClear, onDownload }) => {
  if (!file) return null;
  let classname = 'media-input';
  if (className) classname += ` ${className}`;
  return (
    <div className={classname}>
      <TableTooltipCell
        textToShow={t(file)}
        optionalRenderer={
          <a style={{ cursor: 'pointer' }} onClick={onDownload && onDownload}>
            {t(file)}
          </a>
        }
      />
      <Icon name="delete" color="red" size="large" onClick={onClear} />
    </div>
  );
};
export default withTranslation()(TheoreticalMedia);
