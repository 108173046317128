import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import {
  PracticalTemplatesStore,
  PracticalTemplateItemDto,
  NewPracticalTemplatesStore,
  ChangePracticalTemplatesStore
} from 'stores/assessments/templates/practical-templates-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemReference, Query, OrderDefinition } from 'stores/dataStore';
import { TableColumn, TableModel, TableView } from 'widgets/collections/table';
import i18n from 'i18n';
import { Message, Icon, Modal, Button, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import ChipInfo from 'widgets/bussiness/chip-info';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import BooleanEditor from 'widgets/bussiness/boolean-editor';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';
import './templates-tab-pane.less';
import { nameof } from 'utils/object';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MultiSelectorNMRFilters, { NMrTagsFiltersValues } from 'widgets/bussiness/multiple-selector/multiple-nmr-editor';
import MultiSelectorMRMachineryFilters, { MrTagsFiltersValues } from 'widgets/bussiness/multiple-selector/multiple-mr-machinery';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';

export interface PracticalTemplatesListProps extends WithTranslation, RouteComponentProps {
  practicalTemplatesStore?: PracticalTemplatesStore;
  newPracticalTemplatesStore?: NewPracticalTemplatesStore;
  changePracticalTemplateStore?: ChangePracticalTemplatesStore;
}

interface TemplatesTabPaneFilters {
  templateId: string;
  role: ItemReference;
  machineRelated?: boolean;
  trainingLevel: ItemReference;
  mrFilters: MrTagsFiltersValues;
  nmrFilters: NMrTagsFiltersValues;
  includeInactives: boolean;
  eventTypeIds: string[];
}

interface PracticalTemplatesListState {
  showModal: 'DisableModal' | 'CloneModal' | 'None';
  query: Query;
  filters: TemplatesTabPaneFilters;
  selectedItem: PracticalTemplateItemDto;
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['practicalTemplatesStore', PracticalTemplatesStore],
  ['newPracticalTemplatesStore', NewPracticalTemplatesStore],
  ['changePracticalTemplateStore', ChangePracticalTemplatesStore]
)
class PracticalTemplatesList extends Component<PracticalTemplatesListProps, PracticalTemplatesListState> {
  state: PracticalTemplatesListState = {
    query: {
      searchQuery: ``,
      orderBy: [
        { direction: 'Descending', field: nameof<PracticalTemplateItemDto>('friendlyId'), useProfile: false },
        { direction: 'Descending', field: 'modifiedOn', useProfile: false }
      ],
      filter: [],
      skip: 0,
      take: 10,
      parameters: {}
    },
    filters: {
      templateId: '',
      role: null,
      trainingLevel: null,
      machineRelated: null,
      mrFilters: null,
      nmrFilters: null,
      includeInactives: false,
      eventTypeIds: []
    },
    showModal: 'None',
    selectedItem: null,
    loading: false,
    someFilterOpened: false
  };

  private columns: TableColumn<PracticalTemplateItemDto>[] = [
    {
      title: i18n.t('Template ID'),
      tooltipRenderer: true,
      renderer: data => data.friendlyId,
      selectableHeader: true,
      sortDefinition: {
        field: nameof<PracticalTemplateItemDto>('friendlyId'),
        useProfile: false,
        active: 'Descending'
      }
    },
    {
      title: i18n.t('Template'),
      tooltipRenderer: false,
      renderer: data => data.title
    },
    {
      title: i18n.t('Role'),
      tooltipRenderer: true,
      renderer: data => (
        <span style={{ backgroundColor: '#EEEEEE', fontWeight: 'normal' }} className="question-bank__cell__tag">
          {data?.profession?.profession ?? ''}
        </span>
      )
    },
    {
      title: i18n.t('Machine Related'),
      tooltipRenderer: true,
      renderer: data => (data.isMachineRelated ? 'Yes' : 'No')
    },
    {
      title: i18n.t('Event Type'),
      tooltipRenderer: true,
      renderer: data => (
        <>
          {' '}
          {data.eventTypes.map(({ name, originalId }, idx) => (
            <React.Fragment key={originalId}>
              {idx !== 0 && <br />}
              {name}
            </React.Fragment>
          ))}{' '}
        </>
      )
    },
    {
      title: i18n.t('Training Level'),
      tooltipRenderer: true,
      renderer: data => data.trainingLevel && data.trainingLevel.name
    },
    {
      title: i18n.t('Machinery'),
      renderer: data =>
        data.mrPracticalTemplateDetails &&
        (data.mrPracticalTemplateDetails.machineModels && data.mrPracticalTemplateDetails.machineModels.length > 1 ? (
          data.mrPracticalTemplateDetails.machineModels.map(x => (
            <ChipInfo
              info={[
                data.mrPracticalTemplateDetails.cluster && data.mrPracticalTemplateDetails.cluster.name,
                data.mrPracticalTemplateDetails.equipmentType && data.mrPracticalTemplateDetails.equipmentType.name,
                data.mrPracticalTemplateDetails.oem && data.mrPracticalTemplateDetails.oem.name,
                data.mrPracticalTemplateDetails.machineModels && x.name
              ]}
            />
          ))
        ) : (
          <ChipInfo
            info={[
              data.mrPracticalTemplateDetails.cluster && data.mrPracticalTemplateDetails.cluster.name,
              data.mrPracticalTemplateDetails.equipmentType && data.mrPracticalTemplateDetails.equipmentType.name,
              data.mrPracticalTemplateDetails.oem && data.mrPracticalTemplateDetails.oem.name,
              data.mrPracticalTemplateDetails.machineModels && data.mrPracticalTemplateDetails.machineModels[0]?.name
            ]}
          />
        ))
    },
    {
      title: i18n.t('Relation'),
      renderer: data =>
        data.nmrPracticalTemplateDetails && (
          <ChipInfo
            info={[
              data.nmrPracticalTemplateDetails.nmrCluster && data.nmrPracticalTemplateDetails.nmrCluster.name,
              data.nmrPracticalTemplateDetails.functionalArea && data.nmrPracticalTemplateDetails.functionalArea.name,
              data.nmrPracticalTemplateDetails.trainingName && data.nmrPracticalTemplateDetails.trainingName.name,
              data.nmrPracticalTemplateDetails.functionalSubArea && data.nmrPracticalTemplateDetails.functionalSubArea.name
            ]}
          />
        )
    }
  ];

  private onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/practical/template' });
  };
  handleOnEnterKeydown = item => {
    this.handleOnEditItem(item);
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEditItem(item);
  };

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.practicalTemplatesStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: TemplatesTabPaneFilters) => {
    const query: Query = Object.assign(this.state.query);
    const oDataFilter = [];

    if (!!filters.templateId) {
      const parts = [`contains(tolower(Title), '${filters.templateId.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(filters.templateId, 'TP');
      if (!Number.isNaN(friendlyId)) {
        if (filters.templateId.startsWith('TP')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : filters.templateId}')`);
        }
      }

      oDataFilter.push(`(${parts.join(' or ')})`);
    }

    if (filters.role && filters.role.id) oDataFilter.push({ ProfessionId: { eq: { type: 'guid', value: filters.role.id } } });
    if (filters.machineRelated != null) oDataFilter.push({ IsMachineRelated: { eq: filters.machineRelated } });
    if (filters.trainingLevel && filters.trainingLevel.id)
      oDataFilter.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.trainingLevel.id } } });

    if (filters.nmrFilters != null) {
      if (filters.nmrFilters.nmClusters.length > 0)
        oDataFilter.push({ NmrPracticalTemplateDetails: { nmrClusterId: { in: { value: filters.nmrFilters.nmClusters, type: 'guid' } } } });

      if (filters.nmrFilters.functionalArea.length > 0)
        oDataFilter.push({
          NmrPracticalTemplateDetails: { functionalAreaId: { in: { type: 'guid', value: filters.nmrFilters.functionalArea } } }
        });

      if (filters.nmrFilters.trainingName.length > 0)
        oDataFilter.push({
          NmrPracticalTemplateDetails: { trainingNameId: { in: { type: 'guid', value: filters.nmrFilters.trainingName } } }
        });

      if (filters.nmrFilters.functionalSubarea.length > 0)
        oDataFilter.push({
          NmrPracticalTemplateDetails: { functionalSubareaId: { in: { type: 'guid', value: filters.nmrFilters.functionalSubarea } } }
        });
    }

    if (filters.mrFilters != null) {
      if (filters.mrFilters.clusters.length > 0)
        oDataFilter.push({ MrPracticalTemplateDetails: { clusterId: { in: { value: filters.mrFilters.clusters, type: 'guid' } } } });

      if (filters.mrFilters.equipmentTypes.length > 0)
        oDataFilter.push({
          MrPracticalTemplateDetails: { EquipmentTypeId: { in: { type: 'guid', value: filters.mrFilters.equipmentTypes } } }
        });

      if (filters.mrFilters.oems.length > 0)
        oDataFilter.push({ MrPracticalTemplateDetails: { OemId: { in: { type: 'guid', value: filters.mrFilters.oems } } } });

      if (filters.mrFilters.machineModels.length > 0)
        oDataFilter.push({
          MrPracticalTemplateDetails: { MachineModelId: { in: { type: 'guid', value: filters.mrFilters.machineModels } } }
        });
    }

    if (!filters.includeInactives) {
      oDataFilter.push({ isActive: true });
    }

    if ((filters.eventTypeIds || []).length !== 0) {
      oDataFilter.push({
        EventTypes: {
          any: {
            OriginalId: { in: { type: 'guid', value: filters.eventTypeIds } }
          }
        }
      });
    }

    query.filter = oDataFilter;

    return query;
  };

  private handleFilterChange = (property: keyof TemplatesTabPaneFilters, value: any) => {
    const filters = { ...this.state.filters };
    filters[property as string] = value;

    this.setState({ filters: filters }, this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleOnEditItem = (selectedItem: PracticalTemplateItemDto) => {
    if (selectedItem == null || isNullOrWhiteSpaces(selectedItem.id)) return;
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/practical/template/' + selectedItem.id, state: selectedItem });
  };

  private hideModal = () => {
    this.setState({ selectedItem: null, showModal: 'None' });
  };

  private onCloneItem = async () => {
    this.hideModal();
    this.setState({ loading: true });
    const { selectedItem } = this.state;

    this.props.newPracticalTemplatesStore.createNew({
      header: selectedItem.header,
      isActive: selectedItem.isActive,
      isMachineRelated: selectedItem.isMachineRelated,
      title: selectedItem.title,
      trainingLevelId: selectedItem.trainingLevelId,
      professionId: selectedItem.professionId,
      nmrPracticalTemplateDetails: selectedItem.nmrPracticalTemplateDetails && {
        nmrClusterId: selectedItem.nmrPracticalTemplateDetails.nmrClusterId,
        trainingNameId: selectedItem.nmrPracticalTemplateDetails.trainingNameId,
        nmrPracticalQuestions: (selectedItem.nmrPracticalTemplateDetails.nmrPracticalQuestions || []).map(x => x.id),
        functionalAreaId: selectedItem.nmrPracticalTemplateDetails.functionalAreaId,
        functionalSubareaId: selectedItem.nmrPracticalTemplateDetails.functionalSubareaId
      },
      mrPracticalTemplateDetails: selectedItem.mrPracticalTemplateDetails && {
        clusterId: selectedItem.mrPracticalTemplateDetails.clusterId,
        equipmentTypeId: selectedItem.mrPracticalTemplateDetails.equipmentTypeId,
        machineModelsId: selectedItem.mrPracticalTemplateDetails.machineModelsId,
        oemId: selectedItem.mrPracticalTemplateDetails.oemId,
        mrPracticalQuestions: (selectedItem.mrPracticalTemplateDetails.mrPracticalQuestions || []).map(x => x.id)
      },
      eventTypes: selectedItem.eventTypes.map(x => x.originalId)
    });

    try {
      await this.props.newPracticalTemplatesStore.submit();
      this.setState({ selectedItem: null, showModal: 'None', loading: false });
      this.load();
    } catch (error) {
      console.error({ error });
    }
  };

  private onToggleAvailabilityItem = async () => {
    const { selectedItem } = this.state;
    this.setState({ selectedItem: null, showModal: 'None', loading: true });

    this.props.changePracticalTemplateStore.createNew({
      id: selectedItem.id,
      header: selectedItem.header,
      isActive: !selectedItem.isActive,
      title: selectedItem.title,
      trainingLevelId: selectedItem.trainingLevelId,
      nmrPracticalTemplateDetails: selectedItem.nmrPracticalTemplateDetails && {
        nmrClusterId: selectedItem.nmrPracticalTemplateDetails.nmrClusterId,
        trainingNameId: selectedItem.nmrPracticalTemplateDetails.trainingNameId,
        nmrPracticalQuestions: (selectedItem.nmrPracticalTemplateDetails.nmrPracticalQuestions || []).map(x => x.id),
        functionalAreaId: selectedItem.nmrPracticalTemplateDetails.functionalAreaId,
        functionalSubareaId: selectedItem.nmrPracticalTemplateDetails.functionalSubareaId
      },
      mrPracticalTemplateDetails: selectedItem.mrPracticalTemplateDetails && {
        clusterId: selectedItem.mrPracticalTemplateDetails.clusterId,
        equipmentTypeId: selectedItem.mrPracticalTemplateDetails.equipmentTypeId,
        machineModelsId: selectedItem.mrPracticalTemplateDetails.machineModelsId,
        oemId: selectedItem.mrPracticalTemplateDetails.oemId,
        mrPracticalQuestions: (selectedItem.mrPracticalTemplateDetails.mrPracticalQuestions || []).map(x => x.id)
      },
      eventTypes: selectedItem.eventTypes.map(x => x.originalId)
    });

    try {
      await this.props.changePracticalTemplateStore.update();
      this.setState({ loading: false });
      this.load();
    } catch (error) {
      console.error({ error });
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      ({ query }) => ({
        query: { ...query, orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }] }
      }),
      this.load
    );
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  render() {
    const { t, practicalTemplatesStore: store } = this.props;
    const { filters, someFilterOpened } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.practicalTemplatesStore.state
    } as TableModel<PracticalTemplateItemDto>;

    return (
      <div id="practical-templates-list-wrapper" className="question-bank__wrapper">
        <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Grid className="event-types-list-grid">
          {messages.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={messages.map(o => o.body)}
                onDismiss={() => store.clearMessages()}
              />
            </Grid.Row>
          )}

          <div className="tna__inputs-wrapper">
            <ClearableTimerInput
              icon="search"
              className="custom-editor-search ensure-200px-width"
              value={filters.templateId}
              placeholder={t('Search in Template or ID')}
              onChange={(_, { value }) => this.handleFilterChange('templateId', value)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <ProfessionEditor
              clearable
              nullable
              className="custom-editor ensure-175px-width adjust-filter-height"
              placeholder={t('Role')}
              value={filters.role}
              onChange={value => this.handleFilterChange('role', value)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <BooleanEditor
              clearable
              className="practical-boolean-editor ensure-175px-width"
              placeholder={t('Machine Related')}
              value={filters.machineRelated}
              onChange={value => this.handleFilterChange('machineRelated', value)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <MultiEventTypeEditor
              id="practical-template-list-event-types-filter"
              value={filters.eventTypeIds}
              onChange={value => this.handleFilterChange('eventTypeIds', value)}
              placeholder={t('Event Types')}
              useOriginalEventTypeIdAsValue
              className="ensure-200px-min-width adjust-filter-height"
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <TrainingLevelEditor
              value={null}
              onChange={value => this.handleFilterChange('trainingLevel', value)}
              placeholder={t('Training Level')}
              searchable
              clearable
              nullable
              className="ensure-175px-width custom-editor"
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
            <MultiSelectorMRMachineryFilters
              placeholder={t('Filter Machinery')}
              className="question-bank__multiple-dropdown ensure-200px-min-width"
              onChange={value => this.handleFilterChange('mrFilters', value)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiSelectorNMRFilters
              placeholder={t('Filter Relation')}
              className="question-bank__multiple-dropdown ensure-200px-min-width"
              onChange={value => this.handleFilterChange('nmrFilters', value)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={this.state.showModal === 'None'}
              //maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraMenu={[
                {
                  index: 0,
                  className: '',
                  content: (
                    <Button
                      onClick={() => this.handleFilterChange('includeInactives', !this.state.filters.includeInactives)}
                      className="table__custom-white-outline-button"
                    >
                      {this.state.filters.includeInactives ? t('Hide Inactive templates') : t('Show Inactive templates')}
                    </Button>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit')}</span>}
                    </>
                  ),
                  onClick: this.handleOnEditItem
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      {<span className="text__bold">{t('Clone')}</span>}
                    </>
                  ),
                  onClick: (item: PracticalTemplateItemDto) => {
                    this.setState({ selectedItem: item, showModal: 'CloneModal' });
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="toggle off" color="red" />
                      <span className="text__bold text__red">{t('Deactivate')}</span>
                    </>
                  ),
                  isVisible: (item: PracticalTemplateItemDto) => item.isActive,
                  onClick: (item: PracticalTemplateItemDto) => {
                    this.setState({ selectedItem: item, showModal: 'DisableModal' });
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="toggle on" />
                      <span className="text__bold">{t('Activate')}</span>
                    </>
                  ),
                  isVisible: (item: PracticalTemplateItemDto) => !item.isActive,
                  onClick: (item: PracticalTemplateItemDto) => {
                    this.setState({ selectedItem: item, showModal: 'DisableModal' });
                  }
                }
              ]}
              createButtonIcon={{ hasIcon: false }}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              canCreateNew={true}
              createNewButtonTitle={t('New Practical Template')}
              isRowDisableLayout={item => !(item as PracticalTemplateItemDto).isActive}
            />
          </Grid.Row>
        </Grid>

        {this.state.showModal === 'DisableModal' && (
          <Modal open size="tiny" className="change-status-popup" closeOnEscape={true} onClose={this.hideModal}>
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${this.state.selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.hideModal} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}

        {this.state.showModal === 'CloneModal' && (
          <Modal open size="tiny" className="change-status-popup" closeOnEscape={true} onClose={this.hideModal}>
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to clone this template with id ${this.state.selectedItem.friendlyId}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.hideModal} />
              <Button className="change-status-popup__btn change-status-popup__pending-btn" content={t('Yes')} onClick={this.onCloneItem} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(PracticalTemplatesList);
