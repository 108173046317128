import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Button } from 'semantic-ui-react';
import './have-warnings-popup.less';

export interface HaveWarningsPopupProps extends WithTranslation {
  open: boolean;
  onCancelClicked?: () => void;
  onAcceptClicked?: () => void;
}

class HaveWarningsPopup extends React.Component<HaveWarningsPopupProps> {
  public render() {
    const { t } = this.props as any;

    return (
      <Modal open={this.props.open} size="tiny" className="have-warnings-popup" closeOnEscape={true} onClose={this.props.onCancelClicked}>
        <Modal.Content className="have-warnings-popup__content">
          <p>{t("You are going to skip Instructor's event warnings and save avoiding this validation.")}</p>
          <p>{t('Are you sure you want to continue?')}</p>
        </Modal.Content>
        <div className="have-warnings-popup__buttons-container">
          <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.props.onCancelClicked} />
          <Button
            className="have-warnings-popup__btn have-warnings-popup__pending-btn"
            content={t('Yes')}
            onClick={this.props.onAcceptClicked}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(HaveWarningsPopup);
