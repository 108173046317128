import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import i18n from '../../../i18n';

import UserImportView from './user-import/user-import-view';
import ImportLogsListPage from './user-import/imporlogs-list';
import EmployeeTrackLogsPage from './employee-track-log/employee-track-logs-page';

export interface ImportExportHomeProps extends WithTranslation, RouteComponentProps {}

export interface ImportExportHomeState {}

class ImportExportHomePage extends React.Component<ImportExportHomeProps, ImportExportHomeState> {
  constructor(props: ImportExportHomeProps) {
    super(props);
    this.state = {};
  }
  private panes = [
    {
      menuItem: i18n.t('User Imports'),
      render: () => (
        <Tab.Pane attached={false}>
          <UserImportView />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Import Logs'),
      render: () => (
        <Tab.Pane attached={false}>
          <div>
            <ImportLogsListPage />
          </div>
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Employee Track Logs'),
      render: () => (
        <Tab.Pane attached={false}>
          <div>
            <EmployeeTrackLogsPage />
          </div>
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Import/Export Log')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(ImportExportHomePage);
