import React, { FC, memo, useState } from 'react';

import { ItemReference } from '../../../../stores/dataStore';
import EquipmentTypeEditor from '../../../../widgets/bussiness/equipment-type-editor';
import OemEditor from '../../../../widgets/bussiness/oem-editor';
import MachineModelEditor from '../../../../widgets/bussiness/machine-model-editor';
import MultiMachineUnitEditor from '../../../../widgets/bussiness/multi-machine-unit-editor';
import MRClusterEditor from '../../../../widgets/bussiness/mrcluster-editor';
import MultiPlcTypeEditor from '../../../../widgets/bussiness/multi-plc-type-editor';
import { Icon } from 'semantic-ui-react';
import { PatternRowDto } from 'stores/configuration/machinery/pattern-rows-store';

export interface MachineListItemViewModel {
  oem: ItemReference;
  equipmentType: ItemReference;
  machineModel: ItemReference;
  cluster: ItemReference;
  machineUnits: string[];
  plcTypes: string[];
  machineUnitItems?: ItemReference[];
  plcTypeItems?: ItemReference[];
}

interface MachineListItemProps {
  onEditMode?: boolean;
  canDelete?: boolean;
  machine: MachineListItemViewModel;
  pattern?: PatternRowDto;

  onChange?: (machine: MachineListItemViewModel) => void;
  onDeleteClose?: () => void;
}

const MachineListItem: FC<MachineListItemProps> = ({ onEditMode, canDelete, pattern, machine, onChange, onDeleteClose }) => {
  const { cluster, equipmentType, oem, machineModel, machineUnits, plcTypes, machineUnitItems, plcTypeItems } = machine;
  const [relatedClusterIds, setRelatedClusterIds] = useState([]);
  const [relatedEqIds, setRelatedEqIds] = useState([]);
  const handleOnChange = (property: keyof MachineListItemViewModel, value: any) => {
    const newMachine = { ...machine };

    newMachine[property] = value;
    if (property === 'cluster' && value?.id !== cluster?.id) {
      // setRelatedClusterIds(value?.relatedClusterIds || []);
      // setRelatedEqIds(value?.relatedEqIds || []);
      newMachine.equipmentType = null;
      newMachine.oem = oem ? (value == null ? null : oem) : null;
      newMachine.machineModel = null;
      newMachine.machineUnits = null;
      newMachine.plcTypes = null;
    }
    if (property === 'equipmentType' && value?.id !== equipmentType?.id) {
      setRelatedClusterIds([]);
      setRelatedEqIds([]);
      newMachine.cluster = value?.cluster;
      newMachine.oem = oem ? (value == null ? null : oem) : null;
      newMachine.machineModel = null;
      newMachine.machineUnits = null;
      newMachine.plcTypes = null;
    }
    if (property === 'oem' && value?.id !== oem?.id) {
      setRelatedClusterIds(value?.relatedClusterIds || []);
      setRelatedEqIds(value?.relatedEqIds || []);
      if (value == null || newMachine.cluster == null || newMachine.equipmentType == null) {
        newMachine.cluster = value?.cluster;
        newMachine.equipmentType = value?.equipmentType;
      }
      newMachine.machineUnits = null;
      newMachine.machineModel = null;
      newMachine.plcTypes = null;
    }
    if (property === 'machineModel' && value?.id !== machineModel?.id) {
      setRelatedClusterIds([]);
      setRelatedEqIds([]);
      newMachine.cluster = value?.cluster;
      newMachine.equipmentType = value?.equipmentType;
      newMachine.oem = value?.oem;
      newMachine.plcTypes = null;
      newMachine.machineUnits = null;
    }

    onChange(newMachine);
  };

  const clusterBlocked = pattern && !!pattern.machineRelatedClusterId;
  const equipmentTypeBlocked = pattern && !!pattern.equipmentTypeId;
  const oemBlocked = pattern && !!pattern.oemId;
  const machineModelBlocked = pattern && !!pattern.machineModelId;

  return (
    <>
      <div className="border-bottom-gray">
        <MRClusterEditor
          nullable
          reloadOnChange
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          readOnly={!onEditMode || clusterBlocked}
          value={cluster || null}
          onChange={value => handleOnChange('cluster', value)}
          loadDataOnOpen
          oemId={oem?.id || null}
          relatedClusterIds={relatedClusterIds || []}
        />
      </div>

      <div className="border-bottom-gray">
        <EquipmentTypeEditor
          nullable
          clearOnReload
          reloadOnChange
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          readOnly={!onEditMode || equipmentTypeBlocked}
          clusterId={cluster?.id || null}
          value={equipmentType}
          onChange={eqType => handleOnChange('equipmentType', eqType)}
          loadDataOnOpen
          oemId={oem?.id || null}
          relatedEquipmentTypeIds={relatedEqIds || []}
        />
      </div>

      <div className="border-bottom-gray">
        <OemEditor
          nullable
          clearOnReload
          reloadOnChange
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          readonly={!onEditMode || oemBlocked}
          equipmentId={equipmentType?.id || null}
          value={oem}
          onChange={o => handleOnChange('oem', o)}
          loadDataOnOpen
          clusterId={cluster?.id || null}
        />
      </div>

      <div className="border-bottom-gray">
        <MachineModelEditor
          nullable
          reloadOnChange
          clearOnReload
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          readonly={!onEditMode || machineModelBlocked}
          oemId={oem?.id || null}
          equipmentId={equipmentType?.id || null}
          value={machineModel}
          onChange={mm => handleOnChange('machineModel', mm)}
          loadDataOnOpen
          clusterId={cluster?.id || null}
        />
      </div>

      <div className="border-bottom-gray">
        <MultiMachineUnitEditor
          placeholder={'Select Machine Unit'}
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          value={machineUnits}
          oemId={oem?.id || null}
          multiple={true}
          readOnly={!onEditMode}
          equipmentId={equipmentType?.id || null}
          onChange={mu => handleOnChange('machineUnits', mu)}
          machineUnitOptions={machineUnitItems}
          loadDataOnOpen
        />
      </div>

      <div className="border-bottom-gray">
        <MultiPlcTypeEditor
          placeholder={'Select PLC type'}
          className={onEditMode ? 'form__box-content' : 'mr-table__dropdown'}
          value={plcTypes}
          readOnly={!onEditMode}
          oemId={oem?.id || null}
          equipmentId={equipmentType?.id || null}
          onChange={pt => handleOnChange('plcTypes', pt)}
          multiple={true}
          plcTypeOptions={plcTypeItems}
        />
      </div>
      <div className="event-details-tab__mr-table__remove border-bottom-gray">
        {onEditMode && canDelete && <Icon color="red" name="close" link onClick={onDeleteClose} />}
      </div>
    </>
  );
};
MachineListItem.defaultProps = { onChange: () => {}, onDeleteClose: () => {} };

export default memo(MachineListItem);
