import * as Axios from 'axios';
import { injectable } from 'inversify';
import { stringify } from 'query-string';
import * as ContentDisposition from 'content-disposition';
import * as FileSaver from 'file-saver';
import { isNullOrWhiteSpaces } from '../utils/useful-functions';
import { constants } from './configuration-service';
import { IdentityService } from './identity-service';
import { container } from '../inversify.config';

@injectable()
export default class HttpService {
  protected static timeout = 60000 * 10;

  private http: Axios.AxiosInstance = null;

  private identityService: IdentityService;

  constructor() {
    this.http = Axios.default.create({
      baseURL: constants.apiBaseUrl,
      timeout: HttpService.timeout
    });

    this.identityService = container.get(IdentityService);
  }

  public async get<TResponse>(url: string, data?: {}, timeout?: number): Promise<Axios.AxiosResponse<TResponse>> {
    try {
      const params = { rnd: this.makernd(), webrole: this.identityService.activeRole };
      let body = null;
      if (data) {
        for (const prop of Object.getOwnPropertyNames(data)) {
          if (prop === 'oDataQueryString') {
            body = { oDataQueryString: data[prop] };
            delete data[prop];
            continue;
          }
          const type = typeof data[prop];
          if (Array.isArray(data[prop]) || (type !== 'function' && type !== 'object')) {
            params[prop] = data[prop];
          }
        }
      }
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);
      let config = await this.getConfig(data);
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }
      if (body) {
        const postResponse = this.http.post(url, body, config);
        return await postResponse;
      }
      const getResponse = this.http.get(url, config);
      return await getResponse;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  public async post<TRequest, TResponse>(url: string, data: TRequest, timeout?: number): Promise<Axios.AxiosResponse<TResponse>> {
    try {
      let config = (await this.getConfig()) as any;
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }

      const params = { webrole: this.identityService.activeRole };
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);

      const response = this.http.post(url, data, config);
      return await response;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  public async patch<TRequest, TResponse>(url: string, data: TRequest, timeout?: number): Promise<Axios.AxiosResponse<TResponse>> {
    try {
      let config = (await this.getConfig()) as any;
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }

      const params = { webrole: this.identityService.activeRole };
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);
      const response = this.http.patch(url, data, config);
      return await response;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  public async put<TRequest, TResponse>(url: string, data: TRequest, timeout?: number): Promise<Axios.AxiosResponse<TResponse>> {
    try {
      let config = (await this.getConfig()) as any;
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }

      const params = { webrole: this.identityService.activeRole };
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);
      const response = this.http.put(url, data, config);
      return await response;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  public async delete<TRequest, TResponse>(url: string, data?: TRequest, timeout?: number): Promise<Axios.AxiosResponse<TResponse>> {
    try {
      let config = (await this.getConfig()) as any;
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }

      const params = { webrole: this.identityService.activeRole };
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);
      const response = this.http.delete(url, data ? { ...config, data } : { ...config });
      return await response;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  public async download(url: string, filename?: string, timeout?: number, data?: {}): Promise<Axios.AxiosResponse> {
    try {
      const extra = data || {};
      const params = { rnd: this.makernd(), webrole: this.identityService.activeRole, ...extra };
      url += (url.search(/\?/) !== -1 ? '&' : '?') + stringify(params);
      let config = (await this.getConfig()) as any;
      if (timeout != null && timeout > HttpService.timeout) {
        config = { ...config, timeout };
      }
      const response = this.http.get(url, { ...config, responseType: 'blob' });
      const blobResponse = await response;
      if (blobResponse == null || blobResponse.data == null) {
        return Promise.reject({ message: 'Error downloading the file' });
      }
      if (blobResponse.data.size === 0 || blobResponse.status === 204) {
        return Promise.reject({ message: 'There is no data' });
      }

      let fileName = filename;

      if (isNullOrWhiteSpaces(fileName) && blobResponse.headers['content-disposition']) {
        const contentDisposition = ContentDisposition.parse(blobResponse.headers['content-disposition']);
        if (contentDisposition && contentDisposition.parameters && contentDisposition.parameters.filename) {
          fileName = contentDisposition.parameters.filename;
        }
      }

      if (isNullOrWhiteSpaces(fileName) && blobResponse.headers['x-filename']) {
        fileName = decodeURIComponent(blobResponse.headers['x-filename']);
      }

      if (isNullOrWhiteSpaces(fileName)) {
        return Promise.reject({ message: 'No filename' });
      }

      FileSaver.saveAs(blobResponse.data, fileName);

      return blobResponse;
    } catch (reason) {
      return Promise.reject(this.handleError(reason));
    }
  }

  protected handleError(error: Axios.AxiosError, redirect?: string) {
    let msg;
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          msg = { message: 'Access is denied. Redirecting to login in 3s' };
          setTimeout(() => {
            this.identityService.signinRedirect(redirect);
          }, 3000);
          break;
        case 400:
          if (error.response.data.error && error.response.data.error === 'invalid_grant') {
            msg = { message: 'Access is denied. Redirecting to login in 3s' };
            setTimeout(() => {
              this.identityService.signinRedirect();
            }, 3000);
          } else {
            msg = error;
          }
          break;
        default:
          msg = error.response.data.error ? error.response.data.error : error;
          break;
      }
    } else {
      msg = error;
    }

    return msg;
  }

  private async getConfig(data?: {}) {
    await this.identityService.loadUser();
    if (!this.identityService.isAuthenticated()) {
      return data || {};
    }
    const config = {
      // withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.identityService.accessToken}`
      }
    };
    return data
      ? {
          ...data,
          ...config
        }
      : config;
  }

  private makernd() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 16; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
