import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Tab } from 'semantic-ui-react';
import './employee-page.less';
import './background-employee.less';
import i18n from 'i18n';
import { UserProfilesStore, UserProfileDto } from 'stores/profile/user-profile-store';
import ProfileListingTabContent from './profile-listing-tab-content';

export interface ProfileListingComponentProps extends WithTranslation, RouteComponentProps {
  userId: string;
  currentUserProfilesStore?: UserProfilesStore;
}

export interface ProfileListingComponentState {
  tnaUserProfiles: UserProfileDto[];
  ssaUserProfiles: UserProfileDto[];
  iwsUserProfiles: UserProfileDto[];
}

@connect(['currentUserProfilesStore', UserProfilesStore])
class ProfileListingComponent extends Component<ProfileListingComponentProps, ProfileListingComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      tnaUserProfiles: [],
      ssaUserProfiles: [],
      iwsUserProfiles: []
    };
    this.getEmployee();
  }

  @autobind
  private async getEmployee() {
    const currentUserProfileData = await this.props.currentUserProfilesStore.getUserProfileById(this.props.userId, true);

    const tnaUserProfiles = currentUserProfileData.profiles.filter(filterProfile => filterProfile.assessment.toString() === 'TNA');
    const ssaUserProfiles = currentUserProfileData.profiles.filter(filterProfile => filterProfile.assessment.toString() === 'SSA');
    const iwsUserProfiles = currentUserProfileData.profiles.filter(filterProfile => filterProfile.assessment.toString() === 'IWS');

    this.setState({ tnaUserProfiles, ssaUserProfiles, iwsUserProfiles });
  }
  private getPanes = t => {
    const panes = [];
    if (this.state.tnaUserProfiles.length > 0)
      panes.push({
        menuItem: i18n.t('TNA'),
        render: () => (
          <Tab.Pane attached={false} key={'tna-pane'}>
            <ProfileListingTabContent {...this.props} selectFirstAvailableByDefault userProfiles={this.state.tnaUserProfiles} />
          </Tab.Pane>
        )
      });

    if (this.state.ssaUserProfiles.length > 0)
      panes.push({
        menuItem: i18n.t('SSA'),
        render: () => (
          <Tab.Pane attached={false} key={'ssa-pane'}>
            <ProfileListingTabContent {...this.props} selectFirstAvailableByDefault userProfiles={this.state.ssaUserProfiles} />
          </Tab.Pane>
        )
      });
    if (this.state.iwsUserProfiles.length > 0)
      panes.push({
        menuItem: i18n.t('IWS'),
        render: () => (
          <Tab.Pane attached={false} key={'iws-pane'}>
            <ProfileListingTabContent {...this.props} selectFirstAvailableByDefault userProfiles={this.state.iwsUserProfiles} />
          </Tab.Pane>
        )
      });

    return panes;
  };

  public render() {
    const { t } = this.props as any;

    return (
      <>
        <div className="flex-between employee__profile-list-chart">
          <Tab className="employee__tab" menu={{ secondary: true, pointing: true }} panes={this.getPanes(t)} />
        </div>
      </>
    );
  }
}
export default withTranslation()(ProfileListingComponent);
