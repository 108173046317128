import React, { useState, FC, useContext, memo } from 'react';
import { TnaFormItemDto, TnaFormAnswersDto, MachineModelAnswersDto, TnaFormListStore } from 'stores/assessments/forms/tna-forms-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tab, Modal, Button, Divider, Accordion } from 'semantic-ui-react';
import TnaFormTabComponent from './tna-form-tab.component';
import './tna-edit-form-main-styles.less';
import { TheoreticalRel, TNAFormContext } from './tna-edit-form';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import { ApplicationState } from 'stores/reducers';
import { useSelector } from 'react-redux';

interface TNAFormProps extends WithTranslation {
  theoreticals: TheoreticalRel[];
  onExportExcel?: () => void;
  tnaFormListStore?: TnaFormListStore;
}

const TNAFormComponent: FC<TNAFormProps> = ({ t, theoreticals, onExportExcel, tnaFormListStore }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(true);
  const [showScores, setShowScores] = useState<boolean>(false);
  const [instructionsAccordionOpen, setInstructionsAccordionOpen] = useState<boolean>(true);
  const status = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => (summary.item as TnaFormItemDto).status) as string;

  const answers = useSelector<ApplicationState>(
    ({ tnaformlist: { summary } }) => (summary?.item as TnaFormItemDto)?.answers
  ) as TnaFormAnswersDto;
  const language = useSelector<ApplicationState>(
    ({ tnaformlist: { summary } }) => (summary?.item as TnaFormItemDto)?.currentLanguage
  ) as string;

  const formItem = useSelector<ApplicationState>(({ tnaformlist: { summary } }) => summary?.item as TnaFormItemDto) as TnaFormItemDto;

  const header = useSelector<ApplicationState>(
    ({ tnaformlist: { summary } }) => (summary?.item as TnaFormItemDto)?.tnaAssessment?.tnaTemplate?.header
  ) as string;
  const { getAssessorType, getAStatus, submittable, ...contextValues } = useContext(TNAFormContext);
  const { isAdminOrPoC, isAssessor, isEmployee } = contextValues;

  const assessorType = getAssessorType();
  const assessorAnswerStatus = getAStatus();

  const getMachinesModelAnsweredFromSameTemplate = (machineModelAnswers: MachineModelAnswersDto[], item: MachineModelAnswersDto) => {
    const assessorType = getAssessorType();
    const { type } = assessorType;
    let currentUserEdit = 'employeeAnswer';
    if (type === 'functionalExpert') currentUserEdit = 'functionalExpertAnswer';
    if (type === 'lineManager') currentUserEdit = 'lineManagerAnswer';

    return (machineModelAnswers || []).filter(
      x =>
        x.machineModelId !== item.machineModelId &&
        item.tnaTemplateId &&
        x.tnaTemplateId &&
        item.tnaTemplateId === x.tnaTemplateId &&
        (x.machineUnitAnswers || []).any(y =>
          (y.skillAnswers || []).any(z => (z.questionAnswers || []).any(w => w[currentUserEdit]?.answer >= 0))
        )
    );
  };

  const mapToGeneralObj = ({ machineModelAnswers, nonRelatedMachineSkillAnswers }: TnaFormAnswersDto, currentLanguage: string) => {
    const { type, mrSkills, nmrFunctionalExpertsSkills } = assessorType;

    const panes = [];

    if ((nonRelatedMachineSkillAnswers || []).length) {
      let appropriateNMRSkills = nonRelatedMachineSkillAnswers;
      if (nmrFunctionalExpertsSkills)
        appropriateNMRSkills = nonRelatedMachineSkillAnswers.filter(
          ({ skillId }) => nmrFunctionalExpertsSkills.skills.find(s => s.id === skillId) != null
        );
      const nonMachineRelatedSection = isAdminOrPoC ? nonRelatedMachineSkillAnswers : appropriateNMRSkills;
      if (isAdminOrPoC || nmrFunctionalExpertsSkills || type !== 'functionalExpert')
        panes.push({
          menuItem: t('General'),
          render: () => (
            <TnaFormTabComponent
              scoresVisibility={showScores}
              nonMachineRelatedSection={nonMachineRelatedSection}
              onExportExcel={onExportExcel}
              tnaFormListStore={tnaFormListStore}
            />
          )
        });
    }

    (machineModelAnswers || []).forEach(item => {
      if (
        formItem.tnaAssessment.type === 'MultipleMachineHierarchy' &&
        assessorType.type === 'functionalExpert' &&
        assessorType.mrSkills &&
        !isAdminOrPoC &&
        !formItem.machineFunctionalExperts.any(
          x => x.userId === assessorType.userId && x.machineModels.any(mm => mm.id === item.machineModelId)
        )
      )
        return;

      const theos = (theoreticals || []).find(({ id }) => id === item.machineModelId)?.theoreticalTests;

      (isAdminOrPoC || mrSkills || type !== 'functionalExpert') &&
        panes.push({
          menuItem: t(item.machineModel),
          render: () => (
            <>
              <TnaFormTabComponent
                {...item}
                otherMachinesModelAnswers={getMachinesModelAnsweredFromSameTemplate(machineModelAnswers, item)}
                scoresVisibility={showScores}
                theoreticals={theos || []}
                machineRelated
                onExportExcel={onExportExcel}
                tnaFormListStore={tnaFormListStore}
              />
            </>
          )
        });
    });

    return panes;
  };

  const handleTabChange = (_, { activeIndex }) => {
    const { nonRelatedMachineSkillAnswers: nmrMSA } = answers;
    if (isEmployee && !isAdminOrPoC && !isAssessor && (nmrMSA || []).length) {
      const nmrIsValid = nmrMSA.every(({ questionAnswers }) =>
        questionAnswers.every(({ employeeAnswer }) => employeeAnswer?.answer != null)
      );
      if (!nmrIsValid) {
        const text = t('You must fill all general questions in order to complete the other machinery tabs');
        ToastComponent({ type: 'information-toast', text });
        return;
      } else setActiveIndex(activeIndex);
    } else setActiveIndex(activeIndex);
  };

  const acceptConfirmationModal = () => {
    setShowConfirmationModal(false);
    setShowScores(true);
  };

  const { type } = getAssessorType();
  const panes = mapToGeneralObj(answers, language);
  const pending = assessorAnswerStatus?.answerStatus === 'PendingRecommendation';
  const isFE = type === 'functionalExpert';
  const showModal = submittable() && status !== 'Done' && pending && isFE && showConfirmationModal;

  const toggleAccordion = () => {
    setInstructionsAccordionOpen(!instructionsAccordionOpen);
  };

  return (
    <>
      <div>
        {header && (
          <>
            <div className="header-instructions-accordion-spacer ">
              <Accordion className="tna-header-accordion">
                <Accordion.Title active={instructionsAccordionOpen} onClick={toggleAccordion} className="tna-header-accordion__title" />
                <Accordion.Content active={instructionsAccordionOpen}>
                  <div className="tna-edit-form__header__evaluation-criteria">
                    <pre style={{ fontFamily: 'inherit' }}>{header}</pre>
                  </div>
                </Accordion.Content>
              </Accordion>
            </div>
            <Divider />
          </>
        )}

        {panes?.length > 0 && (
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
            className="tna-form-main-tabs"
            panes={panes}
          />
        )}
      </div>

      {showModal && (
        <Modal
          /*closeOnEscape={false}*/ closeOnEscape={true}
          onClose={acceptConfirmationModal}
          open={showConfirmationModal}
          size="mini"
          className="tna-confirmation-modal"
        >
          <Modal.Content className="tna-confirmation-modal__content">
            {<p>{t('You have completed the evaluation successfully. Please, now add your recommendations for this Employee.')}</p>}
          </Modal.Content>
          <Modal.Actions>
            <Button size="tiny" className="tna-confirmation-modal__btn" content={t('Accept')} onClick={acceptConfirmationModal} />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default withTranslation()(memo(TNAFormComponent));
