import React, { FC } from 'react';
import { InputProps, InputOnChangeData } from 'semantic-ui-react';
import { ClearableInput } from './clearable-input';

export interface TimerInputProps extends InputProps {
  ms?: number;
}

let clearableTimerInputTimeoutId: NodeJS.Timeout = null;

export const ClearableTimerInput: FC<TimerInputProps> = (props: TimerInputProps) => {
  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    clearTimeout(clearableTimerInputTimeoutId);
    if (data.value == null || data.value === '') {
      if (props.onChange) props.onChange(event, data);
    } else {
      clearableTimerInputTimeoutId = setTimeout(() => {
        if (props.onChange) props.onChange(event, data);
      }, props.ms || 1000);
    }
  };

  const onKeyPressWrapper = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget != null) {
      clearTimeout(clearableTimerInputTimeoutId);
      if (props.onChange) props.onChange(event as any, { ...props, value: event.currentTarget.value });
    } else if (props.onKeyPress) props.onKeyPress(event);
  };

  return <ClearableInput {...props} onChange={onChangeWrapper} onKeyPress={onKeyPressWrapper} />;
};
