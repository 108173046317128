import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import { CreateCheckListDto, NewCheckListStore } from 'stores/configuration/events-n-requests/checklists-store';
import { nameof } from 'utils/object';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import { ItemReference } from 'stores/dataStore';

interface NewCheckListViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newCheckList?: NewCheckListStore;
}

interface NewCheckListViewState {
  eventType: ItemReference;
  userClickedSaved: boolean;
}

@connect(['newCheckList', NewCheckListStore])
class NewCheckListView extends React.Component<NewCheckListViewProps, NewCheckListViewState> {
  private get newCheckListStore() {
    return this.props.newCheckList;
  }

  constructor(props: NewCheckListViewProps) {
    super(props);
    this.newCheckListStore.createNew({
      description: '',
      eventTypeId: null,
      rolesThatCreateCheckLists: []
    });
    this.state = {
      eventType: null,
      userClickedSaved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewCheckListViewProps) {
    if (this.newCheckListStore.state.result && this.newCheckListStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newCheckListStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newCheckListStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;

    this.newCheckListStore.change({ ...this.newCheckListStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;
    const { result } = this.newCheckListStore.state;

    const roles = [
      { name: 'Employee', description: 'Employee' },
      { name: 'Planner', description: 'Planner TFT' },
      { name: 'PoC', description: 'PoC' },
      { name: 'Instructor', description: 'Instructor' },
      { name: 'PlannerMTC', description: 'Planner MTC' }
    ];

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newCheckListStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Check List')}</Modal.Header>
        <Modal.Content image>
          <Container className="configuration-event-n-request__modal">
            {this.newCheckListStore.state.result && !this.newCheckListStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newCheckListStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newCheckListStore.state.item && (
              <Form>
                <div className="configuration-event-n-request__modal__element">
                  <Form.Input
                    required
                    fluid
                    error={!this.newCheckListStore.state.item.description && this.state.userClickedSaved}
                    label={t('Description')}
                    placeholder={t('Description')}
                    value={this.newCheckListStore.state.item.description}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateCheckListDto>('description'), value)}
                  />
                </div>

                <div className={`required field inline configuration-event-n-request__modal__element`}>
                  <label>{t('Event Type')}</label>
                  <EventTypeEditor
                    error={!this.newCheckListStore.state.item.eventTypeId && this.state.userClickedSaved}
                    placeholder={t('Event Type')}
                    nullable
                    value={this.state.eventType}
                    onChange={c => {
                      this.newCheckListStore.state.item.eventTypeId = c.id;
                      this.setState({ userClickedSaved: false });
                    }}
                    directOnly={false}
                  />
                </div>

                <div className={`required field inline configuration-event-n-request__modal__element`}>
                  <label>{t('Roles')}</label>
                  <Dropdown
                    placeholder={t('Roles')}
                    search
                    selection
                    multiple
                    closeOnChange
                    closeOnBlur
                    closeOnEscape
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      this.state.userClickedSaved &&
                      (result.messages || []).any(x => x.propertyName === nameof<CreateCheckListDto>('rolesThatCreateCheckLists'))
                    }
                    options={roles.map(x => ({
                      key: `rolesThatCreateCheckLists-${x.name}`,
                      text: x.description,
                      value: x.name,
                      content: x.description
                    }))}
                    value={this.newCheckListStore.state.item.rolesThatCreateCheckLists}
                    onChange={(e, { value }) => this.handleValue(nameof<CreateCheckListDto>('rolesThatCreateCheckLists'), value)}
                  />
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewCheckListView);
