import * as autobind from 'autobind';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, List, Icon, Grid } from 'semantic-ui-react';

import { ItemState, OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { InstructorDto, InstructorStore } from 'stores/instructors/instructors-store';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { LocationDto, DropDownLocationsStore } from 'stores/configuration/locations/locations-store';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DropdownLanguagesStore, LanguageDto } from 'stores/configuration/locations/languages-store';
import NewInstructorAssignmentView from '../users/instructors-assignment/new-instructor-assignment';
import { PillarDto, DropDownPillarsStore } from 'stores/configuration/profiles/pillars-store';
import ChangeInstructorAssignmentView from './instructors-assignment/edit-instructor-assignment';
import { InstructorRolesStore, InstructorRoleDto } from 'stores/instructor-roles/instructor-roles-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import InstructorHistoryView from './instructor-history/history-list';
import { UserDto } from 'stores/users/users-store';

export interface InstructorsListProps extends WithTranslation, RouteComponentProps {
  instructors: InstructorStore;
  dropdownlocations: DropDownLocationsStore;
  dropdownLanguages: DropdownLanguagesStore;
  dropdownPillars: DropDownPillarsStore;
  instructorRoles: InstructorRolesStore;
}

export interface InstructorsListState {
  query: Query;
  changeInstructorShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  assignInstructorShown: boolean;
  instructorHistoryShown: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['instructors', InstructorStore],
  ['dropdownlocations', DropDownLocationsStore],
  ['dropdownLanguages', DropdownLanguagesStore],
  ['dropdownPillars', DropDownPillarsStore],
  ['instructorRoles', InstructorRolesStore]
)
class InstructorsListPage extends React.Component<InstructorsListProps, InstructorsListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    let activeFilters = [];
    const filter: any[] = [{ Enabled: { eq: true } }];
    if (IdentityService.isPowerInstructor(this.identityService.getUserInfo())) {
      const pillar = this.identityService.getCurrentUserDto()?.pillar;
      if (pillar) {
        activeFilters = ['pillar'];
        filter.push({ PillarId: { eq: { value: pillar.id, type: 'guid' } } });
      }
    }
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10, filter },
      changeInstructorShown: false,
      selectedItem: null,
      activeFilters,
      assignInstructorShown: false,
      instructorHistoryShown: false,
      someFilterOpened: false
    };

    this.load();
  }

  @autobind
  private load() {
    this.setFilterLocationPoc();
    this.props.instructors.getAllAsync(this.state.query);
  }

  @autobind
  private setFilterLocationPoc() {
    const currentUserInfo = this.identityService.getUserInfo();
    if (IdentityService.isPoc(currentUserInfo) && this.isEmptyObject(this.state.query.parameters)) {
      const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
      let parameters = {};
      parameters['instructorLocation'] = pocLocations.map(x => x);
      Object.assign(this.state.query, { parameters });
    }
  }

  @autobind
  private isEmptyObject(obj) {
    if (!obj) return true;
    else return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: InstructorDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.instructors.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.filter(x => x.id !== 'location').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    let parameters = {};
    if (filter.length !== filters.length) {
      parameters['instructorLocation'] = filters.first(x => x.id === 'location').filter;
    }

    const query = Object.assign(this.state.query, { filter: [...filter, { Enabled: { eq: true } }], skip: 0, parameters });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ assignInstructorShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ assignInstructorShown: false });

    if (isSuccess) this.load();
  }

  private canEditInstructor = (dto: InstructorDto): boolean => {
    let isPowerInstructor = IdentityService.isPowerInstructor(this.identityService.getUserInfo());

    if (isPowerInstructor) {
      let pillar = this.identityService.getCurrentUserDto()?.pillar;
      if (dto.pillar != null && dto.pillar.id === pillar.id) return true;
      else return false;
    } else return true;
  };

  @autobind
  private handleOnEnterKeydown(item: UserDto) {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  }

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouAdmin = IdentityService.isAdmin(currentUserInfo);
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    const areYouPowerInstructor = IdentityService.isPowerInstructor(this.identityService.getUserInfo());

    let initialPillar: ItemReference = undefined;
    if (areYouPowerInstructor) {
      const pillar = this.identityService.getCurrentUserDto()?.pillar;
      if (pillar) initialPillar = { id: pillar.id, title: pillar.name };
    }

    const tableModel = {
      columns: [
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => data.surname,
          editor: (data, onChange) => (
            <Input
              value={data.surname}
              fluid
              onChange={(e, { value }) => {
                data.surname = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Last Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<InstructorDto>('surname'), `startswith(tolower(surname), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<InstructorDto>('surname'))}
              active={activeFilters.includes(nameof<InstructorDto>('surname'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<InstructorDto>('surname'),
            useProfile: false
          }
        },
        {
          title: t('First Name'),
          tooltipRenderer: true,
          renderer: data => <span>{data.name}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<InstructorDto>('name'), `startswith(tolower(name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<InstructorDto>('name'))}
              active={activeFilters.includes(nameof<InstructorDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<InstructorDto>('name'),
            useProfile: false
          }
        },
        {
          title: t("Instructor's Role"),
          renderer: data => <span>{data.instructorRole ? data.instructorRole.name : ''}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<InstructorRoleDto>
              filterTitle={t("Instructor's Role")}
              triggerTitle={title}
              onFilter={(value: string) => onFilter('instructorRole', { InstructorRoleId: { eq: { value, type: 'guid' } } })}
              onClear={() => onClear('instructorRole')}
              active={activeFilters.includes('instructorRole')}
              getItems={q => this.props.instructorRoles.getAllAsync(q)}
              parameters=""
              orderBy={[{ direction: 'Ascending', field: nameof<InstructorRoleDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: InstructorRoleDto) => l.id}
              titleSelector={(l: InstructorRoleDto) => l.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Location'),
          renderer: data => (data.location ? data.location.location : ''),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LocationDto>
              filterTitle={t('Filter by Location')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<InstructorDto>('location'), value)}
              onClear={() => onClear(nameof<LocationDto>('location'))}
              active={activeFilters.includes(nameof<LocationDto>('location'))}
              getItems={q => this.props.dropdownlocations.getAllAsync(q)}
              parameters="id,location"
              orderBy={[{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(location)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: LocationDto) => l.id}
              titleSelector={(l: LocationDto) => l.location}
              preFilteredIds={areYouPoc ? pocLocations : null}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Language'),
          renderer: data => (data.languages ? this.getLanguagesList(data) : ''),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LanguageDto>
              filterTitle={t('Filter by Language')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter('language', { Languages: { any: { LanguageId: { eq: { value, type: 'guid' } } } } })}
              onClear={() => onClear('language')}
              active={activeFilters.includes('language')}
              getItems={q => this.props.dropdownLanguages.getAllAsync(q)}
              parameters="id,language"
              orderBy={[{ direction: 'Ascending', field: 'language', useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(language)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: LanguageDto) => l.id}
              titleSelector={(l: LanguageDto) => l.language}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Training Language'),
          renderer: data => (data.trainingLanguages ? this.getTrainingLanguagesList(data) : ''),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LanguageDto>
              filterTitle={t('Filter by Training Language')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter('trainingLanguage', { TrainingLanguages: { any: { LanguageId: { eq: { value, type: 'guid' } } } } })
              }
              onClear={() => onClear('trainingLanguage')}
              active={activeFilters.includes('trainingLanguage')}
              getItems={q => this.props.dropdownLanguages.getAllAsync(q)}
              parameters="id,language"
              orderBy={[{ direction: 'Ascending', field: 'language', useProfile: false }]}
              filterGenerator={search =>
                isNullOrWhiteSpaces(search) ? {} : { 'tolower(trainingLanguage)': { startswith: search.toLowerCase() } }
              }
              valueSelector={(l: LanguageDto) => l.id}
              titleSelector={(l: LanguageDto) => l.language}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Pillar'),
          renderer: data => <span>{data.pillar ? data.pillar.name : ''}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<PillarDto>
              filterTitle={t('Filter by Pillar')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter('pillar', { PillarId: { eq: { value, type: 'guid' } } })}
              onClear={() => onClear('pillar')}
              active={activeFilters.includes('pillar')}
              getItems={q => this.props.dropdownPillars.getAllAsync(q)}
              parameters=""
              orderBy={[{ direction: 'Ascending', field: nameof<PillarDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: PillarDto) => l.id}
              titleSelector={(l: PillarDto) => l.name}
              initialValue={initialPillar}
              onActivate={this.handleOnActivateFilter}
            />
          )
        }
      ],
      data: this.props.instructors.state
    } as TableModel<InstructorDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.instructors.state.result && !this.props.instructors.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.instructors.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.assignInstructorShown && !this.state.changeInstructorShown}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              /////////////////For build table keyboard navegation/////////////////
              isRowDisableLayout={(item: UserDto) => !item.enabled}
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              areActionsDisabled={item => !this.canEditInstructor(item as InstructorDto)}
              createNewButtonTitle={t('Assign New Instructor')}
              onNewItem={this.onNewItem}
              extraActions={
                areYouAdmin || areYouPowerInstructor
                  ? [
                      {
                        content: (
                          <Fragment key={'instructor-edit-'}>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </Fragment>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      },
                      {
                        content: (
                          <Fragment key={'instructor-history-btn'}>
                            <Icon name="history" />
                            &nbsp;{t('History')}
                          </Fragment>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onGetHistoryItem());
                        }
                      }
                    ]
                  : []
              }
            ></TableView>
          </Grid.Row>
        </Grid>
        {this.state.assignInstructorShown && <NewInstructorAssignmentView onClose={this.onNewItemClosed} />}
        {this.state.changeInstructorShown && (
          <ChangeInstructorAssignmentView onClose={this.onEditItemClosed} currentInstructor={this.state.selectedItem} />
        )}
        {this.state.instructorHistoryShown && (
          <InstructorHistoryView onClose={this.onGetHistoryItemClosed} currentInstructor={this.state.selectedItem} />
        )}
      </>
    );
  }

  @autobind
  onEditItem(): void {
    this.setState({ changeInstructorShown: true });
  }
  @autobind
  onGetHistoryItem(): void {
    this.setState({ instructorHistoryShown: true });
  }

  @autobind
  onEditItemClosed(isSuccess: boolean): void {
    this.setState({ changeInstructorShown: false });

    if (isSuccess) this.load();
  }
  @autobind
  onGetHistoryItemClosed(isSuccess: boolean): void {
    this.setState({ instructorHistoryShown: false });
  }

  private getLanguagesList = (data: InstructorDto) => {
    return (
      <List>
        {data.languages.map(language => {
          if (language.languageCode)
            return (
              <List.Item key={`${data.userId}-${language.id}-${language.languageCode}`}>
                {language.languageCode} - {language.language}
              </List.Item>
            );

          return <></>;
        })}
      </List>
    );
  };

  private getTrainingLanguagesList = (data: InstructorDto) => {
    return (
      <List>
        {data.trainingLanguages.map(trainingLanguage => {
          if (trainingLanguage.languageCode)
            return (
              <List.Item key={`${data.userId}-${trainingLanguage.id}-${trainingLanguage.languageCode}`}>
                {trainingLanguage.languageCode} - {trainingLanguage.language}
              </List.Item>
            );

          return <></>;
        })}
      </List>
    );
  };
}

export default withTranslation()(InstructorsListPage);
