import {
  InstructorStore,
  NewInstructorStore,
  ChangeInstructorStore,
  InstructorHistoryStore,
  ChangeInstructorHistoryStore
} from './instructors-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new InstructorStore());
  storeBuilder.addRepository(new NewInstructorStore());
  storeBuilder.addRepository(new ChangeInstructorStore());
  storeBuilder.addRepository(new InstructorHistoryStore());
  storeBuilder.addRepository(new ChangeInstructorHistoryStore());
}
