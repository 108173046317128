import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { ItemReference, Query } from 'stores/dataStore';
import { SsaDevelopmentPlanStore, SsaDevelopmentPlanDto } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import i18n from 'i18n';
import { nameof } from 'utils/object';
import YearEditor from 'widgets/bussiness/year-editor';
import DevelopmentPlanStatus from 'site/pages/development-plan/shared/development-plan-status';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';
import SsaActivityTypeEditor from 'widgets/bussiness/ssa-activity-type-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';

interface SsaTabPaneFilters {
  ssaSkill: ItemReference;
  activity: ItemReference;
  year?: number;
  profile: ItemReference;
}

interface SsaTabPaneState {
  query: Query;
  filters: SsaTabPaneFilters;
}

export interface SsaTabPaneProps extends WithTranslation {
  ssaDevelopmentPlanStore?: SsaDevelopmentPlanStore;
}

@connect(['ssaDevelopmentPlanStore', SsaDevelopmentPlanStore])
class SsaTabPane extends Component<SsaTabPaneProps, SsaTabPaneState> {
  state: SsaTabPaneState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10,
      parameters: {}
    },
    filters: {
      ssaSkill: null,
      activity: null,
      year: null,
      profile: null
    }
  };

  componentDidMount() {
    this.load();
  }

  private columns: TableColumn<SsaDevelopmentPlanDto>[] = [
    {
      title: i18n.t('Profile'),
      tooltipRenderer: true,
      renderer: data => data.profileName
    },
    {
      title: i18n.t('Skill Category'),
      tooltipRenderer: true,
      renderer: data => data.ssaSkill && data.ssaSkill.testCategory && data.ssaSkill.testCategory.name
    },
    {
      title: i18n.t('Skill'),
      tooltipRenderer: true,
      renderer: data => data.ssaSkill && data.ssaSkill.name
    },
    {
      title: i18n.t('Activity'),
      tooltipRenderer: true,
      renderer: data => data.ssaActivityItem && data.ssaActivityItem.title
    },
    {
      title: i18n.t('Activity Details'),
      tooltipRenderer: true,
      renderer: data => data.activityDetails
    },
    {
      title: i18n.t('Year'),
      tooltipRenderer: true,
      renderer: data => data.deadlineYear
    },
    {
      title: i18n.t('Status'),
      tooltipRenderer: true,
      renderer: data => <DevelopmentPlanStatus outOfDate={data.outOfDate} status={data.status} />
    }
  ];

  private handleFilterChange = (property: string, value: any) => {
    const filters = { ...this.state.filters };
    filters[property] = value;

    this.setState({ filters: filters }, this.load);
  };

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.ssaDevelopmentPlanStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: SsaTabPaneFilters) => {
    const query: Query = Object.assign(this.state.query);
    const oDataFilter = [];

    if (filters.ssaSkill && filters.ssaSkill.id) oDataFilter.push({ SkillId: { eq: { type: 'guid', value: filters.ssaSkill.id } } });
    if (filters.activity && filters.activity.id) oDataFilter.push({ SsaActivityId: { eq: { type: 'guid', value: filters.activity.id } } });
    if (!!filters.year) oDataFilter.push({ DeadlineYear: { eq: filters.year } });

    query.filter = oDataFilter;

    return query;
  };

  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  render() {
    const { t, ssaDevelopmentPlanStore: store } = this.props;
    const { filters } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.ssaDevelopmentPlanStore.state
    } as TableModel<SsaDevelopmentPlanDto>;

    return (
      <div className="development-plan-by-location__wrapper">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body)}
            onDismiss={() => this.props.ssaDevelopmentPlanStore.clearMessages()}
          />
        )}

        <div className="development-plan-by-location__filters">
          <SkillsEditor
            skillType={SkillType.SSA}
            clearable
            placeholder={t('SSA Skill')}
            value={filters.ssaSkill}
            nullable
            className="custom-editor"
            onChange={value => this.handleFilterChange(nameof<SsaTabPaneFilters>('ssaSkill'), value)}
          />
          <ProfileEditor
            clearable
            placeholder={t('SSA Profile')}
            value={this.state.filters.profile && this.state.filters.profile.id}
            nullable
            className="custom-editor"
            assessment="SSA"
            onChange={value => this.handleFilterChange(nameof<SsaTabPaneFilters>('profile'), value)}
          />

          <SsaActivityTypeEditor
            clearable
            className="custom-editor"
            placeholder={t('Activity')}
            nullable
            value={filters.activity}
            onChange={value => this.handleFilterChange(nameof<SsaTabPaneFilters>('activity'), value)}
          />
          <YearEditor
            clearable
            placeholder={t('Year')}
            value={filters.year}
            className="custom-editor year-editor"
            onChange={value => this.handleFilterChange(nameof<SsaTabPaneFilters>('year'), value)}
          />
        </div>
        <TableView model={tableModel} onRefresh={this.load} onPageChange={this.handlePageChange}></TableView>
      </div>
    );
  }
}

export default withTranslation()(SsaTabPane);
