import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { getProperties } from 'utils/object';
import { ScoreSummary } from './score-summary';

export interface ScoreListComponentProps extends WithTranslation {
  scoreSummary: ScoreSummary;
  type?: 'list' | 'table';
  tableColumnHeaders?: string[];
  source?: 'tna' | 'iws' | 'ssa';
  hasLastRelatedForm?: boolean;
}

class ScoreListComponent extends Component<ScoreListComponentProps> {
  render() {
    const { scoreSummary, tableColumnHeaders, hasLastRelatedForm } = this.props;

    if (this.props.type && this.props.type === 'table') {
      return (
        <Table definition key={'score-list-table'}>
          <Table.Header key={'table-header'}>
            <Table.Row key={'table-header-row'}>
              <Table.HeaderCell key={'table-header-empty'} />
              {(tableColumnHeaders || []).map((header, index) => (
                <Table.HeaderCell key={'header-cell-' + index}> {header} </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body key={'table-body'}>
            {scoreSummary.skillScores.map((skillScore, index) => (
              <Table.Row key={skillScore.id + 'row-' + index}>
                <Table.Cell key={'row-skillName-' + index} width="5">
                  {skillScore.name}
                </Table.Cell>
                {getProperties(skillScore.scores).map((score, i) => {
                  if (typeof score.value === 'number') {
                    return <Table.Cell key={'key-' + i + score.key}> {score.value !== 0 ? score.value : 'N/A'} </Table.Cell>;
                  } else {
                    return <Table.Cell key={'key-' + i + score.key}> {score.value.isCompleted ? score.value.score : 'N/A'} </Table.Cell>;
                    // return (
                    //   <Table.Cell key={'key-' + i + score.key}>
                    //     {' '}
                    //     {score.value.isCompleted ? score.value.score : score.value.score > 0 ? score.value.score : 'N/A'}{' '}
                    //   </Table.Cell>
                    //);
                  }
                })}
                {(skillScore.target || !isNaN(skillScore.target)) &&
                  (this.props.source && this.props.source === 'iws' ? (
                    <Table.Cell key={'row-skillTarget-' + index}>{(skillScore.target || -1) >= 0 ? skillScore.target : 'N/A'}</Table.Cell>
                  ) : (
                    <Table.Cell key={'row-skillTarget-' + index}>{skillScore.target}</Table.Cell>
                  ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <div className="profile-machinery__scores" key={'score-list-summary'}>
          {this.props.scoreSummary.skillScores.map((skillScore, index) => (
            <Fragment key={skillScore.id + '-' + index}>
              <p className="text__regular">{skillScore.name}</p>
              {this.props.source && this.props.source === 'tna' && !skillScore?.isMachineRelated ? (
                <p className="text__bold">
                  {skillScore.score === 0 && hasLastRelatedForm && !skillScore.isUpdated ? 'N/A' : skillScore.score}
                </p>
              ) : (
                <p className="text__bold">{skillScore.score}</p>
              )}
            </Fragment>
          ))}
        </div>
      );
    }
  }
}

export default withTranslation()(ScoreListComponent);
