import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Label, Input, Message, Form, TextArea } from 'semantic-ui-react';

import LocationEditor from 'widgets/bussiness/location-editor';
import PriorityEditor from 'widgets/bussiness/priority-editor';
import RequestReasonEditor from 'widgets/bussiness/request-reason-editor';
import { DateInput } from 'widgets/form/dateInput';
import FormRow from 'site/pages/shared/events-and-requests/form-row';

import { faClock, faCalendar, faStar, faMapMarkedAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RequestFormStore, RequestFormViewModel } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import { Message as msg } from 'stores/types';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { DurationInput } from 'widgets/form/durationInput';
import { DateTimeService } from 'services/datetime-service';
import { EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';

export interface RequestFormHeaderProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
  extraErrors?: msg[];
}

export interface RequestFormHeaderState {
  openDatePopupTo: boolean;
}

@connect(['requestFormStore', RequestFormStore])
class RequestFormHeader extends Component<RequestFormHeaderProps, RequestFormHeaderState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state = { openDatePopupTo: false };

  private handleOnChange = (property: keyof RequestFormViewModel, value: any) => {
    const { startDate, endDate } = this.props.requestFormStore.state.item;
    let change: Partial<RequestFormViewModel> = {};
    change[property as string] = value;
    if (property === 'startDate' || property === 'endDate') {
      const current = DateTimeService.isCoherent(value) ? DateTimeService.toMoment(value) : null;
      const start = DateTimeService.isCoherent(startDate) ? DateTimeService.toMoment(startDate) : null;
      const end = DateTimeService.isCoherent(endDate) ? DateTimeService.toMoment(endDate) : null;

      if (property === 'startDate') {
        if (current && end && current.isAfter(end, 'days')) change.endDate = DateTimeService.toString(current);
        this.setState(({ openDatePopupTo }) => ({ openDatePopupTo: !openDatePopupTo }));
      } else {
        if (current && start && start.isAfter(current, 'days')) change.endDate = DateTimeService.toString(start);
      }
    }

    this.props.requestFormStore.change(change);
  };

  render() {
    const { t, extraErrors, readOnly, requestFormStore } = this.props;
    const { item, result } = requestFormStore.state;
    const { openDatePopupTo } = this.state;
    const messages = result?.messages || [];
    if ((extraErrors || []).length > 0) messages.push(...extraErrors);
    const {
      eventTypeColor,
      eventTypeName,
      originalEventTypeId,
      desiredEventDuration,
      requestingLocation,
      priority,
      startDate,
      endDate,
      requestReason,
      requestReasonComments
    } = item;

    var roleLocations: string[];
    const currentUser = this.identityService.getUserInfo();
    const isRoleWithLocations = IdentityService.isRoleWithLocations(currentUser);

    if (isRoleWithLocations) roleLocations = currentUser.locationsByRoles[currentUser.activeRole] as string[];

    return (
      <div className="form__modal__header-content">
        {messages.length > 0 && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={messages.map(o => o.body).filter(x => x !== '')}
          />
        )}
        <div className="form__header__data">
          <div className="form__header__first-row__event-type flex-start">
            <Label
              className="first-row__event-type__circle"
              circular
              size="mini"
              style={{ backgroundColor: eventTypeColor }}
              key={eventTypeColor}
            ></Label>
            <p className="">{t(eventTypeName)}</p>
          </div>

          <FormRow label={t('Desired Dates Slot')} icon={faCalendar}>
            <div className="flex-wrap">
              <div className="generic-date-picker-modificable__wrapper">
                <DateInput
                  className="planit-eventform-dates eventform-dates__first"
                  readOnly={readOnly}
                  value={startDate}
                  maxDate={endDate}
                  onChange={(_, value) => this.handleOnChange('startDate', value)}
                />

                <DateInput
                  id={'request-form-header-endDate'}
                  className="planit-eventform-dates eventform-dates__second"
                  readOnly={readOnly}
                  forceOpen={openDatePopupTo}
                  value={endDate}
                  minDate={startDate}
                  onChange={(_, value) => this.handleOnChange('endDate', value)}
                  initialValue={startDate}
                />
              </div>
            </div>
          </FormRow>

          <FormRow label={t('Desired Duration')} icon={faClock}>
            <div className="form__header__third-row__durations__element">
              <Input className="form__duration-field-numeric form__duration-field-numeric__wd-number" type="number">
                <DurationInput
                  value={desiredEventDuration}
                  onChange={value => this.handleOnChange('desiredEventDuration', value)}
                  disabled={readOnly}
                />
              </Input>
            </div>
          </FormRow>

          <FormRow label={t('Requesting Location')} icon={faMapMarkedAlt}>
            <LocationEditor
              readOnly={readOnly}
              className="form__header__dropdown-style"
              value={requestingLocation}
              locationsReceived={roleLocations}
              onChange={location => this.handleOnChange('requestingLocation', location)}
              showCountry={true}
            />
          </FormRow>

          <FormRow>
            {(requestReason || !readOnly) && (
              <FormRow label={t('Request Reason')} icon={faQuestionCircle} className="form__header__request-reason__with_column">
                <RequestReasonEditor
                  readOnly={readOnly}
                  className="form__header__dropdown-style form__header__request-reason__dropdown-style"
                  value={requestReason}
                  onChange={value => this.handleOnChange('requestReason', value)}
                  filterEventTypeId={originalEventTypeId}
                />
              </FormRow>
            )}
            {item.eventTypeCategory === EventTypeCategory.Extended && (requestReason || !readOnly) && (
              <FormRow label={t('Support Need Explanation')} icon={faQuestionCircle}>
                <Form className="form__comment-textarea-request-form">
                  <Form.Field>
                    <TextArea
                      rows={3}
                      readOnly={readOnly}
                      transparent="true"
                      className="scroll-not-visible"
                      value={requestReasonComments}
                      onChange={(_, { value }) => this.handleOnChange('requestReasonComments', value as string)}
                      placeholder="Please explain the driver of this request briefly"
                    ></TextArea>
                  </Form.Field>
                </Form>
              </FormRow>
            )}
          </FormRow>

          {(priority || !readOnly) && (
            <FormRow label={t('Priority')} icon={faStar}>
              <PriorityEditor
                nullable
                clearable
                readOnly={readOnly}
                className="form__header__dropdown-style"
                value={priority}
                onChange={p => this.handleOnChange('priority', p)}
              />
            </FormRow>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(RequestFormHeader);
