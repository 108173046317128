import { BaseDto, ItemResult } from '../../types';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { DataStore, QueryResult, Query } from '../../dataStore';
import { container } from '../../../inversify.config';
import HttpService from '../../../services/http-service';
import { AxiosResponse } from 'axios';
import { ToastComponent } from '../../../site/pages/landing-pages/util/toast-component';
import i18n from '../../../i18n';

interface TnaQuestionTranslationState {
  isBusy: boolean;
  result: ItemResult<any>;
}

export interface TnaQuestionFormTranslateLogItemDto extends BaseDto {
  translateProcessId: string;
  totalTnaFormAnswers: number;
  role: string;
  userName: string;
  translatePackageSize: number;
  started: string;
  finished: string;
  translateStatus: TranslateStatusKeys;
}

export enum TranslateStatus {
  Unknown = 0,
  InProcess = 10,
  WithErrors = 20,
  Completed = 30
}
export type TranslateStatusKeys = keyof typeof TranslateStatus;

@repository('@@TNAQUESTIONTRANSLATEFORMS', 'tnaquestiontranslateforms.translate')
export class TranslateTnaQuestionTranslateFormStore extends ReduxRepository<TnaQuestionTranslationState> {
  baseUrl = 'skills/v1';
  initBackgroundTranslateTnaForm = 'translate-tna-question-create-queue';
  retrieveTranslateTnaQuestionLogPath = 'translate-tna-question-get-log';

  public BACKGROUND_TRANSLATE_INITIALIZED = 'BACKGROUND_TRANSLATE_INITIALIZED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.onBackgroundTranslateInitilized.bind(this);
    this.addReducer(this.BACKGROUND_TRANSLATE_INITIALIZED, this.onBackgroundTranslateInitilized, 'AsyncAction');
  }

  public async initBackgroundTnaQuestionFormTranslate() {
    const url = `${this.baseUrl}/${this.initBackgroundTranslateTnaForm}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.BACKGROUND_TRANSLATE_INITIALIZED, httpService.post(url, null));
  }

  public async getTranslateTnaQuestionLog(query: Query, data?: any): Promise<QueryResult<TnaQuestionFormTranslateLogItemDto>> {
    const httpService = container.get(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.BACKGROUND_TRANSLATE_INITIALIZED,
      httpService.get<QueryResult<TnaQuestionFormTranslateLogItemDto>>(
        `${this.baseUrl}/${this.retrieveTranslateTnaQuestionLogPath}?${path}`,
        data
      )
    );

    return result.data;
  }

  private onBackgroundTranslateInitilized = (): AsyncAction<AxiosResponse<any>, TnaQuestionTranslationState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: result => {
        //ToastComponent({ text: i18n.t('Translate initialized'), type: 'success-toast' });
        return { ...this.state, isBusy: false, result: result.data };
      },
      onError: error => {
        ToastComponent({ text: i18n.t('Translate failed'), type: 'error-toast' });
        return {
          ...this.state,
          isBusy: false,
          result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
        };
      }
    };
  };
}
