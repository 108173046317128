import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { nameof } from '../../../utils/object';
import { QueryResult } from 'stores/dataStore';
import MultipleSelectionDropdown, { MultipleSelectionDropdownOptions } from 'widgets/editors/multiple-selection-dropdown';
import { TrainingLevelsStore, TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';

interface MultipleTrainingLevelsEditorProps extends WithTranslation {
  value: TrainingLevelDto[];
  onChange?: (value: TrainingLevelDto[]) => void;
  trainingLevels?: TrainingLevelsStore;
  placeholder?: string;
  clearable?: boolean;
}

interface MultipleTrainingLevelsEditorState {
  options: MultipleSelectionDropdownOptions<TrainingLevelDto>[];
}

@connect(['trainingLevels', TrainingLevelsStore])
class MultipleTrainingLevelsEditor extends React.Component<MultipleTrainingLevelsEditorProps, MultipleTrainingLevelsEditorState> {
  private get trainingLevelsStore() {
    return this.props.trainingLevels;
  }

  state: MultipleTrainingLevelsEditorState = {
    options: []
  };

  componentDidMount() {}

  mapToOptions(trainingLevels: QueryResult<TrainingLevelDto>): MultipleSelectionDropdownOptions<TrainingLevelDto>[] {
    return trainingLevels.items.map(trainingLevel => ({
      id: trainingLevel.id,
      value: trainingLevel,
      text: trainingLevel.name
    }));
  }

  onOpenDropDown = () => {
    if ((this.state.options || []).length <= 0) {
      this.trainingLevelsStore
        .getAllAsync({
          searchQuery: '',
          skip: 0,
          take: 100000,
          orderBy: [{ direction: 'Ascending', field: nameof<TrainingLevelDto>('name'), useProfile: false }],
          filter: []
        })
        .then(trainingLevels => this.setState({ options: this.mapToOptions(trainingLevels) }));
    }
  };

  public render() {
    return (
      <MultipleSelectionDropdown
        searchable
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        onOpenDropDown={this.onOpenDropDown}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(MultipleTrainingLevelsEditor);
