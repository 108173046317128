import { BaseDto, CommandResult, BaseCommandResult } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { MatchRowDto } from './match-rows-store';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface UserImportDto extends BaseDto {
  id: string;
  name: string;
  active: boolean;
  userId: string;
  userName: string;
  surname: string;
  email: string;
  locationCode: string;
  location: string;
  regionId: string;
  region: string;
  countryId: string;
  country: string;
  personnelAreaId: string;
  personnelArea: string;
  positionCodeId: string;
  positionCode: string;
  positionTitle: string;
  positionSF: string;
  hireDate: Date;
  seniority: number;
  lineManagerName: string;
  reason: string;
  noImportFlag: boolean;
  timestamp: Date;
  matchRows: MatchRowDto[];
  isGlobalPoc: boolean;
}

export interface ChangeUserImportDto extends BaseDto {
  id: string;
  name: string;
  active: boolean;
  userId: string;
  userName: string;
  surname: string;
  email: string;
  locationCode: string;
  location: string;
  regionId: string;
  region: string;
  countryId: string;
  country: string;
  personnelAreaId: string;
  personnelArea: string;
  positionCodeId: string;
  positionCode: string;
  positionTitle: string;
  positionSF: string;
  hireDate: Date;
  seniority: number;
  lineManagerName: string;
  reason: string;
  noImportFlag: boolean;
  timestamp: Date;
  matchRows: MatchRowDto[];
}

export class UserImportValidator extends AbstractValidator<UserImportDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('User Import Id is required'));
  }
}

export class ChangeUserImportValidator extends AbstractValidator<ChangeUserImportDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t('User Import Id is required'));
  }
}

@repository('@@USERIMPORTS', 'userimport.summary')
export class UserImportStore extends DataStore<UserImportDto> {
  baseUrl = '/success-factors/v1';
  createPath = 'new-userimport';
  retrievePath = 'get-userimports';
  updatePath = 'update-userimport';
  deletePath = 'delete-userimport';
  deleteBySapUserIdPath = 'delete-userImportsBySAPId';
  prunePath = 'prune-duplicated-user-imports';

  protected validate(item: UserImportDto) {
    return new UserImportValidator().validate(item as any);
  }

  constructor() {
    super('NEW_USERIMPORT', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async deleteUserImportBySapIdAsync(id: string): Promise<CommandResult<any>> {
    const httpService = container.get(HttpService);

    await httpService.delete(`${this.baseUrl}/${this.deleteBySapUserIdPath}/${id}`);

    return { isSuccess: true, items: [], messages: [] };
  }

  public pruneInvalidDuplicatedUserImports() {
    const httpService = container.get(HttpService);
    return httpService.delete<{}, BaseCommandResult>(`${this.baseUrl}/${this.prunePath}`);
  }
}
