import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { ProfessionDto } from 'stores/configuration/profiles/professions-store';
import { ClusterDto } from 'stores/configuration/machinery/clusters-store';
import { EquipmentTypeDto } from 'stores/configuration/machinery/equipment-types-store';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';
import { MachineUnitDto } from 'stores/configuration/machinery/machine-units-store';
import { PlcTypeDto } from 'stores/configuration/machinery/plc-types-store';
import { NMFunctionalSubareaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { NMTrainingNameDto } from 'stores/configuration/events-n-requests/non-machine-related/training-names-store';
import { NMFunctionalAreaDto } from 'stores/configuration/events-n-requests/non-machine-related/functional-areas-store';
import { NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { OemDto } from 'stores/configuration/machinery/oems-store';
import { FormStore } from 'stores/formStore';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { CommandResult } from 'stores/types';

export type AnswerType = 'Unknown' | 'YesOrNo' | 'Rating';
export interface MrPracticalTestQuestionItemDto {
  id: string;
  friendlyId: string;

  question: PracticalQuestionDto;
  questionTranslations: PracticalQuestionDto[];
  professions: ProfessionDto[];

  targetLevel: number;
  targetLevelFormatted: string;

  clusterId: string;
  cluster: ClusterDto;

  equipmentTypeId: string;
  equipmentType: EquipmentTypeDto;

  oemId: string;
  oem: OemDto;

  machineModelId: string;
  machineModel: MachineModelDto;

  machineUnitId: string;
  machineUnit: MachineUnitDto;

  plcTypeId: string;
  plcType: PlcTypeDto;

  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
}

export interface PracticalQuestionDto {
  text: string;
  language: LanguageDto;
}

export interface NmrPracticalTestQuestionItemDto {
  id: string;
  friendlyId: string;

  question: PracticalQuestionDto;
  questionTranslations: PracticalQuestionDto[];

  professions: ProfessionDto[];

  targetLevel: number;
  targetLevelFormatted: string;

  nmrClusterId: string;
  nmrCluster: NMClusterDto;

  functionalAreaId: string;
  functionalArea: NMFunctionalAreaDto;

  trainingNameId: string;
  trainingName: NMTrainingNameDto;

  functionalSubareaId: string;
  functionalSubArea: NMFunctionalSubareaDto;

  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
}

export interface CreateMrPracticalTestQuestionItemDto {
  question: CreatePracticalQuestionDto;
  questionTranslations: CreatePracticalQuestionDto[];
  targetLevel: number;
  professions: string[];
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  machineUnitId: string;
  plcTypeId: string;
  trainingLevelId: string;
}
export interface ChangeMrPracticalTestQuestionItemDto {
  question: CreatePracticalQuestionDto;
  questionTranslations: CreatePracticalQuestionDto[];
  targetLevel: number;
  professions: string[];
  clusterId: string;
  equipmentTypeId: string;
  oemId: string;
  machineModelId: string;
  machineUnitId: string;
  plcTypeId: string;
  trainingLevelId: string;
}

export interface CreateNmrPracticalTestQuestionItemDto {
  question: CreatePracticalQuestionDto;
  questionTranslations: CreatePracticalQuestionDto[];
  targetLevel: number;
  professions: string[];
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string | null;
  functionalSubareaId: string | null;
  trainingLevelId: string;
}

export interface CreatePracticalQuestionDto {
  text: string;
  languageId: string;
}

export interface ChangeMrPracticalTestQuestionItemDto {
  id: string;
  question: ChangePracticalQuestionDto;
  questionTranslations: ChangePracticalQuestionDto[];
  targetLevel: number;
  professions: string[];
  clusterId: string;
  equipmentTypeId: string;
  oemId: string | null;
  machineModelId: string | null;
  machineUnitId: string | null;
  plcTypeId: string | null;
  trainingLevelId: string;
}
export interface ChangeNmrPracticalTestQuestionItemDto {
  id: string;
  question: ChangePracticalQuestionDto;
  questionTranslations: ChangePracticalQuestionDto[];
  targetLevel: number;
  professions: string[];
  nmrClusterId: string;
  functionalAreaId: string;
  trainingNameId: string | null;
  functionalSubareaId: string | null;
  trainingLevelId: string;
}
export interface ChangePracticalQuestionDto {
  text: string;
  languageId: string;
}

@repository('@@MR_PRACTICAL_TEST_QUESTION_BANK', 'mrPracticalTestQuestionBank.summary')
export class MRPracticalQuestionBanksStore extends DataStore<MrPracticalTestQuestionItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-mr-practical-test-question';
  retrievePath = 'get-mr-practical-test-questions';
  updatePath = '';
  deletePath = 'delete-mr-practical-test-question';
  hardDeletePath = '';
  retrieveOnePath = 'get-mr-practical-test-question';
  retrieveCountPath = 'get-mr-practical-test-used-count';

  protected validate(item: MrPracticalTestQuestionItemDto) {
    return new ValidationResult();
  }

  constructor() {
    super('MR_PRACTICAL_TEST_QUESTION_BANK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getUsedPracticalQuestionCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`);
    return result.data;
  }
}

export class MRPracticalQuestionValidator extends ExtendedAbstractValidator<NmrPracticalTestQuestionItemDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}

export class NmrTheoreticalQuestionValidator extends ExtendedAbstractValidator<NmrPracticalTestQuestionItemDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x);
  }
}

@repository('@@MR_PRACTICAL_TEST_QUESTION_BANK', 'mrPracticalTestQuestionBank.wizard')
export class MRPracticalTestQBWizardStore extends FormStore<MrPracticalTestQuestionItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-mr-practical-test-question';
  retrievePath = 'get-nmr-practical-test-questions';
  updatePath = 'update-mr-practical-test-question';
  deletePath = 'delete-nmr-practical-test-question';
  hardDeletePath = '';
  retrieveOnePath = 'get-nmr-practical-test-question';

  protected validate(item: MrPracticalTestQuestionItemDto) {
    return new MRPracticalQuestionValidator().extendValidate(item as any);
  }

  constructor() {
    super('MR_THEORETICAL_TEST_QB_WIZARD', {
      isBusy: false,
      item: undefined,
      count: 0,
      status: 'New',
      result: undefined,
      discard: item => {}
    });
  }

  public submitQuestion = async (update: boolean = false) => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    if (update) {
      const changeMrPracticalTestQuestionItemDto = toChangeMrPracticalTestQuestionItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<ChangeMrPracticalTestQuestionItemDto, CommandResult<ChangeMrPracticalTestQuestionItemDto>>(
          `${this.baseUrl}/${this.updatePath}`,
          changeMrPracticalTestQuestionItemDto
        )
      );
      return result.data;
    } else {
      const createMrPracticalTestQuestionItemDto = toCreateMrPracticalTestQuestionItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<CreateMrPracticalTestQuestionItemDto, CommandResult<CreateMrPracticalTestQuestionItemDto>>(
          `${this.baseUrl}/${this.createPath}`,
          createMrPracticalTestQuestionItemDto
        )
      );
      return result.data;
    }
  };
}

export const toCreateMrPracticalTestQuestionItemDto = (item: MrPracticalTestQuestionItemDto): CreateMrPracticalTestQuestionItemDto => {
  if (!item) return;
  const {
    clusterId,
    equipmentTypeId,
    oemId,
    machineModelId,
    machineUnitId,
    plcTypeId,
    targetLevel,
    question,
    questionTranslations,
    professions,
    trainingLevelId
  } = item;
  return {
    clusterId,
    equipmentTypeId,
    oemId: !clusterId || !equipmentTypeId ? null : oemId,
    machineModelId: !clusterId || !equipmentTypeId || !oemId ? null : machineModelId,
    machineUnitId: !equipmentTypeId || !oemId ? null : machineUnitId,
    plcTypeId: !equipmentTypeId || !oemId ? null : plcTypeId,
    targetLevel,
    question: { text: question.text, languageId: question?.language?.id },
    questionTranslations: (questionTranslations || []).map(({ language, text }) => ({ languageId: language?.id, text })),
    professions: (professions || []).map(({ id }) => id),
    trainingLevelId
  };
};

export const toCreateNMrPracticalTestQuestionItemDto = (item: NmrPracticalTestQuestionItemDto): CreateNmrPracticalTestQuestionItemDto => {
  if (!item) return;
  const {
    nmrClusterId,
    functionalAreaId,
    trainingNameId,
    functionalSubareaId,
    targetLevel,
    question,
    questionTranslations,
    professions,
    trainingLevelId
  } = item;
  return {
    nmrClusterId,
    functionalAreaId,
    trainingNameId: !nmrClusterId || !functionalAreaId ? null : trainingNameId,
    functionalSubareaId: !nmrClusterId || !functionalAreaId || !trainingNameId ? null : functionalSubareaId,
    targetLevel,
    question: { text: question.text, languageId: question?.language?.id },
    questionTranslations: (questionTranslations || []).map(({ language, text }) => ({ languageId: language?.id, text })),
    professions: (professions || []).map(({ id }) => id),
    trainingLevelId
  };
};

export const toChangeMrPracticalTestQuestionItemDto = (item: MrPracticalTestQuestionItemDto): ChangeMrPracticalTestQuestionItemDto => {
  if (!item) return;

  const {
    id,
    question,
    questionTranslations,
    targetLevel,
    professions,
    clusterId,
    equipmentTypeId,
    oemId,
    machineModelId,
    machineUnitId,
    plcTypeId,
    trainingLevelId
  } = item;

  return {
    id,
    question: { text: question.text, languageId: question?.language?.id },
    questionTranslations: (questionTranslations || []).map(({ language, text }) => ({ languageId: language?.id, text })),
    targetLevel,
    professions: (professions || []).map(profession => profession.id),
    clusterId,
    equipmentTypeId,
    oemId: !clusterId || !equipmentTypeId ? null : oemId,
    machineModelId: !clusterId || !equipmentTypeId || !oemId ? null : machineModelId,
    machineUnitId: !equipmentTypeId || !oemId ? null : machineUnitId,
    plcTypeId: !equipmentTypeId || !oemId ? null : plcTypeId,
    trainingLevelId
  };
};

export const toChangeNMrPracticalTestQuestionItemDto = (item: NmrPracticalTestQuestionItemDto): ChangeNmrPracticalTestQuestionItemDto => {
  if (!item) return;

  const {
    id,
    question,
    questionTranslations,
    targetLevel,
    professions,
    nmrClusterId,
    functionalAreaId,
    trainingNameId,
    functionalSubareaId,
    trainingLevelId
  } = item;

  return {
    id,
    question: { text: question.text, languageId: question?.language?.id },
    questionTranslations: (questionTranslations || []).map(({ language, text }) => ({ languageId: language?.id, text })),
    targetLevel,
    professions: (professions || []).map(professions => professions.id),
    nmrClusterId,
    functionalAreaId,
    trainingNameId: !nmrClusterId || !functionalAreaId ? null : trainingNameId,
    functionalSubareaId: !nmrClusterId || !functionalAreaId || !trainingNameId ? null : functionalSubareaId,
    trainingLevelId
  };
};

@repository('@@NMR_PRACTICAL_TEST_QUESTION_BANK', 'nmrPracticalTestQuestionBank.summary')
export class NMRPracticalQuestionBanksStore extends DataStore<NmrPracticalTestQuestionItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-nmr-practical-test-question';
  retrievePath = 'get-nmr-practical-test-questions';
  updatePath = 'update-nmr-practical-test-question';
  deletePath = 'delete-nmr-practical-test-question';
  hardDeletePath = '';
  retrieveOnePath = 'get-nmr-practical-test-question';
  retrieveCountPath = 'get-nmr-practical-test-used-count';

  constructor() {
    super('NMR_PRACTICAL_TEST_QUESTION_BANK', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      itemById: undefined,
      discard: item => {}
    });
  }

  public getUsedPracticalNRMQuestionCountById = async (id: string): Promise<number> => {
    const httpService = container.get(HttpService);
    const result = await httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`);
    return result.data;
  };

  protected validate(item: NmrPracticalTestQuestionItemDto) {
    return new ValidationResult();
  }

  public async getQuestionById(id: string): Promise<MrPracticalTestQuestionItemDto> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<MrPracticalTestQuestionItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`);
    return result.data;
  }
}

@repository('@@NMR_PRACTICAL_TEST_QUESTION_BANK', 'nmrPracticalTestQuestionBank.wizard')
export class NMRPracticalTestQBWizardStore extends FormStore<NmrPracticalTestQuestionItemDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-nmr-practical-test-question';
  retrievePath = 'get-nmr-practical-test-questions';
  updatePath = 'update-nmr-practical-test-question';
  deletePath = 'delete-nmr-practical-test-question';
  hardDeletePath = '';
  retrieveOnePath = 'get-nmr-practical-test-question';
  retrieveCountPath = 'get-nmr-practical-test-used-count';

  protected validate(item: NmrPracticalTestQuestionItemDto) {
    return new NmrTheoreticalQuestionValidator().extendValidate(item as any);
  }

  constructor() {
    super('NMR_PRACTICAL_TEST_QB_WIZARD', {
      isBusy: false,
      item: undefined,
      count: 0,
      status: 'New',
      result: undefined,
      discard: item => {}
    });
  }

  public submitNMQuestion = async (update: boolean = false) => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    if (update) {
      const changeNmrPracticalTestQuestionItemDto = toChangeNMrPracticalTestQuestionItemDto(this.state.item);

      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.put<ChangeNmrPracticalTestQuestionItemDto, CommandResult<ChangeNmrPracticalTestQuestionItemDto>>(
          `${this.baseUrl}/${this.updatePath}`,
          changeNmrPracticalTestQuestionItemDto
        )
      );
      return result.data;
    } else {
      const createNmrPracticalTestQuestionItemDto = toCreateNMrPracticalTestQuestionItemDto(this.state.item);
      const result = await this.dispatchAsync(
        this.ENTITY_SAVE,
        httpService.post<CreateNmrPracticalTestQuestionItemDto, CommandResult<CreateNmrPracticalTestQuestionItemDto>>(
          `${this.baseUrl}/${this.createPath}`,
          createNmrPracticalTestQuestionItemDto
        )
      );
      return result.data;
    }
  };
}
