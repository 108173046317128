import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Input, Icon } from 'semantic-ui-react';
import NewInfoSupportDetailedComponent from './new-info-support-detailed.component';
import './new-events-support-details.style.less';
import InputCostComponent from 'widgets/form/input-cost.component';
import { UserDto } from 'stores/users/users-store';
import { EventPositionDto, NewEventSupportPositionsDto } from 'stores/events/events-store';
import { EventFormStore, EventSupportDetailsViewModel } from 'stores/events/event-form-store';
import { DatePeriod } from 'site/pages/landing-pages/shared-scheduler-components/events/scheduler-period-service';
import moment from 'moment';
import { DateTimeService } from 'services/datetime-service';

interface NewEventsSupportDetailsProps extends WithTranslation {
  readonly?: boolean;
  supportDetails: EventSupportDetailsViewModel;
  onChange?: (value: EventSupportDetailsViewModel) => void;
  isHideEditButton: boolean;
  eventFormStore?: EventFormStore;
}

const NewEventsSupportDetails: FunctionComponent<NewEventsSupportDetailsProps> = props => {
  const { t, readonly, supportDetails, onChange, isHideEditButton, eventFormStore } = props;
  const { newSupportPositions, positionsRequested, totalCost } = supportDetails;

  const onChangeHandler = (newPosition: NewEventSupportPositionsDto, index: number) => {
    const newSupportPositions = supportDetails.newSupportPositions.map((position, i) =>
      index === i ? (position = newPosition) : position
    );
    onChange({ ...supportDetails, newSupportPositions });
  };
  const sumTotalCosts = newSupportPositions.reduce(
    (prev, { eventNewModelPositions }) => (eventNewModelPositions || []).reduce((p, { actualCost }) => Number(actualCost) + p, 0) + prev,
    0
  );

  useEffect(() => {
    const sd = { ...supportDetails };
    const { state } = eventFormStore;
    const { startDate, endDate } = state.item;
    const start = DateTimeService.toMoment(startDate);
    const end = DateTimeService.toMoment(endDate);

    (sd.newSupportPositions || []).map((supportPosition, idx) => {
      const notInEventDate = (supportPosition.eventNewModelPositions || []).any(({ startDate, endDate }, i) => {
        const fromDate = DateTimeService.toMoment(startDate);
        const toDate = DateTimeService.toMoment(endDate);
        return start.isAfter(fromDate, 'D') || end.isBefore(toDate, 'D');
      });

      if (notInEventDate) {
        (supportPosition.eventNewModelPositions || []).map(eventNewModelPosition => {
          const enmpStartDate = eventNewModelPosition.startDate;
          const enmpEndDate = eventNewModelPosition.endDate;

          const fromDate = DateTimeService.toMoment(enmpStartDate);
          const toDate = DateTimeService.toMoment(enmpEndDate);

          if (start.isAfter(fromDate, 'D') || start.isAfter(toDate, 'D') || end.isBefore(toDate, 'D')) {
            if (start.isAfter(fromDate, 'D')) eventNewModelPosition.startDate = startDate;
            if (start.isAfter(toDate, 'D')) eventNewModelPosition.endDate = startDate;
            if (end.isBefore(toDate, 'D')) eventNewModelPosition.endDate = endDate;
            return { ...eventNewModelPosition };
          }
          return eventNewModelPosition;
        });
      }

      return null;
    });

    totalCost !== sumTotalCosts && onChange({ ...supportDetails, totalCost: sumTotalCosts });
  }, [sumTotalCosts, onChange, totalCost, supportDetails, eventFormStore, eventFormStore.state.item]);

  const onNewPositionHandler = (user: UserDto, dates: DatePeriod, supportPosition: number) => {
    const nextSupportPositions = supportDetails.newSupportPositions.map((newSupportPos, i) => {
      if (supportPosition === i) {
        const newEventPosition: EventPositionDto = {
          actualCost: '',
          actualDays: '',
          actualHours: '',
          startDate: dates ? dates.from : null,
          endDate: dates ? dates.to : null,
          user,
          userId: user.id,
          userLocationId: user.roles.find(x => x.role.name === 'Employee').location.id,
          plannedDays: dates.from && dates.to ? moment(dates.to).diff(moment(dates.from), 'days') + 1 : 0,
          travelDays: null,
          noTravel: false
        };

        newSupportPos.eventNewModelPositions = [...newSupportPos.eventNewModelPositions, newEventPosition];
      }
      return newSupportPos;
    });
    onChange({ ...supportDetails, newSupportPositions: nextSupportPositions });
  };

  return (
    <div className="new-events-support-details__container">
      <div className="events-support-details__main-info">
        <Icon name="cogs" />
        <div className="events-support-details__main-data">
          <span>{t('Position Requested')}</span>
          <Input disabled value={positionsRequested} />
        </div>
        <div className="events-support-details__main-data">
          <span>{t('Total Cost')}</span>
          <InputCostComponent onChange={null} disabled disabledLayout value={sumTotalCosts} />
        </div>
      </div>
      {(newSupportPositions || []).map((newSupportPosition, i) => (
        <NewInfoSupportDetailedComponent
          key={'event-support-position' + i}
          readonly={readonly}
          onNewPosition={(user, dates) => onNewPositionHandler(user, dates, i)}
          {...newSupportPosition}
          onChange={value => onChangeHandler(value, i)}
          isHideEditButton={isHideEditButton}
          eventFormStore={eventFormStore}
        />
      ))}
    </div>
  );
};

export default withTranslation()(NewEventsSupportDetails);
