import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import LanguageEditor from 'widgets/bussiness/language-editor';
import { TheoreticalFormView } from 'stores/assessments/forms/theoretical-form-test-store';
import RemainingTime from '../components/remaining-time';

interface HeaderProps extends WithTranslation, TheoreticalFormView {
  onChange: (property: keyof TheoreticalFormView, value: any) => void;
}

const TheoreticalFormHeader: FC<HeaderProps> = props => {
  const { t, onChange, user, eventDetails, friendlyId, result, finishedTime, endedAt, answered } = props;
  const { typeRelated, tnaDetails, currentLanguage, startedAt, hoursDuration, time, numberOfQuestions } = props;

  let test: { type: 'Event' | 'TNA'; title: string } = { type: null, title: 'TITLE' };
  if (typeRelated === 'Event') {
    test.title = `${friendlyId} - ${eventDetails.title}`;
    test.type = 'Event';
  } else if (typeRelated === 'TNA') {
    test.title = `${friendlyId} - ${tnaDetails.title}`;
    test.type = 'TNA';
  }

  return (
    <div className="edit-form__header__data-language flex-between">
      <div className="edit-form__header__general-info">
        <div className="edit-form__header__general-info__element">
          <h5>{t('Employee')}</h5>
          <p>{`${user.firstName} ${user.lastName}`}</p>
        </div>

        <div className="edit-form__header__general-info__element">
          <h5>{t(test.type)}</h5>
          <p>{test.title}</p>
        </div>
        <div className="edit-form__header__general-info__element">
          <h5>{t('Number of Questions')}</h5>
          <p>{`${numberOfQuestions} Questions`}</p>
        </div>
        {!endedAt && !answered ? (
          <div className="edit-form__header__general-info__element">
            <h5>{t('Remaining Time')}</h5>
            <RemainingTime
              finishedTime={finishedTime}
              onFinished={() => onChange('finishedTime', true)}
              start={startedAt}
              duration={hoursDuration}
            />
          </div>
        ) : (
          <>
            <div className="edit-form__header__general-info__element">
              <h5>{t('Total Time')}</h5>
              <p>{time}</p>
            </div>
            <div className="edit-form__header__general-info__element">
              <h5>{t('Result')}</h5>
              <p>{result}</p>
            </div>
          </>
        )}
      </div>

      <LanguageEditor
        className="planit-user-dropdown-custom"
        placeholder={t('Select Language')}
        clearable
        nullable
        onlyForTests
        value={currentLanguage}
        onChange={value => onChange('currentLanguage', value?.id ?? null)}
      />
    </div>
  );
};

export default withTranslation()(TheoreticalFormHeader);
