import * as React from 'react';
import {
  Popup,
  SemanticICONS,
  InputProps,
  PopupProps,
  GridProps,
  GridRowProps,
  GridColumnProps,
  Grid,
  Icon,
  SemanticWIDTHS
} from 'semantic-ui-react';
import semanticIconList from './semantic-icon-list.json';
import './icon-selector.less';
import { ClearableTimerInput } from './../clearable-timer-input';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import Fuse from 'fuse.js';

interface IconSelectorProps extends WithTranslation {
  value?: SemanticICONS;
  onChange: (value: SemanticICONS) => void;
  rowsPerPage?: number;
  columnsPerPage?: number;
  nullable?: boolean;
  clearable?: boolean;
  inputProps?: React.HTMLProps<HTMLDivElement>;
  popupProps?: PopupProps;
  searchInputProps?: InputProps;
  iconGridProps?: GridProps;
  iconGridRowProps?: GridRowProps;
  iconGridColumnProps?: GridColumnProps;
}

interface IconSelectorState {
  value: SemanticICONS;
  activePage: number;
  totalPages: number;
  rowsPerPage: number;
  columnsPerPage: number;
  currentData: SemanticICONS[];
  search: string;
}

class IconSelector extends React.Component<IconSelectorProps, IconSelectorState> {
  private defaultRowsPerPage = 3;
  private defaultColumnsPerPage = 5;
  private fuse;

  constructor(props: IconSelectorProps) {
    super(props);

    let currentData = semanticIconList as SemanticICONS[];
    if (props.nullable) currentData = [null, ...currentData];

    const rowsPerPage = props.rowsPerPage == null || props.rowsPerPage <= 0 ? this.defaultRowsPerPage : props.rowsPerPage;
    const columnsPerPage =
      props.columnsPerPage == null || props.columnsPerPage <= 0 || props.columnsPerPage > 16
        ? this.defaultColumnsPerPage
        : props.columnsPerPage;

    const pageSize = rowsPerPage * columnsPerPage;
    const totalPages = Math.ceil(currentData.length / pageSize);

    let value = null;
    let activePage = 1;

    if (!isNullOrWhiteSpaces(props.value)) {
      const idx = currentData.findIndex(x => x === props.value);
      if (idx >= 0) {
        value = props.value;
        activePage = Math.ceil(idx / pageSize) + (idx % pageSize === 0 ? 1 : 0);
      }
    }

    this.fuse = new Fuse(semanticIconList);

    this.state = {
      value,
      activePage,
      rowsPerPage,
      columnsPerPage,
      currentData,
      totalPages,
      search: ''
    };
  }

  private getPage = () => {
    const { activePage, rowsPerPage, columnsPerPage, currentData } = this.state;
    const pageSize = rowsPerPage * columnsPerPage;
    const activeIdx = activePage - 1;
    return currentData
      .slice(activeIdx * pageSize, activePage * pageSize)
      .reduce((result: SemanticICONS[][], _: SemanticICONS, index: number, array: SemanticICONS[]) => {
        if (index % columnsPerPage === 0) result.push(array.slice(index, index + columnsPerPage));
        return result;
      }, [] as SemanticICONS[][]);
  };

  private goToPage = (p: number) => {
    const { totalPages } = this.state;
    if (p < 1 || p > totalPages) return;
    this.setState({ activePage: p });
  };

  private goToPrevPage = () => {
    const { activePage } = this.state;
    if (activePage <= 1) return;
    this.setState({ activePage: activePage - 1 });
  };

  private goToNextPage = () => {
    const { totalPages, activePage } = this.state;
    if (activePage >= totalPages) return;
    this.setState({ activePage: activePage + 1 });
  };
  private onChange = (icon: SemanticICONS) => {
    this.setState({ value: icon });
    if (this.props.onChange) this.props.onChange(icon);
  };

  private onSearch = (_, { value }) => {
    const { rowsPerPage, columnsPerPage } = this.state;

    let currentData: SemanticICONS[];

    if (value == null || value === '') {
      currentData = semanticIconList as SemanticICONS[];
    } else {
      // currentData = (semanticIconList as SemanticICONS[]).filter(x => x.startsWith(value));
      currentData = this.fuse.search(value).map(x => x.item) as SemanticICONS[];
    }

    if (this.props.nullable) currentData = [null, ...currentData];

    const totalPages = Math.ceil(currentData.length / (rowsPerPage * columnsPerPage));

    this.setState({
      activePage: 1,
      currentData,
      totalPages,
      search: value
    });
  };

  private onClear = (e: React.MouseEvent) => {
    if (this.props.clearable) {
      e.stopPropagation();
      this.onChange(null);
    }
  };

  public render() {
    const { t, inputProps, popupProps, searchInputProps, iconGridProps, iconGridRowProps, iconGridColumnProps, clearable } = this.props;
    const { value, activePage, columnsPerPage, totalPages, search } = this.state;
    return (
      <Popup
        position="bottom center"
        {...popupProps}
        className={`icon-selector__popup ${popupProps ? popupProps.className : ''}`}
        on="click"
        trigger={
          <div {...inputProps} className={`ui selection dropdown icon-selector__input ${inputProps ? inputProps.className : ''}`}>
            <div className="icon-selector__input__icon-container">
              <Icon className="icon-selector__input__icon" name={value} />
            </div>
            {clearable && value != null ? (
              <i className="icon-selector__input__icon-clear dropdown icon clickable-icon" onClick={this.onClear}></i>
            ) : (
              <i className="icon-selector__input__icon-dropdown dropdown icon clickable-icon"></i>
            )}
          </div>
        }
      >
        <Grid {...iconGridProps} className={`icon-selector__grid ${iconGridProps ? iconGridProps.className : ''}`}>
          <Grid.Row className="icon-selector__grid__search-row">
            <Grid.Column textAlign="center" verticalAlign="middle" width={16}>
              <ClearableTimerInput icon="search" placeholder={t('Search')} {...searchInputProps} value={search} onChange={this.onSearch} />
            </Grid.Column>
          </Grid.Row>
          {this.getPage().map((row: SemanticICONS[], ridx: number) => (
            <Grid.Row
              {...iconGridRowProps}
              className={`icon-selector__grid__icon-row ${iconGridRowProps ? iconGridRowProps.className : ''}`}
              columns={columnsPerPage as SemanticWIDTHS}
              key={`${ridx}-${Math.random()}`}
            >
              {row.map((c: SemanticICONS, cidx: number) => (
                <Grid.Column
                  {...iconGridColumnProps}
                  className={`icon-selector__grid__icon-row__icon-column ${iconGridColumnProps ? iconGridColumnProps.className : ''}`}
                  verticalAlign="middle"
                  textAlign="center"
                  key={`${ridx}-${cidx}-${Math.random()}`}
                >
                  <Icon
                    className={`clickable-icon ${c == null ? 'icon-selector-null-choice' : c === value ? 'icon-selector-selected' : ''}`}
                    name={c}
                    onClick={() => this.onChange(c)}
                  >
                    {c == null ? '\u00A0' : null}
                  </Icon>
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          <Grid.Row className="icon-selector__grid__paginator-row">
            <Grid.Column
              className="icon-selector__grid__paginator-row__double-left-handle"
              textAlign="center"
              verticalAlign="middle"
              width={3}
            >
              <Icon className="clickable-icon" name="angle double left" disabled={activePage === 1} onClick={() => this.goToPage(1)} />
            </Grid.Column>
            <Grid.Column className="icon-selector__grid__paginator-row__left-handle" textAlign="center" verticalAlign="middle" width={3}>
              <Icon className="clickable-icon" name="chevron left" disabled={activePage === 1} onClick={this.goToPrevPage} />
            </Grid.Column>
            <Grid.Column className="icon-selector__grid__paginator-row__center-handle" textAlign="center" verticalAlign="middle" width={4}>
              {`${activePage}/${totalPages}`}
            </Grid.Column>
            <Grid.Column className="icon-selector__grid__paginator-row__right-handle" textAlign="center" verticalAlign="middle" width={3}>
              <Icon className="clickable-icon" name="chevron right" disabled={activePage === totalPages} onClick={this.goToNextPage} />
            </Grid.Column>
            <Grid.Column
              className="icon-selector__grid__paginator-row__double-right-handle"
              textAlign="center"
              verticalAlign="middle"
              width={3}
            >
              <Icon
                className="clickable-icon"
                name="angle double right"
                disabled={activePage === totalPages}
                onClick={() => this.goToPage(totalPages)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Popup>
    );
  }
}

export default withTranslation()(IconSelector);
