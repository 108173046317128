import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface EquipmentTypeDto extends BaseDto {
  id: string;
  name: string;
  clusterId: string;
  clusterName: string;
}

export interface CreateEquipmentTypeDto {
  name: string;
  clusterId: string;
}

export interface ChangeEquipmentTypeDto {
  id: string;
  name: string;
  clusterId: string;
}

export class CreateEquipmentTypeValidator extends AbstractValidator<CreateEquipmentTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type Cluster is required'));
  }
}

export class ChangeEquipmentTypeValidator extends AbstractValidator<ChangeEquipmentTypeDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Equipment Type Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type name is required'));

    this.validateIfString(o => o.clusterId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Equipment Type Cluster is required'));
  }
}

@repository('@@EQUIPMENT-TYPES', 'equipmentTypes.summary')
export class EquipmentTypesStore extends DataStore<EquipmentTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-equipment-type';
  retrievePath = 'get-equipment-types';
  updatePath = 'update-equipment-type';
  retrieveOnePath = 'get-equipment-types';
  deletePath = 'delete-equipment-type';

  protected validate(item: EquipmentTypeDto) {
    return new ChangeEquipmentTypeValidator().validate(item);
  }

  public async getById(id: string): Promise<EquipmentTypeDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<EquipmentTypeDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('EQUIPMENT_TYPE', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EQUIPMENT-TYPES', 'equipmentTypes.dropdown')
export class DropDownEquipmentTypesStore extends DataStore<EquipmentTypeDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = 'get-equipment-types';
  updatePath = '';
  retrieveOnePath = '';
  deletePath = '';

  protected validate(item: EquipmentTypeDto) {
    return new ChangeEquipmentTypeValidator().validate(item);
  }

  constructor() {
    super('EQUIPMENT_TYPEDROPDOWN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(
    query: Query,
    equimentTypeIds?: string[],
    search?: string,
    clusterId?: string
  ): Promise<QueryResult<EquipmentTypeDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    if ((this.state.items || []).length > 0) {
      let equimentTypesDto = this.state.items.map(({ item }) => item);

      if ((equimentTypeIds || []).length > 0) {
        equimentTypesDto = equimentTypesDto.filter(x => equimentTypeIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(clusterId)) {
        equimentTypesDto = equimentTypesDto.filter(x => x.clusterId === clusterId);
      }

      if (!isNullOrWhiteSpaces(search)) {
        equimentTypesDto = equimentTypesDto.filter(x => x.name.startsWith(search));
      }

      let itemResult = { items: equimentTypesDto, count: equimentTypesDto.length } as QueryResult<EquipmentTypeDto>;
      return new Promise<QueryResult<EquipmentTypeDto>>(resolve => resolve(itemResult));
    } else {
      if (query.filter && query.filter instanceof Array && query.filter.length > 0) {
        const result = await httpService.get<QueryResult<EquipmentTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);
        return result.data;
      } else {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<EquipmentTypeDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      }
    }
  }
}

@repository('@@EQUIPMENT-TYPES', 'equipmentTypes.new')
export class NewEquipmentTypeStore extends FormStore<CreateEquipmentTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-equipment-type';
  retrievePath = 'get-equipment-types';
  updatePath = 'update-equipment-type';

  protected validate(item: CreateEquipmentTypeDto) {
    return new CreateEquipmentTypeValidator().validate(item);
  }

  constructor() {
    super('NEW_EQUIPMENT_TYPE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EQUIPMENT-TYPES', 'equipmentTypes.change')
export class ChangeEquipmentTypeStore extends FormStore<ChangeEquipmentTypeDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-equipment-type';
  retrievePath = 'get-equipment-types';
  updatePath = 'update-equipment-type';

  protected validate(item: ChangeEquipmentTypeDto) {
    return new ChangeEquipmentTypeValidator().validate(item);
  }

  constructor() {
    super('CHANGE_EQUIPMENT_TYPE', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
