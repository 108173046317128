import React from 'react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { RequestStatus, ExtendedStatusValidation } from 'stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { replaceAll } from 'utils/useful-functions';
import { userCanEditGeaOrGmaRequest } from 'utils/extended-requests-utils';

interface RequestEditButtonProps extends WithTranslation {
  requestId: string;
  requestStatus: RequestStatus;
  requestCreatorRoles: string[];
  isGea: boolean;
  isGma: boolean;
  extendedStatusValidation?: ExtendedStatusValidation;

  isOnEditMode: boolean;

  onEdit?: () => void;
  onSave?: () => void;
}

class RequestEditButton extends Component<RequestEditButtonProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  render() {
    if (!this.isVisible()) return <></>;

    if (!this.props.isOnEditMode) return this.renderEditButton();

    return this.renderSaveButton();
  }

  renderEditButton() {
    const { t } = this.props;
    return (
      <Button
        className="form__actions__button form__white-btn"
        icon="edit"
        labelPosition="right"
        onClick={() => this.props.onEdit && this.props.onEdit()}
        content={t('Edit')}
      />
    );
  }

  renderSaveButton() {
    const { t } = this.props;
    return (
      <Button
        className="form__actions__button form__white-btn"
        icon="save"
        labelPosition="right"
        onClick={() => this.props.onSave && this.props.onSave()}
        content={t('Save')}
      />
    );
  }

  isVisible(): boolean {
    return this.isVisibleInCurrentStatus() && this.currentUserHasPermission();
  }

  isVisibleInCurrentStatus(): boolean {
    return this.props.requestStatus.toString() === 'Draft' || this.props.requestStatus.toString() === 'Pending';
  }

  currentUserHasPermission(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma, extendedStatusValidation, requestCreatorRoles, requestStatus } = this.props;

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    if ((requestCreatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''))) {
      if (isGma) return userCanEditGeaOrGmaRequest('GMA', extendedStatusValidation, requestCreatorRoles, requestStatus, userInfo);

      if (isGea) return userCanEditGeaOrGmaRequest('GEA', extendedStatusValidation, requestCreatorRoles, requestStatus, userInfo);

      return true;
    }
    return false;
  }
}

export default withTranslation()(RequestEditButton);
