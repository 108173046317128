import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Form, Container, TextArea, Dimmer, Loader, Message, Icon } from 'semantic-ui-react';
import LanguageEditor from 'widgets/bussiness/language-editor';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';
import {
  ChangeSsaQuestionBankStore,
  ChangeSsaQuestionBankDto,
  SsaQuestionBankDto
} from 'stores/assessments/questionBank/ssa-questionBank-store';
import { LanguagesStore } from 'stores/configuration/locations/languages-store';
import { nameof } from 'utils/object';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import { ItemReference } from 'stores/dataStore';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import i18n from 'i18n';
import { ChangeQuestionDto, QuestionDto } from 'stores/assessments/questionBank/tna-questionBank-store';

interface ChangeSsaQuestionBankViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeQuestionBank?: ChangeSsaQuestionBankStore;
  languagesStore?: LanguagesStore;
  currentData: SsaQuestionBankDto;
}

interface ChangeSsaQuestionBankState {
  category: ItemReference;
  skill: ItemReference;
  userClickedSave: boolean;
  questionTranslations: ChangeQuestionDto[];
}

@connect(['changeQuestionBank', ChangeSsaQuestionBankStore], ['languagesStore', LanguagesStore])
class ChangeSsaQuestionBankView extends React.Component<ChangeSsaQuestionBankViewProps, ChangeSsaQuestionBankState> {
  private get changeQuestionBankStore() {
    return this.props.changeQuestionBank;
  }

  constructor(props: ChangeSsaQuestionBankViewProps) {
    super(props);

    this.state = {
      category: Object.assign({ id: this.props.currentData.testCategoryId, title: this.props.currentData.testCategory.name }),
      skill: Object.assign(
        this.props.currentData.ssaSkill
          ? { id: this.props.currentData.ssaSkillId, title: this.props.currentData.ssaSkill.name }
          : { id: null, title: null }
      ),
      userClickedSave: false,
      questionTranslations: this.props.currentData.questionTranslations
        ? this.mapToCreateQuestionDto(this.props.currentData.questionTranslations)
        : []
    };
    this.changeQuestionBankStore.state.result = null;

    this.changeQuestionBankStore.change({
      id: this.props.currentData.id,
      question: { text: this.props.currentData.question.text, languageId: this.props.currentData.question.language.id },
      testCategoryId: this.props.currentData.testCategoryId,
      professions: this.props.currentData.professions ? this.props.currentData.professions.map(p => p.id) : [],
      ssaSkillId: this.props.currentData.ssaSkillId,
      questionTranslations: this.props.currentData.questionTranslations
        ? this.mapToCreateQuestionDto(this.props.currentData.questionTranslations)
        : []
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeSsaQuestionBankViewProps) {
    if (this.changeQuestionBankStore.state.result && this.changeQuestionBankStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSave: false });
    const change = {};
    change[property] = value;
    this.changeQuestionBankStore.change({ ...this.changeQuestionBankStore.state.item, ...change });
  }

  private mapToCreateQuestionDto(items: QuestionDto[]): ChangeQuestionDto[] {
    let data: ChangeQuestionDto[] = [];

    items.forEach(i => {
      data.push({ text: i.text, languageId: i.language.id });
    });
    return data;
  }

  private onChangeQuestion(q: string) {
    let question = this.changeQuestionBankStore.state.item.question;
    if (question) {
      question.text = q;
    } else {
      question = { text: q, languageId: this.props.currentData.question.language.id };
    }
    this.handleValue(nameof<ChangeSsaQuestionBankDto>('question'), question);
  }

  @autobind
  private onChangeItem() {
    this.changeQuestionBankStore.update();
  }

  @autobind
  private onCancelNewItem() {
    this.changeQuestionBankStore.clear();
    this.props.onClose(false);
  }

  addQuestionsTranslation = () => {
    let question = this.state.questionTranslations;
    question.push({ text: '', languageId: null });

    this.setState({ questionTranslations: question });
  };

  handleQuestionTransaltionTextChange = (index: number, questionText: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.text = questionText;

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<ChangeSsaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  handleQuestionTransaltionLanguageChange = (index: number, languageId: string) => {
    let questionTranslations = this.state.questionTranslations;
    let question = questionTranslations[index];
    question.languageId = languageId;
    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<ChangeSsaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  removeDateRange = index => {
    let questionTranslations = this.state.questionTranslations;
    questionTranslations.splice(index, 1);

    this.setState({ questionTranslations }, () =>
      this.handleValue(nameof<ChangeSsaQuestionBankDto>('questionTranslations'), questionTranslations)
    );
  };

  showQuestionTranslationRows = () => {
    return this.state.questionTranslations.map((question, index) => {
      return (
        <Form.Group className="tna-question-bank__new-rows" key={`${question.languageId}_${index}`}>
          <Form.Field
            required
            className="tna-question-bank__label-textarea"
            error={this.state.userClickedSave && question.text.length === 0}
          >
            <TextArea
              maxLength="500"
              className="tna-question-bank__new-rows__textarea"
              value={question ? question.text : ''}
              onChange={(e, { value }) => this.handleQuestionTransaltionTextChange(index, value.toString())}
            />
          </Form.Field>
          <div className="tna-question-bank__new-rows__drop-remove">
            <LanguageEditor
              className={'planit-user-dropdown-custom'}
              nullable
              value={question.languageId}
              onChange={c => {
                this.handleQuestionTransaltionLanguageChange(index, c == null ? null : c.id);
              }}
              onlyForTests={true}
            />

            <div className="new-pattern__remove-icon">
              <Icon className="clickable-icon remove-icon" color="red" name="remove" onClick={() => this.removeDateRange(index)} />
            </div>
          </div>
        </Form.Group>
      );
    });
  };

  public render() {
    const { t } = this.props as any;

    const modalTitle = 'Edit SSA Question';
    return (
      <Modal className="tna-question-bank__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeQuestionBankStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="tna-squestion-bank__modal-header">{t(modalTitle)}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeQuestionBankStore.state.result && !this.changeQuestionBankStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeQuestionBankStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeQuestionBankStore.state.item && (
              <Form>
                <div className="tna-question-bank__wrapper scroll">
                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field className="tna-question-bank__label-input">
                      <label>{t('SSA Skill')}</label>
                      <SkillsEditor
                        nullable
                        skillType={SkillType.SSA}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select SSA Skill')}
                        value={this.state.skill}
                        onChange={s => this.handleValue(nameof<ChangeSsaQuestionBankDto>('ssaSkillId'), s != null ? s.id : null)}
                        clearable
                      />
                    </Form.Field>

                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        (this.changeQuestionBankStore.state.item.testCategoryId == null ||
                          isNullOrWhiteSpaces(this.changeQuestionBankStore.state.item.testCategoryId)) &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Category')}</label>
                      <TestCategorySelector
                        nullable
                        isTnaCategory={false}
                        className="planit-user-dropdown-custom"
                        placeholder={t('Select Category')}
                        value={this.state.category}
                        onChange={category => this.handleValue(nameof<ChangeSsaQuestionBankDto>('testCategoryId'), category?.id)}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className="tna-question-bank__selctor-group">
                    <Form.Field
                      required
                      className="tna-question-bank__label-input"
                      error={
                        (this.changeQuestionBankStore.state.item.professions == null ||
                          this.changeQuestionBankStore.state.item.professions.length === 0) &&
                        this.state.userClickedSave
                      }
                    >
                      <label>{t('Roles')}</label>
                      <MultiProfessionEditor
                        className="planit-user-dropdown"
                        placeholder={t('Roles')}
                        value={this.props.currentData.professions ? this.props.currentData.professions.map(p => p.id) : []}
                        onChange={value => this.handleValue(nameof<ChangeSsaQuestionBankDto>('professions'), value)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group className="tna-question-bank__selctor-textarea">
                    <Form.Field
                      required
                      className="tna-question-bank__label-textarea"
                      error={this.state.userClickedSave && this.changeQuestionBankStore.state.item.question.text.length === 0}
                    >
                      <label>{t('Question')}</label>
                      <TextArea
                        maxLength="500"
                        className="tna-question-bank__custom-editorequest__textarea"
                        value={
                          this.changeQuestionBankStore.state.item.question ? this.changeQuestionBankStore.state.item.question.text : ''
                        }
                        onChange={(e, { value }) => this.onChangeQuestion(value.toString())}
                      />

                      <Button
                        className="custom-table-add-row-btn add-row-btn"
                        type="button"
                        content="Add Row"
                        onClick={this.addQuestionsTranslation}
                      >
                        {i18n.t('Add Translation')}
                      </Button>
                    </Form.Field>
                  </Form.Group>
                  {this.state.questionTranslations.length > 0 && <>{this.showQuestionTranslationRows()}</>}
                </div>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="new-tna-question-bank__btns">
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeSsaQuestionBankView);
