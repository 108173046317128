import React, { Component } from 'react';
import Step from 'widgets/form/templates-step-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import TheoreticalTemplateQuestionComponent from './theoretical-third-step-question-component';
import ChooseMrTheoreticalQuestionsView from './choose-mr-theoretical-wizard-questions';
import ChooseNmrTheoreticalQuestionsView from './choose-nmr-theoretical-wizard-questions';
import {
  TheoreticalTemplateWizardStore,
  QuestionViewMrModel,
  QuestionViewNmrModel,
  toGenerateMrQuestions,
  toGenerateNMrQuestions,
  TheoreticalTemplateWizardViewModel
} from 'stores/assessments/wizard/theoretical-template-wizard-store';
import { connect } from 'redux-scaffolding-ts';
import './questions-step.less';
import { NmrTheoreticalQuestionItemDto, TheoreticalQuestionMRItemDto } from 'stores/assessments/questionBank/theoretical-test-store';

export interface QuestionsStepsProps extends WithTranslation {
  active: boolean;
  theoreticalTemplateWizardStore?: TheoreticalTemplateWizardStore;
  mode: string;
}

export interface QuestionsStepsState {
  showQuestionsModal: boolean;
}

@connect(['theoreticalTemplateWizardStore', TheoreticalTemplateWizardStore])
class QuestionsSteps extends Component<QuestionsStepsProps, QuestionsStepsState> {
  state = { showQuestionsModal: false };

  toShowQuestionsModal = () => {
    this.setState({ showQuestionsModal: true });
  };

  hideQuestionsModal = () => {
    this.setState({ showQuestionsModal: false });
  };

  handleMoveUpQuestion = (questionIdx: number, property: keyof TheoreticalTemplateWizardViewModel) => {
    const { item } = this.props.theoreticalTemplateWizardStore.state;
    let questions = [];

    if (Array.isArray(item[property])) {
      //questions = [...(item[property])];
      questions = [...(item[property] as QuestionViewNmrModel[] | QuestionViewMrModel[])];
      const temporalVariable = { ...questions[questionIdx - 1] };
      questions[questionIdx - 1] = { ...questions[questionIdx] };
      questions[questionIdx] = { ...temporalVariable };
      this.props.theoreticalTemplateWizardStore.change({ [property]: questions });
    }
  };

  handleMoveDownQuestion = (questionIdx: number, property: keyof TheoreticalTemplateWizardViewModel) => {
    const { item } = this.props.theoreticalTemplateWizardStore.state;
    let questions = [];

    if (Array.isArray(item[property])) {
      //questions = [...(item[property])];
      questions = [...(item[property] as QuestionViewNmrModel[] | QuestionViewMrModel[])];
      const temporalVariable = { ...questions[questionIdx + 1] };
      questions[questionIdx + 1] = { ...questions[questionIdx] };
      questions[questionIdx] = { ...temporalVariable };
      this.props.theoreticalTemplateWizardStore.change({ [property]: questions });
    }
  };

  moveQuestionHandler = (dir: 'up' | 'down', idx: number, isMR: boolean = false) => {
    let property: keyof TheoreticalTemplateWizardViewModel = null;
    if (isMR) property = 'questionsMr';
    else property = 'questionsNMr';
    if (dir === 'up') this.handleMoveUpQuestion(idx, property);
    if (dir === 'down') this.handleMoveDownQuestion(idx, property);
  };

  removeQuestionHandler = (idx: number, isMr) => {
    const item = this.props.theoreticalTemplateWizardStore.state.item;
    if (!isMr) this.props.theoreticalTemplateWizardStore.change({ questionsNMr: [...item.questionsNMr].filter((_, i) => i !== idx) });
    else this.props.theoreticalTemplateWizardStore.change({ questionsMr: [...item.questionsMr].filter((_, i) => i !== idx) });
  };

  addQuestionsHandler = (questions: TheoreticalQuestionMRItemDto[] & NmrTheoreticalQuestionItemDto[]) => {
    const { theoreticalTemplateWizardStore } = this.props;
    const { machineRelated, questionsMr, questionsNMr } = theoreticalTemplateWizardStore.state.item;
    if (machineRelated) {
      const addedQuestions = questions as TheoreticalQuestionMRItemDto[];
      const newQuestions: QuestionViewMrModel[] = toGenerateMrQuestions(addedQuestions);
      theoreticalTemplateWizardStore.change({
        questionsMr: [...(questionsMr || []), ...newQuestions]
      });
    } else {
      const addedQuestions = questions as NmrTheoreticalQuestionItemDto[];
      const newQuestions: QuestionViewNmrModel[] = toGenerateNMrQuestions(addedQuestions);
      theoreticalTemplateWizardStore.change({
        questionsNMr: [...(questionsNMr || []), ...newQuestions]
      });
    }
  };

  render() {
    const { t, active, theoreticalTemplateWizardStore } = this.props;
    const { showQuestionsModal } = this.state;
    const {
      questionsMr,
      questionsNMr,
      machineRelated,
      professionId,
      mrCluster,
      equipmentType,
      oem,
      machineModel,
      nmrCluster,
      functionalArea,
      functionalSubarea,
      trainingName
    } = theoreticalTemplateWizardStore.state.item;

    return (
      <div className="theoretical-template-wizard__questions-container">
        <Step
          active={active}
          number={3}
          title={t('SELECT QUESTIONS')}
          render={
            <div className="third-step__content">
              <div className="ssa-wizard__checkpoint-container">
                <div className="ssa-wizard__add-question-btn">
                  <Button onClick={this.toShowQuestionsModal} className="ssa-wizard__add-questions-btn" type="button">
                    {t('Add Questions')}
                  </Button>
                </div>
                {machineRelated &&
                  (questionsMr || []).map((question: QuestionViewMrModel, i) => (
                    <TheoreticalTemplateQuestionComponent
                      key={i}
                      move={dir => this.moveQuestionHandler(dir, i, machineRelated)}
                      canGoUp={i > 0}
                      canGoDown={i + 1 < questionsMr.length}
                      questionMr={question}
                      onRemoveQuestion={() => this.removeQuestionHandler(i, machineRelated)}
                      index={i}
                    />
                  ))}
                {!machineRelated &&
                  (questionsNMr || []).map((question: QuestionViewNmrModel, i) => (
                    <TheoreticalTemplateQuestionComponent
                      key={i}
                      move={dir => this.moveQuestionHandler(dir, i)}
                      canGoUp={i > 0}
                      canGoDown={i + 1 < questionsNMr.length}
                      questionNmr={question}
                      onRemoveQuestion={() => this.removeQuestionHandler(i, machineRelated)}
                      index={i}
                    />
                  ))}
              </div>
              {showQuestionsModal && machineRelated && (
                <ChooseMrTheoreticalQuestionsView
                  professionId={professionId}
                  clusterId={mrCluster && mrCluster.id}
                  equipmentTypeId={equipmentType && equipmentType.id}
                  oemId={oem && oem.id}
                  machineModelId={machineModel && machineModel.id}
                  onAddTheoreticalQuestions={this.addQuestionsHandler}
                  onCloseModal={this.hideQuestionsModal}
                  alreadySelectedQuestions={questionsMr.map(({ id }) => id)}
                />
              )}
              {showQuestionsModal && !machineRelated && (
                <ChooseNmrTheoreticalQuestionsView
                  professionId={professionId}
                  functionalAreaId={functionalArea && functionalArea.id}
                  functionalSubAreaId={functionalSubarea && functionalSubarea.id}
                  clusterId={nmrCluster && nmrCluster.id}
                  trainingNameId={trainingName && trainingName.id}
                  onAddTheoreticalQuestions={this.addQuestionsHandler}
                  onCloseModal={this.hideQuestionsModal}
                  alreadySelectedQuestions={questionsNMr.map(({ id }) => id)}
                />
              )}
            </div>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(QuestionsSteps);
