import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from '../../dataStore';
import { FormStore } from '../../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { TestCategoryDto } from '../testCategory-store';
import { EventDto } from 'stores/events/events-store';

export interface TnaSkillDto extends BaseDto {
  id: string; //Guid
  friendlyId: string;
  name: string;
  description: string;
  testCategoryId: string;
  testCategory: TestCategoryDto;
  isMachineRelated: boolean;
}

export interface CreateTnaSkillDto {
  testCategoryId: string;
  description: string;
  name: string;
  isMachineRelated: boolean;
}

export interface ChangeTnaSkillDto {
  id: string;
  testCategoryId: string;
  description: string;
  name: string;
  isMachineRelated: boolean;
}

export class CreateTnaSkillValidator extends AbstractValidator<CreateTnaSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.testCategoryId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));

    this.validateIf(o => o.isMachineRelated)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Must specify if Machine Related'));
  }
}

export class ChangeTnaSkillValidator extends AbstractValidator<ChangeTnaSkillDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Skill Id is required'));

    this.validateIfString(o => o.testCategoryId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIfString(o => o.description)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Description is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Skill is required'));

    this.validateIf(o => o.isMachineRelated)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Must specify if Machine Related'));
  }
}

@repository('@@TNASKILLS', 'tnaskills.summary')
export class TnaSkillsStore extends DataStore<TnaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-skill';
  retrievePath = 'get-tna-skills';
  updatePath = 'update-tna-skill';
  deletePath = 'delete-tna-skill';
  retrieveOnePath = 'get-tna-skill';
  retrieveCountPath = 'get-used-skill-count';
  retrieveEventsStudents = 'get-events-students-by-form-id';

  protected validate(item: TnaSkillDto) {
    return new ChangeTnaSkillValidator().validate(item);
  }

  constructor() {
    super('TNASKILL', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getSkillById(id: string): Promise<TnaSkillDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<TnaSkillDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  public async getUsedSkillCountById(id: string): Promise<number> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<number>(`${this.baseUrl}/${this.retrieveCountPath}/${id}`)
    );
    return result.data;
  }

  public async getEventsByEmployeeAndFormIds(query: Query, userId: string, formId: string): Promise<QueryResult<EventDto>> {
    const httpService = container.get(HttpService);

    query.toBody = true;
    const { path, body } = DataStore.getRequestParts(query);

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.post<any, QueryResult<EventDto>>(`${this.baseUrl}/${this.retrieveEventsStudents}?${path}`, {
        ...body,
        StudentsIds: [userId],
        FormId: formId
      })
    );

    return result.data;
  }
}

@repository('@@TNASKILLS', 'tnaskills.new')
export class NewTnaSkillStore extends FormStore<CreateTnaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-skill';
  retrievePath = 'get-tna-skills';
  updatePath = 'update-tna-skill';

  protected validate(item: CreateTnaSkillDto) {
    return new CreateTnaSkillValidator().validate(item);
  }

  constructor() {
    super('NEW_TNASKILL', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@TNASKILLS', 'tnaskills.change')
export class ChangeTnaSkillStore extends FormStore<ChangeTnaSkillDto> {
  baseUrl = 'skills/v1';
  createPath = 'new-tna-skill';
  retrievePath = 'get-tna-skills';
  updatePath = 'update-tna-skill';

  protected validate(item: ChangeTnaSkillDto) {
    return new ChangeTnaSkillValidator().validate(item);
  }

  constructor() {
    super('CHANGE_TNASKILL', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
