import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { DeliveryTypeDto, DropDownDeliveryTypesStore } from '../../../stores/configuration/events-n-requests/delivery-types-store';
import { nameof } from '../../../utils/object';
import { DropdownProps } from 'widgets/form/selectionInput';
import { Dropdown } from 'semantic-ui-react';

interface DeliveryTypeselectorProps extends DropdownProps, WithTranslation {
  value: string;
  onChange?: (value: string) => void;
  deliveryTypes?: DropDownDeliveryTypesStore;
  direction?: 'left' | 'right';
}

@connect(['deliveryTypes', DropDownDeliveryTypesStore])
class DeliveryTypesSelector extends React.Component<DeliveryTypeselectorProps> {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    this.props.deliveryTypes
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<DeliveryTypeDto>('name'), useProfile: false }],
        filter: []
      })
      .then(response => {
        this.setState({
          isLoading: false,
          options: response.items.map(item => ({ value: item.id, text: item.name }))
        });
      });
  }

  public render() {
    const { isLoading, options } = this.state;
    const { searchable, clearable, className, placeholder, onChange, value } = this.props;
    const selectedValue = value || null;

    return (
      <Dropdown
        selection
        loading={isLoading}
        search={searchable}
        clearable={clearable}
        className={className}
        placeholder={placeholder}
        value={selectedValue}
        options={options}
        onChange={(_, { value }) => onChange(value as string)}
        selectOnBlur={false}
      />
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(DeliveryTypesSelector);
