import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../assets/less/not-found.less';
import ErrorImage from '../assets/img/error.svg';

export class NotFoundView extends React.Component<any, any> {
  render() {
    const { t } = this.props as any;
    return (
      <div className="error-404__container display-flex-center">
        <div className="error404__message-wrapper display-flex-center">
          <div className="error404__img-container display-flex-center">
            <img className="error404__image" src={ErrorImage} alt="error 404" />
          </div>
          <p className="error404__title"> {t('Resource not found')}</p>
          <p className="error404__info-text">
            {' '}
            {t('Select an item from the left menu or return to')} <Link to={`/`}>{t('home')}</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotFoundView);
