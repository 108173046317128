import { BaseDto, ItemResult } from '../../types';
import { ValidationFailure } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import i18n from '../../../i18n';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { PatternRowDto, CreatePatternRowDto, CreatePatternRowValidator, ChangePatternRowDto } from './pattern-rows-store';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { PlcTypeDto } from './plc-types-store';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { isNullOrEmpty } from 'utils/useful-functions';

export interface PatternDto extends BaseDto {
  id: string;
  name: string;
  patternRows: PatternRowDto[];
  active: boolean;
}

export interface CreatePatternDto {
  name: string;
  patternRows: CreatePatternRowDto[];
  active: boolean;
}

export interface ChangePatternDto {
  id: string;
  name: string;
  patternRows: ChangePatternRowDto[];
  active: boolean;
}

export class IdNameDto {
  id: string;
  name: string;
}

export class HierarchyResponseDto {
  clusters: IdNameDto[];
  equipmentTypes: IdNameDto[];
  oems: IdNameDto[];
  machineModels: IdNameDto[];
  machineUnits: IdNameDto[];
  plcTypes: IdNameDto[];
}

export class HierarchyDto {
  clusterIds?: string[];
  equipmentTypeIds?: string[];
  oemIds?: string[];
  machineModelIds?: string[];
  machineUnitIds?: string[];
  plcTypeIds?: string[];
}

export class CreatePatternValidator extends ExtendedAbstractValidator<CreatePatternDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pattern name is required'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.patternRows || []).all((i, idx) => i != null && new CreatePatternRowValidator(idx, this.addErrors).extendValidate(i).isValid())
      )
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ patternRows }) => {
        const machines = (patternRows || []).filter(x => !isNullOrEmpty(x?.machineModelId)).map(x => x?.machineModelId);
        const isRepeated = (machines || []).some((item, index) => machines.indexOf(item) !== index);
        return !isRepeated;
      })
      .when(({ patternRows }) => (patternRows || []).length > 1)
      .withFailureMessage(i18n.t('There are Pattern Rows repeated'));
  }
}

export class ChangePatternValidator extends ExtendedAbstractValidator<ChangePatternDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Pattern Id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Pattern name is required'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.patternRows || []).all((i, idx) => i != null && new CreatePatternRowValidator(idx, this.addErrors).extendValidate(i).isValid())
      )
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ patternRows }) => {
        const machines = (patternRows || []).filter(x => !isNullOrEmpty(x?.machineModelId)).map(x => x?.machineModelId);
        const isRepeated = (machines || []).some((item, index) => machines.indexOf(item) !== index);
        return !isRepeated;
      })
      .when(({ patternRows }) => (patternRows || []).length > 1)
      .withFailureMessage(i18n.t('There are Pattern Rows repeated'));
  }
}

@repository('@@PATTERNS', 'patterns.summary')
export class PatternsStore extends DataStore<PatternDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-pattern';
  retrievePath = 'get-patterns';
  retrieveOnePath = 'get-pattern';
  updatePath = 'update-pattern';
  deletePath = '';

  retrieveOnePLCPath = 'get-plc-type';

  protected validate(item: PatternDto) {
    return new ChangePatternValidator().validate(item);
  }

  public async getById(id: string): Promise<PatternDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<PatternDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );

    return result.data;
  }

  public async getPlcNameById(id: string): Promise<PlcTypeDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePLCPath,
      httpService.get<PlcTypeDto>(`${this.baseUrl}/${this.retrieveOnePLCPath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('PATTERN', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@PATTERNS', 'patterns.new')
export class NewPatternStore extends FormStore<CreatePatternDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-pattern';
  retrievePath = 'get-patterns';
  updatePath = 'update-pattern';

  protected validate(item: CreatePatternDto) {
    return new CreatePatternValidator().validate(item);
  }

  constructor() {
    super('NEW_PATTERN', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PATTERNS', 'patterns.change')
export class ChangePatternStore extends FormStore<ChangePatternDto> {
  baseUrl = 'machines/v1';
  createPath = 'new-pattern';
  retrievePath = 'get-patterns';
  updatePath = 'update-pattern';

  protected validate(item: ChangePatternDto) {
    return new ChangePatternValidator().validate(item);
  }

  constructor() {
    super('CHANGE_PATTERN', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@PATTERNS', 'patterns.hierarchy')
export class HierarchyStore extends DataStore<HierarchyResponseDto> {
  baseUrl = 'machines/v1';
  createPath = '';
  retrievePath = '';
  retrieveOnePath = 'get-hierarchy';
  updatePath = '';
  deletePath = '';

  protected validate(item: HierarchyResponseDto) {
    return null; //new ChangePatternValidator().validate(item);
  }

  public async get(data: HierarchyDto): Promise<ItemResult<HierarchyResponseDto>> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.post<HierarchyDto, ItemResult<HierarchyResponseDto>>(`${this.baseUrl}/${this.retrieveOnePath}`, data)
    );

    return result.data;
  }
  constructor() {
    super('HIERARCHY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}
