import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import {
  TrainingLevelDto,
  ChangeTrainingLevelDto,
  ChangeTrainingLevelStore
} from 'stores/configuration/events-n-requests/training-levels-store';
import { nameof } from 'utils/object';

interface ChangeTrainingLevelViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeTrainingLevel?: ChangeTrainingLevelStore;
  currentDto: TrainingLevelDto;
}

// tslint:disable-next-line:no-empty-interface
interface ChangeTrainingLevelViewState {
  editorValue: any;
}

@connect(['changeTrainingLevel', ChangeTrainingLevelStore])
class ChangeTrainingLevelView extends React.Component<ChangeTrainingLevelViewProps, ChangeTrainingLevelViewState> {
  private get changeTrainingLevelStore() {
    return this.props.changeTrainingLevel;
  }

  constructor(props: ChangeTrainingLevelViewProps) {
    super(props);

    this.changeTrainingLevelStore.state.result = null;

    this.changeTrainingLevelStore.change({
      id: this.props.currentDto.id,
      name: this.props.currentDto.name
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeTrainingLevelViewProps) {
    if (this.changeTrainingLevelStore.state.result && this.changeTrainingLevelStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.changeTrainingLevelStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeTrainingLevelStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.changeTrainingLevelStore.change({ ...this.changeTrainingLevelStore.state.item, ...change });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.changeTrainingLevelStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('Edit Training Level')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.changeTrainingLevelStore.state.result && !this.changeTrainingLevelStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.changeTrainingLevelStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.changeTrainingLevelStore.state.item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    error={!this.changeTrainingLevelStore.state.item.name}
                    label={t('Training Level')}
                    placeholder={t('Name')}
                    value={this.changeTrainingLevelStore.state.item.name}
                    onChange={(e, { value }) => this.handleValue(nameof<ChangeTrainingLevelDto>('name'), value)}
                  />
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onCancelChangeItem}>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeTrainingLevelView);
