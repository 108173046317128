import React from 'react';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { UserImportStore } from '../../../../../../stores/user-import/user-import-store';
import { Modal, Message, Button } from 'semantic-ui-react';
import './user-import-delete-popup.less';

export interface UserImportDeletePopupProps extends WithTranslation {
  open: boolean;
  userImportStore?: UserImportStore;
  selectedItem: any;

  onCancelClicked?: () => void;
  onAcceptClicked?: () => void;
  onError?: () => void;
}

@connect(['userimport', UserImportStore])
class UserImportDeletePopup extends Component<UserImportDeletePopupProps> {
  handleOnCancelClicked() {
    this.props.onCancelClicked && this.props.onCancelClicked();
  }

  handleValue(property: string, value: any) {
    const state = this.state;
    state[property] = value;
    this.setState(state);
  }

  render() {
    const { t } = this.props;

    return (
      <Modal
        open={this.props.open}
        size="tiny"
        className="delete-user-import-popup"
        centered={true}
        closeOnEscape={true}
        onClose={() => this.handleOnCancelClicked()}
      >
        <Modal.Content className="delete-user-import-popup__content">
          {this.props.userImportStore.state.result && !this.props.userImportStore.state.result.isSuccess && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={this.props.userImportStore.state.result.messages.map(o => o.body)}
            />
          )}
          <div className="delete-user-import-popup__description">
            <p>{t('Are you sure you want to delete this record?')}</p>
            <p>{t('Please, remember that this action is not reversible.')}</p>
          </div>
        </Modal.Content>
        <div className="delete-user-import-popup__buttons-container">
          <Button
            className="delete-user-import-popup__btn delete-user-import-popup__cancel-btn"
            content={t('Cancel')}
            onClick={() => this.handleOnCancelClicked()}
          />
          <Button
            className="delete-user-import-popup__btn delete-user-import-popup__pending-btn"
            content={t('Accept')}
            onClick={this.props.onAcceptClicked}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(UserImportDeletePopup);
