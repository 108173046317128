import * as React from 'react';
import { Flag, FlagNameValues } from 'semantic-ui-react';
import { validFlag } from 'utils/country-infos';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface ICountryFlagProps {
  countryName?: string;
  countryIsoCode?: string;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
}

export const CountryFlag: React.FunctionComponent<ICountryFlagProps> = (props: ICountryFlagProps) => {
  if (!isNullOrWhiteSpaces(props.countryIsoCode) && validFlag(props.countryIsoCode.toLowerCase())) {
    return (
      <Flag id={props.id} className={props.className} style={props.style} name={props.countryIsoCode.toLowerCase() as FlagNameValues} />
    );
  }
  if (!isNullOrWhiteSpaces(props.countryName) && validFlag(props.countryName.toLowerCase())) {
    return <Flag id={props.id} className={props.className} style={props.style} name={props.countryName.toLowerCase() as FlagNameValues} />;
  }
  return null;
};
