import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ChangeSsaDevelopmentPlanStore, SsaDevelopmentPlanDto } from 'stores/development-plan/ssa-development-plan.store';
import { connect } from 'redux-scaffolding-ts';
import { Modal, Button, Message, Loader, Dimmer, TextArea } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { UserProfileListViewModel } from './user-profile-list';
import { ItemReference } from 'stores/dataStore';
import YearEditor from 'widgets/bussiness/year-editor';
import DevelopmentPlanStatusEditor from 'widgets/bussiness/development-plan-status-editor';
import UserProfileList from './user-profile-list';
import './edit-development-plan.modal.less';
import { DevelopmentPlanStatus } from 'stores/development-plan/development-plan-status.enum';
import TestCategorySelector from 'widgets/bussiness/selectors/test-category-selector';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';
import SsaActivityTypeEditor from 'widgets/bussiness/ssa-activity-type-editor';

export interface EditDevelopmentPlanModalProps extends WithTranslation {
  open: boolean;
  developmentPlan: SsaDevelopmentPlanDto;
  onClose?: () => void;
  changeSsaDevelopmentPlanStore?: ChangeSsaDevelopmentPlanStore;
}

interface EditDevelopmentPlanModalState {
  employee: UserProfileListViewModel;
  category: ItemReference;
  skill: ItemReference;
  activity: ItemReference;
  activityDetails: string;
  status: DevelopmentPlanStatus;
}

@connect(['changeSsaDevelopmentPlanStore', ChangeSsaDevelopmentPlanStore])
class EditDevelopmentPlanModal extends Component<EditDevelopmentPlanModalProps, EditDevelopmentPlanModalState> {
  constructor(props: Readonly<EditDevelopmentPlanModalProps>) {
    super(props);

    const { developmentPlan } = props;

    this.state = {
      employee: {
        id: developmentPlan.userId,
        employeeId: developmentPlan.user && developmentPlan.user.employeeId,
        employee: developmentPlan.user && `${developmentPlan.user.lastName}, ${developmentPlan.user.firstName}`,
        sfPosition: developmentPlan.user && developmentPlan.user.sfPosition,
        location:
          developmentPlan.user &&
          developmentPlan.user.location &&
          `${developmentPlan.user.location.location} - ${developmentPlan.user.location.countryName}`,
        profile: developmentPlan.profileName
      },
      category: developmentPlan.ssaSkill &&
        developmentPlan.ssaSkill.testCategory && {
          id: developmentPlan.ssaSkill.testCategory.id,
          title: developmentPlan.ssaSkill.testCategory.name
        },
      skill: developmentPlan.ssaSkill && { id: developmentPlan.ssaSkill.id, title: developmentPlan.ssaSkill.name },
      activity: developmentPlan.ssaActivityItem && { id: developmentPlan.ssaActivityItem.id, title: developmentPlan.ssaActivityItem.title },
      activityDetails: developmentPlan.activityDetails,
      status: developmentPlan.status
    };
  }

  private handleOnValueChanged = (property: string, value: any) => {
    let newState = { ...this.state };
    newState[property] = value;

    this.setState(newState);
  };

  private handleOnClose = () => {
    this.props.changeSsaDevelopmentPlanStore.reset();
    this.props.onClose && this.props.onClose();
  };

  private handleOnAccept = () => {
    const { developmentPlan } = this.props;
    const { activity, activityDetails, status } = this.state;

    this.props.changeSsaDevelopmentPlanStore.createNew({
      id: developmentPlan.id,
      nextStatus: status,
      activityId: activity && activity.id,
      activityDetails: activityDetails
    });

    this.props.changeSsaDevelopmentPlanStore.update().then(x => {
      if (x.isSuccess) this.props.onClose && this.props.onClose();
    });
  };

  render() {
    const { t, open, developmentPlan, changeSsaDevelopmentPlanStore: store } = this.props;
    const { employee, category, skill, activity, activityDetails, status } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    return (
      <Modal
        open={open}
        className="ssa-edit-development-plan-modal"
        onClose={this.handleOnClose}
        centered={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        //closeOnEscape={false}
        closeOnEscape={true}
        closeOnPortalMouseLeave={false}
      >
        <Dimmer active={store.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate />
        </Dimmer>
        <Modal.Header className="ssa-edit-development-plan-modal__header">{t('Edit SSA Development Plan')}</Modal.Header>
        <Modal.Content className="ssa-edit-development-plan-modal__content">
          {messages.length > 0 && (
            <Message
              className="error-message__style"
              icon="exclamation circle"
              error
              header={t('An error ocurred')}
              list={messages.map(o => o.body)}
              onDismiss={() => this.props.changeSsaDevelopmentPlanStore.clearMessages()}
            />
          )}
          <div className="ssa-edit-development-plan-modal__field">
            <label>{t('Employee')}</label>
          </div>
          <UserProfileList userProfiles={[employee]} />
          <div className="ssa-edit-development-plan-modal__field">
            <label>{t('Select SSA Skill')}</label>
            <div className="flex-column">
              <label>{t('Category')}</label>
              <TestCategorySelector
                readOnly
                clearable
                nullable
                placeholder={t('SSA Skill Category')}
                isTnaCategory={false}
                value={category}
              />
            </div>
            <div className="flex-column">
              <label>{t('SSA Skill')}</label>
              <SkillsEditor clearable nullable readOnly placeholder={t('SSA Skill')} skillType={SkillType.SSA} value={skill} />
            </div>
          </div>
          <div className="ssa-edit-development-plan-modal__field flex-start">
            <label>{t('Select Activity')}</label>
            <SsaActivityTypeEditor
              className="custom-editor"
              placeholder={t('Activity')}
              value={activity}
              onChange={value => this.handleOnValueChanged(nameof<EditDevelopmentPlanModalState>('activity'), value)}
            />
            <TextArea
              className="ssa-development-plan-modal__textarea"
              placeholder={t('Details about the activity')}
              value={activityDetails}
              onChange={(_, { value }) =>
                this.handleOnValueChanged(nameof<EditDevelopmentPlanModalState>('activityDetails'), value as string)
              }
            />
          </div>
          <div className="ssa-edit-development-plan-modal__field">
            <label>{t('Set Deadline')}</label>
            <YearEditor readOnly className="custom-editor year-editor" placeholder={t('Year')} value={developmentPlan.deadlineYear} />
          </div>
          <div className="ssa-edit-development-plan-modal__field">
            <label>{t('Set Status')}</label>
            <DevelopmentPlanStatusEditor
              className="custom-editor"
              placeholder={t('Status')}
              value={status}
              onChange={value => this.handleOnValueChanged(nameof<EditDevelopmentPlanModalState>('status'), value)}
            />
          </div>
        </Modal.Content>
        <Modal.Actions className="ssa-edit-development-plan-modal__actions">
          <Button className="basic" onClick={this.handleOnClose}>
            {t('Cancel')}
          </Button>
          <Button className="confirm-changes-btn positive" onClick={this.handleOnAccept}>
            {t('Confirm Changes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(EditDevelopmentPlanModal);
