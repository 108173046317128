import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Button } from 'semantic-ui-react';
import FormRow from 'site/pages/shared/events-and-requests/form-row';
import { faIdCardAlt, faThLarge, faCogs, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import LocationEditor from 'widgets/bussiness/location-editor';
import PatternMachines, { PatternMachinesViewModel } from 'site/pages/shared/events-and-requests/pattern-machines';
import NonMachineRelatedComponent, {
  NonMachineRelatedComponentViewModel
} from 'site/pages/shared/events-and-requests/non-machine-related/non-machine-related';
import './request-details-tab.less';
import { RequestFormStore, RequestFormViewModel } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import { EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';

export interface RequestDetailsTabPaneProps extends WithTranslation {
  readOnly: boolean;
  requestFormStore?: RequestFormStore;
}

@connect(['requestFormStore', RequestFormStore])
class RequestDetailsTabPane extends Component<RequestDetailsTabPaneProps> {
  private handleOnChange = (property: keyof RequestFormViewModel, value: any) => {
    let change: Partial<RequestFormViewModel> = {};
    change[property as string] = value;
    if (property === 'machineRelated' || property === 'category') {
      change.nmrCluster = null;
      change.nmrFunctionalArea = null;
      change.nmrTrainingName = null;
      change.nmrFunctionalSubArea = null;
    }
    if (property === 'machineRelated' && value) change.category = 'Technical' as any;
    if (property === 'category' && value === 'Functional') change.machineRelated = false;
    this.props.requestFormStore.change(change);
  };

  private handleOnPatternMachinesChange = ({ pattern, machines }: PatternMachinesViewModel) => {
    this.props.requestFormStore.change({ pattern, machines });
  };

  private handleNMRChange = (value: NonMachineRelatedComponentViewModel) => {
    this.props.requestFormStore.change({
      nmrCluster: value.cluster,
      nmrFunctionalArea: value.functionalArea,
      nmrFunctionalSubArea: value.functionalSubArea,
      nmrTrainingName: value.trainingName
    });
  };

  render() {
    const { t, readOnly, requestFormStore } = this.props;
    const { nmrFunctionalArea, nmrFunctionalSubArea, nmrTrainingName, machineRelated, ...rest } = requestFormStore.state.item;
    const { eventTypeCategory, eventLocation, profession, nmrCluster, category, pattern, machines } = rest;
    const patternMachines: PatternMachinesViewModel = { pattern, machines };

    const nMR: NonMachineRelatedComponentViewModel = {
      cluster: nmrCluster,
      functionalArea: nmrFunctionalArea,
      functionalSubArea: nmrFunctionalSubArea,
      trainingName: nmrTrainingName
    };

    return (
      <Form className="form__event-details__container">
        <FormRow
          className="form__event-details-tab__role form__event-details-tab__element"
          label={t('Event Location')}
          icon={faMapMarkerAlt}
        >
          <LocationEditor
            readOnly={readOnly}
            className="form__header__dropdown-style"
            value={eventLocation}
            onChange={value => this.handleOnChange('eventLocation', value)}
            showCountry={true}
          />
        </FormRow>

        {(profession || !readOnly) && eventTypeCategory && eventTypeCategory !== EventTypeCategory.Extended && (
          <FormRow className="form__event-details-tab__role form__event-details-tab__element" label={t('Role')} icon={faIdCardAlt}>
            <ProfessionEditor
              nullable
              clearable
              readOnly={readOnly}
              className="form__header__dropdown-style"
              value={profession}
              onChange={value => this.handleOnChange('profession', value)}
            />
          </FormRow>
        )}

        {eventTypeCategory && eventTypeCategory !== EventTypeCategory.Extended && (
          <FormRow className="form__event-details-tab__category form__event-details-tab__element" label={t('Category')} icon={faThLarge}>
            <CategoryFormEditor
              readOnly={readOnly}
              className="form__header__dropdown-style"
              value={category}
              onChange={value => this.handleOnChange('category', value)}
            />
          </FormRow>
        )}

        {eventTypeCategory && eventTypeCategory !== EventTypeCategory.Extended && (
          <FormRow
            className="form__event-details-tab__category form__event-details-tab__element"
            label={t('Machine Related')}
            icon={faCogs}
          >
            <Form.Field className="planit-requestform-fields" inline>
              <Button.Group>
                <Button
                  disabled={readOnly}
                  attached="left"
                  className={machineRelated ? 'events-form__btn__positive' : 'events-form__btn__negative'}
                  onClick={() => this.handleOnChange('machineRelated', true)}
                >
                  {t('Machine Related')}
                </Button>
                <Button
                  disabled={readOnly}
                  attached="right"
                  className={machineRelated ? 'events-form__btn__negative' : 'events-form__btn__positive'}
                  onClick={() => this.handleOnChange('machineRelated', false)}
                >
                  {t('Non-Machine Related')}
                </Button>
              </Button.Group>
            </Form.Field>
          </FormRow>
        )}

        {eventTypeCategory && eventTypeCategory !== EventTypeCategory.Extended && (
          <>
            <div className="form__event-details-tab__mr__pattern form__event-details-tab__element mr-table-component__common-styles">
              {machineRelated && (
                <PatternMachines readOnly={readOnly} value={patternMachines} onChange={this.handleOnPatternMachinesChange} />
              )}
            </div>

            {!machineRelated && (
              <NonMachineRelatedComponent category={category} value={nMR} readOnly={readOnly} onChange={this.handleNMRChange} />
            )}
          </>
        )}
      </Form>
    );
  }
}

export default withTranslation()(RequestDetailsTabPane);
