import { DeliveryTypesStore, NewDeliveryTypeStore, ChangeDeliveryTypeStore, DropDownDeliveryTypesStore } from './delivery-types-store';
import { TrainingLevelsStore, NewTrainingLevelStore, ChangeTrainingLevelStore, DropDownTrainingLevelsStore } from './training-levels-store';
import { NMClustersStore, NewNMClusterStore, ChangeNMClusterStore, DropDownNMClustersStore } from './non-machine-related/clusters-store';
import { NMCategoriesStore, NewNMCategoryStore, ChangeNMCategoryStore } from './non-machine-related/categories-store';
import {
  NMFunctionalAreasStore,
  NewNMFunctionalAreaStore,
  ChangeNMFunctionalAreaStore,
  DropDownNMFunctionalAreasStore
} from './non-machine-related/functional-areas-store';
import {
  NMTrainingNamesStore,
  NewNMTrainingNameStore,
  ChangeNMTrainingNameStore,
  DropDownNMTrainingNamesStore
} from './non-machine-related/training-names-store';
import {
  NMFunctionalSubareasStore,
  NewNMFunctionalSubareaStore,
  ChangeNMFunctionalSubareaStore,
  DropDownNMFunctionalSubareasStore
} from './non-machine-related/functional-subareas-store';
import {
  NmrHoursDurationStore,
  NewNmrHoursDurationStore,
  ChangeNmrHoursDurationStore
} from './non-machine-related/nmr-hours-durations-store';
import { MrHoursDurationsStore, NewMrHoursDurationStore, ChangeMrHoursDurationStore } from './mr-hours-durations-store';
import { CustomizationDataStore, NewCustomizationDataStore, ChangeCustomizationDataStore } from './customization-data-store';
import { PriorityStore, NewPriorityStore, ChangePriorityStore, DropDownPriorityStore } from './priorities-store';
import { RequestReasonStore, NewRequestReasonStore, ChangeRequestReasonStore } from './request-reasons-store';
import { CheckListStore, NewCheckListStore, ChangeCheckListStore } from './checklists-store';
import { ChangeRejectReasonsStore, NewRejectReasonsStore, RejectReasonsStore } from './rejection-reasons-store';
import { RejectedByStore, NewRejectedByStore, ChangeRejectedByStore } from './rejected-by-store';
import {
  EventTypeGroupsStore,
  DropDownEventTypeGroupsStore,
  NewEventTypeGroupStore,
  ChangeEventTypeGroupStore
} from './event-type-group-store';

import { TypeHoursCodeStore, DropDownTypeHoursCodeStore, NewTypeHoursCodeStore, ChangeTypeHoursCodeStore } from './type-hour-code-store';

import {
  EventGroupRelationshipsStore,
  DropDownEventGroupRelationshipsStore,
  NewEventGroupRelationshipStore,
  ChangeEventGroupRelationshipStore
} from './event-group-rel-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new CheckListStore());
  storeBuilder.addRepository(new NewCheckListStore());
  storeBuilder.addRepository(new ChangeCheckListStore());
  storeBuilder.addRepository(new DeliveryTypesStore());
  storeBuilder.addRepository(new NewDeliveryTypeStore());
  storeBuilder.addRepository(new ChangeDeliveryTypeStore());
  storeBuilder.addRepository(new TrainingLevelsStore());
  storeBuilder.addRepository(new DropDownTrainingLevelsStore());
  storeBuilder.addRepository(new NewTrainingLevelStore());
  storeBuilder.addRepository(new ChangeTrainingLevelStore());
  storeBuilder.addRepository(new NMClustersStore());
  storeBuilder.addRepository(new NewNMClusterStore());
  storeBuilder.addRepository(new ChangeNMClusterStore());
  storeBuilder.addRepository(new NMCategoriesStore());
  storeBuilder.addRepository(new NewNMCategoryStore());
  storeBuilder.addRepository(new ChangeNMCategoryStore());
  storeBuilder.addRepository(new NMFunctionalAreasStore());
  storeBuilder.addRepository(new DropDownNMFunctionalAreasStore());
  storeBuilder.addRepository(new NewNMFunctionalAreaStore());
  storeBuilder.addRepository(new ChangeNMFunctionalAreaStore());
  storeBuilder.addRepository(new NMTrainingNamesStore());
  storeBuilder.addRepository(new DropDownNMTrainingNamesStore());
  storeBuilder.addRepository(new NewNMTrainingNameStore());
  storeBuilder.addRepository(new ChangeNMTrainingNameStore());
  storeBuilder.addRepository(new NMFunctionalSubareasStore());
  storeBuilder.addRepository(new DropDownNMFunctionalSubareasStore());
  storeBuilder.addRepository(new NewNMFunctionalSubareaStore());
  storeBuilder.addRepository(new ChangeNMFunctionalSubareaStore());
  storeBuilder.addRepository(new CustomizationDataStore());
  storeBuilder.addRepository(new NewCustomizationDataStore());
  storeBuilder.addRepository(new ChangeCustomizationDataStore());
  storeBuilder.addRepository(new PriorityStore());
  storeBuilder.addRepository(new NewPriorityStore());
  storeBuilder.addRepository(new ChangePriorityStore());
  storeBuilder.addRepository(new DropDownPriorityStore());
  storeBuilder.addRepository(new RequestReasonStore());
  storeBuilder.addRepository(new NewRequestReasonStore());
  storeBuilder.addRepository(new ChangeRequestReasonStore());
  storeBuilder.addRepository(new DropDownDeliveryTypesStore());
  storeBuilder.addRepository(new NmrHoursDurationStore());
  storeBuilder.addRepository(new NewNmrHoursDurationStore());
  storeBuilder.addRepository(new ChangeNmrHoursDurationStore());
  storeBuilder.addRepository(new MrHoursDurationsStore());
  storeBuilder.addRepository(new NewMrHoursDurationStore());
  storeBuilder.addRepository(new ChangeMrHoursDurationStore());
  storeBuilder.addRepository(new ChangeRejectReasonsStore());
  storeBuilder.addRepository(new NewRejectReasonsStore());
  storeBuilder.addRepository(new RejectReasonsStore());
  storeBuilder.addRepository(new RejectedByStore());
  storeBuilder.addRepository(new NewRejectedByStore());
  storeBuilder.addRepository(new ChangeRejectedByStore());
  storeBuilder.addRepository(new EventTypeGroupsStore());
  storeBuilder.addRepository(new DropDownEventTypeGroupsStore());
  storeBuilder.addRepository(new NewEventTypeGroupStore());
  storeBuilder.addRepository(new ChangeEventTypeGroupStore());
  storeBuilder.addRepository(new TypeHoursCodeStore());
  storeBuilder.addRepository(new DropDownTypeHoursCodeStore());
  storeBuilder.addRepository(new NewTypeHoursCodeStore());
  storeBuilder.addRepository(new ChangeTypeHoursCodeStore());
  storeBuilder.addRepository(new EventGroupRelationshipsStore());
  storeBuilder.addRepository(new DropDownEventGroupRelationshipsStore());
  storeBuilder.addRepository(new NewEventGroupRelationshipStore());
  storeBuilder.addRepository(new ChangeEventGroupRelationshipStore());
  storeBuilder.addRepository(new DropDownNMClustersStore());
}
